const actionTypes = require('constants/actionTypes');
const omit = require('lodash/object/omit');
const uniqueId = require('lodash/utility/uniqueId');
const Immutable = require('immutable');

const initialState = Immutable.Map({
    notifications: Immutable.List([])
});

function notifications(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.SHOW_NOTIFICATION:
            let newNotification = omit(action, 'type');
            newNotification.uid = uniqueId('notification_');
            newNotification.showed = false;
            return state.merge({
                notifications: state.get('notifications').push(newNotification)
            });

        case actionTypes.HIDE_NOTIFICATION:
            return state.merge({
                notifications: state.get('notifications').filter(value => {
                    return value.uid !== action.uid;
                })
            });

        case actionTypes.SHOWED_NOTIFICATION:
            return state.merge({
                notifications: state.get('notifications').map(notification => {
                    if(notification.uid === action.uid) {
                        notification.showed = true
                    }
                    return notification
                })
            });

        default:
            return state
    }
}
module.exports = notifications;
