
//@vendors
const Immutable = require('immutable');

const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    WIZARD_THIRD_STEP,
} = require('constants/index');

const actionTypes = require('constants/contactCenter/actionTypes');

const authorizeBizumStep1 = require('./authorizeBizumSteps/authorizeBizumStep1');
const authorizeBizumStep2 = require('./authorizeBizumSteps/authorizeBizumStep2');
const authorizeBizumStep3 = require('./authorizeBizumSteps/authorizeBizumStep3');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            authorizeBizumStep1(undefined, {type: null}),
            authorizeBizumStep2(undefined, {type: null}),
            authorizeBizumStep3(undefined, {type: null}),
            {hasSignaturePattern:true, operationParams:{}}
        ],
        visibleStep: 1,
        isFetching: false,
        hasBeenSent: false,
        error: '',
        invalidSecurityDataError: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return authorizeBizumStep1(step, action);
            case SECOND_STEP:
                return authorizeBizumStep2(step, action);
            case THIRD_STEP:
                return authorizeBizumStep3(step, action);
        }
    });
}

function AuthorizeBizum(state = setInitialState(), action) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.BIZUM_REGISTER__SET_ACCOUNT:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BIZUM_REGISTER__VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_THIRD_STEP:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;

        case actionTypes.BIZUM_REGISTER_TERMS_CONDITIONS_SUCCESS:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BIZUM_REGISTER_SET_ERROR:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });

        case actionTypes.BIZUM_REGISTER_TERMS_CONDITIONS_FAILURE:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });

        case actionTypes.BIZUM_REGISTER_TERMS_CONDITIONS_REQUEST:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });

        case actionTypes.BIZUM_REGISTER__SET_TERMS_CONDITIONS:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BIZUM_REGISTER__SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.BIZUM_AUTHORIZE_SAVE_START:
            return state.merge({
                error: '',
                isFetching: true,
            });

        case actionTypes.BIZUM_AUTHORIZE_SAVE_SUCCESS:
            return state.merge({
                hasBeenSent: true,
                error: '',
                isFetching: false
            });
        case actionTypes.BIZUM_REGISTER_WITH_PORTABILITY:
            return state.merge({
                hasBeenSent: true,
                error: action.payload.error,    // errorCode to display portability flow
                isFetching: false,
                authorizeBizumToken: action.payload.tokenFirma  // store token returned from confirmAlta for confimarPortability
            });
        case actionTypes.BIZUM_REGISTER_WITH_PORTABILITY_RESEND:
            return state.merge({
                tokenFirma: action.payload.tokenFirma  // update stored token returned from confirmAlta while resending
            });
        case actionTypes.BIZUM_AUTHORIZE_SAVE_FAILURE :
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });

        case actionTypes.BIZUM_AUTHORIZE_INVALID_SECURITY_DATA_ERROR:
            return state.merge({
                isFetching: false,
                invalidSecurityDataError: true,
            });

        case actionTypes.BIZUM_AUTHORIZE_VALID_SECURITY_DATA:
            return state.merge({
                invalidSecurityDataError: false,
            });

        case actionTypes.BIZUM_AUTHORIZE_SAVE_RESET:
            return setInitialState();

        default:
            return state;
    }
}

module.exports = AuthorizeBizum;
