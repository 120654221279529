const Immutable = require('immutable');

// @ constants
const {
    MORTGAGE_REQUEST_VIEW_PROCESS_FILE_REQUEST,
    MORTGAGE_REQUEST_VIEW_PROCESS_FILE_SUCESS,
    MORTGAGE_REQUEST_VIEW_PROCESS_FILE_FAILURE,
    MORTGAGE_REQUEST_VIEW_PROCESS_DATA_REQUEST,
    MORTGAGE_REQUEST_VIEW_PROCESS_DATA_SUCESS,
    MORTGAGE_REQUEST_VIEW_PROCESS_DATA_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    file: '',
    error: '',
    isDownloading: false,
    isPrinting: false,
    isFetched: false,
    relatedProducts: {
        isFetching: false,
        homeInsurancePriceClosed: false,
        show: false
    }
});

const mortgageRequestViewProcess = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_VIEW_PROCESS_FILE_REQUEST:
            return state.merge({
                isDownloading: action.data.isDownloading,
                isPrinting: action.data.isPrinting
            });
        case MORTGAGE_REQUEST_VIEW_PROCESS_FILE_SUCESS:
            return state.merge({
                file: action.file.dataBase64,
                isDownloading: false,
                isPrinting: false,
                isFetched: true
            });
        case MORTGAGE_REQUEST_VIEW_PROCESS_FILE_FAILURE:
            return state.merge({
                error: action.file.error,
                isDownloading: false,
                isPrinting: false,
                isFetching: false
            });
        case MORTGAGE_REQUEST_VIEW_PROCESS_DATA_REQUEST:
            return initialState.mergeDeep({
                relatedProducts: {
                    isFetching: true
                }
            });
        case MORTGAGE_REQUEST_VIEW_PROCESS_DATA_SUCESS:
            const { homeInsurancePriceClosed, show } = action.data.relatedProducts;
            return state.mergeDeep({
                relatedProducts: {
                    isFetching: false,
                    homeInsurancePriceClosed: homeInsurancePriceClosed,
                    show: show
                }
            });
        case MORTGAGE_REQUEST_VIEW_PROCESS_DATA_FAILURE:
            return state.mergeDeep({
                relatedProducts: {
                    isFetching: false
                }
            });
        default:
            return state
    }
}

module.exports = mortgageRequestViewProcess
