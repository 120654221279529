// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        confirmKey: '',
        confirmKeyIsValid: true,
        newKey: '',
        newKeyIsValid: true,
        missedKeyTest: 0,
        newKeyDirty: false,
        valid: false
    });
}

function setField(step, action) {
    const { inputField, data } = action.payload;
    return step.merge({
        [inputField]: data,
        [`${inputField}Dirty`]: true
    });
}

function validateFields(state){
    return state.merge({
        confirmKeyIsValid: state.get('confirmKey').length && state.get('confirmKey') === String(state.get('newKey')),
        newKeyIsValid: state.get('newKey').length && !state.get('missedKeyTest'),
        missedKeyTest: state.get('missedKeyTest')
    });
}

function validateStep(step) {
    const validateState = validateFields(step);
    return validateState.merge({
        valid: !validateState.missedKeyTest && validateState.get('confirmKeyIsValid') && validateState.get('newKeyIsValid'),
        newKeyDirty: false,
        missedKeyTest: validateState.missedKeyTest
    });
}

function accessKeyChangeStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ACCESS_KEY_CHANGE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.ACCESS_KEY_CHANGE_SET_FIELD:
            return setField(state,action);
        default:
            return state;
    }
}

module.exports = accessKeyChangeStep1;
