const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');

const brokerTransferValuesStep1 = require('./brokerTransferValues/brokerTransferValuesStep1');
const brokerTransferValuesStep2 = require('./brokerTransferValues/brokerTransferValuesStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerTransferValuesStep1(undefined, {type: null}),
            brokerTransferValuesStep2(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerTransferValuesStep1(step, action);
            case SECOND_STEP:
                return brokerTransferValuesStep2(step, action);
        }
    })
}

function brokerTransferValuesReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.BROKER_TRANSFER_VALUES_SET_TYPE:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_VALUES_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep,
                        isFetching: false
                    })
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(1).get('valid') ? 3 : 2;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep,
                        isFetching: false
                    })
            }
            return state;
        case actionTypes.BROKER_TRANSFER_VALUES_RESET_CONTRACTS_ERRORS:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNT_FROM:
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNT_TO:
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNTS_FROM_OPTIONS:
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNTS_TO_OPTIONS:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_VALUES_TOGGLE_CHECK:
        case actionTypes.BROKER_TRANSFER_VALUES_SET_TITLES:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_VALUES_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.BROKER_TRANSFER_VALUES_RESET:
            return setInitialState();
        case actionTypes.BROKER_TRANSFER_VALUES_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.BROKER_TRANSFER_VALUES_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.BROKER_TRANSFER_VALUES_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            })
        case actionTypes.BROKER_TRANSFER_VALUES_FAILURE:
            return state.merge({
                isFetching: false,
                submitError: true,
                error: 'Contract not found' //@hardcoded
            })
        default:
            return state
    }
}

module.exports = brokerTransferValuesReducer;
