// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    accountMovementRequest: {},
    cardMovementRequest: {},
    emitedTransferMovementRequest: {}
});

function personeticsDeepLinks(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.SET_PERSONETICS_ACCOUNT_MOVEMENT_REQUEST:
            return state.merge({
                accountMovementRequest: action.payload,
            });
        case actionTypes.SET_PERSONETICS_CARD_MOVEMENT_REQUEST:
            return state.merge({
                cardMovementRequest: action.payload,
            });
        case actionTypes.SET_PERSONETICS_EMITED_TRANSFER_MOVEMENT_REQUEST:
            return state.merge({
                emitedTransferMovementRequest: action.payload,
            });
        default:
            return state;
    }
}

module.exports = personeticsDeepLinks;