// @ vendors
const React = require('react');
//@commons
const { FormattedText, formatText, getLanguage } = require('core/i18n').i18n;
//@helpers
const formValidationHelper = require('../utilities/formValidationHelper');
const { formatAmount } = require('utilities/currencyHelper');
const DateHelper = require('utilities/dateHelper');
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
const { recordLink } = require("utilities/tagging");
const { getStore } = require('core/storeHelper');
const { getDepositsPrintedDocument } = require('../utilities/contactCenter/depositsPrintedDocumentHelper');

let store = null;
const validStore = () => {
    if (!store) store = getStore();
    return !!(typeof store !== 'undefined' && store);
}
//@constants
const {
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
    DEPOSIT_HIRE_CONFIRMATION_MANAGER,
    EURO_SYMBOL
} = require('constants/index');
const {
    CHECKED_ACTION_EVENT,
    DEPOSIT_ACCEPT,
    INCREASE_NEW_MONEY_MODAL_CLOSE,
    INCREASE_NEW_MONEY_MODAL_CONTINUE,
    DEPOSIT_BACK,
    DEPOSIT_BIGGER_THAN_BALANCE,
    DEPOSIT_BIGGER_THAN_GLOBAL_POSITION_INCREMENT,
    DEPOSIT_CLOSE,
    DEPOSIT_CONTINUE,
    DEPOSIT_MORE_INFO,
    DEPOSIT_NO_MONEY,
    DEPOSIT_RENOVATION,
    DEPOSIT_VIEW,
    UNCHECKED_ACTION_EVENT,
} = require('constants/tagging');
const DATE_FORMAT_TYPES = require('commonsConstants/dateFormatTypes');
const { HIRING_MAIN_DEPOSITS_WITHOUT_NEWBALANCE } = require('constants/flagBasedFeatures')


const datesNotations = {
    es: DATE_FORMAT_TYPES['es'].DATE_FORMAT_WITH_SLASHES,
    en: DATE_FORMAT_TYPES['en'].DATE_FORMAT_WITH_SLASHES,
    de: 'DD.MM.YYYY',
    pt: DATE_FORMAT_TYPES['pt'].DATE_FORMAT_WITH_SLASHES,
    fr: DATE_FORMAT_TYPES['fr'].DATE_FORMAT_WITH_SLASHES,
    nl: DATE_FORMAT_TYPES['nl'].DATE_FORMAT_WITH_DASHES,
}

function customCloseButton() {
    return <div />
}

function customErrorNotAllowed(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    const maximumAvailableAmount = `${formatAmount(
        depositHireData.get('bssaldo1')
    )} ${EURO_SYMBOL}`;
    const currentGlobalPosition = `${formatAmount(
        depositHireData.get('bssaldo2')
    )} ${EURO_SYMBOL}`;
    const globalPositionAtBeginningOfCampaign = `${formatAmount(
        depositHireData.get('bssaldo3')
    )} ${EURO_SYMBOL}`;
    const activeHiredDeposits = `${formatAmount(
        depositHireData.get('bssaldo4')
    )} ${EURO_SYMBOL}`;

    return (
        <table>
            <tbody>
                <tr>
                    <td colSpan="2" className="deposit-nm__custom-error-text">
                        <FormattedText value="contractsView-depositEighteenMonthsContractCustomErrorHeader" />
                        <strong>
                            {maximumAvailableAmount}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td className="deposit-nm__custom-error-cell">
                        <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine1" />
                    </td>
                    <td className="deposit-nm__custom-error-cell">
                        <strong>
                            {currentGlobalPosition}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td className="deposit-nm__custom-error-cell">
                        <FormattedText value="contractsView-depositEighteenMonthsCustomErrorTableLine2" />
                    </td>
                    <td className="deposit-nm__custom-error-cell">
                        <strong>
                            {globalPositionAtBeginningOfCampaign}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td className="deposit-nm__custom-error-cell">
                        <FormattedText value="contractsView-depositEighteenMonthsCustomErrorTableLine3" />
                    </td>
                    <td className="deposit-nm__custom-error-cell">
                        <strong>
                            {activeHiredDeposits}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className="deposit-nm__custom-error-footer">
                        <FormattedText value="contractsView-depositEighteenMonthsContractCustomErrorFooter" />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function loadSliderAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.min_value = depositHireData.get('importeMin');
        component.max_value = depositHireData.get('importeMax');
        component.default_value = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
    }
    return component;
}

function depositnm_loadAmountInputAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.importMin = depositHireData.get('importeMin');
        component.importMax = depositHireData.get('importeMax');
        component.defaultValue = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
        const chosenDepositMax = contractWizard.getIn(['deposit_product', 'max']);
        const chosenDepositMin = contractWizard.getIn(['deposit_product', 'min']);
        component.infoMessage = chosenDepositMax
            ? <FormattedText value={component.infoMessageComplete} injectedStrings={[`${formatAmount(chosenDepositMin)} ${EURO_SYMBOL}`, `${formatAmount(chosenDepositMax)} ${EURO_SYMBOL}`]} />
            : <FormattedText value={component.infoMessageMinimum} injectedStrings={[`${formatAmount(chosenDepositMin)} ${EURO_SYMBOL}`]} />;
    }
    return component;
}


function maxValueAllowedIsPositive(depositHireData) {
    return depositHireData && depositHireData.get('bssaldo1', 0) >= 0;
}

function isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData) {
    return (
        0 < depositHireData.get('bssaldo1', 0) &&
        depositHireData.get('bssaldo1', 0) < depositHireData.get('importeMin', 0)
    );
}

function isOpenMethodOrNegative(depositHireData) {
    return (
        depositHireData.get('error') ||
        !maxValueAllowedIsPositive(depositHireData) ||
        isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData)
    );
}

function depositBiggerThanMaxValueAllowed(contractWizard) {
    const depositAmount = findElementbyId(contractWizard.get('card_details'), 'monto_traspasar');
    return depositAmount > contractWizard.getIn(['depositHireData', 'bssaldo1'], 0);
}

function dropdwonAccountShouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'installment', 'value')
    );
}

function depositnm_shouldInputSubLabelBuild(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    return (depositHireData && depositHireData.get('bssaldo1', 0) > 0);
}

function shouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'cta_seleccionada', 'description') &&
        !contractWizard.get('intervenersIsFetching') &&
        !contractWizard.get('dataIsFetching') &&
        !contractWizard.getIn(['depositHireData', 'error']) &&
        maxValueAllowedIsPositive(contractWizard.get('depositHireData'))
    );
}

function buildSliderExtraInfo(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    let tooltip = null;

    if (!contractWizard.get('dataIsFetching')) {
        const currentGlobalPosition = `${formatAmount(
            depositHireData.get('bssaldo2')
        )} ${EURO_SYMBOL}`;
        const globalPositionAtBeginningOfCampaign = `${formatAmount(
            depositHireData.get('bssaldo3')
        )} ${EURO_SYMBOL}`;
        const activeHiredDeposits = `${formatAmount(
            depositHireData.get('bssaldo4')
        )} ${EURO_SYMBOL}`;
        const maximumAvailableAmount = `${formatAmount(
            depositHireData.get('bssaldo1')
        )} ${EURO_SYMBOL}`;

        tooltip = (
            <article className="deposit-nm__slider-tooltip">
                <table>
                    <thead>
                        <tr>
                            <td
                                colSpan="2"
                                className="deposit-nm__slider-tooltip-cell"
                            >
                                <FormattedText value="contractsView-depositEighteenMonthsTooltipTableHeader" />
                            </td>
                        </tr>
                    </thead>
                    <tbody className="deposit-nm__slider-tooltip-body">
                        <tr>
                            <td className="deposit-nm__slider-tooltip-cell">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine1" />
                            </td>
                            <td className="deposit-nm__slider-tooltip-cell">
                                {currentGlobalPosition}
                            </td>
                        </tr>
                        <tr>
                            <td className="deposit-nm__slider-tooltip-cell">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine2" />
                            </td>
                            <td className="deposit-nm__slider-tooltip-cell">
                                {globalPositionAtBeginningOfCampaign}
                            </td>
                        </tr>
                        <tr>
                            <td className="deposit-nm__slider-tooltip-cell">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine3" />
                            </td>
                            <td className="deposit-nm__slider-tooltip-cell">
                                {activeHiredDeposits}
                            </td>
                        </tr>
                        <tr>
                            <td className="deposit-nm__slider-tooltip-footer-cell deposit-nm__slider-tooltip-footer-cell--first-section">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableHeader" />
                            </td>
                            <td className="deposit-nm__slider-tooltip-footer-cell deposit-nm__slider-tooltip-footer-cell--second-section">
                                {maximumAvailableAmount}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </article>
        );
    }

    return tooltip;
}

function signParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails
        ? findElementbyId(immCardDetails, 'cta_seleccionada')
        : null;
    let selectedAccount;

    if (immAccounts && selectedAccountId) {
        const immAccount = immAccounts.getIn(['byId', selectedAccountId]);
        selectedAccount =
            API_ENTERPRISE_NUMBER +
            API_CENTER +
            immAccount.get('product') +
            immAccount.get('contractNumber');
    }

    return {
        originAccount: selectedAccount
    };
}

function onChangeAmount(amount) {
    let ret = [];
    ret.push({
        target: 'monto_traspasar',
        value: amount
    });
    return ret;
}

function onChangeCheckbox(card_details, component, event) {
    card_details.find(detail => detail.id === component.target).value = event.target.checked;

    recordLink(window.utag_last_name, DEPOSIT_RENOVATION, event.target.checked ? CHECKED_ACTION_EVENT : UNCHECKED_ACTION_EVENT);
    return card_details;
}

function getCustomPrintDocument(contractWizard, immProfile, immAccounts) {
    return getDepositsPrintedDocument(contractWizard, immProfile, immAccounts);
}

function selectedAccountBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const ctaSeleccionada = findElementbyId(cardDetails, 'cta_seleccionada');
    const selectedAccountBalance =
        contractWizard.getIn([
            's_accounts',
            'value',
            'byId',
            ctaSeleccionada,
            'balances',
            'availableBalance',
            'amount'
        ]) || findElementbyId(cardDetails, 'balance_account');
    return selectedAccountBalance;
}

function depositBiggerThanBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = findElementbyId(cardDetails, 'monto_traspasar');
    return depositAmount > selectedAccountBalance(contractWizard);
}

function show45DaysWarning(contractWizard) {
    return depositBiggerThanBalance(contractWizard) || depositBiggerThanMaxValueAllowed(contractWizard);
}


function setSuccessStepPrefix() {
    let successStepPrefix = {};

    return successStepPrefix;
}


function setSuccessStepAdditionalInfo() {
    return null;
}

function nextButtonDisabled(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = cardDetails && findElementbyId(cardDetails, 'monto_traspasar');
    return !!(cardDetails && !findElementbyId(cardDetails, 'cta_seleccionada', 'description') ||
        contractWizard.get('intervenersIsFetching') || contractWizard.get('dataIsFetching') || !depositAmount ||
        contractWizard.getIn(['depositHireData', 'error']) || !maxValueAllowedIsPositive(contractWizard.get('depositHireData')));
}

function pollingNextButtonDisabled(contractWizard) {
    return !contractWizard.get('conditionDocumentConfirmation') && !__CONTACT_CENTER__ || (__CONTACT_CENTER__ && !contractWizard.getIn(['confirmationCheckboxes', 'areValid']));
}

function showFinalModal(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    if (!contractWizard.get('dataIsFetching') && (!depositHireData.get('error') && maxValueAllowedIsPositive(depositHireData))) {
        return depositBiggerThanMaxValueAllowed(contractWizard) ||
            depositBiggerThanBalance(contractWizard) ||
            isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData);
    }
    return false;
}

function showInitialModal(depositHireData)  {
    const flagWithoutNewBalanceEnable = validStore() && store.getState().flagBasedFeatures.get(HIRING_MAIN_DEPOSITS_WITHOUT_NEWBALANCE)
        return (
            (depositHireData.get('error') || !maxValueAllowedIsPositive(depositHireData))
            && flagWithoutNewBalanceEnable
        )
}

function showWithoutNewBalanceModal(depositHireData) {
    const flagWithoutNewBalanceEnable = validStore() && store.getState().flagBasedFeatures.get(HIRING_MAIN_DEPOSITS_WITHOUT_NEWBALANCE)
    return (
        (depositHireData.get('error') || !maxValueAllowedIsPositive(depositHireData))
        && !flagWithoutNewBalanceEnable
    )
}

function modalWithTable(contractWizard, textLineOne, amountLineOne, text, notes, renderTable = true, textInjectedStrings = []) {
    const depositHireData = contractWizard.get('depositHireData');
    const context = contractWizard.getIn(['deposit_product', 'context']);
    const depositStartDate = contractWizard.getIn(['deposit_product', 'startDate']);

    const maximumAvailableAmount = formatAmount(depositHireData.get('bssaldo1'));
    const currentGlobalPosition = `${formatAmount(depositHireData.get('bssaldo2'))} ${EURO_SYMBOL}`;
    const globalPositionAtBeginningOfCampaign = `${formatAmount(depositHireData.get('bssaldo3'))} ${EURO_SYMBOL}`;
    const activeHiredDeposits = `${formatAmount(depositHireData.get('bssaldo4'))} ${EURO_SYMBOL}`;
    const currentDate = DateHelper.todayDate(datesNotations[getLanguage()]);
    const renderLineOne = textLineOne && (
        <div>
            <FormattedText
                value={textLineOne}
                injectedStrings={[amountLineOne]}
            />
        </div>
    );

    const renderWarningMessage = maximumAvailableAmount && (
        <div className="deposit-nm__initial-modal-message">
            <FormattedText
                value="contractsView-contractsView_InitialModalLine2"
                injectedStrings={[formatText(`${context}-${depositStartDate}`), maximumAvailableAmount]}
            />
        </div>
    );

    const renderModalTable = renderTable && (
        <div className="deposit-nm__initial-modal-table-container">
            <table className="deposit-nm__initial-modal-table">
                <tbody>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column">
                            <FormattedText
                                value="contractsView-contractsView_depositnmInitialModalTableLine1"
                                injectedStrings={[currentDate]}
                            />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column">
                            {currentGlobalPosition}
                        </td>
                    </tr>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column">
                            <FormattedText
                                value="contractsView-contractsView_depositnmInitialModalTableLine2"
                                injectedStrings={[formatText(`${context}-${depositStartDate}`)]}
                            />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column">
                            {globalPositionAtBeginningOfCampaign}
                        </td>
                    </tr>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column deposit-nm__initial-modal-table-last">
                            <FormattedText value="contractsView-contractsView_depositnmInitialModalTableLine3" />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column deposit-nm__initial-modal-table-last">
                            {activeHiredDeposits}
                        </td>
                    </tr>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column ">
                            <FormattedText value="contractsView-contractsView_depositnmInitialModalTableLine4" />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column">
                            {maximumAvailableAmount}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="deposit-nm__initial-modal-body">
            {renderLineOne}
            {renderWarningMessage}
            {renderModalTable}
            <div>
                <FormattedText value={text} injectedStrings={textInjectedStrings} />
            </div>
            <div>
                {notes}
            </div>
        </div>
    );
}

function withoutNewBalanceModalChildren(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    const decreaseAmount = contractWizard.get('decreaseAmount');
    const context = contractWizard.getIn(['deposit_product', 'context']);
    const depositName = contractWizard.getIn(['deposit_product', 'name']);
    const depositStartDate = contractWizard.getIn(['deposit_product', 'startDate']);
    const phones = (
        <div className="deposit-nm__initial-modal-phones-container">
            <FormattedText className="deposit-nm__initial-modal-phone" value="contractsView-contractsView_depositnmPhoneNumberClients" />
        </div>
    );

    let modal;

    if (depositHireData.get('error')) {
        modal = modalWithTable(
            contractWizard,
            undefined,
            undefined,
            'contractsView-contractsView_depositnmStep2WithoutNewBalanceModalDescription',
            phones,
            false,
            [formatText(`${context}-${depositName}`), formatText(`${context}-${depositStartDate}`), formatAmount(decreaseAmount)]
        );
    }

    return modal;
}

function initialModalChildren(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    const decreaseAmount = contractWizard.get('decreaseAmount');
    const context = contractWizard.getIn(['deposit_product', 'context']);
    const depositName = contractWizard.getIn(['deposit_product', 'name']);
    const depositStartDate = contractWizard.getIn(['deposit_product', 'startDate']);
    const phones = (
        <div className="deposit-nm__initial-modal-phones-container">
            <FormattedText className="deposit-nm__initial-modal-phone" value="contractsView-contractsView_depositnmPhoneNumberClients" />
        </div>
    );

    let modal;
    if (depositHireData.get('error')) {
        modal = modalWithTable(
            contractWizard,
            undefined,
            undefined,
            'contractsView-contractsView_depositnmModalLine4Negative',
            phones,
            false,
            [formatText(`${context}-${depositName}`), formatText(`${context}-${depositStartDate}`), formatAmount(decreaseAmount)]
        );
    }

    return modal;
}

function finalModalTitle(contractWizard) {
    return depositBiggerThanMaxValueAllowed(contractWizard)
        ? 'contractsView-contractsView_depositnmValidationModalTitleBiggerThanAllowed'
        : 'contractsView-contractsView_Step2ValidationModalTitle';
}

function modalWithSummary() {
    return (
        <div className="deposit-nm__initial-modal-body">
            <div className="deposit-nm__initial-modal-message">
                <FormattedText value="contractsView-contractsView_depositnmFinalModalRequestedMoreThanBalanceLine1" />
            </div>
        </div>
    );
}

function initialModalStateChange(contractWizard,state){
    switch (state) {
        case 'OPEN':
            recordLink(window.utag_last_name, DEPOSIT_NO_MONEY ,DEPOSIT_VIEW)
            break;
        case 'CLOSED':
            recordLink(window.utag_last_name, DEPOSIT_NO_MONEY ,DEPOSIT_CLOSE)
            break;
        case 'ONCONTINUE':
            recordLink(window.utag_last_name, DEPOSIT_NO_MONEY ,INCREASE_NEW_MONEY_MODAL_CONTINUE)
            break;
        case 'ONBACK':
            recordLink(window.utag_last_name, DEPOSIT_NO_MONEY ,INCREASE_NEW_MONEY_MODAL_CLOSE)
            break;
    }
}

function moreInfoModalStateChange(contractWizard,state){
    switch (state) {
        case 'OPEN':
            recordLink(window.utag_last_name, DEPOSIT_MORE_INFO ,DEPOSIT_VIEW)
            break;
        case 'CLOSED':
            recordLink(window.utag_last_name, DEPOSIT_MORE_INFO ,DEPOSIT_CLOSE)
            break;
        case 'ONCONTINUE':
            recordLink(window.utag_last_name, DEPOSIT_MORE_INFO ,DEPOSIT_ACCEPT)
            break;
    }
}

function onFinalModalStateChange(contractWizard,state){
    const states = [
        {
            callback: (modalType)=>{recordLink(window.utag_last_name, modalType ,DEPOSIT_VIEW)},
            value: 'OPEN'
        },
        {
            callback: (modalType)=>{recordLink(window.utag_last_name, modalType ,DEPOSIT_CLOSE)},
            value: 'CLOSED'
        },
        {
            callback: (modalType)=>{recordLink(window.utag_last_name, modalType ,DEPOSIT_CONTINUE)},
            value: 'ONCONTINUE'
        },
        {
            callback: (modalType)=>{recordLink(window.utag_last_name, modalType ,DEPOSIT_BACK)},
            value: 'ONBACK'
        }
    ];

    if (depositBiggerThanMaxValueAllowed(contractWizard)) {
        states.some(item =>{
            if(item.value === state){
                item.callback(DEPOSIT_BIGGER_THAN_GLOBAL_POSITION_INCREMENT)
                return true
            } else {
                false
            }
        })
    } else if (depositBiggerThanBalance(contractWizard)) {
         states.some(item =>{
            if(item.value === state){
                item.callback(DEPOSIT_BIGGER_THAN_BALANCE)
                return true
            } else {
                false
            }
        })
    }

}

function newBalanceDetailedSummaryContent(contractWizard, textFirstParagraph, secondParagraph) {
    const currentDate = DateHelper.todayDate(datesNotations[getLanguage()]);
    const depositHireData = contractWizard.get('depositHireData');
    const context = contractWizard.getIn(['deposit_product', 'context']);
    const depositStartDate = contractWizard.getIn(['deposit_product', 'startDate']);
    const maximumAvailableAmount = `${formatAmount(depositHireData.get('bssaldo1'))} ${EURO_SYMBOL}`;
    const currentGlobalPosition = `${formatAmount(depositHireData.get('bssaldo2'))} ${EURO_SYMBOL}`;
    const globalPositionAtBeginningOfCampaign = `${formatAmount(depositHireData.get('bssaldo3'))} ${EURO_SYMBOL}`;
    const activeHiredDeposits = `${formatAmount(depositHireData.get('bssaldo4'))} ${EURO_SYMBOL}`;
    const renderFirstParagraph = textFirstParagraph && (
        <div>
            <FormattedText
                value={textFirstParagraph}
                injectedStrings={[formatText(`${context}-${depositStartDate}`), maximumAvailableAmount]}

            />
        </div>
    );
    const renderModalTable = (
        <div className="deposit-nm__initial-modal-table-container">
            <table className="deposit-nm__initial-modal-table">
                <tbody>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column">
                            <FormattedText value="contractsView-depositNMMoreInfoModalTableLine1" injectedStrings={[currentDate]} />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column">
                            {currentGlobalPosition}
                        </td>
                    </tr>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column">
                            <FormattedText
                                value="contractsView-depositNMMoreInfoModalLine2"
                                injectedStrings={[formatText(`${context}-${depositStartDate}`)]}
                            />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column">
                            {globalPositionAtBeginningOfCampaign}
                        </td>
                    </tr>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column deposit-nm__initial-modal-table-last">
                            <FormattedText value="contractsView-depositNMMoreInfoModalTableLine3" />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column deposit-nm__initial-modal-table-last">
                            {activeHiredDeposits}
                        </td>
                    </tr>
                    <tr className="deposit-nm__initial-modal-table-row">
                        <th className="deposit-nm__initial-modal-table-first-column">
                            <FormattedText value="contractsView-depositNMMoreInfoModalTableLine4" />
                        </th>
                        <td className="deposit-nm__initial-modal-table-second-column">
                            {maximumAvailableAmount}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="deposit-nm__initial-modal-body">
            {renderFirstParagraph}
            {renderModalTable}
            <div>
                {secondParagraph}
            </div>
        </div>
    );
}

function moreInfoModalChildren(contractWizard) {
    if (!contractWizard.get('dataIsFetching')) {
        const modal = newBalanceDetailedSummaryContent(contractWizard, 'contractsView-depositNMMoreInfoModalFirstParagraph', formatText('contractsView-depositNMMoreInfoModalSecondParagraph'))
        return modal;
    }
}

function finalModalChildren(contractWizard) {
    if (!contractWizard.get('dataIsFetching')) {
        const cardDetails = contractWizard.get('card_details');
        const amount = findElementbyId(cardDetails, 'monto_traspasar') || 0;
        const depositHireData = contractWizard.get('depositHireData');

        let modal;
        if (depositBiggerThanMaxValueAllowed(contractWizard) || isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData)) {
            modal = modalWithTable(
                contractWizard,
                '',
                formatAmount(amount),
                'contractsView-contractsView_depositnmFinalModalTableLine3',
            );
        } else if (depositBiggerThanBalance(contractWizard)) {
            modal = modalWithSummary(contractWizard, formatAmount(amount));
        }
        return modal;
    }
}

function validateStep1(card_details) {
    return {
        card_details: card_details,
        isValidStep: true
    };
}

function validateStep2(cardDetails, state, isValid) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const slideAmountIsNonZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;
    let newCardDetails;

    if (isValid && isValidAccount && slideAmountIsNonZero) {
        newCardDetails = cardDetails.update(
            cardDetails.findIndex(item => item.get('id') === 'validationModal'),
            item => item.set('openModal', showFinalModal(state))
        );
    } else {
        newCardDetails = cardDetails;
    }

    return {
        card_details: newCardDetails,
        isValidStep: isValidAccount && slideAmountIsNonZero
    };
}


function validateStep3(card_details, conditionDocumentConfirmation) {
    let validation;
    if (!__CONTACT_CENTER__) {
        validation = {
            card_details: card_details,
            isValidStep: conditionDocumentConfirmation,
        };
    } else {
        validation = {
            card_details: card_details,
            isValidStep: true,
        };
    }
    return validation;
}

function validateStep4(cardDetails, immConfirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = immConfirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: immConfirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: immConfirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, state.get('conditionDocumentConfirmation'));

        case 4:
            return validateStep4(immCardDetails, immConfirmationCheckboxes);

    }
}

function hireParamsFn(contractWizard, immAccount) {
    const cardDetails = contractWizard.get('card_details');
    const depositProduct = contractWizard.get('deposit_product');
    const depositHireData = contractWizard.get('depositHireData');
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const interveners = contractWizard.get('interveners');
    const intervenersData = interveners.map((intervener, index) => ({
        desIntervencion: intervener.get('type'),
        formaIntervencion: intervener.get('interventionWay'),
        nombre: intervener.get('name'),
        numeroPersona: intervener.getIn(['identification', 'code']),
        ordenIntervencion: index,
        tipoIntervencion: intervener.get('typeNumber'),
        tipoPersona: intervener.getIn(['identification', 'type'])
    }));

    return {
        aa1: depositHireData.get('aa1'),
        aa2: depositHireData.get('aa2'),
        aa3: depositHireData.get('aa3'),
        adelta: depositHireData.get('adelta'),
        campana: DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
        canalComercializacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        canalOperacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        codPeridoLiq: depositHireData.get('codPeridoLiq'),
        cotPeriodo: depositHireData.get('cotPeriodo'),
        cuentaSeleccionada: {
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        },
        datos: {
            dato: intervenersData
        },
        depositoB: 'S', // "N" if is Welcome Deposit, otherwise "S" //@hardcoded
        descPeriodicidad: depositHireData.get('descPeriodicidad'),
        descPlazo: depositHireData.get('descPlazo'),
        descProducto: depositHireData.get('descProducto'),
        fechaVencimiento: depositHireData.get('fechaVencimiento'),
        gestor: DEPOSIT_HIRE_CONFIRMATION_MANAGER,
        importe,
        importeMax: depositHireData.get('importeMax'),
        importeMin: depositHireData.get('importeMin'),
        indCapit: 'N', // interests capitalization "S" or "N" //@hardcoded
        indRevTo: findElementbyId(cardDetails, 'renewDeposit') ? 'S' : 'N', // Renovation type
        indala01: depositHireData.get('indala01'),
        indala02: depositHireData.get('indala02'),
        indala03: depositHireData.get('indala03'),
        indala04: depositHireData.get('indala04'),
        moneda: depositHireData.get('moneda'),
        numEmp: DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
        perLiq: depositHireData.get('perLiq'),
        plazoPos: depositHireData.get('plazoPos'),
        producto: {
            codigodeestandar: depositProduct.get('standard'),
            subtipodeproducto: {
                subtipodeproducto:
                    depositProduct.get('subtype'),
                tipodeproducto: {
                    tipodeproducto: depositProduct.get('type'),
                }
            }
        },
        saldoCuentaSeleccionada: {
            divisa: immAccount.getIn(['balance', 'currency']),
            importe: immAccount.getIn(['balance', 'amount'])
        },
        tasa: depositHireData.get('tasa'),
        tipo: depositProduct.get('type')
    };
}

function loadTermsAndConditionsLegalWindow(setTermsAndCondsLegalWindow) {
    setTermsAndCondsLegalWindow();
}

module.exports = {
    depositnm_buildSliderExtraInfo: buildSliderExtraInfo,
    depositnm_customErrorNotAllowed: customErrorNotAllowed,
    depositnm_customCloseButton: customCloseButton,
    depositnm_dropdwonAccountShouldBuild: dropdwonAccountShouldBuild,
    depositnm_FinalModalChildren: finalModalChildren,
    depositnm_FinalModalTitle: finalModalTitle,
    depositnm_getCustomPrintDocument: getCustomPrintDocument,
    depositnm_hireParamsFn: hireParamsFn,
    depositnm_InitialModalChildren: initialModalChildren,
    depositnm_WithoutNewBalanceModalChildren: withoutNewBalanceModalChildren,
    depositnm_isOpenMethodOrNegative: isOpenMethodOrNegative,
    depositnm_loadSliderAmounts: loadSliderAmounts,
    depositnm_loadAmountInputAmounts,
    depositnm_loadTermsAndConditionsLegalWindow: loadTermsAndConditionsLegalWindow,
    depositnm_loan_SignParams: signParams,
    depositnm_moreInfoModalChildren: moreInfoModalChildren,
    depositnm_nextButtonDisabled: nextButtonDisabled,
    depositnm_onChangeAmount: onChangeAmount,
    depositnm_onChangeCheckbox: onChangeCheckbox,
    depositnm_pollingNextButtonDisabled: pollingNextButtonDisabled,
    depositnm_setSuccessStepAdditionalInfo: setSuccessStepAdditionalInfo,
    depositnm_setSuccessStepPrefix: setSuccessStepPrefix,
    depositnm_shouldBuild: shouldBuild,
    depositnm_shouldInputSubLabelBuild,
    depositnm_showFinalModal: showFinalModal,
    depositnm_showInitialModal: showInitialModal,
    depositnm_showWithoutNewBalanceModal: showWithoutNewBalanceModal,
    depositnm_validateStep: validateStep,
    depositnm_show45DaysWarning: show45DaysWarning,
    depositnm_finalModalStateChange: onFinalModalStateChange,
    depositnm_moreInfoModalStateChange: moreInfoModalStateChange,
    depositnm_initialModalStateChange: initialModalStateChange,
};
