module.exports = {
    requestInProgress: {
        errorTitle: 'contractsView-upgradeToNominaRequsetInProgressErrorTitle',
        errorBody: 'contractsView-upgradeToNominaRequsetInProgressErrorBody',
        backButtonLabel: 'contractsView-errorBackButtonLabelGlobal',
        backButtonLink: '/myprofile/global'
    },
    timeoutError: {
        errorTitle: 'contractsView-upgradeToNominaTimeoutErrorTitle',
        errorBody: 'contractsView-upgradeToNominaTimeoutErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    }
}
