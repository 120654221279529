// @ vendors
const Immutable = require('immutable');

// @ constants
const {
    MORTGAGE_REQUEST_FETCH_ACTIVITIES_REQUEST,
    MORTGAGE_REQUEST_FETCH_ACTIVITIES_SUCCESS,
    MORTGAGE_REQUEST_FETCH_ACTIVITIES_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    descripcion: '',
    fecha: '',
    isFetching: false,
    error: ''
});

const mortgageActivities = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_ACTIVITIES_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_ACTIVITIES_SUCCESS:
            return state.merge({
                descripcion: action.data.data.descripcion || '',
                fecha: action.data.data.fecha || '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_ACTIVITIES_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = mortgageActivities;
