const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    agreementChecked: __CONTACT_CENTER__,
    agreementIsDirty: false,
    valid: __CONTACT_CENTER__
});

function validateStep(state) {
    if(__CONTACT_CENTER__) return state

    return state.merge({
        valid: state.get('agreementChecked'),
        agreementIsDirty: true
    });
}

function hireCardMetallicStep4(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_METALLIC_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.HIRE_CARD_METALLIC_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                agreementChecked: action.payload.checked,
                agreementIsDirty: false
            });

        default:
            return state;
    }
}

module.exports = hireCardMetallicStep4;
