const get = require('lodash/object/get');
const filter = require('lodash/collection/filter');
const { groupBy } = require('lodash/collection');
const constants = require('constants/index');
const { hash } = require('utilities/hash');
const trim = require('lodash/string/trim');
const { JUNIOR_ACCOUNT_SUCCESS_CODE, CHARITY_SUB_TYPES, CHARITY_TYPE } = require('constants/index');
const { PRODUCT_NAME } = require('utilities/tagging');

/**
 * Remove repeated contracts
 *
 * @param {Array} items
 * @returns {Array}
 */
function uniqContractFilter(items) {
    let result = [];
    let contracts = [];

    items.forEach(item => {
        const isRepeated = contracts.some(contract => {
            return (
                contract.number === item.contract.number &&
                contract.product === item.contract.product
            );
        });

        if (!isRepeated) {
            result.push(item);
            contracts.push(item.contract);
        }
    });

    return result;
}

/**
 * Retrieve a group of given currency using `field` argument (eg: 'saldoActual|saldoDisponible|SaldoDispuesto, etc')
 * and calculate the amount of `import` group by each `divisa`.
 *
 * @param {Array} items
 * @param {String} field
 * @returns {*}
 */
function groupByCurrencyAndCalculateTotally(items, field) {
    if (items.length === 0) {
        return [
            {
                currency: constants.EURO_TEXT,
                amount: 0
            }
        ];
    }
    // group items by currency fieldCurrency.
    const currencyGrouped = groupBy(items, `${field}.currency`);
    // iterate over each group of currency, and calculate total amount.
    const currencys = Object.keys(currencyGrouped).map(currency => {
        let total = {
            currency,
            amount: 0
        };
        currencyGrouped[currency].forEach(item => {
            total.amount += item[field].amount || 0;
        });
        return total;
    });

    // filter dirty currency like: empty, null or undefined.
    return filter(currencys, currency => !!currency.currency);
}

const applySign = (value, signAsText) => {
    if (signAsText === 'D') {
        // Debe
        return value * -1;
    } else {
        // Haber
        return value;
    }
};

function isBasicAccount(account) {
    return (
        get(account, 'cnuevo.producto') === '300' &&
        get(account, 'criterios.c1.subtipoproducto') === '810'
    );
}

function isECard(card) {
    return (
        get(card, 'contrato.producto') === constants.TYPE_CONTRACT_MASTERCARD &&
        get(card, 'criterios.c1.subtipoproducto') === constants.ECARD_SUBTYPE
    );
}

function isMinicard(card) {
    const subtype = get(card, 'criterios.c1.subtipoproducto');
    const product = get(card, 'contrato.producto');

    return product === '506' && subtype === '007';
}

function isMetallicCard(card) {
    const type = get(card, 'contrato.producto', '');
    const subtype = get(card, 'criterios.c1.subtipoproducto', '');
    let isMetallic = false;

    if (type === '506') {
        isMetallic = ['020', '021', '022'].includes(subtype);
    }

    return isMetallic;
}
function isR42MasterCard(card) {
    const type = get(card, 'contrato.producto', '');
    const subtype = get(card, 'criterios.c1.subtipoproducto', '');
    let isR42Master = false;

    if (type === '506') {
        isR42Master = ['013', '014', '015'].includes(subtype);
    }

    return isR42Master;
}
function isR42BetaalPasCard(type, subtype) {
    return type === '506' && subtype === '019';
}


function isOpenDebit(card) {
    const subtype = get(card, 'criterios.c1.subtipoproducto');
    const product = get(card, 'contrato.producto');

    // '013' is the subtype for openDebit cards in Germany
    return product === '506' && (subtype === '002' || subtype === '004');
}

function isOpenCreditCard(type, subtype) {
    return type === '500' && subtype === '801';
}

function isCharity(card) {
    const subtype = get(card, 'criterios.c1.subtipoproducto');
    const product = get(card, 'contrato.producto');
    const isCharitySubType = CHARITY_SUB_TYPES.some(sub => sub === subtype);

    return product === CHARITY_TYPE && isCharitySubType;
}

const isOpenYoung = card => {
    const product = get(card, 'contrato.producto');
    const subtype = get(card, 'criterios.c1.subtipoproducto');

    return product === '506' && subtype === '010';
};

function isMortgage(product, subtype, productStandard) {
    if (product === '103') {
        return true;
    }

    //(nicolas.olmos): HIPOTECA OPEN 123-806
    if (product === '123') {
        if (
            subtype === '800' ||
            subtype === '804' ||
            subtype === '805' ||
            subtype === '806' ||
            subtype === '807'
        ) {
            return true;
        }

        if (subtype === '802' && productStandard !== '0000004') {
            return true;
        }
    }

    return false;
}

function isAres(product, subtype) {
    return product === '143' && subtype === '808';
}

function isPayroll(product, subtype) {
    return (product === '143' && subtype === '804');
}

function isRetail(product, subtype) {
    return (product === '143' && subtype === '806');
}

function isOldLoanType(type, subtype) {
    const OLD_SUBTYPES = ['100', '308', '800', '801', '802', '803', '804', '805', '806'];
    return type === '123' && OLD_SUBTYPES.indexOf(subtype) !== -1;
}

function isRestrictedLoanType(type, subtype) {
    const RESTRICTED_SUBTYPES = ['807', '808'];
    return type === '123' && RESTRICTED_SUBTYPES.indexOf(subtype) !== -1;
}

function isPreapproved(product, subtype) {
    return product === '143' && subtype === '802';
}

function isFutureAccount(account) {
    return (
        get(account, 'cnuevo.producto') === '300' &&
        get(account, 'criterios.c1.subtipoproducto') === '804'
    );
}

function isCardAvailable(card) {
    return get(card, 'filtros.valido', 'N') !== 'N';
}

function isItemAvailable(item) {
    return get(item, 'filtros.valido', 'N') !== 'N';
}

function filterCardsAvailables(cards) {
    return filter(cards, card => isCardAvailable(card));
}

function filterCards(cards) {
    return filterCardsAvailables(filter(cards, card => !isECard(card)));
}

function mergeOrderAndId(byOrder, byId) {
    return (
        (byOrder &&
            byOrder.map(id => {
                return byId.toJS ? byId.get(id) : byId[id]; //Return an immutable list if byId is an immutable object
            })) ||
        []
    );
}

function getCardType(card) {
    // (Andres): We have several ways of detecting the card type, and none of them can cover
    // all scenarios, so we will first check the product number, and if we don't find a known
    // number, we will then check the tipoa property
    const creditProducts = ['500', '501', '521', '522'];
    // remove contact-center code, when in privada have the additional code for "tarjeta solidaria" debit subproduct (011)
    const debitSubproducts = [
        '002',
        '004',
        '005',
        '007',
        '008',
        '009',
        ...__CONTACT_CENTER__ ? ['011'] : [],
    ];

    const ecardSubproducts = ['003'];

    const cardProduct = get(card, 'contrato.producto', '');
    const cardSubproduct = get(card, 'criterios.c1.subtipoproducto', '');

    if (creditProducts.indexOf(cardProduct) > -1) {
        return constants.CARD_CREDIT;
    }

    if (cardProduct === '506') {
        if (debitSubproducts.indexOf(cardSubproduct) > -1) {
            return constants.CARD_DEBIT;
        }

        if (ecardSubproducts.indexOf(cardSubproduct) > -1) {
            return constants.CARD_ECARD;
        }
    }

    if (card.tipoa === 'D') {
        return constants.CARD_DEBIT;
    }

    if (card.tipoa === 'C') {
        return constants.CARD_CREDIT;
    }

    if (card.tipoa == null) {
        return constants.CARD_ECARD;
    }
}

/*
    Returns value of 'indicadorSituacion' field for endpoint 'cuentas/doctos-talon' depending of active filter
*/
function getChequebookApiStatusValue(status) {
    switch (status) {
        case constants.CHEQUEBOOK_FILTER_PAYED:
            return 'P';
        case constants.CHEQUEBOOK_FILTER_IRREGULAR:
            return 'A';
        case constants.CHEQUEBOOK_FILTER_COMPOSED:
            return 'C';
        case constants.CHEQUEBOOK_FILTER_RETURNED:
            return 'D';
        case constants.CHEQUEBOOK_FILTER_PAYED_RETURNED_COMPOSED:
            return '';
        default:
            return ''; // all cheques
    }
}

function generateAccountId(iban) {
    return hash(trim(iban.country + iban.controlDigit + iban.codbban));
}

// This method switches commas and dots in number
function correctDecimalSymbol(numberAsString) {
    return numberAsString.replace(/\./g, '').replace(/,/g, '.');
}

// This method cleans number coming in a very specific format from ISBAN
function stringToNumber(numberAsString) {
    if (!numberAsString) {
        return 0;
    }

    numberAsString = trim(numberAsString);

    let operationSign = numberAsString.substr(-1) === 'H' ? 1 : -1;

    let cleanedNumberString = trim(numberAsString.substring(0, numberAsString.length - 1));
    cleanedNumberString = correctDecimalSymbol(cleanedNumberString);

    return parseFloat(cleanedNumberString) * operationSign;
}

function isPremiumCard({ type, subtype }) {
    /*
     * This is a business rule:
     *    for product 501, subtype 003 = Premium Credit Visa
     *    for product 506, subtype 009 = Premium Debit Mastercard
     *    This applies for all standard numbers.
     *
     * Note: this is not harcoded.
     **/

    if (type === '501' && subtype === '003') {
        return true;
    }

    if (type === '506' && subtype === '009') {
        return true;
    }

    return false;
}

function isDiamondCard({ type, subtype, standard }) {
    /*
     * This is a business rule:
     *    for product 501, subtype 002 = Diamond Credit Visa
     *    for product 506, subtype 008 = Diamond Debit Mastercard
     *    for product 501, subtype 004 = Diamond Infinite
     *    for product 522, subtype 001 , standard 0000002 = Diamond Credit Corporativa
     *    This applies for all standard numbers.
     *
     * Note: this is not harcoded.
     **/

    if (type === '501' && subtype === '002') {
        return true;
    }

    if (type === '506' && subtype === '008') {
        return true;
    }

    if (type === '501' && subtype === '004') {
        return true;
    }

    if (type === '522' && subtype === '001' && standard === '0000002') {
        return true;
    }

    return false;
}

function isRevolvingCard({ type, subtype }) {
    /*
     * This is a business rule:
     *    for product 500, subtype 003 = Revolving Card Visa
     *    This applies for all standard numbers.
     *
     * Note: this is not harcoded.
     **/

    if (type === '500' && subtype === '003') {
        return true;
    }

    return false;
}

function isVirtualCredit(type, subtype) {
    return type === '500' && subtype === '803';
}

function getRevolvingPack({ standard }) {
    const parsedStandard = parseInt(standard, 10);
    let pack;

    if (Number.isInteger(parsedStandard)) {
        if (parsedStandard <= 4) {
            pack = 1;
        } else if (parsedStandard <= 8) {
            pack = 2;
        } else if (parsedStandard <= 12) {
            pack = 3;
        } else if (parsedStandard <= 16) {
            pack = 4;
        }
    }

    return pack;
}

function getStandardNumber(typeNumber, subtypeNumber) {
    //type -> subtype -> standard number
    const standardNumbers = {
        '500': {
            '801': '0000012'
        },
        '501': {
            '002': '0000003',
            '003': '0000003'
        },
        '532': {
            '001': '0000001'
        }
    };

    return standardNumbers[typeNumber][subtypeNumber];
}

function isViaT(typeNumber, subtypeNumber) {
    return typeNumber === '532' && subtypeNumber === '001';
}

function existingJunior(responseCode) {
    return responseCode !== JUNIOR_ACCOUNT_SUCCESS_CODE;
}

function isLegalCard({ type, subtype, standard }) {
    /*
     * This is a business rule:
     *    for product 521, subtype 001, standard 0000001 = Visa Classic - Legal
     *    for product 521, subtype 800, standard 0000001 = Visa Open - Legal
     *    for product 522, subtype 001, standard 0000001 = Visa Oro - Legal
     *    for product 522, subtype 001, standard 0000002 = Diamond Credit Corporativa
     *    for product 506, subtype 002, standard 0000002 = 4B Mastercard - Legal basic (Debit Card)
     *
     * Note: this is not harcoded.
     **/

    if (type === '521' && (subtype === '001' || subtype === '800') && standard === '0000001') {
        return true;
    } else if (type === '522' && subtype === '001' && (standard === '0000001' || standard === '0000002')) {
        return true;
    } else if (type === '506' && subtype === '002' && standard === '0000002') {
        return true;
    }

    return false;
}

function isAtHomeAbroad(typeNumber, subtypeNumber) {
    return typeNumber === '506' && subtypeNumber === '005';
}

function isVirtualDebit(typeNumber, subtypeNumber) {
    return typeNumber === '506' && subtypeNumber === '012';
}

function isNerudaCard(type, subtype) {
    return type === '500' && subtype === '802';
}

function isVanityCard(type, subtype) {
    return type === '500' && subtype === '805';
}

function isElHormigueroCard(type, subtype) {
    return type === '500' && subtype === '804';
}

function isDiamondCreditCard(type, subtype) {
    return type === '501' && subtype === '002';
}

function isDiamondInfiniteCreditCard(type, subtype) {
    return type === '501' && subtype === '004';
}

function isPremiumCreditCard(type, subtype) {
    return type === '501' && subtype === '003';
}

function validRevolvingCardType(type, subtype) {
    return isNerudaCard(type, subtype)
        || isVanityCard(type, subtype)
        || isOpenCreditCard(type, subtype)
        || isElHormigueroCard(type, subtype)
        || isDiamondCreditCard(type, subtype)
        || isDiamondInfiniteCreditCard(type, subtype)
        || isPremiumCreditCard(type, subtype)
        || isVirtualCredit(type, subtype)
        || isViaT(type, subtype);
}

function isR42(typeNumber, subtypeNumber) {
    let isR42 = false;

    if (typeNumber === '506') {
        if (subtypeNumber === '013') {//Germany
            isR42 = true;
        } else if (subtypeNumber === '015') {//Portugal
            isR42 = true;
        } else if (subtypeNumber === '014') {//Netherlands
            isR42 = true;
        } else if (subtypeNumber === '019') {//Netherlands Maestro
            isR42 = true;
        }
    } else if (isNerudaCard(typeNumber, subtypeNumber)) {
        isR42 = true;
    } else if (isVanityCard(typeNumber, subtypeNumber) || isElHormigueroCard(typeNumber, subtypeNumber)) {
        isR42 = true;
    }

    return isR42;
}

function getTypeCardForTaggin(card) {
    const cardType = getCardType(card)
    if(cardType === constants.CARD_CREDIT) return PRODUCT_NAME.CARD_CREDIT
    else if(isOpenDebit(card)) return PRODUCT_NAME.OPEN_DEBIT
    else if(isOpenYoung(card)) return PRODUCT_NAME.OPEN_YOUNG
    else if(isECard(card)) return PRODUCT_NAME.ECARD
    else if(isCharity(card)) return PRODUCT_NAME.CHARITY
    else if(isViaT(card)) return PRODUCT_NAME.VIA_T
    else if(isMinicard(card)) return PRODUCT_NAME.MINI_CARD
    else if(isPremiumCard(card)) return PRODUCT_NAME.PREMIUM_CARD
    else if(isDiamondCard(card)) return PRODUCT_NAME.DIAMON_CARD

    return PRODUCT_NAME.EMPTY_CASE
}

module.exports = {
    existingJunior,
    uniqContractFilter,
    groupByCurrencyAndCalculateTotally,
    filterCards,
    isCardAvailable,
    mergeOrderAndId,
    getCardType,
    getChequebookApiStatusValue,
    applySign,
    isAres,
    isBasicAccount,
    isECard,
    isMortgage,
    isPayroll,
    isPreapproved,
    isMetallicCard,
    isMinicard,
    isOpenDebit,
    isRetail,
    isCharity,
    isOpenYoung,
    isItemAvailable,
    isFutureAccount,
    generateAccountId,
    stringToNumber,
    correctDecimalSymbol,
    isDiamondCard,
    isPremiumCard,
    isOpenCreditCard,
    isRevolvingCard,
    getRevolvingPack,
    isOldLoanType,
    isRestrictedLoanType,
    getStandardNumber,
    isViaT,
    isLegalCard,
    isAtHomeAbroad,
    isElHormigueroCard,
    isNerudaCard,
    isR42,
    isVanityCard,
    isVirtualCredit,
    isVirtualDebit,
    getTypeCardForTaggin,
    isR42MasterCard,
    isR42BetaalPasCard,
    validRevolvingCardType
};
