// When we separate the initial state from the reducer it make easy to
// create test, because we no more need a fixture to fake this state. We
// now can use the real initial state of the reducer, and we never will have
// different data sources. If we change this initial state, test will break.

// @vendors
import { fromJS } from 'immutable';

// @constants
import avatarH1 from 'assets/images/avatars/H1.png';
import avatarH2 from 'assets/images/avatars/H2.png';
import avatarH3 from 'assets/images/avatars/H3.png';
import avatarH4 from 'assets/images/avatars/H4.png';
import avatarM1 from 'assets/images/avatars/M1.png';
import avatarM2 from 'assets/images/avatars/M2.png';
import avatarM3 from 'assets/images/avatars/M3.png';
import avatarM4 from 'assets/images/avatars/M4.png';

export const setInitialState = () => fromJS({
    alias: '',
    browsingLanguage: null,
    settingAliasError: '',
    fullname: '',
    apellidoDos: {
        apellido: '',
        particula: null
    },
    apellidoUno: {
        apellido: '',
        particula: null
    },
    fechaNacimiento: '',
    avatar: '',
    codFormatoDomicilio: '11',
    codigoPostal: '',
    links: [
        {
            href: 'string',
            rel: 'string',
            templated: true
        }
    ],
    currentlyVisibleImage: 'default',
    listAvatars: [
        {
            value: "hombre1",
            url: avatarH1
        },
        {
            value: "hombre2",
            url: avatarH2
        },
        {
            value: "hombre3",
            url: avatarH3
        },
        {
            value: "hombre4",
            url: avatarH4
        },
        {
            value: "mujer1",
            url: avatarM1
        },
        {
            value: "mujer2",
            url: avatarM2
        },
        {
            value: "mujer3",
            url: avatarM3
        },
        {
            value: "mujer4",
            url: avatarM4
        }
    ],
    listPaisesTributacionAdic: [],
    listaDispositivos: {
        dispositivo: []
    },
    nombreCliente: '',
    nombreCompleto: '',
    nombreDeVia: '',
    nombrePlaza: '',
    numeroDeVia: '',
    numeroDomicilio: 1,
    paisDomicilio: '',
    paisNacimiento: '',
    paisResidencia: '',
    paisNacionalidad: '',
    sexo: '',
    plaza: {
        codigovia: 0,
        numeroversionvia: 0,
        plaza: {
            discriminanteplaza: null,
            plaza: null,
            provincia: ''
        }
    },
    plazaDeNacimiento: '',
    profileAvatarEditError: false,
    profileAvatarEditModalOpen: false,
    provincia: {
        discriminanteplaza: 0,
        plaza: 0,
        provincia: ''
    },
    restoDomicilio: '',
    telefonoContacto: {},
    tipoDeVia: '',
    documentoIdentificacion: {
        tipodocumpersonacorp: '',
        codigodocumpersonacorp: ''
    },
    isFetching: false,
    hasBeenFetched: false,
    isSettingAlias: false,
    isCollective: false,
    collectiveCompanyId: '',
    collectiveId: '',
    collectiveName: '',
    collectiveType: {
        code3: '',
        code4: '',
        company: ''
    },
    clientProfile: 1
});
