const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const {
    BROKER_ALERT_CONFIGURATION_TYPE_STOCKS_OPTION,
    BROKER_ALERT_RATE_VALUE_CERO_EURO_PERCENTAGE_VALUE,
    BROKER_ALERT_PERCENTAGE_CONDITIONS_IDS,
    BROKER_ALERT_MAXIMUM_PERCENTAGE_VALUE,
    BROKER_ALERT_MAXIMUM_PERCENTAGE_IBEX35_VALUE,
    BROKER_ALERT_MAXIMUM_EURO_VALUE,
    BROKER_ALERT_MAXIMUM_POINTS_VALUE

} = require('constants/index');
const trim = require('lodash/string/trim');
const SanitizeHelper = require('utilities/sanitizeHelper');

const emptySelectedValuesStocks = () => ({
    productId: '',
    rateType: '',
    rateValue: '',
    productName: '',
    productExchangeId: '',
    productSpcSymbol: '',
    productTradePrice: '',
    errorFlags: {
        productInput: false,
        rateTypeInput: false,
        rateValueInput: false
    },
    isFormValid: false
});

const emptySelectedValuesForIndex = () => ({
    rateLabel: '',
    rateType: '',
    rateValue: '',
    productExchangeId: '',
    productSpcSymbol: '',
    productTradePrice: '',
    errorFlags: {
        rateTypeInput: false,
        rateValueInput: false
    },
    isFormValid: false
});

const emptySelectedValues = () => {
    return {
        configurationType: BROKER_ALERT_CONFIGURATION_TYPE_STOCKS_OPTION,
        configurationLabel: '',
        alertId: '',
        isFormValid: false,
        isIndexFormValid: false,
        stocks: emptySelectedValuesStocks()
    };
};

const initialState = Immutable.fromJS({
    createIsActive: false,
    editIsActive: false,
    selectedValues: emptySelectedValues(),
    defaultAlerts: [],
    customAlerts: [],
    customEditResult: [],
    errorFlags: {
        productInput: false,
        rateTypeInput: false,
        rateValueInput: false
    },
    isFormValid: false,
    isCreatingAlert: false,
    isEditingAlert: false,
    isGettingAlerts: false,
    getAlertsErrorReceived: false,
    getAlertsError: '',
    getAlertsFetchSuccess: false,
    customAlertsAreOutdated: false

});

function validateForm(state) {
    const rateType = state.getIn(['selectedValues', 'stocks', 'rateType']);
    const rateValue = state.getIn(['selectedValues', 'stocks', 'rateValue']);

    const hasMoneyAmountConditions = BROKER_ALERT_PERCENTAGE_CONDITIONS_IDS.indexOf(rateType) === -1;
    const hasPercentConditions = BROKER_ALERT_PERCENTAGE_CONDITIONS_IDS.indexOf(rateType) > -1;

    const isProductEmpty = !state.getIn(['selectedValues', 'stocks', 'productId']);
    const isRateTypeEmpty = rateType === '';
    const isRateValueEmpty = rateValue === '';
    const isRateValueAmountTooHigh = hasMoneyAmountConditions ? (rateValue > BROKER_ALERT_MAXIMUM_EURO_VALUE) : false;
    const isRateValuePercentageTooHigh = hasPercentConditions ? (rateValue > BROKER_ALERT_MAXIMUM_PERCENTAGE_VALUE) : false;
    const isRateValueZeroOrLower = parseFloat(rateValue) <= BROKER_ALERT_RATE_VALUE_CERO_EURO_PERCENTAGE_VALUE;

    const isRateValueError =  rateType && (
        isRateTypeEmpty
        || isRateValueEmpty
        || isRateValueZeroOrLower
        || isRateValueAmountTooHigh
        || isRateValuePercentageTooHigh
    );

    const isFormValid = !isProductEmpty && !isRateValueError;

    return state.mergeDeepIn(['selectedValues', 'stocks'], {
        errorFlags: {
            productInput: isProductEmpty,
            rateTypeInput: isRateTypeEmpty,
            rateValueInput: isRateValueError
        },
        isFormValid
    });
}

function validateIndexForm(state, indexName) {
    const rateType = state.getIn(['selectedValues', indexName, 'rateType']);
    const rateValue = state.getIn(['selectedValues', indexName, 'rateValue']);

    const hasMoneyAmountConditions = BROKER_ALERT_PERCENTAGE_CONDITIONS_IDS.indexOf(rateType) === -1;
    const hasPercentConditions = BROKER_ALERT_PERCENTAGE_CONDITIONS_IDS.indexOf(rateType) > -1;

    const isRateTypeEmpty = rateType === '';
    const isRateValueEmpty = rateValue === '';
    const isRateValueAmountTooHigh = hasMoneyAmountConditions ? (rateValue > BROKER_ALERT_MAXIMUM_POINTS_VALUE) : false;
    const isRateValuePercentageTooHigh = hasPercentConditions ? (rateValue > BROKER_ALERT_MAXIMUM_PERCENTAGE_IBEX35_VALUE) : false;
    const isRateValueZeroOrLower = parseFloat(rateValue) <= BROKER_ALERT_RATE_VALUE_CERO_EURO_PERCENTAGE_VALUE;

    const isRateValueError =  rateType && (
        isRateTypeEmpty
        || isRateValueEmpty
        || isRateValueZeroOrLower
        || isRateValueAmountTooHigh
        || isRateValuePercentageTooHigh
    );

    const isFormValid = rateType && !isRateValueError;

    return state.mergeDeepIn(['selectedValues', indexName], {
        errorFlags: {
            rateTypeInput: isRateTypeEmpty,
            rateValueInput: isRateValueError
        },
        isFormValid
    });
}

function parseAlerts(alerts) {
    let byId = {};
    let byOrder = [];

    alerts.forEach(data => {
        const alert = data.alertAdvanced;

        if ( alert.statusName !== 'Activa' ){
            return;
        }

        alert.alertId = alert.alertId.toString();
        alert.isEditing = false;
        alert.isRemoving = false;

        alert.instrument = SanitizeHelper.sanitizeData(alert.instrument);

        byId[alert.alertId] = alert;
        byOrder.push(alert.alertId.toString());
    });

    return {
        byId,
        byOrder
    };
}

function buildIndexObject(indexName) {
    return ({ [indexName]: emptySelectedValuesForIndex()});
}

const brokerAlertsReducer = (state = initialState, action) => {
    let newState;

    switch (action.type) {

        case actionTypes.BROKER_ALERTS_CONFIG_GET_DEFAULT_ALERTS:
            newState = state.set('defaultAlerts', Immutable.fromJS(action.payload.data));
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_RESET:
            return initialState;

        case actionTypes.BROKER_ALERTS_CONFIG_GET_ALERTS_REQUEST:
            return state.merge({
                isGettingAlerts: true,
                getAlertsError: '',
                getAlertsFetchSuccess: false,
                getAlertsErrorReceived: false
            });

        case actionTypes.BROKER_ALERTS_CONFIG_GET_ALERTS_SUCCESS:
            return state.merge({
                isGettingAlerts: false,
                getAlertsError: '',
                getAlertsFetchSuccess: true,
                getAlertsErrorReceived: false,
                customAlertsAreOutdated: false,
                customAlerts: parseAlerts(action.payload.data || [])
            });

        case actionTypes.BROKER_ALERTS_CONFIG_GET_ALERTS_FAILURE:
            return state.merge({
                isGettingAlerts: false,
                getAlertsError: action.payload.error,
                getAlertsFetchSuccess: false,
                getAlertsErrorReceived: true
            });

        case actionTypes.BROKER_ALERTS_CONFIG_CREATE_ALERT:
            newState = state.set('createIsActive', true)
                        .set('editIsActive', false)
                        .set('selectedValues', Immutable.fromJS(emptySelectedValues()));
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_SELECT_PRODUCT:
        return state.mergeDeepIn(['selectedValues', 'stocks'], {
                productId: action.payload.product.value,
                productName: action.payload.product.widgetSearchProductLabel,
                productExchangeId: action.payload.product.exchangeId,
                productSpcSymbol: trim(action.payload.product.spcSymbol),
                productTradePrice: action.payload.product.last,
                errorFlags: {
                    productInput: false
                }
            });

        case actionTypes.BROKER_ALERTS_CONFIG_CONFIGURATION_TYPE_CHANGE:
            const { indexName, type } = action.payload;
            const emptyIndexObject = buildIndexObject(indexName);
            newState = state.mergeDeepIn(['selectedValues'], emptyIndexObject);
            return newState.mergeIn(['selectedValues'], {
                configurationType: type,
                configurationLabel: type
            });

        case actionTypes.BROKER_ALERTS_CONFIG_SELECT_RATE_TYPE:
            return state.mergeIn(['selectedValues', 'stocks'], {
                rateType: action.payload.rateType,
                rateLabel: action.payload.rateLabel,
                errorFlags: {
                    rateTypeInput: false
                }
            });

        case actionTypes.BROKER_ALERTS_CONFIG_SELECT_RATE_VALUE:
            return state.mergeIn(['selectedValues', 'stocks'], {
                rateValue: action.payload.rate,
                errorFlags: {
                    rateValueInput: false
                }
            });

        case actionTypes.BROKER_ALERTS_CONFIG_SELECT_RATE_TYPE_FOR_INDEX:{
            const { indexName, rateType, rateLabel } = action.payload;
            return state.mergeIn(['selectedValues', indexName], {
                rateType: rateType,
                rateValue: '',
                rateLabel: rateLabel,
                errorFlags: {
                    rateTypeInput: false
                }
            });
        }

        case actionTypes.BROKER_ALERTS_CONFIG_SELECT_RATE_VALUE_FOR_INDEX:{
            const { indexName, rate } = action.payload;
            return state.mergeIn(['selectedValues', indexName], {
                rateValue: rate,
                errorFlags: {
                    rateValueInput: false
                }
            });
        }

        case actionTypes.BROKER_ALERTS_CONFIG_CANCEL_ALERT:
            newState = state.set('selectedValues', Immutable.fromJS(emptySelectedValues()))
                        .set('createIsActive', false)
                        .set('editIsActive', false)
                        .set('customEditResult', Immutable.fromJS([]))
                        .setIn(['errorFlags', 'productInput'], false)
                        .setIn(['errorFlags', 'rateTypeInput'], false)
                        .setIn(['errorFlags', 'rateValueInput'], false)
                        .set('isFormValid', true);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_CREATE_ALERT_REQUEST:
            return state.merge({
                isCreatingAlert: true,
                createAlertError: '',
                createAlertErrorReceived: false
            });

        case actionTypes.BROKER_ALERTS_CONFIG_CREATE_ALERT_SUCCESS:
            return state.merge({
                isCreatingAlert: false,
                isFormValid: false,
                isIndexFormValid: false,
                createIsActive: false,
                customAlertsAreOutdated: true
                // ANDRES TODO agregar nueva alerta a la lista de alertas
            });

        case actionTypes.BROKER_ALERTS_CONFIG_CREATE_ALERT_FAILURE:
            return state.merge({
                isCreatingAlert: false,
                isIndexFormValid: false,
                isFormValid: false
            });

        case actionTypes.BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_REQUEST:
            return state.mergeDeepIn(['customAlerts', 'byId'], {
                [action.payload.alertId]: {
                    isRemoving: true
                }
            });

        case actionTypes.BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_FAILURE:
            return state.mergeDeepIn(['customAlerts', 'byId'], {
                [action.payload.alertId]: {
                    isRemoving: false
                }
            });

        case actionTypes.BROKER_ALERTS_CONFIG_DELETE_CUSTOM_ALERT_SUCCESS:
            const newById = state.getIn(['customAlerts', 'byId']).filterNot(alert => alert.get('alertId') === action.payload.alertId);
            const newByOrder = state.getIn(['customAlerts', 'byOrder']).filterNot(id => id === action.payload.alertId);
            return state
                .setIn(['customAlerts', 'byId'], newById)
                .setIn(['customAlerts', 'byOrder'], newByOrder);

        case actionTypes.BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT:
            const configurationType = action.payload.configurationType;
            const customSearchResult = configurationType === BROKER_ALERT_CONFIGURATION_TYPE_STOCKS_OPTION ? 
                [{
                    label: action.payload.selectedValues.productName,
                    widgetSearchProductLabel: action.payload.selectedValues.productName,
                    value: action.payload.selectedValues.productId
                }] : [];
            newState = state.set('createIsActive', false)
                        .set('editIsActive', action.payload.alertId)
                        .setIn(['selectedValues', 'alertId'], action.payload.alertId)
                        .setIn(['selectedValues', 'configurationType'], configurationType)
                        .setIn(['selectedValues', 'configurationLabel'], configurationType)
                        .setIn(['selectedValues', configurationType], Immutable.fromJS(action.payload.selectedValues))
                        .set('customEditResult', Immutable.fromJS(customSearchResult))
                        .setIn(['errorFlags', 'productInput'], false)
                        .setIn(['errorFlags', 'rateTypeInput'], false)
                        .setIn(['errorFlags', 'rateValueInput'], false)
                        .set('isFormValid', true);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_VALIDATE_INDEX_FORM:
            newState = validateIndexForm(state, action.payload.indexName);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_UPDATE_CUSTOM_ALERT:
            newState = state.set('editIsActive', false)
                            .set('customAlerts', Immutable.fromJS(action.payload.data));
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_VALIDATE_FORM:
            newState = validateForm(state);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_DISABLE_PRODUCT_ERROR:
            newState = state.setIn(['errorFlags', 'productInput'], false);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_DISABLE_QUOTE_TYPE_ERROR:
            newState = state.setIn(['errorFlags', 'rateTypeInput'], false);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_DISABLE_QUOTE_VALUE_ERROR:
            newState = state.setIn(['errorFlags', 'rateValueInput'], false)
                            .setIn(['errorMessagesFlag', 'rateValueEmpty'], false)
                            .setIn(['errorMessagesFlag', 'ratePercentageEmpty'], false)
                            .setIn(['errorMessagesFlag', 'rateValueAboveZero'], false);
            return newState;

        case actionTypes.BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_REQUEST:
            return state.merge({
                isEditingAlert: true
            });

        case actionTypes.BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_FAILURE:
            return state.merge({
                isEditingAlert: false
            });

        case actionTypes.BROKER_ALERTS_CONFIG_EDIT_CUSTOM_ALERT_SUCCESS:
            const configType = action.payload.data.get('configurationType');
            return state.merge({
                editIsActive: '',
                isEditingAlert: false,
                customAlertsAreOutdated: true
            }).mergeDeepIn(['customAlerts', 'byId'], {
                [action.payload.alertId]: {
                    productExchangeId: action.payload.data.getIn([configType, 'productExchangeId']),
                    productTradePrice: action.payload.data.getIn([configType, 'productTradePrice']),
                    conditionId: action.payload.data.getIn([configType, 'rateType']),
                    conditionName: action.payload.data.getIn([configType, 'rateLabel']),
                    conditionValue: action.payload.data.getIn([configType, 'rateValue'])
                }
            });

        case actionTypes.BROKER_ALERTS_CONFIG_RESET_SELECTED_VALUES_STOCKS:
            return state.mergeIn(['selectedValues'], {
                stocks: emptySelectedValuesStocks()
            });

        case actionTypes.BROKER_ALERTS_CONFIG_RESET_SELECTED_VALUES_FOR_INDEX:{
            const { indexName } = action.payload;
            return state.mergeIn(['selectedValues'], {
                [indexName]: emptySelectedValuesForIndex()
            });}

        default:
            return state
    }
}

module.exports = brokerAlertsReducer;
