export const commonFilterAnalyticsId = {
    APPLY_BUTTON: "APPLY_BUTTON",
    CANCEL_BUTTON: "CANCEL_BUTTON",
    CLOSE_MODAL: "CLOSE_MODAL",
    FILTER_BUTTON: "FILTER_BUTTON",
    FILTER_BUTTON_CLICK: "FILTER_BUTTON_CLICK",
    INPUT_FROM: "INPUT_FROM",
    INPUT_TO: "INPUT_TO",
    OPEN_MODAL: "OPEN_MODAl",
    RESET_FILTER: "RESET_FILTER",
    SELECT_DATE: "SELECT_DATE",
};

export const commonAnalyticsId = {
    COMMON_BUTTON: "COMMON_BUTTON",
    RETRY_BUTTON: "RETRY_BUTTON",
};

export const getEventCategory = () => ({
    categoriaEvento: window.utag_last_name,
});

const commonFieldsModalFilter = () => ({
    ...getEventCategory(),
    accionEvento: "modal filtros",
});

export const commonCatalogue = {
    [commonAnalyticsId.COMMON_BUTTON]: (eventAction) => ({
        ...getEventCategory(),
        accionEvento: eventAction,
    }),
    [commonAnalyticsId.RETRY_BUTTON]: () => ({
        ...getEventCategory(),
        accionEvento: "reintentar",
    }),
};

export const commonCatalogueFilters = {
    [commonFilterAnalyticsId.FILTER_BUTTON_CLICK]: () => ({
        ...getEventCategory(),
        accionEvento: "filtros",
    }),
    [commonFilterAnalyticsId.APPLY_BUTTON]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "aplicar",
    }),
    [commonFilterAnalyticsId.CANCEL_BUTTON]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "cancelar",
    }),
    [commonFilterAnalyticsId.CLOSE_MODAL]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "cerrar",
    }),
    [commonFilterAnalyticsId.FILTER_BUTTON]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "filtrar",
    }),
    [commonFilterAnalyticsId.INPUT_FROM]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "desde/textBox",
    }),
    [commonFilterAnalyticsId.INPUT_TO]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "hasta/textBox",
    }),
    [commonFilterAnalyticsId.OPEN_MODAL]: () => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: "abierto",
    }),
    [commonFilterAnalyticsId.SELECT_DATE]: (selectedDate) => ({
        ...commonFieldsModalFilter(),
        etiquetaEvento: `fecha/${selectedDate}`,
    }),
    [commonFilterAnalyticsId.RESET_FILTER]: () => ({
        ...getEventCategory(),
        accionEvento: "eliminar filtro",
    }),
};

module.exports = {
    getEventCategory,
    commonAnalyticsId,
    commonCatalogue,
    commonCatalogueFilters,
    commonFilterAnalyticsId,
};
