const { ONLY_LETTERS_AND_SPACES_REGEX } = require('constants/index');

module.exports = {
    title: 'contractsView-joinholdersTitle',
    order: 4,
    footer_text: '',
    position: 'middle',
    override_validation_step: 3,
    components: [
        {
            id_component: 'custom-error-message',
            id: 'step3-validation-error',
            chekcIds: ['step3-validation-error'],
            type: 'error',
            msg_error:'contractsView-validationErrorStep2Step3',
            classNames:'contract-view__custom-error-message-step3'
        },
        {
            id_component: 'holders',
            id: 'holders',
            target: 'holders',
            personalInfoDisclaimerTexts: {
                errorMessage: 'contractsView-serviceContractErrorMessage',
                label: 'disclaimerPersonalInfo-intervenersPayrollAccount'
            },
            prefix: 'holder_#N#_',
            source: 'checkingPayrollAccount_loadHoldersRadioOptions',
            relationTypeSource: 'checkingPayrollAccount_loadRelationTypeCmb',
            identificationTypeSource: 'checkingPayrollAccount_loadIdentificationTypeCmb',
            value: [],
            holderDescription: 'contractsView-joinholdersDescription',
            regexInputFullName: ONLY_LETTERS_AND_SPACES_REGEX
        }
    ]
};
