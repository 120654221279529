const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP } = require('constants/index');

const taxAndRentsPaymentWithDraftStep1 = require('./taxAndRentsPaymentWithDraft/taxAndRentsPaymentWithDraftStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            taxAndRentsPaymentWithDraftStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        signatureKeyFailure: false,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return taxAndRentsPaymentWithDraftStep1(step, action);
        }
    })
}

function taxAndRentsPaymentWithDraftReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_FAILURE:
            return state.merge({
                error: action.payload.error,
                inProgress: false,
                isFetching: false
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_ACCOUNT:
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_AMOUNT:
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_JUSTIFICATIVE:
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DECLARATION_RESULT:
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_FRACTION_PAYMENT:
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DIFFERENTIAL_FEE:
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DOMICILIATE_SECOND_PAYMENT:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true,
                isFetching: false
            })
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_CLEAR:
            return setInitialState();
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                signatureKeyFailure: false,
                errorReceived: '',
                inProgress: false
            })
        default:
            return state
    }
}

module.exports = taxAndRentsPaymentWithDraftReducer;
