// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const initialState = Immutable.fromJS({
    mergedPDF: '',
    hasBeenFetched: false,
    isFetching: false,
    isPdfDownloaded: false,
    errorContent: ''
});

function parseMergedPDFResponse(state, response){

    return state.merge({
        hasBeenFetched: true,
        isFetching: false,
        mergedPDF: response.xhr.response,
        errorContent: ''
    });
}

function pdfReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.PDF_DOCUMENTS_MERGE_REQUEST:
            return state.merge({
                isFetching: true
            });

        case actionTypes.PDF_DOCUMENTS_MERGE_SUCCESS:
            return parseMergedPDFResponse(state, action.payload.response);

        case actionTypes.PDF_DOCUMENTS_MERGE_ERROR:
            return state.merge({
                errorContent: action.payload.error,
                isFetching: false,
            });
        case actionTypes.PDF_DOCUMENTS_DOWNLOAD_SUCCESS:
            return state.merge({
                isPdfDownloaded: true,
            });
        case actionTypes.PDF_DOCUMENTS_DOWNLOAD_ERROR:
            return state.merge({
                isPdfDownloaded: false,
            });
        case actionTypes.PDF_DOCUMENTS_MERGE_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = pdfReducer;
