// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        filesAttached:[],
    });
}

function validateStep(step) {
    const validStep = step.get('filesAttached').size > 0;
    return step.merge({
        valid: validStep,
        remarkIsDirty: true,
    });
}

function accountDirectDebitsStep3(state = setInitialState(), action) {
    let updatedFileArray;
    switch(action.type) {
        case actionTypes.ACCOUNT_DIRECT_DEBIT_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.ACCOUNT_DIRECT_DEBIT_ADD_FILE:
            const fileObj = Immutable.fromJS({
                name: get(action.payload,'file.file.name',''),
                data: action.payload.file,
                description:'',
                success: false,
                error: ''
            });
            updatedFileArray = state.get('filesAttached').push(fileObj);
            return state.mergeDeep({
                filesAttached: updatedFileArray
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_REMOVE_FILE:
            updatedFileArray = state.get('filesAttached').delete(action.payload.index);
            return state.merge({
                filesAttached: updatedFileArray
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_SET_DESCRIPTION:
            return state.setIn(['filesAttached', action.payload.index, 'description'], action.payload.description);
        case actionTypes.ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_SUCCESS:
            return state.setIn(['filesAttached', action.payload.index, 'success'], true);
        case actionTypes.ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_ERROR:
            return state.setIn(['filesAttached', action.payload.index, 'error'], action.payload.error);
        default:
            return state;
    }
}
module.exports = accountDirectDebitsStep3;
