const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const { formatText } = require('core/i18n').i18n;
const { EURO_SYMBOL } = require('constants/index');

const contract = {
    title: 'contractsView-preApprovedLoanTitle',
    service: '....',
    last_step: 5,
    code_op: 74,
    SignParamsFn: 'pre_approved_loan_SignParams',
    define_steps: 3,
    onCancelURL: '/myprofile/loans-mortgages',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelAccounts',
    errorBackButtonLink: '/myprofile/loans-mortgages',
    breadcrumb: [
        {
            text: 'breadcrumb-loansAndMortgages',
            url: '/myprofile/loans-mortgages'
        },
        {
            text: 'contractsView-preApprovedLoanBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            {
                id: 'i_solicitado',
                label: 'contractsView-loanAmount',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL, // @hardcoded
                formatNumber: true,
                order: 1,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ]
            },
            {
                id: 'meses_seleccionado',
                label: 'contractsView-preApprovedLoanSlider2Label',
                value: null,
                type: 'single',
                format_type: formatText('contractsView-months'),
                order: 2,
                confirmation_step: {
                    columns: 2,
                    order: 2,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ]
            },
            {
                id: 'c_mensual',
                label: 'contractsView-monthlyFee',
                value: 221.5, // @hardcoded
                type: 'single',
                format_type: EURO_SYMBOL, // @hardcoded
                formatNumber: true,
                order: 1,
                confirmation_step: {
                    columns: 3,
                    order: 3,
                    row: 1
                },
                visible: []
            },
            /* PENDING: comment to be removed after finished
            1. The application must consult in an API Web Service the group of the client,
            and depending on the group, the aplication display the respective TIN:
            Grupo 1:
            - TIN: 5,95%
            Grupo 2:
            - TIN: 6,95%
            Grupo 3a:
            - TIN: 7,45%
            Grupo 3b:
            - TIN: 8,10%"
            */
            {
                id: 't_interes',
                label: 'contractsView-tin',
                value: 5.95, // @hardcoded
                type: 'single',
                format_type: '%',
                formatNumber: true,
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            /* PENDING: comment to be removed after finished
            "1. The application must consult in an API Web Service the group of the client,
            and depending on the group, the aplication display the respective TAE:
            Grupo 1:
            - TAE: 6,11%
            Grupo 2:
            - TAE: 8,27%
            Grupo 3a:
            - TAE: 8,40%
            Grupo 3b:
            - TAE: 8,40%"
            */
            {
                id: 'tae',
                label: 'contractsView-tae',
                value: 6.11, // @hardcoded
                type: 'single',
                format_type: '%',
                formatNumber: true,
                order: 4,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ]
            },
            /* PENDING: comment to be removed after finished
            "1. The value will be updated in the card in real time, once the ""Importe del préstamo""
             will be select in the step number two
            2. The application must consult in an API Web Service the group of the client,
             and depending on the group, the aplication display the respective Comisión Apertura:
            Grupo 1:
            - Comisión Apertura: 0%
            Grupo 2:
            - Comisión Apertura: 1,50%
            Grupo 3a:
            - Comisión Apertura: 1,50%
            Grupo 3b:
            - Comisión Apertura: 0%
            3. When the ""Importe del préstamo"" will be select in the step number two, this value will be multiplied
             by the respective ""Comisión de apertura"", the application must use this formula:
              (""Importe de Apertura"" * ""Comisión de Apertura"")/100"
            */
            { // PENDING: Currently working always with 2%? At least the field label is fixed.
                id: 'c_apertura',
                label: 'contractsView-openingCommission',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL, // @hardcoded
                formatNumber: true,
                order: 5,
                confirmation_step: {
                    columns: 4,
                    order: 4,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 5
                    },
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    },
                    {
                        step: 4,
                        order: 5
                    }
                ]
            },
            {
                type: 'separator',
                order: 6,
                visible: [
                    {
                        step: 1,
                        order: 6
                    },
                    {
                        step: 2,
                        order: 6
                    },
                    {
                        step: 3,
                        order: 6
                    },
                    {
                        step: 4,
                        order: 6
                    }
                ]
            },
            /* PENDING: comment to be removed after finished
            "1. The value will be updated in the card in real time, once it is selected in the step number three
            2. The customer should see the following account information:
            - Tipo de Cuenta
            - IBAN
            - Saldo de la cuenta "
            */
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 7,
                visible: [
                    {
                        step: 1,
                        order: 7
                    },
                    {
                        step: 2,
                        order: 7
                    },
                    {
                        step: 3,
                        order: 7
                    },
                    {
                        step: 4,
                        order: 7
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'finalidad_seleccionado',
                label: '',
                value: 0, // @hardcoded
                type: 'single',
                format_type: '',
                order: 0,
                visible:[],
                isErrorVisible: false
            },
            {
                id: 'dia_pago_seleccionado',
                label: '',
                value: 2, // @hardcoded
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: [],
                isFetching: false
            },
            {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            }
        ]
    },
    steps: [
        step1,
        step2,
        step3
    ]
};

module.exports = {
    contract
};
