// @vendor
import { logout } from "ok-login"; // Login interface

// @core
const apiURLBuilder = require("core/apiURLBuilder");
const { getStore } = require("core/storeHelper");
const { cookiesDomain } = require("core/environmentConfig");

// @utilities
const storage = require("utilities/storage");
const { changeBiocatchContext } = require("utilities/biocatch");

// @constants
const {
    KEEPALIVE_TIME,
    LOGOUT_TIME_LIMIT,
    LOGOUT_TIME_ADDITIONAL
} = require("constants/index");
const actionTypes = require("constants/actionTypes");
const { TOKEN_CREDENTIAL } = require("constants/session");
const biocatch = require("constants/biocatch");

// Keep Alive Timer
let keepAliveTimer = null;
// Logout Timer
let logoutTimer = null;
// Id for throttle the manageLogoutTimer function
let throttleId = null;

const { SESSION_EXPIRED } = biocatch;

// Access to store to check the timers time
let store = null;
const validStore = () => {
    if (!store) store = getStore();
    return !!(typeof store !== "undefined" && store);
};

const logoutRedirection = country => {
    if (window.__DEBUG_MODE__) {
        location.href = location.origin + "/login";
    } else {
        const language = storage.cookies.get("language");
        location.href = apiURLBuilder.getPublicSiteUrl(country, language);
    }
};

const logoutUser = handleLogout => {
    logout({
        url: apiURLBuilder.getURL("logout"),
        cookiesDomain: cookiesDomain
    }).then(() => {
        handleLogout();
    });
};

const showLogoutModal = dispatch => {
    // NOTE: The action must be called this way to avaoid cyclic dependencies with APIRequestHelper
    dispatch({ type: actionTypes.SHOW_LOGOUT_POPUP });
};

const showKeepAliveModal = dispatch => {
    // NOTE: The action must be called this way to avaoid cyclic dependencies with APIRequestHelper
    dispatch({ type: actionTypes.SHOW_KEEPALIVE_POPUP });
};

const logoutUserByTimeout = dispatch => {
    // hiding keep alive modal to ensure that both modals should not pop up at the same time
    dispatch({ type: actionTypes.HIDE_KEEPALIVE_POPUP });
    // Show logout modal
    // NOTE: The action must be called this way to avaoid cyclic dependencies with APIRequestHelper
    dispatch({ type: actionTypes.SHOW_LOGOUT_POPUP });
    // Clear timers
    clearTimeout(keepAliveTimer);
    clearTimeout(logoutTimer);
    // Logout user. This, apart from other things, calls removeCookieAndExpirationTime
    changeBiocatchContext(SESSION_EXPIRED);
    logout({
        url: apiURLBuilder.getURL("logout"),
        cookiesDomain: cookiesDomain
    });
};

// Function to avoid innecessary iterations of timers process (acces to tokens, etc)
const throttleManageLogoutTimer = (
    dispatch,
    expirationUpdated,
    tokenCredential
) => {
    // Clear timers
    clearTimeout(keepAliveTimer);
    clearTimeout(logoutTimer);
    // Get correct time for logout
    let logoutTimerTime = LOGOUT_TIME_LIMIT;
    if (expirationUpdated) {
        logoutTimerTime = expirationUpdated;
    } else if (validStore()) {
        const expiration = store.getState().logout.get("expiration");
        logoutTimerTime = expiration;
    }

    // IMPORTANT: Update expiration time of token credential cookie to maintain access in case ok keeping session active
    if (tokenCredential) {
        // LOGOUT_TIME_ADDITIONAL is added to avoid expiring the token cookie before calling logout endpoint
        const expirationInMilisec =
            new Date().getTime() +
            (logoutTimerTime + LOGOUT_TIME_ADDITIONAL) * 1000;
        const expiration = new Date(expirationInMilisec);
        storage.cookies.set(TOKEN_CREDENTIAL, tokenCredential, {
            expires: expiration
        });
    }

    // Create and store new timers for the keepAlive and logout
    keepAliveTimer = setTimeout(
        showKeepAliveModal,
        (logoutTimerTime - KEEPALIVE_TIME) * 1000,
        dispatch
    );
    logoutTimer = setTimeout(
        logoutUserByTimeout,
        logoutTimerTime * 1000,
        dispatch
    );
};

const manageLogoutTimer = (dispatch, expirationUpdated, tokenCredential) => {
    // If setTimeout is already scheduled, no need to do anything
    if (throttleId) return;
    // Schedule a setTimeout after delay seconds
    throttleId = setTimeout(function() {
        throttleManageLogoutTimer(dispatch, expirationUpdated, tokenCredential);
        // Once setTimeout is finished, throttleId = null so the next execution can be scheduled by the setTimeout
        throttleId = null;
    }, 500);
};

module.exports = {
    showLogoutModal,
    logoutRedirection,
    logoutUser,
    manageLogoutTimer
};
