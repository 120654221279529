// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');
const { 
    PROMOTION_ALREADY_HIRED_ERROR,
    PROMOTIONAL_CODE_ERROR
} = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        byId: {},
        byOrder: [],
        isFetchingPromotion: false,
        isPromotionAlreadyHired: false,
        isSettingPromotion: false,
        fetchPromotionSuccess: false,
        fetchingPromotionError: '',
        setPromotionSuccessful: false,
        settingPromotionError: ''
    });
}

function processPromotions(state, data, promotionalCode) {
    const hasPromotionalCodes = (data.length > 0);
    let byId = {};
    let byOrder = [];

    data.forEach( promotion => {
        const promotionCode= promotion.code;

        byId[promotionalCode] = {
            app: promotion.app,
            code: promotion.code,
            start: promotion.start,
            finish: promotion.finish,
            product: promotion.product,
            subTitle: promotion.subTitle,
            title: promotion.title,
            url: promotion.url,
            urlImg: promotion.urlImg
        };
        byOrder.push(promotionCode);
    });

    return state.mergeDeep({
        byId,
        byOrder,
        fetchingPromotionError: (hasPromotionalCodes) ? '' : PROMOTIONAL_CODE_ERROR,
        fetchPromotionSuccess: (hasPromotionalCodes),
        isFetchingPromotion: false
    });
}

function processSetPromotion(state, data, promotionCode) {

    return state.mergeDeep({
        byId: {
            [promotionCode]: {
                claimedId: data.id || '',
                claimed: data.msg || ''
            }
        },
        isPromotionAlreadyHired: false,
        isSettingPromotion: false,
        setPromotionSuccessful: true,
        settingPromotionError: ''
    });
}

function processPromotionAlreadyHired(errorDescription) {
     return !!errorDescription && errorDescription.includes(PROMOTION_ALREADY_HIRED_ERROR);
}

function promotions(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.HIRE_PROMOTION_WILL_CANCEL:
            return state.merge({
                isFetchingPromotion: false,
                isPromotionAlreadyHired: false,
                isSettingPromotion: false,
                fetchPromotionSuccess: false,
                fetchingPromotionError: '',
                setPromotionSuccessful: false,
                settingPromotionError: ''
            });
        case actionTypes.SET_HIRE_PROMOTION_REQUEST:
            return state.merge({
                isSettingPromotion: true
            });
        case actionTypes.SET_HIRE_PROMOTION_FAILURE:
            return state.merge({
                settingPromotionError: action.payload.error,
                setPromotionSuccessful: false,
                isPromotionAlreadyHired: processPromotionAlreadyHired(action.payload.error.description),
                isSettingPromotion: false
            });
        case actionTypes.SET_HIRE_PROMOTION_SUCCESS:
            return processSetPromotion(state, action.payload.data, action.payload.promotionalCode);
        case actionTypes.FETCH_PROMOTION_REQUEST:
            return state.merge({
                isFetchingPromotion: true
            });
        case actionTypes.FETCH_PROMOTION_SUCCESS:
            return processPromotions(state, action.payload.data, action.payload.promotionalCode);
        case actionTypes.FETCH_PROMOTION_FAILURE:
            return state.merge({
                fetchingPromotionError: action.payload.error,
                fetchPromotionSuccess: false,
                isFetchingPromotion: false
            });

        case actionTypes.PROMOTIONS_CANCEL_PROMOTIONAL_CODE:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = promotions;
