// @ vendors
const Immutable = require('immutable');

// @ constants
const {
    AGGREGATOR_ADD_ENTITY_PSD2_INIT,
    AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_REQUEST,
    AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_SUCCESS,
    AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_FAILURE,
    AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_REQUEST,
    AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_SUCCESS,
    AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_FAILURE
} = require('constants/actionTypes');

const initialState = {
    isFetchingUrl: false,
    redirectionUrl: null,
    error: null,
    isFetchingToken: false,
    responseToken: null,
    errorToken: null,
};

function setInitialState() {
    return Immutable.fromJS(initialState);
}

function aggregatorAddEntity(state = setInitialState(), action) {
    switch (action.type) {
        case AGGREGATOR_ADD_ENTITY_PSD2_INIT: {
            return state.merge(initialState)
        }
        case AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_REQUEST: {
            return state.merge({isFetchingUrl: action.payload})
        }
        case AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_SUCCESS: {
            return state.merge({redirectionUrl: action.payload.redirectionUrl})
        }
        case AGGREGATOR_ADD_ENTITY_PSD2_GET_URL_FAILURE: {
            return state.merge({error: action.payload})
        }
        case AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_REQUEST: {
            return state.merge({isFetchingToken: action.payload})
        }
        case AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_SUCCESS: {
            return state.merge({responseToken: action.payload})
        }
        case AGGREGATOR_ADD_ENTITY_PSD2_GET_TOKEN_FAILURE: {
            return state.merge({errorToken: action.payload})
        }
        default:
            return state;
    }
}

module.exports = aggregatorAddEntity;