//@vendors
const Immutable = require('immutable');
//@helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map({
        showError: false,
        motive: '',
        valid: false
    });
}

function validateStep(step) {
    const motive = step.get('motive');

    return step.merge({
        valid: !!motive,
        showError: !motive
    });
}

function accountCancelChequebookStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_SET_MOTIVE:
            return state.merge({
                motive: action.payload.motive,
                showError: false
            });
        default:
            return state;
    }
}

module.exports = accountCancelChequebookStep1;
