// @ vendor
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP
} = require('constants/actionTypes');

const setInitialState = () =>{
    return Immutable.Map().merge({
        valid: false
    });
};

const validateStep = step => {
    return step.merge({
        valid: true
    });
};

const mortgageRequestAppraisalRequestStep1 = (state = setInitialState(), action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP:
            return validateStep(state);
    }

    return state;
};

module.exports = mortgageRequestAppraisalRequestStep1;
