const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const successStep = require('./successStep');
const {
    OPENYOUNG_ACCOUNT_TYPE,
    OPENYOUNG_ACCOUNT_SUBTYPE,
    OPENYOUNG_ACCOUNT_STANDARD
} = require('constants/index');
const { OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS } = require('constants/hiringProductInformation');
// @ helpers
const { formatText } = require('core/i18n').i18n;

const contract = {
    alertMessage: 'contractsView-juniorAccountAlertMessage',
    title: 'contractsView-juniorAccountTitle',
    successStepType: 'dynamic',
    service: '....',
    last_step: 4,
    define_steps: 3,
    onCancelURL: '/myprofile/accounts',
    termsAndCondsKey: OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS,
    code_op: 80,
    showIntervenerControls: false,
    SignParamsFn: 'junior_account_SignParams',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelGlobal',
    errorBackButtonLink: '/myprofile/global',
    hasPrecontractualLink: true,
    accountAlias: OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS,
    type: OPENYOUNG_ACCOUNT_TYPE,
    subtype: OPENYOUNG_ACCOUNT_SUBTYPE,
    standard: OPENYOUNG_ACCOUNT_STANDARD,
    warningContractMessage: 'contractsView-juniorWarningContractMessage',
    operativeName:'operatives-juniorAccount',
    tealiumStepsLabels: [
        'precontractual',
        'holderData',
        'linkedAccount',
        'confirmation'
    ],
    tealiumName: 'contractingAccount',
    customTagging: 'deposittransfer_customTagging',
    confirmationExtraComponents: [
        {
            id_component: 'validation-modal',
            id: 'validationModal',
            title: 'contractsView-juniorAccountModalTitle',
            content: 'juniorAccount_validationModalContent',
            allowContinue: false,
            showCloseButton: true,
            cancelRoute: '/myprofile/accounts'
        }
    ],
    breadcrumb: [
        {
            text: 'breadcrumb-accounts',
            url: '/myprofile/accounts'
        },
        {
            text: 'contractsView-juniorAccountBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        form_target: 'form_count',
        card_details: [
            {
                id: 'form_count',
                label: '',
                value: [
                    'junior_form_card_owners',
                    'junior_form_personal_data',
                    'junior_form_contact_data',
                    'junior_form_tax_data'
                ],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            //Forms Values
            {
                id: 'junior_form_card_owners',
                value: [],
                visible: []
            },
            {
                id: 'junior_form_personal_data',
                value: [],
                visible: []
            },
            {
                id: 'junior_form_contact_data',
                value: [],
                visible: []
            },
            {
                id: 'junior_form_tax_data',
                value: [],
                visible: []
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 1,
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 2,
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ]
            },
            {
                id: 'different_ship_country',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'excludes_id',
                label: '',
                value: [],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'validationModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            ...wizardComponentsHelper.returnObjects(step2)
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-juniorAccountServiceContractLabel1'
                        },
                        {
                            text: 'contractsView-juniorAccountServiceContractLabel2',
                            id: 'btn_ContractsView_precontractual',
                            href: 'contractsView-juniorAccountServiceContractLabel2URL'
                        },
                        {
                            text: 'contractsView-juniorAccountServiceContractLabel3'
                        },
                        {
                            text: 'contractsView-juniorAccountServiceContractLabel4',
                            id: 'btn_ContractsView_product',
                            href: 'contractsView-juniorAccountServiceContractLabel4URL'
                        },
                        {
                            text: 'contractsView-juniorAccountServiceContractLabel6'
                        },
                        {
                            //TODO: This term should be the contractsView-juniorAccountServiceContractLabel5
                            // without the pre string that is in contractsView-juniorAccountServiceContractLabel6
                            text: 'contractsView-juniorAccountServiceContractLabel5.1',
                            id: 'btn_ContractsView_minorDetails',
                            href: 'contractsView-juniorAccountServiceContractLabel5URL'
                        },
                        {
                            text: 'contractsView-juniorAccountServiceContractLabel7'
                        }
                    ]
                }
            ]
        },
        preContractDocuments : {
            preContractPdfFileName: "documentosCuentadeAhorroOpenYoung",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_juniorAccountPdfTitle')
        }
    },
    steps: [
        step1,
        step2,
        step3
    ],
    successStep
};

module.exports = {
    contract
};
