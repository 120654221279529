// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_VISIBLE_STEP,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_WILL_CANCEL,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_RESET_STEP,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_CLEAR,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_REQUEST,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_SUCCESS,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_FAILURE,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_REQUEST,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_SUCCESS,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_FAILURE,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_VISIT_DATE,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_ATTEND_OPTION,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_APPRAISAL_DATE,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_SCHEDULE_OPTION,
    MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_REQUEST,
    MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_SUCCESS,
    MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_FAILURE,
    MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_ACCOUNT,
    MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_TOTAL_COST
} = require('constants/actionTypes');

const { FIRST_STEP, SECOND_STEP } = require('constants/index');
// @ reducers
const mortgageRequestAppraisalRequestStep1 = require('./mortgageRequestAppraisalRequest/mortgageRequestAppraisalRequestStep1');
const mortgageRequestAppraisalRequestStep2 = require('./mortgageRequestAppraisalRequest/mortgageRequestAppraisalRequestStep2');

const setInitialState = () => {
    return Immutable.Map().merge({
        steps: [
            mortgageRequestAppraisalRequestStep1(undefined, { type: null }),
            mortgageRequestAppraisalRequestStep2(undefined, { type: null }),
            {hasSignaturePattern: true}
        ],
        error: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        visitData: {},
        getVisitDataSuccess: false,
        success: false
    });
};

const updateStep1 = (steps, action) => {
    return steps.update(FIRST_STEP, step => {
        return mortgageRequestAppraisalRequestStep1(step, action);
    });
};

const updateStep2 = (steps, action) => {
    return steps.update(SECOND_STEP, step => {
        return mortgageRequestAppraisalRequestStep2(step, action);
    });
};

const mortgageRequestAppraisalRequest = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    switch (action.type) {
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.data.step
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_WILL_CANCEL:
            return state.merge({
                willLeave: true
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP:
        case MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_REQUEST:
        case MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_SUCCESS:
            visibleStep = 0;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? 2 : 1;
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = partialSteps.getIn([SECOND_STEP, 'valid']) ? 3 : 2;
                    break;
                default:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 1;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_FAILURE:
            partialSteps = updateStep2(state.get('steps'), action);
            visibleStep = partialSteps.getIn([SECOND_STEP, 'valid']) ? 3 : 2;
            return state.merge({
                steps: partialSteps,
                visibleStep,
                error: true
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_CLEAR:
            return setInitialState();
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_REQUEST:
            return state.set('inProgress', true);
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_SUCCESS:
            return state.merge({
                success: true,
                inProgress: false
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_REQUEST:
            return state.set('inProgress', true);
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_SUCCESS:
            return state.merge({
                inProgress: false,
                visitData: action.data,
                getVisitDataSuccess: true
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_GET_APPRAISAL_FAILURE:
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_SET_APPRAISAL_FAILURE:
            return state.merge({
                error: true,
                inProgress: false
            });
        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_VISIT_DATE:
        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_ATTEND_OPTION:
        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_APPRAISAL_DATE:
        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_SCHEDULE_OPTION:
        case MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_ACCOUNT:
        case MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_TOTAL_COST:
            return state.merge({
                steps: updateStep2(state.get('steps'), action)
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_RESET_STEP:
            return state.merge({
                steps: updateStep2(state.get('steps'), action)
            });
        default:
            return state;
    }
};

module.exports = mortgageRequestAppraisalRequest;
