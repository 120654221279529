// @ vendors
const Immutable = require('immutable');
const moment = require('moment');
const get = require('lodash/object/get');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_REQUEST,
    MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_SUCCESS,
    MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    listaAmortPrestamo: [],
    error: '',
    isFetching: false
});

const mapAmortization = (list, currency) => {
    let amortizationList = [];

    list.forEach(movement => {
        const item = {
            date: moment(movement.fechaAmortiz),
            installment: {
                currency,
                amount: get(movement, 'importeFranquicia', 0)
            },
            amortizedCapital: {
                currency,
                amount: get(movement, 'capital', 0)
            },
            interests: {
                currency,
                amount: get(movement, 'importeIntereses', 0)
            },
            pendingCapital: {
                currency,
                amount: get(movement, 'capitalPendiente', 0)
            }
        };

        amortizationList.push(item);
    });

    return amortizationList;
};

const operativeGeneralData = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_SUCCESS:
            const listaAmortizacionPrestamo = action.data.listaAmortPrestamo;
            const amortizationList = mapAmortization(
                listaAmortizacionPrestamo.amortPrestamo,
                action.data.codMonedaPrestamo
            );

            return state.mergeDeep({
                listaAmortPrestamo: amortizationList,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_AMORTIZATION_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = operativeGeneralData;
