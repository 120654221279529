// @ vendors
const Immutable = require('immutable');

// @ constants
const {
    MORTGAGE_REQUEST_ESTATES_FETCH_DATA_REQUEST,
    MORTGAGE_REQUEST_ESTATES_FETCH_DATA_FAILURE,
    MORTGAGE_REQUEST_ESTATES_DATA_RESET,
    MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_REQUEST,
    MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_SUCCESS,
    MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_FAILURE,
    MORTGAGE_REQUEST_ESTATES_DATA_FILES_LOADED,
    MORTGAGE_REQUEST_ESTATES_DATA_FETCH_FILE_FAILURE,
    MORTGAGE_REQUEST_ESTATES_DATA_FETCH_FILES_REQUEST,
    MORTGAGE_REQUEST_ESTATES_DATA_DOCUMENT
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    data: {
        VIVIENDA: [],
        TRASTERO: [],
        GARAJE: []
    },
    dataPanel: {
        VIVIENDA: [],
        TRASTERO: [],
        GARAJE: []
    },
    documentData: {
        filename: '',
        contentType: '',
        base64: ''
    },
    error: '',
    isFetching: false,
    isFetchingData: false,
    dataRequested: false,
    dataPanelRequested: false
});

const estates = (state = initialState, action) => {
    switch (action.type) {

        case MORTGAGE_REQUEST_ESTATES_DATA_RESET:
            return initialState.merge();

        case MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_REQUEST:
        case MORTGAGE_REQUEST_ESTATES_FETCH_DATA_REQUEST:
            return state.merge({
                isFetching: true,
                isFetchingData: true
            });

        case MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_SUCCESS:
            return state.mergeDeep({
                dataPanel: action.data,
                isFetching: false,
                dataPanelRequested: true
            });

        case MORTGAGE_REQUEST_ESTATES_DATA_FILES_LOADED:
            return state.merge({
                data: action.data,
                isFetching: false,
                dataRequested: true
            });
        case MORTGAGE_REQUEST_ESTATES_DATA_DOCUMENT:
            return state.merge({
                isFetching: false,
                dataRequested: true,
                documentData: action.documentData
            });

        case MORTGAGE_REQUEST_ESTATES_DATA_FETCH_FILE_FAILURE:
        case MORTGAGE_REQUEST_ESTATES_DATA_FETCH_LIST_FAILURE:
        case MORTGAGE_REQUEST_ESTATES_FETCH_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case MORTGAGE_REQUEST_ESTATES_DATA_FETCH_FILES_REQUEST:
            return state.merge();

        default:
            return state;
    }
};

module.exports = estates;
