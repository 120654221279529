/**
 * format the phone number depending if it is mobile or note
 * @param {string} number
 * @param {bool} isMobile
 * @returns {string}
 */
function formatPhone(number, isMobile) {
    if (number) {
        if (isMobile) {
            const formattedNumber = number.replace(/(.{3})/g,"$1 ");
            if (number.length % 3 === 0) {
                return formattedNumber.substring(0, formattedNumber.length-1);
            }
            return formattedNumber;
        } else {
            return number.replace(/(.{2})(.{3})(.{2})(.{2})/,"$1 $2 $3 $4");
        }
    }
}
/**
 * format the phone international number depending if it is mobile or note
 * @param {string} countryCode
 * @param {string} number
 * @param {bool} isMobile
 * @returns {string}
 */

function formatInternationalPhone(countryCode, number, isMobile) {
    if (number) {
        let startingDigits = number.slice(0, 2);
        let numberFormat = number.includes('+')? formatPhone(number, isMobile):`(+${countryCode}) ${formatPhone(number, isMobile)}`;
        if(startingDigits === "90" || startingDigits === "80" || startingDigits === "70")  {
            numberFormat = `${formatPhone(number, isMobile)}`;
        }
        const numberNoSpaces = number.replace(/ /g,'').replace(/[{()}]/g,'');
        switch (countryCode) {
            case "49":
                const numberPlusCountryDe = numberNoSpaces.includes('+')? numberNoSpaces :`+${countryCode}0${numberNoSpaces}`;
                numberFormat = numberPlusCountryDe.replace(/(.{3})(.{1})(.{2})(.{3})(.{3})(.{3})/,"$1 ($2) $3 $4 $5 $6");
                break;
            case "351":
                const numberPlusCountryPt = numberNoSpaces.includes('+')? numberNoSpaces :`+${countryCode}${numberNoSpaces}`;
                numberFormat = numberPlusCountryPt.replace(/(.{4})(.{3})(.{3})(.{3})/,"($1) $2 $3 $4");
                break;
            case "31":
                const numberPlusCountryNl = numberNoSpaces.includes('+')? numberNoSpaces :`+${countryCode}0${numberNoSpaces}`;
                numberFormat = numberPlusCountryNl.replace(/(.{3})(.{1})(.{2})(.{4})(.{3})/,"$1 ($2) $3 $4 $5");
            break;
        }

        return numberFormat;
    }

    return '';
}

 /**
  * format the phone number when multiple phone number present divided by / depending if it is mobile or note
  * @param {string} countryCode
  * @param {string} number
  * @param {bool} isMobile
  * @returns {string}
  */
function formatMultiplePhoneNumber(countryCode, number, isMobile) {
    let phoneNumberFormatted = number && number.split('/');
    let phoneNumbers = phoneNumberFormatted.map(function(el) {
        return formatInternationalPhone(countryCode, el, isMobile);
    })
    return phoneNumbers.join(' / ');
}

module.exports = { formatPhone, formatInternationalPhone, formatMultiplePhoneNumber };