// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, DATE_FILTER_NONE } = require('constants/index');

const initialState = Immutable.fromJS({
    childDetails: {},
    error: '',
    isFetching: false,
    success: false,
    byId: {},
    byOrder: [],
    nextPage: '',
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        },
        status: {
            isActive: false,
            status: null
        },
        childList: {
            isActive: false,
            childsPan: []
        },
        amount: {
            isActive: false,
            from: {
                amount: null,
                currency: EURO_TEXT
            },
            to: {
                amount: null,
                currency: EURO_TEXT
            }
        },
    }
});

function processRequestDetailList(state, payload) {
    const requestDetailList = get(payload.response, 'requestDetailList', []);
    const nextPage = get(payload.response, '_links.nextPage.href');
    const childDetails = payload.childDetails;
    const isFilterSelected = payload.isFilterSelected;
    let childCardList = [];
    let byId = isFilterSelected ? {} : state.get('byId').toJS();
    let byOrder = isFilterSelected ? [] : state.get('byOrder').toJS();
    childCardList = Object.keys(childDetails).map(pan => {
        return {pan, childName: childDetails[pan] }
    })
    requestDetailList.forEach(requestData => {
        const requestId = get(requestData, 'requestId');
        requestData['date'] = requestData['requestDate'];
        requestData['childName'] = childDetails[requestData['pan']];
        if (!!requestId) {
            byId[requestId] = requestData;
            byOrder.push(requestId + '');
        }
    });
    const nextState = {
        error: '',
        isFetching: false,
        nextPage: nextPage,
        success: true,
    };

    return state.withMutations(map => {
        map.merge(nextState)
        map.set('childDetails', Immutable.fromJS(childCardList),);
        map.set('byId', Immutable.fromJS(byId))
        map.set('byOrder', Immutable.fromJS(byOrder));
    });
}

function openYoungHistory(state = initialState, action) {
    switch (action.type) {
        case actionTypes.OPENYOUNG_HISTORY_IS_FETCHING:
            return state.merge({
                error: '',
                isFetching: true,
                success: false
            });
        case actionTypes.OPENYOUNG_HISTORY_SUCCESS:
            return processRequestDetailList(state, action.payload);
        case actionTypes.OPENYOUNG_HISTORY_FAILURE:
            return state.merge({
                error: action.payload,
                isFetching: false,
                success: false
            });
        case actionTypes.OPENYOUNG_HISTORY_RESET:
            return initialState;
        case actionTypes.OPENYOUNG_HISTORY_FILTER:
            const filters = action.payload.filters;
            const dateFilterSelected = get(filters, 'date.selected') !== DATE_FILTER_NONE;
            const statusSelected = get(filters, 'status.status') !== null;
            const amountFilterSelected = get(filters, 'amount.to.amount') !== null || get(filters, 'amount.from.amount');
            const partial = state.mergeDeep({
                filters
            });
            const childSelected = get(filters, 'childList.childsPan').filter(child => child['isSelected']).length > 0;
            return partial.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected
                    },
                    status: {
                        isActive: statusSelected
                    },
                    amount: {
                        isActive: amountFilterSelected
                    },
                    childList: {
                        isActive: childSelected
                    }
                }
            });
        case actionTypes.OPENYOUNG_HISTORY_RESET_FILTER:
            const { filterKey } = action.payload;
            return state.setIn(['filters', filterKey], initialState.getIn(['filters', filterKey]));
        case actionTypes.OPENYOUNG_HISTORY_CLEAR_FILTER:
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
    default:
            return state;
    }
}

module.exports = openYoungHistory;