const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP } = require('constants/index');

const cardSecurePaymentStep1 = require('./cardSecurePayment/cardSecurePaymentStep1');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            cardSecurePaymentStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        fetchSuccessful: false,
        willCancel: false,
        error: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardSecurePaymentStep1(step, action);
        }
    })
}

function cardSecurePaymentReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_SECURE_PAYMENT_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_SECURE_PAYMENT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_SECURE_PAYMENT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.CARD_SECURE_PAYMENT_RESET_VIEW:
            return setInitialState();
        case actionTypes.CARD_SECURE_PAYMENT_SECURE_COMMERCE_CHANGES:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.CARD_SECURE_PAYMENT_NO_SECURE_COMMERCE_CHANGES:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.CARD_SECURE_PAYMENT_SETUP_SUCCESS:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                isFetching: false,
                fetchSuccessful: true
            });
        case actionTypes.CARD_SECURE_PAYMENT_SETUP_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
                fetchSuccessful: false
            })
        case actionTypes.FETCH_CARD_SECURE_PAYMENT_CONFIGURATION:
            return state.merge({
                isFetching: true,
                fetchSuccessful: false
            });
        case actionTypes.CARD_SECURE_PAYMENT_SAVE_SETUP_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.CARD_SECURE_PAYMENT_SAVE_SETUP_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.CARD_SECURE_PAYMENT_SAVE_SETUP_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            })
        default:
            return state
    }
}

module.exports = cardSecurePaymentReducer;
