const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, DATE_FILTER_NONE } = require('constants/index');
const Immutable = require('immutable');
const get = require('lodash/object/get');
const { hash } = require('utilities/hash');

// @Constants
const {
    BROKER_TYPE_STOCKS_NEW_DECIMALS
} = require('constants/index');

const initialState = Immutable.fromJS({});
const initialStateFilters = Immutable.fromJS({
    date: {
        isActive: false,
        selected: DATE_FILTER_NONE
    }
});
const initialStateForValuesRequest = (actualFilters = initialStateFilters) => Immutable.fromJS({
    byId: {},
    byOrder: [],
    isFetching: true,
    success: false,
    filters: actualFilters,
    error: '',
    nextLink: null
});

function prepareMovements (movements = []) {
    let byId = {};
    let byOrder = [];

    movements.forEach(movement => {
        if(movement.descMovimiento){
            const id = hash([movement.descMovimiento, movement.fechaEjecucion, movement.titulos]);

            byId[id] = {
                id,
                description: movement.descMovimiento || '',
                titles: movement.titulos || '',
                date: movement.fechaEjecucion,
                price: {
                    amount: get(movement, 'cotizacion.numeroimportevalores'),
                    currency: get(movement, 'cotizacion.divisa') || EURO_TEXT,
                    decimalsLength: BROKER_TYPE_STOCKS_NEW_DECIMALS
                }
            }
            byOrder.push(id);
        }
    });

    return {
        byId,
        byOrder
    }
}

function brokerValueMovements(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_VALUE_MOVEMENTS_REQUEST:
            const immFilters = state.getIn([action.payload.valueId, 'filters']);
            return state.merge({
                [action.payload.valueId]: initialStateForValuesRequest(immFilters)
            });
        case actionTypes.BROKER_VALUE_MOVEMENTS_SUCCESS:
            const preparedMovements = prepareMovements(
                get(action, 'payload.data.listaMovContratos.movimientoContrato')
            );
            const getNextMovements = action.payload.data._links.nextPage ? action.payload.data._links.nextPage.href : null;
            return state.mergeDeep({
                [action.payload.valueId]: {
                    isFetching: false,
                    success: true,
                    byId: preparedMovements.byId,
                    byOrder: preparedMovements.byOrder,
                    nextLink: getNextMovements
                }
            });
        case actionTypes.BROKER_VALUE_MOVEMENTS_FAILURE:
            return state.mergeDeep({
                [action.payload.valueId]: {
                    isFetching: false,
                    error: action.payload.error
                }
            });
        /// action para actualizar para agregar los nuevos
        case actionTypes.BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_REQUEST:
            return state.mergeDeep({
                [action.payload.valueId]: {
                    success: false,
                }
            });
        case actionTypes.BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_SUCCESS:
            const nextMovements = prepareMovements(
                get(action, 'payload.data.listaMovContratos.movimientoContrato')
            );
            const prevMovementsById = state.getIn([action.payload.valueId, 'byId']).toJS();
            const prevMovementsByOrder = state.getIn([action.payload.valueId, 'byOrder']).toJS();
            const nextLink = action.payload.data._links.nextPage ? action.payload.data._links.nextPage.href : null;
            const newState = state.mergeDeep({
                [action.payload.valueId]: {
                    isFetching: false,
                    success: true,
                    byId: {
                        ...prevMovementsById,
                        ...nextMovements.byId
                    },
                    byOrder: [
                        ...prevMovementsByOrder,
                        ...nextMovements.byOrder
                    ],
                    nextLink: nextLink
                }
            });
            return newState;
        case actionTypes.BROKER_VALUE_MOVEMENTS_GET_MORE_MOVEMENTS_FAILURE:
            return state.mergeDeep({
                [action.payload.valueId]: {
                    isFetching: false,
                    error: action.payload.error
                }
            });
        case actionTypes.BROKER_VALUE_MOVEMENTS_SET_FILTER:
            return state.mergeDeep({
                [action.payload.valueId]: {
                    filters: action.payload.filter
                }
            });
        case actionTypes.BROKER_VALUE_MOVEMENTS_CLEAR_FILTER:
            return state.mergeDeep({
                [action.payload.valueId]: {
                    filters: initialStateFilters
                }
            });
        default:
            return state;
    }
}

module.exports = brokerValueMovements;
