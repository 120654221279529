// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false
});

function morningStarInvestmentData(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_MORNING_STAR_INVESTMENT_DATA_IN_PROGRESS:
            return state.mergeDeep({
                isFetching: true
            });
        case actionTypes.GET_MORNING_STAR_INVESTMENT_DATA_SUCCESS:
            return state.mergeDeep({
                isFetching: false,
                rating: action.payload.Rating,
                name: action.payload.Name,
                categoryName: action.payload.CustomCategoryIdName,
                returnM0: action.payload.ReturnM0,
                returnM12: action.payload.ReturnM12,
                returnM36: action.payload.ReturnM36,
                returnM60: action.payload.ReturnM60
            });
        case actionTypes.GET_MORNING_STAR_INVESTMENT_DATA_FAILURE:
            return state.mergeDeep({
                isFetching: false,
                error: action.payload.error,
                rating: '',
                name: '',
                categoryName: '',
                returnM0: '',
                returnM12: '',
                returnM36: '',
                returnM60: ''
            });
        default:
            return state
    }
}

module.exports = morningStarInvestmentData;
