const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_DONE
} = require('../constants')

const {
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_MORE_DOCUMENTS_FAILURE,
    FETCH_MORE_DOCUMENTS_SUCCESS,
    FETCH_ADDITIONALDOCUMENTATION_FAILURE,
    FETCH_ADDITIONALDOCUMENTATION_SUCCESS,
    FETCH_ADDITIONALDOCUMENTATION_NO_DOCUMENTS_SUCCESS,
    FETCH_LEGAL_DOCUMENTATION_SUCCESS,
    FETCH_LEGAL_DOCUMENTATION_FAILURE,
    FETCH_LEGAL_DOCUMENTATION_NO_DOCUMENTS_SUCCESS
} = require('constants/actionTypes')

const ACTION_CODE_DOCUMENTATION = 5146
const ACTION_CODE_DOCUMENTATION_LEGAL_CORRESPONDENCE = 5147
const ACTION_CODE_DOCUMENTATION_ANNUAL_INFORMATION = 5148
const ACTION_CODE_DOCUMENTATION_CONTRACTS = 5149
const ACTION_CODE_DOCUMENTATION_QUERY_CONTRACTS_ACCOUNTS = 5150
const ACTION_CODE_DOCUMENTATION_QUERY_CONTRACTS_CARDS = 5151
const ACTION_CODE_DOCUMENTATION_QUERY_CONTRACTS_WALLET = 5152

module.exports = {
    [ACTION_CODE_DOCUMENTATION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [
                FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_FAILURE,
                FETCH_MORE_DOCUMENTS_SUCCESS, FETCH_MORE_DOCUMENTS_FAILURE
            ],
            check: (action) => {
                const { type } = action
                let operationState = OPERATION_LOG_DONE

                if (type === FETCH_DOCUMENTS_FAILURE || type === FETCH_MORE_DOCUMENTS_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_DOCUMENTATION,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_DOCUMENTATION_LEGAL_CORRESPONDENCE]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [
                FETCH_LEGAL_DOCUMENTATION_SUCCESS,
                FETCH_LEGAL_DOCUMENTATION_FAILURE,
                FETCH_LEGAL_DOCUMENTATION_NO_DOCUMENTS_SUCCESS
            ],
            check: (action) => {
                const { type } = action;
                let operationState = (type === FETCH_LEGAL_DOCUMENTATION_SUCCESS ||  type === FETCH_LEGAL_DOCUMENTATION_NO_DOCUMENTS_SUCCESS)
                    ? OPERATION_LOG_DONE
                    : OPERATION_LOG_FAIL;

                return {
                    actionCode: ACTION_CODE_DOCUMENTATION_LEGAL_CORRESPONDENCE,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_DOCUMENTATION_ANNUAL_INFORMATION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [
                FETCH_ADDITIONALDOCUMENTATION_SUCCESS,
                FETCH_ADDITIONALDOCUMENTATION_FAILURE,
                FETCH_ADDITIONALDOCUMENTATION_NO_DOCUMENTS_SUCCESS
            ],
            check: (action) => {
                const {type} = action;
                let operationState =
                    ( type === FETCH_ADDITIONALDOCUMENTATION_SUCCESS || type === FETCH_ADDITIONALDOCUMENTATION_NO_DOCUMENTS_SUCCESS)
                    ? OPERATION_LOG_DONE
                    : OPERATION_LOG_FAIL;

                return {
                    actionCode: ACTION_CODE_DOCUMENTATION_ANNUAL_INFORMATION,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_DOCUMENTATION_CONTRACTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/documentation/contracts'
        }
    },
    [ACTION_CODE_DOCUMENTATION_QUERY_CONTRACTS_ACCOUNTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/documentation/contracts/account/[0-9]+'
        }
    },
    [ACTION_CODE_DOCUMENTATION_QUERY_CONTRACTS_CARDS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/documentation/contracts/cards/[0-9]+'
        }
    },
    [ACTION_CODE_DOCUMENTATION_QUERY_CONTRACTS_WALLET]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/documentation/contracts/wallet/[0-9]+'
        }
    }
}
