// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    inProgress: false,
    success: false,
    error: ''
});

function cardReplaceReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_CARD_REPLACE_INITIAL:
            return initialState;
        case actionTypes.SET_CARD_REPLACE_REQUEST:
            return state.merge({
                inProgress: true
            });
        case actionTypes.SET_CARD_REPLACE_SUCCESS:
            return state.merge({
                success: true
            });
        case actionTypes.SET_CARD_REPLACE_FAILURE:
            return state.merge({
                error: action.payload.error,
                success: false
            });
        default:
            return state
    }
}

module.exports = cardReplaceReducer;
