module.exports = {
    title: 'contractsView-payrollAccountStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info',
            cms_key: 'payrollaccount'
        },
    ...(
        __CONTACT_CENTER__ ?
        [{
            id_component: 'legal-information'
        }] : []
    )
    ]
};
