// @ vendor
const Immutable = require('immutable');
const moment = require('moment');

// @ constants
const {
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_VISIT_DATE,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_ATTEND_OPTION,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_APPRAISAL_DATE,
    MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_SCHEDULE_OPTION,
    MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_REQUEST,
    MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_SUCCESS,
    MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_FAILURE,
    MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_ACCOUNT,
    MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_TOTAL_COST,
    MORTGAGE_REQUEST_APPRAISAL_REQUEST_RESET_STEP
} = require('constants/actionTypes');

const {
    ATTEND,
    SELECT_DATE
} = require('constants/mortgageRequestAppraisalRequest').APPRAISER_VISIT_OPTIONS;

const setInitialState = () => {
    return Immutable.Map().merge({
        valid: false,
        isFetching: false,
        validUI: false,
        stepHasBeenVisited: false,
        appraiserVisitData: {
            selectVisitDate: '',
            selectAttendVisit: '',
            minDate: moment().startOf('day'),
            selectedVisitDate: moment().startOf('day'),
            scheduleSelectedOption: '',
            hasSelectAttendVisitError: false,
            hasSelectVisitDateError: false,
            hasScheduleError: false,
            selectAttendVisitIsDirty: false,
            visitDateIsDirty: false,
            scheduleIsDirty: false
        },
        totalCost: 0,
        accountIBAN: null,
        accountBalance: 0,
        accountId: null,
        hasAccountError: false,
        accountErrorCode: ''
    });
};

const validateStep = step => {
    const selectAttendVisit = step.getIn(['appraiserVisitData', 'selectAttendVisit']);
    const selectVisitDate = step.getIn(['appraiserVisitData', 'selectVisitDate']);
    const scheduleSelectedOption = step.getIn(['appraiserVisitData', 'scheduleSelectedOption']);
    const accountId = step.get('accountId');
    const accountBalance = step.get('accountBalance');
    const totalCost = step.get('totalCost');
    const stepHasBeenVisited = step.get('stepHasBeenVisited');
    const hasSelectAttendVisitError = stepHasBeenVisited && !selectAttendVisit;
    const hasSelectVisitDateError = stepHasBeenVisited && selectAttendVisit === ATTEND && !selectVisitDate;
    const hasScheduleError = stepHasBeenVisited && selectAttendVisit === ATTEND && selectVisitDate === SELECT_DATE && !scheduleSelectedOption;
    let accountErrorCode = step.get('accountErrorCode');

    if (!accountId) {
        accountErrorCode = 0;
    } else if (accountBalance < totalCost) {
        accountErrorCode = 1;
    } else {
        accountErrorCode = '';
    }

    const hasAccountError = stepHasBeenVisited && accountErrorCode !== '';
    const validUI = stepHasBeenVisited && !(hasSelectAttendVisitError || hasSelectVisitDateError ||  hasScheduleError || hasAccountError);
    const valid= stepHasBeenVisited && !(hasSelectAttendVisitError || hasSelectVisitDateError ||  hasScheduleError || hasAccountError);
    const isFetching= false

    return step.mergeDeep({
        validUI,
        valid,
        isFetching,
        hasAccountError,
        accountErrorCode,
        stepHasBeenVisited: true,
        appraiserVisitData: {
            hasSelectAttendVisitError,
            hasSelectVisitDateError,
            hasScheduleError
        }
    });
};

const mortgageRequestAppraisalRequestStep2 = (state = setInitialState(), action) => {
    let newState = state;

    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_TOTAL_COST:
            newState = state.merge({
                totalCost: action.data
            });

            return validateStep(newState);

        case MORTGAGE_REQUEST_FETCH_APPRAISER_REQUEST_SET_ACCOUNT:
            const { accountId, accountBalance, accountIBAN } = action.data;
            newState = state.merge({
                accountId,
                accountBalance,
                accountIBAN
            });

            return validateStep(newState);

        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_VALIDATE_STEP:
            return validateStep(state);
        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_ATTEND_OPTION:
            newState = state.mergeDeep({
                appraiserVisitData: {
                    selectAttendVisit: action.data,
                    selectAttendVisitIsDirty: true
                }
            });

            return validateStep(newState);

        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_VISIT_DATE:
            newState = state.mergeDeep({
                appraiserVisitData: {
                    selectVisitDate: action.data,
                    visitDateIsDirty: true
                }
            });

            return validateStep(newState);

        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_APPRAISAL_DATE:
            return state.mergeDeep({
                appraiserVisitData: {
                    selectedVisitDate: action.data
                }
            });
        case MORTGAGE_REQUEST_APPRAISER_VISIT_UPDATE_SCHEDULE_OPTION:
            newState = state.mergeDeep({
                appraiserVisitData: {
                    scheduleSelectedOption: action.data,
                    scheduleIsDirty: true
                }
            });

            return validateStep(newState);
        case MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_REQUEST:
            return state.set('isFetching', true);
        case MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_SUCCESS:
            return state.merge({
                valid: true,
                isFetching: false
            });
        case MORTGAGE_REQUEST_APPRAISER_VISIT_POST_APPRAISAL_FAILURE:
            return state.merge({
                valid: false,
                isFetching: false
            });
        case MORTGAGE_REQUEST_APPRAISAL_REQUEST_RESET_STEP:
            switch (action.data.step) {
                case 2:
                    return state.merge({
                        valid: false,
                        isFetching: false,
                        validUI: false
                    });
                case 3:
                    return state.merge({
                        valid: false,
                        isFetching: false
                    });
            }
    }

    return state;
};

module.exports = mortgageRequestAppraisalRequestStep2;
