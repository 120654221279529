// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        disclaimerIsDirty: false,
        hasSignaturePattern: true,
        isDisclaimerChecked: false,
        loading: false,
        valid: false,
        showWarning: false,
        allDocsRead: false
    });
}

function validateStep(state) {
    return state.merge({
        valid: __CONTACT_CENTER__ ? true : state.get('isDisclaimerChecked'),
        disclaimerIsDirty: true
    });
}

const changeManifestCheckbox = (state) => {
    if (state.get('allDocsRead')) {
        return state.set('isDisclaimerChecked', !state.get('isDisclaimerChecked'))
            .set('valid', true)
            .set('showWarning', false);
    }
    else {
        return state.set('showWarning', true);
    }
}

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
};

function brokerRightsSellStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_RIGHTS_SELL_DISCLAIMER_CLAUSE_CHANGED:
            return changeManifestCheckbox(state);
        case actionTypes.BROKER_RIGHTS_SELL_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_RIGHTS_SELL_CONFIRM_REQUEST:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_RIGHTS_SELL_CONFIRM_SUCCESS:
        case actionTypes.BROKER_RIGHTS_SELL_CONFIRM_FAILURE:
            return state.merge({
                loading: false
            });
        case actionTypes.BROKER_RIGHTS_SELL_DOCUMENTS_READ:
            return setDocumentsRead(state);
        default:
            return state;
    }
}

module.exports = brokerRightsSellStep2;
