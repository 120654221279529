module.exports = {
    id_component: 'tab-form',
    label: 'Datos de contacto',
    id: 'tab-form-contact',
    exclude: [],
    grid: [
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-email',
                label: 'Correo electrónico*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_contact_data'
            }
        ],
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-email-two',
                label: 'Correo electrónico*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_contact_data'
            }
        ],
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-contact-number',
                label: 'Tel contacto 1*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_contact_data'
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-contact-number-two',
                label: 'Tel contacto 2*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_contact_data'
            },
            {
                id_component: 'combo',
                id: 'cmb_horario_contacto',
                label: 'Horario de contacto*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbContactSchedule',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_contact_data',
                options: [],
                order: 3,
                msg_error: ''
            }
        ]
    ]
};
