// @ vendors
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

function withFeatureToggling(WrappedComponent) {
    class BaseWithFeatureToggling extends PureComponent {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return connect(
        state => ({
            flagBasedFeatures: state.flagBasedFeatures
        })
    )(BaseWithFeatureToggling)
}

export default withFeatureToggling;
