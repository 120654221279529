//@constants
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');

const getOriginCountry = (profileOriginCountry) => {
  const originArray = location.origin.split('.')
  const countryOriginDomain = originArray[originArray.length - 1]
  const country = profileOriginCountry || countryOriginDomain || DEFAULT_ORIGIN_COUNTRY 
  return country
}

module.exports = {
  getOriginCountry
};
