// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    insights: [],
    isAllInsightsClosed: false,
    initSuccess: false,
    initErrorDesc: '',
    errorContent: '',
    hasBeenFetched: false,
    isFetchingContent: false,
});

function parseContentResponse(state, response){
    const allInsights = response.insights.map(insight => {
        insight.closeStatus = false;
        return insight;
    })
    const insights = allInsights.filter((insight, index) => {
        return index < 4
    })
    return state.merge({
        hasBeenFetched: true,
        isFetchingContent: false,
        insights: insights
    });
}

function changeInsightCloseStatus(state, id){
    const insights = state.get('insights');
    let isAllInsightsClosed = true;
    const insightsArr = insights.map(insight => {
        if(insight.get('id') === id) {
            const closeStatus = insight.get('closeStatus');
            const newInsight = insight.set('closeStatus', !closeStatus);
            return newInsight;
        }
        if (!insight.get('closeStatus')) {
            isAllInsightsClosed = false
        }
        return insight;
    });

    return state.mergeDeep({
        insights: insightsArr,
        isAllInsightsClosed: isAllInsightsClosed
    });
}

function personeticsTeaser(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.GET_PERSONETICS_INSIGHTS_REQUEST:
            return state.merge({
                isFetchingContent: true,
            });

        case actionTypes.GET_PERSONETICS_INSIGHTS_SUCCESS:
            return parseContentResponse(state, action.payload.response);

        case actionTypes.GET_PERSONETICS_INSIGHTS_FAILURE:
            return state.merge({
                errorContent: action.payload.error,
                isFetchingContent: false,
            });

        case actionTypes.SET_PERSONETICS_INSIGHT_CLOSE_STATUS:
            return changeInsightCloseStatus(state, action.payload.id);

        case actionTypes.INIT_PERSONETICS_FAILURE:
            return state.merge({
                initSuccess: false,
                initErrorDesc: action.payload.error,
            });

        case actionTypes.INIT_PERSONETICS_SUCCESS:
            return state.merge({
                initSuccess: true,
            });

        default:
            return state;
    }
}

module.exports = personeticsTeaser;
