// @ constants
const { TOKEN_CREDENTIAL } = require('constants/session');
const { LOCAL_ENVIRONMENT } = require('constants/index');
// @helpers
const isArray = require('lodash/lang/isArray');
const isFunction = require('lodash/lang/isFunction');
const { getGapUrl } = require('utilities/contactCenter/gapUrlHelper');

// @contactCener
// @constans
import { DR_SESSION } from 'constants/contactCenter/session';

let _agentId, _agentToken, _clientId, _clientPersonCode, _clientPersonType, _interactionId, _sessionId, _storagePrefix, _inOffice, _redirectURL, _navigateURL;
let _securityLevel = 'ID';
let _registerTrace = ()=>{window.console.log('Uso de REGISTER TRACE seteado en por DEFECTO');};
let  _changeSecurityLevel;
let _raiseN2;
let _isDisasterRecovery = false;
let _gdprUpdated = () => {};

const _getStoragePrefix = sessionId => `Session_${sessionId.replace(/-/g,'')}.`;

const _applyStoragePrefix = (userKey) => {
    return _storagePrefix ? _storagePrefix + userKey : userKey;
};

const _disasterRecoveryRaiseN2 = () => {
    const win = window.open(getGapUrl(_clientPersonType, _clientPersonCode), '_blank');
    if(win){
        win.focus();
    }
}

const usd = {
    securityLevels: {
        DS: 'DS',
        ID: 'ID',
        NI: 'ID',
        N1: 'N1',
        N2: 'N2'
    },

    initDisasterRecovery: (agentId, agentToken, clientId, interactionId, securityLevel, sessionId) => {
        _agentId = agentId;
        _agentToken = agentToken;
        _clientId = clientId;
        _clientPersonCode = _clientId.substring(1, _clientId.length);
        _clientPersonType = _clientId.substr(0, 1);
        _interactionId = interactionId;
        _securityLevel = securityLevel;
        _sessionId = sessionId;
        _storagePrefix = _getStoragePrefix(_sessionId);
        _changeSecurityLevel = () => {};
        _isDisasterRecovery = true;
        _raiseN2 = _disasterRecoveryRaiseN2;
    },

    initDisasterRecoveryStore: () => {
        if (window.document.location.pathname.indexOf('disasterRecovery') === -1) {
            const drSession = sessionStorage.getItem(DR_SESSION);
            if (drSession) {
                const { agentId, agentToken, clientId, interactionId, securityLevel, sessionId } = JSON.parse(drSession);
                if (agentId && agentToken && clientId && interactionId && securityLevel && sessionId) {
                    usd.initDisasterRecovery(agentId, agentToken, clientId, interactionId, securityLevel, sessionId);
                }
            }
        }
    },

    cleanDisasterRecoveryStore: () => {
        sessionStorage.removeItem(DR_SESSION);
    },

    init: (usdInformation) => {
        window.console.log('initialize usd vars', usdInformation);
        _agentId = usdInformation.agentId;
        _agentToken = usdInformation.agentToken;
        _clientId = usdInformation.fClient;
        _clientPersonCode = _clientId.substring(1, _clientId.length);
        _clientPersonType = _clientId.substr(0, 1);
        _interactionId = usdInformation.interactionId;
        _securityLevel = usdInformation.securityLevel;
        _sessionId = usdInformation.sessionId;
        _storagePrefix = _getStoragePrefix(_sessionId);
        _registerTrace = (...args) => {
            window.console.log('Inicio del uso de REGISTER TRACE seteado en INIT');
            usdInformation.RegisterTrace(...args);
            window.console.log('Fin del uso de REGISTER TRACE seteado en INIT');
        },
        _changeSecurityLevel = usdInformation.ChangeSecurityLevel;
        _inOffice = usdInformation.inOffice;
        _redirectURL = usdInformation.redirectURL;
        _navigateURL = usdInformation.navigateURL;
        _raiseN2 = usdInformation.RaiseN2;
        _gdprUpdated = isFunction(usdInformation.GdprUpdated) ? usdInformation.GdprUpdated : () => {};
        window.console.log('ending initialization usd vars');
    },

    refreshAgentToken: (agentToken) => {
        _agentToken = agentToken;

        const drSession = sessionStorage.getItem(DR_SESSION);
        if (drSession) {
            const {
                agentId,
                clientId,
                interactionId,
                securityLevel,
                sessionId,
            } = JSON.parse(drSession);

            sessionStorage.setItem(DR_SESSION, JSON.stringify({ agentId, agentToken, clientId, interactionId, securityLevel, sessionId }));
        }
    },

    requestInformationFromUSD: () => {
        const requestUSDInformation = window.parent.requestUSDInformation;
        let usdEvent = window.location.search.slice(1);
        let usdEnabled = false
        if (window.__ENV__ !== LOCAL_ENVIRONMENT && usdEvent){
                usdEvent = usdEvent.replace("data=", ""); //TODO: Remove when NOT hosted by CRM
                usdEvent = decodeURIComponent(usdEvent);
                window.open(usdEvent);
                usdEnabled = true;
        } else {
            if(requestUSDInformation){
                requestUSDInformation();
                usdEnabled = true;
            }
        }

        return usdEnabled;
    },

    getSecurityInfo:() => {
        return {
            agentId: _agentId,
            agentToken: _agentToken,
            clientPersonCode: _clientPersonCode,
            clientPersonType: _clientPersonType,
            interactionId: _interactionId,
            isDisasterRecovery: _isDisasterRecovery
        };
    },
    getAgentId: () => {
        return _agentId;
    },
    getAgentToken: () => {
        return _agentToken;
    },
    getClientId: () => {
        return _clientId;
    },
    getInteractionId: () => {
        return _interactionId;
    },
    getSecurityLevel: () => {
        return _securityLevel;
    },
    getSessionId: () => {
        return _sessionId;
    },
    getStoragePrefix: sessionId => {
        return _getStoragePrefix(sessionId);
    },
    getInOffice: () => {
        return _inOffice;
    },
    getRedirectURL: () => {
        return _redirectURL;
    },
    getNavigateURL: () => {
        return _navigateURL;
    },
    applyStoragePrefix: _applyStoragePrefix,
    isPrefixed: (key) => {
        return key ? key.startsWith(_storagePrefix) : false;
    },
    registerTrace: (...params) => {
        _registerTrace(_sessionId, ...params)
    },

    traceEvent: (actionCode, operationError, errorMessage) => {
        const doTrace = (sessionId, actionCode, operationError, errorMessage) => {
            if (window.__ENV__ === LOCAL_ENVIRONMENT) {
                window.console.log('WRAP-UP TRACE: actionCode: ' + actionCode + ' operationError: ' + operationError + ' errorMessage: ' + errorMessage);
            } else {
                _registerTrace(sessionId, actionCode, operationError, errorMessage);
            }
        };

        if (isArray(actionCode)) {
            actionCode.forEach(element => {
                doTrace(_sessionId, element, operationError, errorMessage);
            });
        } else {
            doTrace(_sessionId, actionCode, operationError, errorMessage);
        }
    },
    setSecurityLevel: (newSecurityLevel) => {
        _securityLevel = newSecurityLevel;
        _changeSecurityLevel(_sessionId, _securityLevel);
    },
    gdprUpdated: (result1, result2, errorMessage) => {
        try {
            _gdprUpdated(_sessionId, result1, result2, errorMessage);

        } catch (error) {
            window.console.error('Error executing usdInformation.GdprUpdated function: ', error);
        }
    },
    getCredentialCookieName: () => {
        return _applyStoragePrefix(TOKEN_CREDENTIAL);
    },
    getCredentialCookieExpiration: () => {
        const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
        return inOneHour;
    },
    raiseN2: (actionCode, operationError, errorMessage ) => {
        return _raiseN2(_sessionId, actionCode, operationError, errorMessage);
    },
    resetClient: () => {
        _clientId = "";
        _interactionId = "";
        _clientPersonCode = "";
        _clientPersonType = "";
        _interactionId = "";
        _securityLevel = "";
        _sessionId = "";
    },
    resetAgent: () => {
        _agentId = "";
        _agentToken = "";
    }
};

module.exports = usd;
