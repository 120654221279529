// @Vendors
const Immutable = require('immutable');

// @Actions
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    valid: __CONTACT_CENTER__? true:false,
    hasSignaturePattern: true,
    acceptedManifest: false,
    manifestInvalid: false,
    showWarning: false,
    allDocsRead: false
});

const toggleManifestCheckbox = (immState) => {
    if (immState.get('allDocsRead')) {
        return immState.set('acceptedManifest', !immState.get('acceptedManifest'))
            .set('valid', true)
            .set('manifestInvalid', false)
            .set('showWarning', false);
    }
    else {
        return immState.set('showWarning', true);
    }
};

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
}

const brokerRightsCashExchangeStep2 = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_SUCCESS:
            return state.merge({
                valid: true
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_FAILURE:
            return state.merge({
                valid: false,
                manifestInvalid: true
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_MANIFEST:
            return toggleManifestCheckbox(state);
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_DOCUMENTS_READ:
            return setDocumentsRead(state)
        default:
            return state;
    }
}

module.exports = brokerRightsCashExchangeStep2
