//@vendor
const Immutable = require('immutable');

//@constant
const actionTypes = require('constants/actionTypes');

// @helpers
const { formatText } = require('core/i18n').i18n;

const initialState = Immutable.fromJS({
    isFetchingContent : false,
    contentError: false,
    hasBeenFetched: false
});

function parseContentResponse(state, contents, id){
    let cardContents = {};

    if(contents.length > 0){
        const { body } = contents[0];
        const value = body && body[0] && body[0].value;
        const cmsValues = value && JSON.parse(value.replace(/&nbsp;/g, ' '));

        const title = formatText(cmsValues.title.key);

        let subtitle;
        if(cmsValues.subtitle && cmsValues.subtitle.key) {
            subtitle = cmsValues.subtitle.key;
        }

        const contentItems = cmsValues.contentItems;
        const contentData = contentItems && contentItems.data;
        let advantages = [];

        if( contentData ) {
            let data = contentItems.data;
            data.forEach((item) => {
                let advantage = { icon : item.icon, text : item.key }

                if(item.params.length) {
                    advantage.text = formatText(item.key, item.params);
                }

                advantages.push(advantage);
            })
        }

        cardContents = {
            title: title || '',
            subtitle: subtitle || '',
            advantages: advantages
        }
    }
    return state.merge({
        isFetchingContent: false,
        hasBeenFetched: true,
        [id]: cardContents
    });
}

function collectivesCardContentReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.COLLECTIVE_CARD_CONTENT_REQUEST:
            return state.merge({
                isFetchingContent: true,
            });

        case actionTypes.COLLECTIVE_CARD_CONTENT_SUCCESS:
            return parseContentResponse(state, action.payload.response, action.payload.id);

        case actionTypes.COLLECTIVE_CARD_CONTENT_FAILURE:
            return state.merge({
                contentError: true,
                error: action.payload.error,
                isFetchingContent: false,
            });

        default:
            return state;
    }
}

module.exports = collectivesCardContentReducer;
