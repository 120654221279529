// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_PAYMENT_REQUEST,
    MORTGAGE_REQUEST_FETCH_PAYMENT_SUCCESS,
    MORTGAGE_REQUEST_FETCH_PAYMENT_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    appraisalCost: 0,
    paymentAccount: '',
    simpleNoteCost: 0,
    isFetching: false,
    error: ''
});

const payment = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_PAYMENT_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_PAYMENT_SUCCESS:
            const paymentAction = action.data.data;

            return state.merge({
                appraisalCost: paymentAction.appraisalCost,
                paymentAccount: paymentAction.paymentAccount,
                simpleNoteCost: paymentAction.simpleNoteCost,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_PAYMENT_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = payment;
