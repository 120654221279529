// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_REQUEST,
    HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_SUCCESS,
    HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_FAILURE,
} = require('constants/contactCenter/actionTypes');
const {
    FIFTH_STEP,
    FIRST_STEP,
    FOURTH_STEP,
    SECOND_STEP,
    THIRD_STEP,
} = require('constants/index');

// @reducers
const hireCardAtHomeAbroadStep1 = require('./hireCardAtHomeAbroad/hireCardAtHomeAbroadStep1');
const hireCardAtHomeAbroadStep2 = require('./hireCardAtHomeAbroad/hireCardAtHomeAbroadStep2');
const hireCardAtHomeAbroadStep3 = require('./hireCardAtHomeAbroad/hireCardAtHomeAbroadStep3');
const hireCardAtHomeAbroadStep4 = require('./hireCardAtHomeAbroad/hireCardAtHomeAbroadStep4');
const hireCardAtHomeAbroadStep5 = require('./hireCardAtHomeAbroad/hireCardAtHomeAbroadStep5');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorMsg: '',
    isFetching: false,
    steps: [
        hireCardAtHomeAbroadStep1(),
        hireCardAtHomeAbroadStep2(),
        hireCardAtHomeAbroadStep3(),
        hireCardAtHomeAbroadStep4(),
        hireCardAtHomeAbroadStep5()
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag:false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardAtHomeAbroadStep1(step, action);
            case SECOND_STEP:
                return hireCardAtHomeAbroadStep2(step, action);
            case THIRD_STEP:
                return hireCardAtHomeAbroadStep3(step, action);
            case FOURTH_STEP:
                return hireCardAtHomeAbroadStep4(step, action);
            case FIFTH_STEP:
            return hireCardAtHomeAbroadStep5(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function hireCardAtHomeAbroadReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 4:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 5:
                    partialSteps = updateStep(FIFTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
            case actionTypes.HIRE_CARD_LOADING:
                return state.merge({
                    loadingFlag: action.payload.loadingFlag
                });
        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_SET_ACCOUNT:
            return state.merge({
                steps: updateStep(THIRD_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_SET_AGREEMENT_CHECKBOX:
            const stepToUpdate = __CONTACT_CENTER__ ? FIRST_STEP : FIFTH_STEP;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action)
            });

        case HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_REQUEST:
        case HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_SUCCESS:
        case HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_FAILURE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                isFetching: false
            });

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_RESET:
            return initialState;

        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        default:
            return state;
    }
}

module.exports = hireCardAtHomeAbroadReducer;
