// const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS([
        {
            title: "Cuenta Corriente",
            description: "Sin gastos ni comisiones a la que podrás asociar otros productos como tarjetas.",
            img: 'ad-img-1'
        },
        {
            title: "Tarjeta Débito 4B",
            description: "Recarga tu ecard desde 1 € hasta 3.000 € en cajeros automáticos.",
            img: 'ad-img-2'
        },
        {
            title: "Tarjeta Visa Open",
            description: "Recarga tu ecard desde 1 € hasta 3.000 € en cajeros automáticos.",
            img: 'ad-img-4'
        },
        {
            title: "Prepago E-card",
            description: "Tarjeta virtual donde sólo puedes gastar el dinero que hayas cargado antes.",
            img: 'ad-img-3'
        }
    ]);
}



function productsAdsReducer(state = setInitialState(), action) {
    switch (action.type) {
        default:
            return state
    }
}

module.exports = productsAdsReducer;
