// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    hireTravelPlus: false,
    valid: true,
});

function cardActivationR42Step1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.SET_CARD_ACTIVATION_R42_VALIDATE_STEP:
            return state;
        case actionTypes.SET_CARD_ACTIVATION_R42_HIRE_TRAVEL_PLUS_VALUE:
            return state.merge({
                hireTravelPlus: !state.get('hireTravelPlus'),
            });
        default:
            return state;
    }
}

module.exports = cardActivationR42Step1;
