const debounce = require('utilities/debounce');

const ScrollUtils = {

    /**
     * Scrolls until the position of a given element
     * @param  {HTMLElement} parent The container of the element to scroll to
     * @param  {HTMLElement} element The element to scroll to
     * @param  {Number} duration  The duration of the scrolling animation
     * @param  {Number} currentTime The progress time in the scrolling animation
     */
    scrollTo(parent, element, duration = 500, currentTime = 0) {
        if (!parent || !element) {
            return;
        }

        const toPosition = element.offsetTop - 10;

        if (duration === 0) {
            parent.scrollTop = toPosition;
        } else {
            if (currentTime >= duration) {
                return;
            }
            let progress = currentTime / duration * Math.PI / 2;
            let position = toPosition * (Math.sin(progress));

            setTimeout(() => {
                parent.scrollTop = position;
                this.scrollTo(parent, element, duration, currentTime + 10);
            }, 10);
        }
    },

    on(element = window, callback) {
        const onScroll = debounce(callback, 10);
        element.addEventListener('scroll', onScroll, false);
        return onScroll;
    },

    off(element = window, callback) {
        element.removeEventListener('scroll', callback);
    },

    scrollToTop() {
        window.scrollTo(0, 0);
    },

    scrollWindowTo(element, buffer = 0 , duration = 500, currentTime = 0) {
        if (!element) {
            return;
        }

        const currentScrollY = window.pageYOffset;
        const toPosition = element.getBoundingClientRect().top + window.pageYOffset + buffer;
        const targetScrollY = toPosition - currentScrollY;

        if (duration === 0) {
            window.scrollTo(0, targetScrollY);
        } else {
            if (currentTime >= duration) {
                return;
            }
            let progress = currentTime / duration * Math.PI / 2;
            let position = currentScrollY + (targetScrollY * (Math.sin(progress)));

            setTimeout(() => {
                window.scrollTo(0, position);
                
                this.scrollWindowTo(element, buffer, duration, currentTime + 10);
            }, 10);
        }
    },
    preventBackgroundScroll (on) {
        if (on) {
            document.querySelector('body').classList.add('no-scroll');
        } else {
            document.querySelector('body').classList.remove('no-scroll');
        }
    }
}

module.exports = ScrollUtils;
