// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    EURO_TEXT,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    WIZARD_THIRD_STEP
} = require('constants/index');
const brokerStocksAdvancedBuyStep1 = require('./brokerStocksAdvancedBuy/brokerStocksAdvancedBuyStep1');
const brokerStocksAdvancedBuyStep2 = require('./brokerStocksAdvancedBuy/brokerStocksAdvancedBuyStep2');
const brokerStocksAdvancedBuyStep3 = require('./brokerStocksAdvancedBuy/brokerStocksAdvancedBuyStep3');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerStocksAdvancedBuyStep1(undefined, {type: null}),
            brokerStocksAdvancedBuyStep2(undefined, {type: null}),
            brokerStocksAdvancedBuyStep3(undefined, {type: null})
        ],
        visibleStep: 1,
        productCurrency: EURO_TEXT,
        willCancel: false,
        submitSuccessful: false,
        //ISIN of the stock I'm buying
        isin: '',
        //ISO of the stock I'm buying
        iso: '',
        validationServiceResponse: {},
        signaturePatternToken: '',
        error: '',
        emissionDate : moment(),
        exAnteFetching: false,
        exAnteData: {},
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerStocksAdvancedBuyStep1(step, action);
            case SECOND_STEP:
                return brokerStocksAdvancedBuyStep2(step, action);
            case THIRD_STEP:
                return brokerStocksAdvancedBuyStep3(step, action);
        }
    })
}

function brokerStocksAdvancedBuyReducer(state = setInitialState(), action) {
    let partialSteps;
    let visibleStep;

    switch (action.type) {
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_RESET:
            return setInitialState();
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                    });
                case WIZARD_THIRD_STEP:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_TERMS_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_SELECTED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_RESET:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
        case actionTypes.BROKER_SEARCH_SELECTED_RESULT_RESET:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_TYPE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_LIMITED_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDITY_DATE_CHANGED:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_SET_LIMITED_VALUE_CURRENCY:
            return state.merge({
                productCurrency: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_FINISH:
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_REQUEST:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                case WIZARD_THIRD_STEP:
                    return state;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_SUCCESS:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                case WIZARD_THIRD_STEP:
                    return state;
                case WIZARD_SECOND_STEP: {
                    // there is no easy way due to how this is done to make 3rd step to reflect 1st step validityDate
                    // it is like it is hardcoded that step 2 has to have a validityDate even there is no datePicker in step 2
                    // in order to be reflected in summary. such level of indirection and hardness is caused due to how in step 3
                    // AdvancedBuySellStockSummary and BuySellStockSummary are used
                    const newState = state.setIn(['steps', SECOND_STEP, 'validityDate'], state.getIn(['steps', FIRST_STEP, 'validityDate']));

                    partialSteps = updateStep(SECOND_STEP, newState.get('steps'), action);

                    return newState.merge({
                        steps: partialSteps,
                        visibleStep: WIZARD_THIRD_STEP,
                        validationServiceResponse: action.payload.data
                    });
                }
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_FAILURE:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                case WIZARD_THIRD_STEP:
                    return state;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        error: action.payload.data.error
                    });
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_SET_ORDER_ID:
            return state.merge({
                isin: action.payload.isin,
                iso: action.payload.iso
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_SIGNATURE_SUCCESS:
            return state.set('signaturePatternToken', action.payload.token);
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONFIRM_REQUEST:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONFIRM_SUCCESS:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONFIRM_FAILURE:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                error: action.payload.data.error
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_REQUEST:
            return state.setIn(['steps', THIRD_STEP, 'orderStorage'], true)
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_SUCCESS:
            return state.setIn(['steps', THIRD_STEP, 'orderStorage'], false)
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_STORE_ORDER_ERROR:
            return state.setIn(['steps', THIRD_STEP, 'orderStorage'], false)
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_FETCHING:
            return state.merge({
                exAnteFetching: true,
            })
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_SUCCESS:
            return state.merge({
                exAnteData: action.payload.response,
                exAnteFetching: false,
            })
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_EXANT_COST_ERROR:
            return state.merge({
                exAnteFetching: false,
            })
        default:
            return state
    }
}

module.exports = brokerStocksAdvancedBuyReducer;
