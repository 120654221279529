//@vendor
const Immutable = require('immutable');

//@constants
const actionTypes = require('constants/actionTypes');
const {
    EURO_TEXT,
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_OTHER
} = require('constants/index');
// @utilities
const { formatText } = require('core/i18n').i18n;

function setInitialState() {
    return Immutable.fromJS({
        dataLoaded: false,
    });
}

const initialState = setInitialState();

const REJECTED_POSSIBLE_STATES = {
    X: formatText('broker-ordersAndMovementsActivityPlanStateOperation'),
    B: formatText('broker-ordersAndMovementsActivityPlanStateLackOfBalance')
}

function updateRejectedMovements(rejectedLists,nameOfOperation) {
    let byId = {};
    let byOrder = [];
    rejectedLists.map((rejectedList, index) =>{
        const idImp = index.toString();
        const parsedRejectedList = {
            id: idImp,
            date: (rejectedList.fecSolScr).toString(),
            operation: (nameOfOperation || '').trim(),
            import: {
                amount: rejectedList ? rejectedList['importe.importe'] : 0,
                currency: rejectedList ? rejectedList['importe.divisa'] : EURO_TEXT,
            },
            state:REJECTED_POSSIBLE_STATES[(rejectedList.estadoOperacion || '').trim()],
            isFetching: false,
            inProgress: false,
            submitSuccessful: false,
            errorReceived: false,
            isRejectedActivity: true,
            movementType: BROKER_ORDERS_AND_MOVEMENTS_PLAN_OTHER
        };

        byId[idImp] = parsedRejectedList;
        byOrder.push(idImp);
    });

    return {
        byId,
        byOrder
    }
}

function brokerContractRejectedActivities(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_CONTRACT_REJECTED_ACTIVITIES_REQUEST:
           return state.merge({
                [action.payload.contractId]:{
                    isFetching: true
                }
            });

        case actionTypes.BROKER_GET_CONTRACT_REJECTED_ACTIVITIES_SUCCESS:
            let newState;
            let preparedMovements = updateRejectedMovements(action.payload.data.listaMovRechazadosDet.movRechazadoDeta,action.payload.nameOfOperation);
            newState = state.mergeDeep({
                [action.payload.contractId]: {
                    isFetching: false,
                    success: true,
                    isRejectedActivity: true,
                    dataLoaded: true,
                    byId: preparedMovements.byId,
                    byOrder: preparedMovements.byOrder
                }
            });
            return newState;

        case actionTypes.BROKER_GET_CONTRACT_REJECTED_ACTIVITIES_FAILURE:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    isFetching: false,
                    error: action.payload.error
                }
            });
        default:
            return state;
    }
}

module.exports = brokerContractRejectedActivities;
