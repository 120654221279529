const actionTypes = require('constants/actionTypes');
const { DATE_FILTER_NONE } = require('constants/index');
const get = require('lodash/object/get');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        getChoreListFetch: false,
        getChoreDetailFetch: false,
        choreList: [],
        byId: {},
        byOrder: [],
        choreDetail: {},
        getChoreListError: undefined,
        getChoreDetailError: undefined,
        filters: {
            date: {
                isActive: false,
                selected: DATE_FILTER_NONE,
                from: null,
                to: null
            },
            status: {
                isActive: false,
                status: null
            }
        }
    });
}

function parseChoreList(choreList, choreDetail) {
    let byId = {};
    let byOrder = [];

    choreList.forEach((chore)=>{
        chore["isExpanded"] = false;
        if(choreDetail && choreDetail.get('isExpanded') && chore["choreAssignmentId"] === choreDetail.get('choreAssignmentId') ){
            chore["isExpanded"] = true;
        }
        chore["date"] = chore["createdDate"];
        byId[chore["choreAssignmentId"].toString()] = chore,
        byOrder.push(chore["choreAssignmentId"].toString())
    })

    return {
        byId,
        byOrder
    }
}
function openYoungCardChores(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_LIST_REQUEST:
            return state.merge({
                getChoreListFetch: true,
                getChoreListError: undefined,
                choreList:[],
                byId: {},
                byOrder: []
            });
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_LIST_SUCCESS:
            return state.mergeDeep({
                getChoreListFetch: false,
                getChoreListError: undefined,
                choreList: action.payload.choreList
            },parseChoreList(action.payload.choreList, state.get('choreDetail')));
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_LIST_ERROR:
            return state.merge(setInitialState(),{
                getChoreListError: action.payload.error
            });
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_DETAIL_REQUEST:
            return state.merge({
                getChoreDetailFetch: true,
                getChoreDetailError: undefined,
                choreDetail:{}
            });
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_DETAIL_SUCCESS:
            return state.mergeDeep({
                getChoreDetailFetch: false,
                getChoreDetailError: undefined,
                choreDetail: action.payload.choreDetail
            },parseChoreList(state.get('choreList').toJS(), Immutable.fromJS(action.payload.choreDetail)));
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_DETAIL_ERROR:
            return state.merge({
                choreDetail:{},
                getChoreDetailFetch: false,
                getChoreDetailError: action.payload.error || true
            });
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_APPLY_FILTER:
            const filters = action.payload.filters;
            const dateFilterSelected = get(filters, 'date.selected') !== DATE_FILTER_NONE;
            const statusSelected = get(filters, 'status.status') !== null;
            const partial = state.mergeDeep({
                filters
            });

            return partial.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected
                    },
                    status: {
                        isActive: statusSelected
                    }
                }
            });
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_CLEAR_FILTER:
            const initialState = setInitialState();
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
        case actionTypes.OPENYOUNG_CARD_GET_CHORE_RESET_FILTER:
            const { filterKey } = action.payload;
            return state.setIn(['filters', filterKey], setInitialState().getIn(['filters', filterKey]));
        default:
            return state
    }
}
module.exports = openYoungCardChores;
