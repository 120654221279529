// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        dayLimit : '',
        isFetchError: false,
        isFetchSuccess: false,
        isFetching: false,
        isOtpError: false,
        isOtpFetching: false,
        isOtpSuccess: false,
        isSendSignatureKeyClicked: false,
        isVerified: false,
        lastRequestedDate : ''
    });
}

function processSignatureKeyFetchStatusSuccess(state, response) {
    return state.merge({
        dayLimit: response.dayLimit,
        isFetchError: false,
        isFetchSuccess: true,
        isFetching: false,
        isOtpError: false,
        isOtpFetching: false,
        isOtpSuccess: false,
        isSendSignatureKeyClicked: false,
        isVerified: response.verified,
        lastRequestedDate : response.lastRequestedDate
    });
}

function processSignatureKeyFetchStatusError(state) {
    return state.merge({
        isFetchError: true,
        isFetching: false,
        isOtpError: false,
        isOtpFetching: false,
        isOtpSuccess: false,
        isSendSignatureKeyClicked: false
    });
}

function processSignatureKeyPostStatusSuccess(state) {
    return state.merge({
        isFetchSuccess: false,
        isFetching: false,
        isOtpError: false,
        isOtpFetching: false,
        isOtpSuccess: true,
        isSendSignatureKeyClicked: false
    });
}

function processSignatureKeyPostStatusError(state) {
    return state.merge({
        isFetchSuccess: false,
        isFetching: false,
        isOtpError: true,
        isOtpFetching: false,
        isOtpSuccess: false,
        isSendSignatureKeyClicked: false
    });
}

function sendSignatureKeyReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_SIGNATURE_KEY_STATUS_REQUEST:
            return state.merge({
                isFetching: true,
                isFetchSuccess: false,
                isFetchError: false,
                isOtpSuccess: false,
                isOtpError: false,
                isSendSignatureKeyClicked: false
            });
        case actionTypes.FETCH_SIGNATURE_KEY_STATUS_SUCCESS:
            return processSignatureKeyFetchStatusSuccess(state, action.payload.response);
        case actionTypes.FETCH_SIGNATURE_KEY_STATUS_ERROR:
            return processSignatureKeyFetchStatusError(state);
        case actionTypes.SEND_SIGNATURE_KEY:
            return state.merge({
                isSendSignatureKeyClicked: true
            });
        case actionTypes.POST_SIGNATURE_KEY_STATUS_REQUEST:
            return state.merge({
                isOtpFetching: true,
                isSendSignatureKeyClicked: false,
                isFetchSuccess: false
            })
        case actionTypes.POST_SIGNATURE_KEY_STATUS_SUCCESS:
            return processSignatureKeyPostStatusSuccess(state);
        case actionTypes.POST_SIGNATURE_KEY_STATUS_ERROR:
            return processSignatureKeyPostStatusError(state);
        default:
            return state;
    }
}

module.exports = sendSignatureKeyReducer;
