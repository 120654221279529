// @ constants
const {
    AMOUNT_TARGET_TYPE,
    FORMATTEDTEXT_TYPE,
    RENEWABLE_DEPOSIT_TYPE,
    TEXT_TARGET_TYPE,
    TINTAE_TYPE,
} = require('constants/index');

module.exports = {
    setSuccessStepPrefix: 'deposit_setSuccessStepPrefix',
    setSuccessAdditionalInfo: 'deposit_setSuccessStepAdditionalInfo',
    title: 'contractSummary-depositWNMSuccessTitle',
    backButtonText: 'contractSummary-backToDeposits',
    backButtonUrl: '/myprofile/deposits',
    details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        title: 'contractSummary-depositProduct',
                        body: 'contractsView-depositWNMProductName',
                    },
                    {
                        columns: 12,
                        target: 'monto_traspasar',
                        title: 'contractSummary-requestedAmount',
                        type: AMOUNT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        title: 'contractSummary-expirationDate',
                        target: 'expiration',
                        type: TEXT_TARGET_TYPE
                    },
                ]
            },
            {
                columns: 6,
                offset: 1,
                details: [
                    {
                        columns: 12,
                        title: 'contractSummary-installment',
                        target: 'installment',
                        type: FORMATTEDTEXT_TYPE
                    },
                    {
                        columns: 12,
                        title: 'contractSummary-profitability',
                        type: TINTAE_TYPE
                    },
                    {
                        columns: 12,
                        target: 'renewDeposit',
                        field: 'value',
                        title: 'contractSummary-automaticRenewal',
                        type: RENEWABLE_DEPOSIT_TYPE
                    },
                    {
                        columns: 12,
                        title: 'contractSummary-interestPayments',
                        target: 'perLiq',
                        type: TEXT_TARGET_TYPE
                    },
                ]
            }
        ]
    ],
    warningMessage: [
        [
            {
                text: 'contractSummary-depositWNMSuccessWarningMessage'
            }
        ]
    ]
}
