// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { SECOND_STEP } = require('constants/index');
// @reducer
const cancelPostPaymentWizardStep = require('./contactCenter/cancelPostPayment/cancelPostPaymentWizardStep');
function setInitialState() {
     return Immutable.Map().merge({
        steps: [
            cancelPostPaymentWizardStep(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        submitSuccessful: false,
        isFetching: false,
        dataLoaded: false,
        errorReceived: false,
        postPaymentDetails: {}
    });
}

function updateWizardStep(steps, action) {
    return steps.update(SECOND_STEP, (step) => cancelPostPaymentWizardStep(step, action));
}

const initialState = setInitialState();

function cancelPostPayment(state = initialState, action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CANCEL_POST_PAYMENT_RESET:
            return setInitialState();
        case actionTypes.CANCEL_POST_PAYMENT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.CANCEL_POST_PAYMENT_STEP_VALIDATE:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateWizardStep(state.get('steps'), action);
                    break;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep: 2
            });
        case actionTypes.CANCEL_POST_PAYMENT_FAILURE:
        case actionTypes.FETCH_CANCEL_POST_PAY_DATA_FAILURE:
            return state.merge({
                error: action.payload.error
            });
        case actionTypes.CANCEL_POST_PAYMENT_CANCEL:
            return state.merge({
                willCancel: true
            });
        case actionTypes.FETCH_CANCEL_POST_PAY_DATA_SUCCESS:
            return state.merge({
                postPaymentDetails: action.payload.data.salidamvtos.lpagos0.pagos,
                dataLoaded: true
            });
        case actionTypes.CANCEL_POST_PAYMENT_SUCCESS:
            return state.merge({
                error: '',
                submitSuccessful: true
            });

        default:
            return state
    }
}

module.exports = cancelPostPayment;
