const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');
const { EURO_TEXT } = require('constants/index');
const now = require('lodash/date/now');

const initialState = Immutable.fromJS({});

function parseRetentions(retentions) {
    let byId = {};
    let byOrder = [];
    let legacyRetentions = [];

    retentions.map((retention, index) => {
        const parsedRetention = {
            isOutdated: false,
            id: now() + index.toString(),
            description: trim(retention.descripm || retention.concepto),
            date: retention.fechaAlta,
            startDate: retention.fechaAlta,
            endDate: retention.fechaBaja,
            reason: trim(retention.descripRetencion),
            balance: {
                amount: get(retention, 'importeRetencion.importe', 0),
                currency: get(retention, 'importeRetencion.divisa', EURO_TEXT)
            },
        };

        byId[parsedRetention.id] = parsedRetention;
        byOrder.push(parsedRetention.id);
        legacyRetentions.push(parsedRetention); //TODO: remove, it is just for backward compatibility
    });

    return {
        byOrder: byOrder,
        byId: byId,
        retentions: legacyRetentions //TODO: remove, it is just for backward compatibility
    };
}

function accountsRetentionsReducer(state = initialState, action) {
    let parsedRetentions;

    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_RETENTIONS_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {}
                }
            });
        case actionTypes.FETCH_ACCOUNT_RETENTIONS_SUCCESS:
            parsedRetentions = parseRetentions(action.payload.retentions);

            return state.mergeDeep({
                [action.payload.accountId]: {
                    isOutdated: false,
                    nextPage: action.payload.nextPage,
                    retentions: parsedRetentions.retentions, //TODO: remove, it is just for backward compatibility
                    byOrder: parsedRetentions.byOrder,
                    byId: parsedRetentions.byId,
                    loading: false,
                    error: false

                }
            });

        case actionTypes.FETCH_NEXT_ACCOUNT_RETENTIONS_PAGE_SUCCESS:
            parsedRetentions = parseRetentions(action.payload.retentions);
            const currentRetentions = state.get(action.payload.accountId);
            return state.mergeDeep({
                [action.payload.accountId]: {
                    nextPage: action.payload.nextPage,
                    retentions: currentRetentions
                        .get('retentions')
                        .concat(Immutable.fromJS(parsedRetentions.retentions)), //TODO: remove, it is just for backward compatibility
                    byOrder: currentRetentions
                        .get('byOrder')
                        .concat(Immutable.fromJS(parsedRetentions.byOrder)),
                    byId: parsedRetentions.byId,
                    loading: false,
                    error: false
                }
            });
        case actionTypes.FETCH_ACCOUNT_RETENTIONS_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    error: action.payload.data
                }
            });
        case actionTypes.ACCOUNT_RETENTIONS_OUTDATED:
            return state.map(item => item.set('isOutdated', true));
        default:
            return state;
    }
}

module.exports = accountsRetentionsReducer;
