import channelsGet from './channelsGet';
import channelsSet from './channelsSet';
import countriesGet from './countriesGet';
import countriesOnlySet from './countriesOnlySet';
import countrySet from './countrySet';
import enrollInfoGet from './enrollInfoGet';
import zoneSet from './zoneSet';


export default [
    ...channelsGet,
    ...channelsSet,
    ...countriesGet,
    ...countriesOnlySet,
    ...countrySet,
    ...enrollInfoGet,
    ...zoneSet,
];
