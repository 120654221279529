const Immutable = require('immutable');

const actionTypes = require('constants/actionTypes');

const setInitialState = () => {
    return Immutable.fromJS({
        byId: {},
        byOrder: [],
        isFetching: false,
        fetchSuccess: false,
        error: false
    });
}

const initialState = setInitialState();

function processNews(state, data) {
    let byId = {};
    let byOrder = [];

    data.forEach( news => {
        const id = news.story_id;
        byId[id] = {
            id,
            titulo: news.titulo,
            entradilla: news.entradilla,
            subtitulo: news.subtitulo,
            sumario: news.sumario,
            cuerpo: news.cuerpo,
            date: news.date
        }
        byOrder.push(id);
    });

    return state.merge({
        isFetching: false,
        fetchSuccess: true,
        error: false,
        byId,
        byOrder
    });
}

function brokerNewsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_NEWS_REQUEST: 
          return state.merge({
              isFetching: true
          });
        case actionTypes.BROKER_GET_NEWS_SUCCESS:
          return processNews(state, action.payload.data.response); 
        case actionTypes.BROKER_GET_NEWS_FAILURE: 
          return state.merge({
              isFetching: false,
              error: action.payload.error,
              hasBeenFetched: false
          });
        default:
            return state;
    }
}

module.exports = brokerNewsReducer;
