// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');

const setInitialState = () => (
    Immutable.fromJS({
        steps: [
            {},
            { hasSignaturePattern: true }
        ],
        visibleStep: WIZARD_FIRST_STEP,
        isFetching: false,
        fetchSuccessful: false,
        fetchError: false,
        fetchErrorCode: '',
        fetchErrorMessage: '',
        fetchResponse: {},
        isSaving: false,
        submitSuccessful: false,
        submitError: false,
        submitErrorCode: '',
        submitErrorMessage: '',
        invalidSecurityDataError: false
    })
);

const copyDataFromResponse = response => {
    const data = {
        codIBANCodbBan: response.codIBANCodbBan,
        codIBANDigitoControl: response.codIBANDigitoControl,
        codIBANPais: response.codIBANPais,
        telefono: response.telefono,
    };

    return Immutable.fromJS(data);
};

const cancelBizumReducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.BIZUM_CANCEL_QUERY_CONTRACT_START:
            return state.merge({
                isFetching: true,
                fetchSuccessful: false,
                fetchError: false,
                fetchErrorCode: '',
                fetchErrorMessage: '',
                fetchResponse: {},
            });

        case actionTypes.BIZUM_CANCEL_QUERY_CONTRACT_SUCCESS:
            return state.merge({
                isFetching: false,
                fetchSuccessful: true,
                fetchError: false,
                fetchErrorCode: '',
                fetchErrorMessage: '',
                fetchResponse: copyDataFromResponse(action.payload),
            });

        case actionTypes.BIZUM_CANCEL_QUERY_CONTRACT_FAILURE:
            return state.merge({
                isFetching: false,
                fetchSuccessful: false,
                fetchError: true,
                fetchErrorCode: action.payload.error.error,
                fetchErrorMessage: action.payload.error.errorDescription,
                fetchResponse: {},
            });

        case actionTypes.BIZUM_CANCEL_VALIDATE_STEP:
            if (action.payload.step === WIZARD_FIRST_STEP) {
                return state.merge({
                    visibleStep: WIZARD_SECOND_STEP
                });
            } else {
                return state;
            }

        case actionTypes.BIZUM_CANCEL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.BIZUM_CANCEL_SAVE_START:
            return state.merge({
                isSaving: true,
                submitSuccessful: false,
                submitError: false,
                submitErrorCode: '',
                submitErrorMessage: '',
                invalidSecurityDataError: false,
            });

        case actionTypes.BIZUM_CANCEL_SAVE_SUCCESS:
            return state.merge({
                isSaving: false,
                submitSuccessful: true,
                submitError: false,
                submitErrorCode: '',
                submitErrorMessage: '',
            });

        case actionTypes.BIZUM_CANCEL_SAVE_FAILURE:
            return state.merge({
                isSaving: false,
                submitSuccessful: false,
                submitError: true,
                submitErrorCode: action.payload.error.error,
                submitErrorMessage: action.payload.error.errorDescription,
            });

        case actionTypes.BIZUM_CANCEL_INVALID_SECURITY_DATA_ERROR:
            return state.merge({
                isSaving: false,
                invalidSecurityDataError: true,
            });

        case actionTypes.BIZUM_CANCEL_CLEAR_REQUEST:
            return setInitialState();

        default:
            return state;
    }
};

module.exports = cancelBizumReducer;
