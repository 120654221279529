const actionTypes = require('../constants/actionTypes');

const {
    recordLink,
    recordProcess,
    PROC_START,
    PROC_CANCEL,
    PROC_END,
    PROC_FAIL,
    PROC_FILTER_ACCOUNT_DEBITS,
    PROC_FILTER_ACCOUNT_TRANSACTIONS,
    PROC_FILTER_DOCUMENTS,
    PROC_FILTER_EMITTED_TRANSFERS,
    PROC_FILTER_RECEIVED_TRANSFERS,
    PROC_FILTER_SCHED_TRANSFERS,
    PROC_FILTER_CARDS_TRANSACTIONS,
    PROC_GET_DEBIT_PDF
} = require('../utilities/tagging');

const tagsToApply = {
    [actionTypes.FETCH_DOCUMENTS_FILTER]: [PROC_FILTER_DOCUMENTS, PROC_START],
    [actionTypes.FETCH_DOCUMENTS_FILTER_CLEAR]: [PROC_FILTER_DOCUMENTS, PROC_CANCEL],
    [actionTypes.SET_SCHEDULED_TRANSFERS_FILTERS]: [PROC_FILTER_SCHED_TRANSFERS, PROC_START],
    [actionTypes.CLEAR_SCHEDULED_TRANSFERS_FILTERS]: [PROC_FILTER_SCHED_TRANSFERS, PROC_CANCEL],
    [actionTypes.SET_RECEIVED_TRANSFERS_FILTERS]: [PROC_FILTER_RECEIVED_TRANSFERS, PROC_START],
    [actionTypes.CLEAR_RECEIVED_TRANSFERS_FILTERS]: [PROC_FILTER_RECEIVED_TRANSFERS, PROC_CANCEL],
    [actionTypes.SET_EMITED_TRANSFERS_FILTERS]: [PROC_FILTER_EMITTED_TRANSFERS, PROC_START],
    [actionTypes.CLEAR_EMITED_TRANSFERS_FILTERS]: [PROC_FILTER_EMITTED_TRANSFERS, PROC_CANCEL],
    [actionTypes.CARDS_TRANSACTIONS_SET_FILTERS]: [PROC_FILTER_CARDS_TRANSACTIONS, PROC_START],
    [actionTypes.FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR]: [PROC_FILTER_CARDS_TRANSACTIONS, PROC_CANCEL],
    [actionTypes.FETCH_ACCOUNT_TRANSACTIONS_FILTER]: [PROC_FILTER_ACCOUNT_TRANSACTIONS, PROC_START],
    [actionTypes.FETCH_ACCOUNT_TRANSACTIONS_FILTER_CLEAR]: [PROC_FILTER_ACCOUNT_TRANSACTIONS, PROC_CANCEL],
    [actionTypes.AUTHORIZATIONS_FILTER]: [PROC_FILTER_ACCOUNT_DEBITS, PROC_START],
    [actionTypes.RECEIPTS_FILTER]: [PROC_FILTER_ACCOUNT_DEBITS, PROC_START],
};

const tagsLinkToApply = {
    [actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_REQUEST]: [PROC_GET_DEBIT_PDF, PROC_START],
    [actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_SUCCESS]: [PROC_GET_DEBIT_PDF, PROC_END],
    [actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_FAILURE]: [PROC_GET_DEBIT_PDF, PROC_FAIL]
};


function actionTagging() {
    return (next) =>
        (action) => {
            action.type && tagsToApply[action.type] && recordProcess(...tagsToApply[action.type]);
            return next(action);
        };
}

function actionTaggingLink() {
    return (next) =>
        (action) => {
            action.type && tagsLinkToApply[action.type] && recordLink(...tagsLinkToApply[action.type]);
            return next(action);
        };
}

export {
    actionTagging,
    actionTaggingLink
}
