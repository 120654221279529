const React = require('react');


function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}


const withContactCenterValidation = (WrappedComponent, {withNoWrapper = false} = {}) => {
    if (withNoWrapper) {
        if (__CONTACT_CENTER__)  return null;
        return WrappedComponent;
    }
    class CCValidated extends React.Component {
        render() {
            if (__CONTACT_CENTER__) return null;
            return <WrappedComponent {...this.props} />
        }
    }

    CCValidated.displayName = `CCValidated(${getDisplayName(WrappedComponent)})`;
    return CCValidated;
}


module.exports = withContactCenterValidation
