// @ vendors
const Immutable = require("immutable");
// @ constants
const actionTypes = require("constants/actionTypes");
const {
    categoryOptions,
    getSubcategoryOptions,
} = require("constants/categories");

const initialState = Immutable.fromJS({
    isOpen: false,
    type: "",
    id: "",
    movementId: "",
    category: "",
    subcategory: "",
    description: "",
    date: "",
    amount: 0,
    subcategoryList: [],
    error: "",
    isUpdating: false,
    categoryCode: "",
    subCategoryCode: "",
});

function categoryUpdate(state = initialState, action) {
    let subcategoryList;
    let category;
    let subcategory;
    switch (action.type) {
        case actionTypes.CATEGORY_UPDATE_OPEN:
            category = action.payload.category || categoryOptions()[0].value;
            subcategoryList = getSubcategoryOptions(category);
            subcategory =
                action.payload.subcategory || subcategoryList[0].value;

            return state.merge({
                category,
                subcategory,
                subcategoryList,
                isOpen: true,
                type: action.payload.type,
                id: action.payload.id,
                movementId: action.payload.movementId,
                description: action.payload.description,
                date: action.payload.date,
                amount: action.payload.amount,
                error: "",
                categoryCode: action.payload.categoryCode,
                subCategoryCode: action.payload.subCategoryCode,
            });
        case actionTypes.CATEGORY_UPDATE_SET_CATEGORY:
            category = action.payload.category;
            subcategoryList = getSubcategoryOptions(category);

            return state.merge({
                category,
                subcategoryList,
                subcategory: subcategoryList[0].value,
            });
        case actionTypes.CATEGORY_UPDATE_SET_SUBCATEGORY:
            return state.merge({
                subcategory: action.payload.subcategory,
            });
        case actionTypes.CATEGORY_UPDATE_REQUEST:
            return state.merge({
                isUpdating: true,
            });
        case actionTypes.CATEGORY_UPDATE_CLOSE:
        case actionTypes.CATEGORY_UPDATE_SUCCESS:
            return initialState;
        case actionTypes.CATEGORY_UPDATE_FAILURE:
            return state.merge({
                error: action.payload.error,
                isUpdating: false,
            });
        default:
            return state;
    }
}

module.exports = categoryUpdate;
