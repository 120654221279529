const { recordCommercialSpaceV2, recordPathV2 } = require("./tagging");

const buildEnhancedEcommerceTag = (productName, productImpressionList) => ({
    tealium_event: "product_impression",
    product_impression_id: [productName],
    product_impression_name: [productName],
    product_impression_variant: ["open"],
    product_impression_brand: [`${productName}.mbBanner`],
    product_impression_list: [productImpressionList],
    product_impression_position: ["0"],
});

export const trackViewAndComercialSpace = ({
    immLoan,
    analyticView,
    productImpressionList,
}) => {
    const type = immLoan.get("product");
    const subType = immLoan.get("productSubType");
    const productName = immLoan.get("product") + "." + subType;
    recordPathV2(analyticView(type, subType));
    const customData = buildEnhancedEcommerceTag(
        productName,
        productImpressionList
    );
    recordCommercialSpaceV2({ tealiumEvent: "product_impression" }, customData);
};

module.exports = {
    trackViewAndComercialSpace,
};
