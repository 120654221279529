const actionTypes = require('../constants/actionTypes');
const Immutable = require('immutable');
const { toNumericArray } = require('utilities/stringHelper');
const get = require('lodash/object/get');

// const { SMS_CODE_REGEX } = require('constants/index');

const initialState = Immutable.fromJS({
    token: null,
    operationsParams: null,

    // Init config
    isFetchingConfig: false,
    isFetchingConfigSuccess: false,
    isFetchingConfigError: false,
    isFetchingConfigForbiddenError: false,

    // Signature Code
    isFetchingSignaturePassCode: false,
    isFetchingSignaturePassCodeSuccess: false,
    isFetchingSignaturePassCodeError: false,
    requiredDigits: null,
    consumerInputDigits: [],
    consumerFinishInputDigits: false,
    showRequiredSignatureCode: false, // used to show a message if Signature Code step has not being validated.

    // SMS Code
    isFetchingSMSCode: false,
    isFetchingSMSCodeSuccess: false,
    isFetchingSMSCodeError: false,
    isFetchingSMSDescriptionError: '',
    isFetchingSMSError :'',
    requiredSMS: false,
    inputValueSMS: '',

    isSMSValid: false, // this flag indicates if the format of SMS input is valid or not.
    showWarningSMS: false, // used to show a message when user wants to validate SMS step but SMS input is not valid.

    withValidateButton: true,
    showBiocatchExceptionModal: false,

    callbackCalled: false
});

function createEmptyRequiredDigitsArray(state) {
    const requiredDigits = state.get('requiredDigits');

    if (requiredDigits) {
        return new Array(requiredDigits.length).fill('');
    }

    return [];
}

function checkforForbiddenError(payload) {
    let error = payload && payload.message && payload.message.body;
    if (error && error.status === 403 && error.message === "READ_ONLY_USER") {
        return true;
    } else {
        return false;
    }
}

function signaturePatternReducer(state = initialState, action) {
    let emptyDigits = [];
    switch (action.type) {
        case actionTypes.SIGNATURE_PATTERN_RESET:
            return initialState;
        // Init Config Actions
        case actionTypes.SIGNATURE_PATTERN_CONFIG_REQUEST:
            return state.merge({
                operationsParams: action.payload.operationsParams,
                isFetchingConfig: true,
                isFetchingConfigError: false,
                isFetchingConfigSuccess: false,
                callbackCalled: false,
                isFetchingConfigForbiddenError: false
            });
        case actionTypes.SIGNATURE_PATTERN_CONFIG_RESTART_REQUEST:
            return state.merge({
                isFetchingConfig: true,
                isFetchingConfigError: false,
                isFetchingConfigSuccess: false,
                isFetchingSMSCodeError: '',
                showWarningSMS: false,
                callbackCalled: false,
                inputValueSMS: '',
                consumerInputDigits: createEmptyRequiredDigitsArray(state),
                isFetchingSignaturePassCodeError: false,
                reasonForRestart: get(action, 'payload.reasonForRestart'),
                isFetchingConfigForbiddenError: false
            });
        case actionTypes.SIGNATURE_PATTERN_CONFIG_REQUEST_SUCCESS:
            let requiredDigits = action.payload.requiredDigits;
            let requiredSMS = action.payload.requiredSMS;

            const isFetchingSignaturePassCodeSuccess = !requiredDigits;
            const id = action.payload.id;

            if (requiredDigits) {
                requiredDigits = toNumericArray(requiredDigits);
                emptyDigits = new Array(requiredDigits.length).fill('');
            }

            return state.merge({
                requiredDigits,
                requiredSMS,
                isFetchingSignaturePassCodeSuccess,
                consumerInputDigits: emptyDigits,
                token: action.payload.token,
                isFetchingConfig: false,
                isFetchingConfigSuccess: true,
                isFetchingConfigError: false,
                isFetchingConfigForbiddenError: false,
                id
            });
        case actionTypes.SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE:
            return state.merge({
                isFetchingConfig: false,
                isFetchingConfigSuccess: false,
                isFetchingConfigError: true,
                isFetchingConfigForbiddenError: checkforForbiddenError(action.payload)
            });

        // Signature Code Actions
        case actionTypes.SIGNATURE_PATTERN_PASS_CODE_VALIDATION:
            return state.merge({
                isFetchingSignaturePassCode: true,
                showRequiredSignatureCode: false,
                isFetchingSignaturePassCodeError: false,
                reasonForRestart: ''
            });
        case actionTypes.SIGNATURE_PATTERN_PASS_CODE_VALIDATION_SUCCESS:
            return state.merge({
                requiredSMS: action.payload.requiredSMS,
                token: action.payload.token,
                isFetchingSignaturePassCode: false,
                isFetchingSignaturePassCodeSuccess: true,
                isFetchingSignaturePassCodeError: false
            });
        case actionTypes.SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE:
            return state.merge({
                requiredSMS: action.payload.requiredSMS,
                isFetchingSignaturePassCode: false,
                isFetchingSignaturePassCodeSuccess: false,
                isFetchingSignaturePassCodeError: true,
                consumerInputDigits: createEmptyRequiredDigitsArray(state)
            });
        case actionTypes.SIGNATURE_PATTERN_VALIDATE_DIGIT:
            const digits = state
                .get('consumerInputDigits')
                .set(action.payload.position, action.payload.newDigit);
            return state.merge({
                consumerFinishInputDigits: digits.indexOf('') === -1,
                consumerInputDigits: digits,
                showRequiredSignatureCode: false,
                isFetchingSignaturePassCodeError: false
            });
        case actionTypes.SIGNATURE_PATTERN_SHOW_REQUIRED:
            return state.merge({
                showRequiredSignatureCode: !state.get('isFetchingSignaturePassCodeSuccess')
            });

        // SMS Code Actions
        case actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION:
            return state.merge({
                isFetchingSMSCode: true,
                isFetchingSMSCodeError: false,
                isFetchingSMSDescriptionError: '',
                isFetchingSMSError: '',
                reasonForRestart: ''
            });
        case actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS:
            return state.merge({
                token: action.payload.token,
                isFetchingSMSCode: false,
                isFetchingSMSCodeSuccess: true,
                isFetchingSMSCodeError: false,
                isFetchingSMSDescriptionError: '',
                isFetchingSMSError: '',
            });
        case actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE:
            return state.merge({
                isFetchingSMSCode: false,
                isFetchingSMSCodeSuccess: false,
                isFetchingSMSCodeError: action.payload.error,
                isFetchingSMSDescriptionError: action.payload.errorDescription,
                isFetchingSMSError: action.payload.errorCode,
                showWarningSMS: true
            });
        case actionTypes.SIGNATURE_PATTERN_UPDATE_INPUT_SMS:
            const value = action.payload.value;

            return state.merge({
                inputValueSMS: value,
                // (Andres): Production is sending a OTP value that has a different format than the one
                // we get from preprod. So, temporarely we will remove the UI validation of the SMS code
                // and assume it is always valid.
                isSMSValid: true,
                // isSMSValid: SMS_CODE_REGEX.test(value),
                showWarningSMS: false
            });
        case actionTypes.SIGNATURE_PATTERN_SHOW_WARNING_SMS:
            return state.merge({
                showWarningSMS: true
            });
        case actionTypes.SIGNATURE_PATTERN_CALLBACK_CALLED:
            return state.merge({
                callbackCalled: true
            });
        case actionTypes.SIGNATURE_PATTERN_SET_WITH_VALIDATE_BUTTON:
            return state.merge({
                withValidateButton: action.withValidateButton
            });
        case actionTypes.SIGNATURE_PATTERN_BIOCATCH_EXCEPTION:
            return state.merge({
                isFetchingConfig: false,
                showBiocatchExceptionModal: true
            })
        default:
            return state;
    }
}

module.exports = signaturePatternReducer;
