const {
    GET_FOOTER_IN_PROGRESS,
    GET_FOOTER_SUCCESS,
    GET_FOOTER_ERROR,
} = require("constants/actionTypes");
const Immutable = require("immutable");

const initialState = Immutable.fromJS({
    isFetching: false,
    success: false,
    error: false,
    footerData: null,
});

const footer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FOOTER_IN_PROGRESS:
            return state.merge({
                isFetching: true,
            });
        case GET_FOOTER_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                footerData: action.payload.response.footerData,
            });
        case GET_FOOTER_ERROR:
            return state.merge({
                isFetching: false,
                error: true,
            });
        default:
            return state;
    }
};

module.exports = footer;