// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    amount: 0,
    valid: false,
    flagsError: {
        amountError: false
    }
});

const setAmount = (immState, amount) => {
    return immState.set('amount', Math.max(0, amount));
};

const validate = (immState) => {
    const amountValid = immState.get('amount') > 0;
    return immState.set('valid', amountValid)
                   .mergeIn(['flagsError', 'amountError'], !amountValid)
}

function brokerStocksPurchaseOrderStep1Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_CHANGE_AMOUNT:
            return setAmount(state, action.payload.amount);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_VALIDATE_STEP:
            return validate(state);
        default:
            return state;
    }
}

module.exports = brokerStocksPurchaseOrderStep1Reducer;
