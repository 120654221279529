// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');

// @utilities
const { buildProductLink } = require('utilities/productLinksHelper');
const { toFormatDate } = require('utilities/dateHelper');
const { formatWithoutRound } = require('utilities/currencyHelper');
const { toCamelCase } = require('utilities/stringHelper');

//@Actions
const actionTypes = require('constants/actionTypes');

const NO_DATA = 'noData';

// @constants
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS,
    DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR,
    DATE_FORMAT,
    BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH,
    BROKER_MYPROFILE_PRODUCT_FILE,
    BROKER_MYPROFILE_FUND_FILE,
    MYPROFILE_BROKER_EXPLORE_URL,
    ALTERNATIVE_FUNDS
} = require('constants/index');

const initialState = () => {
    return Immutable.fromJS({
        results: {},
        search: '',
        isFetching: false,
        success: false,
        fetched: false,
        error: false,
        searchEmpty: false,
        searchCollapsed: false,
        isOnlyOneProduct: false,
        onlyOneProductType: '',
        pageNumber: 0,
        pageNumberFormatted: '',
        displayedFilters: false,
        isClickBuyProduct: false,
        shouldSearch: false,
        saveFilters:{}
    });
}

const formatName = (name) => {
    return name.length > BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH ?
        `${name.slice( 0, BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH - 1 )}...`
        : name;
}

const buildQuotation = (value, type) => {
    return (type == TYPE_FUNDS || type == TYPE_PLANS) ? formatWithoutRound(value,3) : formatWithoutRound(value,4);
}

const resultsAdapter = (arr=[], type) => {
    return {
        instrumentsList: arr.instrumentsList.map(item => {
            return Object.assign({}, item, {
                name: item.name.trim(),
                assetClass: get(item,'assetClass', NO_DATA),
                subAssetClass: get(item,'subAssetClass', NO_DATA),
                quotation: buildQuotation(get(item, 'quotation', '-'), type),
                quotationDate: get(item, 'quotationDate') ?
                    toCamelCase(trim(toFormatDate(get(item, 'quotationDate'), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR, DATE_FORMAT))) : '-',
                dayChangeDate: get(item, 'dayChangeDate') ?
                    toCamelCase(trim(toFormatDate(get(item, 'dayChangeDate'), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR, DATE_FORMAT))) : '-',
                quotationTime:  get(item, 'quotationTime') ?
                    trim(get(item, 'quotationTime').slice(0,5)) : '',
                link: buildProductLink(type, item),
                dayChangePercentage: formatWithoutRound(get(item, 'dayChangePercentage', '-'), 2),
                percentageVariationTime: get(item,'percentageVariationTime', '-'),
                rentabilityPercentage: formatWithoutRound(get(item, 'yearToDateChangePercentage','-'), 2),
                shortName: formatName(item.name)
            });
        }),
        total: arr.total,
        totalPages: arr.totalPages,
        totalPagesFormatted: arr ? formatWithoutRound(arr.totalPages) : 0
    }
}

const sanitizeData = (data) => {
    return {
        stocks: resultsAdapter(data.stocks, TYPE_STOCKS),
        funds: resultsAdapter(data.funds, TYPE_FUNDS),
        plans: resultsAdapter(data.plans, TYPE_PLANS),
        etfs: resultsAdapter(data.etfs, TYPE_ETFS),
        warrants: resultsAdapter(data.warrants, TYPE_WARRANTS),
        alternativeFunds: resultsAdapter(data.alternativeFunds, ALTERNATIVE_FUNDS),
    };
}

const getProductTypes = (data) => {
    const keys = Object.keys(data);

    return keys.filter((value) => data[value].total > 0);
}


const searchPlanIndex = (state, dgsCode) => {
    const plans = state.getIn(['results', TYPE_PLANS, 'instrumentsList']);
    return plans ? plans.findIndex(item => item.get('dgsCode') === dgsCode) : null;
}

const fetchingDgsCode = (state, dgsCode) => {
    let planIndex = searchPlanIndex(state, dgsCode);
    return planIndex >= 0 ? state.setIn(['results', TYPE_PLANS, 'instrumentsList', planIndex, 'isFetchingDgs'], true) : state;
}

const successDgsCode = (state, dgsCode, secId) => {
    let planIndex = searchPlanIndex(state, dgsCode);
    return planIndex >= 0 ? state.setIn(['results', TYPE_PLANS, 'instrumentsList', planIndex, 'secId'], secId) : state;
}

const buyButtonClick = (state, dgsCode) => {
    let planIndex = searchPlanIndex(state, dgsCode);
    return planIndex >= 0 ? state.setIn(['results', TYPE_PLANS, 'instrumentsList', planIndex, 'isClickBuyProduct'], true) : state;
}

const updateWatchlistId = (productKey, watchlistId, state) => {
    let results = state.get('results');
    results.keySeq().forEach(productType => {
        const instrumentsList = results.getIn([productType, 'instrumentsList']);
        const productIndex = instrumentsList.findIndex(item => item.get('productKey') === productKey);
        if (productIndex !== -1) {
            results = results.setIn([productType, 'instrumentsList', productIndex, 'watchlistId'], watchlistId);
        }
    });
    return results;
}

const brokerProductAdvancedSearchReducer = (state = initialState(), action) => {
    switch (action.type) {
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_REQUEST:
            let newState = state;
            if (action.payload.clearLastResult) {
                newState = initialState();
            }
            return newState.merge({
                isFetching: true,
                success: false
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS:
            const data = action.payload.data;
            const results = sanitizeData(data);
            const productTypes = getProductTypes(results);
            const searchEmpty = productTypes.length === 0;
            const isOnlyOneProduct = productTypes.length === 1;
            const onlyOneProductType = productTypes.length === 1 ? productTypes[0] : '';
            const displayedFilters = state.get('displayedFilters');
            return state.merge({
                isFetching: false,
                success: true,
                fetched: true,
                results,
                searchEmpty,
                searchCollapsed: !displayedFilters,
                isOnlyOneProduct,
                onlyOneProductType,
                pageNumber: parseInt(action.payload.pageNumber),
                pageNumberFormatted: formatWithoutRound(action.payload.pageNumber) ,
                dissplayedFilters: false,
                error: false
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_FAILURE:
            return state.merge({
                isFetching: false,
                success: false,
                fetched: true,
                searchEmpty: false,
                searchCollapsed: true,
                error: action.payload.data.error
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_CLEAR:
            return initialState();
        case actionTypes.BROKER_ADVANCED_SEARCH_WIDGET_CHANGE_INPUT:
            return state.set('search', action.payload.search);
        case actionTypes.BROKER_ADVANCED_SEARCH_EDIT:
            return state.set('searchCollapsed', action.payload.searchCollapsed);
        case actionTypes.BROKER_ADVANCED_SEARCH_BUY_BUTTON_CLICK:
            return buyButtonClick(state, action.payload.dgsCode);
        case actionTypes.BROKER_ADVANCED_SEARCH_SHOULD_SEARCH:
            return state.set('shouldSearch', action.payload.data);
        case actionTypes.BROKER_MORNINGSTAR_URL_PLANS_REQUEST:
            return fetchingDgsCode(state, action.payload.dgsCode);
        case actionTypes.BROKER_MORNINGSTAR_URL_PLANS_SUCCESS:
            return successDgsCode(state, action.payload.dgsCode, action.payload.data);
        case actionTypes.BROKER_ADVANCED_SEARCH_SAVE_FILTERS:
            return state.set('savedFilters',action.payload.currentFilters);
        case actionTypes.LOCATION_CHANGE:
            if (!action.payload.pathname.includes(MYPROFILE_BROKER_EXPLORE_URL)
                || action.payload.pathname.includes(BROKER_MYPROFILE_PRODUCT_FILE)
                || action.payload.pathname.includes(BROKER_MYPROFILE_FUND_FILE)) {
                return initialState();
            } else {
                return state
            }
        case actionTypes.BROKER_WATCHLIST_ID_UPDATE:
            const { productKey, watchlistId } = action.payload;
            const updateResults = updateWatchlistId(productKey, watchlistId, state);
            return state.merge({
                results: updateResults
            });
        default:
            return state;
    }
}

module.exports = brokerProductAdvancedSearchReducer;
