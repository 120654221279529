/*
    Reducer -> GeneriInterstitial
    Reducer to diferrents interstitials (commercial, fioc, legal)
    We include here differents functions to change properties for interstitials

    1. Get Interstitial type actions
    2. Get content for interstitials
    3. Mark interstitial as show
    4. Commercial actions
*/
const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const setInitialState = () => Immutable.fromJS({
    type: '',
    content: {},
    fetched: false,
    fetching: false,
    hasBeenShown: false,
    increaseSuccess: false,
    fromAdvancedInterstitialModal: false
});

function interstitialGeneric(state = setInitialState(), action = {}) {
    switch (action.type) {
        // Get Interstitial type actions
        case actionTypes.FETCH_GET_INTERSTITIAL_TYPE_IN_PROGRESS:
            return state.merge({
                fetching: true
            });
        case actionTypes.FETCH_GET_INTERSTITIAL_TYPE_FAILURE:
            return state.merge({
                fetching: false,
                error: action.payload.error
            });
        case actionTypes.FETCH_GET_INTERSTITIAL_TYPE_SUCCESS:
            return state.mergeDeep({
                fetched: true,
                fetching: false,
                type: action.payload ? action.payload.interstitialType : '',
                content: action.payload
            });
        // Get content for interstitials
        case actionTypes.FETCH_GET_INTERSTITIAL_CONTENT_IN_PROGRESS:
            return state.merge({
                fetching: true
            });
        case actionTypes.FETCH_GET_INTERSTITIAL_CONTENT_FAILURE:
            return state.merge({
                fetching: false,
                error: action.payload.error
            });
        case actionTypes.FETCH_GET_INTERSTITIAL_CONTENT_SUCCESS:
            return state.merge({
                fetched: true,
                fetching: false,
                content: action.payload,
            });
        // Mark interstitial as show
        case actionTypes.MARK_INTERSTITIAL_AS_SHOWN:
            return state.merge({
                type: '',
                hasBeenShown: true
            });
        case actionTypes.FETCH_INCREASE_INTERSTITIAL_SHOWN_SUCCESS:
            return state.merge({
                increaseSuccess: true
            });
        case actionTypes.FETCH_INCREASE_INTERSTITIAL_SHOWN_FAILURE:
            return state.merge({
                increaseSuccess: false,
                error: action.payload.error
            });
        case actionTypes.ADVANCED_INTERSTITIAL_SET_FLAG:
            return state.merge({
                fromAdvancedInterstitialModal: true
            });
        case actionTypes.ADVANCED_INTERSTITIAL_RESET_FLAG:
            return state.merge({
                fromAdvancedInterstitialModal: false
            });
        default:
            return state;
    }
}


module.exports = interstitialGeneric;
