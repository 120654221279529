//@vendor
const Immutable = require('immutable');
const max = require('lodash/math/max');
const min = require('lodash/math/min');

//@constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
//@reducers
const cardModifyLimitStep1 = require('./cardModifyLimit/cardModifyLimitStep1');
const cardModifyLimitStep2 = require('./cardModifyLimit/cardModifyLimitStep2');

const initialState = Immutable.fromJS({
    steps: [
        cardModifyLimitStep1(undefined, {type: null}),
        cardModifyLimitStep2()
    ],
    maximumLimit: 0,
    minimumLimit: 0,
    visibleStep: 1,
    success: false,
    willLeave: false,
    willCancel: false,
    error: false,
    errorCode: '',
    isFetching: false,
    increaseLimits: {},
    decreaseLimits: {},
    limits: [],
    approvedAmount: 0,
    operationType: false,
    operationStatus: false,
    operationErrorMessage: ''
});

const mapCardReferenceData = referenceData => ({
    channel: referenceData.canal || '',
    standard: referenceData.standard || '',
    subtype: referenceData.subtipo || '',
    type: referenceData.tipo || ''
});

//(nicolas.olmos): response from tarjetas/contrataciones
const mapIncreaseLimits = ({ creditLimitList, cardReferenceData, maximumLimitAutorize }) => {
    const limitsList = creditLimitList.map(limit => parseInt(limit, 10));
    const minValueFromList = min(limitsList);
    const parsedMaximumLimitAutorize = parseFloat(maximumLimitAutorize);
    let newMaximumLimitAutorize;

    if (parsedMaximumLimitAutorize < minValueFromList) {
        newMaximumLimitAutorize = 0;
    } else {
        newMaximumLimitAutorize = parsedMaximumLimitAutorize;
    }

    return {
        limitsList,
        cardReferenceData: mapCardReferenceData(cardReferenceData),
        //(nicolas.olmos): if we set a new limit over this value (and this value is over zero) we will show a modal with a differente offer
        maximumLimitAutorize: newMaximumLimitAutorize
    };
};

function updateStep(index, state, action) {
    const increaseLimits = state.get('increaseLimits');
    const steps = state.get('steps');

    return steps.update(index, step => {
        let partialStep;
        switch (index) {
            case FIRST_STEP:
                partialStep = step.set('increaseLimits', increaseLimits);
                return cardModifyLimitStep1(partialStep, action);
            case SECOND_STEP:
                return cardModifyLimitStep2(step, action);
        }
    });
}

const getLimits = state => {
    const currentLimit = state.getIn(['steps', FIRST_STEP, 'currentLimit']);
    const comparer = (firstValue, secondValue) => firstValue - secondValue;
    const isCurrentValue = value => value === currentLimit;
    let increaseLimits = state.getIn(['increaseLimits', 'limitsList']);

    if(!increaseLimits.find(isCurrentValue)) {
        increaseLimits = increaseLimits.push(currentLimit);
    }

    //The increaselimits call returns lower values sometimes (than current limit).
    const limits = increaseLimits.sort(comparer).toJS()
        .filter(function(elem, index, self) { return index == self.indexOf(elem); });

    return state.mergeDeep({
        limits,
        maximumLimit: max(limits),
        minimumLimit: min(limits)
    });
};

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function cardModifyLimitReducer(state = initialState, action) {
    let partialSteps;
    let visibleStep;

    switch (action.type) {
        case actionTypes.CARD_INCREASE_LIMITS_REQUEST:
            return state.merge({
                success: false,
                error: false,
                isFetching: true
            });
        case actionTypes.CARD_INCREASE_LIMITS_SUCCESS:
            const increaseLimits = mapIncreaseLimits(action.payload);
            return state.mergeDeep({
                increaseLimits: increaseLimits,
                isFetching: false,
                success: false,
                error: false})
            .setIn(['steps', FIRST_STEP, 'maximumLimitAutorize'], increaseLimits.maximumLimitAutorize);
        case actionTypes.CARD_REQUEST_LIMITS_FAILURE:
            return state.merge({
                success: false,
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.CARD_MODIFY_LIMIT_INCREASE_REQUEST:
        case actionTypes.CARD_MODIFY_LIMIT_DECREASE_REQUEST:
            return state.merge({
                success: false,
                error: false,
                isFetching: true
            });
        case actionTypes.CARD_MODIFY_LIMIT_INCREASE_SUCCESS:
            return state.merge({
                success: true,
                error: false,
                isFetching: false,
                operationStatus: action.payload.operationStatus,
                approvedAmount: action.payload.approvedAmount
            });
        case actionTypes.CARD_MODIFY_LIMIT_DECREASE_SUCCESS:
            return state.merge({
                success: true,
                error: false,
                isFetching: false,
                approvedAmount: action.payload.approvedAmount
            });
        case actionTypes.CARD_MODIFY_LIMIT_INCREASE_FAILURE:
            return state.merge({
                success: false,
                error: true,
                errorCode: action.payload.errorCode,
                isFetching: false,
                operationErrorMessage: action.payload.operationErrorMessage,
                operationStatus: action.payload.operationStatus
            });
        case actionTypes.CARD_MODIFY_LIMIT_DECREASE_FAILURE:
            return state.merge({
                success: false,
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.CARD_GET_LIMITS:
            return getLimits(state);
        case actionTypes.CARD_MODIFY_LIMIT_SHOW_MODAL:
        case actionTypes.CARD_MODIFY_LIMIT_CLOSE_MODAL:
        case actionTypes.CARD_MODIFY_LIMIT_SETUP_STEP:
            partialSteps = state.getIn(['steps', FIRST_STEP]);
            return state.setIn(['steps', FIRST_STEP], cardModifyLimitStep1(partialSteps, action));
        case actionTypes.CARD_MODIFY_LIMIT_VALIDATE_STEP:
            const currentStep = action.payload.step;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state, action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state, action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.CARD_MODIFY_LIMIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_MODIFY_LIMIT_SET_LIMIT:
            return state.merge({
                steps: updateStep(FIRST_STEP, state, action),
                operationType: action.payload.operationType
            })
        case actionTypes.CARD_MODIFY_LIMIT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_MODIFY_LIMIT_CLEAR:
            return initialState;
        case actionTypes.CARD_MODIFY_LIMIT_RESET_STEP:
            return state.setIn(['steps', FIRST_STEP, 'tentativeLimit'], action.payload.limit);
        case actionTypes.CARD_MODIFY_LIMIT_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                steps: updateStep(SECOND_STEP, state, action)
            });
        default:
            return state;
    }
}

module.exports = cardModifyLimitReducer;
