const formHelper = require('../utilities/formHelper');

const workTypeList = [
{ value:1, label:"Cuenta propia"},
{ value:2, label:"Cuenta ajena"},
{ value:3, label:"Empresario"},
{ value:4, label:"Funcionario"},
{ value:5, label:"No trabajas"}];


function personal_loadCmbWorkType(){
    return workTypeList;
}

//"Cuenta propia"
const activityOwnAccount = [
{ value:1, label:"Abogado"},
{ value:2, label:"Agente/Representante/Jefe de Ventas"},
{ value:3, label:"Arquitecto Técnico (Aparejador)"},
{ value:4, label:"Arquitecto"},
{ value:5, label:"Conductor/Taxista"},
{ value:6, label:"Economista"},
{ value:7, label:"Electricista"},
{ value:8, label:"Escritores y Periodistas"},
{ value:9, label:"Escultores/Pintores/Fotógrafos/Decoradores"},
{ value:10, label:"Fontanero"},
{ value:11, label:"Ingeniero Superior"},
{ value:12, label:"Ingeniero Técnico (Perito)"},
{ value:13, label:"Mécanico"},
{ value:14, label:"Otros Licenciados Superiores"},
{ value:15, label:"Otros Profesionales"},
{ value:16, label:"Otros Técnicos Grado Medio"},
{ value:17, label:"Peluquero / Peluquera"},
{ value:18, label:"Profesional de Informática (Nivel Medio)"},
{ value:19, label:"Profesional de la Sanidad (Nivel Superior"},
{ value:20, label:"Profesional de la Sanidad (Nivel Medio)"}];

//Cuenta Ajena
const activityExternalAccount=[
{ value:1, label:"Abogado"},
{ value:2, label:"Agente/Representante/Jefe de Ventas"},
{ value:3, label:"Analista de Aplicaciones Informáticas"},
{ value:4, label:"Arquitecto Técnico (Aparejador)"},
{ value:5, label:"Arquitecto"},
{ value:6, label:"Camarero/Barman"},
{ value:7, label:"Dependiente de Comercio/Vendedor"},
{ value:8, label:"Directivo de Empresa Privada"},
{ value:9, label:"Economista"},
{ value:10, label:"Empleado Administrativo/Contabilidad"},
{ value:11, label:"Empleado Instituciones/Financieras"},
{ value:12, label:"Escritores y Periodistas"},
{ value:13, label:"Escultores/Pintores/Fotógrafos/Decoradores"},
{ value:14, label:"Estadístico,Matemático"},
{ value:15, label:"Ingeniero Superior"},
{ value:16, label:"Ingeniero Técnico (Perito)"},
{ value:17, label:"Jefe/Encargado (Industria o Servicios)"},
{ value:18, label:"Jefe de Oficina Administrativa"},
{ value:19, label:"Otros Licenciados Superiores"},
{ value:20, label:"Otros Profesionales"},
{ value:21, label:"Otros Técnicos Grado Medio"},
{ value:22, label:"Personal de Seguridad Privado"},
{ value:23, label:"Personal de Servicios Diversos"},
{ value:24, label:"Profesional de la sanidad (Nivel Superior)"},
{ value:24, label:"Profesional de la sanidad (Nivel Medio)"},
{ value:24, label:"Profesional de la sanidad (Profesores)"}
];

//Empresario
const activityBusinessMan=[
{ value:1, label:"Otros Propietarios de Empresas"},
{ value:2, label:"Propietario de Establecimiento Comercial"},
{ value:3, label:"Propietario de Hoteles"},
{ value:4, label:"Propietario de Restaurantes"}
];

//Funcionario
const activityOfficer=[
{ value:1, label:"Bomberos, Policías y Personal de Protección"},
{ value:2, label:"Carteros, Ordenanzas y Mensajeros"},
{ value:3, label:"Directivo Administración Pública"},
{ value:4, label:"Empleado Administrativo/Contabilidad"},
{ value:5, label:"Militar de las Fuerzas Armadas"}
];

//No trabajas
const activityNotWork=[
{ value:1, label:"Ama de Casa"},
{ value:2, label:"Desempleado"},
{ value:3, label:"Estudiante"},
{ value:4, label:"Jubilado"},
{ value:5, label:"Rentista"}
];

function personal_onChangeCmbWorkType(cmbSelected){
    let ret=[];
    switch (cmbSelected.value){
        case 1: //Cta propia
        ret.push({
                    target: "excludes_id",
                    value: ['tab-datos-empresa','tab-datos-otros-ingresos']
                });
        break;
        case 2: //Cta ajena
        ret.push({
                    target: "excludes_id",
                    value: ['tab-datos-trabajador']
                });
        break;
        case 3: //Empresario
        ret.push({
                    target: "excludes_id",
                    value: ['tab-datos-empresa','tab-datos-otros-ingresos']
                });
        break;
        case 4: //Funcionario
        ret.push({
            target: "excludes_id",
            value: ['tab-datos-trabajador']
        });
        break;
        case 5: //No Trabajas
        ret.push({
            target: "excludes_id",
            value: ['tab-datos-empresa','tab-datos-otros-ingresos']
        });
        break;
    }

    ret.push(
    {
        target: "work_type_id",
        value: cmbSelected.value
    }
    );
    return ret;
}


function personal_loadActivity(actualState){
	switch (actualState.filter(x=>x.id==="work_type_id")[0].value){
            case 0:
            return []

            case 1: //Cuenta Propia
            return activityOwnAccount;

            case 2: //Cuenta Ajena
			return activityExternalAccount;

			case 3: // Empresario
			return activityBusinessMan;

			case 4: //Funcionario
			return activityOfficer;

			case 5: //No trabajas
			return activityNotWork;
    }
}

function personal_onChangeCmbActivity(cmbSelected,component,card_details){
    let ret=[];
    //Actividad=Ama de Casa / Estudiante -- value:1 | value: 3
    if (cmbSelected.value===1 || cmbSelected.value ===3){
        ret.push({
            target: "excludes_id",
            value: ['tab-datos-empresa','tab-datos-otros-ingresos','tab-datos-trabajador']
        });
        card_details.filter(x=>x.id===component.target)[0].isErrorVisible = true;
    }
    else {
        ret.push({
            target: "excludes_id",
            value: ['tab-datos-empresa','tab-datos-otros-ingresos']
        });
        card_details.filter(x=>x.id===component.target)[0].isErrorVisible = false;
    }

    ret.push(
    {
        target: "activity_id",
        value: cmbSelected.value
    }
    );
    return ret;
}

function personal_onChangeSliderMoneyNeeded(value,component,actualState){
    let ret=[];
    ret.push({
            target: "i_solicitado",
            value: value
        },
        {
            target: "c_mensual",
            value: Math.round((((value*2)/100 ) /  actualState.filter(x=>x.id==="meses_seleccionado")[0].value) * 100) / 100
        }
    );
    return ret;
}

function personal_onChangeSliderMonthsNeeded(value,component,actualState){
    let ret=[];
    ret.push(
        {
            target: "c_mensual",
            value: Math.round(( (actualState.filter(x=>x.id==="i_solicitado")[0].value  + (actualState.filter(x=>x.id==="i_solicitado")[0].value*2)/100 ) /  value) * 100) / 100
        },
        {
            target: "meses_seleccionado",
            value: value
        }
    );
    return ret;
}

function validateStep1(card_details){
    let ret={
        card_details:card_details,
        isValidStep: true
    };

    return ret;
}

function validateStep2(card_details){
    let ret={
        card_details:card_details,
        isValidStep: true
    };

    return ret;
}

function validateStep3(card_details){
    let ret={
        card_details:card_details,
        isValidStep: true
    };

    return ret;
}

function validateStep4(card_details){
    let ret={
        card_details:card_details,
        isValidStep: true
    };

    return ret;
}

function personal_loan_validateStep(step,card_details){
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    switch (step) {
        case 1:
        return validateStep1(card_details)

        case 2:
        return validateStep2(card_details)

        case 3:
        return validateStep3(card_details)

        case 4:
        return validateStep4(card_details)
    }
}
//Forms Handlers
function personalLoan_onChangeCmbPurpose(sourceEvent, component, state) {
    sourceEvent = formHelper.buildComboTargetObject(sourceEvent, component);
    return formHelper.handleFormElementData(sourceEvent,component, state);
}

function personalLoan_handleBlur(sourceEvent, component, state) {
    return formHelper.handleFormElementData(sourceEvent,component, state);
}

function personalLoan_handleDatePickerCallback(sourceEvent, component, state) {
    sourceEvent = formHelper.buildDatePickerTargetObject(sourceEvent, component);
    return formHelper.handleFormElementData(sourceEvent,component, state);
}

function personalLoan_onChangeAmount(sourceEvent, component, state) {
    sourceEvent = formHelper.buildAmountTargetObject(sourceEvent, component);
    return formHelper.handleFormElementData(sourceEvent, component, state);
}

const mockedData = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' }
];
function personalLoan_loadCmbPais() { return mockedData; }
function personalLoan_loadCmbProvince() {return mockedData;}
function personalLoan_loadCmbTypeOfRoad() {return mockedData;}
function personalLoan_loadCmbContactSchedule() {return mockedData;}
function personalLoan_loadCmbFormOfIntervention() {return mockedData;}
function personalLoan_loadCmbInterventionType() {return mockedData;}
function personalLoan_loadCmbRelationshipWithFirstTitular() {return mockedData;}
function personalLoan_loadCmbTaxResidenceInSpain() {return mockedData;}
function personalLoan_loadCmbNationality() {return mockedData;}
function personalLoan_loadCmbResidenceCountry() {return mockedData;}
function personalLoan_loadCmbCountryOfBirth() {return mockedData;}
function personalLoan_loadCmbLanguage() {return mockedData;}
function personalLoan_loadCmbLevelOfStudies() {return mockedData;}
function personalLoan_loadCmbCivilStatus() {return mockedData;}
function personalLoan_loadCmbGender() {return mockedData;}
function personalLoan_loadCmbDocumentType() {return mockedData;}
function personalLoan_loadCmbLoanPurpose() { return mockedData; }
function personalLoan_loadCmbGracePeriod() { return mockedData; }
function personalLoan_loadCmbCurrentHousingRegimen() { return mockedData; }
function personalLoan_loadCmbCurrentMortgageCancellationFee() { return mockedData; }
function personalLoan_loadCmbHolder() { return mockedData; }
function personalLoan_loadCmbGuarantor() { return mockedData; }
function personalLoan_activity_own_account() { return activityOwnAccount; }

module.exports = {
	personal_loadCmbWorkType: personal_loadCmbWorkType,
	personal_onChangeCmbWorkType: personal_onChangeCmbWorkType,
	personal_loadActivity: personal_loadActivity,
	personal_onChangeCmbActivity: personal_onChangeCmbActivity,
    personal_onChangeSliderMoneyNeeded: personal_onChangeSliderMoneyNeeded,
    personal_onChangeSliderMonthsNeeded: personal_onChangeSliderMonthsNeeded,
    personalLoan_onChangeCmbPurpose: personalLoan_onChangeCmbPurpose,
    personalLoan_handleBlur: personalLoan_handleBlur,
    personalLoan_handleDatePickerCallback: personalLoan_handleDatePickerCallback,
    personalLoan_loadCmbPais: personalLoan_loadCmbPais,
    personalLoan_loadCmbProvince: personalLoan_loadCmbProvince,
    personalLoan_loadCmbTypeOfRoad: personalLoan_loadCmbTypeOfRoad,
    personalLoan_loadCmbContactSchedule: personalLoan_loadCmbContactSchedule,
    personalLoan_loadCmbFormOfIntervention: personalLoan_loadCmbFormOfIntervention,
    personalLoan_loadCmbInterventionType: personalLoan_loadCmbInterventionType,
    personalLoan_loadCmbRelationshipWithFirstTitular: personalLoan_loadCmbRelationshipWithFirstTitular,
    personalLoan_loadCmbTaxResidenceInSpain: personalLoan_loadCmbTaxResidenceInSpain,
    personalLoan_loadCmbNationality: personalLoan_loadCmbNationality,
    personalLoan_loadCmbResidenceCountry: personalLoan_loadCmbResidenceCountry,
    personalLoan_loadCmbCountryOfBirth: personalLoan_loadCmbCountryOfBirth,
    personalLoan_loadCmbLanguage: personalLoan_loadCmbLanguage,
    personalLoan_loadCmbLevelOfStudies: personalLoan_loadCmbLevelOfStudies,
    personalLoan_loadCmbCivilStatus: personalLoan_loadCmbCivilStatus,
    personalLoan_loadCmbGender: personalLoan_loadCmbGender,
    personalLoan_loadCmbDocumentType: personalLoan_loadCmbDocumentType,
    personalLoan_loadCmbLoanPurpose: personalLoan_loadCmbLoanPurpose,
    personalLoan_loadCmbGracePeriod: personalLoan_loadCmbGracePeriod,
    personalLoan_loadCmbCurrentHousingRegimen: personalLoan_loadCmbCurrentHousingRegimen,
    personalLoan_loadCmbCurrentMortgageCancellationFee: personalLoan_loadCmbCurrentMortgageCancellationFee,
    personalLoan_onChangeAmount: personalLoan_onChangeAmount,
    personalLoan_loadCmbHolder: personalLoan_loadCmbHolder,
    personalLoan_loadCmbGuarantor: personalLoan_loadCmbGuarantor,
    personal_loan_validateStep: personal_loan_validateStep,
    personalLoan_activity_own_account: personalLoan_activity_own_account
};
