const { COMBINED_DEPOSIT_CONTRACT_INFO_CMS_KEY } = require('constants/hiringProductInformation');

module.exports = {
    title: 'contractsView-contractsView_combinedDepositStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info',
            cms_key: COMBINED_DEPOSIT_CONTRACT_INFO_CMS_KEY,
            services: [
                {
                    propName: 'requestReferenceStandard',
                    params: {},
                    alreadyCalled: false
                }
            ]
        },
        {
            id_component: 'legal-information'
        }
    ]
};
