// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    valid: false,
    noOfProductsIsDirty: false,
    deletedAtIndex: -1,
    deletedProduct: {},
    selectedProductsArray: [
        {
            product: null,
            noOfProducts: 0,
            duplicateProductError: false
        },
    ]
});

function validateStep(step) {
    const selectedProductsArray = step.get('selectedProductsArray');
    const havingSomeNonEmptyRows = selectedProductsArray.some(productRow => {
        return productRow.get('product') !== null && productRow.get('noOfProducts') > 0;
    });
    const rowsWithProductAndZeroNumber = selectedProductsArray.filter(productRow => {
        return productRow.get('product') !== null && productRow.get('noOfProducts') <= 0;
    });
    const valid = havingSomeNonEmptyRows && rowsWithProductAndZeroNumber.size === 0;

    return step.merge({
        valid,
        noOfProductsIsDirty: true
    });
}

function externalTransferValuesStep2(state = initialState, action) {
    switch(action.type) {
        case actionTypes.EXTERNAL_TRANSFER_VALUES_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_NO_OF_PRODUCTS: {
            const _productIndex = action.payload.productIndex;
            const _noOfProducts = action.payload.noOfProducts;
            return state.setIn(['selectedProductsArray', _productIndex, 'noOfProducts'], _noOfProducts)
                .merge({
                    noOfProductsIsDirty: false
                });
        }
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH_INPUT_FIELD: {
            const _product = action.payload.product;
            const _productIndex = action.payload.productIndex;
            const _selectedProductsArray = state.get('selectedProductsArray');
            const productKey = _product.get('productKey');
            const productIndex = _selectedProductsArray.findIndex(product => product.getIn(['product', 'productKey']) === productKey);
            const _duplicateError = productIndex !== -1;
            let newProductRow = {
                duplicateProductError: _duplicateError
            };
            if (!_duplicateError) {
                newProductRow['product'] = _product;
            }

            return state.mergeIn(['selectedProductsArray', _productIndex], Immutable.fromJS(newProductRow));
        }
        case actionTypes.EXTERNAL_TRANSFER_VALUES_RESET_DUPLICATE_ERROR: {
            const _productIndex = action.payload.productIndex;

            return state.mergeIn(['selectedProductsArray', _productIndex], {
                duplicateProductError: false
            });
        }
        case actionTypes.EXTERNAL_TRANSFER_VALUES_ADD_PRODUCT_EMPTY_ROW: {
            const _selectedProductsArray = state.get('selectedProductsArray');
            const newProductRow = Immutable.fromJS({
                product: null,
                noOfProducts: 0
            });
            return state.merge({
                selectedProductsArray: _selectedProductsArray.push(newProductRow)
            });
        }
        case actionTypes.EXTERNAL_TRANSFER_VALUES_DELETE_PRODUCT: {
            const _productIndex = action.payload.productIndex;
            const deletedAtIndex = _productIndex;
            const deletedProduct = state.getIn(['selectedProductsArray', _productIndex]);
            const _updatedSelectedProductArray = state.get('selectedProductsArray').delete(_productIndex);
            const _updatedSelectedProductArrayLength = _updatedSelectedProductArray.size;
            const newProductRow = Immutable.fromJS({
                product: null,
                noOfProducts: 0
            });
            return state.merge({
                deletedAtIndex,
                deletedProduct,
                selectedProductsArray: _updatedSelectedProductArrayLength === 0 ?
                    _updatedSelectedProductArray.push(newProductRow) :
                    _updatedSelectedProductArray
            });
        }
        case actionTypes.EXTERNAL_TRANSFER_VALUES_UNDO_DELETION: {
            const deletedAtIndex = state.get('deletedAtIndex');
            const deletedProduct = state.get('deletedProduct');
            if (deletedAtIndex !== -1) {
                const _updatedSelectedProductArray = state.get('selectedProductsArray').insert(deletedAtIndex, deletedProduct);
                return state.merge({
                    deletedAtIndex: -1,
                    deletedProduct: {},
                    selectedProductsArray: _updatedSelectedProductArray
                });
            }
        }

        default:
            return state;
    }
}
module.exports = externalTransferValuesStep2;
