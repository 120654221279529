// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_REQUEST,
    MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_SUCCESS,
    MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_REQUEST,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_IN_PROGRESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_REQUEST,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_REQUEST,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    hasInsurance: false,
    insurancesRelatedPricing: {},
    insurancesRelatedStatusData: {},
    error: '',
    isFetchingPricing: false,
    pricingError: '',
    insuranceStatusError: '',
    isFetchingInsurancesStatus: false,
    insuranceConfig: {},
    isFetchingConfig: false,
    insuranceConfigError: '',
    insuranceLinkError: '',
    isFetchingInsuranceLink: false,
});

const mortgageRequestManagerInsuranceRelated = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_SUCCESS:
            return state.merge({
                hasInsurance: action.data.incluyeSeguroHogar,
                isFetching: false
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_FAILURE:
            return state.merge({
                error: action.error,
                isFetching: false
            });

        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_IN_PROGRESS:
            return state.merge({
                isFetchingPricing: true
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_SUCCESS:
            return state.merge({
                insurancesRelatedPricing: action.data,
                isFetchingPricing: false
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_FAILURE:
            return state.merge({
                pricingError: action.error,
                isFetchingPricing: false
            });

        case MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_REQUEST:
            return state.merge({
                isFetchingInsurancesStatus: true
            });
        case MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_SUCCESS:
            return state.merge({
                insurancesRelatedStatusData: action.data,
                isFetchingInsurancesStatus: false
            });
        case MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_FAILURE:
            return state.merge({
                insuranceStatusError: action.error,
                isFetchingInsurancesStatus: false
            });

        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_REQUEST:
            return state.merge({
                isFetchingConfig: true
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_SUCCESS:
            return state.merge({
                insuranceConfig: action.data,
                isFetchingConfig: false
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_FAILURE:
            return state.merge({
                insuranceConfigError: action.error,
                isFetchingConfig: false
            });

        case MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_REQUEST:
            return state.merge({
                insuranceLinkError: '',
                isFetchingInsuranceLink: true
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_SUCCESS:
            return state.merge({
                isFetchingInsuranceLink: false
            });
        case MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_FAILURE:
            return state.merge({
                insuranceLinkError: action.error,
                isFetchingInsuranceLink: false
            });

        default:
            return state;
    }
};

module.exports = mortgageRequestManagerInsuranceRelated;