// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        emittedDateTime: 0,
        isEmitted: false,
    });
}

function validateStep(step) {
    const valid = true;

    return step.merge({
        valid,
    });
}

function brokerETFsSellStep2(state = setInitialState(), action) {
    let newState;
    switch (action.type) {
        case actionTypes.BROKER_ETFS_SELL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_ETF_SELL_FINISH:
        newState = state.merge({
            isEmitted: false, // change this to 'true' if timestamp is required to show on confrimation screen
            emittedDateTime: moment(parseInt(action.payload.data))
        });
        return newState;
        default:
            return state;
    }
}

module.exports = brokerETFsSellStep2;
