const terms = [
    {
        name: 'Acciones preferentes',
        definition: 'Las acciones preferentes son acciones que pagan un dividendo prefijado (muy parecido a los cupones de los títulos de renta fija) pero no tienen derecho a voto (excepto en circunstancias extraordinarias). Por el contrario, el dividendo de las acciones normales es muy variable y puede no existir.',
    },
    {
        name: 'Acción',
        definition: 'Una acción es un título representativo de la propiedad de una parte de la empresa. Para el inversor, las acciones conllevan un riesgo elevado ya que sus cotizaciones fluctúan en función de la oferta y la demanda y no hay garantía de que éste recupere su capital cuando las venda. El poseedor de acciones también tiene derecho a votar en las juntas generales de la sociedad y a recibir dividendos en el caso de que se distribuyan, pero será el último en recibir dinero en caso de quiebra.',
    },
    {
        name: 'Activos Netos',
        definition: 'Es el patrimonio del fondo medido en millones de la divisa en la que está denominado el fondo (millones de euros para un fondo denominado en euros).',
    },
    {
        name: 'ADRs',
        definition: 'Los ADRs o American Depositary Receipts (ADRs) son títulos negociables detenidos por un banco estadounidense y representativos de un determinado número de acciones extranjeras. Las compañías que no son americanas pero que quieren cotizar en la bolsa estadounidense deben ofrecer ADRs.',
    },
    {
        name: 'Alfa',
        definition: 'El alfa es el rendimiento adicional obtenido por un fondo respecto a su índice de referencia o benchmark teniendo en cuenta la exposición de este fondo al riesgo de mercado (medido por la Beta).',
    },
    {
        name: 'American Stock Exchange (AMEX)',
        definition: 'El American Stock Exchange (o AMEX) es el segundo mayor mercado de los Estados Unidos. El AMEX se centra en valores de mediana y pequeña capitalización.',
    },
    {
        name: 'Amsterdam Exchange (AEX)',
        definition: 'El Amsterdam Exchange es la bolsa más importante de Holanda. Comprende el AEX-Effectenbeurs, el AEX-Optiebeurs y el AEX-Agrarische Termijnmarkt. El AEX forma ahora parte del Euronext junto con las bolsas de Paris y Bruselas.',
    },
    {
        name: 'Análisis de resultado',
        definition: 'El análisis del resultado obtenido por un determinado fondo es un proceso que se desarrolla en dos etapas. Primero, se compara la rentabilidad del fondo respecto a su índice de referencia. Luego, se analiza los métodos utilizados por los gestores para llegar a ese resultado.',
    },
    {
        name: 'Aportación a un plan de pensión',
        definition: 'Es la cantidad invertida por el partícipe en el plan de pensión.',
    },
    {
        name: 'Aportación extraordinaria',
        definition: 'En un plan de pensiones, el partícipe puede decidir hacer una aportación en el momento que desea y en la cuantía que desea, siempre que respete el mínimo establecido por el propio plan para este tipo de aportaciones.',
    },
    {
        name: 'Aportación mínima inicial',
        definition: 'La aportación mínima inicial es la cantidad mínima que debe invertir el partícipe para poder entrar en el fondo.',
    },
    {
        name: 'Aportación Mínima Mensual',
        definition: 'Es la aportación mínima que puede hacer un partícipe a un plan en caso de tener un plan de ahorro mensual.'
    },
    {
        name: 'Aportación periódica',
        definition: 'Al contratar un plan de pensiones, el partícipe puede comprometerse, a través del boletín de adhesión al plan, a realizar unas aportaciones con una determinada periodicidad (mensual, trimestral o anual). La inversión mínima periódica está establecida por el propio plan.',
    },
    {
        name: 'Asesor financiero',
        definition: 'Un asesor financiero es una persona o compañía que ofrece sus consejos y servicios de inversión cobrando por ello una comisión fija o variable.',
    },
    {
        name: 'Banco Central Europeo',
        definition: 'El Banco Central Europeo (BCE) fue fundado el 1 de junio de 1998 y tiene su sede en Francfort. Su objetivo primordial consiste en mantener la estabilidad de precios pero también es el responsable de fijar la política monetaria en toda la zona euro.',
    },
    {
        name: 'Banco de Inglaterra',
        definition: 'Fue fundado en 1694 y supervisa todo el sitema financiero en el Reino Unido. Empezó a establecer los tipos de interés (el más concoido es el tipo repo) en 1997.',
    },
    {
        name: 'BCE',
        definition: 'El Banco Central Europeo (BCE) fue fundado el 1 de junio de 1998 y tiene su sede en Francfort. Su objetivo primordial consiste en mantener la estabilidad de precios pero también es el responsable de fijar la política monetaria en toda la zona euro.',
    },
    {
        name: 'Benchmark',
        definition: 'El benchmark de un determinado fondo de inversión es el índice de referencia que se utiliza para comparar su comportamiento en un determinado periodo de tiempo. Por ejemplo para comparar la evolución de un fondo de acciones españolas se puede tomar el IBEX 35 como benchmark.',
    },
    {
        name: 'Beneficiario',
        definition: 'Es la persona que tiene derecho a percibir las prestaciones del plan una vez acaecida una de las contingencias del plan. Puede ser el propio partícipe del plan o cualquier otra persona física.',
    },
    {
        name: 'Beneficio por acción',
        definition: 'El benefico por acción (o Earnings per Share - EPS - en inglés) es el ratio entre los beneficios totales de una compañía y el número total de acciones de esa compañía.',
    },
    {
        name: 'Beta',
        definition: 'La beta de un fondo o de una acción es una medida de la sensibilidad del fondo o de la acción a los movimientos del mercado. La beta del mercado es igual a 1,00 por definición. Una beta de 1,10 significa que el fondo o la acción ha tenido, de media, una rentabilidad un 10% superior a la del mercado cuando éste subía, pero un 10% peor que el mercado cuando este bajaba. De la misma forma una beta de 0,85 indica que el fondo ha tenido un comportamiento un 15% peor que el mercado cuando este se movía al alza y un 15% mejor que el mercado cuando este se movía a la baja.',
    },
    {
        name: 'Bienes de consumo',
        definition: 'Porcentaje de la cartera invertido en empresas que fabrican productos alimentarios, bebidas, tabaco, productos de belleza e higiene, productos textiles, automóviles o electrodomésticos. Ejemplos: Nestlé y Altadis.',
    },
    {
        name: 'Bienes industriales',
        definition: 'Porcentaje de la cartera invertido en empresas del sector aeroespacial, de defensa, fabricantes de productos químicos, de máquinas industriales o materiales de construcción. Ejemplos: EADS, Boeing y DuPont.',
    },
    {
        name: 'Blue Chip.',
        definition: 'Un blue chip es un valor de gran capitalización bursátil y de gran liquidez',
    },
    {
        name: 'Boletín de adhesión al plan',
        definition: 'Es el documento que firma el partícipe al contratar un plan de pensiones, por el que se compromete a realizar una serie de aportaciones y en el que se designa el o los beneficiarios del plan.',
    },
    {
        name: 'Bolsa de Italia',
        definition: 'La Bolsa de Italia es el resultado de la unificación en 1991 de las 10 bolsas nacionales. Situada en Milán sus principales índices son el MIB, el MIBTEL y el MIB 30.',
    },
    {
        name: 'Bono basura',
        definition: 'Un bono basura (o "junk bond") es un bono que ofrece un cupón muy alto para compensar su mayor probabilidad de incumplimento. Entran dentro de la categoría de bonos de alto rendimiento y van destinado específicamente a inversores que quieran asumir un alto riesgo.',
    },
    {
        name: 'Bono cupón cero',
        definition: 'Un bono cupón cero es un bono que no paga cupones pero que se emite al descuento. Es decir, el inversor que paga supongamos 900 euros por un bono cupón cero a tres años, no recibirá ningún cupón durante esos tres años pero recibirá, por ejemplo, 1000 euros a vencimiento.',
    },
    {
        name: 'Bono de amortización anticipada',
        definition: 'Es un bono que como su nombre indica puede ser amortizado anticipadamente (es decir, antes del vencimiento previsto) a discreción del emisor. Permite a este último refinanciar su deuda a un tipo más bajo en caso de que los tipos de interés caigan por debajo del cupón del bono.',
    },
    {
        name: 'Bottom-up',
        definition: 'En una gestión bottom-up (o de abajo hacia arriba) el gestor analiza primero las empresas individualmente para luego extender su análisis a los diferentes sectores y finalmente al entorno macroeconómico general.',
    },
    {
        name: 'Broker',
        definition: 'Un broker es una firma o individuo que intermedia entre el comprador y el vendedor de un título, cobrando una comisión por la transacción efectuada. A diferencia de un broker-dealer, un broker no es propietario de los títulos que vende.',
    },
    {
        name: 'CAC 40',
        definition: 'El CAC 40 es el principal índice de la bolsa de Paris. Está compuesto por los 40 mayores valores franceses.',
    },
    {
        name: 'Calidad crediticia',
        definition: 'La calidad crediticia es la valoración de una obligación basada en sus condiciones financieras en términos de calidad (de su emisor) y seguridad.',
    },
    {
        name: 'Call',
        definition: 'Una opción es un contrato mediante el cual el inversor tiene el derecho (pero no la obligación) bien a comprar (opción call) bien a vender (opción put) un determinado activo financiero (como por ejemplo, acciones) a un precio y una fecha predeterminados.',
    },
    {
        name: 'Cambio de fondo',
        definition: 'El cambio de fondo se refiere al traslado de dinero de un fondo a otro. En España, desde el pasado 1 de enero 2003, los traspasos entre fondos y/o Sicav (siempre que éstas últimas tengan al menos 500 partícipes a nivel global) no tributan por las eventuales ganancias generadas.',
    },
    {
        name: 'CAPM',
        definition: 'El CAPM (o Capital Asset Pricing Model en inglés) es un modelo matemático utilizado para valorar un título en función de la relación entre el riesgo y la rentabilidad esperada. Este modelo es clave en la teoría de carteras en la que la rentabilidad esperada se expresa (E) como función de la rentabilidad esperada del mercado (Rm), de la rentabilidad del activo sin riesgo (R) y de la Beta. Más concretamente: E = R + (Beta) (Rm – R).',
    },
    {
        name: 'Cartera',
        definition: 'La cartera del fondo es el conjunto de activos (títulos de renta variable, renta fija,...) que posee el fondo.',
    },
    {
        name: 'Categoría Garantizados',
        definition: 'Se integran dentro de esta categoría los planes para los que existe una garantía externa de obtención de un determinado rendimiento.',
    },
    {
        name: 'Categoría Morningstar',
        definition: 'Los fondos se clasifican en función de su estilo de gestión actual y no simplemente en función de lo que diga el reglamento de gestión, ni tampoco en función del hecho de haber obtenido una determinada rentabilidad. Para que las categorías sean homogéneas, Morningstar clasifica los fondos en función de los activos que tienen en cartera. Se analizan varias carteras para asegurar que se toma en cuenta la política de inversión real del fondo.',
    },
    {
        name: 'Categoría Renta Fija Corto Plazo',
        definition: 'Se integran dentro de esta categoría los planes que no invierten en activos de renta variable y cuya duración media de la cartera sea inferior o igual a dos años.',
    },
    {
        name: 'Categoría Renta Variable',
        definition: 'Se integran dentro de esta categoría los planes que invierten más del 75% de su cartera en renta variable.',
    },
    {
        name: 'Categoría Renta Variable Mixta',
        definition: 'Se integran dentro de esta categoría los planes que invierten entre el 30% y el 75% de su cartera en renta variable.',
    },
    {
        name: 'Ciclo económico',
        definition: 'El ciclo económico consiste en la alternancia de periodos de expansión económica y de contracción, marcados por cambios en variables macroeconómicas como los tipos de interés, la tasa de paro o la productividad.',
    },
    {
        name: 'Clases de activo',
        definition: 'Las clases de activo corresponden a las grandes categorías de inversiones que tienen diferentes niveles de riesgo y rentabilidad como son las acciones, las obligaciones, los inmuebles o la liquidez. Las acciones son las que ofrecen el mejor potencial de crecimiento pero con un alto nivel de riesgo. Los bonos, por el contrario, ofrecen un determinado rendimiento con un grado de riesgo relativamente moderado. Los inmuebles ofrecen potencial de crecimiento pero con el inconveniente de la iliquidez. La liquidez, por último, presenta la rentabilidad esperada más baja pero con las ventajas de la disponibilidad y de la seguridad de la inversión.',
    },
    {
        name: 'Clasificación Inverco',
        definition: 'Inverco ha elaborado su propia clasificación para los fondos de inversión. Las categorías contempladas son las siguientes: FIAMM, FIM de renta fija a corto plazo, FIM de renta fija a largo plazo, FIM de renta fija mixta, FIM de renta variable mixta, FIM de renta variable nacional, FIAMM internacional, FIM de renta fija mixta internacional, FIM de renta variable mixta internacional, FIM de renta variable euro, FIM de renta variable internacional, FIM global, FIM de renta fija internacional, Garantizados de renta fija, Garantizados de renta variable y Fondos inmobiliarios.',
    },
    {
        name: 'CNMV',
        definition: 'La CNMV o Comisión Nacional del Mercado de Valores es la institución que regula los mercados financieros en España. Entre sus objetivos están el control y la supervisión de los fondos de inversión así como la protección de los inversores particulares.',
    },
    {
        name: 'Coeficiente de liquidez',
        definition: 'El coeficiente de liquidez de un fondo de inversión es la parte del patrimonio del fondo (un 3% como mínimo) que por ley debe estar bien en efectivo bien en títulos de renta fija a muy corto plazo y fácilmente realizables para poder hacer frente a los reembolsos de los partícipes.',
    },
    {
        name: 'Coeficiente reductor',
        definition: 'Es un coeficiente que aplica Hacienda para reducir fiscalmente las ganancias o pérdidas procedentes de la venta de participaciones de fondos compradas antes del 31 de diciembre de 1994. Esos porcentajes se incrementan en función de la antigüedad acumulada en el fondo. Para las participaciones compradas en el año 94 el coeficiente reductor es del 14,28%. Para las participaciones compradas en el año 93, el coeficiente es del 28,56% (2 x 14,28%); para las del año 92, 42,84% (3 x 14,28%) y así sucesivamente hasta un coeficiente reductor del 100% para las participaciones compradas en los años 88 o anteriores.',
    },
    {
        name: 'Comisión anual de gestión',
        definition: 'Es la comisión que cobra la entidad gestora por su trabajo. Aunque generalmente se expresa como un porcentaje anual sobre el patrimonio (algunos fondos también cobran en función de los resultados obtenidos o una combinación de ambos) se descuenta diariamente y automáticamente del valor liquidativo del fondo. La ley establece unas comisiones máximas para esta comisión anual de gestión que variarán en función del tipo de fondo. Para los FIM, por ejemplo, esa comisión máxima de gestión no podrá superar el 2,25% anual cuando se calcula únicamente en función del patrimonio del fondo. Cuando se calcula sólo en función de los resultados, la comisión de gestión no podrá superar el 18% de los mismos. Y cuando se utilizan ambas variables, esta comisión no podrá ser superior al 1,35% del patrimonio y el 9% de los resultados. En cuanto a los FIAMM, la comisión máxima de gestión es del 1% anual cuando se calcula únicamente en función del patrimonio del fondo; cuando se calcula únicamente en función de los resultados, es del 10% de los mismos; y cuando se utilizan ambas variables, la comisión máxima de gestión no podrá superar el 0,67% del patrimonio y el 3,33% de los resultados.',
    },
    {
        name: 'Comisión de cambio de fondo',
        definition: 'La comisión de cambio de fondo es la comisión que cobra la gestora por el traspaso de un fondo a otro dentro de una misma familia.',
    },
    {
        name: 'Comisión de compra-venta',
        definition: 'La comisión de compra-venta es la comisión que hay que pagar al broker por la compra o la venta de títulos en nuestro nombre. Varía en función del volumen de la orden y del servicio asociado a esta última.',
    },
    {
        name: 'Comisión de control',
        definition: 'Tanto el plan de pensiones como el fondo de pensiones tienen una comisión de control. Es un órgano que controla el buen funcionamiento del plan o del fondo y que está formado por representantes tanto de la entidad promotora como de los partícipes y beneficiarios.',
    },
    {
        name: 'Comisión de depósito',
        definition: 'La comisión de depósito es la comisión que cobra la entidad depositaria por su labor. Al igual que la comisión de gestión se expresa como un porcentaje anual sobre el patrimonio del fondo aunque se descuenta diariamente del valor liquidativo. Legalmente no puede superar el 0,2% anual sobre el patrimonio efectivo en el caso de los FIM y el 0,15% anual sobre el patrimonio custodiado en el caso de los FIAMM.',
    },
    {
        name: 'Comisión de gestión',
        definition: 'Es la comisión que cobra la entidad gestora por su trabajo. Aunque generalmente se expresa como un porcentaje anual sobre el patrimonio (algunos fondos también cobran en función de los resultados obtenidos o una combinación de ambos) se descuenta diariamente y automáticamente del valor liquidativo del fondo. La ley establece unas comisiones máximas para esta comisión anual de gestión que variarán en función del tipo de fondo. Para los FIM, por ejemplo, esa comisión máxima de gestión no podrá superar el 2,25% anual cuando se calcula únicamente en función del patrimonio del fondo. Cuando se calcula sólo en función de los resultados, la comisión de gestión no podrá superar el 18% de los mismos. Y cuando se utilizan ambas variables, esta comisión no podrá ser superior al 1,35% del patrimonio y el 9% de los resultados. En cuanto a los FIAMM, la comisión máxima de gestión es del 1% anual cuando se calcula únicamente en función del patrimonio del fondo; cuando se calcula únicamente en función de los resultados, es del 10% de los mismos; y cuando se utilizan ambas variables, la comisión máxima de gestión no podrá superar el 0,67% del patrimonio y el 3,33% de los resultados.',
    },
    {
        name: 'Comisión de reembolso',
        definition: 'Es una comisión que paga el inversor una sola vez en el momento de vender sus participaciones. No todos los fondos cobran esa comisión y generalmente disminuye en función del tiempo en que uno ha mantenido esas participaciones (cuanto mayor el tiempo de permanencia, menor la comisión de reembolso). En todo caso, la ley establece unos límites máximos para esa comisión. En el caso de los FIM, ese límite es del 5% sobre el precio de las participaciones mientras que para los FIAMM esta comisión no puede ser superior al 1% sobre el precio de las participaciones.',
    },
    {
        name: 'Comisión de suscripción',
        definition: 'La comisión de suscripción sólo se cobra al inversor cuando éste compra o vuelve a comprar participaciones del fondo. Esta comisión generalmente se cobra como un porcentaje sobre la cantidad invertida. Es importante saber que no todos los fondos cobran esa comisión de suscripción y que la ley ha establecido unos límites máximos que no se pueden superar. En el caso de los FIM, ese límite es del 5% sobre el precio de las participaciones y del 1% sobre el precio de las participaciones en el caso de los FIM.',
    },
    {
        name: 'Comisión Nacional del Mercado de Valores',
        definition: 'La CNMV o Comisión Nacional del Mercado de Valores es la institución que regula los mercados financieros en España. Entre sus objetivos están el control y la supervisión de los fondos de inversión así como la protección de los inversores particulares.',
    },
    {
        name: 'Comisión sobre resultados',
        definition: 'Algunos fondos cobran, en concepto de comisión de gestión, una parte fija y otra variable en función de los resultados obtenidos por el fondo.',
    },
    {
        name: 'Comprar y mantener',
        definition: 'Comprar y mantener ("Buy and hold" en inglés) es una estrategia que consiste en que el inversor compra un fondo (o cualquier otro título) y lo mantiene durante un periodo relativamente largo de tiempo. Es la estrategia opuesta a la del trading a corto plazo o "market timing".',
    },
    {
        name: 'Contingencias',
        definition: 'Las contingencias de un plan de pensiones son todas las situaciones que dan derecho a la percepción de las prestaciones del plan. Las principales contingencias contempladas por la ley son: la jubilación o situación asimilable (si el partícipe no tiene derecho a una pensión de jubilación podrá percibir las prestaciones del plan a partir de los 60 años); la invalidez laboral total para la profesión habitual o absoluta para todo trabajo; el fallecimiento del partícipe (en este caso será el beneficiario del plan el que reciba las prestaciones del plan); el paro de larga duración (superior a doce meses) y la enfermedad grave del partícipe.',
    },
    {
        name: 'Crecimiento',
        definition: 'Se utiliza el término crecimiento para aquellos fondos (llamados fondos de crecimiento o "growth fund") que invierten sus carteras en valores con perspectivas de fuerte crecimiento en sus beneficios o en sus ventas.',
    },
    {
        name: 'Cuartil',
        definition: 'El cuartil muestra la posición relativa del plan contra los otros planes de la misma categoría. El cuartil 1 significa que el plan está entre los 25% más altos de la categoría. El cuartil 4 significa que el plan esta entre los 25% más bajos de la categoría.',
    },
    {
        name: 'Cupón',
        definition: 'El cupón de un bono o de una obligación corresponde a la remuneración que recibe el inversor al comprar un bono o una obligación. En la mayoría de los casos, en nuestro país, el cupón se paga anualmente. En otros países, se pagan cupones de forma semianual.',
    },
    {
        name: 'Curva de tipos',
        definition: 'La curva de tipos describe la relación que existe entre el vencimiento de un bono y su rendimiento. Se aplica habitualmente a los bonos del Estado. Generalmente la curva de tipos tiene una pendiente positiva (mayor rendimiento para las obligaciones de vencimiento más alejado) pero también se pueden observar otras situaciones (curva de tipos invertida, en forma de U,...).',
    },
    {
        name: 'Código DGS del Fondo',
        definition: 'Es el código con el que el fondo asociado al plan de pensiones está inscrito en la Dirección General de Seguros.',
    },
    {
        name: 'Código DGS del Plan',
        definition: 'Es el código con el que el plan de pensiones está inscrito en la Dirección General de Seguros.',
    },
    {
        name: 'Datos a',
        definition: 'Datos a... indica la fecha de la cartera que tenemos en nuestra base de datos. Una de las actividades de Morningstar es, en efecto, la de recolectar, validar y almacenar las carteras completas de los fondos de inversión para luego analizarlas.',
    },
    {
        name: 'DAX',
        definition: 'El DAX o DAX XETRA es el principal índice de la bolsa alemana. Incorpora los 30 mayores valores alemanes.',
    },
    {
        name: 'Depositario',
        definition: 'Es la entidad financiera (banco o caja de ahorros) encargada de la custodia de los activos del fondo así como de las suscripciones y reembolsos de las participaciones. También tiene como obligación la de supervisar y vigilar la actuación de la entidad gestora.',
    },
    {
        name: 'Depreciación',
        definition: 'La depreciación de una moneda corresponde a una caída de su valor frente a otra moneda. Por ejemplo, si la semana pasada podíamos cambiar 1 euro contra 0,95 dólar y esta semana un euro vale 0,90 dólar, entonces el valor del euro se ha depreciado frente al dólar porque podemos adquirir menos dólares por la misma cantidad de euros.',
    },
    {
        name: 'Derecho de separación',
        definition: 'El derecho de separación es el derecho que tiene todo partícipe de salirse del fondo sin pagar comisión de reembolso alguna en caso de modificación significativa del reglamento o folleto explicativo del fondo (por ejemplo: modificación de la política de inversión, sustitución de la entidad gestora o depositaria, modificación de las comisiones,...). El plazo para poder ejercer ese derecho de separación es de un mes desde la fecha de la notificación obligatoria a todos los partícipes y el valor liquidativo que se aplica en este caso es el que corresponda a la fecha de aprobación de esta modificación por la CNMV.',
    },
    {
        name: 'Derechos consolidados',
        definition: 'Se designa por derechos consolidados la suma de las aportaciones realizadas por el partícipe y del rendimiento, positivo o negativo, obtenido por el fondo.',
    },
    {
        name: 'Derivado',
        definition: 'Derivado es el término genérico para designar a aquellos títulos cuyo precio está basado en un activo subyacente. Ejemplos de derivados son los futuros, los warrants, los swaps y las opciones.',
    },
    {
        name: 'Descuento sobre el valor liquidativo',
        definition: 'Cuando un fondo (cerrado) cotiza con un descuento sobre su valor liquidativo, ésto significa que el valor total de los títulos presentes en el fondo (valor liquidativo) es superior a la cotización del fondo en cuestión. Este descuento se expresa generalmente en porcentaje.',
    },
    {
        name: 'Desviación típica',
        definition: 'La desviación típica de un fondo es una medida del riesgo del fondo. Indica cómo, en términos medios, la rentabilidad del fondo se ha desviado de su media. Una desviación típica alta significa que la rentabilidad del fondo ha experimentado fuertes variaciones mientras que una baja indica que la rentabilidad del fondo ha sido mucho más estable. Muchas veces se utiliza el término volatilidad para designar a la desviación típica. La desviación típica de un fondo es un dato interesante en dos sentidos. En primer lugar, porque cuanto mayores fluctuaciones presenta la rentabilidad de un fondo, más arriesgado será el fondo en cuestión. Además, permite comparar todos los fondos entre ellos, desde los fondos monetarios hasta los fondos de acciones emergentes. En segundo lugar, porque los fondos que han sido más volátiles en el pasado también tienen tendencia a ser los más volátiles en el futuro. En este sentido la desviación típica es una señal de alarma muy útil. La desviación típica se expresa, como la rentabilidad, en porcentaje. La calculamos basándonos en las rentabilidades de los últimos 36 meses (o 3 años).',
    },
    {
        name: 'Devaluación',
        definition: 'La devaluación de una divisa, en un sistema de cambios fijos, corresponde a una caída en el valor de esta divisa en comparación con otra, caída generalmente consecuencia de una decisión de la autoridad monetaria correspondiente.',
    },
    {
        name: 'Distribución de activos',
        definition: 'La distribución de activos es la repartición de la cartera del fondo en las distintas categorías de activos financieros (acciones, obligaciones, efectivo, ...) y activos tangibles (inmuebles, materias primas, metales preciosos, ...). Incluye el impacto económico de posiciones en derivados del fondo.',
    },
    {
        name: 'Distribuidor',
        definition: 'El distribuidor es la compañía responsable de la venta y marketing de los fondos a los inversores. También tiene la responsabilidad de enviar la documentación, como folletos e informes trimestrales, a los partícipes.',
    },
    {
        name: 'Diversificación',
        definition: 'La diversificación es la estrategia que consiste en distribuir sus inversiones entre varios tipos de activos (acciones, obligaciones, activos monetarios,...) con el fin de reducir el riesgo total de su cartera.',
    },
    {
        name: 'Dividendo',
        definition: 'El dividendo es la remuneración que otorga una empresa a sus accionistas por haber invertido en ella. No todas las compañías reparten dividendo y las que lo hacen pueden hacerlo en un único pago al año o en varios.',
    },
    {
        name: 'Domicilio',
        definition: 'Es el país de residencia de una determinada persona, fondo o gestora. Puede tener implicaciones fiscales importantes.',
    },
    {
        name: 'Dow Jones Industrial Average',
        definition: 'El Dow Jones (o más exactamente el Dow Jones Industrial Average) es un índice de la bolsa de Nueva York creado en 1896 y que comprende 30 empresas americanas de gran capitalización y liquidez.',
    },
    {
        name: 'Duración',
        definition: 'La duración de un título de renta fija es, simplificando bastante, una medida (expresada en años) del tiempo medio que le queda hasta su vencimiento. También sirve para medir el impacto que tiene una variación de los tipos de interés sobre su precio.',
    },
    {
        name: 'Efectivo',
        definition: 'Esta categoría de activos incluye la liquidez, así como activos monetarios (letras del tesoro, etc.)',
    },
    {
        name: 'Ejercicio fiscal',
        definition: 'El ejercicio fiscal corresponde al periodo de tiempo (generalmente 12 meses) en el que una compañía publica su balance y cuenta de resultados. No tiene necesariamente que coincidir con el año natural.',
    },
    {
        name: 'Energía',
        definition: 'Porcentaje de la cartera invertido en empresas que producen o se dedican al refino del petróleo y del gas, proveedoras de equipamientos para la exploración, operadores de oleoductos y gasoductos. Ejemplos: Repsol YPF y Total Fina Elf.',
    },
    {
        name: 'EPSV',
        definition: 'Las EPSV o entidades de protección social voluntarias son productos de ahorro, con las mismas ventajas fiscales que los planes de pensiones, pero que sólo pueden ser contratados por residentes del País vasco. Una de las grandes diferencias respecto a los planes de pensiones es que el partícipe puede rescatar sus ahorros pasados 10 años, independientemente de si éste se ha jubilado o no.',
    },
    {
        name: 'Estado de posición',
        definition: 'Es un documento que recibe el partícipe con cierta periodicidad (mensual en el caso de los FIAMM y trimestral en el de los FIM) y que recoge el valor liquidativo del fondo a la fecha en que se emite, el número de participaciones que posee el partícipe así como el detalle de las operaciones (suscripciones o reembolsos) realizadas durante el periodo contemplado.',
    },
    {
        name: 'Estructura de capital',
        definition: 'La estructura de capital se refiere a la forma en que una compañía está financiada por los inversores. La elección básica se sitúa entre financiar la empresa vía capital (acciones) o vía deuda (obligaciones). El ratio deuda-capital es una de las medidas más utilizadas para definir la estructura de capital de una determinada empresa.',
    },
    {
        name: 'ETF (Exchange-Traded Fund)',
        definition: 'Un EFT (Exchange Traded Fund) o Fondo cotizado en bolsa es un instrumento financiero híbrido entre un fondo de inversión y una acción. Al igual que las acciones, cotizan en una bolsa de valores y, por lo tanto, su precio varía a lo largo del día pero su filosofía es muy parecida a la de un fondo índice ya que su objetivo es simplemente reproducir la composición de algún índice en concreto.',
    },
    {
        name: 'Eurobono',
        definition: 'Se denomina por eurobono a un título emitido fuera de un determinado país pero denominado en la divisa de este país (por ejemplo eurobonos denominados en yenes japoneses emitidos fuera de Japón).',
    },
    {
        name: 'Euronext',
        definition: 'Euronext es el resultado de la fusión de las bolsas de Paris, Ámsterdam y Bruselas.',
    },
    {
        name: 'Ex-Dividendo / Ex-Cupón',
        definition: 'La palabra ex-dividendo o ex-coupon se refiere a un título vendido sin el derecho a cobrar el dividendo o el cupón. Los títulos ex-dividendo se venden generalmente en el periodo comprendido entre el anuncio y el pago del dividendo.',
    },
    {
        name: 'Extensión del Track Record',
        definition: 'Una clase de un fondo puede tener una rentabilidad anterior a su fecha de inicio cuando pensamos que hay un beneficio para el inversor particular al permitir una extensión del historial de rentabilidad para esa clase utilizando la rentabilidad de una clase más antigua o la rentabilidad de un fondo predecesor, con limitaciones específicas sobre cuándo y cómo se hará la extensión. Estos historiales de extensión de rentabilidad servirán como base para calcular la rentabilidad y las comparativas con el peer group. En todo caso Morningstar sólo permitirá esta extensión a una clase cuando haya una continuidad significativa con la clase más antigua. Si quiere más información sobre la política de Morningstar pinche aquí',
    },
    {
        name: 'Familia de fondos',
        definition: 'Una familia de fondos es un grupo de fondos con distintas estrategias de inversión pero pertenecientes a la misma gestora. Una familia de fondos permite al partícipe pasar de un fondo a otro sin coste o con un coste reducido.',
    },
    {
        name: 'Fecha',
        definition: 'Corresponde a la fecha del último valor liquidativo disponible.',
    },
    {
        name: 'Fecha de creación',
        definition: 'Corresponde a la fecha de lanzamiento del fondo.',
    },
    {
        name: 'Federal Funds Rate',
        definition: 'Se designa con este término a los tipos de interés a corto plazo en Estados Unidos. Establecidos por el FOMC, la tasa de fondos federales americanos determina el precio al que los bancos americanos se prestan dinero entre sí.',
    },
    {
        name: 'Federal Open Market Committee (FOMC)',
        definition: 'El FOMC (o Federal Open Market Committe) es el Comité de Mercado Abierto de la Reserva Federal norteamericana. Es el que controla la política monetaria estadounidense y establece los rangos de crecimiento de los agregados monetarios.',
    },
    {
        name: 'FIAMM',
        definition: 'Fondo de Inversión en Activos del Mercado Monetario. También conocido como fondo monetario o fondo de dinero. Es una categoría de fondos de inversión que debe tener invertido al menos un 90% de su cartera en renta fija a corto plazo (vencimiento no superior a 18 meses).',
    },
    {
        name: 'FII',
        definition: 'Fondo de Inversión Inmobiliario. Es una categoría de fondos de inversión que invierte su patrimonio en inmuebles ya sean viviendas, oficinas, garajes,... Su rentabilidad procede tanto de la venta de esos inmuebles como del cobro de los alquileres.',
    },
    {
        name: 'FIM',
        definition: 'Fondo de Inversión Mobiliaria. Categoría de fondos de inversión que debe tener al menos el 80% de su patrimonio invertido en valores de renta fija o de renta variable admitidos a negociación en una bolsa de valores.',
    },
    {
        name: 'FIME',
        definition: 'Fondo de Inversión Mobiliaria Especializado en valores no negociados. Es una categoría de fondos de inversión que invierte entre un 50% y 80% de su activo en valores no negociados en mercados secundarios.',
    },
    {
        name: 'FIMF',
        definition: 'Fondo de Inversión Mobiliaria de Fondos. También conocido como fondo de fondos. Es una categoría de fondos de inversión que invierte al menos un 50% de su activo en participaciones de otros fondos de inversión.',
    },
    {
        name: 'FIMP',
        definition: 'Fondo de Inversión Mobiliaria Principal. Es una categoría de fondos de inversión que se caracteriza por tener como partícipes a otros fondos de inversión.',
    },
    {
        name: 'FIMS',
        definition: 'Fondo de Inversión Mobiliaria Subordinado. Es una categoría de fondos de inversión que invierte al menos un 80% de su cartera en participaciones del FIMP designado en su folleto informativo.',
    },
    {
        name: 'Financial Services Authority (FSA)',
        definition: 'El Financial Services Authority (FSA) es la entidad reguladora de la City en Londres y, como tal, la responsable de la protección de los intereses de los inversores en el Reino Unido.',
    },
    {
        name: 'Flagship Fund',
        definition: 'Generalmente se denomina como "flagship fund" (fondo de referencia en inglés) al fondo más antiguo de una gestora o aquél que tiene el mayor patrimonio. Estos fondos suelen llevar el mismo nombre que la gestora.',
    },
    {
        name: 'Folleto explicativo',
        definition: 'Como dice la ley, el folleto explicativo "recoge la información necesaria para que el inversor pueda formular un juicio fundado sobre la inversión que se le propone". Este folleto debe ser registrado en la CNMV dónde puede ser consultado. Existen en realidad dos versiones para los folletos explicativos: el folleto completo y el folleto simplificado (que es simplemente una parte de el folleto completo). En ellos podemos encontrar las siguientes informaciones: datos identificativos el fondo, características generales del fondo, política de inversión y comisiones aplicadas.',
    },
    {
        name: 'Fondo mixto',
        definition: 'Un fondo mixto es un fondo que invierte parte de su cartera en acciones y parte en renta fija.',
    },
    {
        name: 'Fondo abierto',
        definition: 'A diferencia de un fondo cerrado, un fondo abierto no tiene un número fijo de participaciones o acciones. El partícipe puede por lo tanto entrar y salir del fondo comprando y vendiendo el número de participaciones deseado al valor liquidativo del día correspondiente.',
    },
    {
        name: 'Fondo Asociado',
        definition: 'Cada Plan de Pensiones tiene asociado un Fondo de Pensiones, aunque varios planes pueden tener el mismo fondo asociado.',
    },
    {
        name: 'Fondo cerrado',
        definition: 'Los fondos cerrados son fondos que se subdividen en un número fijo de acciones que cotizan en bolsa. El precio de las acciones vendrá determinado por la oferta y la demanda y no por su valor liquidativo. Puede por lo tanto existir un descuento o una prima entre la cotización y el valor liquidativo del fondo.',
    },
    {
        name: 'Fondo de acciones',
        definition: 'Un fondo de acciones o de renta variable es un fondo que invierte su cartera en acciones.',
    },
    {
        name: 'Fondo de acumulación',
        definition: 'Un fondo de acumulación es un fondo que no reparte dividendos a los partícipes. La inmensa mayoría de fondos domiciliados en nuestro país responden a esta definición.',
    },
    {
        name: 'Fondo de bonos de alto rendimiento',
        definition: 'Un fondo de bonos de alto rendimiento o "high yield" es un fondo de renta fija que invierte en títulos (bonos y obligaciones) que tienen un rating más bajo que el de los títulos de mayor solvencia. Por eso ofrecen un rendimiento más elevado pero el riesgo es también mayor.',
    },
    {
        name: 'Fondo de dinero',
        definition: 'Fondo de Inversión en Activos del Mercado Monetario. También conocido como fondo monetario o fondo de dinero. Es una categoría de fondos de inversión que debe tener invertido al menos un 90% de su cartera en renta fija a corto plazo (vencimiento no superior a 18 meses).',
    },
    {
        name: 'Fondo de distribución',
        definition: 'Contrariamente al fondo de acumulación, el fondo de distribución (o de reparto) distribuye dividendos a sus partícipes. Son muy pocos los fondos comercializados en nuestro país que responden a esta definición.',
    },
    {
        name: 'Fondo de fondos',
        definition: 'Un fondo de fondos (o FIMF) es un fondo que invierte al menos un 50% de su activo en participaciones de otros fondos.',
    },
    {
        name: 'Fondo de inversión',
        definition: 'Un fondo de inversión es un instrumento de ahorro que reúne el dinero de muchos partícipes para invertirlo en una serie de activos (acciones, renta fija, activos monetarios,...) en función de la política de gestión que tenga establecida la entidad gestora.',
    },
    {
        name: 'Fondo de pensiones',
        definition: 'El fondo de pensiones es el patrimonio conjunto formado por las aportaciones de todos los participes así como el rendimiento que la entidad gestora haya podido obtener con él, invirtiéndolo en activos financieros (renta fija, renta variable, liquidez,...). Cada plan de pensiones se integra en un fondo de pensiones. Para cada plan hay uno y un solo fondo de pensiones, pero existe la posibilidad de que varios planes de pensiones estén adscritos al mismo fondo.',
    },
    {
        name: 'Fondo de renta fija',
        definition: 'Un fondo de renta fija es un fondo que invierte en bonos y obligaciones y cuyo valor liquidativo está inversamente relacionado con los tipos de interés. Una subida de tipos de interés provoca una caída del valor liquidativo y una bajada de tipos de interés una subida del valor liquidativo del fondo.',
    },
    {
        name: 'Fondo de renta variable',
        definition: 'Un fondo de acciones o de renta variable es un fondo que invierte su cartera en acciones.',
    },
    {
        name: 'Fondo de reparto',
        definition: 'Contrariamente al fondo de acumulación, el fondo de distribución (o de reparto) distribuye dividendos a sus partícipes. Son muy pocos los fondos comercializados en nuestro país que responden a esta definición.',
    },
    {
        name: 'Fondo de valor',
        definition: 'Se utiliza el término valor para designar a un fondo (fondo de valor o "value fund") que invierte su cartera en acciones “baratas” desde el punto de vista de sus ratios PER o P/CF.',
    },
    {
        name: 'Fondo garantizado',
        definition: 'Es una clase particular de fondo FIM que tiene asociada una carta de garantía que asegura al partícipe bien una determinada rentabilidad (que puede estar referenciada a uno o varios índices bursátiles) bien un determinado valor liquidativo al final del periodo de garantía. El partícipe recibirá la garantía estipulada siempre y cuando suscriba el fondo durante un determinado plazo de suscripción y mantenga las participaciones hasta el vencimiento de la garantía.',
    },
    {
        name: 'Fondo garantizado de renta fija',
        definition: 'Es un fondo garantizado que asegura al partícipe una determinada rentabilidad o un determinado valor liquidativo al final de un plazo preestablecido.',
    },
    {
        name: 'Fondo garantizado de renta variable',
        definition: 'Es un fondo garantizado cuya garantía está referenciada al comportamiento de uno o varios índices bursátiles durante un determinado periodo de tiempo.',
    },
    {
        name: 'Fondo global',
        definition: 'Un fondo global es un fondo con una política de gestión muy flexible ya que puede invietir su cartera tanto en acciones como en obligaciones tanto nacionales como extranjeras.',
    },
    {
        name: 'Fondo High Yield',
        definition: 'Un fondo de bonos de alto rendimiento o "high yield" es un fondo de renta fija que invierte en títulos (bonos y obligaciones) que tienen un rating más bajo que el de los títulos de mayor solvencia. Por eso ofrecen un rendimiento más elevado pero el riesgo es también mayor.',
    },
    {
        name: 'Fondo Inmobiliario',
        definition: 'Fondo de Inversión Inmobiliario. Es una categoría de fondos de inversión que invierte su patrimonio en inmuebles ya sean viviendas, oficinas, garajes,... Su rentabilidad procede tanto de la venta de esos inmuebles como del cobro de los alquileres.',
    },
    {
        name: 'Fondo mixto',
        definition: 'Un fondo mixto invierte tanto en renta fija como en renta variable.',
    },
    {
        name: 'Fondo mixto de acciones',
        definition: 'Es un fondo mixto que tiene más peso en acciones que en renta fija en su cartera. También recibe el nombre de fondo mixto de renta variable.',
    },
    {
        name: 'Fondo mixto de renta fija',
        definition: 'Es un fondo mixto con más peso en renta fija que en acciones.',
    },
    {
        name: 'Fondo monetario',
        definition: 'Fondo de Inversión en Activos del Mercado Monetario. También conocido como fondo monetario o fondo de dinero. Es una categoría de fondos de inversión que debe tener invertido al menos un 90% de su cartera en renta fija a corto plazo (vencimiento no superior a 18 meses).',
    },
    {
        name: 'Fondo paraguas',
        definition: 'Un fondo paraguas es un fondo compuesto por varios sub-fondos, cada uno de ellos con su política de inversión propia y que permite a los inversores moverse de un sub-fondo a otro a un coste reducido.',
    },
    {
        name: 'Fondo regulado',
        definition: 'Un fondo regulado es un fondo que ha recibido la autorización por parte de la CNMV (Comisión Nacional del Mercado de Valores) para poder ser distribuido en nuestro país.',
    },
    {
        name: 'Fondo sectorial',
        definition: 'Un fondo sectorial es un fondo que invierte su cartera en acciones de un determinado sector (farmacéutico, bancario, tecnológico, etc).',
    },
    {
        name: 'Fondo tracker',
        definition: 'Los fondos tracker son fondos que intentan emular el comportamiento de un determinado índice. También se conocen como fondos índice.',
    },
    {
        name: 'Fondo ético',
        definition: 'Un fondo ético es un fondo que incluye algún compromiso ético en su política de gestión, compromiso que puede traducirse bien en la no incorporación en su cartera de determinadas empresas o sectores (por ejemplo empresas de armamento, tabaqueras, ....) o en dirigir sus inversiones hacia determinadas empresas o sectores especialmente sensibilizados con algún tema (como por ejemplo la protección del medio ambiente).',
    },
    {
        name: 'Fondo índice',
        definition: 'Un fondo índice es un fondo que tiene como objetivo a replicar un determinado índice bursátil ajustando el peso de cada valor en su cartera con el peso relativo que tiene en el índice en cuestión. Por ley estos fondos pueden invertir hasta un 35% de su cartera en un mismo valor.',
    },
    {
        name: 'FT 30',
        definition: 'El FT 30, también conocido como el FTSE Ordinary Share Index, data de 1935 y está basado en el valor de las 30 mayores compañías del Reino Unido.',
    },
    {
        name: 'FTSE',
        definition: 'FTSE, también pronunciado "Footsie", recibe el nombre del Financial Times (FT) y del London Stock Exchange (SE), que son sus copropietarios. La familia de los FTSE incluye a muchos índices como el FTSE 100, 200 y el techMARK, pero el más conocido es el FTSE 100. Generalmente se habla del FTSE haciendo referencia al FTSE 100.',
    },
    {
        name: 'FTSE All Share Index',
        definition: 'El FTSE All Share Index contiene las 900 mayores empresas cotizadas en el London Stock Exchange.',
    },
    {
        name: 'Futuro',
        definition: 'Un futuro es un producto financiero derivado mediante el cual el inversor se compromete bien a comprar bien a vender un determinado activo financiero (índice bursátil, acciones,...) a un precio y una fecha predeterminados.',
    },
    {
        name: 'G7 (Grupo de los 7)',
        definition: 'El G7 es el grupo de los 7 países industriales más importantes del mundo: Estados Unidos, Japón, Gran Bretaña, Alemania, Francia, Italia y Canadá. De particular importancia son las reuniones de los ministros de economía y finanzas de este grupo.',
    },
    {
        name: 'G8',
        definition: 'Es el G7 más Rusia.',
    },
    {
        name: 'GDR (Global Depositary Receipt)',
        definition: 'Un GDR es comparable a un ADR. Es un título negociable representativo de un determinado número de acciones extranjeras.',
    },
    {
        name: 'Gestión activa',
        definition: 'Se habla de gestión es activa cuando el gestor utiliza su propio criterio para determinar los títulos a incluir en la cartera del fondo en vez de seguir un determinado índice o benchmark.',
    },
    {
        name: 'Gestión de activos',
        definition: 'La gestión de activos se refiere a la gestión de una cartera compuesta por determinadas clases de activo como acciones, obligaciones o liquidez.',
    },
    {
        name: 'Gestión pasiva',
        definition: 'Una gestión es pasiva cuando el gestor pretende simplemente seguir el comportamiento de un determinado índice. Diseñará por lo tanto la cartera del fondo imitando la composición de ese índice o benchmark.',
    },
    {
        name: 'Gestor',
        definition: 'El gestor o equipo gestor es la persona o equipo contratado por la entidad gestora y directamente responsable de la gestión de la cartera del fondo.',
    },
    {
        name: 'Gestora',
        definition: 'La gestora es la entidad que se encarga de llevar a cabo la política de inversión del fondo. Por su trabajo cobra una comisión llamada comisión de gestión que se descuenta diariamente del valor liquidativo del fondo.',
    },
    {
        name: 'Growth & income fund',
        definition: 'Un "growth and income fund" es un fondo donde el gestor intenta elegir inversiones para obtener tanto rentabilidad por dividendo como plusvalías a largo plazo.',
    },
    {
        name: 'Growth Fund',
        definition: 'Se utiliza el término crecimiento para aquellos fondos (llamados fondos de crecimiento o "growth fund") que invierten sus carteras en valores con perspectivas de fuerte crecimiento en sus beneficios o en sus ventas.',
    },
    {
        name: 'Hang Seng',
        definition: 'El Hang Seng es el índice de los 33 mayores valores de la bolsa de Hong Kong.',
    },
    {
        name: 'Hedge fund',
        definition: 'Un "hedge fund" es un fondo de inversión cuyo objetivo es obtener la mayor rentabilidad posible utilizando todas las posibilidades de inversión al alcance del gestor (como pueden ser los instrumentos derivados) pero sin límite alguno. En general se considera que los hedge funds son fondos bastante complejos y en principio más adecuados para inversores profesionales o con mucho dinero. En nuestro país la legislación actual no permite la comercialización de estos fondos. Están normalmente domiciliados en paraísos fiscales.',
    },
    {
        name: 'IBEX 35',
        definition: 'Índice selectivo de la bolsa de Madrid que reúne a los 35 valores españoles de mayor capitalización.',
    },
    {
        name: 'Impuesto sobre plusvalía',
        definition: 'En España las ganancias provenientes de fondos de inversión tributan al tipo marginal si la antigüedad acumulada por las participaciones es inferior a un año y al tipo fijo del 18% si la antigüedad es superior a un año.',
    },
    {
        name: 'Income ratio',
        definition: 'El rendimiento del fondo se calcula dividiendo la suma de rendimientos (dividendos en el caso de fondos de acciones o cupones en el caso de los fondos de renta fija) netos (de gastos) por el valor liquidativo medio del fondo. ofrecidos por el fondo por The income ratio refers to the percentage of current income earned per share. It is calculated by dividing the fund\'s net investment income by its average NAV. (The net investment income is the total income of the fund, less operating expenses.) The income ratio indicates how much of the fund\'s total return comes from income. A high income ratio suggests that the underlying fund depends on dividend distributions or coupon payments to fill out its total return while a low income ratio suggests that capital appreciation is very likely responsible for the fund\'s total return.',
    },
    {
        name: 'Indicadores económicos adelantados',
        definition: 'Los indicadores económicos adelantados son series económicas que indican el movimiento futuro de la economía. Ejemplos de indicadores económicos adelantados son las horas de trabajo por semana en la industria, los precios de las materias primas o de los bienes intermedios o los permisos de construcción de nuevas viviendas.',
    },
    {
        name: 'Indicadores económicos retrasados',
        definition: 'Son indicadores económicos que siguen la evolución de la actividad económica. Un ejemplo de indicador económico retrasado en una economía que está empezando a crecer podría ser una disminución del plazo medio de desempleo, mientras que en una economía que está empezando a decrecer sería un alargamiento del tiempo de desempleo.',
    },
    {
        name: 'Índice',
        definition: 'Un índice es un conjunto de títulos cuidadosamente elegidos (generalmente en función de su capitalización bursátil) para representar a un determinado mercado (región, país o sector) como, por ejemplo, el IBEX 35 para la bolsa española. Muchos fondos utilizan un índice de referencia o benchmark para medir su comportamiento relativo.',
    },
    {
        name: 'Inflación',
        definition: 'La tasa de inflación mide el aumento de los precios en un determinado periodo de tiempo. En España se utiliza el IPC (Índice de Precios al Consumo, índice representativo de la cesta de la compra) para medir esa tasa de inflación.',
    },
    {
        name: 'Informe trimestral',
        definition: 'Es un documento que elabora la entidad gestora cada trimestre y que se entrega de forma gratuita a todos los partícipes del fondo. Existen dos versiones de informes trimestrales: el completo y el simplificado. En ellos podemos encontrar datos generales sobre el fondo, la gestora y depositario, el patrimonio del fondo, su valor liquidativo o sus comisiones, así como información sobre su cartera y un comentario sobre el comportamiento del fondo.',
    },
    {
        name: 'Infraponderar',
        definition: 'Se utiliza el término infraponderar para indicar que un gestor o un fondo dedica a un determinado valor o a un determinado sector de su cartera un peso inferior al que tiene en el benchmark o índice de referencia. Por ejemplo si un fondo de acciones españolas, cuyo índice de referencia es el IBEX 35, sólo tiene invertido un 5% de su cartera en Telefónica mientras que ese mismo valor tiene un peso de más del 25% en el IBEX 35, entonces diríamos que el gestor ha infraponderado Telefónica en su cartera. (nota: este razonamiento se ha hecho sin considerar las eventuales posiciones en derivados que pueda tener ese fondo en ese valor).',
    },
    {
        name: 'Institución de inversión colectiva',
        definition: 'Es el término genérico utilizado para designar tanto a los fondos de inversión como a las sociedades de inversión SIM (Sociedad de Inversión Mobiliaria) y SIMCAV (Sociedad de Inversión Mobiliaria de Capital Variable).',
    },
    {
        name: 'International Securities Identification Number (ISIN)',
        definition: 'El ISIN es un código de identificación internacional, único para cada título o fondo.',
    },
    {
        name: 'Inverco',
        definition: 'Inverco es la institución que reagrupa y defiende los intereses de las entidades gestoras de fondos de inversión y planes de pensiones.',
    },
    {
        name: 'Inversor institucional',
        definition: 'Es un término genérico que se utiliza para designar a una organización que invierte grandes cantidades de dinero (propio o ajeno) en títulos y fondos. Ejemplos de inversores institucionales son los propios bancos, los fondos de pensiones, las compañías de seguro o las sociedades de inversión.',
    },
    {
        name: 'IPC (Indice de Precios al Consumo)',
        definition: 'El IPC o Índice de Precios al Consumo es un índice representativo de la cesta de la compra en España y es la referencia para medir la tasa de inflación.',
    },
    {
        name: 'IPO',
        definition: 'IPO son las siglas inglesas de "Initial Public Offering" y designa la salida a bolsa de una empresa.',
    },
    {
        name: 'La City',
        definition: 'La City es el barrio financiero de Londres.',
    },
    {
        name: 'Letras del Tesoro',
        definition: 'Las letras del Tesoro son títulos de renta fija a corto plazo (las hay a 6 meses, un año y 18 meses) emitidos al descuento por el Estado Español.',
    },
    {
        name: 'Liquidez',
        definition: 'La palabra liquidez tiene dos acepciones. Cuando hablamos de la liquidez de un fondo de inversión, nos referimos a la parte de la cartera invertida en renta fija a muy corto plazo (repos, cuentas corrientes, depósitos a corto plazo, letras del Tesoro,...). Cuando hablamos de liquidez de un determinado valor o título, nos referimos a la facilidad de comprar y vender ese valor o título sin provocar distorsiones en su precio.',
    },
    {
        name: 'London Interbank Offered Rate (LIBOR)',
        definition: 'El LIBOR (o London Interbank Offered Rate) es el tipo de interés, en el mercado de Londres, al que se prestan dinero los bancos entre sí. En España hablaríamos de MIBOR (Madrid Interbank Offered Rate) o de EURIBOR (para la zona euro), tipos que sirven de referencia para muchos préstamos hipotecarios.',
    },
    {
        name: 'London Stock Exchange (LSE)',
        definition: 'La Bolsa de Londres o London Stock Exchange es el resultado de la fusión en 1973 de seis bolsas regionales de Gran Bretaña e Irlanda. El índice más conocido del LSE es el FTSE 100.',
    },
    {
        name: 'Límite de aportación',
        definition: 'Los partícipes pueden deducirse de su base imponible las cantidades anules aportadas a su plan de pensiones, pero hasta un cierto límite que dependerá de su edad. Para los partícipes menores de 52 años, el límite máximo de deducción será de 8.000 euros. Para los partícipes mayores de 52 años, el límite anterior se incrementará en 1.250 euros por cada año que exceda de 52, hasta un tope máximo de 24.250 euros para partícipes de 65 años o más. Así, un partícipe de 58 años podrá deducirse un máximo de 15.500 euros en su declaración de la renta.',
    },
    {
        name: 'Market Timing',
        definition: 'El "Market Timing" es una estrategia de inversión en la que el inversor compra y vende acciones o fondos de inversión con el objetivo de aprovecharse de los movimientos del mercado anticipados (o así cree el inversor) por algún que otro indicador econmómico o técnico.',
    },
    {
        name: 'Media',
        definition: 'La línea "Media" muestra la media de los diferentes datos de los fondos que responden a los criterios elegidos. Los datos incluyen las comisiones de gestión, rentabilidades a 1 día, 1 año, 3 años, etc, los Ratings Morningstar, los detalles del Style Box y las desviaciones estándar. Se puede realizar la búsqueda por gestora o por sectores o una combinación de los dos criterios. Es importante recalcar que aunque sólo se muestren los 300 primeros fondos, la media se calcula sobre todos los fondos que responden a los criterios de búsqueda.',
    },
    {
        name: 'Mediana',
        definition: 'En una serie, la mediana es el valor que se encuentra en la mitad de la serie. Dicho de otra forma es el percentil 50. Por ejemplo, en la serie 1,4, 7, 8, 10, 11, 23, 45 y 52, la mediana es 10.',
    },
    {
        name: 'Medios',
        definition: 'Porcentaje de la cartera invertido en empresas que poseen redes de difusión o que crean o distribuyen contenidos. Ejemplos: AOL, Time Warner, Recoletos.',
    },
    {
        name: 'Memoria anual',
        definition: 'La memoria anual es, como su nombre indica, un documento que por ley tiene que elaborar la gestora una vez al año y que recoge la situación del fondo con fecha de final de año así como su cuenta de resultados.',
    },
    {
        name: 'Mercado emergente',
        definition: 'Se utiliza la expresión "mercado emergente" para designar a un mercado financiero de un país en desarrollo. Invertir en un mercado emergente supone asumir muchos más riesgos que hacerlo en un mercado de un país desarrollado debido a los riesgos tanto políticos, económicos, sociales como de iliquidez de los títulos que cotizan en él.',
    },
    {
        name: 'MIB 30',
        definition: 'El MIB 30 es el principal índice de la bolsa italiana. Reúne a los 30 mayores valores de la bolsa de Milán.',
    },
    {
        name: 'Moneda del fondo',
        definition: 'Es la divisa en la que está denominado el fondo.',
    },
    {
        name: 'NASDAQ',
        definition: 'El Nasdaq (o National Association of Securities Dealers Automated Quotations) es la segunda bolsa más importante de Estados Unidos. Es el mercado de acciones tecnológicas por excelencia. En él cotizan empresas como Cisco, Intel, Microsoft o Dell Computers.',
    },
    {
        name: 'New York Stock Exchange (NYSE)',
        definition: 'La Bolsa de Nueva York (NYSE - "New York Stock Exchange") es la mayor y más antigua bolsa de Estados Unidos. Los principales índices cotizados en el NYSE incluyen al S&P 500 y al Dow Jones Industrial Average.',
    },
    {
        name: 'Nikkei 225',
        definition: 'El Nikkei 225 es el principal índice de la bolsa japonesa. Reúne a los 225 mayores valores japoneses.',
    },
    {
        name: 'Objectivo de inversión',
        definition: 'Es el objetivo estipulado en el folleto o reglamento de gestión del fondo. Indica lo que harán los gestores con el dinero de los partícipes.',
    },
    {
        name: 'Obligación',
        definition: 'Una obligación es un título de renta fija emitido por un Estado o por una empresa privada según el cual éste o ésta se compromete a pagar durante la vida de esa obligación unos cupones a un tipo predeterminado y a rembolsar el capital a vencimiento. Existen algunos casos particulares como las obligaciones cupón cero que no pagan ningún cupón pero que se emiten con descuento.',
    },
    {
        name: 'Obligación convertible',
        definition: 'Una obligación (o bono) convertible es una obligación (o bono) emitida por una empresa pero que puede convertirse en algún momento en una acción a un precio establecido de antemano. La decisión de convertir la obligación (o bono) en acción puede recaer tanto sobre el emisor del título como sobre el inversor, pero en ningún caso es una obligación.',
    },
    {
        name: 'OPA',
        definition: 'Son las siglas de Oferta Pública de Adquisición. Se habla de OPA cuando una empresa toma el control (compra las acciones) de otra empresa ya sea de forma hostil u amistosa.',
    },
    {
        name: 'Opción',
        definition: 'Una opción es un contrato mediante el cual el inversor tiene el derecho (pero no la obligación) bien a comprar (opción call) bien a vender (opción put) un determinado activo financiero (como por ejemplo, acciones) a un precio y una fecha predeterminados.',
    },
    {
        name: 'OPV',
        definition: 'Son las siglas de Oferta Pública de Venta. Se habla de OPV cuando una empresa saca a bolsa parte de su capital.',
    },
    {
        name: 'P/CF',
        definition: 'El Precio/Cash Flow o P/CF es un ratio que mide la relación entre la cotización de un valor y el flujo de caja por acción.',
    },
    {
        name: 'P/E',
        definition: 'El PER o P/E (Price Earnings Ratio en inglés) es un ratio que mide la relación entre el precio de una acción y su beneficio por acción. Generalmente las empresas de alto crecimiento tienen un PER elevado mientras que las empresas más maduras tienen un PER mucho más bajo.',
    },
    {
        name: 'P/VC',
        definition: 'El Precio/Valor Contable - o Price/Book (P/B) en inglés - es un ratio financiero calculado dividiendo la cotización de la acción dividido por el valor contable por acción. Compara el precio de una compañía respecto a su valor en libros o valor contable. En principio, una acción que cotiza a varias veces su valor contable podría indicar que se trata de una acción de crecimiento ya que los inversores estarían esperando un crecimiento futuro del valor contable.',
    },
    {
        name: 'Participación',
        definition: 'Una participación de un determinado fondo de inversión corresponde simplemente a una pequeña parte de éste. Cada participación en sí representa por lo tanto una pequeña parte de la cartera del fondo. El precio de cada participación se denomina valor liquidativo.',
    },
    {
        name: 'Partícipe',
        definition: 'Se utiliza esa palabra para designar al inversor en fondos de inversión.',
    },
    {
        name: 'Partícipe en suspenso',
        definition: 'Cuando el partícipe deja de aportar dinero al plan de pensiones, pasa a ser partícipe en suspenso pero mantiene sus derechos consolidados en el plan.',
    },
    {
        name: 'Pasaporte comunitario',
        definition: 'En el mundo de los fondos de inversión se entiende por pasaporte comunitario la posibilidad para cualquier fondo registrado en un Estado miembro de la Unión Económica y Monetaria de poder ser libremente comercializado en los otros Estados miembros.',
    },
    {
        name: 'Patrimonio',
        definition: 'Es el patrimonio del fondo medido en millones de la divisa en la que está denominado el fondo (millones de euros para un fondo denominado en euros).',
    },
    {
        name: 'PER',
        definition: 'El PER o P/E (Price Earnings Ratio en inglés) es un ratio que mide la relación entre el precio de una acción y su beneficio por acción. Generalmente las empresas de alto crecimiento tienen un PER elevado mientras que las empresas más maduras tienen un PER mucho más bajo.',
    },
    {
        name: 'Perfil de Riesgo',
        definition: 'La flecha que aparece en el Perfil de Riesgo indica el riesgo del fondo medido por su desviación típica, la cual indica cómo, en términos medios, la rentabilidad del fondo se ha desviado de su media. Una desviación típica alta significa que la rentabilidad del fondo ha experimentado fuertes variaciones mientras que una desviación típica baja indica que la rentabilidad del fondo ha sido mucho más estable. Los fondos que se encuentran en la parte de Riesgo Bajo tienen una desviación típica comprendida entre 0 y 13,34, los que se encuentran en la parte de Riesgo Medio tienen una desviación típica comprendida entre 13,34 y 26,67 y los que figuran en la parte de Riesgo Alto tienen una desviación típica por encima de 26,67.',
    },
    {
        name: 'Performance Moyenne',
        definition: 'La Performance Moyenne est la performance moyenne annuelle du fonds sur les 3 dernières années.',
    },
    {
        name: 'Plan de pensiones',
        definition: 'Un plan de pensiones es un producto financiero, complementario del sistema público de la Seguridad Social, cuyo objetivo es permitir a sus partícipes recibir unas prestaciones en determinados supuestos jubilación, invalidez,...), mediante aportaciones al plan en cuestión.',
    },
    {
        name: 'Plan de pensiones asociado',
        definition: 'Los planes de pensiones asociados son aquellos promovidos por un determinado colectivo o asociación y cuyos partícipes son miembros de este colectivo o asociación.',
    },
    {
        name: 'Plan de pensiones de empleo',
        definition: 'Los planes de pensiones de empleo son aquellos en los que el promotor es una empresa y sus partícipes los trabajadores de dicha empresa. En este caso es la propia empresa la que efectúa las aportaciones a favor de los partícipes.',
    },
    {
        name: 'Plan de pensión del sistema individual',
        definition: 'Los planes de pensiones del sistema individual son aquellos planes en los que las aportaciones las efectúa voluntariamente el propio partícipe y en los que el promotor es una entidad financiera (banco, caja de ahorros o compañía de seguros). Cualquier persona física con capacidad legal (mayor de 18 años) puede ser partícipe de un plan del sistema individual ya que se basan en el principio de no discriminación. Las personas jubiladas también pueden realizar aportaciones a planes de pensiones pero el dinero sólo lo podrá recuperar el beneficiario del plan.',
    },
    {
        name: 'Plusvalía',
        definition: 'La plusvalía es la ganancia obtenida al vender las participaciones de un fondo de inversión, calculada como la diferencia entre el valor de venta y el de compra.',
    },
    {
        name: 'Precio/Cash Flow',
        definition: 'El Precio/Cash Flow o P/CF es un ratio que mide la relación entre la cotización de un valor y el flujo de caja por acción.',
    },
    {
        name: 'Precio/Valor Contable',
        definition: 'El Precio/Valor Contable - o Price/Book (P/B) en inglés - es un ratio financiero calculado dividiendo la cotización de la acción dividido por el valor contable por acción. Compara el precio de una compañía respecto a su valor en libros o valor contable. En principio, una acción que cotiza a varias veces su valor contable podría indicar que se trata de una acción de crecimiento ya que los inversores estarían esperando un crecimiento futuro del valor contable.',
    },
    {
        name: 'Prima sobre el valor liquidativo',
        definition: 'Cuando un fondo (cerrado) cotiza con una prima sobre su valor liquidativo, esto significa que el valor total de los títulos presentes en el fondo (valor liquidativo) es inferior a la cotización del fondo en cuestión. Esta prima se expresa generalmente en porcentaje.',
    },
    {
        name: 'Promotor',
        definition: 'El promotor es la entidad, corporación, empresa, asociación o colectivo de cualquier clase que crea y participa en la puesta en marcha del plan de pensiones.',
    },
    {
        name: 'PSA (Plan Sistemático de Ahorro)',
        definition: 'Un Plan Sistemático de Ahorro es un plan que permite al partícipe de un determinado fondo de inversión realizar aportaciones periódicas según las condiciones (periodicidad, aportaciones mínimas,...) estipuladas en el regalmento de gestión del fondo.',
    },
    {
        name: 'Punto básico',
        definition: 'Un punto básico corresponde a 0,01% y se utiliza para medir los cambios en los tipos de interés. Por ejemplo un cambio del 6.25% al 6.75% en los tipos de interés correspondería a un aumento de 50 puntos básicos.',
    },
    {
        name: 'Put',
        definition: 'Una opción es un contrato mediante el cual el inversor tiene el derecho (pero no la obligación) bien a comprar (opción call) bien a vender (opción put) un determinado activo financiero (como por ejemplo, acciones) a un precio y una fecha predeterminados.',
    },
    {
        name: 'R cuadrado',
        definition: 'R cuadrado (R2) representa el porcentaje de variación de rentabilidad de la variable dependiente (en este caso la rentabilidad del fondo de inversión) que puede ser explicado por la variable independiente (la rentabilidad de su índice de referencia). Si tomamos por ejemplo un fondo que siga de muy cerca al índice IBEX 35 y si hacemos una regresión respecto a ese índice obtendremos un R2 igual a 1 o, por lo menos, muy próximo a 1. Esto significa que prácticamente todas las fluctuaciones de rentabilidad del fondo pueden explicarse por la evolución del índice en cuestión.',
    },
    {
        name: 'Rango en categoría',
        definition: 'Mide en una escala de 1 a 100 la rentabilidad del fondo comparada con la del resto de fondos de su misma categoría. Cuanto menor sea ese número, mejor se habrá comportado el fondo.',
    },
    {
        name: 'Ranking',
        definition: 'Representa la posición del plan dentro de su categoría en el periodo indicado.',
    },
    {
        name: 'Rating de crédito',
        definition: 'El Rating de crédito mide la probabilidad de que una determinada obligación incumpla una de sus obligaciones. Existen muchas compañías de rating que ofrecen sus sistema de valoración como pueden ser Moody\'s, Standard & Poor\'s y Fitch. Las obligaciones con rating desde AAA a BBB- son considerados como \'investment grade\' (grado de inversión) mientras que los rating de BB+ hacia abajo son del tipo \'speculative grade\' (grado especulativo).',
    },
    {
        name: 'Rating Morningstar',
        definition: 'El Rating Morningstar mide la rentabilidad ajustada por el riesgo de un determinado fondo respecto a su categoría Morningstar utilizando datos de rentabilidad de los últimos 36 meses o 3 años. En función de estos criterios clasificamos los fondos de la siguiente manera. El 10% de los mejores fondos reciben 5 estrellas, el 22,5% siguiente 4 estrellas, el 35% siguiente 3 estrellas, el 22,5% siguiente 2 estrellas y el último 10% 1 estrella.',
    },
    {
        name: 'Ratio de Sharpe',
        definition: 'El ratio de Sharpe es una medida de rentabilidad-riesgo desarrollada por el premio Nobel William Sharpe. Se calcula con los datos de los últimos 36 meses dividiendo el exceso de rentabilidad obtenida por el fondo (respecto al activo sin riesgo) por la desviación estándar de esos excesos de rentabilidad. Cuánto mayor sea ese ratio de Sharpe mejor comportamiento habrá demostrado el fondo en el periodo analizado. El ratio de Sharpe mide, por lo tanto, el exceso de rentabilidad por unidad de riesgo.',
    },
    {
        name: 'Ratio de Sharpe - Rating Morningstar',
        definition: 'El famoso ratio de Sharpe se basa en uno de los principios fundamentales en materia de inversión. Uno está dispuesto a invertir en un fondo o en un activo con riesgo si la rentabilidad esperada es mayor que la del activo sin riesgo (letras del tesoro por ejemplo). Sharpe toma como riesgo la volatilidad de las rentabilidades pasadas. Por lo tanto, si llamamos Rfondo la rentabilidad del fondo, Rsegura la rentabilidad del activo sin riesgo y s la volatilidad de las rentabilidades, entonces el ratio de Sharpe es: (Rfondo - Rsegura ) / s Dicho de otra forma es el exceso de rentabilidad respecto al activo sin riesgo, en relación con el riesgo asumido. Para el cálculo del Rating Morningstar, se utiliza el índice de Stutzer, algo distinto del ratio de Sharpe. En efecto, el riesgo medido según la fórmula de Sharpe parte de la hipótesis de que las rentabilidades mensuales se distribuyen en forma de curva normal (distribución normal). El índice de Stutzer, por el contrario, no asume esta hipótesis sino que parte de la distribución real de las rentabilidades. Cuando la distribución es perfectamente normal, el ratio de Sharpe y el índice de Stutzer producen los mismos resultados.',
    },
    {
        name: 'Rendimiento',
        definition: 'El rendimiento es la rentabilidad (expresada en porcentaje) que obtiene un inversor vía dividendos o cupones sobre su inversión inicial. No debe ser confudido con la rentabilidad total de la inversión.',
    },
    {
        name: 'Renta fija',
        definition: 'Se designa por renta fija cualquier título negociable, representativo de una deuda ya sea de una entidad pública o de una entidad privada. Dentro de la renta fija podemos diferenciar en función de su vencimiento lo que son bonos, obligaciones, letras.',
    },
    {
        name: 'Renta fija privada',
        definition: 'Designa cualquier título de renta fija emitido por una empresa privada.',
    },
    {
        name: 'Renta fija pública',
        definition: 'Se designa por renta fija pública cualquier título de renta fija emitido por un Estado o Nación.',
    },
    {
        name: 'Renta variable',
        definition: 'Se utiliza el término renta variable para designar la inversión en acciones que cotizan en bolsa.',
    },
    {
        name: 'Rentabilidad',
        definition: 'La rentabilidad mide cómo una inversión se ha revalorizado (o caído) en un determinado periodo de tiempo. Los inversores pueden comparar la rentabilidad obtenida por su fondo con otros fondos de similares características para ver si éste lo ha hecho bien o mal.',
    },
    {
        name: 'Rentabilidad %',
        definition: 'Indica la rentabilidad total, en euros, obtenida por el fondo por años naturales, asi como la rentabilidad acumulada en lo que llevamos de año.',
    },
    {
        name: 'Rentabilidad acumulada',
        definition: 'La rentabilidad acumulada mide la rentabilidad en el periodo considerado ya sea a un año, un mes, una semana o un día. Por ejemplo, a fecha del 7 de junio del 2001, la rentabilidad acumulada a un año cubre el periodo que va desde el 8 de junio del 2000 hasta el 7 de junio del 2001 y la rentabilidad a una semana es la que va desde el 1 de junio del 2001 hasta el 7 de junio del 2001.',
    },
    {
        name: 'Rentabilidad ajustada al coste',
        definition: 'La rentabilidad ajustada al coste es el hecho de incluir las comisiones de cada fondo en el cálculo de su rentabilidad con el objetivo de compararlo de una forma homogénea con los otros fondos de su categoría.',
    },
    {
        name: 'Rentabilidad Anual',
        definition: 'La rentabilidad anual indica la rentabilidad en un año natural. Para años naturales (por ejemplo, 2003) se calcula del 31 de diciembre del año anterior (en este caso el 2002) al 31 de diciembre del año siendo considerado (en este caso el 2003). No se muestra en el año en el que comenzó el plan (ya que no sería un año completo). La rentabilidad en el año indica la rentabilidad obtenida por el plan en el año en curso (desde el 31 de diciembre del año anterior, hasta la fecha del último valor liquidativo). No se muestra si el plan ha comenzado a operar en el año en curso.',
    },
    {
        name: 'Rentabilidad anualizada',
        definition: 'Es la rentabilidad del fondo en base anual. Por ejemplo si un fondo obtiene una rentabilidad del 5% en seis meses, entonces su rentabilidad anualizada será del 10,25%.',
    },
    {
        name: 'Rentabilidad en el año',
        definition: 'Rentabilidad en el año indica la rentabilidad obtenida por el fondo en el año en curso. Por ejemplo si usted visita la página el día 8 de junio, la rentabilidad en el año indica simplemente la rentabilidad acumulada por el fondo desde el 1 de enero hasta la fecha. Las rentabilidades se actualizan diariamente excepto sábados, domingos y días festivos.',
    },
    {
        name: 'Rentabilidad media',
        definition: 'Es la rentabilidad media anual del plan de pensiones en euros en los últimos tres años.',
    },
    {
        name: 'Rentabilidad por dividendo',
        definition: 'La rentabilidad por dividendo de una acción se calcula dividiendo la suma de dividendos pagados durante un determinado periodo de tiempo por la cotización. Por ejemplo, si una acción que cotiza a 50 EUR ha pagado un dividendo total de 1 EUR, la rentabilidad por dividendo será igual a un 2% (1/50).',
    },
    {
        name: 'Rentabilidad total',
        definition: 'Es la rentabilidad total en euros obtenida por el plan en un determinado periodo de tiempo que puede ser 1 año, tres años,...',
    },
    {
        name: 'Retención',
        definition: 'La retención del 18% que le practica su entidad gestora sobre las ganancias obtenidas al vender sus participaciones es un pago adelantado a Hacienda que deberá incluir en su declaración de la renta (IRPF).',
    },
    {
        name: 'Rotación',
        definition: 'La rotación de un fondo, que se calcula como un porcentaje sobre el patrimonio total, es una forma de medir la actividad del fondo en los últimos 12 meses. Por ejemplo, una rotación del 20% significa que el valor de las compras y ventas efectuadas por el fondo representan una quinta parte del patrimonio total del fondo. Ahora bien, una rotación del 100% o más no significa que todos los títulos han sido objeto de compra-venta. En la práctica, indica el porcentaje de la cartera que ha cambiado en los últimos doce meses.',
    },
    {
        name: 'Salud',
        definition: 'Porcentaje de la cartera invertido en empresas de biotecnología, farmacéuticas, de investigación médica, servicios hospitalarios y equipamientos médicos. Ejemplos: Pfizer, Merck y Aventis.',
    },
    {
        name: 'Securities and Exchange Commission (SEC)',
        definition: 'La SEC es la agencia federal norteamericana, fundada en 1934, que protege a los inversores americanos de las malas prácticas en los mercados de valores. La SEC fija las reglas y reglamentaciones que deben cumplir todas las empresas registradas en Estados Unidos.',
    },
    {
        name: 'SEDOL',
        definition: 'SEDOL son las siglas de Stock Echange Daily Official List. Es un número de siete dígitos que identifica a cada título o fondo.',
    },
    {
        name: 'Servicios a las empresas',
        definition: 'Porcentaje de la cartera invertido en empresas de los sectores de la publicidad, consulting, ingeniería y construcción, seguridad, distribución a profesionales y transporte aéreo. Ejemplos: Iberia y Adecco.',
    },
    {
        name: 'Servicios a los consumidores',
        definition: 'Porcentaje de la cartera invertido en empresas de los sectores de la distribución, construcción de viviendas, viajes, ocio o educación. Ejemplos: Carrefour y Wal-Mart.',
    },
    {
        name: 'Servicios financieros',
        definition: 'Porcentaje de la cartera invertido en bancos, compañías de seguro y de gestión de activos. Ejemplos: BSCH y AXA.',
    },
    {
        name: 'Servicios públicos',
        definition: 'Porcentaje de la cartera invertido en empresas que producen o distribuyen electricidad, agua o gas. Ejemplos: Endesa y Vivendi Environnement.',
    },
    {
        name: 'SICAV',
        definition: 'Las SICAV o Sociedades de Inversión de Capital Variable tienen su domicilio fiscal fuera de España (generalmente Luxemburgo). Son muy parecidos a los fondos de inversión aunque también presentan algunas diferencias. La más importante es que las SICAV no se subdividen en participaciones sino en acciones. En todo caso las SICAV que están registradas en la CNMV disfrutan del mismo tratamiento fiscal que los fondos nacionales.',
    },
    {
        name: 'Sobreponderar',
        definition: 'Se utiliza el término sobreponderar para indicar que un gestor o un fondo dedica a un determinado valor o sector de su cartera un peso superior al que tiene ese mismo valor o sector en el benchmark o índice de referencia.',
    },
    {
        name: 'Sociedad de inversión',
        definition: 'Una sociedad de inversión es, al igual que un fondo de inversión, una institución de inversión colectiva. Pero a diferencia de los fondos, las sociedades de inversión se subdividen no en participaciones sino en acciones y, por lo tanto, cotizan en bolsa. En España existen las SIM (Sociedades de Inversión Mobiliaria - de capital fijo) como las SIMCAV (Sociedades de Inversión Mobiliaria de Capital Variable).',
    },
    {
        name: 'Software',
        definition: 'Porcentaje de la cartera invertido en empresas que desarrollan y comercializan sistemas de explotación y aplicaciones informáticas. Ejemplos: Microsoft, Oracle y Siebel Systems.',
    },
    {
        name: 'Split',
        definition: 'Un split se produce cuando una compañía aumenta el número de sus acciones en circulación sin modificar la cantidad proporcional de acciones de cada accionista. Permite a los pequeños inversores tener acceso a acciones que cotizan con un alto precio. Por ejemplo, si una acción que cotiza a 100 euros realiza un split de uno por tres, la acción cotizará a 30 euros pero el número de acciones que posee el inversor se multiplicará por tres.',
    },
    {
        name: 'Standard and Poor\'s 500 Stock Index (S&P 500)',
        definition: 'El S&P 500 o Standard and Poor’s 500 es un índice que agrupa los 500 mayores valores industriales estadounidenses.',
    },
    {
        name: 'Style Box de Morningstar',
        definition: 'El Morningstar Style Box ilustra gráficamente el estilo de gestión de un fondo de acciones en un determinado momento. El Style Box combina dos variables: el Tamaño (empresas de capitalización grande, mediana o pequeña) y el Estilo (Valor, Mixto o Crecimiento). El Style Box permite de un vistazo darse cuenta del estilo de gestión de un fondo, aspecto fundamental para una buena repartición de cartera. En cuanto al Style Box de fondos de renta fija, éste tiene en cuenta dos de los factores de riesgo de cualquier fondo de renta fija: la exposición a los tipos de interés y la exposición al riesgo de crédito.',
    },
    {
        name: 'Supermercado de fondos',
        definition: 'Un supermercado de fondos es un sitio en el que el inversor puede comprar, bien por internet bien por teléfono, fondos de muchas gestoras distintas.',
    },
    {
        name: 'Tasa de descuento',
        definition: 'La tasa de descuento es el tipo de interés utilizado para convertir una inversión futura en una cantidad actual. Utilizando esta tasa de descuento un inversor puede saber, por ejemplo, si está pagando un precio justo por un bono que paga un cupón anual de 50 euros y cuyo principal es de 1000 euros ya que utilizando la tasa de descuento puede calcular el valor actual de los flujos futuros de ese bono.',
    },
    {
        name: 'Telecomunicaciones',
        definition: 'Porcentaje de la cartera invertido en empresas proveedoras de servicios de comunicación que utilizan líneas fijas o servicios o accesos inalámbricos. Ejemplos: Telefónica, AT&T y Vodafone.',
    },
    {
        name: 'Teoría Moderna de Cartera',
        definition: 'Bajo Teoría Moderna de Cartera se hace referencia al conjunto de innovaciones introducidas desde 1950 en la gestión de carteras. El concepto central de la Teoría Moderna de Cartera es el de la Diversificación, es decir el hecho de que con una combinación de activos bien elegida se puede conseguir una mayor rentabilidad con un menor riesgo que en cualquier de los activos elegidos individualmente. Otro concepto importante es el Riesgo de Mercado. El riesgo de mercado puede subdividirse en dos partes: por un lado, las fluctuaciones debido a los movimientos del mercado en general (ver Beta) y, por otro lado, las fluctuaciones independientes de las del mercado en general (riesgo específico).',
    },
    {
        name: 'Tipos de cartera',
        definition: 'Existen dos tipos de cartera: la rápida y la transaccional. La cartera rápida es la manera más rápida para crear una cartera. La cartera transaccional ofrece la posibilidad de hacer un seguimiento completo de la cartera (compras, ventas, splits, dividendos).',
    },
    {
        name: 'Top-down',
        definition: 'En una gestión top-down (o de arriba hacia abajo) el gestor examina en primer lugar variables macroeconómicas generales para luego afinar su análisis por sectores y terminar por los valores individuales.',
    },
    {
        name: 'Tracking error',
        definition: 'El tracking error describe la volatilidad de la diferencia de rentabilidad entre un fondo y su benchmark. Por lo tanto, los fondos de gestión pasiva tendrán un tracking error pequeño.',
    },
    {
        name: 'UCITS',
        definition: 'UCITS son las siglas inglesas de "Collective Investment in Transferable Securities". Se refiere a la directiva de la Unión Europea que establece las condiciones bajo las cuales un fondo domiciliado en un estado miembro puede ser distribuido en todos los otros países miembros. El objetivo es simplificar las reglamentaciones en materia de inversiones y aumentar la protección del inversor.',
    },
    {
        name: 'Unit Linked',
        definition: 'Un unit linked es un seguro mixto de vida y ahorro que invierte en fondos o en carteras de fondos de inversión.',
    },
    {
        name: 'Unión Europea (UE)',
        definition: 'La Unión Europea (UE) tiene su origen en el Tratado de Roma y en la constitución de un mercado común de 6 países en 1957, originalmente conocido como la Comunidad Europea. Una moneda común, un espacio común europeo para la libre circulación de bienes y servicios y una alianza política entre sus estados miembros forman la base de la Unión Europea.',
    },
    {
        name: 'Valor',
        definition: 'Se utiliza el término valor para designar a un fondo (fondo de valor o "value fund") que invierte su cartera en acciones “baratas” desde el punto de vista de sus ratios PER o P/CF.',
    },
    {
        name: 'Valor liquidativo',
        definition: 'El valor liquidativo de un fondo de inversión corresponde al precio de cada participación. Se calcula dividiendo el patrimonio total del fondo por el número de participaciones.',
    },
    {
        name: 'Valores cíclicos',
        definition: 'Los valores cíclicos son aquellos cuyo rendimiento se mueve en línea con el comportamiento de la economía en general. El valor de sus acciones sube en periodos de crecimiento pero sufre en periodos de recesión. Entre los sectores cíclicos podemos mencionar a los automóviles, la construcción y la industria manufacturera.',
    },
    {
        name: 'Value fund',
        definition: 'Se utiliza el término valor para designar a un fondo (fondo de valor o "value fund") que invierte su cartera en acciones “baratas” desde el punto de vista de sus ratios PER o P/CF.',
    },
    {
        name: 'Vencimiento',
        definition: 'El vencimiento de una obligación o de un bono es la fecha en que el inversor recibe el principal. Por ejemplo, el vencimiento de un bono a cinco años emitido el 1 de Noviembre del 2000 será el 1 de Noviembre del 2005.',
    },
    {
        name: 'Venta a corto',
        definition: 'La venta a corto (o ponerse corto) es el hecho de vender unos títulos sin tenerlos en cartera pero con la esperanza de poder recomprarlos a precios más bajos para así obtener una ganancia entre el precio de venta y el de compra.',
    },
    {
        name: 'VL',
        definition: 'El valor liquidativo de un fondo de inversión corresponde al precio de cada participación. Se calcula dividiendo el patrimonio total del fondo por el número de participaciones.',
    },
    {
        name: 'Volatilidad',
        definition: 'La desviación típica de un fondo es una medida del riesgo del fondo. Indica cómo, en términos medios, la rentabilidad del fondo se ha desviado de su media. Una desviación típica alta significa que la rentabilidad del fondo ha experimentado fuertes variaciones mientras que una baja indica que la rentabilidad del fondo ha sido mucho más estable. Muchas veces se utiliza el término volatilidad para designar a la desviación típica. La desviación típica de un fondo es un dato interesante en dos sentidos. En primer lugar, porque cuanto mayores fluctuaciones presenta la rentabilidad de un fondo, más arriesgado será el fondo en cuestión. Además, permite comparar todos los fondos entre ellos, desde los fondos monetarios hasta los fondos de acciones emergentes. En segundo lugar, porque los fondos que han sido más volátiles en el pasado también tienen tendencia a ser los más volátiles en el futuro. En este sentido la desviación típica es una señal de alarma muy útil. La desviación típica se expresa, como la rentabilidad, en porcentaje. La calculamos basándonos en las rentabilidades de los últimos 36 meses (o 3 años).',
    },
    {
        name: 'Zona Euro',
        content: 'La zona euro designa al área geográfica compuesta por los 12 países siguientes: Alemania, Austria, Bélgica, España, Finlandia, Francia, Grecia, Holanda, Irlanda, Italia, Luxemburgo y Portugal.',
    },
];

module.exports = terms;
