const moment = require('moment');
const entities = require('ent');
const get = require('lodash/object/get');

const { getPublicSiteUrl } = require('core/apiURLBuilder');
const { hasPublicUrl, hasPrivateUrl } = require('utilities/commercialAdsHelper.js');

const {
    CMS_PRIVATE_PREFIX,
    CMS_PUBLIC_PREFIX,
    DATE_FORMAT_API,
    DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR,
    RETAIL_LOAN_AD_ID,
} = require('constants/index');

const getAdType = (adLabel) => {
    let type = adLabel.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

    if (adLabel === 'CrossSell') {
        type = `${type}ing`;
    }

    return type;
};

const getBody = (body) => {
    if (body && !!body.value) {
        body.value = entities.decode(body.value);
    }

    return body;
};

const getFieldLink = addFieldLink => {
    const fieldLink = {};
    if (addFieldLink) {
        fieldLink.title = addFieldLink.title;
        if (!!addFieldLink.uri) {
            if (hasPublicUrl(addFieldLink.uri)) {
                fieldLink.uri = addFieldLink.uri.replace(CMS_PUBLIC_PREFIX, getPublicSiteUrl());
                fieldLink.targetBlank = false;
            } else if (hasPrivateUrl(addFieldLink.uri)) {
                fieldLink.uri = addFieldLink.uri;
                fieldLink.targetBlank = false;
            } else {
                fieldLink.uri = addFieldLink.uri;
                fieldLink.targetBlank = true;
            }
        }
    }

    return fieldLink;
};

const getV2Body = (body) => {
    let bodyContent = {};
    if (body) {
        bodyContent.value = entities.decode(body);
    }
    return bodyContent;
};

const getV2FieldLink = (ad) => {
    const adLabel = get(ad, 'creativity_type');
    let addFieldLink = get(ad, 'content.cta');
    if (adLabel === "creativity_cross_selling") {
        addFieldLink = get(ad, 'content.link');
    }
    const fieldLink = {};
    if (addFieldLink) {
        fieldLink.title = addFieldLink.text;
        if (!!addFieldLink.url) {
            if (hasPublicUrl(addFieldLink.url)) {
                fieldLink.uri = addFieldLink.url.replace(CMS_PUBLIC_PREFIX, getPublicSiteUrl());
                fieldLink.targetBlank = false;
            } else if (hasPrivateUrl(addFieldLink.url)) {
                fieldLink.uri = addFieldLink.url.replace(CMS_PRIVATE_PREFIX, '');
                fieldLink.targetBlank = false;
            } else {
                fieldLink.targetBlank = true;
                fieldLink.uri = addFieldLink.url;
            }

            // We need to check if there is value for new_tab in CMS, in other case
            // we don´t have to change.
            const newTab = !!get(addFieldLink, 'options.attributes.new_tab', undefined);

            if (newTab !== undefined) {
                fieldLink.targetBlank = newTab;
            }

            fieldLink.color = addFieldLink.color;
            fieldLink.iconGhost = get(addFieldLink, 'ghost_cta.enabled', false);
            fieldLink.inverseGhost = get(addFieldLink, 'ghost_cta.contrast', '') === 'light';

            if (!!addFieldLink.font_icon) {
                fieldLink.icon = get(addFieldLink, 'font_icon.icon', '');
                fieldLink.iconAlignment = get(addFieldLink, 'font_icon.alignment', '');
                fieldLink.isOnlyIcon = get(addFieldLink, 'font_icon.icon_only', '');
                fieldLink.iconIsEnabled = get(addFieldLink, 'font_icon.enabled', false);
                fieldLink.iconColor = get(addFieldLink, 'font_icon.contrast', 'light');
            }
        }
    }
    return fieldLink;
};

const mapRetailLoanFields = (ad) => {
    return {
        adId: get(ad, 'location_id'),
        adLabel: get(ad, 'creativity_type'),
        body: getV2Body(get(ad, 'content.field_body.value')),
        fieldAltText: get(ad, 'content.image_large.alt'),
        fieldChannel: get(ad, 'channel'),
        fieldCmsId: get(ad, 'cms_id'),
        fieldImageLarge: {
            alt: get(ad, 'content.field_image.alt'),
            title: get(ad, 'content.field_image.title'),
            url: get(ad, 'content.field_image.url')
        },
        fieldImageMedium: {
            alt: get(ad, 'content.image_medium.alt'),
            title: get(ad, 'content.image_medium.title'),
            url: get(ad, 'content.image_medium.url')
        },
        fieldImageSmall: {
            alt: get(ad, 'content.image_small.alt'),
            title: get(ad, 'content.image_small.title'),
            url: get(ad, 'content.image_small.url')
        },
        fieldLink: getV2FieldLink(ad),
        nid: get(ad, 'id').split('-')[0],
        title: get(ad, 'content.field_text_title.value'),
        propositionId: get(ad, 'propId'),
        offerId: get(ad, 'offer_id'),
        offerCode: get(ad, 'offer_code')
    };
}

const formatColorFromPublic = (colorWithoutFormat = '') => {
    let color = colorWithoutFormat.toUpperCase();

    if (color !== 'TRANSPARENT') {
        color = `#${colorWithoutFormat}`;
    }

    return color;
}

const validatePromoByDate = (startDate, endDate) => {
    return moment(moment().startOf('day'), DATE_FORMAT_API).isBetween(moment(startDate.format(DATE_FORMAT_API), DATE_FORMAT_API), moment(endDate.format(DATE_FORMAT_API), DATE_FORMAT_API), null, '[]');
}

const parseAdFromCMS = (ad) => {
    const adLocationId = get(ad, 'location_id');

    // OBK314-78539: Loans node is different for that we need to do a custom mapping. To be removed in R15
    if (adLocationId === RETAIL_LOAN_AD_ID) {
        return mapRetailLoanFields(ad);
    } else if (adLocationId) {
        const adId = get(ad, 'location_id');
        const adLabel = get(ad, 'creativity_type');
        return {
            adId,
            adLabel,
            body: getV2Body(get(ad, 'content.description.body')),
            ctaDetails: get(ad, 'content.cta'),
            fieldAltText: get(ad, 'content.image_large.alt'),
            fieldChannel: get(ad, 'channel'),
            fieldCmsId: get(ad, 'cms_id'),
            fieldImageLarge: {
                alt: get(ad, 'content.image_large.alt'),
                title: get(ad, 'content.image_large.title'),
                url: get(ad, 'content.image_large.url')
            },
            fieldImageMedium: {
                alt: get(ad, 'content.image_medium.alt'),
                title: get(ad, 'content.image_medium.title'),
                url: get(ad, 'content.image_medium.url')
            },
            fieldImageSmall: {
                alt: get(ad, 'content.image_small.alt'),
                title: get(ad, 'content.image_small.title'),
                url: get(ad, 'content.image_small.url')
            },
            fieldLastDays: {
                color: formatColorFromPublic(get(ad, 'content.last_days.color', '')),
                color_name: get(ad, 'content.last_days.color_name', ''),
                date: {
                    value: moment(get(ad, 'content.last_days.date.value', '')).format(DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR),
                    end_value: moment(get(ad, 'content.last_days.date.end_value', '')).format(DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR),
                },
                enable: get(ad, 'content.last_days.enable', false) && validatePromoByDate(moment(get(ad, 'content.last_days.date.value', ''), DATE_FORMAT_API), moment(get(ad, 'content.last_days.date.end_value', '')), DATE_FORMAT_API),
                icon: {
                    color: formatColorFromPublic(get(ad, 'content.last_days.icon.color', '')),
                    color_name: get(ad, 'content.last_days.icon.color_name', ''),
                    enable: get(ad, 'content.last_days.icon.enable', false),
                    font_icon: get(ad, 'content.last_days.icon.font_icon', ''),
                },
                size: get(ad, 'content.last_days.size', '') || 'label-small',
                text: get(ad, 'content.last_days.text', ''),
                text_color: formatColorFromPublic(get(ad, 'content.last_days.text_color', '')),
                text_color_name: get(ad, 'content.last_days.text_color_name', ''),
            },
            fieldValidPeriod: {
                date: {
                    value: moment(get(ad, 'content.valid_period.date.value', ''), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR).format(DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR),
                    end_value: moment(get(ad, 'content.valid_period.date.end_value', ''), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR).format(DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR),
                },
                enable: get(ad, 'content.valid_period.enable', false) && validatePromoByDate(moment(get(ad, 'content.valid_period.date.value', ''), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR), moment(get(ad, 'content.valid_period.date.end_value', ''), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR)),
                font_icon: get(ad, 'content.valid_period.font_icon', ''),
                font_icon_enable: get(ad, 'content.valid_period.font_icon_enable', false),
                position: get(ad, 'content.valid_period.position', ''),
                size: get(ad, 'content.valid_period.size', '') || 'date-small',
                text: get(ad, 'content.valid_period.text', ''),
            },
            fieldLink: getV2FieldLink(ad),
            nid: get(ad, 'id').split('-')[0],
            title: get(ad, 'content.title'),
            propositionId: get(ad, 'propId'),
            offerId: get(ad, 'offer_id'),
            offerCode: get(ad, 'offer_code')
        };
    } else {
        const adId = get(ad, 'fieldLocationId[0].value');
        const adLabel = get(ad, 'fieldMultichannelPrivate[0].termName');
        const mandatory = get(ad, 'fieldExtraConfig[0].value[0].mandatory');
        return {
            abTesting: get(ad, 'abTesting'),
            adId,
            adLabel,
            adType: getAdType(adLabel),
            body: getBody(get(ad, 'body[0]')),
            fieldAltText: get(ad, 'fieldAltText[0].value'),
            fieldChannel: get(ad, 'fieldChannel[0].value'),
            fieldCmsId: get(ad, 'fieldCmsId[0].value'),
            fieldImageLarge: {
                alt: get(ad, 'fieldImageLarge[0].alt'),
                height: get(ad, 'fieldImageLarge[0].height'),
                targetId: get(ad, 'fieldImageLarge[0].targetId'),
                targetType: get(ad, 'fieldImageLarge[0].targetType'),
                targetUuid: get(ad, 'fieldImageLarge[0].targetUuid'),
                title: get(ad, 'fieldImageLarge[0].title'),
                url: get(ad, 'fieldImageLarge[0].url'),
                width: get(ad, 'fieldImageLarge[0].width')
            },
            fieldImageMedium: {
                alt: get(ad, 'fieldImageMedium[0].alt'),
                height: get(ad, 'fieldImageMedium[0].height'),
                targetId: get(ad, 'fieldImageMedium[0].targetId'),
                targetType: get(ad, 'fieldImageMedium[0].targetType'),
                targetUuid: get(ad, 'fieldImageMedium[0].targetUuid'),
                title: get(ad, 'fieldImageMedium[0].title'),
                url: get(ad, 'fieldImageMedium[0].url'),
                width: get(ad, 'fieldImageMedium[0].width')
            },
            fieldImageSmall: {
                alt: get(ad, 'fieldImageSmall[0].alt'),
                height: get(ad, 'fieldImageSmall[0].height'),
                targetId: get(ad, 'fieldImageSmall[0].targetId'),
                targetType: get(ad, 'fieldImageSmall[0].targetType'),
                targetUuid: get(ad, 'fieldImageSmall[0].targetUuid'),
                title: get(ad, 'fieldImageSmall[0].title'),
                url: get(ad, 'fieldImageSmall[0].url'),
                width: get(ad, 'fieldImageSmall[0].width')
            },
            fieldLink: getFieldLink(get(ad, 'fieldLink[0]')),
            fieldMultichannelPrivate: {
                targetId: get(ad, 'fieldMultichannelPrivate[0].targetId'),
                targetType: get(ad, 'fieldMultichannelPrivate[0].targetType'),
            },
            fieldYoutubeVideo: {
                title: get(ad, 'fieldYoutubeVideo[0].title'),
                uri: get(ad, 'fieldYoutubeVideo[0].uri')
            },
            nid: get(ad, 'nid[0].value'),
            mandatory: mandatory ? mandatory.trim().toLowerCase() === "true" : false,
            title: get(ad, 'title[0].value'),
            poIds: get(ad, 'fieldPoIds[0].value'),
            propositionId: get(ad, 'propositionId'),
            offerSpaceName: get(ad, 'offerSpaceName'),
            rank: get(ad, 'rank')
        };
    }
}



module.exports = {
    getBody,
    getFieldLink,
    parseAdFromCMS
}
