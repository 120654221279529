// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    BROKER_ETF_BUY_BUYFOR_TITLES,
    BROKER_ETF_BUY_BUYFOR_AMOUNT,
    BROKER_ETF_BUY_BUYFOR_VALUE_DEFAULT,
    BROKER_NO_STOCKS_CONTRACT_SELECTED,
    BROKER_ORDER_TYPE_TO_MARKET,
    BROKER_ETF_BUY_ORDER_TYPE_VALUE_DEFAULT,
    BROKER_ORDER_TYPE_LIMITED
} = require('constants/index');
const DateHelper = require('utilities/dateHelper');

function setInitialState() {
    const firstDate = DateHelper.getNextBussinessDay(true, false);
    const limitDate = DateHelper.getStockLimitDate(firstDate);
    const excludeDates = DateHelper.getExcludedWeekends(limitDate);
    const validityDate = firstDate.clone();

    return Immutable.fromJS({
        stocksContractSelectedValue: BROKER_NO_STOCKS_CONTRACT_SELECTED,
        buyForOption: BROKER_ETF_BUY_BUYFOR_TITLES,
        buyForValue: BROKER_ETF_BUY_BUYFOR_VALUE_DEFAULT,
        titleQuantity: BROKER_ETF_BUY_BUYFOR_VALUE_DEFAULT,
        orderTypeOption: BROKER_ORDER_TYPE_TO_MARKET,
        orderTypeValue: BROKER_ETF_BUY_ORDER_TYPE_VALUE_DEFAULT,
        firstDate,
        validityDate,
        limitDate,
        excludeDates,
        errorFlags: {
            buyForValue: false,
            orderTypeValue: false,
            isETFsContractEmpty: false,
            orderTypeValueLowerThanLimit: false,
            stocksContract: false,
            holidayError: false
        },        
        valid: false
    });
}

function validateStep(step) {
    let isInvalidOrderTypeValue = false;
    let isAmountLowerThanLimit = false;
    let isETFsContractEmpty = false;
    let isInvalidBuyForValue = !step.get('buyForValue') || parseFloat(step.get('buyForValue')) <= 0;
    const isStocksContractEmpty = step.get('stocksContractSelectedValue') === BROKER_NO_STOCKS_CONTRACT_SELECTED;

    if (step.get('buyForOption')===BROKER_ETF_BUY_BUYFOR_AMOUNT){
        isInvalidBuyForValue = isInvalidBuyForValue || parseFloat(step.get('buyForValue')) < parseFloat(step.get('productPrice'));
    }

    if (step.get('orderTypeOption') === BROKER_ORDER_TYPE_LIMITED){
        isInvalidOrderTypeValue = (
            step.get('orderTypeValue') === '' || parseFloat(step.get('orderTypeValue')) <= 0
        );
        isAmountLowerThanLimit = (
            step.get('buyForOption') === BROKER_ETF_BUY_BUYFOR_AMOUNT &&
            step.get('buyForValue') < step.get('orderTypeValue')
        );
    }

    isETFsContractEmpty = step.get('stocksContractSelectedValue') === BROKER_NO_STOCKS_CONTRACT_SELECTED;

    const valid =
        !isStocksContractEmpty &&
        !isInvalidBuyForValue &&
        !isInvalidOrderTypeValue &&
        !isAmountLowerThanLimit &&
        !isETFsContractEmpty;

    return step.merge({
        errorFlags: {
            buyForValue: isInvalidBuyForValue,
            orderTypeValue: isInvalidOrderTypeValue,
            orderTypeValueLowerThanLimit: isAmountLowerThanLimit,
            isETFsContractEmpty: isETFsContractEmpty,
            stocksContract: isStocksContractEmpty
        },
        valid
    });
}

function brokerETFBuyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_ETF_BUY_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_ETF_BUY_STOCK_CONTRACT_CHANGED:
            return state.merge({
                stocksContractSelectedValue: action.payload.value,
                errorFlags: {
                    stocksContract: false
                }
            });
        case actionTypes.BROKER_ETF_BUY_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_ETF_BUY_BUYFOR_VALUE_RESET:{
            let newState;

            switch (state.get('buyForOption')) {
                case BROKER_ETF_BUY_BUYFOR_TITLES:
                    newState = state.merge({
                        buyForValue: null,
                        productPrice: action.payload.productPrice,
                        titleQuantity: null,
                        errorFlags: {
                            buyForValue: false,
                        }
                    });
                break;
                case BROKER_ETF_BUY_BUYFOR_AMOUNT:
                    newState = state.merge({
                        buyForValue: action.payload.value,
                        productPrice: action.payload.productPrice,
                        titleQuantity: null,
                        errorFlags: {
                            buyForValue: false,
                        }
                    });
                break;
            }

            return newState;
        }

        case actionTypes.BROKER_ETF_BUY_ORDER_TYPE_VALUE_RESET:
            return state.merge({
                orderTypeValue: action.payload.value,
                errorFlags: {
                    orderTypeValue: false
                }
            });
        case actionTypes.BROKER_ETF_BUY_BUYFOR_OPTION_CHANGED:
            return state.merge({
                buyForOption: action.payload.value
            });
        case actionTypes.BROKER_ETF_BUY_BUYFOR_VALUE_CHANGED: {
            let newState;

            switch (state.get('buyForOption')) {
                case BROKER_ETF_BUY_BUYFOR_TITLES:
                    newState = state.merge({
                        titleQuantity: action.payload.value,
                        buyForValue: action.payload.value
                    });
                break;
                case BROKER_ETF_BUY_BUYFOR_AMOUNT:
                    newState = state.merge({
                        buyForValue: action.payload.value,
                        titleQuantity: action.payload.titleQuantity
                    });
                break;
            }

            return newState;
        }
        case actionTypes.BROKER_ETF_BUY_ORDER_TYPE_OPTION_CHANGED:
            return state.merge({
                orderTypeOption: action.payload.value
            });
        case actionTypes.BROKER_ETF_BUY_ORDER_TYPE_VALUE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value
            });
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
            const firstDate = DateHelper.getNextBussinessDay(action.payload.isAvailableToday, action.payload.currentDate);
            const limitDate = DateHelper.getStockLimitDate(firstDate);
            const validityDate = DateHelper.getValidDate(firstDate, limitDate, state.get('validityDate'));
            const excludeDates = DateHelper.getExcludedWeekends(limitDate);
            
            return state.merge({
                firstDate,
                validityDate,
                limitDate,
                excludeDates
            });
        case actionTypes.BROKER_ETF_BUY_SET_HOLIDAY_ERROR:
            return state.setIn(['errorFlags', 'holidayError'], false);
        default:
            return state;
    }
}

module.exports = brokerETFBuyStep1;
