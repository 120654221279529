import countryNameHelper from 'utilities/countryNameHelper';
const getCountryISO3 = require("country-iso-2-to-3");
const { isOnlyOriginCountryEnable } = require('utilities/cardControl/cardControlHelper')

const mapOriginCountryZone = (zones, originCountry) => {
  const country = zones.reduce((acc, current) => {
    const country = current.countries.filter(country => {
      return country.name === getCountryISO3(originCountry);
    });
    return {
      ...acc,
      ...country
    }
  }, {})[0]
  return {
    name: country.name,
    nameLiteral: countryNameHelper(country.name),
    enabled: isOnlyOriginCountryEnable(zones, originCountry)
  }
}


export default mapOriginCountryZone
