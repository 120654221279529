//@vendor
const Immutable = require('immutable');
//@constants
const actionTypes = require('constants/actionTypes');
// @ utilities
const { isValidPin } = require('utilities/cardsHelper');

const initialState = Immutable.fromJS({
    isDirty: false,
    inProgress: false,
    newPin: '',
    newPinIsValid: false,
    newPinConfirmation: '',
    newPinConfirmationIsValid: false,
    setPinErrorMsg: false,
    success: false,
    valid: true,
    validationForced: false,
});

function validate(state, birthDate) {
    const newPin = state.get('newPin');
    const newPinConfirmation = state.get('newPinConfirmation');

    let valid = true;
    let newPinIsValid = true;
    let newPinConfirmationIsValid = true;

    if (!isValidPin(newPin, birthDate)) {
        valid = false;
        newPinIsValid = false;
    } else if (newPin !== newPinConfirmation) {
        valid = false;
        newPinConfirmationIsValid = false;
    }

    return state.merge({
        isDirty: true,
        newPinConfirmationIsValid,
        newPinIsValid,
        valid,
    });
}

function hireCardPinSetReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_PIN_SET_VALIDATE_STEP:
            return validate(state, action.payload.birthDate);

        case actionTypes.HIRE_CARD_PIN_SET_SET_PIN:
            return state.merge({
                [action.payload.pinType]: action.payload.pin
            });

        case actionTypes.HIRE_CARD_PIN_SET_SUCCESS:
            return state.merge({
                success: true,
            });

        case actionTypes.HIRE_CARD_PIN_SET_FAILURE:
            return state.merge({
                setPinErrorMsg: action.payload.errorMsg,
            });

        case actionTypes.HIRE_CARD_PIN_SET_FORCE_VALIDATE:
            return state.merge({
                valid: true,
                validationForced: true,
            });

        case actionTypes.HIRE_CARD_PIN_SET_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hireCardPinSetReducer;
