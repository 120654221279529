const Immutable = require('immutable');

const {
  CATEGORIES_SUMMARY_BOX_FETCHING,
  CATEGORIES_SUMMARY_BOX_SUCCESS,
  CATEGORIES_SUMMARY_BOX_FAILURE,
} = require('constants/actionTypes');

const setInitialState = () => {
  return Immutable.fromJS({
    error: false,
    fetching: false,
    categoriesSummaryBox: [],
  });
};

function expensesByCategoriesSummaryBox(state = setInitialState(), action) {
  switch (action.type) {
    case CATEGORIES_SUMMARY_BOX_FETCHING:
      return state.merge({
        error: false,
        fetching: true,
      });

    case CATEGORIES_SUMMARY_BOX_FAILURE:
      return state.merge({
        error: true,
        fetching: false,
      });
    case CATEGORIES_SUMMARY_BOX_SUCCESS:
      return state.merge({
        error: false,
        fetching: false,
        categoriesSummaryBox: Array.isArray(action.payload.data) ? action.payload.data : [],
      });

    default:
      return state;
  }
}

module.exports = expensesByCategoriesSummaryBox;
