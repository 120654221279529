/**
 * Created by adderlyjaureguicampos on 03/01/17.
 */
const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const { EURO_SYMBOL } = require('constants/index');

const contract = {
    title: 'contractsView-duoCombinedDepositContractTitle',
    service: '....',
    last_step: 5,
    code_op: 77,
    hireParamsFn: 'e_card_HireParams',
    SignParamsFn: 'e_card_SignParams',
    define_steps: 3,
    onCancelURL: '/myprofile/global',
    showWarningMessage:  true,
    showIntervenerControls: true,
    showGrupoamaCheckbox: true,
    warningContractMessage: 'contractsView-duoCombinedContractWarningMessage',
    operativeName: 'operatives-depositHire_duoCombinedDeposit',
    securityConfirmationLabel: 'contractsView-duoSecurityConfirmationLabel',
    codeConfirmationLabel: 'contractsView-duoCodeConfirmationLabel',
    readAndAcceptLabel: 'contractsView-duoReadAndAcceptLabel',
    showHelpBar: true,
    breadcrumb: [
        {
            text: 'breadcrumb-deposits',
            url: '/myprofile/deposits'
        },
        {
            text: 'contractsView-newDepositBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            {
                id: 'importe_depositar',
                label: 'Importe a invertir',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 1,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'in_deposit',
                label: 'En depósito',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 2,
                confirmation_step: {
                    columns: 3,
                    order: 2,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ]
            },
            {
                id: 'in_monetary_fund',
                label: 'En fondo',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 3,
                confirmation_step: {
                    columns: 3,
                    order: 3,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            {
                id: 'monetary_found',
                label: 'Fondo ',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 4,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ]
            },
            {
                type: 'separator',
                order: 5,
                visible: [
                    {
                        step: 1,
                        order: 5
                    },
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    },
                    {
                        step: 4,
                        order: 5
                    }
                ]
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 6,
                visible: [
                    {
                        step: 1,
                        order: 6
                    },
                    {
                        step: 2,
                        order: 6
                    },
                    {
                        step: 3,
                        order: 6
                    },
                    {
                        step: 4,
                        order: 6
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'intervinientes_list',
                label: 'Intervinientes',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 7,
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 2
                },
                visible: [
                    {
                        step: 1,
                        order: 7
                    },
                    {
                        step: 2,
                        order: 7
                    },
                    {
                        step: 3,
                        order: 7
                    },
                    {
                        step: 4,
                        order: 7
                    }
                ]
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'investmentFundList',
                value: null,
                visible: []
            }
        ]
    },
    steps: [
        step1,
        step2,
        step3
    ]
};

module.exports = {
    contract
};
