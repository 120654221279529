// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    cardBeneficiary: {
        personCode: '',
        personName: '',
        personType: '',
    },
    documentationState: {
        remainingDays: -1,
        state: '',
    },
    error: false,
    errorDescription: '',
    fetchBeneficiarySuccess: false,
    fetchDocumentationSuccess: false,
    isFetching: false,
});

function processBeneficiary(state, beneficiary) {
    const cardBeneficiary = {
        personCode: get(beneficiary, 'numeroPersonaCliente.codigodepersona', ''),
        personType: get(beneficiary, 'numeroPersonaCliente.tipodepersona', ''),
        personName: get(beneficiary, 'nombreBeneficiario', ''),
    };

    return state.merge({
        cardBeneficiary,
        fetchBeneficiarySuccess: true,
        isFetching: false,
    });
}

function processDocumentationState(state, documentation) {
    const documentationState = {
        remainingDays: get(documentation, 'diasRestantes'),
        state: get(documentation, 'estado'),
    };

    return state.merge({
        documentationState,
        fetchDocumentationSuccess: true,
        isFetching: false,
    });
}

function processDocumentationStateReset() {
    const documentationState = {
        remainingDays: -1,
        state: '',
    };

    return initialState.merge({
        documentationState,
        fetchDocumentationSuccess: false,
    });
}

function openYoungDocumentation(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_GET_CARD_BENEFICIARY_REQUEST:
        case actionTypes.OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_REQUEST:
            return state.merge({
                error: false,
                isFetching: true,
            });

        case actionTypes.OPEN_YOUNG_GET_BENEFICIARY_DOCUMENTATION_DATA_FAILURE:
            return state.merge({
                error: true,
                errorDescription: action.payload.errorDescription,
                isFetching: false,
            });

        case actionTypes.OPEN_YOUNG_GET_CARD_BENEFICIARY_SUCCESS:
            return processBeneficiary(state, action.payload.response);

        case actionTypes.OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_SUCCESS:
            return processDocumentationState(state, action.payload.response);
            
        case actionTypes.OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_RESET:
            return processDocumentationStateReset();
        
        case actionTypes.OPEN_YOUNG_RESET_BENEFICIARY_DOCUMENTATION_DATA:
            return initialState;

        default:
            return state;
    }
}

module.exports = openYoungDocumentation;




