// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @constants
const actionTypes = require('constants/actionTypes');
const { BROKER_ORDER_TYPE_TO_MARKET, BROKER_ORDER_TYPE_LIMITED } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        rightsQuantity: 0,
        rightsQuantityIsValid: true,
        rightsQuantityDirty: false,
        validityDate: moment(),
        orderTypeOption: BROKER_ORDER_TYPE_TO_MARKET,
        orderTypeValue: 0,
        loading: false,
        mustRunMifid: false,
        errorFlags: {
            minorThanLote: false,
            notMultiploLote: false
        }
    });
}

function validateStep(step, catalogData) {
    const rightsQuantity = step.get('rightsQuantity');
    const orderTypeOption = step.get('orderTypeOption');
    const orderTypeValue = step.get('orderTypeValue');
    const minorThanLote = catalogData.get('operatedByLot') ? rightsQuantity < catalogData.get('lot') : false;
    const notMultiploLote = catalogData.get('operatedByLot') ? !(rightsQuantity % catalogData.get('lot') == 0) : false;

    const valid = (orderTypeOption !== BROKER_ORDER_TYPE_LIMITED || 
        (orderTypeOption === BROKER_ORDER_TYPE_LIMITED && orderTypeValue > 0)) &&
        rightsQuantity > 0 && !notMultiploLote && !minorThanLote;

    return step.merge({
        valid,
        rightsQuantityIsValid: rightsQuantity > 0,
        rightsQuantityDirty: false,
        errorFlags: {
            minorThanLote,
            notMultiploLote
        }
    });
}

function brokerRightsBuyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_RIGHTS_BUY_CONTINUE_FROM_MIFID:
            return state.merge({
                loading: false,
                mustRunMifid: false
            });
        case actionTypes.BROKER_RIGHTS_BUY_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_RIGHTS_BUY_ORDER_TYPE_CHANGED:
            return state.merge({
                orderTypeOption: action.payload.value,
                orderTypeValue: 0
            });
        case actionTypes.BROKER_RIGHTS_BUY_LIMITED_VALUE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value
            });
        case actionTypes.BROKER_RIGHTS_BUY_RIGHTS_QUANTITY_CHANGED:
            return state.merge({
                rightsQuantity: action.payload.value,
                rightsQuantityDirty: true,
                errorFlags: {
                    minorThanLote: false,
                    notMultiploLote: false
                }
            });
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_UI_STEP:
            return validateStep(state, action.payload.catalogData);
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_REQUEST:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_SUCCESS:
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_FAILURE:
            return state.merge({
                loading: true,
                mustRunMifid: true
            });
        default:
            return state;
    }
}

module.exports = brokerRightsBuyStep1;
