const { prefixActionCodesInFlows, wrapActionCode } = require('./utilities')

/*
    The entries in this array, must be the name of the javascript files
    for each group of flows, without the extension
*/
const files = [
    'accounts',
    'authentication',
    'broker',
    'cardControl',
    'cardsHire',
    'card',
    'deposits',
    'devices',
    'documentation',
    'transfers',
    'mifid',
    'passwords',
    'profile',
    'global',
    'loansAndMortgages',
    'investments',
    'insurances',
    'robo',
    'signaturePattern'
]

const wrapupflows = {}
const secondaryFlows = []

files.forEach((file) => {
    const fileFlows = require(`./flows/${file}`)
    const { secondaryFlows: fileSecondaryFlows } = require(`./flows/${file}`)
    Object.assign(wrapupflows, fileFlows)
    secondaryFlows.push(...fileSecondaryFlows)
})

module.exports = {
    wrapupflows: prefixActionCodesInFlows(wrapupflows),
    secondaryFlows: Object.values(secondaryFlows).map(wrapActionCode)
}
