// @vendors
const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    activeTab: 0
});

const brokerLandingLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BROKER_LANDING_CHANGE_TAB:
            return state.merge({
                activeTab: action.payload.tab
            });
        case actionTypes.BROKER_LANDING_RESET_TAB:
            return state.set('activeTab', 0);
        default:
            return state;
    }
};

module.exports = brokerLandingLoaderReducer;