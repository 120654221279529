// @ vendors
const Inmutable = require('immutable');

// @ commons
const { get } = require('lodash');

// @ constants
const {
    MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_REQUEST,
    MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_SUCCESS,
    MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_FAILURE
} = require('constants/actionTypes');

const initialState = Inmutable.fromJS({
    load: false,
    isFetching: false,
    participantsList: [],
    state: {},
    error: ''
});

const getParticipants = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_SUCCESS:
            return state.merge({
                isFetching: false,
                state: action.data.participants,
                participantsList: get(action.data, 'participants.listaIntervinientes.lista', []),
                load: true
            });
        case MORTGAGE_REQUEST_FETCH_PARTICIPANTS_OPERATIVE_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.data.error
            })
        default:
            return state

    }
}

module.exports = getParticipants;
