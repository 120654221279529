// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isFetching: false,
    isFetched: false,
    fetchError: false,
    fetchErrorMessage: '',
    fundSecId: '',
    riskLevel: '',
    investmentStrategy: '',
    KIDSRI:'',
});

const copyDataFromResponse = response => {
    if (!response || !response[0]) {
        return {};
    }

    const data = response[0];

    return {
        fundSecId: data.Id ? data.Id : '',
        riskLevel: data.CollectedSRRI ? (data.CollectedSRRI.Rank ? data.CollectedSRRI.Rank : '') : '',
        investmentStrategy: data.InvestmentStrategy ? data.InvestmentStrategy : '',
        KIDSRI: data.KID ? (data.KID.SRI ? data.KID.SRI : '') : '',
    };
};

const fundLegalConfirmationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_START:
            return state.merge({
                isFetching: true,
                isFetched: false,
                fetchError: false,
                fetchErrorMessage: '',
                fundSecId: action.payload.fundSecId,
                riskLevel: '',
                investmentStrategy: '',
                KIDSRI:''
            });

        case actionTypes.FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_SUCCESS:
            return state.merge({
                isFetching: false,
                isFetched: true,
                fetchError: false,
                fetchErrorMessage: '',
            })
            .merge(copyDataFromResponse(action.payload));

        case actionTypes.FUND_LEGAL_CONFIRMATION_MORNINGSTAR_SECURITY_DETAILS_REQUEST_ERROR:
            return state.merge({
                isFetching: false,
                isFetched: true,
                fetchError: true,
                fetchErrorMessage: action.payload.error,
                fundSecId: action.payload.fundSecId,
                riskLevel: '',
                investmentStrategy: '',
                KIDSRI:'',
            });

        case actionTypes.FUND_LEGAL_CONFIRMATION_RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

module.exports = fundLegalConfirmationReducer;
