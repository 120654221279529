// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    taxes: {
        euribor: '',
        tae: '',
        tin: {
            fixed: '',
            variable: ''
        }
    },
    error: '',
    isFetching: false,
    taxesHasBeenFetched: false
});

const taxesInformation = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_TAXES_INFORMATION_REQUEST:
            return state.set('isFetching', true);

        case actionTypes.MORTGAGE_REQUEST_TAXES_INFORMATION_SUCCESS:
            const data = action.data;
            const euribor = !!data.euribor ? data.euribor : '';
            const tae = !!data.tae ? data.tae : '';
            const fixed = !!data.tin.fija ? data.tin.fija : '';
            const variable = !!data.tin.variable ? data.tin.variable : '';
            const taxes = {
                euribor,
                tae,
                tin: {
                    fixed,
                    variable
                }
            };
            return state.merge({
                taxes,
                error: null,
                isFetching: false,
                taxesHasBeenFetched: true
            });

        case actionTypes.MORTGAGE_REQUEST_TAXES_INFORMATION_ERROR:
            return state.merge({
                error: action.error,
                isFetching: false
            });
        default:
            return state;
    }
};

module.exports = taxesInformation;
