// @vendor
const Immutable = require('immutable');
const moment = require('moment');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { INITIAL_AMOUNT_OF_HISTORY_ROWS } = require('constants/contactCenter/cardsControlHistory');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            amountOfHistoryRows: INITIAL_AMOUNT_OF_HISTORY_ROWS,
            error: null,
            filterDateFrom: moment().startOf('day'),
            filterDateTo: moment().startOf('day'),
            hasMore: false,
            isFetching: false,
            isValidSelection: true,
            results: null
        })
    );
};

function validateSelection(from, to) {
    return (to.diff(from) >= 0);
}

function formatResults(results) {
    let byId = {};
    let byOrder = [];

    results.forEach((result) => {
        const parsedResult = {
            id: result.cardActionId,
            date: result.date,
            time: result.time,
            source: result.source,
            operative: result.operative
        }

        byId[parsedResult.id] = parsedResult;
        byOrder.push(parsedResult.id + '');
    });

    return {
        byId,
        byOrder
    };
}

function cardsControlHistory(state = setInitialState(), action) {
    let fromValue;
    let toValue;
    let isValidSelection;

    switch (action.type) {
        case actionTypes.CARDS_CONTROL_HISTORY_RESET:
            return setInitialState();

        case actionTypes.CARDS_CONTROL_HISTORY_SET_DATE_FROM:
            fromValue = action.payload.value;
            toValue = state.get('filterDateTo');
            isValidSelection = validateSelection(fromValue, toValue);

            return state.merge({
                amountOfHistoryRows: INITIAL_AMOUNT_OF_HISTORY_ROWS,
                error: null,
                filterDateFrom: action.payload.value,
                hasMore: false,
                isValidSelection,
                results: null,
            });

        case actionTypes.CARDS_CONTROL_HISTORY_SET_DATE_TO:
            fromValue = state.get('filterDateFrom');
            toValue = action.payload.value;
            isValidSelection = validateSelection(fromValue, toValue);

            return state.merge({
                amountOfHistoryRows: INITIAL_AMOUNT_OF_HISTORY_ROWS,
                error: null,
                filterDateTo: action.payload.value,
                hasMore: false,
                isValidSelection,
                results: null
            });

        case actionTypes.CARDS_CONTROL_HISTORY_QUERY_REQUEST:
            return state.merge({
                hasMore: false,
                isFetching: true
            });

        case actionTypes.CARDS_CONTROL_HISTORY_QUERY_SUCCESS:
            return state.merge({
                amountOfHistoryRows: action.payload.numActions,
                error: null,
                hasMore: action.payload.hasMore,
                isFetching: false,
                results: formatResults(action.payload.data.cardActions)
            })

        case actionTypes.CARDS_CONTROL_HISTORY_QUERY_FAILURE:
            return state.merge({
                amountOfHistoryRows: INITIAL_AMOUNT_OF_HISTORY_ROWS,
                error: action.payload.error,
                hasMore: false,
                isFetching: false
            })

        default:
            return state;
    }
}

module.exports = cardsControlHistory;
