const React = require('react');
const consents = require('constants/consents');

// @utilities
const { formatText } = require('core/i18n').i18n;
const {
    recordLink
 } = require('utilities/tagging');

const parseConsentsForUpdate = (immConsents) => {
    return immConsents.map(immConsent => ({
        consentCellId: immConsent.getIn(['consentCell', 'id']),
        status: immConsent.get('status'),
        channels: immConsent.has('channels') && immConsent.get('channels').size > 0 ?
            immConsent.get('channels').map(immChannel => ({id: immChannel.get('id'), accepted: immChannel.get('accepted')})).toJS()
            : undefined
    })).toJS();
};

const getOnlyChangedConsents = (initialConsents, updatedConsents) => {
    let result = [];
    initialConsents.forEach((consent, index) => {
        let changedConsent = {};
        for (let key in consent) {
            if (key === 'status' || key === 'consentCellId') {
                changedConsent[key] = updatedConsents[index][key];
            } else if (key === 'channels' && !!consent[key] && consent[key].size) {
                let isChannelChanged = false;
                consent[key].forEach((channel, i) => {
                    if (channel.accepted !== updatedConsents[index][key][i].accepted) {
                        isChannelChanged = true;
                    }
                });
                if (isChannelChanged) {
                    changedConsent[key] = [...updatedConsents[index][key]];
                }
            }
        }
        Object.keys(changedConsent).length && result.push(changedConsent);
    })
    return result.filter(consent => consent['channels']);
}

const getStateGdprConsent = (status) => {
    let statusGdpr;
    switch (status) {
        case consents.OKCONSENTS_POSITIVE_VALUE:
            statusGdpr = 'gdpr-accepted'
            break;
        case consents.OKCONSENTS_NEGATIVE_VALUE:
            statusGdpr = 'gdpr-notAccepted'
            break;
        default:
            statusGdpr = 'gdpr-pending'
            break;
    }

    return statusGdpr;
}

const recordGdprClauseInteraction = (value, category = 'interaccion clausulas GDPR', id = 'todas finalidades') => {
    recordLink(
        category,
        `Claúsula ${id}`,
        `resultado claúsula: ${value}`
    );
}

const buildLastStepInformation = (immConsents) => {
    const yes = [];
    const no = [];
    let content;

    immConsents.get('channels').map(immConsent => {
        if (immConsent.get('accepted')) {
            yes.push(formatText(immConsent.get('poEditorChannel')));
        } else {
            no.push(formatText(immConsent.get('poEditorChannel')));
        }
    });

    if (yes.length === 0) {
        content = <span>{formatText('gdpr-notAccepted')}</span>;
    } else {
        content = (
            <div>
                <p>{formatText('gdpr-accepted')}: {yes.join(', ')}</p>
                <p>{formatText('gdpr-notAccepted')}: {no.join(', ')}</p>
            </div>
        );
    }

    return content;
}

module.exports = {
    buildLastStepInformation,
    getOnlyChangedConsents,
    getStateGdprConsent,
    parseConsentsForUpdate,
    recordGdprClauseInteraction
};
