// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        emittedDateTime: 0,
        isEmitted: false
    });
}

function validateStep(step) {
    return step.merge({
        valid: true
    });
}

function brokerStocksSellStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_SELL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_STOCKS_SELL_FINISH:
            const newState = state.merge({
                emittedDateTime: moment(parseInt(action.payload.data)),
                isEmitted: true
            });
            return newState;
        default:
            return state;
    }
}

module.exports = brokerStocksSellStep2;
