const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
  ...(__CONTACT_CENTER__
    ? {
        agreementChecked: false,
        agreementIsDirty: false,
        valid: false,
        hireRestriction: false,
      }
    : {
        valid: false,
        hireRestriction: false,
      }),
});

function validateStep(state, validFlag) {
  if (validFlag !== undefined) {
    if (validFlag) {
      return state.merge({
        valid: false,
        hireRestriction: true,
      });
    } else {
      if(typeof state.get('agreementChecked') === 'boolean' && state.get('agreementChecked') === false){
        return state.merge({
          valid: state.get('agreementChecked'),
          hireRestriction: !state.get('agreementChecked'),
          agreementIsDirty: !state.get('agreementChecked'),
        });
      }
      else {
      return state.merge({
        valid: true,
        hireRestriction: false,
      });
    }
    }
  }
  return state.merge({
    valid: state.get('agreementChecked'),
    agreementIsDirty: true,
  });
}

function hireCardOpenYoungStep1(state = initialState, action = { type: null }) {
  switch (action.type) {
    case actionTypes.HIRE_CARD_OPEN_YOUNG_VALIDATE_STEP:
      return validateStep(state, action.payload.valid);
    case actionTypes.HIRE_CARD_OPEN_YOUNG_SET_AGREEMENT_CHECKBOX:
      return state.merge({
        agreementChecked: action.payload.checked,
        agreementIsDirty: false,
      });
    default:
      return state;
  }
}

module.exports = hireCardOpenYoungStep1;
