import { fromJS } from 'immutable';

import {
    BROKER_INIT_LEGAL_DOCUMENTATION,
    BROKER_SET_LEGAL_DOCUMENTATION_CHECKED,
    BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_SUCCESS,
    BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_FAILURE,
    BROKER_REMOVE_LEGAL_DOCUMENTATION,
    BROKER_SET_LEGAL_DOCUMENTATION_VALIDATE_FETCHING
} from 'constants/actionTypes';

const INITIAL_STATE = fromJS({
    isFetching: false,
    uuid: '',
    boxes: {}
});

const brokerLegalDocumentation = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case BROKER_INIT_LEGAL_DOCUMENTATION:
            return state.mergeDeep({
                boxes: {
                    [action.product]: {
                        documents: {...action.validDocuments},
                        completed: false,
                    },
                },
                uuid: action.uuid
            });
        case BROKER_SET_LEGAL_DOCUMENTATION_CHECKED:
            return state.merge({
                isFetching: true
            });
        case BROKER_SET_LEGAL_DOCUMENTATION_VALIDATE_FETCHING:
            return state.mergeDeep({
                boxes: {
                    [action.product]: {
                        documents: {
                            [action.documentType]: { 'isFetching': true },
                        },
                        completed: !action.incompleted
                    },
                }
            });
        case BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_SUCCESS:
            return state.mergeDeep({
                boxes: {
                    [action.product]: {
                        documents: {
                            [action.documentType]: { read: true, isFetching: false },
                        },
                        completed: !action.incompleted
                    },
                },
                isFetching: false
            });
        case BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_FAILURE:
            return state.mergeDeep({
                isFetching: false,
                boxes: {
                    [action.product]: {
                        documents: {
                            [action.documentType]: { read: false, isFetching: false },
                        },
                        completed: !action.incompleted
                    },
                },
            });

        case BROKER_REMOVE_LEGAL_DOCUMENTATION:
            return INITIAL_STATE

        default:
            return state;
    }
}

export default brokerLegalDocumentation;
