// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');
const { BROKER_ORDER_TYPE_TO_MARKET, BROKER_ORDER_TYPE_LIMITED } = require('constants/index');

function setInitialState() {
    let validityDate;

    return Immutable.fromJS({
        selectedOptionValue: -1,
        selectedOptionTitles: 0,
        titleQuantity: '',
        orderTypeOption: BROKER_ORDER_TYPE_TO_MARKET,
        orderTypeValue: 0,
        validityDate,
        limitDate: moment().add(90, 'days'),
        loading: false,
        valid: false,
        titleChangedManually: false,
        errorFlags: {
            notMultiploLote: false,
            minorThanLote: false
        }
    });
}

function validateStep(step, catalogData) {
    const orderTypeValid = step.get('selectedOptionValue') !== -1;
    const orderTypeValueValid =
        step.get('orderTypeOption') !== BROKER_ORDER_TYPE_LIMITED ||
        (step.get('orderTypeOption') === BROKER_ORDER_TYPE_LIMITED &&
            step.get('orderTypeValue') > 0);

    const titleQuantityValid = step.get('titleQuantity') !== '0' && step.get('titleQuantity') !== '';
    const titleQuantityLimitValid = step.get('titleQuantity') <= step.get('selectedOptionTitles');

    let isSellByLotError = false;
    let minorThanLote = false;
    let notMultiploLote = false;
    const operatedByLot = catalogData.get('operatedByLot');
    if (operatedByLot) {
        minorThanLote = step.get('titleQuantity') < catalogData.get('lot');
        notMultiploLote = !(step.get('titleQuantity') % catalogData.get('lot') == 0);
        isSellByLotError = (
            minorThanLote || notMultiploLote
        );
    }

    const valid =
        orderTypeValueValid && orderTypeValid && titleQuantityValid && titleQuantityLimitValid && !isSellByLotError;
    return step.merge({
        valid,
        orderTypeValueValid,
        titleQuantityValid,
        orderTypeValid,
        titleQuantityLimitValid,
        errorFlags: {
            minorThanLote,
            notMultiploLote
        }
    });
}

function brokerStocksAdvancedSellStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_UI_STEP:
            return validateStep(state, action.payload.catalogData);
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_STOCK_CONTRACT_CHANGED:
            return state.merge({
                selectedOptionValue: action.payload.value,
                selectedOptionTitles: action.payload.titles
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: action.payload.value,
                titleChangedManually: action.payload.manually,
                errorFlags: {
                    minorThanLote: false,
                    notMultiploLote: false
                }
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_ORDER_TYPE_CHANGED:
            return state.merge({
                orderTypeOption: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_LIMITED_VALUE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_REQUEST:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_SUCCESS:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_FAILURE:
            return state.merge({
                loading: false
            });
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedSellStep2;
