const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const { BROKER_IBEX35_STOCK_MARKET_NAME } = require('constants/index');

const initialState = () => Immutable.fromJS({
    fetched: false,
    fetching: false,
    market: BROKER_IBEX35_STOCK_MARKET_NAME,
    companies: []
});

const digestMarketConsensusResponse = (payload, state) => {
    const numberOfItems = 7; // to show first five, order is DESC
    const immTopFive = Immutable.fromJS(payload.data.resultados.slice(0, numberOfItems));

    return state.mergeIn(['fetching'], false)
                .mergeIn(['fetched'], true)
                .setIn(['companies'], immTopFive);
}

const brokerMarketConsensusReducer = (state = initialState(), action) => {

    switch (action.type) {

        case actionTypes.BROKER_MARKET_CONSENSUS_GET_FAILURE:
            //> TODO handle error
            return state.mergeIn(['fetching'], false)
                        .mergeIn(['fetched'], false);

        case actionTypes.BROKER_MARKET_CONSENSUS_GET_SUCCESS:
            return digestMarketConsensusResponse(action.payload, state);

        case actionTypes.BROKER_MARKET_CONSENSUS_IS_FETCHING:
            return state.mergeIn(['fetching'], true);

        default:
            return state;
    }
}

module.exports = brokerMarketConsensusReducer;
