//@vendors
const Immutable = require('immutable');

//@constants
const actionTypes = require('constants/contactCenter/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP
} = require('constants/index');

//@steps
const updateClientContactTimeStep1 = require('./steps/updateClientContactTimeStep1');
const updateClientContactTimeStep2 = require('./steps/updateClientContactTimeStep2');


function setInitialState() {
    return Immutable.fromJS({
        steps: [
            updateClientContactTimeStep1(undefined, {type: null}),
            updateClientContactTimeStep2(undefined, {type: null}),
            {hasSignaturePattern:true, operationParams:{}}
        ],
        visibleStep: 1,
        isFetching: false,
        hasBeenSent: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return updateClientContactTimeStep1(step, action);
            case SECOND_STEP:
                return updateClientContactTimeStep2(step, action);
        }
    });
}

function UpdateClientContactTime(state = setInitialState(), action) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.UPDATE_CONTACT_TIME_VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.UPDATE_CONTACT_TIME_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.UPDATE_CONTACT_TIME_IN_PROGRESS:
            return state.merge({
                isFetching:true,
                hasBeenSent: false,
                error:''
            });
        case actionTypes.UPDATE_CONTACT_TIME_ERROR:
            return state.merge({
                isFetching:false,
                hasBeenSent: false,
                error: action.payload.error,
            });

        case actionTypes.UPDATE_CONTACT_TIME_SUCCESS:
            return state.merge({
                isFetching:false,
                hasBeenSent: true,
                error: ''
            });
        case actionTypes.UPDATE_CONTACT_TIME_FROM_TIME:
            const partialStepFromTime = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialStepFromTime
            });
        case actionTypes.UPDATE_CONTACT_TIME_TO_TIME:
            const partialStepToTime = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialStepToTime
            });
        case actionTypes.UPDATE_CONTACT_TIME_RESET:
            return setInitialState();

        default:
            return state;
    }
}

module.exports = UpdateClientContactTime;
