import { call, put, takeLatest } from 'redux-saga/effects';
import { APIGet } from 'utilities/APIRequestHelper';
import apiURLBuilder from 'core/apiURLBuilder';
import { RECEIPT_GET_PREVIEW } from 'constants/actionTypes';
import { receiptGetPreviewFailure, receiptSetPreview } from 'actions/accountDebits/receipts';

function* receiptGetPreview({ accountId, receiptId, codprod, numsor, prod, numCont, centro, idioma }) {
   try {
        const requestParams = {
            dispatch: null,
            endpoint: 'accountDebits',
            url: apiURLBuilder.getURL('accountDebits'),
            query: {
                codprod,
                numsor,
                prod,
                numCont,
                centro,
                idioma
                // dpiRender: dpi
            }
        }
        const response = yield call(APIGet, null, apiURLBuilder.getURL('accountDebitsPreview'), requestParams);
        yield put(receiptSetPreview(accountId, receiptId, response.pdfThumbnail))
    } catch (e) {
        yield put(receiptGetPreviewFailure(accountId, receiptId, e.error));
   }
}

function* receiptsSagas() {
    yield [
        takeLatest(RECEIPT_GET_PREVIEW, receiptGetPreview)
    ]
}

export default [
    receiptsSagas
]


