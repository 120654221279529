// @ vendors
const Immutable = require('immutable');
const { sortByOrder } = require('lodash/collection');
const get = require('lodash/object/get');
const now = require('lodash/date/now');
const trim = require('lodash/string/trim');
// @ constants
const { EURO_TEXT } = require('constants/index');
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    errorPeriods: '',
    isFetching: false,
    isFetchingPeriods: false
});

function sortLiquidations(liquidations) {
    return sortByOrder(liquidations, ['fechaFinalPeriodoLiq'], ['desc']);
}

function updateLiquidations(liquidations) {
    let byId = {};
    let byOrder = [];
    let totalPaid = 0;

    liquidations.map((liquidation, index) => {
        const parsedLiquidations = {
            id: now() + index.toString(),
            payment: {
                amount: get(liquidation, 'importeLiquido.importe', 0),
                currency: get(liquidation, 'importeLiquido.divisa', EURO_TEXT)
            },
            periodFromDate: liquidation.fechaComienzoPeriodoLiq || '',
            periodToDate: liquidation.fechaFinalPeriodoLiq || ''
        };

        byId[parsedLiquidations.id] = parsedLiquidations;
        byOrder.push(parsedLiquidations.id);
        totalPaid = totalPaid + parsedLiquidations.payment.amount;
    });

    return {
        byId: byId,
        byOrder: byOrder,
        totalPaid: totalPaid
    };
}

function updateLiquidationsDetails(details) {
    let updatedDetails = {};

    if (details.length) {
        updatedDetails = {
            creditInterest: {
                payment: {
                    currency: details[0].importeLiquido.divisa,
                    amount: details[0].importeLiquido.importe
                },
                type: details[0].tasaLiquidacion
            },
            taxRetention: {
                payment: {
                    currency: details[1].importeLiquido.divisa,
                    amount: details[1].importeLiquido.importe
                },
                type: details[1].tasaLiquidacion
            }
        };
    }

    return updatedDetails;
}

function liquidations(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_LIQUIDATIONS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_LIQUIDATIONS_SUCCESS:
            const sortedLiquidations = sortLiquidations(action.payload.liquidations);

            return state.merge(updateLiquidations(sortedLiquidations));
        case actionTypes.FETCH_LIQUIDATIONS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.FETCH_LIQUIDATIONS_DETAILS_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.liquidationId]: updateLiquidationsDetails(action.payload.details)
                }
            });
        case actionTypes.FETCH_LIQUIDATIONS_DETAILS_FAILURE:
            return state.merge({
                error: action.payload.error
            });
        case actionTypes.FETCH_LIQUIDATIONS_DETAILS_COMPLETED:
            return state.merge({
                isFetching: false
            });
        case actionTypes.FETCH_LIQUIDATIONS_PERIOD_REQUEST:
            return state.merge({
                isFetchingPeriods: true
            });
        case actionTypes.FETCH_LIQUIDATIONS_PERIOD_SUCCESS:
            return state.merge({
                isFetchingPeriods: false,
                errorPeriods: '',
                liquidationType: trim(action.payload.description)
            });
        case actionTypes.FETCH_LIQUIDATIONS_PERIOD_FAILURE:
            return state.merge({
                errorPeriods: action.payload.error,
                isFetchingPeriods: false
            });
        case actionTypes.RESET_LIQUIDATIONS_FAILURE:
            return state.merge({
                error: '',
                errorPeriods: '',
                isFetchingPeriods: false
            });
        default:
            return state
    }
}

module.exports = liquidations;
