module.exports = {
    title: 'contractsView-upgradeToPayrollAccountSelectedAccount',
    order: 3,
    footer_text: '',
    position: 'middle',
    override_validation_step: 2,
    components: [
        {
            id_component: 'dropdown-accounts',
            label: 'contractsView-upgradeToPayrollAccountStep2DropdownAccountLabelPreselectedAccount',
            options: [],
            type: 'single',
            isIntervenerAvailable: true,
            showIntervenersOnlyInCard: true,
            onChangeIntervenerFn: 'upgrade_to_payroll_account_onChangeIntervener',
            target: 'cta_seleccionada',
            targetIntervener: 'intervinientes_list',
            order: 1,
            isMaxAmountLabelAvailable: false,
            required: true
        }
    ]
};