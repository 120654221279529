// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const isEmpty = require('lodash/lang/isEmpty');
const trim = require('lodash/string/trim');
// @ utilities
const {
    defaultCallTimeValue,
    getBrokerCallTimeOptions,
    getPrivateCallTimeOptions
} = require('utilities/callTimeOptions');
// @ constants
const {
    BROKER_CALLMEBACK_TYPE,
    BROKER_CALLMEBACK_MAX_COMMENT_CHARS,
    BROKER_CALLMEBACK_INITIAL_TEL_CODE,
    BROKER_CALLMEBACK_PAGE_TITLE,
    BROKER_CALLMEBACK_PAGE_PATH,
    NON_DOTS_FORMAT_TEL_REGEXP,
    BROKER_CALLMEBACK_VALID_TEL_REG_EX,
    BROKER_CALLMEBACK_VALID_TEL_CODE_REG_EX,
    CALL_TIME_OPTIONS_STATUS_OK
} = require('constants/index');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');
const actionTypes = require('constants/actionTypes');

const modalInitialState = () => Immutable.Map({
    type: BROKER_CALLMEBACK_TYPE,
    message: '',
    telcode: `+${BROKER_CALLMEBACK_INITIAL_TEL_CODE}`,
    phone: '',
    profilePhone: '',
    callTime: defaultCallTimeValue(),
    validTelCode: true,
    validTel: false,
    valid: false
});

const initialState = () => Immutable.Map({
    showModal: false,
    callTimeOptions: getPrivateCallTimeOptions(),
    pageTitle: BROKER_CALLMEBACK_PAGE_TITLE,
    pagePath: BROKER_CALLMEBACK_PAGE_PATH,
    requestCallSent: false,
    successRequestCall: false,
    isFetching: false,
    getSpecialistsError: '',
    status: CALL_TIME_OPTIONS_STATUS_OK,
    requestingCall: false
}).merge(modalInitialState());

const validate = immState => {
    const profilePhone = immState.get('profilePhone');
    const phone = immState.get('phone');
    const telcode = immState.get('telcode');
    const validTelCode = BROKER_CALLMEBACK_VALID_TEL_CODE_REG_EX.test(telcode);
    const phoneEqualsToProfilePhone = phone == profilePhone;
    const phoneHasValidFormat = BROKER_CALLMEBACK_VALID_TEL_REG_EX.test(phone);
    const validTel = phone && (phoneEqualsToProfilePhone || phoneHasValidFormat);

    return immState.merge({
        validTelCode,
        validTel,
        valid: validTelCode && validTel
    });
};

function updateAssignedSpecialist(assignedSpecialist) {
    let formattedAssignedSpecialist = {};

    if (!isEmpty(assignedSpecialist)) {
        const fullName = get(assignedSpecialist, 'nombre', '');

        formattedAssignedSpecialist = {
            email: get(assignedSpecialist, 'email', ''),
            imageUrl: get(assignedSpecialist, 'urlImagen', ''),
            name: fullName,
            phone: get(assignedSpecialist, 'telefono', '').replace(NON_DOTS_FORMAT_TEL_REGEXP, ' '),
            timetable: get(assignedSpecialist, 'horarios', {})
        };
    }

    return formattedAssignedSpecialist;
}

function updateSpecialists(data) {
    const assignedSpecialist = get(data, 'gestor', {});
    const specialists = get(data, 'oficina.gestores', []);
    let formattedAssignedSpecialist = updateAssignedSpecialist(assignedSpecialist);
    let formattedSpecialists = [];

    specialists.forEach(specialist => {
        const fullName = get(specialist, 'nombre', '');
        const parsedSpecialist = {
            email: get(specialist, 'email', ''),
            imageUrl: get(specialist, 'urlImagen', ''),
            name: fullName,
            phone: get(specialist, 'telefono', '').replace(NON_DOTS_FORMAT_TEL_REGEXP, ' '),
            specialistId: get(specialist, 'numeroEmpleado', '')
        };

        if (
            !isEmpty(formattedAssignedSpecialist) &&
            formattedAssignedSpecialist.name === parsedSpecialist.name
        ) {
            formattedSpecialists.unshift(parsedSpecialist);
        } else {
            formattedSpecialists.push(parsedSpecialist);
        }
    });

    return {
        assignedSpecialist: formattedAssignedSpecialist,
        isFetching: false,
        office: {
            alias: get(data, 'oficina.alias', '').replace('O', ''),
            email: get(data, 'oficina.email', ''),
            name: get(data, 'oficina.nombre', ''),
            phone: get(data, 'oficina.telefono', '').replace(NON_DOTS_FORMAT_TEL_REGEXP, ' '),
            specialists: formattedSpecialists
        },
        segment: get(data, 'segmento', '')
    };
}

function brokerInvestmentSpecialists(state = initialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_CALLMEBACK_RESET:
            return initialState();
        case actionTypes.BROKER_CALLMEBACK_SET_PROFILE_PHONE:
            const cleanProfilePhone = trim(action.payload.profilePhone).replace(/ /g, '');
            return state.set('profilePhone', cleanProfilePhone);
        case actionTypes.BROKER_CALLMEBACK_VALIDATION:
            return validate(state);
        case actionTypes.BROKER_CALLMEBACK_TOGGLE_MODAL:
            const showModal = state.get('showModal');
            return state.set('showModal', !showModal).merge(modalInitialState());
        case actionTypes.BROKER_CALLMEBACK_CHANGE_TEL_CODE:
            return state.set('telcode', action.payload.telcode);
        case actionTypes.BROKER_CALLMEBACK_CHANGE_TEL:
            const cleanPhone = trim(action.payload.phone).replace(/ /g, '');
            return state.set('phone', cleanPhone);
        case actionTypes.BROKER_CALLMEBACK_GET_CALL_TIME:
            const countryUser = action.payload.country ? action.payload.country : DEFAULT_ORIGIN_COUNTRY;
            return state.set('callTimeOptions', getBrokerCallTimeOptions(countryUser));
        case actionTypes.BROKER_CALLMEBACK_CHANGE_CALL_TIME:
            return state.set('callTime', action.payload.callTime);
        case actionTypes.BROKER_CALLMEBACK_CHANGE_COMMENT:
            const message = action.payload.message || '';
            return state.set('message', message.substring(0, BROKER_CALLMEBACK_MAX_COMMENT_CHARS));
        case actionTypes.CALLMEBACK_GET_CALL_TIME:
            return state.set('callTimeOptions', getPrivateCallTimeOptions());
        case actionTypes.CALLMEBACK_RESET:
            return state.merge({
                requestCallSent: false
            });
        case actionTypes.CALLMEBACK_IN_PROGRESS:
            return state.merge({
                requestingCall: true
            });
        case actionTypes.CALLMEBACK_SUCCESS:
            return state.merge({
                requestingCall: false,
                requestCallSent: true,
                successRequestCall: true,
                status: CALL_TIME_OPTIONS_STATUS_OK
            });
        case actionTypes.CALLMEBACK_FAILURE:
            return state.merge({
                requestingCall: false,
                requestCallSent: true,
                successRequestCall: false
            });

        case actionTypes.BROKER_GET_SPECIALISTS:
            return state.merge({
                isFetching: true
            });
        case actionTypes.BROKER_GET_SPECIALISTS_SUCCESS:
            return state.merge(updateSpecialists(action.payload.specialistsData));
        case actionTypes.BROKER_GET_SPECIALISTS_FAILURE:
            return state.merge({
                getSpecialistsError: action.payload.error,
                isFetching: false
            });
        default:
            return state;
    }
}

module.exports = brokerInvestmentSpecialists;
