const find = require('lodash/collection/find')
const has = require('lodash/object/has')
const get = require('lodash/object/get')
const endsWith = require('lodash/string/endsWith')
const mapValues = require('lodash/object/mapValues')
const _ = require('utilities/contactCenter/lodashfp')
const { traceFlowEvent } = require('utilities/contactCenter/wrapUp/wrapUpCore')
const {
    OPERATION_LOG_TYPE_ENTRY,
    OPERATION_LOG_DONE
} = require('utilities/contactCenter/wrapUp/constants')
const { wrapupflows } = require('./wrapupflows')
const { wrapActionCode } = require('./utilities')
// These paths do not start new flows
const NOT_FLOW_STARTER_PATHS = [
    '/myprofile/broker/error',
    '/myprofile/robo/customPlanByObjective',
    '/myprofile/robo/hireManagementPlan/objective'
]

const hasEntryPath = log => has(log, 'entry.path')

const buildUsdRouteLogger = logs => {
    return (store, { location }) => {
        const state = store.getState()
        const deposits = state.deposits.toJS()
        const logsByActionCode = { ...logs, ...deposits.retentionFlows}

        const routes = mapValues(_.pickBy(hasEntryPath)(logsByActionCode), (log, actionCode) => {
            const originalPath = get(log, 'entry.path')
            const check = get(log, 'entry.check')
            const finalPath = endsWith(originalPath, '/') ?
                originalPath.slice(0, -1) : originalPath

            return {
                actionCode: actionCode,
                path: finalPath,
                check
            }
        })

        const route = find(routes, route => {
            const regex = new RegExp(`^${route.path}$`, 'i')
            const pathname = endsWith(location.pathname, '/') ?
                location.pathname.slice(0, -1) : location.pathname
            return regex.test(pathname)
        })

        if (route) {
            let { actionCode, check } = route
            traceFlowEvent.start(actionCode)

            let alreadyAccomplishedDependencies = false
            if (check) {
                const operationStateObj = check(location.pathname, state);
                const {
                    operationState
                } = operationStateObj
                actionCode = operationStateObj.actionCode ? wrapActionCode(operationStateObj.actionCode) :
                    actionCode;
                alreadyAccomplishedDependencies = operationState === OPERATION_LOG_DONE
            }

            // Check if the flow has entry associated actions,
            //  if not, trace the event as OK
            const successActions = get(logsByActionCode[actionCode], 'entry.success')
            const allDone = !successActions || successActions.length === 0 || alreadyAccomplishedDependencies
            if (allDone) {
                traceFlowEvent.notifyFlow(actionCode, OPERATION_LOG_TYPE_ENTRY, OPERATION_LOG_DONE)
            }
        } else {
            if (!NOT_FLOW_STARTER_PATHS.includes(location.pathname)) {
                traceFlowEvent.reset()
            }
        }
    }
}

const usdRouteLogger = buildUsdRouteLogger(wrapupflows)

module.exports = {
    usdRouteLogger,
    buildUsdRouteLogger
}
