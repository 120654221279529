// @ constants
const {
    PRINTER_OPEN_PREVIEW,
    DOCUMENTS_PRINTED_AND_SIGNED_RADIO_CHANGED,
    PRINTED_INDICATORS_RESET,
    SHOW_PRINT_REQUIRED,
    SHOW_PRINTED_AND_SIGNED_STATUS_REQUIRED,
    PRINTER_CLEAN_ERROR_MESSAGE,
    PRINTED_AND_SIGNED_SUCCESS,
} = require('constants/contactCenter/actionTypes')

// @ vendors
const Immutable = require('immutable')

function setInitialState() {
    return Immutable.Map({
        isShowingPreview: false,
        showPrintedDocumentsConfirmation: false,
        documentsPrintedAndSigned: '',
        printRequired: false,
        printedAndSignedStatusRequired: false,
        printedAndSignedSuccess: false,
    });
}

function printer(state = setInitialState(), action) {
    switch (action.type) {
        case PRINTER_OPEN_PREVIEW:
            return state.merge({
                isShowingPreview: true,
                printRequired: false,
                showPrintedDocumentsConfirmation: true,
            })
        case DOCUMENTS_PRINTED_AND_SIGNED_RADIO_CHANGED:
            return state.merge({
                documentsPrintedAndSigned: action.payload.value,
            })
        case SHOW_PRINT_REQUIRED:
            return state.merge({
                printRequired: true,
            })
        case SHOW_PRINTED_AND_SIGNED_STATUS_REQUIRED:
            return state.merge({
                printedAndSignedStatusRequired: true,
            })
        case PRINTER_CLEAN_ERROR_MESSAGE:
            return state.merge({
                printRequired: false,
                printedAndSignedStatusRequired: false
            })
        case PRINTED_AND_SIGNED_SUCCESS:
            return state.merge({
                printRequired: false,
                printedAndSignedStatusRequired: false,
                printedAndSignedSuccess: true,
            })
        case PRINTED_INDICATORS_RESET:
            return setInitialState();
        default:
            return state
    }
}

module.exports = printer
