// @ vendor
const Immutable = require('immutable');

// @ constants
const {
    CARD_DATA_REQUEST,
    CARD_DATA_SUCCESS,
    CARD_DATA_FAILURE,
} = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isFetching: false,
    error: '',
    cardData: {},
});

function cardData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CARD_DATA_REQUEST:
            return INITIAL_STATE.merge({
                isFetching: true,
            });
        case CARD_DATA_FAILURE:
            return INITIAL_STATE.merge({
                error: action.payload.error
            });
        case CARD_DATA_SUCCESS:
            return INITIAL_STATE.merge({
                cardData: action.payload
            });
        default:
            return state;
    }
}

module.exports = cardData;
