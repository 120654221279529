// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: false,
    errorDescription: '',
    fetchSuccess: false,
    isFetching: false,
    names: [],
    selectedIndex: 0,
    valid: false,
});

function hireCardNameSelectorReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_NAME_SELECTOR_SET_ID:
            return state.merge({
                selectedIndex: action.payload.id,
            });

        case actionTypes.HIRE_CARD_NAME_SELECTOR_REQUEST:
            return state.merge({
                error: false,
                errorDescription: '',
                fetchSuccess: false,
                isFetching: true,
            });

        case actionTypes.HIRE_CARD_NAME_SELECTOR_SUCCESS:
            return state.merge({
                error: false,
                fetchSuccess: true,
                isFetching: false,
                names: action.payload.names,
            });

        case actionTypes.HIRE_CARD_NAME_SELECTOR_FAILURE:
            return state.merge({
                error: true,
                errorDescription: action.payload.error,
                isFetching: false,
            });

        case actionTypes.HIRE_CARD_NAME_SELECTOR_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hireCardNameSelectorReducer;
