// @vendor
const get = require('lodash/object/get');

// @utils
const { unwrapActionCode } = require('utilities/contactCenter/wrapUp/utilities');

// @constants
const {
    RA_SUITABILITY_GET_TEST_RESULT_FAILURE,
    RA_CURRENT_PROJECTION_CHART_FAILURE,
    RA_SUITABILITY_GET_TEST_RESULT_SUCCESS,
    RA_SUITABILITY_GET_TEST_QUESTIONS_SUCCESS,
    RA_SUITABILITY_GET_TEST_QUESTIONS_FAILURE,
    RA_SUITABILITY_TEST_HISTORICAL_TEST_SUCCESS,
    RA_CURRENT_PROJECTION_CHART_SUCCESS,
    RA_PORTFOLIO_DISTRIBUTION_GET_DISTRIBUTION_SUCCESS,
    RA_PORTFOLIO_DISTRIBUTION_GET_DISTRIBUTION_FAILURE,
    RA_SIMULATION_REQUEST_SUCCESS,
    RA_SIMULATION_REQUEST_FAILURE,
    RA_GET_LAST_MOVEMENT_SUCCESS,
    RA_GET_LAST_MOVEMENT_FAILURE,
    RA_CANCEL_PLAN_FAILURE,
    RA_CANCEL_PLAN_SUCCESS,
    RA_HIRE_MANAGEMENT_PLAN_SET_PDF,
    RA_MANAGEMENT_PLAN_CHANGE_PRODUCT_ERROR,
    RA_MANAGEMENT_PLAN_CHANGE_PRODUCT_SUCCESS,
    RA_MANAGEMENT_PLAN_GET_INVESTMENT_PROFILE_FAILURE,
    RA_MANAGEMENT_PLAN_GET_INVESTMENT_PROFILE_SUCCESS,
    RA_SUITABILITY_TEST_SIGN_TEST_FAILURE,
    RA_SUITABILITY_TEST_SEND_TEST_FAILURE,
    RA_CHECK_STATUS_FAILURE,
    RA_CHECK_STATUS_SUCCESS,
    RA_ASSOCIATE_OBJECTIVE_SUCCESS,
    RA_DISSOCIATE_OBJECTIVE_SUCCESS,
    RA_PORTFOLIO_HISTORY_REQUEST_SUCCESS,
    RA_PORTFOLIO_HISTORY_REQUEST_FAILURE,
    RA_HIRE_MANAGEMENT_PLAN_FINISH,
    RA_HIRE_MANAGEMENT_PLAN_CREATE_ACCOUNT_ERROR,
} = require('wealth-robo/src/raConstants/actionTypes');
const { FIRST_STEP } = require('constants/index');
const { RA_INVESTMENT_TYPE_FOUNDS } = require('wealth-robo/src/raConstants/index');
const {
    BROKER_ERROR
} = require('constants/actionTypes');

const { RA_SUITABILITY_TEST_SIGN_TEST_SUCCESS } = require('wealth-robo/src/raConstants/contactCenter/actionTypes');

const { SUITABILITY_TEST_VALID } = require('wealth-robo/src/raConstants/index');

const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE,
    FLOW_TYPE_ONLY_ACCESS
} = require('utilities/contactCenter/wrapUp/constants');

const ACTION_CODE_GET_DISCRETIONAL_MANAGEMENT_DETAIL_ACCOUNT = 5234;
const ACTION_CODE_GLOBAL_POSITION_DISCRETIONAL_MANAGEMENT = 5188;
const ACTION_CODE_CONTRACT_DISCRETIONAL_MANAGEMENT_WITH_OBJECTIVES = 5189;
const ACTION_CODE_DISCRETIONAL_MANAGEMENT_MOVEMENTS_AND_ORDERS = 5190;
const ACTION_CODE_CONTRACT_DISCRETIONAL_MANAGEMENT = 5192;
const ACTION_CODE_ADDITIONAL_CONTRIBUTION = 5193;
const ACTION_CODE_QUERY_CONTRACT_DISCRETIONAL_MANAGEMENT_WITH_GOALS = 5194;
const ACTION_CODE_CONSULT_DISCRETIONAL_MANAGEMENT = 5195;
const ACTION_CODE_MODIFY_PLAN = 5196;
const ACTION_CODE_CANCEL_PERIODIC_CONTRIBUTIONS = 5198;
const ACTION_CODE_CANCEL_DISCRETIONARY_MANAGEMENT_WITH_GOALS = 5199;
const ACTION_CODE_CANCEL_DISCRETIONARY_MANAGEMENT = 5200;
const ACTION_CODE_REIMBURSEMENT_DISCRETIONARY_MANAGEMENT_WITH_GOALS = 5201;
const ACTION_CODE_REIMBURSEMENT_DISCRETIONARY_MANAGEMENT = 5202;
const ACTION_CODE_SUITABILITY_TEST = 5191;
const ACTION_CODE_WEALTH_FAQ = 5233;
const ACTION_CODE_QUERY_INVERSTOR_PROFILE = 5227;
const ACTION_CODE_QUERY_SUITABILITY_TEST = 5228;
const ACTION_CODE_SUITABILITY_TEST_REDO = 5229;
const ACTION_CODE_QUERY_CONTRACT_DISCRETIONAL_MANAGEMENT_WITH_GOALS_DETAIL = 5236;
const ACTION_CODE_ASSOCIATE_OBJECTIVE = 5252;
const ACTION_CODE_DISSOCIATE_OBJECTIVE = 5253;
const ACTION_CODE_HISTORY_OF_PORTFOLIO = 5261;
const ACTION_CODE_CUSTOM_PLAN_BY_OBJECTIVE = 5262;
const ACTION_CODE_HIRE_CUSTOM_PLAN_BY_OBJECTIVE = 5265;
const ACTION_CODE_PORTFOLIO_PURCHASED_WITH_FUND = 5263;
const ACTION_CODE_OBJECTIVE_PURCHASED_WITH_FUND = 5264;
const ACTION_CODE_HIRE_PURE_PORTFOLIO = 5267;

let lastCode;
let initialStatus, initialPath = '';

module.exports = {
    [ACTION_CODE_GLOBAL_POSITION_DISCRETIONAL_MANAGEMENT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/general/robo'
        }
    },
    [ACTION_CODE_CONTRACT_DISCRETIONAL_MANAGEMENT_WITH_OBJECTIVES]: {
        entry: {
            path: '/myprofile/robo/setGoal',
            failure: [
                { action: RA_SIMULATION_REQUEST_FAILURE }
            ],
            success: [
                { action: RA_SIMULATION_REQUEST_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CUSTOM_PLAN_BY_OBJECTIVE]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/goal/customPlanByObjective',
        }
    },
    [ACTION_CODE_HIRE_CUSTOM_PLAN_BY_OBJECTIVE] : {
        entry: {
            path: '/myprofile/robo/hire/hireManagementPlan/(objective|gbi)+',
            failure: [
                { action: RA_SUITABILITY_GET_TEST_RESULT_FAILURE }
            ],
            success: [
                { action: RA_SUITABILITY_GET_TEST_RESULT_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_PURE_PORTFOLIO] : {
        entry: {
            path: '/myprofile/robo/hire/hireManagementPlan',
            failure: [
                { action: RA_SUITABILITY_GET_TEST_RESULT_FAILURE }
            ],
            success: [
                { action: RA_SUITABILITY_GET_TEST_RESULT_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_DISCRETIONAL_MANAGEMENT_MOVEMENTS_AND_ORDERS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/lastMovements(/[A-Z]+/[a-z0-9]+)?',
            failure: [
                { action: RA_GET_LAST_MOVEMENT_FAILURE }
            ],
            success: [
                { action: RA_GET_LAST_MOVEMENT_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CONTRACT_DISCRETIONAL_MANAGEMENT]: {
        entry: {
            path: '/myprofile/robo/management',
            failure: [
                { action: RA_MANAGEMENT_PLAN_GET_INVESTMENT_PROFILE_FAILURE },
            ],
            success: [
                { action: RA_MANAGEMENT_PLAN_GET_INVESTMENT_PROFILE_SUCCESS },
                { action: RA_HIRE_MANAGEMENT_PLAN_SET_PDF }
            ]
        }
    },
    [ACTION_CODE_ADDITIONAL_CONTRIBUTION]: {
        entry: {
            path: '/myprofile/robo/additionalContribution'
        }
    },
    [ACTION_CODE_QUERY_CONTRACT_DISCRETIONAL_MANAGEMENT_WITH_GOALS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/objectiveTracking/[\\w]+'
        }
    },
    [ACTION_CODE_QUERY_CONTRACT_DISCRETIONAL_MANAGEMENT_WITH_GOALS_DETAIL]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/goalDistribution/[\\w]+'
        }
    },
    [ACTION_CODE_CONSULT_DISCRETIONAL_MANAGEMENT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/portfolioTracking/[\\w]+'
        }
    },
    [ACTION_CODE_GET_DISCRETIONAL_MANAGEMENT_DETAIL_ACCOUNT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/getAccountDetails/[\\w]|[\%]+'
        }
    },
    [ACTION_CODE_MODIFY_PLAN]: {
        entry: {
            path: '/myprofile/robo/modifyPlan/[\\w]+',
            failure: [
                { action: RA_PORTFOLIO_DISTRIBUTION_GET_DISTRIBUTION_FAILURE },
                { action: RA_SUITABILITY_GET_TEST_RESULT_FAILURE },
                { action: RA_CURRENT_PROJECTION_CHART_FAILURE }
            ],
            success: [
                { action: RA_PORTFOLIO_DISTRIBUTION_GET_DISTRIBUTION_SUCCESS },
                { action: RA_SUITABILITY_GET_TEST_RESULT_SUCCESS },
                { action: RA_CURRENT_PROJECTION_CHART_SUCCESS }
            ]
        },
        finish: {
            action: [  RA_MANAGEMENT_PLAN_CHANGE_PRODUCT_ERROR,  RA_MANAGEMENT_PLAN_CHANGE_PRODUCT_SUCCESS  ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;

                if (action.type === RA_MANAGEMENT_PLAN_CHANGE_PRODUCT_ERROR) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    operationState: operationState,
                    actionCode: ACTION_CODE_MODIFY_PLAN
                }
            }
        }
    },
    [ACTION_CODE_CANCEL_PERIODIC_CONTRIBUTIONS]: {
        entry: {
            path: '/myprofile/robo/cancelPeriodicContributions/[\\w]+'
        }
    },
    [ACTION_CODE_CANCEL_DISCRETIONARY_MANAGEMENT]:{
        entry: {
            path: '/myprofile/robo/cancelPlan/[\\w]+',
            check: (path, state) => {
                const idPortfolio = path.split('/')[4];
                const immPortfolio = state.raGlobalPosition.get("portfolios").find(function(obj){return obj.get('id') === idPortfolio;});
                const hasGoal = immPortfolio.get('hasGoal');

                const operationState = OPERATION_LOG_DONE;
                let actionCode = ACTION_CODE_CANCEL_DISCRETIONARY_MANAGEMENT;

                if (hasGoal) {
                    actionCode = ACTION_CODE_CANCEL_DISCRETIONARY_MANAGEMENT_WITH_GOALS;
                }

                lastCode = actionCode;

                return {
                    operationState,
                    actionCode
                }
            }
        },
        finish: {
            action: [  RA_CANCEL_PLAN_FAILURE ,  RA_CANCEL_PLAN_SUCCESS  ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === RA_CANCEL_PLAN_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    operationState: operationState,
                    actionCode: lastCode
                }
            }
        }
    },
    [ACTION_CODE_REIMBURSEMENT_DISCRETIONARY_MANAGEMENT]:{
        entry: {
            path: '/myprofile/robo/reimbursement/[\\w]+',
            check: (path, state) => {
                const idPortfolio = path.split('/')[4];
                const immPortfolio= state.raGlobalPosition.get("portfolios").find(function(obj){return obj.get('id') === idPortfolio;});
                const hasGoals = immPortfolio && immPortfolio.get('hasGoal');

                const operationState = OPERATION_LOG_DONE;
                let actionCode = ACTION_CODE_REIMBURSEMENT_DISCRETIONARY_MANAGEMENT;

                if (hasGoals) {
                    actionCode = ACTION_CODE_REIMBURSEMENT_DISCRETIONARY_MANAGEMENT_WITH_GOALS;
                }

                return {
                    operationState,
                    actionCode
                }
            }
        }
    },
    [ACTION_CODE_SUITABILITY_TEST]: {
        entry: {
            action: [
                RA_SUITABILITY_GET_TEST_QUESTIONS_SUCCESS,
                RA_SUITABILITY_GET_TEST_QUESTIONS_FAILURE,
                RA_SUITABILITY_GET_TEST_RESULT_SUCCESS,
                RA_SUITABILITY_GET_TEST_RESULT_FAILURE
            ],
            check: (action, state, traceFlowEvent) => {
                const immCheckStatus = state.raCheckStatus;
                const immSuitabilityTest = state.raSuitabilityTest;
                const path = get(state, 'routing.locationBeforeTransitions.pathname');
                const currentActionCode = unwrapActionCode(traceFlowEvent.getCurrentActionCode());

                // Cases for exclusion:
                // 1) the action was thrown from a different URL
                // 2) the action was thrown before showing a modal and exiting the view
                // 3) the action was thrown in the middle of the operation
                if (path !== '/myprofile/robo/test'
                    || immSuitabilityTest.get('isNonresidentialModalOpen')
                    || immSuitabilityTest.get('isErrorFetchingCountryOfResidenceModalOpen')
                    || !!currentActionCode) {
                    return ({
                        operationState: OPERATION_LOG_NONE,
                        actionCode: ACTION_CODE_SUITABILITY_TEST
                    });
                }

                initialStatus = immCheckStatus.get('checkStatus');

                let operationState;
                switch(action.type) {
                    case RA_SUITABILITY_GET_TEST_QUESTIONS_SUCCESS:
                    case RA_SUITABILITY_GET_TEST_RESULT_SUCCESS:
                        operationState = OPERATION_LOG_DONE;
                        break;
                    case RA_SUITABILITY_GET_TEST_QUESTIONS_FAILURE:
                    case RA_SUITABILITY_GET_TEST_RESULT_FAILURE:
                        operationState = OPERATION_LOG_FAIL;
                        break;
                }

                const actionCode = (initialStatus === SUITABILITY_TEST_VALID) ? ACTION_CODE_SUITABILITY_TEST_REDO : ACTION_CODE_SUITABILITY_TEST;

                return {
                    operationState,
                    actionCode
                }
            }
        },
        finish: {
            action: [
                RA_SUITABILITY_TEST_SIGN_TEST_FAILURE,
                RA_SUITABILITY_TEST_SEND_TEST_FAILURE,
                RA_SUITABILITY_TEST_SIGN_TEST_SUCCESS
            ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;

                if (action.type === RA_SUITABILITY_TEST_SIGN_TEST_FAILURE || action.type === RA_SUITABILITY_TEST_SEND_TEST_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                }

                const actionCode = (initialStatus === SUITABILITY_TEST_VALID) ? ACTION_CODE_SUITABILITY_TEST_REDO : ACTION_CODE_SUITABILITY_TEST;
                initialStatus = undefined;

                return {
                    operationState: operationState,
                    actionCode
                }
            }
        }
    },
    [ACTION_CODE_ASSOCIATE_OBJECTIVE]: {
        entry: {
            path: '/myprofile/robo/associateObjective/[\\w]+',
            check: (path) => {
                initialPath = path;

                return {
                    operationState: OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_ASSOCIATE_OBJECTIVE
                }
            }
        },
        finish: {
            action: [
                RA_ASSOCIATE_OBJECTIVE_SUCCESS,
                BROKER_ERROR,
            ],
            check: (action) => {
                let operationState = OPERATION_LOG_NONE;
                if (initialPath.includes('/myprofile/robo/associateObjective/')) {
                    operationState = action.type === RA_ASSOCIATE_OBJECTIVE_SUCCESS ?
                        OPERATION_LOG_DONE :
                        OPERATION_LOG_FAIL;
                }
                initialPath = '';

                return {
                    actionCode: ACTION_CODE_ASSOCIATE_OBJECTIVE,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_DISSOCIATE_OBJECTIVE]: {
        entry: {
            path: '/myprofile/robo/dissociateObjective/[\\w]+',
            check: (path) => {
                initialPath = path;

                return {
                    operationState: OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_DISSOCIATE_OBJECTIVE
                }
            }
        },
        finish: {
            action: [
                RA_DISSOCIATE_OBJECTIVE_SUCCESS,
                BROKER_ERROR,
            ],
            check: (action) => {
                let operationState = OPERATION_LOG_NONE;
                if (initialPath.includes('/myprofile/robo/dissociateObjective/')) {
                    operationState = action.type === RA_DISSOCIATE_OBJECTIVE_SUCCESS ?
                        OPERATION_LOG_DONE :
                        OPERATION_LOG_FAIL;
                }
                initialPath = '';

                return {
                    actionCode: ACTION_CODE_DISSOCIATE_OBJECTIVE,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_PORTFOLIO_PURCHASED_WITH_FUND]: {
        entry: {
            path: '/myprofile/robo/hire/hireManagementPlan',
        },
        finish: {
            action: [
                RA_HIRE_MANAGEMENT_PLAN_FINISH,
                RA_HIRE_MANAGEMENT_PLAN_CREATE_ACCOUNT_ERROR,
            ],
            check: (action, state) => {
                const immHireManagementPlan = state.raHireManagementPlan;
                const isHiringWithFunds = immHireManagementPlan.getIn(['steps', FIRST_STEP, 'investmentType']) === RA_INVESTMENT_TYPE_FOUNDS;

                let operationState = OPERATION_LOG_NONE;
                if (isHiringWithFunds) {
                    operationState = action.type === RA_HIRE_MANAGEMENT_PLAN_FINISH ?
                        OPERATION_LOG_DONE :
                        OPERATION_LOG_FAIL;
                }

                return {
                    actionCode: ACTION_CODE_PORTFOLIO_PURCHASED_WITH_FUND,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_OBJECTIVE_PURCHASED_WITH_FUND]: {
        entry: {
            path: '/myprofile/robo/hire/hireManagementPlan/objective',
        },
        finish: {
            action: [
                RA_HIRE_MANAGEMENT_PLAN_FINISH,
                RA_HIRE_MANAGEMENT_PLAN_CREATE_ACCOUNT_ERROR,
            ],
            check: (action, state) => {
                const immHireManagementPlan = state.raHireManagementPlan;
                const isHiringWithFunds = immHireManagementPlan.getIn(['steps', FIRST_STEP, 'investmentType']) === RA_INVESTMENT_TYPE_FOUNDS;

                let operationState = OPERATION_LOG_NONE;
                if (isHiringWithFunds) {
                    operationState = action.type === RA_HIRE_MANAGEMENT_PLAN_FINISH ?
                        OPERATION_LOG_DONE :
                        OPERATION_LOG_FAIL;
                }

                return {
                    actionCode: ACTION_CODE_OBJECTIVE_PURCHASED_WITH_FUND,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_HISTORY_OF_PORTFOLIO]: {
        entry: {
            action: [
                RA_PORTFOLIO_HISTORY_REQUEST_SUCCESS,
                RA_PORTFOLIO_HISTORY_REQUEST_FAILURE
            ],
            check: (action) => {
                let operationState;
                if (action.type === RA_PORTFOLIO_HISTORY_REQUEST_SUCCESS) {
                    operationState = OPERATION_LOG_DONE;
                } else {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    operationState,
                    actionCode: ACTION_CODE_HISTORY_OF_PORTFOLIO
                }
            }
        }
    },
    [ACTION_CODE_QUERY_INVERSTOR_PROFILE]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/testStatus',
            success: [
                { action: RA_SUITABILITY_TEST_HISTORICAL_TEST_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_WEALTH_FAQ]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/faq',
            failure: [
                { action: RA_CHECK_STATUS_FAILURE }
            ],
            success: [
                { action: RA_CHECK_STATUS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_QUERY_SUITABILITY_TEST]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/robo/test/readOnly',
            failure: [
                { action: RA_SUITABILITY_GET_TEST_QUESTIONS_FAILURE }
            ],
            success: [
                { action: RA_SUITABILITY_GET_TEST_QUESTIONS_SUCCESS }
            ]
        }
    }
}
