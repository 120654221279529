// @vendors
const Immutable = require('immutable');

// @helpers
const DateHelper = require('utilities/dateHelper');
const actionTypes = require('constants/actionTypes');

// @constants
const {
    BROKER_CONDITION_OVER_WARRANT_PRICE,
    BROKER_DEFAULT_CONDITION_TYPE_ID,
    BROKER_DEFAULT_CONDITION_TYPE_VALUE
} = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        //"Tipo de orden" radio button
        orderType: BROKER_CONDITION_OVER_WARRANT_PRICE,
        //Option selected in "Tipo de condición" dropdown list
        conditionType: BROKER_DEFAULT_CONDITION_TYPE_ID,
        //"Valor de la condición" input value
        conditionTypeValue: BROKER_DEFAULT_CONDITION_TYPE_VALUE,
        //"Plazo de validez" date
        validityDate: DateHelper.getImmediateBussinessDay(), // @TODO important check if still is valid time at moment of submit

        invalidConditionTypeValue: false,
        valid: false
    });
}

function validateStep(step) {
    const invalidConditionTypeValue = +step.get('conditionTypeValue') === BROKER_DEFAULT_CONDITION_TYPE_VALUE;

    return step.merge({
        invalidConditionTypeValue,
        valid: !invalidConditionTypeValue
    });
}

function brokerWarrantsAdvancedBuyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERMS_CHANGED:
            return state.merge({
                orderType: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED:
            return state.merge({
                conditionType: action.payload.value,
                conditionTypeValue: 0
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED:
            return state.merge({
                conditionTypeValue: action.payload.value
            });

        default:
            return state;
    }
}

module.exports = brokerWarrantsAdvancedBuyStep1;
