const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        fromAccount: '',
        fromAccountBalance: '',
        fromAccountIsValid: true, //doesn't check required
        fromAccountIsDirty: false, //avoid showing error message before first user interaction
        toAccount: '',
        toAccountCurrency: '',
        toAccountIsDirty: false, //avoid showing error message before first user interaction
        amount: 0,
        amountIsValid: false,
        amountIsDirty: false, //avoid showing error message before first user interaction
        concept: '',
        conceptIsDirty: false,
        isToday: true,
    })
}

function validateFromAccount(step) {
    function checkFromAccount() {
        const fromAccountBalance = step.get('fromAccountBalance');
        const isToday = step.get('isToday');

        if (typeof fromAccountBalance === 'number' && fromAccountBalance > 0) {
            // original account balance has funds
            return true;
        } else if (!isToday) {
            // the transfer will be on a future date
            return true;
        }
        return false;
    }

    return step.merge({
        // if from account is empty we skip the validation and reset valid to default value
        fromAccountIsValid: step.get('fromAccount') === '' ? true : checkFromAccount()
    });
}

function validateAmount(step) {
    function checkAmount(){
        const isToday = step.get('isToday');
        const amount = step.get('amount');
        const maxTransferLimit = step.get('maxTransferLimit');
        const fromAccountBalance = step.get('fromAccountBalance');
        
        if(maxTransferLimit && amount > maxTransferLimit){
            return false;
        } 
        else {
        if (amount > 0) {
            // If no original account has been selected yet, we asume valid until we have a value to compare
            if (typeof fromAccountBalance !== 'number') {
                return true;
            }

            // original account has been selected
            if (amount <= fromAccountBalance){
                // Original account has been selected and has enough balance
                return true;
            } else if (!isToday){
                // Original account has not enough balance, but the transfer is future so we omit checking
                return true;
            }
        }
    }
        return false;
    }

    return step.merge({
        amountIsValid : checkAmount()
    });
}

function validateStep(state) {
    const currentState = validateAmount(validateFromAccount(state));
    const fromAccount = currentState.get('fromAccount');
    const fromAccountIsValid = currentState.get('fromAccountIsValid');
    const toAccount = currentState.get('toAccount');
    const amountIsValid = currentState.get('amountIsValid');

    let valid = !!fromAccount && fromAccountIsValid && !!toAccount && amountIsValid;

    return currentState.merge({
        valid,
        fromAccountIsDirty: true,
        toAccountIsDirty: true,
        amountIsDirty: true,
        conceptIsDirty: true
    });
}

function passStep1Reducer(state = setInitialState(), action) {
    let newState;
    switch (action.type) {
        case actionTypes.PASS_RESET:
            return setInitialState();
        case actionTypes.PASS_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.PASS_FROM_ACCOUNT:
            newState = state.merge({
                fromAccount: action.payload.accountId,
                fromAccountBalance: action.payload.amount,
                fromAccountIsDirty: true,
                ...(action.payload.isPredefinedToAccount ? {} : { toAccount: '' }),
            });
            return validateAmount(validateFromAccount(newState));
        case actionTypes.PASS_TO_ACCOUNT:
            return state.merge({
                toAccount: action.payload.accountId,
                toAccountCurrency: action.payload.currency,
                toAccountIsDirty: true,
            });
        case actionTypes.PASS_AMOUNT:
            newState = state.merge({
                amount: action.payload.amount,
                amountIsDirty: true,
                maxTransferLimit: action.payload.maxTransferLimit
            });
            return validateAmount(newState)
        case actionTypes.PASS_CONCEPT:
            return state.merge({
                concept: action.payload.concept,
                conceptIsDirty: true
            });
        default:
            return state
    }
}

module.exports = passStep1Reducer;
