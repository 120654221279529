const actionTypes = require('../constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.Map({
    idSeed: 0,
    registeredTooltips: Immutable.List(),
    currentlyVisibleTooltip: null
});

function tooltipReducer(state = initialState, action) {
    const registeredTooltips = state.get('registeredTooltips');
    switch (action.type) {
        case actionTypes.ADD_TOOLTIP:
            return state.merge({
                idSeed: state.get('idSeed') + 1,
                registeredTooltips: registeredTooltips.push(action.id)
            });
        case actionTypes.REMOVE_TOOLTIP:
            const index = registeredTooltips.indexOf(action.id);
            return state.merge({
                registeredTooltips: registeredTooltips.delete(index)
            });
        case actionTypes.SHOW_TOOLTIP:
            return state.merge({
                currentlyVisibleTooltip: action.id
            });
        case actionTypes.HIDE_TOOLTIP:
            return state.merge({
                currentlyVisibleTooltip: null
            });
        default:
            return state
    }
}

module.exports = tooltipReducer;
