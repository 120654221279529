module.exports = {
    title: 'contractsView-contractsView_precontractualConditionsTitle',
    order: 3,
    footer_text: '',
    position: 'middle',
    disableNext: 'depositnm_pollingNextButtonDisabled',
    on_next_click: 'depositnm_loadTermsAndConditionsLegalWindow',
	components: [
		{
			id_component: 'precontractual-conditions',
			id: 'precontractual-conditions',
		},
        ...(
                __CONTACT_CENTER__ ?
                [{
                    id_component: 'legal-information'
                }] : []
            )
	]
};