// @vendors
const Immutable = require('immutable');

// @reducers
const cardsCancelDeferPurchaseStep1Reducer = require('./cardCancelDeferPurchaseStep1');

// @helpers
const get = require('lodash/object/get');

// @constants
const { FIRST_STEP } = require('constants/index');
const {
    CARDS_CANCEL_DEFER_PURCHASE_FAILURE,
    CARDS_CANCEL_DEFER_PURCHASE_INPROGRESS,
    CARDS_CANCEL_DEFER_PURCHASE_SET_VISIBLE_STEP,
    CARDS_CANCEL_DEFER_PURCHASE_SUCCESS,
    CARDS_CANCEL_DEFER_PURCHASE_VALIDATE_STEP,
    CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_ERROR,
    CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_INPROGRESS,
    CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_SUCCESS,
    RESET_CARDS_CANCEL_DEFER_PURCHASE
} = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        cancelDeferPurchaseData: {},
        fetchError: false,
        isFetching: false,
        fetchSuccess: false,
        isLoading: false,
        submitSuccessful: false,
        error: false,
        visibleStep: 1,
        steps: [
            cardsCancelDeferPurchaseStep1Reducer(undefined, {}),
            { hasSignaturePattern: true }
        ]
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardsCancelDeferPurchaseStep1Reducer(step, action);
        }
    })
}

function processGetCancelDeferPurchaseInfo(state, payload) {
    return state.merge({
        cancelDeferPurchaseData: payload.response,
        fetchError: false,
        isFetching: false,
        fetchSuccess: true,
    });
}

function cardCancelDeferPurchaseReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_SUCCESS:
            return processGetCancelDeferPurchaseInfo(state, action.payload);
        case CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_ERROR:
            return state.merge({
                fetchError: get(action, 'payload.error', true),
                isFetching: false
            });
        case CARDS_GET_CANCEL_DEFER_PURCHASE_INFO_INPROGRESS:
            return state.merge({
                isFetching: true,
                fetchError: false
            });
        case CARDS_CANCEL_DEFER_PURCHASE_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case CARDS_CANCEL_DEFER_PURCHASE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case CARDS_CANCEL_DEFER_PURCHASE_SUCCESS:
            return state.merge({
                isLoading: false,
                submitSuccessful: true,
                error: '',
            });
        case CARDS_CANCEL_DEFER_PURCHASE_INPROGRESS:
            return state.set('isLoading', true);
        case CARDS_CANCEL_DEFER_PURCHASE_FAILURE:
            return state.merge({
                isLoading: false,
                submitSuccessful: false,
                error: get(action, 'payload.error', true),
            });
        case RESET_CARDS_CANCEL_DEFER_PURCHASE:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = cardCancelDeferPurchaseReducer;
