const Storage = require('utilities/storage');
const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');

function setInitialState(){
    let name = Storage.localStorage.get('userFirstName') || '';
    name = name.trim();
    const rememberUser = !!Storage.localStorage.get('rememberUser');
    const isAuthenticated = window.__DEBUG_MODE__ ? !!Storage.cookies.get('tokenCredential') : true;
    return Immutable.Map({
       name,
       rememberUser,
       isAuthenticated,
       username: '',
       usernameType: '',
       isAuthenticating: false,
       sessionExpired: false,
       statusText: '',
       firstRun: true,
   });
}

function login(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return state.merge({
                isAuthenticating: true,
                isAuthenticated: false,
                statusText: '',
            });
        case actionTypes.LOGIN_SUCCESS:
            return state.merge({
                username: action.payload.username,
                name: action.payload.name,
                rememberUser: action.payload.rememberUser,
                isAuthenticating: false,
                sessionExpired: false,
                isAuthenticated: true,
                statusText: '',
                firstRun: false
            });
        case actionTypes.LOGIN_FAILURE:
            return state.merge({
                isAuthenticating: false,
                sessionExpired: false,
                isAuthenticated: false,
                statusText: action.payload.error,
                firstRun: false
            });
        case actionTypes.LOGIN_CHANGE_USER:
            return state.merge({
                username: '',
                name: '',
                rememberUser: false,
                firstRun: true
            });
        case actionTypes.LOGOUT_SUCCESS:
            const rememberUser = state.get('rememberUser');
            return state.merge({
                isAuthenticating: false,
                isAuthenticated: false,
                sessionExpired: true,
                username: rememberUser ? state.get('username') : '',
                name: rememberUser ? state.get('name') : ''
            });

        case actionTypes.FETCH_PROFILE_SUCCESS:
            const profile = action.profile;
            return state.merge({
                username: get(profile, 'documentoIdentificacion.codigodocumpersonacorp' , ''),
                usernameType: get(profile, 'documentoIdentificacion.tipodocumpersonacorp' , '')
            });
        default:
            return state
    }
}

module.exports = login;
