const isString = require('lodash/lang/isString');

const entity_table = [
    { htmlCode:'&amp;',value:'&'},
    { htmlCode:'&#225;',value:'á'},
    { htmlCode:'&#233;',value:'é'},
    { htmlCode:'&#237;',value:'í'},
    { htmlCode:'&#243;',value:'ó'},
    { htmlCode:'&#250;',value:'ú'},
    { htmlCode:'&#241;',value:'ñ'},
    { htmlCode:'&#44;',value:','},
    { htmlCode:'&#193;',value:'Á'},
    { htmlCode:'&#201;',value:'É'},
    { htmlCode:'&#205;',value:'Í'},
    { htmlCode:'&#211;',value:'Ó'},
    { htmlCode:'&#218;',value:'Ú'},
    { htmlCode:'&#209;',value:'Ñ'},
    { htmlCode:'&#44;',value:','}
];

const safeCheck = (val) => {
    if (!val || !isString(val)) {
        return val;
    }
}

const SanitizeHelper = {

    /**
     * Sanitize broker product name
     * @param {String} val unsafe value.
     * @returns {String}
     */
    sanitizeData(val) {
        safeCheck(val);
        const txt = document.createElement('textarea');
        txt.innerHTML = val;
        return txt.value;
    },

    /**
     * Encode to ISO-8859-1
     * @param {String} val unsafe value.
     * @returns {String}
     */
    encodeData(val) {
        safeCheck(val);
        entity_table.map(function(obj){
            val= val.replace(obj.value, obj.htmlCode);
        });
        return val;
    }
};

module.exports = SanitizeHelper;
