// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @constants
const {
    BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING,
    BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_SUCCESS,
    BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_ERROR
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    fetching: false,
    fetched: false,
    error: undefined,
    titular: '',
    beneficiaries: [],
    associatedAccount: ''
});

const mapBeneficiaries = beneficiaries => beneficiaries.map(beneficiary => beneficiary.nombreBeneficiario);

function brokerPensionPlanBeneficiariesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING:
            return INITIAL_STATE.merge({
                fetching: true
            });
        case BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_SUCCESS:
            const { response } = action.payload;
            return state.merge({
                fetching: false,
                fetched: true,
                titular: trim(response.apellidosYNombreTitular),
                beneficiaries: mapBeneficiaries(get(response, 'beneficiarios.beneficiario', [])),
                associatedAccount: trim(get(response, 'cuentaAsociadaIban.codbban'))
            });
        case BROKER_PENSION_PLAN_BENEFICIARIES_FETCHING_ERROR:
            return state.merge({
                fetching: false,
                fetched: true,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = brokerPensionPlanBeneficiariesReducer;
