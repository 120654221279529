const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { decodeHTMLEntities } = require('utilities/stringHelper');
const trim = require('lodash/string/trim');
const { BROKER_TODAYS_MAIN_STOCKS_DEFAULT_MARKET } = require('constants/index');

const initialState = Immutable.fromJS({
    error: false,
    loading: false,
    indexes: Immutable.List([])
});

function filterIndexes(indexes, wantedIndexesIds) {
    return indexes.filter(index => {
        const id = trim(index.instrumentId);
        return wantedIndexesIds.indexOf(id) >= 0;
    });
}

function sortIndexes(indexes) {
    // (Andres): We need to send IBEX 35 to the top of the list
    let ibex35ArrayIndex = -1;

    indexes.forEach((item, arrayIndex) => {
        if ( trim(item.instrument) === BROKER_TODAYS_MAIN_STOCKS_DEFAULT_MARKET ){
            ibex35ArrayIndex = arrayIndex;
        }
    });

    // (Andres): If for some reason we didn't find IBEX 35, let's return the original array
    if ( ibex35ArrayIndex === -1 ){
        return indexes;
    }

    const ibex35 = indexes.splice(ibex35ArrayIndex, 1)[0];
    indexes.unshift(ibex35);
    return indexes;
}

function indexesFailure() {
    return initialState.set('error', true);
}

function indexesFetching() {
    return initialState.merge({
        loading: true,
        error: false
    });
}

function indexesAdapter(response, exchangeIndexes) {
    const wantedIndexesIds = exchangeIndexes.map(item => trim(item.instrumentId));
    const filteredIndexes = sortIndexes(filterIndexes(response, wantedIndexesIds));
    return Immutable.fromJS({
        loading: false,
        error: false,
        indexes: Immutable.List(filteredIndexes.map(index => {
            return Immutable.fromJS({
                name: decodeHTMLEntities(index.instrument.trim()),
                last: index.last,
                hour: index.tradeTime,
                updateHour: index.updateTime,
                day: index.tradeDate,
                updateDay: index.updateDate,
                minimum: index.low,
                maximum: index.high,
                isin: trim(index.instrumentId),
                iso: trim(index.miccode),
                indexValue: {
                    variation: index.netChangePrice,
                    percentage: index.netChangePercentage,
                    upOrDown: index.netChangePrice > 0 ? 'up' : 'down'
                }
            });
        }))
    });
}

function brokerIndexesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_INDEXES_FAILURE:
            return indexesFailure();
        case actionTypes.BROKER_GET_INDEXES_FETCHING:
            return indexesFetching();
        case actionTypes.BROKER_GET_INDEXES_SUCCESS:
            return indexesAdapter(action.payload.data, action.payload.exchangeIndexes);
        default:
            return state;
    }
}

module.exports = brokerIndexesReducer;
