const FiltersHelper = {
    findActiveFilters(filters) {
        const filter = filters.find( filter => {
            return filter.get('isActive');
        });

        return !!filter;
    },
    countActiveFilters(filters) {
        let actives = 0;
        filters.forEach( filter => {
            if (filter.get('isActive') || filter.get('active')) {
                actives++;
            }
        });

        return actives;
    }
}

module.exports = FiltersHelper;
