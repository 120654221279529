//@vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
//@actions
const actionTypes = require('constants/contactCenter/actionTypes');
//@constants
const { FIRST_STEP, WIZARD_FIRST_STEP } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            {
                valid: false,
                depositValue: 0,
                hasRenewal: 'S',
                errorState: false
            },
            {hasSignaturePattern: true}
        ],
        addImpositionValidation: {},
        addImpositionConf: {},
        isFetching: false,
        error: false,
        submitSuccessful: false,
        isFetchingValidation: false,
        visibleStep: WIZARD_FIRST_STEP,
        willCancel: false
    });
}

function validateStep1(state, depositsData) {
    const depositValue = state.getIn(['steps', FIRST_STEP, 'depositValue']);
    const importeMin = depositsData.getIn(['depositsData', 'importeMin']);
    const importeMax = depositsData.getIn(['depositsData', 'importeMax']);

    if(depositValue >= importeMin && depositValue <= importeMax) {
        return state.merge({
            steps: state.get('steps').setIn([FIRST_STEP, 'valid'], true).setIn([FIRST_STEP, 'errorState'], false),
        });
    }
    else {
        return state.mergeDeep({
            steps: state.get('steps').setIn([FIRST_STEP, 'valid'], false).setIn([FIRST_STEP, 'errorState'], true),
        });
    }
}

function mapValidationResponse (response) {
    if (!response) {
        return {};
    }
    else {
        return {
            "links": get(response, 'links'),
            "response": {
                "aimpmar": get(response, 'response.aimpmar'),
                "aimporm": get(response, 'response.aimporm'),
                "aimpors": get(response, 'response.aimpors'),
                "arefconc": get(response, 'response.arefconc'),
                "areferen": get(response, 'response.areferen'),
                "arefernn": get(response, 'response.arefernn'),
                "aresdat1": get(response, 'response.aresdat1'),
                "aresdat2": get(response, 'response.aresdat2'),
                "aresdat3": get(response, 'response.aresdat3'),
                "aresdat4": get(response, 'response.aresdat4'),
                "aresdat5": get(response, 'response.aresdat5'),
                "aresdat6": get(response, 'response.aresdat6'),
                "aresdat7": get(response, 'response.aresdat7'),
                "avisopr": get(response, 'response.avisopr'),
                "b2CODCT": get(response, 'response.b2CODCT'),
                "b2EMPCT": get(response, 'response.b2EMPCT'),
                "caso": get(response, 'response.caso'),
                "codclcom": get(response, 'response.codclcom'),
                "codmocta": get(response, 'response.codmocta'),
                "codmons1": get(response, 'response.codmons1'),
                "codmons2": get(response, 'response.codmons2'),
                "codmonsw": get(response, 'response.codmonsw'),
                "codperiv": get(response, 'response.codperiv'),
                "codpers": get(response, 'response.codpers'),
                "coestre1": get(response, 'response.coestre1'),
                "coestref": get(response, 'response.coestref'),
                "coperiod": get(response, 'response.coperiod'),
                "coperliq": get(response, 'response.coperliq'),
                "cotperi1": get(response, 'response.cotperi1'),
                "cotperio": get(response, 'response.cotperio'),
                "desccom2": get(response, 'response.desccom2'),
                "descod": get(response, 'response.descod'),
                "desestan": get(response, 'response.desestan'),
                "diamaxpe": get(response, 'response.diamaxpe'),
                "digitoc1": get(response, 'response.digitoc1'),
                "digitoc2": get(response, 'response.digitoc2'),
                "digitoc3": get(response, 'response.digitoc3'),
                "digitoc4": get(response, 'response.digitoc4'),
                "doclin1": get(response, 'response.doclin1'),
                "doclin10": get(response, 'response.doclin10'),
                "doclin11": get(response, 'response.doclin11'),
                "doclin12": get(response, 'response.doclin12'),
                "doclin13": get(response, 'response.doclin13'),
                "doclin14": get(response, 'response.doclin14'),
                "doclin15": get(response, 'response.doclin15'),
                "doclin2": get(response, 'response.doclin2'),
                "doclin3": get(response, 'response.doclin3'),
                "doclin4": get(response, 'response.doclin4'),
                "doclin5": get(response, 'response.doclin5'),
                "doclin6": get(response, 'response.doclin6'),
                "doclin7": get(response, 'response.doclin7'),
                "doclin8": get(response, 'response.doclin8'),
                "doclin9": get(response, 'response.doclin9'),
                "fechaVencimiento": get(response, 'response.fechaVencimiento'),
                "fecini": get(response, 'response.fecini'),
                "fecini1": get(response, 'response.fecini1'),
                "fecval1": get(response, 'response.fecval1'),
                "fecval11": get(response, 'response.fecval11'),
                "fecval2": get(response, 'response.fecval2'),
                "fecval22": get(response, 'response.fecval22'),
                "fecvenc2": get(response, 'response.fecvenc2'),
                "fecvenci": get(response, 'response.fecvenci'),
                "idcent": get(response, 'response.idcent'),
                "idcent1": get(response, 'response.idcent1'),
                "idcent2": get(response, 'response.idcent2'),
                "idcentca": get(response, 'response.idcentca'),
                "idcesta": get(response, 'response.idcesta'),
                "idcontc2": get(response, 'response.idcontc2'),
                "idcontca": get(response, 'response.idcontca'),
                "idcontcc": get(response, 'response.idcontcc'),
                "idcontr": get(response, 'response.idcontr'),
                "idcontr1": get(response, 'response.idcontr1'),
                "idempr": get(response, 'response.idempr'),
                "idempr01": get(response, 'response.idempr01'),
                "idempr2": get(response, 'response.idempr2'),
                "idemprca": get(response, 'response.idemprca'),
                "idemprct": get(response, 'response.idemprct'),
                "idprod": get(response, 'response.idprod'),
                "idprod1": get(response, 'response.idprod1'),
                "idprodca": get(response, 'response.idprodca'),
                "idscontr": get(response, 'response.idscontr'),
                "idscontt": get(response, 'response.idscontt'),
                "idsprod": get(response, 'response.idsprod'),
                "idsprod2": get(response, 'response.idsprod2'),
                "imcoimpo": get(response, 'response.imcoimpo'),
                "impcipf": get(response, 'response.impcipf'),
                "impcta2": get(response, 'response.impcta2'),
                "impctvl": get(response, 'response.impctvl'),
                "impopts": get(response, 'response.impopts'),
                "impordeb": get(response, 'response.impordeb'),
                "imporhab": get(response, 'response.imporhab'),
                "importen": get(response, 'response.importen'),
                "incaldia": get(response, 'response.incaldia'),
                "indaplic": get(response, 'response.indaplic'),
                "indcapit": get(response, 'response.indcapit'),
                "indcondi": get(response, 'response.indcondi'),
                "indpend": get(response, 'response.indpend'),
                "indprec": get(response, 'response.indprec'),
                "indresi": get(response, 'response.indresi'),
                "indrevto": get(response, 'response.indrevto'),
                "listaAltaImposicionValidacion": {
                    "lista": [
                        {
                            "atasa": get(response, 'response.listaAltaImposicionValidacion.lista[0].atasa'),
                            "atasafin": get(response, 'response.listaAltaImposicionValidacion.lista[0].atasafin'),
                            "coconcli": get(response, 'response.listaAltaImposicionValidacion.lista[0].coconcli'),
                            "copercon": get(response, 'response.listaAltaImposicionValidacion.lista[0].copercon'),
                            "coredond": get(response, 'response.listaAltaImposicionValidacion.lista[0].coredond'),
                            "cotraapl": get(response, 'response.listaAltaImposicionValidacion.lista[0].cotraapl'),
                            "cotrefer": get(response, 'response.listaAltaImposicionValidacion.lista[0].cotrefer'),
                            "descripe": get(response, 'response.listaAltaImposicionValidacion.lista[0].descripe'),
                            "dispon1": get(response, 'response.listaAltaImposicionValidacion.lista[0].dispon1'),
                            "fecdesde": get(response, 'response.listaAltaImposicionValidacion.lista[0].fecdesde'),
                            "fechasta": get(response, 'response.listaAltaImposicionValidacion.lista[0].fechasta'),
                            "fecomval": get(response, 'response.listaAltaImposicionValidacion.lista[0].fecomval'),
                            "fefinval": get(response, 'response.listaAltaImposicionValidacion.lista[0].fefinval'),
                            "fetasref": get(response, 'response.listaAltaImposicionValidacion.lista[0].fetasref'),
                            "imminfr": get(response, 'response.listaAltaImposicionValidacion.lista[0].imminfr'),
                            "intactua": get(response, 'response.listaAltaImposicionValidacion.lista[0].intactua'),
                            "plazocl2": get(response, 'response.listaAltaImposicionValidacion.lista[0].plazocl2'),
                            "poimfran": get(response, 'response.listaAltaImposicionValidacion.lista[0].poimfran'),
                            "tafranq": get(response, 'response.listaAltaImposicionValidacion.lista[0].tafranq'),
                            "tasa": get(response, 'response.listaAltaImposicionValidacion.lista[0].tasa'),
                            "tasa1": get(response, 'response.listaAltaImposicionValidacion.lista[0].tasa1'),
                            "tasadif": get(response, 'response.listaAltaImposicionValidacion.lista[0].tasadif'),
                            "tasamaxi": get(response, 'response.listaAltaImposicionValidacion.lista[0].tasamaxi'),
                            "tasamini": get(response, 'response.listaAltaImposicionValidacion.lista[0].tasamini'),
                            "tasaref": get(response, 'response.listaAltaImposicionValidacion.lista[0].tasaref'),
                            "traplic": get(response, 'response.listaAltaImposicionValidacion.lista[0].traplic')
                        }
                    ]
                },
                "marca": get(response, 'response.marca'),
                "nomlogo": get(response, 'response.nomlogo'),
                "nroemp": get(response, 'response.nroemp'),
                "nudivfij": get(response, 'response.nudivfij'),
                "numcta": get(response, 'response.numcta'),
                "plazocas": get(response, 'response.plazocas'),
                "plazoclq": get(response, 'response.plazoclq'),
                "refcoron": get(response, 'response.refcoron'),
                "subgrc2": get(response, 'response.subgrc2'),
                "subgrca": get(response, 'response.subgrca'),
                "taf": get(response, 'response.taf'),
                "tipcur": get(response, 'response.tipcur'),
                "tipopers": get(response, 'response.tipopers'),
                "zcodper": get(response, 'response.zcodper'),
                "ztipoper": get(response, 'response.ztipoper')
            }
        };
    }
}

function addImpositionDepositReducer (state = setInitialState(), action) {

    switch(action.type) {
        case actionTypes.ADD_DEPOSIT_IMPOSITION_RESET:
            return setInitialState();

        case actionTypes.ADD_DEPOSIT_IMPOSITION_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.data
            });

        case actionTypes.ADD_DEPOSIT_IMPOSITION_VALIDATE_STEP:
            if (state.get('visibleStep') === WIZARD_FIRST_STEP) {
                return validateStep1(state, action.payload.depositsData);
            }
            return state;
        case actionTypes.ADD_DEPOSIT_IMPOSITION_SET_CURRENT_VALUE:
            return state.merge({
                steps: state.get('steps').setIn([FIRST_STEP, 'depositValue'], action.payload.depositValue)
            });

        case actionTypes.ADD_DEPOSIT_IMPOSITION_REQUEST:
            return state.merge({
                isFetching: true
            });

        case actionTypes.ADD_DEPOSIT_IMPOSITION_SUCCESS:
            return state.merge({
                addImpositionConf: action.payload.data,
                isFetching: false,
                submitSuccessful: true
            });

        case actionTypes.ADD_DEPOSIT_IMPOSITION_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.ADD_DEPOSIT_IMPOSITION_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
                submitSuccessful: false
            });

        case actionTypes.DEPOSIT_VALIDATION_REQUEST:
            return state.merge({
                isFetchingValidation: true,
            });
        case actionTypes.DEPOSIT_VALIDATION_SUCCESS:
            return state.merge({
                isFetchingValidation: false,
                addImpositionValidation: mapValidationResponse(action.payload.response),
                validationSuccess: true
            });

        case actionTypes.DEPOSIT_VALIDATION_ERROR:
            return state.merge({
                error: action.payload.error,
                isFetchingValidation: false,
                success: false
            });

        case actionTypes.ADD_DEPOSIT_IMPOSITION_UPDATE_RENEWAL_PREFERENCE:
            return state.merge({
                steps: state.get('steps').setIn([FIRST_STEP, 'hasRenewal'], action.payload.hasRenewal)
            });

        default:
            return state;
    }
}

module.exports = addImpositionDepositReducer;
