/*
 * These reducers manage to promises that are launched again two separate documentation services:
 * -Legacy documents from extractos-open
 * -Newer generic documentation from document-manager
 * Every SUCCESS request will be merged manually into the existing state by getting:
 * -SORTED
 * -Visible/Hidden
 * -Memento'd
 * document-manager documentation does not paginate. We do not pseudo-paginate document-manager-only document groups.
 * We pseudo paginate only the resulting state on combined groups. Tipically "EVERY DOCUMENT" group by hiding document-manager outlining groups.
 * A cache is implemented by using mergeDeep when adding new documents to the underlying state.
 * @Refactored: alejandro.delavina@gruposantander.com
 * @Date: 20/05/2018
 *
 * Adding pseudo paginate for specific document groups. Groups feeded by not paginated service response.
 * @Modified: franco.cardoso@globant.com
 * @Date: 08/02/2019
 */

// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
const contactCenterActionTypes = require('constants/contactCenter/actionTypes');
const {
    DATE_FILTER_NONE,
    DATE_FORMAT_API,
    DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR
} = require('constants/index');
const { HASHED_DOC_GROUP } = require('constants/documentsGroups');
// @helpers
const isArray = require('lodash/lang/isArray');
const trim = require('lodash/string/trim');
const sortByOrder = require('lodash/collection/sortByOrder');

const initialState = Immutable.fromJS({
    isFetching: false,
    isFetchingGroups: false,
    isFetchingContactCenterDocuments: false,
    emailSent: false,
    emailSentTo: '',
    emailError: false,
    emailErrorMessage: '',
    isMoreDocumentsFetching: false,
    isGenericDocumentsFetching: false,
    documents: [],
    documentsByGroup: {},
    documentsGroups: [],
    documentsTypes: [],
    selectedDocument: {},
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        },
        typeOfDocument: {
            isActive: false,
            type: ''
        },
        contract: {
            isActive: false,
            number: ''
        },
        account: {
            isActive: false,
            number: ''
        }
    },
    groupSelected: '0000'
});

function normalizeDocumentType(documentType) {
    const documentTypeTrimmed = trim(documentType);
    const documentTypeWithoutAnySpaces = documentTypeTrimmed.replace(/\s/g, '');

    return documentTypeWithoutAnySpaces.toLowerCase();
}

function formatGenericToContract(document) {
    const documentType = normalizeDocumentType(get(document, 'documentType'));
    const documentTypeTranslation = documentType ? `documents-${documentType}` : '';

    return {
        codSubaplicacion: document.codSubaplicacion || '',
        codComunicacion: document.objectId || '',
        codTipoComunicacion: document.codTipoComunicacion || '',
        nombreTipoComunicacion: documentTypeTranslation,
        codCategoriaComunicacion: document.codCategoriaComunicacion || '',
        nombreCategoriaComunicacion: trim(document.nombreCategoriaComunicacion),
        codGrupoComunicacion: document.codGrupoComunicacion || '',
        nombreGrupoComunicacion: trim(document.nombreGrupoComunicacion),
        fechaAlta: moment(document.date, DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR).format(DATE_FORMAT_API) || '',
        fechaEnvio: moment(document.date, DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR).format(DATE_FORMAT_API) || '',
        indLeido: document.indLeido || '',
        numContrato: document.idContract || '',
        codCanal: document.codCanal || '',
        codFormato: document.codFormato || '',
        codIdioma: document.codIdioma || '',
        gestorDocumental: document.gestorDocumental || '',
        composicionOnLine: document.composicionOnLine || '',
        refDocumento: trim(document.refDocumento),
        codSubaplicacionComp: document.codSubaplicacionComp || '',
        isFetching: false,
        isMifid2Document: true,
        hidden: false
    };
}

function formatMifidCostBreakdownDocumentToContract(document) {
    return {
        codComunicacion: document.objectId || '',
        nombreTipoComunicacion: 'documents-costBreakdownDocument',
        numContrato: document.isin || '',
        documentType: document.documentType || '',
        isFetching: false,
        isMifid2Document: true,
        mifid2SendEmailButton: true,
        documentWithNoDates: true,
        isSendingEmail: false
    };
}

function nextGenericDocumentPage(documentGroup, selectedGroup) {
    const pageSize = HASHED_DOC_GROUP[selectedGroup].pageSize;
    const {
        lastIndex,
        documents,
    } = documentGroup;
    const hasMore = documents.length > lastIndex + pageSize;

    if (lastIndex == 0 && hasMore) {
        documents.slice(pageSize).map(docs => { docs.hidden = true; })
    } else {
        documents.slice(lastIndex, lastIndex + pageSize).map((doc,index) => {
            documents[index + lastIndex].hidden = false;
        });
    }
    documentGroup.lastIndex = hasMore ? lastIndex + pageSize : documents.length - 1;
    documentGroup.documents = documents;
    documentGroup.hasMore = hasMore ? 'S' : 'N';

    return documentGroup;
}

function paginateGenericDocuments(documentGroup, selectedGroup) {
    if(!!HASHED_DOC_GROUP[selectedGroup].paginateLocally) {
        return nextGenericDocumentPage({
                ...documentGroup,
                lastIndex: 0
            },selectedGroup);
    }
    return documentGroup;
}

function parseMIFID2Documents(data) {
    let parseMIFID2Documents = [];

    if (isArray(data)) {
        parseMIFID2Documents = data.map(formatGenericToContract);
    }

    parseMIFID2Documents = sortByOrder(parseMIFID2Documents, parsedDocument => {
        return parsedDocument.fechaAlta;
    }, 'desc');

    return parseMIFID2Documents;
}

function sortMIFID2Documents(data) {
    let sortedMIFID2Documents = [];

    sortedMIFID2Documents = sortByOrder(data, document => {
        const minDate = moment(new Date(0)).format(DATE_FORMAT_API);

        return document.fechaAlta ? document.fechaAlta : minDate;
    }, 'desc');

    return sortedMIFID2Documents;
}

function setDocumentsStatusByGroup(fieldName, state, code, value) {
    let newGroup = {};
    let currentGroup = state.get('groupSelected');
    let documents = state.get('documentsByGroup');
    let group = documents.get(currentGroup);

    let docsWithFetchingStatus = group.get('documents').map(document => {
        if (document.get('codComunicacion') === code) {
            return document.merge({ [fieldName]: value });
        }
        return document;
    });

    newGroup[currentGroup] = group.merge({ documents: docsWithFetchingStatus });

    return documents.merge(newGroup);
}

//This function makes 'invisible' every generic document that was generated before the last BKS document that we have fetched.
function mergeDocumentsPages(state, selectedGroup) {
  let shouldShowDocumentManagerDocuments = false;
  let documents = state[selectedGroup].documents;

  if( state[selectedGroup].hasMore === 'S' )
  {
      //If there are more pages, we will 'hide' every document-manager document that comes up after the last retrieved extractos-open document.
      //We do this to coordinate a paginated request (extractos-open) along a non-paginated request (document-manager)
      //slice() is necesary to duplicate the array before reversing it, otherwise the documents get shown the other way around.
      documents.slice().reverse().map(function (doc, index) {
          if (doc.isMifid2Document && !shouldShowDocumentManagerDocuments) {
              documents[documents.length-1-index].hidden = true;
          }
          else if (!doc.isMifid2Document) {
              shouldShowDocumentManagerDocuments = true;
          }
      });
  }
  else if ( state[selectedGroup].hasMore === 'N' ) {
      documents.map(function (doc, index) { documents[index].hidden = false; })
  }
  return state;
}

function mergeDocumentsGroup(currState, payload, selectedGroup) {
    const currStateJS = currState.toJS();

    if (currStateJS[selectedGroup] === undefined || currStateJS[selectedGroup] === {}) {
        return payload;
    }

    let newState = currStateJS;

    newState[selectedGroup].memento = payload[selectedGroup].memento;
    newState[selectedGroup].hasMore = payload[selectedGroup].hasMore;

    //Merges the new 'state' with the existing one.
    payload[selectedGroup].documents.map(doc =>
        newState[selectedGroup].documents.push(doc)
    );

    //Sorts everything up in a deterministically.
    newState[selectedGroup].documents = newState[selectedGroup].documents.sort(function (a, b) {
        let dateDiff = moment(b.fechaEnvio,DATE_FORMAT_API).diff(moment(a.fechaEnvio,DATE_FORMAT_API));
        if(dateDiff === 0) {
            return b.codComunicacion.localeCompare(a.codComunicacion);
        }
        return dateDiff;
    });
    return Immutable.fromJS(mergeDocumentsPages(newState, selectedGroup));
}

function appendMifidCostBreakdownDocuments(state, action) {


    let existingDocuments = state.getIn([ 'documentsByGroup', action.payload.data.groupSelected, 'documents']);
    existingDocuments = existingDocuments ? existingDocuments.toJS() : [];
    const costBreakdownDocuments = action.payload.data.mifidAllDocuments.map(formatMifidCostBreakdownDocumentToContract);
    const mifidAllDocuments = Immutable.fromJS(sortMIFID2Documents(existingDocuments.concat(costBreakdownDocuments)));

    const formattedResponse = {};
    formattedResponse[action.payload.data.groupSelected] = {documents: mifidAllDocuments};

    return state.merge({
        documentsByGroup: state.get('documentsByGroup').merge(formattedResponse),
        isFetchingContactCenterDocuments: false
    });
}

const documents = (state = initialState, action) => {
    switch (action.type) {

        //These actionTypes handle legacy documents fetching
        case actionTypes.FETCH_MORE_DOCUMENTS_REQUEST:
            return state.mergeDeep({
                isPartialFetching: true,
                isFetching: (Object.keys(state.get('documentsByGroup').toJS()).length === 0),
                isMoreDocumentsFetching: true,
                emailSent: false,
                emailSentTo: '',
                emailError: false,
                emailErrorMessage: ''
            });
        case actionTypes.FETCH_MORE_DOCUMENTS_SUCCESS: {
            return state.merge({
                documentsByGroup: mergeDocumentsGroup(state.get('documentsByGroup'),action.payload.data,state.get('groupSelected')),
                error: null,
                isPartialFetching: state.get('isGenericDocumentsFetching'),
                isMoreDocumentsFetching: false,
                isFetching: false
            });
        }
        case actionTypes.FETCH_MORE_DOCUMENTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isPartialFetching: false,
                isMoreDocumentsFetching: false,
                isFetching: false
            });

        //These actionTypes handle generic documents fetching
        case actionTypes.FETCH_GENERIC_DOCUMENTS_REQUEST:
            return state.mergeDeep({
                isPartialFetching: true,
                isGenericDocumentsFetching: true
            });

        case actionTypes.FETCH_GENERIC_DOCUMENTS_SUCCESS:
            const loadingGroup = state.get('groupSelected');
            let documents = {documents: parseMIFID2Documents(action.payload.data.documents)};
            documents = paginateGenericDocuments(documents, loadingGroup);

            return state.mergeDeep({
                documentsByGroup: mergeDocumentsGroup(state.get('documentsByGroup'),{[loadingGroup]: documents},state.get('groupSelected')),
                error: null,
                isPartialFetching: !!HASHED_DOC_GROUP[loadingGroup].paginateLocally ? false : state.get('isMoreDocumentsFetching'),
                isGenericDocumentsFetching: false
            });

        case actionTypes.FETCH_GENERIC_DOCUMENTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isPartialFetching: false,
                isGenericDocumentsFetching: false
            });

        case actionTypes.FETCH_OK_API_DOCUMENTS_REQUEST:
            return state.mergeDeep({
                isPartialFetching: true,
                isOkApiDocumentsFetching: true
            });
    
        case actionTypes.FETCH_OK_API_DOCUMENTS_SUCCESS:
            const selectedGroup = state.get('groupSelected');
            const documentsSelected = paginateGenericDocuments({documents: action.payload.data.documents}, selectedGroup);
            return state.mergeDeep({
                documentsByGroup: mergeDocumentsGroup(state.get('documentsByGroup'),{[selectedGroup]: documentsSelected},state.get('groupSelected')),
                error: null,
                isPartialFetching: !!HASHED_DOC_GROUP[selectedGroup].paginateLocally ? false : state.get('isMoreDocumentsFetching'),
                isOkApiDocumentsFetching: false
            });
    
        case actionTypes.FETCH_OK_API_DOCUMENTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isPartialFetching: false,
                isOkApiDocumentsFetching: false
            });
            
        case actionTypes.GET_NEXT_GENERIC_DOCUMENT_PAGE:
            const groupSelected = state.get('groupSelected');
            const documentGroup = state.getIn(['documentsByGroup', groupSelected]);
            return state.mergeDeep({
                documentsByGroup: {
                    [groupSelected]:nextGenericDocumentPage(documentGroup.toJS(), groupSelected)
                }
            });

        //These actionTypes handle document filtering & resets current reducer.
        case actionTypes.FETCH_DOCUMENTS_FILTER: {
            let { filters } = action.payload;
            const dateFilterSelected = !!get(filters, 'date.from') || !!get(filters, 'date.to');

            return state.merge(initialState.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected,
                        selected: get(filters, 'date.selected'),
                        from: get(filters, 'date.from'),
                        to: get(filters, 'date.to')
                    }
                },
                groupSelected: state.get('groupSelected')
            }));
        }

        case actionTypes.FETCH_DOCUMENTS_FILTER_RESET: {

            return state.merge(initialState.mergeDeep({
                groupSelected: state.get('groupSelected')
            }));
        }

        case actionTypes.FETCH_DOCUMENTS_RESET: {
            return state.merge(initialState);
        }

        //This actionType handles the group Change by keeping a nice cache of documents for coming back and forth between groups.
        case actionTypes.FETCH_DOCUMENTS_GROUP_CHANGE: {
            const groupSelected = action.payload.groupSelected;
            return state.mergeDeep({
                groupSelected
            });
        }

        //These actionTypes handle Downloads
        case actionTypes.FETCH_DOCUMENTS_PDF_REQUEST:
            return state.merge({
                documentsByGroup: setDocumentsStatusByGroup('isFetching', state, action.payload.codComunicacion, true)
            });
        case actionTypes.FETCH_DOCUMENTS_PDF_FAILURE:
            return state.merge({
                error: true,
                documentsByGroup: setDocumentsStatusByGroup('isFetching', state, action.payload.codComunicacion, false)
            });
        case actionTypes.FETCH_DOCUMENTS_PDF_SUCCESS:
            return state.merge({
                pdfs: action.payload.data,
                error: '',
                documentsByGroup: setDocumentsStatusByGroup('isFetching', state, action.payload.codComunicacion, false)
            });
        case contactCenterActionTypes.MIFID_DOCUMENTS_SEND_MAIL_REQUEST:
            return state.merge({
                emailSent: false,
                emailSentTo: '',
                emailError: false,
                emailErrorMessage: '',
                documentsByGroup: setDocumentsStatusByGroup('isSendingEmail', state, action.payload.objectId, true)
            });
        case contactCenterActionTypes.MIFID_DOCUMENTS_SEND_MAIL_REQUEST_SUCCESS:
            return state.merge({
                emailSent: true,
                emailSentTo: action.payload.email,
                emailError: false,
                emailErrorMessage: '',
                documentsByGroup: setDocumentsStatusByGroup('isSendingEmail', state, action.payload.objectId, false)
            });
        case contactCenterActionTypes.MIFID_DOCUMENTS_SEND_MAIL_REQUEST_ERROR:
            return state.merge({
                emailSent: false,
                emailSentTo: '',
                emailError: true,
                emailErrorMessage: action.payload.error.description,
                documentsByGroup: setDocumentsStatusByGroup('isSendingEmail', state, action.payload.objectId, false)
            });
        case contactCenterActionTypes.MIFID_DOCUMENTS_SEND_MAIL_RESET:
            return state.merge({
                emailSent: false,
                emailSentTo: '',
                emailError: false,
                emailErrorMessage: ''
            });
        case contactCenterActionTypes.DOCUMENTATION_MIFID_COST_BREAKDOWN_FETCHING:
            return state.merge({
                isFetchingContactCenterDocuments: true
            });
        case contactCenterActionTypes.DOCUMENTATION_MIFID_COST_BREAKDOWN_SUCCESS:
            return appendMifidCostBreakdownDocuments(state, action);

        case contactCenterActionTypes.SET_SELECTED_DOCUMENT:
            return state.merge({
                selectedDocument: action.payload
            });

        default:
            return state
    }
};

module.exports = documents;
