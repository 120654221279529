// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    fetchSuccess: false,
    isFetching: false,
    isOpen: false,
    tutorialList: [],
});

const tutorialReducer = (state = initialState, action = {type: null}) => {
    switch (action.type) {
        case actionTypes.TUTORIAL_SHOULD_OPEN:
            const { tutorial } = action.payload;
            const immTutorial = state.get('tutorialList').find(immTutorial => 
                immTutorial.get('tutorial') === `tg-tutorial-${tutorial}`
            );
            let isOpen = false;
            if (!!immTutorial) {
                isOpen = !immTutorial.get('seen');
            }
            return state.merge({
                isOpen,
            });
        case actionTypes.TUTORIAL_TOGGLE_OPEN:
            return state.merge({
                isOpen: action.payload.isOpen,
            });
        case actionTypes.TUTORIAL_GET_LIST_FAILURE:
        case actionTypes.TUTORIAL_SET_SEEN_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.TUTORIAL_GET_LIST_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
            });
        case actionTypes.TUTORIAL_SET_SEEN_REQUEST:
            const { tutorials } = action.payload;
            let tutorialListIndex = [];
            let tutorialList = state.get('tutorialList').toJS();
            tutorials.forEach(tutorialValue => {
                const tutorialIndex = state.get('tutorialList').findIndex(immTutorial =>
                    immTutorial.get('tutorialId') === tutorialValue && !immTutorial.get('seen')
                );
                if (!!tutorialIndex) {
                    tutorialListIndex.push(tutorialIndex);
                    if (!!tutorialList[tutorialIndex]) {
                        tutorialList[tutorialIndex].seen = true;
                    }
                }
            });

            return state.merge({
                error: '', 
                isFetching: true, 
                tutorialList: Immutable.fromJS(tutorialList)
            });
        case actionTypes.TUTORIAL_GET_LIST_SUCCESS:
            return state.mergeDeep({
                fetchSuccess: true,
                tutorialList: action.payload.tutorials,
            });
        case actionTypes.TUTORIAL_SET_SEEN_SUCCESS:
            return state.mergeDeep({
                isFetching: false,
            });
        default:
            return state
    }
};

module.exports = tutorialReducer;
