// @vendors
const Immutable = require('immutable');
 
// @constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');

// @reducers
const cardActivationR42Step1 = require('./cardActivationR42/cardActivationR42Step1');

const initialState = Immutable.fromJS({
    error: false,
    errorMsg: '',
    isFetching: false,
    isFetchingTravelPlus: false,
    steps: [
        cardActivationR42Step1(),
        {hasSignaturePattern: true},
    ],
    submitSuccessful: false,
    travelPlusState: '',
    visibleStep: 1,
    willCancel: false,
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardActivationR42Step1(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function cardActivationR42Reducer(state = initialState, action = { type: null }) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.SET_CARD_ACTIVATION_R42_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep,
                    });
            }

            return state;

        case actionTypes.SET_CARD_ACTIVATION_R42_HIRE_TRAVEL_PLUS_VALUE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
            });

        case actionTypes.SET_CARD_ACTIVATION_R42_TRAVEL_PLUS_STATE:
            return state.merge({
                travelPlusState: action.payload.travelPlusState,
            });

        case actionTypes.SET_CARD_ACTIVATION_R42_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step,
            });

        case actionTypes.SET_CARD_ACTIVATION_R42_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel,
            });

        case actionTypes.SET_CARD_ACTIVATION_R42_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false,
            });

        case actionTypes.SET_CARD_ACTIVATION_R42_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
            });

        case actionTypes.SET_CARD_ACTIVATION_R42_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                isFetching: false,
            });
        
        case actionTypes.SET_CARD_ACTIVATION_R42_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = cardActivationR42Reducer;
