const Immutable = require('immutable');

// @ constants
const {
    MORTGAGE_REQUEST_ASSET_FILE_RESET,
    MORTGAGE_REQUEST_ASSET_FILE_REQUEST,
    MORTGAGE_REQUEST_ASSET_FILE_SUCCESS,
    MORTGAGE_REQUEST_ASSET_FILE_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    file: '',
    contentType: '',
    error: '',
    isFetching: false,
    isFetched: false
});

const mortgageRequestAssets = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_ASSET_FILE_RESET:
            return initialState.merge();

        case MORTGAGE_REQUEST_ASSET_FILE_REQUEST:
            return state.merge({
                isFetching: true
            });

        case MORTGAGE_REQUEST_ASSET_FILE_SUCCESS:
            return state.merge({
                file: action.data.dataBase64,
                contentType: action.data.contentType,
                isFetched: true
            });

        case MORTGAGE_REQUEST_ASSET_FILE_FAILURE:
            return state.merge({
                error: action.data.error
            });

        default:
            return state
    }
}

module.exports = mortgageRequestAssets
