const Immutable = require('immutable');
const get = require('lodash/object/get');

const {
    CANCEL_PENSION_PLAN_PENDING_ORDER_REQUEST,
    CANCEL_PENSION_PLAN_PENDING_ORDER_SUCCESS,
    CANCEL_PENSION_PLAN_PENDING_ORDER_FAILURE,

    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_RESET,
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SET_VISIBLE_STEP,
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SHOW_CANCEL_MODAL,

    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_REQUEST,
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_SUCCESS,
    BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_FAILURE,
} = require('constants/contactCenter/actionTypes');

const { WIZARD_FIRST_STEP } = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    error: '',
    isValidating: false,
    validationData: {
        codEstatus: null,
    },
    isFetching: false,
    hasBeenFetched: false,
    currentStep: WIZARD_FIRST_STEP,
    showCancelModal: false,
    steps: [
        {},
        {
            hasSignaturePattern: true
        }
    ],
});

const createCancelPensionPlanPendingOrderRequestState = previousState => {
    return previousState.merge({
        isFetching: true,
        hasBeenFetched: false,
        error: '',
    });
};

const createCancelPensionPlanPendingOrderErrorState = (previousState, error) => {
    return INITIAL_STATE.merge({
        isFetching: false,
        hasBeenFetched: false,
        error,
    });
};

const createCancelPensionPlanPendingOrderSuccessState = previousState => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: true,
        error: '',
    });
};

const setVisibleStep = (previousState, step) => {
    return previousState.merge({
        currentStep: step,
    });
}

const validateStepRequest = () => {
    return INITIAL_STATE.merge({
        isValidating: true
    });
}

const validateStepSuccess = (previousState, response) => {
    return previousState.merge({
        isValidating: false,
        validationData: {
            codEstatus: get(response, 'codEstatus')
        }
    });
}

const validateStepFailure = (previousState, error) => {
    return INITIAL_STATE.merge({
        isValidating: false,
        error,
    });
}

const showCancelModal = (previousState, show) => {
    return previousState.merge({
        showCancelModal: show
    });
}

function brokerPensionPlanCancelPendingOrder(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CANCEL_PENSION_PLAN_PENDING_ORDER_REQUEST:
            return createCancelPensionPlanPendingOrderRequestState(state);
        case CANCEL_PENSION_PLAN_PENDING_ORDER_FAILURE:
            return createCancelPensionPlanPendingOrderErrorState(state, action.payload.error);
        case CANCEL_PENSION_PLAN_PENDING_ORDER_SUCCESS:
            return createCancelPensionPlanPendingOrderSuccessState(state);
        case BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_REQUEST:
            return validateStepRequest();
        case BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_FAILURE:
            return validateStepFailure(state, action.payload.error);
        case BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_VALIDATE_API_SUCCESS:
            return validateStepSuccess(state, action.payload.response);
        case BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_SHOW_CANCEL_MODAL:
            return showCancelModal(state, action.payload.show);
        case BROKER_PENSION_PLAN_CANCEL_PENDING_ORDER_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
}

module.exports = brokerPensionPlanCancelPendingOrder;
