// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
        nivelEstudios: 'UNKNOWN',
        situacionLaboral: 'UNKNOWN',
        tipoContratoTrabajo: 'UNKNOWN',
        mesIncorporacionTrabajo: '',
        anioIncorporacionTrabajo: '',
        archivoVidaLaboral: [],
        archivoIrpf: [],
        idCea: '',
        fechaGenCea: ['', '', ''],
        codigoCea1: '',
        codigoCea2: '',
        codigoCea3: '',
        codigoCea4: '',
        codigoCea5: '',
        codigoCea6: '',
        empldeclfl: '',
        emplobsfl: '',
        returnFl: false,
        error: '',
        message: '',
        isFetching: false,
        isFetchingFile: false,
        confirmed: false,
        flag: false,
        errorUploadFile: ''
});

const workData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_HOLDER_WORK_RESET:
            return initialState.merge();

        case actionTypes.MORTGAGE_REQUEST_FETCH_HOLDER_WORK_REQUEST:
            return state.merge({
                flag: false,
                isFetching: true,
                confirmed: false
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_HOLDER_WORK_SUCCESS:
            const data = action.data.data;
            let newState = state;

            const fechaGenCea = get(data, 'fechaGenCea', '');

            if (fechaGenCea) {
                const genCeaDate = moment(fechaGenCea);
                newState = newState.merge({
                    fechaGenCea: [genCeaDate.format('DD'), genCeaDate.format('MM'), genCeaDate.format('YYYY')]
                });
            } else {
                newState = newState.merge({
                    fechaGenCea: ['', '', '']
                });
            }

            return newState.mergeDeep({
                nivelEstudios: get(data, 'nivelEstudios', 'UNKNOWN') || 'UNKNOWN',
                situacionLaboral: get(data, 'situacionLaboral', 'UNKNOWN') || 'UNKNOWN',
                tipoContratoTrabajo: get(data, 'tipoContratoTrabajo', 'UNKNOWN') || 'UNKNOWN',
                mesIncorporacionTrabajo: get(data, 'mesIncorporacionTrabajo', '') || '',
                anioIncorporacionTrabajo: get(data, 'anioIncorporacionTrabajo', '') || '',
                archivoVidaLaboral: get(data, 'archivoVidaLaboral', []) || [],
                archivoIrpf: get(data, 'archivoIrpf', []) || [],
                idCea: get(data, 'idCea', '') || '',
                codigoCea1: get(data, 'codigoCea1', '') || '',
                codigoCea2: get(data, 'codigoCea2', '') || '',
                codigoCea3: get(data, 'codigoCea3', '') || '',
                codigoCea4: get(data, 'codigoCea4', '') || '',
                codigoCea5: get(data, 'codigoCea5', '') || '',
                codigoCea6: get(data, 'codigoCea6', '') || '',
                empldeclfl: get(data, 'empldeclfl', '') || '',
                emplobsfl: get(data, 'emplobsfl', '') || '',
                returnFl: get(data, 'returnFl', false) || false,
                error: '',
                isFetching: false,
                flag: true,
                confirmed: false
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_HOLDER_WORK_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case actionTypes.MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_REQUEST:
            return state.merge({
                isFetching: true
            });

        case actionTypes.MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false,
                flag: false,
                confirmed: true
            });

        case actionTypes.MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FAILURE:
            return state.merge({
                errorUploadFile: action.data.error,
            });

        case actionTypes.MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_REQUEST:
            return state.merge({
                isFetchingFile: true
            });

        case actionTypes.MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_SUCCESS:
            return state.merge({
                error: '',
                isFetchingFile: false,
                confirmed: true
            });

        case actionTypes.MORTGAGE_REQUEST_UPLOAD_HOLDER_WORK_FILE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetchingFile: false
            });

        case actionTypes.MORTGAGE_REQUEST_WORK_FLAG:
            return state.set('flag', action.flag);

        default:
            return state;
    }
};

module.exports = workData;
