// @vendors
const React = require('react');

// @utilities
const formValidationHelper = require('../utilities/formValidationHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
const { haveEmail } = require('../utilities/formHelper');
const { FormattedText } = require('core/i18n').i18n;

// @components
const { OKLink } = require('commonsComponents/OKLink');

// @constants
const { CONTRACT_SUMMARY_CONTACT_FAX_NO_PREFIX } = require('constants/index');

function validateStep1(cardDetails) {
    return {
        card_details: cardDetails,
        isValidStep: true
    };
}

function validateStep2(cardDetails) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    const isValidAccount = !!selectedAccount;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    return {
        card_details: cardDetails,
        isValidStep: isValidAccount
    };
}

function validateStep3(cardDetails, confirmationCheckboxes, state) {
    const promotionalCodeChecked =
        !state.get('promotionalCode') || state.get('promotionalCodeAcceptanceCheckboxChecked');
    const updatedCheckboxList = confirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    return {
        promotionalCodeAcceptanceCheckboxErrorState: !promotionalCodeChecked,
        card_details: cardDetails,
        isValidStep: confirmationCheckboxes.get('areValid') && promotionalCodeChecked,
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateLegalWindowStep(cardDetails, conditionDocumentConfirmation) {
    return {
        card_details: cardDetails,
        isValidStep: conditionDocumentConfirmation,
    };
}

function validateLegalWindowDicStep(cardDetails) {
    return {
        card_details: cardDetails,
        isValidStep: true
    };
}

function upgradeToPayrollAccount_setSuccessStepPrefix(contractWizard, immProfileFields, seed) {
    let successStepPrefix = {};

    if (contractWizard.get(seed)) {
        successStepPrefix.seed = 'seed_';
    }
    if (haveEmail(immProfileFields)) {
        successStepPrefix.email = 'successWithEmail_';
    }
    return successStepPrefix;
}

function upgrade_to_payroll_account_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    //Remember, if steps order is changed override prop must be updated
    const overrideValidation = state.getIn(['steps', step - 1, 'override_validation_step']);
    let stepToValidate = step;

    if (!!overrideValidation) {
        stepToValidate = overrideValidation;
    }

    switch (stepToValidate) {
        case 1:
            return validateStep1(immCardDetails);

        case 2:
            return validateStep2(immCardDetails);

        case 3:
        case 4:
            return validateStep3(immCardDetails, immConfirmationCheckboxes, state);
        case 'legalWindow':
            return validateLegalWindowStep(immCardDetails, state.get('conditionDocumentConfirmation'));
        case 'legalWindowDic':
            return validateLegalWindowDicStep(immCardDetails);
    }
}

function upgradeToPayrollAccount_SignParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails
        ? findElementbyId(immCardDetails, 'cta_seleccionada')
        : null;
    let immSelectedAccount;

    if (immAccounts && selectedAccountId) {
        immSelectedAccount = immAccounts.getIn([
            'byId',
            selectedAccountId,
            'ibanComplex',
            'codbban'
        ]);
    }

    return {
        originAccount: immSelectedAccount
    };
}

function upgradeToPayrollAccount_HireParams(immAccount, contractWizard) {
    const promotionalCode = contractWizard.getIn(['promotionalCode', 'code']) || '';
    return !immAccount ? null : {
            accountNumber: immAccount.getIn(['ibanComplex', 'codbban']),
            promotionalCode: promotionalCode,
            tipo: immAccount.get('newProduct'),
            subtipo: immAccount.get('accountType'),
            product: immAccount.get('product'),
            contractNumber: immAccount.get('contractNumber')
    };

}

function upgrade_to_payroll_account_instructions() {
    return (
        <div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountInstruction"
                    className="contract-summary__instructions-text"
                />
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText value="contractSummary-payrollAccountSuccessParagraph16One" className="contract-summary__instructions-text"/>
                <OKLink
                    modifier="inline"
                    to="https://www.openbank.es/assets/static/pdf/Cuenta%20N%C3%B3mina/Solicitud%20Autorizaci%C3%B3n%20Domiciliaci%C3%B3n%20N%C3%B3mina.pdf"
                    target="_blank"
                >
                    <FormattedText value="contractSummary-payrollAccountSuccessParagraph16Link" className="contract-summary__instructions-link" />
                </OKLink>
                <FormattedText value="contractSummary-payrollAccountSuccessParagraph16Two" className="contract-summary__instructions-text"/>
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText value="contractSummary-payrollAccountSuccessParagraph17One" className="contract-summary__instructions-text"/>
                <OKLink
                    modifier="inline"
                    to="https://www.openbank.es/assets/static/pdf/Cuenta%20N%C3%B3mina/Solicitud%20Autorizaci%C3%B3n%20Domiciliaci%C3%B3n%20Recibos.pdf"
                    target="_blank"
                >
                    <FormattedText value="contractSummary-payrollAccountSuccessParagraph17Link" className="contract-summary__instructions-link" />
                </OKLink>
                <FormattedText value="contractSummary-payrollAccountSuccessParagraph17Two" className="contract-summary__instructions-text"/>
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess01"
                    className="contract-summary__instructions-text-tabbed contract-summary__instructions-text-tabbed--bullet"
                />
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess02"
                    className="contract-summary__instructions-text-tabbed contract-summary__instructions-text-tabbed--bullet"
                    injectedStrings={[CONTRACT_SUMMARY_CONTACT_FAX_NO_PREFIX]}
                />
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess03"
                    className="contract-summary__instructions-text-tabbed contract-summary__instructions-text-tabbed--bullet"
                />
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess04"
                    className="contract-summary__instructions-text-tabbed contract-summary__instructions-text-tabbed--bullet"
                />
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess05"
                    className="contract-summary__instructions-text-tabbed"
                />
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess06"
                    className="contract-summary__instructions-text-tabbed"
                />
                <FormattedText
                    value="contractSummary-upgradeToPayrollAccountSuccess07"
                    className="contract-summary__instructions-text-tabbed"
                />
            </div>
            <div className="contract-summary__instructions-paragraph">
                <FormattedText
                    value="contractSummary-payrollAccountSuccessParagraph19"
                    className="contract-summary__instructions-text"
                />
            </div>
        </div>
    );
}

module.exports = {
    upgrade_to_payroll_account_validateStep,
    upgrade_to_payroll_account_instructions,
    upgradeToPayrollAccount_HireParams,
    upgradeToPayrollAccount_setSuccessStepPrefix,
    upgradeToPayrollAccount_SignParams
};
