const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../inputs');

function buildContactData(prefix) {
    const contactData = wizardComponentsHelper.replacePrefix(inputs.contactData, prefix);

    return { 
        id_component: 'fioc',
        id: prefix + 'contact_data',
        exclude: [],
        grid: [
            [
                contactData.email
            ],
            [
                contactData.mobile_phone,
            ],
            [
                contactData.address_label
            ],
            [
                contactData.type_of_road,
                contactData.road_name,
            ],
            [
                contactData.address_number,
                contactData.address_others,
            ],
            [
                contactData.country,
            ],
            [
                contactData.province,
                contactData.town,
                contactData.postal_code,
            ]
        ]
    }

}

module.exports = buildContactData;