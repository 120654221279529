const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    agreementChecked: false,
    agreementIsDirty: false,
    hasSignaturePattern: true,
    valid: true
});

function validateStep(state, action) {
    const { isRevolving } = action.payload;

    if(__CONTACT_CENTER__ && isRevolving) {
        return state.merge({
            valid: state.get('agreementChecked'),
            agreementIsDirty: true
        });
    } else {
        return state
    }
}

function cardModifyLimitStep2(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.CARD_MODIFY_LIMIT_VALIDATE_STEP:
            return validateStep(state, action);
        case actionTypes.CARD_MODIFY_LIMIT_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                agreementChecked: action.payload.checked,
                agreementIsDirty: false,
                valid: action.payload.checked
            });

        default:
            return state;
    }
}

module.exports = cardModifyLimitStep2;