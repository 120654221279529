const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    valid: false
});

function validateStep(state, externalComponentsAreValid) {
    return state.merge({
        valid: externalComponentsAreValid
    });
}

function hireCardOpenCreditStep2(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_OPEN_CREDIT_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);
        default:
            return state;
    }
}

module.exports = hireCardOpenCreditStep2;
