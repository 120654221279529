// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    documents: []
});

function setPdfDocumentsFetchingStatus (state, process, value) {
    return state.get('documents').map(document => {
        if (document.get('process') === process) {
            return document.merge({ isFetching: value });
        }
        return document;
    });
}

function saddeReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_IBAN_REQUEST: 
            return state.merge({
                isFetchingIban: true,
                fetchingIbanError: false,
            });
        case actionTypes.FETCH_IBAN_SUCCESS: 
            return state.merge({
                isFetchingIban: false,
                fetchingIbanError: false,
            });
        case actionTypes.FETCH_IBAN_FAILURE: 
            return state.merge({
                isFetchingIban: false,
                fetchingIbanError: true,
                error: action.payload.error
            });
        case actionTypes.FETCH_SADDE_DOCUMENTS_REQUEST:
            return state.merge({
                isFetchingDocuments: true
            });
        case actionTypes.FETCH_SADDE_DOCUMENTS_SUCCESS:
            return state.merge({
                isFetchingDocuments: false
            }).set('documents', Immutable.fromJS(action.payload.documents));
        case actionTypes.FETCH_SADDE_DOCUMENTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetchingDocuments: false,
                documents: []
            });
        case actionTypes.FETCH_SADDE_PDF_DOCUMENTS_REQUEST:
            return state.merge({
                documents: setPdfDocumentsFetchingStatus(state, action.payload.process, true)
            });
        case actionTypes.FETCH_SADDE_PDF_DOCUMENTS_SUCCESS:
            return state.merge({
                documents: setPdfDocumentsFetchingStatus(state, action.payload.process, false)
            });
        case actionTypes.FETCH_SADDE_PDF_DOCUMENTS_FAILURE:
            return state.merge({
                documents: setPdfDocumentsFetchingStatus(state, action.payload.process, false)
            });
        case actionTypes.FETCH_SADDE_PDF_DOCUMENT_PREVIEW_REQUEST:
            return state;
        case actionTypes.FETCH_SADDE_PDF_DOCUMENT_PREVIEW_SUCCESS:
             return state.merge({
                previewDocumentUrl: action.payload.documents.precontractualReturn.infoPrecontractualDetalleList.infoPrecontractualDetalleList.url,
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.FETCH_SADDE_PDF_DOCUMENT_PREVIEW_FAILURE:
            return state;
        case actionTypes.FETCH_PRE_CONTRACTUALS_REQUEST:
            return state.merge({
                isFetchingPrecontracts: true
            })
        case actionTypes.FETCH_PRE_CONTRACTUALS_SUCCESS:
            return state.merge({
                isFetchingPrecontracts: false
            }).set('precontracts', Immutable.fromJS(action.payload.precontracts));
        case actionTypes.FETCH_PRE_CONTRACTUALS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetchingPrecontracts: false,
                precontracts: []
            });
        default:
            return state;
    }
}

module.exports = saddeReducer;
