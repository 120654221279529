// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    EURO_TEXT,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    WIZARD_THIRD_STEP
} = require('constants/index');
const brokerStocksAdvancedSellStep1 = require('./brokerStocksAdvancedSell/brokerStocksAdvancedSellStep1');
const brokerStocksAdvancedSellStep2 = require('./brokerStocksAdvancedSell/brokerStocksAdvancedSellStep2');
const brokerStocksAdvancedSellStep3 = require('./brokerStocksAdvancedSell/brokerStocksAdvancedSellStep3');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerStocksAdvancedSellStep1(undefined, {type: null}),
            brokerStocksAdvancedSellStep2(undefined, {type: null}),
            brokerStocksAdvancedSellStep3(undefined, {type: null})
        ],
        visibleStep: 1,
        productCurrency: EURO_TEXT,
        willCancel: false,
        submitSuccessful: false,
        //ISIN of the stock I'm selling
        isin: '',
        //ISO of the stock I'm selling
        iso: '',
        validationServiceResponse: {},
        signaturePatternToken: '',
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerStocksAdvancedSellStep1(step, action);
            case SECOND_STEP:
                return brokerStocksAdvancedSellStep2(step, action);
            case THIRD_STEP:
                return brokerStocksAdvancedSellStep3(step, action);
        }
    })
}

function brokerStocksAdvancedSellReducer(state = setInitialState(), action) {
    let partialSteps;
    let visibleStep;

    switch (action.type) {
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_RESET:
            return setInitialState();
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_THIRD_STEP:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_ORDER_TERMS_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_ORDER_CONDITION_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_ORDER_CONDITION_TYPE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_CONDITION_TYPE_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_INPUT_SEARCH_PRODUCT_SELECTED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_ORDER_TERM_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
        case actionTypes.BROKER_SEARCH_SELECTED_RESULT_RESET:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_TITLE_QUANTITY_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_ORDER_TYPE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_LIMITED_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDITY_DATE_CHANGED:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_SET_LIMITED_VALUE_CURRENCY:
            return state.merge({
                productCurrency: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_FINISH:
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_REQUEST:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                case WIZARD_THIRD_STEP:
                    return state;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_SUCCESS:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                case WIZARD_THIRD_STEP:
                    return state;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep: WIZARD_THIRD_STEP,
                        validationServiceResponse: action.payload.data
                    });
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_VALIDATE_FAILURE:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                case WIZARD_THIRD_STEP:
                    return state;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        error: action.payload.data.error
                    });
            }
            return state;
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_SET_ORDER_ID:
            return state.merge({
                isin: action.payload.isin,
                iso: action.payload.iso
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_SIGNATURE_SUCCESS:
            return state.set('signaturePatternToken', action.payload.token);
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_CONFIRM_REQUEST:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_CONFIRM_SUCCESS:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_SELL_CONFIRM_FAILURE:
            partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                error: action.payload.data.error
            });
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedSellReducer;
