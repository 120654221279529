const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isFetching: false,
    hasBeenFetched: false,
    error: '',
    errorReceived: false,
    conditions: []
});

function parseResponse(data) {
    return data.map(item => ({
        value: item.conditionId,
        label: item.name
    }));
}

const brokerAlertsConditionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BROKER_ALERTS_CONFIG_GET_CONDITIONS_REQUEST:
            return state.merge({
                isFetching: true,
                errorReceived: false,
                hasBeenFetched: false,
                error: '',
                conditions: []
            });
        case actionTypes.BROKER_ALERTS_CONFIG_GET_CONDITIONS_SUCCESS:
            return state.merge({
                isFetching: false,
                errorReceived: false,
                hasBeenFetched: true,
                error: '',
                conditions: parseResponse(action.payload.data || [])
            });
        case actionTypes.BROKER_ALERTS_CONFIG_GET_CONDITIONS_FAILURE:
            return state.merge({
                isFetching: false,
                hasBeenFetched: false,
                error: action.payload.error,
                errorReceived: true,
                conditions: []
            });
        default:
            return state
    }
}

module.exports = brokerAlertsConditionsReducer;


