// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    id: null,
    mensaje: '',
    isFetching: false,
    isFetchingConfirm: false,
    renderData: false,
    isFetchingCheckPersonalInfo: false,
    statusConfirmData: '',
    completeHoldersData: false,
    errorDetails: [],
    documentCirbe: null,
    scrollDown: false,
    disbledButtonOffice: true,
});

function setField(state, action) {
    const { inputField, data } = action.payload;
    return state.merge({
        [inputField]: data
    });
}

const holderConfirmEdit = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_REQUEST:
            return state.merge({
                isFetchingConfirm: true
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_SUCCESS:
            return state.merge({
                error: '',
                isFetchingConfirm: false,
                renderData: true
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetchingConfirm: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_EDIT_SET_FIELD:
            return setField(state,action);
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_REQUEST:
            return state.merge({
                errorDetails: [],
                isFetchingCheckPersonalInfo: true
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_SUCCESS:
            return state.merge({
                statusConfirmData: action.data.statusFl,
                completeHoldersData: action.data.statusFl !== 'KO' ? true : false,
                errorDetails: action.data.errorDetails,
                isFetchingCheckPersonalInfo: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                errorDetails: [],
                isFetchingCheckPersonalInfo: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_SUCCESS:
            return state.merge({
                idExpediente: action.payload.idExpedient,
                operationProcessId: action.payload.operationProcessId,
                operationProcesses: action.payload.operationProcesses,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_SUCCESS:
            return state.merge({
                documentCirbe: action.payload.base64Document,
                isFetching: false,
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_SCROLL_DOWN:
            return state.merge({
                scrollDown: true,
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_BUTTON_OFFICE_ENABLE:
            return state.merge({
                disbledButtonOffice: false,
            });          
        default:
            return state
    }
};

module.exports = holderConfirmEdit;
