// @vendors
const Immutable = require('immutable');

// @constants
const {
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    FIRST_STEP
} = require('constants/index');

const {
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_DERIVATIVE_ORIGIN,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_VALIDATE_STEP,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_SET_VISIBLE_STEP,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_SUCCESS,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_ERROR,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_ACCOUNT,
    BROKER_CREATE_TRANSFER_OF_DERIVATIVES_RESET
} = require('constants/actionTypes');

const setInitialState = () => (
    Immutable.fromJS({
        steps: [
            {
                account: '',
                accountIsDirty: false,
                selectedOriginDerivative: null,
                destinationAccount: '',
                amount: 0,
                amountCurrency: 'EUR',
                invalidSelectedOriginDerivative: false,
                invalidAmount: false,
                valid: false
            },
            {
                valid: true
            }
        ],
        creationDate: new Date(),
        visibleStep: WIZARD_FIRST_STEP,
        finished: false,
        loading: false
    })
);

const validateStep = (immState, step) => {
    switch (step) {
        case WIZARD_FIRST_STEP:
            const selectedOriginDerivative = immState.getIn(['steps', FIRST_STEP, 'selectedOriginDerivative']);
            const invalidSelectedOriginDerivative = selectedOriginDerivative === null;
            const accountIsDirty = immState.getIn(['steps', FIRST_STEP, 'destinationAccount']) === '' ||
                                   immState.getIn(['steps', FIRST_STEP, 'destinationAccount']) === undefined;

            const amount = immState.getIn(['steps', FIRST_STEP, 'amount']);
            const invalidAmount = parseFloat(amount) <= 0;

            return immState.mergeIn(
                ['steps', FIRST_STEP],
                {
                    valid: !invalidSelectedOriginDerivative && !invalidAmount && !accountIsDirty,
                    accountIsDirty,
                    invalidSelectedOriginDerivative,
                    invalidAmount
                }
            );
        default:
            return immState;
    }
};

const setVisibleStep = (immState, step) => {
    switch (step) {
        case WIZARD_FIRST_STEP:
            return immState.setIn(['steps', FIRST_STEP, 'valid'], false).set('visibleStep', step);
        case WIZARD_SECOND_STEP:
            return immState.set('visibleStep', step);
        default:
            return immState;
    }
};

function brokerCreateTransferOfDerivativesReducer(state = setInitialState(), action) {
    switch (action.type) {
        /*case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_BEGIN_OPERATION:
            return INITIAL_STATE.set('destinationContractNumber', action.payload.contractNumber);*/
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_DERIVATIVE_ORIGIN:
            return state.mergeIn(
                ['steps', FIRST_STEP],
                {
                    selectedOriginDerivative: action.payload.option,
                    invalidSelectedOriginDerivative: false
                }
             );
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_ACCOUNT:
            return state.mergeIn(
                ['steps', FIRST_STEP],
                {
                    destinationAccount: action.payload.accountId,
                    accountIsDirty: false
                }
            );
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT:
            return state.mergeIn(
                ['steps', FIRST_STEP],
                {
                    amount: action.payload.amount,
                    invalidAmount: false
                }
            );
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_VALIDATE_STEP:
            return validateStep(state, action.payload.step);
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH:
            return state.set('loading', true);
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_SUCCESS:
            return state.merge({
                loading: false,
                finished: true
            });
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_FINISH_ERROR:
            return state.set('loading', false); // @hardcoded
        case BROKER_CREATE_TRANSFER_OF_DERIVATIVES_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = brokerCreateTransferOfDerivativesReducer;
