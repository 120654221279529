const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');
const { FIRST_STEP, DATE_FORMAT_API } = require('constants/index');
const transferStep1 = require('./transfer/transferStep1');
const { parseTransferCosts } = require('utilities/transfersHelper');
// @ contact-center
const {
    TRANSFER_SET_WEEK_DAY,
    TRANSFER_RESET_WEEK_DAY,
    TRANSFER_CUSTOM_REPEAT_ADD_MONTH_DAY,
    TRANSFER_SET_PERIODICITY_RANGE_QUANTITY,
    TRANSFER_SET_PERIODICITY_RANGE_DESCRIPTION,
    TRANSFER_CUSTOM_REPEAT_SET_MONTH_DAY,
    TRANSFER_CUSTOM_REPEAT_REMOVE_MONTH_DAY,
    TRANSFER_SET_PERIODICITY_RANGE_SPECIFICATION,
    TRANSFER_SET_TRANSFER_TYPE
} = require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [transferStep1(undefined, { type: null }), { hasSignaturePattern: true }],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        editBeneficiarySuccessful: false,
        addBeneficiarySuccessful: false,
        willLeave: false,
        referenceNumber: '',
        transferFailed: false, // flag to hide/show wizard Error
        error: '',
        mailError: '',
        extra: {},
        transferImmediate: false,
        isFetchingTransferImmediate: false,
        failureTransferImmediate: false,
        limitError: false,
        transferImmediateAmount: 0,
        doTransferImmediate: false,
        showScamWarning: false,
        validateFromScamWarning: false,
        productOffers: {
            isFetching: false,
            error: false,
            data: null,
            success: false
        }
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return transferStep1(step, action);
        }
    });
}

function updateRequestTransfer(state, action) {
    return state.merge({
        isFetching: false,
        submitSuccessful: true,
        inProgress: false,
        referenceNumber: action.payload.referenceNumber,
        mailError: action.payload.mailError
    });
}

function updateRequestTransferFailure(state, action) {
    return state.merge({
        error: action.payload.error,
        errorDescription: action.payload.errorDescription,
        transferFailed: true,
        isFetching: false,
        submitSuccessful: false
    });
}


function transferReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.TRANSFER_RESET:
            return state.merge({
                ...setInitialState().toJS(),
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
            });
        case actionTypes.TRANSFER_VALIDATE_UI_STEP:
            switch (action.payload.data) {
                case 1:
                    return state.merge({
                        steps: updateStep(FIRST_STEP, state.get('steps'), action)
                    });
            }
            return state;
        case actionTypes.TRANSFER_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.data
            });
        case actionTypes.TRANSFER_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        case actionTypes.TRANSFER_IS_FETCHING:
            return state.merge({
                isFetching: action.payload.data
            });
        case actionTypes.TRANSFER_REQUEST_TRANSFER_REQUEST:
        case actionTypes.TRANSFER_VALIDATE_STEP_REQUEST:
        case actionTypes.BENEFICIARY_DETAILS_FETCH:
            return state.merge({
                isFetching: true,
                error: ''
            });
        case actionTypes.BENEFICIARY_DETAILS_SUCCESS:
        case actionTypes.BENEFICIARY_DETAILS_FAILURE:
            return state.merge({
                isFetching: false
            });
        case actionTypes.TRANSFER_DO_IMMEDIATE:
            return state.merge({
                doTransferImmediate: action.payload.isImmediateTransfer
            })
        case actionTypes.TRANSFER_VALIDATE_STEP_SUCCESS:
            const dateTransferEmisionSetInValidation = state.getIn(['steps',0,'date']).format(DATE_FORMAT_API);
            const fechaValorReturnedByBack = action.payload.fechaValor;
            const isToday = state.getIn(['steps',0,'isToday']);
            
            return state.mergeDeep({
                extra: {
                    costs: parseTransferCosts(action.payload.costs),
                    commissions: {
                        amount: get(action, 'payload.commissions.importe'),
                        currency: get(action, 'payload.commissions.divisa')
                    },
                    shipmentCosts: {
                        amount: get(action, 'payload.shipmentCosts.importe'),
                        currency: get(action, 'payload.shipmentCosts.divisa')
                    },
                    totalPrice: {
                        amount: get(action, 'payload.totalPrice.importe'),
                        currency: get(action, 'payload.totalPrice.divisa')
                    },
                    bicBanco: action.payload.bicBanco,
                    confirmationCode: action.payload.code,
                    confirmationNumber: action.payload.number,
                    agentConfirmationCode: action.payload.agentCode,
                    agentConfirmationNumber: action.payload.agentNumber,
                    returnCode: action.payload.returnCode, // this will come from nosepa only
                    operationCode: action.payload.operationCode,
                    newValidDateWhenItWillBeDone: ( (dateTransferEmisionSetInValidation !== fechaValorReturnedByBack) && isToday)? fechaValorReturnedByBack : null
                },
            });
        case actionTypes.TRANSFER_REQUEST_TRANSFER_SUCCESS:
            return updateRequestTransfer(state, action)
        case actionTypes.TRANSFER_REQUEST_EDIT_TRANSFER_SUCCESS:
            return updateRequestTransfer(state, action)
        case actionTypes.TRANSFER_REQUEST_CHARITY_TRANSFER_SUCCESS:
            return updateRequestTransfer(state, action)
        case actionTypes.TRANSFER_VALIDATE_STEP_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.TRANSFER_REQUEST_TRANSFER_FAILURE:
            return updateRequestTransferFailure(state, action);
        case actionTypes.TRANSFER_REQUEST_EDIT_TRANSFER_FAILURE:
            return updateRequestTransferFailure(state, action);
        case actionTypes.TRANSFER_REQUEST_CHARITY_TRANSFER_FAILURE:
            return updateRequestTransferFailure(state, action);
        case actionTypes.TRANSFER_FROM_ACCOUNT:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                //only mark in progress if the account was chosen in the form
                inProgress: !action.payload.isInitialAccount
            });
        case actionTypes.TRANSFER_ADD_BENEFICIARY_SUCCESS:
            return state.merge({
                addBeneficiarySuccessful: true
            })
        case actionTypes.TRANSFER_IMMEDIATE_FETCHING:
            return state.merge({
                isFetchingTransferImmediate: true,
                failureTransferImmediate: false,
            })
        case actionTypes.TRANSFER_IMMEDIATE_FAILURE:
            return state.merge({
                failureTransferImmediate: true,
                isFetchingTransferImmediate: false
            })
        case actionTypes.TRANSFER_IMMEDIATE_SUCCESS:
            return state.merge({
                transferImmediate: action.payload.isImmediate,
                transferImmediateAmount: action.payload.amount,
                isFetchingTransferImmediate: false,
                failureTransferImmediate: false,
                doTransferImmediate: false
            })
        case actionTypes.TRANSFER_EDIT_BENEFICIARY_SUCCESS:
            return state.merge({
                editBeneficiarySuccessful: true
            });
        case actionTypes.TRANSFER_SCAM_WARNING:
            return state.merge({
                showScamWarning: action.payload.value,
                isFetching: false
            });
        case actionTypes.TRANSFER_LIMIT_ERROR:
            return state.merge({
                limitError: action.payload.value,
            });
        case actionTypes.TRANSFER_BENEFICIARY_EXISTING:
        case actionTypes.TRANSFER_BENEFICIARY_NAME:
        case actionTypes.ADD_BENEFICIARY_NOT_DEDUCTIBLE_MESSAGE:
        case actionTypes.FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY:
        case actionTypes.TRANSFER_BENEFICIARY_ALIAS:
        case actionTypes.TRANSFER_BENEFICIARY_BIC:
        case actionTypes.TRANSFER_BENEFICIARY_IBAN:
        case actionTypes.TRANSFER_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE:
        case actionTypes.TRANSFER_BENEFICIARY_ACCOUNT_NUMBER:
        case actionTypes.TRANSFER_BENEFICIARY_ADD_AGENDA:
        case actionTypes.TRANSFER_BENEFICIARY_TYPE:
        case actionTypes.TRANSFER_BENEFICIARY_COUNTRY:
        case actionTypes.TRANSFER_BENEFICIARY_CURRENCY:
        case actionTypes.TRANSFER_BENEFICIARY_BANK_OPTION:
        case actionTypes.TRANSFER_BENEFICIARY_BANK_NAME:
        case actionTypes.TRANSFER_BENEFICIARY_BANK_ADDRESS:
        case actionTypes.TRANSFER_BENEFICIARY_BANK_CITY:
        case actionTypes.TRANSFER_BENEFICIARY_ADDRESS:
        case actionTypes.TRANSFER_BENEFICIARY_CITY:
        case actionTypes.TRANSFER_AMOUNT:
        case actionTypes.TRANSFER_CONCEPT:
        case actionTypes.TRANSFER_DATE:
        case actionTypes.TRANSFER_SET_ALL_FIELDS:
        case actionTypes.TRANSFER_SET_EMAIL_NOTIFICATION:
        case actionTypes.TRANSFER_PAYMENT_BY_CHANGED:
        case actionTypes.TRANSFER_SET_PERIODICITY_ENABLED:
        case actionTypes.TRANSFER_SET_PERIODICITY_TYPE:
        case actionTypes.TRANSFER_SET_PERIODICITY_RANGE:
        case actionTypes.TRANSFER_SET_PERIODICITY_DATE:
        case actionTypes.TRANSFER_SET_PERIODICITY_COUNT:
        case actionTypes.TRANSFER_SET_COSTS:
        case actionTypes.TRANSFER_SET_TREATMENT_NON_WORKABLE_DAY:
        case actionTypes.TRANSFER_FILL_FORM:
        case actionTypes.TRANSFER_BENEFICIARY_FILL_EDIT_FORM:
        case actionTypes.TRANSFER_BENEFICIARY_CANCEL_EDIT_FORM:
        case actionTypes.TRANSFER_SET_EMITTER:
        case actionTypes.TRANSFER_SET_NGO_VALIDITY:
        case actionTypes.TRANSFER_LOAD_OLD_EMITTER:
        case actionTypes.RESET_TRANSFER_DATES:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            });
        case actionTypes.RESET_IMMEDIATE_TRANSFER:
            return state.merge({
                transferImmediate: false,
                transferImmediateAmount: 0,
                isFetchingTransferImmediate: false,
                failureTransferImmediate: false,
                doTransferImmediate: false
            });
        case TRANSFER_SET_WEEK_DAY:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case TRANSFER_CUSTOM_REPEAT_ADD_MONTH_DAY:
        case TRANSFER_CUSTOM_REPEAT_SET_MONTH_DAY:
        case TRANSFER_CUSTOM_REPEAT_REMOVE_MONTH_DAY:
            return state.merge({
               steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case TRANSFER_SET_PERIODICITY_RANGE_QUANTITY:
            return state.merge({
               steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case TRANSFER_SET_PERIODICITY_RANGE_DESCRIPTION:
            return state.merge({
               steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case TRANSFER_SET_PERIODICITY_RANGE_SPECIFICATION:
            return state.merge({
               steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case TRANSFER_RESET_WEEK_DAY:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case TRANSFER_SET_TRANSFER_TYPE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.SET_PRODUCT_OFFERS_REQUEST:
            return state.mergeDeep({
                productOffers: {
                    isFetching: true,
                    error: false,
                    data: null,
                    success: false
                },
            });
        case actionTypes.SET_PRODUCT_OFFERS_FAILURE:
            return state.mergeDeep({
                productOffers: {
                    isFetching: false,
                    error: true,
                }
            });
        case actionTypes.SET_PRODUCT_OFFERS_SUCCESS:
            return state.mergeDeep({
                productOffers: {
                    isFetching: false,
                    success: true,
                    data: action.payload.response
                }
            });
        case actionTypes.RESET_PRODUCT_OFFERS:
            return state.mergeDeep({
                productOffers: {
                    isFetching: false,
                    success: false,
                    data: null,
                    error: false
                }
            });
        default:
            return state;
    }
}

module.exports = transferReducer;
