// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    collectivesId: '',
    productList: [],
    errorContent: '',
    hasBeenFetched: false,
    isFetchingContent: false,
});

function parseContentResponse(state, response){

    return state.merge({
        hasBeenFetched: true,
        isFetchingContent: false,
        collectivesId: response.collectivesId,
        productList: response.productList
    });
}

function collectivesProductListReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.COLLECTIVE_PRODUCT_LIST_REQUEST:
            return state.merge({
                isFetchingContent: true,
            });

        case actionTypes.COLLECTIVE_PRODUCT_LIST_SUCCESS:
            return parseContentResponse(state, action.payload.response);

        case actionTypes.COLLECTIVE_PRODUCT_LIST_FAILURE:
            return state.merge({
                errorContent: action.payload.error,
                isFetchingContent: false,
            });

        default:
            return state;
    }
}

module.exports = collectivesProductListReducer;
