export const brokerGeneralTags = (eventTag, eventAction) => (
    {
        alertsTooltip: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'manage',
            etiquetaEvento: 'alerts'
        },
        pgOptionsMenu: {

            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'Menu',
            etiquetaEvento: eventTag

        },
        pgSliderMenu: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: eventTag
        },
        pgPreferencesIcon: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'manage',
            etiquetaEvento: 'customizedViews'
        },
        pgBuyButton: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'buy',
            etiquetaEvento: 'products'
        },
        popularTabSelector: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'mostPopularCard - slider',
            etiquetaEvento: eventTag
        },
        tooltip: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'mostPopularCard - tooltip',
            etiquetaEvento: 'tooltip'
        },
        brokerPopular: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: `mostPopularCard - ${eventAction}`,
            etiquetaEvento: eventTag
        },
        fundsSearch: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'mostPopularCard - footer',
            etiquetaEvento: 'Search'
        },
        buildPortfolio: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'mostPopularCard - footer',
            etiquetaEvento: 'buildYourPortfolio'
        },
        myPortfolioCard: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'myPortfolioCard',
            etiquetaEvento: eventTag,
        },
        seeMoreBannerButton: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'footballAndTechnologicalStocks',
            etiquetaEvento: 'seeMore'
        },
        tooltipTrendsCard: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'trendsCard - tooltip',
            etiquetaEvento: 'tooltip'
        },
        trendsCardBuy: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'trendsCard - buy',
            etiquetaEvento: eventTag
        },
        newsCardNew: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'newsCard',
            etiquetaEvento: `${eventTag}New`
        },
        newsCardSeeAll: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'newsCard',
            etiquetaEvento: 'seeAllNews'
        },
        seeMoreEvents: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'agendaCard',
            etiquetaEvento: 'seeMoreEvents'
        },
        agendaCard: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'agendaCard',
            etiquetaEvento: `${eventTag}Event`
        },
        todayProtagonistsSelector: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'protagonistsCard - slider',
            etiquetaEvento: eventTag
        },
        todayProtagonistsProductLink: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'protagonistsCard - seeStocks',
            etiquetaEvento: eventTag
        },
        todayProtagonistsBuyButton: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'protagonistsCard - buyProduct',
            etiquetaEvento: eventTag
        },
        todayProtagonistsSeeMore: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'protagonistsCard',
            etiquetaEvento: 'seeMoreProducts'
        },
        productStatusActive: {
            categoriaEvento: 'myPortfolioHomeInteraction',
            accionEvento: 'productStatus',
            etiquetaEvento: 'view',
            productStatus: eventTag
        }
    }
)