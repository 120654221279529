const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    values: [],

    // Not implemented yet
    funds: [],
    plans: [],
    futures: []
});


function buildContractState(walletItem) {
    return Immutable.fromJS({
        name: walletItem.descripcion,
        accountNumber: walletItem.contrato.numerodecontrato,
        owner: walletItem.nombreTitular,
        totalAmount: walletItem.saldo1.importe,
        currency: walletItem.saldo1.divisa,
        product: walletItem.contrato.producto,
        earnings: null,
        earningsCurrency: 'EUR',
        contract: walletItem.contrato,
        contractNumber: `${walletItem.contrato.numerodecontrato}${walletItem.contrato.producto}`,
        intervenerType: walletItem.descIntervenciones.nomTipInterv,
        positions: 0,
        id: walletItem.contrato.numerodecontrato + walletItem.contrato.producto,
        filtros: walletItem.filtros,
        saldo1: walletItem.saldo1,
        contrato: walletItem.contrato
    });
}

function buildContractsMap(walletList) {
    const contractsMap = {};
    walletList.forEach( walletItem => {
        const id = walletItem.contrato.numerodecontrato + walletItem.contrato.producto;
        contractsMap[id] = buildContractState(walletItem);
    });
    return Immutable.Map(contractsMap);
}

function userInvestmentsContracts(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_WALLET_LIST_SUCCESS:
            return state.merge({
                values: buildContractsMap(action.walletList),
                funds: action.walletFunds
            });
        default:
            return state;
    }
}

module.exports = userInvestmentsContracts;
