//@vendors
const Immutable = require('immutable');

//@helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');
const accountCancelCheques = require('./accountCancelCheques/accountCancelChequesStep1');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            accountCancelCheques(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        willCancel: false,
        error: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return accountCancelCheques(step, action);
        }
    })
}

function accountCancelChequesReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps,
                    })
            }
            return state;
        case actionTypes.ACCOUNT_CANCEL_CHECKS_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.ACCOUNT_CANCEL_CHECKS_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.ACCOUNT_CANCEL_CHECKS_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                errorReceived: ''
            })
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_SUCCESS:
        case actionTypes.ACCOUNT_CANCEL_CHECKS_SETUP_FIRST_STEP:
        case actionTypes.ACCOUNT_CANCEL_CHECKS_CHEQUE_CHANGED:
        case actionTypes.ACCOUNT_CANCEL_CHECKS_AMOUNT_CHANGED:
        case actionTypes.ACCOUNT_CANCEL_CHECKS_REASON_CHANGED:
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_API_REQUEST:
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_API_FAILURE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_API_SUCCESS:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.ACCOUNT_CANCEL_CHECKS_RESET_VIEW:
            return setInitialState();
        case actionTypes.ACCOUNT_CANCEL_CHEQUE_FAILURE:
            return state.mergeDeep({
                error: action.payload.error
            });
        default:
            return state
    }
}

module.exports = accountCancelChequesReducer;
