// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    'success': false,
    'error': '',
    'inProgress': false,
    'newLimit': 0,
    'newLimitIsDirty': false,
    'steps': [
        {
            valid: false
        },
        {
            hasSignaturePattern: true
        }
    ],
    willLeave: false,
    visibleStep: 1,
    willCancel: false
});

function validateNewLimit(state) {
    const newLimit = state.get('newLimit');
    const valid = newLimit > 0;

    return state.setIn(['steps', 0, 'valid'], valid);
}

function creditPregrantedLimitVariationReducer(state = initialState, action) {
    let newState;
    switch (action.type) {
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_SET_LIMIT:
            newState = state.mergeDeep({
                'newLimit': action.payload.limit,
                'newLimitIsDirty': true
            });
            return validateNewLimit(newState);
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_VALIDATE_STEP:
            newState = validateNewLimit(state);
            return newState.mergeDeep({
                'newLimitIsDirty': true
            });
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_WILL_CANCEL:
            return state;
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_CLEAR:
            return initialState;
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_REQUEST:
            return state.merge({
                'inProgress': true
            });
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_SUCCESS:
            return state.merge({
                'success': true,
                'inProgress': false
            });
        case actionTypes.CREDIT_PREGRANTED_LIMIT_VARIATION_FAILURE:
            return state.merge({
                'error': action.payload.error,
                'inProgress': false
            });
        default:
            return state;
    }
}

module.exports = creditPregrantedLimitVariationReducer;
