module.exports = {
    title: 'contractsView-contractsView_checkingAccountCustomizeCardTitle',
    order: 6,
    footer_text: '',
    position: 'middle',
    override_validation_step: 5,
    components: [
        {
            id_component: 'card-image-selector',
            id: 'card-image-selector',
            cardImageIds: [
                'B.506.002.020',
            ],
            cardImageIdsFF: [
                "B.506.002.020", 
            ],
            cardImageIdsFutureMode: [
                'B.506.002.020',
            ],
            onChange: 'checkingPayrollAccount_handleCardImageChange',
        },
        {
            id_component: 'card-pin-change',
            id: 'card-pin-change',
            onBlur: 'checkingPayrollAccount_handlePinBlur',
        },
    ]
};