const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: false,
    warrantChart: [],
    underlyingChart: []
});

function brokerWarrantsProductFileReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_HISTORICAL_FETCHING:
            return state.set('isFetching', true)
                        .set('error', false);
        case actionTypes.BROKER_GET_HISTORICAL_FAILURE:
            return state.set('isFetching', false)
                        .set('error', action.payload.data);
        case actionTypes.BROKER_GET_HISTORICAL_SUCCESS:
            return state.set('isFetching', false)
                        .set('error', false)
                        .set('warrantChart', Immutable.fromJS(action.payload.data.warrant))
                        .set('underlyingChart', Immutable.fromJS(action.payload.data.underlying));
        default:
            return state;
    }
}

module.exports = brokerWarrantsProductFileReducer;
