const BrowserUtil = {

    /**
     * Detects if it's a mobile browser
     * @return {Boolean} true if it's a mobile browser
     */
    isMobile() {
        return /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent);
    },

    /**
     * Gives support to "event.relatedTarget" object on IE and FF
     * https://github.com/facebook/react/issues/2011
     * @param {Event}
     * @return {Object}
     */
    relatedTarget(event) {
        return event.relatedTarget ||
                event.explicitOriginalTarget ||
                document.activeElement;
    },

    browserIsIE() {
        return /(Edge|Trident)/i.test(navigator.appVersion);//checks if it's IE or Edge
    },

    browserIsIE11orBelow() {
        return /(Trident|MSIE)/i.test(navigator.appVersion);//checks if it's IE or below
    },

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
};


module.exports = BrowserUtil;
