//@vendor
const Immutable = require('immutable');
//@actions
const actionTypes = require('constants/actionTypes');
//@constants
const { FIRST_STEP } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        error: false,
        inProgress: false,
        isFetching: false,
        success: false,
        visibleStep: 1,
        newPin: '',
        newPinConfirmation: '',
        newPinConfirmationIsValid: false,
        isDirty: false,
        steps: [
            {
                valid: false
            },
            {
                hasSignaturePattern: true
            }
        ]
    });
}

function validateStepOne(state) {
    const newPin = state.get('newPin');
    const newPinConfirmation = state.get('newPinConfirmation');
    const newPinConfirmationIsValid = state.get('newPinConfirmationIsValid');
    const path = ['steps', FIRST_STEP, 'valid'];
    let valid;
    let visibleStep = 1;

    if (newPin && newPinConfirmation && newPinConfirmationIsValid) {
        valid = true;
        visibleStep = 2;
    }

    return state.set('visibleStep', visibleStep).set('isDirty', true).setIn(path, valid);
}

function validateStep(state, step) {
    switch (step) {
        case 1:
            return validateStepOne(state);
        default:
            return state;
    }
}

function cardMinicardPinChangeReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_PIN_CHANGE_VALIDATE_STEP:
            return validateStep(state, action.payload.step);
        case actionTypes.CARD_PIN_CHANGE_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step)
                   .setIn(['steps', action.payload.step, 'valid'], false);
        case actionTypes.CARD_PIN_CHANGE_SET_NEW_PIN:
            return state.set('newPin', action.payload.newPin);
        case actionTypes.CARD_PIN_CHANGE_SET_NEW_PIN_CONFIRMATION:
            const newPinConfirmation = action.payload.newPinConfirmation;
            return state.set('newPinConfirmation', newPinConfirmation).set('newPinConfirmationIsValid', newPinConfirmation === state.get('newPin'));
        case actionTypes.CARD_PIN_CHANGE_SUCCESFUL:
            return state.set('success', true);
        case actionTypes.CARD_PIN_CHANGE_FAILURE:
            return state.set('error', action.payload.error);
        case actionTypes.CARD_PIN_CHANGE_INVALIDATE_STEP:
            return state.setIn(['steps', action.payload.step, 'valid'], false);
        case actionTypes.CARD_PIN_CHANGE_RESET_VIEW:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = cardMinicardPinChangeReducer;
