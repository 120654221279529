module.exports = {
    id_component: 'tab-form',
    label: 'Datos Personales',
    id: 'tab-form-datos-personales',
    exclude: [],
    grid: [
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-nombre',
                label: 'Nombre*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'onChangeSliderMoneyNeeded',
                target: 'personal_form_personal_data'
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-primer-apellido',
                label: 'Primer apellido*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'onChangeSliderMoneyNeeded',
                target: 'personal_form_personal_data'
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-segundo-apellido',
                label: 'Segundo apellido*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'onChangeSliderMoneyNeeded',
                target: 'personal_form_personal_data'
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_tipo_documento',
                label: 'Tipo de documento*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbDocumentType',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-numero-documento',
                label: 'Numero de documento*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'onChangeSliderMoneyNeeded',
                target: 'personal_form_personal_data'
            },
            {
                id_component: 'date-picker',
                id: 'input-safe-birthday',
                label: 'Fecha de nacimiento*',
                type_format: 'text',
                type: 'single',
                onChangeFn: 'perosnalLoan_handleDatePickerCallback',
                target: 'personal_form_personal_data'
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_sexo',
                label: 'Sexo*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbGender',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'combo',
                id: 'cmb_estado_civil',
                label: 'Estado Civil*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbCivilStatus',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target:'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-hijos-a-cargo',
                label: 'Hijos a cargo*',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'onChangeSliderMoneyNeeded',
                target: 'personal_form_personal_data'
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_nivel_estudios',
                label: 'Nivel de estudios*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbLevelOfStudies',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'combo',
                id: 'cmb_idioma',
                label: 'Idioma*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbLanguage',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_pais_nacimiento',
                label: 'País de nacimiento*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbCountryOfBirth',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'combo',
                id: 'cmb_pais_residencia',
                label: 'País de residencia*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbResidenceCountry',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'combo',
                id: 'cmb_nacionalidad',
                label: 'Nacionalidad*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbNationality',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_residencia_avalista',
                label: 'Residencia Fiscal en España*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbTaxResidenceInSpain',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'combo',
                id: 'cmb_rel_titular_avalista',
                label: 'Relación con el primer titular*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbRelationshipWithFirstTitular',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'combo',
                id: 'cmb_tipo_intervencion_avalista',
                label: 'Tipo de intervención',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbInterventionType',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_forma_intervencion_avalista',
                label: 'Forma de intervención*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbFormOfIntervention',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_personal_data',
                options: [],
                order: 3,
                msg_error: ''
            }
        ]
    ]
};
