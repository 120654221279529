// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
const brokerStocksSellStep1 = require('./brokerStocksSell/brokerStocksSellStep1');
const brokerStocksSellStep2 = require('./brokerStocksSell/brokerStocksSellStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerStocksSellStep1(undefined, { type: null }),
            brokerStocksSellStep2(undefined, { type: null })
        ],
        emisionCode: -1,
        valueCode: -1,
        visibleStep: 1,
        submitSuccessful: false,
        exAnteData: [],
        exAnteDataFetching: false,
        estimatedBaseAmount: null,
        estimatedBaseAmountExchanged: null,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerStocksSellStep1(step, action);
            case SECOND_STEP:
                return brokerStocksSellStep2(step, action);
        }
    });
}

function brokerStocksSellReducer(state = setInitialState(), action) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.BROKER_STOCKS_SELL_RESET:
            return setInitialState();
        case actionTypes.BROKER_STOCKS_SELL_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_STOCKS_SELL_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.BROKER_STOCKS_SELL_VALIDATE_SUCCESS:
            return state
                .set('confirmationData', action.payload.data)
                .setIn(['steps', FIRST_STEP, 'loading'], false)
                .set('visibleStep', 2);
        case actionTypes.BROKER_STOCKS_SELL_VALIDATE_FAILURE:
            return state.setIn(['steps', FIRST_STEP, 'loading'], false);
        case actionTypes.BROKER_STOCKS_SELL_CONFIRM_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'loading'], true);
        case actionTypes.BROKER_STOCKS_SELL_CONFIRM_SUCCESS:
            return state
                .set('submitSuccessful', true)
                .setIn(['steps', SECOND_STEP, 'loading'], false)
                .set('orderNumber', action.payload.data.numeroOrden);
        case actionTypes.BROKER_STOCKS_SELL_CONFIRM_FAILURE:
            //TODO handle error better
            return state
                .set('submitSuccessful', false)
                .setIn(['steps', SECOND_STEP, 'loading'], false);
        case actionTypes.BROKER_STOCKS_SELL_VALIDATE_REQUEST:
            return state.setIn(['steps', FIRST_STEP, 'loading'], true);
        case actionTypes.BROKER_STOCKS_SELL_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_STOCKS_SELL_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_STOCKS_SELL_ORDER_TYPE_CHANGED:
        case actionTypes.BROKER_STOCKS_SELL_LIMITED_VALUE_CHANGED:
        case actionTypes.BROKER_STOCKS_SELL_TITLE_QUANTITY_CHANGED:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
        case actionTypes.BROKER_SELL_CONTRACT_NUMBER:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_BUY_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_STOCKS_SELL_FINISH:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_STOCKS_SELL_SET_CODES:
            return state.set('emisionCode', action.payload.emisionCode)
                        .set('valueCode', action.payload.valueCode);
        case actionTypes.BROKER_STOCKS_SELL_EX_ANTE_FETCHING:
            return state.set('exAnteDataFetching', true);
        case actionTypes.BROKER_STOCKS_SELL_EX_ANTE_SUCCESS:
            return state.merge({
                exAnteData: action.payload.data.response.exAnteCostList,
                exAnteDataFetching: false,
                estimatedBaseAmount: action.payload.data.response.estimatedBaseAmount,
                estimatedBaseAmountExchanged: action.payload.data.response.estimatedBaseAmountExchanged,
            });
        case actionTypes.BROKER_STOCKS_SELL_EX_ANTE_FAILURE:
            return state.set('exAnteDataFetching', false);
        default:
            return state;
    }
}

module.exports = brokerStocksSellReducer;
