const moment = require('moment');
const { contractsValidations } = require('constants/contractsValidations');

module.exports = {
    id_component: 'grid-form',
    label: 'contractsView-juniorAccountPersonalData',
    id: 'tab-form-datos-personales',
    exclude: [],
    buildCondition: 'juniorAccount_showBeneficiaryDataForms',
    grid: [
        [
            {
                id_component: 'simple-text',
                label: 'contractsView-juniorAccountStep2PersonalDataSimpleText'
            }
        ],
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-name',
                label: 'contractsView-juniorAccountFormName',
                type_format: 'text',
                type: 'form',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_personal_data',
                required: true,
                maxLength: 20
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-last-name',
                label: 'contractsView-juniorAccountFormLastName1',
                type_format: 'text',
                type: 'form',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_personal_data',
                required: true,
                maxLength: 80
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-second-last-name',
                label: 'contractsView-juniorAccountFormLastName2',
                type_format: 'text',
                type: 'form',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_personal_data',
                required: true,
                maxLength: 80
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_tipo_documento',
                label: 'contractsView-juniorAccountFormDocumentType',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadCmbDocumentType',
                options: [],
                order: 3,
                target: 'junior_form_personal_data',
                default_value: 'N',
                validations: [
                    {
                        associatedComponent: {
                            id: 'input-number-documents',
                            target: 'junior_form_personal_data',
                            type: 'form'
                        },
                        validationFunction: contractsValidations.VALIDATE_DOCUMENT_TYPE_NOT_EMPTY
                    }
                ],
                required: true
            },
            {
                id_component: 'input-safe',
                id: 'input-number-documents',
                label: 'contractsView-juniorAccountFormDocumentNumber',
                type_format: 'text',
                type: 'form',
                onBlurFn: 'juniorAccount_handleBlur',
                customClass: 'contract-input__uppercase-input',
                target: 'junior_form_personal_data',
                required: true,
                validations: [
                    {
                        associatedComponent: {
                            id: 'cmb_tipo_documento',
                            target: 'junior_form_personal_data',
                            type: 'form'
                        },
                        validationFunction: contractsValidations.VALIDATE_DOCUMENT_NUMBER
                    }, {
                        validationFunction: contractsValidations.VALIDATE_EXISTING_OYACCOUNT_DOCUMENT_NUMBER
                    },
                ]
            },
            {
                id_component: 'date-picker',
                id: 'date-picker-birthday',
                label: 'contractsView-juniorAccountFormBirthdate',
                type_format: 'text',
                type: 'form',
                default_value: moment().startOf('day').subtract(18, 'years').add(1, 'days'),
                onChangeFn: 'juniorAccount_handleDatePickerCallback',
                target: 'junior_form_personal_data',
                required: true,
                yearsDropDown: true,
                maxDate: 'today',
                minDate: {
                    amount: 18,
                    type: 'years'
                }
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_pais_nacimiento',
                label: 'contractsView-juniorAccountFormBirthCountry',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadCmbCountries',
                options: [],
                order: 3,
                target: 'junior_form_personal_data',
                required: true,
                default_value: 'ES' //@hardcoded
            },
            {
                id_component: 'input-safe',
                id: 'input-birthplace',
                label: 'contractsView-juniorAccountFormBirthPopulation',
                type_format: 'text',
                type: 'form',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_personal_data',
                required: true,
                maxLength: 50
            },
            {
                id_component: 'combo',
                id: 'cmb_birth_province',
                label: 'contractsView-juniorAccountFormBirthProvince',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadCmbProvince',
                options: [],
                order: 3,
                target: 'junior_form_personal_data',
                required: true,
                visible: {
                    target: 'junior_form_personal_data.cmb_pais_nacimiento',
                    value: ['ES']
                }
            }
        ],
        [
            {
                id_component: 'radio-button',
                id: 'cmb_sexo',
                label: 'contractsView-juniorAccountFormGenre',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                options: [
                    {
                        label: 'contractsView-juniorAccountFormGenreMale',
                        id: 0
                    },
                    {
                        label: 'contractsView-juniorAccountFormGenreFemale',
                        id: 1
                    }
                ],
                order: 3,
                target: 'junior_form_personal_data',
                required: true,
                default_value: 1 //@hardcoded
            },
            {
                id_component: 'combo',
                id: 'cmb_personal_nacionalidad',
                label: 'contractsView-juniorAccountFormNationality',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadCmbCountries',
                options: [],
                order: 3,
                target: 'junior_form_personal_data',
                required: true,
                default_value: 'ES' //@hardcoded
            },
            {
                id_component: 'clear-item'
            }
        ]
    ]
};
