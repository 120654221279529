// @vendors
const Immutable = require('immutable');
const { get } = require('lodash');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIFTH_STEP,
    FIRST_STEP,
    FOURTH_STEP,
    SECOND_STEP,
    SIXTH_STEP,
    THIRD_STEP,
} = require('constants/index');

// @reducers
const hirePackOpenYoungStep1 = require('./hirePackOpenYoung/hirePackOpenYoungStep1');
const hirePackOpenYoungStep2 = require('./hirePackOpenYoung/hirePackOpenYoungStep2');
const hirePackOpenYoungStep3 = require('./hirePackOpenYoung/hirePackOpenYoungStep3');
const hirePackOpenYoungStep4 = require('./hirePackOpenYoung/hirePackOpenYoungStep4');
const hirePackOpenYoungStep5 = require('./hirePackOpenYoung/hirePackOpenYoungStep5');
const hirePackOpenYoungStep6 = require('./hirePackOpenYoung/hirePackOpenYoungStep6')

// @utilities
const { getCardId } = require('utilities/cardsHelper');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    cardId: '',
    error: false,
    errorMsg: '',
    hireAccountError: false,
    hireCardError: false,
    hiringResponse: {},
    hiringStatus: '',
    isFetching: false,
    steps: [
        hirePackOpenYoungStep1(),
        hirePackOpenYoungStep2(),
        hirePackOpenYoungStep3(),
        hirePackOpenYoungStep4(),
        hirePackOpenYoungStep5(),
        hirePackOpenYoungStep6()
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag: false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hirePackOpenYoungStep1(step, action);
            case SECOND_STEP:
                return hirePackOpenYoungStep2(step, action);
            case THIRD_STEP:
                return hirePackOpenYoungStep3(step, action);
            case FOURTH_STEP:
                return hirePackOpenYoungStep4(step, action);
            case FIFTH_STEP:
                return hirePackOpenYoungStep5(step, action);
            case SIXTH_STEP:
                return hirePackOpenYoungStep6(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function hirePackOpenYoungReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_PACK_OPEN_YOUNG_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 4:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 5:
                    partialSteps = updateStep(FIFTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 6:
                    partialSteps = updateStep(SIXTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
            case actionTypes.HIRE_CARD_LOADING:
                return state.merge({
                    loadingFlag: action.payload.loadingFlag
                });
        case actionTypes.HIRE_PACK_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX:
            return state.merge({
                    steps: updateStep(SECOND_STEP, state.get('steps'), action)
                });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_SET_ACCOUNT:
        case actionTypes.HIRE_PACK_OPEN_YOUNG_VALIDATE_ACCOUNT:
            return state.merge({
                steps: updateStep(THIRD_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_SET_AGREEMENT_CHECKBOX:
            const stepToUpdate = __CONTACT_CENTER__ ? FIRST_STEP : SIXTH_STEP;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action)
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_SUCCESS:
        const panNumber = get(action.payload.response, 'panTarjeta', '');
        const cardId = getCardId(panNumber);

            return state.merge({
                cardId: cardId,
                isFetching: false,
                hiringResponse: action.payload.response,
                submitSuccessful: true,
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.error,
                hireAccountError: action.payload.hireAccountError,
                hireCardError: action.payload.hireCardError,
                isFetching: false
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        case actionTypes.HIRE_PACK_OPEN_YOUNG_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hirePackOpenYoungReducer;
