// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');
const ACCOUNT_PRECAUTIONARY_WARNINGS = require('constants/contactCenter/accountPrecautionaryWarnings');
// @helpers
const DateHelper = require('utilities/dateHelper');
// @utilities
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: '',
    byId: {},
    byOrder: [],
    group: ACCOUNT_PRECAUTIONARY_WARNINGS.GROUP,
    endList: ACCOUNT_PRECAUTIONARY_WARNINGS.END_LIST,
    isFetchingNextPage: false
});

function isWarningValid(warning) {
  return warning.cdCircuit && warning.noticeCircuitCode
      && ACCOUNT_PRECAUTIONARY_WARNINGS.ACTIVE_CODE === warning.sit;
}

function parseWarnings(warnings) {
    let byId = {};
    let byOrder = [];

    warnings.forEach( (warning) => {
        const parsedWarning = {
            date: DateHelper.fromArray(warning.fecalta),
            typology: warning.certext,
            description: warning.demotivo,
            startDate: DateHelper.fromArray(warning.fecvig),
            endDate: DateHelper.fromArray(warning.fecvto),
            cdCircuit: get(warning, 'coaccio2.cdcircuito'),
            noticeCircuitCode: get(warning, 'condAviConex2.regla2AVISO.regla1AVISO.codcircuitoaviso'),
            sit: warning.sit
        };

        parsedWarning.id = hash([parsedWarning.date, parsedWarning.typology, parsedWarning.description, parsedWarning.startDate, parsedWarning.endDate, parsedWarning.cdCircuit, parsedWarning.noticeCircuitCode, parsedWarning.sit])

        if (isWarningValid(parsedWarning)) {
            byId[parsedWarning.id] = parsedWarning;
            byOrder.push(parsedWarning.id);
        }
    });

    return {
        byId,
        byOrder
    };
}

function addMoreWarnings(state, payload) {
    const newWarnings = Immutable.fromJS(parseWarnings(payload.data));

    return state.merge({
        isFetchingNextPage: false,
        error: '',
        byId: state.get('byId').concat(newWarnings.get('byId')),
        byOrder: state.get('byOrder').concat(newWarnings.get('byOrder')),
        group: payload.group,
        endList: payload.endList
    });
}

function accountPrecautionaryWarningsReducer(state = initialState, action) {
    switch(action.type) {
        case actionTypes.ACCOUNTS_PRECAUTIONARY_WARNINGS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS:
            const parsedWarnings = parseWarnings(action.payload.data);
            return state.merge({
                isFetching: false,
                error: '',
                byId: parsedWarnings.byId,
                byOrder: parsedWarnings.byOrder,
                group: action.payload.group,
                endList: action.payload.endList,
                isFetchingNextPage: false
            });
        case actionTypes.ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
                byId: {},
                byOrder: [],
                group: ACCOUNT_PRECAUTIONARY_WARNINGS.GROUP,
                endList: ACCOUNT_PRECAUTIONARY_WARNINGS.END_LIST,
                isFetchingNextPage: false
            });
        case actionTypes.ACCOUNTS_PRECAUTIONARY_WARNINGS_REQUEST_NEXT_PAGE:
            return state.merge({
                isFetchingNextPage: true
            });
        case actionTypes.ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS_NEXT_PAGE:
            return addMoreWarnings(state, action.payload);
        case actionTypes.ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE_NEXT_PAGE:
            return state.merge({
                isFetchingNextPage: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = accountPrecautionaryWarningsReducer;
