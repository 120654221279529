const iconPhoneAndroid = require('assets/images/phone-android.svg');
const iconPhoneAndroidBlock = require('assets/images/phone-android-block.svg');
const iconPhoneAndroidBlockLight = require('assets/images/phone-android-block-light.svg');
const iconPhoneAndroidLight = require('assets/images/phone-android-light.svg');
const iconPhoneAndroidOnline = require('assets/images/phone-android-online.svg');
const iconPhoneIos = require('assets/images/phone-ios.svg');
const iconPhoneIosBlock = require('assets/images/phone-ios-block.svg');
const iconPhoneIosBlockLight = require('assets/images/phone-ios-block-light.svg');
const iconPhoneIosLight = require('assets/images/phone-ios-light.svg');
const iconPhoneIosOnline = require('assets/images/phone-ios-online.svg');
const iconPhone = require('assets/images/phone.svg');
const iconPhoneBlock = require('assets/images/phone-block.svg');
const iconPhoneBlockLight = require('assets/images/phone-block-light.svg');
const iconPhoneLight = require('assets/images/phone-light.svg');
const iconPhoneOnline = require('assets/images/phone-online.svg');
const iconTabletFirefox = require('assets/images/tablet-firefox.svg');
const iconTabletFirefoxBlock = require('assets/images/tablet-firefox-block.svg');
const iconTabletFirefoxBlockLight = require('assets/images/tablet-firefox-block-light.svg');
const iconTabletFirefoxLight = require('assets/images/tablet-firefox-light.svg');
const iconTabletFirefoxOnline = require('assets/images/tablet-firefox-online.svg');
const iconTabletSafari = require('assets/images/tablet-safari.svg');
const iconTabletSafariBlock = require('assets/images/tablet-safari-block.svg');
const iconTabletSafariBlockLight = require('assets/images/tablet-safari-block-light.svg');
const iconTabletSafariLight = require('assets/images/tablet-safari-light.svg');
const iconTabletSafariOnline = require('assets/images/tablet-safari-online.svg');
const iconTabletChrome = require('assets/images/tablet-chrome.svg');
const iconTabletChromeBlock = require('assets/images/tablet-chrome-block.svg');
const iconTabletChromeBlockLight = require('assets/images/tablet-chrome-block-light.svg');
const iconTabletChromeLight = require('assets/images/tablet-chrome-light.svg');
const iconTabletChromeOnline = require('assets/images/tablet-chrome-online.svg');
const iconTabletExplorer = require('assets/images/tablet-explorer.svg');
const iconTabletExplorerBlock = require('assets/images/tablet-explorer-block.svg');
const iconTabletExplorerBlockLight = require('assets/images/tablet-explorer-block-light.svg');
const iconTabletExplorerLight = require('assets/images/tablet-explorer-light.svg');
const iconTabletExplorerOnline = require('assets/images/tablet-explorer-online.svg');
const iconTablet = require('assets/images/tablet.svg');
const iconTabletBlock = require('assets/images/tablet-block.svg');
const iconTabletBlockLight = require('assets/images/tablet-block-light.svg');
const iconTabletLight = require('assets/images/tablet-light.svg');
const iconTabletOnline = require('assets/images/tablet-online.svg');
const iconLaptopSafari = require('assets/images/laptop-safari.svg');
const iconLaptopSafariBlock = require('assets/images/laptop-safari-block.svg');
const iconLaptopSafariBlockLight = require('assets/images/laptop-safari-block-light.svg');
const iconLaptopSafariLight = require('assets/images/laptop-safari-light.svg');
const iconLaptopSafariOnline = require('assets/images/laptop-safari-online.svg');
const iconLaptopChrome = require('assets/images/laptop-chrome.svg');
const iconLaptopChromeBlock = require('assets/images/laptop-chrome-block.svg');
const iconLaptopChromeBlockLight = require('assets/images/laptop-chrome-block-light.svg');
const iconLaptopChromeLight = require('assets/images/laptop-chrome-light.svg');
const iconLaptopChromeOnline = require('assets/images/laptop-chrome-online.svg');
const iconLaptopExplorer = require('assets/images/laptop-explorer.svg');
const iconLaptopExplorerBlock = require('assets/images/laptop-explorer-block.svg');
const iconLaptopExplorerBlockLight = require('assets/images/laptop-explorer-block-light.svg');
const iconLaptopExplorerLight = require('assets/images/laptop-explorer-light.svg');
const iconLaptopExplorerOnline = require('assets/images/laptop-explorer-online.svg');
const iconLaptopFirefox = require('assets/images/laptop-firefox.svg');
const iconLaptopFirefoxBlock = require('assets/images/laptop-firefox-block.svg');
const iconLaptopFirefoxBlockLight = require('assets/images/laptop-firefox-block-light.svg');
const iconLaptopFirefoxLight = require('assets/images/laptop-firefox-light.svg');
const iconLaptopFirefoxOnline = require('assets/images/laptop-firefox-online.svg');
const iconLaptop = require('assets/images/laptop.svg');
const iconLaptopBlock = require('assets/images/laptop-block.svg');
const iconLaptopBlockLight = require('assets/images/laptop-block-light.svg');
const iconLaptopLight = require('assets/images/laptop-light.svg');
const iconLaptopOnline = require('assets/images/laptop-online.svg');

const IMAGES = {
    iconPhoneAndroid,
    iconPhoneAndroidBlock,
    iconPhoneAndroidBlockLight,
    iconPhoneAndroidLight,
    iconPhoneAndroidOnline,
    iconPhoneIos,
    iconPhoneIosBlock,
    iconPhoneIosBlockLight,
    iconPhoneIosLight,
    iconPhoneIosOnline,
    iconPhone,
    iconPhoneBlock,
    iconPhoneBlockLight,
    iconPhoneLight,
    iconPhoneOnline,
    iconTabletFirefox,
    iconTabletFirefoxBlock,
    iconTabletFirefoxBlockLight,
    iconTabletFirefoxLight,
    iconTabletFirefoxOnline,
    iconTabletSafari,
    iconTabletSafariBlock,
    iconTabletSafariBlockLight,
    iconTabletSafariLight,
    iconTabletSafariOnline,
    iconTabletChrome,
    iconTabletChromeBlock,
    iconTabletChromeBlockLight,
    iconTabletChromeLight,
    iconTabletChromeOnline,
    iconTabletExplorer,
    iconTabletExplorerBlock,
    iconTabletExplorerBlockLight,
    iconTabletExplorerLight,
    iconTabletExplorerOnline,
    iconTablet,
    iconTabletBlock,
    iconTabletBlockLight,
    iconTabletLight,
    iconTabletOnline,
    iconLaptopSafari,
    iconLaptopSafariBlock,
    iconLaptopSafariBlockLight,
    iconLaptopSafariLight,
    iconLaptopSafariOnline,
    iconLaptopChrome,
    iconLaptopChromeBlock,
    iconLaptopChromeBlockLight,
    iconLaptopChromeLight,
    iconLaptopChromeOnline,
    iconLaptopExplorer,
    iconLaptopExplorerBlock,
    iconLaptopExplorerBlockLight,
    iconLaptopExplorerLight,
    iconLaptopExplorerOnline,
    iconLaptopFirefox,
    iconLaptopFirefoxBlock,
    iconLaptopFirefoxBlockLight,
    iconLaptopFirefoxLight,
    iconLaptopFirefoxOnline,
    iconLaptop,
    iconLaptopBlock,
    iconLaptopBlockLight,
    iconLaptopLight,
    iconLaptopOnline
}

const getDevicesKey = (blocked, forgotten, unknown = false) => {
    // Visible devices
    if (blocked && !forgotten)
        return "devicesBlocked"
    if (!blocked && !forgotten && unknown)
        return "devicesUnknown"
    if (!blocked && !forgotten && !unknown)
        return "devices"
        
    // Hidden devices
    if (blocked && forgotten)
        return "forgottenDevicesBlocked"
    if (!blocked && forgotten && unknown)
        return "forgottenDevicesUnknown"
    if (!blocked && forgotten && !unknown)
        return "forgottenDevices"
}

const getIsFetchingKey = (blocked, forgotten) => {
    if (blocked && forgotten)
        return "isFetchingforgottenDevicesBlocked"
    if (!blocked && forgotten)
        return "isFetchingforgottenDevices"
    if (blocked && !forgotten)
        return "isFetchingDevicesBlocked"
    if (!blocked && !forgotten)
        return "isFetchingDevices"
}

export {
    IMAGES,
    getDevicesKey,
    getIsFetchingKey
}
