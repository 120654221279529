
const brokerStocksTags = (eventTag, eventAction) => (
    {
        brokerPopular: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: `mostPopular - ${eventAction}Stocks`,
            etiquetaEvento: eventTag
        },
        searchButton: {
            categoriaEvento: 'buscador/simple',
            accionEvento: 'inicio',
            etiquetaEvento: 'stocks'
        },
        buyButton: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'buy',
            etiquetaEvento: 'stocks'
        },
        buyButtonComCard: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'comercialCard',
            etiquetaEvento: 'buy'
        },
        comercialCardsBuy: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'ComercialCard',
            etiquetaEvento: eventTag
        },
        comercialCardsLink: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'ComercialCard',
            etiquetaEvento: eventTag
        },
        tooltip: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'mostPopular',
            etiquetaEvento: 'Tooltip'
        },
        buildPortfolio: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'mostPopular',
            etiquetaEvento: 'BuildYourPortfolio'
        },
        stocksSearch: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'mostPopular',
            etiquetaEvento: 'stocksSearch'
        },
        cnmvLink: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'riskIndicator',
            etiquetaEvento: 'www.cnmv.es'
        },
        openBankLink: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'riskIndicator',
            etiquetaEvento: 'www.openbank.es'
        },
        sliderProducts: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'products'
        },
        sliderContracts: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'contracts'
        },
        rentabilityTooltip: {
            categoriaEvento: 'searchInteraction',
            accionEvento: 'rentabilityTooltip',
            etiquetaEvento: eventTag
        },
        searchInteractionLink: {
            categoriaEvento: 'searchInteraction',
            accionEvento: `see ${eventAction}`,
            etiquetaEvento: eventTag
        },
        searchInteractionButton: {
            categoriaEvento: 'searchInteraction',
            accionEvento: `buy ${eventAction}`,
            etiquetaEvento: eventTag
        },
        buystockStep1: {
            categoriaEvento: 'proc-broker-comprar-fondo-inversion',
            accionEvento: 'inicio',
            etiquetaEvento: eventTag
        },
        dataProtectionLinkStep1: {
            categoriaEvento: 'stockBuyInteraction',
            accionEvento: 'dataProtection',
            etiquetaEvento: 'step 1'
        },
        buystockStep2: {
            categoriaEvento: 'proc-broker-comprar-fondo-inversion',
            accionEvento: 'paso_2',
            etiquetaEvento: eventTag
        },
        GoToSearchButton: {
            categoriaEvento: 'stockBuyInteraction',
            accionEvento: 'goToSearch',
            etiquetaEvento: eventTag
        },
        buttonLegalDocs_DFI: {
            categoriaEvento: 'stockBuyInteraction',
            accionEvento: 'keyData',
            etiquetaEvento: 'read'
        },
        buttonLegalDocs_ECONOMIC_REPORT: {
            categoriaEvento: 'stockBuyInteraction',
            accionEvento: 'lastEconomicReport',
            etiquetaEvento: 'read'
        },
        buttonLegalDocs_MARKETING_REPORT: {
            categoriaEvento: 'stockBuyInteraction',
            accionEvento: 'marketingReport',
            etiquetaEvento: 'read'
        },
        buttonLegalDocs_COST: {
            categoriaEvento: 'stockBuyInteraction',
            accionEvento: 'fees',
            etiquetaEvento: 'read'
        },
        todayProtagonistsSelector: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'protagonistsCard - slider',
            etiquetaEvento: eventTag
        },
        todayProtagonistsTooltip: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'protagonistsCard - tooltip',
            etiquetaEvento: 'tooltip'
        },
        todayProtagonistsInputSelect: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'protagonistsCard',
            etiquetaEvento: eventTag
        },
        todayProtagonistsProductLink: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'protagonistsCard - seeStocks',
            etiquetaEvento: eventTag
        },
        todayProtagonistsBuyButton: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'protagonistsCard - buyProduct',
            etiquetaEvento: eventTag
        },
        todayProtagonistsSeeMore: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'protagonistsCard',
            etiquetaEvento: 'seeMoreProducts'
        },
        knowTheseStocksSlider: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'otherStocks - sliderMenu',
            etiquetaEvento: eventTag
        },
        knowTheseStocksproductLink: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'othersStocks - seeStocks',
            etiquetaEvento: eventTag
        },
        knowTheseStocksBuyButton: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'othersStocks - buyStocks',
            etiquetaEvento: eventTag
        },
        knowTheseStocksAdvSearch: {
            categoriaEvento: 'stocksHomeInteraction',
            accionEvento: 'othersStocks - search',
            etiquetaEvento: 'advancedSearch'
        },
        brokerProductFile: {
            categoriaEvento: 'stockDescriptionInteraction',
            accionEvento: 'evolution',
            etiquetaEvento: 'detail'
        }
    }
)

module.exports = { brokerStocksTags }