// @helpers
const actionTypes = require('constants/actionTypes');
const get = require('lodash/object/get');

// @vendors
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isStatusFetching: false,
    fetched: false,
    fiocStatus: undefined,
    documentationRequested: false,
    fiocRequested: false,
    documentList: [],
    files: {},
    documentListError: undefined,
    uploadDocumentsPending: false,
    uploadDocumentsSuccess: undefined,
    uploadDocumentsError: undefined,
    statusError: false
});
function getFilteredDocumentList(documentosList){
    const allowedDocumentStatus = ["RN", "RE", "RK", "RV"];
    return documentosList.filter(document => allowedDocumentStatus.indexOf(document["estadoDocumento"]) !== -1);
}
function fiocDocumentation(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FIOC_STATUS_RESET:
            return state.mergeDeep({
                fetched: false
            });

        case actionTypes.GET_FIOC_STATUS_IN_PROGRESS:
            return state.mergeDeep({
                fetched: false,
                isStatusFetching: true,
                documentationRequested: false,
                codMessage: "00",
                fiocRequested: false,
                fiocStatus: undefined,
                documentList: [],
                statusError: false
            });
        case actionTypes.GET_FIOC_STATUS_SUCCESS:
            return state.mergeDeep({
                fetched: true,
                isStatusFetching: false,
                codMessage: action.payload.codMensaje,
                //codMessage: "10",
                documentationRequested: action.payload.flagDocumentacion === "1",
                fiocRequested: action.payload.flagFioc === "1",
                //fiocRequested: false,
                fiocStatus: parseInt(action.payload.estadoCliente),
                documentList: getFilteredDocumentList(get(action.payload, 'documentosList.documentosList', [])),
                statusError: false
            });
        case actionTypes.GET_FIOC_STATUS_ERROR:
            return state.mergeDeep({
                fetched: false,
                isStatusFetching: false,
                documentationRequested: false,
                codMessage: "00",
                fiocRequested: false,
                fiocStatus: undefined,
                documentList: [],
                statusError: true
            });
        case actionTypes.REMOVE_DOCUMENT_LIST_ERROR:
            return state.merge({
                documentListError: undefined
            })
        case actionTypes.FIOC_UPLOAD_DOCUMENTS_REQUEST:
            return state.merge({
                uploadDocumentsPending: true,
                uploadDocumentsSuccess: undefined,
                uploadDocumentsError: undefined,
                files: {}
            })
        case actionTypes.FIOC_UPLOAD_DOCUMENTS_SUCCESS:
            return state.merge({
                fetched: false,
                uploadDocumentsPending: false,
                uploadDocumentsSuccess: action.payload.response,
                uploadDocumentsError: undefined
            })
        case actionTypes.FIOC_UPLOAD_DOCUMENTS_ERROR:
            return state.merge({
                uploadDocumentsPending: false,
                uploadDocumentsSuccess: undefined,
                uploadDocumentsError: action.payload.error
            })
        case actionTypes.FIOC_ADD_FILE:
            const fileObj = {
                name: action.payload.name,
                data: action.payload.file
            };
            return state.setIn(['files', fileObj.name], fileObj);
        case actionTypes.FIOC_REMOVE_FILE:
            let files = state.get('files');
            files = files.delete(action.payload.name);
            return state.merge({
                files
            });
        default:
            return state
    }
}

module.exports = fiocDocumentation;
