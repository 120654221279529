// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        fetchError: false,
        fetchSuccess: false,
        isFetching: false,
        isSubscribed: false,
        postStatusError: false,
        postStatusFetching: false,
        postStatusSuccess: false,
        postUnsubscriptionStatusError: false,
        postUnsubscriptionStatusFetching: false,
        postUnsubscriptionStatusSuccess: false,
        showErrorModal: false,
        showSubscriptionModal: false,
        showUnsubscriptionModal: false,
        termsAndConditionChecked: false,
        termsAndConditionsError: false,
        termsAndConditionsFetching: false,
        termsAndConditionsSuccess: false
    });
}

function processPersoneticsSubscriptionStatusSuccess(state, status) {
    return state.merge({
        fetchError: false,
        fetchSuccess: true,
        isFetching: false,
        isSubscribed: status,
        postUnsubscriptionStatusSuccess: false
    });
}

function personeticsSubscriptionReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GET_PERSONETICS_SUBSCRIPTION_STATUS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.GET_PERSONETICS_SUBSCRIPTION_STATUS_SUCCESS:
            return processPersoneticsSubscriptionStatusSuccess(state, action.payload.subscriptionStatus);
        case actionTypes.GET_PERSONETICS_SUBSCRIPTION_STATUS_ERROR:
            return state.merge({
                fetchError: true,
                fetchSuccess: false,
                isFetching: false,
                termsAndConditionChecked: false
            });
        case actionTypes.POST_PERSONETICS_SUBSCRIPTION_STATUS_REQUEST:
            return state.merge({
                isFetching: true,
                termsAndConditionsFetching: true
            });
        case actionTypes.POST_PERSONETICS_SUBSCRIPTION_STATUS_SUCCESS:
            return state.merge({
                isFetching: false,
                isSubscribed: true,
                showSubscriptionModal: false,
                termsAndConditionChecked: false,
                termsAndConditionsError: false,
                termsAndConditionsFetching: false,
                termsAndConditionsSuccess: true
            });
        case actionTypes.POST_PERSONETICS_SUBSCRIPTION_STATUS_ERROR:
            return state.merge({
                fetchSuccess: true,
                isFetching: false,
                isSubscribed: false,
                showErrorModal: true,
                termsAndConditionChecked: false,
                termsAndConditionsError: true,
                termsAndConditionsFetching: false
            });
        case actionTypes.OPEN_PERSONETICS_SUBSCRIPTION_MODAL:
            return state.merge({
                showSubscriptionModal: true,
            });
        case actionTypes.OPEN_PERSONETICS_UNSUBSCRIPTION_MODAL:
            return state.merge({
                showUnsubscriptionModal: true,
            });
        case actionTypes.POST_PERSONETICS_UNSUBSCRIPTION_STATUS_REQUEST:
            return state.merge({
                postUnsubscriptionStatusFetching: true
            });
        case actionTypes.POST_PERSONETICS_UNSUBSCRIPTION_STATUS_SUCCESS:
            return state.merge({
                isSubscribed: false,
                postUnsubscriptionStatusError: false,
                postUnsubscriptionStatusFetching: false,
                postUnsubscriptionStatusSuccess: true,
                showUnsubscriptionModal: false,
            });
        case actionTypes.POST_PERSONETICS_UNSUBSCRIPTION_STATUS_ERROR:
            return state.merge({
                showErrorModal: true,
                postUnsubscriptionStatusError: true,
                postUnsubscriptionStatusFetching: false,
                showUnsubscriptionModal: false,
            });
        case actionTypes.PERSONETICS_SUBSCRIPTION_UNSUBSCRIPTION_CLOSE_MODAL:
            return state.merge({
                showErrorModal: false,
                showSubscriptionModal: false,
                showUnsubscriptionModal: false,
                termsAndConditionsError: false,
                termsAndConditionsFetching: false,
                termsAndConditionsSuccess: false,
                termsAndConditionChecked: false
            });
        case actionTypes.PERSONETICS_SUBSCRIPTION_TERMS_AND_CONDS_CHECKBOX_CLICK:
            return state.merge({
                termsAndConditionChecked: action.payload.termsAndConditionChecked
            })
        default:
            return state;
    }
}

module.exports = personeticsSubscriptionReducer;
