// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    BROKER_ORDER_TYPE_TO_MARKET,
    BROKER_ORDER_TYPE_LIMITED
} = require('constants/index');

function setInitialState() {

    return Immutable.fromJS({
        selectedOptionValue: -1,
        titleQuantity: 0,
        orderTypeOption: BROKER_ORDER_TYPE_TO_MARKET,
        orderTypeValue: 0,
        validityDate: '',
        loading: false,
        valid: false,
        errorFlags: {
            minorThanLote: false,
            notMultiploLote: false
        }
    });
}

function validateStep(step, catalogData) {
    const orderTypeValueValid = (step.get('orderTypeOption') !== BROKER_ORDER_TYPE_LIMITED ||
        (step.get('orderTypeOption') === BROKER_ORDER_TYPE_LIMITED) && step.get('orderTypeValue') > 0);
    const titleQuantityValid = step.get('titleQuantity') !== 0;
    const orderTypeValid = step.get('selectedOptionValue') !== -1;

    let isBuyByLotError = false;
    let minorThanLote = false;
    let notMultiploLote = false;
    const operatedByLot = catalogData.get('operatedByLot');
    if (operatedByLot) {
        minorThanLote = step.get('titleQuantity') < catalogData.get('lot');
        notMultiploLote = !(step.get('titleQuantity') % catalogData.get('lot') == 0);
        isBuyByLotError = (
            minorThanLote || notMultiploLote
        );
    }

    const valid = orderTypeValid && orderTypeValueValid && titleQuantityValid && !isBuyByLotError;
    return step.merge({
        valid,
        orderTypeValueValid,
        titleQuantityValid,
        orderTypeValid,
        errorFlags: {
            minorThanLote,
            notMultiploLote
        }
    });
}

function brokerStocksAdvancedBuyStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_UI_STEP:
            return validateStep(state, action.payload.catalogData);
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED:
            return state.merge({
                selectedOptionValue: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: action.payload.value,
                errorFlags: {
                    minorThanLote: false,
                    notMultiploLote: false
                }
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_TYPE_CHANGED:
            return state.merge({
                orderTypeOption: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_LIMITED_VALUE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_REQUEST:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_SUCCESS:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_FAILURE:
            return state.merge({
                loading: false
            });
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedBuyStep2;
