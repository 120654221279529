// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes =  require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        error: '',
    });
}

function validateStep(step) {
    return step.merge({
        valid: true
    });
}

function updateClientContactTimeStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.UPDATE_CONTACT_TIME_VALIDATE_UI_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = updateClientContactTimeStep2;
