//@commons
const { formatText } = require('core/i18n').i18n;
//@helpers
const { formatAmount, formatInterest } = require('utilities/currencyHelper');
const { getFullName } = require('utilities/personalDataHelper');
const DateHelper = require('utilities/dateHelper')

const KEY_CONTEXT = 'contractsView';

const MAX_INTERVENERS = 4;

const EMPTY_ROW = (
    `<tr class="printer-deposit__table-row">
        <td class='printer-deposit__table-cell'>
        </td>
        <td class='printer-deposit__table-cell'>
        </td>
        <td class='printer-deposit__table-cell'>
        </td>
    </tr>`
);

const DEFAULT_OFFICE_PLACE = 'Madrid'

const getCustomPrintDocumentTable = (contractWizard) => {
    const interveners = contractWizard.get('interveners');
    const filteredInterveners = interveners.filter(intervener => !intervener.get('currentIntervener')).slice(0, MAX_INTERVENERS);
    const tableCells = filteredInterveners.reduce((acc, intervener) => (
        acc + `<tr class="printer-deposit__table-row">
                    <td class='printer-deposit__table-cell'>
                        ${intervener.get('name')}
                    </td>
                    <td class='printer-deposit__table-cell'>
                        ${intervener.get('documentNumber')}
                    </td>
                    <td class='printer-deposit__table-cell'>
                        ${intervener.get('type')}
                    </td>
                </tr>`
    ), ``);
    let emptyTableCells = ``;
    for (let i = 1; i <= MAX_INTERVENERS - filteredInterveners.size; i++) {
        emptyTableCells = emptyTableCells + EMPTY_ROW;  
    }
    return (
        `<table class="printer-deposit__table">
            <tr class="printer-deposit__table-header-row">
                <th class="printer-deposit__table-cell">${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentTableNameHeader')}</th>
                <th class="printer-deposit__table-cell">${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentTableDNIHeader')}</th>
                <th class="printer-deposit__table-cell">${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentTableInterventionHeader')}</th>
            </tr>
            ${tableCells}
            ${emptyTableCells}
        </table>`
    )
}

const getCustomPrintDocumentInterventionTypeCheckboxes = (contractWizard) => {
    const interveners = contractWizard.get('interveners');
    const currentIntervener = interveners.find(item => item.get('currentIntervener'));
    const interventionType = currentIntervener && currentIntervener.get('type');
    const holderClass = interventionType === 'TITULAR' ? 
        'printer-deposit__checkbox-mark' : 
        'printer-deposit__checkbox-unmarked';
    const authorizedClass = interventionType === 'AUTORIZADO' ? 
        'printer-deposit__checkbox-mark' : 
        'printer-deposit__checkbox-unmarked';
    const representativeClass = interventionType === 'APODERADO' ? 
        'printer-deposit__checkbox-mark' : 
        'printer-deposit__checkbox-unmarked';
    return (
        `
            <div class="printer-deposit__row-label">${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentInterventionType')}</div>
                <div class="printer-deposit__row-checkbox">
                    <span class=${holderClass}>${interventionType === 'TITULAR' ? "X" : ''}</span>
                    ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentOwnerIntervention')}
                </div>
                <div class="printer-deposit__row-checkbox">
                    <span class=${authorizedClass}>${interventionType === 'AUTORIZADO' ? "X" : ''}</span>
                    ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentAuthorizedIntervention')}
                </div>
                <div class="printer-deposit__row-checkbox">
                    <span class=${representativeClass}>${interventionType === 'APODERADO' ? "X" : ''}</span>
                    ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentRepresentativeIntervention')}
                </div>
        `
    )
}

const getAccountNumber = (immAccounts, id) => {
    const account = immAccounts.getIn(['byId',id]);
    return account.get('iban');
}

const getDepositsPrintedDocument = (contractWizard, immProfile, immAccounts) => {
    const table = getCustomPrintDocumentTable(contractWizard);
    const checkboxes = getCustomPrintDocumentInterventionTypeCheckboxes(contractWizard);
    const associatedAccount = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'cta_seleccionada');
    const associatedAccountId = associatedAccount && associatedAccount.get('value');
    const associatedAccountNumber = associatedAccountId && getAccountNumber(immAccounts, associatedAccountId);
    const productName = contractWizard.getIn(['deposit_product', 'context']) + "-" + contractWizard.getIn(['deposit_product', 'name']);
    const tin = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'tin').get('value');
    const tae = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'tae').get('value');
    const amount = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'monto_traspasar').get('value');
    const installmentInjectedStrings = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'installment').get('injectedStringValues');
    const installmentInjectedStringsArray =  installmentInjectedStrings && installmentInjectedStrings.toJS();
    return (
        `
            <article class="printer-deposit">
                <div class="printer-deposit__title">${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentTitle')}</div>
                <section class="printer-deposit__bank-data">
                    <div class="printer-deposit__row">
                        <div class="printer-deposit__row-label">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentEntity')}
                        </div>
                        <div class="printer-deposit__row-value">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentEntityValue')}
                        </div>
                    </div>
                    <div class="printer-deposit__row">
                        <div class="printer-deposit__row-label">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentOffice')}
                        </div>
                        <div class="printer-deposit__row-value">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentOfficeValue')}
                        </div>
                    </div>
                </section>
                <section>
                    <div class="printer-deposit__row">
                        <div class="printer-deposit__row-label">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentClient')}
                        </div>
                        <div class="printer-deposit__row-value">
                            ${getFullName(immProfile, false)}
                        </div>
                    </div>
                    <div class="printer-deposit__row">
                        <div class="printer-deposit__row-label">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentDNI')}
                        </div>
                        <div class="printer-deposit__row-value">
                            ${immProfile.getIn(['documentoIdentificacion','codigodocumpersonacorp'])}
                        </div>
                    </div>
                    <div class="printer-deposit__row">
                            ${checkboxes}
                    </div>
                    <div class="printer-deposit__row">
                        <div class="printer-deposit__row-label">
                            ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentAssociatedAccount')}
                        </div>
                        <div class="printer-deposit__row-value">
                            ${associatedAccountNumber}
                        </div>
                    </div>
                </section>
                <section class="printer-deposit__table-section">
                    <div class="printer-deposit__table-title">
                        <span>${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentIntervenersData')}</span>
                    </div>               
                    ${table}
                </section>
                <section>
                    <div class="printer-deposit__row-product">
                        ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentProductContracted', [formatText(productName).toUpperCase()])}
                    </div>
                    <div class="printer-deposit__row-product">
                        ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintDocumentInterests', [formatInterest(tin, false), formatInterest(tae, false)])}
                    </div>
                    <div class="printer-deposit__row-product">
                        <div class="printer-deposit__dual-row-content">
                            <span class="printer-deposit__dual-row-content-item">
                                ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintAmount', [formatAmount(amount)])}
                            </span>
                            <span class="printer-deposit__dual-row-content-item">
                                ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintInstallments', [installmentInjectedStringsArray])}
                            </span>
                        </div>
                    </div>
                </section>
                <section class="printer-deposit__legal-texts-section">
                    ${formatText(KEY_CONTEXT + '-' + 'contractsView_depositPrintLegalText')}
                </section>
            </article>
        `
    )
}

const getDateAndPlaceText = () => {
    const country = DEFAULT_OFFICE_PLACE
    const currentDate = new Date()
    const day = DateHelper.getDay(currentDate)
    const month = DateHelper.getMonthFullName(DateHelper.getMonth(currentDate))
    const year = DateHelper.getFullYear(currentDate)
    return formatText("contractsView-contractsView_depositPrintDocumentDatePlace", [country, day, month, year])
}

module.exports = {
    getCustomPrintDocumentTable,
    getCustomPrintDocumentInterventionTypeCheckboxes,
    getDateAndPlaceText,
    getDepositsPrintedDocument,
};