const Cookies = require('js-cookie');
const { hasLocalStorage } = require('utilities/storage');
const {
    COOKIE_PERCENTAGE_USAGE,
    IE_MAX_COOKIE_SIZE,
    MAX_SESSIONS_SIZE_BEFORE_CURRENT,
    SESSIONS_STATE,
} = require('constants/session');
const remove = require('lodash/array/remove');
const { isPrefixed, getSessionId, getStoragePrefix } = require('utilities/usd');
const moment = require('moment');
const { getCookiesKey } = require('utilities/storage');
const { LOCAL_ENVIRONMENT } = require('constants/index');

const removeSessionFromState = sessionId => {
    if (hasLocalStorage) {
        let sessionsStateArray = [];
        const sessionsState = localStorage.getItem(SESSIONS_STATE);

        if (sessionsState) {
            try {
                sessionsStateArray = JSON.parse(sessionsState);
            } catch (err) {
                window.console.warn('Invalid sessions state format');
                return;
            }
        }

        remove(sessionsStateArray, sessionObject => sessionObject.sessionId === sessionId);
        localStorage.setItem(SESSIONS_STATE, JSON.stringify(sessionsStateArray));
    }
};

const storeCurrentSessionState = () => {
    if (hasLocalStorage) {
        let sessionsStateArray = [];
        const sessionsState = localStorage.getItem(SESSIONS_STATE);

        if (sessionsState) {
            try {
                sessionsStateArray = JSON.parse(sessionsState);
            } catch (err) {
                window.console.warn('Invalid sessions state format');
                return;
            }
        }

        const sessionId = getSessionId();

        // First we remove the session from the array, if exists
        remove(sessionsStateArray, sessionObject => sessionObject.sessionId === sessionId);

        const sessionObject = {
            sessionId: sessionId,
            timestamp: moment().toISOString()
        }
        sessionsStateArray.push(sessionObject);
        localStorage.setItem(SESSIONS_STATE, JSON.stringify(sessionsStateArray));
    }
};

const retrieveSessionsState = () => {
    let sessionsStateArray = [];
    const sessionsState = localStorage.getItem(SESSIONS_STATE);

    if (sessionsState) {
        try {
            sessionsStateArray = JSON.parse(sessionsState);
        } catch (err) {
            window.console.warn('Invalid sessions state format');
        }
    }

    return sessionsStateArray;
};

const removeFromLocalStorageByCondition = (condition = (() => false)) => {
    for (let key in localStorage) {
        if (condition(key)) {
            localStorage.removeItem(key);
        }
    }
};

//TODO: it would be nice if this is only available when compiling the CC version of the webapp rather than replacingin it with an empty implementation
const cleanResources = __CONTACT_CENTER__ ? (domain) => {
    if (hasLocalStorage) {
        localStorage.removeItem('cclanguage');
        localStorage.removeItem('ccAgentLanguage');
        removeFromLocalStorageByCondition(isPrefixed);
        removeSessionFromState(getSessionId());
        localStorage.clear();
        sessionStorage.clear();
    } else {
        if (window.__ENV__ === LOCAL_ENVIRONMENT) {
            Cookies.remove(getCookiesKey('tokenCredential')); //this is bound to the getCookiesKey logic about which keys are prefixed
        } else {
            Cookies.remove(getCookiesKey('tokenCredential'),{domain});
        }
    }
} : () => {};

const clearCookiesCC = (domain) => {
    const usedPercentage = Math.ceil((document.cookie.length * 100) / IE_MAX_COOKIE_SIZE);
    if(usedPercentage >= COOKIE_PERCENTAGE_USAGE){
        const myCookies = Cookies.get();
        for (let key in myCookies) {
            if(key.includes('Session_')) {
                if (window.__ENV__ === LOCAL_ENVIRONMENT) {
                    try {
                        Cookies.remove(key);
                        window.console.log('Session cookie removed: ', key);
                    } catch(err) {
                        window.console.warn('Unable to remove session cookie: ', key);
                    }
                } else {
                    try {
                        Cookies.remove(key,{domain});
                        window.console.log('Session cookie removed: ', key);
                    } catch(err) {
                        window.console.warn('Unable to remove session cookie: ', key);
                    }
                }
            }
        }
    }
}

const removeLocalStorageByPrefix = (prefix) => {
    removeFromLocalStorageByCondition(key => key.indexOf(prefix) === 0);
};

const clearContactCenterSessions = (domain) => {
    if (!__CONTACT_CENTER__) return;

    if (hasLocalStorage) {
        const sessionsStateArray = retrieveSessionsState();

        if (sessionsStateArray.length > MAX_SESSIONS_SIZE_BEFORE_CURRENT) {
            // Sorts the sessions from newest to oldest and keeps only the newest MAX_SESSIONS_SIZE_BEFORE_CURRENT sessions, removes the rest
            sessionsStateArray.sort((firstElement, secondElement) => {
                if (moment(firstElement.timestamp).isBefore(moment(secondElement.timestamp))) {
                    return 1;
                } else {
                    return -1;
                }
            })
            .forEach((sessionObject, index) => {
                if (index >= MAX_SESSIONS_SIZE_BEFORE_CURRENT) {
                    const prefix = getStoragePrefix(sessionObject.sessionId);
                    removeLocalStorageByPrefix(prefix);
                    removeSessionFromState(sessionObject.sessionId);
                    window.console.log('Old session removed: ', sessionObject.sessionId);
                }
            });
        }
    } else {
        // Old fashion cookie removal method
        clearCookiesCC(domain);
    }
};

module.exports = {
    cleanResources,
    clearContactCenterSessions,
    removeLocalStorageByPrefix,
    storeCurrentSessionState,
};
