// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { SECOND_STEP } = require('constants/index');
// @ reducers
const cardPaymentPurchaseDeferralStep1 = require('./cardPaymentPurchaseDeferral/cardPaymentPurchaseDeferralStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            {valid: true},
            cardPaymentPurchaseDeferralStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
    });
}

function calculateDefaultQuotaQuantity(paramTotalAmount) {
    //These are bussines rules
    //These rules are specified on https://jira.corp.globant.com/browse/OPB000-19272

    /*
    Purchase total < 250 € ==> 2
    250 € <= Purchase total < 500 € ==> 3
    500 € <= Purchase total < 750 € ==> 4
    Purchase total > 750 € ==> 6
    */

    const totalAmount = Math.abs(paramTotalAmount);

    if (totalAmount < 250) {
        return 2;
    }

    if (totalAmount < 500) {
        return 3;
    }

    if (totalAmount < 750) {
        return 4;
    }

    return 6;
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case SECOND_STEP:
                return cardPaymentPurchaseDeferralStep1(step, action);
        }
    });
}

function cardPaymentPurchaseDeferralLavandaReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    return state.merge({
                        visibleStep: 2,
                    }) 
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 3 : 2;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SET_DEFAULT_INSTALLMENTS_QUANTITY:
            return state.setIn(['steps', SECOND_STEP, 'installmentQuantity'], calculateDefaultQuotaQuantity(action.payload.totalAmount))
        case actionTypes.CARD_REPAYMENT_REQUEST:
        case actionTypes.CARD_REPAYMENT_FAILURE:
        case actionTypes.CARD_REPAYMENT_SUCCESS:
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SET_OPTION:
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT:
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST:
        case actionTypes.CARD_PAYMENT_PURCHASE_SET_DEFERRAL_OPTION:
        case actionTypes.CARD_PAYMENT_PURCHASE_SET_QUOTA_QUANTITY:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
            });
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_CLEAR:
            return setInitialState();
        case actionTypes.FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            });
        case actionTypes.FETCH_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = cardPaymentPurchaseDeferralLavandaReducer;
