// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_NO_STOCKS_CONTRACT_SELECTED,
    BROKER_WARRANTS_BUY_BUYFOR_TITLES,
    BROKER_WARRANTS_BUY_BUYFOR_AMOUNT,
    BROKER_WARRANTS_BUY_BUYFOR_VALUE_DEFAULT,
    BROKER_ORDER_TYPE_LIMITED
} = require('constants/index');

const DateHelper = require('utilities/dateHelper');

function setInitialState() {
    return Immutable.fromJS({
        stocksContractSelectedValue: BROKER_NO_STOCKS_CONTRACT_SELECTED,
        buyForOption: BROKER_WARRANTS_BUY_BUYFOR_TITLES,
        buyForValue: BROKER_WARRANTS_BUY_BUYFOR_VALUE_DEFAULT,
        titleQuantity: BROKER_WARRANTS_BUY_BUYFOR_VALUE_DEFAULT,
        orderTypeOption: BROKER_ORDER_TYPE_LIMITED,
        orderTypeValue: '',
        errorFlags: {
            buyForValue: false,
            orderTypeValue: false,
            stocksContract: false
        },
        validityDate: DateHelper.getImmediateBussinessDay(), // @TODO recheck this at submitting for edge cases, ex, loading 17:29 submitting 17:35
        valid: false
    });
}

function validateStep(step) {
    const buyForOption = step.get('buyForOption');
    const buyForValue = parseFloat(step.get('buyForValue'));
    const orderTypeValue = parseFloat(step.get('orderTypeValue'));

    const isStocksContractEmpty =
        step.get('stocksContractSelectedValue') === BROKER_NO_STOCKS_CONTRACT_SELECTED;

    const isInvalidBuyForValue =
        !buyForValue ||
        (buyForOption === BROKER_WARRANTS_BUY_BUYFOR_AMOUNT &&
            buyForValue < parseFloat(step.get('productPrice')));

    const isInvalidLimitPrice = !orderTypeValue || orderTypeValue <= 0;

    const valid = !isStocksContractEmpty && !isInvalidBuyForValue && !isInvalidLimitPrice;

    return step.merge({
        errorFlags: {
            buyForValue: isInvalidBuyForValue,
            orderTypeValue: isInvalidLimitPrice,
            stocksContract: isStocksContractEmpty
        },
        valid
    });
}

function brokerWarrantsBuyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_BUY_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.BROKER_WARRANTS_BUY_STOCK_CONTRACT_CHANGED:
            return state.set('stocksContractSelectedValue', action.payload.value);

        case actionTypes.BROKER_WARRANTS_BUY_BUYFOR_OPTION_CHANGED:
            return state.merge({
                buyForOption: action.payload.value,
                buyForValue: ''
            });

        case actionTypes.BROKER_WARRANTS_BUY_BUYFOR_VALUE_CHANGED:
            return state.merge({
                productPrice: action.payload.productPrice,
                buyForValue: action.payload.value
            });

        case actionTypes.BROKER_WARRANTS_BUY_BUYFOR_VALUE_RESET:
            return state.merge({
                buyForValue: BROKER_WARRANTS_BUY_BUYFOR_VALUE_DEFAULT,
                titleQuantity: BROKER_WARRANTS_BUY_BUYFOR_VALUE_DEFAULT,
                productPrice: action.payload.productPrice,
                errorFlags: {
                    buyForValue: false
                }
            });

        case actionTypes.BROKER_WARRANTS_BUY_LIMIT_PRICE_CHANGED:
            return state.set('orderTypeValue', action.payload.value);

        default:
            return state;
    }
}

module.exports = brokerWarrantsBuyStep1;
