// this file does not require translation

export const getDepositProduct = (type, subtype, standard) => {
    return type + '.' + subtype + '.' + standard;
}

export const getMainDepositCategory = (type, subtype, standard, visibleStep = undefined, page = undefined) => {
    const currentStep = visibleStep ? ` 0${visibleStep}` : ''
    return `${page || 'contratar'} ` + type + '.' + subtype + '.' + standard + currentStep;
}

export const getRenewalDepositCategory = (type, subtype, standard, visibleStep = undefined) => {
    return `vencimiento/${type}.${subtype}.${standard}${visibleStep ? ' 0' + visibleStep : ''}`;
}