// @ vendor
const Immutable = require('immutable');
const moment = require('moment');
const padLeft = require('lodash/string/padLeft');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    DEFAULT_COUNTRY,
    DOCUMENT_NUMBER_NIE_REGEX,
    DOCUMENT_NUMBER_NIF_PASSPORT_REGEX,
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIF,
    DOCUMENT_TYPE_NIF_LENGTH,
} = require('constants/index');

const initialState = Immutable.fromJS({
    name: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    lastName: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    lastName2: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    documentType: {
        isDirty: false,
        isValid: false,
        value: DOCUMENT_TYPE_NIF,
    },
    documentNumber: {
        isOyAccountHired: false,
        isOyCardHired: false,
        isDirty: false,
        isFetching: false,
        isValid: false,
        value: '',
    },
    birthDate: {
        isDirty: false,
        isValid: false,
        value: moment().subtract(18, 'years').add(1, 'days'),
    },
    country: {
        isDirty: false,
        isValid: false,
        value: DEFAULT_COUNTRY,
    },
    isValid: false,
    population: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    province: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    gender: {
        isDirty: false,
        isValid: false,
        value: '1',
    },
    nationality: {
        isDirty: false,
        isValid: false,
        value: DEFAULT_COUNTRY,
    },
    isCountryOfBirthInvalid: true,
});

const parseDocumentNumber = (documentType, documentNumber) => {
    let parsedDocumentNumber = documentNumber;

    if (documentType === DOCUMENT_TYPE_NIF && parsedDocumentNumber !== '' &&
        parsedDocumentNumber.length < DOCUMENT_TYPE_NIF_LENGTH) {

        parsedDocumentNumber = padLeft(parsedDocumentNumber, DOCUMENT_TYPE_NIF_LENGTH, '0');
    }

    return parsedDocumentNumber.toUpperCase();
};

const validateRequiredField = (state, fieldName) => !!state.getIn([fieldName, 'value']);

const validateDocumentNumber = (documentType, documentNumber) => {
    const regexByType = {
        [DOCUMENT_TYPE_NIF]: DOCUMENT_NUMBER_NIF_PASSPORT_REGEX,
        [DOCUMENT_TYPE_NIE]: DOCUMENT_NUMBER_NIE_REGEX,
    };

    return regexByType[documentType].test(documentNumber);
};

const validateField = (state, fieldName, isOYPack) => {
    let isValid = false;
    let validatedField = {
        [fieldName]: {
            isDirty: true,
        }
    };

    switch (fieldName) {
        case 'documentNumber':
            const documentType = state.getIn(['documentType', 'value']);
            const documentNumber = state.getIn(['documentNumber', 'value']);
            const documentNumberValidating = state.getIn(['documentNumber', 'isFetching']);
            const documentNumberAccountDuplicated = state.getIn(['documentNumber', 'isOyAccountHired']);
            const documentNumberCardDuplicated = state.getIn(['documentNumber', 'isOyCardHired']);
            let documentNumberDuplicated = documentNumberCardDuplicated;
            validatedField[fieldName].value = parseDocumentNumber(documentType, documentNumber);
            if (isOYPack) {
                documentNumberDuplicated = documentNumberAccountDuplicated || documentNumberCardDuplicated;
            }
            isValid = validateDocumentNumber(documentType, validatedField[fieldName].value) && !documentNumberValidating && !documentNumberDuplicated;
            break;
        case 'province':
                if (state.getIn(['country', 'value']) !== DEFAULT_COUNTRY) {
                    isValid = true;
                } else {
                    isValid = validateRequiredField(state, fieldName);
                }
            break;
        case 'birthDate':
            if (!!state.getIn(['birthDate', 'value'])) {
                isValid = moment().subtract(18, 'years').isBefore(state.getIn(['birthDate', 'value']));
            }
            break;
        default:
            isValid = validateRequiredField(state, fieldName);
            break;
    }
    validatedField[fieldName].isValid = isValid;

    return validatedField;
};

const validateStep = (state) => {
    const validatedFields = Object.assign(
        validateField(state, 'name'),
        validateField(state, 'lastName'),
        validateField(state, 'lastName2'),
        validateField(state, 'documentNumber'),
        validateField(state, 'population'),
        validateField(state, 'province'),
        validateField(state, 'birthDate')
    );
    const fieldNames = Object.keys(validatedFields);
    let isValid = true;

    for (let i = 0; i < fieldNames.length; i++) {
        if (!validatedFields[fieldNames[i]].isValid) {
            isValid = false;
            break;
        }
    }

    return state.mergeDeep(
        {isValid},
        validatedFields
    );
};

const openYoungPersonalData = (state = initialState, action = {type: null}) => {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_RESET:
            return initialState;

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_VALIDATE_FIELD:
            return state.mergeDeep(
                validateField(state, action.payload.fieldName, action.payload.isOYPack)
            );

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_SET_FIELD:
            return state.merge({
                [action.payload.fieldName]: {
                    isDirty: true,
                    isValid: true,
                    value: action.payload.value,
                }
            });

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_FAILURE:
            return state.mergeDeep({
                documentNumber: {
                    isOyAccountHired: false,
                    isOyCardHired: false,
                    isFetching: false,
                    isValid: false,
                    isDirty: false
                }
            });

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_REQUEST:
            return state.mergeDeep({
                documentNumber: {
                    isOyAccountHired: false,
                    isOyCardHired: false,
                    isFetching: true,
                }
            });

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_VALIDATE_DOCUMENT_SUCCESS:
            return state.mergeDeep({
                documentNumber: {
                    isOyAccountHired: action.payload.data.isOyAccountHired,
                    isOyCardHired: action.payload.data.isOyCardHired,
                    isFetching: false,
                    isValid: action.payload.isOYPack ? !(action.payload.data.isOyCardHired || action.payload.data.isOyAccountHired) : !action.payload.data.isOyCardHired,
                }
            });

        case actionTypes.OPEN_YOUNG_PERSONAL_DATA_FILL:
            const immOpenYoungProfile = action.payload.immOpenYoungProfile;
            let newState = {
                birthDate: {
                    value: immOpenYoungProfile.get('birthDate'),
                },
                country: {
                    value: immOpenYoungProfile.get('country') || DEFAULT_COUNTRY,
                },
                documentNumber: {
                    value: immOpenYoungProfile.get('documentNumber'),
                },
                documentType: {
                    value: immOpenYoungProfile.get('documentType') || DOCUMENT_TYPE_NIF,
                },
                gender: {
                    value: immOpenYoungProfile.get('gender'),
                },
                name: {
                    value: immOpenYoungProfile.get('name'),
                },
                nationality: {
                    value: immOpenYoungProfile.get('nationality') || DEFAULT_COUNTRY,
                },
                lastName: {
                    value: immOpenYoungProfile.get('lastName'),
                },
                lastName2: {
                    value: immOpenYoungProfile.get('lastName2'),
                },
                population: {
                    value: immOpenYoungProfile.get('birthSquare'),
                },
                province: {
                    value: immOpenYoungProfile.get('provinceBirth'),
                },
                isCountryOfBirthInvalid: !!(immOpenYoungProfile.get('country'))
            };

            return state.merge(newState);

        default:
            return state;
    }
};

module.exports = openYoungPersonalData;
