/* TODO: We should update checkingPayrollAccountLib to use the fiocValidationHelper
and also move to said helper the third directive building function.
*/
//utilities
const { findElementbyId } = require('../utilities/contractStateHelper');
const formValidationHelper = require('../utilities/formValidationHelper');
const {
    buildTerceraDirectiva,
    getProfession,
    getActivity,
    getTrabajasPor,
    validateSelfEmployed,
    validateEmployed,
    validateRentier,
    validatePensioner,
    validateWithoutActivity,
    validateFields
} = require('../utilities/fiocValidationHelper');
const { getStore } = require('core/storeHelper');

let store = null;
const validStore = () => {
    if (!store) store = getStore();
    return !!(typeof store !== 'undefined' && store);
}

function fioc_HireParams(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return {
        companyName: findElementbyId(cardDetails, 'company_name') || '',
        fioc: buildTerceraDirectiva(cardDetails),
        profession: getProfession(cardDetails),
        activity: getActivity(cardDetails),
        workFor: getTrabajasPor(cardDetails)
    };
}

function validateStep1(cardDetails) {
    const isNeruda = validStore() && store.getState().profile.get('clientProfile') === 2;
    const fiocOcupationValue = findElementbyId(cardDetails, 'fioc_ocupation');
    // DO NOT ORDER ALPHABETICALLY
    const validationFunctions = [
        validateSelfEmployed,
        validateEmployed,
        validateRentier,
        validatePensioner,
        validateWithoutActivity
    ];
    let validFields = [];

    let result = validateFields(cardDetails, validFields, ['fioc_ocupation']);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (fiocOcupationValue || fiocOcupationValue === 0) {
        result = validationFunctions[fiocOcupationValue](cardDetails, validFields, '', isNeruda);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    const isValidForm = validFields.every(formValidationHelper.isValidElement);

    return {
        card_details: cardDetails,
        isValidStep: isValidForm
    };
}

function validateStep2(cardDetails) {//, confirmationCheckboxes) {//UNCOMMENT WHEN OTP NEEDED
    // We create a new checkboxList with the errorState values updated for each checkbox
    /*  const updatedCheckboxList = confirmationCheckboxes
          .get('list')
          .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));
  */
    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: true//confirmationCheckboxes.get('areValid'),
        //confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}
//If returns true, the step is valid and go to the next. If is false, stay in the same step.
function fioc_validateStep(step, state) {//, isValidStep
    const immCardDetails = state.get('card_details');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails);
        case 2:
            //const immConfirmationCheckboxes = state.get('confirmationCheckboxes');//UNCOMMENT WHEN OTP NEEDED
            return validateStep2(immCardDetails);//, immConfirmationCheckboxes);
    }
}

module.exports = {
    fioc_HireParams,
    fioc_validateStep
};