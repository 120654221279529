// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_REQUEST,
    MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_SUCCESS,
    MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_FAILURE,
    MORTGAGE_REQUEST_EXPENSES_PROVISION_SET_FIELD
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    importeCompraVenta: null,
    importeHipoteca: null,
    importeTotalCompraVenta: null,
    arras: false,
    valorArras: null,
    aportacionFondosPropios: null,
    totalGastos: null,
    totalGastosCompraVenta: null,
    totalGastosHipoteca: null,
    cuenta: '',
    importeVivienda: null,
    importeCompraVentaOtros: null,
    gastosCompraVenta: {
        itp: null,
        notaria: null,
        registro: null,
        gestoria: null,
        hacienda: null,
        ajd: null
    },
    gastosHipoteca: {
        comisionApertura: null,
        notaria: null,
        registro: null,
        gestoria: null,
        ajd: null,
        hacienda: null
    },
    pendingSale: null
});

function setField(state, action) {
    const { inputField, data } = action.payload;
    return state.merge({
        [inputField]: data
    });
}

const expensesProvision = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_SUCCESS:
            const expensesProvisionAction = action.data.data;

            return state.merge({
                importeCompraVenta: expensesProvisionAction.importeCompraVenta,
                importeHipoteca: expensesProvisionAction.importeHipoteca,
                importeTotalCompraVenta: expensesProvisionAction.importeTotalCompraVenta,
                importeVivienda: expensesProvisionAction.importeVivienda,
                importeCompraVentaOtros: expensesProvisionAction.importeCompraVentaOtros,
                arras: expensesProvisionAction.arras,
                valorArras: expensesProvisionAction.valorArras,
                aportacionFondosPropios: expensesProvisionAction.aportacionFondosPropios,
                totalGastos: expensesProvisionAction.totalGastos,
                totalGastosCompraVenta: expensesProvisionAction.totalGastosCompraVenta,
                totalGastosHipoteca: expensesProvisionAction.totalGastosHipoteca,
                cuenta: expensesProvisionAction.cuenta,
                gastosCompraVenta: expensesProvisionAction.gastosCompraVenta,
                gastosHipoteca: expensesProvisionAction.gastosHipoteca,
                iva: expensesProvisionAction.iva,
                pendingSale: expensesProvisionAction.pendienteCompraventa,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_EXPENSES_PROVISION_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case MORTGAGE_REQUEST_EXPENSES_PROVISION_SET_FIELD:
            return setField(state, action);
        default:
            return state
    }
};

module.exports = expensesProvision;
