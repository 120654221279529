// @ vendor
const Immutable = require('immutable');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/actionTypes');
const { CHORE_MAX_AMOUNT } = require('constants/index');

// @ commons
const { formatText } = require('core/i18n').i18n;

function setInitialState() {
    return Immutable.Map({
        valid: false,
        amount: 0,
        amountIsDirty: false,
        totalAvailableBalance: 0,
        choreType:[],
        choreTypeIsDirty:false,
        minDate: moment().startOf('day'), 
        selectedEndDate: moment().startOf('day'),
        concept:'',
        conceptIsDirty:false,
        selectedRepetitionType: Immutable.fromJS({value: formatText('createChoreStep-noRepetitions'), label: formatText('createChoreStep-noRepetitions')}),
        repetitionOptionList: [],
    });
}

function validateStep(step) {
    let validAmount = step.get('amount') <= CHORE_MAX_AMOUNT && step.get('amount') !== 0;
    const choreType = step.get('choreType').length !== 0;
    const valid = validAmount && choreType;
   
    return step.merge({
        valid: valid,
        amountIsDirty: true,
        choreTypeIsDirty:true
    });
}

function openYoungCardCreateChoreStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CREATE_CHORE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CREATE_CHORE_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountIsDirty: true
            })
        case actionTypes.CREATE_CHORE_AVAILABLE_BALANCE:
            return state.merge({
                totalAvailableBalance: action.payload.totalAvailableBalance
            });
        case actionTypes.CREATE_CHORE_SET_CHORE_TYPE:
            return state.mergeDeep({
                choreType: action.payload.choreType,
                choreTypeIsDirty:true
            });
        case actionTypes.CREATE_CHORE_SET_REPETITION_TYPE:
            return state.mergeDeep({
                selectedRepetitionType: action.payload.selectedRepetitionType
            });
        case actionTypes.CREATE_CHORE_SET_REPETITION_OPTION_LIST:
            return state.mergeDeep({
                repetitionOptionList: action.payload.repetitionOptionList
            });
        case actionTypes.CREATE_CHORE_VALIDITY_DATE_CHANGED:
            return state.merge({
                selectedEndDate: action.payload.selectedEndDate
            });
        case actionTypes.CREATE_CHORE_CONCEPT_CHANGED:
            return state.merge({
                concept: action.payload.concept,
                conceptIsDirty: true
            });
        default:
            return state;
    }
}

module.exports = openYoungCardCreateChoreStep1;
