
// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
//const { FIRST_STEP } = require('constants/index');
// @ reducers
const STEP_1 = Immutable.fromJS({
    valid: true,
    // cards status can be null= loading or an array: requested
    cards: null,
    selectedCard: null,
    selectedCardError: false
})
const STEP_2 = Immutable.fromJS({
    valid: true,
    financingData: null,
    isFetching: false,
    specificQuota: {
        amount: null,
        isFetching: false,
    },
    chosenDeferral: '',
})
const STEP_3 = Immutable.fromJS({
    hasSignaturePattern: true
})

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            STEP_1,
            STEP_2,
            STEP_3
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
    });
}

function accountPaymentPurchaseDeferralReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP:
            const goToNextStep = () => state.merge({visibleStep: action.payload.step + 1})
            switch (action.payload.step) {
                case 1:
                    if (state.getIn(['steps', 0, 'selectedCard']) === null){
                        const values = state.getIn(['steps', 0]).merge({selectedCardError: true})
                        return state.setIn(['steps', 0], values)
                    }
                    return goToNextStep()
                case 2: {
                    const step = state.getIn(['steps', 1, 'financingData'])
                    const maxQuotas = step.get('maxQuotas')
                    const currentQuota = step.get('currentQuota')
                    const interests = step.get('interests')
                    const quotaMonthly = step.get('quotaMonthly')
                    const installmentList  = step.get('installmentList')
                    if (!maxQuotas && !currentQuota && !interests && !quotaMonthly && !installmentList) {
                        const values = state.getIn(['steps', 1]).merge({error: true})
                        return state.setIn(['steps', 1], values)
                    }
                    return goToNextStep()
                }
                case 3:
                    return goToNextStep()
                default:       
                    return state;
            }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SELECT_CARD: {
            const { cardId } = action.payload
            const values = state.getIn(['steps', 0]).merge({selectedCard: cardId, selectedCardError: false})
            return state.setIn(['steps', 0], values)
        }
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_REQUEST: {
            const values = state.getIn(['steps', 0]).merge({cards: null, selectedCard: null})
            return state.setIn(['steps', 0], values)
        }
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_FAILURE: {
            const { error } = action.payload
            const values = state.getIn(['steps', 0]).merge({error})
            return state.setIn(['steps', 0], values)
        }
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_GET_CARDS_SUCCESS: {
            const { cards } = action.payload
            const values = state.getIn(['steps', 0]).merge({cards})
            return state.setIn(['steps', 0], values)
        }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_OPTION: {
            const { option } = action.payload
            return state.setIn(['steps', 1, 'chosenDeferral'], option)
        }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_REQUEST: {
            const values = state.getIn(['steps', 1]).merge({valid: true, financingData: null, isFetching: true})
            return state.setIn(['steps', 1], values)
        }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_SUCCESS: {
            const { data } = action.payload
            const values = state.getIn(['steps', 1]).merge({financingData: data, isFetching: false})
            return state.setIn(['steps', 1], values)
        }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_QUOTA_FAILURE: {
            const { error } = action.payload
            const values = state.getIn(['steps', 1]).merge({error})
            return state.setIn(['steps', 1], values)
        }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT: {
            const { amount } = action.payload
            const values = state.getIn(['steps', 1, 'specificQuota']).merge({amount: amount, isFetching: false})
            return state.setIn(['steps', 1, 'specificQuota'], values)
        }
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST: {
            return state.setIn(['steps', 1, 'specificQuota', 'isFetching'], true)
        }
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_WILL_CANCEL:
            return state;
        case actionTypes.ACCOUNT_PAYMENT_PURCHASE_DEFERRAL_CLEAR:
            return setInitialState();
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            });
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CONFIRMATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.ACCOUNT_CARD_PAYMENT_PURCHASE_DEFERRAL_CLEAR:
            return setInitialState()
        default:
            return state;
    }
}

module.exports = accountPaymentPurchaseDeferralReducer;
