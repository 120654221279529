// @vendors
import { fromJS } from 'immutable';
import actionTypes from 'constants/actionTypes';

// @constants
import {
    CARD_ECARD,
    CARD_CREDIT,
    CARD_DEBIT,
    MY_PROFILE_ACCOUNT,
    WALLETS,
    TYPE_FUTURES,
    FUNDS,
    PLANS,
    MORTGAGES,
    LOANS,
} from 'constants/index';

const setInitialState = () => fromJS({
    isFetching: false,
    canBeRequestedAccessReport: false,
    canBeRequestedPortabilityReport: false,
    error: '',
    errorGDPR: false,
    sendEmail: false,
    thirdSendEmail:'',
    productsUser: {},
    rights: [],
    success: false,
    showMovements: true,
    profileList: [],
    cardEcardList: [],
    cardDebitList: [],
    cardCreditList: [],
    accountList:[],
    walletsList:[],
    typeFuturesList: [],
    fundsList:[],
    plansList:[],
    loansList:[],
    savingsList:[],
    mortgagesList:[],
    visibleStepAccess: 1,
    successAccess: false,
    errorAccess: false,
    nextFreeAccess: true,
    stepsAccess:[
        {
            title: '',
            order: 1,
            footer_text: '',
            position: 'first',
            valid: 'false',
            components: []
        },
        {
            title: '',
            order: 2,
            footer_text: '',
            position: 'last',
            valid: 'false',
            components: []
        }
    ],
    stepsAccessPay: [
        {
            title: '',
            order: 1,
            footer_text: '',
            position: 'first',
            valid: 'false',
            components: []
        },
        {
            title: '',
            order: 2,
            footer_text: '',
            position: 'middle',
            valid: 'false',
            components: []
        },
        {
            title: '',
            order: 3,
            footer_text: '',
            position: 'last',
            valid: 'false',
            components: []
        }
    ],
    successPortable: false,
    errorPortable: false,
    visibleStepPortable: 1,
    stepsPortable:[
        {
            title: '',
            order: 1,
            footer_text: '',
            position: 'first',
            valid: 'false',
            components: []
        },
        {
            title: '',
            order: 2,
            footer_text: '',
            position: 'middle',
            valid: 'false',
            components: []
        },
        {
            title: '',
            order: 3,
            footer_text: '',
            position: 'last',
            valid: 'false',
            components: []
        }
    ]
});

function updateTypeRightsList(state, list, type) {
    switch (type) {
        case TYPE_FUTURES:
            return state.set('typeFuturesList', list);
        case WALLETS:
            return state.set('walletsList', list);
        case FUNDS:
            return state.set('fundsList', list);
        case CARD_ECARD:
            return state.set('cardEcardList', list);
        case PLANS:
            return state.set('plansList', list);
        case MY_PROFILE_ACCOUNT:
            return state.set('accountList', list);
        case CARD_DEBIT:
            return state.set('cardDebitList', list);
        case CARD_CREDIT:
            return state.set('cardCreditList', list);
        case LOANS:
            return state.set('loansList', list);
        case MORTGAGES:
            return state.set('mortgagesList', list);
        default:
            return state;
    }
}

function updateReports(state, response) {
    return state
        .set('rights', response.reports)
        .set('nextFreeAccess', response.freeReport.ACCESS)
        .set('canBeRequestedAccessReport', response.canBeRequestedReport
            ?
            response.canBeRequestedReport.ACCESS
            :
            false)
        .set('canBeRequestedPortabilityReport', response.canBeRequestedReport
            ?
            response.canBeRequestedReport.PORTABILITY
            :
            false);
}

function secondAccessStep(state) {
    return state
        .set('visibleStepAccess', 2);
}

function firstAccessStep (state) {
    return state
        .set('visibleStepAccess', 1);
}

function nextPortableStep(state) {
    return state.get('visibleStepPortable') < 3
        ?
        state.set('visibleStepPortable', state.get('visibleStepPortable') + 1)
        :
        state;
}

function lastPortableStep(state) {
    return state.get('visibleStepPortable') > 0
        ?
        state.set('visibleStepPortable', state.get('visibleStepPortable') - 1)
        :
        state;
}

function rightsProfile(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.RESET_RIGHTS_PROFILE:
            return state
                .set('visibleStepAccess', 1)
                .set('successAccess', false)
                .set('errorAccess', false)
                .set('visibleStepPortable', 1)
                .set('successPortable', false)
                .set('errorPortable', false)
                .set('successPortable', false)
                .set('cardEcardList', [])
                .set('cardDebitList', [])
                .set('cardCreditList', [])
                .set('accountList', [])
                .set('typeFuturesList', [])
                .set('fundsList', [])
                .set('plansList', [])
                .set('loansList', [])
                .set('savingsList', [])
                .set('mortgagesList', [])
                .set('walletsList', [])
                .set('profileList', []);
        case actionTypes.UPDATE_TYPE_RIGHTS_LIST:
            return updateTypeRightsList(state, action.payload.list, action.payload.type);
        case actionTypes.CHANGE_GET_MOVEMENTS_PRODUCTS:
            return state.set('showMovements', action.payload.response)
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            return state.set('productsUser', action.payload)
        case actionTypes.SHOW_NOTIFICATIONS_EMAIL_IN_PROGRESS:
            return state.set('sendEmail', false);
        case actionTypes.SHOW_NOTIFICATIONS_EMAIL_SUCCESS:
            return state.set('sendEmail', true);
        case actionTypes.SHOW_NEXT_STEP_RIGHTS_ACCESS:
            return secondAccessStep(state);
        case actionTypes.SHOW_LAST_STEP_RIGHTS_ACCESS:
            return firstAccessStep(state);
        case actionTypes.SHOW_NEXT_STEP_RIGHTS_PORTABLE:
            return nextPortableStep(state);
        case actionTypes.SHOW_LAST_STEP_RIGHTS_PORTABLE:
            return lastPortableStep(state);
        case actionTypes.GET_REPORTS_IN_PROGRESS:
            return state.set('isFetching', true)
        case actionTypes.GET_REPORTS_SUCCESS:
            return updateReports(state.set('isFetching', false), action.payload.response);
        case actionTypes.UPDATE_RIGHTS_PROFILE:
            return state.set('profileList', action.payload.response);
        case actionTypes.POST_REPORTS_SUCCESS:
            return state
                .set('successPortable', true)
                .set('errorPortable', false);
        case actionTypes.POST_REPORTS_FAILURE:
            return state
                .set('successPortable', false)
                .set('errorPortable', action.payload.error);
        case actionTypes.POST_ACCESS_REPORTS_SUCCESS:
            return state
                .set('successAccess', true)
                .set('errorAccess', false);
        case actionTypes.POST_ACCESS_REPORTS_FAILURE:
            return state
                .set('successAccess', false)
                .set('errorAccess', action.payload.error);
        default:
            return state;
    }
}

module.exports = rightsProfile;
