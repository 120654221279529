// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        challenge: '',
        error: '',
        isFetching: false,
        success: false
    });
}

function ecommerceKeyRequestReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ECOMMERCE_KEY_RESET:
            return state.mergeDeep({
                challenge: '',
                error: '',
                isFetching: false,
                success: false
            });
        case actionTypes.SET_ECOMMERCE_KEY_EXPIRED:
            return state.mergeDeep({
                challenge: ''
            });
        case actionTypes.FETCH_ECOMMERCE_KEY_REQUEST:
            return state.mergeDeep({
                isFetching: true
            });
        case actionTypes.FETCH_ECOMMERCE_KEY_SUCCESS:
            return state.mergeDeep({
                challenge: action.payload.challenge || '',
                isFetching: false,
                success: !!action.payload.challenge
            });
        case actionTypes.FETCH_ECOMMERCE_KEY_FAILURE:
            return state.mergeDeep({
                error: action.payload.error,
                isFetching: false
            });
        default:
            return state;
    }
}

module.exports = ecommerceKeyRequestReducer;
