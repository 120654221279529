const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.Map().merge({
       isn: '',
       iso: '',
       valueName: '',
       titleAmount: 0,
       titleAmountInputError: false,
       valid: false,
       emitionCode: '',
       valueCode: '',
       currencyCode: '',
    });
}


function validateStep(step) {
    const isInputError = step.get('titleAmount') < 1;
    const isEmitionCodeError = step.get('emitionCode') === '';
    const isValueCodeError = step.get('valueCode') === '';

    const isStepValid = (!isInputError &&
                         !isEmitionCodeError &&
                         !isValueCodeError);
    return step.merge({
        valid: isStepValid,
        titleAmountInputError: isInputError
    });
}

function brokerTransferExternalStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_ISN_ISO:
            return state.merge({
                isn: action.payload.isn,
                iso: action.payload.iso,
                currencyCode: action.payload.currencyCode,
                valueName: action.payload.value
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_TITLE_AMOUNT:
            return state.merge({
                titleAmount: action.payload.titleAmount
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALIDATE_STEP:
            return validateStep(state);
         case actionTypes.BROKER_TRANSFER_EXTERNAL_GET_EMITION_VALUE_CODE_SUCCESS:
            return state.merge({
                emitionCode: action.payload.response.codigoEmision,
                valueCode: action.payload.response.codigoValor
            });
        default:
            return state;
    }
}

module.exports = brokerTransferExternalStep2;
