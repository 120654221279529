const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.Map({
    voiceReader: '',
    id: -1
});

function voiceReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.VOICE_PERFORM_READ:
            return state.merge({
                voiceReader: action.voiceReader,
                id: action.id
            });
        case actionTypes.VOICE_PERFORM_CLEAN:
            return state.merge({
                voiceReader: '',
                id: -1
            });
        default:
            return state
    }
}

module.exports = voiceReducer;
