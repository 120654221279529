// @ vendors
const Immutable = require('immutable');
const moment = require('moment');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @ constants
const {
    MORTGAGE_REQUEST_HOLDER_RESET,
    MORTGAGE_REQUEST_HOLDER_REQUEST,
    MORTGAGE_REQUEST_HOLDER_SUCCESS,
    MORTGAGE_REQUEST_HOLDER_FAILURE,
    MORTGAGE_REQUEST_HOLDER_EDIT_SET_FIELD,
    MORTGAGE_REQUEST_HOLDER_ID_SET,
    MORTGAGE_REQUEST_HOLDER_FLAG
} = require('constants/actionTypes');

const { DEFAULT_COUNTRY } = require('constants/index');

const initialState = Immutable.fromJS({
    ordenNm: 0,
    name: '',
    lastname1: '',
    lastname2: '',
    nationality: '',
    mailAdicional: '',
    day: '',
    month: '',
    year: '',
    civilStatus: 'NO_CONSTA',
    personsNumber: 0,
    idDocument: 'N',
    idDocumentText: '',
    openbankFl: 0,
    expirationDay: '',
    expirationMonth: '',
    expirationYear: '',
    placeResidence: '',
    location: '',
    provinceCode: '',
    province: '',
    postalCode: '',
    typeOfRoad: '',
    nameOfRoad: '',
    numberOfRoad: '',
    confirm: false,
    requestedId: 0,
    dataRequested: false,
    isFetching: false,
    renderData: false
});

function setField(state, action) {
    const { inputField, data } = action.payload;
    return state.merge({
        [inputField]: data
    });
}

const holder = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_HOLDER_RESET:
            return initialState.merge();

        case MORTGAGE_REQUEST_HOLDER_REQUEST:
            return state.merge({
                isFetching: true
            });

        case MORTGAGE_REQUEST_HOLDER_SUCCESS:
            const data = action.data.data;
            const identificador = action.identificador;
            const tipoDocumentoIdentidad = action.tipoDocumentoIdentidad;
            const fechaCaducidad = get(data, 'documentoDeIdentidad.fechaCaducidad', '');
            const fechaNacimiento = get(data, 'fechaNacimiento', '');
            const mortgageOperationProcessId = get(data, 'operationProcessId', '');
            let newState = state;

            if (fechaCaducidad) {
                const expirationDate = moment(fechaCaducidad);
                newState = newState.merge({
                    expirationDay: expirationDate.format('DD'),
                    expirationMonth: expirationDate.format('MM'),
                    expirationYear: expirationDate.format('YYYY')
                });
            } else {
                newState = newState.merge({
                    expirationDay: '',
                    expirationMonth: '',
                    expirationYear: ''
                });
            }

            if (fechaNacimiento) {
                const birthDay = moment(fechaNacimiento);
                newState = newState.merge({
                    day: birthDay.format('DD'),
                    month: birthDay.format('MM'),
                    year: birthDay.format('YYYY')
                });
            } else {
                newState = newState.merge({
                    day: '',
                    month: '',
                    year: ''
                });
            }

            return newState.merge({
                ordenNm: get(data, 'ordenNm', 0) || 0,
                name: trim(get(data, 'nombreCompleto', '')) || '',
                lastname1: trim(get(data, 'primerApellido', '')) || '',
                lastname2: trim(get(data, 'segundoApellido', '')) || '',
                nationality: trim(get(data, 'nacionalidad', DEFAULT_COUNTRY)) || DEFAULT_COUNTRY,
                mailAdicional: trim(get(data, 'mailAdicional', '')) || '',
                mailContacto: trim(get(data, 'mailContacto', '')) || '',
                telefonoMovil: trim(get(data, 'telefonoMovil', '')) || '',
                civilStatus: trim(get(data, 'estadoCivil', 'NO_CONSTA')) || 'NO_CONSTA',
                personsNumber: get(data, 'numeroPersonasACargo', 0) || 0,
                idDocument:
                    trim(
                        get(
                            data,
                            'documentoDeIdentidad.tipoDocumentoIdentidad',
                            tipoDocumentoIdentidad
                        )
                    ) || tipoDocumentoIdentidad,
                idDocumentText:
                    trim(get(data, 'documentoDeIdentidad.identificador', identificador)) ||
                    identificador,
                openbankFl: get(data, 'openbankFl', 0) || 0,
                placeResidence: trim(get(data, 'lugarResidencia', '')) || '',
                location: trim(get(data, 'localidad', '')) || '',
                provinceCode: trim(get(data, 'codigoProvincia', '')) || '',
                province: trim(get(data, 'provincia', '')) || '',
                postalCode: trim(get(data, 'codigoPostal', '')) || '',
                typeOfRoad: trim(get(data, 'tipoVia', '')) || '',
                nameOfRoad: trim(get(data, 'nombreVia', '')) || '',
                numberOfRoad: trim(get(data, 'numeroVia', '')) || '',
                error: '',
                isFetching: false,
                dataRequested: true,
                renderData: true,
                isOpenbankClient: get(data, 'clienteOpenBank', true),
                mortgageOperationProcessId,
            });

        case MORTGAGE_REQUEST_HOLDER_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case MORTGAGE_REQUEST_HOLDER_EDIT_SET_FIELD:
            return setField(state, action);

        case MORTGAGE_REQUEST_HOLDER_ID_SET:
            return state.merge({
                requestedId: action.requestedId
            });

        case MORTGAGE_REQUEST_HOLDER_FLAG:
            return state.merge({
                flag: true
            });

        default:
            return state;
    }
};

module.exports = holder;
