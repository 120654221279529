// @ constants
const { MINIMUM_TO_TRANSFER, MAXIMUM_TO_TRANSFER, AMOUNT_MULTIPLE_OF_NUMBER } = require('constants/contactCenter/cardTransferMoneyToCheckingAccount');
// @ utilities
const { formatText } = require('core/i18n').i18n;
const currencyHelper = require('utilities/currencyHelper');

const validateAmount = (amount, currency, availableBalance) => {

    if (amount < MINIMUM_TO_TRANSFER) {
        return {
            valid: false,
            validationMessage: formatText('cardTransferMoneyToCheckingAccount-lesserThanMinimumError', [ MINIMUM_TO_TRANSFER, currencyHelper.getCurrencySymbol({ currency }) ]),
        };
    }

    if (amount % AMOUNT_MULTIPLE_OF_NUMBER !== 0) {
        return {
            valid: false,
            validationMessage: formatText('cardTransferMoneyToCheckingAccount-notMultipleOfNumberError', [ AMOUNT_MULTIPLE_OF_NUMBER ]),
        };
    }

    if (amount > availableBalance) {
        return {
            valid: false,
            validationMessage: formatText('cardTransferMoneyToCheckingAccount-greaterThanAvailableBalanceError'),
        };
    }

    if (amount > MAXIMUM_TO_TRANSFER) {
        return {
            valid: false,
            validationMessage: formatText('cardTransferMoneyToCheckingAccount-greaterThanMaximumError', [ MAXIMUM_TO_TRANSFER, currencyHelper.getCurrencySymbol({ currency }) ]),
        };
    }

    return {
        valid: true,
        validationMessage: ''
    };
};

module.exports = {
    validateAmount,
};
