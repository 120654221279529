const sustainabilityQuestion400Tags = () => ({
    answer11_1: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "seleccion inversion sostenible",
        etiquetaEvento: "neutral",
    },
    answer11_2: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "seleccion inversion sostenible",
        etiquetaEvento: "intermedio",
    },
    answer11_3: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "seleccion inversion sostenible",
        etiquetaEvento: "avanzado",
    },
    answer11_tooltip: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "tooltip inversion sostenible"
    },
    answer11_modal_open: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "modal inversion sostenible",
        etiquetaEvento: "informacion adicional sobre esta pregunta",
    },
    answer11_modal_close: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "modal inversion sostenible",
        etiquetaEvento: "cerrar",   
    },
    answer11_modal_button: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "modal inversion sostenible",
        etiquetaEvento: "entendido",
    },
});

module.exports = { sustainabilityQuestion400Tags };
