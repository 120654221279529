const get = require('lodash/object/get');

function brokerGeneralAdapter(productData) {
   const currency =
       get(productData, 'currencyCode', '').trim() ||
       get(productData, 'subyacente.currencyCode', '').trim();

   return {
       companyName: productData.instrument.trim(),
       marketCode: productData.localCode.trim(),
       isinCode: productData.instrumentId.trim(),
       isComplex: productData.isComplex,
       micCode: '',
       spcSymbol: productData.spcSymbol,
       marketName: productData.marketName,
       productValue: {
           amount: productData.last,
           currency: productData.currencyCode
       },
       indexValue: {
           variation: productData.netChangePrice,
           percentage: productData.netChangePercentage
       },
       updatePriceDate: productData.updateDate,
       updatePriceTime: productData.updateTime,
       lastPriceDate: productData.tradeDate,
       lastPriceTime: productData.tradeTime,
       buyAmount: productData.bidSize1,
       buyPrice: productData.bid1,
       currency: currency,
       sellAmount: productData.askSize1,
       sellPrice: productData.ask1,
       minimum: productData.low,
       maximum: productData.high,
       opening: productData.open,
       lastClose: productData.yclose,
       volume: productData.volume,
       yearHigh: productData.yearHigh,
       yearLow: productData.yearLow,
       crfRequired: productData.crfRequired,
       w8Required: productData.w8Required,
       yearChangePercentage: productData.yearChangePercentage,
       yearChangePrice: productData.yearChangePrice,
       country: {
           id: get(productData, 'country.id', ''),
           name: get(productData, 'country.name', '')
       },
       catalogData: {
           operatedByLot: get(productData, 'catalogData.operatedByLot', false),
           lot: get(productData, 'catalogData.lot', 0),
           lotValue: get(productData, 'catalogData.lotValue', 0),
       },
       warrant: {
           underlying: {}
       }
   };
}

module.exports = brokerGeneralAdapter;
