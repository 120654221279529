const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState(){
    return Immutable.Map({
        isOpen: false
   });
}

function toggle(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.TOGGLE_IBAN_MODAL:
            return state.merge({
                isOpen: !state.get('isOpen')
            });
        default:
            return state
    }
}

module.exports = toggle;
