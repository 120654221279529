const middlewaresErrorsHandler = (error, getState, action) => {
    if (typeof (action) === 'object' && !!action.type) {
        if (!window.ADRUM) {
            const state = getState();
            window.console.error(error);
            window.console.error('prev state --> ', state);
            window.console.error('action --> ', action);
        } else {
            const errorT = new window.ADRUM.events.Error()
            errorT.msg(`${error.message}\n--> action: ${action.type}`)
            window.ADRUM.report(errorT)
        }
    }
    throw error
}

module.exports = {
    middlewaresErrorsHandler
}
