// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes =  require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        hasBeenSent: false,
        error: '',
        isFetching: false
    });
}

function validateStep(step) {
    const hasBeenSent = step.get('hasBeenSent');
    const valid = hasBeenSent;
    return step.merge({
        valid
    });
}

function authorizeBizumStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BIZUM_REGISTER_TERMS_CONDITIONS_SUCCESS:
            return state.merge({
                 hasBeenSent: true,
                 error: '',
                 isFetching: false
            });
        case actionTypes.BIZUM_REGISTER_TERMS_CONDITIONS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.BIZUM_REGISTER_TERMS_CONDITIONS_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
         });
         case actionTypes.BIZUM_REGISTER__VALIDATE_UI_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = authorizeBizumStep2;
