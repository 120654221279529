const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = () => Immutable.fromJS({
    success: false,
    inProgress: false,
    error: ''
});

const BrokerFundOrderCancelReducer = (state = initialState(), action) => {
    switch (action.type) {
        case actionTypes.BROKER_FUND_ORDER_CANCEL_REQUEST:
            return state.merge({
                inProgress: true
            });
        case actionTypes.BROKER_FUND_ORDER_CANCEL_SIGNATURE_FAILURE:
            return state.merge({
                inProgress: false
            });
        case actionTypes.BROKER_FUND_ORDER_CANCEL_SUCCESS:
            return state.merge({
                inProgress: false,
                success: true
            });
        case actionTypes.BROKER_FUND_ORDER_CANCEL_FAILURE:
            return state.merge({
                inProgress: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_FUND_ORDER_CANCEL_CLEAR:
            return initialState();
        default:
            return state;
    }
}

module.exports = BrokerFundOrderCancelReducer;