// @vendors
const Immutable = require('immutable');
const { formatText, hasTranslation } = require('core/i18n').i18n;

// @helpers
const { decodeHTMLEntities } = require('utilities/stringHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const { ACCOUNT_TYPE_E_LOAN } = require('constants/index');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    requestSuccess: false,
    text: '',
});

const processText = text => {
    let processedText = text;

    if (hasTranslation(text)) {
        processedText = formatText(text);
    }
    //Replace is used to remove instances of " ," and replace them for "," and multiple consecutive spaces for " "
    processedText = decodeHTMLEntities(processedText).replace(/ ,/g, ',').replace(/\ {2,10}/g, ' ');

    return processedText;
};

function termsAndCondsReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.TERMS_AND_CONDS_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
                requestSuccess: false,
            });
        case actionTypes.TERMS_AND_CONDS_SET_TEXT:
            return state.merge({
                text: processText(action.payload.text),
            });
        case actionTypes.TERMS_AND_CONDS_SUCCESS:
            return state.merge({
                isFetching: false,
                requestSuccess: true,
            });
        case actionTypes.TERMS_AND_CONDS_FAILURE: {
            let error = action.payload.error
            if (action.payload.contractType === ACCOUNT_TYPE_E_LOAN) {
                error = {
                    error: {
                        type: 'custom',
                        errorObject: 'serverError'
                    }
                }
            }
            return state.merge({
                error,
                isFetching: false,
            });
        }
        case actionTypes.TERMS_AND_CONDS_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = termsAndCondsReducer;
