// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_REQUEST,
    MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_SUCCESS,
    MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: ''
});

const mortgageDenial = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_DELETE_MORTGAGEDENIAL_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = mortgageDenial;
