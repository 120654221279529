require('../payrollAccount.scss');

//utilities
const formHelper = require('../utilities/formHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
const { PROC_UPGRADE_TO_PAYROLL_ACCOUNT, PROC_PAYROLL_ACCOUNT, recordLink } = require('utilities/tagging');
// @vendors
const React = require('react');
const { FormattedText } = require('core/i18n').i18n;
// @ commons
const { OKBoxTitle } = require('commonsComponents/OKBoxTitle');
const { OKButton } = require('components/commons/OKButton/OKButton');

function payrollAccount_setSuccessStepPrefix(contractWizard, immProfileFields, seed) {
    let prefix = {};
    if (formHelper.haveEmail(immProfileFields)) {
        prefix.email = 'successWithEmail_';
        const hasOneHolder = !!contractWizard && !findElementbyId(contractWizard.get('card_details'), 'holders').get(0);

        if (hasOneHolder) {
            prefix.email = 'successWithEmailWithOneHolder_';
        }
    }
    if (contractWizard.get(seed)) {
        prefix.seed = 'seed_';
    }
    return prefix;
}

function handleUpgradeToPayrollAccountButton(history) {
    const url = '/myprofile/purchase/upgrade-to-payroll-account';
    recordLink(PROC_UPGRADE_TO_PAYROLL_ACCOUNT,'pre-wizard');

    return history.push(url);
}

function handlePayrollAccountButton(updateShouldShowUpgradeToPayrollPreStep) {
    recordLink(PROC_PAYROLL_ACCOUNT,'pre-wizard');
    updateShouldShowUpgradeToPayrollPreStep();
}

function payrollAccount_getTranslations(shouldShowUpgradeToPayrollPreStep, contract) {
    if (shouldShowUpgradeToPayrollPreStep) {
        contract.title = 'contractsView-upgradeToPayrollAccountAccountPreselected';
        contract.breadcrumb[1].text = 'contractsView-upgradeToPayrollAccountAccountPreselected';
    } else {
        contract.title = 'contractsView-payrollAccountContractTitle';
        contract.breadcrumb[1].text = 'contractsView-payrollAccountContractTitle';

    }
}

function payrollAccount_buildUpgradeToPayrollAccountStep(updateShouldShowUpgradeToPayrollPreStep, history) {
    const upgradeMessage = <FormattedText value="contractsView-hasPayrollAccountTitle" />;
    const upgradeSubMessage = (
        <div>
            <FormattedText value="contractsView-hasPayrollAccountMessage" />
        </div>
    );
    const upgradeToPayrollAccountButton = (
        <div className="payroll-account-upgrade-to-payroll-account__upgrade-button">
            <OKButton
                modifier="secondary"
                size="regular"
                onClick={() => handleUpgradeToPayrollAccountButton(history)}
            >
                <FormattedText value="contractsView-upgradeToPayrollAccount" />
            </OKButton>
        </div>
    );
    const continueHiringButton = (
        <div className="payroll-account-upgrade-to-payroll-account__continue-button">
            <OKButton
                id="lnk_wizardErrorStep_mainButton"
                modifier="link-complementary"
                onClick={() => handlePayrollAccountButton(updateShouldShowUpgradeToPayrollPreStep)}
            >
                <FormattedText value="contractsView-continueHiring" format="uppercase" />
            </OKButton>
        </div>
    );

    return (
        <div className="payroll-account-upgrade-to-payroll-account">
            <div className="payroll-account-upgrade-to-payroll-account__highlight">
                <i className="payroll-account-upgrade-to-payroll-account__info-icon" />
                <div className="payroll-account-upgrade-to-payroll-account__messages">
                    <div className="payroll-account-upgrade-to-payroll-account__title">
                        <OKBoxTitle value={upgradeMessage}/>
                    </div>
                    <div className="payroll-account-upgrade-to-payroll-account__message">
                        {upgradeSubMessage}
                    </div>
                </div>
            </div>
            {upgradeToPayrollAccountButton}
            <div className="payroll-account-upgrade-to-payroll-account__footer">
                <div className="payroll-account-upgrade-to-payroll-account__separator" />
                {continueHiringButton}
            </div>
        </div>
    );
}

module.exports = {
    payrollAccount_buildUpgradeToPayrollAccountStep,
    payrollAccount_getTranslations,
    payrollAccount_setSuccessStepPrefix
};
