//utilities
const formHelper = require('../utilities/formHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
const { recordLinkV2 } = require('utilities/tagging');
// @ constants
const { DEPOSIT_VIEW, CONTRACT_BUTTON, CC_DEPOSIT_EXCLUSIVE_OFFER } = require('constants/tagging');
import { RETENTION_CATEGORY } from '../components/ContractDropdownDeposit/constants/constants';

function checkingAccount_setSuccessStepPrefix(immContract, immProfileFields) {
    let successStepPrefix = 'successWithEmail_';
    const hasOneHolder = !!immContract && !findElementbyId(immContract.get('card_details'), 'holders').get(0);

    if (hasOneHolder) {
        successStepPrefix = 'successWithEmailWithOneHolder_';
    }

    return formHelper.haveEmail(immProfileFields) ? {email: successStepPrefix} : {};
}

function checkingAccount_handleStep2LoadTagging(immContract) {
    const mainDeposits = immContract.get('mainDeposits');
    const depositsAllowed = mainDeposits.get('data');
            
    const atLeastOneExclusiveCC = depositsAllowed.some((deposit) => deposit.get('labelCategoryCc') === RETENTION_CATEGORY);

    if (atLeastOneExclusiveCC) {
        const customData = ({
            categoriaEvento: `${CONTRACT_BUTTON} 02`,
            accionEvento: CC_DEPOSIT_EXCLUSIVE_OFFER,
            etiquetaEvento: DEPOSIT_VIEW,
        });
        recordLinkV2(customData);
    }
}

module.exports = {
    checkingAccount_setSuccessStepPrefix: checkingAccount_setSuccessStepPrefix,
    checkingAccount_handleStep2LoadTagging,
};
