// @vendors
const Immutable = require('immutable');
const { hash } = require('utilities/hash');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    API_ENTERPRISE_NUMBER,
    API_CENTER
} = require('constants/index');

const initialState = Immutable.fromJS({
    byId: {},
    byOrder: [],
    isFetching: false,
    error: '',
    fetchSuccess: false,
});

function buildFullContractNumber(card) {
    const contractNumber = get(card, 'contratoTarjeta.numerodecontrato', '');
    const product = get(card, 'contratoTarjeta.producto', '');
    if (!!contractNumber && !!product) {
        return `${API_ENTERPRISE_NUMBER} ${API_CENTER} ${product} ${contractNumber}`;
    }
    return '';
}

function updateCardsList(state, cards) {
    const byId = {};
    const byOrder = [];
    let canceledCard;
    cards.forEach(card => {
        const cardId = hash(card.panTarjeta);
        canceledCard = {
            cardId,
            number: card.panTarjeta || '',
            fullContractNumber: buildFullContractNumber(card),
            contract: {
                product: get(card, 'contratoTarjeta.producto', ''),
                number: get(card, 'contratoTarjeta.numerodecontrato', '')
            },
            interventionType: card.tipoIntervencionDesc || '',
            fechaAlta: card.fechaAlta || '',
            fechaBaja: card.fechaBajaC || '',
            panTarjeta: card.panTarjeta || '',
            product: {
                type: get(card, 'contratoTarjeta.producto', ''),
                subtype: get(card, 'contratoTarjeta.subtipodeproducto', ''),
                standard: get(card, 'contratoTarjeta.estandarDeReferencia', '')
            },
            productSubType: get(card, 'contratoTarjeta.subtipodeproducto', ''),
            newProduct: get(card, 'contratoTarjeta.producto', ''),
            tipoIntervencion: card.tipoIntervencion || '',
            holder: ''
        };
        byId[cardId] = canceledCard;
        byOrder.push(cardId);
    });

    return state
        .mergeDeep({     
            isFetching: false,
            fetchSuccess: true,
        })
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder));
}

function cardsCanceledReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.CARDS_CANCELED_REQUEST:
            return state.merge({
                error: '',
                fetchSuccess: false,
                isFetching: true,
            });

        case actionTypes.CARDS_CANCELED_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });

        case actionTypes.CARDS_CANCELED_SUCCESS:
            return updateCardsList(state, action.payload.cardsCanceled);

        case actionTypes.CARDS_CANCELED_SET_HOLDER_SUCCESS:
            const { cardId, holder } = action.payload;
            return state.mergeDeep({
                byId: {
                    [cardId]: {
                        holder
                    }
                }
            });
        default:
            return state;
    }
}

module.exports = cardsCanceledReducer;
