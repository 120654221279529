const cloneDeep = require('lodash/lang/cloneDeep');
const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../inputs');

function buildGeneralData(prefix) {
    const generalData = cloneDeep(wizardComponentsHelper.replacePrefix(inputs.generalData, prefix));
    const visibleTarget = generalData.nationality.visible.target.replace('###', prefix);
    generalData.nationality.visible.target = visibleTarget;
    generalData.nationality_other.visible.target = visibleTarget;

    return {
        id_component: 'fioc',
        id: prefix + 'contact_data',
        exclude: [],
        grid: [
            [
                generalData.gender,
                generalData.nationality,
                generalData.nationality_other
            ]
        ]
    }
}

module.exports = buildGeneralData;
