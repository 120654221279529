const terms = [
    { name: 'Abgezinste Anleihe',
  definition: 'Diese werden auch Nullkupon-Anleihen oder Zero Bonds genannt. Als Anleihen ohne laufende Zinszahlung resultiert der Ertrag aus der Differenz zwischen einem niedrigen Ausgabepreis, der unter dem Nennwert liegt, und dem höheren Einlösungspreis. Hierbei sind die vom Ausgabezeitpunkt bis zur Fälligkeit anfallenden Zinserträge im Rahmen einer Zinseszinsrechnung vorab ermittelt und im Ausgabepreis berücksichtigt worden.' }
,
{ name: 'Abzinsfaktor',
  definition: 'Der Abzinsfaktor verwandelt den zukünftigen Wert einer Anlage in einen aktuellen Wert. Sie hilft dem Anleger z.B. bei der Suche nach einem fairen Preis für eine Anleihe, die jedes Jahr 50 € Zinsen auszahlt und 1000 € bei Fälligkeit, indem sie den zukünftigen Geldflüssen einen heutigen Wert zuweist.' }
,
{ name: 'Administrator',
  definition: 'Der Administrator überwacht die Geschäftsabläufe in einer Kapitalanlagegesellschaft.' }
,
{ name: 'ADRs',
  definition: 'ADRs (American Depository Receipt) sind von US-amerikanischen Banken ausgegebene handelbare Zertifikate nichtamerikanischer Aktien und werden an einer US-Börse stellvertretend für eine Aktie gehandelt.' }
,
{ name: 'AGM',
  definition: 'AGM (Annual General Meeting): Jährliche Hauptversammlung der Aktionäre eines Unternehmens.' }
,
{ name: 'AIM',
  definition: 'Der AIM (Alternative Investment Market) wurd 1995 in London für kleine Unternehmen gegründet, die sich noch nicht für eine Notierung an einem der großen Märkte wie der London Stock Exchange qualifiziert haben.' }
,
{ name: 'Aktie',
  definition: 'Eine Aktie ist ein Eigentumsanteil an einem börsennotierten Unternehmen.' }
,
{ name: 'Aktien',
  definition: 'Beteiligung an der Aktiengesellschaft.' }
,
{ name: 'Aktienfonds',
  definition: 'Ein Aktienfonds invesiert vorwiegend in Aktien. Er kann dabei eine Anzahl verschiedener Anlagestrategien verfolgen.' }
,
{ name: 'Aktiensektoren',
  definition: 'Durch die Auswahl dieses Kästchens kann der Nutzer die Standardeinstellungen der Sektorengewichtung außer Kraft setzen. Die standardmäßigen Gewichtungen zielen darauf ab, ein hohes Maß an Diversifikation zu erreichen. Sie wurden unter Bezug auf die US- und europäischen Aktienmärkte festgelegt, wobei die hohe Gewichtung der Finanztitel reduziert wurde. Der Nutzer kann je nach Auswahl des entsprechenden Buttons entweder die Gewichtung der Sektoren oder der Supersektoren variieren. Wird nur die Gewichtung der Supersektoren verändert, so behalten die darunter gefassten Sektoren ihre ursprüngliche relative Gewichtung, um eine maximale Diversifikation zu gewährleisten.' }
,
{ name: 'Aktienstil',
  definition: 'Durch die Auswähl dieses Kästchens kann der Nutzer eine gewünschte Aufteilung im Hinblick auf den Stil festgelegen. Dies beinhaltet zum einen die Entscheidung zwischen den Anlagestilen Substanz (Value) und Wachstum (Growth) und zum anderen die Entscheidung für Standard- gegenüber Nebenwerten. Standardmäßig sind ein neutraler Anlagestil und eine durchschnittliche Marktkapitalisierung eingestellt (70% große Werte, 20% mittelgroße Werte, 10% kleine Werte). Bei einer Growth-Orientierung beträgt die Gewichtung für Wachstumsaktien 60%, für Substanzwerte 40% (und umgekehrt). Will der Nutzer verstärkt in Standardwerte investieren, so steigt deren Gewichtung zu Lasten der mittelgroßen und kleinen Werte von 70 auf 85%. Umgekehrt sinkt bei einer Präferenz für Nebenwerte das Gewicht von Standardwerten auf 55%, während sich der Anteil der kleinen und mittelgroßen Werte entsprechend erhöht.' }
,
{ name: 'aktives Management',
  definition: 'Eine aktive Fondsverwaltung weicht in der Titelauswahl von der jeweiligen Benchmarkt/Index ab, im Gegensatz zu passivem Management, dass die Benchmarkt lediglich widerspiegelt. Ziel des aktiven Managements ist die Erwirtschaftung einer Überschussrendite im Vergleich zur Benchmark.' }
,
{ name: 'Alpha',
  definition: 'Alpha ist die Menge, um die ein Fonds die Wertentwicklung des Index übertroffen hat, unter Einbeziehung des Marktrisikos, welches der Fonds eingegangen ist (wird durch <a href="Default.aspx?lang=de&amp;keyword=Beta&amp;HelpType=S">Beta</a> gemessen).' }
,
{ name: 'American Stock Exchange (AMEX)',
  definition: 'Die AMEX ist die zweitgrößte Präsenzbörse in den USA. Sie konzentriert sich auf die Bereiche kleine und mittelgroße Unternehmen, Indexzertifikate und Derivative.' }
,
{ name: 'Amsterdam Exchange (AEX)',
  definition: 'Die Amsterdamer Börse vereint unter ihrem Dach die AEX-Effectenbeurs, die AEX-Optiebeurs und den AEX-Agrarische Termijnmarkt. AEX ist Teil von Euronext, die Vereinigung der Börsen von Amsterdam, Brüssel und Paris.' }
,
{ name: 'Anlageberater',
  definition: 'Ein Anlageberater bietet Beratung zu Investitionen und anderen Finanzprodukten, entweder auf Provisionsbasis oder auf Pauschalgebührbasis (letzteres System steckt in Deutschland noch in den Kinderschuhen). Man unterscheidet dabei zwischen zwei Grundarten: Unabhängigen Anlageberatern, die Produkte mehrerer Anbieter vertreiben und Generalagenten oder Bankberatern, die meist jeweils nur die hauseigenen Produkte anbieten. In diesem Bereich finden jedoch zurzeit zahlreiche Veränderungen statt.' }
,
{ name: 'Anlagekategorien',
  definition: 'Zu den breiten Anlagekategorien gehören z.B. Aktien, Anleihen, Immobilien und Bargeld/Festgeld. Aktien bieten das beste Wachstumspotenzial, jedoch mit einem höheren Risiko. Anleihen bieten laufendes Einkommen mit moderatem Risiko. Immobilien bieten Wertseigerung, sind jedoch unter Umständen illiquide.' }
,
{ name: 'Anlageziel',
  definition: 'Das vom Fondsmanager im Verkaufsprospekt festgelegte Anlageziel (etwa Kapitalwachstum, Kapitalerhalt, Einkommen).' }
,
{ name: 'Anleihe',
  definition: 'Eine Anleihe ist ein Schuldpapier eines Unternehmens oder einer öffentlichen Körperschaft. Während der Laufzeit der Anleihe erhält der Anleger regelmäßige Zinszahlungen, basierend auf dem Zinssatz des Kupons. Mit der Fälligkeit wird die Anleihe zurückgezahlt. Es gibt auch Ausnahmen, wie z.B. Nullkuponanleihen, die keine Zinsen zahlen, aber zu einem Diskont ausgegeben werden.' }
,
{ name: 'Anleihenfonds',
  definition: 'Ein Anleihenfonds legt vorwiegend in fetsverzinslichen Wertpapieren an. Die Wertentwicklung von Anleihenfonds ist umgekehrt proportional zu Veränderungen des Zinsniveaus.' }
,
{ name: 'Art der Optimierung',
  definition: 'Der Optimierer kann ein bestehendes Portfolio rebalancieren oder ein neues zusammenstellen. Dies kann gleichzeitig separat oder gemeinsam durchgeführt werden. Durch das Rebalancieren wird nur ein dann Ergebnis angezeigt, wenn ein bestehendes Portfolio zur Verfügung steht.' }
,
{ name: 'Asset Allocation',
  definition: 'Aufteilung des verfügbaren Kapitals auf verschiedene Anlageformen (Aktien, Anleihen, Geldmarkt, Immobilien, Rohstoffe usw.) oder auf verschiedene Branchen, Länder, Währungen.' }
,
{ name: 'Asset Backed Securities (ABS)',
  definition: 'Asset Backed Securities fassen Zahlungsansprüche aus Hypothekendarlehen, Konsumentenkrediten oder Kreditkartenforderungen zusammen und verbriefen diese in Form von handelbaren, verzinslichen Wertpapieren.' }
,
{ name: 'Auflagedatum',
  definition: 'Datum, zu welchem der Fonds lanciert wurde.' }
,
{ name: 'Ausgabeaufschlag',
  definition: 'Ein Ausgabeaufschlag wird von der  Fondsgesellschaft beim Kauf von Fondsanteilen erhoben.' }
,
{ name: 'Ausgabepreis',
  definition: 'Der Preis zu dem Anleger Anteile des Fonds erwerben können.' }
,
{ name: 'Authorisiert',
  definition: 'Großbritannien: Eine authorisierte Investmentgesellschaft richtet sich nach dem britischen "Financial Services Act". Danach müssen die in Großbritannien tätigen Gesellschaften Mitglied eines Aufsichtsverbandes sein, der gemäß Gesetz gebildet wurde.' }
,
{ name: 'AUTIF',
  definition: 'Alter Name für den britischen Verband der Investmentgesellschaften. Heute: Investment Management Association (IMA)' }
,
{ name: 'AUTIF Classification',
  definition: 'AUTIF classification is <a href="Default.aspx?lang=en-GB&amp;keyword=AUTIF&amp;HelpType=S">AUTIF\'s</a> (the Association of Unit Trusts and Investment Funds) system of grouping funds by their investment strategy. This aims to help investors decide what funds will meet their needs.' }
,
{ name: 'Bank of England',
  definition: 'Die britische Zentral- und Notenbank.' }
,
{ name: 'Basispunkt',
  definition: 'Ein Basispunkt entspricht 0,01%. Veränderungen im Zinsniveau werden oft in Basispunkten angegeben. Ein Anheben der Zinsen von 4,00% auf 4,50% entspräche z.B. einer Steigerung um 50 Basispunkte.' }
,
{ name: 'Benchmark',
  definition: 'Eine Benchmark ist eine vorbestimmte Messlatte für einen Investmentfonds, meist ein Index.' }
,
{ name: 'Beta',
  definition: 'Beta misst die Sensibilität des Fonds im Bezug auf Marktbewegungen. Das Beta des Marktes ist per Definition 1,00. Ein Beta von 1,10 besagt, dass der Fonds in Haussemärkten 10% besser als der Gesamtmarkt abgeschnitten hat, und 10% schlechter in Baissemärkten (angenommen alle anderen Faktoren bleiben konstant). Umgekehrt heißt ein Beta von 0,85, dass sich der Fonds in Haussephasen 15% schlechter als der Markt entwickelt und 15% besser während Baissephasen.' }
,
{ name: 'Bid-Offer Spread (Fund)',
  definition: 'Differenz zwischen Rücknahmepreis (in der Regel Nettoinventarwert) und Ausgabepreis (ggfs. inklusive Ausgabeaufschlag). Die Differenz wird zur Finanzierung von Vertriebsaufwendungen etc. verwendet.' }
,
{ name: 'Big Bang',
  definition: 'Deregulierung der City of London 1986.' }
,
{ name: 'Blend',
  definition: 'Blend bezeichnet einen neutralen Anlagestil, d.h. weder Value- noch Growth-Eigenschaften dominieren. (s. Value, Growth)' }
,
{ name: 'Blue Chips',
  definition: 'Blue Chips sind Aktien eines großen und etablierten Unternehmens mit gutem Kreditrating. In Deutschland finden sich Blue Chips meist im DAX.' }
,
{ name: 'Bond', definition: 'engl. für Anleihe/Rentenpapier' }
,
{ name: 'Bonität',
  definition: 'Bonität ist die Qualität einer Anleihe im Bezug auf ihre Rückzahlungswahrscheinlichkeit.' }
,
{ name: 'Borsa Italiana',
  definition: 'Vereinigung der italienischen Börsen mit Sitz in Mailand.' }
,
{ name: 'Börsendurchschnitt',
  definition: 'Der Börsendurchschnitt (Stock Market Average) ist ein arithmetisches Mittel einer Gruppe von Aktien, die ausgewählt wurden, den Gesamtmarkt zu repräsentieren. Im Gegensatz zu einem Index ist der Durchschnitt nicht gewichtet. Der bekannteste Durchschnitt ist der Dow Jones Industrial Average.' }
,
{ name: 'Bottom-Up-Ansatz',
  definition: 'Beim Bottom-Up-Ansatz liegt der Schwerpunkt auf der Auswahl der Einzeltitel. Die Struktur des Portfolios auf Branchen- oder Regionenebene ist dann größtenteils das Ergebnis der Einzeltitelwahl.' }
,
{ name: 'Bourse de Paris',
  definition: 'Die Pariser Börse ist die französische Standardbörse und Teil von Euronext.' }
,
{ name: 'Buy and Hold-Strategie',
  definition: 'Kaufen und Halten. Durch die lange Haltedauer der Wertpapiere verringert sich die Umschlagshäufigkeit und damit die Transaktionskosten.' }
,
{ name: 'CAC 40 Index',
  definition: 'Der CAC 40 ist der wichtigste französische Aktienindex. Er enthält die 40 größten börsennotierten Unternehmen.' }
,
{ name: 'Capital Gain',
  definition: 'Kapitalgewinn: Gewinn, der bei Verkauf eines Investments anfällt. Kapitalverlust: Verlust bei Verkauf eines Investments' }
,
{ name: 'Capital Gains Tax (CGT)',
  definition: 'Englische Kapitalgewinnsteuer.' }
,
{ name: 'CAPM',
  definition: 'CAPM  (Capital Asset Pricing Model): Mathematisches Modell, mit dem der Preis eines Wertpapiers ermittelt wird, indem das Verhältnis zwischen Risiko und erwarteter Rendite bestimmt wird. CAPM ist ein zentraler Bestandteil der Portfoliotheorie, in dem die erwartete Rendite (E) eines Investments als Funktion der erwarteten Rendite des Marktportfolios (rm) dargestellt wird und der <a href="Default.aspx?lang=en-GB&amp;keyword=Beta&amp;HelpType=S">Beta</a> coefficient ((beta)), E = R + (beta)(rm - R), wobei R die risikofreie Rendite ist.' }
,
{ name: 'Carry Trade',
  definition: 'Bei einem Carry-Trade werden Kredite zu sehr niedrigen kurzfristigen Zinsen aufgenommen und wieder in längerfristige Finanzinstrumente (ausländische Staatsanleihen und Währungen, Rohstoffe, Kreditderivate, usw.) mit höheren Zinsen angelegt. Der Gewinn liegt in der Zinsdifferenz, kann aber durch ungünstige Währungsbewegungen (ausländische Währung verliert an Wert) geschmälert werden. Wertet die ausländische Währung dagegen auf, kommt zum Gewinn aus der Zinsdifferenz ein Währungsgewinn hinzu.' }
,
{ name: 'CAT Standard',
  definition: 'Trifft für Deutschland nicht zu.' }
,
{ name: 'CAT Standards/CAT-Marking',
  definition: 'Trifft für Deutschland nicht zu.' }
,
{ name: 'CDO',
  definition: 'CDOs (Collateralised Debt Obligations) sind Wertpapiere, die durch einen Pool von Forderungen aus Darlehen oder Unternehmensanleihen besichert sind. Dabei unterscheidet man zwischen Collateralized Bond Obligations (CBO) und Collateralized Loan Obligations (CLO). Erstere sind durch Forderungen aus Anleihen besichert, letztere durch Forderungen aus Darlehen (z.B. Firmenkrediten).' }
,
{ name: 'Closed-End Funds',
  definition: 'Börsennotierte geschlossene Fonds.' }
,
{ name: 'Courtage',
  definition: 'Die Courtage ist die Gebühr, die ein Makler für den Kauf oder Verkauf von Wertpapieren berechnet. Sie variiert je nach Umfang der Transaktion.' }
,
{ name: 'Credit Default Swap (CDS)',
  definition: 'Credit Default Swaps sind das meistgenutzte Kreditderivat und bieten eine Versicherung gegen Ausfallrisiken, indem sie die Übertragung von Kreditrisiken ermöglichen. So kann sich der Sicherungsnehmer/käufer durch Bezahlung einer Prämie an den Sicherungsgeber/verkäufer für einen festgesetzten Zeitraum gegen bestimmte Kreditrisiken absichern. Diese werden im Voraus definiert (sog. credit events: z.B. Zahlungsverzug, -ausfall, Insolvenz, Umstrukturierung zum Nachteil des Gläubigers). Die Beziehung zwischen Gläubiger und Schuldner bleibt davon unberührt, d.h. das zugrundeliegende Kreditinstrumen wird nicht übertragen.' }
,
{ name: 'Dachfonds',
  definition: 'Ein Dachfonds ist ein Fonds, der in andere Investmentonds (Zielfonds) investiert, anstatt in Einzelaktien oder Anleihen. Die Grundidee dabei ist es, eine noch breitere Streuung zu erreichen. Allerdings ist die Kostenbelastung bei Dachfonds meist wesentlich höher als bei Einzelfonds.' }
,
{ name: 'Daten per',
  definition: 'Der Stichtag, zu dem die angegebenen Daten aktuell sind.' }
,
{ name: 'Datum',
  definition: 'Datum bezieht sich auf den zuletzt verfügbaren   <a href="Default.aspx?lang=de&amp;keyword=NAV&amp;HelpType=S">NAV</a>' }
,
{ name: 'DAX',
  definition: 'Der DAX 30-Index bildet die Wertentwicklung der 30 wichtigsten deutschen Aktiengesellschaften ab.' }
,
{ name: 'Depotbank',
  definition: 'Die Depotbank verwahrt im Auftrag der Kapitalanlagegesellschaft das Fondsvermögen und übernimmt die Ausgabe und Rücknahme der Fondsanteile. Da es sich beim Fondsvermögen um ein Sondervermögen handelt, muss sie rechtlich und personell von der KAG getrennt sein. Die Tätigkeit einer Depotbank umfasst neben der Verwahrung auch die Verwaltung des Fondsvermögens, die tägliche Fondspreisberechnung, die Abwicklung von Transaktionen und die Überwachung von Investmentrichtlinien. Für diese Tätigkeiten erhält die Depotbank eine Depotbankgebühr, die aus dem Fondsvermögen gezahlt wird.' }
,
{ name: 'Depotstelle',
  definition: 'Die Institution, meist eine Bank, die mit der Verwahrung und manchmal auch Abwicklung der Wertpapiere eines Fonds betraut ist.' }
,
{ name: 'Derivate',
  definition: 'Derivate ist ein Sammelbegriff für Wertpapiere, deren Kurs auf den Kursen anderer, ihnen zugrundeliegender Wertpapiere (Basiswerte) beruht. Beispiele sind Futures, Optionen, Swaps und Warrants. Derivate dienen zur Verlagerung von Marktrisiken. Sie können zur Absicherung eingesetzt werden, um z.B. das Risiko möglicher Verluste eines Aktienportfolios zu verringern. Sie bieten aber auch die Chance zur Renditeerzielung: Oft kann mit dem Einsatz von nur wenig Kapital ein großer Betrag kontrolliert werden. Deshalb wirken sich Preisschwankungen im Basiswert überproportional auf das Derivat aus. Dieser Hebeleffekt, genannt Leverage, bringt allerdings nicht nur hohe Gewinnchancen, sondern auch entsprechende Risiken mit sich.' }
,
{ name: 'Devisentermingeschäft',
  definition: 'Erwerb oder Verkauf von Devisen auf Termin. In diesem Fall erfolgt der Währungstausch zum vereinbarten Terminkurs an einem festgelegten Zeitpunkt in der Zukunft. Der Terminkurs hängt vom Kassakurs (aktueller Kurs) und der Zinsdifferenz zwischen den beiden Währungen ab. Devisentermingeschäfte dienen zur Währungsabsicherung bei Waren- oder Finanzierungsgeschäften, die in ausländischen Währungen zu einem späteren Zeitpunkt fällig werden. Das Fremdwährungsrisiko wird damit eliminiert, man erhält eine sichere Kalkulationsbasis. Der Preis dafür ist, dass man auch auf das Potential für Währungsgewinne verzichtet.' }
,
{ name: 'Die City',
  definition: 'Die City ist der Londoner Finanzbezirk, mit Börse und Bank von England.' }
,
{ name: 'Discount to NAV',
  definition: 'Bei börsennotierten geschlossenen Fonds: Wenn der Wert der Anlagen im Fonds über dem Anteilspreis liegt, zu dem der Fonds notiert' }
,
{ name: 'Diversifikation',
  definition: 'Diversifikation ist eine Strategie zur Risikominderung. Ein diversifiziertes Portfolio investiert in eine Reihe verschiedener Wertpapiere und Investmentfonds. Es sollte Aktien, Anleihen und Immobilienwerte kombinieren. Die Fonds oder Aktien sollten eine Reihe von Sektoren (z.B. Technologie, Finanzen, Gesundheitswesen) und Regionen (z.B. Europa, Nordamerika, Asien) umfassen. Das Ziel ist sicherzustellen, dass die potenziellen Verluste eines Teilbereichs durch die Gewinne eines anderen aufgefangen werden.' }
,
{ name: 'Dividende',
  definition: 'Eine Dividende ist eine Ausschüttung von Gewinnen einer Aktiengesellschaft oder eines Fonds an die Anteilseigner.' }
,
{ name: 'Dividendenrendite',
  definition: 'Die Dividendenrendite ist der Prozentsatz der Dividende im Vergleich zum Aktienkurs. Ist z.B. die Dividende eines Fonds 10 Cent und der Anteilspreis liegt bei 2,10 Euro, so beträgt die Dividendenrendite 4,76%.' }
,
{ name: 'Dividendenrendite (Fonds)',
  definition: 'Der Anteil einer Fondsrendite, der auf Dividendenausschüttungen im Portfolio zurückgeht.' }
,
{ name: 'Domizil',
  definition: 'Ein Domizil ist das Niederlassungsland einer Person, einer Firma oder eines Fonds.' }
,
{ name: 'Dow Jones Industrial Average',
  definition: 'Der Dow Jones Indutrial Average ist einer der bekanntesten US-Börsenindizes. Er umfasst 30 der größten, liquidesten Aktien, die an der Wall Street gehandelt werden (New York Stock Exchange).' }
,
{ name: 'Duration',
  definition: 'Duration ist der durchschnittliche Zeitraum bis zur Auszahlung. Man nutzt diese Kennzahl zur Messung des Einflusses von Zinsbewegungen auf den Kurs einer Anleihe oder eines Anleihefonds. Duration wird in Jahren definiert (z.B. bedeutet eine 3jährige Duration, dass der Wert einer Anleihe um 3% steigen würde, falls die Zinsen um 1% fallen, und umgekehrt).' }
,
{ name: 'Durchschnitt',
  definition: 'Diese Zeile zeigt den Durchschnittswert der verschiedenen Datenpunkte jener Fonds an, die Ihre Suchkriterien erfüllt haben. Dazu gehören Datenpunkte wie Verwaltungsgebühren, alle Wertentwicklungszahlen, Morningstar Ratings, StyleBox-Details und die Standardabweichung. Sie können nach Fondsgesellschaft oder Sektor individuell suchen, oder eine Kombination von beiden verwenden. Bitte beachten Sie, dass zwar die Ergebnisanzeige auf 300 Fonds beschränkt ist, die Durchschnittswerte sich aber auf alle Ihre Kriterien erfüllenden Fonds beziehen.' }
,
{ name: 'Durchschnittliche Marktkapitalisierung',
  definition: 'Die durchschnittliche Marktkapitalisierung errechnet man, indem die Marktkapitalisierung der einzelnen Aktien (Aktienkurs x ausgegebene Anteile) entsprechend ihrem Portfolioanteil berücksichtigt.' }
,
{ name: 'Durchschnittliche Rendite',
  definition: 'Die annualisierte Rendite eines Fonds über 3 Jahre.' }
,
{ name: 'Effektivverzinsung (Rendite)',
  definition: 'Effektivverzinsung ist die Jahresverzinsung in Prozent des eingesetzten Kapitals unter Berücksichtigung des Nominalzinssatzes, des Anschaffungskurses und des Tilgungskurses. Die Effektivverzinsung entspricht der Rendite, wenn die Wiederveranlagung der Ausschüttung zur Ausgangsrendite möglich ist.' }
,
{ name: 'Energie',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Öl, Erdgas, Ölausstatter, Pipelinebetreiber investiert. Beispiele: OMV, Schlumberger und Surgutneftegaz.' }
,
{ name: 'ETF (Exchange-Traded Fund)',
  definition: 'Börsengehandelte Investmentfonds. Im Gegensatz zu traditionellen Fonds gibt es keine tägliche, sondern eine kontinuierliche Preisfeststellung und keine Ausgabeaufschläge.' }
,
{ name: 'Ethische Anlagen',
  definition: 'Ethische Anlagen vermeiden bestimmte Sektoren bzw. bevorzugen solche, die als moralisch hochwertig angesehen werden. Obwohl es keine einheitliche Definition gibt, gehören zu den vermiedenen Sektoren oft Waffen,Tierversuchslabore, Alkohol, Tabak und Glücksspiel.' }
,
{ name: 'Eurobond',
  definition: 'Ein Eurobond ist eine Kapitalmarkttransaktion, die außerhalb des jeweiligen Währungsraumes getätigt wird (z.B. wird ein in Yen denominierter Eurobond außerhalb Japans emittiert).' }
,
{ name: 'Euronext',
  definition: 'Euronext ist der Verbund der Börsen von Amsterdam, Brüssel und Paris.' }
,
{ name: 'European Union (EU)',
  definition: 'Ursprünglich als gemeinsamer Markt von 6 Ländern im Jahr 1957 durch die Römischen Verträge gegründet. Früher als EWG, dann als EG bekannt. Die EU ist mittllerweile auch eine politische Union und umfasst 15 Mitgliedsstaaten, von denen sich 12 der Gemeinschaftwährung Euro angeschlossen haben.' }
,
{ name: 'Eurozone',
  definition: 'Eurozone bezeichnet die Länder, die den Euro als ihre Gemeinschaftswährung eingeführt haben. Zurzeit sind dies Belgien, Finnland, Frankreich, Deutschland, Griechenland, Irland, Italien, Luxemburg, Niederlande, Portugal und Spanien.' }
,
{ name: 'Fälligkeit',
  definition: 'Fälligkeit ist das Datum, an dem eine Schuldverschreibung wie z.B. eine Anleihe zur Rückzahlung fällig wird. So wäre z.B. eine 5-jährige Bundesanleihe, die am 1.1.2000 ausgegeben wurde, am 1.1.2005 fällig.' }
,
{ name: 'Federal Funds Rate',
  definition: 'Die Federal Funds Rate ist der bekannteste US-Zinssatz. Sie wurde durch den Offenmarktausschuss eingeführt. Sie gibt an, welche Zinsen Banken bei der Verleihung von Federal Funds (Bundes-Bankreserven) voneinander erheben.' }
,
{ name: 'Federal Open Market Committee (FOMC)',
  definition: 'Das Federal Open Market Committee (Abk.: FOMC, dt.: Offenmarktausschuss der Federal Reserve) ist der Rat der US-Notenbank bestehend aus 12 Mitgliedern.' }
,
{ name: 'Financial Conduct Authority (FCA)',
  definition: 'Die britische Finanzaufsichtsbehörde.' }
,
{ name: 'Finanzen',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Banken, Versicherungen, Vermögensverwaltung und Wertpapierhandel angelegt ist. Beispiele: Allianz, Deutsche Bank und Amvescap.' }
,
{ name: 'Flaggschifffonds',
  definition: 'Das Paradeprodukt einer Fondsgesellschaft, oft der älteste oder größte Fonds der Palette.' }
,
{ name: 'Fonds',
  definition: 'Ein Fonds investiert in verschiedene Anlagen, gemäß den im Verkaufsprospekt festgelegten Anlagegrundsätzen. Dazu können u.a. Aktien, Anleihen oder Immobilien gehören.' }
,
{ name: 'Fondsgesellschaft',
  definition: 'Eine Fondsgesellschaft verwaltet Investmentfonds.' }
,
{ name: 'Fondsmanager',
  definition: 'Der Fondsmanager ist ein Angestellter der Fondsgesellschaft, der direkt für die Verwaltung des Fondsportfolios verantwortlich ist.' }
,
{ name: 'Fondsplattform',
  definition: 'Über eine Fondsplattform können Fonds verschiedener Gesellschaften erworben werden, oft über das Internet.' }
,
{ name: 'Fondssuche',
  definition: 'Tragen Sie den Fondsnamen ganz oder teilweise ein, um ihn in der Morningstar-Datenbank zu suchen.' }
,
{ name: 'Fondsvolumen',
  definition: 'Gibt das Gesamtnettovermögen eines Fonds zum Monatsende an. Es wird in Millionen Einheiten der Fondswährung dargestellt. Das Fondsvolumen ist ein Anhaltspunkt für Größe, Beweglichkeit und Beliebtheit eines Fonds.' }
,
{ name: 'Fondswährung',
  definition: 'Die Währung, in welcher das Portfolio gehandelt wird.' }
,
{ name: 'FT 30',
  definition: 'Der FT 30 ist ein Aktienindex, der 30 der größten britischen Unternehmen beinhaltet.' }
,
{ name: 'FTSE',
  definition: 'Der FTSE100-Index, oft Footise genannt, ist der wichtigste britische Börsenindex. Er wird gemeinsam von der Financial Times und dem London Stock Exchange aufgelegt.' }
,
{ name: 'FTSE 100',
  definition: 'Der gebräuchlichste britische Aktienindex. Er umfasst die nach Marktkapitalisierung 100 größten Unternehmen der Londoner Börse.' }
,
{ name: 'FTSE 250',
  definition: 'Ein Aktienindex, der die 250 Unternehmen umfasst, die nach Marktkapitalisierung unmittelbar auf den FTSE 100 folgen. Es handelt sich damit um mittelgroße Unternehmen.' }
,
{ name: 'FTSE 350',
  definition: 'Ein Aktienindex, der die Unternehmen des FTSE 100 und des FTSE 250 umfasst.' }
,
{ name: 'FTSE All Share Index',
  definition: 'Der Index für die 900 größten Unternehmen an der Londoner Börse.' }
,
{ name: 'Fund Family',
  definition: 'Fondspalette einer Investmentgesellschaft. Innerhalb der Fondspalette ist ein Wechsel zwischen verschiedenen Fonds häufig mit reduziertem Ausgabeaufschlag möglich.' }
,
{ name: 'Future',
  definition: 'Futures sind standardisierte Terminkontrakte: Sie verpflichten den Käufer zu einem vorbestimmten Termin in der Zukunft, Güter oder Finanzprodukte zu einem bestimmten Preis zu erwerben. Der Verkäufer eines Future-Kontrakts ist zur Lieferung des Basiswerts zu den festgelegten Bedingungen verpflichtet. Es gibt standardisierte Futures: z.B. DAX Future, Bund Future. Sie werden an der Börse gehandelt.' }
,
{ name: 'G7',
  definition: 'G7 steht für die sieben führenden Industrienationen, deren Finanzminister sich regelmäßig zur Diskussion wirtschaftlicher und politischer Themen treffen. Die G7 besteht aus: USA, Kanada, Japan, Frankreich, Italien, Großbritannien und Deutschland. Die G8 bezieht zusätzlich Russland mit ein.' }
,
{ name: 'GDR (Global Depositary Receipt)',
  definition: 'Vergleichbar mit einem ADR (American Depositary Receipt)' }
,
{ name: 'Geschäftsdienste',
  definition: 'Anteil des Portfolios, der in Werbung, Verlagswesen, Druck, Geschäftsunterstützung, Unternehmensberatungen, Arbeitsvermittlung, Ingenieurswesen und Bau, Sicherheitsdiensten, Abfallwirtschaft, Vertrieben und Transportunternehmen angelegt ist. Beispiele: Lufthansa, Hochtief und Heideldruck.' }
,
{ name: 'Geschäftsjahr',
  definition: 'Die 12 Monate, für die ein Unternehmen seine Zahlen veröffentlicht und Steuern entrichtet. Muss nicht dem Kalenderjahr entsprechen.' }
,
{ name: 'Gesundheitswesen',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Biotechnologie, Pharma, Krankenhäuser, Medizintechnik und Altenheime anlgelegt ist. Beispiele: Qiagen, GlaxoSmithKline und Marseille Kliniken.' }
,
{ name: 'Gewinn pro Aktie',
  definition: 'Der Gesamtprofit eines Unternehmens geteilt durch die Anzahl der ausstehenden Anteile. Gewinn pro Aktie dient dem Anleger als Maßstab für die Profitabilität einer Firma. Er wird für die Berechnung des <a href="Default.aspx?lang=de&amp;keyword=P/E&amp;HelpType=S">P/E</a> verwendet.' }
,
{ name: 'Gewinne',
  definition: 'Gewinne bezeichnen das Einkommen eines Unternehmens in einem bestimmten Zeitraum. Die Kosten für Vertrieb, laufende Kosten, Wertminderung und andere Ausgaben werden dabei vom Umsatz abgezogen.' }
,
{ name: 'Gilt', definition: 'Britische Staatsanleihe.' }
,
{ name: 'Globaler Fonds',
  definition: 'Ein globaler Fonds legt weltweit an.' }
,
{ name: 'Growth',
  definition: 'Growth (Wachstum) steht für einen Anlagestil, der Aktien mit überdurchschnittlichem Umsatz- und Gewinnwachstum bevorzugt. Dafür sind Investoren bereit, einen höheren Preis zu bezahlen. Growthtitel weisen demnach hohe Bewertungskennziffern auf, z.B. ein hohes Kurs-Gewinn- oder Kurs-Buchwert-Verhältnis. Growthanleger gehen davon aus, dass hohe Gewinne in der Zukunft den hohen Aktienkurs in der Gegenwart rechtfertigen bzw. weitere Kurssteigerungen ermöglichen.' }
,
{ name: 'Growth &amp; Income Fund',
  definition: 'Ein Fonds, der sich auf Unternehmen fokussiert, die sowohl ein langfristiges Gewinnwachstum als auch eine attraktive Dividende bieten sollen.' }
,
{ name: 'Growth at a Reasonable Price',
  definition: 'Anlageansatz, der nach günstig bewerteten Unternehmen mit hohem Gewinn- und Umsatzwachstum sucht. Moderate Variante des Growth-Ansatzes.' }
,
{ name: 'Hang Seng',
  definition: 'Hang Seng ist der Aktienindex der 33 größten Unternehmen an der Hongkonger Börse.' }
,
{ name: 'Hardware',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Computer, Kommunikationsgeräte, Halbleiter, Teile angelegt ist. Beispiele: Siemens, Infineon und Ericsson.' }
,
{ name: 'Hedgefonds',
  definition: 'Ein Hedgefonds strebt überdurchschnittliche, positive Renditen an unter Verwendung verschiedener, oft aggressiver Strategien, die in traditionellen Investmentfonds meist nicht möglich sind. Dazu gehören u.a.  Hebelwirkung, Baissespekulation, Derivative aller Art und Arbitrage. Hedgefonds sind meist sehr komplex und eignen sich nur für professionelle und institutionelle Anleger.' }
,
{ name: 'Hedging',
  definition: 'Mit Hedging bezeichnet man ein Geschäft zur Absicherung gegen Preisrisiken (z.B. Schwankungen von Wechselkursen oder Rohstoffpreisen). Dabei wird zu einer bestehenden Position ein Gegengeschäft in Form von Terminkontrakten oder Optionen eingegangen. So nutzen Exporteure Devisentermingeschäfte, um ihre später eingehenden Forderungen in Fremdwährungen zu sichern. Auch Importeure können ihre in Zukunft zu begleichenden Rechnungen absichern und so eine bessere Planungsgrundlage schaffen.' }
,
{ name: 'High-Yield Fonds',
  definition: 'Fonds für hochverzinsliche Anleihen (Anleihen mit niederer Bonität).' }
,
{ name: 'Income Units',
  definition: 'Fondsanteil, der regelmäßige eine Dividende ausschüttet.' }
,
{ name: 'Index',
  definition: 'Ein Index ist eine Ansammlung von Wertpapieren die ausgewählt werden, um eine bestimmte Anlagekategorie oder Börse zu repräsentieren. Zu den bekanntesten Indizes gehören der DAX 30, der Dow Jones, S &amp; P 500, CAC 40, FTSE 100 und der Stoxx 50. Viele Fonds messen ihre Wertentwicklung im Vergleich zu einem Index.' }
,
{ name: 'Indexfonds',
  definition: 'Ein Indexfonds ist ein Fonds, der einen bestimmten Index möglichst genau nachbildet, und somit auch die Wertentwicklung des Indizes mitmacht. Auch passive Fonds genannt. Diese Fonds haben meist niedrigere Gebühren als aktiv gemanagte Fonds.' }
,
{ name: 'Indexfonds',
  definition: 'Ein Indexfonds spiegelt eine ausgewählte Benchmark wider, indem er die dort enthaltenen Wertpapiere in der selben Gewichtung im Portfolio hält.' }
,
{ name: 'Individual Savings Account (ISA)',
  definition: 'Steuerbegünstigte Fondsanlage in Großbritannien' }
,
{ name: 'Industriematerialien',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Luftfahrttechnik und Rüstung, Chemie, Machinenbau und Rohstoffe angelegt ist. Beispiele: EADS, BASF und Newmont Mining.' }
,
{ name: 'Inflation',
  definition: 'Inflation ist der Prozentsatz, zu dem Löhne und Gehälter sowie Preise allgemein innerhalb eines bestimmten Zeitraumes steigen (meist ein Jahr).' }
,
{ name: 'Inflationsgeschützte Anleihen',
  definition: 'engl. Inflation-linked bonds. Anleihen, die vor Geldentwertung schützen. Im Vergleich zu traditionellen Anleihen, die eine Nominalrendite erwirtschaften, bieteninflationsgeschützte Anleihen eine reale Rendite: Der Wert des Coupons und/oder des Tilgungsbetrags wird an die Inflationsentwicklung (Verbraucherpreisindex) angepasst. Inflationsgeschützte Anleihen reagieren ebenfalls auf die Zinsentwicklung, aber tendenziell in geringerem Maße als herkömmliche Zinspapiere.' }
,
{ name: 'Inland Revenue',
  definition: 'Britisches Finanzministerium' }
,
{ name: 'Institutioneller Anleger',
  definition: 'Ein Sammelbegriff für Firmen, die größere Summen Geld in Wertpapieren und Fonds anlegen, oft im Auftrag von Privatpersonen. Beispiele sind Pensionsfonds, Versicherungen, Fondsgesellschaften und Banken.' }
,
{ name: 'International Securities Identification Number (ISIN)',
  definition: 'Die internationale Kennziffer für ein börsennotiertes Anlageinstrument.' }
,
{ name: 'Investment Grade',
  definition: 'Ein Rating gibt Auskunft über die Kreditwürdigkeit eines Schuldners. Je höher das Rating, desto geringer die Wahrscheinlichkeit eines Zahlungsausfalls des Schuldners. Man unterscheidet zwischen Anleihen hoher Kreditqualität (Investment Grade) und spekulativen Anleihen (High Yield oder Junk Bonds). Für Investment Grade-Anleihen vergibt Standard &amp; Poor´s die Noten AAA bis BBB, bei Moody´s lauten die Noten Aaa bis Baa.' }
,
{ name: 'Investment Trust',
  definition: 'Britischer geschlossener, börsennotierter Fonds.' }
,
{ name: 'Investmentfonds',
  definition: 'Ein Investmentfonds investiert in verschiedene Anlagen, gemäß den im Verkaufsprospekt festgelegten Anlagegrundsätzen. Dazu können u.a. Aktien, Anleihen oder Immobilien gehören. Die erwirtschafteten Erträge aus Dividenden, Zinsen und Kursgewinnen werden entweder an den Anteilseigner ausgeschüttet oder reinvestiert (thesaurierende Fonds).' }
,
{ name: 'IPO',
  definition: 'IPO steht für Initial Public Offering = Börsengang eines Unternehmens' }
,
{ name: 'ISA',
  definition: 'Steuerbegünstigte Fondsanlage in Großbritannien' }
,
{ name: 'Jährliche Verwaltungsgebühr',
  definition: 'Siehe <a ref="Default.aspx?lang=de&amp;keyword=MngmntFee&amp;HelpType=S"> Management Charge</a>.' }
,
{ name: 'Johannesburg Stock Exchange (JSE)',
  definition: 'Südafrikas einzige Börse, mit Schwerpunkt Gold- und Rohstoffaktien..' }
,
{ name: 'Junk Bond',
  definition: 'Umgangssprachlicher Begriff für eine hochverzinsliche Anleihe (wörtlich Schrottanleihe).' }
,
{ name: 'Kapitalstruktur',
  definition: 'Die Kapitalstruktur beschreibt die Art der Finanzierung durch die Investoren. Dabei wird grundlegend zwischen Aktien und Anleihen unterschieden. Das Verhältnis zwischen Schulden und Aktieneigentum ist die gebräuchlichste Charakterisierung der Kapitalstruktur.' }
,
{ name: 'KGV',
  definition: 'KGV: Das Kurs-Gewinn-Verhältnis wird durch Teilung des aktuellen Aktienkurses durch den Gewinn pro Aktie errechnet. Dabei werden die Gewinne der letzten 12 Monate durch die Anzahl der ausgegebenen Anteile geteilt. Unternehmen von denen die Anleger starkes Gewinnwachstum erwarten haben oft ein hohes KGV, während reifere Firmen meist relativ niedrige KGV´s aufweisen. So hat z. B. eine Aktie mit einem Kurs von 16 Euro mit einem erwarteten Gewinn von 4 Euro je Anteil im nächsten Jahr ein KGV von 4.' }
,
{ name: 'Komplettrendite',
  definition: 'Die Komplettrendite ist die Summe des Kapitalanstiegs eines Wertpapiers und der Ausschüttungen (Dividenden), geteilt durch die ursprüngliche Anlagesumme.' }
,
{ name: 'Konjunkturzyklus',
  definition: 'Der Konjunkturzyklus besteht aus abwechselnden Perioden wirtschaftlicher Expansion und Schrumpfung, gekennzeichnet durch wechselnde Zinssätze, Arbeitlosenquoten und Produktivität. Die momentane Konjunkturperiode (z.B Aufschwung, Rezession) hat Einfluß auf die Wertentwicklung der Anlagesektoren.' }
,
{ name: 'Konsumgüter',
  definition: 'Anteil des Portfolios der in Lebensmitteln, Haushaltsprodukten, Kleidung, Schuhe, Autos, Unterhaltungselektronik, Luxuxgüter, Verpackung und Tabak angelegt ist. Beispiele: Benetton, BMW und Nestlé.' }
,
{ name: 'Korrelation',
  definition: 'Die Korrelation ist eine Beziehung zwischen zwei oder mehr statistischen Variablen. Es gibt positive (je mehr, desto mehr ...) und negative Korrelationen (je mehr, desto weniger ...). Bsp.: Wenn Aktie X steigt, steigt auch Aktie Y (positive Korrelation). Wenn Aktie X steigt, fällt Aktie Y (negative Korrelation). Das Gesamtrisiko eines Portfolios ist umso geringer je weniger die einzelnen Anlagen miteinander korrelieren. Die Korrelation beschreibt aber nicht zwingend einen Ursache-Wirkungs-Zusammenhang in die eine oder andere Richtung.' }
,
{ name: 'Kostenajustierte Rendite',
  definition: 'Die Nettorendite unter Berücksichtigung aller anfallenden Kosten und Gebühren.' }
,
{ name: 'Kreditrating',
  definition: 'Ein Kreditrating bewertet die Wahrscheinlichkeit des Zahlungsausfalls einer Anleihe. Viele Ratingagenturen haben eigene Systeme, darunter Moody´s, Standard &amp; Poors und Fitch. Anleihen mit Ratings zwischen AAA und BBB- werden auch "Investment Grade" genannt, Papiere mit BB+ und darunter sind "Non-Investment Grade" oder auch "Junk Bonds".' }
,
{ name: 'Kündbare Anleihe',
  definition: 'Eine kündbare Anleihe kann vom Emittenten vor Ablauf der Fälligkeit zurück gezahlt werden.' }
,
{ name: 'Kupon',
  definition: 'Der Kupon ist der festgelegte Prozentsatz für Zinszahlungen bei festverzinslichen Wertpapieren.' }
,
{ name: 'Kuponanleihe',
  definition: 'Bei einer Kuponanleihe (auch Inhaberschuldverschreibung) wird dem Inhaber der Anleihe das eingesetzte Kapital sowie die auf den Kupon entfallenden Zinsen ausbezahlt.' }
,
{ name: 'Kurs / Buchwert',
  definition: 'Diesen Wert erhält man durch Teilung des aktuellen Aktienkurses durch den Buchwert pro Anteil. Diese Kennzahl vergleicht, wie der Markt eine Aktie im Vergleich zu den Firmenbilanzen bewertet. Ein Titel mit einem hohen Kurs / Buchwert z.B. deutet meist auf einen Wachstumswert hin, von dem die Anlger glauben, dass sein Buchwert in der Zukunft steigen wird. Ein niedriger Kurs / Buchwert ist oft Anzeichen dafür, dass die Anleger glauben, dass die Vermögenswerte der Firma in ihren Bilanzen zu hoch angesetzt wurden.' }
,
{ name: 'Kurs-Geldfluß Verhältnis',
  definition: 'Diese Bewertungskennzahl wird durch Teilung des aktuellen Aktienkurses durch den Geldfluß oder cash flow, d.h. laufende Einnahmen und Ausgaben eines Unternehmens, errechnet. So bedeutet z.B. ein Kurs - Geldfluss-Verhältnis von 10, dass für jeden Euro, der durch das Unternehmen fließt, der Aktienkurs 10 Euro ist. Diese Zahl variiert sehr stark je nach Sektor, in dem die Aktiengesellschaft tätig ist. In kapitalintensiven Industriezweigen wird das Verhältnis wesentlich niedriger sein als z.B. im Softwarebereich.' }
,
{ name: 'Lagging Economic Indicators',
  definition: 'Konjukturelle Kennzahlen, die hinter der allgemeinen wirtschaftlichen Entwicklung zurück bleiben.' }
,
{ name: 'Laufende Wertentwicklung',
  definition: 'Zeigt die Wertentwicklung in einem sogenannten rollierenden Zeitraum an, d.h. immer 1 Woche, Monat, Jahre usw. zurückgerechnet. Die laufende Wertentwicklung für 1 Jahr am 14.3.2001 würde z.B. den Zeitraum 15.3.2000 bis 14.3.2001 abdecken, die laufende Wertentwicklung für 1 Woche würde die Periode vom 8.3.2001 bis 14.3.2001 beschreiben.' }
,
{ name: 'Leading Economic Indicators',
  definition: 'Konjunkturelle Kennzahlen, die der allgemeinen wirtschaftlichen Entwicklung voraus sind.' }
,
{ name: 'Leverage',
  definition: 'Einsatz von Fremdkapital oder Derivaten, um mit vergleichsweise geringem Kapitaleinsatz eine hohe Rendite zu erzielen. Im ungünstigen Fall können Verluste entstehen, die den Kapitaleinsatz übersteigen. Man bezeichnet dies auch als Hebelwirkung. Somit erhöht Leverage das Risiko.' }
,
{ name: 'lfdJ',
  definition: 'Steht für die Wertentwicklung seit 1.1. des laufenden Jahres.' }
,
{ name: 'Liquidität',
  definition: 'Liquidität zeigt an wie leicht es ist, eine Wertpapier zu kaufen oder zu verkaufen ohne den Kurs nennenswert zu beeinflussen.' }
,
{ name: 'London Interbank Offered Rate (LIBOR)',
  definition: 'LIBOR ist der Zinssatz, den die größten internationalen Banken einander für Kredite in Rechnung stellen.' }
,
{ name: 'London Stock Exchange (LSE)',
  definition: 'Der LSE wurde 1976 durch Fusion 6 großer britischer Regionalbörsen gegründet und ist die bedeutendste Börse Europas. Der bekannteste Index in Verbindung mit der LSE ist der FTSE 100.' }
,
{ name: 'Makler',
  definition: 'Ein Makler ist eine Person oder Firma, die als Vermittler zwischen einem Käufer und Verkäufer von Wertpapieren agiert, und für Ihre Dienste eine Provision berechnet. Durch die Elektronisierung der Börsen verschwindet dieses Berufsbild zusehends.' }
,
{ name: 'Märkte',
  definition: 'Gemeint sind die Börsenplätze weltweit und ihre Entwicklung.' }
,
{ name: 'Markttiming',
  definition: 'Markttiming bezeichnet eine Anlagestrategie, in der Anleger zwischen verschiedenen Wertpapieren oder Fonds hin- und herwechseln, in der Hoffnung von konjunkturellen oder technischen Signalen zu profitieren, die angeblich Marktbewegungen vorausgehen.' }
,
{ name: 'Markt-Timing',
  definition: 'Markt-Timing ist eine Anlagestrategie, in der Anleger zwischen Wertpapieren oder Fondstypen hin-und-herspringen. Dies geschieht in der Hoffnung, von verschiedenen ökonomischen und technischen Indikatoren zu profitieren, die Marktbewegungen vorhersagen sollen.' }
,
{ name: 'Maxi-ISA', definition: 'vgl. ISA' }
,
{ name: 'Medianwert',
  definition: 'Der mittlere Wert in einer Reihe, nicht zu verwechseln mit dem Durchschnitt. Beispiel: in der Reihe 1, 4, 7, 8, 10, 11, 23, 45, und 52, ist der Medianwert 10 (der fünfte von neun Werten).' }
,
{ name: 'Medien',
  definition: 'Anteil des Portfolios, der in Unternehmen investiert, die Fernseh- oder Radiostationen oder Presseorgane besitzen oder betreiben, oder für diese Inhalte erstellen oder liefern. Beispiele: Time Warner, Pearson und TF1.' }
,
{ name: 'MEX',
  definition: 'Wichtigster mexikanischer Aktienindex.' }
,
{ name: 'Mindestanlagesumme',
  definition: 'Die Mindestsumme, die ein Anleger aufbrigen muss, um ein Konto für einen Investmentfonds zu eröffnen.' }
,
{ name: 'Mini-ISA', definition: 'vgl. ISA' }
,
{ name: 'Mischfonds',
  definition: 'Ein Mischfonds kombiniert Aktien und Anleihen in einem Portfolio. Er hat im Allgemeinen ein geringeres Wertsteigerungspotenzial als ein reiner Aktienfonds, aber auch ein niedrigeres Risiko. Mischfonds können verschieden große Aktien- bzw. Anleihenanteile halten. Dies beeinflußt die Wertentwicklung und das Risikoprofil.' }
,
{ name: 'Moderne Portfoliotheorie (MPT)',
  definition: 'Moderne Portfoliotheorie bezieht sich auf eine Reihe von Neuerungen inder Vermögensverwaltung aus den 50er Jahren. Das zentrale Konzept der MPT ist die Diversifikation, d.h. die Feststellung dass eine gut ausgewählte Kombination von Investitionen eine höhere Rendite bei geringerem Risiko erwirtschaften kann als jede Einzelanlageform für sich genommen. Ein weiteres wichtiges Konzept ist das Marktrisiko. Das Risiko eines Fonds kann in zwei Bereiche unterteilt werden: Auf der einen Seite die Schwankungen, die durch Bewgungen im Aktienmarkt insgesamt verursacht werden (beschrieben durch <a href="Default.aspx?lang=en-GB&amp;keyword=Beta&amp;HelpType=S">Beta</a>), auf der anderen Seite Schwankungen, die von breiten Marktbewegungen unabhängig sind (spezifisches Risiko). <a href="Default.aspx?lang=de&amp;keyword=Alpha&amp;HelpType=S">Alpha</a>, <a href="Default.aspx?lang=de&amp;keyword=Beta&amp;HelpType=S">Beta</a>, <a href="Default.aspx?lang=de&amp;keyword=rsquare&amp;HelpType=S">R-squared</a>, Korrelation und Volatilität sind Kennzahlen, die normalerweise mit MPT in Verbindung stehen.' }
,
{ name: 'Morningstar Kategorie',
  definition: 'Aufgrund ihrer tatsächlichen Anlagestils - der nicht immer dem offiziell angegebenen Zielen entspricht - ordnet Morningstar jeden Fonds einer Kategorie zu. Dabei ziehen wir wann immer möglich mehrere komplette Portfolios  zur Beurteilung heran.' }
,
{ name: 'Morningstar Rating',
  definition: 'Das Morningstar Rating ist eine Klassifizierungsmethode nach risikoangepaßter Wertentwicklung innerhalb einer Morningstar Kategorie. Eine ausführliche Beschreibung finden Sie unter "Morningstar Rating" im Bereich "Über uns" - "Unsere Methodik".' }
,
{ name: 'Morningstar Style Box',
  definition: 'Die Morningstar Style Box für Aktienfonds veranschaulicht den vorherrschenden Anlagestil eines Fonds zu einem gegebenen Zeitpunkt. Sie kombiniert zwei Dimensionen: Größe (Marktkapitalisierung: groß mittelgroß, klein) und Bewertung (Value, Blend, Wachstum), woraus neuen verschiedene Stilkombinationen resultieren. Die Style Box liefert ein Bild des Anlageschwerpunkts der Fonds und unterstützt einfache, aber wichtige Entscheidungen hinsichtlich der Struktur eines Portfolios von Fonds. Die Morningstar Style Box für Anleihen erfüllt den gleichen Zweck für Anleihenfonds: sie kombiniert Bonität (hoch, mittel, niedrig) mit Zinssensibilität via modifizierte Duration, identische Stufen). Für weitere Details klicken Sie bitte auf "Über uns" und dann "Morningstar Style Box" unter "Unsere Methodik".' }
,
{ name: 'MSCI',
  definition: 'MSCI (Morgan Stanley Capital International) ist einer der größten Anbieter von internationalen Aktien-, Renten- und Hedgefondsindizes. Insbesondere die Aktienindizes gehören seit 30 Jahren zu den meist genutzten Vergleichsindizes für Aktienfonds. Sie werden auch von Morningstar auf der Aktienseite als Kategorieindizes genutzt und bilden eine große Anzahl an Ländern, Regionen und Branchen ab.' }
,
{ name: 'NASDAQ',
  definition: 'NASDAQ (National Association of Securities Dealers Automated Quotations) ist der größte elektronische Handelsplatz in den USA und die zweitgrößte Börse überhaupt.. Er wurde 1971 ins Leben gerufen. Zu den bekanntesten dorft gehandelten Werten gehören Intel, Dell Computers, Microsoft und Cisco Systems.' }
,
{ name: 'Net Asset Value (NAV)',
  definition: 'Der Nettoinventarwert ist der Wert aller Anlagen in einem Fonds. Der NAV pro Anteil eines Investmentfonds entspricht meist dem Rücknahmepreis, es sei denn eine Rücknahmegebühr wird erhoben.' }
,
{ name: 'Net Assets (Fund)',
  definition: 'vgl. <a href="Default.aspx?lang=en-GB&amp;keyword=NAFnds&amp;HelpType=S">Total Net Assets</a>.' }
,
{ name: 'New York Stock Exchange (NYSE)',
  definition: 'Größte und älteste Börse der USA an der Wall Street, noch immer im Parketthandel betrieben. Der S&amp;P 5000 sowie der DJ 30 gehören zu den bekannten Indizes der New Yorker Börse.' }
,
{ name: 'No-Load Fund',
  definition: 'Ein No-load Fonds ist ein Investmentfonds ohne Aufgabeaufschlag und Vertriebsservicegebühr, oder erhöhte Verwaltungsgebühr. In Deutschland gibt es bislang fast keine echten No-Load Fonds, jedoch zahlreiche Fonds ohne Ausgabeaufschlag (Trading Funds).' }
,
{ name: 'OEIC', definition: 'Trifft in Deutschland nicht zu.' }
,
{ name: 'Ohne Dividende / Kupon',
  definition: 'Aktien oder Anleihen die ohne das Anrecht auf die anstehende Dividende verkauft werden.' }
,
{ name: 'Optimierer Einstellungen',
  definition: 'Durch die Auswahl dieses Kästchens kann der Nutzer ein konservativeres Vorgehen des Optimierers einstellen, ohne dass die grundlegenden Zielsetzungen davon beeinflusst würden. Standardmäßig wird die Fondsperformance mit 40%, das Risiko mit 30% und die Kosten mit 30% gewichtet. Der Nutzer kann das Risiko (Gewichtung erhöht sich auf 40% und ist damit so hoch wie die der Performance) oder die Kosten (Gewichtung erhöht sich auf 40%, gleich der Gewichtung der Performance) stärker betonen.' }
,
{ name: 'Option',
  definition: 'Der Käufer einer Option erwirbt das Recht, aber nicht die Pflicht, eine bestimmte Anzahl von Aktien zu einem bereits bei Vertragsabschluß festgelegten Preis (Ausübungspreis) bis zu einem Stichtag (Verfallstag) zu kaufen (Call) oder zu verkaufen (Put). Der Käufer der Option wird diese nur wahrnehmen, wenn er den Basiswert zu einem niedrigeren als dem aktuellen Marktkurs kaufen oder zu einem höheren als dem aktuellen Marktkurs verkaufen kann. Gegenüber Futures bieten Optionen den Vorteil, dass das Risiko von Vorneherein auf den Kaufpreis der Option (Optionsprämie) beschränkt ist. Mehr als die Prämie setzt der Käufer einer Option nicht ein. Der Verkäufer einer Option (Stillhalter) geht allerdings ein höheres Risiko ein. Wenn der Käufer die Option ausüben will, muss er den Basiswert liefern, d.h. Wertpapiere zu einem höheren Kurs als dem aktuellen Marktkurs kaufen oder zu einem niedrigeren Kurs verkaufen. Für die Übernahme dieses Risikos wird der Stillhalter durch die Optionsprämie entlohnt.' }
,
{ name: 'Penny Stocks',
  definition: 'Typischerweise eine volatile, risikoreiche Aktie eines Unternehmens mit geringer Marktkapitalisierung. Der Aktienkurs war früher unter 10 pence, was diesen Aktien ihren Namen gab.' }
,
{ name: 'PEP',
  definition: 'Britische Form eines Aktiensparplans.' }
,
{ name: 'PEP Transfer', definition: 'vgl. PEP' }
,
{ name: 'Performance',
  definition: 'Ein anderer Begriff für Wertentwicklung, d.h. um wieviel der Wert einer Anlage über einen bestimmten Zeitraum gestiegen oder gefallen ist. Anleger können die Performance von Fonds mit ähnlicher Anlagestrategie vergleichen.' }
,
{ name: 'Performancegebühr',
  definition: 'Bei einigen Fonds fällt eine zusätzliche Performancegebühr an, wenn eine bestimmte Zielmarke in einem festgelegten Zeitraum überschritten wurde.' }
,
{ name: 'Performancemessung',
  definition: 'Ein zweistufiger Przess: zunächst wird untersucht, ob der Fondsmanager seine Benchmark geschlagen hat. Dann wird analysiert, mit welcher Strategie er dies erreicht hat (vor allem, ob er sich an die Vorgaben des Anlageziels gehalten hat).' }
,
{ name: 'Perzentil in Kategorie',
  definition: 'Prozuentualer Rang in der Kategorie von 1 bis 100. Dabei wäre der Fonds mit der besten Wertentwicklung im 1. Perzentil und der schlechteste im 100. Perzentil angesiedelt.' }
,
{ name: 'PIA', definition: 'Britische Regulierungsbehörde.' }
,
{ name: 'Portfolio',
  definition: 'Ein Portfolio ist eine gebündelte Ansammlung von Aktien, Anleihen, Fonds oder anderen Wertpapieren.' }
,
{ name: 'Portfolioarten',
  definition: 'Es gibt zwei Arten von Portfolios: Schnell - und Transaktionsportfolio. Ein Schnellportfolio ist der einfachste Weg, ein Portfolio zu erstellen. Ein Transaktionsportfolio bietet komplette Portfolioüberwachung incl. Dividenden, Splits und Transaktionsgebühren.' }
,
{ name: 'Premium to NAV',
  definition: 'Wenn ein börsennotierter Investmentfonds zu einem Kurs handelt, der den Wert der zugrundeliegenden Fondsanlagen übertrifft.' }
,
{ name: 'R²',
  definition: 'R² bezeichnet den Prozentsatz der Bewegungen eines Fonds, die durch Bewegungen in seinem Referenzindex erklärt werden können. So bedeutet ein R² von 35, dass nur 35% der Bewegungen des Fonds durch die Veränderungen in seinem Vergeichsindex erklärbar sind. R² wird genutzt, um die Aussagekraft von <a href="Default.aspx?lang=de&amp;keyword=Beta&amp;HelpType=S">Beta</a> zu bestimmen. Je höher das R², desto signifikanter das <a href="Default.aspx?lang=de&amp;keyword=Beta&amp;HelpType=S">Beta</a> .' }
,
{ name: 'Rechenschaftsbericht',
  definition: 'Ein Rechenschaftsbericht muss jährlich von jedem Fonds vorgelegt werden. Er enthält eine Auflistung der Postionen zum Stichtag, eine Bilanz incl. der Gebühren und sonstigen Ausgaben und informiert über die Zusammensetzung des Fondsmanagements und Aufsichtsrats.' }
,
{ name: 'REIT',
  definition: 'REIT steht für  „Real Estate Investment Trust“. REITs sind börsennotierte Immobilien-Aktiengesellschaften, die Einkaufszentren, Büroflächen, Hotels, Lagerhäuser oder Wohnungen besitzen und verwalten. Die in den USA bereits seit langem etablierten Gesellschaften schütten einen Großteil ihrer Erträge an die Aktionäre aus. Im Gegenzug dafür sind sie von der Körperschaftssteuer befreit. Stattdessen werden die Dividenden auf Ebene der Anteilseigner (Aktionäre) versteuert.' }
,
{ name: 'Retail Price Index (RPI)',
  definition: 'Retail Price Index [RPI]: Inflationsmaß. Dieser Index basiert auf einem Warenkorb von Gütern und Dienstleistungen (Kleidung, Lebensmittel, Haushaltsgegenstände, Medizinische Versorgung), der einen typischen Verbraucher repräsentiert.' }
,
{ name: 'Risikobudget',
  definition: 'Das Risikobudget legt die zulässige Verlustobergrenze fest. Es dient als Risikopuffer und steht für risikobehaftete Anlagen zur Verfügung. Entwickeln sich diese positiv, wächst das Risikobudget und der Fonds kann seine risikobehafteten Anlagen aufstocken. Kommt es hingegen zu einer negativen Marktentwicklung, wird das Risikobudget aufgezehrt und der Fondsmanager ist gezwungen, Risiko aus dem Portfolio herauszunehmen und sich defensiver auszurichten. Auf diese Weise soll das anfangs eingesetzte Kapital erhalten bleiben. Das Risiko misst sich durch die (historischen) Kursschwankungen (Volatilität) der Anlagen.' }
,
{ name: 'Risikoprofil',
  definition: 'Der Optimierer bietet 5 vorbestimmte Modelle der Vermögensaufteilung. Diese reichen von Werterhaltung bis Aggressiv. Anmerkung für die Nutzer des Morningstar AWS Bedarfsanalysemoduls: Diese 5 wurden aus den gezeigten 21 effizienten Vermögensaufteilungen (wenn nur Erwartungswert und Varianz für den Anleger relevant sind) ausgewählt. Die entsprechenden Risikoprofile unterscheiden sich nur durch ihre Aufteilung auf Kasse, Anleihen und Aktien. Ansonsten ist eine neutrale Ausrichtung im Bezug auf Stil, Marktkapitalisierung, Sektoren und Bonität vorgegeben, außer der Nutzer hat hier Änderungen vorgenommen. Alle Standardeinstellungen und -gewichtungen erscheinen weiter unten in den entsprechenden Kästchen. Beim Öffnen dieser Seite erscheint zunächst immer die mittlere Vermögensaufteilung, "Balance".' }
,
{ name: 'Risikoprofil - Fonds',
  definition: 'Der Pfeil zeigt das Risiko des Fonds anhand der Standardabweichung an. Diese misst, wieweit sich die Fondsrendite im Durchschnitt vom Mittelwert entfernt. Eine hohe Standardabweichung zeigt an, dass die Rendite sehr volatil ist, während eine niedrige Standardabweichung auf eine relativ stetige Wertentwicklung deutet. Niedrig: Standardabweichung 0 - 13,34 Moderat: 13,34 - 26,67, Hoch: größer als 26,67' }
,
{ name: 'Rücknahmeabschlag',
  definition: 'Rücknahmeabschlag; vgl. <a href="Default.aspx?lang=en-GB&amp;keyword=ExtChrg&amp;HelpType=S">Exit Charge</a>.' }
,
{ name: 'Rücknahmegebühr (Fonds)',
  definition: 'Eine Rücknahmegebühr wird beim Verkauf von Fondsanteilen erhoben. Sie fließt direkt dem Fondsvermögen zu.' }
,
{ name: 'Rücknahmepreis',
  definition: 'Der Preis zu dem Anleger Anteile an die Fondsgesellschft zurückgeben können. Entspricht dem NAV, es sei denn es existiert eine Rücknahmegebühr.' }
,
{ name: 'Schwellenland',
  definition: 'Ein Schwellenland oder Emerging Market ist ein Land auf der Schwelle zum Industrieland. Anlagen in Shcwellenländer sind riskanter als solche in voll entwickelten Ländern, da sie oft politisch oder wirtschaftlich instabil sind oder illiquide Kapitalmärkte haben.' }
,
{ name: 'Securities and Exchange Commission (SEC)',
  definition: 'Diese US-Behörde hat seit 1934 die Aufgabe, alle Börsenteilnehmer zu überwachen, Anleger zu schützen und einen fairen und offenen Kapitalmarktverkehr sicherzustellen. Die Anordnungen der SEC sind für alle in den USA notierten Unternehmen verbindlich. Etwas vergleichbares existiert bislang in Deutschland nicht.' }
,
{ name: 'SEDOL',
  definition: 'Eine siebenstellige europäische Wertpapieridentifikationsnummer.' }
,
{ name: 'Sektorenfonds (Branchenfonds)',
  definition: 'Sektorenfonds investieren ausschließlich in einen bestimmeten Wirtschaftssektor, wie z.B. Technologie, Gesundheitswesen oder Finanzen. Durch ihren engen Fokus sind diese Fonds meist riskanter als breit gestreute Fonds, da der Markt oft verschiedene Sektoren bevorzugt und zwischen ihnen rotiert.' }
,
{ name: 'Sharpe Ratio',
  definition: 'Die Sharpe Ratio wurde durch den Nobelpreisträger William Sharpe entwickelt. Sie berechnet die Rendite pro Risikoeinheit, indem sie  die Zusatzrendite durch die Standardabweichung teilt. Je höher die Sharpe Ratio, um so besser die risikoangepasste Rendite eines Fonds.' }
,
{ name: 'Sharpe Ratio - Morningstar Rating',
  definition: 'Die Sharpe Ratio geht von einer grundlegenden Erkenntnis aus: Man investiert in einen Fonds mit einem Risikofaktor nur dann, wenn die erwartete Rendite größer ist als die Rendite einer risikolosen Anlage (wie z.B. einem Festgeldkonto). Sharpe definiert Risiko als die Volatilität der historischen Wertentwicklung. Wenn wir also die Rendite des Fonds als Rfonds bezeichnen, die risikolose Rendite als Rsicher und die Volatilität der Wertentwicklung als v, dann ist die Sharpe Ratio:(Rfonds – Rsicher) / vd.h. nur die Rendite zählt, die über der risikolosen Rendite liegt, was das eingegangene Risiko betrifft..' }
,
{ name: 'Short (Leerverkauf)',
  definition: '"Short" zu sein heisst ein Wertpapier verkauft zu haben, ohne es je besessen zu haben. Man spekuliert damit auf fallende Kurse, um das Papier später günstiger "kaufen" zu können. Der Gewinn ist die Differenz.' }
,
{ name: 'SICAV',
  definition: 'Eine SICAV (Société d´investissement à Capital Variable) ist eine Rechtsform für Investmentfonds. Besonders in Luxemburg verbreitet.' }
,
{ name: 'Software',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Computerbetriebssysteme und Anwendungen angelegt ist. Beispiele: Microsoft, SAP und Misys.' }
,
{ name: 'Sparplan',
  definition: 'Viele Fonds bieten Sparpläne an, bei denen die Anleger feste monatliche Beträge investieren können. Das Minimum beträgt oft 50 Euro. Entscheidend für den Erfolg ist (neben der Qualität des Fonds) das Durchhaltevermögen des Anlegers, d.h. auch in Schwächephasen dabei zu bleiben.' }
,
{ name: 'Split',
  definition: 'Ein Split heisst eine Firma erhöht die Anzahl der ausstehenden Anteile ohne den proportionalen Anteil jedes Anteilseigners zu verändern. Zum Beispiel würde eine Aktie zu 30 Euro bei einem 3:1-Split in 3 Aktien zu 10 Euro umgewandelt.' }
,
{ name: 'Spread',
  definition: 'Der Renditeunterschied zwischen Hochzinsanleihen und Anleihen guter Bonität.' }
,
{ name: 'Stammaktien',
  definition: 'Stammaktien sind Eigentumseinheiten an einem Unternehmen. Sie geben dem Inhaber das Stimmrecht für die Hauptversammlung, bilden jedoch den letzten Rang bei der Gläubigerrangfolge.' }
,
{ name: 'Standard and Poor\'s 500 Stock Index (S&amp;P 500)',
  definition: 'Der S &amp; P 500 ist der bedeutenste Aktienindex der USA. An ihm messen sich zahllose dort anlgende Investmentfonds. Er beinhaltet die 500 wichtigsten börsennotierten Unternehmen der USA.' }
,
{ name: 'Standardabweichung',
  definition: 'Die Standardabweichung eines Fonds misst, wie stark die Wertentwicklung eines Fonds in der Vergangenheit geschwankt hat. Der Begriff <a href="Default.aspx?lang=de&amp;keyword=Vol&amp;HelpType=S">Volatilität</a> wird oft ersatzweise verwendet. Diese Kennzahl ist aus zwei Gründen nützlich. Erstens, weil höhere Volatilität eines Fonds meist höheres Risiko bedeutet. Hierbei kann man Fonds quer durch alle Kategorien vergleichen. Zweitens, weil Fonds, die in der Vergangenheit volatiler waren tendenziell auch in Zukunft volatiler sein werden. Insofern ist die Standardabweichung ein nützliches Warnsignal. Die Standardabweichung wird in Prozent ausgedrückt. Wir berechnen sie auf Basis der letzten 36 monatlichen Wertentwicklungen. Für detaillierte Berechnungen gehen Sie bitte zu "Über uns", und dann zu der Rubrik "Berechnungen".' }
,
{ name: 'Switchen',
  definition: 'Der Umtausch von Anteilen eines Fonds gegen einen anderen.' }
,
{ name: 'Switch-Gebühr',
  definition: 'Einige wenige Fondsgesellschaften erheben noch immer Gebühren, wenn Anleger Gelder von einem ihrer Fonds in einen anderen Fonds der gleichen Gesellschaft umschichten.' }
,
{ name: 'Telekommunikation',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Festnetzbetreiber, Mobilfunk und Ausstatter angelegt ist. Beispiele: Deutsche Telekom, Vodafone und Telefonica.' }
,
{ name: 'TER (Total Expense Ratio)',
  definition: 'Die Total Expense Ratio (Gesamtkostenquote) umfasst alle Gebühren, mit denen ein Fonds im Laufe eines Geschäftsjahres belastet wird. Sie errechnet sich als Verhältnis zwischen Kosten und durchschnittlichem Fondsvermögen. Allerdings gilt zu beachten: Üblicherweise berücksichtigt die TER (wie sie z.B. von deutschen Fondsgesellschaften errechnet wird) nicht die Transaktionskosten, so dass es sich nicht um eine Gesamtkostenquote im engeren Sinne handelt. Enthalten sind: Verwaltungs- und Depotbankvergütung, Veröffentlichungs- und Prüfungskosten, Kosten für weitere Dienstleistungen' }
,
{ name: 'TESSAs',
  definition: 'Britische Form eines Sparplans.' }
,
{ name: 'Thesaurierung',
  definition: 'Ein thesaurierender Fonds schüttet keine Dividenden aus. Das Gegenteil ist ein ausschüttender Fonds.' }
,
{ name: 'Top-Down-Ansatz',
  definition: 'Im Rahmen des Top-Down-Anlageansatzes entscheidet der Fondsmanager zunächst über die Gewichtung von Aktien, Renten, Kasse usw. und in einem zweiten Schritt über die Aufteilung auf Regionen, Branchen und Währungen. Erst daran schließt sich die Einzeltitelauswahl an - orientiert an der zuvor festgelegten, übergeordneten Portfoliostrukturierung.' }
,
{ name: 'Tracker',
  definition: 'siehe <a href="Default.aspx?lang=de&amp;keyword=IndexFnd&amp;HelpType=S">Index Fund</a>.' }
,
{ name: 'Tracking Error',
  definition: 'Tracking Error beschreibt die Volatilität des Renditeunterschiedes zwischen einem Fonds und seinem Vergleichsindex. Auch aktives Risiko genannt. Er ist nützlich in der Wertentwicklungsbeurteilung: Je höher die Zusatzrendite (Outperformance) im Vergleich zum aktiven Risiko, desto besser.' }
,
{ name: 'Trendfolgemodell',
  definition: 'Trendfolgemodelle generieren Kauf- und Verkaufssignale. Sie sind prognosefrei, da sie dem Trend (z.B. eines zugrundeliegenden Index) folgen. Wenn sich ein Aufwärtstrend etabliert, wird der Investitionsgrad erhöht. Umgekehrt sinkt der Investitionsgrad, sobald sich ein Abwärtstrend herausbildet. Trendfolgemodelle erzielen im Idealfall über mehrere Marktzyklen hinweg eine höhere Performance als die Benchmark (Index)  - bei geringerer Volatilität. Vorteil ist die Absicherung bei fallenden Kursen. Nachteil ist die Underperformance in langen Aufwärts- oder Seitwärtstrends.' }
,
{ name: 'Treynor Ratio',
  definition: 'Kennzahl, die die Überschussrendite pro eingegangener Risikoeinheit angibt. Überschussrendite = Fondsrendite abzüglich des Ertrags einer risikolosen Geldanlage. Das Risiko wird mit Beta (Marktrisiko) gemessen. Eine höhere Treynor Ratio wird dabei von Investoren vorgezogen, da sie auf eine geringere Anfälligkeit des Fonds für Marktschwankungen deutet.' }
,
{ name: 'UCITS',
  definition: 'UCITS (Undertakings for Collective Investment in Transferable Securities) ist eine EU-Direktive, die festgelegt hat unter welchen Voraussetzungen Fonds, die in einem EU-Land domiziliert sind, in allen EU-Ländern vertrieben werden können. Zahlreiche nationale Zusatzverordnungen haben die Umsetzung in der Realität bisher erschwert. Im Jahr 2002 soll es jedoch endgültig den "EU-Pass" für Investmentfonds geben.' }
,
{ name: 'Umbrella-Fonds',
  definition: 'Ein Umbrella-Fonds ist ein Mantelfonds, der verschiedene Teilfonds mit verschiedenen Anlagezielen beinhaltet. Die Anleger können zwischen den Teilfonds meist ohne Gebühr hin- und herwechseln.' }
,
{ name: 'Umschlagquote (Turnover Ratio)',
  definition: 'Diese Prozentzahl zeigt an, welcher Anteil des Portfolios in einem bestimmten Zeitraum (meist ein Jahr) umgeschichtet wurde. Eine Umschichtungsquote von 20% bedeutet also, dass der Gesamtumfang der Transaktionen ein Fünftel des Fondsvolumens betrug.' }
,
{ name: 'Unit Trust',
  definition: 'Britische Rechtform von offenen Investmentfonds.' }
,
{ name: 'Unit Value',
  definition: 'Anteilswert eines Unit Trust (britischer Investmentfonds).' }
,
{ name: 'Unit-Linked',
  definition: 'Unit-linked refers to pension plan contributions used to buy units in an approved investment fund.' }
,
{ name: 'Unternehmensanleihe',
  definition: 'Eine Unternehmensanleihe ist eine Schuldverschreibung eine Unternehmens zum Zwecke der Kapitalaufnahme. Sie wird zum Ende der Laufzeit zurückgezahlt und zahlt zudem regelmässige Zinsen an den Anleger.' }
,
{ name: 'Value',
  definition: 'Substanzwertorientierter Anlagestil: Value-Manager suchen nach Unternehmen, die am Markt unter ihrem „inneren“ oder „wahren“ Wert gehandelt werden. Bei der Berechnung des inneren Werts werden die erwarteten zukünftigen Gewinne eines Unternehmens geschätzt, in die Gegenwart abdiskontiert und mit ihrem aktuellen Marktkurs verglichen. Als Value-Titel gelten Unternehmen, die mit einem Abschlag zum inneren Wert notieren und von denen erwartet wird, dass der Markt die Fehlbewertung erkennt und korrigiert. Die Morningstar Style Box weist für Fonds den Anlagestil (Value, Growth, Neutral) aus.' }
,
{ name: 'Value-at-Risk',
  definition: 'Der Value-at-Risk (VaR) ermittelt auf der Basis historischer Daten (z.B. Volatilität) den maximalen Verlust, den ein Portfolio in einem bestimmten Zeitraum mit einer vorgegebenen Wahrscheinlichkeit nicht überschreitet.' }
,
{ name: 'Verbraucherdienste',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Einzelhandel, Touristik, Unterhaltung und Erziehung angelegt ist. Beispiele: Metro, Hennes &amp; Mauritz und TUI.' }
,
{ name: 'Verkaufsgebühr (Fondsgesellschaft)',
  definition: 'Eine Rücknahmegebühr wird beim Verkauf von Fondsanteilen erhoben. Sie geht an die Fondsgesellschaft.' }
,
{ name: 'Verkaufsprospekt',
  definition: 'Ein Verkaufsprsopekt ist die offizielle, schriftliche Zeichnungsurkunde eines Fonds. In ihm werden Anlageziel und Struktur festgelegt. Das Studium dieses Dokuments ist für den Anlger sehr wichtig.' }
,
{ name: 'Vermögensaufteilung',
  definition: 'Dies zeigt an, wie stark ein Fonds oder ein Fondsportfolio in verschiedenen Anlageinstrumenten investiert ist; d.h. wie hoch die Gewichtung von Aktien, Anleihen, liquiden Mitteln oder sonstigen Wertpapieren ist. Ziel der Vermögensaufteilung is die Optimierung des Chance-Risikoporfils im Einklang mit dem Alnagehorizont und den Anlagezielen des Investors.' }
,
{ name: 'Vermögensstrukturierung nach Assetklassen',
  definition: 'Durch die Auswahl dieses Kästchens und die Eingabe der gewünschten Gewichtungen in die entsprechenden Felder kann der Nutzer die  Aufteilung des Fondsvermögens aktiv kontrollieren. Eine Kassequote von mindestens 2% ist empfehlenswert, da Investmentfonds selbst immer Kasse halten.' }
,
{ name: 'Vermögensverwaltung',
  definition: 'Vermögensverwaltung ist der allgemiene Begriff für die Verwaltung eines Portfolios in vorher festgelegten Anlagekategorien, wie z.B. Aktien, Anleihen oder Festgeld.' }
,
{ name: 'Versorger',
  definition: 'Anteil des Portfolios, der in Aktien aus den Bereichen Strom-, Gas- und Wasserversorger angelegt ist. Beispiele: E.on, RWE und Lyonnaise des Eaux.' }
,
{ name: 'Vertriebsstelle',
  definition: 'Die Vertriebsstelle ist die Person oder Firma, die für den Verkauf von Fondsanteilen an Endanleger zuständig ist. Sie ist auch für den Versand von Pflichtliteratur wie z.B. Rechenschaftsberichten verantwortlich.' }
,
{ name: 'Verwaltungsgebühr',
  definition: 'Die Verwaltungsgebühr, meist in Porzent ausgedrückt, wird von der Fondsgesellschaft erhoben. Sie ist ein Teil der laufenden Kosten des Fonds. Aus ihr werden die Kosten für das Fondsmanagement bestritten.' }
,
{ name: 'Volatilität',
  definition: 'Volatilität ist die beobachtete Preisbewegung (Schwankungsbreite) eines Wertpapiers oder Fonds. Die meistgebrauchte Kennzahl dafür ist die Standardabweichung. Volatilität und Standardabweichung gelten gemeinhin als Risikogradmesser (siehe Standardabweichung).' }
,
{ name: 'Vorzugsaktien',
  definition: 'Vorzugsaktien oder Vorzüge zahlen Dividenden zu einem festgelegten Datum, während Stammaktien oder Stämme z.T. gar keine Dividenden ausschütten. Vorzugsaktionäre haben normalerweise kein Stimmrecht. Sie haben jedoch Vorrecht vor Stammaktionären bei Ausschüttungen oder Liquidierungen. In Deutschland (noch) weiter verbreitet als in den meisten Aktienmärkten.' }
,
{ name: 'Währungsabwertung',
  definition: 'Währungsabwertung ist eine Abnahme des Wertes einer Währung im Vergleich zu einer anderen.' }
,
{ name: 'Währungsabwertung',
  definition: 'Wenn eine festgelegte Währung, oft durch Maßnahmen der Regierung, in ihrem Wert gegenüber anderen Währungen herab gesetzt wird.' }
,
{ name: 'Wandelanleihe',
  definition: 'Eine Wandelanleihe ist eine von einem Unternehmen ausgegebene Anleihe, die während ihrer Laufzeit zu einem festgelegten Preis in eine Aktie des Unternehmens umgewandelt werden kann. Die Entscheidung zur Umwandlung kann entweder beim Anleger oder beim Unternehmen liegen, ist jedoch nicht zwingend erforderlich.' }
,
{ name: 'Wertentwicklung p.a.',
  definition: 'Wertentwicklung per annum, d.h. jährlich. Sie beschreibt die durchschnittliche Wertentwicklung pro Jahr (im Gegensatz zur kumulierten Wertentwicklung).' }
,
{ name: 'Wertpapierleihe',
  definition: 'Hierbei verleiht der Besitzer sein Wertpapier mit der Maßgabe, das er nach Ablauf einer festgelegten Leihfrist Papiere gleicher Art und Güte zurückgegeben werden. Der Entleiher kann mit diesen Wertpapieren während der Leihfrist beispielsweise eigene Lieferverpflichtungen erfüllen. Für die Leihe hat er dem Verleiher eine Gebühr zu zahlen. Somit kann der Verleiher durch die Wertpapierleihe Erträge erwirtschaften.' }
,
{ name: 'Zinsstrukturkurve',
  definition: 'Graphische Darstellung des Zusammenhangs zwischen der (Rest)laufzeit und der Verzinsung einer Anleihe. Es werden die Zinsstrukturen festverzinslicher Wertpapiere gleicher Bonität, Restlaufzeit und Denomination dargestellt. Bei einer "normalen" Zinsstrukturkurve liegen die langfristigen Zinssätze über den kurzfristigen Zinssätzen . Im - selteneren- umgekehrten Fall spricht man vom Vorliegen einer inversen Zinsstruktur.' }
,
{ name: 'Zyklische Werte',
  definition: 'Solche Aktien deren Kurse sich meist im Einklang mit der konjunkturellen Lage bewegen (z.B Automobil-, Bau- und Maschinenbaufirmen)' }
]

module.exports = terms;