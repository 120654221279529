import { call, put, takeLatest } from 'redux-saga/effects';
import { APIGet } from 'utilities/APIRequestHelper';
import apiURLBuilder from 'core/apiURLBuilder';
import { FETCH_ACCOUNT_DEBITS_REQUEST } from 'constants/actionTypes';
import { setAuthorizations } from 'actions/accountDebits/authorizations';
import { requestAccountDebitsFailure } from 'actions/accountDebits';
import { setReceipts } from 'actions/accountDebits/receipts';

function* fetchAccountsDebits({ accountId, contractNumber, contractProduct }) {
   try {
        const requestParams = {
            dispatch: null,
            endpoint: 'accountDebits',
            url: apiURLBuilder.getURL('accountDebits'),
            query: {
                contractNumber,
                contractProduct
            }
        }
        const response = yield call(APIGet, null, apiURLBuilder.getURL('accountDebits'), requestParams);
        const { listaAutorizaciones, listaRecibos } = response;
        yield [
            put(setAuthorizations(
                accountId,
                listaAutorizaciones && listaAutorizaciones.autorizacion && listaAutorizaciones.autorizacion.length ? listaAutorizaciones.autorizacion : []
            )),
            put(setReceipts(
                accountId,
                listaRecibos && listaRecibos.recibos && listaRecibos.recibos.length ? listaRecibos.recibos : []
            ))
        ]
    } catch (e) {
        yield put(requestAccountDebitsFailure(e.error, accountId));
   }
}

function* accountDebitsSagas() {
    yield [
        takeLatest(FETCH_ACCOUNT_DEBITS_REQUEST, fetchAccountsDebits)
    ]
}

export default [
    accountDebitsSagas
]
