const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const get = require('lodash/object/get');
const set = require('lodash/object/set');
const moment = require('moment');
const {
    onlyExpensesCategoriesList,
    includesCategory
} = require('constants/categories')

const {
    GP_EXPENSES_FILTER_TYPE_ALL,
    GP_EXPENSES_FILTER_TYPE_ACCOUNTS,
    GP_EXPENSES_FILTER_TYPE_CARDS,
    GP_EXPENSES_FILTER_COMPARE_NONE,
    GP_EXPENSES_FILTER_TYPE_ACCOUNT_ALL,
} = require('constants/index');

function setInitialState() {
    let state =  Immutable.fromJS({
        isFetching: false,
        isPartialFetching: false,
        error: '',
        totalExpenses: 0,
        fetched: {
            [GP_EXPENSES_FILTER_TYPE_ALL]: false,
            [GP_EXPENSES_FILTER_TYPE_ACCOUNT_ALL]: false,
            [GP_EXPENSES_FILTER_TYPE_ACCOUNTS]: false,
            [GP_EXPENSES_FILTER_TYPE_CARDS]: false,
        },
        incomes: {
            currentPeriod: {
                total: {
                    [GP_EXPENSES_FILTER_TYPE_ALL]: 0,
                    [GP_EXPENSES_FILTER_TYPE_ACCOUNTS]: 0,
                    [GP_EXPENSES_FILTER_TYPE_CARDS]: 0
                }
            }
        },
        expenses: {
            currentPeriod: {
                total: {
                    [GP_EXPENSES_FILTER_TYPE_ALL]: 0,
                    [GP_EXPENSES_FILTER_TYPE_ACCOUNTS]: 0,
                    [GP_EXPENSES_FILTER_TYPE_CARDS]: 0
                },
                totalCustomDates: {},
                byCategory: {}
            },
            previousPeriod: {
                total: {
                    [GP_EXPENSES_FILTER_TYPE_ALL]: 0,
                    [GP_EXPENSES_FILTER_TYPE_ACCOUNTS]: 0,
                    [GP_EXPENSES_FILTER_TYPE_CARDS]: 0
                },
                byCategory: {}
            },
            categoriesOrder: []
        },
        expensesByCategory: [],
        filters: {
            type: GP_EXPENSES_FILTER_TYPE_ALL,
            compare: GP_EXPENSES_FILTER_COMPARE_NONE,
            typeAccount: GP_EXPENSES_FILTER_TYPE_ACCOUNT_ALL
        },
        filtersByCategory: {
            contracts: {
                isActive: false,
                cardsContracts: [],
                accountsContracts: []
            },
            date: {
                from: null,
                isActive: false,
                selected: '',
                to: null
            },
            subcategory: {
                isActive: false,
                subcategory: 'all'
            },
            groupContract: {
                value:'2'
            },
            typeAccount: {
                value: 'all'
            },
        }
    });

    return state;
}

const sortCategories = (state) => {
    const filterType = state.get('filters').get('type');
    if (!state.getIn(['expenses', 'currentPeriod', 'byCategory', filterType])){
        return state;
    }
    const sortedCategories = onlyExpensesCategoriesList.sort((categoryA, categoryB) => {
        const currentPeriodByCategoryAndFilter = state.getIn(['expenses', 'currentPeriod', 'byCategory', filterType]);
        let sortOrder = currentPeriodByCategoryAndFilter.get(categoryB) - currentPeriodByCategoryAndFilter.get(categoryA)
        if (!sortOrder){
            const previousPeriodByCategoryAndFilter = state.getIn(['expenses', 'previousPeriod', 'byCategory', filterType]);
            return previousPeriodByCategoryAndFilter.get(categoryB) - previousPeriodByCategoryAndFilter.get(categoryA)
        }
        return sortOrder;
    });

    return state.setIn(['expenses', 'categoriesOrder'], sortedCategories);
};

function updateExpenses(state, expensesData, customDate) {
    const incomes = {
        currentPeriod: {
            total: {},
        },
    };
    const expenses = {
        currentPeriod: {
            total: {},
            totalCustomDates: {},
            byCategory: {}
        },
        previousPeriod: {
            total: {},
            byCategory: {}
        }
    };

    const firstDayOfCurrentMonth = moment().utc().startOf('month');
    const filterType = state.get('filters').get('type');

    expensesData
        .filter((expense) => expense.categories !== 'Movimientos excluidos de la contabilidad')
        .forEach((expense) =>{
            const currentMonth = (moment(expense.fecha) < firstDayOfCurrentMonth) ? false : true;
            let amount, period;
            if(!customDate) {
                if (expense.categories === 'Ingresos') {
                    if (currentMonth) {
                        amount = Math.abs(expense.monto);
                        period = incomes.currentPeriod;
                        period.total[filterType] = (period.total[filterType] || 0) + amount;
                    }
                } else {
                    amount = expense.monto > 0 ? 0 : Math.abs(expense.monto);

                    let category = expenses.categories !== null ? expense.categories.replace("'", "") : ''; // Fix for categories with special char
                    period = currentMonth ? expenses.currentPeriod : expenses.previousPeriod;
                    period.total[filterType] = (period.total[filterType] || 0) + amount;

                    if (includesCategory(category)){
                        set(period, `byCategory.${filterType}.${category}`, amount);
                    } else { // Fallback for inverted category with subcategory
                        category = expense.subCategories !== null ? expense.subCategories.replace("'", "") : ''; // Fix for categories with special char
                        if (includesCategory(category)){
                            set(period, `byCategory.${filterType}.${category}`, amount);
                        }
                    }
                }
            } else {
                if (expense.categories !== 'Ingresos') {
                    amount = expense.monto > 0 ? 0 : Math.abs(expense.monto);
                    let category = expenses.categories !== null ? expense.categories.replace("'", "") : '';
                    period = expenses.currentPeriod;
                    const currentMonth = (moment(expense.fecha) < firstDayOfCurrentMonth) ? false : true;
                    period.totalCustomDates[filterType] = (period.totalCustomDates[filterType] || 0) + amount;
                    if (currentMonth) {
                        period.total[filterType] = (period.total[filterType] || 0) + amount;
                    }
                    if (includesCategory(category)){
                        // set(expenses.currentPeriod, `byCategory.${filterType}.${category}`, amount + get(period, `byCategory.${filterType}.${category}`, amount));
                        const lastAmount = get(expenses.currentPeriod, `byCategory.${filterType}.${category}`);
                        set(expenses.currentPeriod, `byCategory.${filterType}.${category}`, lastAmount !== undefined ? lastAmount + amount : amount);
                    } else { // Fallback for inverted category with subcategory
                            category = expense.subCategories !== null ? expense.subCategories.replace("'", "") : ''; // Fix for categories with special char
                            if (includesCategory(category)){
                                const lastAmount = get(expenses.currentPeriod, `byCategory.${filterType}.${category}`);
                                set(expenses.currentPeriod, `byCategory.${filterType}.${category}`, lastAmount !== undefined ? lastAmount + amount : amount);
                            }
                        }
                }
            }

        });

    incomes.currentPeriod.total[filterType] = incomes.currentPeriod.total[filterType] || 0;

    expenses.currentPeriod.total[filterType] = expenses.currentPeriod.total[filterType] || 0;
    expenses.previousPeriod.total[filterType] = expenses.previousPeriod.total[filterType] || 0;

    onlyExpensesCategoriesList.forEach(category => {
        let categoryValue = get(expenses.currentPeriod, `byCategory.${filterType}.${category}`, 0);
        set(expenses.currentPeriod, `byCategory.${filterType}.${category}`, categoryValue);

        categoryValue = get(expenses.previousPeriod, `byCategory.${filterType}.${category}`, 0);
        set(expenses.previousPeriod, `byCategory.${filterType}.${category}`, categoryValue);
    })

    const newState = state.mergeDeep({
        expenses,
        incomes,
        isFetching: false,
        fetched: {
            [filterType]: true
        }
    })

    return sortCategories(newState);
}

function expenses(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_CATEGORIES_EXPENSES_RESET:
            return setInitialState();
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST_SUCCESS:
            return updateExpenses(state, action.payload.expensesLists, action.payload.customDate);
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST_ERROR:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS:
            const newState = state.merge({
                filters: action.payload.filters
            });
            return sortCategories(newState);
        case actionTypes.GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS_BY_CATEGORY:
            return state.merge({
                isPartialFetching: true,
                filtersByCategory: action.payload.filtersByCategory
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_DETAIL_REQUEST:
            return state.merge({
                isPartialFetching: true
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_DETAIL_SUCCESS:
            return state.merge({
                isPartialFetching: false,
                expensesByCategory: action.payload.expensesByCategory
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE:
            return state.merge({
                isPartialFetching: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = expenses;
