module.exports = {
    title: 'contractsView-upgradeToPayrollAccountStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    disableNext: 'checkingPayrollAccount_disableFirstStepButton',
	on_next_click: 'checkingPayrollAccount_loadTermsAndConditionsLegalWindow',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info',
            cms_key: 'upgradeToPayrollAccount'
        },
        ...(
            __CONTACT_CENTER__ ?
            [{
                id_component: 'legal-information'
            }] : []
        )
    ]
};
