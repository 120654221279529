// @ helpers
const apiURLBuilder = require('core/apiURLBuilder');
//@utilities
const BrowserUtils = require('utilities/browserUtils');
const { DOCUMENT_PRINT_WINDOWS_PARAMS, FIVE_HUNDRED_TWELVE } = require('constants/index');

const {
    BROKER_MIFID_DEFAULT_PDF_AMOUNT_DECIMALS
} = require('constants/index');

function getUrlDocumentByEncodedId(docId) {
    return apiURLBuilder.getURL('morningstarDocumentByEncodeId', { encodedId: docId });
}

const getRounder = decimals => {
    return Math.pow(10, decimals);
}

const getConfirmationPDFSharesAmount = ({
        isLimitedOrder,
        isBuyForAmountOrder,
        amountInput = 0,
        sharesNumber = 0,
        lastPrice = 0,
        decimals = BROKER_MIFID_DEFAULT_PDF_AMOUNT_DECIMALS
}) => {
    if(isLimitedOrder) {
        return '';
    }
    const rounder = getRounder(decimals);
    const amount = isBuyForAmountOrder ? amountInput : sharesNumber * lastPrice;
    const roundedAmount = parseFloat(Math.round(amount * rounder) / rounder).toFixed(decimals);
    return roundedAmount;
}

const base64ToBlob = (base64, type = 'application/octet-stream') => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
}
const openBase64pdfInNewTab = (base64) => {
    const blob = base64ToBlob(base64, 'application/pdf');
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
}

const openBase64pdfInPrintWindow = (document, openNewTab = false) => {

    const byteCharacters = atob(document.toString().split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += FIVE_HUNDRED_TWELVE) {
        const slice = byteCharacters.slice(offset, offset + FIVE_HUNDRED_TWELVE)
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blobObject = new Blob(byteArrays, { type: 'application/pdf' });
    
    if (BrowserUtils.browserIsIE()) {
        window.navigator.msSaveOrOpenBlob(blobObject);
    } else {
        const blobURL = window.URL.createObjectURL(blobObject);
        const winparams = DOCUMENT_PRINT_WINDOWS_PARAMS;
        const printWindow = openNewTab ? window.open(blobURL,'_blank') : window.open(blobURL, '', winparams);

        if (printWindow && !openNewTab) {
            printWindow.print();
        }
    } 
}

module.exports = {
    getConfirmationPDFSharesAmount,
    getUrlDocumentByEncodedId,
    openBase64pdfInNewTab,
    openBase64pdfInPrintWindow
}

