
const brokerWarrantsTags = (eventTag) => (
    {
        comercialCardsLink: {
            categoriaEvento: 'warrantHomeInteraction',
            accionEvento: 'ComercialCard',
            etiquetaEvento: 'more-info'
        },
        buyButtonComCard: {
            categoriaEvento: 'warrantHomeInteraction',
            accionEvento: 'comercialCard',
            etiquetaEvento: 'buy'
        },
        searchButton: {
            categoriaEvento: 'buscador/simple',
            accionEvento: 'inicio',
            etiquetaEvento: 'warrants'
        },
        buyButton: {
            categoriaEvento: 'warrantHomeInteraction',
            accionEvento: 'buy',
            etiquetaEvento: 'warrants'
        },
        sliderProducts: {
            categoriaEvento: 'warrantHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'products'
        },
        sliderContracts: {
            categoriaEvento: 'warrantHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'contracts'
        },
        searchButtonOnProduct: {
            categoriaEvento: 'warrantDescriptionInteraction',
            accionEvento: 'searcher',
            etiquetaEvento: 'click'
        },
        openTraderButton: {
            categoriaEvento: 'warrantDescriptionInteraction',
            accionEvento: 'opentrader',
            etiquetaEvento: 'click'
        },
        buttonBuy: {
            categoriaEvento: 'warrantDescriptionInteraction',
            accionEvento: 'buy',
            etiquetaEvento: eventTag
        },
        tabs: {
            categoriaEvento: 'warrantDescriptionInteraction',
            accionEvento: 'evolution',
            etiquetaEvento: eventTag
        }
    }
)

module.exports = { brokerWarrantsTags }