// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');

// @constants
const actionTypes = require('constants/actionTypes');
const { 
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
    ACCOUNT_INTERVENTION_OWNER_CODE }
= require('constants/index');

const initialState = Immutable.fromJS({
    error: '',
    errorState: false,
    fetchSuccess: false,
    isDirty: false,
    isLoading: false,
    list: [],
    originalList: [],
});

function parseInterveners(interveners) {
    const parsedInterveners = interveners
        .filter(intervener => trim(intervener.tipoIntervencion) === ACCOUNT_INTERVENTION_OWNER_CODE)
        .map(intervener => (
            {
                currentIntervener: !!intervener.currentIntervener,
                identification: {
                    code: intervener.numeroPersona || -1,
                    type: trim(intervener.tipoPersona) || ''
                },
                interventionOrder: trim(intervener.ordenIntervencion) || '',
                interventionWay: trim(intervener.formaIntervencion) || '',
                name: trim(intervener.nombre) || '',
                originalTypeNumber: ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
                relationship: 2,
                type: trim(intervener.desIntervencion) || '',
                typeNumber: ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
            }
        )
    );

    return Immutable.fromJS(parsedInterveners);
}

function validateStep (state) {
    let hasGlobalError = false;

    const newList = state.get('list').map(intervener => {
        const hasError = intervener.get('relationship') < 0;

        if (hasError) {
            hasGlobalError = true;
        }

        return intervener.set('error', hasError);
    });

    return state.merge({
        errorState: hasGlobalError,
        list: newList,
    });
}

function hireOpenYoungInterevenersReducer(state = initialState, action = {type: null}) {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_INTERVENERS_GET_REQUEST:
            return state.merge({
                isLoading: true,
                fetchSuccess: false,
                error: false
            });
        case actionTypes.OPEN_YOUNG_INTERVENERS_GET_SUCCESS:
            return state.merge({
                error: false,
                fetchSuccess: true,
                isDirty: false,
                isLoading: false,
                list: parseInterveners(action.payload.intervenersList),
                originalList: parseInterveners(action.payload.intervenersList),
            });
        case actionTypes.OPEN_YOUNG_INTERVENERS_GET_FAILURE:
            return state.merge({
                isLoading: false,
                error: action.payload.error
            });
        case actionTypes.OPEN_YOUNG_INTERVENERS_EXCLUDE_INTERVENER:
            return state.merge({
                list: state.get('list').delete(action.payload.index),
                isDirty: true
            });
        case actionTypes.OPEN_YOUNG_INTERVENERS_UPDATE_RELATIONSHIP:
            const { index, relationship } = action.payload;
            const updatedList = state.get('list').update(
                index,
                intervener => intervener.withMutations(intervener => {
                    intervener.set('relationship', relationship);
                    intervener.set('error', false);
                })
            );

            return state.merge({
                list: updatedList,
                isDirty: true
            });
        case actionTypes.OPEN_YOUNG_INTERVENERS_RESTORE_DEFAULT_INTERVENERS:
            return state.merge({
                list: state.get('originalList'),
                isDirty: false,
            });
        case actionTypes.OPEN_YOUNG_INTERVENERS_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.OPEN_YOUNG_INTERVENERS_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = hireOpenYoungInterevenersReducer;
