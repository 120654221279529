const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP } = require('constants/index');
const get = require('lodash/object/get');
const cardChargeStep1 = require('./cardCharge/cardChargeStep1');

function setInitialState() {
    return Immutable.fromJS({
        allAccountsInactive: false,
        steps: [
            cardChargeStep1(undefined, {
                type: null
            }),
            {
                hasSignaturePattern: true
            }
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        blockFlow: false,
        willLeave: false,
        willCancel: false,
        error: '',
        extra: {},
        view: ''
    });
}

function updateStep(index, steps, action, view) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardChargeStep1(step, action, view);
        }
    })
}

function cardChargeReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_CHARGE_VALIDATE_UI_STEP:
        let partialSteps;
        let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action, state.get('view'));
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;

                    return state.mergeDeep({
                        steps: partialSteps,
                        visibleStep
                    });
           }
            return state;
        case actionTypes.CARD_CHARGE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_CHARGE_SET_CARD:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action, state.get('view'))
            })
        case actionTypes.CARD_CHARGE_AMOUNT:
        case actionTypes.CARD_CHARGE_ACCOUNT:
        case actionTypes.CARD_WITHDRAW_TOTAL_AMOUNT:
        case actionTypes.CARD_CHARGE_OPERATION_TYPE:
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_TYPE:
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_DATE:
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_COUNT:
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_RANGE:
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_CONCEPT:
        case actionTypes.CARD_CHARGE_DATE:
        case actionTypes.CARD_CHARGE_EDIT_STEP1:
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_ENABLED:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action, state.get('view')),
                inProgress: true
            })
        case actionTypes.ECARD_INFORMATION_REQUEST:
        case actionTypes.CARD_CHARGE_VALIDATION_REQUEST:
        case actionTypes.CARD_CHARGE_CONFIRMATION_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.CARD_CHARGE_VALIDATION_SUCCESS:
            const liquidationData = action.payload.liquidationData;
            return state.mergeDeep({
                isFetching: false,
                extra: {
                    liquidationData : {
                        conceptoLiq: liquidationData.conceptoLiq,
                        estandarDeReferencia: {
                            codigodeestandar: get(liquidationData, 'estandarDeReferencia.codigodeestandar'),
                            subtipodeproducto: {
                                subtipodeproducto: get(liquidationData, 'estandarDeReferencia.subtipodeproducto.subtipodeproducto')
                            }
                        },
                        importeCobro: {
                            divisa: get(liquidationData, 'importeCobro.divisa'),
                            importe: get(liquidationData, 'importeCobro.importe', 0) || 0
                        },
                        importeComision: {
                            divisa: get(liquidationData, 'importeComision.divisa'),
                            importe: get(liquidationData, 'importeComision.importe', 0) || 0
                        },
                        importeEstandar: {
                            divisa: get(liquidationData, 'importeEstandar.divisa'),
                            importe: get(liquidationData, 'importeEstandar.importe', 0) || 0
                        },
                        importeOperacion: {
                            divisa: get(liquidationData, 'importeOperacion.divisa'),
                            importe: get(liquidationData, 'importeOperacion.importe', 0) || 0
                        },
                        importeTeorico: {
                            divisa: get(liquidationData, 'importeTeorico.divisa'),
                            importe: get(liquidationData, 'importeTeorico.importe', 0) || 0
                        },
                        importeTotalOperacion: {
                            divisa: get(liquidationData, 'importeTotalOperacion.divisa'),
                            importe: get(liquidationData, 'importeTotalOperacion.importe', 0) || 0
                        },
                        indTipoCobro: liquidationData.indTipoCobro,
                        saldoActualPrepago: {
                            divisa: get(liquidationData, 'saldoActualPrepago.divisa'),
                            importe: get(liquidationData, 'saldoActualPrepago.importe', 0)
                        }
                    }
                }
            })
        case actionTypes.ECARD_INFORMATION_SUCCESS:
            return state.merge({
                isFetching: false
            })
        case actionTypes.CARD_CHARGE_VALIDATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            })
        case actionTypes.ECARD_INFORMATION_FAILURE:
        case actionTypes.CARD_CHARGE_CONFIRMATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
                blockFlow: true //this flag will lead to WizardError if confirmation failed or request of ecard information failed. Skip validation failure to be able to retry.
            })
        case actionTypes.CARD_CHARGE_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_CHARGE_CLEAR:
            return setInitialState();
        case actionTypes.CARD_CHARGE_CONFIRMATION_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                signatureKeyFailure: false,
                errorReceived: '',
                inProgress: false
            })
        case actionTypes.CARD_CHARGE_VIEW:
            return state.merge({
                view: action.payload.view
            })
        case actionTypes.CARD_CHARGE_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        default:
            return state
    }
}

module.exports = cardChargeReducer;
