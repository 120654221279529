// @vendors
const Immutable = require('immutable');

// @actions
const brokerWarrantsBuyStep1 = require('./brokerWarrantsBuy/brokerWarrantsBuyStep1');
const brokerWarrantsBuyStep2 = require('./brokerWarrantsBuy/brokerWarrantsBuyStep2');

// @constants
const actionTypes = require('constants/actionTypes');
const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP, FIRST_STEP, SECOND_STEP } = require('constants/index');

const setInitialState = () => Immutable.fromJS({
    steps: [
        brokerWarrantsBuyStep1(undefined, {}),
        brokerWarrantsBuyStep2(undefined, {})
    ],
    visibleStep: WIZARD_FIRST_STEP,
    submitSuccessful: false,
    isFetching: false,
    inProgress: false,
    willCancel: false,
    fundamentalDataForInvestorUrl:'',
    WarrantsTransactionCostObjectId: '',
    WarrantsTransactionCostBase64Data: '',
    warrantsKIDBase64Data: '',
    dfiExists: false,
    exAnteCostsList: [],
});

const updateStep = (immState, step, action) => {
    switch (step) {
        case WIZARD_FIRST_STEP:
            const immFirstStep = immState.getIn(['steps', FIRST_STEP]);
            return immState.setIn(['steps', FIRST_STEP], brokerWarrantsBuyStep1(immFirstStep, action));
        case WIZARD_SECOND_STEP:
            const immSecondStep = immState.getIn(['steps', SECOND_STEP]);
            return immState.setIn(['steps', SECOND_STEP], brokerWarrantsBuyStep2(immSecondStep, action));
        default:
            return immState;
    }
};

const productBuyReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_BUY_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_WARRANTS_BUY_VALIDATE_REQUEST:
            return state.setIn(['steps', FIRST_STEP, 'loading'], true);
        case actionTypes.BROKER_WARRANTS_BUY_VALIDATE_SUCCESS:
            return state.set('confirmationData', action.payload.data)
                        .set('visibleStep', WIZARD_SECOND_STEP)
                        .setIn(['steps', FIRST_STEP, 'titleQuantity'], action.payload.data.titulosImporteAproximado)
                        .setIn(['steps', FIRST_STEP, 'loading'], false);
        case actionTypes.BROKER_WARRANTS_BUY_VALIDATE_FAILURE: // @TODO Handle error?
            return state.setIn(['steps', FIRST_STEP, 'loading'], false);
        case actionTypes.BROKER_WARRANTS_BUY_CONFIRM_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'loading'], true)
        case actionTypes.BROKER_WARRANTS_BUY_CONFIRM_FAILURE:
            return state.setIn(['steps', SECOND_STEP, 'loading'], false)
        case actionTypes.BROKER_WARRANTS_BUY_CONFIRM_SUCCESS:
            return state.set('submitSuccessful', true)
                        .setIn(['steps', SECOND_STEP, 'loading'], false)
                        .set('orderConfirmation', Immutable.Map({
                            associatedAccount: action.payload.data.datosCuentaAsociada.codIban.codbban,
                            accountBalance: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.importe,
                            accountCurrency: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.divisa,
                            orderNumber: action.payload.data.numeroOrden
                        }))
                        .set('checkedIsValid',false)
        case actionTypes.BROKER_WARRANTS_BUY_FINISH:
        case actionTypes.BROKER_WARRANTS_SUBSCRIPTION_CHANGE_READCHECKED:
        case actionTypes.BROKER_WARRANTS_SUBSCRIPTION_CHANGE_WARNING_OPERATION_CHECKED:
            return updateStep(state, WIZARD_SECOND_STEP, action);
        case actionTypes.BROKER_WARRANTS_BUY_DOCUMENTS_READ:
            return updateStep(state, WIZARD_SECOND_STEP, action);
        case actionTypes.BROKER_WARRANTS_BUY_SEND_EMAIL_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'sendingEmail'], true)
        case actionTypes.BROKER_WARRANTS_BUY_SEND_EMAIL_SUCCESS:
            return state.setIn(['steps', SECOND_STEP, 'sendingEmail'], false)
        case actionTypes.BROKER_WARRANTS_BUY_SEND_EMAIL_ERROR:
            return state.setIn(['steps', SECOND_STEP, 'sendingEmail'], false)
        case actionTypes.BROKER_WARRANTS_BUY_DFI_DOCUMENT_FETCH_SUCCESS:
            return state.set('fundamentalDataForInvestorUrl', action.payload.url);
        case actionTypes.BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_SUCCESS:
            return state.merge({
                WarrantsTransactionCostBase64Data: action.payload.response.content,
                WarrantsTransactionCostObjectId: action.payload.response.objectId,
                isFetching: false,
                isFetched: true
            });
        case actionTypes.BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH:
            return state.merge({ isFetched: false, isFetching: true });
        case actionTypes.BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_ERROR:
             return state.merge({ isFetched: false, isFetching: false });
        case actionTypes.BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_REQUEST:
            return state.merge({
                error: false,
                isFetching: true
            });
        case actionTypes.BROKER_WARRANTS_BUY_FETCHING_DFI_FAILED:
            return state.merge({ dfiExists: false });
        case actionTypes.BROKER_WARRANTS_BUY_FETCHING_DFI_SUCCESS:
            let warrantsKIDBase64Data = '';
            if (action.payload && action.payload.content) {
                warrantsKIDBase64Data = action.payload.content;
            }
            return state.merge({
                 dfiExists: true,
                 warrantsKIDBase64Data,
            });
        case actionTypes.BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_SUCCESS:
            return state.merge({
                mailSent: true,
                isFetching: false
            });
        case actionTypes.BROKER_WARRANTS_BUY_LEGAL_MAIL_SENT_FAILURE:
            return state.merge({
                error: true,
                mailSent: false,
                isFetching: false
            });
        case actionTypes.BROKER_WARRANTS_BUY_LEGAL_MAIL_RESET:
            return state.merge({
                error: false,
                isFetching: false,
                mailSent: false
            });
        default:
            return state;
    }
};

const setVisibleStep = (immState, step) => {
    switch (step) {
        case WIZARD_FIRST_STEP:
            return immState.set('visibleStep', step).setIn(['steps', FIRST_STEP, 'valid'], false);
        case WIZARD_SECOND_STEP:
            return immState.set('visibleStep', step).setIn(['steps', SECOND_STEP, 'valid'], false);
        default:
            return immState;
    }
};

function brokerWarrantsBuyReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_BUY_RESET:
            return setInitialState();
        case actionTypes.BROKER_WARRANTS_BUY_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case actionTypes.BROKER_WARRANTS_BUY_VALIDATE_STEP:
            return updateStep(state, action.payload.step, action);
        case actionTypes.BROKER_WARRANTS_BUY_WILL_CANCEL:
            return state.set('willCancel', action.payload.value);
        case actionTypes.BROKER_WARRANTS_BUY_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_WARRANTS_BUY_BUYFOR_OPTION_CHANGED:
        case actionTypes.BROKER_WARRANTS_BUY_BUYFOR_VALUE_CHANGED:
        case actionTypes.BROKER_WARRANTS_BUY_BUYFOR_VALUE_RESET:
        case actionTypes.BROKER_WARRANTS_BUY_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_WARRANTS_BUY_LIMIT_PRICE_CHANGED:
            return updateStep(state, WIZARD_FIRST_STEP, action);
        case actionTypes.BROKER_WARRANT_EXANTCOST_ERROR:
            return state.merge({
                exAnteDataFetching: false,
                exAnteDataError: true
            })
        case actionTypes.BROKER_WARRANT_EXANTCOST_FECHING:
            return state.merge({
                exAnteDataFetching: true,
            })
        case actionTypes.BROKER_WARRANT_EXANTCOST_SUCESS:
            return state.merge({
                exAnteCostsList: action.payload.response,
                exAnteDataFetching: false,
                exAnteDataError: false,
            })
        default:
            return productBuyReducer(state, action);
    }
}

module.exports = brokerWarrantsBuyReducer;

