// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, CARD_TRANSFER_MINIMUM_AMOUNT } = require('constants/index');

function setInitialState() {
    return Immutable.Map({
        valid: false,
        fromAccountIsDirty: false,
        amountIsDirty: false,
        amount: 0,
        fromAccount: '',
        fromAccountBalance: 0,
        currency: EURO_TEXT,
        totalAvailableBalance: 0,
        commission: 0
    });
}

function validateStep(step) {
    const validAmount = CARD_TRANSFER_MINIMUM_AMOUNT <= step.get('amount') && step.get('amount') <= step.get('totalAvailableBalance') && step.get('amount') % 100 === 0;

    return step.merge({
        valid: validAmount,
        fromAccountIsDirty: true,
        amountIsDirty: true
    });
}

function cardTransferMoneyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_TRANSFER_MONEY_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_TRANSFER_MONEY_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountIsDirty: true,
                commission: action.payload.commission
            })
        case actionTypes.CARD_TRANSFER_MONEY_AVAILABLE_BALANCE:
            return state.merge({
                totalAvailableBalance: action.payload.totalAvailableBalance
            });
        default:
            return state;
    }
}

module.exports = cardTransferMoneyStep1;
