const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        currentAccountId: '',
        newAccountId: '',
        newAccountIsDirty: false
    })
}

function validateStep(state) {
    const newAccountId = state.get('newAccountId');
    return state.merge({
        valid: !!newAccountId,
        newAccountIsDirty: true
    });
}

function modifyCardAccountStep1Reducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.MODIFY_CARD_ACCOUNT_RESET:
            return setInitialState();
        case actionTypes.MODIFY_CARD_ACCOUNT_SET_CURRENT_ACCOUNT:
             return state.merge({
                 currentAccountId: action.payload.currentAccountId
             });
        case actionTypes.MODIFY_CARD_ACCOUNT_SET_NEW_ACCOUNT:
            return state.merge({
                newAccountId: action.payload.newAccountId
            });
        case actionTypes.MODIFY_CARD_ACCOUNT_VALIDATE_STEP:
            return validateStep(state);
        default:
            return state
    }
}

module.exports = modifyCardAccountStep1Reducer;

