// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
const {
    BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS_SUCCESS,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS_SUCCESS,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_ADD_INTERVENER,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_REMOVE_INTERVENER,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET_INTERVENERS,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ASSOCIATED_ACCOUNT,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ACCEPT_MANIFEST,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_UPDATE_INTERVENERS_API_SUCCESS,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_VALIDATE_STEP,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_SET_VISIBLE_STEP,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_FINISH,
    BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    steps: [
        {
            selectedAssociatedContract: null,
            interveners: [],
            valid: false
        },
        {
            acceptedManifest: false,
            valid: false
        }
    ],
    contracts: [
        {
            accountType: '',
            contractNumber: '',
            product: '',
            balance: '',
            currency: ''
        }
    ],
    /**
     * Map of interveners where the key is the contract number
     */
    interveners: {},
    fetchingContracts: true,
    fetchingInterveners: false,
    finished: false,
    visibleStep: FIRST_STEP + 1,
    /**
     * Response from POST /procesos-contratacion/contratacion/derivados/bdp endpoint
     */
    updatedIntervenersAPIResponse: {}
});

/**
 *
 * @param {Object|null} response From API
 */
function buildContracts(response) {
    return get(response, 'icollContratos.contrato', []).map(contract => {
        return {
            accountType: get(contract, 'tipoCuenta', '').trim(),
            contractNumber: get(contract, 'contratoUnificadoPart.numerodecontrato'),
            product: get(contract, 'contratoUnificadoPart.producto'),
            balance: contract.importec,
            currency: contract.divisac
        };
    });
}

/**
 *
 * @param {Object|null} response From API
 */
function buildInterveners(response) {
    return get(response, 'intervinientes.interviniente').map(intervener => {
        return {
            name: get(intervener, 'nombre', '').trim(),
            type: get(intervener, 'desIntervencion', '').trim(),
            personType: get(intervener, 'tipoPersona', '').trim(),
            personNumber: get(intervener, 'numPersona')
        };
    });
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.Map|undefined}
 */
function getSelectedContractByOptionIndex(immState) {
    if (!immState) return undefined;
    const selectedAssociatedContract = immState.getIn(['steps', FIRST_STEP, 'selectedAssociatedContract']);
    return immState.getIn(['contracts', selectedAssociatedContract]);
}

/**
 *
 * @param {Immutable.Map} immState
 * @param {Immutable.Map} immContract
 * @returns {Immutable.List|undefined}
 */
function getIntervenersByContract(immState, immContract) {
    if (!immState || !immContract) return undefined;
    return immState.getIn(['interveners', immContract.get('contractNumber')]);
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.List|undefined}
 */
function getIntervenersOfSelectedContract(immState) {
    const immSelectedContract = getSelectedContractByOptionIndex(immState);
    return getIntervenersByContract(immState, immSelectedContract);
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.List|undefined}
 */
function getNotSelectedInterveners(immState) {
    if (!immState) return undefined;

    const immSelectedContract = getSelectedContractByOptionIndex(immState);
    const immFirstStepInterveners = immState.getIn(['steps', FIRST_STEP, 'interveners']);

    return getIntervenersByContract(immState, immSelectedContract).filterNot(
        immIntervener => immFirstStepInterveners.contains(immIntervener)
    );
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.Map}
 */
function addIntervener(immState) {
    const immInterveners = immState.get('interveners');
    const immFirstStepInterveners = immState.getIn(['steps', FIRST_STEP, 'interveners']);

    if (immInterveners.size === immFirstStepInterveners.size) {
        return immState;
    }

    const immNotSelectedInterveners = getNotSelectedInterveners(immState);

    if (!immNotSelectedInterveners) {
        return immState;
    }

    return immState.setIn(
        ['steps', FIRST_STEP, 'interveners'],
        immFirstStepInterveners.push(immNotSelectedInterveners.first())
    );
}

/**
 *
 * @param {Immutable.Map} immState
 * @param {number} index Which index of the option list to remove
 * @returns {Immutable.Map}
 */
function removeIntervener(immState, index) {
    // At least we need one intervener
    if (immState.getIn(['steps', FIRST_STEP, 'interveners']).size <= 1) {
        return immState;
    }
    return immState.deleteIn(['steps', FIRST_STEP, 'interveners', index]);
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.Map}
 */
function validateFirstStep(immState) {
    const selectedAssociatedContract = immState.getIn(['steps', FIRST_STEP, 'selectedAssociatedContract']);
    const immFirstStepInterveners = immState.getIn(['steps', FIRST_STEP, 'interveners']);
    return immState.setIn(
        ['steps', FIRST_STEP, 'valid'],
        selectedAssociatedContract !== null && immFirstStepInterveners.size > 0
    );
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.Map}
 */
function validateSecondStep(immState) {
    const acceptedManifest = immState.getIn(['steps', SECOND_STEP, 'acceptedManifest']);
    return immState.setIn(['steps', SECOND_STEP, 'valid'], acceptedManifest);
}

/**
 *
 * @param {Immutable.Map} immState
 * @returns {Immutable.Map}
 */
function validateCurrentStep(immState) {
    switch (immState.get('visibleStep') - 1) {
        case FIRST_STEP:
            return validateFirstStep(immState);
        case SECOND_STEP:
            return validateSecondStep(immState);
        default:
            return immState;
    }
}

function brokerHireDerivativesAccountReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET:
            return INITIAL_STATE;
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS:
            return state.merge({
                fetchingContracts: true,
                contracts: []
            });
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_ASSOCIATED_ACCOUNTS_SUCCESS:
            return state.merge({
                fetchingContracts: false,
                contracts: Immutable.fromJS(buildContracts(action.payload.response))
            });
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS:
            return state.set('fetchingInterveners', true);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_FETCH_INTERVENERS_SUCCESS:
            const immInterveners = Immutable.fromJS(buildInterveners(action.payload.response));
            return state.setIn(['interveners', action.payload.contractNumber], immInterveners)
                        .setIn(['steps', FIRST_STEP, 'interveners'], immInterveners)
                        .set('fetchingInterveners', false);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_ADD_INTERVENER:
            return addIntervener(state);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_REMOVE_INTERVENER:
            return removeIntervener(state, action.payload.index);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_RESET_INTERVENERS:
            return state.setIn(
                ['steps', FIRST_STEP, 'interveners'],
                getIntervenersByContract(
                    state,
                    getSelectedContractByOptionIndex(state)
                )
            );
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ASSOCIATED_ACCOUNT:
            return state.setIn(['steps', FIRST_STEP, 'selectedAssociatedContract'], action.payload.value)
                        .setIn(['steps', FIRST_STEP, 'interveners'], getIntervenersOfSelectedContract(state));
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_CHANGE_ACCEPT_MANIFEST:
            const manifest = state.getIn(['steps', SECOND_STEP, 'acceptedManifest']);
            return state.setIn(['steps', SECOND_STEP, 'acceptedManifest'], !manifest);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_UPDATE_INTERVENERS_API_SUCCESS:
            return state.set('updatedIntervenersAPIResponse', Immutable.fromJS(action.payload.response));
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_VALIDATE_STEP:
            return validateCurrentStep(state);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step)
                        .setIn(['steps', action.payload.step - 1, 'valid'], false);
        case BROKER_HIRE_DERIVATIVES_ACCOUNT_FINISH:
            return state.set('finished', true);
        default:
            return state;
    }
}

module.exports = brokerHireDerivativesAccountReducer;
