// @vendors
const Immutable = require('immutable');
const isString = require('lodash/lang/isString');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');
const { OKDISCOUNTS_EXTERNAL_CODE_PROMOS } = require('constants/okDiscounts');

// @utilities
const { getPersonTypeAndCodeHashed } = require('utilities/hash');

function setInitialState() {
    return Immutable.fromJS({
        errorList: [],
        errorReceived: '',
        userDiscountStatus: null,
        inProgress: false,
        submitSuccessful: false,
        error: false,
        email: '',
        errorPercentage: '',
        externalCode: '',
        externalCodeError: '',
        externalCodeFetching: false,
        okDiscountDetail: {
            isLoading: false,
            discountId: '',
            header: {
                title:
                    {
                        text: '',
                        color: ''
                    },
                icon:
                    {
                        url: '',
                        color: '',
                    },
                background:
                    {
                        url: ''
                    }
            },
            discount: {
                image: {
                    target_id: '',
                    alt: '',
                    title: '',
                    width: '',
                    height: '',
                    target_type: '',
                    target_uuid: '',
                    url: ''
                },
                promotionName: '',
                partnerService: '',
                hasExternalCode: false,
                title: '',
                validityDate: {
                    target_id: '',
                    alt: '',
                    title: '',
                    width: '',
                    height: '',
                    target_type: '',
                    target_uuid: '',
                    url: '',
                    text: ''
                },
                code: '',
                legals: {
                    text: '',
                    url: '',
                    icon: {
                        target_id: '',
                        alt: '',
                        title: '',
                        width: '',
                        height: '',
                        target_type: '',
                        target_uuid: '',
                        url: ''
                    }
                },
                externalWeb: {
                    text: '',
                    url: ''
                },
                requirements: [],
                categoryPath: ''
            }
        },
        percentage: 0,
        percentageBackgroundColor: '',
        percentageIsFetching: false,
        referralPromDiscountError: {},
        referralPromDiscountSuccess: {},
        referralPromDiscountFetching: {},
        referralPromIsFetching: false,
        referralPromIsError: false
    });
}

const getPercentageFromDetails = ({ categoryId, data, discountId }) => {
    let discountPercentageInfo = data.find(discountInfo => {
        const discountContent = get(discountInfo, 'content.discount_content', '');
        return !!discountContent && discountContent.discount_page_cms_id === discountId && discountContent.category.id === categoryId;
    });
    let response = {
        percentage: 0,
        backgroundColor: '#FBB800', // default color yellow
    };

    if (!!discountPercentageInfo) {
        discountPercentageInfo = get(discountPercentageInfo, 'content.discount_content', {});

        if (!!discountPercentageInfo.percentage_background) {
            response.backgroundColor = discountPercentageInfo.percentage_background;
        }
        if (!!discountPercentageInfo.percentage) {
            response.percentage = discountPercentageInfo.percentage;
        }
    }

    return response;
}

const formatBookingDiscountUri = discount => {
    const uri = get(discount, ['field_cta_link', 0, 'uri']);

    if (uri.toLowerCase().includes('booking')) {

        const personTypeAndCodeHashed = getPersonTypeAndCodeHashed();
        if (personTypeAndCodeHashed) {
            return `${uri}-${personTypeAndCodeHashed}`;
        } else {
            return '';
        }
    }

    return uri;
}

const getFieldDiscountsCategory = (categoryInfo = {}, categoryId) => {
    const categoryKey = categoryId || Object.keys(categoryInfo)[0];
    let response = categoryInfo;

    if (!isNaN(categoryKey) && !!categoryInfo[categoryKey]) {
        response = categoryInfo[categoryKey];
    }

    return response;
}

const formatDiscount = (discount, categoryId) => {
    const discountImage = get(discount, ['field_private_image', '0'], get(discount, ['field_image', '0'], ''));
    const validityDate = get(discount, ['field_validation_image', 0], {}) || {};
    const legalIcon = get(discount, ['field_legal_icon', 0], {}) || {};
    const requirements = discount.field_requirements.map(f => ({ body: f.field_body, title: f.field_title }));
    const fieldDiscountsCategory = getFieldDiscountsCategory(discount.field_discounts_category, categoryId);
    const partnerService = get(discount, ['field_partner_service', 0, 'value'], '') || '';

    let ctaColor = get(discount, ['field_private_colors', 0, 'value'], '') || get(discount, 'field_private_colors', '');

    // The prop could be an array and we need to check
    if (!isString(ctaColor)) {
        ctaColor = '';
    }

    return {
        isLoading: false,
        discountId: discount.field_cms_id,
        header: {
            title:
                {
                    text: fieldDiscountsCategory.name,
                    color: fieldDiscountsCategory.field_private_title_color,
                },
            icon:
                {
                    url: get(fieldDiscountsCategory, ['field_private_icon', '0'], ''),
                    color: discount.field_icon_color,
                },
            background:
                {
                    url: get(fieldDiscountsCategory, ['field_background', '0'], '')
                }
        },
        discount: {
            image: {
                target_id: discountImage.target_id,
                alt: discountImage.alt,
                title: discountImage.title,
                width: discountImage.width,
                height: discountImage.height,
                target_type: discountImage.target_type,
                target_uuid: discountImage.target_uuid,
                url: discountImage.url
            },
            promotionName: fieldDiscountsCategory.name,
            categoryPath: fieldDiscountsCategory.slug || '',
            title: discount.field_private_title,
            subTitle: discount.field_private_body,
            breadcrumbTitle: get(discount, ['field_partner', 'name']) || '',
            partnerService: partnerService,
            hasExternalCode: OKDISCOUNTS_EXTERNAL_CODE_PROMOS.includes(partnerService),
            largeDescription: discount.field_private_body,
            validityDate: {
                target_id: validityDate.target_id,
                alt: validityDate.alt,
                title: validityDate.title,
                width: validityDate.width,
                height: validityDate.height,
                target_type: validityDate.target_type,
                target_uuid: validityDate.target_uuid,
                url: validityDate.url,
                text: discount.field_validation_text || ''
            },
            code: {
                text: discount.field_discounts_code || '',
                url: get(discount, ['field_discounts_image', 0, 'url'], '') || ''
            },
            legals: {
                text: discount.field_legal_text || '',
                url: get(discount, ['field_legal_url', 0, 'uri'], '') || '',
                icon: {
                    target_id: legalIcon.target_id || '',
                    alt: legalIcon.alt || '',
                    title: legalIcon.title || '',
                    width: legalIcon.width || '',
                    height: legalIcon.height || '',
                    target_type: legalIcon.target_type || '',
                    target_uuid: legalIcon.target_uuid || '',
                    url: legalIcon.url || ''
                }
            },
            externalWeb: {
                url: get(discount, ['field_web_url', 0, 'uri'], ''),
                text: get(discount, ['field_web_text'], '')
            },
            requirementsTitle: discount.field_requirements_section_title,
            requirements,
            callToAction: {
                color: ctaColor,
                icon: get(discount, 'field_link_font_icon', ''),
                iconAlignment: get(discount, ['field_align_link_icon', 0, 'value'], 'left'),
                iconColor: get(discount, ['field_link_only_icon_color', 0, 'value'], 'light'),
                iconIsEnabled: get(discount, ['field_link_enable_icon', 0, 'value'], false),
                iconGhost: get(discount, ['field_link_ghost', 0, 'value'], false),
                inverseGhost: get(discount, ['field_link_ghost_color', 0, 'value'], '') === 'light',
                isOnlyIcon: get(discount, ['field_link_only_icon', 0, 'value'], false),
                openNewTab: get(discount, ['field_cta_link', 0, 'options', 'attributes', 'new_tab'], 0) === 1,
                text: get(discount, ['field_cta_link', 0, 'title']),
                url: formatBookingDiscountUri(discount)
            }
        }
    }
}

const getReferralPromDiscountFetching = (state, id) => {
    const loader = {};
    loader[id] = {
        isLoading: true
    }
    return state.merge({
        referralPromDiscountFetching: loader,
        referralPromIsFetching: true,
        referralPromIsSuccess: false
    });
}

const getReferralPromDiscountSuccess = (state) => {
    return state.mergeDeep({
        referralPromIsFetching: false,
        referralPromIsError: false,
    });
}

const getReferralPromDiscountError = (state, id) => {
    const error = {};
    error[id] = {
        isError: true
    }
    return state.mergeDeep({
        referralPromDiscountError: error,
        referralPromIsFetching: false,
        referralPromIsError: true,
        referralPromIsSuccess: false
    });
}

const okDiscounts = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_FAILURE:
            return state.merge({
                errorPercentage: action.payload.error,
                percentageIsFetching: false,
        });
        case actionTypes.OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_REQUEST:
            return state.merge({
                errorPercentage: '',
                percentageIsFetching: true,
            });
        case actionTypes.OKDISCOUNT_REQUEST_DETAILS_PERCENTAGE_SUCCESS:
            const { percentage, backgroundColor } = getPercentageFromDetails(action.payload);
            return state.merge({
                percentage,
                percentageBackgroundColor: backgroundColor,
                percentageIsFetching: false,
            });
        case actionTypes.OKDISCOUNT_REQUEST_FETCH_USER_STATUS:
            return state.merge({
                error: false,
                errorReceived: '',
                inProgress: true
            });
        case actionTypes.OKDISCOUNT_REQUEST_SET_SUBSCRIPTION:
            return state.merge({
                inProgress: true
            });
        case actionTypes.OKDISCOUNT_REQUEST_FETCH_USER_STATUS_FAILURE:
            return state.merge({
                inProgress: false,
                error: false,
                errorReceived: action.payload.error
            });
        case actionTypes.OKDISCOUNT_REQUEST_FETCH_USER_STATUS_SUCCESS:
            return state.merge({
                inProgress: false,
                error: false,
                userDiscountStatus: action.payload.okDiscount.userDiscountStatus,
                errorReceived: ''
            });
        case actionTypes.OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_FAILURE:
            return state.merge({
                inProgress: false,
                errorReceived: action.payload.error
            });
        case actionTypes.OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_ERROR:
            return state.merge({
                inProgress: false,
                error: true,
                errorList: action.payload.errorList
            });
        case actionTypes.OKDISCOUNT_REQUEST_SET_SUBSCRIPTION_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                inProgress: false,
                email: action.payload.email
            })
        case actionTypes.FETCH_OK_DISCOUNT_DETAIL:
            return state.merge({
                okDiscountDetail: {
                    isLoading: true
                }
            });
        case actionTypes.FETCH_OK_DISCOUNT_DETAIL_FAILURE:
            return state.mergeDeep({
                okDiscountDetail: {
                    isLoading: false
                },
                error: action.payload.error
            });
        case actionTypes.FETCH_OK_DISCOUNT_DETAIL_SUCCESS:
            const { categoryId, data: { discount } } = action.payload;
            return state.merge({
                okDiscountDetail: Immutable.fromJS(formatDiscount(discount, categoryId))
            });
        case actionTypes.OKDISCOUNT_REQUEST_EXTERNAL_CODE_IN_PROGRESS:
            return state.merge({
                externalCode: '',
                externalCodeError: '',
                externalCodeFetching: true
            });
        case actionTypes.OKDISCOUNT_REQUEST_EXTERNAL_CODE_SUCCESS:
            return state.merge({
                externalCode: action.payload.data,
                externalCodeFetching: false
            });
        case actionTypes.OKDISCOUNT_REQUEST_EXTERNAL_CODE_FAILURE:
            return state.merge({
                externalCodeError: action.payload.error,
                externalCodeFetching: false
            });
        case actionTypes.OKDISCOUNT_REFERRAL_PROMOTION_REQUEST:
            return getReferralPromDiscountFetching(state, action.payload.id);
        case actionTypes.OKDISCOUNT_REFERRAL_PROMOTION_SUCCESS:
            return getReferralPromDiscountSuccess(state);
        case actionTypes.OKDISCOUNT_REFERRAL_PROMOTION_FAILURE:
            return getReferralPromDiscountError(state, action.payload.id);
        default:
            return state;
    }
};

module.exports = okDiscounts;
