// @ vendors
const Immutable = require("immutable");

// @ constants
import actionTypes from "constants/actionTypes";
import { STATUS } from "../../constants/aggregator/aggregatorConsents.constants";

const initialState = {
    ownBusinessPurpouses: STATUS.PENDING,
    thirdPartiesPurpouses: STATUS.PENDING,
    isFetching: false,
    error: false,
    success: false,
    steps: [
        {
            title: "",
            order: 1,
            footer_text: "",
            position: "first",
            valid: "false",
            components: [{}],
        },
        {
            title: "",
            order: 2,
            footer_text: "",
            position: "last",
            valid: "false",
            components: [{}],
        },
    ],
    visibleStep: 1,
};

function setInitialState() {
    return Immutable.fromJS(initialState);
}

function aggregatorChangeConsents(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.AGGREGATOR_CONSENTS_WIZARD_NEXT_STEP: {
            return state.set("visibleStep", action.payload);
        }
        case actionTypes.AGGREGATOR_CONSENTS_WIZARD_PREVIOUS_STEP: {
            return state.set("visibleStep", action.payload);
        }
        case actionTypes.AGGREGATOR_CONSENTS_OWN_BUSINESS_SWITCH_CHANGED: {
            return state.merge({
                ownBusinessPurpouses: action.payload,
            });
        }
        case actionTypes.AGGREGATOR_CONSENTS_THIRD_PARTIES_BUSINESS_SWITCH_CHANGED: {
            return state.merge({
                thirdPartiesPurpouses: action.payload,
            });
        }
        case actionTypes.AGGREGATOR_CONSENTS_FETCH_IN_PROGRESS: {
            return state.merge({ isFetching: true, error: false });
        }
        case actionTypes.AGGREGATOR_CONSENTS_FETCH_ERROR: {
            return state.merge({
                error: true,
                isFetching: false,
                success: false,
            });
        }
        case actionTypes.AGGREGATOR_CONSENTS_FETCH_SUCCESS: {
            let ownBusinessPurpousesValue = STATUS.PENDING;
            let thirdPartiesPurpousesValue = STATUS.PENDING;
            const { consents } = action.payload;
            consents.forEach((consent) => {
                if (consent.consentCell.id === "01") {
                    ownBusinessPurpousesValue = consent.status;
                }
                if (consent.consentCell.id === "02") {
                    thirdPartiesPurpousesValue = consent.status;
                }
            });
            return state.merge({
                isFetching: false,
                error: false,
                success: false,
                ownBusinessPurpouses: ownBusinessPurpousesValue,
                thirdPartiesPurpouses: thirdPartiesPurpousesValue,
            });
        }
        case actionTypes.AGGREGATOR_CONSENTS_SUCCESS: {
            return state.merge({
                success: true,
                isFetching: false,
                error: false,
            });
        }
        case actionTypes.AGGREGATOR_CONSENTS_FETCH_CHANGE_CONSENTS: {
            return state.merge({ isFetching: true });
        }
        case actionTypes.AGGREGATOR_CONSENTS_WIZARD_RESET:{
            return setInitialState();
        }
        default: {
            return state;
        }
    }
}

module.exports = aggregatorChangeConsents;
