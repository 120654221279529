const get = require('lodash/object/get');
const REGEXROUTE = '\/myprofile\/accounts\/[0-9]+\/amazon-gift-check$';

const {
    SELECTED_RADIO_PROMOTIONS,
} = require('constants/accountAmazonGiftCard');

const isExpectedPath = (state) => {
    const pathname = get(state, 'routing.locationBeforeTransitions.pathname');
    const regex = new RegExp(REGEXROUTE);

    return regex.exec(pathname)
}

const getCurrentState = (state) => {
    return state.amazonGiftCards.get("selectedRadio") || SELECTED_RADIO_PROMOTIONS;
}


module.exports = {
    isExpectedPath,
    getCurrentState
}