/**
 * Created by adderlyjaureguicampos on 03/01/17.
 */
/**
 * Created by adderlyjaureguicampos on 27/12/16.
 */
module.exports = {
    title: 'Diseña tu depósito',
    order: 2,
    footer_text: '',
    position: 'middle',
    components:
        [
            {
                id_component: 'investment-fund-list',
                id: 'investmentFundList'
            }
        ]
};
