// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/actionTypes');
const { DATE_FILTER_NONE } = require('constants/index');
const {
    onlyOpenYoungExpensesCategoriesList
} = require('constants/categories');

const setInitialState = () => (
    Immutable.fromJS({
        getExpenseListFetch: false,
        expenses: {
            currentPeriod: {},
            previousPeriod: {},
            categoriesOrder: []
        },
        filters: {
            date: {
                isActive: false,
                selectedMonth: DATE_FILTER_NONE
            }
        },
        getExpenseListError: undefined
    })
);

const sortCategories = (expenses) => {
    const sortedCategories = onlyOpenYoungExpensesCategoriesList.sort((categoryA, categoryB) => {
        const currPeriodAmountCatA = Math.abs(get(expenses, `currentPeriod.${categoryA}.monto`,0));
        const currPeriodAmountCatB = Math.abs(get(expenses, `currentPeriod.${categoryB}.monto`,0))
        const prevPeriodAmountCatA = Math.abs(get(expenses, `previousPeriod.${categoryA}.monto`,0));
        const prevPeriodAmountCatB = Math.abs(get(expenses, `previousPeriod.${categoryB}.monto`,0));
        const sortOrder = currPeriodAmountCatB - currPeriodAmountCatA;
        return sortOrder === 0 ? (prevPeriodAmountCatB - prevPeriodAmountCatA) : sortOrder;
    });
    
    expenses['categoriesOrder'] = sortedCategories;
    return expenses;
}

const updateExpenses = (state, expenseList) => {
    let expenses = {
        currentPeriod: {},
        previousPeriod: {}
    };
    expenseList.forEach(element => {
        if(!onlyOpenYoungExpensesCategoriesList.includes(element.categories)) {
            return true;
        }

        const expenseMonth = moment(element.fecha).month();
        const currentMonth = moment().month();
        const previousMonthString = state.getIn(['filters','date','selectedMonth']);
        const previousMonth = previousMonthString !== DATE_FILTER_NONE ? moment(previousMonthString).month() : DATE_FILTER_NONE;
        
        element.monto = element.monto > 0 ? 0 : Math.abs(element.monto);
        if(expenseMonth === currentMonth) {
            expenses.currentPeriod[element.categories] = element;
        }else if(previousMonth === expenseMonth) {
            expenses.previousPeriod[element.categories] = element;
        }
    });

    return sortCategories(expenses);
}

const openYoungCardBudgets = (state = setInitialState(), action) => {
    switch(action.type) {
        case actionTypes.OPENYOUNG_CARD_GET_EXPENSE_LIST_REQUEST:
            return state.merge({
                getExpenseListFetch: true,
                getExpenseListError: undefined,
                expenses: {
                    currentPeriod: {},
                    previousPeriod: {}
                }
            });
        case actionTypes.OPENYOUNG_CARD_GET_EXPENSE_LIST_SUCCESS:
            return state.mergeDeep({
                getExpenseListFetch: false,
                getExpenseListError: undefined,
                expenses: updateExpenses(state, action.payload.expenseList)
            });
        case actionTypes.OPENYOUNG_CARD_GET_EXPENSE_LIST_ERROR:
            return state.merge(setInitialState(), {
                getExpenseListError: action.payload.error
            });
        case actionTypes.OPENYOUNG_CARD_GET_EXPENSE_LIST_APPLY_FILTER:
            const filters = action.payload.filters;
            const dateFilterSelected = get(filters, 'date.selectedMonth') !== DATE_FILTER_NONE;
            const partial = state.mergeDeep({
                filters
            });

            return partial.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected
                    }
                }
            });
        case actionTypes.OPENYOUNG_CARD_GET_EXPENSE_LIST_CLEAR_FILTER:
            const initialState = setInitialState();
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
        default:
            return state;
    }
}
module.exports = openYoungCardBudgets;
