const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    valid: true
});

function validateStep(state,valid) {
    return state.merge({
        valid: valid
    });
}

function hireCardMetallicStep3(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_METALLIC_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        default:
            return state;
    }
}

module.exports = hireCardMetallicStep3;
