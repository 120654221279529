const i18n = require('core/i18n').i18n;

const signaturePatternHelper = {
    createDigitsEnumeration(digits) {
        const andSeparator = i18n.formatText('commons-and');
        let digitsEnumeration = '';

        digits && digits.forEach((digit, index) => {
            const separator = index === (digits.length - 1) ? ` ${andSeparator} ` : ', ';

            if (index === 0) {
                digitsEnumeration = `${digit}`;
            } else {
                digitsEnumeration = `${digitsEnumeration}${separator}${digit}`;
            }
        });

        return digitsEnumeration;
    },
    getNumberOfPositionsAsString(numberOfPositions) {
        if (!numberOfPositions || numberOfPositions < 0) {
            return '';
        }

        const numberInLetters = i18n.formatText(`signaturePattern-${numberOfPositions}`);
        const position = i18n.formatText('signaturePattern-position');
        const positions = i18n.formatText('signaturePattern-positions');

        let numberOfPositionsAsString;

        if (numberOfPositions <= 8) {
            numberOfPositionsAsString = `${numberInLetters} ${numberOfPositions === 1 ? position : positions}`;
        } else {
            numberOfPositionsAsString = `${numberOfPositions} ${positions}`;
        }

        return numberOfPositionsAsString;
    },
    getStringFromVOandTrustedDevice(virtualOfficeFlag, immSignaturePatternVirtualOffices,  messages) {
        let translationString = '';
        const isVirtualOfficeSuccessAndValid = virtualOfficeFlag && immSignaturePatternVirtualOffices.get('virtualOfficeRequestSuccess') && immSignaturePatternVirtualOffices.get('isVirtualOfficesValid')
        const isVirtualOfficeSuccessAndNotValid = virtualOfficeFlag && immSignaturePatternVirtualOffices.get('virtualOfficeRequestSuccess') && !immSignaturePatternVirtualOffices.get('isVirtualOfficesValid')
        const isVirtualOfficeError = virtualOfficeFlag && immSignaturePatternVirtualOffices.get('virtualOfficeRequestError');

        if (!virtualOfficeFlag || isVirtualOfficeSuccessAndValid) {
            if (immSignaturePatternVirtualOffices.get('isDeviceTrusted') && immSignaturePatternVirtualOffices.get('trustedDeviceRequestSuccess')) {
                translationString = messages.push;
            } else if (!immSignaturePatternVirtualOffices.get('isDeviceTrusted') && immSignaturePatternVirtualOffices.get('trustedDeviceRequestError')) {
                translationString = messages.generic;
            } else {
                translationString = messages.sms;
            }
        } else if (isVirtualOfficeSuccessAndNotValid) {
            if (immSignaturePatternVirtualOffices.get('trustedDeviceRequestSuccess')) {
                translationString = messages.sms
            } else {
                translationString = messages.generic;
            }
        } else if (isVirtualOfficeError) {
            if (immSignaturePatternVirtualOffices.get('isDeviceTrusted') || immSignaturePatternVirtualOffices.get('trustedDeviceRequestError')) {
                translationString = messages.generic;
            } else {
                translationString = messages.sms;
            }
        } else {
            translationString = messages.sms;
        }
        return translationString;
    }
};

module.exports = signaturePatternHelper;
