// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
     return Immutable.Map().merge({
        submitSuccessful: false,
        isFetching: false,
        errorReceived: false
    });
}

const initialState = setInitialState();

function sendN2Password(state = initialState, action) {
   switch (action.type) {
        case actionTypes.SEND_N2_PASSWORD_SET_STEP_SUCCESS:
            return state.merge(initialState,{
                submitSuccessful: true
            });
        case actionTypes.SEND_N2_PASSWORD_SET_STEP_ERROR:
            return state.merge(initialState,{
                errorReceived: true
            });
       case actionTypes.RESET_SEND_N2_PASSWORD_STEP1:
           return state.merge(setInitialState());
        default:
            return state
    }
}

module.exports = sendN2Password;
