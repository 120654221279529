// @vendors
const Immutable = require('immutable');

// @helpers
const { getLanguage, getInitialStrings } = require('core/i18n').i18n;

// @actionTypes
const actionTypes = require('constants/actionTypes');

// @constants
const { TRANSLATION_PLATFORM_PRI, TRANSLATION_PLATFORM_CC } = require('constants/translations');


function setInitialState() {
    const language = getLanguage();

    return Immutable.fromJS({
        translations: getInitialStrings().get('translations'),
        [`error${TRANSLATION_PLATFORM_PRI}${language}`]: false,
        [`isFetching${TRANSLATION_PLATFORM_PRI}${language}`]: false,
        [`hasBeenFetched${TRANSLATION_PLATFORM_PRI}${language}`]: false,
        [`error${TRANSLATION_PLATFORM_CC}${language}`]: false,
        [`isFetching${TRANSLATION_PLATFORM_CC}${language}`]: false,
        [`hasBeenFetched${TRANSLATION_PLATFORM_CC}${language}`]: false
    });
}

const reducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.TRANSLATIONS_FETCH_REQUEST:
            return state.merge({
                [`isFetching${action.payload.platform}${action.payload.language}`]: true,
                [`error${action.payload.platform}${action.payload.language}`]: false
            });
        case actionTypes.TRANSLATIONS_REQUEST_SUCCESS:
            return state.mergeDeep({
                ['translations']: (action && action.payload && action.payload.translations) || {},
                [`isFetching${action.payload.platform}${action.payload.language}`]: false,
                [`error${action.payload.platform}${action.payload.language}`]: false,
                [`hasBeenFetched${action.payload.platform}${action.payload.language}`]: true,
            });
        case actionTypes.TRANSLATIONS_REQUEST_FAILURE:
            return state.merge({
                [`isFetching${action.platform}${action.language}`]: false,
                [`error${action.platform}${action.language}`]: true,
                [`hasBeenFetched${action.platform}${action.language}`]: true,
                [`errorReason${action.platform}${action.language}`]: action.errors
            });
        default:
            return state;
    }
};

export default reducer;
