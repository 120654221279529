const { GET_TOPBAR_IN_PROGRESS, GET_TOPBAR_SUCCESS } = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    success: false,
    isFetching: false,
    topbarData: {}
});

const topbar = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOPBAR_IN_PROGRESS:
            return state.merge({
                isFetching: true,
                success: false,
            });
        case GET_TOPBAR_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                topbarData: action.payload.response.topbarData
            });
        default:
            return state;
    }
}

module.exports = topbar;
