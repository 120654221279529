const signaturePatternTags = (eventTag, eventAction, errorTag = {}) => ({
    inicio: {
        categoriaEvento: "proc-otp",
        accionEvento: "inicio",
        etiquetaEvento: eventTag,
        amount: eventAction,
        action: "start",
        format: "otpAuthentication",
        component: "OTP/SMS",
        element: "backend"
    },
    error: {
        categoriaEvento: "proc-otp",
        accionEvento: "error",
        etiquetaEvento: eventTag,
        amount: eventAction,
        ...(errorTag ? errorTag : {}),
    },
    exito: {
        categoriaEvento: "proc-otp",
        accionEvento: "exito",
        etiquetaEvento: eventTag,
        amount: eventAction,
        action: "success",
        format: "otpAuthentication",
        component: "OTP/SMS",
        element: "backend"
    },
    reinicio: {
        categoriaEvento: "proc-otp",
        accionEvento: "reinicio",
        etiquetaEvento: eventTag,
        amount: eventAction
    }
});

module.exports = { signaturePatternTags };