import { createSelector } from 'reselect'


const getCards = state => {
    try {
        return state.cards.toJS();
    } catch(e) {
        return { byId: {} };
    }
};

const getCardId = (_, props) => props.cardId;

const getCard = createSelector(
    [getCards, getCardId],
    (cards, id) => cards.byId[id]
);

const getCardsMappedByNumber = (state) => {
    let cardsMap = {}
    state.cards.get('byId').forEach((v) => cardsMap[v.get('number')] = v)
    return cardsMap
}

const getCardsAccountPaymentPurchaseDeferral = (state) => state.accountPaymentPurchaseDeferralReducer.getIn(['steps', 0, 'cards'])

const getSelectedCardAccountPaymentPurchaseDeferral = (state) => state.accountPaymentPurchaseDeferralReducer.getIn(['steps', 0, 'selectedCard'])

const getCommercialAds = (state) => state.commercialAds;

const getCardCommercialAd = createSelector(
    [getCard, getCommercialAds],
    (card, commercialAds) => {
        const key = `B.${card.product.type}.${card.product.subtype}.${card.product.standard.slice(-3)}`;
        return commercialAds.toJS().byId[key];
    }
);

const decorateCards = (commercialAds, cardsByNumber, cards) => {
    const decorateCard = (card) => {
        const cardReducer = cardsByNumber[card.get('panTarjeta')]
        if (!cardReducer)
            return
        const typeCard = cardReducer.getIn(['contract','product']);
        const subTypeCard = cardReducer.getIn(['criteria','c1','subtipoproducto']);
        const standardCodeCard =  cardReducer.getIn(['criteria', 'c1', 'estand'], "").slice(-3);
        const commercialCardId = `B.${typeCard}.${subTypeCard}.${standardCodeCard}`
        const commercialCardInfo = commercialAds.getIn(['byId', commercialCardId]);
        const control = cardReducer.get('control')
        
        return cardReducer.mergeDeep(card, {commercialCardInfo, commercialCardId, control})
    }
    return cards.map(decorateCard).filter(c => !!c)
};

const getCardsForAccountPurchaseDeferral = createSelector(
    [ getCardsMappedByNumber, getCardsAccountPaymentPurchaseDeferral , getCommercialAds ],
    (cardsMap, purchaseDeferrabledCards, commercialAds) => {
        if (!purchaseDeferrabledCards) return null
        return decorateCards(commercialAds, cardsMap, purchaseDeferrabledCards)
    }
);

const getSelectedCardsForAccountPurchaseDeferral = createSelector(
    [ getSelectedCardAccountPaymentPurchaseDeferral, getCardsForAccountPurchaseDeferral ],
    (selectedCard, cards) => {
        return !!cards ? cards.find(card => card.get('cardId') === selectedCard) : null
    }
);


export {
    getCard,
    getCardCommercialAd,
    getCardsForAccountPurchaseDeferral,
    getSelectedCardsForAccountPurchaseDeferral
};
