const {
    DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_SUBTYPE,
    DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_TYPE,
} = require('constants/index');
const { DEPOSIT_13_MONTHS_RENEWAL_EXCEPTIONS_CONTRACT_INFO_CMS_KEY } = require('constants/hiringProductInformation');

module.exports = {
    title: 'contractsView-contractsView_depositThirteenMonthsRenovacionExcepcionesStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info',
            cms_key: DEPOSIT_13_MONTHS_RENEWAL_EXCEPTIONS_CONTRACT_INFO_CMS_KEY,
            services: [
                {
                    propName: 'requestReferenceStandard',
                    params: {
                        tipoProducto: DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_TYPE,
                        subtipoProducto: DEPOSIT_13_MONTHS_RENOVACION_EXCEPCION_PRODUCT_SUBTYPE
                    },
                    alreadyCalled: false
                }
            ]
        },
        {
            id_component: 'legal-information'
        }
    ]
};
