// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { COLON, KEY_VALUE_REGEX } = require('constants/index');
const VALUE_DATE_KEY = 'FECHA VALOR';
// @ helpers
const { toCamelCase } = require('utilities/stringHelper');

function mapFields(details) {
    const fields = details
        .filter(field => {
            // Remove all fields that doesn't look like pairs
            // Remove FECHA VALOR key. It's duplicated.
            return field && KEY_VALUE_REGEX.test(field) && field.indexOf(VALUE_DATE_KEY) === -1;
        })
        .map(field => {
            const [fieldLabel, fieldValue] = field.split(COLON);

            return {
                key: toCamelCase(fieldLabel.trim()),
                value: toCamelCase(fieldValue.trim())
            }
        })

    return fields;
}

const initialState = Immutable.fromJS({
    loading: false,
    error: false,
    hasBeenFetched: false,
    fields: []
});

function accountsTransactionDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DETAILS_REQUEST:
            return state.mergeDeep({
                loading: true,
                error: false
            });
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DETAILS_SUCCESS:
            return state.mergeDeep({
                loading: false,
                hasBeenFetched: true,
                fields: mapFields(action.payload.details)
            });
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DETAILS_FAILURE:
            return state.mergeDeep({
                loading: false,
                error: action.payload
            });
        default:
            return state
    }
}

module.exports = accountsTransactionDetailsReducer;
