import 'commonsComponents/main.scss';
import './createApp.scss';

// @vendor
import React, { Suspense } from 'react';
import { LoadingSection } from 'commonsComponents/loadingSection';

const { Provider } = require('react-redux');
const { Router } = require('react-router-dom');

// @core
const { initStoreHelper } = require('core/storeHelper');

// @routes
const Routes = React.lazy(() => import('./routes'));

// @store
const { store, history } = require('./store/configureStore');
const { webAppReducers: reducers } = require('reducers/index');

// @utilities
const { pushOptimize } = require('utilities/optimize')
const { loginCheck } = require('utilities/loginCheck.js');
const { securityLevelCheck } = require('utilities/securityLevelCheckHelper.js');

/**
 * If the mobile device has extra extra large resolutions and the display is
 * retina, the we are going to set the vieport initial zoom to 2 to increase
 * the contents size.
 */

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const isExtraWidth = window.innerWidth >= 1400;
const isRetina = window.devicePixelRatio === 2;

if (isMobile && isExtraWidth && isRetina) {
    let viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', 'width=768, initial-scale=2.0');
}

// Reference to the store to use it outide actions
initStoreHelper(store, reducers);

const App = () => {
    store.subscribe(() => pushOptimize());
    const routeChecker = (__CONTACT_CENTER__) ? securityLevelCheck : loginCheck;
    return(
        <Provider store={store}>
            <Router history={history}>
                <Suspense fallback={
                   <div className='create-app__spinner'><LoadingSection type="round" width={80} /></div> 
               }>
                    <Routes onEnterCallback={routeChecker} />
                </Suspense>
            </Router>
        </Provider>
    )
}

export default App;
