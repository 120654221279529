//@vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

//@helpers
const actionTypes = require('constants/actionTypes');

const { SEVEN_ALPHANUMERIC_CHARACTERS } = require('constants/index');
const { ACCOUNT_CANCEL_CHECKS_DETERIORATED } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        range: {
            first: 0,
            last: 0
        },
        number: '',
        amount: null,
        valid: false,
        isNumberValid: true,
        isNumberDirty: false,
        isAlreadyCancelled: true,
        apiValidationError: false,
        apiValidationInProgress: false,
        reason: ACCOUNT_CANCEL_CHECKS_DETERIORATED
    
    });
}

function validateNumber(state, number) {
    const first = state.getIn(['range', 'first']);
    const last = state.getIn(['range', 'last']);
    const parsedNumber = parseInt(number, 10);
    const isInRange = parsedNumber >= first && parsedNumber <= last;

    return SEVEN_ALPHANUMERIC_CHARACTERS.test(number) && isInRange;
}

function numberChanged(state, number) {
    let nextState = {};

    if (!state.get('isNumberDirty')) {
        nextState.isNumberDirty = true;
    }
    nextState.number = number;
    nextState.isNumberValid = validateNumber(state, number);
    nextState.isAlreadyCancelled = false;

    return state.merge(nextState);
}

function amountChanged(state, amount) {
    let nextState = {};

    nextState.amount = amount;

    return state.merge(nextState);
}

function validateStep(step) {
    const number = step.get('number');
    const reason = step.get('reason');
    const numberValid = validateNumber(step, number);
    const isAlreadyCancelled = step.get('isAlreadyCancelled');

    return step.merge({
        isNumberValid: numberValid,
        isNumberDirty: true,
        isDirty: true,
        isValidUI: numberValid && !!reason && !isAlreadyCancelled
    });
}

const isCancelled = (chequeNumber, response) => {
    const cheques = get(response, 'listaTalonariosAnomalos.talonariosAnomalos', []) || [];

    return cheques.some(cheque => cheque.numeroChequesInicialExtraviados === chequeNumber && cheque.numeroChequesFinalExtraviados === chequeNumber)
}

function accountCancelChequesStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.ACCOUNT_CANCEL_CHECKS_CHEQUE_CHANGED:
            return numberChanged(state, action.payload.number);
        case actionTypes.ACCOUNT_CANCEL_CHECKS_AMOUNT_CHANGED:
            return amountChanged(state, action.payload.amount);
        case actionTypes.ACCOUNT_CANCEL_CHECKS_REASON_CHANGED:
            return state.merge({
                reason: action.payload.value
            });
        case actionTypes.ACCOUNT_CANCEL_CHECKS_SETUP_FIRST_STEP:
            const { range, accountId, chequebookIndex } = action.payload.data;
            return state.merge({
                valid: false,
                accountId,
                chequebookIndex,
                range
            });
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_API_REQUEST:
            return state.merge({
                apiValidationInProgress: true
            });
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_API_FAILURE:
            //if validate cheque status fails, we assume is not cancelled
            return state.merge({
                apiValidationInProgress: false,
                valid: true,
                isAlreadyCancelled: false
            });
        case actionTypes.ACCOUNT_CANCEL_CHECKS_VALIDATE_API_SUCCESS:
            const { chequeNumber, response } = action.payload;
            const isAlreadyCancelled = isCancelled(chequeNumber, response);

            return state.merge({
                valid: !isAlreadyCancelled,
                apiValidationInProgress: false,
                isAlreadyCancelled
            });
        default:
            return state;
    }
}

module.exports = accountCancelChequesStep1;
