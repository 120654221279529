const personalInformationTab = require('./personalInformationTab');
const addresslInformationTab = require('./addressInformationTab');
const contactInformationTab = require('./contactInformationTab');
const workInformationTab = require('./workInformationTab');

module.exports = {
    title: 'Préstamo Personal Open con nómina y 3 recibos',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'tabbed-box',
            id: 'tabbed-box_1',
            exclude: [],
            //exclude: ['cmb_residencia_avalista','cmb_rel_titular_avalista','cmb_tipo_intervencion_avalista','cmb_forma_intervencion_avalista'],
            content: [
                personalInformationTab,
                addresslInformationTab,
                contactInformationTab,
                workInformationTab
            ]
        }
    ]
};
