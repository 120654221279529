// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const find = require('lodash/collection/find');

// @ utilities
const { getRevolvingPack } = require('utilities/APIParsingHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    FOURTH_STEP,
    FIFTH_STEP,
    SECOND_STEP,
    THIRD_STEP,
} = require('constants/index');

// @reducers
const hireCardRevolvingStep1 = require('./hireCardRevolving/hireCardRevolvingStep1');
const hireCardRevolvingStep2 = require('./hireCardRevolving/hireCardRevolvingStep2');
const hireCardRevolvingStep3 = require('./hireCardRevolving/hireCardRevolvingStep3');
const hireCardRevolvingStep4 = require('./hireCardRevolving/hireCardRevolvingStep4');
const hireCardRevolvingStep5 = require('./hireCardRevolving/hireCardRevolvingStep5');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    enabled: false,
    error: false,
    errorScoring: '',
    errorMsg: '',
    fetchSuccessScoring: false,
    hiringStatus: '',
    isFetching: false,
    isFetchingScoring: false,
    pack: 1,
    referenceStandard: '',
    steps: [
        hireCardRevolvingStep1(),
        hireCardRevolvingStep2(),
        hireCardRevolvingStep3(),
        hireCardRevolvingStep4(),
        hireCardRevolvingStep5()
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag:false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardRevolvingStep1(step, action);
            case SECOND_STEP:
                return hireCardRevolvingStep2(step, action);
            case THIRD_STEP:
                return hireCardRevolvingStep3(step, action);
            case FOURTH_STEP:
                return hireCardRevolvingStep4(step, action);
            case FIFTH_STEP:
                return hireCardRevolvingStep5(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function parseScoringResponse(state, response){
    let enabled = state.get('enabled');
    let pack = state.get('pack');
    let referenceStandard = state.get('referenceStandard');

    const revolvingProduct = find(get(response, 'productoActivoSegmentado', []),
        (product) =>
        get(product, 'indicador.id') === '08' && get(product, 'indicador.estado') === '1'
    );

    if (!!revolvingProduct) {
        enabled = true;
        pack = getRevolvingPack(get(revolvingProduct, 'informacion'));
        referenceStandard = get(revolvingProduct, 'informacion.standard');
    }

    return state.merge({
        enabled,
        fetchSuccessScoring: true,
        isFetchingScoring: false,
        pack,
        referenceStandard,
    });
}

function hireCardRevolvingReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_CARD_REVOLVING_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 4:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 5:
                    partialSteps = updateStep(FIFTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
            case actionTypes.HIRE_CARD_LOADING:
                return state.merge({
                    loadingFlag: action.payload.loadingFlag
                });
        case actionTypes.HIRE_CARD_REVOLVING_SET_ACCOUNT:
        case actionTypes.HIRE_CARD_REVOLVING_VALIDATE_ACCOUNT:
            return state.merge({
                steps: updateStep(THIRD_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_REVOLVING_SET_AGREEMENT_CHECKBOX:
            const stepToUpdate = __CONTACT_CENTER__ ? FIRST_STEP : FIFTH_STEP;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_REVOLVING_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_CARD_REVOLVING_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_CARD_REVOLVING_GET_SCORING_REQUEST:
            return state.merge({
                errorScoring: '',
                fetchSuccessScoring: false,
                isFetchingScoring: true,
            });

        case actionTypes.HIRE_CARD_REVOLVING_GET_SCORING_SUCCESS:
            return parseScoringResponse(state, action.payload.response);

        case actionTypes.HIRE_CARD_REVOLVING_GET_SCORING_FAILURE:
            return state.merge({
                errorScoring: action.payload.error,
                isFetchingScoring: false,
            });

        case actionTypes.HIRE_CARD_REVOLVING_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_CARD_REVOLVING_SUCCESS:
            return state.merge({
                hiringStatus: action.payload.hiringStatus,
                isFetching: false,
                submitSuccessful: true
            });

        case actionTypes.HIRE_CARD_REVOLVING_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                hiringStatus: action.payload.hiringStatus,
                isFetching: false
            });

        case actionTypes.HIRE_CARD_REVOLVING_RESET:
            return initialState;

        case actionTypes.HIRE_CARD_REVOLVING_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        default:
            return state;
    }
}

module.exports = hireCardRevolvingReducer;
