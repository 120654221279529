// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

// @ images
const defaultMaleAvatar = require('assets/images/avatar-male-default.svg');

const initialState = Immutable.fromJS({
    avatarError: '',
    childAvatarSuccess: false,
    childListError: '',
    childListSuccess: false,
    isFetchingAvatar: false,
    isFetchingChildList: false,
    openYoungChildList: {},
});

function addChildAvatars(state, response) {
    const immOpenYoungChildList = state.get('openYoungChildList');
    const updatedOpenYoungChildList = response.map((avatar, index) => {
        let immOpenYoungChild;
        if (!!avatar && !!avatar.imageBase64) {
            immOpenYoungChild = immOpenYoungChildList.setIn([index, 'image'], avatar.imageBase64).get(index);
        } else {
            immOpenYoungChild = immOpenYoungChildList.get(index);
        }
        return immOpenYoungChild;
    });

    return state.merge({
        childAvatarSuccess: true,
        isFetchingAvatar: false,
        openYoungChildList: updatedOpenYoungChildList,
    });
}

function parseContentResponse(state, response) {
    const openYoungChildList = response.map(child => ({
        account: !!child.accounts ? child.accounts[0] : {},
        card: !!child.cards ? child.cards[0] : {},
        childKey: child.childKey,
        childName: child.name,
        image: defaultMaleAvatar,
        isSelected: true,
    }));

    return state.merge({
        childListSuccess: true,
        isFetchingChildList: false,
        openYoungChildList,
    });
}

const openYoungDashboard = (state = initialState, action = { type: null }) => {
    switch (action.type) {
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_LIST_REQUEST:
            return state.merge({
                isFetchingChildList: true
            });
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_LIST_SUCCESS:
            return parseContentResponse(state, action.payload.openYoungChildList);
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_LIST_ERROR:
            return state.merge({
                childListError: action.payload.error,
                isFetchingChildList: false,
            });
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_AVATAR_REQUEST:
            return state.merge({
                isFetchingAvatar: true
            });
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_AVATAR_ERROR:
            return state.merge({
                avatarError: action.payload.error,
                isFetchingAvatar: false,
            });
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_AVATAR_SUCCESS:
            return addChildAvatars(state, action.payload.openYoungChildsAvatarList);
        default:
            return state;
    }
};

module.exports = openYoungDashboard;
