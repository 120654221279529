const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS
} = require('constants/index');

const fundsEtfsPlansFilters = (data) => {
    const dataFilters = data.filters[0];

    let filters = {};
    dataFilters.forEach((item) => {
        const fieldName = Object.keys(item)[0];
        switch (fieldName) {
            case 'AdministratorCompanyId':
                filters.promoters = item[fieldName];
                break;

            case 'CategoryId':
                filters.categories = item[fieldName];
                break;

            case 'CustomCategoryId':
                filters.customCategories = item[fieldName];
                break;

            case 'CurrencyId':
                filters.currencies = item[fieldName];
                break;

            case 'CustomMinimumPurchaseAmount':
                filters.minPurchase = item[fieldName];
                break;

            case 'LargestRegion':
                filters.geographicZones = item[fieldName].map(item => {
                    return {id: item, name: item}
                });
                break;

            case 'ManagementFee':
                filters.managementFee = item[fieldName];
                break;

            case 'ReturnM1':
            case 'ReturnM3':
            case 'ReturnM6':
            case 'ReturnM12':
            case 'ReturnM36':
            case 'ReturnM60':
            case 'ReturnM120':
                filters[fieldName.replace(/R/, 'r')] = item[fieldName];
                break;

            default:
                break;
        }
    });
    return filters;
};

const etfsFilters = (response) => {
    const ETFsLeverageFilterTypeId = 9;
    const ETFsCategoryFilterTypeId = 8;
    const ETFsComplexityFilterTypeId = 10;
    const ETFsIssuersFilterTypeId = 6;
    const ETFsMarketFilterTypeId = 7;
    let filters = {};

    response.forEach(item => {
        const array = item.stockClassifications.componentsStockClassificationItem.stockClassificationItem;
        switch (item.idClassificationType) {
            case ETFsLeverageFilterTypeId:
                return filters.leverages = array;
            case ETFsCategoryFilterTypeId:
                return filters.categories = array;
            case ETFsComplexityFilterTypeId:
                return filters.complexities = array;
            case ETFsIssuersFilterTypeId:
                return filters.issuers = array;
            case ETFsMarketFilterTypeId:
                return filters.markets = array;

            default:
                return;
        }
    });

    return filters;

};

const stocksFilters = data => {
    return { markets: data[0].response };
};

const warrantsFilters = data => {
    return data.reduce((prev, curr) => {
        prev[curr.field] = curr.response;
        return prev;
    }, {});
};

const fillFiltersSubReducer = (payload) => {

    const product = payload.product;

    switch (product) {
        case TYPE_STOCKS:
            return stocksFilters(payload.data);

        case TYPE_WARRANTS:
            return warrantsFilters(payload.data);

        case TYPE_ETFS:
            return etfsFilters(payload.data);

        case TYPE_PLANS:
        case TYPE_FUNDS:
            return fundsEtfsPlansFilters(payload.data);

        default:
            return;
    }
};

module.exports = fillFiltersSubReducer;

