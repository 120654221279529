const actionTypes = require('constants/actionTypes');
const ccActionTypes = require('constants/contactCenter/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');

const initialState = Immutable.fromJS({
    ...(__CONTACT_CENTER__ ? {
        agreementChecked: false,
        agreementIsDirty: false,
        valid: false,
        precontractualLinkInfo: {
            error: '',
            hasBeenFetched: false,
            isFetching: false,
            url: ''
        },
        hireRestriction: false,
    }
  : {
      valid: false,
      hireRestriction: false,
    }),
});

function validateStep(state, validFlag) {
    
    if (validFlag !== undefined) {
        if (validFlag) {
          return state.merge({
            valid: false,
            hireRestriction: true,
          });
        } else {
          return state.merge({
            valid: true,
            hireRestriction: false,
          });
        }
      } 
    return state.merge({
        valid: state.get('agreementChecked'),
        agreementIsDirty: true
    });
}


function hireCardAtHomeAbroadStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_VALIDATE_STEP:
            return validateStep(state,action.payload.valid);
        case actionTypes.HIRE_CARD_AT_HOME_ABROAD_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                agreementChecked: action.payload.checked,
                agreementIsDirty: false
            });
        case ccActionTypes.HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_REQUEST:
            return state.merge({
                precontractualLinkInfo: {
                    error: '',
                    isFetching: true
                }
            });
        case ccActionTypes.HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_SUCCESS:
            return state.merge({
                precontractualLinkInfo: {
                    error: '',
                    hasBeenFetched: true,
                    isFetching: false,
                    url: get(action.payload.response, 'precontractualReturn.infoPrecontractualDetalleList.infoPrecontractualDetalleList.url', '')
                }
            });
        case ccActionTypes.HIRE_CARD_AT_HOME_ABROAD_PRECONTRACTUAL_LINK_FAILURE:
            return state.merge({
                precontractualLinkInfo: {
                    error: action.payload.error,
                    isFetching: false,
                    url: ''
                }
            });
        default:
            return state;
    }
}

module.exports = hireCardAtHomeAbroadStep1;
