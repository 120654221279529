const { formatText } = require('core/i18n').i18n

/**
 * Translates three letters country codes to country names.
 * Translates geographical codes (2 digits) to geoZone names.
 * i.e: countryNameHelper('ESP') // => 'España'
 * i.e: countryNameHelper('EU') // => 'European Union'
 **/
function countryNameHelper(code) {
    return (code.length === 3
    ? formatText(`countryNames-${code.toUpperCase()}`)
    : formatText(`geoZoneNames-${code.toUpperCase()}`));
}

export default countryNameHelper;
