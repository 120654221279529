// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    additionalDocumentation: [],
    noDocuments: false
});

function setPdfDocumentsFetchingStatus (state, process, value) {
    const additionalDocumentation = state.get('additionalDocumentation');
    return additionalDocumentation.update(
        additionalDocumentation.findIndex(immDocument => immDocument.get('process') === process),
        immDocument => immDocument.merge({ isFetching: value })
    );
}

const additionalDocumentation = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ADDITIONALDOCUMENTATION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_LEGAL_DOCUMENTATION_SUCCESS:
        case actionTypes.FETCH_ADDITIONALDOCUMENTATION_SUCCESS:
            return state.merge({
                additionalDocumentation: action.payload.data,
                error: '',
                isFetching: false
            });
        case actionTypes.FETCH_LEGAL_DOCUMENTATION_FAILURE:
        case actionTypes.FETCH_ADDITIONALDOCUMENTATION_FAILURE:
            return state.merge({
                additionalDocumentation: [],
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.FETCH_LEGAL_DOCUMENTATION_NO_DOCUMENTS_SUCCESS:
        case actionTypes.FETCH_ADDITIONALDOCUMENTATION_NO_DOCUMENTS_SUCCESS:
            return state.merge({
                additionalDocumentation: [],
                noDocuments: action.payload.data,
                error: '',
                isFetching: false
            });
        case actionTypes.FETCH_ADDITIONALDOCUMENTATION_DOWNLOADING_PDF_FINISHED:
            return state.merge({
                additionalDocumentation: setPdfDocumentsFetchingStatus(state, action.payload.process, false)
            });
        case actionTypes.FETCH_ADDITIONALDOCUMENTATION_DOWNLOADING_PDF_REQUEST:
            return state.merge({
                additionalDocumentation: setPdfDocumentsFetchingStatus(state, action.payload.process, true)
            });
        default:
            return state
    }
};

module.exports = additionalDocumentation;
