// @vendors
const Immutable = require('immutable');

// @reducers
const brokerStocksPurchaseOrderStep1 = require('./brokerStocksPurchaseOrder/brokerStocksPurchaseOrderStep1');
const brokerStocksPurchaseOrderStep2 = require('./brokerStocksPurchaseOrder/brokerStocksPurchaseOrderStep2');

// @constants
const { FIRST_STEP, SECOND_STEP } = require('constants/index');

// @helpers
const actionTypes = require('constants/actionTypes');

const STEP_REDUCERS = [
    brokerStocksPurchaseOrderStep1,
    brokerStocksPurchaseOrderStep2
];

const INITIAL_STATE = Immutable.fromJS({
    steps: [
        brokerStocksPurchaseOrderStep1(undefined, {}),
        brokerStocksPurchaseOrderStep2(undefined, {}),
    ],
    isLoaded: false,
    digitodecontrol: '',
    operation: {
        descriptionValue: '',
        descriptionProcess: '',
        endDate: '',
        codigoEmision: '',
        codigoValorInterno: '',
        tipoProceso: '',
        subtipoProceso: ''
    },
    contract: {
        product: '',
        number: ''
    },
    owner: '',
    walletAccountType: '',
    associatedAccount: {
        number: '',
        balance: {
            amount: 0,
            currency: ''
        }
    },
    visibleStep: FIRST_STEP + 1,
    finished: false,
    fetchConfirmOrder: false,
    orderNumber: ''
});

function brokerStocksPurchaseOrder(state = INITIAL_STATE, action) {
    let partialSteps;
    let step;
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_START:
            return INITIAL_STATE.mergeDeep({
                digitodecontrol: action.payload.cuenta.get('digitodecontrol'),
                operation: action.payload.operation,
                contract: action.payload.contract,
                owner: action.payload.owner,
                associatedAccount: action.payload.associatedAccount,
                walletAccountType: action.payload.walletAccountType,
                isLoaded: action.payload.isLoaded
            });
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_CHANGE_AMOUNT:
            partialSteps  = state.getIn(['steps', FIRST_STEP]);
            return state.setIn(['steps', FIRST_STEP], brokerStocksPurchaseOrderStep1(partialSteps, action));
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_CHANGE_MANIFEST:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksPurchaseOrderStep2(partialSteps, action))
                        .set('fetchConfirmOrder',false);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_DOCUMENTS_READ:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksPurchaseOrderStep2(partialSteps, action))
                .set('fetchConfirmOrder',false);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_VALIDATE_STEP:
            step = action.payload.step;
            const isSecondStep = step === SECOND_STEP ? true : false;
            partialSteps = state.getIn(['steps', step]);
            const stepReducer = STEP_REDUCERS[step];
            return state.setIn(['steps', step], stepReducer(partialSteps, action))
                        .set('fetchConfirmOrder', isSecondStep);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_SET_STEP:
            step = action.payload.step;
            return state.set('visibleStep', step).setIn(['steps', step - 1, 'valid'], false)
                        .setIn(['steps', step - 1, 'loading'], false);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_LOADING:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksPurchaseOrderStep2(partialSteps, action));
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_CONFIRM_FAILURE:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksPurchaseOrderStep2(partialSteps, action))
                        .set('fetchConfirmOrder', false);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_FINISH:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksPurchaseOrderStep2(partialSteps, action))
                        .set('fetchConfirmOrder', false)
                        .set('finished', true)
                        .set('orderNumber', action.payload.orderNumber);
        default:
            return state;
    }
}

module.exports = brokerStocksPurchaseOrder;
