// @ vendors
const Immutable = require('immutable');
const moment = require('moment');
const { get } = require('lodash');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_UNPAID_FEES_REQUEST,
    MORTGAGE_REQUEST_FETCH_UNPAID_FEES_SUCCESS,
    MORTGAGE_REQUEST_FETCH_UNPAID_FEES_FAILURE,
    MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_REQUEST,
    MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_SUCCESS,
    MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_FAILURE,
    MORTGAGE_REQUEST_RESET_UNPAID_FEES,
    MORTGAGE_REQUEST_FETCH_SET_UNPAID_FEES_FILTER,
    MORTGAGE_REQUEST_FETCH_CLEAR_UNPAID_FEES_FILTER,
    MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_SUCCESS,
    MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_REQUEST,
    MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_FAILURE
} = require('constants/actionTypes');
const { DATE_FILTER_NONE } = require('constants/index');

const initialState = Immutable.fromJS({
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        }
    },
    unpaidFeesList: [],
    error: '',
    isFetching: false,
    isEndOfList: '',
    nextPageURL: '',
    isFetchingNextPage: false,
});

const mapFees = list => {
    let unpaidFeesList = [];

    list.forEach(fee => {
        const item = {
            date: moment(fee.fehaCuota),
            number: get(fee, 'cuota', 0),
            state: get(fee, 'situacionCuota', 0) || 0,
            interest: {
                currency: get(fee, 'intcuot.divisa', 'EUR'),
                amount: get(fee, 'intcuot.importe', 0)
            },
            value: {
                currency: get(fee, 'importeCuota.divisa', 'EUR'),
                amount: get(fee, 'importeCuota.importe', 0)
            },
            disableButton: get(fee, 'disableButton', true),
            feeDetails: get(fee, 'feeDetails', []),
            stateKey: get(fee, 'cdsitimp', 'IP'),
            isFetchingDetail: false
        };

        unpaidFeesList.push(item);
    });

    return unpaidFeesList;
};

const unpaidFees = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_UNPAID_FEES_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_REQUEST:
            return state.merge({
                isFetchingNextPage: true
            })
        case MORTGAGE_REQUEST_FETCH_UNPAID_FEES_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false,
                unpaidFeesList: Immutable.fromJS(mapFees(action.data.unpaidFeesList)),
                isEndOfList: action.data.isEndOfList,
                nextPageURL: action.data.nextPageURL
            });
        case MORTGAGE_REQUEST_FETCH_UNPAID_FEES_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_FAILURE:
            return state.set('isFetchingNextPage', false);
        case MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_REQUEST:
            return state.setIn(['unpaidFeesList', action.data.index, 'isFetchingDetail'], true);
        case MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_SUCCESS:
            const { index, disableButton, feeDetails } = action.data;
            const fee = state.getIn(['unpaidFeesList', index]);
            const mergedFee = fee.merge({
                isFetchingDetail: false,
                disableButton,
                feeDetails: Immutable.fromJS(feeDetails)
            });
            return state.setIn(['unpaidFeesList', index], mergedFee);
        case MORTGAGE_REQUEST_FETCH_DISABLE_BUTTON_FAILURE:
            return state.setIn(['unpaidFeesList', action.data.index, 'isFetchingDetail'], false);
        case MORTGAGE_REQUEST_RESET_UNPAID_FEES:
            return state.merge(initialState);
        case MORTGAGE_REQUEST_FETCH_SET_UNPAID_FEES_FILTER:
            const dateFilterSelected = action.data.selected !== DATE_FILTER_NONE;
            return state.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected,
                        selected: action.data.selected,
                        from: action.data.from,
                        to: action.data.to
                    }
                }
            });
        case MORTGAGE_REQUEST_FETCH_CLEAR_UNPAID_FEES_FILTER:
            return state.set('filters', initialState.get('filters'));
        case MORTGAGE_REQUEST_FETCH_NEXT_PAGE_UNPAID_FEES_SUCCESS:
            const { unpaidFeesList, isEndOfList, nextPageURL } = action.data;
            const currentUnpaidFeesList = state.get('unpaidFeesList');
            const parsedFees = mapFees(unpaidFeesList);
            return state.mergeDeep({
                nextPageURL,
                unpaidFeesList: currentUnpaidFeesList.concat(Immutable.fromJS(parsedFees)),
                isEndOfList,
                isFetchingNextPage: false
            });
        default:
            return state
    }
};

module.exports = unpaidFees;
