// @ vendor
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');

// @ utilities
const { formatText } = require('core/i18n').i18n;

// @ constants
const actionTypes = require('constants/actionTypes');



function setInitialState() {
    return Immutable.fromJS({
        isLoading: false,
        fetchSuccess: false,
        list: [],
        error: '',
        ...(__CONTACT_CENTER__) ? { hasBeenFetched: false } : {}
    })
}

const getIntervenerTypeTranslation = (type) => {
    return (type) ? formatText('interventionTypes-' + trim(type)) : '';
}

function parseInterveners (interveners) {
    return interveners.map(intervener => {
        return {
            name: trim(intervener.name) || '',
            type: getIntervenerTypeTranslation(intervener.interventionType),
            identification: {
                code: intervener.personCode || -1,
                type: trim(intervener.personType) || ''
            },
            interventionOrder: trim(intervener.interventionOrder) || '',
            isRemovable: intervener.removable  // Is VERY IMPORTANT to use this flag to decide if allow to remove it!!
        }
    });
}

function parseIntervenersCC(interveners) {
    return interveners.map(intervener => {
        return {
            name: trim(intervener.nomInterviniente) || '',
            type: getIntervenerTypeTranslation(intervener.tipoIntervencion),
            identification: {
                code: get(intervener, 'identifPersona.codigodepersona', -1) || -1,
                type: trim(get(intervener, 'identifPersona.tipodepersona', '')) || ''
            },
            interventionOrder: trim(intervener.ordenIntervencion) || '',
            isRemovable: intervener.removible,
            identificationDocument: {
                code: trim(get(intervener, 'identifDocumento.codigodedocumento', '')) || '',
                type: trim(get(intervener, 'identifDocumento.tipodocumentoofic', '')) || ''
            }
        };
    });
}

function intervenersReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_REQUEST:
            return state.merge({
                isLoading: true,
                fetchSuccess: false,
                error: false
            });
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_SUCCESS:
            const parseIntervenersMethod = __CONTACT_CENTER__ ? parseIntervenersCC : parseInterveners
            return state
                .set('list', Immutable.fromJS(parseIntervenersMethod(action.payload.intervenersList)))
                .merge({
                    isLoading: false,
                    fetchSuccess: true,
                    ...(__CONTACT_CENTER__) ? { hasBeenFetched: true } : {},
                    error: false
                });
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_FAILURE:
            return state.merge({
                isLoading: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = intervenersReducer;
