// @vendors
const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const {
    PERIODIC_PERIODICITY_DEFAULT
} = require('constants/cardPeriodicLoads');

const setInitialState = () => (
    Immutable.fromJS({
        getLoadsListFetch: false,
        byId: {},
        byOrder: [],
        getLoadsListError: undefined,
        filters: {
            amount: {
                isActive: false,
                maxAmount: null,
                minAmount: null
            },
            periodicity: {
                isActive: false,
                periodicity: PERIODIC_PERIODICITY_DEFAULT
            }
        },
        isDeleting: false,
        deleteSuccessful: false,
        errorMessage: undefined
    })
);

const parseLoadsList = (loadList) => {
    let byId = {};
    let byOrder = [];
    loadList.forEach((load) => {
        load['isExpanded'] = false;
        byId[load['scheduleTransferId'].toString()] = load;
        byOrder.push(load['scheduleTransferId'].toString());
    });

    return {
        byId,
        byOrder
    };
}

const cardPeriodicLoads = (state = setInitialState(), action) => {
    switch(action.type) {
        case actionTypes.OPENYOUNG_PERIODIC_LOADS_LIST_REQUEST:
            return state.merge({
                getLoadsListFetch: true,
                getLoadsListError: undefined,
                byId: {},
                byOrder: []
            });
        case actionTypes.OPENYOUNG_PERIODIC_LOADS_LIST_SUCCESS:
            return state.mergeDeep({
                getLoadsListFetch: false,
                getLoadsListError: undefined
            }, parseLoadsList(action.payload.loadList));
        case actionTypes.OPENYOUNG_PERIODIC_LOADS_LIST_ERROR:
            return state.merge(setInitialState(),{
                getLoadsListError: action.payload.error
            });
        case actionTypes.OPENYOUNG_PERIODIC_LOADS_SET_FILTERS:
            const filters = action.payload.filters;
            const amountFilterSelected = get(filters, 'amount.minAmount') !== null
                || get(filters, 'amount.maxAmount') !== null;
            const periodicitySelected = get(filters, 'periodicity.periodicity') !== PERIODIC_PERIODICITY_DEFAULT;
            const partial = state.mergeDeep({
                filters
            });
            return partial.mergeDeep({
                filters: {
                    amount: {
                        isActive: amountFilterSelected
                    },
                    periodicity: {
                        isActive: periodicitySelected
                    }
                }
            });
        case actionTypes.OPENYOUNG_PERIODIC_LOADS_RESET_FILTERS:
            const { filterKey } = action.payload;
            return state.setIn(['filters', filterKey], setInitialState().getIn(['filters', filterKey]));
        case actionTypes.OPENYOUNG_PERIODIC_LOADS_CLEAR_FILTERS:
            const initialState = setInitialState();
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
        case actionTypes.DELETE_PERIODIC_LOAD_REQUEST:
            return state.merge({
                isDeleting: true,
                deleteSuccessful: false,
                errorMessage: undefined
            });
        case actionTypes.DELETE_PERIODIC_LOAD_SUCCESS:
            return state.merge({
                isDeleting: false,
                deleteSuccessful: true,
                errorMessage: undefined
            });
        case actionTypes.DELETE_PERIODIC_LOAD_ERROR:
            return state.merge({
                isDeleting: false,
                deleteSuccessful: false,
                errorMessage: action.payload.error
            });
        default:
            return state;
    }
}
module.exports = cardPeriodicLoads;
