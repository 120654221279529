// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
// @utilitits
const { capitalize } = require('utilities/stringHelper');

function setInitialState() {
    return Immutable.fromJS({
        contractAddress: {},
        errorDescription: '',
        fetchError: false,
        fetchSuccess: false,
        hasContractAddress: false,
        isFetching: false
    });
}

function processContractAddress(state, contractAddressRaw) {
    const { domicilioEsp, numDomicilio, domicilioExt, codFormatoDomicilio } = contractAddressRaw;

    const contractAddress = {
        country: domicilioEsp && domicilioEsp.pais && domicilioEsp.pais.trim() || '',
        id: numDomicilio || -1,
        number: domicilioEsp && domicilioEsp.numeroVia && domicilioEsp.numeroVia.trim() || '',
        postalCode: domicilioEsp && domicilioEsp.codigoPostal && domicilioEsp.codigoPostal.trim() || '',
        population: domicilioEsp && domicilioEsp.localidad && domicilioEsp.localidad.trim() || '',
        province: domicilioEsp && domicilioEsp && domicilioEsp.plaza && domicilioEsp.plaza.provincia && domicilioEsp.plaza.provincia.trim() || '',
        others: domicilioEsp && domicilioEsp.anexoVia && domicilioEsp.anexoVia.trim() || '',
        roadName: domicilioEsp && domicilioEsp.nombreVia && domicilioEsp.nombreVia.trim() || '',
        roadType: domicilioEsp && domicilioEsp.tipoVia && domicilioEsp.tipoVia.trim() || ''
    };

    const contractAddressExt = {
        country: domicilioExt && domicilioExt.pais && domicilioExt.pais.trim() || '',
        addressLine1: domicilioExt && domicilioExt.linea1Direccion && capitalize(domicilioExt.linea1Direccion).trim() || '',
        addressLine2: domicilioExt && domicilioExt.linea2Direccion && capitalize(domicilioExt.linea2Direccion).trim() || '',
        addressCity: domicilioExt && domicilioExt.plaza && capitalize(domicilioExt.plaza).trim() || ''
    };

    return state.merge({
        codFormatoDomicilio,
        contractAddress,
        contractAddressExt,
        fetchSuccess: true,
        fetchError: false,
        hasContractAddress: true,
        isFetching: false
    });
}

function contractAddressReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GET_CONTRACT_ADDRESS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.GET_CONTRACT_ADDRESS_SUCCESS:
            return processContractAddress(state, action.payload.contractAddress);
        case actionTypes.GET_CONTRACT_ADDRESS_NO_RESULT_SUCCESS:
            return state.merge({
                fetchError: false,
                fetchSuccess: true,
                hasContractAddress: false,
                isFetching: false,
            });
        case actionTypes.GET_CONTRACT_ADDRESS_ERROR:
            return state.merge({
                fetchSuccess: false,
                fetchError: true,
                errorDescription: action.payload.errorDescription,
                isFetching: false,
            });
        default:
            return state;
    }
}

module.exports = contractAddressReducer;
