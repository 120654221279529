// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    isModalOpen: false,
    error: '',
    code: ''
});

const requestSmsCode = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_SMS_CODE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_SMS_CODE_SUCCESS:
            return state.merge({
                codeSent: action.data.codeSent,
                isFetching: false,
                isModalOpen: true
            });
        case actionTypes.MORTGAGE_REQUEST_SMS_CODE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_SMS_CODE_CLOSE_MODAL:
            return state.merge({
                isModalOpen: false
            });
        case actionTypes.MORTGAGE_REQUEST_SMS_CHANGE_CODE:
            return state.merge({
                code: action.data.code
            });
        default:
            return state;
    }
};

module.exports = requestSmsCode;
