// @ constants
const { EURO_SYMBOL } = require('constants/index');
const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const successStep = require('./successStep');
const customErrors = require('./customErrors');
const {
    PAYROLL_ACCOUNT_TYPE,
    PAYROLL_ACCOUNT_SUBTYPE,
    PAYROLL_ACCOUNT_STANDARD
} = require('constants/index');
const {
    PAYROLL_ACCOUNT_ALIAS,
    UPGRADE_TO_PAYROLL_ACCOUNT_ALIAS,
    BANKING_PAYROLL_UPGRADE_ACCOUNT_CONTRACTUAL,
    BANKING_PAYROLL_UPGRADE_ACCOUNT_PRECONTRACTUAL,
} = require('constants/hiringProductInformation');
// @ helpers
const { formatText } = require('core/i18n').i18n;

const contract = {
    title: 'contractsView-upgradeToPayrollAccountAccountPreselected',
    last_step: 4,
    define_steps: 3,
    onCancelURL: '/myprofile/accounts',
    termsAndCondsKey: UPGRADE_TO_PAYROLL_ACCOUNT_ALIAS,
    code_op: 110,
    showIntervenerControls: false,
    successStepType: 'dynamic',
    hireParamsFn: 'upgrade_to_payroll_account_HireParams',
    SignParamsFn: 'upgrade_to_payroll_account_SignParams',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelAccountsAcquittance',
    errorBackButtonLink: '/myprofile/accounts', 
    accountAlias: PAYROLL_ACCOUNT_ALIAS,
    type: PAYROLL_ACCOUNT_TYPE,
    subtype: PAYROLL_ACCOUNT_SUBTYPE,
    standard: PAYROLL_ACCOUNT_STANDARD,
    warningContractMessage: 'contractsView-upgradeToPayrollAccountComponentInformationLabel',
    promo_code: true,
    operativeName:"operatives-upgradetoPayrollAccount",
    onlyUpgradeableToPayroll: true,
    hasPolling: true,
    preContractualAlias: BANKING_PAYROLL_UPGRADE_ACCOUNT_PRECONTRACTUAL,
    contractualAlias: BANKING_PAYROLL_UPGRADE_ACCOUNT_CONTRACTUAL,
    tealiumStepsLabels: [
        'precontractual',
        'selection',
        'confirmation'
    ],
    tealiumName: 'contractingAccount',
    customTagging: 'deposittransfer_customTagging',
    breadcrumb: [
        {
            text: 'breadcrumb-accounts',
            url: '/myprofile/accounts'
        },
        {
            text: 'contractsView-upgradeToPayrollAccountAccountPreselected'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            {
                id: 'cta_seleccionada',
                label: 'contractsView-upgradeToPayrollAccountAccountToConvert',
                value: null,
                description: null,
                type: 'single',
                format_type: '',
                order: 1,
                confirmation_step: {
                    columns: 5,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'balance_account',
                label: 'contractsView-upgradeToPayrollAccountBalanceAssociated',
                value: null,
                type: 'single',
                formatNumber: true,
                format_type: EURO_SYMBOL,
                order: 7,
                confirmation_step: {
                    columns: 4,
                    highlighted: true,
                    order: 2,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ]
            },
            {
                id: 'cta_separator',
                type: 'separator',
                order: 3,
                label: 'cta_separator',
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 4,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ]
            },
            {
                id: 'cta_separator',
                type: 'separator',
                order: 3,
                label: 'cta_separator',
                visible: [
                    {
                        step: 4,
                        order: 5
                    }
                ]
            },
            {
                id: 'promotion',
                type: 'promotion',
                visible: [
                    {
                        step: 4,
                        order: 6
                    }
                ]
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'hasAccountPreselected',
                value: false
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowConfirmationText'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowPreviousInformationLabel',
                            id: 'btn_ContractsView_precontractual',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowPreviousInformationUrl'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowDataProtectionLabel',
                            id: 'btn_ContractsView_product_1',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowDataProtectionUrl'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowNaturalPersonsLabel',
                            id: 'btn_ContractsView_product_1',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowNaturalPersonsUrl'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowPrecontractualInformationOnlyLabel',
                            id: 'btn_ContractsView_product_2',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowPrecontractualInformationUrl'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowCommisionInformationDocumentLabel',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowCommisionInformationDocumentUrl'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowMifidLabel',
                            id: 'btn_ContractsView_product_3',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowMifidUrl'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowMaximumRatesLabel',
                            id: 'btn_ContractsView_product_4',
                            href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowMaximumRatesUrl'
                        },
                    ]
                }
            ]
        },
        preContractDocuments : {
            preContractPdfFileName: "documentosCuentaNóminaOpen",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_payrollAccountPdfTitle')
        },
        preContractConditionsDocuments: {
            preContractPdfFileName: "documentosPreContractualesCuentaNóminaOpen",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_payrollAccountPdfTitle'),
        },
        precontractualConfirmationLabel: [
            {
                text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowText'
            },
            {
                text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowContractServiceLabel',
                href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowContractServiceUrl'
            },
            {
                text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowMifidLabel',
                href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowMifidUrl'
            },
            {
                text: 'contractsView-contractsView_upgradePayrollAccountLegalWindowRateValuesLabel',
                href: 'contractsView-contractsView_upgradePayrollAccountLegalWindowRateValuesUrl'

            }
        ]
    },
    steps: [step1, step2, step3],
    successStep,
    customErrors
};

module.exports = { contract };
