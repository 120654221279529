// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');
// @ reducers
const openYoungCardModifyChoreStep1 = require('./openYoungCardModifyChoreStep1');

const setInitialState = () => {
    return Immutable.fromJS({
        steps: [
            openYoungCardModifyChoreStep1(undefined, {type: null}),
             {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        submitError: false,
        willCancel: false,
        error: '',
        errorReceived: '',
        choreList:[],
        canReceiveDeposit: false,
        getCardDetailsError: '',
        valid: false
    });
}

const updateStep = (index, steps, action) => {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return openYoungCardModifyChoreStep1(step, action);
        }
    })
}

const openYoungCardModifyChoreReducer = (state = setInitialState(), action) => {
    let partialSteps;
    switch (action.type) {
        case actionTypes.MODIFY_CHORE_RESET:
            return setInitialState();
        case actionTypes.MODIFY_CHORE_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? 2 : 1;

                    return state.mergeDeep({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.MODIFY_CHORE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.MODIFY_CHORE_SET_AMOUNT:
        case actionTypes.MODIFY_CHORE_VALIDITY_DATE_CHANGED:
        case actionTypes.MODIFY_CHORE_SET_CHORE_TYPE:
        case actionTypes.MODIFY_CHORE_CONCEPT_CHANGED:
        case actionTypes.MODIFY_CHORE_AVAILABLE_BALANCE:
        case actionTypes.MODIFY_CHORE_SET_REPETITION_TYPE:
        case actionTypes.MODIFY_CHORE_SET_REPETITION_OPTION_LIST:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.MODIFY_CHORE_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel,
                conceptIsDirty:false,
                amountIsDirty: false
            })
        case actionTypes.MODIFY_CHORE_CONFIRM_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.MODIFY_CHORE_CONFIRM_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                errorReceived: '',
                inProgress: false
            })
        case actionTypes.MODIFY_CHORE_CONFIRM_ERROR:
            return state.merge({
                isFetching: false,
                submitError: true,
                error: action.payload.error,
                errorReceived: action.error.error
            })
        case actionTypes.MODIFY_CHORE_DETAILS_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.MODIFY_CHORE_DETAILS_SUCCESS:
            return state.mergeDeep({
                isFetching: false,
                choreList: action.payload.choreList
            })
        case actionTypes.MODIFY_CHORE_DETAILS_ERROR:
            return state.merge({
                isFetching: false,
                getChoreDetailsError: action.payload.error
            })
        default:
            return state
    }
}

module.exports = openYoungCardModifyChoreReducer;
