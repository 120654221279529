const { FLOW_TYPE_ONLY_ACCESS,
        OPERATION_LOG_FAIL,
    OPERATION_LOG_DONE,
    OPERATION_LOG_NONE
} = require('../constants')
const {
    GLOBAL_POSITION_REQUEST_SUCCESS,
    GLOBAL_POSITION_REQUEST_FAILURE,
    GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE,
    GLOBAL_POSITION_EXPENSES_DETAIL_SUCCESS
} = require('constants/actionTypes')
const get = require('lodash/object/get');

const ACTION_CODE_SEE_GLOBAL_POSITION = 5001;
const ACTION_CODE_EXPENSES_BY_CATEGORY = 5002;

module.exports = {
    [ACTION_CODE_SEE_GLOBAL_POSITION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [GLOBAL_POSITION_REQUEST_SUCCESS, GLOBAL_POSITION_REQUEST_FAILURE],
            check: (action) => {
                const {type} = action;
                let errorMessage;
                let operationState = OPERATION_LOG_DONE;
                const CURRENT_URL = window.location.pathname;

                if (type === GLOBAL_POSITION_REQUEST_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.data.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description';
                }

                operationState = CURRENT_URL.includes('/myprofile/global')
                    ? operationState
                    : OPERATION_LOG_NONE;

                return {
                    actionCode: ACTION_CODE_SEE_GLOBAL_POSITION,
                    operationState,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_EXPENSES_BY_CATEGORY]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [ GLOBAL_POSITION_EXPENSES_DETAIL_SUCCESS, GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;

                if (action.type === GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_EXPENSES_BY_CATEGORY,
                    operationState
                }
            }
        }
    }
}
