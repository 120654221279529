// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP,
        SECOND_STEP,
        BROKER_HOLIDAY_VALIDATION_ERROR } = require('constants/index');
const DateHelper = require('utilities/dateHelper');

function setInitialState() {
    const firstDate = DateHelper.getNextBussinessDay(true, false);
    const limitDate = DateHelper.getStockLimitDate(firstDate);
    const excludeDates = DateHelper.getExcludedWeekends(limitDate);
    const validityDate = firstDate.clone();

    return Immutable.Map().merge({
        steps: [
            {
                valid: false,
                buyFor: 'titleAmount',
                buyForInput: '',
                buyForConditionalInput: 1,
                orderType: 'toMarket',
                orderTypeLimited: 1,
                isValidating: false,

                firstDate,
                validityDate,
                limitDate,
                excludeDates,

                stocksContractSelectedValue: -1,
                errors: {
                    selectedOption: false,
                    buyForInput: false,
                    buyForConditionalInput: false,
                    buyForConditionalInputLowerThanLimitedImportError: false,
                    orderTypeLimited: false,
                    stocksContractSelectedValue: false,
                    notMultiploLote: false,
                    minorThanLote: false,
                    conditionalIsNotMultipleError: false,
                    conditionalInputLowerThanLotValueError: false
                },
                holidayError: false,
                buyForConditionalInput_titlesToBuy: 0,
                buyForInput_importToBuy: 0
            },
            {
                valid: false,
                stockRisk: 6,
                showError: false
            }
        ],
        visibleStep: 1,
        submitSuccessful: false,
        isFetching: false,
        inProgress: false,
        token: null,
        orderConfirmation: null,
        emissionDate: 0,
        extantCostData: null,
        fetchingExtantConst: false,
    });
}

const validateStep1 = (state, catalogData) => {
    const selectedOption = state.getIn(['steps', FIRST_STEP, 'stocksContractSelectedValue']);
    const buyFor = state.getIn(['steps', FIRST_STEP, 'buyFor']);
    const buyForInput = state.getIn(['steps', FIRST_STEP, 'buyForInput']);
    const buyForConditionalInput = state.getIn(['steps', FIRST_STEP, 'buyForConditionalInput']);
    const titlesToBuy = state.getIn(['steps', FIRST_STEP, 'buyForConditionalInput_titlesToBuy']);
    const orderType = state.getIn(['steps', FIRST_STEP, 'orderType']);
    const orderTypeLimited = state.getIn(['steps', FIRST_STEP, 'orderTypeLimited']);
    const accountExists = selectedOption >= 0;
    let roundedPrice = 0;
    if (state.getIn(['steps', FIRST_STEP, 'productPrice'])) {
        roundedPrice = parseFloat(state.getIn(['steps', FIRST_STEP, 'productPrice']));
    }

    const isAccountInputError = !accountExists;
    const isTitlesInputError = (buyFor === 'titleAmount' && (!buyForInput || buyForInput < 1));
    const isApproxImportError = (buyFor === 'aproxImport' && (buyForConditionalInput <= 0 || buyForConditionalInput < roundedPrice));
    const isLimitedInputError = (orderType === 'limited' && orderTypeLimited <= 0 );
    const isBuyForGreaterThanLimited = (
        buyFor === 'aproxImport' &&
        orderType === 'limited' &&
        buyForConditionalInput &&
        orderTypeLimited &&
        buyForConditionalInput < orderTypeLimited
    );

    let isBuyByLoteError = false;
    const operatedByLot = catalogData.get('operatedByLot');
    let minorThanLote = false;
    let notMultiploLote = false;
    let conditionalInputLowerThanLotValueError = false;
    let conditionalIsNotMultipleError = false;

    if (operatedByLot) {
        minorThanLote = buyFor === 'titleAmount' && (!buyForInput || buyForInput < catalogData.get('lot'));
        notMultiploLote = buyFor === 'titleAmount' && (!buyForInput || !(buyForInput % catalogData.get('lot') == 0));
        conditionalInputLowerThanLotValueError = buyFor === 'aproxImport' && buyForConditionalInput < catalogData.get('lotValue');
        conditionalIsNotMultipleError = buyFor === 'aproxImport' && !(titlesToBuy % catalogData.get('lot') == 0);
        isBuyByLoteError = (
            minorThanLote || notMultiploLote || conditionalInputLowerThanLotValueError || conditionalIsNotMultipleError
        );
    }

    const isStepValid = (!isAccountInputError &&
                         !isTitlesInputError &&
                        !isApproxImportError &&
                        !isLimitedInputError &&
                        !isBuyByLoteError &&
                        !isBuyForGreaterThanLimited);

    return state.mergeIn(['steps', FIRST_STEP, 'valid'], isStepValid)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'selectedOption'], isAccountInputError)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'buyForInput'], isTitlesInputError)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'buyForConditionalInput'], isApproxImportError)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'buyForConditionalInputLowerThanLimitedImportError'], isBuyForGreaterThanLimited)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'orderTypeLimited'], isLimitedInputError)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'notMultiploLote'], notMultiploLote)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'minorThanLote'], minorThanLote)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'conditionalInputLowerThanLotValueError'], conditionalInputLowerThanLotValueError)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'conditionalIsNotMultipleError'], conditionalIsNotMultipleError)
                .mergeIn(['steps', FIRST_STEP, 'errors', 'stocksContractSelectedValue'], isAccountInputError);
};

const validateStep2 = (state) => {
    return state.setIn(['steps', SECOND_STEP, 'valid'], true)
                .setIn(['steps', SECOND_STEP, 'showError'], true);
};

const productBuyReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_BUY_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_STOCKS_BUY_VALIDATE_REQUEST:
            return state.setIn(['steps', FIRST_STEP, 'loading'], true)
                        .setIn(['steps', FIRST_STEP, 'isValidating'], true);
        case actionTypes.BROKER_STOCKS_BUY_VALIDATE_SUCCESS:
            return state.set('confirmationData', action.payload.data)
                        .setIn(['steps', FIRST_STEP, 'loading'], false)
                        .set('visibleStep', 2)
                        .setIn(['steps', FIRST_STEP, 'holidayError'], false)
                        .setIn(['steps', FIRST_STEP, 'isValidating'], false);
        case actionTypes.BROKER_STOCKS_BUY_VALIDATE_FAILURE: //TODO handle error?
            const response = action.payload.data;
            const errorKey = (response.body ? response.body.error : response.error);
            let newState = state;
            if (errorKey === BROKER_HOLIDAY_VALIDATION_ERROR) {
                newState = state.setIn(['steps', FIRST_STEP, 'holidayError'], true);
            }
            return newState.setIn(['steps', FIRST_STEP, 'loading'], false)
                           .setIn(['steps', FIRST_STEP, 'isValidating'], false);
        case actionTypes.BROKER_STOCKS_BUY_CONFIRM_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'loading'], true)
        case actionTypes.BROKER_STOCKS_BUY_CONFIRM_FAILURE:
            return state.setIn(['steps', SECOND_STEP, 'loading'], false)
        case actionTypes.BROKER_STOCKS_BUY_CONFIRM_SUCCESS:
            return state.set('submitSuccessful', true)
                        .set('emissionDate', moment(parseInt(action.payload.data.timestamp)))
                        .setIn(['steps', SECOND_STEP, 'loading'], false)
                        .set('orderConfirmation', Immutable.Map({
                            associatedAccount: action.payload.data.datosCuentaAsociada.codIban.codbban,
                            accountBalance: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.importe,
                            accountCurrency: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.divisa,
                            orderNumber: action.payload.data.numeroOrden
                        }));
        case actionTypes.BROKER_STOCKS_BUY_SEND_EMAIL_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'sendingEmail'], true)
        case actionTypes.BROKER_STOCKS_BUY_SEND_EMAIL_SUCCESS:
            return state.setIn(['steps', SECOND_STEP, 'sendingEmail'], false)
        case actionTypes.BROKER_STOCKS_BUY_SEND_EMAIL_ERROR:
            return state.setIn(['steps', SECOND_STEP, 'sendingEmail'], false)
        default:
            return state;
    }
};

function stocksBuyReducer(state = setInitialState(), action) {

    switch (action.type) {
        case actionTypes.BROKER_STOCKS_BUY_RESET:
            return setInitialState();
        case actionTypes.BROKER_STOCKS_BUY_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.COMPLETE_STOCKS_BUY_STEP2:
            return state.set('submitSuccessful', true);
        case actionTypes.BROKER_STOCKS_BUY_VALIDATE_STEP:
            if (state.get('visibleStep') === 1) {
                const newState = state.set('confirmationData', false);
                return validateStep1(newState, action.payload.catalogData);
            } else {
                return validateStep2(state);
            }
        case actionTypes.BROKER_STOCKS_BUY_RESET_ERRORS_BY_LOT:
            return state.setIn(['steps', FIRST_STEP, 'errors', 'notMultiploLote'], false)
                 .setIn(['steps', FIRST_STEP, 'errors', 'minorThanLote'], false)
                 .setIn(['steps', FIRST_STEP, 'errors', 'conditionalInputLowerThanLotValueError'], false)
                 .setIn(['steps', FIRST_STEP, 'errors', 'conditionalIsNotMultipleError'], false);
        case actionTypes.BROKER_STOCKS_BUY_UPDATE_STEP_1_FORM:
            const prevProductPrice = state.getIn(['steps', FIRST_STEP, 'productPrice']);
            const newState = state.setIn(['steps', FIRST_STEP, action.payload.formData.field], action.payload.formData.value)
                        .setIn(['steps', FIRST_STEP, 'productPrice'], action.payload.productPrice || prevProductPrice)
                        .setIn(['steps', FIRST_STEP, 'errors', action.payload.formData.field], false);
            const catalogData = action.payload.catalogData;

            if (catalogData) {
                if (action.payload.formData.field === 'buyForConditionalInput') {
                    const titlesToBuy = Number((action.payload.formData.value * catalogData.get('lot') / catalogData.get('lotValue')).toFixed(3));
                    return newState.setIn(['steps',FIRST_STEP, 'buyForConditionalInput_titlesToBuy'], titlesToBuy);
                }
                if (action.payload.formData.field === 'buyForInput') {
                    const importToBuy = Number((action.payload.formData.value * catalogData.get('lotValue') / catalogData.get('lot')).toFixed(3));
                    return newState.setIn(['steps',FIRST_STEP, 'buyForInput_importToBuy'], importToBuy);
                }
            }
            return newState;

        case actionTypes.BROKER_STOCKS_BUY_RESET_STEP_1_TITLES_INPUT:
            return state.setIn(['steps', FIRST_STEP, 'buyForInput'], '');
        case actionTypes.BROKER_STOCKS_BUY_RESET_STEP_1_APPROX_IMPORT_INPUT:
            return state.setIn(['steps', FIRST_STEP, 'buyForConditionalInput'], 0);
        case actionTypes.BROKER_STOCKS_BUY_RESET_STEP_1_LIMITED_INPUT:
            return state.setIn(['steps', FIRST_STEP, 'orderTypeLimited'], 0);

        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
            const firstDate = DateHelper.getNextBussinessDay(action.payload.isAvailableToday, action.payload.currentDate);
            const limitDate = DateHelper.getStockLimitDate(firstDate);
            const validityDate = DateHelper.getValidDate(firstDate, limitDate, state.getIn(['steps', FIRST_STEP, 'validityDate']));
            const excludeDates = DateHelper.getExcludedWeekends(limitDate);

            return state.setIn(['steps', FIRST_STEP, 'firstDate'], firstDate)
                        .setIn(['steps', FIRST_STEP, 'validityDate'], validityDate)
                        .setIn(['steps', FIRST_STEP, 'limitDate'], limitDate)
                        .setIn(['steps', FIRST_STEP, 'excludeDates'], excludeDates);

        case actionTypes.BROKER_STOCKS_BUY_SET_HOLIDAY_ERROR:
            return state.setIn(['steps', FIRST_STEP, 'holidayError'], false);
        case actionTypes.BROKER_STOCKS_EXTANTCOST_FECHING:
            return state.setIn(['fetchingExtantConst'], true);
        case actionTypes.BROKER_STOCKS_EXTANTCOST_SUCESS:
            return state.merge({
                extantCostData: action.payload.response,
                extantConstError: false,
                fetchingExtantConst: false,
            });
        case actionTypes.BROKER_STOCKS_EXTANTCOST_ERROR:
            return state.setIn(['extantCostData'], null)
                    .setIn(['extantConstError'], true)
                    .setIn(['fetchingExtantConst'], false);
        case actionTypes.BROKER_STOCKS_BUY_WILL_LEAVE:
        case actionTypes.BROKER_STOCKS_BUY_WILL_CANCEL:
        default:
            return productBuyReducer(state, action);

    }
}

module.exports = stocksBuyReducer;
