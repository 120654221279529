// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    cuentasCotitulares: []
});

const coHolder = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_FETCH_COHOLDER_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_COHOLDER_SUCCESS:
            return state.merge({
                cuentasCotitulares: action.data.data,
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_COHOLDER_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = coHolder;
