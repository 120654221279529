// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    extraImageById: {},
    imagesById: {},
    imagesByOrder: [],
    isFetching: false,
    fetchSuccess: false,
    selectedImageId: '',
    valid: false
});

function parseResponse(state, idsOrder, receivedData, extraImageId) {
    let byId = {};
    let receivedByOrder = [];
    let byOrder = [];

    let extraImageById = {};

    receivedData.forEach(imageData => {
        const id = get(imageData,'fieldCmsId[0].value');
        const imageObject = {
            id,
            alt: get(imageData,'fieldImageLarge[0].alt'),
            url: get(imageData,'fieldImageLarge[0].url')
        };

        if (id === extraImageId) {
            extraImageById[id] = imageObject;
        } else {
            byId[id] = imageObject;
            receivedByOrder.push(id);
        }
    });

    idsOrder.forEach( id => {
        if (receivedByOrder.includes(id)) {
            byOrder.push(id)
        }
    })

    return state.merge({
        isFetching: false,
        fetchSuccess: true,
        selectedImageId: byOrder[0],
    })
    .set('extraImageById', Immutable.fromJS(extraImageById))
    .set('imagesById', Immutable.fromJS(byId))
    .set('imagesByOrder', Immutable.fromJS(byOrder));
}

function validateStep(state) {

    return state.merge({
        valid: !!state.get('selectedImageId')
    });
}

function hireCardImageSelectorReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_IMAGE_UPDATE_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.HIRE_CARD_IMAGE_UPDATE_SELECTED_ID:
            return state.merge({
                selectedImageId: action.payload.id
            });

        case actionTypes.HIRE_CARD_IMAGE_SELECTOR_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
                fetchSuccess: false
            })
            .set('imagesById', Immutable.fromJS({}))
            .set('imagesByOrder', Immutable.fromJS([]));

        case actionTypes.HIRE_CARD_IMAGE_SELECTOR_SUCCESS:
            return parseResponse(state, action.payload.idsOrder, action.payload.images, action.payload.extraImage);

        case actionTypes.HIRE_CARD_IMAGE_SELECTOR_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });

        case actionTypes.HIRE_CARD_IMAGE_SELECTOR_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hireCardImageSelectorReducer;
