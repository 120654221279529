// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialReferralEmpty = function() {
    return Immutable.fromJS({
        blur: false,
        email: '',
        isValid: false
    });
};

const initialState = Immutable.fromJS({
    clipboardCopied: false,
    errorReceived: '',
    invitationCode: '',
    isFetching: false,
    isValid: false,
    inProgress: false,
    promoCode: '',
    referrals: [initialReferralEmpty()],
    submitSuccessful: false,
    visibleStep: 1,
    willLeave: false,
    willCancel: false
});

function sendReferralsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REFERRALS_SET_PROMOTION_LIST:
            return initialState;
        case actionTypes.REFERRALS_SET_INVITATION_LIST:
            return initialState;
        case actionTypes.REFERRALS_FORM_RESET:
            return initialState;
        case actionTypes.REFERRALS_FORM_SUBMIT_REQUEST:
            return state.merge({
                isFetching: true,
                errorReceived: ''
            });
        case actionTypes.REFERRALS_FORM_SUBMIT_SUCCESS:
            const invitationCode = get(action.payload, 'invitationList[0].invitacionCode', '') || '';

            return state.merge({
                invitationCode,
                submitSuccessful: true,
                isFetching: false
            });
        case actionTypes.REFERRALS_FORM_SUBMIT_CLIPBOARD_SUCCESS:
            return state.merge({
                clipboardCopied: true
            });
        case actionTypes.REFERRALS_FORM_SUBMIT_FAILURE:
            return state.merge({
                isFetching: false,
                errorReceived: action.payload.error
            });

        case actionTypes.REFERRALS_FORM_SET_PROMO_CODE:
            return state.merge({
                promoCode: action.payload.code
            });

        default:
            return state;
    }
}

module.exports = sendReferralsReducer;
