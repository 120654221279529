// @utilities
const { getPublicSitePathByLanguaje } = require('core/apiURLBuilder');
const { buildLinks } = require('utilities/fundsHelper');

// @constants
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS,
    BROKER_MYPROFILE_STOCKS_BUY_URL,
    BROKER_MYPROFILE_WARRANTS_BUY_URL,
    BROKER_MYPROFILE_ETFS_BUY_URL,
    BROKER_MYPROFILE_MYWALLET_URL,
    BROKER_MYPROFILE_PRODUCT_FILE,
    BROKER_MYPROFILE_PRODUCT_FILE_CAPITALIZE,
    ALTERNATIVE_FUNDS
} = require('constants/index');

const productLinksHelper = {
    buildProductLink(type, item) {
        switch (type) {
            case TYPE_STOCKS:
                return `${BROKER_MYPROFILE_PRODUCT_FILE}/${item.isin}/${item.market}`
            case TYPE_WARRANTS:
            case TYPE_ETFS:
                return `${BROKER_MYPROFILE_MYWALLET_URL}/${type}${BROKER_MYPROFILE_PRODUCT_FILE_CAPITALIZE}/${item.isin}/${item.market}`
            case TYPE_PLANS:
                return getPublicSitePathByLanguaje('brokerURL-brokerURL_pensionPlanOnPublic', { isin: item.dgsCode })
            case TYPE_FUNDS:
                return buildLinks(item.isin)
            case ALTERNATIVE_FUNDS:
                return `/myprofile/broker/alternative-fund/isin/${item.isin}`
            default:
                return;
        }
    },

    buildBuyProductLink(type, item) {
        switch (type) {
            case TYPE_STOCKS:
                return `${BROKER_MYPROFILE_STOCKS_BUY_URL}${item.get('isin')}/${item.get('market')}`
            case TYPE_WARRANTS:
                return `${BROKER_MYPROFILE_WARRANTS_BUY_URL}${item.get('isin')}/${item.get('market')}`;
            case TYPE_ETFS:
                return `${BROKER_MYPROFILE_ETFS_BUY_URL}${item.get('isin')}/${item.get('market')}`;
            case TYPE_FUNDS:
                return buildLinks(item.isin)
            case TYPE_PLANS:
            return getPublicSitePathByLanguaje('brokerURL-brokerURL_pensionPlanOnPublic', { isin: item.secID })
            default:
                return;
        }
    }
}

module.exports = productLinksHelper;
