const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const setInitialState = () => {
    return Immutable.fromJS({
        isFetching: false,
        fetchSuccess: false,
        error: false,
        isUsPerson: false,
    });
}

const initialState = setInitialState();

function brokerInformativePopUpReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_IS_US_PERSON_FETCHING:
            return state.merge({
                isFetching: true,
                fetchSuccess: false,
                error: false,
            })
        case actionTypes.BROKER_IS_US_PERSON_SUCCESS:
            const data = action.payload;
            return state.merge({
                isUsPerson: data.usPerson,
                fetchSuccess: true,
                isFetching: false,
                error: false,
            })
        case actionTypes.BROKER_IS_US_PERSON_ERROR:
            return state.merge({
                error: true,
                isFetching: false,
                fetchSuccess: false,
            })
        default:
            return state;
    }
}

module.exports = brokerInformativePopUpReducer;
