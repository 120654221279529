const { isMortgage } = require('utilities/APIParsingHelper');

const {
    CARD_ECARD,
    CARD_CREDIT,
    CARD_DEBIT,
    MY_PROFILE_ACCOUNT,
    WALLETS,
    FUNDS,
    PLANS,
    MORTGAGES,
    LOANS,
    REQUESTED_RIGHTS,
    IN_PROGRESS_RIGHTS,
    NOTIFIED_RIGHTS,
    ERROR_RIGHTS,
    EXPIRED_RIGHTS,
    ACCESS,
    PORTABILITY
} = require('constants/index');

// get family for products to sent to ISBAN
function getFamily(type) {
    let family;

    switch (type) {
        case WALLETS:
            family = '05';
            break;
        case FUNDS:
            family = '03';
            break;
        case CARD_ECARD:
            family = '06';
            break;
        case CARD_DEBIT:
            family = '06';
            break;
        case CARD_CREDIT:
            family = '06';
            break;
        case PLANS:
            family = '04';
            break;
        case MY_PROFILE_ACCOUNT:
            family = '02';
            break;
        case LOANS:
        case MORTGAGES:
            family = '01';
    }

    return family;
}

function getRightsState(rightState) {
    let state;
    switch (rightState) {
        case IN_PROGRESS_RIGHTS:
        case REQUESTED_RIGHTS:
            state = 'rightsProfile-inProgressCellTable'
            break;
        case NOTIFIED_RIGHTS:
            state = 'rightsProfile-notifiedCellTable'
            break;
        case EXPIRED_RIGHTS:
            state = 'rightsProfile-expiredCellTable'
            break;
        case ERROR_RIGHTS:
            state = 'rightsProfile-errorCellTable'
            break;
    }

    return state;
}

function getTypeRights(rightsType) {
    let type;
    switch (rightsType) {
        case ACCESS:
            type = 'rightsProfile-rightsAccessPortableTable'
            break;
        case PORTABILITY:
            type = 'rightsProfile-rightsPortableTable'
            break;
    }

    return type;
}

// get all mortgages and loans
function loansWithBalanceBiggerThanCero(mortgageType = false, returnCount = false, immLoans) {
    const loans = immLoans.get('byId').filter(loan => {
        const isMortgageLoan = isMortgage(loan.get('newProduct'), loan.get('productSubType'), loan.get('productStandard'));
        const isRequestedType = mortgageType ? isMortgageLoan : !isMortgageLoan;
        return isRequestedType && loan.getIn(['balance', 'amount']) !== 0;
    });
    return returnCount ? loans.size : loans;
}

// get all cards by type and filters
function getTotalCardsByType(type, immCards) {
    const immCardsList = immCards.get('byOrder');
    const immCardsById = immCards.get('byId');

    const filteredImmCardsList = immCardsList.filter(cardId => {
        const immCard = immCardsById.get(cardId);
        if (immCard.get('type') === type) {
            return immCard.get('type') === type;
        }
    });

    return filteredImmCardsList.size;
}

// get all the products selected
function getTotalProducts(immRightsProfile) {
    const totalProductSelected =
     (immRightsProfile.get('accountList').length ? immRightsProfile.get('accountList').length : 0) +
     (immRightsProfile.get('cardCreditList').length ? immRightsProfile.get('cardCreditList').length : 0) +
     (immRightsProfile.get('cardEcardList').length ? immRightsProfile.get('cardEcardList').length : 0) +
     (immRightsProfile.get('plansList').length ? immRightsProfile.get('plansList').length : 0) +
     (immRightsProfile.get('fundsList').length ? immRightsProfile.get('fundsList').length : 0) +
     (immRightsProfile.get('walletsList').length ? immRightsProfile.get('walletsList').length : 0) +
     (immRightsProfile.get('typeFuturesList').length ? immRightsProfile.get('typeFuturesList').length : 0) +
     (immRightsProfile.get('mortgagesList').length ? immRightsProfile.get('mortgagesList').length : 0) +
     (immRightsProfile.get('loansList').length ? immRightsProfile.get('loansList').length : 0);

    return totalProductSelected
}

module.exports = {
    getRightsState,
    getTypeRights,
    loansWithBalanceBiggerThanCero,
    getTotalCardsByType,
    getTotalProducts,
    getFamily
}
