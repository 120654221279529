// @vendor
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.Map({
    initialized: false,
});

function biocatchReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BIOCATCH_SET_INITIALIZED:
            return state.merge({
                initialized: true,
            });
        case actionTypes.BIOCATCH_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = biocatchReducer;
