const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        isSecureCommerceChecked: false,
        isNoSecureCommerceChecked: false,
        isNoSecureCommerceCheckedLast: false,
        isSecureCommerceCheckedLast: false,
        telefonoMovil: "",
        telefonoFijo: ""
    });
}

function validateStep(step) {
    return step.merge({
        valid: true
    });
}

function cardSecurePaymentStep1(state = setInitialState(), action) {
    let isSecureCommerceChecked;
    let isNoSecureCommerceChecked;
    switch (action.type) {
        case actionTypes.CARD_SECURE_PAYMENT_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_SECURE_PAYMENT_SECURE_COMMERCE_CHANGES:
            return state.set('isSecureCommerceChecked', action.payload.allowSecureCommerce)
        case actionTypes.CARD_SECURE_PAYMENT_NO_SECURE_COMMERCE_CHANGES:
            return state.set('isNoSecureCommerceChecked', action.payload.allowNoSecureCommerce)
        case actionTypes.CARD_SECURE_PAYMENT_SETUP_SUCCESS:
            isSecureCommerceChecked = action.payload.indicadorCES === "S";
            isNoSecureCommerceChecked = action.payload.indicadorPNS === "S";
            return state.merge({
                isSecureCommerceChecked,
                isNoSecureCommerceChecked,
                isSecureCommerceCheckedLast: isSecureCommerceChecked,
                isNoSecureCommerceCheckedLast: isNoSecureCommerceChecked,
                telefonoFijo : action.payload.telefonoFijo,
                telefonoMovil : action.payload.telefonoMovil
            });
        default:
            return state;
    }
}

module.exports = cardSecurePaymentStep1;
