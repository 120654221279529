import Immutable from "immutable";
import actionTypes from "constants/actionTypes";

const initialState = Immutable.fromJS({
    documents: [],
    overDraftProtectionDocuments: {},
})

const updateDocumentFetchStatus = ({ state, documentId, value, contractId }) => {
    return state.get("overDraftProtectionDocuments").get(contractId).map(document => {
        return document.toJS().file.documentId === documentId ? document.merge({ isFetching: value }) : document
    })
}

function okApiReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_PRE_CONTRACTUALS_OK_REQUEST:
            return state.merge({
                isFetchingPrecontracts: true,
            })
        case actionTypes.FETCH_PRE_CONTRACTUALS_OK_SUCCESS:
            return state.merge({
                isFetchingPrecontracts: false,
            }).set("precontracts", Immutable.fromJS(action.payload.precontracts))
        case actionTypes.FETCH_PRE_CONTRACTUALS_OK_API_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetchingPrecontracts: false,
                precontracts: [],
            })
        case actionTypes.FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_IN_PROGRESS:
            return state.merge({
                isFetchingOverDraftProtection: true,
            })
        case actionTypes.FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_ERROR:
            return state.merge({
                isFetchingOverDraftProtection: false,
            })
        case actionTypes.FETCH_CONTRACTUALS_OVERDRAFT_PROTECTION_SUCCESS:
            return state.merge({
                isFetchingOverDraftProtection: false,
                overDraftProtectionDocuments: {
                    ...state.get("overDraftProtectionDocuments"),
                    [action.payload.contractId]: action.payload.data,
                },
            })
        case actionTypes.UPDATE_IS_FETCHING_OVERDRAFT_FILE:
            return state.merge({
                overDraftProtectionDocuments: {
                    ...state.get("overDraftProtectionDocuments"),
                    [action.payload.contractId]: updateDocumentFetchStatus({
                        state,
                        documentId: action.payload.documentId,
                        contractId: action.payload.contractId,
                        value: action.payload.value,
                    }),
                },
            })
        default:
            return state
    }
}

module.exports = okApiReducer