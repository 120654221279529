const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const isArray = require('lodash/lang/isArray');

const initialState = Immutable.fromJS({
    isFetching: false,
    isFetched: false,
    error: false,
    description: '',
    market: '',
    currency: '',
    realTimeValue: 0,
    time: '',
    forecast: {
        oneMonthPrediction: 0,
        threeMonthsPrediction: 0,
        sixMonthsPrediction: 0,
        twelveMonthsPrediction: 0,
    },
});

function brokerIAPredictionDetailReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_IA_GET_PREDICTION_DETAIL_FETCHING:
            return state.set('isFetched', false).set('isFetching', true).set('error', false);

        case actionTypes.BROKER_IA_GET_PREDICTION_DETAIL_FAILURE:
            return state
                .set('isFetched', false)
                .set('isFetching', false)
                .set('error', action.payload.data);

        case actionTypes.BROKER_IA_GET_PREDICTION_DETAIL_SUCCESS:
            const predictionData = isArray(action.payload.data)
                ? action.payload.data[0]
                : action.payload.data;
            if (predictionData) {
                return state
                    .mergeDeep(predictionData)
                    .set('isFetched', true)
                    .set('isFetching', false)
                    .set('error', false);
            } else {
                return state.set('isFetched', false).set('isFetching', false).set('error', true);
            }

        default:
            return state;
    }
}

module.exports = brokerIAPredictionDetailReducer;
