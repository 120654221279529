// @vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: '',
    byId: {},
    byOrder: []
});

function parseWarranties(creditId, warranties) {
    let byId = {};
    let byOrder = [];

    warranties.map( warranty => {
        const parsedWarranty = {
            number: get(warranty, 'contratoIban.codbban'),
            description: warranty.descripcion,
            assessment: get(warranty, 'importeSaldo.importe'),
            currency: get(warranty, 'importeSaldo.divisa')
        };

        byId[parsedWarranty.number] = parsedWarranty;
        byOrder.push(parsedWarranty.number);
    });

    return {
        byId: byId,
        byOrder: byOrder
    };
}

function creditWarrantiesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CREDIT_WARRANTIES_REQUEST:
            return state.merge({
                isFetching: true,
                error: '',
                byId: {},
                byOrder: []
            });
        case actionTypes.CREDIT_WARRANTIES_SUCCESS:
            const parsedWarranties = parseWarranties(action.payload.id, action.payload.data);
            return state.merge({
                isFetching: false,
                error: '',
                byId: parsedWarranties.byId,
                byOrder: parsedWarranties.byOrder
            });
        case actionTypes.CREDIT_WARRANTIES_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
                byId: {},
                byOrder: []
            });
      default:
          return state;
    }
}

module.exports = creditWarrantiesReducer;
