import 'stylesOpenBankApp';
import 'stylesOpenBankAppCC';
import 'commonsComponents/main.scss';
// @components
import App from './createApp';
import {OKErrorBoundary} from 'components/commons/OKErrorBoundary/OKErrorBoundary';

// @vendor
const React = require('react');
const ReactDOM = require('react-dom');

// @utilities
const { cleanResources, clearContactCenterSessions } = require('utilities/cleanHelper');
const { setLanguageDates } = require('utilities/initialization');
const { history } = require('./store/configureStore')

// @constants
const { LOCAL_ENVIRONMENT } = require('constants/index');

setLanguageDates();

/**
 * If the mobile device has extra extra large resolutions and the display is
 * retina, the we are going to set the vieport initial zoom to 2 to increase
 * the contents size.
 */

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const isExtraWidth = window.innerWidth >= 1400;
const isRetina = window.devicePixelRatio === 2;

if (isMobile && isExtraWidth && isRetina) {
    let viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
}

const appContainer = document.getElementById('app');

if (__CONTACT_CENTER__) {
    if (window.__ENV__ === LOCAL_ENVIRONMENT) {
        if (window.parent !== window) {
            window.__REDUX_DEVTOOLS_EXTENSION__ = window.parent.__REDUX_DEVTOOLS_EXTENSION__;
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = window.parent.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ;
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;
        }
    }

    const usd = require('./utilities/usd');
    const loginHelper = require('utilities/loginHelper');
    const {removeAgentLoginInfo} = require('utilities/contactCenter/disasterRecoveryhelper');
    const { cookiesDomain } = require('core/environmentConfig');

    clearContactCenterSessions(cookiesDomain);

    window.setAgentClientData = (usdInformation) => {
        usd.init(usdInformation);
        usd.cleanDisasterRecoveryStore();
        ReactDOM.render(<App />, appContainer);
        removeAgentLoginInfo();// clean cookies for disaster recovery
        window.location.pathname !== '/login'
        && history.push(window.location.pathname) // Reload store to new tabs wrap up call
    };
    window.refreshAgentToken = (agentToken) => {
        usd.refreshAgentToken(agentToken);
        loginHelper.executeSharedLogin({
            onLoginSuccess: () => {},
        }, history);
    };
    window.notifyInteractionEnded = () => {
        usd.cleanDisasterRecoveryStore();
        cleanResources(cookiesDomain);
    };

    if (!usd.requestInformationFromUSD()) {
        usd.initDisasterRecoveryStore();
        ReactDOM.render(<App />, appContainer);
    }
} else {
    ReactDOM.render(<OKErrorBoundary behaviour="log"><App /></OKErrorBoundary>, appContainer);
}
