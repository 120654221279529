import {
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED
} from 'constants/index'

module.exports = {
    title: 'contractsView-eAccountStep2Title',
    order: 3,
    footer_text: '',
    position: 'middle',
    components: [
        {
            id_component: 'dropdown-accounts',
            label: 'contractsView-eAccountDropdownLabel',
            options: [],
            type: 'single',
            target: 'cta_seleccionada',
            isIntervenerAvailable: true,
            intervenersSpecial: true,
            onChangeIntervenerFn: 'eAccount_onChangeIntervener',
            showIntervenerControls: true,
            hideIntervenerLinkActions: true,
            intervenersSubHeader: 'contractsView-eAccountintervenersSubHeader',
            intervenersHierarchicalTypeSelection: true,
            intervenerOptions: [
                INTERVENER_LABEL_HOLDER,
                INTERVENER_LABEL_AUTHORIZED,
                INTERVENER_LABEL_EMPOWERED
            ],
            required: true,
            restoreDefaultIntervenersEnabled: true
        },
        {
            id_component: 'disclaimer-personal-info',
            messageKey: 'eAccountMsg',
            linkTextKey: 'eAccountLinkText',
            linkUrlKey: 'eAccountLinkUrl'
        }
    ]
}
