// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const findKey = require('lodash/object/findKey');

// @utilities
const { hash } = require('utilities/hash');
const { isFutureAccount, generateAccountId } = require('utilities/APIParsingHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    FILTERS_VALID,
    HYPHEN,
    BROKER_JOINT_CONTRACTS_VALUE,
    BROKER_JOINT_CONTRACTS_PLAN,
    BROKER_JOINT_CONTRACTS_FUND,
    BROKER_JOINT_CONTRACTS_FUTURES,
    CHARGE_ACCOUNT_PREFIX,
    EURO_TEXT
} = require('constants/index');

const initialState = Immutable.fromJS({
    byId: {},
    byOrder: [],
    isFetching: false,
    success: false,
    error: ''
});


const mapContracts = (contracts, identificate, map) => {
    const byId = {};
    const byOrder = [];

    contracts.forEach(contract => {
        const id = identificate(contract);

        byId[id] = map(contract);
        byOrder.push(id);
        });

    return {
        byId,
        byOrder
    }
}

const buildAssociatedAccountNumber = (associatedAccount) => {
    const accountNumber = get(associatedAccount, 'oficina.entidad') +
                          get(associatedAccount, 'oficina.oficina') +
                          get(associatedAccount, 'digitodecontrol') +
                          get(associatedAccount, 'numerodecuenta');

    return accountNumber;
};

const mapValuesToContracts = contracts => {
    const identificate = contract => hash([
        contract.contrato.numerodecontrato, contract.aplisub3
    ]);
    const map = contract => ({
        id: identificate(contract),
        type: BROKER_JOINT_CONTRACTS_VALUE,
        filters: {
            valid: get(contract, 'filtros.valido') === FILTERS_VALID
        },
        walletTitular: get(contract, 'nombreTitular'),
        walletNumber: get(contract, 'contrato.numerodecontrato'),
        walletAccountType: get(contract, 'descripcion'),
        walletBalance: {
            amount: get(contract, 'saldo1.importe'),
            currency: get(contract, 'saldo1.divisa')
        },
        associatedAccount: {},
        accountNumber: get(contract, 'cuenta.numerodecuenta', HYPHEN),
        firstTitular: get(contract, 'nombreTitular'),
        firstTitularType: get(contract, 'descIntervenciones.nomTipInterv'),
        productNumber: get(contract, 'contrato.producto'),
        controlDigit: get(contract, 'cuenta.digitodecontrol', HYPHEN),
        productSubtype: get(contract, 'criterios.c1.subtipoproducto')
    });

    return mapContracts(contracts, identificate, map);
};

const mapFundsToContracts = contracts => {
    const identificate = contract => hash([
        contract.contrato.numerodecontrato, contract.aplisub5
    ]);
    const map = contract => ({
        id: identificate(contract),
        type: BROKER_JOINT_CONTRACTS_FUND,
        filters: {
            valid: get(contract, 'filtros.valido') === FILTERS_VALID
        },
        walletTitular: contract.nombretitular,
        walletNumber: get(contract, 'contrato.numerodecontrato'),
        walletAccountType: contract.descripcion,
        walletBalance: {
            amount: get(contract, 'saldo1.importe'),
            currency: get(contract, 'saldo1.divisa')
        },
        // TODO implement for funds associatedAccount function
        associatedAccount: {
            contract: get(contract, 'cuentaAsociada.numerodecontrato'),
            subgroup: get(contract, 'cuentaAsociada.subgrupo')
        },
        accountNumber: get(contract, 'cuenta.numerodecuenta', HYPHEN),
        firstTitular: contract.nombretitular,
        firstTitularType: get(contract, 'descIntervenciones.nomTipInterv'),
        productNumber: get(contract, 'contrato.producto'),
        controlDigit: get(contract, 'cuenta.digitodecontrol', HYPHEN),
        shares: contract.participaciones || "0",
        assetValue: {
            amount: get(contract, 'saldo2.valorliquidativo'),
            currency: get(contract, 'saldo2.divisa')
        },
        lastSettlementDate: get(contract, 'fecproliq'),
        productSubtype: get(contract, 'criterios.c1.subtipoproducto')
    });

    return mapContracts(contracts, identificate, map);
};

const mapPlansToContracts = contracts => {
    const identificate = contract => hash([
        contract.contrato.numerodecontrato, contract.aplisub6
    ]);
    const map = contract => ({
        id: identificate(contract),
        type: BROKER_JOINT_CONTRACTS_PLAN,
        filters: {
            valid: get(contract, 'filtros.valido') === FILTERS_VALID
        },
        walletTitular: contract.nombretitular,
        walletNumber: get(contract, 'contrato.numerodecontrato'),
        walletAccountType: contract.descripcion,
        walletBalance: {
            amount: get(contract, 'saldo1.importe'),
            currency: get(contract, 'saldo1.divisa')
        },
        associatedAccount: {
            number: buildAssociatedAccountNumber(get(contract, 'cuentaAsociada'))
        },
        accountNumber: get(contract, 'cuentaAsociada.numerodecuenta', HYPHEN),
        firstTitular: contract.nombretitular,
        firstTitularType: get(contract, 'descIntervenciones.nomTipInterv'),
        productNumber: get(contract, 'contrato.producto'),
        controlDigit: get(contract, 'cuentaAsociada.digitodecontrol', HYPHEN),
        shares: contract.participaciones || "0",
        assetValue: {
            amount: get(contract, 'saldo2.importe'),
            currency: get(contract, 'saldo2.divisa')
        },
        lastSettlementDate: contract.fecultliq,
        productSubtype: get(contract, 'criterios.c1.subtipoproducto')
    });

    return mapContracts(contracts, identificate, map);
};

const mapFuturesToContracts = contracts => {
    const identificate = contract => hash([
        contract.cviejo.numerodecontrato, contract.aplisub1
    ]);
    const map = contract => {
        const iban = {
            country: contract.ibanComplex.pais,
            controlDigit: contract.ibanComplex.digitodecontrol,
            codbban: contract.ibanComplex.codbban
        }
        const accountId = generateAccountId(iban);
        return ({
            id: identificate(contract),
            accountId,
            type: BROKER_JOINT_CONTRACTS_FUTURES,
            filters: {
                valid: get(contract, 'filtros.valido') === FILTERS_VALID
            },
            walletTitular: contract.nombretitular,
            walletNumber: get(contract, 'cviejo.subgrupo', HYPHEN)+get(contract, 'cviejo.numerodecontrato'),
            walletAccountType: contract.descripcion,
            walletBalance: {
                amount: get(contract, 'saldoActual.importe'),
                currency: get(contract, 'saldoActual.divisa')
            },
            associatedAccount: {},
            accountNumber: get(contract, 'cviejo.subgrupo', HYPHEN)+get(contract, 'cviejo.numerodecontrato'),
            firstTitular: contract.nombretitular,
            firstTitularType: get(contract, 'descIntervenciones.nomTipInterv'),
            productNumber: get(contract, 'cviejo.producto'),
            controlDigit: '',
            shares: contract.participaciones || "0",
            assetValue: {
                amount: get(contract, 'saldoActual.importe'),
                currency: get(contract, 'saldoActual.importe')
            },
            lastSettlementDate: contract.fecimp,
            productSubtype: get(contract, 'criterios.c1.subtipoproducto')
        });
    }

    return mapContracts(contracts.filter(account => isFutureAccount(account)), identificate, map);
}

const joinContractsById = mappedContracts => {
    const jointContracts = {};

    mappedContracts.forEach(contractsMap => {
        Object.assign(jointContracts, contractsMap.byId)
    });

    return jointContracts;
}

const joinContractsByOrder = mappedContracts => {
    let jointContracts = [];

    mappedContracts.forEach(contractsMap => {
        jointContracts = jointContracts.concat(contractsMap.byOrder)
    });

    return jointContracts;
}

function mapContractFundAccounts(accounts, state) {
    const fundContracts = state.get('byId').filter((contract) => {
        return contract.get('type') === BROKER_JOINT_CONTRACTS_FUND;
    });

    const updateFundContracts = fundContracts.map(fund => {
        const matchedAccount = accounts.find(account => {
            return account.get('product') === fund.getIn(['associatedAccount', 'subgroup'])
                && account.get('contractNumber') === fund.getIn(['associatedAccount', 'contract']);
        });

        return fund.mergeDeep({
            associatedAccount: {
                number: matchedAccount ? matchedAccount.getIn(['ibanComplex', 'codbban']):''
            }
        })
    });

    return updateFundContracts;
}

function mapContractToAccount(contract, pgValoresContractsObject) {
    const contractKey = findKey(pgValoresContractsObject, pgValoresContract => {
        const numerodecontrato = get(pgValoresContract, 'contrato');
        const matchingContractNumer = CHARGE_ACCOUNT_PREFIX + contract.get('productNumber') + contract.get('walletNumber');

        return numerodecontrato === matchingContractNumer;
    });

    let associatedAccount;
    const alternativeNumberAccount = contract.get('associatedAccount').get('contract') ? CHARGE_ACCOUNT_PREFIX + contract.get('associatedAccount').get('contract') : '-';

    switch (contract.get('type')) {
        case BROKER_JOINT_CONTRACTS_VALUE:
            associatedAccount = {
                number: contractKey ? get(pgValoresContractsObject[contractKey], 'cuentaAsociada.codBan') : alternativeNumberAccount,
                balance: {
                    amount: contractKey ? get(pgValoresContractsObject[contractKey], 'cuentaAsociada.saldo.importe') : 0,
                    currency: contractKey ? get(pgValoresContractsObject[contractKey], 'cuentaAsociada.saldo.moneda') : EURO_TEXT
                }
            };
            break;
        case BROKER_JOINT_CONTRACTS_FUND:
            associatedAccount = {
                number: contractKey ? get(pgValoresContractsObject[contractKey], 'cuentaAsociada.codBan') : alternativeNumberAccount,
                balance: {
                    amount: contractKey ? get(pgValoresContractsObject[contractKey], 'cuentaAsociada.saldo.importe') : 0,
                    currency: contractKey ? get(pgValoresContractsObject[contractKey], 'cuentaAsociada.saldo.moneda') : EURO_TEXT
                }
            }
            break;
        default:
            associatedAccount = {};
    }

    return contract.mergeDeep({
        associatedAccount
    });
}

function mapAccountsToContracts(pgValoresContractsObject, state) {
    const mergedContracts = state.get('byId').map(contract => {
        return mapContractToAccount(contract, pgValoresContractsObject);
    });

    return mergedContracts;
}

function getIdFromObjects(contractNumber, state) {
    let p = state.get('byId').toJS();
    for (let key in p) {
      if (p.hasOwnProperty(key)) {
        if (p[key].walletNumber === contractNumber) {
            return key;
        }
      }
    }
    return;
}

const BrokerJointContractsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            const mappedValues = mapValuesToContracts(
                get(action, 'payload.datosSalidaValores.valores', [])
            );
            const mappedPlans = mapPlansToContracts(
                get(action, 'payload.datosSalidaPlanes.planes', [])
            );
            const mappedFunds = mapFundsToContracts(
                get(action, 'payload.datosSalidaFondos.fondos', [])
            );
            const mappedFutures = mapFuturesToContracts(
                get(action, 'payload.datosSalidaCuentas.cuentas', [])
            );
            const contracts = Immutable.fromJS({
                byId: joinContractsById([mappedValues, mappedPlans, mappedFunds, mappedFutures]),
                byOrder: joinContractsByOrder([mappedValues, mappedPlans, mappedFunds, mappedFutures]),
                isFetching: false,
                success: true
            });

            return state.merge(contracts);
        case actionTypes.GLOBAL_POSITION_REQUEST_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_GET_MY_INVESTMENTS_SUCCESS:
            const mappedContracts = mapAccountsToContracts(
                get(action, 'payload.contracts'),
                state
            );

            return state.merge({
                byId: mappedContracts
            });
        case actionTypes.BROKER_CONTRACT_POSITIONS_GET_ACCOUNTS_DETAILS:
            return state.mergeDeep({
                byId: mapContractFundAccounts(action.payload.accounts, state)
            });
        case actionTypes.SET_WALLET_ALIAS_SUCCESS:
              const idKey = getIdFromObjects(action.payload.walletNumber, state);
              return state.mergeDeep({
                byId: {
                        [idKey]: {
                            walletAccountType: action.payload.alias
                        }
                    }
                });
        default:
            return state;
    }
}

module.exports = BrokerJointContractsReducer;
