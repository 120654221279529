const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    tutorCheckbox: {
        isDirty: false,
        isValid: false,
        value: false,
    },
    valid: false,
});

function validateStep(state, externalComponentsAreValid) {
    return state.mergeDeep({
        valid: externalComponentsAreValid,
        tutorCheckbox: {
            isDirty: true,
            isValid: !!state.getIn(['tutorCheckbox', 'value']),
        }
    });
}

function hirePackOpenYoungStep2(state = initialState, action = { type: null }) {
    const tutorCheckboxValue = state.getIn(['tutorCheckbox', 'value']);

    switch (action.type) {
        case actionTypes.HIRE_PACK_OPEN_YOUNG_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_PACK_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX:
            return state.mergeDeep({
                tutorCheckbox: {
                    isDirty: true,
                    isValid: !tutorCheckboxValue,
                    value: !tutorCheckboxValue,
                }
            });

        default:
            return state;
    }
}

module.exports = hirePackOpenYoungStep2;
