// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP
} = require('constants/index');

// @ utilities
const DateHelper = require('utilities/dateHelper');

// @reducers
const brokerHirePensionPlanReducerStep1 = require('./brokerHirePensionPlan/brokerHirePensionPlanStep1');
const brokerHirePensionPlanReducerStep2 = require('./brokerHirePensionPlan/brokerHirePensionPlanStep2');

// @actions
const { hasActivePeriodicalContributions } = require('actions/brokerPensionPlanPeriodicalContribution');

const setInitialState = () =>
    Immutable.fromJS({
        steps: [
            brokerHirePensionPlanReducerStep1(undefined, { type: null }),
            brokerHirePensionPlanReducerStep2(undefined, { type: null })
        ],
        errorReceived: '',
        isActiveInPeriodicalPlans: false,
        isBeforeClosingTime: false,
        minDate: moment().startOf('day'),
        isFetching: false,
        isFetchingInterveners: false,
        isFetchedIntervenersSuccess: false,
        isPlanInWallet: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        notFound: false,
        planProductNumber: '',
        planContractNumber: '',
        link: '',
        preContractualsLoading: false,
        preContractualsSuccess: false,
        preContractualsError: false,
    });

const updateStep = (step, steps, action) => {
    const updater =
        step === FIRST_STEP
            ? state => brokerHirePensionPlanReducerStep1(state, action)
            : state => brokerHirePensionPlanReducerStep2(state, action);
    return steps.update(step, updater);
};

const brokerHirePensionPlanReducer = (state = setInitialState(), action) => {
    let partialSteps;
    switch (action.type) {
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_REQUEST:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_VALIDATE_REQUEST:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_SUCCESS:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                isFetching: false,
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_FAILURE:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_VALIDATE_FAILURE:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FAILURE:
            return state.merge({
                isFetching: false,
                error: get(action, 'payload.error'),
                visibleStep: 2
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_REQUEST:
            return state.merge({
                isFetchingInterveners: true,
                isFetchedIntervenersSuccess: false
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_SUCCESS:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                isFetchingInterveners: false,
                isFetchedIntervenersSuccess: true,
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_FAILURE:
            return state.merge({
                isFetchingInterveners: false,
                isFetchedIntervenersSuccess: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_ACCOUNT_NUMBER:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT_PERCENTAGE:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_DATE:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_INITIAL_CONTRIBUTION:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_INTERVENER:
        case actionTypes.FETCH_BROKER_PENSION_PLAN_SUCCESS:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_CONTRIBUTION_ANSWER:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY_AMOUNT:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                inProgress: true,
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_TOGGLE_DISCLAIMER:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_DOCUMENTS_READ:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_VALIDATE_STEP:
            partialSteps = updateStep(state.get('visibleStep') - 1, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_VALIDATE_SUCCESS:
            const { numerodecontrato, producto } = action.payload.data;
            return state.merge({
                isFetching: false,
                planContractNumber: numerodecontrato,
                planProductNumber: producto,
                visibleStep: 2
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_WILL_LEAVE:
        case actionTypes.BROKER_HIRE_PENSION_PLAN_WILL_CANCEL:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_RESET:
            return setInitialState();

        case actionTypes.BROKER_HIRE_PENSION_PLAN_SUCCESS:
            return state.merge({
                inProgress: false,
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_BUILD_LINK:
            return state.merge({
                link: action.payload.link
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_IN_WALLET:
            return state.merge({
                isPlanInWallet: action.payload.isInWallet
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS:
            const hasPeriodicalPlans = action.payload.data.aportacionesPeriodicas.length > 0 ? true : false;
            const planId = action.payload.planId;
            const isActiveInPeriodicalPlans = hasPeriodicalPlans && planId ? hasActivePeriodicalContributions(action.payload.data.aportacionesPeriodicas, planId) : false;
            const isBeforeClosingTime = action.payload.data.isBeforeClosingTime;
            const timestamp = action.payload.data.timeResponse ? action.payload.data.timeResponse.timestamp : null;
            const minDate = DateHelper.getMinDateAvailable(isBeforeClosingTime, timestamp);

            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                isActiveInPeriodicalPlans: isActiveInPeriodicalPlans,
                isBeforeClosingTime: isBeforeClosingTime,
                minDate: minDate,
                steps: partialSteps
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SEND_REQUEST:
            return state.merge({
                preContractualsLoading: true,
                preContractualsSuccess: false,
                preContractualsError: false,
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SEND_SUCCESS:
            return state.merge({
                preContractualsLoading: false,
                preContractualsSuccess: true,
                preContractualsError: false,
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SEND_FAILURE:
            return state.merge({
                preContractualsLoading: false,
                preContractualsSuccess: false,
                preContractualsError: true,
            });
        default:
            return state;
    }
};

module.exports = brokerHirePensionPlanReducer;
