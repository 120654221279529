const clickTaleStart = () => {
    if(typeof clickTaleStartEventSignal === 'function'){
        window['clickTaleStartEventSignal']();
    }
}

const clickTaleEnd = () => {
    if(typeof clickTaleEndEventSignal === 'function'){
        window['clickTaleEndEventSignal']();
    }
}

export {
    clickTaleEnd,
    clickTaleStart
}