// @ vendor
import { fromJS } from 'immutable';
// @ helpers
const { getDefaultKit, createSets, createReferencePillButton, createReferenteKitsCatalog } = require('utilities/securityKit/securityKitHelper');
// @ constants
import {
    FETCH_SECURITY_KIT_CHANGE_FAILURE,
    FETCH_SECURITY_KIT_CHANGE_SUCCESS,
    SECURITY_KIT_CHANGE_SET_VISIBLE_STEP,
    SECURITY_KIT_CHANGE_VALIDATE_STEP,
    SECURITY_KIT_MODIFY_FAILURE,
    SECURITY_KIT_MODIFY_SUCCESS,
    SECURITY_KIT_RESET,
    SECURITY_KIT_GET_KIT_IS_FETCHING
} from 'constants/actionTypes';

const setInitialState = fromJS({
    defaultKit: null,
    descKit: null,
    errorReceived: '',
    sets: [],
    steps: [
        { valid: true },
        { hasSignaturePattern: true }
    ],
    submitSuccessful: false,
    visibleStep: 1,
    referencePillButton: [],
    referenceKits: {},
    securityKitGetKitIsFetching: false
});

const myProfileSecurityKitChangeReducer = (state = setInitialState, action) => {
    switch (action.type) {
        case FETCH_SECURITY_KIT_CHANGE_FAILURE:
            return state.merge({
                errorReceived: action.error,
                securityKitGetKitIsFetching: false
            });
        case FETCH_SECURITY_KIT_CHANGE_SUCCESS:
            return state.merge({
                defaultKit: getDefaultKit(action.sets),
                referencePillButton: createReferencePillButton(action.kitCatalog),
                referenceKits: createReferenteKitsCatalog(action.kitCatalog),
                sets: createSets(action.sets, action.setCatalog),
                securityKitGetKitIsFetching: false,
            });
        case SECURITY_KIT_CHANGE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.step
            })
        case SECURITY_KIT_CHANGE_VALIDATE_STEP:
            let visibleStep;
            switch (state.get('visibleStep')) {
                case 1:
                    visibleStep = 2;
                    break;
                case 2:
                    visibleStep = 2;
                    break;
                default:
                    visibleStep = 1;
            }
            return state.merge({
                visibleStep,
            });
        case SECURITY_KIT_MODIFY_FAILURE:
            return state.merge({
                errorReceived: action.error,
                submitSuccessful: true
            });
        case SECURITY_KIT_MODIFY_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                defaultKit: getDefaultKit(action.sets),
                sets: state.get('sets').merge(action.sets.toJS())
            });
        case SECURITY_KIT_GET_KIT_IS_FETCHING:
            return state.merge({
                securityKitGetKitIsFetching: action.value
            });
        case SECURITY_KIT_RESET:
            return setInitialState;
        default:
            return state
    }
}

module.exports = myProfileSecurityKitChangeReducer;
