const {
    FIRST_STEP,
    BENEFICIARY_TYPE_NGO
} = require('constants/index')
const get = require('lodash/object/get');

const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE,
    OPERATION_LOG_DONE
} = require('../constants')
const {
    TRANSFER_REQUEST_TRANSFER_SUCCESS,
    TRANSFER_REQUEST_EDIT_TRANSFER_SUCCESS,
    TRANSFER_REQUEST_CHARITY_TRANSFER_SUCCESS,
    TRANSFER_REQUEST_TRANSFER_FAILURE,
    TRANSFER_REQUEST_EDIT_TRANSFER_FAILURE,
    TRANSFER_REQUEST_CHARITY_TRANSFER_FAILURE,
    FETCH_EMITED_TRANSFERS_FAILURE,
    FETCH_EMITED_TRANSFERS_SUCCESS,
    FETCH_SCHEDULED_TRANSFERS_FAILURE,
    FETCH_SCHEDULED_TRANSFERS_SUCCESS,
    BIZUM_CANCEL_QUERY_CONTRACT_FAILURE,
    BIZUM_CANCEL_QUERY_CONTRACT_SUCCESS
} = require('constants/actionTypes')


const ACTION_CODE_TRANSFERS = 5130
const ACTION_CODE_TRANSFER = 5131
const ACTION_CODE_TRANSFER_INTO_ACCOUNTS = 5132
const ACTION_CODE_REUSE_TRANSFER = 5133
const ACTION_CODE_SCHEDULE_TRANSFER = 5134
const ACTION_CODE_EDIT_SCHEDULED_TRANSFER = 5138
const ACTION_CODE_REMOVE_SCHEDULED_TRANSFER = 5139
const ACTION_CODE_ADD_BENEFICIARY = 5141
const ACTION_CODE_SEE_BENEFICIARIES = 5140
const ACTION_CODE_EDIT_BENEFICIARY = 5142
const ACTION_CODE_REMOVE_BENEFICIARY = 5143
const ACTION_CODE_TRANSFER_RECEIVED = 5135
const ACTION_CODE_TRANSFER_EMITED = 5136
const ACTION_CODE_TRANSFER_SCHEDULED = 5137
const ACTION_CODE_AUTHORIZE_BIZUM = 5144
const ACTION_CODE_CANCEL_BIZUM = 5145
const ACTION_CODE_TRANSFER_CHARITY = 5183

function getTransferType (action, state) {
    const immTransfer = state.transfer
    const isToday = immTransfer.getIn(['steps', FIRST_STEP, 'isToday']);
    const beneficiaryType = immTransfer.getIn(['steps', FIRST_STEP, 'beneficiary', 'type']);

    let actionCode = ACTION_CODE_TRANSFER
    if (!isToday) {
        actionCode = ACTION_CODE_SCHEDULE_TRANSFER
    }

    let operationState = OPERATION_LOG_DONE
    if (action.type === TRANSFER_REQUEST_TRANSFER_FAILURE) {
        operationState = OPERATION_LOG_FAIL
    }
    if (action.type === TRANSFER_REQUEST_CHARITY_TRANSFER_FAILURE) {
        operationState = OPERATION_LOG_FAIL
    }

    if( beneficiaryType ===  BENEFICIARY_TYPE_NGO ) actionCode = ACTION_CODE_TRANSFER_CHARITY;
    if (window.location.pathname.includes('/scheduled/')) operationState = OPERATION_LOG_NONE;

    return {
        operationState: operationState,
        actionCode: actionCode
    }
}

module.exports = {
    [ACTION_CODE_TRANSFERS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/transfers'
        }
    },
    [ACTION_CODE_TRANSFER]: {
        entry: {
            path: '/myprofile/transfers/transfer'
        },
        finish: {
            action: [ TRANSFER_REQUEST_TRANSFER_SUCCESS, TRANSFER_REQUEST_TRANSFER_FAILURE ],
            check: (action, state) => {
                return getTransferType(action, state);
            }
        }
    },
    [ACTION_CODE_TRANSFER_CHARITY]: {
        entry: {
            path: '/myprofile/transfers/transfer/charity'
        },
        finish: {
            action: [ TRANSFER_REQUEST_CHARITY_TRANSFER_SUCCESS, TRANSFER_REQUEST_TRANSFER_FAILURE ],
            check: (action, state) => {
                return getTransferType(action, state);
            }
        }
    },
    [ACTION_CODE_TRANSFER_INTO_ACCOUNTS]: {
        entry: {
            path: '/myprofile/transfers/pass'
        }
    },
    [ACTION_CODE_REUSE_TRANSFER]: {
        entry: {
            path: '/myprofile/transfers/transfer/emited-reuse/[0-9]+/[0-9]+'
        }
    },
    [ACTION_CODE_EDIT_SCHEDULED_TRANSFER]: {
        entry: {
            path: '/myprofile/transfers/scheduled/[0-9]+/[0-9]+/edit'
        },
        finish: {
            action: [TRANSFER_REQUEST_EDIT_TRANSFER_SUCCESS, TRANSFER_REQUEST_EDIT_TRANSFER_FAILURE],
            check: (action) => {
                const {type} = action;

                let operationState = type === TRANSFER_REQUEST_TRANSFER_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE;
                let errorMessage;

                if (type === TRANSFER_REQUEST_TRANSFER_FAILURE) {
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.data.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description'
                }

                return {
                    operationState,
                    actionCode: ACTION_CODE_EDIT_SCHEDULED_TRANSFER,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_REMOVE_SCHEDULED_TRANSFER]: {
        entry: {
            path: '/myprofile/transfers/scheduled/[0-9]+/remove/[0-9]+'
        }
    },
    [ACTION_CODE_SEE_BENEFICIARIES]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/transfers/agenda'
        }
    },
    [ACTION_CODE_ADD_BENEFICIARY]: {
        entry: {
            path: '/myprofile/transfers/agenda/addBeneficiary'
        }
    },
    [ACTION_CODE_EDIT_BENEFICIARY]: {
        entry: {
            path: '/myprofile/transfers/agenda/[0-9]+/editBeneficiary'
        }
    },
    [ACTION_CODE_TRANSFER_RECEIVED]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/transfers/received/[0-9]+'
        }
    },
    [ACTION_CODE_TRANSFER_EMITED]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/transfers/emited/[0-9]+',
            failure: [
                { action: FETCH_EMITED_TRANSFERS_FAILURE }
            ],
            success: [
                { action: FETCH_EMITED_TRANSFERS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_TRANSFER_SCHEDULED]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/transfers/scheduled/[0-9]+',
            failure: [
                { action: FETCH_SCHEDULED_TRANSFERS_FAILURE }
            ],
            success: [
                { action: FETCH_SCHEDULED_TRANSFERS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_AUTHORIZE_BIZUM]: {
        entry: {
            path: '/myprofile/transfers/authorize-bizum'
        }
    },
    [ACTION_CODE_CANCEL_BIZUM]: {
        entry: {
            path: '/myprofile/transfers/cancel-bizum',
            failure: [
                { action: BIZUM_CANCEL_QUERY_CONTRACT_FAILURE }
            ],
            success: [
                { action: BIZUM_CANCEL_QUERY_CONTRACT_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_REMOVE_BENEFICIARY]: {
        entry: {
            path: '/myprofile/transfers/agenda/[0-9]+/remove'
        }
    }
}
