const {
    DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_TYPE,
    DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_SUBTYPE,
} = require('constants/contactCenter/hireDeposits');

module.exports = {
    title: 'contractsView-depositThirteenMonthsDueDatesStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info',
            cms_key: 'deposit13mDueDates',
            services: [
                {
                    propName: 'requestReferenceStandard',
                    params: {
                        tipoProducto: DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_TYPE,
                        subtipoProducto: DEPOSIT_13_MONTHS_DUE_DATES_PRODUCT_SUBTYPE
                    },
                    alreadyCalled: false
                }
            ]
        },
        {
            id_component: 'legal-information'
        }
    ]
};
