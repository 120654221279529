// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    DATE_FILTER_NONE,
    COLLECTIVOS_NTIFCTN_CRRNT_TO_PAYROLL,
    COLLECTIVOS_NTIFCTN_HAS_PAYROLL_ACCNT,
    COLLECTIVOS_NTIFCTN_HAS_NO_PAYROLL_AND_CURRENT_ACNT
} = require('constants/index');

const initialState = Immutable.fromJS({
    disclaimerPrompt: false,
    disclaimerClosed: false,
    isFetching: false,
    fetchSuccess: false,
    isReading: false,
    readSuccess: false,
    notifications: [],
    promptNotification: null,
    error: '',
    isFetchingNextPage: false,
    nextPage: '',
    unreadCount: 0,
    unreadCountCached: false,
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: '',
            to: ''
        },
        situation: {
            isActive: false,
            selected: ''
        },
        state: {
            isActive: false,
            selected: ''
        }
    }
});

const getPromptMessage = (prompMessage) => {
    const message = prompMessage.message.toUpperCase();

    prompMessage.target = '_blank';
    prompMessage.subtitleOne = null;
    prompMessage.linkInnerText = null;
    prompMessage.subtitleTwo = null;
    
    switch (message) {
        case 'PENDING':
            prompMessage.subtitleOne = 'prospectsPrompt-pendingProcessSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-pendingProcessLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-pendingProcessSubtitleTwo';
            break;
        case 'READY':
            prompMessage.subtitleOne = 'prospectsPrompt-processReadySubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-processReadyLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-processReadySubtitleTwo';
            break;
        case 'CUSTOM-ONB':
            prompMessage.target = '_self';
            prompMessage.subtitleOne = 'prospectsPrompt-messageOnbSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-messageOnbLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-messageOnbSubtitleTwo';
            break;
        case 'CUSTOM-ONB-ROBO':
            prompMessage.target = '_self';
            prompMessage.subtitleOne = 'prospectsPrompt-messageOnbRoboSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-messageOnbRoboLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-messageOnbRoboSubtitleTwo';
            break;
        case 'CUSTOM-ONB-GBI':
            prompMessage.target = '_self';
            prompMessage.subtitleOne = 'prospectsPrompt-messageOnbGbiSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-messageOnbGbiLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-messageOnbGbiSubtitleTwo';
            break;
        case 'CUSTOM-ONB-FONDOS':
            prompMessage.target = '_self';
            prompMessage.subtitleOne = 'prospectsPrompt-messageOnbFondosSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-messageOnbFondosLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-messageOnbFondosSubtitleTwo';
            break;
        case 'CUSTOM-ONB-PLANES':
            prompMessage.target = '_self';
            prompMessage.subtitleOne = 'prospectsPrompt-messageOnbPlanesSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-messageOnbPlanesLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-messageOnbPlanesSubtitleTwo';
            break;
        case 'CUSTOM-ONB-CARTERA':
            prompMessage.target = '_self';
            prompMessage.subtitleOne = 'prospectsPrompt-messageOnbCarteraSubtitleOne';
            prompMessage.linkInnerText = 'prospectsPrompt-messageOnbCarteraLinkInnerText';
            prompMessage.subtitleTwo = 'prospectsPrompt-messageOnbCarteraSubtitleTwo';
            break;
        default:
            break;
    }

    return prompMessage;
}

const getPromptNotification = (notifications, state) => {
    let promptList;

    if (notifications.length) {
        const notificationsList = notifications.filter(
            notification =>
                notification.messageType &&
                notification.messageType.toUpperCase() === 'NOTIFICATION' &&
                notification.message &&
                notification.message.toUpperCase() === 'ACTIVE'
        );

        if (!notificationsList.length) {
            promptList = notifications.filter(
                notification => notification.messageType &&
                notification.messageType.toUpperCase() === 'DISCLAIMER'
            );

            promptList = promptList.map(prompMessage => getPromptMessage(prompMessage));
        }
    }

    return (promptList && promptList.length) ? promptList[0] : state.get('promptNotification');
};

const getCollectivePromptNotification = (response, state) => {
    let collectivePromptNotification;
    if(response.notificationType && response.showHeaderAlertMessage) {
        collectivePromptNotification = {
            title: 'collectivesNotification-promptTitle',
            isCollectivePromptNotification: true
        };
        switch (response.notificationType) {
            case COLLECTIVOS_NTIFCTN_CRRNT_TO_PAYROLL:
                collectivePromptNotification.message = 'collectivesNotification-promptMsg1';
                break;
            case COLLECTIVOS_NTIFCTN_HAS_PAYROLL_ACCNT:
                collectivePromptNotification.message = 'collectivesNotification-promptMsg2';
                break;
            case COLLECTIVOS_NTIFCTN_HAS_NO_PAYROLL_AND_CURRENT_ACNT:
                collectivePromptNotification.message = 'collectivesNotification-promptMsg3';
                break;
        }
    }
    return collectivePromptNotification || state.get('promptNotification');
}

const userNotifications = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_NOTIFICATIONS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.USER_NOTIFICATIONS_SUCCESS:
            const nextNotifications = action.payload.data;
            const isFetchingNextPage = state.get('isFetchingNextPage');
            const notifications = isFetchingNextPage ? state.get('notifications').concat(Immutable.fromJS(nextNotifications)) : nextNotifications;
            return state.merge({
                isFetching: false,
                isFetchingNextPage: false,
                fetchSuccess: true,
                notifications: notifications,
                nextPage: action.payload.nextPage,
                promptNotification: getPromptNotification(notifications, state)
            });
        case actionTypes.USER_NOTIFICATIONS_NEXT_PAGE_REQUEST:
            return state.merge({
                isFetchingNextPage: true
            });
        case actionTypes.USER_NOTIFICATIONS_FAILURE:
            return state.mergeDeep({
                isFetching: false,
                isFetchingNextPage: false,
                error: action.payload.error
            });
        case actionTypes.SET_USER_NOTIFICATIONS_REQUEST:
            return state.merge({
                isReading: true
            });
        case actionTypes.SET_USER_NOTIFICATIONS_SUCCESS:
            return state.merge({
                isReading: false,
                readSuccess: true
            });
        case actionTypes.SET_USER_NOTIFICATIONS_FAILURE:
            return state.mergeDeep({
                isReading: false,
                error: action.payload.error
            });
        case actionTypes.USER_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
            return state.merge({
                unreadCountCached: true,
                unreadCount: action.payload.data.count
            });
        case actionTypes.USER_UNREAD_NOTIFICATIONS_COUNT_INVALIDATE_CACHE:
            return state.merge({
                unreadCountCached: false
            })
        case actionTypes.SET_USER_NOTIFICATION_PROSPECT_PROMPT:
            return state.mergeDeep({
                disclaimerPrompt: !state.get('disclaimerPrompt')
            });
        case actionTypes.SET_USER_NOTIFICATION_PROSPECT_CLOSED:
            return state.mergeDeep({
                disclaimerPrompt: false,
                disclaimerClosed: true
            });
        case actionTypes.SET_COLLECTIVE_USER_PROMPT_NOTIFICATION:
            const response = action.payload.response;
            return state.mergeDeep({
                disclaimerPrompt: response.showHeaderAlertMessage,
                promptNotification: getCollectivePromptNotification(response, state)
            });
        case actionTypes.USER_NOTIFICATIONS_FILTERS_CLEAR:
            return state.set('filters', initialState.get('filters'));
        case actionTypes.USER_NOTIFICATIONS_SET_FILTERS:
            return state.set('filters',Immutable.fromJS(action.payload.filters));

        default:
            return state
    }
};

module.exports = userNotifications;
