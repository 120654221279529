const ACCOUNT_TYPES = {
    cuentaCorriente : {
        cuentaNominaOpen: '300805',
        cuentaCorrienteOperativa: '300800',
        cuentaCorrienteOperativaHBF: '300801',
        cuentaCorrienteEspecial: '300002',
        cuentaDeDerivados: '300804',
        cuenta123Openbank: '300807',
        cuentaCorrienteOperativaDivisas: '300803',
        otras: '300'
    },
    cuentaAhorro: {
        cuentaJunior: '301101',
        cuentaOpen: '301001',
        cuentaAhorroEmpresa: '301002',
        cuentaVivienda: '301003',
        cuentaOpenPlus: '301004',
        cuentaMasOpen: '301005',
        eCuenta: '301006',
        cuentaBienvenida: '301007',
        otras: '301'
    },
    creditoInversion: {
        creditoInversion: '100800',
        creditoMigracion: '100801',
        otras: '100'
    }
}

function sortAccounts (accounts, accountsById) {

    function getAccountTypeCode (productCode, subTypeCode) {
        const code = productCode.trim() + subTypeCode.trim();

        switch (code) {
            case ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteEspecial:
            case ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteOperativa:
            case ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteOperativaHBF:
            case ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteOperativaDivisas:
            case ACCOUNT_TYPES.cuentaCorriente.cuentaDeDerivados:
            case ACCOUNT_TYPES.cuentaCorriente.cuentaNominaOpen:
            case ACCOUNT_TYPES.cuentaCorriente.cuenta123Openbank:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaOpen:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaAhorroEmpresa:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaVivienda:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaOpenPlus:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaMasOpen:
            case ACCOUNT_TYPES.cuentaAhorro.eCuenta:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaBienvenida:
            case ACCOUNT_TYPES.cuentaAhorro.cuentaJunior:
            case ACCOUNT_TYPES.creditoInversion.creditoInversion:
            case ACCOUNT_TYPES.creditoInversion.creditoMigracion:
                return code;
        }

        return productCode.trim();
    }

    function orderByAliasAndIban (accountIdPrev, accountIdNext) {
        const accountPrev = accountsById[accountIdPrev];
        const accountNext = accountsById[accountIdNext];
        const aliasComparationResult = accountPrev.alias.localeCompare(accountNext.alias);
        let result;

        if (aliasComparationResult == 0) {
            result = accountPrev.iban.localeCompare(accountNext.iban);
        }
        else {
            result = aliasComparationResult;
        }

        return result;
    }

    function getAccountsByOwnership (ownership, accounts) {
        return accounts.sort(orderByAliasAndIban).filter(accountId => {
            const account = accountsById[accountId];
            const accountOwnership = account.interventionType.trim();

            return ownership === accountOwnership;
        });
    }

    function getAccountsByCode (code) {
        const filteredAccounts = accounts.filter(accountId => {
            const account = accountsById[accountId];
            const productCode = account.newProduct;
            const subproductCode = account.accountType;
            const productTotalCode = getAccountTypeCode(productCode, subproductCode);

            return productTotalCode === code;
        });

        if (filteredAccounts.length > 1) {
            return [].concat(
                getAccountsByOwnership('TITULAR', filteredAccounts),
                getAccountsByOwnership('AUTORIZADO', filteredAccounts),
                getAccountsByOwnership('APODERADO', filteredAccounts),
                getAccountsByOwnership('REPRESENTANTE LEGAL', filteredAccounts),
                getAccountsByOwnership('PROPIETARIO', filteredAccounts),
                getAccountsByOwnership('MIEMBRO ADICIONAL', filteredAccounts),
                getAccountsByOwnership('ADMINISTRADOR /REP LEG JURID', filteredAccounts)
            );
        }

        return filteredAccounts;
    }

    return [].concat(
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuentaNominaOpen),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteOperativa),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteOperativaHBF),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteEspecial),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuentaDeDerivados),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuenta123Openbank),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.otras),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaJunior),
        getAccountsByCode(ACCOUNT_TYPES.cuentaCorriente.cuentaCorrienteOperativaDivisas),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaOpen),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaAhorroEmpresa),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaVivienda),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaOpenPlus),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaMasOpen),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.eCuenta),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.cuentaBienvenida),
        getAccountsByCode(ACCOUNT_TYPES.cuentaAhorro.otras),
        getAccountsByCode(ACCOUNT_TYPES.creditoInversion.creditoInversion),
        getAccountsByCode(ACCOUNT_TYPES.creditoInversion.creditoMigracion),
        getAccountsByCode(ACCOUNT_TYPES.creditoInversion.otras)
    );
}

module.exports = {
    ACCOUNT_TYPES,
    sortAccounts
};
