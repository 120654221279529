const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    let state = Immutable.fromJS({
        isFetching: false,
        error: '',
        errorGDPR: false,
        consents: {},
        success: false,
        visibleStep: 1,
        gdprCommercial: true,
        steps: [
            {
                title: '',
                order: 1,
                footer_text: '',
                position: 'first',
                valid: 'false',
                components: [
                    {
                    }
                ]
            },
            {
                title: '',
                order: 2,
                footer_text: '',
                position: 'last',
                valid: 'false',
                components: [
                    {
                    }
                ]
            },
        ]
    });
    return state;
}

function updateConsents(state, response) {
    let newState = state.set('consents', response.consents);
    return (newState);
}

function updateConsentsProfile(state, response) {
    let newState = state.set('consents', response.toJS());
    return (newState);
}

function updateFirstConsents(state) {
    let newState = state.set('visibleStep', 2);
    return (newState);
}

function updateSecondConsents(state) {
    let newState = state.set('visibleStep', 1);
    return (newState);
}


function consentsProfile(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CONSENTS_PROFILE_REQUEST:
            return state.merge({
                isFetching: false
            });
        case actionTypes.CONSENTS_PROFILE_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.CONSENTS_PROFILE_SUCCESS:
            return updateConsents(state, action.payload.response);
        case actionTypes.UPDATE_RESET_CONSENTS_GDPR:
            return setInitialState();
        case actionTypes.UPDATE_CONSENTS_GDPR_SUCCESS:
            return state.set('success', true)
                        .set('errorGDPR', false)
                        .set('gdprCommercial', action.valueCommercial);
        case actionTypes.UPDATE_CONSENTS_GDPR_ERROR:
            return state.set('errorGDPR', true)
                .set('success', false);
        case actionTypes.UPDATE_CONSENTS_NEXT_STEP:
            return updateFirstConsents(state);
        case actionTypes.UPDATE_CONSENTS_LAST_STEP:
            return updateSecondConsents(state);
        case actionTypes.UPDATE_CONSENTS_PROFILE_SUCCESS:
            return updateConsentsProfile(state, action.payload.consents);
        default:
            return state;
    }
}

module.exports = consentsProfile;
