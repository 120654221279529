// @ vendors
const Immutable = require('immutable');
// @ constants
const { FIRST_STEP } = require('constants/index');
const actionTypes = require('constants/contactCenter/actionTypes');
const cancelCardPayLaterStep1 = require('./contactCenter/cancelCardPayLater/cancelCardPayLaterStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            cancelCardPayLaterStep1(undefined, { type: null }),
            { hasSignaturePattern: true }
        ],
        visibleStep: 1,
        signatureKeyFailure: false,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        errorReceived: false,
        isPayLater: false,
        cancelledAmount: '',
        currency: '',
        dueDate: '',
        numero: '',
        tiposit: '',
        hasPayLaterMovements: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cancelCardPayLaterStep1(step, action);
        }
    })
}

const initialState = setInitialState();

function cancelCardPayLater(state = initialState, action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CANCEL_CARD_PAY_LATER_STEP_RESET:
            return state.merge({
                visibleStep: 1,
                errorReceived: false,
            });
        case actionTypes.CANCEL_CARD_PAY_LATER_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.CANCEL_CARD_PAY_LATER_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
        case actionTypes.CANCEL_CARD_PAY_LATER_INFORMATION_REQUEST:
            return state.merge({
                isPayLater: true

            });
        case actionTypes.CANCEL_CARD_PAY_LATER_INFORMATION_SUCCESS:
            return action.payload.movements === undefined ? initialState : state.merge({
                cancelledAmount: action.payload.movements.impopera,
                currency: action.payload.movements.codmon,
                dueDate: action.payload.movements.fechape,
                numero: action.payload.movements.numero,
                tiposit: action.payload.movements.tiposit,
                hasPayLaterMovements: true
            });
        case actionTypes.CANCEL_CARD_PAY_LATER_SET_STEP_SUCCESS:
            return state.merge(initialState, {
                submitSuccessful: true,
                hasPayLaterMovements: false
            });
        case actionTypes.CANCEL_CARD_PAY_LATER_SET_STEP_ERROR:
            return state.merge({
                errorReceived: true,
                hasPayLaterMovements:true
            });
        case actionTypes.CANCEL_CARD_PAY_LATER_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.CANCEL_CARD_PAY_LATER_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        default:
            return state
    }
}

module.exports = cancelCardPayLater;
