const apiURLBuilder = require('core/apiURLBuilder')

function showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/pdf" })
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    } else {
        var link = document.createElement('a');
        //createObjectURL returns a blob URL as a string.
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.click()
    }
}

function pdfBase64ToBinary(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function cleanStartBase64(base64) {
    return base64.split(',')[1];
}

function getUrlDocument(isin, documentType, originCountry) {
    return apiURLBuilder.getURL('morningstarFundDocumentPDF', { isin: isin, documentType: documentType, originCountry: originCountry })
}

function getAlternativeFundsDocumentsUrl(isin, documentType, originCountry) {
    return apiURLBuilder.getURL('allFundsLegalDocuments', { isin: isin, documentType: documentType, originCountry: originCountry })
}

export { showFile, pdfBase64ToBinary, cleanStartBase64, getUrlDocument, getAlternativeFundsDocumentsUrl }
