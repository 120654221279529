// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, DATE_FILTER_NONE } = require('constants/index');
// @ utilities
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    fetchingPdf: false,
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        },
        amount: {
            isActive: false,
            from: {
                amount: null,
                currency: EURO_TEXT
            },
            to: {
                amount: null,
                currency: EURO_TEXT
            }
        },
        currency: {
            isActive: false,
            value: ''
        }
    }
});

function getAccountIban(account) {
    return !!account ? trim(`${account.pais}${account.digitodecontrol}${account.codbban}`) : false;
}

function generateBic(action) {
    const codSwift = get(action, 'payload.details.codSwift');
    const centroMsjSwift = get(action, 'payload.details.centroMsjSwift');

    if (codSwift && centroMsjSwift) {
        return `${centroMsjSwift.empresa}${centroMsjSwift.centro}${codSwift}`;
    }

    return '';
}

function parseTransfers(transfers, accountId) {
    let byId = {};
    let byOrder = [];

    transfers.forEach(movement => {
        const numerodeorden = get(movement, 'idOrdenTransfEmitida.numerodeorden');
        const producto = get(movement, 'idOrdenTransfEmitida.producto');
        const amount = get(movement, 'importeNominal.importe', 0);

        const parsedTransfer = {
            'id': hash([movement.fechaEmision, numerodeorden, producto, amount]),
            'beneficiaryName': trim(movement.beneficiario),
            'concept': movement.concepto,
            'date': movement.fechaEmision,
            'amount': {
                amount,
                'currency': get(movement, 'importeNominal.divisa', EURO_TEXT)
            },
            'reference': {
                numerodeorden,
                producto,
                center: get(movement, 'idOrdenTransfEmitida.centro.centro', '') || '',
                enterprise: get(movement, 'idOrdenTransfEmitida.centro.empresa', '') || ''
            },
            'country': movement.pais,
            'transferType': movement.tipoTransferencia,
            'accountId': accountId,
            'emitter': {
                code: get(movement, 'personaTitular.codigodepersona', '') || '',
                type: get(movement, 'personaTitular.tipodepersona', '') || ''
            },
            'payer': {
                code: get(movement, 'personaOrdenante.codigodepersona', '') || '',
                type: get(movement, 'personaOrdenante.tipodepersona', '') || ''
            },
            'isPass': movement.isPass, //This flag indicates if the movement is a transfer or a pass
            'isExpanded': movement.selected
        };

        byId[parsedTransfer.id] = parsedTransfer;
        byOrder.push(parsedTransfer.id);
    });

    return {
        byId: byId,
        byOrder: byOrder
    };
}

function parseLiquidationDetails(details) {
    const liquidationDetails = {};

    details.forEach(detail => {
        const id = detail.concepto || '';

        if (!!id) {
            liquidationDetails[id] = {
                type: detail.conceptoTraducido || '',
                beneficiaryCosts: {
                    currency: get(detail, 'gastosBeneficiario.divisa') || EURO_TEXT,
                    amount: get(detail, 'gastosBeneficiario.importe') || 0
                },
                ownerCosts: {
                    currency: get(detail, 'gastosOrdenante.divisa') || EURO_TEXT,
                    amount: get(detail, 'gastosOrdenante.importe') || 0
                }
            }
        }
    });

    return liquidationDetails;
}

function emitedTransfersReducer(state = initialState, action) {
    let parsedTransfers;

    switch (action.type) {
        case actionTypes.FETCH_EMITED_TRANSFERS_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {},
                }
            });
        case actionTypes.FETCH_EMITED_TRANSFERS_NEXT_PAGE_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loadingNextPage: true
                }
            });
        case actionTypes.FETCH_EMITED_TRANSFERS_SUCCESS:
            parsedTransfers = parseTransfers(action.payload.transfers, action.payload.accountId);
            return state.set(action.payload.accountId, Immutable.fromJS({
                nextPage: action.payload.nextPage,
                byOrder: parsedTransfers.byOrder,
                byId: parsedTransfers.byId,
                loading: false
            }));
        case actionTypes.FETCH_EMITED_TRANSFERS_NEXT_PAGE_SUCCESS:
            const currentMovements = state.get(action.payload.accountId);
            parsedTransfers = parseTransfers(action.payload.transfers, action.payload.accountId);
            return state.mergeDeep({
                [action.payload.accountId]: {
                    nextPage: action.payload.nextPage,
                    byOrder: currentMovements.get('byOrder').concat(Immutable.fromJS(parsedTransfers.byOrder)),
                    byId: parsedTransfers.byId,
                    loadingNextPage: false,
                    error: false
                }
            });
        case actionTypes.FETCH_EMITED_TRANSFERS_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    loadingNextPage: false,
                    error: action.payload.error
                }
            });
        case actionTypes.SET_EMITED_TRANSFERS_FILTERS:
            const filters = action.payload.filters;
            const dateFilterSelected = get(filters, 'date.selected') !== DATE_FILTER_NONE;
            // @TODO: we need to remove this hardcoded 0 and 9999999.99 and improve this logic
            const hasAmountFilters = (get(filters, 'amount.from.amount') !== null && get(filters, 'amount.from.amount') !== 0) ||
                (get(filters, 'amount.to.amount') !== null && get(filters, 'amount.to.amount') !== 9999999.99);
            const hasCurrencyFilter = !!get(filters, 'currency.value');
            const partial = state.mergeDeep({
                filters
            });

            return partial.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected,
                    },
                    amount: {
                        isActive: hasAmountFilters
                    },
                    currency: {
                        isActive: hasCurrencyFilter
                    }
                }
            });
        case actionTypes.FETCH_EMITED_TRANSFERS_DETAILS_REQUEST:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                fetchingDetails: true
            });
        case actionTypes.SET_EMITED_TRANSFER_DETAILS_SUCCESS:
            let conceptText = get(action, 'payload.details.concepto', '') || '';
            conceptText += get(action, 'payload.details.conceptoDos', '') || '';
            conceptText += get(action, 'payload.details.conceptoTres', '') || '';
            conceptText += get(action, 'payload.details.conceptoCuatro', '') || ''; 
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                details: {
                    amount: {
                        amount: get(action, 'payload.details.importe.importe'),
                        currency: get(action, 'payload.details.importe.divisa')
                    },
                    payer: trim(get(action, 'payload.details.nombreOrdenante') || ''),
                    beneficiary: get(action, 'payload.details.nombreBeneficiario'),
                    beneficiaryAddress: get(action, 'payload.details.domicilioBenef'),
                    beneficiaryCity: get(action, 'payload.details.localidadBeneficiario'),
                    beneficiaryCountry: get(action, 'payload.details.ctaAbonoIban.pais'),
                    receiverBank: get(action, 'payload.details.bancoBeneficiario'),
                    receiverBankCity: get(action, 'payload.details.localidadBancoBeneficiario'),
                    receiverBankAddress: get(action, 'payload.details.domicilioBanco'),
                    issueDate: get(action, 'payload.details.fechaEmision'),
                    valueDate: get(action, 'payload.details.fechaValor'),
                    abonoIban: getAccountIban(get(action, 'payload.details.ctaAbonoIban')),
                    cargoIban: getAccountIban(get(action, 'payload.details.ctaCargoIban')),
                    transferType: trim(get(action, 'payload.details.tipoTransferencia') || get(action, 'payload.details.datoTraducido')) || '',
                    transferExpenses: get(action, 'payload.details.tradGastosPorCuenta'),
                    concept: conceptText,
                    bic: generateBic(action),
                    accountNumber: trim(get(action, 'payload.details.descCtaAbono', '')),
                    isPsd2Payment: get(action, "payload.details.isPsd2Payment")
                },
                fetchingDetails: false,
                fetchingLiquidations: false,
                fetchLiquidationsSuccess: false,
                liquidationsError: ''
            });
        case actionTypes.SET_EMITED_TRANSFER_DETAILS_FAILURE:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                error: action.payload.error,
                fetchingDetails: false
            });
        case actionTypes.RESET_EMITED_TRANSFERS_FILTER:
            const { filter } = action.payload;
            return state.setIn(['filters', filter], initialState.getIn(['filters', filter]));
        case actionTypes.CLEAR_EMITED_TRANSFERS_FILTERS:
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
        case actionTypes.EMITED_TRANSFER_SET_PDF_FETCHING:
            return state.mergeDeep({
                fetchingPdf: action.payload.value
            });
        case actionTypes.FETCH_EMITED_TRANSFER_LIQUIDATIONS_REQUEST:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                fetchingLiquidations: true,
                fetchLiquidationsSuccess: false,
                liquidationsError: ''
            }).setIn([action.payload.accountId, 'byId', action.payload.transferId, 'liquidations'],
                Immutable.fromJS({})
            );
        case actionTypes.FETCH_EMITED_TRANSFER_LIQUIDATIONS_SUCCESS:
            const liquidations = get(action, 'payload.liquidations');
            const details = get(action, 'payload.liquidations.listaDetalle.detalleLiquidacion');
            const liquidationDetails = parseLiquidationDetails(details);
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                fetchingLiquidations: false,
                fetchLiquidationsSuccess: true,
                liquidations: {
                    costs: {
                        codbban: trim(get(liquidations, 'ctaCargoGastos.codbban')) || '',
                        controlDigit: get(liquidations, 'ctaCargoGastos.digitodecontrol') || '',
                        country: get(liquidations, 'ctaCargoGastos.pais') || ''
                    },
                    nominal: {
                        codbban: trim(get(liquidations, 'ctaCargoNominal.codbban')) || '',
                        controlDigit: get(liquidations, 'ctaCargoNominal.digitodecontrol') || '',
                        country: get(liquidations, 'ctaCargoNominal.pais') || ''
                    },
                    counterpart: {
                        currency: get(liquidations, 'importeContravalor.divisa') || EURO_TEXT,
                        amount: get(liquidations, 'importeContravalor.importe') || 0
                    },
                    order: {
                        currency: get(liquidations, 'importeOrden.divisa') || EURO_TEXT,
                        amount: get(liquidations, 'importeOrden.importe') || 0
                    },
                    owed: {
                        currency: get(liquidations, 'importeTotAdeudado.divisa') || EURO_TEXT,
                        amount: get(liquidations, 'importeTotAdeudado.importe') || 0
                    },
                    beneficiary: {
                        currency: get(liquidations, 'importeTotBenef.divisa') || EURO_TEXT,
                        amount: get(liquidations, 'importeTotBenef.importe') || 0
                    },
                    owner: {
                        currency: get(liquidations, 'importeTotOrdenante.divisa') || EURO_TEXT,
                        amount: get(liquidations, 'importeTotOrdenante.importe') || 0
                    },
                    transferred: {
                        currency: get(liquidations, 'importeTransferido.divisa') || EURO_TEXT,
                        amount: get(liquidations, 'importeTransferido.importe') || 0
                    },
                    liquidationDetails
                }
            });
        case actionTypes.FETCH_EMITED_TRANSFER_LIQUIDATIONS_FAILURE:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                fetchingLiquidations: false,
                liquidationsError: action.payload.error
            });
        default:
            return state
    }

}

module.exports = emitedTransfersReducer;
