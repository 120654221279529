// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map({
        inProgress: false,
        success: false,
        error: '',
        scaRequiredToDownload: false,
        fileFormat: ''
    });
}

function cardsTransactionsDownload(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_CARDS_TRANSACTIONS_DOWNLOAD_REQUEST:
            return state.merge({
                inProgress: true,
                error: '',
                success: false
            });
        case actionTypes.FETCH_CARDS_TRANSACTIONS_DOWNLOAD_SUCCESS:
            return state.merge({
                inProgress: false,
                success: true
            });
        case actionTypes.FETCH_CARDS_TRANSACTIONS_DOWNLOAD_FAILURE:
            return state.merge({
                inProgress: false,
                error: action.payload.error,
            });
        case actionTypes.FETCH_CARDS_TRANSACTIONS_DOWNLOAD_SCA_REQUIRED:
            return state.merge({
                inProgress: false,
                scaRequiredToDownload: true,
                fileFormat: action.format
            });
        case actionTypes.FETCH_CARD_TRANSACTIONS_DOWNLOAD_SCA_RESET:
            return state.merge({
                fileFormat: '',
                inProgress: false,
                scaRequiredToDownload: false
            })
        default:
            return state;
    }
}

module.exports = cardsTransactionsDownload;
