// @vendors
const Immutable = require('immutable');
const moment = require('moment');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @helpers
const { getPublicSitePathByLanguaje } = require('core/apiURLBuilder');

// @constants
const { HYPHEN } = require('constants/index');

const {
    BROKER_SUBSCRIPTION_START,
    BROKER_SUBSCRIPTION_CHANGE_MANIFEST,
    BROKER_SUBSCRIPTION_VALIDATE_STEP,
    BROKER_SUBSCRIPTION_FINISH,
    BROKER_SUBSCRIPTION_DOCUMENTS_READ
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    linkCNMV: '',
    linkTriptico: '',
    acceptedManifest: !!__CONTACT_CENTER__,
    manifestInvalid: false,
    digitodecontrol: '',
    value: {
        codigoEmision: '',
        codigoValorInterno: '',
        descriptionValue: '',
        stockExchange: '-', // @hardcoded
        descriptionProcess: '',
        startDate: '',
        endDate: '',
        tipoProceso: ''
    },
    contract: {
        number: '',
        product: ''
    },
    associatedAccount: {
        number: '',
        balance: {
            amount: '',
            currency: ''
        }
    },
    walletAccountType: '',
    owner: '',
    orderNumber: '',
    stockMarket: '',
    startingDate: '',
    operationDate: '',
    shares: 0,
    valid: false,
    finished: false,
    selectedCorporateOperationIndex: -1,
    showWarning: false,
    allDocsRead: false
});

const validate = immState => {
    const acceptedManifest = immState.get('acceptedManifest');
    const manifestInvalid = !acceptedManifest;
    return immState.set('valid', acceptedManifest).set('manifestInvalid', manifestInvalid);
};

const finish = (immState, response) => {
    return immState.mergeDeep({
        finished: true,
        orderNumber: HYPHEN, // @hardcoded (franco.montenegro) Not comming from the service
        stockMarket: trim(get(response, 'descripcionBolsa')) || HYPHEN,
        startingDate: trim(get(response, 'fechaValor')),
        operationDate: moment(),
        shares: Number(get(response, 'numeroDerechos')) || 0,
        associatedAccount: {
            balance: {
                amount: Number(get(response, 'saldoCuentaAsociada.importe')),
                currency: trim(get(response, 'saldoCuentaAsociada.divisa'))
            }
        }
    });
};

const toggleManifestCheckbox = (immState) => {
    if (immState.get('allDocsRead')) {
        return immState.set('acceptedManifest', !immState.get('acceptedManifest'))
            .set('manifestInvalid', false)
            .set('showWarning', false);
    }
    else {
        return immState.set('showWarning', true);
    }
};

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
}

function brokerSubscriptionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_SUBSCRIPTION_START:
            return INITIAL_STATE.mergeDeep({
                selectedContract: Immutable.fromJS(action.payload.selectedContract),
                contractProductNumber: trim(get(action.payload.cuenta, 'fullNumber')),
                digitodecontrol: trim(get(action.payload.cuenta, 'digitodecontrol')),
                value: Immutable.fromJS(action.payload.operation),
                contract: Immutable.fromJS(action.payload.contract),
                owner: trim(action.payload.owner),
                associatedAccount: Immutable.fromJS(action.payload.associatedAccount),
                walletAccountType: action.payload.walletAccountType,
                linkTriptico: getPublicSitePathByLanguaje('broker-staticPdfValoresTriptico', {
                    isin: get(action.payload.operation, 'isin'),
                    mercado: get(action.payload.operation, 'iso')
                }),
                linkCNMV: getPublicSitePathByLanguaje('broker-staticPdfCNMV', {
                    isin: get(action.payload.operation, 'isin'),
                    mercado: get(action.payload.operation, 'iso')
                })
            });
        case BROKER_SUBSCRIPTION_CHANGE_MANIFEST:
            return toggleManifestCheckbox(state);
        case BROKER_SUBSCRIPTION_VALIDATE_STEP:
            return validate(state);
        case BROKER_SUBSCRIPTION_FINISH:
            return finish(state, action.payload.response);
        case BROKER_SUBSCRIPTION_DOCUMENTS_READ:
            return setDocumentsRead(state);
        default:
            return state;
    }
}

module.exports = brokerSubscriptionReducer;
