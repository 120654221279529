const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const lodashFind = require('lodash/collection/find');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

const initialState = Immutable.Map(
    {
        stock: 0,
        "invests": []
    }
);

function getByOperationId(activities, id) {
    return lodashFind(activities, { operationId: id });
}

function mapInvests(invests) {
    return invests.map( (inv) => {
        let spcSymbol = (get(inv, 'spcSymbol') || '');
        return {
            stock: trim(spcSymbol.substring(spcSymbol.indexOf(':') + 1)),
            buyValue: Math.round( get(inv, 'percentagePurchase') || 0),
            sellValue: Math.round( get(inv, 'percentageSale') || 0),
            instrumentId: get(inv, 'instrumentId') || null,
            miccode: get(inv, 'miccode') || null
        };
    });
}

function brokerMarketsTrendReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_MARKET_TREND_ACTIVITY_FAILURE:
            return state;
        case actionTypes.BROKER_GET_MARKET_TREND_ACTIVITY_SUCCESS:
            const generalSales = getByOperationId(action.payload.data, 1);
            return state.merge({
                stock: get(generalSales, 'percentage')
            });
        case actionTypes.BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_FAILURE:
            return state;
        case actionTypes.BROKER_GET_MARKET_TREND_ACTIVITY_BY_TRENDING_SUCCESS:
            const invests = mapInvests(action.payload.data);
            return state.merge({
                invests
            });
        default:
            return state;
    }
}

module.exports = brokerMarketsTrendReducer;
