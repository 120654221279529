const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_NONE,
} = require('utilities/contactCenter/wrapUp/constants');

const {
    FETCH_BROKER_PENSION_PLAN_FAILURE,
    FETCH_BROKER_PENSION_PLAN_SUCCESS,
    BROKER_GET_MY_INVESTMENTS_FAILURE,
    BROKER_GET_MY_INVESTMENTS_SUCCESS,
} = require('constants/actionTypes');

const ACTION_CODE_PENSION_PLAN_PERIODIC_CONTRIBUTION = 5179;
const ACTION_CODE_STOCK_EXTERNAL_TRANSFER = 5232;

module.exports = {
    [ACTION_CODE_PENSION_PLAN_PERIODIC_CONTRIBUTION]: {
        entry: {
            path: '/myprofile/broker/pensionPlansPeriodicContribution/[0-9]+-[0-9]+-[0-9]',
            success: [
                { action: FETCH_BROKER_PENSION_PLAN_SUCCESS }
            ],
            failure: [
                { action: FETCH_BROKER_PENSION_PLAN_FAILURE }
            ]
        }
    },

    [ACTION_CODE_STOCK_EXTERNAL_TRANSFER]: {
        entry: {
            path: '/myprofile/broker/externalTransferValues',
            check: (path, state) => {
                const { brokerMyInvestments } = state
                let operationState = OPERATION_LOG_NONE;

                if (brokerMyInvestments.get('fetchSuccess') && !brokerMyInvestments.get('isOutdated')) {
                    operationState = OPERATION_LOG_DONE;
                }

                return {
                    actionCode: ACTION_CODE_STOCK_EXTERNAL_TRANSFER,
                    operationState
                }
            },
            success: [
                { action: BROKER_GET_MY_INVESTMENTS_SUCCESS }
            ],
            failure: [
                { action: BROKER_GET_MY_INVESTMENTS_FAILURE }
            ]
        }
    },
}