
// @ constants
const {
    BROKER_ADVANCED_SEARCH_UTAG_NAME
} = require('constants/index');

const {
    recordLinkV2,
    PROC_INVESTMENT_BROKER_PG_NAME,
    PROC_BROKER_STOCKS_PG_NAME,
    PROC_BROKER_FUNDS_PG_NAME,
    PROC_BROKER_PLANS_PG_NAME,
    PROC_BROKER_ETFS_PG_NAME,
    PROC_BROKER_WARRANTS_PG_NAME,
    PROC_BROKER__PRODUCT_FILE,
    PROC_BROKER__MARKETS_PG_NAME,
    RA_GLOBAL_POSITION,
    RA_MANAGEMENT_PLAN,
    RA_REIMBURSEMENT,
    PROPC_RA_HIRE_MANAGEMENT_PLAN,
    SUSTAINABILITY_QUESTION_400,
    SUSTAINABILITY_QUESTION_401,
    PROC_BROKER_CONVENIENCE_TEST,
    PROC_RA_SUITABILITY_TEST,
    PROC_SIGNATURE_PATTERN,
    BROKER_EXTRAORDINARY_PENSION_PLAN,
} = require("./tagging");

const { brokerGeneralTags } = require('./taggingInfo/brokerGeneralTags');
const { brokerGeneralProductsTableTags } = require('./taggingInfo/brokerGeneralProductsTableTags');

const { brokerStocksTags } = require('./taggingInfo/brokerStocksTags');
const { brokerFundsTags } = require('./taggingInfo/brokerFundsTags');
const { brokerPlansTags } = require('./taggingInfo/brokerPlansTags');
const { brokerEtfsTags } = require('./taggingInfo/brokerEtfsTags');
const { brokerWarrantsTags } = require('./taggingInfo/brokerWarrantsTags');
const { brokerInvestementProductsTableTags } = require('./taggingInfo/brokerInvestmentProductsTags');
const { brokerAdvSearchTags } = require('./taggingInfo/brokerAdvSearchTags');
const { brokerProductFileTags } = require('./taggingInfo/brokerProductFileTags');
const { brokerMarketsTags } = require('./taggingInfo/brokerMarketsTags');
const { raGlobalPositionTags } = require('./taggingInfo/raGlobalPositionTags');
const { raReimbursementTags } = require('./taggingInfo/raReimbursement');
const { raManagementPlanTags } = require('./taggingInfo/raManagementPlanTags')
const { raHireManagementPlanTags } = require('./taggingInfo/raHireManagementPlanTags');
const { brokerConvenienceTestTags } = require('./taggingInfo/brokerConvenienceTestTags');
const { raSuitabilityTestTags } = require('./taggingInfo/raSuitabilityTestTags');
const { sustainabilityQuestion401Tags } = require('./taggingInfo/sustainabilityQuestion401Tags');
const { sustainabilityQuestion400Tags } = require('./taggingInfo/sustainabilityQuestion400Tags');
const { signaturePatternTags } = require('./taggingInfo/signaturePatternTags');
const { brokerPensionPlanExtraordinaryTags } = require('./taggingInfo/brokerPensionPlanExtraordinaryTags')

const getTaggingData = (eventTag, eventAction, brokerType, stepProcess, errorTag) => (
    {
        [PROC_INVESTMENT_BROKER_PG_NAME]: { ...brokerGeneralTags(eventTag, eventAction, brokerType), ...brokerGeneralProductsTableTags(eventTag, eventAction, brokerType) },
        [PROC_BROKER_STOCKS_PG_NAME]: { ...brokerStocksTags(eventTag, eventAction), ...brokerInvestementProductsTableTags(eventTag, eventAction, brokerType) },
        [PROC_BROKER_FUNDS_PG_NAME]: { ...brokerFundsTags(eventTag, eventAction), ...brokerInvestementProductsTableTags(eventTag, eventAction, brokerType) },
        [PROC_BROKER_PLANS_PG_NAME]: { ...brokerPlansTags(eventTag, eventAction), ...brokerInvestementProductsTableTags(eventTag, eventAction, brokerType) },
        [PROC_BROKER_ETFS_PG_NAME]: { ...brokerEtfsTags(eventTag, eventAction), ...brokerInvestementProductsTableTags(eventTag, eventAction, brokerType) },
        [PROC_BROKER_WARRANTS_PG_NAME]: { ...brokerWarrantsTags(eventTag, eventAction), ...brokerInvestementProductsTableTags(eventTag, eventAction, brokerType) },
        [BROKER_ADVANCED_SEARCH_UTAG_NAME]: brokerAdvSearchTags(),
        [PROC_BROKER__PRODUCT_FILE]: brokerProductFileTags(eventTag, eventAction),
        [PROC_BROKER__MARKETS_PG_NAME]: brokerMarketsTags(eventTag, eventAction),
        [RA_GLOBAL_POSITION]: raGlobalPositionTags(),
        [RA_REIMBURSEMENT]: raReimbursementTags(eventTag),
        [RA_MANAGEMENT_PLAN]: raManagementPlanTags(eventTag),
        [PROPC_RA_HIRE_MANAGEMENT_PLAN]: raHireManagementPlanTags(eventTag),
        [PROC_BROKER_CONVENIENCE_TEST]: brokerConvenienceTestTags(eventTag, eventAction, brokerType, stepProcess),
        [PROC_RA_SUITABILITY_TEST]: raSuitabilityTestTags(eventTag, eventAction, brokerType, stepProcess),
        [SUSTAINABILITY_QUESTION_400]: sustainabilityQuestion400Tags(),
        [SUSTAINABILITY_QUESTION_401]: sustainabilityQuestion401Tags(eventTag),
        [PROC_SIGNATURE_PATTERN]: signaturePatternTags(eventTag, eventAction, errorTag),
        [BROKER_EXTRAORDINARY_PENSION_PLAN]: brokerPensionPlanExtraordinaryTags(),
    }
)

/**
 * @Send tagging info.
 * @use taggingEventHandled(pageName, 'brokerPopular', 'etiquetaEvento', 'accionEvento');
 * @params "pageName" -> Name of the main page that shows the component, this param is the key
 * object in 'getTaggingData' function.
 * @params "event" -> Name of event tag shot, this param is the key in the object returned by functions
 * in the "getTaggingata()"
 * @params "eventTag and eventAction" are params in order to replace with variable data instead static strings
 * in tag info.
 */
const taggingEventHandled = (pageName, event, eventTag, eventAction, stepProcess, errorTag) => {
    const brokerType = pageName && pageName.split('/')[1]
    !!getTaggingData()[pageName] && getTaggingData()[pageName][event] &&
        recordLinkV2(getTaggingData(eventTag, eventAction, brokerType, stepProcess, errorTag)[pageName][event])
}

module.exports = { taggingEventHandled }
