//@helpers
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');

//@constants
const {
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
    DEPOSIT_HIRE_CONFIRMATION_MANAGER,
} = require('constants/index');

function depositTwentyFourMonthsRetention_hireParamsFn(immContractWizard, immAccount) {
    const cardDetails = immContractWizard.get('card_details');
    const depositHireData = immContractWizard.get('depositHireData');
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const immInterveners = immContractWizard.get('interveners');
    const intervenersData = immInterveners.map((intervener) => ({
        desIntervencion: intervener.get('type'),
        formaIntervencion: intervener.get('interventionWay'),
        nombre: intervener.get('name'),
        numeroPersona: intervener.getIn(['identification', 'code']),
        ordenIntervencion: intervener.get('interventionOrder'),
        tipoIntervencion: intervener.get('typeNumber'),
        tipoPersona: intervener.getIn(['identification', 'type'])
    })).toJS();

    const {
        standard: hiringDataContract_standard,
        subType: hiringDataContract_subType,
        type: hiringDataContract_type,
    } = immContractWizard.get('hiring_data_contract').toJS();

    return {
        aa1: depositHireData.get('aa1'),
        aa2: depositHireData.get('aa2'),
        aa3: depositHireData.get('aa3'),
        adelta: depositHireData.get('adelta'),
        campana: DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
        canalComercializacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        canalOperacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        codPeridoLiq: depositHireData.get('codPeridoLiq'),
        cotPeriodo: depositHireData.get('cotPeriodo'),
        cuentaSeleccionada: {
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        },
        datos: {
            dato: intervenersData
        },
        depositoB: 'S', // "N" if is Welcome Deposit, otherwise "S" //@hardcoded
        descPeriodicidad: depositHireData.get('descPeriodicidad'),
        descPlazo: depositHireData.get('descPlazo'),
        descProducto: depositHireData.get('descProducto'),
        fechaVencimiento: depositHireData.get('fechaVencimiento'),
        gestor: DEPOSIT_HIRE_CONFIRMATION_MANAGER,
        importe,
        importeMax: depositHireData.get('importeMax'),
        importeMin: depositHireData.get('importeMin'),
        indCapit: 'N', // interests capitalization "S" or "N" //@hardcoded
        indRevTo: findElementbyId(cardDetails, 'renewDeposit') ? 'S' : 'N', // Renovation type
        indala01: depositHireData.get('indala01'),
        indala02: depositHireData.get('indala02'),
        indala03: depositHireData.get('indala03'),
        indala04: depositHireData.get('indala04'),
        moneda: depositHireData.get('moneda'),
        numEmp: DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
        perLiq: depositHireData.get('perLiq'),
        plazoPos: depositHireData.get('plazoPos'),
        producto: {
            codigodeestandar: hiringDataContract_standard,
            subtipodeproducto: {
                subtipodeproducto: hiringDataContract_subType,
                tipodeproducto: {
                    tipodeproducto: hiringDataContract_type // should be the same as tipo
                }
            }
        },
        saldoCuentaSeleccionada: {
            divisa: immAccount.getIn(['balance', 'currency']),
            importe: immAccount.getIn(['balance', 'amount'])
        },
        tasa: depositHireData.get('tasa'),
        tipo: hiringDataContract_type // Should be the same as tipodeproducto
    };
}

module.exports = {
    depositTwentyFourMonthsRetention_hireParamsFn: depositTwentyFourMonthsRetention_hireParamsFn,
};
