const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP } = require('constants/index');

const cardBlockStep1 = require('./cardBlock/cardBlockStep1');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            cardBlockStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        cardReplaceSuccessful: false,
        cardNewStatus: '',// this info will come from confirmation service
        willLeave: false,
        willCancel: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardBlockStep1(step, action);
        }
    })
}

function cardBlockReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_BLOCK_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_BLOCK_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_BLOCK_RESET:
            return setInitialState();
        case actionTypes.CARD_BLOCK_COMMENTS:
        case actionTypes.CARD_BLOCK_MOTIVE:
        case actionTypes.CARD_BLOCK_REQUEST_CARD_REPLACE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.CARD_BLOCK_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_BLOCK_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.CARD_BLOCK_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                cardNewStatus: action.payload.cardNewStatus,
                inProgress: false
            })
        case actionTypes.CARD_BLOCK_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            })
        case actionTypes.CARD_BLOCK_AND_REPLACE_SUCCESS:
            return state.merge({
                cardReplaceSuccessful: true
            });
        default:
            return state
    }
}

module.exports = cardBlockReducer;
