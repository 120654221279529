// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @helpers
const { FIRST_STEP, SECOND_STEP, WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');
const actionTypes = require('constants/actionTypes');
const brokerRightsBuyStep1 = require('./brokerRightsBuy/brokerRightsBuyStep1');
const brokerRightsBuyStep2 = require('./brokerRightsBuy/brokerRightsBuyStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerRightsBuyStep1(undefined, { type: null }),
            brokerRightsBuyStep2(undefined, { type: null })
        ],
        visibleStep: 1,
        selectedCorporateOperationIndex: -1,
        orderNumber: '',
        validationServiceResponse: {},
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        dataToken: '',
        error: '',
        exAnteCostsList: {},
        exAnteDataFetching: false,
        exAnteDataError: false,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerRightsBuyStep1(step, action);
            case SECOND_STEP:
                return brokerRightsBuyStep2(step, action);
        }
    });
}

function setVisibleStep(immState, newVisibleStep) {
    return immState.set('visibleStep', newVisibleStep);
}

function getOperationStateBeforeStartingMifid(immState, operationDataBeforeMifid) {
    const immOperationStateBeforeStartingMifid = immState.merge(operationDataBeforeMifid);
    return immOperationStateBeforeStartingMifid;
}

function continueOperationFromMifid(immState, action) {
    const operationDataBeforeMifid = get(action, 'payload.operationData') || {};
    const immOperationStateBeforeMifid = getOperationStateBeforeStartingMifid(immState, operationDataBeforeMifid);
    const immFirstStepAfterContinuingFromMifid = updateStep(FIRST_STEP, immOperationStateBeforeMifid.get('steps'), action);
    const immContinueFromMifidAndAdvanceToSecondStep = setVisibleStep(immOperationStateBeforeMifid, WIZARD_SECOND_STEP);

    return immContinueFromMifidAndAdvanceToSecondStep.merge({
        steps: immFirstStepAfterContinuingFromMifid
    });
}

function brokerRightsBuyReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.BROKER_RIGHTS_BUY_CONTINUE_FROM_MIFID:
            return continueOperationFromMifid(state, action);
        case actionTypes.BROKER_RIGHTS_BUY_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_RIGHTS_BUY_ORDER_TYPE_CHANGED:
        case actionTypes.BROKER_RIGHTS_BUY_LIMITED_VALUE_CHANGED:
        case actionTypes.BROKER_RIGHTS_BUY_RIGHTS_QUANTITY_CHANGED:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_BUY_DISCLAIMER_CLAUSE_CHANGED:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_BUY_DOCUMENTS_READ:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.BROKER_RIGHTS_BUY_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case actionTypes.BROKER_RIGHTS_BUY_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.BROKER_RIGHTS_BUY_CONFIRM_REQUEST:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_BUY_CONFIRM_SUCCESS:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true,
                orderNumber: action.payload.orderNumber
            });
        case actionTypes.BROKER_RIGHTS_BUY_CONFIRM_FAILURE:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                error: action.payload.data.error
            });
        case actionTypes.BROKER_RIGHTS_BUY_SELECTED_CORPORATE_INDEX_CHANGED:
            return state.merge({
                selectedCorporateOperationIndex: action.payload.value
            });
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_REQUEST:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_SECOND_STEP:
                    return state;
            }
            return state;
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_SUCCESS:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        validationServiceResponse: action.payload.data
                    });
                case WIZARD_SECOND_STEP:
                    return state;
            }
            return state;
        case actionTypes.BROKER_RIGHTS_BUY_VALIDATE_FAILURE:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        error: action.payload.data.error
                    });
                case WIZARD_SECOND_STEP:
                    return state;
            }
            return state;
        case actionTypes.BROKER_RIGHTS_BUY_RESET:
            return setInitialState().set('selectedCorporateOperationIndex', state.get('selectedCorporateOperationIndex'));
        case actionTypes.BROKER_RIGHTS_EXTANTCOST_ERROR:
            return state.merge({
                exAnteDataFetching: false,
                exAnteDataError: true
            })
        case actionTypes.BROKER_RIGHTS_EXTANTCOST_FECHING:
            return state.merge({
                exAnteDataFetching: true,
            })
        case actionTypes.BROKER_RIGHTS_EXTANTCOST_SUCESS:
            return state.merge({
                exAnteCostsList: action.payload.response,
                exAnteDataFetching: false,
                exAnteDataError: false,
            })
        default:
            return state;
    }
}

module.exports = brokerRightsBuyReducer;
