const {
    SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE,
    SIGNATURE_PATTERN_CONFIG_REQUEST_SUCCESS,
    SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE,
    SIGNATURE_PATTERN_PASS_CODE_VALIDATION_SUCCESS,
    SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE,
    SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS
} = require('constants/actionTypes');

const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE,
    OPERATION_LOG_TYPE_ENTRY,
    ACTION_CODE_SIGNATURE_PATTERN,
    ACTION_CODE_OTP,
} = require('utilities/contactCenter/wrapUp/constants');


module.exports = {
    [ACTION_CODE_SIGNATURE_PATTERN]: {
        entry: {
            action: [
                SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE,
                SIGNATURE_PATTERN_CONFIG_REQUEST_SUCCESS
            ],
            check: (action) => {
                const {
                    payload: { allVerificationDone, requiredSMS },
                    type,
                } = action;
                let actionCode = ACTION_CODE_SIGNATURE_PATTERN;
                let operationState = OPERATION_LOG_NONE;

                if (type === SIGNATURE_PATTERN_CONFIG_REQUEST_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                } else {
                    if (!allVerificationDone) {
                        actionCode = requiredSMS ? ACTION_CODE_OTP : ACTION_CODE_SIGNATURE_PATTERN;
                        operationState = OPERATION_LOG_DONE;
                    }
                }

                return {
                    actionCode,
                    operationState
                }
            }
        },
        finish: {
            action: [
                SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE,
                SIGNATURE_PATTERN_PASS_CODE_VALIDATION_SUCCESS,
                SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE,
                SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS
            ],
            check: (action) => {
                const {
                    payload: { allVerificationDone },
                    type,
                } = action;
                let actionCode = ACTION_CODE_SIGNATURE_PATTERN;
                let operationState = OPERATION_LOG_DONE;

                if (
                    type === SIGNATURE_PATTERN_PASS_CODE_VALIDATION_FAILURE ||
                    type === SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE
                ) {
                    operationState = OPERATION_LOG_FAIL;
                    actionCode = type === SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE ? ACTION_CODE_OTP : ACTION_CODE_SIGNATURE_PATTERN;
                } else {
                    actionCode = type === SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS ? ACTION_CODE_OTP : ACTION_CODE_SIGNATURE_PATTERN;

                    if (!allVerificationDone) {
                        operationState = OPERATION_LOG_NONE;
                    }
                }

                return {
                    actionCode,
                    operationState
                }
            },
            after: (action) => {
                const { type } = action;
                const actionCode = ACTION_CODE_OTP;
                let operationState = OPERATION_LOG_NONE;

                if (type === SIGNATURE_PATTERN_SMS_VALIDATION_FAILURE) {
                    operationState = OPERATION_LOG_DONE;
                }

                return {
                    actionCode,
                    operation: OPERATION_LOG_TYPE_ENTRY,
                    operationState
                }
            }
        }
    }
}

module.exports.secondaryFlows = [
    ACTION_CODE_SIGNATURE_PATTERN,
    ACTION_CODE_OTP
]
