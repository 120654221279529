const usd = require('utilities/usd')
const { wrapupflows, secondaryFlows: allowedSecondaryFlows } = require('./wrapupflows')
const get = require('lodash/object/get')
const { wrapUpDisaster } = require('actions/contactCenter/wrapUpDisaster');
const { isDisasterRecoveryMode } = require('utilities/contactCenter/disasterRecoveryhelper');

const {
    OPERATION_LOG_TYPE_INITIALIZE,
    OPERATION_LOG_TYPE_ENTRY,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE_AND_RESET,
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_TYPE_FINISH,
    SIGNATURE_PATTERN_FAIL_NOTIFICATION,
    OTP_FAIL_NOTIFICATION,
    ACTION_CODE_PREFIX,
    ACTION_CODE_SIGNATURE_PATTERN,
    ACTION_CODE_OTP,
} = require('./constants')

// Add second flow exception if you want to not registrer ACCESO and OPERATIVA as it's
// and, eventually, register its OPERATIVA KO using the base actionCode but keeping a proper a second flow error Message
const secondFlowException = {
    [`${ACTION_CODE_PREFIX}${ACTION_CODE_OTP}`] : {
        errorMessage:OTP_FAIL_NOTIFICATION
    },
    [`${ACTION_CODE_PREFIX}${ACTION_CODE_SIGNATURE_PATTERN}`] : {
        errorMessage:SIGNATURE_PATTERN_FAIL_NOTIFICATION
    }
}

const { unwrapActionCode, checkFinishActionsCode } = require('./utilities')

const generateNavigationId = () => (parseInt('' + (new Date().getTime() + Math.floor(Math.random() * 10000000)).toFixed())).toString(16)

const currentFlow = {
    actionCode: null,
    navigationId: null,
    state: null,
    stateUpdatedAt: null,
    executedActions: {
        [OPERATION_LOG_TYPE_ENTRY]: [],
        [OPERATION_LOG_TYPE_FINISH]: []
    }
}

const secondaryFlows = {
    
}

let latestFlow

const checkFinishDiffCode = () => {
    const finishActionCode = checkFinishActionsCode.get(0);
    if (finishActionCode) {
        currentFlow.actionCode = finishActionCode
        latestFlow = finishActionCode;
    }
}

const updateFlow = (update = {}, actionCode = undefined) => {
    const updatedAt = {  }
    if (update.state) {
        updatedAt.stateUpdatedAt = new Date()
    }

    if (!actionCode || !allowedSecondaryFlows.includes(actionCode)) {
        Object.assign(currentFlow, update, updatedAt)
        latestFlow = currentFlow.actionCode
    } else {
        if (secondaryFlows[actionCode]) {
            Object.assign(secondaryFlows[actionCode], update, updatedAt)
        } else if (allowedSecondaryFlows.includes(actionCode)) {
            secondaryFlows[actionCode] = Object.assign({}, update, updatedAt)
        }
        latestFlow = null
    }
}

const getFromFlow = (attr, actionCode = undefined) => {
    // Force same navigationId for the primary and secondaries flows
    if (attr === 'navigationId') return currentFlow[attr]

    if (!actionCode || currentFlow.actionCode === actionCode) {
        return get(currentFlow, attr)
    } else {
        if (secondaryFlows[actionCode]) {
            return get(secondaryFlows[actionCode], attr)
        }
    }
}

const resetTraceFlowEvent = () => {
    latestFlow = null
    currentFlow.actionCode = null
    currentFlow.navigationId = null
    currentFlow.state = null
    currentFlow.stateUpdatedAt = null
    currentFlow.executedActions = {
        [OPERATION_LOG_TYPE_ENTRY]: [],
        [OPERATION_LOG_TYPE_FINISH]: []
    }
    for (let prop in secondaryFlows) {
        delete secondaryFlows[prop]
    }
    checkFinishActionsCode.clear();
}

const notifyUsdWrapUp = ({ actionCode, failed, errorMessage = '', navigationId, operation }) => {
    // Remove string prefix before its notification
    const originalActionCode = unwrapActionCode(actionCode)
    if (isDisasterRecoveryMode()){
        const params = {
            actionCode: originalActionCode,
            operationError: failed,
            errorMessage: JSON.stringify(errorMessage),
            navigationId,
            operation
        }
        traceFlowEvent.setUp.dispatch(wrapUpDisaster(params));
    } else {
        usd.registerTrace(originalActionCode, failed, errorMessage, { navigationId, operation })
    }
}

/*
 * If the same action code is called within 3 seconds in some specific states, it migth be
 * a redirection loop that shouldn't be recorded
 */
const mightBeRedirectionLoop = (actionCode) => {
    const currentTime = (new Date()).getTime()
    const isSameActionCode = currentFlow.actionCode === actionCode
    const isStartingState = !currentFlow.state||
        currentFlow.state === OPERATION_LOG_TYPE_INITIALIZE ||
        currentFlow.state === OPERATION_LOG_TYPE_ENTRY
    const hasEllapsedFewTime = currentFlow.stateUpdatedAt && (currentTime - currentFlow.stateUpdatedAt.getTime() < 3000)

    return isSameActionCode && isStartingState && hasEllapsedFewTime
}

const isPrimaryFlow = actionCode => !allowedSecondaryFlows.includes(actionCode)

const isSecondaryFlow = actionCode => allowedSecondaryFlows.includes(actionCode)

let flows = wrapupflows

const traceFlowEvent = {
    setUp: {
        dispatch: () => {}
    },
    reset: resetTraceFlowEvent,
    start: actionCode => {
        if (!mightBeRedirectionLoop(actionCode)) {
            // Should reset if a primary code
            if (!allowedSecondaryFlows.includes(actionCode)) {
                resetTraceFlowEvent()
            }
            updateFlow({
                navigationId: generateNavigationId(),
                actionCode: actionCode,
                state: OPERATION_LOG_TYPE_INITIALIZE
            }, actionCode)
        }
    },
    getCurrentNavigationId: () => currentFlow.navigationId,
    getCurrentActionCode: () => currentFlow.actionCode,
    logExecutedAction: (logType, actionType, actionCode) => {
        updateFlow({
            executedActions: Object.assign({},
                getFromFlow('executedActions', actionCode),
                {
                    [logType]: [
                        ...getFromFlow(`executedActions.${logType}`, actionCode),
                        actionType
                    ]
                }
            )
        }, actionCode)
    },
    getExecutedActions: (logType, actionCode) => getFromFlow(`executedActions.${logType}`, actionCode),
    notify: (operation, operationState, errorMessage = '') => {
        checkFinishDiffCode()
        const actionCode = latestFlow || getFromFlow('actionCode')
        traceFlowEvent.notifyFlow(actionCode, operation, operationState, errorMessage)
    },
    getCurrentState: () => currentFlow.state,
    notifyFlow: (actionCode, operation, operationState, errorMessage = '') => {
        const navigationId = getFromFlow('navigationId', actionCode)
        const failed = operationState === OPERATION_LOG_FAIL

        let reportedNavigationId = navigationId
        if (get(flows, `${actionCode}.type`) === FLOW_TYPE_ONLY_ACCESS) {
            reportedNavigationId = undefined
        }

        const notification = { actionCode, failed, errorMessage, navigationId: reportedNavigationId }
        if (actionCode && currentFlow.actionCode) {
            if (operation === OPERATION_LOG_TYPE_ENTRY) {
                // Avoid notification if there is a loop in the router                
                if (getFromFlow('state', actionCode) !== OPERATION_LOG_TYPE_ENTRY) {
                    updateFlow({ state: OPERATION_LOG_TYPE_ENTRY }, actionCode);

                    //UPDATE: if the action is not a secondary action with an exception so notify the ACCESS
                    if (!(isSecondaryFlow(actionCode) && secondFlowException.hasOwnProperty(actionCode))) {
                        notifyUsdWrapUp(Object.assign({}, notification, {
                            operation: 'ACCESO'
                        }))
                    }

                    if (getFromFlow('actionCode') === actionCode && operationState === OPERATION_LOG_FAIL) {
                        // If it's primary flow and has error, reset the whole flow
                        resetTraceFlowEvent()
                    } else if (isSecondaryFlow(actionCode) && failed) {
                        // If it's secondary flow and has error, remove the secondary flow from the history
                        delete secondaryFlows[actionCode]
                    }
                }
            } else if (getFromFlow('state', actionCode) === OPERATION_LOG_TYPE_ENTRY
                && operation === OPERATION_LOG_TYPE_FINISH
            ) {
                // UPDATE: Check if action is on the secondary flow and report a FAILURE notification
                // usign the base action code with second flow failure reason
                if (isSecondaryFlow(actionCode) && secondFlowException.hasOwnProperty(actionCode)) {
                    if (failed) {
                        const currentFlowFailNotification = {
                            actionCode: currentFlow.actionCode,
                            failed,
                            errorMessage: secondFlowException[actionCode].errorMessage,
                            navigationId: reportedNavigationId
                        };
                        notifyUsdWrapUp(Object.assign({}, currentFlowFailNotification, {
                            operation: 'OPERATIVA'
                        }))
                    }                 
                }
                else {
                    if(operationState === OPERATION_LOG_NONE_AND_RESET){
                        resetTraceFlowEvent()
                    } else {
                        notifyUsdWrapUp(Object.assign({}, notification, {
                            operation: 'OPERATIVA'
                        }))
                    }
                }

                updateFlow({ state: OPERATION_LOG_TYPE_FINISH }, actionCode)

                if (isPrimaryFlow(actionCode)) {
                    // If it's primary flow and has error, reset the whole flow
                    resetTraceFlowEvent()
                } else if (isSecondaryFlow(actionCode) && failed) {
                    // If it's secondary flow and has error, remove the secondary flow from the history
                    delete secondaryFlows[actionCode]
                } else {
                    currentFlow.stateUpdatedAt = new Date()
                }
            } else if (getFromFlow('state', currentFlow.actionCode) === OPERATION_LOG_TYPE_ENTRY
                && operation === OPERATION_LOG_TYPE_FINISH
            ) {
                notifyUsdWrapUp(Object.assign({}, notification, {
                    operation: 'OPERATIVA'
                }))
                updateFlow({ state: OPERATION_LOG_TYPE_FINISH }, currentFlow.actionCode)
            } 
        }
    }
}

const setFlows = inputFlows => {
    flows = inputFlows
}

module.exports = {
    traceFlowEvent,
    setFlows
}
