const {
    FLOW_TYPE_ONLY_ACCESS
} = require('../constants')

const {
    BROKER_CONVENIENCE_MIFID_TEST_FINISH
} = require('constants/actionTypes')

const {
    OPERATION_LOG_DONE
} = require('utilities/contactCenter/wrapUp/constants')

const ACTION_CODE_CONVENIENCE_TEST_MIFID_WIZARD = 5165
const ACTION_CODE_CONSULT_MIFID = 5166
const ACTION_CODE_CONVENIENCE_TEST_MIFID = 5167

module.exports = {
    [ACTION_CODE_CONVENIENCE_TEST_MIFID]: {
        entry: {
            path: '/myprofile/broker/convenience-mifid-test/wizard',
            check: (path, state) => {
                const consulting = state.brokerConvenienceMiFIDTest.get("consulting");
                const operationState = OPERATION_LOG_DONE;
                let actionCode = ACTION_CODE_CONVENIENCE_TEST_MIFID;

                if (consulting) {
                    actionCode = ACTION_CODE_CONVENIENCE_TEST_MIFID_WIZARD;
                }

                return {
                    operationState,
                    actionCode
                }
            }
        },
        finish: {
            action: [ BROKER_CONVENIENCE_MIFID_TEST_FINISH ]
        }
    },
    [ACTION_CODE_CONSULT_MIFID]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
                path: '/myprofile/broker/consult-mifid'
            }
    }
}

module.exports.secondaryFlows = [
    ACTION_CODE_CONVENIENCE_TEST_MIFID
]
