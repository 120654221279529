// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
// @ constants
const actionTypes = require('constants/actionTypes');
const { DEPOSIT_INTERVENTION_OWNER, EURO_TEXT } = require('constants/index');
// @ utilities
const { groupByCurrencyAndCalculateTotally } = require('utilities/APIParsingHelper');
const { getLastNChars } = require('utilities/stringHelper');

function setInitialState() {
    return Immutable.fromJS({
        data: [],
        totalAvailableBalance: [],
        allSavings: [],
        excludedSavings: 0
    });
}

function formatRequestSavings(savings) {

    const allSavings = savings.map(saving => {
        return {
            alias: saving.descripcion,
            accountHolder: get(saving, 'nombretitular', ''),
            description: getLastNChars(get(saving, 'cnuevo.numerodecontrato', 0)),
            cardNumber: saving.panmdp,
            contractNumber: get(saving, 'cnuevo.numerodecontrato'),
            fullContractNumber: get(saving, 'contratoIban.codbban', 0),
            product:  get(saving, 'cnuevo.producto', ''),
            ...(__CONTACT_CENTER__ ? { productDescription: get(saving, 'catalogData.estandar', '') } : {}),
            isHolder: trim(get(saving, 'descIntervenciones.nomTipInterv', '')) === DEPOSIT_INTERVENTION_OWNER,
            totalAvailableBalance: {
                amount: get(saving, 'saldo1.importe', 0) || 0,
                currency: get(saving, 'saldo1.divisa', EURO_TEXT) || EURO_TEXT
            },
            subTypeProduct: get(saving, 'criterios.c1.subtipoproducto', 0),
            id: `${get(saving, 'cnuevo.numerodecontrato', '')}${get(saving, 'cnuevo.producto', '')}`,
            interventionType: trim(get(saving, 'descIntervenciones.nomTipInterv', '')),
            valid: get(saving, 'filtros.valido').trim() === 'S'
        }
    });

    const data = allSavings.filter(saving => saving.valid);

    return {
        data,
        allSavings,
        totalAvailableBalance: groupByCurrencyAndCalculateTotally(data, 'totalAvailableBalance'),
        excludedSavings: allSavings.length - data.length
    }
}


function savings(state = setInitialState(), action) {
    let newState;
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            return state.merge(formatRequestSavings(action.payload));

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_SAVING_SUCCESS:
              newState = setInitialState();

              const newAllSavings = state.get('allSavings').map(saving => {
                  if (saving.get('id') === action.payload.savingId) {
                      return saving.set('valid', action.payload.valid);
                  }
                  return saving;
              });

              const newData = newAllSavings.filter(saving => saving.get('valid'));

              return newState.mergeDeep({
                  data: newData,
                  allSavings:newAllSavings,
                  totalAvailableBalance: groupByCurrencyAndCalculateTotally(newData.toJS(), 'totalAvailableBalance'),
                  excludedSavings: newAllSavings.size - newData.size
              });

        default:
            return state
    }
}

module.exports = savings;
