// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
 
// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
} = require('constants/index');

// @reducers
const hireCardECardStep1 = require('./hireCardECard/hireCardECardStep1');
const hireCardECardStep2 = require('./hireCardECard/hireCardECardStep2');
const hireCardECardStep3 = require('./hireCardECard/hireCardECardStep3');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorMsg: '',
    isFetching: false,
    steps: [
        hireCardECardStep1(),
        hireCardECardStep2(),
        hireCardECardStep3(),
    ],
    isFetchingEcardPdf: false,
    pdfDownloadError: false,
    textRelief: '',
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag:false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardECardStep1(step, action);
            case SECOND_STEP:
                return hireCardECardStep2(step, action);
            case THIRD_STEP:
                return hireCardECardStep3(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function hireCardECardReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_CARD_ECARD_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
            case actionTypes.HIRE_CARD_LOADING:
                return state.merge({
                    loadingFlag: action.payload.loadingFlag
                });
        case actionTypes.HIRE_CARD_ECARD_SET_ACCOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_ECARD_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                steps: updateStep(THIRD_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_ECARD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_CARD_ECARD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_CARD_ECARD_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_CARD_ECARD_SUCCESS:
            const { response } = action.payload;

            return state.merge({
                isFetching: false,
                response: {
                    cardContract: {
                        contractNumber: response.contratoTarjeta && response.contratoTarjeta.numerodecontrato,
                        product: response.contratoTarjeta && response.contratoTarjeta.producto
                    },
                    cardNumber: response.numTarjeta,
                    customerAddress: response.descDomicilioCliente,
                    CVCCode: {
                        denAttribute: response.codigoCVC && response.codigoCVC.denomatributo,
                        valueAttribute: response.codigoCVC && response.codigoCVC.valoratributo
                    },
                    expirationDate: response.fechaVencimiento,
                    info: response.info
                },
                submitSuccessful: true
            });

        case actionTypes.HIRE_CARD_ECARD_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                isFetching: false
            });
        

        case actionTypes.HIRE_CARD_ECARD_GENERATE_PDF_REQUEST:
            return state.merge({
                isFetchingEcardPdf: true,
                pdfDownloadError: false
            });

        case actionTypes.HIRE_CARD_ECARD_GENERATE_PDF_FAILURE:
            return state.merge({
                isFetchingEcardPdf: false,
                pdfDownloadError: true
            });

        case actionTypes.HIRE_CARD_ECARD_GENERATE_PDF_SUCCESS:
            return state.merge({
                isFetchingEcardPdf: false,
                pdfDownloadError: false
            });

        case actionTypes.HIRE_CARD_ECARD_GET_TEXT_RELIEF_SUCCESS:
            return state.mergeDeep({
                response: {
                    textRelief: trim(action.payload.response.textoRelief)
                }
            });

        case actionTypes.HIRE_CARD_ECARD_RESET:
            return initialState;

        case actionTypes.HIRE_CARD_ECARD_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        default:
            return state;
    }
}

module.exports = hireCardECardReducer;
