const actionTypes = require('constants/contactCenter/actionTypes');
const Immutable = require('immutable');

const setInitialState = () => {
    return Immutable.Map().merge({
        cookieCredential: '',
        bankToken: '',
        inOffice: false,
        isFetching: false,
        error: '',
        success: false
    });
};

const agentAuthentication = (state = setInitialState(), action) => {

    switch (action.type) {
        case actionTypes.AGENT_AUTHENTICATION_SUCCESS:
            return state.merge({
                cookieCredential: action.payload.cookieCredential,
                bankToken: action.payload.bankToken,
                isFetching: false,
                success: true,
                inOffice: action.payload.channel.includes('OFI')
            });


        case actionTypes.AGENT_AUTHENTICATION_REQUEST:
            return state.merge({
                isFetching: true,
                success: false
            });

        case actionTypes.AGENT_AUTHENTICATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
                success: false
            });

        case actionTypes.AGENT_OFFICE_VALIDATION:
            return state.merge({
                inOffice: action.payload
            });

        default:
            return state;
    }
};

module.exports = agentAuthentication;
