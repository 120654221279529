// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND
} = require('constants/index');

function setInitialState() {
   return Immutable.Map().merge({
       valid: false,
       validOriginPlan: true,
       originPlanData: {},
       isFetching: false,
       error: false,
       success: false,
       notFound: false
   });
}

function validateStep(step){
    const validOriginPlan = step.get('originPlanData').size && 
        (step.get('success') || step.get('notFound'));
    return step.merge({
        valid: validOriginPlan,
        validOriginPlan: validOriginPlan
    });
}

function originPlanDataFetchNotAvailable(state) {
    return state.get('originPlanData').merge({
        gestionEntity: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        depositEntity: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        pensionFund: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND
    });
}

function originPlanDataFetchAvailable(response, state) {
    return state.get('originPlanData').merge({
        gestionEntity: response.ProviderCompany.Name,
        depositEntity: response.CustodianCompanies[0].Name, 
        pensionFund: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND
    });
}

function brokerInternalTransferFundsStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_ORIGIN:
            return state.merge({
                originPlanData: action.payload.planData,
                validOriginPlan: true
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_DATA_REQUEST:
            return state.merge({
                isFetching: true,
                error: false,
                notFound: false,
                success: false
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_NOT_FOUND:
            return state.merge({
                isFetching: false,
                notFound: true,
                originPlanData: originPlanDataFetchNotAvailable(state)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                originPlanData: originPlanDataFetchAvailable(action.payload.securityDetails, state)
            })
        default:
           return state;
   }
}

module.exports = brokerInternalTransferFundsStep1;
