const map = require('lodash/collection/map');
const parseInt = require('lodash/string/parseInt');
const padLeft = require('lodash/string/padLeft');
const { NON_ALPHANUMERIC_REGEX, NON_NUMERIC_REGEXP } = require('constants/index');
const { getPublicSiteUrl } = require('core/apiURLBuilder');
const get = require('lodash/object/get');

const toCamelCase = (str = '') => {
    return str.replace(/[a-z\u00E0-\u00FC]\S*/gi, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const toFirstUppercase = (str = '') => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

const toFirstLowerCase = (str = '') => {
    return str.charAt(0).toLowerCase() + str.substr(1);
}

const capitalize = (str = '') => {
    return str.charAt(0).toUpperCase() + str.substr(1);
};

const toNumericArray = (str = '', separator = ' ') => {
    return map(str.split(separator), parseInt);
};

const capitalizeFirstLetter = (str = '') => {
    if (typeof str !== 'string') return undefined
    return str.charAt(0).toUpperCase() + str.substr(1, str.length).toLowerCase();
}

const getLastNChars = (str = '', qtty = 4, symbol = '.', qttySymbol = 3) => {
    if (!str) return '';

    const newStr = str.substring(str.length - qtty, str.length);
    const symbolPrefix = symbol.repeat(qttySymbol);

    return `${symbolPrefix}${newStr}`;
};

const getFirstNChars = (str = '', qtty) => {

    if(!str) return '';

    if (str.length <= qtty) return str;

    const newStr = str.substring(0, qtty);

    return `${newStr}...`;
};

const decodeHTMLEntities = (str = '') => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    let response = get(doc, 'documentElement.textContent', '');

    if (!response) { // FIX FOR IE11
        response = get(doc, 'documentElement.innerText', '');
    }

    return response;
};

const cleanUpAccents = (str = '') => {
    return str
        .replace(/[Á]/g, 'A')
        .replace(/[á]/g, 'a')
        .replace(/[É]/g, 'E')
        .replace(/[é]/g, 'e')
        .replace(/[Í]/g, 'I')
        .replace(/[í]/g, 'i')
        .replace(/[Ó]/g, 'O')
        .replace(/[ó]/g, 'o')
        .replace(/[Ú]/g, 'U')
        .replace(/[ú]/g, 'u')
        .replace(/[Ü]/g, 'U')
        .replace(/[ü]/g, 'u');
};

const nonNumericFilter = (str = '') => {
    return str.replace(NON_NUMERIC_REGEXP, '');
};

const nonAlphanumericFilter = (string) => {
    return string.replace(NON_ALPHANUMERIC_REGEX, '');
}

const formatVolume = (number, sectionDelimiter = '.', decimalLimiter = ',') => {
    let parsedNumber = parseFloat(number);

    if (parsedNumber !== number && number) {
        parsedNumber = parseFloat(number.replace(decimalLimiter, sectionDelimiter))
    }
    if (!isNaN(parsedNumber)) {
        const parts = parsedNumber.toString()
            .replace(sectionDelimiter, decimalLimiter)
            .split(decimalLimiter);
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sectionDelimiter);

        return parts.join(decimalLimiter);
    } else {
        return '';
    }
};

const replaceNChars = (str, at = 0, qtty = 10, rch = '*') => {
    if (!str) return '';

    return str.replace(str.substr(at, qtty), function (txt) {
        return txt.replace(/./g, rch);
    });
};

const stringToBoolean = (value) => {
    if (value && typeof value === 'string') {
        if (value.toLowerCase() === 'true') return true;
        if (value.toLowerCase() === 'false') return false;
    }

    return value;
};

const isString = (value) => {
   return !!(value && typeof value === 'string')

};


const addOrdinal = (lang, n) => {
    if (lang === 'en') {
        const s = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;

        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    if (lang === 'es') {
        const s = ['er', 'do', 'to', 'vo', 'mo', 'no'];
        let r = n;

        if (n > 10) {
            r += s[3];
        } else {
            switch (n) {
                case 1:
                case 3:
                    r += s[0];
                    break;
                case 2:
                    r += s[1];
                    break;
                case 4:
                case 5:
                case 6:
                    r += s[2];
                    break;
                case 8:
                    r += s[3];
                    break;
                case 7:
                case 10:
                    r += s[4];
                    break;
                case 9:
                    r += s[5];
                    break;
            }
        }

        return r;
    }
};
const addSpacesBetweenWords = (...words) => {
    let spacedWords = '';

    for (let i = 0; i < words.length; i++){
        if (!!words[i]){
            spacedWords += (!!spacedWords) ? ` ${words[i]}` : `${words[i]}`;
        }
    }

    return spacedWords;
};

const padLeftWithZeros = (st, length = 2) => {
    if (!st)
        return null

    return padLeft(`${st}`, length, '0')
}

const joinStringsWithSeparator = (separator, strings) => {
  return strings.join([separator]);
}

const deleteFirstLastCharacter = (string) => {
    if(string) {
        return string.substring(1, string.length-1)
    }
    return '';
}

const getFirstNWords = (str = '', qtty = 10, symbol = '.', qttySymbol = 3) => {
    const symbolPrefix = symbol.repeat(qttySymbol);
    let arrayString = str ? str.split(' ') : '';
    arrayString = arrayString ? arrayString.slice(0, (arrayString.length >= qtty ? qtty : arrayString.length)).join(' ') : '';

    return `${arrayString}${symbolPrefix}`;
}

const getDocumentURL = (cmsDocumentUrl = '') => {
    if (cmsDocumentUrl == '' || cmsDocumentUrl.startsWith('http')) {
        return cmsDocumentUrl;
    }
    const publicSiteUrl = getPublicSiteUrl();
    return (`${publicSiteUrl}${cmsDocumentUrl}`);
}

const getCountryFromISIN = (isinCode = '') => {
    return isinCode.substr(0, 2);
}

module.exports = {
    isString,
    capitalize,
    toCamelCase,
    toFirstUppercase,
    toNumericArray,
    getLastNChars,
    getFirstNChars,
    decodeHTMLEntities,
    cleanUpAccents,
    nonNumericFilter,
    formatVolume,
    replaceNChars,
    stringToBoolean,
    nonAlphanumericFilter,
    addOrdinal,
    addSpacesBetweenWords,
    capitalizeFirstLetter,
    padLeftWithZeros,
    toFirstLowerCase,
    joinStringsWithSeparator,
    deleteFirstLastCharacter,
    getFirstNWords,
    getDocumentURL,
    getCountryFromISIN
};
