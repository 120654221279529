// @vendors
const Immutable = require('immutable');

// @constants
const {
    BROKER_CASH_CHARGE_CHANGE_TITLES_AMOUNT,
    BROKER_CASH_CHARGE_CHANGE_ACCEPT_INSTRUCTIONS,
    BROKER_CASH_CHARGE_VALIDATE_STEP,
    BROKER_CASH_CHARGE_SET_VISIBLE_STEP
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    titlesAmount: 0,
    acceptedInstructions: false,
    valid: false
});

const setTitlesAmount = (immState, titles) => {
    return immState.set('titlesAmount', Math.max(0, parseInt(titles, 10) || 0));
};

const validate = (immState) => {
    const titlesAmount = immState.get('titlesAmount');
    const acceptedInstructions = immState.get('acceptedInstructions');
    return immState.set('valid', titlesAmount > 0 && acceptedInstructions);
}

function brokerCashChargeStep1Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_CASH_CHARGE_CHANGE_TITLES_AMOUNT:
            return setTitlesAmount(state, action.payload.titles);
        case BROKER_CASH_CHARGE_CHANGE_ACCEPT_INSTRUCTIONS:
            return state.set('acceptedInstructions', !state.get('acceptedInstructions'));
        case BROKER_CASH_CHARGE_VALIDATE_STEP:
            return validate(state);
        case BROKER_CASH_CHARGE_SET_VISIBLE_STEP:
            return state.set('valid', false);
        default:
            return state;
    }
}

module.exports = brokerCashChargeStep1Reducer;
