const storage = require('utilities/storage');
const { store } = require('store/configureStore');
const { getURL } = require('core/apiURLBuilder');
const { isClientWithoutAccountsBeforePG, redirectToRNCPages } = require('actions/noClient');
const { redirectToNerudaUserPages } = require('actions/nerudaUser');
const { redirectToAresUserPages } = require('actions/aresUser');
import { logout } from 'ok-login';


const loginCheck = (history) => {
    const token = storage.cookies.get('tokenCredential');
    const destination = history.location.pathname;
    const immProfile = store.getState().profile;

    // (andres): / and not logged -> /login
    // (andres): /login and not logged -> don't redirect
    // (andres): /* and not logged -> /login
    // (andres): / and logged -> /myprofile/global
    // (andres): /login and logged -> /myprofile/global
    // (andres): /* and logged -> don't redirect

    if (window.__DEBUG_MODE__ && !token && destination !== '/login') {
        location.href = `${location.origin}/login`;
    }

    if (destination === '/') {
        history.replace('/myprofile/global', { nextPathname: destination });
    }

    // (andres): Since we don't have a logout yet. When we go to /login, we
    // remove the cookie and *assumed* the user has logged out
    if (token && destination === '/login') {
        logout({
            url: getURL('logout')
        });
    }

    document.querySelector('body').scrollTop = 0;

    if (isClientWithoutAccountsBeforePG()) {
        const replaceURL = redirectToRNCPages(location.href, true, history);
        if (replaceURL) {
            history.replace(replaceURL, { nextPathname: destination });
        }
    } else if (immProfile.get('hasBeenFetched') && immProfile.get('isNerudaUser')) {
        const replaceURL = redirectToNerudaUserPages(location.pathname, true, history);
        if (!!replaceURL) {
            history.replace(replaceURL, { nextPathname: destination });
        }
    } else if (immProfile.get('hasBeenFetched') && immProfile.get('isAresUser')) {
        const replaceURL = redirectToAresUserPages(location.pathname, true, history);
        if (!!replaceURL) {
            history.replace(replaceURL, { nextPathname: destination });
        }
    }

    return true;
};

export {
    loginCheck
}
