// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        accountNumber: '',
        error: '',
        isFetching: false,
        byId: {},
        byOrder: []
    });
}

function updateTaxReturns(state, taxReturns) {
    let byId = {};
    let byOrder = [];

    taxReturns.forEach((taxReturn) => {
       const parsedTaxReturns = {
            document: taxReturn.listaNumDocumento,
            period: taxReturn.listaPeriodo,
            type: taxReturn.listaTipoDeclaracion,
            amount: {
                amount: get(taxReturn, 'listaImporteDec.importe', 0),
                currency: get(taxReturn, 'listaImporteDec.divisa', EURO_TEXT)
            },
            reference: taxReturn.listaNumReferenciaR,
            model: taxReturn.listaModeloR,
            excercise: taxReturn.listaEjercicio
        };
        const taxReturnId = taxReturn.listaNumReferenciaR

        byId[taxReturnId] = parsedTaxReturns;
        byOrder.push(taxReturnId);
    });

    const nextState = {
        error: '',
        isFetching: false
    }

    return state
        .merge(nextState)
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder));
}

const taxesAndRents = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.TAXES_AND_RENTS_CHANGE_ACCOUNT:
            return state.merge({
                accountNumber: action.payload.accountNumber,
                controlDigit: action.payload.controlDigit,
                accountId: action.payload.accountId
            });
        case actionTypes.TAXES_AND_RENTS_IN_PROGRESS:
            return state.merge({
                isFetching: true
            });
        case actionTypes.TAXES_AND_RENTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.TAXES_AND_RENTS_SUCCESS:
            return updateTaxReturns(state, action.payload.users);
        case actionTypes.TAXES_AND_RENTS_SUCCESS_DETAIL:
            const details = action.payload.details;

            return state.mergeDeep({
                isFetching: false,
                byId: {
                    [action.payload.id]: {
                        operationDate: details.fechaOperacion,
                        valueDate: details.fechaValor,
                        holder: details.Nombre,
                        nif: details.documentoNif,
                        diferentialPay: {
                            amount: get(details, 'importeDec.importe', 0),
                            currency: get(details, 'importeDec.divisa', EURO_TEXT)
                        },
                        admin: details.admHacienda,
                        documentCode: details.numeroDocumento,
                        nrc: details.nrc,
                        type: details.tipoDeclaracion
                    }
                }
            });
        default:
            return state
    }
};

module.exports = taxesAndRents;
