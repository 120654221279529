// @ vendors
const Immutable = require('immutable');
const { get } = require('lodash');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    inProgress: false,
    success: false,
    error: '',
    showTransferDisclaimer: false
});

function cardsPaymentCycleReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARDS_PAYMENT_CYCLE_REQUEST:
            return state.merge({
                inProgress: true
            });
        case actionTypes.CARDS_PAYMENT_CYCLE_SUCCESS:
            return state.merge({
                success: true,
                showTransferDisclaimer: get(action , 'payload.data.nexMonth')
            });
        case actionTypes.CARDS_PAYMENT_CYCLE_FAILURE:
            return state.merge({
                error: action.payload.error,
                success: false
            });
        default:
            return state
    }
}

module.exports = cardsPaymentCycleReducer;
