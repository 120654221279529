// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { FIRST_STEP } = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    steps: [
        {
            cardId: '',
            interveners: [],
            associatedAccount: {},
            intervenerId: {},
        },
        {
            cardType: '',
            validateServiceResponse: {}
        },
        {
            hasSignaturePattern: true
        }
    ],
    visibleStep: 1,
    isFetching: false,
    fetched: false,
    fetchError: false,
    fetchErrorMessage: '',
    isFetchingValidate: false,
    validateError: false,
    validateErrorMessage: '',
    isSaving: false,
    saveSuccessful: false,
    saveError: false,
    saveErrorMessage: ''
});

const getIntervenersFromServiceResponse = serviceResponse => {
    if (!serviceResponse) {
        return Immutable.fromJS([]);
    }

    const intervenersFromService = get(serviceResponse, 'listaIntervinientes.intervinientes', []);
    const interveners = [];

    intervenersFromService.forEach(intervenerFromService => {
        // Exclude the contract owner from the intervener for the operative
        if (!get(intervenerFromService, 'principal', false)) {
            interveners.push({
                intervenerId: {
                    personType: get(intervenerFromService, 'interviniente.tipodepersona', ''),
                    personCode: get(intervenerFromService, 'interviniente.codigodepersona', ''),
                },
                intervenerName: get(intervenerFromService, 'nombreInterviniente', '').trim(),
                interventionType: get(intervenerFromService, 'tipoIntervencion', '').trim()
            });
        }
    });

    return Immutable.fromJS(interveners);
};

const getAssociatedAccountFromServiceResponse = serviceResponse => {
    if (!serviceResponse) {
        return Immutable.fromJS({});
    }

    return Immutable.fromJS({
        country: get(serviceResponse, 'cuentaAsociada.pais', ''),
        controlDigit: get(serviceResponse, 'cuentaAsociada.digitodecontrol', ''),
        codbban: get(serviceResponse, 'cuentaAsociada.codbban', '').trim()
    });
};

const getCardTypeFromServiceResponse = serviceResponse => {
    if (!serviceResponse) {
        return '';
    }

    return get(serviceResponse, 'tipoTarjeta', '').trim();
};

const getLimitsArray = limits => {
    const limitsArray = [];

    limits.forEach(limit => {
        limitsArray.push({
            "importeLimitePoliza": {
                "importe": get(limit, 'importeLimitePoliza.importe'),
                "divisa": get(limit, 'importeLimitePoliza.divisa')
            },
            "codigoModificacion": get(limit, 'codigoModificacion'),
            "nombreColectivo": get(limit, 'nombreColectivo')
        });
    });

    return limitsArray;
};

const getAddresses = addresses => {
    const addressArray = [];

    addresses.forEach(address => {
        addressArray.push({
            "plaza": get(address, 'plaza', ''),
            "nombreVia": get(address, 'nombreVia', ''),
            "nroVia": get(address, 'nroVia', ''),
            "resto": get(address, 'resto', ''),
            "tipoVia": get(address, 'tipoVia', ''),
            "codigoPostal": get(address, 'codigoPostal', '')
        });
    });

    return addressArray;
}

const getValidateServiceResponse = serviceResponse => {
    return Immutable.fromJS({
        "nombreEstampado": get(serviceResponse, 'nombreEstampado', ''),
        "apellido1": get(serviceResponse, 'apellido1', ''),
        "apellido2": get(serviceResponse, 'apellido2', ''),
        "listadoIdentificacionPersona": {
            "identificacion": getAddresses(get(serviceResponse, 'listadoIdentificacionPersona.identificacion', []))
        },
        "datosAlta": {
            "nrodomicAux": get(serviceResponse, 'datosAlta.nrodomicAux'),
            "motivoSituacion": get(serviceResponse, 'datosAlta.motivoSituacion'),
            "idioma": get(serviceResponse, 'datosAlta.idioma'),
            "codpersx": get(serviceResponse, 'datosAlta.codpersx'),
            "plasticoCODPLAS": get(serviceResponse, 'datosAlta.plasticoCODPLAS'),
            "valorRequisitoEmpresa": get(serviceResponse, 'datosAlta.valorRequisitoEmpresa'),
            "valorRequisitoOp": get(serviceResponse, 'datosAlta.valorRequisitoOp'),
            "fechaCaducidadFECADVIG": get(serviceResponse, 'datosAlta.fechaCaducidadFECADVIG'),
            "fechaInicioFEINIVIG": get(serviceResponse, 'datosAlta.fechaInicioFEINIVIG'),
            "indicadorEnvioRecepcionINDENV": get(serviceResponse, 'datosAlta.indicadorEnvioRecepcionINDENV'),
            "indicadorEnvioINDENV1": get(serviceResponse, 'datosAlta.indicadorEnvioINDENV1'),
            "nombreAdicionalONOMADIC": get(serviceResponse, 'datosAlta.nombreAdicionalONOMADIC'),
            "panMediosPagoPANMDP": get(serviceResponse, 'datosAlta.panMediosPagoPANMDP'),
            "tipoPersonaListaTIPOPERX": get(serviceResponse, 'datosAlta.tipoPersonaListaTIPOPERX'),
            "tipoSituacionTIPSITUA": get(serviceResponse, 'datosAlta.tipoSituacionTIPSITUA'),
            "txtRelieve": get(serviceResponse, 'datosAlta.txtRelieve'),
            "ultimoNumAsigULNUMIAS": get(serviceResponse, 'datosAlta.ultimoNumAsigULNUMIAS'),
            "codigoSubProdIDSUBTIPO": get(serviceResponse, 'datosAlta.codigoSubProdIDSUBTIPO'),
            "codEstandarRefCOESTREF": get(serviceResponse, 'datosAlta.codEstandarRefCOESTREF'),
            "listaLimites": {
                "limites": getLimitsArray(get(serviceResponse, 'datosAlta.listaLimites.limites', []))
            }
        }
    });
}

const cardRequestAdditionalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.CARD_REQUEST_ADDITIONAL_FETCH_DATA_START:
            return state.mergeDeep({
                isFetching: true,
                fetched: false,
                fetchError: false,
                fetchErrorMessage: '',
                steps: [
                    {
                        cardId: '',
                        interveners: Immutable.fromJS([]),
                        associatedAccount: Immutable.fromJS({})
                    },
                    {
                        cardType: ''
                    }
                ]
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR:
            return state.mergeDeep({
                isFetching: false,
                fetched: true,
                fetchError: true,
                fetchErrorMessage: action.payload.error.description,
                steps: [
                    {
                        cardId: '',
                        interveners: Immutable.fromJS([]),
                        associatedAccount: Immutable.fromJS({})
                    },
                    {
                        cardType: ''
                    }
                ]
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_FETCH_DATA_SUCCESS:
            return state.mergeDeep({
                isFetching: false,
                fetched: true,
                fetchError: false,
                fetchErrorMessage: '',
                steps: [
                    {
                        cardId: action.payload.cardId,
                        interveners: getIntervenersFromServiceResponse(action.payload.response),
                        associatedAccount: getAssociatedAccountFromServiceResponse(action.payload.response)
                    },
                    {
                        cardType: getCardTypeFromServiceResponse(action.payload.response)
                    }
                ]
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_SELECT_INTERVENER:
            return state.setIn(['steps', FIRST_STEP, 'intervenerId' ], Immutable.fromJS(action.payload.intervenerId));

        case actionTypes.CARD_REQUEST_ADDITIONAL_VALIDATE_START:
            return state.mergeDeep({
                isFetchingValidate: true,
                validateError: false,
                validateErrorMessage: '',
                steps: [
                    {},
                    {
                        validateServiceResponse: {}
                    }
                ]
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_VALIDATE_ERROR:
            return state.mergeDeep({
                isFetchingValidate: false,
                validateError: true,
                validateErrorMessage: action.payload.error.description,
                steps: [
                    {},
                    {
                        validateServiceResponse: {}
                    }
                ]
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_VALIDATE_SUCCESS:
            return state.mergeDeep({
                isFetchingValidate: false,
                validateError: false,
                validateErrorMessage: '',
                steps: [
                    {},
                    {
                        validateServiceResponse: getValidateServiceResponse(action.payload.response)
                    }
                ]
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_CLEAR_VALIDATION:
            return state.merge({
                'validateError': false,
                'validateErrorMessage': false
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_VALIDATE_STEP:
            switch (action.payload.step) {
                case 2:
                    return state.merge({
                        visibleStep: 3
                    });
                default:
                    return state;
            }

        case actionTypes.CARD_REQUEST_ADDITIONAL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_CONFIRMATION_START:
            return state.merge({
                isSaving: true,
                saveSuccessful: false,
                saveError: false,
                saveErrorMessage: ''
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_CONFIRMATION_ERROR:
            return state.merge({
                isSaving: false,
                saveSuccessful: false,
                saveError: true,
                saveErrorMessage: action.payload.error.description
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_CONFIRMATION_SUCCESS:
            return state.merge({
                isSaving: false,
                saveSuccessful: true,
                saveError: false,
                saveErrorMessage: ''
            });

        case actionTypes.CARD_REQUEST_ADDITIONAL_CLEAR:
            return INITIAL_STATE;

        default:
            return state;
    }
};

module.exports = cardRequestAdditionalReducer;
