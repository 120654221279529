// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    files: {},
    isFetching: false,
    confirmed: false,
    isFetchingIRPF: false,
    confirmedIRPF: false,
    isFetching130: false,
    confirmed130: false,
    fileAdded: false
});

const holderEconomicEdit = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_RESET:
            return initialState.merge();

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_REQUEST:
            return state.merge({
                isFetching: true,
                confirmed: false
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false,
                confirmed: true
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_IRPF_REQUEST:
            return state.merge({
                isFetchingIRPF: true
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_IRPF_SUCCESS:
            return state.merge({
                error: '',
                isFetchingIRPF: false,
                confirmedIRPF: true
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_IRPF_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetchingIRPF: false
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_130_REQUEST:
            return state.merge({
                isFetching130: true
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_130_SUCCESS:
            return state.merge({
                error: '',
                isFetching130: false,
                confirmed130: true
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_WORK_130_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching130: false
            });

        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_ECONOMIC_ADD_FILE:
            const fileObj = {
                name: action.payload.name,
                data: action.payload.file
            };
            state = state.setIn([ 'files', fileObj.name ], fileObj);
            return state.merge({
                fileAdded: true
            });

        default:
            return state;
    }
};

module.exports = holderEconomicEdit;
