// @ vendors
const React = require('react');
//@commons
const { FormattedText } = require('core/i18n').i18n;
const { OKMessage } = require('commonsComponents/OKMessage');
//@helpers
const formValidationHelper = require('../utilities/formValidationHelper');
const { formatAmount } = require('utilities/currencyHelper');
const { haveEmail } = require('../utilities/formHelper');
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
//@constants
const {
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
    DEPOSIT_HIRE_CONFIRMATION_MANAGER,
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE,
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE_CC,
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_TYPE,
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_STANDARD,
    EURO_SYMBOL
} = require('constants/index');

function depositEighteenMonths_customErrorNotAllowed(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    const maximumAvailableAmount = `${formatAmount(
        depositHireData.get('bssaldo1')
    )} ${EURO_SYMBOL}`;
    const currentGlobalPosition = `${formatAmount(
        depositHireData.get('bssaldo2')
    )} ${EURO_SYMBOL}`;
    const globalPositionAtBeginningOfCampaign = `${formatAmount(
        depositHireData.get('bssaldo3')
    )} ${EURO_SYMBOL}`;
    const activeHiredDeposits = `${formatAmount(
        depositHireData.get('bssaldo4')
    )} ${EURO_SYMBOL}`;

    return (
        <table>
            <tbody>
                <tr>
                    <td colSpan="2" className="deposit-eighteen-months__custom-error-text">
                        <FormattedText value="contractsView-depositEighteenMonthsContractCustomErrorHeader" />
                        <strong>
                            {maximumAvailableAmount}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td className="deposit-eighteen-months__custom-error-cell">
                        <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine1" />
                    </td>
                    <td className="deposit-eighteen-months__custom-error-cell">
                        <strong>
                            {currentGlobalPosition}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td className="deposit-eighteen-months__custom-error-cell">
                        <FormattedText value="contractsView-depositEighteenMonthsCustomErrorTableLine2" />
                    </td>
                    <td className="deposit-eighteen-months__custom-error-cell">
                        <strong>
                            {globalPositionAtBeginningOfCampaign}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td className="deposit-eighteen-months__custom-error-cell">
                        <FormattedText value="contractsView-depositEighteenMonthsCustomErrorTableLine3" />
                    </td>
                    <td className="deposit-eighteen-months__custom-error-cell">
                        <strong>
                            {activeHiredDeposits}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className="deposit-eighteen-months__custom-error-footer">
                        <FormattedText value="contractsView-depositEighteenMonthsContractCustomErrorFooter" />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function depositEighteenMonths_loadSliderAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.min_value = depositHireData.get('importeMin');
        component.max_value = depositHireData.get('importeMax');
        component.default_value = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
    }
    return component;
}

function maxValueAllowedIsPositive(depositHireData) {
    return depositHireData && depositHireData.get('bssaldo1', 0) >= 0;
}

function isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData) {
    return (
        0 < depositHireData.get('bssaldo1', 0) &&
        depositHireData.get('bssaldo1', 0) < depositHireData.get('importeMin', 0)
    );
}

function isOpenMethodOrNegative(depositHireData) {
    return (
        depositHireData.get('error') ||
        !maxValueAllowedIsPositive(depositHireData) ||
        isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData)
    );
}

function depositBiggerThanMaxValueAllowed(contractWizard) {
    const depositAmount = findElementbyId(contractWizard.get('card_details'), 'monto_traspasar');
    return depositAmount > contractWizard.getIn(['depositHireData', 'bssaldo1'], 0);
}

function depositEighteenMonths_shouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'cta_seleccionada', 'description') &&
        !contractWizard.get('intervenersIsFetching') &&
        !contractWizard.get('dataIsFetching') &&
        !contractWizard.getIn(['depositHireData', 'error']) &&
        maxValueAllowedIsPositive(contractWizard.get('depositHireData'))
    );
}

function depositEighteenMonths_buildSliderExtraInfo(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    let tooltip = null;

    if (!contractWizard.get('dataIsFetching')) {
        const currentGlobalPosition = `${formatAmount(
            depositHireData.get('bssaldo2')
        )} ${EURO_SYMBOL}`;
        const globalPositionAtBeginningOfCampaign = `${formatAmount(
            depositHireData.get('bssaldo3')
        )} ${EURO_SYMBOL}`;
        const activeHiredDeposits = `${formatAmount(
            depositHireData.get('bssaldo4')
        )} ${EURO_SYMBOL}`;
        const maximumAvailableAmount = `${formatAmount(
            depositHireData.get('bssaldo1')
        )} ${EURO_SYMBOL}`;

        tooltip = (
            <article className="deposit-eighteen-months__slider-tooltip">
                <table>
                    <thead>
                        <tr>
                            <td
                                colSpan="2"
                                className="deposit-eighteen-months__slider-tooltip-cell"
                            >
                                <FormattedText value="contractsView-depositEighteenMonthsTooltipTableHeader" />
                            </td>
                        </tr>
                    </thead>
                    <tbody className="deposit-eighteen-months__slider-tooltip-body">
                        <tr>
                            <td className="deposit-eighteen-months__slider-tooltip-cell">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine1" />
                            </td>
                            <td className="deposit-eighteen-months__slider-tooltip-cell">
                                {currentGlobalPosition}
                            </td>
                        </tr>
                        <tr>
                            <td className="deposit-eighteen-months__slider-tooltip-cell">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine2" />
                            </td>
                            <td className="deposit-eighteen-months__slider-tooltip-cell">
                                {globalPositionAtBeginningOfCampaign}
                            </td>
                        </tr>
                        <tr>
                            <td className="deposit-eighteen-months__slider-tooltip-cell">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine3" />
                            </td>
                            <td className="deposit-eighteen-months__slider-tooltip-cell">
                                {activeHiredDeposits}
                            </td>
                        </tr>
                        <tr>
                            <td className="deposit-eighteen-months__slider-tooltip-footer-cell deposit-eighteen-months__slider-tooltip-footer-cell--first-section">
                                <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableHeader" />
                            </td>
                            <td className="deposit-eighteen-months__slider-tooltip-footer-cell deposit-eighteen-months__slider-tooltip-footer-cell--second-section">
                                {maximumAvailableAmount}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </article>
        );
    }

    return tooltip;
}

function deposit_eighteen_months_loan_SignParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails
        ? findElementbyId(immCardDetails, 'cta_seleccionada')
        : null;
    let selectedAccount;

    if (immAccounts && selectedAccountId) {
        const immAccount = immAccounts.getIn(['byId', selectedAccountId]);
        selectedAccount =
            API_ENTERPRISE_NUMBER +
            API_CENTER +
            immAccount.get('product') +
            immAccount.get('contractNumber');
    }

    return {
        originAccount: selectedAccount
    };
}

function depositEighteenMonths_onChangeAmount(amount) {
    let ret = [];
    ret.push({
        target: 'monto_traspasar',
        value: amount
    });
    return ret;
}

function depositEighteenMonths_onChangeCheckbox(card_details, component, event) {
    card_details.find(detail => detail.id === component.target).value = event.target.checked;

    return card_details;
}

function selectedAccountBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const ctaSeleccionada = findElementbyId(cardDetails, 'cta_seleccionada');
    const selectedAccountBalance =
        contractWizard.getIn([
            's_accounts',
            'value',
            'byId',
            ctaSeleccionada,
            'balances',
            'availableBalance',
            'amount'
        ]) || findElementbyId(cardDetails, 'balance_account');
    return selectedAccountBalance;
}

function depositBiggerThanBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = findElementbyId(cardDetails, 'monto_traspasar');
    return depositAmount > selectedAccountBalance(contractWizard);
}

const checkIfDepositIsRenewable = contractWizard => {
    const cardDetails = contractWizard.get('card_details');
    const isRenewable = !!findElementbyId(cardDetails, 'renewDeposit');
    return isRenewable;
}

function depositEighteenMonths_setSuccessStepPrefix(contractWizard, immProfileFields) {
    let successStepPrefix = {};

    if (depositBiggerThanBalance(contractWizard)) {
        successStepPrefix.flow = 'pending_';
    }
    if (haveEmail(immProfileFields)) {
        successStepPrefix.email = 'successWithEmail_';
    }
    if (checkIfDepositIsRenewable(contractWizard)) {
        successStepPrefix.renew = 'renewable_';
    }
    return successStepPrefix;
}

function successPendingInfo() {
    return (
        <div className="deposit-eighteen-months__additional-info">
            <p>
                <FormattedText value="contractSummary-depositEighteenMonthsPendingText" />
            </p>
            <p className="deposit-eighteen-months__additional-info--note-text">
                <FormattedText value="contractSummary-depositEighteenMonthsPendingNote" />
            </p>
        </div>
    );
}

function depositEighteenMonths_setSuccessStepAdditionalInfo(contractWizard) {
    return depositBiggerThanBalance(contractWizard) ? successPendingInfo() : null;
}

function depositEighteenMonths_nextButtonDisabled(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = cardDetails && findElementbyId(cardDetails, 'monto_traspasar');
    return !!(cardDetails && !findElementbyId(cardDetails, 'cta_seleccionada', 'description') ||
     contractWizard.get('intervenersIsFetching') || contractWizard.get('dataIsFetching') || !depositAmount ||
     contractWizard.getIn(['depositHireData', 'error']) || !maxValueAllowedIsPositive(contractWizard.get('depositHireData')));
}

function depositEighteenMonths_showFinalModal(contractWizard) {
    if (!contractWizard.get('dataIsFetching') && !isOpenMethodOrNegative(contractWizard.get('depositHireData'))) {
        return depositBiggerThanMaxValueAllowed(contractWizard) || depositBiggerThanBalance(contractWizard);
    }
    return false;
}

function modalWithTable(contractWizard, textLineOne, amountLineOne, text, notes, renderTable = true) {
    const depositHireData = contractWizard.get('depositHireData');
    const maximumAvailableAmount = formatAmount(depositHireData.get('bssaldo1'));
    const currentGlobalPosition = `${formatAmount(depositHireData.get('bssaldo2'))} ${EURO_SYMBOL}`;
    const globalPositionAtBeginningOfCampaign = `${formatAmount(depositHireData.get('bssaldo3'))} ${EURO_SYMBOL}`;
    const activeHiredDeposits = `${formatAmount(depositHireData.get('bssaldo4'))} ${EURO_SYMBOL}`;
    const renderLineOne = textLineOne && (
        <div>
            <FormattedText
                value={textLineOne}
                injectedStrings={[amountLineOne]}
            />
        </div>
    );

    const renderWarningMessage = maximumAvailableAmount && (
        <div className="deposit-eighteen-months__initial-modal-message">
            <OKMessage type="important">
                <FormattedText
                    value="contractsView-depositEighteenMonthsInitialModalLine2"
                    injectedStrings={[maximumAvailableAmount]}
                />
            </OKMessage>
        </div>
    );

    const renderModalTable = renderTable && (
        <div className="deposit-eighteen-months__initial-modal-table-container">
            <table className="deposit-eighteen-months__initial-modal-table">
                <tbody>
                    <tr className="deposit-eighteen-months__initial-modal-table-row">
                        <th className="deposit-eighteen-months__initial-modal-table-first-column">
                            <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine1" />
                        </th>
                        <td className="deposit-eighteen-months__initial-modal-table-second-column">
                            {currentGlobalPosition}
                        </td>
                    </tr>
                    <tr className="deposit-eighteen-months__initial-modal-table-row">
                        <th className="deposit-eighteen-months__initial-modal-table-first-column">
                            <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine2" />
                        </th>
                        <td className="deposit-eighteen-months__initial-modal-table-second-column">
                            {globalPositionAtBeginningOfCampaign}
                        </td>
                    </tr>
                    <tr className="deposit-eighteen-months__initial-modal-table-row">
                        <th className="deposit-eighteen-months__initial-modal-table-first-column">
                            <FormattedText value="contractsView-depositEighteenMonthsInitialModalTableLine3" />
                        </th>
                        <td className="deposit-eighteen-months__initial-modal-table-second-column">
                            {activeHiredDeposits}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="deposit-eighteen-months__initial-modal-body">
            {renderLineOne}
            {renderWarningMessage}
            {renderModalTable}
            <div>
                <FormattedText value={text} />
            </div>
            <div>
                {notes}
            </div>
        </div>
    );
}

function modalWithTableLine4Positive() {
    return (
        <div className="deposit-eighteen-months__initial-modal-note-text">
            <FormattedText value="contractsView-depositEighteenMonthsInitialModalLine4Positive" />
        </div>
    );
}

function depositEighteenMonths_InitialModalChildren(contractWizard) {
    const depositHireData = contractWizard.get('depositHireData');
    const phones = (
        <div className="deposit-eighteen-months__initial-modal-phones-container">
            <i className="icon-s-llamar deposit-eighteen-months__initial-modal-phone-icon"></i>
            <FormattedText className="deposit-eighteen-months__initial-modal-phone" value="contractsView-depositEighteenMonths_phoneNumberClients" />
        </div>
    );

    let modal;
    if (depositHireData.get('error')) {
        modal = modalWithTable(
            contractWizard,
            undefined,
            undefined,
            'contractsView-depositEighteenMonthsAccountModalLine4Negative',
            phones,
            false
        );
    } else if (isMinAmountToHireBiggerThanMaximumAvailableAmount(depositHireData, 'modal')) {
        modal = modalWithTable(
            contractWizard,
            'contractsView-depositEighteenMonthsInitialModalLine1',
            formatAmount(contractWizard.getIn(['depositHireData', 'importeMin'])),
            'contractsView-depositEighteenMonthsInitialModalLine3Positive',
            modalWithTableLine4Positive()
        );
    } else if (!maxValueAllowedIsPositive(depositHireData)) {
        modal = modalWithTable(
            contractWizard,
            'contractsView-depositEighteenMonthsAccountModalLine1Negative',
            formatAmount(contractWizard.getIn(['depositHireData', 'importeMin'])),
            'contractsView-depositEighteenMonthsAccountModalLine4Negative',
            phones
        );
    }

    return modal;
}

function depositEighteenMonths_FinalModalTitle(contractWizard) {
    return depositBiggerThanMaxValueAllowed(contractWizard)
        ? 'contractsView-depositEighteenMonthsStep2ValidationModalTitleBiggerThanAllowed'
        : 'contractsView-depositEighteenMonthsStep2ValidationModalTitle';
}

function modalWithSummary(contractWizard, requestedAmount) {
    const cardDetails = contractWizard.get('card_details');
    const ctaSeleccionada = findElementbyId(cardDetails, 'cta_seleccionada', 'description');
    const balance = formatAmount(selectedAccountBalance(contractWizard));

    return (
        <div className="deposit-eighteen-months__initial-modal-body">
            <div className="deposit-eighteen-months__initial-modal-message">
                <OKMessage type="important">
                    <FormattedText value="contractsView-depositEighteenMonthsFinalModalRequestedMoreThanBalanceLine1" />
                </OKMessage>
            </div>
            <div>
                {modalWithTableLine4Positive()}
            </div>
            <div className="deposit-eighteen-months__initial-modal-summary-container">
                <div className="row">
                    <div className="col-xs-4">
                        <FormattedText value="contractSummary-requestedAmount" /><br />
                        <strong>{`${requestedAmount} ${EURO_SYMBOL}`}</strong>
                    </div>
                    <div className="col-xs-8">
                        <FormattedText value="contractsView-associatedAccount" /><br />
                        <strong>{ctaSeleccionada}</strong>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-offset-4 col-xs-8">
                        <FormattedText value="contractsView-accountBalanceAssociated" /><br />
                        <strong>{`${balance} ${EURO_SYMBOL}`}</strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

function depositEighteenMonths_FinalModalChildren(contractWizard) {
    if (!contractWizard.get('dataIsFetching')) {
        const cardDetails = contractWizard.get('card_details');
        const amount = findElementbyId(cardDetails, 'monto_traspasar') || 0;

        let modal;
        if (depositBiggerThanMaxValueAllowed(contractWizard)) {
            modal = modalWithTable(
                contractWizard,
                '',
                formatAmount(amount),
                'contractsView-depositEighteenMonthsFinalModalLine3',
                modalWithTableLine4Positive()
            );
        } else if (depositBiggerThanBalance(contractWizard)) {
            modal = modalWithSummary(contractWizard, formatAmount(amount));
        }

        return modal;
    }
}

function validateStep1(card_details) {
    return {
        card_details: card_details,
        isValidStep: true
    };
}

function validateStep2(cardDetails, state, isValid) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const slideAmountIsNonZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;
    let newCardDetails;

    if (isValid && isValidAccount && slideAmountIsNonZero) {
        newCardDetails = cardDetails.update(
            cardDetails.findIndex(item => item.get('id') === 'validationModal'),
            item => item.set('openModal', depositEighteenMonths_showFinalModal(state))
        );
    } else {
        newCardDetails = cardDetails;
    }

    return {
        card_details: newCardDetails,
        isValidStep: isValidAccount && slideAmountIsNonZero
    };
}

function validateStep3(cardDetails, immConfirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = immConfirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: immConfirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: immConfirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function deposit_eighteen_months_validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, immConfirmationCheckboxes);
    }
}

function depositEighteenMonths_hireParamsFn(contractWizard, immAccount) {
    const cardDetails = contractWizard.get('card_details');
    const depositHireData = contractWizard.get('depositHireData');
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const interveners = contractWizard.get('interveners');
    const intervenersData = interveners.map((intervener, index) => ({
        desIntervencion: intervener.get('type'),
        formaIntervencion: intervener.get('interventionWay'),
        nombre: intervener.get('name'),
        numeroPersona: intervener.getIn(['identification', 'code']),
        ordenIntervencion: index,
        tipoIntervencion: intervener.get('typeNumber'),
        tipoPersona: intervener.getIn(['identification', 'type'])
    }));

    return {
        aa1: depositHireData.get('aa1'),
        aa2: depositHireData.get('aa2'),
        aa3: depositHireData.get('aa3'),
        adelta: depositHireData.get('adelta'),
        campana: DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
        canalComercializacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        canalOperacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        codPeridoLiq: depositHireData.get('codPeridoLiq'),
        cotPeriodo: depositHireData.get('cotPeriodo'),
        cuentaSeleccionada: {
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        },
        datos: {
            dato: intervenersData
        },
        depositoB: 'S', // "N" if is Welcome Deposit, otherwise "S" //@hardcoded
        descPeriodicidad: depositHireData.get('descPeriodicidad'),
        descPlazo: depositHireData.get('descPlazo'),
        descProducto: depositHireData.get('descProducto'),
        fechaVencimiento: depositHireData.get('fechaVencimiento'),
        gestor: DEPOSIT_HIRE_CONFIRMATION_MANAGER,
        importe,
        importeMax: depositHireData.get('importeMax'),
        importeMin: depositHireData.get('importeMin'),
        indCapit: 'N', // interests capitalization "S" or "N" //@hardcoded
        indRevTo: findElementbyId(cardDetails, 'renewDeposit') ? 'S' : 'N', // Renovation type
        indala01: depositHireData.get('indala01'),
        indala02: depositHireData.get('indala02'),
        indala03: depositHireData.get('indala03'),
        indala04: depositHireData.get('indala04'),
        moneda: depositHireData.get('moneda'),
        numEmp: DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
        perLiq: depositHireData.get('perLiq'),
        plazoPos: depositHireData.get('plazoPos'),
        producto: {
            codigodeestandar: DEPOSIT_HIRE_CONFIRMATION_PRODUCT_STANDARD,
            subtipodeproducto: {
                subtipodeproducto: __CONTACT_CENTER__ ?
                    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE_CC :
                    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE,
                tipodeproducto: {
                    tipodeproducto: DEPOSIT_HIRE_CONFIRMATION_PRODUCT_TYPE // should be the same as tipo
                }
            }
        },
        saldoCuentaSeleccionada: {
            divisa: immAccount.getIn(['balance', 'currency']),
            importe: immAccount.getIn(['balance', 'amount'])
        },
        tasa: depositHireData.get('tasa'),
        tipo: DEPOSIT_HIRE_CONFIRMATION_PRODUCT_TYPE // Should be the same as tipodeproducto
    };
}

module.exports = {
    deposit_eighteen_months_validateStep: deposit_eighteen_months_validateStep,
    deposit_eighteen_months_loan_SignParams: deposit_eighteen_months_loan_SignParams,
    depositEighteenMonths_customErrorNotAllowed: depositEighteenMonths_customErrorNotAllowed,
    depositEighteenMonths_onChangeAmount: depositEighteenMonths_onChangeAmount,
    depositEighteenMonths_onChangeCheckbox: depositEighteenMonths_onChangeCheckbox,
    depositEighteenMonths_isOpenMethodOrNegative: isOpenMethodOrNegative,
    depositEighteenMonths_InitialModalChildren: depositEighteenMonths_InitialModalChildren,
    depositEighteenMonths_hireParamsFn: depositEighteenMonths_hireParamsFn,
    depositEighteenMonths_showFinalModal: depositEighteenMonths_showFinalModal,
    depositEighteenMonths_FinalModalChildren: depositEighteenMonths_FinalModalChildren,
    depositEighteenMonths_FinalModalTitle: depositEighteenMonths_FinalModalTitle,
    depositEighteenMonths_setSuccessStepPrefix: depositEighteenMonths_setSuccessStepPrefix,
    depositEighteenMonths_setSuccessStepAdditionalInfo: depositEighteenMonths_setSuccessStepAdditionalInfo,
    depositEighteenMonths_loadSliderAmounts: depositEighteenMonths_loadSliderAmounts,
    depositEighteenMonths_buildSliderExtraInfo: depositEighteenMonths_buildSliderExtraInfo,
    depositEighteenMonths_shouldBuild: depositEighteenMonths_shouldBuild,
    depositEighteenMonths_nextButtonDisabled: depositEighteenMonths_nextButtonDisabled,
    depositBiggerThanBalance,
    modalWithTable,
    modalWithTableLine4Positive,
    modalWithSummary,
    validateStep1,
    validateStep3
};
