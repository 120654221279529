const Immutable = require("immutable");
const actionTypes = require("constants/actionTypes");
const setInitialState = () =>
    Immutable.fromJS({
        loading: false,
        error: false,
        success: false,
    });

function interstitialLegalAccept(state = setInitialState(), action = {}) {
    switch (action.type) {
        case actionTypes.HANDLE_ACCEPT_LEGAL_INPROGRESS:
            return state.merge({
                loading: true,
            });
        case actionTypes.HANDLE_ACCEPT_LEGAL_FAILURE:
            return state.merge({
                loading: false,
                success: false,
                error: true,
            });
        case actionTypes.HANDLE_ACCEPT_LEGAL_SUCCESS:
            return state.mergeDeep({
                loading: false,
                success: true,
                error: false,
            });
        default:
            return state;
    }
}

module.exports = interstitialLegalAccept;
