const { capitalizeFirstLetter } = require('utilities/stringHelper');

export const brokerGeneralProductsTableTags = (eventTag, eventAction, brokerType) => (
    {
        optionsMenu: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `${eventAction}Menu` ,
            etiquetaEvento: eventTag
        },
        contributeButton: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `pensions - contribute${eventAction && capitalizeFirstLetter(eventAction)}`,
            etiquetaEvento: `${eventTag}`
        },
        actionButtonBuy: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `${eventAction} - buy${eventAction}`,
            etiquetaEvento: `${eventTag}`
        },
        actionButtonSell: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `${eventAction && eventAction.finalType} - sell${eventAction && capitalizeFirstLetter(eventAction.finalType)}${eventAction && eventAction.extraTag}`,
            etiquetaEvento: `${eventTag}`
        },
        actionButtonMenu: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `pensions - contributePlans - ${eventAction}`,
            etiquetaEvento: `${eventTag}`
        },
        searchLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: eventTag,
            etiquetaEvento: `search`
        },
        seeAllLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: eventTag,
            etiquetaEvento: 'seeAll'
        },
        productLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `${eventAction} - see${eventAction && capitalizeFirstLetter(eventAction)}`,
            etiquetaEvento: `${eventTag}`
        },
        emptyStateLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `${eventAction}Empty`,
            etiquetaEvento: 'link'
        },
        productsTableAccordion: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'chevron',
            etiquetaEvento: eventTag
        },
        brokerProductsTableLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: eventTag,
            etiquetaEvento: 'link'
        }
    })