// @vendors
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/actionTypes');
const { COUNTRY_CODE_ES } = require('commonsConstants/countryCodes');
const { ALL_NOT_ALLOWED_COUNTRIES } = require('constants/index.js');
// @utilities
import { createConfig } from 'utilities/flagBasedFeatures';

// To be constant of what is uses in the application, can be usefull init this state
// with posibles flags.

function setInitialState() {
    return Immutable.fromJS({
        'account-revolving': false,
        'add-documentation-openyoung-enabled': false,
        'agregador-accounts-enabled': false,
        'agregador-cards-enabled': false,
        'agregador-deposits-enabled': false,
        'agregador-investments-enabled': false,
        'agregador-loans-enabled': false,
        'broker-cambios-comercializadora': false,
        'broker-transfer-pension-plans': false,
        'budget': false,
        'card-cancel-deferred-purchase-enabled': false,
        'card-cancel-pay-later-enabled': false,
        'chores': false,
        'collective-diamond-pack-enabled': false,
        'databank-enabled': false,
        'direct-money-enabled': false,
        'error': '',
        'not_allowed_evidence_overdraft': false,
        'hasBeenFetched': false,
        'hasError': false,
        'hire-accounts-pin-required-enabled': false,
        'hire-card-at-home-abroad-enabled': false,
        'hire-card-revolving-enabled': false,
        'hire-card-virtual-credit-enabled': false,
        'hire-deposit-thirteen-months-with-exceptions-second-renewal': false,
        'hire-deposit-thirteen-months-with-exceptions': false,
        'hire-payroll-account': false,
        'hire-welcome-account': false,
        'home-insurance-enabled': false,
        'immediate-transfers-enabled': false,
        'not_allowed_transfers_security_warning_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'isFetching': false,
        'loan-retail-enabled': false,
        'mortgage-home-insurance-enabled': false,
        'not_allowed_adobe_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_amazon_gift_card_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_aggregator_consents_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_block_device_security_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_bring_my_funds_info_view_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_callmeback_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_callmeback_genesys_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_tink_aggregator_countries': false,
        'not_allowed_check_legales_countries': false,
        'not_allowed_checking_account_countries': false,
        'not_allowed_credit_card_revolving_process_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_cards_search_and_amount_filters_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_card_activation_greater_than_one_year_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_accounts_search_and_amount_filters_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_denial_account_hiring_blocked_account_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_deposits_topbar_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hiring_main_deposits_with_newbalance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_deposit_retention_exclusive_offer_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hiring_main_deposits_without_newbalance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_informative_deposit_wafer_without_newbalance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_discounted_mortgage_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_e_commerce_pgbanner_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hide_new_deposit_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hide_virtual_additional_card_cc_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hire_accounts_add_documentation_countries': false,
        'not_allowed_hire_passport_pin_countries': false,
        'not_allowed_hiring_combined_deposits_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hiring_thirteen_deposits_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hiring_eighteen_deposits_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hiring_eighteen_deposits_retention_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hiring_twentyfour_deposits_retention_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_dali_option_gap_access_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_landing_discrimination_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_modal_icon_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_internet_payments_adhered_commerce_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_metal_card_validations_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_cancel_option_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_idd_regulation_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_no_related_legal_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_obligations_document_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_recommendation_factors_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_related_legal_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_related_otp_option_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_usability_improvements_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_related_pre_appraisal_notification_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_ads_circular_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_insurance_extra_services_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_landing_risk_indicator_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_lavanda_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_limit_credit_card_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_loan_check_cirbe_personal_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_home_insurance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_life_insurance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_payroll_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_repsol_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_wealth_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_bonuses_cards_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_contracted_insurance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_guide_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_payment_table_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_payment_table_v2_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_mortgage_pricing_without_ltv_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_notifications_management_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_otp_virtual_office_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_payroll_account_countries': false,
        'not_allowed_personal_loan_drago_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_personetics_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_personetics_virtual_office_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_pre_contractual_easy_loan_mail_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_pre_contractual_no_related_mail_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_pre_contractual_overdraft_protection_mail_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_pre_contractual_payroll_loan_mail_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_pre_contractual_related_insurance_mail_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_pre_contractual_retail_loan_mail_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_life_insurance_wafer_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_transfer_on_savings_account_cta_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_transfers_popup_between_3k_25k_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_interstitial_legal_modal_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_25k_more_transfers_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_message_hire_2_cards_24h_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_regenerate_signature_key_with_biocatch_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_r42_benefits_and_insurance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_r42_benefits_and_insurance_access_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_r42_benefits_insurance_section_countries':ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_r42_travel_plus_off_hiring_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_sending_signature_key_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_revolving_v2_ine_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_travel_plus_R42_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hire_el_hormiguero_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hire_neruda_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_hire_vanity_fair_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_payroll_loan_legal_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_easy_loan_legal_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_retail_loan_legal_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_open_credit_aquamarina_cardart_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_overdraft_protection_legal_check_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_revolving_afi_modify_limit_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_sca_banner_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_sending_pn_otp_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_virtual_debit_cancer_cardart_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_virtual_debit_pride_cardart_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_open_markets_personal_data_cession_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_open_markets_personal_data_intersticial_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_standalone_related_insurance_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_url_discount_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_wealth_otp_phone_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_wealth_broker_ia_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'not_allowed_wealth_suitability_test_sustainability_questions_countries': ALL_NOT_ALLOWED_COUNTRIES,
        'profile-shipping-address-add-enabled': false,
        'profile-shipping-address-edit-enabled': false,
        'profile-shipping-address-remove-enabled': false,
        'sec-kits': false,
        'sec-sessions': false,
        'status-documentation-openyoung-enabled': false,
        'tpps_enabled': false,
        'transfer-external-securities': false,
        'tutor-database-enabled': false,
        'upload-img-domiciliacion-enabled': false,
        'virtual-debit-card-hiring-enabled': false,
        'watchlist-enabled': false,
        'wealth-cm-traspass-enabled': true,
        'wealth-spinoff': false,
        'web-basic-account-consents-off-enabled': false,
        'web-gc-enabled': false,
        'web-inactive-account-enabled': false,
        'web-reuse-transfer-enabled': false,
    });
}

const flagBasedFeatures = (state = setInitialState(), action) => {
  switch (action.type) {
    case actionTypes.FLAG_BASED_FEATURES_REQUEST:
      return state.merge({
        isFetching: true,
        successfully: false,
        hasError: false,
        error: '',
      });
    case actionTypes.FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY:
      const originCountry = action.originCountry
        ? action.originCountry.toUpperCase()
        : COUNTRY_CODE_ES;

      return state.merge(
        {
          originCountry: originCountry,
        },
        createConfig(state.toJS(), originCountry)
      );
    case actionTypes.FLAG_BASED_FEATURES_SUCCESS:
      return state.merge(
        {
          isFetching: false,
          hasBeenFetched: true,
          error: '',
        },
        createConfig(action.payload.flags, state.get('originCountry'))
      );
    case actionTypes.FLAG_BASED_FEATURES_ERROR:
      return state.merge({
        isFetching: false,
        hasError: true,
        error: action.payload.error,
      });
    default:
      return state;
  }
};

module.exports = flagBasedFeatures;
