// @ vendor
const Immutable = require('immutable');
const moment = require('moment');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
// @ constants
const actionTypes = require('constants/actionTypes');
const { getTranslatedCountry } = require('constants/countries');
const {
    FIRST_STEP,
    SECOND_STEP,
} = require('constants/index');
// @helpers
const PersonalDataHelper = require('utilities/personalDataHelper');
const {
    DEFAULT_COUNTRY,
    DOCUMENT_TYPE_NIF,
    GENDER_FEMALE,
    GENDER_MALE,
} = require('constants/index');

// @reducers
// below reducer should be specific for contact data, and then you shoul add another one for each personal and tax data if neccesary
const openYoungProfileDetailsStep1 = require('./openYoungProfileDetails/openYoungProfileDetailsStep1');

const initialState = Immutable.fromJS({
    address: '',
    addressCountry: '',
    addressNumber: 0,
    birthDate: moment().subtract(18, 'years').add(1, 'days'),
    country: DEFAULT_COUNTRY,
    countriesTributation: [],
    error: false,
    fetchSuccess: false,
    formatAddress: '',
    fullName: '',
    gender: '0',
    documentCodeNumber: '',
    documentCodeType: DOCUMENT_TYPE_NIF,
    isFetching: false,
    lastName: '',
    lastName2: '',
    mobile: '',
    mobileDispositive: {},
    name: '',
    nationality: DEFAULT_COUNTRY,
    population: '',
    populationBirth: '',
    province: '',
    provinceBirth: {},
    steps: [
        openYoungProfileDetailsStep1(),
        {hasSignaturePattern: true},
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    isMobileError: false,
    isAddressError: false,
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return openYoungProfileDetailsStep1(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;
    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function openYoungProfileDetailsReducer(state = initialState, action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_SET_VALIDATE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_REQUEST:
            return state.merge({
                error: '',
                fetchSuccess: false,
                isFetching: true,
            });
        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_SUCCESS:
            const {response, userInfo, originCountry} = action.payload;
            let mobileDispositive;

            if (response.listaDispositivos) {
                const dispositivesList = response.listaDispositivos.find((dispositivo) => dispositivo.tipoDispositivo === 'T ') || {};
                mobileDispositive = {
                    timeFrom: dispositivesList.horaDesde || '',
                    timeTo: dispositivesList.horaHasta || '',
                    indexPreferent: dispositivesList.indPreferente || '',
                    numberDispositive: dispositivesList.numDispositivo || '',
                    typeDispositive: dispositivesList.tipoDispositivo || '',
                    typeUsed: dispositivesList.tipoUso || '',
                };
            }

            let countriesTributation = get(response, 'listPaisesTributacionAdic.tributAdicional') || [];
            countriesTributation = countriesTributation.map((country) => {
                return {
                    reason: trim(get(country, 'indTipoTributacion', '') || ''),
                    country: trim(get(country, 'paisTributacion', '') || ''),
                    tin: trim(get(country, 'tin.codigodocumpersonacorp', '') || ''),
                };
            });

            return state.merge({
                addressCountry: getTranslatedCountry((response.paisDomicilio || 'es')),
                addressFormatted: PersonalDataHelper.getFormattedAddress(Immutable.fromJS(response)) || '',
                addressNumber: response.numeroDomicilio || 0,
                birthDate: moment(trim(response.fechaNacimiento || '')),
                contractNumber: userInfo.get('contractNumber') || '',
                countriesTributation,
                country: trim(response.paisNacimiento || ''),
                documentCodeNumber: trim(get(response, 'documentoIdentificacion.codigodocumpersonacorp') || ''),
                documentCodeType: trim(get(response, 'documentoIdentificacion.tipodocumpersonacorp') || ''),
                fetchSuccess: true,
                formatAddress: trim(get(response, 'domicilio.formatodomicilio') || ''),
                fullName: trim(response.nombreCompleto),
                gender: trim(response.sexo) === GENDER_FEMALE ? GENDER_FEMALE : GENDER_MALE,
                isFetching: false,
                mobile: trim(response.dispositivo || '').replace(/\-/g, '').substr(3),
                mobileDispositive,
                name: trim(response.nombreCliente || ''),
                nationality: response.paisNacionalidad || '',
                numberStreet: response.numeroDeVia || '',
                lastName: trim(get(response, 'apellidoUno.apellido') || ''),
                lastName2: trim(get(response, 'apellidoDos.apellido') || ''),
                panNumber: userInfo.get('panNumber') || '',
                originCountry,
                personCode: userInfo.get('personCode') || '',
                personType: userInfo.get('personType') || '',
                population: trim(response.nombrePlaza || ''),
                populationBirth: trim(response.plazaDeNacimiento || ''),
                postalCode: trim(response.codigoPostal || ''),
                product: userInfo.get('product') || '',
                province: trim(get(response, 'plaza.plaza.provincia') || ''),
                provinceBirth: {
                    discriminatePopulation: trim(get(response, 'provincia.discriminanteplaza') || ''),
                    population: trim(get(response, 'provincia.plaza') || ''),
                    province: trim(get(response, 'provincia.provincia') || ''),
                },
                restOfAddress: trim(response.restoDomicilio || ''),
                streetName: trim(response.nombreDeVia || ''),
                typeOfRoads: trim(response.tipoDeVia || ''),
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_RESET:
            return initialState;

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.OPEN_YOUNG_UPDATE_PROFILE_DETAILS_REQUEST:
            return state.merge({
                error: false,
                isFetching: true,
                submitSuccessful: false,
            });

        case actionTypes.OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
                isAddressError: action.payload.typeErrors.address,
                isMobileError: action.payload.typeErrors.mobile,
            });

        case actionTypes.OPEN_YOUNG_UPDATE_PROFILE_DETAILS_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            });

        default:
            return state;
    }
}

module.exports = openYoungProfileDetailsReducer;
