const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    addressWasUpdated: false,
    mobileWasUpdated: false,
    valid: true
});

function openYoungProfileDetailsStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_VALIDATE_STEP:
            let newState = {
                valid: action.payload.valid
            };
            if (action.payload.dataChanged) {
                if (action.payload.dataChanged.contactData) {
                    newState.addressWasUpdated = action.payload.dataChanged.contactData.addressWasUpdated;
                    newState.mobileWasUpdated = action.payload.dataChanged.contactData.mobileWasUpdated;
                }
            }
            return state.mergeDeep(newState);
        default:
            return state;
    }
}

module.exports = openYoungProfileDetailsStep1;
