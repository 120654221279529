// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        acceptedManifest: false,
        manifestNotChecked: false,
        showWarning: false,
        allDocsRead: false
    });
}

const validateStep = (immState) => {
    const acceptedManifest = immState.get('acceptedManifest');
    return immState.merge({
        valid: acceptedManifest,
        manifestNotChecked: !acceptedManifest
    });
};

function changeManifestCheckbox(immState) {
    if (immState.get('allDocsRead')) {
        return immState.set('acceptedManifest', !immState.get('acceptedManifest'))
            .set('valid', true)
            .set('showWarning', false);
    }
    else {
        return immState.set('showWarning', true);
    }
}

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
};

function BrokerRequestFinancialOperationChangeWizardStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_OPERATION_CHECKBOX:
            return changeManifestCheckbox(state);
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_DOCUMENTS_READ:
            return setDocumentsRead(state);
        default:
            return state;
    }
}

module.exports = BrokerRequestFinancialOperationChangeWizardStep2;
