import { call, put, takeLatest } from 'redux-saga/effects';
import {
    infoGetFailed as getFailed,
    infoGetSucceeded as getSucceeded,
} from 'actions/cardControl';
import { error as errorAction } from 'actions/notifications';
import { CARD_INFO_GET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { APIGet } from 'utilities/APIRequestHelper';
import mapChannels from './mapChannels';
import mapCountryNames from './mapCountryNames';
import originCountryZone from './originCountryZone'

function* getInfo({ payload: { cardId, pan, originCountry, lastDigitsPan } }) {
  try {
      const requestParams = {
          dispatch: null,
          endpoint: 'cardEnrollAndGet',
          url: apiURLBuilder.getURL('cardEnrollAndGet', { pan, lastDigitsPan }),
      };
      const {
          paymentChannels: channels,
          location: locations
      } = yield call(APIGet, null, apiURLBuilder.getURL('cardEnrollAndGet', { pan, lastDigitsPan }), requestParams);
      yield put(getSucceeded({
        cardId,
        channels: mapChannels(channels),
        locations: locations.zones.map(mapCountryNames),
        originCountryZone: originCountryZone(locations.zones, originCountry)
      }));
  } catch ({ message }) {
      yield put([
          getFailed({ message }),
          errorAction({ message }, { voice: message }),
      ]);
  }
}


function* infoGetSaga() {
  yield [
      takeLatest(CARD_INFO_GET_REQUESTED, getInfo),
  ];
}


export default [
  infoGetSaga,
];
export { infoGetSaga, getInfo };
