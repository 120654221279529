/**
 * Created by adderlyjaureguicampos on 03/01/17.
 */
const { EURO_SYMBOL } = require('constants/index');

module.exports = {
    title: 'Diseña tu depósito',
    order: 2,
    footer_text: '',
    position: 'middle',
    components: [
        {
            id_component: 'dropdown-accounts',
            label: 'Seleccione una cuenta',
            options: [],
            type: 'single',
            source: 'duoCombinedDeposit_loadCmbAccounts',
            onChangeFn: 'duoCombinedDeposit_onChangeDropdownAccounts',
            sourceIntervener: 'duoCombinedDeposit_loadIntevenerOptions',
            onChangeIntervenerFn: 'duoCombinedDeposit_onChangeIntervener',
            target: 'cta_seleccionada',
            targetIntervener: 'intervinientes_list',
            order: 1,
            isIntervenerAvailable: true,
            isMaxAmountLabelAvailable: false,
            maxAmountValue: 0,
            msg_error: 'Debe seleccionar una opción.'
        },
        {
            id_component: 'slider',
            target: 'importe_depositar',
            label: 'Ingresa el importe a traspasar',
            min_value: 0,
            max_value: 100000,
            type: 'single',
            format_type: EURO_SYMBOL,
            default_value: 50000,
            order: 1,
            min_label: 'MIN. 000.000 €',
            max_label: 'MAX. 100.000 €',
            onChangeFn: 'duoCombinedDeposit_onChangeAmount',
            money_component_value: 50000,
            is_money: true
        }
    ]
};
