import { call, put, takeLatest } from 'redux-saga/effects';
import {
    countriesGetFailed as getFailed,
    countriesGetSucceeded as getSucceeded,
} from 'actions/cardControl';
import { error as errorAction } from 'actions/notifications';
import { CARD_COUNTRIES_GET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { APIGet } from 'utilities/APIRequestHelper';
import mapCountryNames from './mapCountryNames';


function* getCountries({ payload: { cardId, pan } }) {
  try {
      const requestParams = {
          dispatch: null,
          endpoint: 'cardEnrollAndGet',
          url: apiURLBuilder.getURL('cardEnrollAndGet', { pan }),
      };
      const { location: locations } = yield call(APIGet, null, apiURLBuilder.getURL('cardEnrollAndGet', { pan }), requestParams);
      yield put(getSucceeded({ cardId, locations: locations.zones.map(mapCountryNames) }));
  } catch ({ message }) {
      yield put([
          getFailed({ message }),
          errorAction({ message }, { voice: message }),
      ]);
  }
}


function* countriesGetSaga() {
  yield [
      takeLatest(CARD_COUNTRIES_GET_REQUESTED, getCountries),
  ];
}


export default [
  countriesGetSaga,
];
export { countriesGetSaga, getCountries };
