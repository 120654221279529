// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        emittedDateTime: 0,
        isEmitted: false,
        hasSignaturePattern: true,
        shouldValidate: true,
        documentsCheckbox: false,
        riskCheckbox: false,
    });
}

function validateStep(step) {
    return step.merge({
        valid: true,
        shouldValidate: (step.get('shouldValidate')?false:false)
    });
}

function brokerETFBuyStep2(state = setInitialState(), action) {
    let newState;

    switch (action.type) {
        case actionTypes.BROKER_ETF_BUY_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_ETF_BUY_TOGGLE_DOCUMENTS_CHECKBOX:
            if(state.get('documentsCheckbox')){
                return state.merge({
                    documentsCheckbox: false,
                    riskCheckbox: false
                })
            }else {
                return state.merge({
                    documentsCheckbox: true,
                })
            }
        case actionTypes.BROKER_ETF_BUY_TOGGLE_RISK_CHECKBOX:
            return state.merge({
                riskCheckbox: !state.get('riskCheckbox'),
            })
        case actionTypes.BROKER_ETF_BUY_RESET_CHECKBOX:
            return state.merge({
                documentsCheckbox: false,
                riskCheckbox: false
            })
        case actionTypes.BROKER_ETF_BUY_FINISH:
            newState = state.merge({
               isEmitted: false, // change this to 'true' if timestamp is required to show on confrimation screen
               emittedDateTime: moment(parseInt(action.payload.data))
            });
            return newState;
        default:
            return state;
    }
}

module.exports = brokerETFBuyStep2;
