// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/actionTypes');

// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');

const setInitialState = () => {
    return Immutable.fromJS({
        isFetching: false,
        sharedAccounts: {}
    });
}

const updateSharedAccountList = (state, action) => {
    const sharedAccounts = action.data;

    const sharedAccountsObj = sharedAccounts.reduce((obj, account) => {
        const accountIban = get(account, 'iban');
        const country = accountIban.substring(0, 2);
        const controlDigit = accountIban.substring(2, 4);
        const codbban = accountIban.substring(4);

        const iban = {
            country,
            controlDigit,
            codbban
        }

        const accountId = generateAccountId(iban);

        obj[accountId] = account;
        return obj;
     }, {})

    return state.merge({
        isFetching: false,
        sharedAccounts: sharedAccountsObj
    });
}

const sharedAccountsReducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.REQUEST_SHARED_ACCOUNTS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.REQUEST_SHARED_ACCOUNTS_SUCCESS:
            return updateSharedAccountList(state, action);
        case actionTypes.REQUEST_SHARED_ACCOUNTS_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.error
            });
        default:
            return state;
    }
}

module.exports = sharedAccountsReducer;
