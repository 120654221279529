// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ utilities
const { hash } = require('utilities/hash');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');

const initialState = Immutable.fromJS({});

function mapFields (movements) {
    let byId = {};
    let byOrder = [];

    movements.forEach(movement => {
        const newMovement = {
            balanceConcept: movement.conceptoSaldo || '',
            date: movement.fechaOperacion || '',
            hour: movement.horaOperacion || '00:00',
            import: {
                amount: get(movement, 'importe.importe', 0),
                currency: get(movement, 'importe.divisa', EURO_TEXT)
            },
            commerce: movement.comercioCajero || '',
            cardNumber: movement.numeroTarjeta || ''
        };

        const id = hash([movement.fechaOperacion, newMovement.commerce, newMovement.import.amount, newMovement.cardNumber]);
        newMovement.id = id;

        byId[id] = newMovement;
        byOrder.push(id);
    });

    return {
        byOrder,
        byId
    }
}

function LoanMovementsReducer(state = initialState, action) {
    let processedMovements;
    switch (action.type) {
        case actionTypes.FETCH_CARD_STATEMENT_MOVEMENTS_REQUEST:
            return state.mergeDeep({
                [action.payload.statementId]: {
                    byOrder: [],
                    byId: {},
                    isFetching: true,
                    error: ''
                }
            });
        case actionTypes.FETCH_CARD_STATEMENT_MOVEMENTS_SUCCESS:
            processedMovements = mapFields(action.payload.movements);
            return state.mergeDeep({
                [action.payload.statementId]: {
                    byId: processedMovements.byId,
                    byOrder: processedMovements.byOrder,
                    isFetching: false,
                    nextPage: action.payload.nextPage
                },
            });
        case actionTypes.FETCH_CARD_STATEMENT_MOVEMENTS_FAILURE:
            return state.mergeDeep({
                [action.payload.statementId]: {
                    isFetching: false,
                    error: action.payload.error
                }
            });
        case actionTypes.FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_REQUEST:
            return state.mergeDeep({
                [action.payload.statementId]: {
                    isFetchingNextPage: true,
                    error: ''
                }
            });
        case actionTypes.FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_SUCCESS:
            const currentState = state.get(action.payload.statementId);
            processedMovements = mapFields(action.payload.movements);
            return state.mergeDeep({
                [action.payload.statementId]: {
                    byId: processedMovements.byId,
                    byOrder: currentState.get('byOrder').concat(processedMovements.byOrder),
                    isFetchingNextPage: false,
                    nextPage: action.payload.nextPage
                },
            });
        case actionTypes.FETCH_CARD_STATEMENT_MOVEMENTS_NEXT_PAGE_FAILURE:
            return state.mergeDeep({
                [action.payload.statementId]: {
                    isFetchingNextPage: false,
                    error: action.payload.error
                }
            });
        default:
            return state;
    }
}

module.exports = LoanMovementsReducer;
