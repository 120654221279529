// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    BROKER_CONDITION_OVER_WARRANT_PRICE,
    BROKER_DEFAULT_CONDITION_TYPE_ID,
    BROKER_DEFAULT_CONDITION_TYPE_VALUE
} = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        //"Tipo de orden" radio button
        orderType: BROKER_CONDITION_OVER_WARRANT_PRICE,
        //Option selected in "Tipo de condición" dropdown list
        conditionType: BROKER_DEFAULT_CONDITION_TYPE_ID,
        //"Valor de la condición" input value
        conditionTypeValue: BROKER_DEFAULT_CONDITION_TYPE_VALUE,
        //"Plazo de validez" date
        validityDate: moment().add(90, 'days'),
        limitDate: moment().add(90, 'days'),
        valid: false
    });
}

function validateStep(step) {
    const validStep = +step.get('conditionTypeValue') !== BROKER_DEFAULT_CONDITION_TYPE_VALUE;

    return step.merge({
        valid: validStep
    });
}

function brokerWarrantsAdvancedSellStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERMS_CHANGED:
            return state.merge({
                orderType: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED:
            return state.merge({
                conditionType: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED:
            return state.merge({
                conditionTypeValue: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });

        default:
            return state;
    }
}

module.exports = brokerWarrantsAdvancedSellStep1;
