// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    THIRD_STEP
} = require('constants/index');

// @ reducers
const accountDirectDebitsStep3 = require('./accountDirectDebits/accountDirectDebitsStep3');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            {valid: true},
            {valid: true},
            accountDirectDebitsStep3(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        submitPartialSuccess: false,
        submitError: undefined,
        willLeave: false,
        willCancel: false,
        error: '',
        fromAccount:''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case THIRD_STEP:
                return accountDirectDebitsStep3(step, action);
        }
    });
}

function accountDirectDebits(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.ACCOUNT_DIRECT_DEBIT_RESET:
            return setInitialState();
        case actionTypes.ACCOUNT_DIRECT_DEBIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_SET_ACCOUNT:
            return state.merge({
                fromAccount: action.payload.fromAccount
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_VALIDATE_STEP:
            switch (action.payload.step) {
                case 3:
                    const partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    const visibleStep = partialSteps.get(THIRD_STEP).get('valid') ? 4 : 3;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                default:
                    return state.merge({
                        visibleStep: action.payload.step + 1
                    });
            }
        case actionTypes.ACCOUNT_DIRECT_DEBIT_UPLOAD_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_UPLOAD_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_UPLOAD_ERROR:
            return state.merge({
                isFetching: false,
                submitError: action.payload.error
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_UPLOAD_PARTIAL_SUCCESS:
            return state.merge({
                isFetching: false,
                submitPartialSuccess: true
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_ADD_FILE:
        case actionTypes.ACCOUNT_DIRECT_DEBIT_REMOVE_FILE:
        case actionTypes.ACCOUNT_DIRECT_DEBIT_SET_DESCRIPTION:
        case actionTypes.ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_SUCCESS:
        case actionTypes.ACCOUNT_DIRECT_DEBIT_FILE_UPLOAD_ERROR:
            return state.merge({
                steps: updateStep(THIRD_STEP, state.get('steps'), action)
            });
        case actionTypes.ACCOUNT_DIRECT_DEBIT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        default:
            return state;
    }
}

module.exports = accountDirectDebits;
