import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    countriesOnlySetFailed as setFailed,
    countriesOnlySetSucceeded as setSucceeded,
} from 'actions/cardControl';
import { error as errorAction } from 'actions/notifications';
const { success } = require('actions/notifications');
const { formatText } = require('core/i18n').i18n;
import { CARD_COUNTRIES_ONLY_SET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { getCard } from 'selectors/cards';
import { APIPost } from 'utilities/APIRequestHelper';


function* setCountriesOnly({ payload: { cardId, pan, countriesIds, lastDigitsPan } }) {
    const output = yield select(getCard, { cardId });
    if(output) {
        const { locations } = yield select(getCard, { cardId });
        yield put(setSucceeded({ cardId, countriesIds }));
        try {
            const requestParams = {
                dispatch: null,
                endpoint: 'cardSetCountry',
                url: apiURLBuilder.getURL('cardSetCountriesOnly', { pan }),
                query: { names: countriesIds, lastDigitsPan },
            };
            yield call(APIPost, null, apiURLBuilder.getURL('cardSetCountriesOnly', { pan }), requestParams);
            yield put(success({message: formatText("cardsConfig-showSuccessNotificationCountries")}))
        } catch ({ message }) {
            yield put(setFailed({ cardId, locations, message }));
            yield put(errorAction({ message }, { voice: message }));
        }
    }
}


function* countriesOnlySetSaga() {
    yield [
        takeLatest(CARD_COUNTRIES_ONLY_SET_REQUESTED, setCountriesOnly),
    ];
}


export default [
  countriesOnlySetSaga,
];
export { countriesOnlySetSaga, setCountriesOnly };
