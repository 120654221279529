function buildComponentId(reference, name) {
    return name.concat('__', reference);
}

function generateOtherIncomeFields(componentId) {
    const idNames = ['input-safe-procedencia', 'cmb_tipo_actividad', 'input-safe-importe'];
    const positionIdentifiers = ['one', 'two', 'three', 'four'];
    const otherIncomeFields = [];

    positionIdentifiers.forEach(position => {
        idNames.forEach(name => {
            let otherIncome =  [
                {
                    id_component: 'input-safe',
                    id: buildComponentId(componentId, name.concat('-', position)),
                    label:'Procedencia',
                    type_format: 'text',
                    type: 'single',
                    onBlurFn: 'personalLoan_handleBlur'
                },
                {
                    id_component: 'combo',
                    id: buildComponentId(componentId, name.concat('-', position)),
                    label: 'Periodicidad',
                    type: 'form',
                    placeholder: 'Seleccione una opción ...',
                    source: 'loadCmbPurpose',
                    onChangeFn: 'personalLoan_onChangeCmbPurpose',
                    target: 'personal_form_work_information_data',
                    options: [],
                    order: 3,
                    msg_error: ''
                },
                {
                    id_component: 'input-safe',
                    id: buildComponentId(componentId, name.concat('-', position)),
                    label: 'Importe',
                    type_format: 'text',
                    type: 'single',
                    onBlurFn: 'personalLoan_handleBlur'
                }
            ];
            otherIncomeFields.push(otherIncome);
        })
    });

    return otherIncomeFields;
}


module.exports = {
    id_component: 'tab-form',
    id: 'tab-datos-laborales',
    label: 'Datos laborales',
    exclude: [],
    grid: [
        [
            {
                id_component: 'combo',
                id: 'cmb_tipo_trabajo',
                label: 'Tipo de trabajo*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personal_loadCmbWorkType',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_work_information_data',
                options: [],
                order:3,
                msg_error:''
            },
            {
                id_component: 'combo',
                id: 'cmb_tipo_actividad',
                label: 'Actividad*',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personal_loadActivity',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_work_information_data',
                options: [],
                order:3,
                msg_error: 'Si eres estudiante/ama de casa y no tienes ingresos deberá incluir también los datos de tus padres o personas  con ingresos que junto contigo vayan a ser avalistas o titulares del préstamo, pulsando al final del paso 2 en el botón Añadir Avalista u Otro Titular para poder estudiar si la operación es viable.'
            }
        ],
        [
            {
                id_component: 'tab-form',
                label: 'Datos del trabajador',
                id: 'tab-datos-trabajador',
                exclude: [],
                grid: [
                    [
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-antiguedad-profesion'),
                            label: 'Antiguedad en profesión*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-inegresos-netos'),
                            label: 'Ingresos netos anuales*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-importe-justificado'),
                            label: 'Importe justificado*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        }
                    ],
                    [
                        {
                            id_component: 'combo',
                            id: buildComponentId(this, 'cmb_periodicidad'),
                            label: 'Periodicidad',
                            type: 'form',
                            placeholder: 'Seleccione una opción ...',
                            source: 'loadCmbPurpose',
                            onChangeFn: 'personalLoan_onChangeCmbPurpose',
                            target: 'personal_form_work_information_data',
                            options: [],
                            order: 3,
                            msg_error: ''
                        },
                        {
                            id_component: 'combo',
                            id: buildComponentId(this, 'cmb_tipo_tributacion'),
                            label: 'Tipo de tributación*',
                            type: 'form',
                            placeholder: 'Seleccione una opción ...',
                            source: 'loadCmbPurpose',
                            onChangeFn: 'personalLoan_onChangeCmbPurpose',
                            target: 'personal_form_work_information_data',
                            options: [],
                            order: 3,
                            msg_error: ''
                        }
                    ]
                ]
            }
        ],
        [
            {
                id_component: 'tab-form',
                label: 'Datos de la empresa',
                id: 'tab-datos-empresa',
                exclude: [],
                grid: [
                    [
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-nombre-empresa') ,
                            label: 'Nombre*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-cif'),
                            label: 'C.I.F.*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-telefono'),
                            label: 'Telefono*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        }
                    ],
                    [
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-antiguedad-empresa'),
                            label: 'Antiguedad en la empresa*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-domicilio'),
                            label: 'Domicilio*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-localidad'),
                            label: 'Localidad*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        }
                    ],
                    [
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-antiguedad-laboral'),
                            label:'Antiguedad laboral*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        },
                        {
                            id_component: 'combo',
                            id: buildComponentId(this, 'cmb_tipo_relacion-laboral'),
                            label: 'Tipo de relación laboral*',
                            type: 'form',
                            placeholder: 'Seleccione una opción ...',
                            source: 'loadCmbPurpose',
                            onChangeFn: 'personalLoan_onChangeCmbPurpose',
                            target: 'personal_form_work_information_data',
                            options: [],
                            order: 3,
                            msg_error: ''
                        },
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-nomina-mensual-neta'),
                            label: 'Nómina mensual neta*',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        }
                    ],
                    [
                        {
                            id_component: 'input-safe',
                            id: buildComponentId(this, 'input-safe-pagas-extras'),
                            label: 'Pagas extras al año',
                            type_format: 'text',
                            type: 'single',
                            onBlurFn: 'personalLoan_handleBlur'
                        }
                    ]
                ]
            }
        ],
        [
            {
                id_component: 'tab-form',
                label: 'Otros ingresos',
                id: 'tab-datos-otros-ingresos',
                exclude: [],
                grid: [generateOtherIncomeFields('tab-datos-otros-ingresos')]
            }
        ]
    ]
};
