// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
});

const coHolderEdit = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_EDIT_COHOLDER_RESET:
            return initialState.merge();

        case actionTypes.MORTGAGE_REQUEST_EDIT_COHOLDER_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_COHOLDER_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_COHOLDER_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_ADD_COHOLDER_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_ADD_COHOLDER_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_ADD_COHOLDER_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = coHolderEdit;
