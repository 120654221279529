const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    ...(__CONTACT_CENTER__ ? {
    valid: true,
    hireRestriction: false,
}
: {
  valid: false,
}),
});

function validateStep(state, validFlag) {
    
    if (validFlag !== undefined) {
        if (validFlag) {
          return state.merge({
            valid: false,
            hireRestriction: true,
          });
        } else {
          return state.merge({
            valid: true,
            hireRestriction: false,
          });
        }
      }
    return state;
}

function hireCardECardStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_ECARD_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);
        default:
            return state;
    }
}

module.exports = hireCardECardStep1;
