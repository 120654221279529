import actionTypes from 'constants/actionTypes';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isFetching: false,
    isFetched: false,
    error: false,
    markets: []
});

function brokerIAPredictionMarketsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_IA_GET_PREDICTION_MARKETS_FETCHING:
            return state
                .set('isFetched', false)
                .set('isFetching', true)
                .set('error', false);

        case actionTypes.BROKER_IA_GET_PREDICTION_MARKETS_FAILURE:
            return state
                .set('isFetched', false)
                .set('isFetching', false)
                .set('error', action.payload.data);

        case actionTypes.BROKER_IA_GET_PREDICTION_MARKETS_SUCCESS:
            const marketsResults = action.payload.data
            if (marketsResults) {
                return state.set('markets', marketsResults)
                        .set('isFetched', true)
                        .set('isFetching', false)
                        .set('error', false);
            } else {
                return state
                        .set('isFetched', false)
                        .set('isFetching', false)
                        .set('error', true);
            }

        default:
            return state;
    }
}

export default brokerIAPredictionMarketsReducer
