// @ vendor
const Immutable = require('immutable');
const trim = require('lodash/string/trim');

// @ constants
const {
    DOCUMENT_NUMBER_NIF_PASSPORT_REGEX2,
    DOCUMENT_NUMBER_NIF_INVALID_CHARS
} = require('constants/index');
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        institution: '',
        institutionDirty: false,
        institutionIsValid: true,
        nif: '',
        nifDirty: false,
        nifIsValid: true,
        account: '',
        accountDirty: false,
        accountIsValid: true,
        hasNif: true,
    });
}

function validateStep(step) {
    const nif = trim(step.get('nif'));
    const hasNif = step.get('hasNif')
    const account = trim(step.get('account'));
    const nifIsValid = DOCUMENT_NUMBER_NIF_PASSPORT_REGEX2.test(nif);
    const valid = (!hasNif || nifIsValid) && !!account;
    return step.merge({
        valid: valid,
        nif,
        account,
        nifDirty: false,
        accountDirty: false,
        institutionIsValid: true,
        nifIsValid,
        accountIsValid: !!account
    });
}

const setNif = (immState, nif) => {
    return immState.merge({
        nif: (nif + '').replace(DOCUMENT_NUMBER_NIF_INVALID_CHARS, ''),
        nifDirty: true
    });
};

function externalTransferFundStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_INSTITUTION_NAME:
            return state.merge({
                institution: action.payload.institutionName,
                institutionDirty: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_NIF:
            return setNif(state, action.payload.nif);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_ACCOUNT:
            return state.merge({
                account: action.payload.account,
                accountDirty: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_HAS_NIF:
            return state.merge({
                hasNif: action.payload.mustHasNif
            });
    }

    return state;
}

module.exports = externalTransferFundStep1;
