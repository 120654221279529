//@vendors
const Immutable = require('immutable');

//@helpers
const actionTypes = require('constants/actionTypes');

const GLOBAL_POSITION_BOXES = require('constants/globalPositionBoxes');

const MULTIPLE_ALLOWED = {
    cards: true,
    accounts: true,
    pg: true,
    depositsOpen: true,
    depositsClose: true,
};
const initialState = Immutable.fromJS({
    expandedBoxes: {
        accounts: [0], // The first account box will be opened by default.
        cards: __CONTACT_CENTER__ ? [] : [0], // The first card box will be opened by default (only for 'privada').
        depositsOpen: [0], // The first deposit box will be opened by default.
        depositsClose: [0], // The first closed deposit box will be opened by default.
        pg: [
            GLOBAL_POSITION_BOXES.ACCOUNT,
            GLOBAL_POSITION_BOXES.CREDIT_CARD,
            GLOBAL_POSITION_BOXES.CREDIT_LANDING,
            GLOBAL_POSITION_BOXES.INVESTMENT,
            GLOBAL_POSITION_BOXES.LOANS_LANDING,
            GLOBAL_POSITION_BOXES.MORTGAGE_LANDING,
        ]
    }
});

function landingsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LANDING_BOX_TOGGLE:

            const boxes = state.get('expandedBoxes').get(action.payload.type);

            if (!boxes) {
                return state;
            }

            if (!action.payload.expanded) {
                const index = boxes.indexOf(action.payload.id);

                return state.setIn(['expandedBoxes', action.payload.type], boxes.delete(index));
            }

            if (MULTIPLE_ALLOWED[action.payload.type]) {
                return state.mergeDeep({
                    expandedBoxes: {
                        [action.payload.type]: boxes.push(action.payload.id)
                    }
                });
            }

            if (!MULTIPLE_ALLOWED[action.payload.type]) {
                const empty = boxes.clear();
                return state.mergeDeep({
                    expandedBoxes: {
                        [action.payload.type]: empty.push(action.payload.id)
                    }
                });
            }
        default:
            return state
    }
}

module.exports = landingsReducer;
