//@helpers
const formValidationHelper = require('../utilities/formValidationHelper');
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
const { formatAmount } = require('utilities/currencyHelper');
//@constants
const {
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
    DEPOSIT_HIRE_CONFIRMATION_MANAGER,
} = require('constants/index');
const {
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_TYPE,
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_SUBTYPE,
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_STANDARD,
} = require('constants/contactCenter/hireDeposits');
// Common functions for all deposits 13 months
const {
    depositThirteenMonths_isOpenMethodOrNegative: isOpenMethodOrNegative,
    depositBiggerThanBalance,
    modalWithTable,
    modalWithTableLine4Positive,
    modalWithSummary,
    validateStep1,
    validateStep3
} = require('routes/contractsView/libs/depositThirteenMonthsLib');

function depositBiggerThanMaxValueAllowed(contractWizard) {
    if (contractWizard.getIn(['depositHireData', 'bssaldo1'], null) === null) {
        // Product is not configured as part of "Dinero Nuevo" campaign, the check doesn't apply
        return false;
    }

    const depositAmount = findElementbyId(contractWizard.get('card_details'), 'monto_traspasar');

    return depositAmount > contractWizard.getIn(['depositHireData', 'bssaldo1'], 0);
}

function depositThirteenMonthsLinkedMaturities_showFinalModal(contractWizard) {
    if (!contractWizard.get('dataIsFetching') && !isOpenMethodOrNegative(contractWizard.get('depositHireData'))) {
        return depositBiggerThanMaxValueAllowed(contractWizard) || depositBiggerThanBalance(contractWizard);
    }
    return false;
}

function depositThirteenMonthsLinkedMaturities_FinalModalTitle(contractWizard) {
    return depositBiggerThanMaxValueAllowed(contractWizard)
        ? 'contractsView-depositThirteenMonthsStep2ValidationModalTitleBiggerThanAllowed'
        : 'contractsView-depositThirteenMonthsStep2ValidationModalTitle';
}

function depositThirteenMonthsLinkedMaturities_FinalModalChildren(contractWizard) {
    if (!contractWizard.get('dataIsFetching')) {
        const cardDetails = contractWizard.get('card_details');
        const amount = findElementbyId(cardDetails, 'monto_traspasar') || 0;

        let modal;
        if (depositBiggerThanMaxValueAllowed(contractWizard)) {
            modal = modalWithTable(
                contractWizard,
                '',
                formatAmount(amount),
                'contractsView-depositThirteenMonthsFinalModalLine3',
                modalWithTableLine4Positive()
            );
        } else if (depositBiggerThanBalance(contractWizard)) {
            modal = modalWithSummary(contractWizard, formatAmount(amount));
        }

        return modal;
    }
}

function validateStep2(cardDetails, state, isValid) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const slideAmountIsNonZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;
    let newCardDetails;

    if (isValid && isValidAccount && slideAmountIsNonZero) {
        newCardDetails = cardDetails.update(
            cardDetails.findIndex(item => item.get('id') === 'validationModal'),
            item => item.set('openModal', depositThirteenMonthsLinkedMaturities_showFinalModal(state))
        );
    } else {
        newCardDetails = cardDetails;
    }

    return {
        card_details: newCardDetails,
        isValidStep: isValidAccount && slideAmountIsNonZero
    };
}

function deposit_thirteen_months_linked_maturities_validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, immConfirmationCheckboxes);
    }
}

function depositThirteenMonthsLinkedMaturities_hireParamsFn(contractWizard, immAccount) {
    const cardDetails = contractWizard.get('card_details');
    const depositHireData = contractWizard.get('depositHireData');
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const interveners = contractWizard.get('interveners');
    const intervenersData = interveners.map((intervener) => (
        {
            desIntervencion: intervener.get('type'),
            formaIntervencion: intervener.get('interventionWay'),
            nombre: intervener.get('name'),
            numeroPersona: intervener.getIn(['identification', 'code']),
            ordenIntervencion: intervener.get('interventionOrder'),
            tipoIntervencion: intervener.get('typeNumber'),
            tipoPersona: intervener.getIn(['identification', 'type'])
        }
    )).toJS();

    return {
        aa1: depositHireData.get('aa1'),
        aa2: depositHireData.get('aa2'),
        aa3: depositHireData.get('aa3'),
        adelta: depositHireData.get('adelta'),
        campana: DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
        canalComercializacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        canalOperacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        codPeridoLiq: depositHireData.get('codPeridoLiq'),
        cotPeriodo: depositHireData.get('cotPeriodo'),
        cuentaSeleccionada: {
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        },
        datos: {
            dato: intervenersData
        },
        depositoB: 'S', // "N" if is Welcome Deposit, otherwise "S" //@hardcoded
        descPeriodicidad: depositHireData.get('descPeriodicidad'),
        descPlazo: depositHireData.get('descPlazo'),
        descProducto: depositHireData.get('descProducto'),
        fechaVencimiento: depositHireData.get('fechaVencimiento'),
        gestor: DEPOSIT_HIRE_CONFIRMATION_MANAGER,
        importe,
        importeMax: depositHireData.get('importeMax'),
        importeMin: depositHireData.get('importeMin'),
        indCapit: 'N', // interests capitalization "S" or "N" //@hardcoded
        indRevTo: findElementbyId(cardDetails, 'renewDeposit') ? 'S' : 'N', // Renovation type
        indala01: depositHireData.get('indala01'),
        indala02: depositHireData.get('indala02'),
        indala03: depositHireData.get('indala03'),
        indala04: depositHireData.get('indala04'),
        moneda: depositHireData.get('moneda'),
        numEmp: DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
        perLiq: depositHireData.get('perLiq'),
        plazoPos: depositHireData.get('plazoPos'),
        producto: {
            codigodeestandar: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_STANDARD,
            subtipodeproducto: {
                subtipodeproducto: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_SUBTYPE,
                tipodeproducto: {
                    tipodeproducto: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_TYPE // should be the same as tipo
                }
            }
        },
        saldoCuentaSeleccionada: {
            divisa: immAccount.getIn(['balance', 'currency']),
            importe: immAccount.getIn(['balance', 'amount'])
        },
        tasa: depositHireData.get('tasa'),
        tipo: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_TYPE // Should be the same as tipodeproducto
    };
}

module.exports = {
    deposit_thirteen_months_linked_maturities_validateStep: deposit_thirteen_months_linked_maturities_validateStep,
    depositThirteenMonthsLinkedMaturities_hireParamsFn: depositThirteenMonthsLinkedMaturities_hireParamsFn,
    depositThirteenMonthsLinkedMaturities_showFinalModal: depositThirteenMonthsLinkedMaturities_showFinalModal,
    depositThirteenMonthsLinkedMaturities_FinalModalChildren: depositThirteenMonthsLinkedMaturities_FinalModalChildren,
    depositThirteenMonthsLinkedMaturities_FinalModalTitle: depositThirteenMonthsLinkedMaturities_FinalModalTitle,
};
