// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_TYPE_REQUEST,
    MORTGAGE_REQUEST_FETCH_TYPE_SUCCESS,
    MORTGAGE_REQUEST_FETCH_TYPE_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    tipoProducto: '',
    cuotaMensual: 0,
    error: '',
    mortgageAmount: 0
});

const mortgageRequestConditionsType = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_TYPE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_TYPE_SUCCESS:
            return state.merge({
                tipoProducto: action.data.tipoProducto,
                cuotaMensual: action.data.cuotaMensual,
                mortgageAmount: action.data.importeHipoteca,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_TYPE_FAILURE:
            return state.merge({
                tipoProducto: '',
                error: action.data.error,
                isFetching: false
            });
        default:
            return state;
    }
};

module.exports = mortgageRequestConditionsType;
