// @vendor
const Immutable = require('immutable');

// @utilities
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');
const { SPAIN_COUNTRY_CODE, TIN_TYPE } = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        scrolledToError: true,
        birthCountry: '',
        birthCountryDirty: false,
        birthCountryIsValid: true,
        birthProvince: '',
        birthProvinceDirty: false,
        birthProvinceIsValid: true,
        birthSquare: '',
        birthSquareDirty: false,
        birthSquareIsValid: true,
        email: '',
        emailDirty: false,
        emailIsValid: true,
        emailNotEmpty: true,
        locality: '',
        localityDirty: false,
        localityIsValid: true,
        numberStreet: '',
        numberStreetDirty: false,
        numberStreetIsValid: true,
        phoneCode: SPAIN_COUNTRY_CODE,
        phoneNumber: '',
        phoneNumberDirty: false,
        phoneNumberIsValid: true,
        phoneNumberNotEmpty: true,
        postalCode: '',
        postalCodeDirty: false,
        postalCodeIsValid: true,
        postalCodeIsNotEmpty: true,
        postalCodeValidLength: true,
        province: '',
        provinceDirty: false,
        provinceIsValid: true,
        repeatEmail: '',
        repeatEmailDirty: false,
        repeatEmailIsValid: true,
        repeatEmailNotEmpty: true,
        repeatPhoneNumber: '',
        repeatPhoneNumberDirty: false,
        repeatPhoneNumberIsValid: true,
        repeatPhoneNumberNotEmpty: true,
        restOfAddress: '',
        streetName: '',
        streetNameDirty: false,
        streetNameIsValid: true,
        taxAdditionalCountryIsAdding: false,
        taxAdditionalCountryIsValid: true,
        typeOfRoads: '',
        typeOfRoadsDirty: false,
        typeOfRoadsIsValid: true,
        listPaisesTributacionAdic: [],
        newFiscalData: []
    });
}

function setField(step, action) {
    const { inputField, data } = action.payload;
    return step.merge({
        [inputField]: data,
        [`${inputField}Dirty`]: true
    });
}

function setAdditionalCountries(step, action) {
    const {
        data: { countryCode, taxingReasonCode, taxingCountryDocumentText, taxingCountryDocumentID },
        index
    } = action.payload;
    const newCountry = Immutable.fromJS({
        paisTributacion: countryCode,
        indTipoTributacion: taxingReasonCode,
        isEditing: false,
        textTin: taxingCountryDocumentText,
        tin: { tipodocumpersonacorp: TIN_TYPE, codigodocumpersonacorp: taxingCountryDocumentID }
    });
    const immCountries = step.get('listPaisesTributacionAdic');

    let countries;
    if (index === immCountries.size) {
        countries = immCountries.push(newCountry);
    } else {
        countries = immCountries.update(index, item => item.mergeDeep(newCountry));
    }
    return step.merge({
        listPaisesTributacionAdic: countries
    });
}

function deleteAdditionalCountries(step, action) {
    return step.merge({
        listPaisesTributacionAdic: step
            .get('listPaisesTributacionAdic')
            .delete(action.payload.index)
    });
}

function profileEditorStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.PROFILE_ACTION_CHANGE_VALUE:
            return state.mergeDeep(action.payload);
        case actionTypes.FETCH_PROFILE_SUCCESS:
            const profile = action.profile;
            const dispositivos = get(profile, 'listaDispositivos.dispositivo') || [];
            const emailObject = dispositivos.find(d => {
                return d.tipoDispositivo.trim() === 'I';
            });
            const email = !!emailObject ? emailObject.detalleContacto.trim() : '';
            const phoneObject = dispositivos.find(d => {
                return d.tipoDispositivo.trim() === 'T';
            });
            const arrayPhoneNumber = !!phoneObject && !!phoneObject.detalleContacto && phoneObject.detalleContacto.trim().split('-');
            const firstCountry = get(profile, 'listPaisesTributacionAdic.tributAdicional[0]');
            let listPaisesTributacionAdic;
            if (firstCountry) {
                listPaisesTributacionAdic =
                    firstCountry.indTipoTributacion === null &&
                        firstCountry.paisTributacion === null
                        ? []
                        : profile.listPaisesTributacionAdic.tributAdicional;
            } else {
                listPaisesTributacionAdic = [];
            }

            listPaisesTributacionAdic.forEach((paisTributacion, index) => {
                paisTributacion.id = index;
            });

            const hasCountryCode = arrayPhoneNumber.length === 3;
            const phoneCode = parseInt(
                hasCountryCode ? arrayPhoneNumber[0] : SPAIN_COUNTRY_CODE
            ).toString();
            const phoneNumber =
                arrayPhoneNumber[hasCountryCode ? 1 : 0] + arrayPhoneNumber[hasCountryCode ? 2 : 1];
            let newState = {
                birthCountry: profile.paisNacimiento || '',
                birthProvince: profile.provincia.provincia || '',
                birthSquare: profile.plazaDeNacimiento || '',
                email,
                listPaisesTributacionAdic,
                locality: profile.nombrePlaza || '',
                numberStreet: profile.numeroDeVia || '',
                phoneCode,
                phoneNumber,
                postalCode: profile.codigoPostal || '',
                province: profile.plaza.plaza.provincia || '',
                restOfAddress: profile.restoDomicilio || '',
                streetName: profile.nombreDeVia || '',
                typeOfRoads: profile.tipoDeVia || ''
            };
            for (let key in newState) {
                newState[`${key}Initial`] = state.has(`${key}Initial`) ? state.get(`${key}Initial`) : newState[key];
            }

            return state.merge(newState);
        case actionTypes.PROFILE_EDIT_SET_FIELD:
            return setField(state, action);
        case actionTypes.PROFILE_UPDATE_ADDITIONAL_TAX_COUNTRIES:
            return setAdditionalCountries(state, action);
        case actionTypes.PROFILE_DELETE_ADDITIONAL_TAX_COUNTRIES:
            return deleteAdditionalCountries(state, action);
        case actionTypes.PROFILE_EDIT_EDITING_TAX_ADDITIONAL_COUNTRY_SET:
            const { index, value } = action.payload;
            return state.merge({
                listPaisesTributacionAdic: state.get('listPaisesTributacionAdic').update(index, component => component.set('isEditing', value))
            });
        case actionTypes.PROFILE_EDIT_ADDING_TAX_ADDITIONAL_COUNTRY_SET:
            return state.merge({
                taxAdditionalCountryIsAdding: action.payload.value,
            });
        case actionTypes.SET_FISCAL_DATA:
            return state.merge({
                newFiscalData: action.countries
            });
        default:
            return state;
    }
}

module.exports = profileEditorStep1;
