const {
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE,
    DEPOSIT_HIRE_CONFIRMATION_PRODUCT_TYPE
} = require('constants/index');

module.exports = {
    title: 'contractsView-depositThirteenMonthsStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info',
            cms_key: 'deposit13m',
            services: [
                {
                    propName: 'requestReferenceStandard',
                    params: {
                        tipoProducto: DEPOSIT_HIRE_CONFIRMATION_PRODUCT_TYPE,
                        subtipoProducto: DEPOSIT_HIRE_CONFIRMATION_PRODUCT_SUBTYPE
                    },
                    alreadyCalled: false
                }
            ]
        },
        ...(
            __CONTACT_CENTER__ ?
            [{
                id_component: 'legal-information'
            }] : []
        )
    ]
};
