// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        init: {
            error: false,
            isFetching: false,
            success: false,
        },
        documents: {
            error: false,
            isFetching: false,
            success: false,
        },
        operationProcessId: null,
    });
}

function contractualPolling(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CONTRACTUAL_POLLING_INIT_REQUEST:
            return state.mergeDeep({
                init: {
                    isFetching: true
                }
            });
        case actionTypes.CONTRACTUAL_POLLING_INIT_SUCCESS:
            return state.mergeDeep({
                init: {
                    isFetching: false,
                    success: true
                },
                operationProcessId: action.payload.operationProcessId,
            });
        case actionTypes.CONTRACTUAL_POLLING_INIT_FAILURE:
            return state.mergeDeep({
                init: {
                    isFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorContractual'
                    }
                }
            });
        case actionTypes.CONTRACTUAL_POLLING_DOCUMENTS_REQUEST:
            return state.mergeDeep({
                documents: {
                    isFetching: true
                }
            });
        case actionTypes.CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS:
            return state.mergeDeep({
                documents: {
                    isFetching: false,
                    success: true,
                },
            });
        case actionTypes.CONTRACTUAL_POLLING_DOCUMENTS_FAILURE:
            return state.mergeDeep({
                documents: {
                    isFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorContractual'
                    }
                }
            });
        case actionTypes.CONTRACTUAL_POLLING_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = contractualPolling;
