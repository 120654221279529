const isAllowedCountry = (notAllowedCountries, originCountry) => {
    return !notAllowedCountries || (originCountry && !notAllowedCountries.includes(originCountry))
}

const isAllowedCountryTink = (notAllowedCountries, originCountry) => {
    if(notAllowedCountries && Array.isArray(notAllowedCountries)) {
        return !notAllowedCountries || (originCountry && !notAllowedCountries.includes(originCountry))
    } else {
        return notAllowedCountries;
    }
};

export { isAllowedCountry, isAllowedCountryTink };
