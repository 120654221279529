const { isUndefined } = require('lodash/lang');

const {
    OPERATION_LOG_NONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_DONE,
} = require('utilities/contactCenter/wrapUp/constants');

const {
    CARD_COUNTRIES_ONLY_SET_SUCCEEDED,
    CARD_COUNTRIES_ONLY_SET_FAILED,
    CARD_COUNTRY_SET_SUCCEEDED,
    CARD_COUNTRY_SET_FAILED,
    CARD_CHANNELS_SET_REQUESTED,
    CARD_CHANNELS_SET_SUCCEEDED,
    CARD_CHANNELS_SET_FAILED,
    CARD_COUNTRY_SET_REQUESTED,
    CARD_ZONE_ENTER,
    CARD_ZONE_SET_REQUESTED,
    CARD_ZONE_SET_SUCCEEDED,
    CARD_ZONE_SET_FAILED,
    CARD_INFO_GET_SUCCEEDED,
    CARD_INFO_GET_FAILED,
    CARD_INFO_GET_REQUESTED,
    CARD_COUNTRIES_ONLY_SET_REQUESTED,
    CARD_INFO_FROM_CACHE
} = require('constants/actionTypes');

const CARD_CONTROL_CONFIG = 5217;
const CARD_CONTROL_CONFIG_COUNTRY_ZONE = 5204;
const CARD_CONTROL_CONFIG_ATM_ACTIVATE = 5207;
const CARD_CONTROL_CONFIG_ATM_DEACTIVATE = 5208;
const CARD_CONTROL_CONFIG_TPV_ACTIVATE = 5209;
const CARD_CONTROL_CONFIG_TPV_DEACTIVATE = 5210;
const CARD_CONTROL_CONFIG_ONLINE_ACTIVATE = 5211;
const CARD_CONTROL_CONFIG_ONLINE_DEACTIVATE = 5212;

let latestCardChannels = undefined;
let lastEntryAction = null;

const channelEntryAction = (channel, activate_action_code, deactivate_action_code) => (action) => {
    const { payload: { channels } } = action
    let operationState = OPERATION_LOG_NONE
    let actionCode

    const currentChannel = channels[channel]

    if (typeof currentChannel !== 'undefined') {
        latestCardChannels = {
            channel: channel,
            enabled: currentChannel
        }
        operationState = OPERATION_LOG_DONE
        actionCode = currentChannel ? activate_action_code: deactivate_action_code
    }

    return {
        actionCode,
        operationState
    }
}

const channelFinishAction = (channel, activate_action_code, deactivate_action_code) => (action) => {
    let operationState = OPERATION_LOG_NONE
    let actionCode

    if (latestCardChannels && latestCardChannels['channel'] === channel) {
        operationState = (action.type === CARD_CHANNELS_SET_SUCCEEDED) ? OPERATION_LOG_DONE : OPERATION_LOG_FAIL
        actionCode = latestCardChannels['enabled'] ? activate_action_code : deactivate_action_code
    }

    return {
        actionCode,
        operationState
    }
}

module.exports = {
    [CARD_CONTROL_CONFIG]: {
        entry: {
            action: [ CARD_INFO_GET_SUCCEEDED, CARD_INFO_GET_FAILED, CARD_INFO_FROM_CACHE, CARD_INFO_GET_REQUESTED, CARD_ZONE_ENTER ],
            check: (action) => {
                const { type } = action;
                const actionCode = CARD_CONTROL_CONFIG;
                let operationState = OPERATION_LOG_DONE;

                if (
                    type === CARD_INFO_GET_FAILED ||
                    isUndefined(type) && lastEntryAction === CARD_INFO_GET_REQUESTED
                ) {
                    lastEntryAction = null;
                    operationState = OPERATION_LOG_FAIL;
                } else if (
                    type === CARD_INFO_GET_REQUESTED
                    ) {
                    lastEntryAction = CARD_INFO_GET_REQUESTED;
                    operationState = OPERATION_LOG_NONE;
                } else if (
                    isUndefined(type) && lastEntryAction !== CARD_INFO_GET_REQUESTED
                ) {
                    lastEntryAction = null;
                    operationState = OPERATION_LOG_NONE;
                }

                return {
                    actionCode,
                    operationState
                }
            }
        }
    },
    [CARD_CONTROL_CONFIG_ATM_ACTIVATE]: {
        entry: {
            action: [CARD_CHANNELS_SET_REQUESTED],
            check: channelEntryAction('atm', CARD_CONTROL_CONFIG_ATM_ACTIVATE, CARD_CONTROL_CONFIG_ATM_DEACTIVATE)
        },
        finish: {
            action: [CARD_CHANNELS_SET_SUCCEEDED, CARD_CHANNELS_SET_FAILED],
            check: channelFinishAction('atm', CARD_CONTROL_CONFIG_ATM_ACTIVATE, CARD_CONTROL_CONFIG_ATM_DEACTIVATE)
        }
    },
    [CARD_CONTROL_CONFIG_TPV_ACTIVATE]: {
        entry: {
            action: [CARD_CHANNELS_SET_REQUESTED],
            check: channelEntryAction('tpv', CARD_CONTROL_CONFIG_TPV_ACTIVATE, CARD_CONTROL_CONFIG_TPV_DEACTIVATE)
        },
        finish: {
            action: [CARD_CHANNELS_SET_SUCCEEDED, CARD_CHANNELS_SET_FAILED],
            check: channelFinishAction('tpv', CARD_CONTROL_CONFIG_TPV_ACTIVATE, CARD_CONTROL_CONFIG_TPV_DEACTIVATE)
        }
    },
    [CARD_CONTROL_CONFIG_ONLINE_ACTIVATE]: {
        entry: {
            action: [CARD_CHANNELS_SET_REQUESTED],
            check: channelEntryAction('online', CARD_CONTROL_CONFIG_ONLINE_ACTIVATE, CARD_CONTROL_CONFIG_ONLINE_DEACTIVATE)
        },
        finish: {
            action: [CARD_CHANNELS_SET_SUCCEEDED, CARD_CHANNELS_SET_FAILED],
            check: channelFinishAction('online', CARD_CONTROL_CONFIG_ONLINE_ACTIVATE, CARD_CONTROL_CONFIG_ONLINE_DEACTIVATE)
        }
    },
    [CARD_CONTROL_CONFIG_COUNTRY_ZONE]: {
        entry: {
            action: [CARD_ZONE_SET_REQUESTED, CARD_COUNTRY_SET_REQUESTED, CARD_COUNTRIES_ONLY_SET_REQUESTED],
            check: () => ({
                actionCode: CARD_CONTROL_CONFIG_COUNTRY_ZONE,
                operationState: OPERATION_LOG_DONE
            })
        },
        finish: {
            action: [
                CARD_COUNTRIES_ONLY_SET_FAILED,
                CARD_COUNTRIES_ONLY_SET_SUCCEEDED,
                CARD_ZONE_SET_FAILED,
                CARD_ZONE_SET_SUCCEEDED,
                CARD_COUNTRY_SET_FAILED,
                CARD_COUNTRY_SET_SUCCEEDED,
            ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;

                if ( [ CARD_COUNTRIES_ONLY_SET_FAILED, CARD_ZONE_SET_FAILED, CARD_COUNTRY_SET_FAILED].includes(action.type)) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: CARD_CONTROL_CONFIG_COUNTRY_ZONE,
                    operationState
                }
            }
        }
    }
}

module.exports.secondaryFlows = [
    CARD_CONTROL_CONFIG_ATM_ACTIVATE,
    CARD_CONTROL_CONFIG_ATM_DEACTIVATE,
    CARD_CONTROL_CONFIG_TPV_ACTIVATE,
    CARD_CONTROL_CONFIG_TPV_DEACTIVATE,
    CARD_CONTROL_CONFIG_ONLINE_ACTIVATE,
    CARD_CONTROL_CONFIG_ONLINE_DEACTIVATE,
    CARD_CONTROL_CONFIG_COUNTRY_ZONE,
]
