const Immutable = require('immutable');

const {
    CATEGORIES_LIST_FETCHING,
    CATEGORIES_LIST__SUCCESS,
    CATEGORIES_LIST__FAILURE
} = require('constants/actionTypes');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            error: false,
            fetching: false,
            categoriesList: []
        })
    );
};

function expensesByCategoriesListCategories(state = setInitialState(), action) {
    switch (action.type) {
        case CATEGORIES_LIST_FETCHING:
            return state.merge({
                error: false,
                fetching: true
            });

        case CATEGORIES_LIST__FAILURE:
            return state.merge({
                error: true,
                fetching: false
            });

        case CATEGORIES_LIST__SUCCESS:
            return state.merge({
                error: false,
                fetching: false,
                categoriesList: action.payload.data
            });

        default:
            return state;
    }
}

module.exports = expensesByCategoriesListCategories;