//@vendor
const { isUndefined } = require('lodash/lang');
const { get } = require('lodash');
const range = require('lodash/utility/range');

//@constants
const { EMPTY_CONCEPT } = require('constants/index')
const {
    CARD_PAYMENT_METHOD_FIXED,
    DATE_FORMAT_API,
    PERIODICITY_CONSTANTLY,
    PERIODICITY_DATE,
    API_ENTERPRISE_NUMBER,
    API_CENTER,
    PERIODICITY_COUNT,
    PREMIUM_CARD,
    DIAMOND_CARD,
    VIAT_CARD,
    REVOLVING_CARD,
    OPEN_CREDIT_CARD,
    MAX_LIMIT_CREDIT_CARD
 } = require('constants/index');
 const { NERUDA_CARD_BONIFICATION_LEVELS, NERUDA_CARD_BONIFICATION_STATUS } = require('constants/nerudaCard');

 // @utilities
const { hash } = require('utilities/hash');

const getAssociatedCardOwner = card => {
    let owner = EMPTY_CONCEPT;
    if (card.get('beneficiaryName') && card.get('beneficiaryName') !== EMPTY_CONCEPT) {
        owner = card.get('beneficiaryName');
    } else if (card.get('titularName') && card.get('titularName') !== EMPTY_CONCEPT) {
        owner = card.get('titularName');
    }
    return owner;
};

const getCardAvailableBalance = card => {
    // This amount is the more recent possible value
    let amount = card.getIn(['extra', 'eCardInfo', 'saldoActualPrepago', 'importe']);

    if (isUndefined(amount)){
        // This is the available balance from the PG. It can be outdated, so we only use it as fallback
        amount = card.getIn(['availableBalance', 'amount']);
    }

    const currency = card.getIn(['availableBalance', 'currency']);

    return {
        amount: amount || 0,
        currency: currency || 'EUR'
    };
};

const creditOptionsDisableContinue = (maxLimitToAuthorize, methodOfPayment) => {
    let disableContinue = false;

    if (methodOfPayment) {
        const selectedFixedType = methodOfPayment.get('methodOfPayment') === CARD_PAYMENT_METHOD_FIXED;
        const fixedFeeAmount = methodOfPayment.get('fixedFeeAmount') || '';
        disableContinue = selectedFixedType && fixedFeeAmount > maxLimitToAuthorize
    }

    return disableContinue;
};

const getEndDateForCardCharge= (immCardCharge) => {
    let endDateValue;

    switch (immCardCharge.get('periodicityType')) {
        case PERIODICITY_CONSTANTLY:
            endDateValue = null;
            break;
        case PERIODICITY_DATE:
            endDateValue = immCardCharge
                .get('periodicityDate')
                .format(DATE_FORMAT_API);
            break;
        case PERIODICITY_COUNT:
            endDateValue= immCardCharge
                .get('periodicityEstimatedEndDate')
                .format(DATE_FORMAT_API);
            break;
    }
    return endDateValue;
}

const formatCardContract = immCard => {
    return `${API_ENTERPRISE_NUMBER}${API_CENTER}${immCard.getIn(['contract', 'product'])}${immCard.getIn(['contract', 'number'])}`;
}

const formatCardNumber = (number, lengthNumberVisibles, character) => {
    const numberNoSpaces = number.replace(/\s/g, '');
    const result = ((character.repeat(numberNoSpaces.length - lengthNumberVisibles)) + (numberNoSpaces.substr(-lengthNumberVisibles))).match(/.{1,4}/g).join().replace(/,/g , " ");
    return result;
}

const getPANWithSpaces = immCard => immCard.get('number');

const getPANWithoutSpaces = immCard => immCard.get('number').replace(/\s/g, '');

const getFormatedShippingAddress = (shippingAddress) => {
    const address = shippingAddress.get('domicilioEsp');
    let shippingAddressObj = {
        country : {value : address.get('pais')},
        number : {value : address.get('numeroVia')},
        postalCode : {value : address.get('codigoPostal')},
        population : {value : address.get('localidad')},
        province : {value : address.getIn(['plaza','provincia'])},
        others : {value : address.get('anexoVia')},
        roadName : {value : address.get('nombreVia')},
        roadType : {value : address.get('tipoVia')},
        numDomicilio : shippingAddress.get('numDomicilio')
    }
    return shippingAddressObj;
}

const getCardId = (panNumber) => {
    let cardId;

    if (!!panNumber) {
        cardId = hash(formatCardNumber(panNumber, panNumber.length, ' '));
    }

    return cardId;
}

const getCardType = immCard => {
    if(immCard.get('isPremium')) return PREMIUM_CARD
    else if(immCard.get('isDiamond')) return DIAMOND_CARD
    else if(immCard.get('isViaT')) return VIAT_CARD
    else if(immCard.get('isRevolving')) return REVOLVING_CARD
    else return OPEN_CREDIT_CARD
}

const getCardData = (creditType, type, immGlobalVariables) => {

    const tintaeValues = {
        'OPEN_CREDIT_CARD': immGlobalVariables.getIn(['variables', 'parameters', 'open_credit', type]),
        'DIAMOND_CARD': immGlobalVariables.getIn(['variables', 'parameters', 'pack_diamond', type]),
        'PREMIUM_CARD': immGlobalVariables.getIn(['variables', 'parameters', 'pack_premium', type]),
        'VIAT_CARD': immGlobalVariables.getIn(['variables', 'parameters', 'via-t', type]),
    };

    return tintaeValues[creditType];
}

const getCardTypeHire = creditType => {
    if(creditType === 'premium') return PREMIUM_CARD
    else if(creditType === 'diamond') return DIAMOND_CARD
    else if(creditType === 'viat') return VIAT_CARD
    else if(creditType === 'revolving') return REVOLVING_CARD
    else return OPEN_CREDIT_CARD
}

const getTooltipOptions = (value, type) => {
    let valueOutput = ''
    switch (type) {
        case PREMIUM_CARD:
                valueOutput = value + 'Premium'
            break;
        case DIAMOND_CARD:
                valueOutput = value + 'Diamond'
            break;
        case VIAT_CARD:
                valueOutput = value + 'ViaT'
            break;
        case REVOLVING_CARD:
                valueOutput = value + 'Revolving'
            break;
        case OPEN_CREDIT_CARD:
                valueOutput = value + 'OpenCredit'
            break;
        default:
                valueOutput = value
            break;
    }
    return valueOutput
}

const getInterests = (immGlobalVariables, immCard) => {
    const type = getCardType(immCard)
    let interestObject = {
        interestMn : '',
        interestTae : '',
        interestTin : '',
    }
    switch (type) {
        case PREMIUM_CARD:
                const immPackPremiumPercentages = immGlobalVariables.getIn(['variables', 'parameters', 'pack_premium']);
                interestObject = {
                    interestMn : immPackPremiumPercentages && immPackPremiumPercentages.get('aplazado_mensual'),
                    interestTae : immPackPremiumPercentages && immPackPremiumPercentages.get('aplazado_tae'),
                    interestTin : immPackPremiumPercentages && immPackPremiumPercentages.get('aplazado_tin')
                }
            break;
        case DIAMOND_CARD:
                const immPackDiamondPercentages = immGlobalVariables.getIn(['variables', 'parameters', 'pack_diamond']);
                interestObject = {
                    interestMn : immPackDiamondPercentages && immPackDiamondPercentages.get('aplazado_mensual'),
                    interestTae : immPackDiamondPercentages && immPackDiamondPercentages.get('aplazado_tae'),
                    interestTin : immPackDiamondPercentages && immPackDiamondPercentages.get('aplazado_tin')
                }
            break;
        case VIAT_CARD:
                const immViaTPercentages = immGlobalVariables.getIn(['variables', 'parameters', 'via-t']);
                interestObject = {
                    interestMn : immViaTPercentages && immViaTPercentages.get('aplazado_mensual'),
                    interestTae : immViaTPercentages && immViaTPercentages.get('aplazado_tae'),
                    interestTin : immViaTPercentages && immViaTPercentages.get('aplazado_tin')
                }
            break;
        case REVOLVING_CARD:
                const immRevolvingPackPercentages = immGlobalVariables.getIn(['variables', 'parameters', `revolving_pack_${immCard.get('revolvingPack')}`]);
                interestObject = {
                    interestMn : immRevolvingPackPercentages && immRevolvingPackPercentages.get('aplazado_mensual'),
                    interestTae : immRevolvingPackPercentages && immRevolvingPackPercentages.get('aplazado_tae'),
                    interestTin : immRevolvingPackPercentages && immRevolvingPackPercentages.get('aplazado_tin'),
                }
        case OPEN_CREDIT_CARD:
                const immOpenCreditPercentages = immGlobalVariables.getIn(['variables', 'parameters', 'open_credit']);
                interestObject = {
                    interestMn : immOpenCreditPercentages && immOpenCreditPercentages.get('aplazado_mensual'),
                    interestTae : immOpenCreditPercentages && immOpenCreditPercentages.get('aplazado_tae'),
                    interestTin : immOpenCreditPercentages && immOpenCreditPercentages.get('aplazado_tin'),
                }
            break;
        default:
                const immOpenCreditPercentagesDefault = immGlobalVariables.getIn(['variables', 'parameters', 'open_credit']);
                interestObject = {
                    interestMn : immOpenCreditPercentagesDefault && immOpenCreditPercentagesDefault.get('aplazado_mensual'),
                    interestTae : immOpenCreditPercentagesDefault && immOpenCreditPercentagesDefault.get('aplazado_tae'),
                    interestTin : immOpenCreditPercentagesDefault && immOpenCreditPercentagesDefault.get('aplazado_tin'),
                }
            break;
    }

    if(!interestObject.interestMn) {
        interestObject = {
            interestMn : '',
            interestTae : '',
            interestTin : '',
        }
    }
    return interestObject
}

const checkIfPinHasSameCharacters = (pin) => {
    const firstNum = pin.charAt(0);

    return pin === `${firstNum}${firstNum}${firstNum}${firstNum}`;
}

const checkPinInSecuence = (pin) => {
    const firstNumber = parseInt(pin.charAt(0));

    let ascendingNumbers;
    let descendingNumbers;

    if (firstNumber < 7) {
        ascendingNumbers = range(firstNumber, firstNumber + 4).join("");
    }

    if (firstNumber > 2) {
        descendingNumbers = range(firstNumber - 3, firstNumber + 1).reverse().join("");
    }

    return pin === ascendingNumbers || pin === descendingNumbers;
}

const checkBirthDateInPin = (pin, birthDate) => {
    const parsedBirthDate = birthDate.split('-');

    let retDate;

    if (Array.isArray(parsedBirthDate)) {
        const year = parsedBirthDate[0];
        const dayAndMonth = parsedBirthDate[2] + parsedBirthDate[1];
    
        retDate = pin === year || pin === dayAndMonth;
    }

    return retDate;
}

const isValidPin = (pin, birthDate) => {
    let isValid = true;

    if (pin.length !== 4 || checkIfPinHasSameCharacters(pin) || 
        checkPinInSecuence(pin) || checkBirthDateInPin(pin, birthDate)) {
        isValid = false;
    }

    return isValid;
}

const isAvailableHireCreditCards = (cardsHired) => {
    let creditCards = cardsHired.getIn(['credit', 'byOrder']).filter(cardId => {
        const immCard = cardsHired.getIn(['byId', cardId]);
        return immCard.get('status') === 'EN VIGOR';
    });
    return creditCards.size < MAX_LIMIT_CREDIT_CARD;
}

export const getFormattedProductAdvantages = (advantages) => {
    let advantagesList = advantages.map(advantage => {
       return {
            id: get(advantage, 'attributes.field_cms_id'),
            text : get(advantage, 'attributes.field_title'),
            icon : get(advantage, 'attributes.field_icon'),
            description : get(advantage, 'attributes.field_description.processed')
        }
    });
    return advantagesList;
}

export const getFormattedProductBannerAdvantages = (advantages) => {
    let advantagesList = advantages.map(advantage => {
        return {
            text : get(advantage, 'attributes.field_description.processed') || '',
            icon : get(advantage, 'attributes.field_icon')
        }
    });
    return advantagesList;
}

export const getNerudaBonification = cardStandard => {
    const bonificationLevel = Object.keys(NERUDA_CARD_BONIFICATION_LEVELS).find(bonificationStandard => bonificationStandard === cardStandard);
    const bonificationStatus = Object.keys(NERUDA_CARD_BONIFICATION_STATUS).find(bonificationStandard => bonificationStandard === cardStandard);

    return {
        level: NERUDA_CARD_BONIFICATION_LEVELS[bonificationLevel],
        status: NERUDA_CARD_BONIFICATION_STATUS[bonificationStatus],
    }
}

module.exports = {
    getAssociatedCardOwner,
    getCardType,
    getTooltipOptions,
    getInterests,
    getCardId,
    getCardTypeHire,
    getCardData,
    getCardAvailableBalance,
    isAvailableHireCreditCards,
    getNerudaBonification,
    creditOptionsDisableContinue,
    getEndDateForCardCharge,
    formatCardContract,
    formatCardNumber,
    getPANWithSpaces,
    getPANWithoutSpaces,
    getFormatedShippingAddress,
    getFormattedProductAdvantages,
    getFormattedProductBannerAdvantages,
    isValidPin,
}

