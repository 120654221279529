// @ vendors
const React = require('react');
// @ components
import AccountsTransferRedirector from '../../../components/commons/accountsTransferRedirector/accountsTransferRedirector';
// @ utilities
const formHelper = require('../utilities/formHelper');
const formValidationHelper = require('../utilities/formValidationHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
const { FormattedText, formatText } = require('core/i18n').i18n;
// @ constants
const { API_CENTER, API_ENTERPRISE_NUMBER } = require('constants/index');
const { TRANSFER_ON_SAVINGS_ACCOUNT_CTA } = require('constants/flagBasedFeatures');

function eAccount_signParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails ? findElementbyId(immCardDetails, 'cta_seleccionada') : null;
    let selectedAccount;

    if (immAccounts && selectedAccountId) {
        const immAccount = immAccounts.getIn(['byId', selectedAccountId]);
        selectedAccount = API_ENTERPRISE_NUMBER + API_CENTER + immAccount.get('product') + immAccount.get('contractNumber');
    }

    return {
        originAccount: selectedAccount
    };
}

function eAccount_onChangeAmount(amount) {
    return [{
        target: 'monto_traspasar',
        value: amount
    }];
}

function eAccount_onChangeIntervener(interveners, typeSelected, index) {
    const intervinientesList = interveners.map(item => {
        return item.get('id') === index ? item.set('type', typeSelected) : item;
    });

    return [{
        target: 'intervinientes_list',
        value: intervinientesList
    }];
}

function validateStep1(card_details) {
    return {
        card_details,
        isValidStep: true
    };
}

function validateStep2Web(immCardDetails) {
    return {
        card_details: immCardDetails,
        isValidStep: true
    }
}

function validateAccountStep(immCardDetails) {
    let validationObject = {
        card_details: immCardDetails,
        isValidStep: false
    }
    validationObject = formValidationHelper.isValidAccount(validationObject.card_details);
    return validationObject;
}

function validateStep2(immCardDetails) {
    if (!__CONTACT_CENTER__) {
        return validateStep2Web(immCardDetails);
    } else {
        return validateAccountStep(immCardDetails);
    }
}

function validateCheckboxesStep(card_details, confirmationCheckboxes) {
    const updatedCheckboxList = confirmationCheckboxes.get('list').map(
        item => item.set('errorState', item.get('isRequired') && !item.get('value'))
    );

    return {
        card_details,
        isValidStep: confirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep3(card_details, confirmationCheckboxes) {
    if (!__CONTACT_CENTER__) {
        return validateAccountStep(card_details);
    } else {
        return validateCheckboxesStep(card_details, confirmationCheckboxes);
    }
}

function validateStep4(card_details, confirmationCheckboxes) {
    if (__CONTACT_CENTER__) {
        return {
            card_details,
            isValidStep: true
        }
    } else {
        return validateCheckboxesStep(card_details, confirmationCheckboxes);
    }

}

function eAccount_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false, stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    const immInterveners = state.get('interveners');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails);

        case 2:
            return validateStep2(immCardDetails, immInterveners);

        case 3:
            return validateStep3(immCardDetails, immConfirmationCheckboxes);

        case 4:
            return validateStep4(immCardDetails,immConfirmationCheckboxes);
    }
}

function eAccount_setSuccessStepPrefix(contractWizard, immProfileFields) {
    const accountData = contractWizard.getIn(["mainAccount", "data"]);
    const accountType = accountData.getIn(['accountDefinition', 'accountType']);
    
    let successStepPrefix = {};

    switch (accountType) {
        case 'SAVINGS_WITHOUT_CONDITION_WITH_TERM':
            successStepPrefix.flow = 'withoutBonificationWithTerm_';
            break;
    
        default:
            break;
    }

    if (formHelper.haveEmail(immProfileFields)) {
        successStepPrefix.email = 'successWithEmail_';
    }

    return successStepPrefix;
}

function eAccount_buildExtraInfo() {
    return (
        <p><FormattedText value="contractsView-eAccountTooltipText" /></p>
    );
}


function eAccount_pollingNextButtonDisabled(contractWizard, immPrecontractualDocumentation) {
    const precontractualDocumentation = immPrecontractualDocumentation.getIn(['documentation', 'documents'], []).toJS();
    const allDocumentationAreAccepted = precontractualDocumentation.every(document => document.isAccepted);
    return !allDocumentationAreAccepted
}

function loadTermsAndConditionsLegalWindow(setTermsAndCondsLegalWindow) {
    setTermsAndCondsLegalWindow();
}

function verifyAccountLimit(limitAccount) {
    limitAccount && limitAccount()
}

function handleSuccessStepGoToTransfer(history, immContractsState) {
    const newAccount = immContractsState.get('newAccount');

    history.push({
        pathname: '/myprofile/transfers/pass',
        state: {
            accountCodbban: newAccount,
        }
    });
}

function eAccount_successStepCustomInstructions(history, immContractsState, immFlagBasedFeatures) {
    if (immFlagBasedFeatures.get(TRANSFER_ON_SAVINGS_ACCOUNT_CTA)) {
        const title = formatText('contractsView-contractsView_savingsAccountGoToTransferTitle');
        const message = formatText('contractsView-contractsView_savingsAccountGoToTransferMessage');
        const buttonText = formatText('contractsView-contractsView_savingsAccountGoToTransferCTA');
        
        return <AccountsTransferRedirector title={title} message={message} buttonText={buttonText} handleClick={() => handleSuccessStepGoToTransfer(history, immContractsState)} />
    } else {
        return null;
    }
}

module.exports = {
    eAccount_onChangeAmount,
    eAccount_onChangeIntervener,
    eAccount_validateStep,
    eAccount_signParams,
    eAccount_buildExtraInfo,
    eAccount_setSuccessStepPrefix,
    eAccount_pollingNextButtonDisabled,
    loadTermsAndConditionsLegalWindow,
    verifyAccountLimit,
    eAccount_successStepCustomInstructions,
};
