const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { BROKER_NO_STOCKS_ACCOUNT_SELECTED } = require('constants/index');

function validateAccountValues(step) {
    const accountIdFrom = step.get('accountIdFrom');
    const accountIdTo = step.get('accountIdTo');
    return (
        accountIdFrom !== BROKER_NO_STOCKS_ACCOUNT_SELECTED &&
        accountIdTo !== BROKER_NO_STOCKS_ACCOUNT_SELECTED
    );
}

function setInitialState() {
    return Immutable.Map().merge({
        accountIdFrom: BROKER_NO_STOCKS_ACCOUNT_SELECTED,
        accountIdTo: BROKER_NO_STOCKS_ACCOUNT_SELECTED,
        accountsFromOptions: [],
        accountsToOptions: [],
        valid: false
    });
}

function validateStep(step, validContracts) {
    return step.merge({
        valid: validateAccountValues(step) && !!validContracts,
        validContracts: !!validContracts
    });
}

function brokerTransferValuesStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_TRANSFER_VALUES_VALIDATE_STEP:
            return validateStep(state, action.payload.validContracts);
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNT_FROM:
            return state.merge({
                accountIdFrom: action.payload.accountId
            });
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNT_TO:
            return state.merge({
                accountIdTo: action.payload.accountId
            });
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNTS_FROM_OPTIONS:
            return state.merge({
                accountsFromOptions: action.payload.accountsOptions
            });
        case actionTypes.BROKER_TRANSFER_VALUES_SET_ACCOUNTS_TO_OPTIONS:
            return state.merge({
                accountsToOptions: action.payload.accountsOptions
            });
        case actionTypes.BROKER_TRANSFER_VALUES_RESET_CONTRACTS_ERRORS:
            return state.merge({
                validContracts: true
            });
        default:
            return state;
    }
}

module.exports = brokerTransferValuesStep1;
