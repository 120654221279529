// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_SIMPLE_NOTE_REQUEST,
    MORTGAGE_REQUEST_SIMPLE_NOTE_SUCCESS,
    MORTGAGE_REQUEST_SIMPLE_NOTE_FAILURE,
    MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_REQUEST,
    MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_SUCCESS,
    MORTGAGE_REQUEST_SIMPLE_NOTE_ADD_FILE,
    MORTGAGE_REQUEST_SIMPLE_NOTE_RESET_STATE,
    MORTGAGE_REQUEST_SIMPLE_NOTE_UPLOAD_FILE_ERROR
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    files: {},
    isUploading: false,
    uploaded: false,
    errorUploading: {},
    isRequesting: false,
    requested: false,
    errorRequest: '',
});

const simpleNote = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_SIMPLE_NOTE_RESET_STATE:
            return initialState.merge();

        case MORTGAGE_REQUEST_SIMPLE_NOTE_ADD_FILE:
            return state.setIn(['files', action.data.estateId], action.data.file);

        case MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_REQUEST:
            return state.set('isUploading', true);

        case MORTGAGE_REQUEST_SIMPLE_NOTE_SAVE_SUCCESS:
            return state.merge({
                isUploading: false,
                uploaded: true
            });

        case MORTGAGE_REQUEST_SIMPLE_NOTE_UPLOAD_FILE_ERROR:
            return state.setIn(['errorUploading', action.data.estateId], action.data.error);

        case MORTGAGE_REQUEST_SIMPLE_NOTE_REQUEST:
            return state.set('isRequesting', true);

        case MORTGAGE_REQUEST_SIMPLE_NOTE_SUCCESS:
            return state.merge({
                    requested: true,
                    isRequesting: false
                });

        case MORTGAGE_REQUEST_SIMPLE_NOTE_FAILURE:
            return state.merge({
                errorRequest: action.data.error,
                isRequesting: false
            });

        default:
            return state;
    }
};

module.exports = simpleNote;
