// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    arrayintervinientesimul: {
        intervinientesimul: []
    },
    edadmenornm: 0,
    error: '',
    req: false
});

const mortgageExtraData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_SUCCESS:
            const data = action.data.data;
            return state.merge({
                arrayintervinientesimul: {
                    intervinientesimul: get( data, 'expdatosadicional.arrayintervinientesimul.intervinientesimul', [] )
                },
                edadmenornm: get( data, 'expdatosadicional.edadmenornm', 0 ),
                error: '',
                isFetching: false,
                req: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state;
    }
};

module.exports = mortgageExtraData;
