module.exports = {
    id_component: 'grid-form',
    label: 'contractsView-openyoungDataStep2TabForWhom',
    id: 'tab-form-titular-data',
    buildCondition: 'juniorAccount_showCheckboxTutor',
    grid: [
        [
            {
                id_component: 'checkbox',
                label: 'contractsView-juniorAccountStep2ValidationCheckBox',
                id: 'checkbox-tutor',
                onChangeFn: 'juniorAccount_onChangeCheckBox',
                target: 'checkbox-tutor',
                type: 'single',
                value: false,
                errorMessage: 'contractsView-serviceContractErrorMessage',
                required: true,
            },
        ]
    ]
};