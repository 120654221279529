// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    ammounts: {
        livingPlace: null,
        storageRoom: null,
        garage: null
    },
    error: '',
    isFetching: false,
    ammountsRequested: false,
    dataSaved: false
});

const estates = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_REQUEST:
            return state.set('isFetching', true);

        case actionTypes.MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_SUCCESS:
            return state.merge({
                isFetching: false,
                dataSaved: true
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_SAVE_AMMOUNT_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_SAVE_REQUEST_DONE:
            return state.set('dataSaved', true);

        case actionTypes.MORTGAGE_REQUEST_ESTATES_RESET:
            return initialState.merge();

        case actionTypes.MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_REQUEST:
            return state.set('isFetching', true);

        case actionTypes.MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_SUCCESS:
            return state.mergeDeep({
                isFetching: false,
                ammountsRequested: true,
                ammounts: {
                    livingPlace: action.data.data.viviendas,
                    storageRoom: action.data.data.trasteros,
                    garage: action.data.data.garajes,
                }
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_FETCH_AMMOUNT_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        default:
            return state;
    }
};

module.exports = estates;
