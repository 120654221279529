// @vendors
const Immutable = require('immutable');
const MICCODE_CONVERSION = require('constants/miccodes');
const actionTypes = require('constants/actionTypes');

// @helpers
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS,
    BROKER_SEARCH_RESULTS_MAX_CHARS_LABEL
} = require('constants/index');
const sanitizeHelper = require('utilities/sanitizeHelper');
const { getFirstNChars } = require('utilities/stringHelper');


const resultsAdapter = (arr, type, term) => {

    switch (type) {
        case TYPE_STOCKS:
            return arr.map(item => {
                const iso = item.miccode.trim();
                const market = MICCODE_CONVERSION.find(function(market){return market.MIC == iso});
                const isin = item.instrumentId.trim();
                const spcSymbol = item.spcSymbol.trim();
                const instrument = item.instrument.trim();
                let suffix = ` (${spcSymbol.split(":").pop()})`;
                return {
                    value: isin + iso + term,
                    label: sanitizeHelper.sanitizeData(instrument),
                    widgetSearchProductLabel: getFirstNChars(
                        sanitizeHelper.sanitizeData(instrument),
                        BROKER_SEARCH_RESULTS_MAX_CHARS_LABEL
                    ) + suffix,
                    term,
                    id: {
                        isin,
                        iso
                    },
                    type,
                    currencyCode: item.currencyCode,
                    spcSymbol: item.spcSymbol,
                    last: item.last,
                    exchangeId: item.exchangeId,
                    isNational: item.national,
                    instrumentId: isin,
                    market: market
                };
            }).sort(item => item.isNational ? 0 : 1); // nationals first
        case TYPE_WARRANTS:
        case TYPE_ETFS:
            return arr.map(item => {
                const isin = item.instrumentId.trim();
                const iso = item.miccode.trim();
                const market = MICCODE_CONVERSION.find(function(market){return market.MIC == iso});
                const spcSymbol = item.spcSymbol.trim();
                const instrument = item.instrument.trim();
                let suffix = '';

                switch (type) {
                    case TYPE_ETFS:
                        suffix = ` ${spcSymbol}`;
                        break;
                }

                return {
                    value: isin + iso + term,
                    label: sanitizeHelper.sanitizeData(instrument),
                    widgetSearchProductLabel: getFirstNChars(
                        sanitizeHelper.sanitizeData(instrument),
                        BROKER_SEARCH_RESULTS_MAX_CHARS_LABEL
                    ) + suffix,
                    term,
                    id: {
                        isin,
                        iso
                    },
                    type,
                    currencyCode: item.currencyCode,
                    spcSymbol: item.spcSymbol,
                    last: item.last,
                    exchangeId: item.exchangeId,
                    isNational: item.national,
                    instrumentId: isin,
                    market: market
                };
            }).sort(item => item.isNational ? 0 : 1); // nationals first

        case TYPE_PLANS:
            return arr.map(item => {
                const value = item.SecId ? item.SecId.trim() + term : '';
                const name = item.Name.trim();
                const id = item.SecId;
                const instrumentId = item.DGSCode;
                const charsAmount = BROKER_SEARCH_RESULTS_MAX_CHARS_LABEL + 8;
                let suffix = '';

                return {
                    value,
                    label: sanitizeHelper.sanitizeData(name),
                    widgetSearchProductLabel: getFirstNChars(
                        sanitizeHelper.sanitizeData(name),
                        charsAmount
                    ) + suffix,
                    term,
                    id,
                    type,
                    instrumentId
                };
            });
        case TYPE_FUNDS:
            return arr.map(item => {
                const value = item.SecId ? item.SecId.trim() + term : '';
                const name = item.Name.trim();
                const id = item.SecId;
                const instrumentId = item.ISIN;
                const charsAmount = BROKER_SEARCH_RESULTS_MAX_CHARS_LABEL + 8;
                let suffix = '';

                return {
                    value: value,
                    label: sanitizeHelper.sanitizeData(name),
                    widgetSearchProductLabel: getFirstNChars(
                        sanitizeHelper.sanitizeData(name),
                        charsAmount
                    ) + suffix,
                    term,
                    id: id,
                    type,
                    instrumentId
                };
            });

        default:
            //error this place is not recognized
            break;
    }
};

const resultsGrouping = (arr, term) => {
    const all = {}; // below is in the order should go, Object.keys takes this order
    all[TYPE_STOCKS] = [];
    all[TYPE_FUNDS] = [];
    all[TYPE_PLANS] = [];
    all[TYPE_ETFS] = [];
    all[TYPE_WARRANTS] = [];

    arr.forEach(item => all[item.type].push(item));

    const multiArrays = Object.keys(all).map(key => {
        return all[key].length > 0 ? [
            {disabled: true, value: key+term, label: key, term},
            ...all[key]
        ] : [];
    });

    const flattenedArrays = multiArrays.reduce((prev, curr) => [...prev, ...curr], []);
    
    return flattenedArrays;
}

const addShowMoreLink = (resultList, term) => {
    let finalArray = [];
    if (resultList.length !== 0) {
        finalArray = [
            ...resultList,
            {value: 'show-more-link' + term, label: 'show-more-link', showMoreLink: true},
            {value: 'hiden-option' , label: '', hide: true}
        ];
    }
    return finalArray;
}

const resultsByTypePlan = (planTypeObject, resultList, amountOfResults) => {
    if (planTypeObject && resultList.length < amountOfResults) {
        return [...resultList, planTypeObject];
    } else {
        return resultList;
    }
}

const searchForProductLimited = (term, data, state) => {

    const amountOfResults = 15;
    const stocks = resultsAdapter(data.stocks, TYPE_STOCKS, term);
    const etfs = resultsAdapter(data.etfs, TYPE_ETFS, term);
    const warrants = resultsAdapter(data.warrants, TYPE_WARRANTS, term);
    const pensions = resultsAdapter(data.pensions, TYPE_PLANS, term);
    const funds = resultsAdapter(data.funds, TYPE_FUNDS, term);
    const plansToReturn = state.get('plansToReturn');

    let result = [];
    let plansToReturnSize = plansToReturn.size;

    for (let i=0; i < 5; i++) {
        if (plansToReturnSize) {
            for (let j=0; j < plansToReturnSize; j++) {
                let plan = plansToReturn.get(j);

                switch (plan) {
                    case TYPE_STOCKS:
                        result = resultsByTypePlan(stocks[i], result, amountOfResults);
                        break;
                    case TYPE_ETFS:
                        result = resultsByTypePlan(etfs[i], result, amountOfResults);
                        break;
                    case TYPE_PLANS:
                        result = resultsByTypePlan(pensions[i], result, amountOfResults);
                        break;
                    case TYPE_FUNDS:
                        result = resultsByTypePlan(funds[i], result, amountOfResults);
                        break;
                    case TYPE_WARRANTS:
                        result = resultsByTypePlan(warrants[i], result, amountOfResults);
                        break;
                    default:
                        break;
                }
            }
        } else {
            result = resultsByTypePlan(stocks[i], result, amountOfResults);
            result = resultsByTypePlan(etfs[i], result, amountOfResults);
            result = resultsByTypePlan(pensions[i], result, amountOfResults);
            result = resultsByTypePlan(funds[i], result, amountOfResults);
            result = resultsByTypePlan(warrants[i], result, amountOfResults);
        }
    }
    if(plansToReturnSize > 1){
        result = resultsGrouping(result, term);
    }

    return addShowMoreLink(result, term);
}

const searchForProduct = (term, data) => {

    const stocks = resultsAdapter(data.stocks, TYPE_STOCKS, term);
    const etfs = resultsAdapter(data.etfs, TYPE_ETFS, term);
    const warrants = resultsAdapter(data.warrants, TYPE_WARRANTS, term);
    const pensions = resultsAdapter(data.pensions, TYPE_PLANS, term);
    const funds = resultsAdapter(data.funds, TYPE_FUNDS, term);

    let result = [];

    result = [...result, ...stocks];
    result = [...result, ...etfs];
    result = [...result, ...warrants];
    result = [...result, ...pensions];
    result = [...result, ...funds];

    return resultsGrouping(result, term);
}

const searchProductsState = (term = '', results = [], isFetching = false, pageSize = 5, plansToReturn = []) => {
    return Immutable.fromJS({
        isFetching,
        results,
        term,
        pageSize,
        plansToReturn
    });
};

const setPlansList = (state, plansToReturn) => {
    return state.merge({
        plansToReturn: plansToReturn
    });
};

const initialState = searchProductsState();

const applyForReducer = (state = initialState, action) => {
    let newState;
    let newResults;
    switch (action.type) {
        case actionTypes.BROKER_SEARCH_PRODUCT_FETCHING:
            newState = searchProductsState(action.payload.term, [], true);
            return newState;
        case actionTypes.BROKER_SEARCH_PRODUCT_FAILURE:
            //> TODO handle error console.log(action, 'failure');
            newState = state.set('isFetching', false);
            return newState;
        case actionTypes.BROKER_SEARCH_PRODUCT_SUCCESS:
            newResults = searchForProduct(action.payload.term, action.payload.data);
            newState = searchProductsState(action.payload.term, newResults, false);
            return newState;
        case actionTypes.BROKER_LIMITED_SEARCH_PRODUCT_SUCCESS:
            newResults = searchForProductLimited(action.payload.term, action.payload.data, state);
            newState = searchProductsState(action.payload.term, newResults, false);
            return newState;
        case actionTypes.BROKER_SEARCH_PRODUCT_RESET:
            newState = searchProductsState();
            return newState;
        case actionTypes.BROKER_SEARCH_SET_PLANS_LIST:
            newState = setPlansList(state, action.payload);
            return newState;
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH:
            const results = state.get('results');
            const selectedValue = action.payload.selectedValue;
            const selectedLabel = action.payload.selectedLabel;
            const isValueSelected =  results.filter(object =>
                object.get('value') === selectedValue
            ).size === 0;

            if (isValueSelected && !!selectedValue && results.getIn([results.size-1, 'hide'])) {
                return state.mergeIn(['results', results.size-1], {
                    value: selectedValue,
                    label: selectedLabel
                });
            }
            return state;
        default:
            return state
    }
}

module.exports = applyForReducer;
