// @vendor
const { Map } = require("immutable");

// @helpers
const { addSpacesBetweenWords } = require("./stringHelper");

// @utilities
const { formatInternationalPhone } = require("utilities/phoneHelper");

// @ constants
const {
    EMAIL_REGEX,
    PHONE_NUMBER_MIN_LENGTH,
    PT_PHONE_NUMBER_MIN_LENGTH,
    DE_PHONE_NUMBER_MIN_LENGTH,
    NL_PHONE_NUMBER_MIN_LENGTH
} = require("constants/index");
const PROVINCES = require("constants/provinces");
const {
    DEFAULT_ORIGIN_COUNTRY,
    COUNTRY_PHONE_CODES,
    COUNTRIES_CODES: { ES, PT },
    getTranslatedCountry
} = require("constants/countries");
const { getTranslatedRoad } = require("constants/typeOfRoads");

// @commons
const POSTAL_CODE_REGEX = require("commonsConstants/postalCodes");

const PersonalDataHelper = {
    createAddressLine(addressArray) {
        return addressArray
            .map(part => {
                return !!part ? `${part.trim()}` : "";
            })
            .join(", ");
    },

    getDeviceList(immFields) {
        return immFields.get("listaDispositivos").get("dispositivo");
    },

    getDeviceFirstByType(immFields, deviceType) {
        const immDeviceList = PersonalDataHelper.getDeviceList(immFields);
        if (immDeviceList.size === 0) return "";
        return immDeviceList
            .find(device => device.get("tipoDispositivo").trim() === deviceType)
            .get("detalleContacto");
    },

    getPhoneNumber(immFields) {
        const contactPhone = immFields.get("telefonoContacto");
        if (contactPhone) {
            const countryCode = contactPhone.get("codigoPais");
            const phoneNumber = contactPhone.get("numero");
            return formatInternationalPhone(countryCode, phoneNumber, false);
        }
        return "";
    },

    getPhoneCodeFromOriginCountry(originCountry) {
        return COUNTRY_PHONE_CODES[originCountry] || COUNTRY_PHONE_CODES[ES];
    },

    getEmail(immFields) {
        return PersonalDataHelper.getDeviceFirstByType(immFields, "I").trim();
    },

    getAddress(immFields) {
        const address = {};
        address.typeOfRoads = immFields.get("tipoDeVia")
            ? immFields.get("tipoDeVia").trim()
            : "";
        address.streetName = immFields.get("nombreDeVia")
            ? immFields.get("nombreDeVia").trim()
            : "";
        address.numberStreet = immFields.get("numeroDeVia")
            ? immFields.get("numeroDeVia").trim()
            : "";
        address.restOfAddress = immFields.get("restoDomicilio")
            ? immFields.get("restoDomicilio").trim()
            : "";
        address.locality = immFields.get("nombrePlaza")
            ? immFields.get("nombrePlaza").trim()
            : "";
        address.province = immFields
            .get("plaza")
            .get("plaza")
            .get("provincia")
            ? immFields
                .get("plaza")
                .get("plaza")
                .get("provincia")
                .trim()
            : "";
        address.addressCountry = immFields.get("paisDomicilio")
            ? immFields.get("paisDomicilio").trim()
            : "";
        address.postalCode = immFields.get("codigoPostal")
            ? immFields.get("codigoPostal").trim()
            : "";
        address.poblacionNacimiento = immFields.get("poblacionNacimiento");
        return address;
    },

    getTaxData(immFields) {
        const birthCountry = immFields.get("paisNacimiento").trim();
        const birthProvince = immFields
            .get("provincia")
            .get("provincia")
            .trim();
        const birthSquare = immFields.get("plazaDeNacimiento").trim();
        return {
            birthCountry: birthCountry,
            birthProvince: birthProvince,
            birthSquare: birthSquare
        };
    },

    getListCountriesWithAdditionalTaxing(immFields) {
        const listCountriesAdditionalTaxing = immFields.get(
            "listPaisesTributacionAdic"
        );
        return listCountriesAdditionalTaxing;
    },

    getFormattedAddress(immProfile) {
        const {
            typeOfRoads,
            streetName,
            numberStreet,
            restOfAddress,
            locality,
            province,
            postalCode,
            addressCountry
        } = PersonalDataHelper.getAddress(immProfile);

        let finalTypeOfRoads = typeOfRoads
            ? getTranslatedRoad(typeOfRoads)
            : "";
        let finalStreetName = streetName || "";
        let finalNumberStreet = numberStreet || "";
        let finalRestOfAddress = restOfAddress || "";
        let finalLocality = locality || "";
        let finalProvinces = PROVINCES[province] || "";
        let finalAddressCountry = getTranslatedCountry(addressCountry) || "";
        let finalPostalCode = !!postalCode ? `(${postalCode})` : "";

        let firstSection = addSpacesBetweenWords(
            finalTypeOfRoads,
            finalStreetName,
            finalNumberStreet
        );
        let secondSection = addSpacesBetweenWords(
            finalRestOfAddress,
            finalLocality
        );
        let fourthSection = addSpacesBetweenWords(
            finalAddressCountry,
            finalPostalCode
        );

        let firstSeparator =
            !!finalRestOfAddress || !!finalLocality ? ", " : "";
        let secondSeparator = !!finalProvinces ? ", " : "";
        let thirdSeparator =
            !!finalAddressCountry || !!finalPostalCode ? ", " : "";

        return `${firstSection}${firstSeparator}${secondSection}${secondSeparator}${finalProvinces}${thirdSeparator}${fourthSection}`;
    },

    getFullName(immProfile, lastNamesFirst = true) {
        const firstName = immProfile.get("nombreCliente", "").trim();
        const lastNames = `${immProfile
            .getIn(["apellidoUno", "apellido"], "")
            .trim()} ${immProfile
                .getIn(["apellidoDos", "apellido"], "")
                .trim()}`;

        if (lastNamesFirst) {
            return `${lastNames}, ${firstName}`;
        }

        return `${firstName} ${lastNames}`;
    },

    getHireFormattedShippingAddress(immHireContactData, originCountry) {
        if (!immHireContactData) return "";

        if (originCountry === DEFAULT_ORIGIN_COUNTRY) {
            return PersonalDataHelper.getHireFormattedAddressES(
                immHireContactData
            );
        }
        return PersonalDataHelper.getHireFormattedAddressEXT(
            immHireContactData
        );
    },

    getHireFormattedAddress(immHireContactData) {
        const typeOfRoad =
            getTranslatedRoad(
                immHireContactData.getIn(["roadType", "value"])
            ) || "";
        const streetName =
            immHireContactData.getIn(["roadName", "value"]) || "";
        const numberStreet =
            immHireContactData.getIn(["number", "value"]) || "";
        const restOfAddress =
            immHireContactData.getIn(["others", "value"]) || "";
        const locality =
            immHireContactData.getIn(["population", "value"]) || "";
        const province =
            PROVINCES[immHireContactData.getIn(["province", "value"])] || "";
        const addressCountry =
            getTranslatedCountry(
                immHireContactData.getIn(["country", "value"])
            ) || "";
        const postalCode = immHireContactData.getIn(["postalCode", "value"])
            ? `(${immHireContactData.getIn(["postalCode", "value"])})`
            : "";

        const firstSection = addSpacesBetweenWords(
            typeOfRoad,
            streetName,
            numberStreet
        );
        const secondSection = addSpacesBetweenWords(restOfAddress, locality);
        const fourthSection = addSpacesBetweenWords(addressCountry, postalCode);

        const firstSeparator = !!restOfAddress || !!locality ? ", " : "";
        const secondSeparator = !!province ? ", " : "";
        const thirdSeparator = !!addressCountry || !!postalCode ? ", " : "";

        return `${firstSection}${firstSeparator}${secondSection}${secondSeparator}${province}${thirdSeparator}${fourthSection}`;
    },

    getFormattedShippingAddress(profileShippingAddress) {
        const typeOfRoad =
            getTranslatedRoad(profileShippingAddress.roadType) || "";
        const streetName = profileShippingAddress.roadName || "";
        const numberStreet = profileShippingAddress.number || "";
        const restOfAddress = profileShippingAddress.others || "";
        const locality = profileShippingAddress.population || "";
        const province = PROVINCES[profileShippingAddress.province] || "";
        const addressCountry =
            getTranslatedCountry(profileShippingAddress.country) || "";
        const postalCode = profileShippingAddress.postalCode
            ? `(${profileShippingAddress.postalCode})`
            : "";

        const firstSection = addSpacesBetweenWords(
            typeOfRoad,
            streetName,
            numberStreet
        );
        const secondSection = addSpacesBetweenWords(restOfAddress, locality);
        const fourthSection = addSpacesBetweenWords(addressCountry, postalCode);

        const firstSeparator = !!restOfAddress || !!locality ? ", " : "";
        const secondSeparator = !!province ? ", " : "";
        const thirdSeparator = !!addressCountry || !!postalCode ? ", " : "";

        return `${firstSection}${firstSeparator}${secondSection}${secondSeparator}${province}${thirdSeparator}${fourthSection}`;
    },

    getFormattedShippingAddressExt(shippingAddress) {
        const addressLine1 = shippingAddress.addressLine1 || "";
        const addressLine2 = shippingAddress.addressLine2 || "";
        const addressCity = shippingAddress.addressCity || "";
        const country = getTranslatedCountry(shippingAddress.country) || "";

        const firstSeparator = !!addressLine2 ? ", " : "";
        const secondSeparator = !!addressCity ? ", " : "";
        const thirdSeparator = !!country ? ", " : "";

        return `${addressLine1}${firstSeparator}${addressLine2}${secondSeparator}${addressCity}${thirdSeparator}${country}`;
    },

    getHireFormattedAddressES(immHireContactData) {
        return PersonalDataHelper.getHireFormattedAddress(
            immHireContactData.get("shippingAddress")
        );
    },

    getHireFormattedAddressEXT(immHireContactData) {
        const address =
            immHireContactData.getIn(
                ["shippingAddressList", "domicilios", 0, "domicilioExt"],
                new Map()
            ) || new Map();
        return !address.isEmpty()
            ? PersonalDataHelper.createAddressLine([
                address.get("linea1Direccion"),
                address.get("linea2Direccion"),
                address.get("plaza"),
                address.get("pais")
            ])
            : null;
    },

    isValidAddress(immAddress, originCountry = ES) {
        const isValid =
            immAddress.get("locality") !== "" &&
            immAddress.get("numberStreet") !== "" &&
            immAddress.get("streetName") !== "" &&
            immAddress.get("postalCode") !== "";

        if (originCountry == ES) {
            return (
                isValid &&
                immAddress.get("province") !== "" &&
                immAddress.get("typeOfRoads") !== "" &&
                immAddress
                    .get("postalCode")
                    .startsWith(immAddress.get("province")) &&
                POSTAL_CODE_REGEX[originCountry].test(
                    immAddress.get("postalCode")
                )
            );
        } else {
            return (
                isValid &&
                POSTAL_CODE_REGEX[originCountry].test(
                    immAddress.get("postalCode")
                )
            );
        }
    },

    isValidPhoneNumber(phoneNumber, originCountry = ES) {
        let minLength = phoneNumber.replace(/ /g, "").length;

        switch (originCountry) {
            case "ES":
                return minLength === PHONE_NUMBER_MIN_LENGTH;
            case "PT":
                return minLength >= PT_PHONE_NUMBER_MIN_LENGTH;
            case "DE":
                return minLength >= DE_PHONE_NUMBER_MIN_LENGTH;
            case "NL":
                return minLength >= NL_PHONE_NUMBER_MIN_LENGTH;
        }

        return true;
    },

    isValidEmail(email) {
        return EMAIL_REGEX.test(email);
    },

    getDocument(immFields) {
        return {
            documentType: immFields
                .getIn(["documentoIdentificacion", "tipodocumpersonacorp"])
                .trim(),
            documentNumber: immFields
                .getIn(["documentoIdentificacion", "codigodocumpersonacorp"])
                .trim()
        };
    },

    //from 34664555256 returns +34 6** **5 256
    getMaskedPhone(completePhone, originCountry = ES) {
        const cleanContent = completePhone.replace(/\-/g, "");
        let maskedPhone = `+${cleanContent.substr(0, 2)} ${cleanContent.substr(
            2,
            1
        )}** **${cleanContent.substr(7, 1)} ${cleanContent.substr(8)}`;
        switch (originCountry) {
            case PT:
                maskedPhone = `+${cleanContent.substr(
                    0,
                    3
                )} ${cleanContent.substr(3, 1)}** *** ${cleanContent.substr(
                    9
                )}`;
                break;
        }

        return maskedPhone;
    },

    getFormattedPhone(completePhone, originCountry = ES) {
        const cleanContent = completePhone.replace(/\-/g, "");
        let formattedPhone = `+${cleanContent.substr(
            0,
            2
        )} ${cleanContent.substr(2, 3)} ${cleanContent.substr(
            5,
            3
        )} ${cleanContent.substr(8)}`;
        switch (originCountry) {
            case PT:
                formattedPhone = `+${cleanContent.substr(
                    0,
                    3
                )} ${cleanContent.substr(3, 3)} ${cleanContent.substr(
                    6,
                    3
                )} ${cleanContent.substr(9)}`;
                break;
        }
        return formattedPhone;
    },

    formattedPhone(code, number) {
        return !code || !number
            ? ""
            : `+${code} ${number.substr(0, 3)} ${number.substr(
                3,
                3
            )} ${number.substr(6, 3)}`;
    },

    formattedPhoneOnly(number) {
        let num = number || "";
        num = num.replace(/ /g, "");
        return num
            ? `${num.substr(0, 3)} ${num.substr(3, 3)} ${num.substr(6, 3)}`
            : "";
    },

    getPhoneOnly(immFields) {
        const number = immFields.getIn(["telefonoContacto", "numero"]);
        return PersonalDataHelper.formattedPhoneOnly(number);
    },

    getPhoneCodeOnly(immFields) {
        const code = immFields.getIn(["telefonoContacto", "codigoPais"]);
        return code ? `+${code}` : "";
    },

    isValidRoadNameInput(roadName) {
        return !/\(|\)+/g.test(roadName);
    }
};

module.exports = PersonalDataHelper;
