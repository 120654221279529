// @vendors
const Immutable = require('immutable');

// @reducers
const brokerCashChargeStep1Reducer = require('./brokerCashCharge/brokerCashChargeStep1');
const brokerCashChargeStep2Reducer = require('./brokerCashCharge/brokerCashChargeStep2');

// @constants
const { FIRST_STEP } = require('constants/index');

const {
    BROKER_CASH_CHARGE_START,
    BROKER_CASH_CHARGE_CHANGE_TITLES_AMOUNT,
    BROKER_CASH_CHARGE_CHANGE_ACCEPT_INSTRUCTIONS,
    BROKER_CASH_CHARGE_VALIDATE_STEP,
    BROKER_CASH_CHARGE_SET_VISIBLE_STEP,
    BROKER_CASH_CHARGE_FINISH
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    steps: [
        brokerCashChargeStep1Reducer(undefined, {}),
        brokerCashChargeStep2Reducer(undefined, {})
    ],
    operation: {
        descriptionValue: '',
        stockExchange: 'Bolsa: Madrid Prue', // @hardcoded
        endDate: '',
    },
    contract: {
        product: '',
        number: ''
    },
    owner: '',
    walletAccountType: '',
    orderNumber: '',
    visibleStep: FIRST_STEP + 1,
    finished: false
});

const STEP_REDUCERS = [
    brokerCashChargeStep1Reducer,
    brokerCashChargeStep2Reducer
];

function brokerCashChargeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_CASH_CHARGE_START:
            return INITIAL_STATE.mergeDeep({
                digitodecontrol: action.payload.cuenta.digitodecontrol,
                contractProductNumber: action.payload.cuenta.fullNumber,
                operation: action.payload.operation,
                contract: action.payload.contract,
                owner: action.payload.owner,
                walletAccountType: action.payload.walletAccountType
            });
        case BROKER_CASH_CHARGE_CHANGE_TITLES_AMOUNT:
        case BROKER_CASH_CHARGE_CHANGE_ACCEPT_INSTRUCTIONS:
        case BROKER_CASH_CHARGE_VALIDATE_STEP:
            const immFirstStep = state.getIn(['steps', FIRST_STEP]);
            return state.setIn(['steps', FIRST_STEP], brokerCashChargeStep1Reducer(immFirstStep, action));
        case BROKER_CASH_CHARGE_SET_VISIBLE_STEP:
            const step = action.payload.step;
            const immStep = state.getIn(['steps', step - 1]);
            const stepReducer = STEP_REDUCERS[step - 1];
            return state.set('visibleStep', step).setIn(['steps', step - 1], stepReducer(immStep, action));
        case BROKER_CASH_CHARGE_FINISH:
            return state.set('finished', true);
        default:
            return state;
    }
}

module.exports = brokerCashChargeReducer;
