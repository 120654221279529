// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    date: null,
    onboardDocumentOkApi:{},
    isFetchingDocument: false,
    isFetchingDate: false,
    isFetchingDocOkApi:false
});


function onboardDocument(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_ONBOARD_DOCUMENT_DATE_ERROR:
            return state.merge({
                'date': null,
                'isFetchingDate': false
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_OK_API_ERROR:
            return state.merge({
                'onboardDocumentOkApi': {},
                'isFetchingDocOkApi': false
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_DATE_SUCCESS:
            return state.merge({
                'date': action.payload.date,
                'fileDownloadError': null,
                'isFetchingDate': false
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_OK_API_SUCCESS:
            return state.merge({
                'onboardDocumentOkApi': action.payload.onboardDocumentOkApi,
                'isFetchingDocOkApi': false
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_PDF_ERROR:
            return state.merge({
                'date': null,
                'fileDownloadError': action.payload.error,
                'isFetchingDocument': false
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_PDF_SUCCESS:
            return state.merge({
                'isFetchingDocument': false
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_PDF_IN_PROGRESS:
            return state.merge({
                'isFetchingDocument': true
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_DATE_IN_PROGRESS:
            return state.merge({
                'isFetchingDate': true
            })
        case actionTypes.GET_ONBOARD_DOCUMENT_OK_API_IN_PROGRESS:
            return state.merge({
                'isFetchingDocOkApi': true
            })
        default:
            return state;
    }
}


module.exports = onboardDocument;
