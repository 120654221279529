// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    importeHipoteca: 0,
    importeVivienda: 0,
    plazo: 0,
    tipoProducto: '',
    cuotaMensual: 0
});

const panelTitle = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_FETCH_PANEL_TITLE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_PANEL_TITLE_SUCCESS:
            return state.merge({
                importeHipoteca: action.data.data.importeHipoteca || 0,
                importeVivienda: action.data.data.importeVivienda || 0,
                cuotaMensual: action.data.data.cuotaMensual || 0,
                plazo: action.data.data.plazo || 0,
                tipoProducto: action.data.data.tipoProducto || '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_PANEL_TITLE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = panelTitle;
