const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const {
    EURO_SYMBOL,
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED
} = require('constants/index');

module.exports = {
    title: 'contractsView-depositEighteenMonthsStep2Title',
    order: 2,
    footer_text: '',
    disableNext: 'depositEighteenMonths_nextButtonDisabled',
    position: 'middle',
    components:
        [
            {
                id_component: 'dropdown-accounts',
                label: 'contractsView-depositEighteenMonthsStep2Account',
                options: [],
                type: 'single',
                target: 'cta_seleccionada',
                targetIntervener: 'intervinientes_list',
                intervenersSpecial: true,
                order: 1,
                isIntervenerAvailable: true,
                renderInterveners: 'depositEighteenMonths_shouldBuild',
                isMaxAmountLabelAvailable: false,
                intervenersHeader: 'contractsView-depositEighteenMonthsIntervenersHeader',
                maxAmountValue: 0,
                required: true,
                errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_EIGHTEEN,
                accountChangeExtraFn: 'refreshDepositEighteenHireInformation',
                intervenersHierarchicalTypeSelection: true,
                intervenerOptions: [
                    INTERVENER_LABEL_HOLDER,
                    INTERVENER_LABEL_AUTHORIZED,
                    INTERVENER_LABEL_EMPOWERED
                ],
                showIntervenerControls: true,
                restoreDefaultIntervenersEnabled: true
            },
            {
                id_component: 'slider',
                label: 'contractsView-depositEighteenMonthsStep2Amount',
                min_value: 0,// is populated by service
                max_value: 0,// is populated by service
                default_value: 0,// is populated by service
                value: null,// is populated by service
                type: 'single',
                format_type: EURO_SYMBOL,
                inputType: 'float',
                onChangeFn: 'depositEighteenMonths_onChangeAmount',
                is_money: true,
                title: 'contractsView-amountTitle',
                target: 'monto_traspasar',
                source: 'depositEighteenMonths_loadSliderAmounts',
                buildExtraInfo: 'depositEighteenMonths_buildSliderExtraInfo',
                tooltipNoPadding: true,
                buildCondition: 'depositEighteenMonths_shouldBuild'
            },
            {
                id_component: 'interest-band'
            },
            {
                id_component: 'checkbox',
                id: 'renewDeposit',
                target: 'renewDeposit',
                label: 'contractsView-depositEighteenMonthsStep2Renew',
                type: 'single',
                onChangeFn: 'depositEighteenMonths_onChangeCheckbox',
                buildCondition: 'depositEighteenMonths_shouldBuild'
            },
            {
                id_component: 'validation-modal',
                id: 'validationModal',
                title: 'depositEighteenMonths_FinalModalTitle',
                titleType: 'calculated',
                content: 'depositEighteenMonths_FinalModalChildren',
                allowContinue: true,
                open: 'depositEighteenMonths_showFinalModal',
                showCloseButton: false,
                leaveButtonText: 'contractsView-depositEighteenMonthsBackButtonModal'
            },
            {
                id_component: 'validation-modal',
                id: 'accountModal',
                title: 'contractsView-depositEighteenMonthsStep2InitialModalTitle',
                content: 'depositEighteenMonths_InitialModalChildren',
                allowContinue: false,
                showCloseButton: false,
                leaveButtonText: 'contractsView-depositEighteenMonthsBackButtonModal'
            },
            {
                id_component: 'disclaimer-personal-info',
                messageKey: 'depositEighteenMonthsMsg',
                linkTextKey: 'depositEighteenMonthsLinkText',
                linkUrlKey: 'depositEighteenMonthsLinkUrl'
            }
        ]
};
