const { contractsValidationsErrorMessages } = require('constants/contractsValidations');

module.exports = {
    title: 'contractsView-juniorAccountStep3Title',
    order: 3,
    footer_text: '',
    position: 'middle',
    components: [
        {
            id_component: 'dropdown-accounts',
            label: 'contractsView-juniorAccountDropdownLabel',
            options: [],
            type: 'single',
            target: 'cta_seleccionada',
            juniorInterveners: true,
            order: 1,
            onlyHolder: true,
            isIntervenerAvailable: true,
            intervenersSpecial: true,
            restoreDefaultIntervenersEnabled: true,
            isMaxAmountLabelAvailable: false,
            maxAmountValue: 0,
            required: true,
            errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_JUNIOR_ACCOUNT
        }
    ]
}
