const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        getMoneyRequestDetailFetch: false,
        moneyRequestDetail: {},
        getMoneyRequestDetailError: undefined,
        isRejecting: false,
        submitSuccessful: false,
        isFetching: false
    });
}
function openYoungCardMoneyRequest(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_REQUEST:
            return state.merge({
                getMoneyRequestDetailFetch: true,
                getMoneyRequestDetailError: undefined,
                moneyRequestDetail:{},
                isRejecting: false,
                submitSuccessful: false,
                isFetching: true
            });
        case actionTypes.OPENYOUNG_CARD_GET_MONEY_REQUEST_CONFIRMATION:
            return state.merge({
                getMoneyRequestDetailFetch: true,
                getMoneyRequestDetailError: undefined,
                moneyRequestDetail:{},
                isRejecting: false,
                submitSuccessful: false,
                isFetching: true
            });
        case actionTypes.OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_SUCCESS:
            return state.mergeDeep({
                getMoneyRequestDetailFetch: false,
                getMoneyRequestDetailError: undefined,
                moneyRequestDetail: action.payload.moneyRequestDetail,
                isRejecting: false,
                submitSuccessful: false,
                isFetching: false
            });
        case actionTypes.OPENYOUNG_CARD_GET_MONEY_REQUEST_DETAIL_ERROR:
            return state.merge({
                moneyRequestDetail:{},
                getMoneyRequestDetailFetch: false,
                getMoneyRequestDetailError: action.payload.error || true,
                isRejecting: false,
                submitSuccessful: false,
                isFetching: false
            });
        case actionTypes.OPENYOUNG_CARD_REJECT_REQUEST:
            return state.merge({
                isRejecting: true,
                submitSuccessful: false
            });
        case actionTypes.OPENYOUNG_CARD_GET_MONEY_REQUEST_CONFIRMATION_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isFetching: false
            })
        default:
            return state
    }
}
module.exports = openYoungCardMoneyRequest;
