// @vendor
const get = require('lodash/object/get');

// @utils
const { unwrapActionCode } = require('utilities/contactCenter/wrapUp/utilities');

// @constants
const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_NONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_DONE,
} = require('utilities/contactCenter/wrapUp/constants');

const {
    FETCH_PROFILE_FAILURE,
    FETCH_PROFILE_SUCCESS,
    OPEN_YOUNG_GET_PROFILE_DETAILS_FAILURE,
    OPEN_YOUNG_GET_PROFILE_DETAILS_SUCCESS,
    OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE,
    OPEN_YOUNG_UPDATE_PROFILE_DETAILS_SUCCESS,
    PROFILE_EDIT_SET_EDIT_MODE,
    PROFILE_UPDATE_CONSENTS_STATUS_FAILURE,
    PROFILE_UPDATE_CONSENTS_STATUS_SUCCESS,
    PROFILE_SHIPPING_ADDRESS_ADD_FAILURE,
    PROFILE_SHIPPING_ADDRESS_ADD_SUCCESS,
    PROFILE_SHIPPING_ADDRESS_DELETE_FAILURE,
    PROFILE_SHIPPING_ADDRESS_DELETE_SUCCESS,
    PROFILE_SHIPPING_ADDRESS_UPDATE_FAILURE,
    PROFILE_SHIPPING_ADDRESS_UPDATE_SUCCESS,
} = require('constants/actionTypes');


const ACTION_CODE_PROFILE_ADD_SHIPPING_ADDRESS = 5258;
const ACTION_CODE_PROFILE_DELETE_SHIPPING_ADDRESS = 5260;
const ACTION_CODE_PROFILE_EDIT = 5153;
const ACTION_CODE_PROFILE_EDIT_PHONE = 5154;
const ACTION_CODE_PROFILE_EDIT_EMAIL = 5155;
const ACTION_CODE_PROFILE_EDIT_ADDRESS = 5156;
const ACTION_CODE_PROFILE_EDIT_FISCAL_DATA = 5157;
const ACTION_CODE_PROFILE_UPDATE_CONTACT_TIME_PHONE = 5158;
const ACTION_CODE_PROFILE_UPDATE_CONTACT_TIME_EMAIL = 5159;
const ACTION_CODE_PROFILE_EDIT_FIOC_UPDATE = 5160;
const ACTION_CODE_PROFILE_EDIT_FIOC = 5161;
const ACTION_CODE_PROFILE_EDIT_FATCA = 5162;
const ACTION_CODE_TAX_AND_RENTS = 5163;
const ACTION_CODE_CONSENT = 5203;
const ACTION_CODE_PROFILE_UPDATE_MAIN_PHONE_NUMBER = 5206;
const ACTION_CODE_PROFILE_UPDATE_SHIPPING_ADDRESS = 5259;
const ACTION_CODE_OPEN_YOUNG_BENEFICIARY_PERSONAL_DATA = 5239;
const ACTION_CODE_OPEN_YOUNG_BENEFICIARY_CONTACT_DATA_EDIT = 5247;
const ACTION_CODE_OPEN_YOUNG_BENEFICIARY_FISCAL_DATA_EDIT = 5241;

const ACTION_CODE_PROFILE_CONSENT_STATUS = 15001;

module.exports = {
    [ACTION_CODE_PROFILE_EDIT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [ FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE ],
            check: (action, state, traceFlowEvent) => {
                const path = get(state, 'routing.locationBeforeTransitions.pathname');
                const currentActionCode = unwrapActionCode(traceFlowEvent.getCurrentActionCode());

                if (path !== '/myprofile/edit' || !!currentActionCode) {
                    return ({
                        operationState: OPERATION_LOG_NONE,
                        actionCode: ACTION_CODE_PROFILE_EDIT
                    });
                }

                let operationState = OPERATION_LOG_NONE;
                if (action.type === FETCH_PROFILE_SUCCESS) {
                    operationState = OPERATION_LOG_DONE;
                } else if (action.type === FETCH_PROFILE_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    actionCode: ACTION_CODE_PROFILE_EDIT,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_PROFILE_EDIT_PHONE]: {
        entry: {
            action: [ PROFILE_EDIT_SET_EDIT_MODE ],
            check: action => ({
                operationState: action.payload.value === 'PHONE' ? OPERATION_LOG_DONE : OPERATION_LOG_NONE
            })
        }
    },
    [ACTION_CODE_PROFILE_EDIT_EMAIL]: {
        entry: {
            action: [ PROFILE_EDIT_SET_EDIT_MODE ],
            check: action => ({
                operationState: action.payload.value === 'EMAIL' ? OPERATION_LOG_DONE : OPERATION_LOG_NONE
            })
        }
    },
    [ACTION_CODE_PROFILE_EDIT_ADDRESS]: {
        entry: {
            action: [ PROFILE_EDIT_SET_EDIT_MODE ],
            check: action => ({
                operationState: action.payload.value === 'ADDRESS' ? OPERATION_LOG_DONE : OPERATION_LOG_NONE
            })
        }
    },
    [ACTION_CODE_PROFILE_EDIT_FISCAL_DATA]: {
        entry: {
            action: [ PROFILE_EDIT_SET_EDIT_MODE ],
            check: action => ({
                operationState: action.payload.value === 'FISCAL_DATA' ? OPERATION_LOG_DONE : OPERATION_LOG_NONE
            })
        }
    },
    [ACTION_CODE_PROFILE_UPDATE_CONTACT_TIME_PHONE]: {
        entry: {
            path: '/myprofile/edit/update-client-contact-time/PHONE_CONTACT_TIME',
        }
    },
    [ACTION_CODE_PROFILE_UPDATE_CONTACT_TIME_EMAIL]: {
        entry: {
            path: '/myprofile/edit/update-client-contact-time/EMAIL_CONTACT_TIME'
        }
    },
    [ACTION_CODE_PROFILE_EDIT_FIOC_UPDATE]: {
        entry: {
            path: '/myprofile/fioc'
        }
    },
    [ACTION_CODE_CONSENT]: {
        entry: {
            path: '/myprofile/consent'
        }
    },
    [ACTION_CODE_PROFILE_EDIT_FIOC]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/edit/fioc'
        }
    },
    [ACTION_CODE_PROFILE_EDIT_FATCA]: {
        entry: {
            path: '/myprofile/fatca'
        }
    },
    [ACTION_CODE_TAX_AND_RENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
                path: '/myprofile/taxAndRents'
            }
    },
    [ACTION_CODE_PROFILE_UPDATE_MAIN_PHONE_NUMBER]: {
        entry: {
            path: '/myprofile/edit/update-main-phone'
        },
    },
    [ACTION_CODE_OPEN_YOUNG_BENEFICIARY_PERSONAL_DATA]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/openyoung-profile-details/[0-9]+',
            failure: [
                { action: OPEN_YOUNG_GET_PROFILE_DETAILS_FAILURE }
            ],
            success: [
                { action: OPEN_YOUNG_GET_PROFILE_DETAILS_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_OPEN_YOUNG_BENEFICIARY_CONTACT_DATA_EDIT]: {
        entry: {
            path: '/myprofile/openyoung-profile-details/[0-9]+/contact-data-edit'
        },
        finish: {
            action: [ OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE, OPEN_YOUNG_UPDATE_PROFILE_DETAILS_SUCCESS ],
            check: (action) => {
                const { type } = action.payload;
                let operationState = OPERATION_LOG_DONE;

                if( type !== 'contactData' ) {
                    return {
                        actionCode: ACTION_CODE_OPEN_YOUNG_BENEFICIARY_CONTACT_DATA_EDIT,
                        operationState: OPERATION_LOG_NONE
                    }
                } else if ( action.type === OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_OPEN_YOUNG_BENEFICIARY_CONTACT_DATA_EDIT,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_OPEN_YOUNG_BENEFICIARY_FISCAL_DATA_EDIT]: {
        entry: {
            path: '/myprofile/openyoung-profile-details/[0-9]+/fiscal-data-edit'
        },
        finish: {
            action: [ OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE, OPEN_YOUNG_UPDATE_PROFILE_DETAILS_SUCCESS ],
            check: (action) => {
                const { type } = action.payload;
                let operationState = OPERATION_LOG_DONE;


                if( type !== 'fiscalData' ) {
                    return {
                        actionCode: ACTION_CODE_OPEN_YOUNG_BENEFICIARY_FISCAL_DATA_EDIT,
                        operationState: OPERATION_LOG_NONE
                    }
                } else if ( action.type === OPEN_YOUNG_UPDATE_PROFILE_DETAILS_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_OPEN_YOUNG_BENEFICIARY_FISCAL_DATA_EDIT,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_PROFILE_ADD_SHIPPING_ADDRESS]: {
        entry: {
            path: '/myprofile/edit/shipping-address/add'
        },
        finish: {
            action: [PROFILE_SHIPPING_ADDRESS_ADD_FAILURE, PROFILE_SHIPPING_ADDRESS_ADD_SUCCESS],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;

                if (action.type === PROFILE_SHIPPING_ADDRESS_ADD_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorDescription.description');
                }

                return {
                    actionCode: ACTION_CODE_PROFILE_ADD_SHIPPING_ADDRESS,
                    errorMessage,
                    operationState,
                }
            }
        }
    },
    [ACTION_CODE_PROFILE_DELETE_SHIPPING_ADDRESS]: {
        entry: {
            path: '/myprofile/edit/shipping-address/remove'
        },
        finish: {
            action: [PROFILE_SHIPPING_ADDRESS_DELETE_FAILURE, PROFILE_SHIPPING_ADDRESS_DELETE_SUCCESS],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;

                if (action.type === PROFILE_SHIPPING_ADDRESS_DELETE_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorDescription.description');
                }

                return {
                    actionCode: ACTION_CODE_PROFILE_DELETE_SHIPPING_ADDRESS,
                    errorMessage,
                    operationState,
                }
            }
        }
    },
    [ACTION_CODE_PROFILE_UPDATE_SHIPPING_ADDRESS]: {
        entry: {
            path: '/myprofile/edit/shipping-address/update'
        },
        finish: {
            action: [PROFILE_SHIPPING_ADDRESS_UPDATE_FAILURE, PROFILE_SHIPPING_ADDRESS_UPDATE_SUCCESS],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;
                if (action.type === PROFILE_SHIPPING_ADDRESS_UPDATE_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorDescription.description');
                }

                return {
                    actionCode: ACTION_CODE_PROFILE_UPDATE_SHIPPING_ADDRESS,
                    errorMessage,
                    operationState,
                }
            }
        }
    },
    [ACTION_CODE_PROFILE_CONSENT_STATUS]: {
        entry: {
            path: '/myprofile/edit/consent'
        },
        finish: {
            action: [PROFILE_UPDATE_CONSENTS_STATUS_SUCCESS, PROFILE_UPDATE_CONSENTS_STATUS_FAILURE],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;
                if (action.type === PROFILE_UPDATE_CONSENTS_STATUS_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.error');
                }
                
                return {
                    actionCode: ACTION_CODE_PROFILE_CONSENT_STATUS,
                    errorMessage,
                    operationState
                }
            }
        }
    }
}
