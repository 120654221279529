const { toCamelCase } = require('utilities/stringHelper');
const moment = require('moment');
const { getLanguage } = require('core/i18n').i18n;

const setLanguageDates = () => {
    moment.locale(getLanguage());
}

const getName = immProfile => {
    if (immProfile.get('nombreCliente'))
        return toCamelCase(immProfile.get('nombreCliente').trim());

    return '';
};

const getFriendlyName = immProfile => {
    if (immProfile.get('alias'))
        return immProfile.get('alias').trim();

    return getName(immProfile);
};

module.exports = {
    setLanguageDates,
    getFriendlyName,
    getName,
}
