// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map({
        valid: false,
        remarkIsDirty: false,
        remark: ''
    });
}

function validateStep(step) {
    const validRemark = true;

    return step.merge({
        valid: validRemark,
        remarkIsDirty: true
    });
}

function openYoungCardRejectChoreStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.OPENYOUNG_CARD_REJECT_CHORE_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.OPENYOUNG_CARD_REJECT_CHORE_SET_REMARK:
            return state.merge({
                remark: action.payload.remark
            });

        default:
            return state;
    }
}

module.exports = openYoungCardRejectChoreStep1;
