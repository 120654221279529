require('../noNewMoneyDeposits.scss');

// @ vendors
const React = require('react');

//@commons
const { FormattedText } = require('core/i18n').i18n;
const { OKMessage } = require('commonsComponents/OKMessage');

//@helpers
const formValidationHelper = require('../utilities/formValidationHelper');
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
const { formatAmount } = require('utilities/currencyHelper');
const { haveEmail } = require('../utilities/formHelper');

//@constants
const {
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    EURO_SYMBOL
} = require('constants/index');

function selectedAccountBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const ctaSeleccionada = findElementbyId(cardDetails, 'cta_seleccionada');
    const selectedAccountBalance =
        contractWizard.getIn([
            's_accounts',
            'value',
            'byId',
            ctaSeleccionada,
            'balances',
            'availableBalance',
            'amount'
        ]) || findElementbyId(cardDetails, 'balance_account');
    return selectedAccountBalance;
}

function depositBiggerThanBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = findElementbyId(cardDetails, 'monto_traspasar');
    return depositAmount > selectedAccountBalance(contractWizard);
}

const checkIfDepositIsRenewable = contractWizard => {
    const cardDetails = contractWizard.get('card_details');
    const isRenewable = !!findElementbyId(cardDetails, 'renewDeposit');
    return isRenewable;
}

function setSuccessStepPrefix(contractWizard, immProfileFields) {
    let successStepPrefix = {};

    if (depositBiggerThanBalance(contractWizard)) {
        successStepPrefix.flow = 'pending_';
    }
    if (haveEmail(immProfileFields)) {
        successStepPrefix.email = 'successWithEmail_';
    }
    if (checkIfDepositIsRenewable(contractWizard)) {
        successStepPrefix.renew = 'renewable_';
    }
    return successStepPrefix;
}

function modalWithTableLine4Positive() { // TODO CI change all keys
    return (
        <div className="no-new-money-deposits__initial-modal-note-text">
            <FormattedText value="contractsView-contractsView_noNewMoneyDepositInitialModalLine4Positive" />
        </div>
    );
}

function modalWithSummary(contractWizard, requestedAmount) {
    const cardDetails = contractWizard.get('card_details');
    const ctaSeleccionada = findElementbyId(cardDetails, 'cta_seleccionada', 'description');
    const balance = formatAmount(selectedAccountBalance(contractWizard));

    return (
        <div className="no-new-money-deposits__initial-modal-body">
            <div className="no-new-money-deposits__initial-modal-message">
                <OKMessage type="important">
                    <FormattedText value="contractsView-contractsView_noNewMoneyDepositFinalModalRequestedMoreThanBalanceLine1" />
                </OKMessage>
            </div>
            <div>
                {modalWithTableLine4Positive()}
            </div>
            <div className="no-new-money-deposits__initial-modal-summary-container">
                <div className="row">
                    <div className="col-xs-4">
                        <FormattedText value="contractSummary-requestedAmount" /><br />
                        <strong>{`${requestedAmount} ${EURO_SYMBOL}`}</strong>
                    </div>
                    <div className="col-xs-8">
                        <FormattedText value="contractsView-associatedAccount" /><br />
                        <strong>{ctaSeleccionada}</strong>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-offset-4 col-xs-8">
                        <FormattedText value="contractsView-accountBalanceAssociated" /><br />
                        <strong>{`${balance} ${EURO_SYMBOL}`}</strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

function showFinalModal(contractWizard) {
    if (!contractWizard.get('dataIsFetching') && !contractWizard.getIn(['depositHireData', 'error'])) {
        return depositBiggerThanBalance(contractWizard);
    }
    return false;
}

function finalModalChildren(contractWizard) {
    if (!contractWizard.get('dataIsFetching')) {
        const cardDetails = contractWizard.get('card_details');
        const amount = findElementbyId(cardDetails, 'monto_traspasar') || 0;

        let modal;
        if (depositBiggerThanBalance(contractWizard)) {
            modal = modalWithSummary(contractWizard, formatAmount(amount));
        }

        return modal;
    }
}

function validateStep1(card_details) {
    return {
        card_details: card_details,
        isValidStep: true
    };
}

function validateStep2(cardDetails, state, isValid) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const slideAmountIsNonZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;
    let newCardDetails;

    if (isValid && isValidAccount && slideAmountIsNonZero) {
        newCardDetails = cardDetails.update(
            cardDetails.findIndex(item => item.get('id') === 'validationModal'),
            item => item.set('openModal', showFinalModal(state))
        );
    } else {
        newCardDetails = cardDetails;
    }

    return {
        card_details: newCardDetails,
        isValidStep: isValidAccount && slideAmountIsNonZero
    };
}

function validateStep3(cardDetails, immConfirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = immConfirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: immConfirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: immConfirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, immConfirmationCheckboxes);
    }
}

function loadSliderAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.min_value = depositHireData.get('importeMin');
        component.max_value = depositHireData.get('importeMax');
        component.default_value = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
    }
    return component;
}

function shouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'cta_seleccionada', 'description') &&
        !contractWizard.get('intervenersIsFetching') &&
        !contractWizard.get('dataIsFetching') &&
        !contractWizard.getIn(['depositHireData', 'error'])
    );
}

function loan_SignParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails
        ? findElementbyId(immCardDetails, 'cta_seleccionada')
        : null;
    let selectedAccount;

    if (immAccounts && selectedAccountId) {
        const immAccount = immAccounts.getIn(['byId', selectedAccountId]);
        selectedAccount =
            API_ENTERPRISE_NUMBER +
            API_CENTER +
            immAccount.get('product') +
            immAccount.get('contractNumber');
    }

    return {
        originAccount: selectedAccount
    };
}

function onChangeAmount(amount) {
    let ret = [];
    ret.push({
        target: 'monto_traspasar',
        value: amount
    });
    return ret;
}

function onChangeCheckbox(card_details, component, event) {
    card_details.find(detail => detail.id === component.target).value = event.target.checked;

    return card_details;
}

function successPendingInfo() {
    return (
        <div className="no-new-money-deposits__additional-info">
            <p>
                <FormattedText value="contractSummary-contractSummary_noNewMoneyDepositPendingText" />
            </p>
            <p className="no-new-money-deposits__additional-info--note-text">
                <FormattedText value="contractSummary-contractSummary_noNewMoneyDepositPendingNote" />
            </p>
        </div>
    );
}

function setSuccessStepAdditionalInfo(contractWizard) {
    return depositBiggerThanBalance(contractWizard) ? successPendingInfo() : null;
}

function nextButtonDisabled(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = cardDetails && findElementbyId(cardDetails, 'monto_traspasar');
    return !!(cardDetails && !findElementbyId(cardDetails, 'cta_seleccionada', 'description') ||
     contractWizard.get('intervenersIsFetching') || contractWizard.get('dataIsFetching') || !depositAmount ||
     contractWizard.getIn(['depositHireData', 'error']));
}

module.exports = {
    finalModalChildren,
    loadSliderAmounts,
    loan_SignParams,
    nextButtonDisabled,
    onChangeAmount,
    onChangeCheckbox,
    setSuccessStepAdditionalInfo,
    setSuccessStepPrefix,
    shouldBuild,
    showFinalModal,
    validateStep,
};