const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    inProgress: false,
    error: '',
    success: false
});

const cardTravelOnReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CARD_TRAVEL_ON_RESET:
            return initialState;
        case actionTypes.CARD_TRAVEL_ON_REQUEST:
            return state.merge({
                inProgress: true
            });
        case actionTypes.CARD_TRAVEL_ON_SUCCESS:
            return state.merge({
                inProgress: false,
                success: true
            });
        case actionTypes.CARD_TRAVEL_ON_FAILURE:
            return state.merge({
                inProgress: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = cardTravelOnReducer;