// @helpers
const actionTypes = require('constants/actionTypes');

// @vendors
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: undefined,
    variables: undefined
});

function globalVariablesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GLOBAL_VARIABLES_REQUEST:
            return state.merge({
                isFetching: true,
                error: undefined
            });
        case actionTypes.GLOBAL_VARIABLES_SUCCESS:
            return state.merge({
                isFetching: false,
                variables: action.payload.data,
                error: undefined
            });
        case actionTypes.GLOBAL_VARIABLES_FAILURE:
            return state.merge({
                isFetching: false,
                variables: undefined,
                error: action.payload.error
            });
        default:
            return state
    }
}

module.exports = globalVariablesReducer;
