// @vendor
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const { LOGOUT_TIME_LIMIT } = require('constants/index');

function setInitialState(){
    return Immutable.Map({
        isOpen: false,
        showBlank: false,
        keepAliveModalVisible: false,
        expiration: LOGOUT_TIME_LIMIT,
        isFetchingInitialKeepAlive: false,
        hasBeenFetchedInitialKeepAlive: false,
        error: null,
   });
}

function logout(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.SHOW_LOGOUT_POPUP:
            return state.merge({
                isOpen: true,
                showBlank: true
            });
        
        case actionTypes.HIDE_LOGOUT_POPUP:
            return state.merge({
                isOpen: false,
                showBlank: true
            });

        case actionTypes.SHOW_KEEPALIVE_POPUP:
            return state.merge({
                keepAliveModalVisible: true
            });

        case actionTypes.HIDE_KEEPALIVE_POPUP:
            return state.merge({
                keepAliveModalVisible: false
            })
        
        case actionTypes.KEEP_ALIVE_SUCCESS:
            return state.merge({
                expiration: action.expiration,
                keepAliveModalVisible: false,
                hasBeenFetchedInitialKeepAlive: true,
                isFetchingInitialKeepAlive: false,
            });
        
        case actionTypes.KEEP_ALIVE_ERROR:
            return state.merge({
                error: action.error,
                keepAliveModalVisible: false,
                hasBeenFetchedInitialKeepAlive: true,
                isFetchingInitialKeepAlive: false,
            })

        case actionTypes.REQUEST_INITIAL_KEEP_ALIVE:
            return state.merge({
                isFetchingInitialKeepAlive: true
            });

        default:
            return state
    }
}

module.exports = logout;
