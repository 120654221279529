//@vendor
const Immutable = require('immutable');
//@constants
const actionTypes = require('constants/actionTypes');
const initialState = Immutable.fromJS({
    isFetching: false,
    error: false,
    titleKey: '',
    descriptionKey: '',
    url: ''
});


function collectiveCommissionsReducer(state = initialState , action) {
    switch(action.type){
        case actionTypes.COLLECTIVE_COMISSION_DATA_REQUEST:
            return state.merge({
                isFetching: true,
                error: false
            });

        case actionTypes.COLLECTIVE_COMISSION_DATA_SUCCESS:
            return state.merge({
                titleKey: action.payload.titleKey,
                descriptionKey: action.payload.descriptionKey,
                url: action.payload.url,
                isFetching: false,
                error: false
            });

        case actionTypes.COLLECTIVE_COMISSION_DATA_FAILURE:
            return state.merge({
                isFetching: false,
                error: true
            });

        default:
            return state;

    }
}

module.exports = collectiveCommissionsReducer;
