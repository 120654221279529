//@vendor
const Immutable = require('immutable');
//@actions
const actionTypes = require('constants/actionTypes');
//@constants
const { FIRST_STEP, WIZARD_SECOND_STEP, WIZARD_FIRST_STEP } = require('constants/index');
//@ reducers
const creditAccountStep1 = require('./creditCardModifyAccountStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            creditAccountStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        isFetching: false,
        error: false,
        submitSuccessful: false,
        visibleStep: WIZARD_FIRST_STEP,
        willCancel: false,
        associatedAccountDetails:{}
    });
}

function updateStep1(steps, action) {
    return steps.update(FIRST_STEP, (step) => {
        return creditAccountStep1(step, action);
    })
}

function modifyCardAccountReducer (state = setInitialState(), action) {
    let partialSteps;
    let visibleStep;
    switch(action.type) {
        case actionTypes.MODIFY_CARD_ACCOUNT_RESET:
            return setInitialState();
        case actionTypes.MODIFY_CARD_ACCOUNT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.data
            });
        case actionTypes.MODIFY_CARD_ACCOUNT_VALIDATE_STEP:
            visibleStep = state.get('visibleStep');
            if (visibleStep === WIZARD_FIRST_STEP) {
                partialSteps = updateStep1(state.get('steps'), action);
                visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep,
            });
        case actionTypes.MODIFY_CARD_ACCOUNT_SET_CURRENT_ACCOUNT:
        case actionTypes.MODIFY_CARD_ACCOUNT_SET_NEW_ACCOUNT:
             partialSteps = updateStep1(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.MODIFY_CARD_ACCOUNT_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MODIFY_CARD_ACCOUNT_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.ASSOCIATED_CARD_ACCOUNT_DETAILS_SUCCESS:
            return state.merge({
                associatedAccountDetails: action.payload.data.listaCtasAsociadas.ctasAsociadas[0]
            });
        case actionTypes.MODIFY_CARD_ACCOUNT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.MODIFY_CARD_ACCOUNT_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
                submitSuccessful: false
            });
        default:
            return state;
    }
}

module.exports = modifyCardAccountReducer;
