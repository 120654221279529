// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_TRANSFER_PENSION_PLAN_FISC_A_OPTION,
    BROKER_TRANSFER_PENSION_PLAN_FISC_B_OPTION,
    BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION,
    BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_OPTION,
    BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_OPTION,
    BROKER_TRANSFER_PENSION_PLAN_FISC_A_OPTION_SERVICE,
    BROKER_TRANSFER_PENSION_PLAN_FISC_B_OPTION_SERVICE,
    BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION_SERVICE,
    BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_SERVICE,
    BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_SERVICE,
    BROKER_TRANSFER_PENSION_PLAN_EUR,
    BROKER_TRANSFER_PENSION_PLAN_ERROR_BELOW_MIN
} = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        selectedTypeOption: BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_OPTION,
        selectedFiscOption: BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION,
        selectedFiscOptionService:BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION_SERVICE,
        selectedTypeOptionService:BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_SERVICE,
        partialFiscOptionEnabled: false,
        partialAportation: {
            amount: 0.0,
            currency: BROKER_TRANSFER_PENSION_PLAN_EUR
        },
        minimumAportation: {
            amount: 0.0,
            currency: BROKER_TRANSFER_PENSION_PLAN_EUR,
        },
        maximumAportation: {
            amount: 0.0,
            currency: BROKER_TRANSFER_PENSION_PLAN_EUR,
        },
        inputError: false,
        errorType: ''
   });
}

function validateStep(step){
    let validAmount = true;
    let errorType;
    if(step.get('selectedTypeOption') === BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_OPTION) {
        const partialAportationAmount = parseFloat(step.getIn(['partialAportation', 'amount']));
        validAmount = partialAportationAmount >= step.getIn(['minimumAportation', 'amount']);
        if(!validAmount) {
            errorType = BROKER_TRANSFER_PENSION_PLAN_ERROR_BELOW_MIN;
        }

    }
    return step.merge({
        valid: validAmount,
        inputError: !validAmount,
        errorType: errorType
    });
}

function getEqualOption(value) {
    switch (value) {
        case BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_OPTION: 
            return BROKER_TRANSFER_PENSION_PLAN_TYPE_PARTIAL_SERVICE
        default: 
            return BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_SERVICE
    }
}

function getEqualFiscalization(value) {
    switch (value) {
        case BROKER_TRANSFER_PENSION_PLAN_FISC_A_OPTION: 
            return BROKER_TRANSFER_PENSION_PLAN_FISC_A_OPTION_SERVICE
        case BROKER_TRANSFER_PENSION_PLAN_FISC_B_OPTION:
            return BROKER_TRANSFER_PENSION_PLAN_FISC_B_OPTION_SERVICE
        default: 
            return BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION_SERVICE
    }
}

function amountObject(value) {
    return {
        amount: value,
        currency: BROKER_TRANSFER_PENSION_PLAN_EUR
    }
}

function setPartialAportationEnabled(transferTypeOption, state) {
    const partialFiscOptionEnabled = transferTypeOption !== BROKER_TRANSFER_PENSION_PLAN_TYPE_TOTAL_OPTION;
    const selectedFiscOption = partialFiscOptionEnabled ? state.get('selectedFiscOption') : BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION;
    const selectedFiscOptionService = partialFiscOptionEnabled ? getEqualFiscalization(state.get('selectedFiscOption')):getEqualFiscalization(BROKER_TRANSFER_PENSION_PLAN_FISC_C_OPTION);
    const selectedTypeOptionService = getEqualOption(transferTypeOption);
    return state.merge({
        selectedTypeOptionService: selectedTypeOptionService,
        selectedFiscOptionService: selectedFiscOptionService,
        selectedTypeOption: transferTypeOption,
        selectedFiscOption: selectedFiscOption,
        partialFiscOptionEnabled: partialFiscOptionEnabled
    });
}

function brokerInternalTransferFundsStep3(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_TRANSFER_TYPE_OPTION:
            return setPartialAportationEnabled(action.payload.option, state);
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_FISCALIZATION_OPTION:
            return state.merge({
                selectedFiscOptionService: getEqualFiscalization(action.payload.option),
                selectedFiscOption: action.payload.option
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_AMOUNT:
            return state.merge({
                partialAportation: {
                    amount: action.payload.amount,
                    currency: BROKER_TRANSFER_PENSION_PLAN_EUR
                }
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_AMOUNT_RANGE:
            const actualValue = state.getIn(['partialAportation','amount']);
            const partialAportationValue = actualValue === action.payload.minimumAmount ? action.payload.minimumAmount : actualValue;
            return state.merge({
                minimumAportation: amountObject(action.payload.minimumAmount),
                partialAportation: amountObject(partialAportationValue)
            })
        default:
            return state;
   }
}

module.exports = brokerInternalTransferFundsStep3;
