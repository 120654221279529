const Immutable = require('immutable');

const actionTypes = require('constants/actionTypes');

const setInitialState = () => {
    return Immutable.fromJS({
        isFetching: false,
        fetchSuccess: false,
        error: false,
        youInvestPopUp: false,
        roboadvisorPopUp: false,
    });
}

const initialState = setInitialState();


function brokerInformativePopUpReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_INFORMATIVE_POPUPS_FETCHING:
            return state.merge({
                isFetching: true,
                fetchSuccess: false,
                error: false,
            })
        case actionTypes.BROKER_INFORMATIVE_POPUPS_SUCCESS:
            const youInvestPopUp = !!action.payload.data.popupDates &&
                !!action.payload.data.popupDates['you-invest'] ?
                action.payload.data.popupDates['you-invest'] : null;

            const roboadvisorPopUp = !!action.payload.data.popupDates &&
                !!action.payload.data.popupDates['we-invest-for-you'] ?
                action.payload.data.popupDates['we-invest-for-you'] : null;

            return state.merge({
                isFetching: false,
                fetchSuccess: true,
                error: false,
                youInvestPopUp: youInvestPopUp,
                roboadvisorPopUp: roboadvisorPopUp,
            })
        case actionTypes.BROKER_INFORMATIVE_POPUPS_ERROR:
        return state.merge({
            isFetching: false,
            fetchSuccess: false,
            error: true
        })
        default:
            return state;
    }
}

module.exports = brokerInformativePopUpReducer;
