const Immutable = require('immutable');
const {
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_REQUEST,
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE,
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS,

    EARLY_CANCEL_IMPOSITION_REQUEST,
    EARLY_CANCEL_IMPOSITION_FAILURE,
    EARLY_CANCEL_IMPOSITION_SUCCESS,

    EARLY_IMPOSITION_CANCEL_SET_VISIBLE_STEP,
    EARLY_IMPOSITION_CANCEL_VALIDATE,
    EARLY_CANCEL_IMPOSITION_SHOW_CANCEL_MODAL,
} = require('constants/contactCenter/actionTypes');
const {
    IDLE,
    FETCHING,
    FETCHED,
    FETCH_FAILED,
    SAVING,
    SAVED,
    SAVE_FAILED,
} = require('constants/contactCenter/depositOrImpositionCancellationStatus');
const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    status: IDLE,
    error: '',
    impositionEarlyCancellation: {},
    currentStep: WIZARD_FIRST_STEP,
    showCancelModal: false,
    steps: [
        {},
        {
            hasSignaturePattern: true
        }
    ],
});

const createFetchingState = (previousState, {depositId, impositionId}) => {
    return INITIAL_STATE.merge({
        status: FETCHING,
        depositId,
        impositionId,
    });
}

const createFetchErrorState = (previousState, error) => {
    return previousState.merge({
        status: FETCH_FAILED,
        error,
        impositionEarlyCancellation: {},
    });
}

const createFetchSucessState = (previousState, impositionEarlyCancellation) => {
    return previousState.merge({
        status: FETCHED,
        error: '',
        impositionEarlyCancellation,
    });
}

const createIsSavingState = previousState => {
    return previousState.merge({
        status: SAVING,
        error: '',
    });
};

const createSaveErrorState = (previousState, error) => {
    return previousState.merge({
        status: SAVE_FAILED,
        error,
    });
};

const setVisibleStep = (previousState, step) => {
    return previousState.merge({
        currentStep: step,
    });
}

const validateStep = (previousState) => {
    return previousState.get('currentStep') === WIZARD_FIRST_STEP ? previousState.merge({
        currentStep: WIZARD_SECOND_STEP
    }) : previousState;
}

const showCancelModal = (previousState, show) => {
    return previousState.merge({
        showCancelModal: show
    });
}

const createSaveSucessState = previousState => {
    return previousState.merge({
        status: SAVED,
        error: '',
    });
};

function impositionEarlyCancellation(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_REQUEST:
            return createFetchingState(state, action.payload);
        case FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE:
            return createFetchErrorState(state, action.payload.error);
        case FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS:
            return createFetchSucessState(state, action.payload);
        case EARLY_CANCEL_IMPOSITION_REQUEST:
            return createIsSavingState(state);
        case EARLY_CANCEL_IMPOSITION_FAILURE:
            return createSaveErrorState(state, action.payload.error);
        case EARLY_CANCEL_IMPOSITION_SUCCESS:
            return createSaveSucessState(state);
        case EARLY_IMPOSITION_CANCEL_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case EARLY_IMPOSITION_CANCEL_VALIDATE:
            return validateStep(state, action.payload.step);
        case EARLY_CANCEL_IMPOSITION_SHOW_CANCEL_MODAL:
            return showCancelModal(state, action.payload.show);
        default:
            return state;
    }
}

module.exports = impositionEarlyCancellation;
