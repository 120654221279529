//@vendor
const Immutable = require('immutable');
const lodashFind = require('lodash/collection/find');

//@constants
const {
    CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_REQUEST,
    CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_SUCCESS,
    CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_FAILURE,

    MODIFY_COMMERCE_AND_ATM_LIMITS_RESET,
    MODIFY_COMMERCE_AND_ATM_LIMITS_SET_VISIBLE_STEP,
    MODIFY_COMMERCE_AND_ATM_LIMITS_VALIDATE,
    MODIFY_COMMERCE_AND_ATM_LIMITS_SHOW_CANCEL_MODAL,
    MODIFY_COMMERCE_AND_ATM_LIMITS_HIDE_WARNING_MODAL,

    LIMITS_DATA_REQUEST,
    LIMITS_DATA_SUCCESS,
    LIMITS_DATA_FAILURE,

    SET_MODIFY_AMOUNT,
    SET_LIMITS_RANGE
} = require('constants/contactCenter/actionTypes');

const modifyTypes = require('constants/contactCenter/modifyCommerceAndATMLimits');

const {
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    FIRST_STEP
} = require('constants/index');

//@utilities
const { getCardLimits } = require('utilities/contactCenter/modifyCommerceAndATMLimitsHelper');

const INITIAL_STATE = Immutable.fromJS({
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    currentStep: WIZARD_FIRST_STEP,
    showCancelModal: false,
    limitsData: {
        isFetching: false,
        success: false,
        error: '',
        data: {},
        arrayLimitRanges: []
    },
    steps: [
        {
            showWarningModal: false,
            newSelectedLimit: '',
            valid: false
        },
        {},
        {
            hasSignaturePattern: true
        }
    ],
});

const createConfirmModifyCommerceAndATMLimitsRequestState = previousState => {
    return previousState.merge({
        isFetching: true,
        hasBeenFetched: false,
        error: '',
    });
};

const createConfirmModifyCommerceAndATMLimitsErrorState = (previousState, error) => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: false,
        error,
    });
};

const createConfirmModifyCommerceAndATMLimitsSuccessState = previousState => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: true,
        error: '',
    });
};

const createLimitsDataRequestState = (previousState) => {
    return previousState.mergeDeep({
        limitsData: {
            isFetching: true,
            success: false,
            error: ''
        }
    });
};

const createLimitsDataSuccessState = (previousState, payload) => {
    const cardLimitType = modifyTypes[payload.type.toUpperCase()];
    let cardLimitObject = lodashFind(payload.response.limitesDebito, (element) => { return element.codigoLimite === cardLimitType});
    const newSteps = [{newSelectedLimit: cardLimitObject.importeLimiteActual.importe}];

    return previousState.mergeDeep({
        steps: newSteps,
        limitsData: {
            isFetching: false,
            success: true,
            error: '',
            data: cardLimitObject,
        }
    });
};

const createLimitsDataFailure = (previousState, error) => {
    return previousState.mergeDeep({
        limitsData: {
            isFetching: false,
            success: false,
            error
        }
    })
}

const setLimitsRange = (previousState, cardSubtype) => {
    const limitsData = getCardLimits(cardSubtype);

    return previousState.mergeDeep({
        limitsData: {
            ...limitsData
        }
    });
};

const setAmount = (previousState, amount) => {
    return previousState.mergeDeep({
        steps: [
            {
                newSelectedLimit: amount
            }
        ]
    });
}

const setVisibleStep = (previousState, step) => {
    return previousState.merge({
        currentStep: step,
    });
}

const validateStep = (previousState, step) => {
    switch (step) {
        case WIZARD_FIRST_STEP:
            const currentLimit = previousState.getIn(['limitsData', 'data', 'importeLimiteActual', 'importe']);
            const selectedLimit = previousState.getIn(['steps', FIRST_STEP, 'newSelectedLimit']);
            const valid = currentLimit !== selectedLimit;

            return previousState.mergeDeep({
                currentStep: valid ? step + 1 : WIZARD_FIRST_STEP,
                steps: [
                    {
                        showWarningModal: !valid,
                        valid: valid
                    }
                ]
            });
        case WIZARD_SECOND_STEP:
            return previousState.merge({
                currentStep: step +1
            });
        default:
            return previousState;
    }
}

const showCancelModal = (previousState, show) => {
    return previousState.merge({
        showCancelModal: show
    });
}

const hideWarningModal = (previousState) => {
    return previousState.mergeDeep({
        steps: [
            {
                showWarningModal: false
            }
        ]
    });
}

function modifyCommerceAndATMLimits(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_REQUEST:
            return createConfirmModifyCommerceAndATMLimitsRequestState(state);
        case CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_FAILURE:
            return createConfirmModifyCommerceAndATMLimitsErrorState(state, action.payload.error);
        case CONFIRM_MODIFY_COMMERCE_AND_ATM_LIMITS_SUCCESS:
            return createConfirmModifyCommerceAndATMLimitsSuccessState(state);
        case MODIFY_COMMERCE_AND_ATM_LIMITS_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case MODIFY_COMMERCE_AND_ATM_LIMITS_VALIDATE:
            return validateStep(state, action.payload.step);
        case MODIFY_COMMERCE_AND_ATM_LIMITS_SHOW_CANCEL_MODAL:
            return showCancelModal(state, action.payload.show);
        case MODIFY_COMMERCE_AND_ATM_LIMITS_HIDE_WARNING_MODAL:
            return hideWarningModal(state);
        case MODIFY_COMMERCE_AND_ATM_LIMITS_RESET:
            return INITIAL_STATE;
        case LIMITS_DATA_REQUEST:
            return createLimitsDataRequestState(state);
        case LIMITS_DATA_SUCCESS:
            return createLimitsDataSuccessState(state, action.payload);
        case LIMITS_DATA_FAILURE:
            return createLimitsDataFailure(state, action.payload.error);
        case SET_MODIFY_AMOUNT:
            return setAmount(state, action.payload);
        case SET_LIMITS_RANGE:
            return setLimitsRange(state, action.payload);
        default:
            return state;
    }
}

module.exports = modifyCommerceAndATMLimits;
