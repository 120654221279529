// @vendors
const Immutable = require('immutable');
const { formatText, hasTranslation } = require('core/i18n').i18n;

// @helpers
const { decodeHTMLEntities } = require('utilities/stringHelper');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: null,
    isIframeIsFetching: false,
    isIframeSuccess: false,
    isIframeError: false,
    paramsAFI: {
        amount: null,
        installmentes: null,
        interestRate: null,
        calculationType: null,
        method: null,
        typePayment: null,
        valuePayment: null,
    },
    paramsInne: {
        tin: null,
        tae: null,
    },
    temporaryDocumentId: null,
    textCheckInne: '',
    textCheckRevolving: '',
});

const processText = text => {
    let processedText = text;

    if (hasTranslation(text)) {
        processedText = formatText(text);
    }
    //Replace is used to remove instances of " ," and replace them for "," and multiple consecutive spaces for " "
    processedText = decodeHTMLEntities(processedText).replace(/ ,/g, ',').replace(/\ {2,10}/g, ' ');

    return processedText;
};

const validateTermsAndCondsText = (state, response) => {
    const { inne, revolving } = response;
    return state.merge({
        textCheckInne: processText(inne),
        textCheckRevolving: revolving ? processText(revolving) : ''
    });
}

function revolvingPersistentDataReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.REVOLVING_SET_AFI_PARAMS:
            return state.merge({
                paramsAFI: {
                    ...action.payload.params
                }
            });

        case actionTypes.REVOLVING_SET_INNE_PARAMS:
            return state.merge({
                paramsInne: {
                    ...action.payload.params
                }
            });

        case actionTypes.REVOLVING_TERMS_AND_CONDS_SET_TEXT:
            return validateTermsAndCondsText(state, action.payload.text);

        case actionTypes.REVOLVING_AFI_INFORMATION_FETCHING:
            return state.merge({
                isIframeIsFetching: true,
                isIframeSuccess: false,
                isIframeError: false,
            });

        case actionTypes.REVOLVING_AFI_INFORMATION_SUCCESS:
            return state.merge({
                isIframeIsFetching: false,
                isIframeSuccess: true,
                isIframeError: false,
            });

        case actionTypes.REVOLVING_AFI_INFORMATION_ERROR:
            return state.merge({
                error: action.payload.error,
                isIframeIsFetching: false,
                isIframeSuccess: false,
                isIframeError: true,
            });
        case actionTypes.REVOLVING_AFI_INFORMATION_RESET:
            return state.merge({
                isIframeIsFetching: false,
                isIframeSuccess: false,
                isIframeError: false,
            });
        case actionTypes.REVOLVING_INNE_SUCCESS:
            const { temporaryDocumentId } = action.payload
            return state.merge({
                temporaryDocumentId
            });
        default:
            return state;
    }
}

module.exports = revolvingPersistentDataReducer;
