// @vendors
const Immutable = require('immutable');

const apiURLBuilder = require('core/apiURLBuilder');

// @constants
const {
    CONTRATO_PRESTACION_SERVICIOS_PERSONAS_FISICAS,
    BROKER_PLAN_LEGAL_DOCUMENT_DDFP
} = require('constants/index');

const {
    BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH,
    BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR,
    BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS,
    BROKER_PLAN_LEGAL_DOCUMENT_SUBSCRIPTION_NEWSLETTER_FETCH_SUCCESS
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    fundamentalPlanDataUrl: '',
    specificationsDocumentUrl: '',
    subscriptionNewsletterUrl: '',
    contractProvisionServicesNaturalPersonsUrl: CONTRATO_PRESTACION_SERVICIOS_PERSONAS_FISICAS,
    isFetching: false,
    isFetched: false
});

const getLinksFromMorningstarextService = (immState, dgscode, originCountry) => {
    return immState.merge({
        isFetching: false,
        isFetched: true,
        fundamentalPlanDataUrl:
            apiURLBuilder.getURL('morningstarPlanLegalDocumentUrl', {dgscode: dgscode, documentType: BROKER_PLAN_LEGAL_DOCUMENT_DDFP, originCountry }),
        specificationsDocumentUrl:
            apiURLBuilder.getURL('morningstarPlanLegalDocumentUrl', {dgscode: dgscode, documentType: 'Especificaciones', originCountry }),
    });
};

function brokerPlanLegalDocument(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS:
            return getLinksFromMorningstarextService(state, action.payload.planDgsCode, action.payload.originCountry)
        case BROKER_PLAN_LEGAL_DOCUMENT_SUBSCRIPTION_NEWSLETTER_FETCH_SUCCESS:
            return state.set('subscriptionNewsletterUrl', action.payload.url);
        case BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH:
            return state.merge({
                isFetching: true,
                isFetched: false,
                fundamentalPlanDataUrl: '',
                fundamentalPlanDataEncodedDocumentId: '',
                specificationsDocumentUrl: '',
                specificationsDocumentEncodedDocumentId: '',
            });
        case BROKER_PLAN_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR:
            return state.merge({ isFetching: false, isFetched: true });
        default:
            return state;
    }
}

module.exports = brokerPlanLegalDocument;
