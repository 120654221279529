// @vendor
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { GAPS_GAP } = require('constants/contactCenter/optionIdTracker');
// @commons
const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            steps: [
                {},
                { hasSignaturePattern: true }
            ],
            visibleStep: WIZARD_FIRST_STEP,
            operative: { value: GAPS_GAP },
            errorReceived: false,
            incompleteInputs: false,
        })
    );
};

function gapAccess(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GAP_ACCESS_SET_OPERATIVE:
            return state.merge({
                operative: action.payload.operative,
            });

        case actionTypes.GAP_ACCESS_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.GAP_VALIDATE_STEP:
            if (action.payload.step === WIZARD_FIRST_STEP) {
                return state.merge({
                    visibleStep: WIZARD_SECOND_STEP
                });
            } else {
                return state;
            }

        case actionTypes.GAP_RESET:
            return setInitialState();

        case actionTypes.GAP_SECURITY_DATA_ERROR:
            return state.merge({
                incompleteInputs: true,
            });

        default:
            return state;
    }
}

module.exports = gapAccess;
