// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        isFetching: false,
        errorReceived: false,
        shippingAddressList: {},
        shippingAddressType: '',
        shippingAddress: {}
    });
}

function shippingAddressListReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GET_SHIPPING_ADDRESS_LIST_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.GET_SHIPPING_ADDRESS_LIST_SUCCESS:
            return state.merge({
                shippingAddressList: action.payload.response,
                errorReceived: false,
                isFetching: false
            });
        case actionTypes.GET_SHIPPING_ADDRESS_LIST_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                isFetching: false
            });
        case actionTypes.SET_CARD_SHIPPING_ADDRESS:
            return state.merge({
                shippingAddress: action.payload.address
            });
        case actionTypes.SET_SHIPPING_ADDRESS_TYPE:
            return state.merge({
                shippingAddressType: action.payload.addressType
            });
        case actionTypes.RESET_CARD_SHIPPING_ADDRESS:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = shippingAddressListReducer;
