// @ vendors
const includes = require('lodash/collection/includes');
const { List } = require('immutable');

// @ utilities
const { getLastNChars } = require('./stringHelper');
const currencyHelper = require('./currencyHelper');

// @ constants
const {
    ACCOUNT_INTERVENTION_AUTHORIZED,
    ACCOUNT_INTERVENTION_CO_OWNER,
    ACCOUNT_INTERVENTION_OWNER,
    CHECKING_ACCOUNT_CODE,
    EURO_TEXT,
    ACCOUNT_INTERVENTION_REPRESENTATIVE,
    ACCOUNT_TYPE_PAYROLL,
    ACCOUNT_NEW_PRODUCT,
    ACCOUNT_TYPE_CURRENT_WITH_FOREIGN_BALANCE
} = require('constants/index');

let filterValue = "";

const { ACCOUNTS_WITH_CHEQUES_OR_DEBITS_SUBTYPES } = require('constants/accounts');

const isChequeOrDebitAccount = (accountProduct, accountSubType) => {
    return (
        accountProduct === String(CHECKING_ACCOUNT_CODE) &&
        ACCOUNTS_WITH_CHEQUES_OR_DEBITS_SUBTYPES.includes(accountSubType)
    );
};

const isPayrollAccount = (accountType, product) => {
    return accountType === ACCOUNT_TYPE_PAYROLL && product === ACCOUNT_NEW_PRODUCT;
}

const isAssociatedAccount = (account, associatedAccount) => {
    return account !== associatedAccount
};

const isUpgradeableToPayrollAccount = (accountProduct, accountSubType) => {
    return (
        accountProduct === String(CHECKING_ACCOUNT_CODE) &&
        accountSubType === ACCOUNTS_WITH_CHEQUES_OR_DEBITS_SUBTYPES[0]
    );
};

const getCurrentAccounts = (accountIndexes, accountsById) => {
    const howMany = accountIndexes ? accountIndexes.length || accountIndexes.size : 0;
    return !accountIndexes || !howMany
        ? List()
        : accountIndexes.filter(accountId =>
            isChequeOrDebitAccount(
                accountsById.getIn([accountId, 'newProduct']),
                accountsById.getIn([accountId, 'accountType'])
            )
        );
};

const getHiringAccounts = (accountIndexes, accountById, onlyOwner = false, onlyHolder = false) => {
    if (onlyHolder) {
        return getCurrentAccounts(accountIndexes, accountById).filter(accountId =>
            accountById.getIn([accountId, 'interventionType']) === ACCOUNT_INTERVENTION_OWNER
        );
    }
    let interventionTypes = [ACCOUNT_INTERVENTION_OWNER, ACCOUNT_INTERVENTION_CO_OWNER, ACCOUNT_INTERVENTION_REPRESENTATIVE];

    !onlyOwner && interventionTypes.push(ACCOUNT_INTERVENTION_AUTHORIZED);
    return getCurrentAccounts(accountIndexes, accountById).filter(accountId =>
        includes(interventionTypes, accountById.getIn([accountId, 'interventionType']))
    );
};

const getCreditCardCurrentAccounts = (accountIndexes, accountById, associatedAccount) => {
    return getCurrentAccounts(accountIndexes, accountById).filter(accountId =>
        isAssociatedAccount(associatedAccount, accountById.getIn([accountId, 'ibanComplex', 'codbban']))
    );
}

const getAccountByImposition = (impositionAssociatedAccount, immAccounts) => {
    return immAccounts.get('byId').find(immAccount => {
        return (
            immAccount.get('contractNumber') ===
            (impositionAssociatedAccount.numerodecontrato ||
                impositionAssociatedAccount.get('contractNumber')) &&
            immAccount.get('product') ===
            (impositionAssociatedAccount.producto || impositionAssociatedAccount.get('product'))
        );
    });
};

const getFundsByImposition = immImpositions => {
    let fundsAmountTotal = {
        amount: 0,
        currency: EURO_TEXT,
        participations: 0
    };

    immImpositions.get('byId').map(imposition => {
        if (fundsAmountTotal.currency === imposition.getIn(['fundsAmount', 'currency'])) {
            fundsAmountTotal.amount =
                fundsAmountTotal.amount + imposition.getIn(['fundsAmount', 'amount']);
            fundsAmountTotal.participations += imposition.get('fundParticipations');
        }
    });

    return fundsAmountTotal;
};

const getCurrentSelectAccount = (immAccount) => {
    let alias = immAccount.get('alias');
    let iban = getLastNChars(immAccount.get('iban'));
    let title = immAccount.getIn(['catalogData', 'subtype']);
    let availableBalance = currencyHelper.formatAmountObject(immAccount.getIn(['balances', 'availableBalance']));
    return `${alias} ...${iban} - ${title}: ${availableBalance.amount} ${availableBalance.currencySymbol}`;
}

// Filters the accounts that meet the flow requirements.
const filterValidAccounts = (accountsById, immAccounts, data) => {
    const { currencyAllowed, onlyOwner, onlyHolder } = data;
    const immAccountsById = currencyAllowed ?
        accountsById.filter(account => account.getIn(['balance', 'currency']) === currencyAllowed) :
        accountsById;

    return getHiringAccounts(immAccounts.get('byOrder'), immAccountsById, onlyOwner, onlyHolder) || [];
};

// Used to search associated account id from account number
const searchAssociatedAccountId = (accountNumber, immAccounts) => {
    const product = accountNumber && accountNumber.substr(0, 3);
    const contractNumber = accountNumber && accountNumber.substr(3);

    return immAccounts.get('byOrder').find((accountId) => {
        const account = immAccounts.getIn(['byId', accountId]);
        return account.get('contractNumber') === contractNumber && account.get('product') === product;
    });
}

// Filters all the accounts that don't have a debit card.
const getAccountsWithoutDebitCard = (immAccounts, immCards) => (
    immAccounts.get('byId').filter(account => {
        let accountWithoutDebitCard;
        const accountHasDebit = immCards.getIn(['debit', 'byOrder']).find(cardId => {
            const immCard = immCards.getIn(['byId', cardId]);
            return immCard.get('isOpenDebit') &&
                immCard.get('status') === 'EN VIGOR' &&
                (immCard.get('associatedAccountShort') === account.get('contractNumber'));
        });

        if (!accountHasDebit) {
            accountWithoutDebitCard = account;
        }

        return accountWithoutDebitCard;
    })
);

// Filter all OY accounts
const getOpenYoungAccounts = (immAccounts, oyAccountsList) => {
    let openYoungAccounts = immAccounts.get('byOrder');
    openYoungAccounts = openYoungAccounts.filter(accountId => {
        const immAccount = immAccounts.getIn(['byId', accountId]);
        const oyAccount = oyAccountsList.filter((account) => {
            return (account.get('producto') === immAccount.get('newProduct'))
                && (account.get('numerodecontrato') === immAccount.get('newContractNumber'));
        });
        if (oyAccount.size !== 0) {
            return accountId;
        }
    });
    return openYoungAccounts;
};

// Filter all salary account
const getPayrollAccounts = (accountIndexes, accountsById) => {
    const howMany = accountIndexes ? accountIndexes.length || accountIndexes.size : 0;
    return (!accountIndexes || !howMany)
        ? List()
        : accountIndexes.filter(accountId =>
            isPayrollAccount(
                accountsById.getIn([accountId, 'accountType']),
                accountsById.getIn([accountId, 'newProduct'])
            )
        );
};

//Filter currentAccount without forign balance account
const getCurrentAccountWithoutForeignBalance = (accountIndexes, accountById) => {
    return getCurrentAccounts(accountIndexes, accountById).filter(accountId =>
        accountById.getIn([accountId, 'accountType']) !== ACCOUNT_TYPE_CURRENT_WITH_FOREIGN_BALANCE
    );
};

const hasAllAccountsInactive = (accountIndexes, immAccounts) => {
    let isAllAccountsInactive = accountIndexes.every((account) => immAccounts.getIn([account, 'isInactiveAccount']));
    return isAllAccountsInactive;
}

const hasAllAccountsInactiveAndNegativeBalance = (accountIndexes, immAccounts) => {
    let isAllAccountsInactive = accountIndexes.every((account) => immAccounts.getIn([account, 'isInactiveAccount']) ||
        immAccounts.getIn([account, 'isNegativeBalance']));
    return isAllAccountsInactive;
}

const getNumOfActiveAccounts = (accountIndexes, immAccounts) => {
    const isAllAccountsInActive = hasAllAccountsInactive(accountIndexes, immAccounts);

    let count = 0;
    if (!isAllAccountsInActive) {
        accountIndexes.forEach((account) => {
            if (!immAccounts.getIn([account, 'isInactiveAccount'])) {
                count += 1;
            }
        });
    }

    return count;
}

const saveDateFilter = (value) => {
    filterValue = value;
};

const returnDateFilter = () => {
    return filterValue;
};


module.exports = {
    getCurrentSelectAccount,
    getCreditCardCurrentAccounts,
    filterValidAccounts,
    getAccountByImposition,
    getAccountsWithoutDebitCard,
    getCurrentAccountWithoutForeignBalance,
    getCurrentAccounts,
    getFundsByImposition,
    getNumOfActiveAccounts,
    getOpenYoungAccounts,
    getPayrollAccounts,
    hasAllAccountsInactive,
    hasAllAccountsInactiveAndNegativeBalance,
    isChequeOrDebitAccount,
    isPayrollAccount,
    isUpgradeableToPayrollAccount,
    returnDateFilter,
    saveDateFilter,
    searchAssociatedAccountId,
};
