// @ vendor
const Immutable = require('immutable');
// @ reducers
const profileEditorStep1 = require('./profileEditor/profileEditorStep1');
// @ helpers
const PersonalDataHelper = require('utilities/personalDataHelper');
// @ constants
const actionTypes = require('constants/actionTypes');
const { isValidDocumentNumberOfType } = require('routes/contractsView/utilities/inputsValidationHelper')
const {
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIF
} = require('constants/index');
const { FIRST_STEP, SECOND_STEP, POSTAL_CODE_REGEX, PREFIX_SPANISH_ISIN_CODE } = require('constants/index');
const {
    PROFILE_EDIT_FORMS: {
        ADDRESS,
        FISCAL_DATA,
        EMAIL,
        PHONE,
        NEWADDRESS
    }
} = require('constants/myProfileEditor');
const { COUNTRIES_CODES: { ES } } = require('constants/countries');

function setInitialState() {
    return Immutable.Map().merge({
        editMode: '',
        steps: [
            profileEditorStep1(undefined, { type: null }),
            { hasSignaturePattern: true }
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        validateStepError: false,
        willLeave: false,
        willCancel: false,
        errorReceived: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, (step) => {
        switch (index) {
            case FIRST_STEP:
                return profileEditorStep1(step, action);
        }
    })
}

function validateEmailForm(immStep) {
    // we check that the user entered data in both inputs
    return immStep.get('emailDirty') && immStep.get('repeatEmailDirty') &&
        // we check that it has the right format
        PersonalDataHelper.isValidEmail(immStep.get('email')) &&
        // we check that new email and repeated new email are the same
        immStep.get('email') === immStep.get('repeatEmail');
}

function validatePhoneForm(immStep, originCountry) {
    // we check that the user entered data in both inputs
    return immStep.get('phoneNumberDirty') && immStep.get('repeatPhoneNumberDirty') &&
        // we check that it has the right length
        PersonalDataHelper.isValidPhoneNumber(immStep.get('phoneNumber'), originCountry) &&
        // we check that new phone and repeated new phone are the same
        immStep.get('phoneNumber') === immStep.get('repeatPhoneNumber');
}

function validateNewAddressForm(immStep, immProfile) {
    let cont = true
    if (cont && immStep.get('country') && immStep.get('country') !== PREFIX_SPANISH_ISIN_CODE) {
        cont = cont && immStep.get('country') !== '' &&
            immStep.get('streetName') !== '';
    } else {
        cont = cont && immStep.get('locality') !== '' &&
            immStep.get('numberStreet') !== '' &&
            immStep.get('province') !== '' &&
            immStep.get('streetName') !== '' &&
            immStep.get('typeOfRoads') !== '' &&
            immStep.get('postalCode') !== '' &&
            immStep.get('postalCode').startsWith(immStep.get('province')) &&
            POSTAL_CODE_REGEX.test(immStep.get('postalCode'));
    }

    cont = cont && immStep.get('residenceCountry') && immStep.get('moreFiscalResidences') &&
        immStep.get('residenceCountry').trim() === immStep.get('country').trim() &&
        ((immStep.get('residenceCountry') !== PREFIX_SPANISH_ISIN_CODE && (immStep.get('residenceTin') || immStep.get('textAreaTin'))) ||
            (immStep.get('residenceCountry') === PREFIX_SPANISH_ISIN_CODE &&
                (immProfile.get('paisResidencia') === immStep.get('residenceCountry') ||
                    (immStep.get('nifNie') && (isValidDocumentNumberOfType(immStep.get('nifNie'), DOCUMENT_TYPE_NIE) || isValidDocumentNumberOfType(immStep.get('nifNie'), DOCUMENT_TYPE_NIF)))))) &&
        ((immStep.get('moreFiscalResidences') === 'S' && immStep.get('listPaisesTributacionAdic').size > 0) || immStep.get('moreFiscalResidences') === 'N')

    return cont
}

function validateAddressForm(immStep, originCountry) {
    return PersonalDataHelper.isValidAddress(immStep, originCountry);
}

function validateFiscalDataForm(immStep) {
    return !immStep.get('listPaisesTributacionAdic').some((immCountry) => immCountry.get('isEditing')) &&
        !immStep.get('taxAdditionalCountryIsAdding') &&
        immStep.get('birthCountry').trim() !== '' &&
        (immStep.get('birthCountry').trim() !== PREFIX_SPANISH_ISIN_CODE ? true : immStep.get('birthProvince').trim() !== '') &&
        immStep.get('birthSquare').trim() !== '';
}

function profileEditorReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.PROFILE_ACTION_CHANGE_VALUE:
            const inProgress = action.payload && action.payload.inProgress;
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                inProgress: inProgress !== undefined ? inProgress : true
            });
        case actionTypes.PROFILE_EDIT_ADDING_TAX_ADDITIONAL_COUNTRY_SET:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
            });
        case actionTypes.PROFILE_EDIT_RESET:
            return setInitialState().set('originCountry', state.get('originCountry'));
        case actionTypes.SET_PROFILE_INIT_STEP: {
            const { profile } = action.payload
            const step = state.getIn(['steps', 0]).merge({
                country: profile.paisDomicilio,
                locality: profile.nombrePlaza,
                numberStreet: profile.numeroDeVia,
                postalCode: profile.codigoPostal,
                province: profile.plaza.plaza.provincia,
                streetName: profile.nombreDeVia,
                typeOfRoads: profile.tipoDeVia,
                birthCountry: profile.paisNacimiento,
                birthProvince: profile.provincia.provincia,
                birthSquare: profile.plazaDeNacimiento,
                birthDay: profile.fechaNacimiento,
                listPaisesTributacionAdic: profile.listPaisesTributacionAdic
            })
            return state.setIn(['steps', 0], step)
        }
        case actionTypes.PROFILE_EDIT_VALIDATE_STEP:
            const { profile } = action.payload;
            const originCountry = state.get('originCountry').toUpperCase();
            let visibleStep;
            let formIsValid = false;
            const immFirstStep = state.getIn(['steps', 0]);
            const formValidators = {
                [NEWADDRESS]: {
                    isValid: (step) => validateNewAddressForm(step, profile),
                    stepNewData: {
                        countryBlur: true,
                        localityBlur: true,
                        numberStreetBlur: true,
                        postalCodeBlur: true,
                        provinceBlur: true,
                        streetNameBlur: true,
                        typeOfRoadsBlur: true,
                        residenceTinBlur: true,
                        birthCountryBlur: true,
                        birthProvinceBlur: true,
                        birthSquareBlur: true,
                        residenceCountryBlur: true,
                        textAreaTinBlur: true,
                        moreFiscalResidencesBlur: true,
                        nifNieBlur: true
                    }
                },
                [ADDRESS]: {
                    isValid: validateAddressForm,
                    stepNewData: {
                        localityBlur: true,
                        numberStreetBlur: true,
                        postalCodeBlur: true,
                        provinceBlur: true,
                        streetNameBlur: true,
                        typeOfRoadsBlur: true

                    }
                },
                [FISCAL_DATA]: {
                    isValid: validateFiscalDataForm,
                    stepNewData: {
                        birthCountryBlur: true,
                        birthProvinceBlur: true,
                        birthSquareBlur: true
                    }
                },
                [EMAIL]: {
                    isValid: validateEmailForm,
                    stepNewData: {
                        emailBlur: true,
                        repeatEmailBlur: true
                    }
                },
                [PHONE]: {
                    isValid: validatePhoneForm,
                    stepNewData: {
                        phoneNumberBlur: true,
                        repeatPhoneNumberBlur: true
                    }
                }
            };
            formIsValid = formValidators[state.get('editMode')].isValid(immFirstStep, originCountry);
            let stepNewData = Object.assign(
                { valid: formIsValid, scrolledToError: false },
                formValidators[state.get('editMode')].stepNewData
            );
            partialSteps = state.get('steps').update(0, immStep => immStep.merge(stepNewData));
            visibleStep = partialSteps.getIn([0, 'valid']) ? 2 : 1;
            return state.merge({
                inProgress: true,
                steps: partialSteps,
                validateStepError: !formIsValid,
                visibleStep
            });
        case actionTypes.FETCH_PROFILE_REQUEST:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.FETCH_PROFILE_SUCCESS:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                // TODO: PASAPORTE & https://jira.ods.ok-cloud.net/browse/FRONTCC-57
                // Default value if the server does not return any value
                originCountry: action.profile.originCountry || ES,
                steps: partialSteps
            });
        case actionTypes.PROFILE_EDIT_SET_FIELD:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                inProgress: true
            });
        case actionTypes.PROFILE_EDIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.PROFILE_EDIT_WILL_CANCEL:
        case actionTypes.PROFILE_EDIT_WILL_LEAVE:
            return state.merge({
                willLeave: true
            })
        case actionTypes.SET_PROFILE_EDIT_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.SET_PROFILE_EDIT_SUCCESS:
            return state.mergeDeep({
                submitSuccessful: true,
                inProgress: false,
                isFetching: false
            });
        case actionTypes.SET_PROFILE_EDIT_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                inProgress: false,
                isFetching: false,
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                submitSuccessful: false
            });
        case actionTypes.SET_FISCAL_DATA:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                inProgress: true,
                steps: partialSteps
            });
        case actionTypes.PROFILE_UPDATE_ADDITIONAL_TAX_COUNTRIES:
        case actionTypes.PROFILE_DELETE_ADDITIONAL_TAX_COUNTRIES:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                inProgress: true,
                steps: partialSteps
            });

        case actionTypes.PROFILE_EDIT_EDITING_TAX_ADDITIONAL_COUNTRY_SET:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });

        case actionTypes.PROFILE_EDIT_SET_EDIT_MODE:
            return state.merge({
                editMode: action.payload.value
            });

        default:
            return state
    }
}

module.exports = profileEditorReducer;
