// @core
const { cookiesDomain } = require('core/environmentConfig');

// @commons
const storageCommons = require('commonsUtilities/storage');

// @utilities
const { applyStoragePrefix } = require('utilities/usd');

// @constants
const { TOKEN_CREDENTIAL } = require('constants/session');
const { LOCAL_ENVIRONMENT, PRODUCTION_ENVIRONMENT } = require('constants/index');
const isProduction = window.__ENV__ === PRODUCTION_ENVIRONMENT;
const isLocal = window.__ENV__ === LOCAL_ENVIRONMENT



const getLocalStorageKey = (userKey) => {
    return (__CONTACT_CENTER__) ? applyStoragePrefix(userKey, isProduction) : userKey;
}

const getCookiesKey = (userKey) => {
    return (__CONTACT_CENTER__ && userKey === TOKEN_CREDENTIAL) ? applyStoragePrefix(userKey, isProduction) : userKey;
}


const hasLocalStorage = () => storageCommons.hasLocalStorage();


const cookiesMethods =  {
    set: (key, value, attributes = {}) => storageCommons.cookies.set(getCookiesKey(key), value, attributes, __CONTACT_CENTER__, isLocal, getCookiesKey(TOKEN_CREDENTIAL), cookiesDomain),
    get: key => storageCommons.cookies.get(getCookiesKey(key), __CONTACT_CENTER__, getCookiesKey(TOKEN_CREDENTIAL)),
    remove: (key, attributes) => storageCommons.cookies.remove(getCookiesKey(key), attributes, __CONTACT_CENTER__, getCookiesKey(TOKEN_CREDENTIAL))
}

const localStorageMethods = {
    set: (key, value) => storageCommons.localStorage.set(getLocalStorageKey(key), value, __CONTACT_CENTER__, isLocal, getLocalStorageKey(TOKEN_CREDENTIAL), cookiesDomain),
    get: (key) => storageCommons.localStorage.get(getLocalStorageKey(key), __CONTACT_CENTER__, getLocalStorageKey(TOKEN_CREDENTIAL)),
    remove: (key) => storageCommons.localStorage.remove(getLocalStorageKey(key), __CONTACT_CENTER__, getLocalStorageKey(TOKEN_CREDENTIAL)),
    removeLike: (key) => {
        for (const localKey in localStorage) {
            if (localKey.includes(key)) {
                storageCommons.localStorage.remove(localKey, __CONTACT_CENTER__, getLocalStorageKey(TOKEN_CREDENTIAL));
            }
        }
    }
}

const sessionStorageMethods = {
    set: (key, value) => storageCommons.sessionStorage.set(key, value, __CONTACT_CENTER__, isLocal, TOKEN_CREDENTIAL, cookiesDomain),
    get: key => storageCommons.sessionStorage.get(key, __CONTACT_CENTER__, TOKEN_CREDENTIAL),
    remove: (key) => storageCommons.sessionStorage.remove(key, __CONTACT_CENTER__, TOKEN_CREDENTIAL),
}

const openIndexedDatabase = () => {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open('OpenDatabase', 1);
  
      openRequest.onupgradeneeded = () => {
        const db = openRequest.result;
        if (!db.objectStoreNames.contains('translations')) {
          db.createObjectStore('translations');
        }
      };
  
      openRequest.onsuccess = () => {
        resolve(openRequest.result);
      };
  
      openRequest.onerror = () => {
        reject(openRequest.error);
      };
    });
  };

  const indexedDatabaseMethods = {  
    set: async (key, value) => {
      const db = await openIndexedDatabase();
      const transaction = db.transaction('translations', 'readwrite');
      const store = transaction.objectStore('translations');
      return await store.put(value, key);
    },

    get: async (key) => {
      const db = await openIndexedDatabase();
      const transaction = db.transaction('translations', 'readwrite');
      const store = transaction.objectStore('translations');
      const request = await store.get(key);
      return new Promise((resolve, reject) => {
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = () => {
          reject(request.error);
        };
      });
    },

    remove: async (key) => {
        const db = await openIndexedDatabase();
        const transaction = db.transaction('translations', 'readwrite');
        const store = transaction.objectStore('translations');
        await store.delete(key);
      },
  };

module.exports = {
    hasLocalStorage,
    cookies: cookiesMethods,
    localStorage: localStorageMethods,
    sessionStorage: sessionStorageMethods,
    indexedDatabaseStorage: indexedDatabaseMethods
};
