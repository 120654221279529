// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_REQUEST,
    MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_SUCCESS,
    MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_FAILURE,
    MORTGAGE_REQUEST_SUMMARY_DOCUMENTATION_RESET
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    data: [],
    error: '',
    isFetching: false,
    documentsRequested: false
});

const dataProprietor = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_SUMMARY_DOCUMENTATION_RESET:
            return initialState.merge();

        case MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_REQUEST:
            return state.merge({
                isFetching: true
            });

        case MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_SUCCESS:
            return state.merge({
                data: action.data.data || [],
                error: '',
                isFetching: false,
                documentsRequested: true
            });

        case MORTGAGE_REQUEST_FETCH_SUMMARY_DOCUMENTATION_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        default:
            return state;
    }
};

module.exports = dataProprietor;
