const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    scrollToTop: false,
    valid: true,
});

function validateStep(state, externalComponentsAreValid) {
    return state.merge({
        scrollToTop: !externalComponentsAreValid,
        valid: externalComponentsAreValid,
    });
}

function myProfileShippingAddressStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.PROFILE_SHIPPING_ADDRESS_SCROLL_TO_TOP_TOGGLE:
            return state.merge({
                scrollToTop: action.payload.scrollToTop,
            })

        case actionTypes.PROFILE_SHIPPING_ADDRESS_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        default:
            return state;
    }
}

module.exports = myProfileShippingAddressStep1;
