// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    newOffer: 0,
    tae: 0,
    isFetching: false,
    isSaving: false
});

const mortgageRequestMovingExpenses = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_MONTHLY_FEE_REQUEST:
            return state.set('isFetching', true);
        case actionTypes.MORTGAGE_REQUEST_MOVING_FEE_SUCCESS:
            return state.merge({
                newOffer: action.data.amount,
                tae: action.data.tae,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_MOVING_FEE_FAILURE:
            return state.set('isFetching', false);
        case actionTypes.MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_REQUEST:
            return state.set('isSaving', true);
        case actionTypes.MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_SUCCESS:
        case actionTypes.MORTGAGE_REQUEST_ACCEPT_MOVING_FEE_FAILURE:
            return state.set('isSaving', false);
        default:
            return state;
    }
};

module.exports = mortgageRequestMovingExpenses;
