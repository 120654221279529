// @vendors
const Immutable = require('immutable');
// const moment = require('moment');

// @utilities
const {getDevicesKey, getIsFetchingKey} = require('utilities/devices');

const {
    FETCH_SESSIONS_BY_DEVICE_IN_PROGRESS,
    FETCH_SESSIONS_BY_DEVICE_SUCCESS,
    FETCH_SESSIONS_BY_DEVICE_FAILURE,
    FETCH_DEVICE_ACTION_IN_PROGRESS,
    FETCH_DEVICE_ACTION_SUCCESS,
    FETCH_DEVICE_ACTION_ERROR,
    FETCH_SESSION_ACTION_IN_PROGRESS,
    FETCH_SESSION_ACTION_SUCCESS,
    FETCH_SESSION_ACTION_ERROR,
    FETCH_DEVICES_FAILURE,
    FETCH_DEVICES_SUCCESS,
    FETCH_DEVICES_IN_PROGRESS,
    FETCH_DEVICES_CLEAR,
    FETCH_DEVICES_CLEAR_SESSIONS,
    FETCH_DEVICE_ENROLMENT_INFORMATION,
    FETCH_DEVICE_ENROLMENT_IN_PROGRESS,
    FETCH_DEVICE_ENROLMENT_ERROR,
    FETCH_DEVICE_ENROLMENT_SUCCESS,
    FETCH_DEVICE_ENROLMENT_STATE,
    FETCH_DEVICE_ENROLMENT_DATA_TOKEN,
    MODIFY_DEVICE_ALIAS_SUCCESS,
    MODIFY_DEVICE_ALIAS_ERROR,
    FETCH_AGENT_PROFILE_VALIDATE_SUCCESS,
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    errors: {
        sessions: {},
        sessionsAction: {},
        deviceAction: {},

        devices: false,
        devicesBlocked: false,
        devicesUnknown: false,
        forgottenDevices: false,
        forgottenDevicesBlocked: false,
        forgottenDevicesUnknown: false,
    },
    // Enrol device
    trust: {
        enrol: false,
        isFetching: true,
        dataToken: null,
        device: {},
        error: {},
        success: false
    },
    // Visible devices
    devices: [],
    devicesBlocked: [],
    devicesUnknown: [],
    // Hidden devices
    forgottenDevices: [],
    forgottenDevicesBlocked: [],
    forgottenDevicesUnknown: [],

    sessions: {},
    isFetchingSession: {},
    isFetchingDeviceAction: {},
    isFetchingSessionAction: {},

    isFetchingDevices: false,
    isFetchingDevicesBlocked: false,
    isFetchingforgottenDevices: false,
    isFetchingforgottenDevicesBlocked: false,
    isAgentProfileAuthorized: false,
});

/**
 * A device is considered unknown if:
 *    1. Last connection has been between 1 am and 6 am
 *
 * Param: Not blocked or forgotten devices
 * Returns: Object with two lists. Devices and Unknown devices
 */
const filterdevicesUnknown = (devices) => {
    return devices.reduce(
        (accumulator, currentDevice) => {

            // NOTE:
            // At fiurst time the rule is not applied
            accumulator.devices.push(currentDevice);
            return accumulator

            // // Firstly, the device has to have a last lastConnection
            // const lastConnection = currentDevice && currentDevice.sessions && currentDevice.sessions[0] && currentDevice.sessions[0].fhStart
            // if (!lastConnection) {
            //     accumulator.devices.push(currentDevice);
            // } else {
            //     // Moment of last connection
            //     const lastConnectionMoment = moment(lastConnection)
            //     // Moment of last connection set to 1 am
            //     const previousRangeMoment = moment(lastConnectionMoment).startOf('day').set('hour', 2);
            //     // Moment of last connection set to 6 am
            //     const laterRangeMoment = moment(lastConnectionMoment).startOf('day').set('hour', 6);
            //     // Check if lastConnection is in the range
            //     const isUnknown = lastConnectionMoment.isBetween(previousRangeMoment, laterRangeMoment);
            //     // Push of the device
            //     accumulator[(isUnknown ? "devicesUnknown" : "devices")].push(currentDevice);
            // }
            // return accumulator;
        },
        {devices: [], devicesUnknown: []}
    )
}

function devices(state = initialState, action) {
    switch (action.type) {
        case FETCH_DEVICES_CLEAR:
            return initialState;

        case FETCH_DEVICES_IN_PROGRESS: {
            const {payload: {blocked, forgotten, isFetching}} = action
            return state.mergeDeep({
                errors: {
                    [getDevicesKey(blocked, forgotten, true)]: false,
                    [getDevicesKey(blocked, forgotten, false)]: false
                },
                [getDevicesKey(blocked, forgotten, true)]: [],
                [getDevicesKey(blocked, forgotten, false)]: [],
                [getIsFetchingKey(blocked, forgotten)]: isFetching
            })
        }

        case FETCH_DEVICES_SUCCESS: {
            const {payload: {blocked, forgotten, devices}} = action

            // NOTE:
            // Temporary filter of unknown devices.
            // In the future the rule should be applied in backend

            if (!blocked) {
                const filteredDevices = filterdevicesUnknown(devices)
                return state.merge({
                    [getDevicesKey(blocked, forgotten, false)]: filteredDevices.devices,
                    [getDevicesKey(blocked, forgotten, true)]: filteredDevices.devicesUnknown,
                    [getIsFetchingKey(blocked, forgotten)]: false
                })
            } else {
                return state.merge({
                    [getDevicesKey(blocked, forgotten)]: devices,
                    [getIsFetchingKey(blocked, forgotten)]: false
                })
            }
        }

        case FETCH_DEVICES_FAILURE: {
            const {payload: {error, blocked, forgotten}} = action
            return state.mergeDeep({
                errors: {
                    [getDevicesKey(blocked, forgotten, true)]: error,
                    [getDevicesKey(blocked, forgotten, false)]: error
                },
                [getIsFetchingKey(blocked, forgotten)]: false
            })
        }

        case FETCH_SESSIONS_BY_DEVICE_IN_PROGRESS: {
            const {payload: {deviceId}} = action
            return state.setIn(['isFetchingSession', deviceId], true).removeIn(['errors', 'sessions', deviceId]);
        }
        case FETCH_SESSIONS_BY_DEVICE_SUCCESS: {
            const {payload: {sessions, deviceId}} = action
            return state.setIn(['isFetchingSession', deviceId], false)
                .setIn(['sessions', deviceId], sessions)
        }
        case FETCH_SESSIONS_BY_DEVICE_FAILURE: {
            const {payload: {deviceId, error}} = action
            return state.setIn(['isFetchingSession', deviceId], false)
                .setIn(['errors', 'sessions', deviceId], error)
        }
        case FETCH_DEVICES_CLEAR_SESSIONS: {
            const {payload: {deviceId}} = action
            return state.removeIn(['sessions', deviceId])
        }
        case FETCH_DEVICE_ACTION_IN_PROGRESS: {
            const {payload: {deviceId}} = action
            return state.setIn(['isFetchingDeviceAction', deviceId], true)
                .removeIn(['errors', 'deviceAction', deviceId]);
        }
        case FETCH_DEVICE_ACTION_SUCCESS: {
            const {payload: {deviceId}} = action
            return state.setIn(['isFetchingDeviceAction', deviceId], false)
        }
        case MODIFY_DEVICE_ALIAS_SUCCESS: {
            const {payload: {deviceId}} = action
            return state.setIn(['isFetchingDeviceAction', deviceId], false)
        }
        case MODIFY_DEVICE_ALIAS_ERROR: {
            const {payload: {deviceId, error}} = action
            return state.setIn(['isFetchingDeviceAction', deviceId], false)
                .setIn(['errors', 'deviceAction', deviceId], error);
        }
        case FETCH_DEVICE_ACTION_ERROR: {
            const {payload: {deviceId, error}} = action
            return state.setIn(['isFetchingDeviceAction', deviceId], false)
                .setIn(['errors', 'deviceAction', deviceId], error);
        }
        case FETCH_SESSION_ACTION_IN_PROGRESS: {
            const {payload: {sessionId}} = action
            return state.setIn(['isFetchingSessionAction', sessionId], true)
                .removeIn(['errors', 'sessionsAction', sessionId]);
        }
        case FETCH_SESSION_ACTION_SUCCESS: {
            const {payload: {sessionId}} = action
            return state.setIn(['isFetchingSessionAction', sessionId], false)
        }
        case FETCH_SESSION_ACTION_ERROR: {
            const {payload: {sessionId, error}} = action
            return state.setIn(['isFetchingSessionAction', sessionId], false)
                .setIn(['errors', 'sessionsAction', sessionId], error);
        }
        case FETCH_DEVICE_ENROLMENT_INFORMATION: {
            const {payload: {device}} = action;
            return state.setIn(['trust', 'device'], Immutable.fromJS(device))
        }
        case FETCH_DEVICE_ENROLMENT_IN_PROGRESS: {
            const {payload: {isFetching}} = action;
            return state.setIn(['trust', 'isFetching'], isFetching)
                .setIn(['trust', 'success'], false)
                .setIn(['trust', 'error'],  Immutable.fromJS({}))
        }
        case FETCH_DEVICE_ENROLMENT_ERROR: {
            const {payload: {error}} = action;
            return state.setIn(['trust', 'error'], Immutable.fromJS(error))
                .setIn(['trust', 'success'], false)
                .setIn(['trust', 'isFetching'], false);
        }
        case FETCH_DEVICE_ENROLMENT_SUCCESS: {
            const {payload: {success}} = action;
            return state.setIn(['trust', 'success'], success)
                .setIn(['trust', 'error'],  Immutable.fromJS({}))
                .setIn(['trust', 'isFetching'], false);
        }
        case FETCH_DEVICE_ENROLMENT_STATE: {
            const {payload: {enrol}} = action;
            return state.setIn(['trust', 'enrol'], enrol);
        }
        case FETCH_DEVICE_ENROLMENT_DATA_TOKEN: {
            const {payload: {dataToken}} = action;
            return state.setIn(['trust', 'dataToken'], dataToken);
        }
        case FETCH_AGENT_PROFILE_VALIDATE_SUCCESS: {
            const { payload: { authorized } } = action;
            return state.set('isAgentProfileAuthorized', authorized);
        }
        default:
            return state
    }
}

module.exports = devices;
