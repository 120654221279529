// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: '',
    success: false
});

function creditsCancelPregrantedReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CREDITS_CANCEL_PREGRANTED_REQUEST:
            return state.merge(
                {
                    isFetching: true
                }
            );
        case actionTypes.CREDITS_CANCEL_PREGRANTED_SUCCESS:
            return state.merge(
                {
                    isFetching: false,
                    success: true
                }
            );
        case actionTypes.CREDITS_CANCEL_PREGRANTED_FAILURE:
            return state.merge(
                {
                    isFetching: false,
                    error: action.payload.error
                }
            );
        case actionTypes.CREDITS_CANCEL_PREGRANTED_CLEAR:
            return initialState;
        default:
            return state
    }
}

module.exports = creditsCancelPregrantedReducer;
