// @ vendors
const Immutable = require('immutable');
const { each } = require('lodash');

// @ constants
const actionTypes = require('constants/actionTypes');
// @ commons
const { formatText } = require('core/i18n').i18n;

const SELECT_OPTIONS = {
    REQUEST: 'request',
    UPLOAD: 'upload'
};

const initialState = Immutable.fromJS({
    error: '',
    list: [],
    isFetching: false,
    hasBeenFetched: false,
    estateDeleted: false,
    showModal: false,
    estatesState: {},
});

const handleSetEstatesState = (state, action) => {
    const { estates } = action.data;
    const newEstatesState = estates.reduce((previousValue, current) => ({
        ...previousValue,
        [current.get('bienId')]: { value: SELECT_OPTIONS.REQUEST, label: formatText('mortgageRequestSimpleNote-request') }
    }), {});

    return state.merge({
        estatesState: newEstatesState
    });
};

const setMultiplesEstatesState = (state, action) => {

    let currentEstatesState = state.get('estatesState').toJS();
    each(action.data.estates, (value, key) => {
        const estateState = {
            value,
            label: value === SELECT_OPTIONS.REQUEST
                ? formatText('mortgageRequestSimpleNote-request')
                : formatText('mortgageRequestSimpleNote-upload')
        }

        currentEstatesState[key] = Immutable.Map(estateState);
    });
    const newState = state.set('estatesState',  Immutable.Map(currentEstatesState));
    return newState;

}

const mortgageRequestInmuebles = (state = initialState, action) => {
    let index, isFetching, partialMergedState, fetchingPopulationsCounter, fetchingRegistersCounter;
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_ESTATES_DELETE_SHOW_MODAL:
            return state.set('showModal', action.data.showModal);

        case actionTypes.MORTGAGE_REQUEST_ESTATES_DELETE_SUCCESS:
            return state.merge({
                estateDeleted: true,
                isFetching: false,
                showModal: false,
                list: state.get('list')
                    .filter(estate => estate.get('id') !== action.data.propertyId)
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_DELETE_RESET:
            return state.merge({
                estateDeleted: false,
                showModal: false
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_UPDATE_SUCCESS:

            const newList = state.get('list').map(estate => {

                if (estate.get('id') === action.data.estate.get('id')) {
                    estate = action.data.estate;
                }

                return estate;
            });

            return state.merge({
                list: newList,
                estateDeleted: true,
                isFetching: false,
                showModal: false
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_UPDATE_REQUEST:
        case actionTypes.MORTGAGE_REQUEST_ESTATES_DELETE_REQUEST:
            return state.set('isFetching', true);

        case actionTypes.MORTGAGE_REQUEST_FETCH_INMUEBLES_REQUEST:
            return state.merge({
                isFetching: true,
                hasBeenFetched: false
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_INMUEBLES_SUCCESS:
            const { list } = action.data;
            isFetching = action.data.isFetching;

            return state.merge({
                isFetching: isFetching,
                hasBeenFetched: !isFetching,
                list,
                error: false
            });

        case actionTypes.MORTGAGE_REQUEST_ESTATES_UPDATE_FAILURE:
        case actionTypes.MORTGAGE_REQUEST_ESTATES_DELETE_FAILURE:
        case actionTypes.MORTGAGE_REQUEST_FETCH_INMUEBLES_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_INMUEBLES_RESET:
            return initialState.merge();

        case actionTypes.MORTGAGE_REQUEST_SET_ESTATES_WITH_PROVINCES:
            return state.set('list', Immutable.fromJS(action.data));

        case actionTypes.MORTGAGE_REQUEST_SET_ESTATE_POPULATION:
            const populationData = action.data.populationData;
            const populationList = action.data.populationList;
            return state.mergeDeepIn(['list', action.data.idx], { populationData, populationList });

        case actionTypes.MORTGAGE_REQUEST_SET_ESTATE_REGISTER:
            const registerData = action.data.registerData;
            const registerList = action.data.registerList;
            return state.mergeDeepIn(['list', action.data.idx], { registerData, registerList });
        case actionTypes.MORTGAGE_REQUEST_ESTATES_REQUESTS_POPULATIONS:
            fetchingPopulationsCounter = action.data.fetchingPopulationsCounter;
            return state.merge({
                isFetchingPopulations: true,
                fetchingPopulationsCounter
            });
        case actionTypes.MORTGAGE_REQUEST_ESTATES_REQUEST_REGISTERS:
            fetchingRegistersCounter = action.data.fetchingRegistersCounter;
            return state.merge({
                isFetchingRegisters: true,
                fetchingRegistersCounter
            });
        case actionTypes.MORTGAGE_REQUEST_ESTATES_SET_PROVINCE_NAME:
            const { provinceName } = action.data;
            index = action.data.index;
            const estatesCount = state.get('list').size;
            fetchingPopulationsCounter = state.get('fetchingPopulationsCounter');
            fetchingRegistersCounter = state.get('fetchingRegistersCounter');
            isFetching = index + 1 < estatesCount || fetchingPopulationsCounter > 0 || fetchingRegistersCounter > 0;
            partialMergedState = state.mergeDeepIn(['list', index], { provinceName });
            return partialMergedState.merge({
                isFetching,
                hasBeenFetched: !isFetching
            });
        case actionTypes.MORTGAGE_REQUEST_ESTATES_SET_POPULATION_NAME:
            const { populationName } = action.data;
            index = action.data.index;
            fetchingPopulationsCounter = state.get('fetchingPopulationsCounter') - 1;
            fetchingRegistersCounter = state.get('fetchingRegistersCounter');
            isFetching = fetchingPopulationsCounter > 0 || fetchingRegistersCounter > 0;
            partialMergedState = state.mergeDeepIn(['list', index], { populationName });
            return partialMergedState.merge({
                isFetching,
                fetchingPopulationsCounter,
                hasBeenFetched: !isFetching
            });
        case actionTypes.MORTGAGE_REQUEST_ESTATES_SET_REGISTER_NAME:
            const { registerName } = action.data;
            index = action.data.index;
            fetchingRegistersCounter = state.get('fetchingRegistersCounter') - 1;
            fetchingPopulationsCounter = state.get('fetchingPopulationsCounter');
            isFetching = fetchingPopulationsCounter > 0 || fetchingRegistersCounter > 0;
            partialMergedState = state.mergeDeepIn(['list', index], { registerName });
            return partialMergedState.merge({
                isFetching,
                fetchingRegistersCounter,
                hasBeenFetched: !isFetching
            });

        case actionTypes.MORTGAGE_REQUEST_SET_ESTATE_STATE:
            const { id, state : estateState } = action.data;
            const currentEstatesState = state.get('estatesState');
            const newEstateState = currentEstatesState.set(`${id}`, Immutable.Map(estateState));
            const newState = state.set('estatesState', newEstateState);
            return newState;

        case actionTypes.MORTGAGE_REQUEST_SET_MULTIPLE_ESTATE_STATE:
            return setMultiplesEstatesState(state, action);

        case actionTypes.MORTGAGE_REQUEST_CLEAR_ESTATES_STATE:
            return state.set('estatesState', Immutable.Map({}));

        case actionTypes.MORTGAGE_REQUEST_SET_ESTATES_STATE:
            return handleSetEstatesState(state, action);

        default:
            return state;
    }
};

module.exports = mortgageRequestInmuebles;
