const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
// @ constants
const { PLACEHOLDER_MANAGER }  = require('constants/links');

const initialState = Immutable.fromJS({
    id: '',
    img: PLACEHOLDER_MANAGER,
    name: '',
    lastName: '',
    email: '',
    phone: '',
    cellphone: '',
    foto: '',
    horario: ''
});

function mortgageRequestManagerInfo(state = initialState, action) {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_MANAGER_INFO:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_MANAGER_INFO_SUCCESS:
            const gestor = action.data.data;
            let lastName = gestor.primerApellido || '';
            if ( gestor.segundoApellido ){
               lastName += ' '+ gestor.segundoApellido;
            }
            return state.merge({
                id: gestor.id,
                name : gestor.nombre,
                lastName : lastName,
                email : gestor.email,
                phone : gestor.telefono,
                foto: gestor.foto,
                cellphone : gestor.whatsApp,
                img : gestor.avatar || PLACEHOLDER_MANAGER,
                horario: gestor.horario,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_MANAGER_INFO_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state;
    }
}

module.exports = mortgageRequestManagerInfo;
