//@vendors
const Immutable = require('immutable');

//@helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');
const cardMobileRechargeStep1 = require('./cardMobileRecharge/cardMobileRechargeStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            cardMobileRechargeStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        willCancel: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardMobileRechargeStep1(step, action);
        }
    })
}

function cardMobileRechargeReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_MOBILE_RECHARGE_SETUP_STEP:
        case actionTypes.CARD_MOBILE_RECHARGE_OPERATOR_CHANGED:
        case actionTypes.CARD_MOBILE_RECHARGE_AMOUNT_CHANGED:
        case actionTypes.CARD_MOBILE_RECHARGE_MOBILE_PHONE_CHANGED:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

            return state.merge({
                steps: partialSteps
            });
        case actionTypes.CARD_MOBILE_RECHARGE_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_MOBILE_RECHARGE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_MOBILE_RECHARGE_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.SET_CARD_MOBILE_RECHARGE_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.SET_CARD_MOBILE_RECHARGE_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            })
        case actionTypes.SET_CARD_MOBILE_RECHARGE_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            })
        case actionTypes.CARD_MOBILE_RECHARGE_RESET_VIEW:
            return setInitialState();
        default:
            return state
    }
}

module.exports = cardMobileRechargeReducer;
