// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    BROKER_ORDER_TYPE_TO_MARKET,
    BROKER_ORDER_TYPE_LIMITED,
    BROKER_NO_STOCKS_CONTRACT_SELECTED
} = require('constants/index');
const DateHelper = require('utilities/dateHelper');

function setInitialState() {
    const firstDate = DateHelper.getNextBussinessDay(true, false);
    const limitDate = DateHelper.getStockLimitDate(firstDate);
    const excludeDates = DateHelper.getExcludedWeekends(limitDate);
    const validityDate = firstDate.clone();

    return Immutable.fromJS({
        selectedOptionValue: -1,
        selectedOptionTitles: 0,
        orderTypeOption: BROKER_ORDER_TYPE_TO_MARKET,
        orderTypeValue: 0,
        sellPrice: 1,
        titleQuantity: '',
        firstDate,
        validityDate,
        limitDate,
        excludeDates,
        errorFlags: {
            stocksContract: false,
            minorThanLote: false,
            notMultiploLote: false,
        },
        valid: false,
        titleChangedManually: false
    });
}

function validateStep(step, catalogData) {
    const titleQuantityValid = step.get('titleQuantity') != '0' && step.get('titleQuantity') != '';
    const titleQuantityLimitValid = step.get('titleQuantity') <= step.get('selectedOptionTitles');
    const isStocksContractEmpty = step.get('selectedOptionValue') === BROKER_NO_STOCKS_CONTRACT_SELECTED;

    let isSellByLotError = false;
    let minorThanLote = false;
    let notMultiploLote = false;
    const operatedByLot = catalogData.get('operatedByLot');
    if (operatedByLot) {
        minorThanLote = step.get('titleQuantity') < catalogData.get('lot');
        notMultiploLote = !(step.get('titleQuantity') % catalogData.get('lot') == 0);
        isSellByLotError = (
            minorThanLote || notMultiploLote
        );
    }
    let valid = (!isStocksContractEmpty) && titleQuantityValid && titleQuantityLimitValid && (!isSellByLotError);
    let orderTypeValueValid = true;

    if (step.get('orderTypeOption') === BROKER_ORDER_TYPE_LIMITED) {
        orderTypeValueValid = parseFloat(step.get('orderTypeValue')) > 0;
        valid = valid && orderTypeValueValid;
    }

    return step.merge({
        valid,
        titleQuantityValid,
        titleQuantityLimitValid,
        orderTypeValueValid,
        errorFlags: {
            stocksContract: isStocksContractEmpty,
            minorThanLote: minorThanLote,
            notMultiploLote: notMultiploLote
        }
    });
}

function brokerStocksSellStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_SELL_VALIDATE_STEP:
            return validateStep(state, action.payload.catalogData);
        case actionTypes.BROKER_STOCKS_SELL_STOCK_CONTRACT_CHANGED:
            return state.merge({
                selectedOptionValue: action.payload.value,
                selectedOptionTitles: action.payload.titles,
                errorFlags: {
                    stocksContract: false
                }
            });
        case actionTypes.BROKER_STOCKS_SELL_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_SELL_ORDER_TYPE_CHANGED:
            return state.merge({
                orderTypeOption: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_SELL_LIMITED_VALUE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value,
                sellPrice: action.payload.sellPrice
            });
        case actionTypes.BROKER_STOCKS_SELL_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: trim(action.payload.value),
                titleChangedManually: action.payload.manually,
                errorFlags: {
                    minorThanLote: false,
                    notMultiploLote: false
                }
            });
        case actionTypes.BROKER_SELL_CONTRACT_NUMBER:
            return state.merge({
                selectedOptionValue: action.payload.contract
            });
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
            const firstDate = DateHelper.getNextBussinessDay(action.payload.isAvailableToday, action.payload.currentDate);
            const limitDate = DateHelper.getStockLimitDate(firstDate);
            const validityDate = DateHelper.getValidDate(firstDate, limitDate, state.get('validityDate'));
            const excludeDates = DateHelper.getExcludedWeekends(limitDate);

            return state.merge({
                firstDate,
                validityDate,
                limitDate,
                excludeDates
            });
        default:
            return state;
    }
}

module.exports = brokerStocksSellStep1;
