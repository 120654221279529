// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const initialState = Immutable.fromJS({
    documentHasBeenFetched: false,
    documentIsFetching: false,
    isPdfDocumentDownloaded: false,
    documentPDF: {
        base64string: '',
        base64: null,
        contentType: '',
    },
    documentError: '',
    inneHasBeenFetched: false,
    inneIsFetching: false,
    isPdfInneDownloaded: false,
    innePDF: {
        base64string: '',
        base64: null,
        contentType: '',
    },
    inneError: '',
    documentReaded: false,
    precontractualError: '',
    precontractualIsFetching: false,
    precontractualMailSent: false,
    termsAndCondsIsFetching: false,
    termsAndCondsRequestSuccess: false,
    termsAndCondsError: '',
    insuranceUrlIsFetching: false,
    insuranceUrlSuccess: false,
    insuranceUrlError: '',
    insuranceUrl: []
});

function revolvingPDFResponse(state, response){

    return state.merge({
        documentHasBeenFetched: true,
        documentIsFetching: false,
        documentPDF: {
            ...response
        },
        documentError: ''
    });
}

function innePDFResponse(state, { response }){

    return state.merge({
        inneHasBeenFetched: true,
        inneIsFetching: false,
        innePDF: {
            ...response
        },
        inneError: ''
    });
}

function pdfRevolvingReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.REVOLVING_DOCUMENT_REQUEST:
            return state.merge({
                documentIsFetching: true
            });
        case actionTypes.REVOLVING_DOCUMENT_SUCCESS:
            return revolvingPDFResponse(state, action.payload.response);

        case actionTypes.REVOLVING_DOCUMENT_ERROR:
            return state.merge({
                documentError: action.payload.error,
                documentIsFetching: false,
            });
        case actionTypes.REVOLVING_DOCUMENT_DOWNLOAD_SUCCESS:
            return state.merge({
                isPdfDocumentDownloaded: true,
            });
        case actionTypes.REVOLVING_DOCUMENT_DOWNLOAD_ERROR:
            return state.merge({
                isPdfDocumentDownloaded: false,
            });
        case actionTypes.REVOLVING_INNE_REQUEST:
            return state.merge({
                inneIsFetching: true
            });
        case actionTypes.REVOLVING_INNE_SUCCESS:
            return innePDFResponse(state, action.payload);

        case actionTypes.REVOLVING_INNE_ERROR:
            return state.merge({
                inneError: action.payload.error,
                inneIsFetching: false,
            });
        case actionTypes.REVOLVING_INNE_DOWNLOAD_SUCCESS:
            return state.merge({
                isPdfInneDownloaded: true,
            });
        case actionTypes.REVOLVING_INNE_DOWNLOAD_ERROR:
            return state.merge({
                isPdfInneDownloaded: false,
            });
        case actionTypes.REVOLVING_INNE_DOCUMENT_READED:
            return state.merge({
                documentReaded: action.payload.readed
            });
        case actionTypes.REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_REQUEST:
            return state.merge({
                precontractualError: '',
                precontractualIsFetching: true
            });
        case actionTypes.REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_SUCCESS:
            return state.merge({
                precontractualIsFetching:false,
                precontractualMailSent: true
            });
        case actionTypes.REVOLVING_SEND_PRECONTRACTUAL_DOCUMENT_MAIL_ERROR:
            return state.merge({
                precontractualError: action.payload.error,
                precontractualIsFetching: false,
                precontractualMailSent: false
            });
        case actionTypes.REVOLVING_TERMS_AND_CONDS_REQUEST:
            return state.merge({
                termsAndCondsError: '',
                termsAndCondsIsFetching: true,
                termsAndCondsRequestSuccess: false,
            });
        case actionTypes.REVOLVING_TERMS_AND_CONDS_SUCCESS:
            return state.merge({
                termsAndCondsIsFetching: false,
                termsAndCondsRequestSuccess: true,
            });
        case actionTypes.REVOLVING_TERMS_AND_CONDS_FAILURE:
            return state.merge({
                termsAndCondsError: action.payload.error,
                termsAndCondsIsFetching: false,
            });

        case actionTypes.REVOLVING_INSURANCE_URL_FETCHING:
            return state.merge({
                insuranceUrlIsFetching: true,
                insuranceUrlSuccess: false,
                insuranceUrl: []
            });

        case actionTypes.REVOLVING_INSURANCE_URL_SUCCESS:
            return state.merge({
                insuranceUrlIsFetching: false,
                insuranceUrlSuccess: true,
                insuranceUrl: action.payload.response
            });

        case actionTypes.REVOLVING_INSURANCE_URL_ERROR:
            return state.merge({
                insuranceUrlError: action.payload.error,
                insuranceUrlIsFetching: false,
                insuranceUrlSuccess: false,
                insuranceUrl: []
            });

        case actionTypes.REVOLVING_RESET_PROCESS:
            return initialState
        default:
            return state;
    }
}

module.exports = pdfRevolvingReducer;
