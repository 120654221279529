// @ vendors
const { fromJS, List } = require('immutable');
const get = require('lodash/object/get');
// @ constants
const {
    FETCH_ACCOUNT_DEBITS_FAILURE,
    RECEIPTS_FILTER,
    RECEIPTS_FILTER_BY_NAME,
    RECEIPT_GET_PREVIEW_FAILURE,
    RECEIPT_SET_PREVIEW,
    SET_RECEIPTS
} = require('constants/actionTypes');
// @ constants
const {
    EURO_TEXT,
    API_CENTER,
    API_ENTERPRISE_NUMBER
} = require('constants/index');

const initialState = fromJS({})

const receiptNumber = (receipt) => {
    const { codprod, numsor } = receipt;
    const enterprise = get(receipt, 'idempr', '') || API_ENTERPRISE_NUMBER;
    const center = get(receipt, 'idcent.centro', '') || API_CENTER;
    return `${enterprise}${center}${codprod || 0}${numsor}`;
}

const createReceipts = (receiptsList, accountId) => {
    return receiptsList.reduce((accumulator, receipt) => {
        const { codmon, impordv } = receipt;
        return {
            ...accumulator,
            [receiptNumber(receipt)]: {
                ...receipt,
                accountId,
                authorizationId: `${receipt.identemi}-${receipt.refmand}`,
                codprod: receipt.codprod || 0,
                receiptNumber: receiptNumber(receipt),

                amount: {
                    amount: impordv || 0,
                    currency: codmon || EURO_TEXT
                },
            }
        }
    }, {});
}

const filterCheckFilter = (checkFilters, receipts, receiptsIds) => {
    const status = checkFilters.reduce((acc, checkFilter) => {
        return acc.concat(checkFilter.get('value'));
    }, List());
    return receiptsIds.filter(receiptId => {
        return status.indexOf(receipts.get(receiptId).get('cestado')) > -1;
    })
}

const filterName = (name, receipts, receiptsIds) => {
    return receiptsIds.filter( receiptId => {
        return receipts.get(receiptId).get('nombre') == name;
    })
}

const filterDate = (filters, receipts, receiptsIds) => {
    return receiptsIds.filter( receiptId => {
        const from = filters.get('from').format("YYYY-MM-DD");
        const to = filters.get('to').format("YYYY-MM-DD");
        return receipts.get(receiptId).get('fecvenci') >= from && receipts.get(receiptId).get('fecvenci') <= to;
    })
}

const filterReceipts = (receipts, receiptsIds, filters) => {
    let newReceiptsIds = receiptsIds;
    if (filters) {
        const checkFilters = filters.filter(filter => filter.get('filterType') == 'checkFilter' && filter.get('active'));
        if (checkFilters.size) {
            newReceiptsIds = filterCheckFilter(checkFilters, receipts, newReceiptsIds);
        }
        if (filters.get('name') && filters.get('name').get('active')) {
            newReceiptsIds = filterName(filters.get('name').get('selected'), receipts, newReceiptsIds);
        }
        if (filters.get('date') && filters.get('date').get('active')) {
            newReceiptsIds = filterDate(filters.get('date'), receipts, newReceiptsIds);
        }
    }
    return newReceiptsIds;
}

const receipts = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACCOUNT_DEBITS_FAILURE:
            return state.mergeDeep({
                [action.accountId]: {
                    byId: {},
                    byOrder: [],
                    error: action.error,
                    expandedRowId: ''
                }
            });
        case RECEIPTS_FILTER:
            const filteredReceipts = filterReceipts(
                state.getIn([action.accountId, 'byId']),
                state.getIn([action.accountId, 'byOrder']),
                action.immFilters
            );
            return state.setIn([action.accountId, 'filteredIds'], filteredReceipts);
        case RECEIPTS_FILTER_BY_NAME:
            const filteredReceiptsByName = filterName(
                action.name,
                state.getIn([action.accountId, 'byId']),
                state.getIn([action.accountId, 'byOrder'])
            );
            return state.setIn([action.accountId, 'filteredIds'], filteredReceiptsByName);
        case SET_RECEIPTS:
            const receiptsIds = action.receipts.map(receipt => {
                return receiptNumber(receipt);
            })
            return state.mergeDeep({
                [action.accountId]: {
                    byOrder: receiptsIds,
                    byId: createReceipts(action.receipts, action.accountId),
                    filteredIds: receiptsIds
                }
            });
        case RECEIPT_GET_PREVIEW_FAILURE:
            return state.setIn([action.accountId, 'byId', action.receiptId, 'previewError'], action.error);
        case RECEIPT_SET_PREVIEW:
            return state.setIn([action.accountId, 'byId', action.receiptId, 'preview'], action.preview);
        default:
            return state;
    }
};

module.exports = receipts;
