const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_NONE,
    FLOW_TYPE_ONLY_ACCESS,
} = require('utilities/contactCenter/wrapUp/constants')

const {
    USER_DATA_FAILURE,
    USER_PHONE_SUCCESS,
    RAISE_TO_N2_FAILURE,
    RAISE_TO_N2_SUCCESS,
    SECURITY_KIT_CHANGE_START,
} = require('constants/contactCenter/actionTypes')

const {
    FETCH_SECURITY_KIT_CHANGE_FAILURE,
    FETCH_SECURITY_KIT_CHANGE_SUCCESS,
} = require('constants/actionTypes')

const ACTION_CODE_OTP_KEY_SEND = 5168
const ACTION_CODE_SEND_N1_POST = 5169
const ACTION_CODE_SEND_N2_POST = 5170
const ACTION_CODE_GAP_ACCESS = 5171
const ACTION_CODE_SECURITY_KIT = 5220
const ACTION_CODE_SECURITY_KIT_CHANGE = 5173
const ACTION_CODE_HIRE_PROMOTION = 5174

module.exports = {
    [ACTION_CODE_OTP_KEY_SEND]: {
        entry: {
            path: '/keys/send',
            failure: [
                { action: USER_DATA_FAILURE }
            ],
            success: [
                { action: USER_PHONE_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SEND_N1_POST]: {
        entry: {
            path: '/keys/send-n1-post'
        }
    },
    [ACTION_CODE_SEND_N2_POST]: {
        entry: {
            path: '/keys/send-n2-post'
        }
    },
    [ACTION_CODE_HIRE_PROMOTION]: {
        entry: {
            path: '/myprofile/promotions/hirePromotion'
        }
    },
    [ACTION_CODE_GAP_ACCESS]: {
        entry: {
                path: '/gap-access',
            },
        finish: {
            action: [ RAISE_TO_N2_SUCCESS , RAISE_TO_N2_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_NONE;

                if (action.type === RAISE_TO_N2_SUCCESS) {
                    operationState = OPERATION_LOG_DONE;
                }

                return {
                    actionCode: ACTION_CODE_GAP_ACCESS,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_SECURITY_KIT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/keys/securitykit',
            failure: [
                { action: FETCH_SECURITY_KIT_CHANGE_FAILURE }
            ],
            success: [
                { action: FETCH_SECURITY_KIT_CHANGE_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SECURITY_KIT_CHANGE]: {
        entry: {
            action: [ SECURITY_KIT_CHANGE_START ]
        }
    }
}
