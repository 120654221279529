// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isPlayButtonVisible: true,
    isReplayButtonVisible: false,
    hasUserSetMenu: false,
    isModalVisible: false
});

const closeModal = () => INITIAL_STATE;

const displayModalIfNecessary = (immState) => {
    const hasUserSetMenu = immState.get('hasUserSetMenu');
    return immState.set('isModalVisible', !hasUserSetMenu);
};

const updateUserSetMenu = (immState, hasUserSetMenu) => {
    return immState.set('hasUserSetMenu', hasUserSetMenu);
};

const playVideo = (immState) => immState.merge({
    isPlayButtonVisible: false,
    isReplayButtonVisible: false
});

const finishVideo = (immState, privateMenuType) => immState.merge({
    isPlayButtonVisible: false,
    isReplayButtonVisible: true,
    privateMenuType
});

function brokerSpinOffModalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.BROKER_SPIN_OFF_MODAL_FINISHED_VIDEO:
            return finishVideo(state, action.payload.privateMenuType);
        case actionTypes.BROKER_SPIN_OFF_MODAL_PLAY_VIDEO:
            return playVideo(state);
        case actionTypes.BROKER_SPIN_OFF_MODAL_CLOSE_MODAL:
            return closeModal();
        case actionTypes.BROKER_SPIN_OFF_MODAL_BEFORE_ENTER_BROKER:
            return displayModalIfNecessary(state);
        case actionTypes.BROKER_SPIN_OFF_MODAL_HAS_USER_SET_MENU:
            return updateUserSetMenu(state, get(action, 'payload.hasUserSetMenu'));
        default:
            return state;
    }
}

module.exports = brokerSpinOffModalReducer;
