const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const {
    EURO_SYMBOL,
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED,
    WITHOUT_NEW_MONEY_DEPOSIT_URL
} = require('constants/index');

module.exports = {
    title: 'contractsView-depositNMStep2Title',
    order: 2,
    footer_text: '',
    disableNext: 'depositnm_nextButtonDisabled',
    position: 'middle',
    components:
        [
            {
                id_component: 'dropdown-deposits',
                target: 'installment',
                label: 'contractsView-depositNMdepositsDropdownLabel',
                placeholder: 'contractsView-depositNMdepositsDropdownPlaceholder',
                required: true,
                refreshFunction: 'refreshNewMoneyDepositHireInformationDepositDropdown',
                extraClasses: ['col-md-12', 'contract-view__wizard-field-no-left-padding'],
            },
            {
                id_component: 'dropdown-accounts',
                label: 'contractsView-depositNMStep2Account',
                options: [],
                type: 'single',
                target: 'cta_seleccionada',
                targetIntervener: 'intervinientes_list',
                intervenersSpecial: true,
                isIntervenerAvailable: true,
                renderInterveners: 'depositnm_shouldBuild',
                isMaxAmountLabelAvailable: false,
                intervenersHeader: 'contractsView-depositNMIntervenersHeader',
                maxAmountValue: 0,
                required: true,
                errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_NM,
                accountChangeExtraFn: 'refreshNewMoneyDepositHireInformation',
                intervenersHierarchicalTypeSelection: true,
                intervenerOptions: [
                    INTERVENER_LABEL_HOLDER,
                    INTERVENER_LABEL_AUTHORIZED,
                    INTERVENER_LABEL_EMPOWERED
                ],
                showIntervenerControls: true,
                restoreDefaultIntervenersEnabled: true,
                buildCondition: 'depositnm_dropdwonAccountShouldBuild',
                showSavingsAccountsMessage: true,
            },
            {
                id_component: 'import-amount-input',
                id: 'import-input',
                label: 'contractsView-depositNMStep2Amount',
                subLabel: 'contractsView-depositNMStep2AmountInputSubLabel',
                type: 'form',
                target: 'monto_traspasar',
                onChangeFn: 'depositnm_onChangeAmount',
                importMin: 0,
                importMax: 0,
                inputType: 'float',
                amountZeroAlertVisible: false,
                source: 'depositnm_loadAmountInputAmounts',
                subLabelBuildCondition: 'depositnm_shouldInputSubLabelBuild',
                defaultValue: 1,
                order: 1,
                infoMessageComplete: 'contractsView-depositNMStep2ImportInputComplete',
                infoMessageMinimum: 'contractsView-depositNMStep2ImportInputMin',
                format_type: EURO_SYMBOL,
                buildCondition: 'depositnm_shouldBuild',
            },
            {
                id_component: 'interest-band'
            },
            {
                id_component: 'validation-modal',
                id: 'validationModal',
                title: 'depositnm_FinalModalTitle',
                titleType: 'calculated',
                content: 'depositnm_FinalModalChildren',
                allowContinue: true,
                open: 'depositnm_showFinalModal',
                onMoldalStateChange: 'depositnm_finalModalStateChange',
                showCloseButton: false,
                leaveButtonText: 'contractsView-contractsView_depositnmBackButtonModal'
            },
            {
                id_component: 'validation-modal',
                id: 'accountModal',
                title: 'contractsView-contractsView_depositnmStep2InitialModalTitle',
                content: 'depositnm_InitialModalChildren',
                customCloseButton: 'depositnm_customCloseButton',
                allowContinue: true,
                cancelRoute: WITHOUT_NEW_MONEY_DEPOSIT_URL,
                showCloseButton: false,
                onMoldalStateChange: 'depositnm_initialModalStateChange',
                leaveButtonText: 'contractsView-contractsView_depositnmStep2InitialModalExitLabel',
                primaryButtonTextAllowContinue: 'contractsView-contractsView_depositnmModalNoBalancePrimaryButton',
            },
            {
                id_component: 'validation-modal',
                id: 'withoutNewBalanceModal',
                title: 'contractsView-contractsView_depositnmStep2WithoutNewBalanceModalTitle',
                content: 'depositnm_WithoutNewBalanceModalChildren',
                customCloseButton: 'depositnm_customCloseButton',
                allowContinue: false,
                showCloseButton: false,
                onMoldalStateChange: 'depositnm_initialModalStateChange',
                primaryButtonTextAllowContinue: 'contractsView-contractsView_depositnmStep2WithoutNewBalanceModalPrimaryButton',
            },
            {
                id_component: 'information-modal',
                id: 'moreInfoModal',
                content: 'depositnm_moreInfoModalChildren',
                contentType: 'complex',
                buttonText: 'contractsView-depositNMMoreInfoModalButton',
                openModal: false,
                footerType: 'navigation',
                onMoldalStateChange: 'depositnm_moreInfoModalStateChange',

            },
            {
                id_component: 'disclaimer-personal-info',
                messageKey: 'depositNMMsg',
                linkTextKey: 'depositNMLinkText',
                linkUrlKey: 'depositNMLinkUrl'
            }
        ]
};
