import { call, put, takeLatest } from 'redux-saga/effects';
import {
    channelsGetFailed as getFailed,
    channelsGetSucceeded as getSucceeded,
} from 'actions/cardControl';
import { error as errorAction } from 'actions/notifications';
import { CARD_CHANNELS_GET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { APIGet } from 'utilities/APIRequestHelper';
import mapChannels from './mapChannels';


function* getChannels({ payload: { cardId, pan } }) {
    try {
        const requestParams = {
            dispatch: null,
            endpoint: 'cardEnrollAndGet',
            url: apiURLBuilder.getURL('cardEnrollAndGet', { pan }),
        };
        const { paymentChannels: channels } = yield call(APIGet, null, apiURLBuilder.getURL('cardEnrollAndGet', { pan }), requestParams);
        yield put(getSucceeded({ cardId, channels: mapChannels(channels) }));
    } catch ({ message }) {
        yield put([
            getFailed({ message }),
            errorAction({ message }, { voice: message }),
        ]);
    }
}


function* channelsGetSaga() {
    yield [
        takeLatest(CARD_CHANNELS_GET_REQUESTED, getChannels),
    ];
}


export default [
    channelsGetSaga,
];
export { getChannels, channelsGetSaga };
