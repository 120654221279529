const brokerMarketsTags = (eventTag, eventAction) => (
    {
        keepReadingLink: {
            categoriaEvento: 'newsDescriptionInteraction',
            accionEvento: `new ${eventAction}`,
            etiquetaEvento: 'keep reading'
        },
        seeMoreNewsLink: {
            categoriaEvento: 'newsDescriptionInteraction',
            accionEvento: 'new',
            etiquetaEvento: 'see all'
        },
        keepReadingMarkets: {
            categoriaEvento: 'newsDescriptionInteraction',
            accionEvento: `markets and finance ${eventAction}`,
            etiquetaEvento: 'keep reading'
        },
        seeMoreMarketsNews: {
            categoriaEvento: 'newsDescriptionInteraction',
            accionEvento: 'markets and finance',
            etiquetaEvento: 'see all'
        },
        indexTooltip: {
            categoriaEvento: 'indexDescriptionInteraction',
            accionEvento: 'tooltip',
            etiquetaEvento: 'open'
        },
        indexGraphToggle: {
            categoriaEvento: 'indexDescriptionInteraction',
            accionEvento: eventAction,
            etiquetaEvento: eventTag
        },
        indexSeeMore: {
            categoriaEvento: 'indexDescriptionInteraction',
            accionEvento: 'see more products',
            etiquetaEvento: 'open'
        }
    })

module.exports = { brokerMarketsTags }