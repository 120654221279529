// @vendors
const React = require('react');
const { Iterable } = require('immutable');
const moment = require('moment');

// @commons
const { OKLink } = require('commonsComponents/OKLink');

// @core
const { formatText, FormattedText } = require('core/i18n').i18n;

// @utilities
const formHelper = require('../utilities/formHelper');
const formValidationHelper = require('../utilities/formValidationHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
const mockDataHelper = require('../utilities/mockDataHelper');

// @constants
const { countryOptions } = require('constants/countries');
const { sortedTypesOfRoadsOptions } = require('constants/typeOfRoads');
const provincesOptions = require('constants/provinces');
const {
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    DEFAULT_COUNTRY,
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIF
} = require('constants/index');


let cardOwnersInfo = [];

function juniorAccount_onChangeCheckBox(cardDetails, component, event) {
    cardDetails.find(detail => detail.id === component.target).value = event.target.checked;
    return cardDetails;
}

// ____________________ Form Elements handlers __________________
function juniorAccount_handleBlur(sourceEvent, component, state) {
    return formHelper.handleFormElementData(sourceEvent, component, state);
}

function juniorAccount_handleDatePickerCallback(sourceEvent, component, state) {
    sourceEvent = formHelper.buildDatePickerTargetObject(sourceEvent, component);
    return formHelper.handleFormElementData(sourceEvent, component, state);
}

function juniorAccount_setSuccessStepPrefix(contractWizard,immProfileFields){
    let successStepPrefix = {};
    if (formHelper.haveEmail(immProfileFields)) {
        successStepPrefix.email = 'successWithEmail_';
    }
    return successStepPrefix;
}
// _______________________________________________________________

//Para propositos de poblar y probar evento change

function juniorAccount_loadCmbRelationship() {
    return [{
        value: 'Padre',
        label: formatText('contractsView-father')
    }, {
        value: 'Madre',
        label: formatText('contractsView-mother')
    }, {
        value: 'Tutor Legal',
        label: formatText('contractsView-guardian')
    }];
}

function juniorAccount_loadCmbTaxReason() {
    return [
        {
            value: 'R',
            label: formatText('contractsView-taxReasonResidence')
        },
        {
            value: 'U',
            label: formatText('contractsView-taxReasonCitizenship')
        },
        {
            value: 'X',
            label: formatText('contractsView-taxReasonBoth')
        },
    ];
}

function juniorAccount_loadCmbDocumentType() {
    return [
        {
            value: DOCUMENT_TYPE_NIF,
            label: formatText('contractsView-documentTypeNIF')
        },
        {
            value: DOCUMENT_TYPE_NIE,
            label: formatText('contractsView-documentTypeNIE')
        }
    ];
}

function juniorAccount_loadCmbCountries() {
    return countryOptions();
}

function juniorAccount_loadTypeOfRoad() {
    return sortedTypesOfRoadsOptions();
}

function juniorAccount_loadCmbProvince() {
    return mockDataHelper.sortedElements(provincesOptions);
}

function juniorAccount_getCardOwners() {
    return cardOwnersInfo;
}

function juniorAccount_hasCardOwners() {
    return juniorAccount_getCardOwners().length > 0;
}

function juniorAccount_doesntHaveCardOwners() {
    return !juniorAccount_hasCardOwners();
}

function juniorAccount_showBeneficiaryDataForms(immContractWizard) {
    let showForms = false;
    const immCardDetails = immContractWizard.get('card_details');

    if (!!immCardDetails) {
        const cardOwner = findElementbyId(immCardDetails, 'juniorAccount_cardOwner');
        const isTutor = findElementbyId(immCardDetails, 'checkbox-tutor');
        const doesntHaveCardOwners = juniorAccount_doesntHaveCardOwners();

        showForms = (doesntHaveCardOwners && isTutor) || !['loading', '0'].includes(cardOwner);
    }

    return showForms;
}

function juniorAccount_showCheckboxTutor(immContractWizard) {
    return juniorAccount_doesntHaveCardOwners() || juniorAccount_showBeneficiaryDataForms(immContractWizard);
}

function juniorAccount_disableSecondStepNextButton(immContractWizard) {
    return !juniorAccount_showBeneficiaryDataForms(immContractWizard);
}

function juniorAccount_updateContactInfoForm(state, immInfo) {
    let disableFields = false;

    let addressOthers = '';
    let country = '';
    let population = '';
    let phone = '';
    let postalCode = '';
    let province = '';
    let streetName = '';
    let streetNumber = '';
    let streetType = 'CL';

    if (!!immInfo) {
        const immContactInfo = immInfo.get('contactInfo');
        disableFields = true;
        addressOthers = immContactInfo.get('addressOthers');
        country = immContactInfo.get('country');
        phone = immContactInfo.get('phone');
        postalCode = immContactInfo.get('postalCode');
        population = immContactInfo.get('population');
        province = immContactInfo.get('province');
        streetName = immContactInfo.get('streetName');
        streetNumber = immContactInfo.get('streetNumber');
        streetType = immContactInfo.get('streetType');
    }

    state = formHelper.buildDisabled(addressOthers, disableFields, state, 'input-safe-otros-datos');
    state = formHelper.buildDisabled(country, disableFields, state, 'cmb_pais');
    state = formHelper.buildDisabled(phone, disableFields, state, 'input-safe-phone-number');
    state = formHelper.buildDisabled(population, disableFields, state, 'input-safe-poblacion');
    state = formHelper.buildDisabled(postalCode, disableFields, state, 'input-safe-codigo-postal');
    state = formHelper.buildDisabled(province, disableFields, state, 'cmb_provincia');
    state = formHelper.buildDisabled(streetName, disableFields, state, 'input-safe-nombre-via');
    state = formHelper.buildDisabled(streetNumber, disableFields, state, 'input-safe-numero');
    state = formHelper.buildDisabled(streetType, disableFields, state, 'cmb_tipo_via');

    return state;
}

function juniorAccount_updatePersonalInfoForm(state, immInfo){
    let disableFields = false;

    let birthCountry = DEFAULT_COUNTRY;
    let birthDate = moment().subtract(18, 'years').add(1, 'days');
    let birthPopulation = '';
    let birthProvince = '';
    let documentNumber = '';
    let documentType = 'N';
    let gender = 1;
    let name = '';
    let nationality = DEFAULT_COUNTRY;
    let lastName = '';
    let secondLastName = '';

    if(!!immInfo) {
        disableFields = true;
        birthCountry = immInfo.get('birthCountry');
        birthDate = immInfo.get('birthDate');
        birthPopulation = immInfo.get('birthPopulation');
        birthProvince = immInfo.get('birthProvince');
        documentNumber = immInfo.get('documentNumber');
        documentType = immInfo.get('documentType');
        gender = immInfo.get('gender');
        name = immInfo.get('name');
        nationality = immInfo.get('nationality');
        lastName = immInfo.get('lastName');
        secondLastName = immInfo.get('secondLastName');
    }

    state = formHelper.buildDisabled(birthCountry, disableFields, state, 'cmb_pais_nacimiento');
    state = formHelper.buildDisabled(birthDate, disableFields, state, 'date-picker-birthday');
    state = formHelper.buildDisabled(birthPopulation, disableFields, state, 'input-birthplace');
    state = formHelper.buildDisabled(birthProvince, disableFields, state, 'cmb_birth_province');
    state = formHelper.buildDisabled(documentNumber, disableFields, state, 'input-number-documents');
    state = formHelper.buildDisabled(documentType, disableFields, state, 'cmb_tipo_documento');
    state = formHelper.buildDisabled(gender, disableFields, state, 'cmb_sexo');
    state = formHelper.buildDisabled(name, disableFields, state, 'input-safe-name');
    state = formHelper.buildDisabled(nationality, disableFields, state, 'cmb_personal_nacionalidad');
    state = formHelper.buildDisabled(lastName, disableFields, state, 'input-safe-last-name');
    state = formHelper.buildDisabled(secondLastName, disableFields, state, 'input-safe-second-last-name');

    return state;
}

function juniorAccount_updateTaxInfoForm(state, immInfo) {
    let disableFields = false;

    let hasOtherCountriesTaxes = 0;
    let countries = [];

    if (!!immInfo) {
        disableFields = true;
        hasOtherCountriesTaxes = immInfo.get('otherCountriesTaxes').size > 0 ? 1 : 0;
        countries = immInfo.get('otherCountriesTaxes').toJS();
    }

    state = formHelper.buildDisabled(hasOtherCountriesTaxes, disableFields, state, 'radio_obligaciones_fiscales');
    state = formHelper.buildDisabled(countries, disableFields, state, 'tax-additionalcountries');

    return state;
}

function juniorAccount_loadCardOwnerInfoInForms(state, immInfo) {
    const component = state.find(component => component.id === 'juniorAccount_cardOwner');
    const selectedValue = state.find(component => component.id === 'junior_form_card_owners').value.find(component => component.id === 'juniorAccount_cardOwner').value;
    const source = formHelper.buildTarget(String(selectedValue), component);
    state = formHelper.saveData(source, component, state);
    state = juniorAccount_updatePersonalInfoForm(state, immInfo);
    state = juniorAccount_updateContactInfoForm(state, immInfo);
    state = juniorAccount_updateTaxInfoForm(state, immInfo);

    return formHelper.handleFormElementData(source, component, state);
}

function juniorAccount_onChangeCardOwner(value, component, state) {
    const source = formHelper.buildTarget('loading', component);
    const checkboxTutorComponent = state.find(component => component.id === 'checkbox-tutor');
    const checkboxTutorSource = formHelper.buildTarget(false, checkboxTutorComponent);

    state = formHelper.saveData(source, component, state);
    state = formHelper.saveData(checkboxTutorSource, checkboxTutorComponent, state);

    return formHelper.handleFormElementData(source, component, state);
}

function juniorAccount_onClickNewBeneficiary(state) {
    const component = state.find(component => component.id === 'juniorAccount_cardOwner');
    const source = formHelper.buildTarget('new', component);
    const checkboxTutorComponent = state.find(component => component.id === 'checkbox-tutor');
    const checkboxTutorSource = formHelper.buildTarget(false, checkboxTutorComponent);

    state = formHelper.saveData(source, component, state);
    state = formHelper.saveData(checkboxTutorSource, checkboxTutorComponent, state);
    state = juniorAccount_updatePersonalInfoForm(state);
    state = juniorAccount_updateContactInfoForm(state);
    state = juniorAccount_updateTaxInfoForm(state);

    return formHelper.handleFormElementData(source, component, state);
}

function juniorAccount_setCardOwners(cardOwners) {
    if(!!cardOwners){
        cardOwnersInfo = cardOwners
            .filter(owner => owner.hasCards && !owner.hasAccounts && owner.isUnder18)
            .map(owner => ({
                id: owner.personCode,
                label: `${owner.name}`,
                noFormatText: true,
            }));
    }

    return cardOwnersInfo;
}

function juniorAccount_validationModalContent () {
    return (
        <div>
            <div className="validation-modal__body">
                <FormattedText value="contractsView-juniorAccountModalBody" />
            </div>
        </div>
    );
}

//TODO: Move to a library file -- utilities folder

function validateStep1(card_details) {
    let ret = {
        card_details: card_details,
        isValidStep: true
    };

    return ret;
}

function validateStep2(card_details) {
    let ret = {
        card_details: card_details,
        isValidStep: true
    };
    let validForms = [];

    //Tax Data Form
    const oFiscales = formHelper.getFieldInTarget(ret.card_details, 'radio_obligaciones_fiscales');
    const additionalCountries = formHelper.getFieldInTarget( ret.card_details, 'tax-additionalcountries');
    let oFiscalesValid = true;

    if (Iterable.isIterable(oFiscales) &&
        oFiscales.get('value') == 1 &&
        Iterable.isIterable(additionalCountries) &&
        additionalCountries.get('value').size < 1) {
            oFiscalesValid = false;
        }
    validForms.push(oFiscalesValid);

    let isFormValid = validForms.every(formValidationHelper.isValidElement);
    ret = {
        card_details: ret.card_details,
        isValidStep: isFormValid
    };

    return ret;
}

function validateStep3(state) {
    let immCardDetails = state.get('card_details');
    let interveners = state.get('interveners');
    const accountId = findElementbyId(immCardDetails, 'cta_seleccionada');
    let isValidAccount = !!accountId;
    immCardDetails = formValidationHelper.updateValue(immCardDetails, 'cta_seleccionada', !isValidAccount);
    if (isValidAccount) {
        const validInterveners = state.get('interveners').map(intervener => intervener.get('relationship') !== null);
        interveners = state.get('interveners').map(intervener => intervener.set('error', intervener.get('relationship') === null));
        isValidAccount = validInterveners.every(formValidationHelper.isValidElement);
    }

    return ({
        interveners,
        card_details: immCardDetails,
        isValidStep: isValidAccount
    });
}

//TODO: Move to a library (validation library for example)

function validateStep4(card_details, confirmationCheckboxes) {
    const updatedCheckboxList = confirmationCheckboxes.get('list').map(
        item => item.set('errorState', item.get('isRequired') && !item.get('value'))
    );

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details,
        isValidStep: confirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function juniorAccount_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails)

        case 2:
            return validateStep2(immCardDetails)

        case 3:
            return validateStep3(state)

        case 4:
            return validateStep4(immCardDetails, immConfirmationCheckboxes)
    }
}

function juniorAccount_SignParams(contractWizard, immAccounts) {

    const card_details = contractWizard.get('card_details');
    const cta_seleccionada = card_details ? card_details.find(x => x.get('id') === 'cta_seleccionada') : null;
    const accountId = cta_seleccionada ? cta_seleccionada.get('value') : null;
    const immAccount = immAccounts.get('byId').get(accountId);

    let params;

    if (immAccount) {
        params = {
            originAccount: API_ENTERPRISE_NUMBER + API_CENTER + immAccount.get('product') + immAccount.get('contractNumber')
        }
    } else {
        params = {}
    }

    return params;
}

function juniorAccount_successInstructions () { // eslint-disable-line
    return (
        <div className="contract-view__instructions">
            <h4 className="contract-view__instructions-title"><FormattedText value="contractSummary-juniorAccountInstructionTitle" /></h4>
            <ul className="contract-view__instructions-list">
                <li className="contract-view__instructions-item"><FormattedText value="contractSummary-juniorAccountInstruction1" /></li>
                <li className="contract-view__instructions-item"><FormattedText value="contractSummary-juniorAccountInstruction2" /></li>
            </ul>
            <p className="contract-view__instructions-detail">
                <FormattedText value="contractSummary-mailInstruction1" />
                <OKLink modifier="emphasize">
                    <FormattedText value="contractSummary-mailInstruction" />
                </OKLink>
                <FormattedText value="contractSummary-mailInstruction2" />
            </p>
            <div className="contract-view__instructions-address">
                <p className="contract-view__instructions-detail contract-view__instructions-detail--openbank">
                    <FormattedText value="contractSummary-openbank" />
                </p>
                <p className="contract-view__instructions-detail">
                    <FormattedText value="contractSummary-postalAddress" />
                </p>
                <p className="contract-view__instructions-detail">
                    <FormattedText value="contractSummary-address" />
                </p>
            </div>
        </div>
    );
}

function juniorAccount_onChangeSelect(selectedValue, component, state) {
    const { addFunction: { value, element } } = component;
    if (selectedValue !== value) {
        state.find(modal => modal.id === element).openModal = true;
    }
    return state;
}

function juniorAccount_modalInfoAction(state) {
    return formHelper.buildDisabled('', false, state, 'cmb_pais');
}

module.exports = { // alphabetically ordered
    juniorAccount_disableSecondStepNextButton,
    juniorAccount_doesntHaveCardOwners,
    juniorAccount_getCardOwners,
    juniorAccount_handleBlur,
    juniorAccount_handleDatePickerCallback,
    juniorAccount_hasCardOwners,
    juniorAccount_loadCardOwnerInfoInForms,
    juniorAccount_loadCmbCountries,
    juniorAccount_loadCmbDocumentType,
    juniorAccount_loadCmbProvince,
    juniorAccount_loadCmbRelationship,
    juniorAccount_loadCmbTaxReason,
    juniorAccount_loadTypeOfRoad,
    juniorAccount_modalInfoAction,
    juniorAccount_onChangeCardOwner,
    juniorAccount_onChangeCheckBox,
    juniorAccount_onChangeSelect,
    juniorAccount_onClickNewBeneficiary,
    juniorAccount_setCardOwners,
    juniorAccount_setSuccessStepPrefix,
    juniorAccount_showBeneficiaryDataForms,
    juniorAccount_showCheckboxTutor,
    juniorAccount_SignParams,
    juniorAccount_successInstructions,
    juniorAccount_validateStep,
    juniorAccount_validationModalContent,
};
