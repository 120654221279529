const step1 = require('./step1');
const step2 = require('./step2');
const successStep = require('./successStep');

// @constants
const {
    EURO_SYMBOL
} = require('constants/index');
const { DEPOSIT_24_MONTHS_RETENTION_ALIAS } = require('constants/hiringProductInformation');
const {
    DEPOSIT_TWENTYFOUR_MONTHS_RETENTION,
    messages: { DEPOSIT_TWENTYFOUR_MONTHS_AND_GDPR }
} = require('constants/operationTypes');

// @helpers
const { DEPOSIT_TWENTYFOUR_MONTHS_RETENTION_GDPR_PID } = require('constants/gdprPlacementIds');
const { DEPOSITS_INTEREST_BAND_COUNTRIES } = require('constants/flagBasedFeatures');

const contract = {
    title: 'contractsView-contractsView_depositTwentyFourMonthsRetentionTitle',
    service: '....',
    last_step: 3,
    showIntervenerControls: true,
    SignParamsFn: 'deposit_twentyFour_months_retention_loan_SignParams',
    gdprPlacementId: DEPOSIT_TWENTYFOUR_MONTHS_RETENTION_GDPR_PID,
    multiOTPMessageCode: DEPOSIT_TWENTYFOUR_MONTHS_AND_GDPR,
    define_steps: 2,
    successStepType: 'dynamic',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractSummary-backToDeposits',
    errorBackButtonLink: '/myprofile/deposits',
    onCancelURL: '/myprofile/deposits',
    hireParamsFn: 'depositTwentyFourMonthsRetention_hireParamsFn',
    hasPrecontractualLink: true,
    biggerLegalTexts: true,
    accountAlias: DEPOSIT_24_MONTHS_RETENTION_ALIAS,
    termsAndCondsKey: DEPOSIT_24_MONTHS_RETENTION_ALIAS,
    warningContractMessage: 'contractsView-contractsView_depositTwentyFourMonthsRetentionWarningMessage',
    operativeName: 'operatives-operatives_depositTwentyFourMonthsRetention',
    code_op: DEPOSIT_TWENTYFOUR_MONTHS_RETENTION,
    breadcrumb: [
        {
            text: 'breadcrumb-deposits',
            url: '/myprofile/deposits'
        },
        {
            text: 'contractsView-newDepositBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            {
                id: 'monto_traspasar',
                label: 'contractsView-contractsView_noNewMoneyDepositAmountToDepositLabel',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 1,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    }
                ]
            },
            {
                id: 'gross_interest',
                label: 'contractsView-grossInterest',
                value: null,
                type: 'single',
                featureFlags: [DEPOSITS_INTEREST_BAND_COUNTRIES],
                format_type: EURO_SYMBOL,
                formatNumber: true,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ]
            },
            {
                type: 'separator',
                order: 4,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    }
                ]
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'balance_account',
                label: 'contractsView-accountBalanceAssociated',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 5,
                visible: [
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    }
                ]
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 2
                },
                order: 6,
                visible: [
                    {
                        step: 1,
                        order: 6
                    },
                    {
                        step: 2,
                        order: 6
                    },
                    {
                        step: 3,
                        order: 6
                    }
                ]
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 3,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'renewDeposit',
                label: '',
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'validationModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'accountModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel1'
                        },
                        {
                            text: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel2',
                            id: 'btn_ContractsView_precontractual',
                            href: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel2URL'
                        },
                        {
                            text: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel3'
                        },
                        {
                            text: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel4',
                            id: 'btn_ContractsView_product',
                            href: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel4URL'
                        },
                        {
                            text: 'contractsView-depositTwentyFourMonthsRetentionServiceContractLabel5'
                        }
                    ]
                }
            ]
        }
    },
    steps: [step1, step2],
    successStep
};

module.exports = { contract };
