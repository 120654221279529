// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    list: []
});

function parseInterveners(interveners) {
    return interveners.map(intervener => {
        return {
            name: trim(intervener.nomInterviniente),
            type: trim(intervener.tipoIntervencion),
            identification: {
                code: get(intervener, 'identifDocumento.codigodedocumento', -1) || -1,
                type: trim(get(intervener, 'identifDocumento.tipodocumentoofic', ''))
            },
            interventionOrder: trim(intervener.ordenIntervencion)
        };
    });
}

function depositIntervenersReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_DEPOSIT_INTERVENERS_REQUEST:
            return state.mergeDeep({
                isFetching: true,
                error: false
            });
        case actionTypes.FETCH_DEPOSIT_INTERVENERS_SUCCESS:
            return state.mergeDeep({
                error: false,
                isFetching: false,
                hasBeenFetched: true,
                list: Immutable.fromJS(parseInterveners(action.payload.intervenersList))
            });
        case actionTypes.FETCH_DEPOSIT_INTERVENERS_FAILURE:
            return state.mergeDeep({
                isFetching: false,
                hasBeenFetched: true,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = depositIntervenersReducer;
