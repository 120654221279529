// @vendor
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP, FIRST_STEP } = require('constants/index');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            steps: [
                {
                    newAsociatedAccountId: null,
                    codbban:null,
                    controlDigit:null,
                    country: null,
                    iban:null
                },
                { hasSignaturePattern: true }
            ],
            visibleStep: WIZARD_FIRST_STEP,
            errorReceived: false,
            errorReceivedMsg: '',
            incompleteInputs: false,
            asociatedAccount: {},
            asociatedAccountFetched: false,
            isFetchingAsociatedAccount: false,
            showCancelModal: false,
            depositId: '',
            submitSuccessful: false,
            submitError: false,
            error: ''
        })
    );
};

function depositChangeAccount(state = setInitialState(), action) {
    const firstStepNewAsociatedAccountId = ['steps', FIRST_STEP, 'newAsociatedAccountId'];
    const firstStepCodbban = ['steps', FIRST_STEP, 'codbban'];
    const firstStepControlDigit = ['steps', FIRST_STEP, 'controlDigit'];
    const firstStepCountry = ['steps', FIRST_STEP, 'country'];
    const firstStepIban = ['steps', FIRST_STEP, 'iban'];
    switch (action.type) {
        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_SET_OPERATIVE:
            return state.merge({
                operative: action.payload.operative,
            });

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_VALIDATE_STEP:
            if (action.payload.step === WIZARD_FIRST_STEP) {
                return state.merge({
                    visibleStep: WIZARD_SECOND_STEP
                });
            } else {
                return state;
            }

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_HANDLE_ERROR:
            return state.merge({
                errorReceived: true,
                errorReceivedMsg:action.payload.errorMsg
            });

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_RESET:
            return setInitialState();

        case actionTypes.FETCH_DEPOSIT_ASOCIATED_ACCOUNT_REQUEST:
            return state.merge({
                isFetchingAsociatedAccount: action.payload.isFetchingAsociatedAccount
            });

        case actionTypes.FETCH_DEPOSIT_ASOCIATED_ACCOUNT_SUCCESS:
            return state.merge({
                asociatedAccount: action.payload.asociatedAccount,
                asociatedAccountFetched: true,
                isFetchingAsociatedAccount: false,
                errorReceived: false,
                errorReceivedMsg: ''
            });

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_SET_ASOCIATED_ACCOUNT_ID:
            return state
                .setIn(firstStepNewAsociatedAccountId, action.payload.newAsociatedAccountId)
                .setIn(firstStepCodbban, action.payload.codbban)
                .setIn(firstStepControlDigit, action.payload.controlDigit)
                .setIn(firstStepCountry, action.payload.country)
                .setIn(firstStepIban, action.payload.iban)
                .merge({
                    errorReceivedMsg:''
                });

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_SHOW_CANCEL_MODAL:
            return state.merge({
                showCancelModal: action.payload.show
            });

        case actionTypes.FETCH_DEPOSIT_ASOCIATED_ACCOUNT_FAILURE:
            return state.merge({
                submitError: true,
                error: action.payload.error
            });

        case actionTypes.DEPOSIT_CHANGE_ACCOUNT_SET_DEPOSIT_ID:
            return state.merge({
                depositId: action.payload.depositId
            });

        case actionTypes.FETCH_DEPOSIT_MODIFY_ASOCIATED_ACCOUNT_SUCCESS:
            return state.merge({
                submitSuccessful: true
            });

        case actionTypes.FETCH_DEPOSIT_MODIFY_ASOCIATED_ACCOUNT_FAILURE:
            return state.merge({
                submitError: true,
                error: action.payload.error
            });

        default:
            return state;
    }
}

module.exports = depositChangeAccount;
