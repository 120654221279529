//@vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const sortedIndex = require('lodash/array/sortedIndex');

//@constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, FIRST_STEP, CARD_MODIFY_DEBIT_LIMITS_TYPE_COMMERCE, CARD_MODIFY_DEBIT_LIMITS_TYPE_EXTRACT } = require('constants/index');
//@reducers
const cardModifyDebitLimitsStep1 = require('./cardModifyDebitLimits/cardModifyDebitLimitsStep1');

const initialState = Immutable.fromJS({
    steps: [
        cardModifyDebitLimitsStep1(undefined, {type: null}),
        {hasSignaturePattern: true}
    ],
    submitSuccessful: false,
    isLoading: false,
    isFetching: false,
    fetchSuccess: false,
    error: '',
    operationType: CARD_MODIFY_DEBIT_LIMITS_TYPE_COMMERCE,
    debitLimits: {
        [CARD_MODIFY_DEBIT_LIMITS_TYPE_COMMERCE]: {
            currency: EURO_TEXT,
            currentLimit: 0,
            limits: [],
        },
        [CARD_MODIFY_DEBIT_LIMITS_TYPE_EXTRACT]: {
            currency: EURO_TEXT,
            currentLimit: 0,
            limits: [],
        }
    },
    visibleStep: 1,
    willCancel: false,
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardModifyDebitLimitsStep1(step, action);
        }
    });
}

function buildLimits(limits, immCurrentLimit) {
    const currentLimit = immCurrentLimit.get('amount').toString();
    if (!limits.includes(currentLimit)) {
        limits.splice(sortedIndex(limits, currentLimit), 0, currentLimit.toString());
    }
    return {
        limits,
        currentLimit: immCurrentLimit.get('amount'),
        currency: immCurrentLimit.get('currency'),
    }
}

function parseLimits(state, response, immCommerceCurrentLimit, immExtractCurrentLimit) {
    const limits = get(response, 'tarjetaList.tarjetaList[0].limitesCreditoList.limitesCreditoList') || [];
    const commerceLimits = limits.slice();
    const extractLimits = limits.slice();

    return state.mergeDeep({
        debitLimits: {
            [CARD_MODIFY_DEBIT_LIMITS_TYPE_COMMERCE]: buildLimits(commerceLimits, immCommerceCurrentLimit),
            [CARD_MODIFY_DEBIT_LIMITS_TYPE_EXTRACT]: buildLimits(extractLimits, immExtractCurrentLimit),
        },
        fetchSuccess: true,
        error: false,
        isFetching: false,
    })
}

function cardModifyDebitLimitsReducer(state = initialState, action) {
    let partialSteps;
    let visibleStep;

    switch (action.type) {
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_FETCH_REQUEST:
            return state.merge({
                error: '',
                fetchSuccess: false,
                isFetching: true,
            });
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_FETCH_SUCCESS:
            return parseLimits(state, action.payload.response, action.payload.commerceCurrentLimit, action.payload.extractCurrentLimit);
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_FETCH_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SUBMIT_REQUEST:
            return state.merge({
                error: '',
                submitSuccessful: false,
                isLoading: true,
            });
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SUBMIT_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isLoading: false
            })
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SUBMIT_FAILURE:
            return state.merge({
                error: action.payload.error,
                isLoading: false,
            });
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SET_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SET_LIMIT:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
            })
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SETUP_STEP:
            return state.merge({
                operationType: action.payload.operationType,
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
            })
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = cardModifyDebitLimitsReducer;
