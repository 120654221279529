// @vendors
const Immutable = require('immutable');
// @ commons
const { formatText } = require('core/i18n').i18n;
// @constants
const actionTypes = require('constants/actionTypes');
// @constants
const {
    TYPE_STOCKS,
    TYPE_ETFS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_PLANS,
    TYPE_OTHERS
} = require('constants/index');

const labels = [
    formatText('broker-myInvestmentsProductTabName'),
    formatText('broker-myInvestmentsContractsTabName')
];

function setInitialState() {
    return Immutable.fromJS({
        waferIsExpanded: {
            [`${TYPE_STOCKS}`]: false,
            [`${TYPE_ETFS}`]: false,
            [`${TYPE_WARRANTS}`]: false,
            [`${TYPE_FUNDS}`]: false,
            [`${TYPE_PLANS}`]: false,
            [`${TYPE_OTHERS}`]: false
        },
        selectedView: {
            id: 0, //0 - view per products, 1 - view per contracts
            idToShow: 1, //0 - view per products, 1 - view per contracts
            label: labels[0],
            labelToShow: labels[1]
        }
    });
}

function setWaferAcordionState(state, waferType) {
    const isExpandedState = state.getIn(['waferIsExpanded', waferType]);
    return state.setIn(['waferIsExpanded', waferType], !isExpandedState)
}

function brokerMyInvestmentWidget(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_MY_INVESTMENTS_SET_ACORDION_EXPANDED:
            return setWaferAcordionState(state, action.payload.data);
        case actionTypes.BROKER_MY_INVESTMENTS_SET_VIEW:
            return state.merge({
                selectedView: {
                    id: action.payload.view,
                    idToShow: state.getIn(['selectedView','id']),
                    label: labels[action.payload.view],
                    labelToShow: labels[state.getIn(['selectedView','id'])],
                }
            });
        default:
            return state;
    }
}

module.exports = brokerMyInvestmentWidget;
