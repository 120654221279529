// @ vendors
const Immutable = require('immutable');
const { set } = require('lodash');

// @ constants
const {
    MORTGAGE_REQUEST_FETCH_DOCUMENTATION_REQUEST,
    MORTGAGE_REQUEST_FETCH_DOCUMENTATION_SUCCESS,
    MORTGAGE_REQUEST_FETCH_DOCUMENTATION_FAILURE,
    MORTGAGE_REQUEST_DOCUMENTATION_RESET,
    MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_INIT,
    MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_SUCCESS,
    MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_FAILURE
} = require('constants/actionTypes');

const { HOLDER_ORDER } = require('constants/mortgageRequestDocumentationCenter');

const initialState = Immutable.fromJS({
    isFetching: {},
    error: {},
    uploadFileButonState: false,
    uploading: {},
    documentOrderNumber: HOLDER_ORDER
});

const documentationCenter = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_DOCUMENTATION_RESET:
            return initialState.merge();

        case MORTGAGE_REQUEST_FETCH_DOCUMENTATION_REQUEST:
            return state.setIn(['isFetching', action.data.fileType], true);

        case MORTGAGE_REQUEST_FETCH_DOCUMENTATION_SUCCESS:
            return state.mergeDeep({
                error: set({}, action.data.fileType, ''),
                isFetching: set({}, action.data.fileType, false)
            });

        case MORTGAGE_REQUEST_FETCH_DOCUMENTATION_FAILURE:
            return state.mergeDeep({
                error: set({}, action.data.fileType, action.data.error),
                isFetching: set({}, action.data.fileType, false)
            });
        case MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_INIT:
            return state.merge({
                uploadFileButonState: true,
                uploading: set({}, action.data.fileType, true),
                documentOrderNumber: action.data.documentOrderNumber
            });
        case MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_SUCCESS:
            return state.merge({
                uploadFileButonState: false,
                error: set({}, action.data.fileType, false),
                uploading: set({}, action.data.fileType, false)
            });
        case MORTGAGE_REQUEST_OK_UPLOAD_BUTTON_FAILURE:
            return state.merge({
                uploadFileButonState: false,
                error: set({}, action.data.fileType, true),
                uploading: set({}, action.data.fileType, false)
            })
        default:
            return state;
    }
};

module.exports = documentationCenter;
