
const brokerPlansTags = (eventTag, eventAction) => (
    {
        brokerPopular: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: `mostPopular - ${eventAction}Plans`,
            etiquetaEvento: eventTag
        },
        searchButton: {
            categoriaEvento: 'buscador/simple',
            accionEvento: 'inicio',
            etiquetaEvento: 'plans'
        },
        buyButton: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: 'buy',
            etiquetaEvento: 'plans'
        },
        buyButtonComCard: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: 'comercialCard',
            etiquetaEvento: 'buy'
        },
        sliderProducts: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'products'
        },
        sliderContracts: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'contracts'
        },
        comercialCardsLink: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: 'comercialCard',
            etiquetaEvento: eventTag
        },
        explorePlans: {
            categoriaEvento: 'plansHomeInteraction',
            accionEvento: 'mostPopularCard',
            etiquetaEvento: 'seeMorePlans'
        }
    }
)
module.exports = { brokerPlansTags }