// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
const brokerWarrantsSellStep1 = require('./brokerWarrantsSell/brokerWarrantsSellStep1');
const brokerWarrantsSellStep2 = require('./brokerWarrantsSell/brokerWarrantsSellStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerWarrantsSellStep1(undefined, {type: null}),
            brokerWarrantsSellStep2(undefined, {type: null})
        ],
        visibleStep: 1,
        submitSuccessful: false,
        isFetching: false,
        mailSent: false,
        error: false,
        inProgress: false,
        willCancel: false,
        sellWarrantsTransactionCostObjectId:'',
        sellWarrantsTransactionCostBase64Data: '',
        documentStore: false,
        exAnteData: [],
        exAnteDataFetching: false,
        estimatedBaseAmount: null,
        estimatedBaseAmountExchanged: null,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerWarrantsSellStep1(step, action);
            case SECOND_STEP:
                return brokerWarrantsSellStep2(step, action);
        }
    })
}

const productBuyReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_SELL_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_REQUEST:
            return state.setIn(['steps', FIRST_STEP, 'loading'], true);
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_SUCCESS:
            return state.set('confirmationData', action.payload.data)
                        .setIn(['steps', FIRST_STEP, 'loading'], false)
                        .set('visibleStep', 2);
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_FAILURE: //TODO handle error?
            return state.setIn(['steps', FIRST_STEP, 'loading'], false);

        case actionTypes.BROKER_WARRANTS_SELL_CONFIRM_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'loading'], true)
        case actionTypes.BROKER_WARRANTS_SELL_CONFIRM_FAILURE:
            return state.setIn(['steps', SECOND_STEP, 'loading'], false)
        case actionTypes.BROKER_WARRANTS_SELL_CONFIRM_SUCCESS:
            return state.set('submitSuccessful', true)
                        .setIn(['steps', SECOND_STEP, 'loading'], false)
                        .set('orderConfirmation', Immutable.Map({
                            associatedAccount: action.payload.data.datosCuentaAsociada.codIban.codbban,
                            accountBalance: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.importe,
                            accountCurrency: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.divisa,
                            orderNumber: action.payload.data.numeroOrden
                        }))
                        .set('checkedIsValid',false)
        case actionTypes.BROKER_WARRANTS_SELL_SUBSCRIPTION_CHANGE_READCHECKED:
                    let partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                        return state.merge({
                            steps: partialSteps
                        });
        case actionTypes.BROKER_WARRANTS_SELL_DOCUMENTS_READ:
                    let partialStep = updateStep(SECOND_STEP, state.get('steps'), action);
                        return state.merge({
                            steps: partialStep
                        });
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_CHECK:
                        return state.merge({
                            checkedIsValid: true

                         });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_FETCH:
            return state.merge({ isFetched: false, isFetching: true });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_ERROR:
                        return state.merge({ isFetched: false, isFetching: false });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_COST_SUCCESS:
                    return state.merge({
                        sellWarrantsTransactionCostBase64Data: action.payload.response.content,
                        sellWarrantsTransactionCostObjectId: action.payload.response.objectId,
                        isFetching: false,
                        isFetched: true
                    });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_REQUEST:
            return state.merge({
                error: false,
                isFetching: true
            });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_SUCCESS:
            return state.merge({
                mailSent: true,
                isFetching: false
            });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_MAIL_SENT_FAILURE:
            return state.merge({
                error: true,
                mailSent: false,
                isFetching: false
            });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_MAIL_RESET:
            return state.merge({
                error: false,
                isFetching: false,
                mailSent: false
            });
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_REQUEST:
                        return state.setIn(['steps', SECOND_STEP, 'documentStore'], true)
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_SUCCESS:
                        return state.setIn(['steps', SECOND_STEP, 'documentStore'], false)
        case actionTypes.BROKER_WARRANTS_SELL_LEGAL_DOCUMENT_STORE_ERROR:
                        return state.setIn(['steps', SECOND_STEP, 'documentStore'], false)
        case actionTypes.BROKER_WARRANTS_SELL_EX_ANTE_FETCHING:
            return state.set('exAnteDataFetching', true);
        case actionTypes.BROKER_WARRANTS_SELL_EX_ANTE_SUCCESS:
            return state.merge({
                exAnteData: action.payload.data.response.exAnteCostList,
                exAnteDataFetching: false,
                estimatedBaseAmount: action.payload.data.response.estimatedBaseAmount,
                estimatedBaseAmountExchanged: action.payload.data.response.estimatedBaseAmountExchanged,
            });
        case actionTypes.BROKER_WARRANTS_SELL_EX_ANTE_FAILURE:
            return state.set('exAnteDataFetching', false);
        default:
            return state;
    }
};

function brokerWarrantsSellReducer(state = setInitialState(), action) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_SELL_RESET:
            return setInitialState();
        case actionTypes.BROKER_WARRANTS_SELL_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.BROKER_WARRANTS_SELL_FINISH:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true
        });
        case actionTypes.BROKER_WARRANTS_SELL_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_SELL_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_WARRANTS_SELL_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_WARRANTS_SELL_TITLE_QUANTITY_CHANGED:
        case actionTypes.BROKER_WARRANTS_SELL_LIMIT_PRICE_CHANGED:
        case actionTypes.BROKER_WARRANTS_SELL_SET_SELL_PRICE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        default:
            return productBuyReducer(state, action);
    }
}

module.exports = brokerWarrantsSellReducer;



