// @vendors
const Immutable = require('immutable');

// @helpers
const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP
} = require('constants/index');
const actionTypes = require('constants/actionTypes');
const brokerRightsSellStep1 = require('./brokerRightsSell/brokerRightsSellStep1');
const brokerRightsSellStep2 = require('./brokerRightsSell/brokerRightsSellStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerRightsSellStep1(undefined, {type: null}),
            brokerRightsSellStep2(undefined, {type: null})
        ],
        visibleStep: 1,
        selectedCorporateOperationIndex: -1,
        orderNumber: '',
        validationServiceResponse: {},
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        dataToken: '',
        error: '',
        exAnteData: [],
        exAnteDataFetching: false,
        estimatedBaseAmount: null,
        estimatedBaseAmountExchanged: null,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerRightsSellStep1(step, action);
            case SECOND_STEP:
                return brokerRightsSellStep2(step, action);
        }
    })
}

function brokerRightsBuyReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.BROKER_RIGHTS_SELL_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_RIGHTS_SELL_ORDER_TYPE_CHANGED:
        case actionTypes.BROKER_RIGHTS_SELL_LIMITED_VALUE_CHANGED:
        case actionTypes.BROKER_RIGHTS_SELL_RIGHTS_QUANTITY_CHANGED:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_SELL_DISCLAIMER_CLAUSE_CHANGED:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_SELL_DOCUMENTS_READ:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_SELL_VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    })
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.BROKER_RIGHTS_SELL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.BROKER_RIGHTS_SELL_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.BROKER_RIGHTS_SELL_CONFIRM_REQUEST:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_SELL_CONFIRM_SUCCESS:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true,
                orderNumber: action.payload.orderNumber
            });
        case actionTypes.BROKER_RIGHTS_SELL_CONFIRM_FAILURE:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                error: action.payload.data.error
            });
        case actionTypes.BROKER_RIGHTS_SELL_SELECTED_CORPORATE_INDEX_CHANGED:
            return state.merge({
                selectedCorporateOperationIndex: action.payload.value
            })
        case actionTypes.BROKER_RIGHTS_SELL_VALIDATE_REQUEST:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case WIZARD_SECOND_STEP:
                    return state;
            }
            return state;
        case actionTypes.BROKER_RIGHTS_SELL_VALIDATE_SUCCESS:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep: WIZARD_SECOND_STEP,
                        validationServiceResponse: action.payload.data
                    });
                case WIZARD_SECOND_STEP:
                    return state;
            }
            return state;
        case actionTypes.BROKER_RIGHTS_SELL_VALIDATE_FAILURE:
            switch (action.payload.step) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps,
                        error: action.payload.data.error
                    });
                case WIZARD_SECOND_STEP:
                    return state;
            }
            return state;
        case actionTypes.BROKER_RIGHTS_SELL_RESET:
            return setInitialState();
        case actionTypes.BROKER_RIGHTS_SELL_EX_ANTE_FETCHING:
            return state.set('exAnteDataFetching', true);
        case actionTypes.BROKER_RIGHTS_SELL_EX_ANTE_SUCCESS:
                return state.merge({
                    exAnteData: action.payload.data.response.exAnteCostList,
                    exAnteDataFetching: false,
                    estimatedBaseAmount: action.payload.data.response.estimatedBaseAmount,
                    estimatedBaseAmountExchanged: action.payload.data.response.estimatedBaseAmountExchanged,
                });
        case actionTypes.BROKER_RIGHTS_SELL_EX_ANTE_FAILURE:
            return state.set('exAnteDataFetching', false);
        default:
            return state
    }
}

module.exports = brokerRightsBuyReducer;
