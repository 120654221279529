// @vendors
const { fromJS } = require('immutable');

// @constants
import {
    OK_WIZARD_ADD_STEP,
    OK_WIZARD_GET_INFO_FETCHING,
    OK_WIZARD_GET_INFO_SUCCESS,
    OK_WIZARD_GET_INFO_ERROR,
    OK_WIZARD_REMOVE_STEP,
    OK_WIZARD_RESET_DATA,
    OK_WIZARD_SET_ALL_ACCOUNTS_TO_INACTIVE,
    OK_WIZARD_SET_BUTTON_STATUS,
    OK_WIZARD_SET_CURRENT_STEP,
    OK_WIZARD_SET_TYPE_CHANGE,
    OK_WIZARD_SET_WIZARD_ERROR,
    OK_WIZARD_SET_WIZARD_SUCCESS,
    OK_WIZARD_GET_RESTRICTION_ERROR,
    OK_WIZARD_HIRE_CARD_LOADING,
    OK_WIZARD_SET_NGO_SELECTED,
    OK_WIZARD_SET_ASSOCIATE_ACCOUNT_SELECTED
} from 'constants/actionTypes';

const initialState = fromJS({
    allAccountsInactive: false,
    error: false,
    hireRestriction: false,
    isFetching: false,
    steps: [],
    submitSuccessful: false,
    willCancel: false,
    currentStep: 0,
    info: '',
    typeChange: '',
    validateLoadingFlag: false,
    isNgoNotSelected: false,
    isAssociateAccountNotSelected:false,
    buttons: {
        goBack: {
            loading: false,
            disabled: false
        },
        cancel: {
            loading: false,
            disabled: false
        },
        previous: {
            loading: false,
            disabled: false
        },
        next: {
            loading: false,
            disabled: false
        },
        submit: {
            loading: false,
            disabled: false
        }
    }
});

export const okWizard = (state = initialState, action) => {
    switch (action.type) {
        case OK_WIZARD_ADD_STEP:
            return state
                .setIn(['steps', action.position], fromJS(action.step));
        case OK_WIZARD_REMOVE_STEP:
            return state
                .deleteIn(['steps', action.position]);
        case OK_WIZARD_SET_CURRENT_STEP:
            return state
                .set('currentStep', action.position);
        case OK_WIZARD_RESET_DATA:
            return initialState;
        case OK_WIZARD_SET_TYPE_CHANGE:
            return state
                .set('typeChange', action.typeChange);
        case OK_WIZARD_SET_WIZARD_SUCCESS:
            return state
                .set('submitSuccessful', true)
                .set('error', false)
                .set('hireRestriction', false);
        case OK_WIZARD_SET_WIZARD_ERROR:
            return state
                .set('submitSuccessful', false)
                .set('error', true)
                .set('hireRestriction', false);
        case OK_WIZARD_GET_INFO_FETCHING:
            return state
                .set('isFetching', true);
        case OK_WIZARD_GET_INFO_SUCCESS:
            return state
                .set('isFetching', false)
                .set('info', action.response);
        case OK_WIZARD_GET_INFO_ERROR:
            return state
                .set('isFetching', false)
                .set('error', action.error)
                .set('hireRestriction', false);
        case OK_WIZARD_GET_RESTRICTION_ERROR:
            return state
                    .set('isFetching', false)
                    .set('error', action.error)
                    .set('hireRestriction', action.restrictionError);
        case OK_WIZARD_HIRE_CARD_LOADING:
            return state.
                    set('validateLoadingFlag', action.validateLoadingFlag);    
        case OK_WIZARD_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state
                .set('allAccountsInactive', true)
        case OK_WIZARD_SET_NGO_SELECTED:
            return state
                .set('isNgoNotSelected', action.payload)
        case OK_WIZARD_SET_ASSOCIATE_ACCOUNT_SELECTED:
            return state
                .set('isAssociateAccountNotSelected', action.payload)
        case OK_WIZARD_SET_BUTTON_STATUS:
            switch (action.button) {
                case 'goBack':
                    return state
                        .setIn(['buttons', 'goBack', 'loading'], action.status.loading)
                        .setIn(['buttons', 'goBack', 'disabled'], action.status.disabled); 
                case 'cancel':
                    return state
                        .setIn(['buttons', 'cancel', 'loading'], action.status.loading)
                        .setIn(['buttons', 'cancel', 'disabled'], action.status.disabled);
                case 'previous':
                    return state
                        .setIn(['buttons', 'previous', 'loading'], action.status.loading)
                        .setIn(['buttons', 'previous', 'disabled'], action.status.disabled);
                case 'next':
                    return state
                        .setIn(['buttons', 'next', 'loading'], action.status.loading)
                        .setIn(['buttons', 'next', 'disabled'], action.status.disabled);
                case 'submit':
                    return state
                        .setIn(['buttons', 'submit', 'loading'], action.status.loading)
                        .setIn(['buttons', 'submit', 'disabled'], action.status.disabled);
                default:
                    return state;
            }
        default:
            return state;
    }
};
