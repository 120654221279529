// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

// @reducers
const brokerStocksSaleOrderStep1Reducer = require('./brokerStocksSaleOrder/brokerStocksSaleOrderStep1');
const brokerStocksSaleOrderStep2Reducer = require('./brokerStocksSaleOrder/brokerStocksSaleOrderStep2');

// @constants
const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP
} = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    steps: [
        brokerStocksSaleOrderStep1Reducer(undefined, {}),
        brokerStocksSaleOrderStep2Reducer(undefined, {}),
    ],
    digitodecontrol: '',
    operation: {
        descriptionValue: '',
        descriptionProcess: '',
        endDate: '',
        codigoEmision: '',
        codigoValorInterno: '',
        tipoProceso: '',
        subtipoProceso: ''
    },
    contract: {
        product: '',
        number: ''
    },
    owner: '',
    walletAccountType: '',
    associatedAccount: {
        number: '',
        balance: {
            amount: 0,
            currency: ''
        }
    },
    visibleStep: FIRST_STEP + 1,
    validatingOperation: false,
    finished: false,
    fetchConfirmOrder: false,
    orderNumber: '',
    isLoaded: false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerStocksSaleOrderStep1Reducer(step, action);
            case SECOND_STEP:
                return brokerStocksSaleOrderStep2Reducer(step, action);
        }
    });
}

function brokerStocksSaleOrderReducer(state = INITIAL_STATE, action) {
    let partialSteps;
    let step;
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_SALE_ORDER_START:
            return INITIAL_STATE.mergeDeep({
                digitodecontrol: action.payload.cuenta.digitodecontrol,
                operation: action.payload.operation,
                contract: action.payload.contract,
                owner: action.payload.owner,
                associatedAccount: action.payload.associatedAccount,
                walletAccountType: action.payload.walletAccountType,
                isLoaded: action.payload.isLoaded
            });
        case actionTypes.BROKER_STOCKS_SALE_ORDER_CHANGE_TITLES_AMOUNT:
            partialSteps = state.getIn(['steps', FIRST_STEP]);
            return state.setIn(['steps', FIRST_STEP], brokerStocksSaleOrderStep1Reducer(partialSteps, action));
        case actionTypes.BROKER_STOCKS_SALE_ORDER_SET_OPERATION_INDEX:
            partialSteps = state.getIn(['steps', FIRST_STEP]);
            return state.setIn(['steps', FIRST_STEP], brokerStocksSaleOrderStep1Reducer(partialSteps, action));
        case actionTypes.BROKER_STOCKS_SALE_ORDER_CHANGE_MANIFEST:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksSaleOrderStep2Reducer(partialSteps, action))
                        .set('fetchConfirmOrder',false);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_DOCUMENTS_READ:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksSaleOrderStep2Reducer(partialSteps, action))
                        .set('fetchConfirmOrder',false);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_VALIDATE:
            let visibleStep;
            step = action.payload.step;
            switch(state.get('visibleStep')) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(step, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                    break;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(step, state.get('steps'), action);
                    visibleStep = WIZARD_SECOND_STEP;
                    break;
                default:
                    partialSteps = updateStep(step, state.get('steps'), action);
                    visibleStep = WIZARD_FIRST_STEP;
                    break;
            }
            return state.mergeDeep({
                steps: partialSteps,
                visibleStep,
                fetchConfirmOrder: step === SECOND_STEP && partialSteps.getIn([SECOND_STEP, 'valid'])
            });
        case actionTypes.BROKER_STOCKS_SALE_ORDER_FINISH_VALIDATION_STARTED:
            return state.set('validatingOperation', true);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_SET_VISIBLE_STEP:
            step = action.payload.step;
            return state.set('visibleStep', step)
                        .setIn(['steps', step - 1, 'valid'], false)
                        .setIn(['steps', step, 'loading'], false)
                        .setIn(['steps', step, 'errorFlags', 'manifestNotChecked'], false);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_LOADING:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksSaleOrderStep2Reducer(partialSteps, action));
        case actionTypes.BROKER_STOCKS_SALE_ORDER_CONFIRM_FAILURE:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksSaleOrderStep2Reducer(partialSteps, action))
                        .set('fetchConfirmOrder', false)
                        .set('validatingOperation', false);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_FINISH:
            partialSteps = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], brokerStocksSaleOrderStep2Reducer(partialSteps, action))
                        .set('fetchConfirmOrder', false)
                        .set('finished', true)
                        .set('orderNumber', action.payload.orderNumber);
        default:
            return state;
    }
}

module.exports = brokerStocksSaleOrderReducer;
