const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const brokerGeneralAdapter = require('./brokerProductAdapter/brokerGeneralAdapter');
const brokerWarrantAdapter = require('./brokerProductAdapter/brokerWarrantAdapter');
const isArray = require('lodash/lang/isArray');
const sanitizeHelper = require('utilities/sanitizeHelper');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @constants
const {
    BROKER_CATEGORY_WARRANT,
    BROKER_ALERT_BUY_BUTTON_STOCK,
    BROKER_ALERT_BUY_BUTTON_ETF
} = require('constants/index');

const initialState = Immutable.fromJS({
    isFetching: false,
    isFetched: false,
    isFetchingStockCatalogue: false,
    error: false,
    companyName: '',
    marketCode: '',
    isinCode: '',
    isComplex: false,
    productValue: {
        amount: 0,
        currency: ''
    },
    indexValue: {
        variation: 0,
        percentage: 0,
        upOrDown: ''
    },
    updatePriceDate: '',
    updatePriceTime: '',
    lastPriceDate: '',
    lastPriceTime: '',
    last: 0,
    buyAmount: 0,
    buyPrice: 0,
    currency: '',
    sellAmount: 0,
    sellPrice: 0,
    minimum: '',
    maximum: '',
    opening: '',
    lastClose: 0,
    volume: '',
    yearHigh: 0,
    yearLow: 0,
    yearChangePercentage: 0,
    yearChangePrice: 0,
    country: {
        id: '',
        name: ''
    },
    possessions: {
        titles: 0,
        valuation: 0,
        currency: ''
    },
    emisionCode: '',
    valueCode: '',
    warrant: {
        underlyingName: '',
        issuerName: '',
        activeType: '',
        warrantType: '',
        expirationDate: '',
        excercisePrice: 0,
        marketplace: '',
        demandVolume: 0,
        demandPrice: 0,
        offerPrice: 0,
        offerVolume: 0,
        exchangeId: 0,
        spcSymbol: '',
        underlying: {
            last: 0,
            variationPerc: 0,
            max: 0,
            min: 0,
            open: 0,
            date: '',
            time: '',
            exchangeId: 0,
            spcSymbol: ''
        }
    },
    catalogData: {
        operatedByLot: false,
        lot: 0,
        lotValue: 0,
    },
    risk:'',
});

function requestProductFileAdapter(productData) {
    const productCategory = trim(get(productData, 'category'));

    let formattedData = brokerGeneralAdapter(productData);

    formattedData.companyName = sanitizeHelper.sanitizeData(formattedData.companyName);

    switch (productCategory) {
        case BROKER_CATEGORY_WARRANT:
            formattedData.manager = trim(get(productData, 'issuerName'));
            formattedData.warrant = brokerWarrantAdapter(productData);
            formattedData.micCode = trim(get(productData, 'micCode')); //second 'C' upper case for warrants only
            formattedData.currency = get(productData, 'subyacente.currencyCode');
            formattedData.currencyCode = trim(get(productData, 'currencyCode'));

            break;
        case BROKER_ALERT_BUY_BUTTON_ETF:
            formattedData.manager = trim(get(productData, 'manager'));
            formattedData.micCode = trim(get(productData, 'miccode'));
            formattedData.spcSymbol = trim(get(productData, 'spcSymbol'));
            delete formattedData.warrant;

            break;
        case BROKER_ALERT_BUY_BUTTON_STOCK:
            formattedData.manager = trim(get(productData, 'instrument'));
            formattedData.micCode = trim(get(productData, 'miccode'));
            formattedData.itf = get(productData, 'itf');
            delete formattedData.warrant;

            break;
        default:
            formattedData.micCode = trim(get(productData, 'miccode', '') || get(productData, 'micCode', ''));
    }

    return formattedData;
}

function brokerProductFileReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_PRODUCT_FILE_FETCHING:
            return state
                .set('isFetched', false)
                .set('isFetching', true)
                .set('error', false);

        case actionTypes.BROKER_GET_PRODUCT_FILE_FAILURE:
            return state
                .set('isFetched', false)
                .set('isFetching', false)
                .set('error', action.payload.data);

        case actionTypes.BROKER_GET_PRODUCT_FILE_SUCCESS:
            const productData = isArray(action.payload.data) ? action.payload.data[0] : action.payload.data;
            if (productData) {
                return state.mergeDeep(requestProductFileAdapter(productData))
                        .set('isFetched', true)
                        .set('isFetching', false)
                        .set('error', false);
            } else {
                return state
                        .set('isFetched', false)
                        .set('isFetching', false)
                        .set('error', true);
            }

        case actionTypes.BROKER_GET_POSSESSIONS_SUCCESS:
            return state
                .set('emisionCode', action.payload.summary.codigoEmision)
                .set('valueCode', action.payload.summary.codigoValor)
                .mergeIn(['possessions', 'titles'], action.payload.summary.titulos)
                .mergeIn(['possessions', 'valuation'], action.payload.summary.valoracion)
                .mergeIn(['possessions', 'currency'], action.payload.summary.divisa);

        case actionTypes.BROKER_GET_PRODUCT_CATALOGUE_FETCHING:
            return state.set('isFetchingStockCatalogue', true);

        case actionTypes.BROKER_GET_PRODUCT_CATALOGUE_SUCCESS:
            let risk;
            if(action.payload.stocks.instrumentsList[0]) {
                risk = parseInt(action.payload.stocks.instrumentsList[0].risk.charAt(0));
            }

            return state.set('isFetchingStockCatalogue', false)
                        .set('risk', risk)

        case actionTypes.BROKER_GET_PRODUCT_CATALOGUE_FAILURE:
            return state.set('isFetchingETFCatalogue', false);

        case actionTypes.BROKER_PRODUCT_FILE_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = brokerProductFileReducer;
