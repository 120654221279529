const ACTION_CODE_OTP = 5224
const ACTION_CODE_PREFIX = 'action-code-'
const ACTION_CODE_SIGNATURE_PATTERN = 5223
const FLOW_TYPE_ONLY_ACCESS = 'FLOW_TYPE_ONLY_ACCESS'
const OPERATION_LOG_TYPE_INITIALIZE = 'OPERATION_LOG_TYPE_INITIALIZE'
const OPERATION_LOG_TYPE_ENTRY = 'OPERATION_LOG_TYPE_ENTRY'
const OPERATION_LOG_TYPE_SHOW_WIZARD = 'OPERATION_LOG_TYPE_SHOW_WIZARD'
const OPERATION_LOG_TYPE_FINISH = 'OPERATION_LOG_TYPE_FINISH'
const OPERATION_LOG_INIT = 'OPERATION_LOG_INIT'
const OPERATION_LOG_ACCESS_BY_ACTION = 'OPERATION_LOG_ACCESS_BY_ACTION'
const OPERATION_LOG_NONE = 'OPERATION_LOG_NONE'
const OPERATION_LOG_NONE_AND_RESET = 'OPERATION_LOG_NONE_AND_RESET'
const OPERATION_LOG_DONE = 'OK'
const OPERATION_LOG_FAIL = 'KO'
const OPERATION_TYPE_VIEW = 'OPERATION_TYPE_VIEW'
const OPERATION_TYPE_OPERATIVE = 'OPERATION_TYPE_OPERATIVE'
const OTP_FAIL_NOTIFICATION = 'FALLA EN LA VERIFICACION DE OTP'
const SIGNATURE_PATTERN_FAIL_NOTIFICATION = 'FALLA EN LA VERIFICACION DE CLAVE DE FIRMA'
const UNKNOWN_ACTION_TYPE = 'UNKNOWN_ACTION_TYPE'

module.exports = {
    ACTION_CODE_OTP,
    ACTION_CODE_PREFIX,
    ACTION_CODE_SIGNATURE_PATTERN,
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_TYPE_INITIALIZE,
    OPERATION_LOG_TYPE_ENTRY,
    OPERATION_LOG_TYPE_SHOW_WIZARD,
    OPERATION_LOG_TYPE_FINISH,
    OPERATION_LOG_INIT,
    OPERATION_LOG_NONE,
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_TYPE_VIEW,
    OPERATION_TYPE_OPERATIVE,
    OTP_FAIL_NOTIFICATION,
    SIGNATURE_PATTERN_FAIL_NOTIFICATION,
    UNKNOWN_ACTION_TYPE,
    OPERATION_LOG_ACCESS_BY_ACTION,
    OPERATION_LOG_NONE_AND_RESET
}
