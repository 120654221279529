require('../diamondPremiumViaTVisaTooltip.scss');
// @ vendors
const React = require('react');
// @ utilities
const { formatText, FormattedText } = require('core/i18n').i18n;
const { creditOptionsDisableContinue } = require('utilities/cardsHelper');
const { formatAmount } = require('utilities/currencyHelper');
const PersonalDataHelper = require('utilities/personalDataHelper');
// @ contract utilities
const { findElementbyId, getStandard } = require('../utilities/contractStateHelper');
const { haveEmail } = require('../utilities/formHelper');
const mockDataHelper = require('../utilities/mockDataHelper');
//@constants
const {
    CARD_PAYMENT_METHOD_FIXED,
    CARD_PAYMENT_METHOD_VARIABLE
} = require('constants/index');

function diamondPremiumViaTVisa_fixedFeeOptions(creditLimit, contractName) {
    return mockDataHelper.generateMethodOfPaymentOptions(false, creditLimit, contractName);
}

function diamondPremiumViaTVisa_percentageOptions() {
    return mockDataHelper.generateMethodOfPaymentOptions(true);
}

function diamondPremiumViaTVisa_setSuccessStepPrefix(contractWizard, immProfileFields) {
    let successStepPrefix = {};

    if (contractWizard.get('creditCardPending')) {
        successStepPrefix.flow = 'pending_';
    }

    if (haveEmail(immProfileFields)) {
        successStepPrefix.email = 'successWithEmail_';
    }

    return successStepPrefix;
}

function diamondPremiumViaTVisa_creditOptionsDisableContinueButton(maxLimitToAuthorize, methodOfPayment) {
    return creditOptionsDisableContinue(maxLimitToAuthorize, methodOfPayment);
}

function diamondPremiumViaTVisa_creditOptions(amount, maxLimitToAuthorize, disableContinue) {
    const warning = disableContinue && {
            message: 'contractsView-creditLimitWarning',
            messageType: 'error'
        };
    return [
        Object.assign({
                value: true,
                label: formatText('contractsView-creditLimitApprovedRequest', [maxLimitToAuthorize])
            },
            warning
        ),
        {
            value: false,
            label: formatText('contractsView-creditLimitPendingRequest', [amount]),
            subLabel: 'contractsView-cardsPendingText'
        }
    ];
}

function validateStep1(card_details) {
    return {
        card_details,
        isValidStep: true
    };
}


function validateStep2(card_details) {
    return {
        card_details,
        isValidStep: true
    };
}

function validateStep3(cardDetails, state, isValidStep) {
    const moneyNeeded = findElementbyId(cardDetails, 'creditLimit');
    const maximumApproved = state.get('maxLimitToAuthorize');

    if (!isValidStep) {
        return {
            card_details: cardDetails,
            isValidStep: isValidStep
        }
    }

    if (isValidStep && parseFloat(maximumApproved) > 0 && moneyNeeded > parseFloat(maximumApproved)) {
        cardDetails = cardDetails.update(
            cardDetails.findIndex(
                item => item.get('id') === 'creditLimitSelection'
            ), item => item.set('openModal', isValidStep)
        );
    }

    return {
        card_details: cardDetails,
        isValidStep: isValidStep
    };
}

function validateStep4(card_details, state) {
    const confirmationCheckboxes = state.get('confirmationCheckboxes');
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = confirmationCheckboxes.get('list').map(
        item => item.set('errorState', item.get('isRequired') && !item.get('value'))
    );

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details,
        isValidStep: confirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep(step, state, isValidStep) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');

    if (state.get('name_contract') === 'via-t') {
        // This is necessary because 'via-t' has 3 steps instead of 4. As the validation of the steps 1 and 2
        // are the same, it is possible to increase the number of the validation in the mentioned flow.
        step += 1;
    }

    const validations = [
        validateStep1,
        validateStep2,
        validateStep3,
        validateStep4
    ];

    return validations[step - 1](immCardDetails, state, isValidStep);
}

function diamondPremiumViaTVisa_validateStep(step, state, isValidStep) {
    return validateStep(step, state, isValidStep);
}

function diamondPremiumViaTVisa_onChangeCreditLimit(value, component, cardDetails) {
    let changes = [{
        target: 'creditLimit',
        value
    }];
    const methodOfPayment = cardDetails.find(detail => detail.id === 'methodOfPayment').value;
    if ( methodOfPayment && methodOfPayment.methodOfPayment === CARD_PAYMENT_METHOD_FIXED ) {
        methodOfPayment.fixedFeeAmount = Math.ceil(value / 300) * 30;
        changes.push({
            target: 'methodOfPaymentTitle',
            value: `${formatText('contractsView-methodOfPaymentDeferredFixedFee')}`
        });
        changes.push({
            target: 'feeAmount',
            value: `${formatAmount(methodOfPayment.fixedFeeAmount)} ${methodOfPayment.inputFormat}`
        });
    }
    return changes;
}

function diamondPremiumViaTVisa_SignParams() {
    return {};
}

function diamondPremiumViaTVisa_HireParams(cardDetails, immAccount, immProfileFields, gdprPlacementId) {
    const paymentShare = cardDetails.getIn(['methodOfPayment', 'value', 'feeAmount']) || '';
    let params;

    if (immAccount) {
        params = {
            email: PersonalDataHelper.getEmail(immProfileFields),
            accountNumber: immAccount.getIn(['ibanComplex', 'codbban']),
            hasGdprPlacementId: !!gdprPlacementId,
            paymentShare,
            limit: findElementbyId(cardDetails, 'creditLimit'),
            paymentMethod: findElementbyId(cardDetails, 'methodOfPayment').get('methodOfPayment'),
            standard: getStandard(findElementbyId(cardDetails, 'card_image'))
        }

        if (params.paymentMethod === CARD_PAYMENT_METHOD_FIXED || params.paymentMethod === CARD_PAYMENT_METHOD_VARIABLE) {
            params.paymentShare = params.paymentMethod === CARD_PAYMENT_METHOD_FIXED ?
                findElementbyId(cardDetails, 'methodOfPayment').get('fixedFeeAmount') :
                findElementbyId(cardDetails, 'methodOfPayment').get('percentageAmount')
        }
    }

    return params;
}

function diamondPremiumViaTVisa_buildExtraInfo(contractWizard) {
    const contractName = contractWizard.get('name_contract');
    const feeAmountIncrease = mockDataHelper.returnFeeAmountIncrease(contractName);

    return (
        <article>
            <div className="diamond-premium-via-t-visa__tooltip diamond-premium-via-t-visa__tooltip-title">
                <FormattedText value="contractsView-diamondPremiumViaTVisaTooltipTitle1" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip">
                <FormattedText injectedStrings={[feeAmountIncrease]} value="contractsView-diamondPremiumViaTVisaTooltipText1" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip diamond-premium-via-t-visa__tooltip-title">
                <FormattedText value="contractsView-diamondPremiumViaTVisaTooltipTitle2" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip">
                <FormattedText value="contractsView-diamondPremiumViaTVisaTooltipText2" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip diamond-premium-via-t-visa__tooltip-title">
                <FormattedText value="contractsView-diamondPremiumViaTVisaTooltipTitle3" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip">
                <FormattedText value="contractsView-diamondPremiumViaTVisaTooltipText3" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip diamond-premium-via-t-visa__tooltip-footer">
                <FormattedText value="contractsView-diamondPremiumViaTVisaTooltipFooter" />
            </div>
        </article>
    );
}

function diamondPremiumViaTVisa_buildAddressExtraInfo() {
    return (
        <article>
            <div className="diamond-premium-via-t-visa__tooltip diamond-premium-via-t-visa__tooltip-title">
                <FormattedText value="contractsView-diamondPremiumViaTVisaAddresTooltipTitle" />
            </div>
            <div className="diamond-premium-via-t-visa__tooltip">
                <FormattedText value="contractsView-diamondPremiumViaTVisaAddresTooltipText" />
            </div>
        </article>
    );
}

module.exports = {
    diamondPremiumViaTVisa_buildAddressExtraInfo,
    diamondPremiumViaTVisa_buildExtraInfo,
    diamondPremiumViaTVisa_creditOptions,
    diamondPremiumViaTVisa_creditOptionsDisableContinueButton,
    diamondPremiumViaTVisa_fixedFeeOptions,
    diamondPremiumViaTVisa_HireParams,
    diamondPremiumViaTVisa_onChangeCreditLimit,
    diamondPremiumViaTVisa_percentageOptions,
    diamondPremiumViaTVisa_setSuccessStepPrefix,
    diamondPremiumViaTVisa_SignParams,
    diamondPremiumViaTVisa_validateStep
};
