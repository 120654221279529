const { getSecurityLevel, securityLevels } = require('utilities/usd');
const Storage = require('utilities/storage');
const { AGENT_INFO, TOKEN_CREDENTIAL } = require('constants/session');
// @actions
const routingActions = require('actions/routing');

const {
    SECURITY_LEVEL_UPGRADE_PATH,
    UNKNOWN_SECURITY_LEVEL_PATH,
    SECURITY_LEVEL_WHITELIST,
    AUTHENTICATION_WHITELIST,
} = require('constants/secureRouting');

const disasterRecoverySecurityLevelCheck = (history) => {
    const destination = history.location.pathname;
    const agentInfo = Storage.cookies.get(AGENT_INFO);
    const agentToken = agentInfo ? JSON.parse(agentInfo).bankToken : '';

    if (!agentToken) {
        if (destination !== '/disasterRecovery/agentLogin') {
            history.replace('/disasterRecovery/agentLogin', { nextPathname: destination })
        }
    }
    if (agentToken && destination === '/disasterRecovery' ) {
        history.replace('/disasterRecovery/customerFinder', { nextPathname: destination })
    }
    if (agentToken && destination === '/disasterRecovery/agentLogin') {
        Storage.cookies.remove(AGENT_INFO);
    }
    document.querySelector('body').scrollTop = 0;
    return true;
}

const clientSecurityLevelCheck = (history) => {
    const token = Storage.cookies.get(TOKEN_CREDENTIAL);
    const destination = history.location.pathname;
    const securityLevel = getSecurityLevel();
    if (!token) {
        if (!AUTHENTICATION_WHITELIST.includes(destination)){
            history.replace('/login', { nextPathname: destination });
        }
    } else {

        if( destination === '/' ){
            history.replace('/myprofile/global', { nextPathname: destination })
        }

        if (!SECURITY_LEVEL_WHITELIST.includes(destination)) {
            switch (securityLevel) {
                case securityLevels.ID:
                    history.replace(SECURITY_LEVEL_UPGRADE_PATH, { nextPathname: destination });
                    break;
                case securityLevels.N1:
                case securityLevels.N2:
                    // Allow the redirection as it is
                    break;
                default:
                    history.replace(UNKNOWN_SECURITY_LEVEL_PATH);
            }
        }
    }
    document.querySelector('body').scrollTop = 0;
    return true;
}

const securityLevelCheck = (history) => {
    const destination = history.location.pathname;
    return destination.includes('disasterRecovery')?  disasterRecoverySecurityLevelCheck(history) : clientSecurityLevelCheck(history) ;
}

const handleOnUnathotizedError = (error,dispatch) =>{
    const currentRoute = location.pathname;
    if(AUTHENTICATION_WHITELIST.includes(currentRoute)){
        throw error;
    }
    else {
        dispatch(routingActions.enableSessionErrorLevelRedirect());
    }
}
module.exports = {
    securityLevelCheck,
    handleOnUnathotizedError
}
