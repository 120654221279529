// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP, THIRD_STEP, FOURTH_STEP, EURO_TEXT } = require('constants/index');
// @ reducers
const externalTransferFundStep1 = require('./externalTransferFund/externalTransferFundStep1');
const externalTransferFundStep2 = require('./externalTransferFund/externalTransferFundStep2');
const externalTransferFundStep3 = require('./externalTransferFund/externalTransferFundStep3');
const externalTransferFundStep4 = require('./externalTransferFund/externalTransferFundStep4');
// @ contact-center
const {
    BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND,
    BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND_RESET,
    PRE_CONTRACTUAL_MAIL_SENT_SUCCESS,
    PRE_CONTRACTUAL_MAIL_SENT_FAILURE,
} = require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            externalTransferFundStep1(undefined, { type: null }),
            externalTransferFundStep2(undefined, { type: null }),
            externalTransferFundStep3(undefined, { type: null }),
            externalTransferFundStep4(undefined, { type: null })
        ],
        originFund: {
            isin: '',
            name: '',
            nifGestora: '',
            currency: EURO_TEXT
        },
        errorReceived: '',
        isFetching: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        showingHelp: false,
        isLoading: false,
        isFetchingConfirmation: false,
        mustRunMifid: false
    });
}

function updateStep1(steps, action) {
    return steps.update(FIRST_STEP, step => {
        return externalTransferFundStep1(step, action);
    });
}

function updateStep2(steps, action) {
    return steps.update(SECOND_STEP, step => {
        return externalTransferFundStep2(step, action);
    });
}

function updateStep3(steps, action) {
    return steps.update(THIRD_STEP, step => {
        return externalTransferFundStep3(step, action);
    });
}

function updateStep4(steps, action) {
    return steps.update(FOURTH_STEP, step => {
        return externalTransferFundStep4(step, action);
    });
}

function updateInteveners(interveners) {
    let byId = {};
    let byOrder = [];

    interveners.forEach(intervener => {
        const parsedInterveners = {
            nombre: intervener.nombre.trim(),
            numeropersona: intervener.numeropersona,
            tipopersona: intervener.tipopersona,
            desintervencion: intervener.desintervencion.trim(),
            tipointervencion: intervener.tipointervencion,
            formaintervencion: intervener.formaintervencion
        };
        const intervenerId = intervener.numeropersona + intervener.tipopersona;

        byId[intervenerId] = parsedInterveners;
        byOrder.push(intervenerId);
    });

    return {
        interveners: {
            byId: byId,
            byOrder: byOrder
        },
        error: '',
        isFetchingFunds: false
    };
}

function updateVisibleStep(immState, newVisibleStep) {
    const mustRunMifid = newVisibleStep == FOURTH_STEP;

    return immState.merge({
        visibleStep: mustRunMifid ? immState.get('visibleStep') : newVisibleStep,
        mustRunMifid
    });
}

function continueFromMifid(immState, externalTransferFundStateBeforeMifid) {
    return immState
        .merge(externalTransferFundStateBeforeMifid)
        .merge({
            visibleStep: 3,
            mustRunMifid: false
        });
}

function cancelMifid(immState, externalTransferFundStateBeforeMifid) {
    return immState
        .merge(externalTransferFundStateBeforeMifid)
        .set('mustRunMifid', false);
}

const formatShares = shares => {
    const MAX_SHARES = 10000000000;
    const MAX_SHARES_FORMATTED = '9999999999.9999999';
    return shares === MAX_SHARES ? MAX_SHARES_FORMATTED : shares.toFixed(7);
};

const externalTransferFund = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    let newState;
    let response;
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_CANCEL_MIFID:
            return cancelMifid(state, action.payload.externalTransferFundStateBeforeMifid);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_CONTINUE_FROM_MIFID:
            return continueFromMifid(state, action.payload.externalTransferFundStateBeforeMifid);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET:
        case actionTypes.BROKER_GET_MY_INVESTMENTS_REQUEST:
            return setInitialState();
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_VISIBLE_STEP:
            return updateVisibleStep(state, action.payload.step);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_WILL_CANCEL:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SHOW_HELP:
            return state.merge({
                showingHelp: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_HIDE_HELP:
            return state.merge({
                showingHelp: false
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP:
            visibleStep = 0;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = partialSteps.get(1).get('valid') ? 3 : 2;
                    break;
                case 3:
                    partialSteps = updateStep3(state.get('steps'), action);
                    visibleStep = partialSteps.get(2).get('valid') ? 4 : 3;
                    break;
                case 4:
                    partialSteps = updateStep4(state.get('steps'), action);
                    visibleStep = 4;
                    break;
                default:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 1;
            }
            return updateVisibleStep(state.merge({ steps: partialSteps }), visibleStep);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_INSTITUTION_NAME:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_NIF:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_ACCOUNT:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_HAS_NIF:
            partialSteps = updateStep1(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_CONVENIENCE_MIFID_TEST_RETAKE_TEST:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SELECT_FUND:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_FUND:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_FUND:
            partialSteps = updateStep2(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_AMOUNT_DIRTY:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_DESTINATION_FUND:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_TYPE:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_SUBTYPE:
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_AMOUNT:
            partialSteps = updateStep3(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_CHECKBOX:
            partialSteps = updateStep4(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_DOCUMENTS_READ:
            partialSteps = updateStep4(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_FAILURE:
            return state.merge({
                isFetchingOriginFund: false,
                isFetchingInteveners: false,
                isFetchingValidation: false,
                isFetchingConfirmation: false,
                error: action.payload.error,
                isLoading: false
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_ORIGIN_FUND_IN_PROGRESS:
            return state.merge({
                isFetchingOriginFund: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_INTERVENERS_IN_PROGRESS:
            return state.merge({
                isFetchingInteveners: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_VALIDATION_IN_PROGRESS:
            return state.merge({
                isFetchingValidation: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_CONFIRMATION_IN_PROGRESS:
            return state.merge({
                isFetchingConfirmation: true,
                isLoading: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_ORIGIN_FUND_SUCCESS:
            response = action.payload.data.datos.dato[0];
            return state.merge({
                originFund: {
                    nifGestora: response.nifGestora,
                    isin: response.isin,
                    currency: response.divisa,
                    name: response.nombre
                },
                isFetchingOriginFund: false
            });

        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_GET_INTERVENERS_SUCCESS:
            response = action.payload.data;
            newState = state.merge({
                codfon: response.codfon,
                isFetchingInteveners: false
            });
            return newState.merge(updateInteveners(response.datos.dato));
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_VALIDATION_SUCCESS:
            response = action.payload.data;
            return state.merge({
                validation: {
                    codigodepersona: response.gestoraDestino.codigodepersona,
                    icsalcon: formatShares(response.icsalcon),
                    icsaldis: formatShares(response.icsaldis),
                    tippers1: response.tippers1
                },
                isFetchingValidation: false
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_POST_CONFIRMATION_SUCCESS:
            return state.merge({
                isFetchingConfirmation: false,
                submitSuccessful: true,
                isLoading: false,
                emissionDate: moment(parseInt(get(action, 'payload.data.timestamp', '')))
            });

        case BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND:
        case BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND_RESET:
        case PRE_CONTRACTUAL_MAIL_SENT_SUCCESS:
        case PRE_CONTRACTUAL_MAIL_SENT_FAILURE:
            partialSteps = updateStep4(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_REQUEST:
            return state.setIn(['steps', FOURTH_STEP, 'documentStorage'], true);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_SUCCESS:
            return state.setIn(['steps', FOURTH_STEP, 'documentStorage'], false);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_DOCUMENT_ERROR:
            return state.setIn(['steps', FOURTH_STEP, 'documentStorage'], false);
        default:
            return state;
    }
};

module.exports = externalTransferFund;
