const actionTypes = require('constants/actionTypes');
const moment = require('moment');
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const { CHEQUEBOOK_FILTER_PAYED_RETURNED_COMPOSED, CHEQUEBOOK_FILTER_IRREGULAR } = require('constants/index');
const { hash } = require('utilities/hash');
const get = require('lodash/object/get');

const initialState = Immutable.fromJS({});

function parseCheques(accountId, cheques) {
    let byId = {};
    let byOrder = [];

    cheques.map( cheque => {
        const parsedCheque = {
            'accountId': accountId,
            'initialNum': cheque.numChequeInicial || '',
            'finalNum': cheque.numChequeFinal || '',
            'pendingCount': cheque.numChequesPendientes,
            'payedCount': cheque.numChequesPagados,
            'irregularCount': cheque.numChequesExtraviados,
            'currency': cheque.monedaPedido,
            'status': cheque.codigoIncidencia,
        };

        byId[parsedCheque.initialNum] = parsedCheque;
        byOrder.push(parsedCheque.initialNum);
    });

    return {
        byOrder: byOrder,
        byId: byId
    };
}

function parseChequebook(accountId, chequebook, currency, isIrregular = false) {
    let byId = {};
    let byOrder = [];

    chequebook.forEach( cheque => {
        const number = cheque.numeroCheque || cheque.numeroChequesInicialExtraviados;
        const parsedCheque = {
            number,
            accountId,
            status: trim(cheque.situacionDocumento),
            balance: {
                amount: isIrregular ? get(cheque, 'importe.importe') || 0 : get(cheque, 'importe') || 0,
                currency: currency
            },
            date: cheque.fechaPresentacion || cheque.fecha || ''
        };

        if (parsedCheque.date && !parsedCheque.date.includes('-') && parsedCheque.date.length >= 6) {
            parsedCheque.date = moment(parsedCheque.date, 'DDMMYY');
        }

        parsedCheque.id = hash([parsedCheque.number, parsedCheque.status]);
        byId[parsedCheque.id] = parsedCheque;
        byOrder.push(parsedCheque.id);
    });

    return {
        byOrder: byOrder,
        byId: byId
    };
}

function parseIrregularChequebook(accountId, chequebook, currency) {
    chequebook.forEach(cheque => {
       if (cheque.numeroChequesInicialExtraviados === cheque.numeroChequesFinalExtraviados){
            cheque.numeroCheque = cheque.numeroChequesInicialExtraviados;
       } else {
            cheque.numeroCheque = `${cheque.numeroChequesInicialExtraviados} - ${cheque.numeroChequesFinalExtraviados}`;
       }
    });

    return parseChequebook(accountId, chequebook, currency, true);
}

function accountsRetentionsReducer(state = initialState, action) {
    let parsedCheques;
    let parsedChequebook;
    let currency;
    let accountId;
    let chequebookId;

    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_CHEQUES_REQUEST:
            return state.set(action.payload.accountId, Immutable.fromJS({
                    nextPage: '',
                    nextPageLoading: false,
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {}
            }));
        case actionTypes.FETCH_ACCOUNT_CHEQUES_SUCCESS:
            accountId = action.payload.accountId;
            parsedCheques = parseCheques(accountId, action.payload.cheques);
            return state.set(accountId, Immutable.fromJS({
                    nextPage: action.payload.nextPage,
                    byOrder: parsedCheques.byOrder,
                    byId: parsedCheques.byId,
                    loading: false,
                    error: false,
                    chequebooks: {},
                    chequebookFilter: CHEQUEBOOK_FILTER_PAYED_RETURNED_COMPOSED
            }));
        case actionTypes.FETCH_ACCOUNT_CHEQUES_PAGE_SUCCESS:
            accountId = action.payload.accountId;
            parsedCheques = parseCheques(accountId, action.payload.cheques);
            const currentCheques = state.get(action.payload.accountId);
            return state.mergeDeep({
                [action.payload.accountId]: {
                    nextPage: action.payload.nextPage,
                    byOrder: currentCheques.get('byOrder').concat(Immutable.fromJS(parsedCheques.byOrder)),
                    byId: parsedCheques.byId,
                    loading: false,
                    error: false
                }
            });
        case actionTypes.FETCH_ACCOUNT_CHEQUES_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    error: action.payload.error
                }
            });
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_REQUEST:
            return state.setIn([ action.payload.accountId, 'chequebooks', action.payload.chequebookId], Immutable.fromJS({
                    nextPage: '',
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {},

            }));
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_NEXT_PAGE_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    chequebooks: {
                        [action.payload.chequebookId]: {
                            nextPage: '',
                            nextPageLoading: true
                        }
                    }
                }
            });
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_SUCCESS:
            accountId = action.payload.accountId;
            chequebookId = action.payload.chequebookId;
            currency = state.getIn([accountId,'byId', chequebookId, 'currency']);
            const selectedFilter = state.getIn([accountId, 'chequebookFilter']);
            if (selectedFilter === CHEQUEBOOK_FILTER_IRREGULAR) {
                parsedChequebook = parseIrregularChequebook(accountId, action.payload.irregularChequebook, currency);
            } else {
                parsedChequebook = parseChequebook(accountId, action.payload.chequebook, currency);
            }

            return state.setIn([accountId, 'chequebooks', chequebookId], Immutable.fromJS({
                    nextPage: action.payload.nextPage,
                    loading: false,
                    error: false,
                    byOrder: parsedChequebook.byOrder,
                    byId: parsedChequebook.byId
            }));
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_NEXT_PAGE_SUCCESS:
            accountId = action.payload.accountId;
            chequebookId = action.payload.chequebookId;
            currency = state.getIn([accountId,'byId', chequebookId, 'currency']);
            parsedChequebook = parseChequebook(accountId, action.payload.chequebook, currency);

            return state.setIn([accountId, 'chequebooks', chequebookId], Immutable.fromJS({
                    nextPage: action.payload.nextPage,
                    nextPageLoading: false,
                    byOrder: state.getIn([accountId, 'chequebooks', chequebookId, 'byOrder']).concat(Immutable.fromJS(parsedChequebook.byOrder)),
                    byId: state.getIn([accountId, 'chequebooks', chequebookId, 'byId']).merge(parsedChequebook.byId)
            }));
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    chequebooks: {
                        [action.payload.chequebookId]: {
                            loading: false,
                            error: action.payload.error
                        }
                    }
                }
            });
        case actionTypes.FETCH_ACCOUNT_CHEQUEBOOK_SET_FILTER:
            return state.setIn([action.payload.accountId, 'chequebookFilter'], action.payload.selected);
        default:
            return state
    }
}

module.exports = accountsRetentionsReducer;
