// @ utilities
const { isValidAccount } = require('../utilities/formValidationHelper');
const mockHelper = require('../utilities/mockDataHelper');
const { onChangeAmount, haveEmail } = require('../utilities/formHelper');

function openStudent_onChangeAmount(amount) {
    return onChangeAmount(amount);
}

function openStudent_onChangeIntervener(item, typeSelected, index) {
    return mockHelper.onChangeIntervener(item, typeSelected, index);
}

function validateStep1(card_details) {
    return {
        card_details,
        isValidStep: true
    };
}

function validateStep2(card_details) {
    return isValidAccount(card_details);
}

function validateStep3(card_details, confirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = confirmationCheckboxes.get('list').map(
        item => item.set('errorState', item.get('isRequired') && !item.get('value'))
    );

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details,
        isValidStep: confirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function open_student_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails);

        case 2:
            return validateStep2(immCardDetails);

        case 3:
            return validateStep3(immCardDetails,immConfirmationCheckboxes);
    }
}

function openStudent_SignParams() {
    return {};
}

function openStudent_HireParams(immAccount) {
    let ret;

    if (immAccount) {
        ret = {
            cuentaCargo: '00730100' + immAccount.get('product') + immAccount.get('contractNumber')
        }
    }
    return ret;
}

function openStudent_setSuccessStepPrefix(cardDetails, immProfileFields) {
    return haveEmail(immProfileFields) ? {email: 'successWithEmail_'} : {};
}

module.exports = {
	open_student_validateStep: open_student_validateStep,
    openStudent_onChangeAmount: openStudent_onChangeAmount,
    openStudent_onChangeIntervener: openStudent_onChangeIntervener,
    openStudent_SignParams: openStudent_SignParams,
    openStudent_HireParams: openStudent_HireParams,
    openStudent_setSuccessStepPrefix: openStudent_setSuccessStepPrefix
};
