const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const passStep1 = require('./pass/passStep1');
const { FIRST_STEP } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            passStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        referenceNumber: '',
        amountReceived: '',
        errorReceived: '',
        isValidating: false,
        validationError: false,
        validationSuccess: false,
        isFutureTransfer: false,
        isPredefinedToAccount: false,
        newAccountNotFoundError: false,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return passStep1(step, action);
        }
    })
}

function passReducer(state = setInitialState(), action) {
    let partialSteps;
    let newState;

    switch (action.type) {
        case actionTypes.PASS_RESET:
            return setInitialState();
        case actionTypes.PASS_VALIDATE_UI_STEP:
            switch (action.payload.data) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.PASS_VALIDATE_STEP_REQUEST:
            return state.merge({
                isValidating: true
            });
        case actionTypes.PASS_VALIDATE_STEP_SUCCESS:
            switch (action.payload.step) {
                case 1:
                    let visibleStep = state.get('steps').get(0).get('valid') ? 2 : 1;
                    return state.merge({
                        isValidating: false,
                        validationSuccess: true,
                        visibleStep
                    });
            }
        case actionTypes.PASS_VALIDATE_STEP_FAILURE:
            return state.merge({
                isValidating: false,
                validationError: true,
                errorReceived: action.payload.error
            });
        case actionTypes.PASS_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.data
            })
        case actionTypes.PASS_WILL_LEAVE:
            return state.merge({
                willLeave: true
            })
        case actionTypes.PASS_REQUEST_TRANSFER_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.PASS_REQUEST_TRANSFER_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                signatureKeyFailure: false,
                errorReceived: '',
                inProgress: false,
                referenceNumber: action.payload.data.referenceNumber,
                amountReceived: action.payload.data.amount
            })
        case actionTypes.PASS_REQUEST_TRANSFER_FAILURE:
            newState = state.merge({
                errorReceived: action.payload.error,
                isFetching: false,
                submitSuccessful: false
            })
            return newState;
        case actionTypes.PASS_FROM_ACCOUNT:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: !action.payload.isInitialAccount
            })

        case actionTypes.PASS_PREDEFINED_TO_ACCOUNT:
            return state.merge({
                isPredefinedToAccount: action.payload.isPredefined
            });
        case actionTypes.PASS_TO_ACCOUNT:
        case actionTypes.PASS_AMOUNT:
        case actionTypes.PASS_CONCEPT:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.PASS_SET_FUTURE_TRANSFER:
            return state.merge({
                isFutureTransfer: true
            })
        case actionTypes.PASS_SET_NEW_ACCOUNT_NOT_FOUND_ERROR:
            return state.merge({
                newAccountNotFoundError: action.payload.isError
            })
        default:
            return state
    }
}

module.exports = passReducer;
