// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: true,
        emittedDateTime: 0,
        isEmitted: false,
        checked: true,
        checkedIsValid: true
    });
}

function validateStep(step) {
    const checkedIsValid = step.get('checked') || __CONTACT_CENTER__;

    return step.merge({
        valid: checkedIsValid,
        checkedIsValid
    });
}

function brokerWarrantsSellStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_SELL_FINISH:
            let newState = state.merge({
                isEmitted: true,
                emittedDateTime: moment(parseInt(action.payload.data))
            });
            return newState;
        default:
            return state;
    }
}

module.exports = brokerWarrantsSellStep2;
