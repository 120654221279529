// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
});

const targetDate = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_UPDATE_TARGET_DATE_REQUEST:
            return state.set('isFetching', true);

        case actionTypes.MORTGAGE_REQUEST_UPDATE_TARGET_DATE_SUCCESS:
            return state.merge({
                error: null,
                isFetching: false
            });

        case actionTypes.MORTGAGE_REQUEST_UPDATE_TARGET_DATE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state;
    }
};

module.exports = targetDate;
