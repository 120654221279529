// @ vendors
const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        isFetching: false,
        error: false,
        success: true,
    });
}


function registerDocumentsReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_SUCCESS:
            return state.mergeDeep({
                isFetching: false,
                error: false,
                success: true,
            });
        case actionTypes.CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_FAILURE:
            return state.mergeDeep({
                isFetching: false,
                error: true,
                success: false,
            });
        case actionTypes.CONTRACT_METHOD_REGISTER_PRODUCT_DOCUMENTS_REQUEST:
            return state.mergeDeep({
                isFetching: true,
                error: false,
                success: false,                   
            })
        default:
            return state;
    }
}

module.exports = registerDocumentsReducer;
