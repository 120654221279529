// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');
const { hash } = require('utilities/hash');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, DATE_FILTER_NONE } = require('constants/index');

const initialState = Immutable.fromJS({
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        },
        amount: {
            isActive: false,
            from: {
                amount: null,
                currency: EURO_TEXT
            },
            to: {
                amount: null,
                currency: EURO_TEXT
            }
        },
        currency: {
            isActive: false,
            value: ''
        }
    }
});

function getAccountIban(account){
    return `${account.pais}${account.digitodecontrol}${account.codbban}`;
}

function parseTransfers(transfers, accountId) {
    let byId = {};
    let byOrder = [];

    transfers.forEach(movement => {
        const amount = get(movement, 'importeNominal.importe', 0);
        const date = movement.fechaRecepcion;
        const product = get(movement, 'referenciaTransfRecibida.producto');
        const number = get(movement, 'referenciaTransfRecibida.numerodeorden');

        const parsedTransfer = {
            'id': hash([amount, date, product, number]),
            'accountId': accountId,
            'beneficiaryName': movement.nombreBeneficiario,
            'concept': movement.concepto,
            'date': moment(date),
            'country': movement.pais,
            'amount': {
                amount,
                'currency': get(movement, 'importeNominal.divisa', EURO_TEXT)
            },
            'reference': {
                product,
                number
            },
            'type': get(movement, 'indicadorTipoTransferencia'),
            'numTrnsf': get(movement, 'numTrD7'),
            'isPass': movement.isPass //This flag indicates if the movement is a transfer or a pass
        }

        byId[parsedTransfer.id] = parsedTransfer;
        byOrder.push(parsedTransfer.id);
    });

    return {
        byId: byId,
        byOrder: byOrder
    };
}

function receivedTransfersReducer(state = initialState, action) {
    let parsedTransfers;

    switch (action.type) {
        case actionTypes.FETCH_RECEIVED_TRANSFERS_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {},
                }
            });
        case actionTypes.FETCH_RECEIVED_TRANSFERS_NEXT_PAGE_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loadingNextPage: true
                }
            });
        case actionTypes.FETCH_RECEIVED_TRANSFERS_SUCCESS:
            parsedTransfers = parseTransfers(action.payload.transfers, action.payload.accountId);
            return state.set(action.payload.accountId, Immutable.fromJS({
                    nextPage: action.payload.nextPage,
                    byOrder: parsedTransfers.byOrder,
                    byId: parsedTransfers.byId,
                    loading: false
                }));
        case actionTypes.FETCH_RECEIVED_TRANSFERS_NEXT_PAGE_SUCCESS:
            const currentMovements = state.get(action.payload.accountId);
            parsedTransfers = parseTransfers(action.payload.transfers, action.payload.accountId);
            return state.mergeDeep({
                [action.payload.accountId]: {
                    nextPage: action.payload.nextPage,
                    byOrder: currentMovements.get('byOrder').concat(Immutable.fromJS(parsedTransfers.byOrder)),
                    byId: parsedTransfers.byId,
                    loadingNextPage: false,
                    error: false
                }
            });
        case actionTypes.FETCH_RECEIVED_TRANSFERS_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    loadingNextPage: false,
                    error: action.payload.data
                }
            });
        case actionTypes.SET_RECEIVED_TRANSFERS_FILTERS:
            const filters = action.payload.filters;
            const dateFilterSelected = get(filters, 'date.selected') !== DATE_FILTER_NONE;
            // @TODO: we need to remove this hardcoded 0 and 9999999.99 and improve this logic
            const hasAmountFilters = (get(filters, 'amount.from.amount') !== null && get(filters, 'amount.from.amount') !== 0) ||
                (get(filters, 'amount.to.amount') !== null && get(filters, 'amount.to.amount') !== 9999999.99);
            const hasCurrencyFilter = !!get(filters, 'currency.value');
            const partial = state.mergeDeep({
                filters
            });

            return partial.mergeDeep({
                filters: {
                    date: {
                        isActive: dateFilterSelected,
                    },
                    amount: {
                        isActive: hasAmountFilters
                    },
                    currency: {
                        isActive: hasCurrencyFilter
                    }
                }
            });
        case actionTypes.RESET_RECEIVED_TRANSFERS_FILTER:
            const { filter } = action.payload;
            return state.setIn(['filters', filter], initialState.getIn(['filters', filter]));
        case actionTypes.CLEAR_RECEIVED_TRANSFERS_FILTERS:
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
        case actionTypes.FETCH_RECEIVED_TRANSFERS_DETAILS_REQUEST:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                fetchingDetails: true
            });
        case actionTypes.FETCH_RECEIVED_TRANSFERS_DETAILS_FAILURE:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                error: action.payload.error,
                fetchingDetails: false
            });
        case actionTypes.FETCH_RECEIVED_TRANSFERS_DETAILS_SUCCESS:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                details: {
                    amount: {
                        amount: get(action, 'payload.details.importeTransferencia.importe'),
                        currency: get(action, 'payload.details.importeTransferencia.divisa')
                    },
                    officeBank: {
                        center: get(action, 'payload.details.bancoOficina.centro'),
                        enterprise: get(action, 'payload.details.bancoOficina.empresa')
                    },
                    payer: get(action, 'payload.details.nombreOrdenante'),
                    beneficiary: get(action, 'payload.details.nombreBeneficiario'),
                    emissorBank: get(action, 'payload.details.nombreBanco'),
                    issueDate: get(action, 'payload.details.fechaEmision'),
                    valueDate: get(action, 'payload.details.fechaValor'),
                    originCountry: get(action, 'payload.details.paisOrigen'),
                    account: getAccountIban(get(action, 'payload.details.ctaAbono')),
                    transferType: get(action, 'payload.details.tipoTransferencia') || get(action, 'payload.details.desTipoTransfPe'),
                    transferExpenses: get(action, 'payload.details.desGastos'),
                    concept: get(action, 'payload.details.conceptos').join('')
                },
                fetchingDetails: false
            });
        default:
            return state
    }

}

module.exports = receivedTransfersReducer;
