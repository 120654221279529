const Immutable = require('immutable');

// TODO Add types to actionTypes file
const {
    CONFIRM_SEND_EXTRACTS_VIA_MAIL_REQUEST,
    CONFIRM_SEND_EXTRACTS_VIA_MAIL_SUCCESS,
    CONFIRM_SEND_EXTRACTS_VIA_MAIL_FAILURE,

    SEND_EXTRACTS_VIA_MAIL_RESET,
    SEND_EXTRACTS_VIA_MAIL_SET_VISIBLE_STEP,
    SEND_EXTRACTS_VIA_MAIL_VALIDATE,
    SEND_EXTRACTS_VIA_MAIL_SHOW_CANCEL_MODAL,
} = require('constants/contactCenter/actionTypes');

const {
    WIZARD_FIRST_STEP
} = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    currentStep: WIZARD_FIRST_STEP,
    showCancelModal: false,
    steps: [

        {},
        {},

        {
            hasSignaturePattern: true
        }
    ],
});

const createConfirmSendExtractsViaMailRequestState = previousState => {
    return previousState.merge({
        isFetching: true,
        hasBeenFetched: false,
        error: '',
    });
};

const createConfirmSendExtractsViaMailErrorState = (previousState, error) => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: false,
        error,
    });
};

const createConfirmSendExtractsViaMailSuccessState = previousState => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: true,
        error: '',
    });
};

const setVisibleStep = (previousState, step) => {
    return previousState.merge({
        currentStep: step,
    });
}

const validateStep = (previousState, step) => {
    return (step < INITIAL_STATE.get('steps').size) ? previousState.merge({
        currentStep: step + 1
    }) : previousState;
}

const showCancelModal = (previousState, show) => {
    return previousState.merge({
        showCancelModal: show
    });
}

function sendExtractsViaMail(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CONFIRM_SEND_EXTRACTS_VIA_MAIL_REQUEST:
            return createConfirmSendExtractsViaMailRequestState(state);
        case CONFIRM_SEND_EXTRACTS_VIA_MAIL_FAILURE:
            return createConfirmSendExtractsViaMailErrorState(state, action.payload.error);
        case CONFIRM_SEND_EXTRACTS_VIA_MAIL_SUCCESS:
            return createConfirmSendExtractsViaMailSuccessState(state);
        case SEND_EXTRACTS_VIA_MAIL_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case SEND_EXTRACTS_VIA_MAIL_VALIDATE:
            return validateStep(state, action.payload.step);
        case SEND_EXTRACTS_VIA_MAIL_SHOW_CANCEL_MODAL:
            return showCancelModal(state, action.payload.show);
        case SEND_EXTRACTS_VIA_MAIL_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
}

module.exports = sendExtractsViaMail;
