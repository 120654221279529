//@constants
const { GAP_URL_PRE, GAP_URL_PRO } = require('constants/contactCenter/gapUrl');
const { PRODUCTION_ENVIRONMENT, OCULTO_ENVIRONMENT } = require('constants/index');

const getGapUrl = (clientPersonType, clientPersonCode) => {
    const gapUrl =  (window.__ENV__ === PRODUCTION_ENVIRONMENT || window.__ENV__ === OCULTO_ENVIRONMENT) ? GAP_URL_PRO : GAP_URL_PRE;
    return `${gapUrl}servlet/PProxy?cmd=JAGap3&busqueda=1&tipoPersona=${clientPersonType}&codPersona=${clientPersonCode}`;
}

module.exports = {
    getGapUrl
}
