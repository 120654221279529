// @ vendors
const { fromJS } = require('immutable');
// @ constants
const {
    AUTHORIZATIONS_FILTER,
    EXPAND_ROW,
    FETCH_ACCOUNT_DEBITS_FAILURE,
    SET_AUTHORIZATIONS
} = require('constants/actionTypes');
import {
    AUTHORIZATION_ACTIVE,
    AUTHORIZATION_CANCELED,
    API_CENTER,
    API_ENTERPRISE_NUMBER
} from 'constants/index';
const { AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS } = require('constants/actionTypes');


const initialState = fromJS({})

const receiptNumber = (receipt) => {
    const { codprod, numsor } = receipt;
    return `${API_ENTERPRISE_NUMBER}${API_CENTER}${codprod || 0}${numsor}`;
}

const checkStatus = (auth) => {
    return ['S', 's'].indexOf(auth.indicadorAvisoSiNo) == -1 && ['LEV', 'SEV'].indexOf(auth.tipoAviso) == -1 ? AUTHORIZATION_ACTIVE : AUTHORIZATION_CANCELED;
}

const formatNoticeActivation = (noticeActivation) => {
    if (noticeActivation.tsAltaAviso) {
        const [date, time] = noticeActivation.tsAltaAviso.split(' ');
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}T${time}`;
    } else {
        return null;
    }
}

const createReceiptList = (authorizationsList) => {
    return authorizationsList.listaRecibos &&
        authorizationsList.listaRecibos.recibos &&
        authorizationsList.listaRecibos.recibos.length &&
        authorizationsList.listaRecibos.recibos.map(receipt => {
            return receiptNumber(receipt);
        })
}

const createAuthorizations = (authorizationsList, accountId) => {
    return authorizationsList.reduce((accumulator, auth) => {
        const id = `${auth.descClaveInterna}-${auth.refMandato}`
        return {
            ...accumulator,
            [id]: {
                ...auth,
                accountId,
                status: checkStatus(auth),
                id,
                noticeActivation: formatNoticeActivation(auth),
                receiptsIds: createReceiptList(auth)
            }
        }
    }, {});
}

const filterStatus = (status, authorizations, authorizationsIds) => {
    return authorizationsIds.filter( authorizationId => {
        return authorizations.get(authorizationId).get('status') == status;
    })
}

const filterAuthorizations = (authorizations, authorizationsIds, filters) => {
    let newAuthorizationsIds = authorizationsIds;
    if (filters) {
        if (filters.get('status') && filters.get('status').get('active')) {
            newAuthorizationsIds = filterStatus(filters.get('status').get('selected'), authorizations, authorizationsIds);
        }
    }
    return newAuthorizationsIds;
}

const authorizations = (state = initialState, action) => {

    switch (action.type) {
        case AUTHORIZATIONS_FILTER:
            const filteredAuthorizationsIds = filterAuthorizations(
                state.getIn([action.accountId, 'byId']),
                state.getIn([action.accountId, 'byOrder']),
                action.immFilters
            );
            return state.setIn([action.accountId, 'filteredIds'], filteredAuthorizationsIds);
        case EXPAND_ROW:
            return state.mergeDeep({
                [action.accountId]: {
                    expandedRowId: action.rowId
                }
            });
        case SET_AUTHORIZATIONS:
            const authorizations = action.authorizations.map(auth => `${auth.descClaveInterna}-${auth.refMandato}`);
            return state.merge({
                [action.accountId]: {
                    expandedRowId: '',
                    byOrder: authorizations,
                    byId: createAuthorizations(action.authorizations, action.accountId),
                    filteredIds: authorizations
                }
            });
        case FETCH_ACCOUNT_DEBITS_FAILURE:
            return state.mergeDeep({
                [action.accountId]: {
                    byId: {},
                    byOrder: [],
                    error: action.error,
                    expandedRowId: ''
                }
            });
        case AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS:
            return state
                .delete(action.sourceAccountId)
                .delete(action.destinationAccountId);
        default:
            return state;
    }
};

module.exports = authorizations;
