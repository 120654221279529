const terms = [
{ name: 'A City',
  definition: 'A City é a zona financeira de Londres.' }
,
{ name: 'Acção',
  definition: 'Uma acção é um título representativo de propriedade de parte de uma empresa. Para o investidor, as acções contêm um risco elevado uma vez que as suas cotações flutuam em função da oferta e da procura e não há garantia que aquele recupere o capital investido quando vende as acções. O detentor de acções também tem direito a voto em assembleias gerais e a receber dividendos em caso de distribuição. Contudo, será o último em termos de prioridade no produto de venda dos activos em caso de liquidação da empresa.' }
,
{ name: 'Acções cíclicas',
  definition: 'As acções cíclicas são aquelas cuja rendibilidade varia consoante a economia em geral. O valor das suas acções sobe em períodos de crescimento e desce em períodos de recessão. Entre as sectores cíclicos destacam-se o automóvel, a construção e as indústrias de transformação.' }
,
{ name: 'Acções Preferenciais',
  definition: 'As acções preferenciais são acções que pagam um dividendo pré-determinado (muito parecido com os cupões dos títulos de renda fixa) mas sem direito de voto (excepto em circunstâncias excepcionais). Pelo contrário, o dividendo das acções comuns é muito variável e pode não existir.' }
,
{ name: 'Activos Líquidos',
  definition: 'Ver <a href="Default.aspx?lang=es&amp;keyword=TotNA&amp;HelpType=S">Património</a>.' }
,
{ name: 'ADRs',
  definition: 'Os ADRs (American Depositary Receipts) são títulos negociáveis detidos por um Banco dos Estados Unidos e representativos de um determinado número de acções estrangeiras. As empresas não americanas mas que querem ser cotadas numa bolsa norte-americana têm de emitir ADRs.' }
,
{ name: 'Alfa',
  definition: 'O alfa é o rendimento adicional obtido por um fundo relativamente ao seu índice de referência ou benchmark tendo em conta a exposição deste fundo ao risco de mercado (medido pelo <a href="Default.aspx?lang=es&amp;keyword=Beta&amp;HelpType=S">Beta</a>).' }
,
{ name: 'Alocação de activos',
  definition: 'A alocação de activos é a repartição da carteira do fundo pelas diversas categorias de activos financeiros (acções, obrigações, liquidez...) e activos tangíveis (imóveis, matérias primas, metais preciosos, ...). Inclui o impacto económico das posições de derivados do fundo.' }
,
{ name: 'American Stock Exchange (AMEX)',
  definition: 'O American Stock Exchange (o AMEX) é o segundo maior mercado dos Estados Unidos. O AMEX centra-se em empresas de pequena ou média dimensão em termos de capitalização.' }
,
{ name: 'Amsterdam Exchange (AEX)',
  definition: 'O Amsterdam Exchange é a bolsa mais importante da Holanda. Inclui o AEX-Effectenbeurs, o AEX-Optiebeurs y o AEX-Agrarische Termijnmarkt. O AEX forma agora parte do Euronext juntamente com as bolsas de Lisboa, Paris e Bruxelas.' }
,
{ name: 'Análise do Resultado',
  definition: 'A análise do resultado obtido por um determinado fundo é um processo que se desenvolve em duas etapas. Primeiro compara-se a rendibilidade do fundo relativamente ao seu índice de referência. Depois analisam-se os métodos utilizados pelos gestores para a obtenção desse resultado.' }
,
{ name: 'Assessor Financeiro',
  definition: 'Um assessor financeiro é uma pessoa ou empresa que oferece os seus conselhos e serviços de investimento cobrando por esses serviços uma comissão fixa ou variável.' }
,
{ name: 'Banco Central Europeu',
  definition: 'O Banco Central Europeu (BCE) foi fundado em 1 de Junho de 1998 e tem a sua sede em Frankfurt. O seu objectivo primordial consiste em manter a estabilidade dos preços mas também é responsável por definir a política monetária para toda a zona Euro.' }
,
{ name: 'Banco de Inglaterra',
  definition: 'Foi fundado em 1694 e supervisiona todo o sistema financeiro no Reino Unido. Começou a definir taxas directoras (a mais conhecida é a taxa repo) em 1997.' }
,
{ name: 'BCE',
  definition: 'Ver <a href="Default.aspx?lang=es&amp;keyword=bce&amp;HelpType=S">Banco Central Europeu</a>.' }
,
{ name: 'Benchmark',
  definition: 'Um benchmark de um determinado fundo de investimento é o índice de referência que se utiliza para comparar o seu comportamento num determinado período de tempo. Por exemplo para comparar a evolução de um fundo de acções portuguesas pode-se utilizar o PSI 20 como benchmark.' }
,
{ name: 'Beta',
  definition: 'O beta de um fundo ou de uma acção é uma medida da sensibilidade do fundo ou da acção aos movimentos (oscilações) do mercado. O beta do mercado é por definição igual a 1,00. Um beta de 1,10 significa que o fundo ou a acção teve, em média, uma rentabilidade 10% superior à do mercado quando este subiu e 10% pior que o mercado quando este desceu. Da mesma forma, um fundo ou acção com um beta de 0,85 teve um comportamento 15% pior que o mercado quando este subiu e 15% melhor quando o mercado desceu.' }
,
{ name: 'Blue Chip',
  definition: 'Uma blue chip é uma acção de elevada capitalização bolsista e liquidez.' }
,
{ name: 'Bolsa de Itália',
  definition: 'A Bolsa de Itália é o resultado da unificação, em 1991, das 10 bolsas nacionais. Situada em Milão, os seus principais índices são o MIB, o MIBTEL e o MIB 30.' }
,
{ name: 'Bottom-up',
  definition: 'Numa gestão bottom-up (de baixo para cima) o gestor analiza primeiro as empresas individualmente para a seguir extender a sua análise aos diferentes sectores e finalmente ao contexto macroeconómico geral.' }
,
{ name: 'Buy &amp; Hold',
  definition: 'Buy &amp; Hold é uma estratégia que consiste em o investidor  subscrever um fundo (ou qualquer outro título) e retê-lo durante um período relativamente longo de tempo. Esta estratégia é oposta à do trading a curto prazo ou "market timing".' }
,
{ name: 'CAC 40',
  definition: 'O CAC 40 é o índice base da bolsa de Paris. É composto pelas 40 principais acções francesas. O CAC 40 forma agora parte do Euronext juntamente com as bolsas de Lisboa, Amesterdão e Bruxelas.' }
,
{ name: 'Call',
  definition: 'Ver <a href="Default.aspx?lang=es&amp;keyword=Opcion&amp;HelpType=S">Opção</a>.' }
,
{ name: 'CAPM',
  definition: 'O CAPM (Capital Asset Pricing Model em inglês) é um modelo matemático utilizado para valorizar um título em função da relação entre o risco e a rendibilidade esperada. Este modelo é fundamental na teoria das carteiras em que a rendibilidade esperada (E) se expressa como função da rendibilidade esperada do mercado (Rm), da rendibilidade dos activos sem risco (R) e do <a href="Default.aspx?lang=es&amp;keyword=Beta&amp;HelpType=S">Beta</a>. Mais concretamente:  E = R + (Beta) (Rm – R).' }
,
{ name: 'Carteira',
  definition: 'A carteira do fundo é o conjunto de activos (acções, obrigações, ....) que o fundo possui.' }
,
{ name: 'Categoria Morningstar',
  definition: 'Os fundos classificam-se em função do seu estilo de gestão actual e não simplesmente em função do que diz o regulamento de gestão, nem tão pouco em função do facto de ter obtido uma determinada rendibilidade. Para que as categorias sejam homogéneas, a Morningstar classifica os fundos em função dos activos que tem a carteira. Analisam-se várias carteiras para assegurar que se toma em conta a política de investimento real do fundo.' }
,
{ name: 'Ciclo económico',
  definition: 'O ciclo económico consiste na alternância de períodos de expansão económica e de contracção, marcados por alterações em variáveis macro-económicas como as taxas de juros ou a produtividade.' }
,
{ name: 'Classes de activo',
  definition: 'As classes de activos correspondem às grandes categorias de investimentos que têm diferentes níveis de risco e rentabilidade, tais como as  acções, as obrigações, os imóveis ou a liquidez. As acções são as que oferecem maior potencial de rendibilidade mas com um elevado nível de risco. As obrigações distribuem um determinado rendimento com um grau de risco relativamente moderado. Os imóveis podem gerar rendimento e valorização mas têm menor liquidez. As aplicações em tesouraria têm menor rendibilidade esperada mas maior liquidez e segurança.' }
,
{ name: 'Coeficiente de liquidez',
  definition: 'O coeficiente de liquidez de um fundo de investimento é a parte do património do fundo (3% de mínimo) que por lei deve estar em numerário e em títulos de rendimento fixo a muito curto prazo e facilmente realizáveis para poder fazer face aos reembolsos dos participantes.' }
,
{ name: 'Começar',
  definition: 'Bem vindo ao Glossário da Morningstar. Introduza a palavra relativa ao tema que procura e pressione o icon "Pesquisar".' }
,
{ name: 'Comissão de corretagem',
  definition: 'A comissão de corretagem é a comissão a pagar ao broker pela compra ou venda de títulos. Varia em função do volume da ordem e do serviço associado a esta última.' }
,
{ name: 'Comissão de depósito',
  definition: 'A comissão de depósito é a comissão que cobra a entidade depositária pelo seu serviço.' }
,
{ name: 'Comissão de gestão',
  definition: 'É a comissão que cobra a entidade gestora pelos seus serviços. Ainda que se expresse como uma percentagem anual sobre o património (alguns fundos também cobram em função dos resultados obtidos ou uma combinação de ambos) desconta-se diariamente.' }
,
{ name: 'Comissão de gestão anual',
  definition: 'Ver <a href="Default.aspx?lang=es&amp;keyword=MngmntFee&amp;HelpType=S">Comissão de gestão</a>.' }
,
{ name: 'Comissão de Gestão e Distribução',
  definition: 'A comissão de gestão é a comissão que cobra a entidade gestora pelos seus serviços. Ainda que se expresse como uma percentagem anual sobre o património (alguns fundos também cobram em função dos resultados obtidos ou uma combinação de ambos) desconta-se diariamente. A comissão de distribuição é cobrada para fazer face aos serviços do distribuidor e é calculada da mesma forma que a comissão de gestão.' }
,
{ name: 'Comissão de performance',
  definition: 'Alguns fundos cobram <a href="Default.aspx?lang=pt&amp;keyword=MngmntFee&amp;HelpType=S">Comissão de gestão</a>, uma parte fixa e outra variável em função dos resultados obtidos pelo fundo. A comissão de performance é esta última parte que só é cobrada se atingidos os resultados pré definidos.' }
,
{ name: 'Comissão de resgate',
  definition: 'É a comissão que paga o investidor uma só vez no momento da venda das suas participações. Nem todos os fundos cobram essa comissão e geralmente diminuem em função do tempo durante o qual se manteve essas participações (quanto maior o tempo de permanência menor a comissão de resgate ).' }
,
{ name: 'Comissão de subscrição',
  definition: 'A comissão de subscrição só se cobra ao investidor quando este subscreve unidades do fundo. Esta comissão geralmente cobra-se como uma percentagem sobre a quantidade investida. Nem todos os fundos cobram essa taxa.' }
,
{ name: 'Comissão por transferência',
  definition: 'Comissão por transferência do fundo é a comissão que cobra a entidade gestora pela transferência de um fundo para outro dentro da mesma familia.' }
,
{ name: 'Corretor',
  definition: 'Um broker é uma empresa ou indivíduo que funciona como intermediário entre o comprador e o vendedor de títulos, cobrando uma comissão por transacção efectuada. A diferença entre um broker e um dealer está no facto do broker não ser detentor dos títulos que intermedeia.' }
,
{ name: 'Crescimento',
  definition: 'Utiliza-se o termo crescimento para aqueles fundos (chamados fundos de crescimento ou "growth funds") que investem as suas carteiras em acções de empresas com perspectivas de  forte crescimento nos seus lucros e/ou vendas.' }
,
{ name: 'Cupão',
  definition: 'O cupão de uma obrigação corresponde à remuneração que recebe o investidor pela compra da obrigação. Na maioria dos casos, no nosso país, o cupão é pago anualmente. Noutros países os cupões são pagos semestralmente.' }
,
{ name: 'Curva de rendimentos',
  definition: 'A curva de rendimentos relaciona o rendimento das obrigações e o seu prazo de vencimento. Aplica-se frequentemente a títulos de dívida pública. Geralmente a curva de rendimentos tem uma inclinação positiva ( i.e. rendimento mais elevado para as obrigações com prazos de vencimento mais longos ) mas podem verificar-se outras situações nomeadamente a curva invertida ou em formato de U.' }
,
{ name: 'Dados a',
  definition: 'Dados a... indica a data da carteira que temos na nossa base de dados. Uma das actividades da Morningstar é, com efeito, recolher, validar e avaliar as carteiras completas dos fundos de investimento para a seguir analisá-las.' }
,
{ name: 'Data',
  definition: 'Corresponde à data da última unidade de participação disponível.' }
,
{ name: 'Data de lançamento',
  definition: 'Corresponde à data de lançamento do fundo.' }
,
{ name: 'DAX',
  definition: 'O  DAX XETRA é o principal índice da bolsa alemã. Inclui as 30 principais acções alemãs.' }
,
{ name: 'Depositário',
  definition: 'É a entidade financeira responsável pela custódia dos activos do fundo assim como pelas subscrições.' }
,
{ name: 'Depreciação',
  definition: 'A depreciação de uma moeda corresponde a uma queda do seu valor face a outra moeda. Por exemplo, se na semana passada podíamos cambiar 1 euro por 0,95 dólares e esta semana 1 euro vale 0,90 dólares, então o valor do euro depreciou-se face ao dólar porque se pode adquirir menos dólares com o mesmo número de euros.' }
,
{ name: 'Derivado',
  definition: 'Derivado é o termo genérico para designar os produtos cujo preço está baseado num activo subjacente. Exemplos de derivados são os futuros, os warrants, os swaps e as opções.' }
,
{ name: 'Desconto sobre o valor de liquidação',
  definition: 'Quando um fundo fechado é cotado com um desconto sobre o valor do seu património líquido, isto significa que o valor do património líquido por acção é superior  à cotação do fundo em questão. Este desconto expressa-se geralmente em percentagem.' }
,
{ name: 'Desvalorização',
  definition: 'A desvalorização de uma divisa, num sistema de câmbios fixos, corresponde a uma queda do valor desta divisa em comparação com outra, queda que é geralmente consequência de uma decisão da respectiva autoridade monetária.' }
,
{ name: 'Desvio Padrão',
  definition: 'O desvio padrão de um fundo é uma medida do risco do fundo. Indica como é que, em termos médios, a rendibilidade de um fundo se desviou da sua média. Um desvio padrão elevado significa que a rendibilidade do fundo experimentou fortes variações e um desvio padrão baixo indica que a rendibilidade do fundo tem sido muito mais estável. Utiliza-se frequentemente a terminologia volatilidade para designar o desvio padrão. Este é um dado muito interessante para o investidor de dois prismas. Em primeiro lugar quanto maior a volatilidade da rendibilidade do fundo maior risco terá esse fundo. Por outro lado, permite comparar todos os fundos entre si, desde os fundos de acções aos de obrigações. Em segundo lugar, os fundos que têm sido mais voláteis no passado têm a tendência para serem mais voláteis no futuro. Nesse sentido o desvio padrão é um sinal de alerta muito útil. O desvio padrão expressa-se, tal como a rendibilidade, em percentagem. Os cálculos de desvio padrão por nós realizados utilizam valores de rendibilidade obtidos nos últimos 3 anos.' }
,
{ name: 'Distribuidor',
  definition: 'O distribuidor é a empresa responsável pela venda e marketing dos fundos aos investidores. Tem também a responsabilidade de enviar a documentação, como por exemplo folhetos e informações trimestais, aos participantes.' }
,
{ name: 'Diversificação',
  definition: 'A diversificação é a estratégia que consiste em distribuir os seus investimentos  por vários activos (acções, obrigações, mercados monetários ...) com o fim de reduzir o risco total da sua carteira.' }
,
{ name: 'Dividendo',
  definition: 'O dividendo é a remuneração paga por uma empresa aos seus accionistas por terem investido nela. Nem todas as empresas distribuem dividendos e as que o fazem podem fazê-lo num pagamento único ou em vários ao longo do ano.' }
,
{ name: 'Dow Jones Industrial Average',
  definition: 'O Dow Jones (ou mais exactamente O Dow Jones Industrial Average) é o índice da bolsa de Nova Iorque constituído em 1896 e inclui 30 empresas americanas de elevada capitalização e liquidez.' }
,
{ name: 'Duração',
  definition: 'A duração de um título de rendibilidade fixa é, simplificando bastante, uma medida (expressa em anos) do tempo médio que lhe falta para o seu vencimento. Serve também para medir o impacto que tem uma variação das taxas de juro sobre o seu preço.' }
,
{ name: 'Estrutura de capital',
  definition: 'A estrutura de capital refere-se à forma em que uma empresa está financiada pelos investidores. A escolha básica está em decidir se se financia via capital (acções) ou via dívida (obrigações). O rácio dívida-capital é uma das medidas mais utilizadas para definir a estrutura de capital de uma empresa.' }
,
{ name: 'ETF (Exchange-Traded Fund)',
  definition: 'Um ETF (Exchange Traded Fund) ou Fundo cotado em bolsa é um instrumento financeiro híbrido entre um fundo de investimento e uma acção. Tal como as acções, são cotados na bolsa de valores e, por isso, o seu preço varia ao longo da sessão. Contudo, a sua filosofia é muito parecida com a de um fundo índice e o seu objectivo é simplesmente reproduzir a composição de um índice específico.' }
,
{ name: 'Eurobond',
  definition: 'Chama-se eurobonds aos títulos emitidos fora de um determinado país mas expressos na divisa desse país (por exemplo eurobonds expressos em yenes japoneses emitidos fora do Japão).' }
,
{ name: 'Euronext',
  definition: 'Euronext é o resultado da fusão das bolsas de Paris, Amesterdão, Bruxelas e Lisboa..' }
,
{ name: 'Ex-dividendo / Ex-Cupão',
  definition: 'A palavra ex-dividendo ou ex-cupão refere-se a um título vendido sem o direito de receber o dividendo ou o cupão. Os títulos ex-dividendos vendem-se geralmente no período compreendido entre o anúncio e o pagamento do dividendo.' }
,
{ name: 'Exercício fiscal',
  definition: 'O exercício fiscal corresponde ao período de tempo (geralmente 12 meses) em que uma empresa publica a sua Demonstração de Resultados. Não tem necessariamente que coincidir com o ano civil.' }
,
{ name: 'Família de fundos',
  definition: 'Uma família de fundos é um grupo com distintas estratégias de investimento mas que pertence à mesma gestora. Uma família de fundos permite ao participante passar de um fundo para outro sem custos ou com um custo muito reduzido.' }
,
{ name: 'Federal Funds Rate',
  definition: 'Designam-se com este termo as taxas de juro a curto prazo nos Estados Unidos. Estabelecidas por <ahref="default.aspx?lang=pt &keyword="FMOC&amp;HelpType=S&quot;">FOMC (Federal Open Market Committee), a taxa dos Federal Funds Rate determina o custo ao qual os bancos norte-americanos aplicam fundos entre si.</ahref="default.aspx?lang=pt>' }
,
{ name: 'Federal Open Market Committee (FOMC)',
  definition: 'O FOMC (ou Federal Open Market Commitee) é o Comité da Reserva Federal norte americana que controla a política monetária dos Estados Unidos e estabelece níveis  de crescimento dos agregados monetários.' }
,
{ name: 'Financial Conduct Authority (FCA)',
  definition: 'O Financial Conduct Authority (FCA) é a entidade reguladora da City em Londres e, como tal, a responsável pela protecção dos investidores no Reino Unido.' }
,
{ name: 'Flagship Fund',
  definition: 'Geralmente denomina-se como "flagship fund" (fundo de referência em inglês) ao fundo mais antigo de uma gestora ou aquele que tem maior património. Estes fundos podem adquirir o mesmo nome da gestora.' }
,
{ name: 'FT 30',
  definition: 'O FT 30, também conhecido por FTSE Ordinary Share Index, data de 1935 e inclui 30 das maiores empresas do Reino Unido.' }
,
{ name: 'FTSE',
  definition: 'O FTSE, também conhecido como "Footsie", recebe o nome do Finantial Times (FT) e do London Stock Exchange (SE) que são seus co-proprietários. A família FTSE inclui muitos índices como o FTSE 100, 200 e o  techMARK, mas o mais conhecido é o FTSE 100. Geralmente quando se fala de FTSE refere-se ao FTSE 100.' }
,
{ name: 'FTSE 100',
  definition: 'O FTSE 100 teve início em 1984 como o índice que reúne as 100 maiores empresas, capitalização versátil, da bolsa de Londres (London Stock Exchange).' }
,
{ name: 'FTSE All Share Index',
  definition: 'O FTSE All Share Index contém as 900 maiores empresas cotadas na London Stock Exchange.' }
,
{ name: 'Fundo aberto',
  definition: 'Diferente de um fundo fechado, um fundo aberto não tem um número fixo de participações ou acções. O participante pode por isso entrar e sair do fundo subscrevendo e resgatando o número de unidades de participação desejadas.' }
,
{ name: 'Fundo de acções',
  definition: 'Um fundo de acções ou de renda variável é um fundo que investe nos mercados accionistas.' }
,
{ name: 'Fundo de capital garantido',
  definition: 'É uma classe particular de fundos que providencia ao cliente um determinado valor de reembolso e/ou rendibilidade desde que o participante detenha o fundo até à data de reembolso final.' }
,
{ name: 'Fundo de capitalização',
  definition: 'Um fundo de capitalização é um fundo que não distribui rendimentos aos subscritores. A grande maioria de fundos domiciliados no nosso país correspondem a esta definição.' }
,
{ name: 'Fundo de distribuição',
  definition: 'Contrariamente ao fundo de capitalização, o fundo de distribuição distribui dividendos aos seus participantes. São relativamente poucos os fundos comercializados no nosso país que correspondem a esta definição.' }
,
{ name: 'Fundo de investimento',
  definition: 'Um fundo de investimento é um instrumento de aforro que reúne o dinheiro de muitas participações para investi-lo numa série de activos (acções, títulos de rendimento fixo, activos de mercado monetário, etc. ) em função da política de gestão que a sociedade gestora tenha estabelecido.' }
,
{ name: 'Fundo de Investimento Imobiliário',
  definition: 'Fundo de Investimento Imobiliário. É uma categoria de fundos de investimento que investe o seu património em imóveis. A sua rendibilidade provêm tanto da venda desses imóveis como dos proveitos de rendas.' }
,
{ name: 'Fundo de Investimento Mobiliário',
  definition: 'Fundo de Investimento Mobiliário. Categoria de fundos de investimento que investe primordialmente em títulos de renda variável e/ou de rendimento fixo.' }
,
{ name: 'Fundo de obrigações de elevado rendimento',
  definition: 'Um fundo de obrigações "high yield" é um fundo de rendimento fixo que investe em títulos obrigacionistas que têm um rating mais baixo que os títulos com melhor qualidade creditícia.  Por isso oferecem um rendimento mais elevado porque o risco tembém é maior.' }
,
{ name: 'Fundo de renda variável',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=EqtyFnd&amp;HelpType=S">Fundo de acções</a>.' }
,
{ name: 'Fundo de rendimento fixo',
  definition: 'Um fundo de rendimento fixo é um fundo que investe em obrigações e cujo valor está inversamente relacionado com a evolução das taxas de juro. Uma subida das taxas de juros provoca uma queda do valor do fundo e uma descida das taxas de juro um aumento do valor do fundo.' }
,
{ name: 'Fundo de tesouraria',
  definition: 'Fundo de investimento em activos do mercado Monetário. Também conhecido como fundo de mercado monetário. É uma categoria de fundos de investimento que investe a sua carteira primordialmente em produtos de rendimento fixo a curto prazo.' }
,
{ name: 'Fundo do fundos',
  definition: 'Categoria de fundos de investimento que investe em participações de outros fundos de investimentos.' }
,
{ name: 'Fundo ético',
  definition: 'Um fundo ético é um fundo que inclui algum compromisso  ético na sua política de gestão, compromisso que pode bem traduzir-se na não incorporação na sua carteira de determinadas empresas ou sectores (por exemplo, empresas de armamento, tabaqueiras, ....) ou em dirigir os seus investimentos para determinados sectores ou empresas especialmente sensibilizados para um determinado tema ( ex: protecção do meio ambiente ).' }
,
{ name: 'Fundo fechado',
  definition: 'Os fundos fechados são fundos que se subdividem em um número fixo de acções cotadas na Bolsa. O preço das acções será determinado pela oferta e pela procura e não pelo seu valor de liquidação. Pode no entanto existir um desconto ou um prémio entre a cotação e o valor de liquidação do fundo.' }
,
{ name: 'Fundo global',
  definition: 'Um fundo global é um fundo com uma política de gestão muito flexível já que pode  investir a sua carteira  tanto em acções como em obrigações tanto nacionais como estrangeiras.' }
,
{ name: 'Fundo High Yield',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=Hgh-YldFnd&amp;HelpType=S">Fundo de obrigações de elevado rendimento</a>.' }
,
{ name: 'Fundo índice',
  definition: 'Um fundo índice é um fundo que tem como objectivo replicar um determinado índice bolsista ajustando o peso de cada título da sua carteira ao peso relativo que esse título tem no índice em questão.' }
,
{ name: 'Fundo misto',
  definition: 'Um fundo misto investe tanto em rendimento fixo como em acções.' }
,
{ name: 'Fundo misto',
  definition: 'Um fundo misto é um fundo que investe parte da sua carteira em acções e parte em obrigações.' }
,
{ name: 'Fundo monetário',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=FIAMM&amp;HelpType=S">Fundo de tesouraria</a>.' }
,
{ name: 'Fundo registado',
  definition: 'Um fundo registado é um fundo autorizado pela CMVM (Comissão do Mercado de Valores Mobiliários ) a ser comercializado no nosso país' }
,
{ name: 'Fundo sectorial',
  definition: 'Um fundo sectorial é um fundo que investe a sua carteira em acções de um determinado sector (farmacéutico, bancário, tecnológico, etc).' }
,
{ name: 'Fundo tracker',
  definition: 'Os fundos tracker são fundos que procuram replicar o comportamento de um determinado índice. Também são conhecidos como fundos índice.' }
,
{ name: 'Fundo umbrella',
  definition: 'Um fundo umbrella é um fundo composto por vários sub-fundos, cada um destes com a sua política de investimentos própria e que permite aos investidores a transferência de um sub-fundo para outro a um custo reduzido.' }
,
{ name: 'Fundo valor',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=Valor&amp;HelpType=S">Valor</a>.' }
,
{ name: 'Futuro',
  definition: 'Um futuro é um produto financeiro derivado mediante o qual o investidor se compromete tanto a comprar ou a vender um determinado activo financeiro ( ex: índice bolsista, acções,...) a um preço e uma data pré-determinados.' }
,
{ name: 'G7 (Grupo dos 7)',
  definition: 'O G7 é o grupo dos 7 países industriais mais importantes do mundo: Estados Unidos, Japão, Reino Unido, Alemanha, França, Itália e Canadá. De significativa importância são as reuniões dos ministros da economia e finanças deste grupo.' }
,
{ name: 'G8',
  definition: 'É o <a href="Default.aspx?lang=pt&amp;keyword=G7&amp;HelpType=S">G7</a> mais a  Rússia' }
,
{ name: 'GDR (Global Depositary Receipt)',
  definition: 'Um GDR é comparável a um ADR. É um título negociável representativo de um determinado número de acções estrangeiras.' }
,
{ name: 'Gestão activa',
  definition: 'Fala-se de gestão activa quando o gestor utiliza o seu próprio critério para determinar os títulos a incluir na carteira do fundo em vez de seguir um determinado índice ou benchmark.' }
,
{ name: 'Gestão de activos',
  definition: 'A gestão de activos de uma carteira corresponde à gestão de determinadas classes de activos como acções, obrigações ou liquidez.' }
,
{ name: 'Gestão passiva',
  definition: 'Uma gestão é passiva quando o gestor pretende apenas seguir o comportamento de um determinado índice. O gestor irá consequentemente constituir uma carteira que replique o respectivo índice ou benchmark.' }
,
{ name: 'Gestor',
  definition: 'O gestor ou equipa de gestores são contratados por uma entidade gestora e directamente responsáveis pela gestão do fundo.' }
,
{ name: 'Gestora',
  definition: 'A gestora é a entidade que se encarrega de pôr em prática a política de investimento do fundo. Pela sua função cobra a chamada comissão de gestão que é diariamente descontada ao valor líquido do fundo.' }
,
{ name: 'Growth &amp; income fund',
  definition: 'Um "growth and income fund" é um fundo no qual o gestor efectua investimentos com o objectivo de obter rendibilidade através de proveitos de dividendos como mais valias a longo prazo.' }
,
{ name: 'Growth Fund',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=Grwth&amp;HelpType=S">Crescimento</a>.' }
,
{ name: 'Hang Seng',
  definition: 'O Hang Seng é o índice que representa  33 das principais acções cotadas na bolsa de Hong Kong.' }
,
{ name: 'Hedge fund',
  definition: 'Um "hedge fund" é um fundo de investimento cujo objectivo é obter a maior rendibilidade possível utilizando todas as possibilidades de investimento ao alcance do gestor (como podem ser os instrumentos derivados) mas sem nenhum limite. Em geral considera-se que os "hedge funds" são fundos relativamente complexos e mais adequados a investidores profissionais ou com elevado património. Este tipo de fundos estão geralmente domiciliados em zonas fiscalmente eficientes.' }
,
{ name: 'IBEX 35',
  definition: 'Índice representativo da bolsa de Madrid que reúne 35 das acções espanholas de maior capitalização.' }
,
{ name: 'Income ratio',
  definition: 'O rendimento do fundo calcula-se dividindo o total dos rendimentos (dividendos no caso de fundos de acções ou cupões no caso dos fundos de rendimento fixo) líquidos (de custos) pelo valor patrimonial líquido médio do fundo.' }
,
{ name: 'Inflação',
  definition: 'A taxa de inflação mede o aumento dos preços num determinado período de tempo. Em Portugal utiliza-se o IPC (Índice de preços ao Consumidor), índice representativo de um conjunto de produtos e serviços, para medir a taxa de inflação.' }
,
{ name: 'International Securities Identification Number (ISIN)',
  definition: 'O ISIN é um código de identificação internacional, único para cada título ou fundo.' }
,
{ name: 'Investidor institucional',
  definition: 'É o termo genérico para designar uma organização com valores avultados em títulos e/ou fundos. Exemplos de investidores institucionais são os bancos, os fundos de pensões, as empresas de seguros e as sociedades ou fundos de investimento.' }
,
{ name: 'Investimento mínimo inicial',
  definition: 'O investimento mínimo inicial é o montante mínimo inicial que o participante deve investir para poder entrar no fundo.' }
,
{ name: 'IPC (Indice de Preços ao Consumidor)',
  definition: 'O IPC, Índice de Preços no Consumidor é um índice utilizado para medir a taxa de inflação.' }
,
{ name: 'IPO',
  definition: 'IPO são as siglas inglesas de "Initial Public Offering" e designa a operação inicial de oferta de títulos para posterior admissão à cotação em bolsa.' }
,
{ name: 'Junk Bond',
  definition: 'Uma "junk bond" é uma obrigação que oferece um rendimento muito alto para compensar a sua maior probabilidade de incumprimento. Entram na categoria de obrigações de elevado rendimento e são destinadas especificamente a investidores que queiram assumir um elevado risco.' }
,
{ name: 'Lagging indicators',
  definition: 'São indicadores económicos que seguem a evolução da actividade económica. Um exemplo deste tipo de indicador, numa economia que está a começar a crescer, poderia ser a diminuição do prazo médio de desemprego, enquanto numa economia que está com ritmo de crescimento a abrandar seria o aumento do prazo médio de desemprego.' }
,
{ name: 'Leading indicators',
  definition: 'Os "leading indicators" indicam o movimiento futuro da economía. Exemplos deste tipo de indicadores económicos são as horas de trabalho por semana na indústria, os preços das matérias primas e dos bens intermédios e as licenças de construção de novas habitações.' }
,
{ name: 'Liquidez',
  definition: 'A palavra liquidez tem dois sentidos. Quando falamos de liquidez de um fundo de investimento, referímo-nos a parte da carteira investida em rendimento fixo a muito curto prazo (repos, contas correntes, depósitos a curto prazo, títulos de tesouro, ....). Quando falamos da liquidez de um título referimo-nos à faculdade de comprar ou vender esse título sem impacto relevante na sua cotação.' }
,
{ name: 'London Interbank Offered Rate (LIBOR)',
  definition: 'O LIBOR (ou London Interbank Offered Rate) é a taxa de juro, no mercado de Londres a que os bancos realizam aplicações entre si.' }
,
{ name: 'London Stock Exchange (LSE)',
  definition: 'A Bolsa de Londres ou London Stock Exchange é o resultado da fusão, em 1973, de seis bolsas regionais da Grã Bretanha e Irlanda. O índice mais conhecido da LSE é o FTSE 100.' }
,
{ name: 'Mais valia',
  definition: 'A mais valia é um ganho obtido pela venda das participações de um fundo de investimento, igual à diferença entre o valor de venda e o de compra.' }
,
{ name: 'Market Timing',
  definition: 'O "Market Timing" é uma estratégia de investimento segundo a qual o investidor compra e vende acções ou fundos de investimento com o objectivo de beneficiar das oscilações do mercado (assim acredita o investidor) anticipados por um indicador económico ou técnico.' }
,
{ name: 'Média',
  definition: 'A linha "Média" mostra a média dos diferentes dados dos fundos que respondem aos critérios seleccionados. Os dados incluem as comissões de gestão, rendibilidades a 1 ano, 3 anos, etc, as Estrelas Morningstar, os detalhes do Style Box e os desvios padrão. Pode-se realizar uma pesquisa por gestora ou por sectores ou por uma combinação dos dois critérios. É importante realçar que a média é calculada sobre todos os fundos que correspondem ao critério de pesquisa.' }
,
{ name: 'Mediana',
  definition: 'Numa série, a mediana é o valor situado a meio da série. Dito de outra forma é o percentil 50. Por exemplo na série 1,4,7,8,10,11,23,45,52, a mediana é 10.' }
,
{ name: 'Mercado emergente',
  definition: 'Utiliza-se a expressão "mercado emergente" para designar um mercado financeiro de um país em desenvolvimento. Investir num  mercado emergente supõe assumir muito mais riscos do que fazê-lo num mercado de um país desenvolvido devido aos riscos tantos políticos, económicos, sociais e assim como à menor liquidez dos títulos.' }
,
{ name: 'MIB 30',
  definition: 'O MIB 30 é o principal índice da bolsa italiana. Reune  30 das principais acções cotadas na Bolsa de Valores de Milão.' }
,
{ name: 'Moeda do fundo',
  definition: 'É a divisa em que está expresso o fundo.' }
,
{ name: 'NASDAQ',
  definition: 'O Nasdaq (ou National Association of Securities Dealers Automated Quotations) é a segunda bolsa mais importante dos Estados Unidos. É o Mercado de acções tecnológicas por excelência. Nela estão cotadas empresas como a Cisco, Intel, Microsoft ou Dell Computers' }
,
{ name: 'New York Stock Exchange (NYSE)',
  definition: 'A Bolsa de Nova Iorque (NYSE - "New York Stock Exchange") é a maior e mais antiga bolsa dos Estados Unidos. Os principais índices cotados na NYSE incluem o S&amp;P 500 e o Dow Jones Industrial Average.' }
,
{ name: 'Nikkei 225',
  definition: 'O Nikkei 225 é o principal índice da bolsa japonesa. Reune 225 das principais empresas japonesas.' }
,
{ name: 'Objectivo do investimento',
  definition: 'É o objectivo estipulado no prospecto ou regulamento de gestão do fundo. Indica que objectivos privilegiam os gestores com a aplicação dos valores dos participantes.' }
,
{ name: 'Obrigação',
  definition: 'Uma obrigação é um título de rendimento fixo emitido por um Estado ou por uma empresa privada segundo o qual  o emitente se compromete a pagar durante a vida dessa obrigação cupões a uma taxa predeterminada e a reembolsar o capital na data de vencimento. Existem alguns casos particulares como as obrigações de cupão zero que não pagam cupão mas são emitidas a desconto.' }
,
{ name: 'Obrigação com reembolso antecipado',
  definition: 'É uma obrigação que, como o nome indica, pode ser amortizada antecipadamente (ou seja, antes do vencimento previsto) por decisão do emitente. Permite a este último refinanciar a sua dívida a uma taxa mais baixa no caso em que a taxa do cupão se situe acima das taxas de juro de mercado.' }
,
{ name: 'Obrigação convertível',
  definition: 'Uma obrigação convertível é uma obrigação emitida por uma empresa que pode no entanto ser convertida numa acção a um preço pré-estabelecido. A decisão de converter a obrigação em acção pode recair tanto sobre o emitente como sobre o detentor da obrigação.' }
,
{ name: 'Obrigação de cupão zero',
  definition: 'Uma obrigação de cupão zero é uma obrigação que não paga cupões mas é emitida a desconto. Por exemplo, o investidor que dispende 900 euros por uma obrigação de cupão zero a três anos, não receberá qualquer cupão durante esse período, mas no vencimento é reembolsado por 1000 euros.' }
,
{ name: 'Obrigação diversa',
  definition: 'Designa qualquer título de rendimento fixo emitido por uma empresa privada.' }
,
{ name: 'Obrigações de dívida pública',
  definition: 'Designa-se por obrigações de dívida pública qualquer título de dívida emitido por um Estado ou Nação.' }
,
{ name: 'OPA',
  definition: 'São as siglas de Oferta Pública de Aquisição. Fala-se de OPA quando uma empresa assume o controlo (compra as acções) de outra empresa seja de forma hostil ou amigável.' }
,
{ name: 'Opção',
  definition: 'Uma opção é um contrato mediante o qual o investidor tem a opção (mas não a obrigação) de compra (option call) e de venda (option put) relativamente a um determinado activo financeiro (como por exemplo acções) a um preço e a uma data pré-determinados.' }
,
{ name: 'OPV',
  definition: 'São as siglas de Oferta Pública de Venda. Fala-se de OPV quando é efectuada uma oferta pública de venda de parte do capital de uma empresa.' }
,
{ name: 'P/CF',
  definition: 'O Preço/Cash Flow ou P/CF é o rácio que mede a relação entre a cotação de um valor e o cash flow por acção.' }
,
{ name: 'P/E',
  definition: 'O PER ou P/E (Price Earnings Ratio em inglês) é um rácio que mede a relação entre o preço de uma acção e os resultados por acção. Geralmente as empresas de elevado crescimento têm um PER elevado mas as empresas mais maduras têm um PER mais baixo.' }
,
{ name: 'P/VC',
  definition: 'O Preço/Valor Contabilístico - Price/Book Value (P/B) em inglês - é um rácio financeiro calculado dividindo a cotação da acção pelo valor contabílistico da acção. Este indicador compara o preço de uma empresa face ao seu valor contabilístico. Em princípio, uma acção que se cote várias vezes acima do valor contabilístico poder-se-ia  assumir que se trata de uma acção de crescimento e que os investidores estariam prevendo o crescimento futuro do valor contabilístico.' }
,
{ name: 'Participação',
  definition: 'Uma participação de um determinado fundo de investimento corresponde simplemente a uma parte deste. Cada participação en si representa uma pequena parte da carteira do fundo. O preço dessa participação denomina-se valor da U.P.' }
,
{ name: 'Participante',
  definition: 'Utiliza-se esta designação para se referir ao investidor em fundos de investimento.' }
,
{ name: 'Passaporte comunitário',
  definition: 'No mundo dos fundos de investimento entende-se por passaporte comunitário a possibilidade de qualquer fundo registado num Estado Membro da União Económica e Monetária de poder ser livremente comercializado noutros Estados Membros.' }
,
{ name: 'Património',
  definition: 'Património do fundo medido na divisa em que está denominado o fundo.' }
,
{ name: 'PER',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=P/E&amp;HelpType=S">P/E</a>' }
,
{ name: 'Percentil',
  definition: 'Mede numa escala de 1 a 100 a rendibilidade do fundo comparada com a do resto dos fundos dessa mesma categoria. Quanto menor for esse número melhor o comportamento do fundo.' }
,
{ name: 'Perfil de Risco',
  definition: 'A seta que aparece no Perfil de Risco indica o risco do fundo medido pelo seu desvio padrão, a qual indica como, em termos médios, a rendibilidade do fundo se desviou da sua média. Um desvio padrão alto significa que a rendibilidade apresentou fortes oscilações e um desvio padrão baixo indica que a rendibilidade do fundo foi mais estável. Os fundos encontram-se distribuídos pelos diversos escalões de risco de acordo com a seguinte classificação: Risco baixo – desvio padrão anualizado inferior a 1,5%; Risco médio baixo – desvio padrão anualizado entre 1,5% e 5%; Risco médio – desvio padrão anualizado entre 5% e 10%; Risco médio alto desvio padrão anualizado entre 10% e 15%; Risco alto desvio padrão anualizado entre 15% e 20%; Risco muito alto desvio padrão anualizado superior a 20%.' }
,
{ name: 'Performance média',
  definition: 'A performance média anual do fundo nos últimos 3 anos.' }
,
{ name: 'Plano de Reforços Periódicos',
  definition: 'Um Plano de Reforços Periódicos é um plano que permite ao participante de um determinado fundo de investimento realizar investimentos periódicos segundo as condições estipuladas no regulamento da gestão do fundo (periodicidade, investimentos mínimos,...).' }
,
{ name: 'Ponto base',
  definition: 'Um ponto base corresponde a 0,01% e utiliza-se para medir a variação das taxas de juro. Por exemplo a um aumento das taxa de juro de 6.25% para 6.75%  corresponderia a um aumento de 50 pontos base.' }
,
{ name: 'Preço/Cash Flow',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=P/CF&amp;HelpType=S">P/CF</a>.' }
,
{ name: 'Preço/Valor Contabilístico',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=P/B&amp;HelpType=S">P/VC</a>.' }
,
{ name: 'Prémio sobre o valor de liquidação',
  definition: 'Quando um fundo (fechado) é cotado com um prémio sobre o seu valor patrimonial líquido, isto significa que o valor total dos títulos que fazem parte do fundo (valor líquido) é inferior à cotação do fundo em questão. Este prémio expressa-se normalmente em percentagem.' }
,
{ name: 'Prospecto',
  definition: 'Documento explicativo que reúne a informação necessária para que o investidor possa formular um juízo fundamentado sobre o investimento que lhe é proposto. Este documento deve ser registado na CMVM onde pode ser consultado.' }
,
{ name: 'Put',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=Opcion&amp;HelpType=S">Opção</a>.' }
,
{ name: 'Qualidade creditícia',
  definition: 'A qualidade creditícia é o valor de uma obrigação baseada nas suas condições financeiras em termos de qualidade (do emissor) e de segurança.' }
,
{ name: 'R quadrado',
  definition: 'R quadrado (R2) representa a percentagem de variação de rendibilidade da variável dependente (neste caso a rendibilidade do fundo de investimento) que pode ser explicado pela variável independente (a rendibilidade do índice de referência). Se utilizarmos por exemplo um fundo que siga de muito perto o Índice PSI 20 e efectuarmos uma regressão sobre esse índice obteremos um R2 igual a 1 ou, pelo menos, muito próximo de 1. Isso significa que praticamente todas as flutuações de rendibilidade do fundo podem explicar-se pela evolução do índice em questão.' }
,
{ name: 'Rácio de Sharpe',
  definition: 'O rácio de Sharpe é uma medida de rendibilidade-risco desenvolvida pelo prémio Nobel William Sharpe. É calculado com os dados dos últimos 36 meses dividindo o excesso de rendibilidade obtida pelo fundo (face à de um activo sem risco) pelo desvio padrão desse mesmo excesso de rendibilidade. Quanto maior o rácio de Sharpe melhor o comportamento do fundo no período analisado. O rácio de Sharpe mede, consequentemente, o excesso de rendibilidade por unidade de risco.' }
,
{ name: 'Ranking na categoria',
  definition: 'Mede numa escala de 1 a 100 a rendibilidade do fundo comparada com a do resto dos fundos dessa mesma categoria. Quanto menor for esse número melhor o comportamento do fundo.' }
,
{ name: 'Rating de crédito',
  definition: 'O Rating de crédito mede a probabilidade de incumprimento de uma determinada obrigação. Existem diversas empresas de rating que oferecem os seus sistemas de notação como a Moody´s, Standard &amp; Poor´s ou Fitch. As obrigações com rating desde AAA a BBB- são considerados como ´investment grade´ e as com rating de BB+ ou inferior são definidas como ´speculative grade´ .' }
,
{ name: 'Rating Morningstar',
  definition: 'O Rating Morningstar mede a rendibilidade ajustada ao risco de um determinado fundo relativamente à sua categoria Morningstar utilizando dados de rendibilidade dos últimos 36 meses ou 3 anos. Em função destes critérios classificamos os fundos da seguinte forma: os primeiros 10% recebem 5 estrelas, os 22,5% seguintes recebem 4 estrelas, os 35% seguintes recebem 3 estrelas, os 22,5% recebem 2 estrelas e os últimos 10% recebem uma estrela.' }
,
{ name: 'Relatório Anual',
  definition: 'O Relatório Anual é, como o nome indica, um documento, que por lei, a gestora tem de elaborar anualmente e que recolhe a situação do fundo à data do fim do ano assim como a sua demonstração de resultados.' }
,
{ name: 'Renda variável',
  definition: 'Utiliza-se o termo renda variável para designar as acções cotadas em bolsa.' }
,
{ name: 'Rendibilidade',
  definition: 'A rendibilidade mede como o investimento se valorizou ou desvalorizou num determinado período de tempo. Os investidores podem comparar a rendibilidade obtida pelo seu fundo com a de outros fundos com características semelhantes para ver se se comportou bem ou não.' }
,
{ name: 'Rendibilidade %',
  definition: 'Rendibilidade total, em euros, obtida por um fundo em cada ano civil, assim como a rendibilidade acumulada no ano em curso.' }
,
{ name: 'Rendibilidade acumulada',
  definition: 'A rendibilidade acumulada mede a rendibilidade num determinado período, seja ele um ano, um mês, uma semana ou um dia. Por exemplo, à data de 7 de Junho de 2001, a rendibilidade acumulada a um ano compreende o período que vai desde 7 de Junho de 2000 até 7 de Junho de 2001 e a rendibilidade semanal o que vai de 1 de Junho de 2001 a 7 de Junho de 2001.' }
,
{ name: 'Rendibilidade anualizada',
  definition: 'É a rendibilidade anual do fundo. Por exemplo se um fundo obtém uma rendibilidade de 5% em seis meses, então a sua rendibilidade anual será de 10,25%.' }
,
{ name: 'Rendibilidade corrigida de custos',
  definition: 'A rendibilidade ajustada ao custo consiste em incluir as comissões de cada fundo no cálculo da sua rendibilidade com o objectivo de compará-lo de forma homogénea com os outros fundos da mesma categoria.' }
,
{ name: 'Rendibilidade por dividendo',
  definition: 'A rendibilidade por dividendo de uma acção calcula-se dividindo o total dos dividendos pagos durante um determinado período de tempo pela cotação. Por exemplo, se uma acção cotada a 50 euros paga um dividendo de 1 euro, a rentabilidade é de 2% (1/50).' }
,
{ name: 'Rendidibidade no corrente ano',
  definition: 'Rendibilidade no ano indica a rendibilidade obtida pelo fundo no ano em curso. Por exemplo se consultar o site no dia 8 de Junho, a rendibilidade no ano indica simplesmente a rendibilidade acumulada pelo fundo desde 1 de Janeiro até à corrente data. As rendibilidades são actualizadas diariamente excepto nos Sábados, Domingos e Feriados.' }
,
{ name: 'Rendimento',
  definition: 'O rendimento é a rendibilidade (expressa em percentagem) que obtém um investidor por dividendos ou cupões sobre o seu investimento inicial. Não deve ser confundido com a rendibilidade total do investimento.' }
,
{ name: 'Rendimento fixo',
  definition: 'Designa-se por rendimento fixo qualquer título negociável, representativo de dívida quer seja de uma entidade pública ou de uma entidade privada.' }
,
{ name: 'Resultado Líquido por Acção',
  definition: 'O resultado por acção (ou Earnings per Share - EPS em inglês) é o rácio entre os resultados líquidos de uma empresa e o número total de acções dessa empresa.' }
,
{ name: 'Rotação',
  definition: 'A rotação de um fundo, que se calcula como uma percentagem sobre o património total, é uma forma de medir a actividade do fundo nos últimos 12 meses. Por exemplo, uma rotação de 20% significa que o valor das compras e vendas efectuadas pelo fundo representam um quinto do valor do seu património. Uma rotação de mais de 100% não significa que todos os títulos foram objecto de compra ou venda. Na prática esta estatística indica a percentagem da carteira que variou ao longo dos últimos 12 meses.' }
,
{ name: 'Securities and Exchange Commission (SEC)',
  definition: 'A SEC é uma agência federal norte-americana, fundada en 1934, que entre as suas atribuições inclui-se a protecção dos investidores norte-americanos de eventuais más prácticas nos mercados de capitais. A SEC define as regras e regulamentações que devem cumprir todas as empresas registadas nos Estados Unidos da América.' }
,
{ name: 'SEDOL',
  definition: 'SEDOL são as iniciais de Stock Echange Daily Official List. É um número de sete dígitos que identifica cada título ou fundo.' }
,
{ name: 'SICAV',
  definition: 'Os SICAV tem o seu domicilio fiscal geralmente no Luxemburgo. São muito parecidos com os fundos de inverstimento mas apresentam algumas diferenças. A mais relevante é a que as SICAV não se subdividem em unidades de participação mas em acções.' }
,
{ name: 'Sobre-exposto',
  definition: 'Utiliza-se o termo sobre-exposto para indicar que um gestor ou fondo investe num título ou sector da sua carteira um peso superior  ao que teria esse mesmo título ou sector no benchmark ou índice de referência.' }
,
{ name: 'Sociedade de investimento',
  definition: 'Uma sociedade de investimento é, assim como um fundo de investimento, uma instituição de investimento colectivo. No entanto diferentemente  dos fundos, as sociedades de investimento subdividem-se não em participações mas em acções e, por isso são cotadas em bolsa.' }
,
{ name: 'Split',
  definition: 'Efectua-se um split quando uma empresa aumenta o número das acções outstanding sem modificar a proporcão de acções por cada accionista. Permite aos pequenos inversores ter acesso a acções que têm uma cotação elevada.' }
,
{ name: 'Standard and Poor´s 500 Stock Index (S&amp;P 500)',
  definition: 'O S&amp;P 500, ou Standard and Poor’s 500, é um índice que agrupa 500 das empresas sediadas nos Estados Unidos da América.' }
,
{ name: 'Style Box de Morningstar',
  definition: 'O Morningstar Style Box ilustra gráficamente o estilo de gestão de um fundo de acções num determinado momento. O Style Box combina duas variáveis: a Dimensão (empresas de  capitalização elevada, média ou pequena) e o Estilo (Valor, Misto ou Crescimento) . O Style Box permite avaliar o estilo de gestão de um fundo, aspecto fundamental para uma boa repartição da carteira.  Encontrará mais detalhes e cálculos sobre o Morningstar Style Box em "Conheça-nos".' }
,
{ name: 'Sub-exposto',
  definition: 'Utiliza-se o termo sub-exposto para indicar que um fundo aplica a um determinado título ou a um determinado sector da sua carteira um peso inferior ao que tem no respectivo benchmark ou índice de referência.' }
,
{ name: 'Supermercado de fundos',
  definition: 'Um "supermercado de fundos" é uma entidade junto da qual o investidor pode comprar, por internet ou por telefone, fundos de diversas entidades gestoras.' }
,
{ name: 'Taxa de desconto',
  definition: 'A taxa de desconto é a taxa de juro utilizada para converter um investimento futuro num valor actual. Utilizando esta taxa de desconto um investidor pode saber, por exemplo, se está a pagar um preço justo por uma obrigação que paga um cupão anual de 50 Euros e com valor nominal de 1000 Euros. Utilizando a taxa de desconto pode-se calcular o valor actual dos rendimentos futuros dessa obrigação.' }
,
{ name: 'Teoria Moderna de Gestão de Carteiras',
  definition: 'Refere-se ao conjunto de inovacões introduzidas desde 1950 na gestão de carteiras. O conceito central da Teoria Moderna de Gestão de Carteiras é o da diversificação. Ou seja, com uma carteira de activos pode-se conseguir uma melhor relacção rendibilidade/risco do que com um activo individual. Outro conceito importante é o risco de mercado. O risco de mercado pode dividir-se em duas partes: por um lado as variações devidas a movimentos do mercado em geral ( vide Beta ) e por outro lado as variações independentes das oscilações do mercado em geral ( risco específico ).' }
,
{ name: 'Tesouraria',
  definition: 'Esta categoria de activos inclui numerário assim como activos monetários (títulos de tesouro, etc.)' }
,
{ name: 'Tracking error',
  definition: 'O tracking error descreve a volatilidade da diferença de rendibilidade entre um fundo e o seu benchmark. Consequentemente, os fundos de gestão passiva tendem a apresentar um tracking error mais baixo.' }
,
{ name: 'Transferência de fundo',
  definition: 'Refere-se à possibilidade de transferência das aplicações de um cliente de um fundo para outro.' }
,
{ name: 'UCITS',
  definition: 'UCITS são iniciais de "Collective Investment in Transferable Securities". Refere-se a uma directiva da União Europeia que estabelece as condições base sobre as quais um fundo domiciliado em um estado membro pode ser distribuido en todos os outros países membros. O objectivo é simplificar as regulamentações em matéria de investimentos e aumentar a protecção dos investidores.' }
,
{ name: 'União Europeia (UE)',
  definition: 'A União Europeia (UE) tem a sua origem no Tratado de Roma e na constituição de um mercado comum de 6 países em 1957, inicialmente conhecido por Comunidade Europeia. Uma moeda comum, um espaço comum europeu para a livre circulação de bens e serviços e uma aliança política formam a base da União Europeia.' }
,
{ name: 'Unit Linked',
  definition: 'United linked é um seguro misto de vida e de poupança que investe em fundos ou em carteiras de fundos de investimento.' }
,
{ name: 'UP',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=NAV&amp;HelpType=S">Valor da UP</a>.' }
,
{ name: 'Valor',
  definition: 'Utiliza-se a terminologia valor para designar um fundo (fundo de valor ou "value fund") que investe a sua carteira em acções relativamente “baratas” do ponto de vista dos seus rácios <a href="Default.aspx?lang=pt&amp;keyword=P/E&amp;HelpType=S">P/E</a> o <a href="Default.aspx?lang=pt&amp;keyword=P/CF&amp;HelpType=S">P/CF</a>.' }
,
{ name: 'Valor da UP',
  definition: 'O valor da UP de um fundo corresponde ao preço da UP. Calcula-se dividindo o património total do fundo pelo número de UP´s.' }
,
{ name: 'Value fund',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=Valor&amp;HelpType=S">Fundo valor</a>.' }
,
{ name: 'Vencimento',
  definition: 'O vencimento de uma obrigação é a data em que o investidor recebe o capital. Por exemplo, o vencimento a cinco anos de uma obrigação emitida a 1 de Novembro  de 2000 será a 1 de Novembro de 2005.' }
,
{ name: 'Venda a descoberto',
  definition: 'A venda a descoberto consiste na possibilidade de venda de títulos sem tê-los em carteira. O investidor ao realizar uma operação com estas características tem a expectativa de poder recomprar a preços mais baixos para assim obter um ganho de capital.' }
,
{ name: 'Volatilidade',
  definition: 'Ver <a href="Default.aspx?lang=pt&amp;keyword=StanDeviation&amp;HelpType=S">Desvio Padrão</a>' }
,
{ name: 'Zona Euro',
  definition: 'A zona Euro designa a área geográfica composta pelos 12 países seguintes: Alemanha, Austria, Bélgica, Espanha, Finlândia, França, Grécia, Holanda, Irlanda, Itália, Luxemburgo e Portugal.' }
]

module.exports = terms;