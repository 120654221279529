// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
     return Immutable.Map().merge({
        submitSuccessful: false,
        isFetching: false,
        errorReceived: false
    });
}

const initialState = setInitialState();

function sendN1Password(state = initialState, action) {
   switch (action.type) {
        case actionTypes.SEND_N1_PASSWORD_SET_STEP_SUCCESS:
            return state.merge(initialState,{
                submitSuccessful: true
            });
        case actionTypes.SEND_N1_PASSWORD_SET_STEP_ERROR:
            return state.merge(initialState,{
                errorReceived: true
            });
        default:
            return state
    }
}

module.exports = sendN1Password;
