const validateConfirmationCheckboxesForCC = (previousObjectValidated, confirmationCheckboxes, step, hasCheckboxes) => {
    let validationResult = previousObjectValidated;

    if (__CONTACT_CENTER__ && step === 1 && hasCheckboxes) {
        const checkboxesList = confirmationCheckboxes.get('list');
        const updatedCheckboxList = checkboxesList.map(
            item => item.set('errorState', item.get('isRequired') && !item.get('value'))
        );

        validationResult.isValidStep = confirmationCheckboxes.get('areValid');
        validationResult.confirmationCheckboxes = confirmationCheckboxes.set('list', updatedCheckboxList);
    }

    return validationResult;
}

module.exports = validateConfirmationCheckboxesForCC;
