const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const moment = require('moment');
const { getPeriodicityNextValidDate } = require('utilities/transfersHelper');

const {
    BENEFICIARY_TYPE_EXISTING,
    CARD_CHARGE_OPERATION_CHARGE,
    CARD_CHARGE_OPERATION_DISCHARGE,
    CARD_ECARD,
    ECARD_MAX_BALANCE,
    OPENYOUNG_MAX_BALANCE,
    PERIODICITY_CONSTANTLY,
    PERIODICITY_COUNT,
    PERIODICITY_COUNT_DEFAULT,
    PERIODICITY_WEEKLY,
    SAVINGS_REQUEST_VIEW
} = require('constants/index');

const calculatePeriodicityEstimatedEndDate = () => {
    const currentDate = moment().startOf('day').add(1, 'day')
    const periodicityEstimatedEndDate = currentDate.add(1, 'weeks');
    return periodicityEstimatedEndDate
}

function setInitialState() {
    return Immutable.fromJS({
        cardId: '',
        valid: false,
        amount: 0,
        cardBalance: 0,
        cardType: '',
        accountId: '',
        accountAvailableBalance: 0,
        operationDirty: false,
        accountDirty: false,
        amountDirty: false,
        amountMiniumReached: false,
        amountMaximumReached: false,
        amountAvailableinAccount: false,
        minDate: moment().startOf('day').add(1, 'day'),
        date: moment().startOf('day').add(1, 'day'),
        isToday: true,
        accountChosen: false,
        accountHasBalance: false,
        operationValid: true,
        operationType: CARD_CHARGE_OPERATION_CHARGE,
        periodicityEnabled: false,
        periodicityRange: PERIODICITY_WEEKLY,
        periodicityType: PERIODICITY_CONSTANTLY,
        periodicityMinDate: moment().startOf('day').add(1, 'week'),
        periodicityDate: moment().startOf('day').add(1, 'week'),
        periodicityEstimatedEndDate: calculatePeriodicityEstimatedEndDate(),
        periodicityCount: PERIODICITY_COUNT_DEFAULT,
        periodicityCountIsValid: true,
        periodicityConcept: '',
        periodicityConceptDirty: false,
        withdrawTotal: false,
        isEdit: false,
        editInfo: {},
        view: '',
    });
}

// If discharging, card balance should be positive
const checkOperationValid = (step) => {
    if (step.get('operationType') === CARD_CHARGE_OPERATION_CHARGE){
        return true
    } else {
        return step.get('cardBalance') > 0
    }
}

// Account should have been chosen
const checkAccountChosen = (step) => !!step.get('accountId')

// Account should have balance if we are charging the ecard
const checkAccountHasBalance = (step) => {
    if (step.get('operationType') === CARD_CHARGE_OPERATION_CHARGE){
        return step.get('accountAvailableBalance') > 0
    } else {
        return true
    }
}

// Amount should be more than cero on discharge, at least 10 euros in charge
const checkAmountMiniumReached = (step) => {
    const hasAmount = !!step.get('amount');
    return hasAmount && step.get('amount') > 0
}

// Amount plus card balance should be less than 1.650,00 euros
const checkAmountMaximumReached = step => {
    let maxBalance;
    let isMaxBalanceReached;
    const hasAmount = !!step.get('amount');
    const cardHasBalance = !!step.get('cardBalance');
    if(step.get('view') === SAVINGS_REQUEST_VIEW) {
        maxBalance = step.get('cardBalance');
        isMaxBalanceReached =  hasAmount && (parseFloat(step.get('amount')) <= maxBalance);
    } else if (step.get('operationType') === CARD_CHARGE_OPERATION_CHARGE){
        maxBalance = step.get('cardType') === CARD_ECARD ? ECARD_MAX_BALANCE : OPENYOUNG_MAX_BALANCE;
        if(step.get('periodicityEnabled')){
            isMaxBalanceReached =  hasAmount && parseFloat(step.get('amount')) <= maxBalance;
        } else {
            isMaxBalanceReached = hasAmount && parseFloat(step.get('amount')) + parseFloat(step.get('cardBalance') || 0) <= maxBalance;
        }
    } else {
        isMaxBalanceReached =  hasAmount && cardHasBalance && step.get('amount') <= step.get('cardBalance');
    }
    return isMaxBalanceReached;
}

// If charging, account should have enough balance. If discharging
const checkAmountAvailableinAccount = (step) => {
    const hasAmount = !!step.get('amount');
    if (step.get('operationType') === CARD_CHARGE_OPERATION_CHARGE){
        return hasAmount && step.get('amount') <= step.get('accountAvailableBalance');
    } else {
        return true;
    }
}

function validateAmount(step) {
    function checkAmount() {
        const isToday = step.get('isToday');
        const amount = step.get('amount');
        const fromAccountBalance = step.get('fromAccountBalance');
        const periodicityEnabled = step.get('periodicityEnabled');

        if (amount > 0) {
            // If no original account has been selected yet, we asume valid until we have a value to compare
            if (typeof fromAccountBalance !== 'number') {
                return true;
            }

            // original account has been selected
            if (amount <= fromAccountBalance) {
                // Original account has been selected and has enough balance
                return true;
            } else if (!isToday || (isToday && periodicityEnabled)) {
                // Original account has not enough balance, but the transfer is future so we omit checking
                return true;
            } else {
                const immBeneficiary = step.get('beneficiary');
                const beneficiaryCurrency =
                    immBeneficiary.get('type') === BENEFICIARY_TYPE_EXISTING
                        ? immBeneficiary.getIn(['existing', 'currency'])
                        : immBeneficiary.getIn(['new', 'currency']);

                if (step.get('fromAccountCurrency') !== beneficiaryCurrency) {
                    // If origin account currency is different than destination currency
                    // we cannot make a conversion between currencies, so we assume is valid.
                    return true;
                }
            }
        }
        return false;
    }

    return step.merge({
        amountIsValid: checkAmount()
    });
}

function validateFromAccount(step) {
    function checkFromAccount() {
        const fromAccountBalance = step.get('fromAccountBalance');
        const isToday = step.get('isToday');
        const periodicityEnabled = step.get('periodicityEnabled');

        if (typeof fromAccountBalance === 'number' && fromAccountBalance > 0) {
            // original account balance has funds
            return true;
        } else if (!isToday || (isToday && periodicityEnabled)) {
            // the transfer will be on a future date
            return true;
        }
        return false;
    }

    return step.merge({
        fromAccountIsValid: !!step.get('fromAccount') && checkFromAccount()
    });
}

function validateStep(step) {

    // Validate from account section
    let currentState = validateFromAccount(step);

    // Validate amount section
    currentState = validateAmount(currentState);

    const amountMiniumReached = checkAmountMiniumReached(step);
    const amountMaximumReached = checkAmountMaximumReached(step);
    const amountAvailableinAccount = checkAmountAvailableinAccount(step);
    const operationValid = checkOperationValid(step);
    const accountChosen = checkAccountChosen(step);
    const accountHasBalance = checkAccountHasBalance(step);

    let periodicityCountIsValid;
    let isValid;
    const amount = step.get('amount');
    const operationType = step.get('operationType');
    const periodicityEnabled = currentState.get('periodicityEnabled');
    const periodicityConcept = currentState.get('periodicityConcept');
    const periodicityConceptDirty = (periodicityConcept.length === 0 ? true: false)
    const maxBalance = step.get('cardType') === CARD_ECARD ? ECARD_MAX_BALANCE : OPENYOUNG_MAX_BALANCE;
    const startDate = step.get('date');
    const currentDate = moment().startOf('day');
    const isStartDateValid = (startDate > currentDate);

    if (!periodicityEnabled || operationType === CARD_CHARGE_OPERATION_DISCHARGE) {
        isValid = amountMiniumReached
            && amountMaximumReached
            && amountAvailableinAccount
            && accountChosen
            && accountHasBalance
            && operationValid;
    } else {
        isValid = amountMiniumReached
            && operationValid
            && accountChosen
            && amount <= maxBalance
            && !periodicityConceptDirty
            && isStartDateValid
    }
    // Validate periodicity section
    if (periodicityEnabled) {
        const periodicityType = currentState.get('periodicityType');
        // Periodicty is valid if enabled, periodicty type is not count, or periodicity count is valid
        periodicityCountIsValid = periodicityType !== PERIODICITY_COUNT || currentState.get('periodicityCountIsValid');

        isValid = isValid && periodicityCountIsValid;
    }

    const output = {
          amountMiniumReached,
          amountMaximumReached,
          amountAvailableinAccount,
          accountChosen,
          accountHasBalance,
          operationValid,
          valid: isValid
    }
    if(periodicityEnabled) output.periodicityConceptDirty = periodicityConceptDirty
    return step.merge(output);
}

function cardChargeStep1(state = setInitialState(), action, view) {
    const today = moment().startOf('day');
    let nextDate, newState;
    switch (action.type) {
        case actionTypes.CARD_CHARGE_SET_CARD:
            return state.merge({
                cardId: action.payload.cardId,
                cardBalance: action.payload.cardBalance,
                cardType: action.payload.cardType,
                view: view
            })
        case actionTypes.CARD_CHARGE_VALIDATE_UI_STEP:
            return validateStep(state.merge({
                operationDirty: true,
                accountDirty: true,
                amountDirty: true,
                view: view
            }));
        case actionTypes.CARD_CHARGE_AMOUNT:
            return validateStep(state.merge({
                amount: action.payload.amount,
                amountDirty: true,
                view: view
            }));
        case actionTypes.CARD_CHARGE_ACCOUNT:
            return validateStep(state.merge({
                accountId: action.payload.accountId,
                accountAvailableBalance: action.payload.accountAvailableBalance,
                accountDirty: true,
                view: view
            }));
        case actionTypes.CARD_CHARGE_OPERATION_TYPE:
            return validateStep(state.merge({
                operationType: action.payload.operationType,
                operationDirty: true,
                view: view
            }));
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_TYPE:
            return state.merge({
                periodicityType: action.payload.type,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true,
                periodicityDate: moment(state.get('periodicityMinDate'))
            });
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_DATE:
            return state.merge({
                periodicityDate: action.payload.date
            });
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_COUNT:
            return state.merge({
                periodicityCount: action.payload.count,
                periodicityEstimatedEndDate: action.payload.estimatedDate,
                periodicityCountIsValid: action.payload.count > 0
            });
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_RANGE:
            nextDate = getPeriodicityNextValidDate(action.payload.range, state.get('date'));
            return state.merge({
                periodicityRange: action.payload.range,
                periodicityType: PERIODICITY_CONSTANTLY,
                periodicityMinDate: nextDate,
                periodicityDate: nextDate,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true
            });
        case actionTypes.CARD_CHARGE_DATE:
            nextDate = getPeriodicityNextValidDate(
                state.get('periodicityRange'),
                action.payload.date
            );
            newState = state.mergeDeep({
                date: action.payload.date,
                periodicityType: PERIODICITY_CONSTANTLY,
                periodicityMinDate: nextDate,
                periodicityDate: nextDate,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true,
                isToday: action.payload.date.isSame(today),
                beneficiary: {
                    email: {
                        value: '',
                        isValid: true
                    }
                }
            });
            return validateStep(newState);
        case actionTypes.CARD_CHARGE_TODAY_DATE:
            state.mergeDeep({
                date: action.payload.date,
            });
            return state;
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_ENABLED:
            return state.mergeDeep({
                periodicityEnabled: action.payload.enabled,
                beneficiary: {
                    email: {
                        value: '',
                        isValid: true
                    }
                }
            });
        case actionTypes.CARD_CHARGE_SET_PERIODICITY_CONCEPT:
            return state.merge({
                periodicityConcept: action.payload.periodicityConcept,
                periodicityConceptDirty: action.payload.periodicityConceptDirty
             });
        case actionTypes.CARD_WITHDRAW_TOTAL_AMOUNT:
            return state.merge({
                withdrawTotal: action.payload.checked,
                view: view
             });
         case actionTypes.CARD_CHARGE_VIEW:
             return state.merge({
                 view: action.payload.view
            });
        case actionTypes.CARD_CHARGE_EDIT_STEP1:
            const data = action.payload.data;
            return state.mergeDeep({
                isEdit: data.isEdit,
                editInfo: data.editInfo
            });
        default:
            return state;
    }
}

module.exports = cardChargeStep1;
