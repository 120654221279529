// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');
const { ONLY_LETTERS_AND_NUMBERS_REGEX } = require('constants/index');

const initialState = Immutable.fromJS({
    byId: {},
    editingById: {
        new: {
            country: {
                isDirty: false,
                isValid: false,
                value: '',
            },
            isEditing: true,
            isValid: false,
            reason: {
                isDirty: false,
                isValid: false,
                value: '',
            },
            tin: {
                isDirty: false,
                isValid: false,
                value: '',
            },
        },
    },
    hasTaxData: {
        isDirty: false,
        isValid: false,
        value: false,
    },
    isValid: true,
    validatedStep: false,
});

const validateRequiredField = (state, id, fieldName) => !!state.getIn(['editingById', id, fieldName, 'value']);

const validateRegex = (state, id, fieldName, regex) => regex.test(state.getIn(['editingById', id, fieldName, 'value']));

const validateTin = (state, id) => validateRequiredField(state, id, 'tin') && validateRegex(state, id, 'tin', ONLY_LETTERS_AND_NUMBERS_REGEX) && 
    state.getIn(['editingById', id, 'tin', 'value']).length > 1;

const validateFormField = (state, id, fieldName) => {
    let isValid;
    let validatedFormField = {
        editingById: {
            [id]: {
                [fieldName]: {
                    isDirty: true,
                }
            }
        }
    };

    switch (fieldName) {
        case 'tin':
            isValid = validateTin(state, id);
            break;

        default:
            isValid = validateRequiredField(state, id, fieldName);
            break;
    }
    validatedFormField.editingById[id][fieldName].isValid = isValid;

    return validatedFormField;
};

const validateForm = (state, id) => {
    const countryIsValid = validateRequiredField(state, id, 'country');
    const reasonIsValid = validateRequiredField(state, id, 'reason');
    const tinIsValid = validateTin(state, id);
    const isValid = countryIsValid && reasonIsValid && tinIsValid;

    return {
        countryIsValid,
        isValid,
        reasonIsValid,
        tinIsValid,
    };
};

// If the user is still editing or adding data, we don't allow to move forward
const validateStep = (state) => {
    const noTaxData = !state.getIn(['hasTaxData', 'value']);
    const stillEditing = state.get('editingById').delete('new').size > 0;
    const stillAdding = state.getIn(['editingById', 'new', 'isEditing']);
    const taxDataAdded = state.get('byId').size > 0;

    return state.mergeDeep({
        isValid: noTaxData || (taxDataAdded && !stillAdding && !stillEditing),
        validatedStep: true,
    });
};

const openYoungTaxData = (state = initialState, action = { type: null }) => {
    let country;
    let id;
    let newState;
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_TAX_DATA_RESET:
            return initialState;

        case actionTypes.OPEN_YOUNG_TAX_DATA_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.OPEN_YOUNG_TAX_DATA_VALIDATE_FORM_FIELD:
            return state.mergeDeep(
                validateFormField(state, action.payload.id, action.payload.fieldName)
            );

        case actionTypes.OPEN_YOUNG_TAX_DATA_VALIDATE_FORM:
            id = action.payload.id;
            const {
                countryIsValid,
                isValid,
                reasonIsValid,
                tinIsValid,
            } = validateForm(state, id);

            return state.mergeDeep({
                editingById: {
                    [id]: {
                        country: {
                            isDirty: true,
                            isValid: countryIsValid,
                        },
                        isValid,
                        reason: {
                            isDirty: true,
                            isValid: reasonIsValid,
                        },
                        tin: {
                            isDirty: true,
                            isValid: tinIsValid,
                        },
                    }
                }
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_SET_FIELD:
            return state.merge({
                [action.payload.field]: {
                    isDirty: true,
                    value: action.payload.value,
                },
                isValid: action.payload.field === 'hasTaxData' ? !action.payload.value : state.get('isValid'),
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_SET_FORM_FIELD:
            id = action.payload.id;
            return state.mergeDeep({
                editingById: {
                    [id]: {
                        [action.payload.fieldName]: {
                            isDirty: true,
                            isValid: true,
                            value: action.payload.value
                        },
                    }
                },
                validatedStep: false,
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_TOGGLE_ADD_FORM:
            return state.mergeDeep({
                editingById: {
                    new: {
                        isEditing: action.payload.visible,
                    }
                },
                validatedStep: false,
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_REMOVE:
            return state.merge({
                byId: state.get('byId').delete(action.payload.id),
                validatedStep: false,
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_START_EDIT:
            id = action.payload.id;
            return state.mergeDeep({
                editingById: {
                    [id]: state.getIn(['byId', id]).set('isEditing', true),
                },
                byId: {
                    [id]: {
                        isEditing: true,
                    }
                }
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_CANCEL_ADD_NEW:
            return state.mergeDeep({
                editingById: {
                    new: {
                        country: {
                            isDirty: false,
                            isValid: false,
                            value: '',
                        },
                        isEditing: false,
                        reason: {
                            isDirty: false,
                            isValid: false,
                            value: ''
                        },
                        tin: {
                            isDirty: false,
                            isValid: false,
                            value: ''
                        },
                    },
                },
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_CANCEL_UPDATE:
            id = action.payload.id;
            return state.withMutations(state => {
                state.mergeDeep({
                    byId: {
                        [id]: state.getIn(['byId', id]).set('isEditing', false),
                    }
                });
                state.set('editingById', state.get('editingById').delete(id));
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_UPDATE:
            id = action.payload.id;
            return state.withMutations(state => {
                state.mergeDeep({
                    byId: {
                        [id]: state.getIn(['editingById', id]).set('isEditing', false),
                    }
                });
                state.set('editingById', state.get('editingById').delete(id));
            });

        case actionTypes.OPEN_YOUNG_TAX_DATA_ADD_NEW:
            id = action.payload.id;
            country = state.getIn(['editingById', id, 'country', 'value']);
            if (id === 'new') {
                newState = state.mergeDeep({
                    editingById: {
                        new: {
                            country: {
                                isDirty: false,
                                isValid: false,
                                value: '',
                            },
                            isEditing: false,
                            isValid: false,
                            reason: {
                                isDirty: false,
                                isValid: false,
                                value: ''
                            },
                            tin: {
                                isDirty: false,
                                isValid: false,
                                value: ''
                            },
                        },
                    },
                    byId: {
                        [country]: state.getIn(['editingById', id]).set('isEditing', false),
                    },
                });
            } else {
                newState = state.mergeDeep({
                    byId: {
                        [id]: state.getIn(['editingById', id]).set('isEditing', false),
                    },
                });
            }

            return newState;
        case actionTypes.OPEN_YOUNG_TAX_DATA_FILL_OPENYOUNG_PROFILE:
            const immCountries = action.payload.immCountriesTributation;
            const hasTaxData = immCountries.size > 0;
            let countriesTributation = {};
            if (hasTaxData) {
                immCountries.forEach((immCountryInfo) => {
                    countriesTributation[immCountryInfo.get('country')] = {
                        country: {
                            isDirty: false,
                            isValid: true,
                            value: immCountryInfo.get('country'),
                        },
                        isEditing: false,
                        isValid: true,
                        reason: {
                            isDirty: false,
                            isValid: true,
                            value: immCountryInfo.get('reason'),
                        },
                        tin: {
                            isDirty: false,
                            isValid: true,
                            value: immCountryInfo.get('tin'),
                        },
                    }
                });
            }
            return state.mergeDeep({
                byId: countriesTributation,
                editingById: {
                    new: {
                        isEditing: false,
                    }
                },
                hasTaxData: {
                    value: hasTaxData,
                },
            });

        default:
            return state;
    }
};

module.exports = openYoungTaxData;
