const { ALPHANUMERIC_CHARACTERS } = require('constants/index');
module.exports = {

    id_component: 'grid-form',
    label: 'contractsView-juniorAccountTaxData',
    id: 'tab-form-tax-data',
    exclude: [],
    buildCondition: 'juniorAccount_showBeneficiaryDataForms',
    grid: [
        [
            {
                id_component: 'radio-button',
                id: 'radio_obligaciones_fiscales',
                label: 'contractsView-juniorAccountTaxObligations',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                options: [{
                    'label': 'contractsView-juniorAccountTaxObligationsNo',
                    'id': 0
                },
                {
                    'label': 'contractsView-juniorAccountTaxObligationsYes',
                    'id': 1
                }],
                order: 3,
                target: 'junior_form_tax_data',
                required: true,
                default_value: 0
            }
        ],
        [
            {
                id_component: 'tax-additional-country',
                id: 'tax-additionalcountries',
                label: 'contractsView-juniorAccountTaxCountry',
                type: 'form',
                target: 'junior_form_tax_data',
                sourceCountries: 'juniorAccount_loadCmbCountries',
                sourceTaxReasons: 'juniorAccount_loadCmbTaxReason',
                labelCountry: 'contractsView-juniorAccountTaxCountry',
                tinLength: 30,
                tinRegex: ALPHANUMERIC_CHARACTERS,
                labelTaxReason: 'contractsView-juniorAccountTaxReason',
                labelTinCode: 'contractsView-juniorAccountTaxTinCode',
                required: true,
                visible: {
                    target: 'junior_form_tax_data.radio_obligaciones_fiscales',
                    value: [1]
                }
            }
        ]
    ]
}
