// @vendor
import hash from 'utilities/hash';
import get from 'lodash/object/get';
import Storage from 'utilities/storage';

// @setter
import { setInitialState } from './profile.model';

// @logic
import { generateDevicesList, generateCollectiveID } from './profile.logic';

// @actionTypes
import {
    FETCH_PROFILE_REQUEST,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAILURE,
    PROFILE_UPDATE_PICTURE,
    PROFILE_SET_ALIAS_IN_PROGRESS,
    PROFILE_SET_ALIAS_ERROR,
    PROFILE_SET_ALIAS_SUCCESS,
    PROFILE_EDIT_AVATAR_MODAL,
    PROFILE_EDIT_AVATAR_ERROR,
    PROFILE_SET_BROWSING_LANGUAGE_IN_PROGRESS,
    PROFILE_SET_BROWSING_LANGUAGE_SUCCESS,
    PROFILE_SET_BROWSING_LANGUAGE_ERROR,
} from 'constants/actionTypes';

// @constants
import { GENDER_MALE } from 'constants/index';
import defaultMaleAvatar from 'assets/images/avatar-male-default.svg';
import defaultFemaleAvatar from 'assets/images/avatar-female-default.svg';
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');

const reducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case FETCH_PROFILE_REQUEST:
            return state.merge({
                ...(__CONTACT_CENTER__ ? { error: null } : {}),
                isFetching: true
            });
        case FETCH_PROFILE_SUCCESS:
            const profile = get(action, 'profile', {});
            const fullname = get(action, 'fullname', '');
            const firstCountry = get(profile, 'listPaisesTributacionAdic.tributAdicional[0]');
            if (get(profile, 'segmentDigilant')) {
                Storage.localStorage.set('segmentDigilant', profile.segmentDigilant);
            }
            else {
                Storage.localStorage.set('segmentDigilant', 'N/A');
            }

            let listPaisesTributacionAdic;
            if (firstCountry) {
                listPaisesTributacionAdic = firstCountry.indTipoTributacion === null && firstCountry.paisTributacion === null ? [] : profile.listPaisesTributacionAdic.tributAdicional;
            } else {
                listPaisesTributacionAdic = [];
            }
            const newState = state.set('listPaisesTributacionAdic', listPaisesTributacionAdic);

            let devicesList = get(profile, 'listaDispositivos.dispositivo', []) || [];

            if (get(profile, 'listaDispositivos')) {
                profile.listaDispositivos.dispositivo = generateDevicesList(devicesList);
            }

            let defaultAvatar = (profile.sexo === GENDER_MALE ? defaultMaleAvatar : defaultFemaleAvatar);
            const collectiveUserId = (profile.isCollective) ? generateCollectiveID(profile) : '';
            return newState.mergeDeep({
                alias: profile.alias || '',
                fullname,
                apellidoDos: profile.apellidoDos || {
                    apellido: '',
                    particula: null
                },
                apellidoUno: profile.apellidoUno || {
                    apellido: '',
                    particula: null
                },
                avatar: ((!profile.avatar) ? defaultAvatar : profile.avatar),
                browsingLanguage: profile.browsingLanguage,
                codigoPostal: profile.codigoPostal || '',
                isFetching: false,
                hasBeenFetched: true,
                listaDispositivos: profile.listaDispositivos,
                isCollective: profile.isCollective,
                collectiveId: collectiveUserId,
                collectiveName: profile.collectiveName || '',
                collectiveCompanyId: profile.collectiveType && profile.collectiveType.company || '',
                collectiveType: profile.collectiveType || {
                    code3: '',
                    code4: '',
                    company: ''
                },
                customerId: profile.customerId,
                isNerudaUser: profile.clientProfile === 2, // 1:normal user 2: neruda user
                isAresUser: profile.clientProfile === 3, // 3: ares user
                listPaisesTributacionAdic,
                mainLanguage: profile.mainLanguage && profile.mainLanguage.toUpperCase() || DEFAULT_ORIGIN_COUNTRY,
                nombreCliente: profile.nombreCliente || '',
                nombreCompleto: profile.nombreCompleto && profile.nombreCompleto.trim() || '',
                nombreDeVia: profile.nombreDeVia || '',
                nombrePlaza: profile.nombrePlaza || '',
                numDomicilio: profile.numDomicilio || '',
                numeroDeVia: profile.numeroDeVia || '',
                originCountry: profile.originCountry && profile.originCountry.toUpperCase() || DEFAULT_ORIGIN_COUNTRY,
                paisDomicilio: profile.paisDomicilio || '',
                paisNacimiento: profile.paisNacimiento || '',
                paisResidencia: profile.paisResidencia || '',
                paisNacionalidad: profile.paisNacionalidad || '',
                sexo: profile.sexo || '',
                fechaNacimiento: profile.fechaNacimiento || '',
                plaza: profile.plaza || {
                    codigovia: 0,
                    numeroversionvia: 0,
                    plaza: {
                        discriminanteplaza: null,
                        plaza: null,
                        provincia: ''
                    }
                },
                plazaDeNacimiento: profile.plazaDeNacimiento || '',
                provincia: profile.provincia || {
                    discriminanteplaza: 0,
                    plaza: 0,
                    provincia: ''
                },
                restoDomicilio: profile.restoDomicilio || '',
                telefonoContacto: profile.telefonoContacto || {},
                tipoDeVia: profile.tipoDeVia || '',
                documentoIdentificacion: {
                    tipodocumpersonacorp: profile.documentoIdentificacion.tipodocumpersonacorp,
                    codigodocumpersonacorp: profile.documentoIdentificacion.codigodocumpersonacorp,
                },
                codigoPersona: hash.getPersonTypeAndCode(),
                tratamiento: profile.tratamiento || '',
                numeroExpediente: profile.numeroExpediente || ''
            });
        case FETCH_PROFILE_FAILURE:
            return state.merge({
                error: action.profile.error,
                isFetching: false,
                hasBeenFetched: true
            });
        case PROFILE_UPDATE_PICTURE:
            return state.merge({
                avatar: action.currentlyVisibleImage,
                currentlyVisibleImage: action.currentlyVisibleImage
            });
        case PROFILE_SET_ALIAS_IN_PROGRESS:
            return state.merge({
                isSettingAlias: true
            });
        case PROFILE_SET_ALIAS_ERROR:
            return state.merge({
                settingAliasError: action.payload.error,
                isSettingAlias: false
            });
        case PROFILE_SET_ALIAS_SUCCESS:
            return state.merge({
                alias: action.payload.alias,
                isSettingAlias: false
            });
        case PROFILE_EDIT_AVATAR_MODAL:
            return state.merge({
                profileAvatarEditModalOpen: !state.get('profileAvatarEditModalOpen')
            });
        case PROFILE_EDIT_AVATAR_ERROR:
            return state.merge({
                profileAvatarEditError: action.payload.error
            });
        case PROFILE_SET_BROWSING_LANGUAGE_IN_PROGRESS:
            return state.merge({
                isSettingBrowsingLanguage: true
            });
        case PROFILE_SET_BROWSING_LANGUAGE_SUCCESS:
            return state.merge({
                browsingLanguage: action.payload.browsingLanguage,
                isSettingBrowsingLanguage: false
            });

        case PROFILE_SET_BROWSING_LANGUAGE_ERROR:
            return state.merge({
                settingBrowsingLanguageError: action.payload.error,
                isSettingBrowsingLanguage: false
            });

        default:
            return state;
    }
};

export default reducer;
