// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    data: {
        comunidadAutonoma: '',
        cuotaMensual: 0,
        estadoVivienda: '',
        finalidad: '',
        importeHipoteca: 0,
        importeVivienda: 0,
        cuotaMensualFormatted: 0,
        plazo: 0,
        tipoProducto: '',
        cuotaMensualResto: null,
    },
    feeData: {
        cuotaNm: 0,
        cuotaResto: 0,
    },
    error: '',
    req: false,
    reqFee: false,
    confirmed: false,
    isFetching: false,
    isFetchingFee: false,
    isFetchingUpdate: false,
    isFetchingDelete: false,
    isFetchingPersonalData: false,
    updateResult: {
        reduce_term: 0,
        add_holder_or_term: 0,
        add_holder_or_contact_us: 0,
        contact_us: 0
    },
    updateOk: false,
    hasBeenFetched: false,
});

const setField = (state, action) => {
    const { inputField, data } = action.payload;
    return state.merge({
        [inputField]: data
    });
};

const mortgageData = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.MORTGAGE_REQUEST_RESET_UPDATE_DATA_RESULT:
            return state.mergeDeep({
                updateResult: {
                    reduce_term: 0,
                    add_holder_or_term: 0,
                    add_holder_or_contact_us: 0,
                    contact_us: 0
                }
            });

        case actionTypes.MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_REQUEST:
            return state.merge({
                isFetchingUpdate: true
            });

        case actionTypes.MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_SUCCESS:
            return state.merge({
                ...action.data,
                isFetchingUpdate: false
            });

        case actionTypes.MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetchingUpdate: false
            });

        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DATA_SUCCESS:
            return state.merge({
                data: action.data.data,
                error: '',
                isFetching: false,
                req: true,
                hasBeenFetched: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_FEE_RESET:
            return state.mergeDeep({
                feeData: {
                    cuotaNm: 0,
                    cuotaResto: 0
                },
                reqFee: false
            });

        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_FEE_REQUEST:
            return state.merge({
                isFetchingFee: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_FEE_SUCCESS:
            const feeData = action.data.data;
            return state.merge({
                feeData,
                error: '',
                isFetchingFee: false,
                reqFee: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_FEE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetchingFee: false
            });

        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DELETE_REQUEST:
            return state.merge({
                isFetchingDelete: true
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DELETE_SUCCESS:
            return state.merge({
                error: '',
                isFetchingDelete: false
            });
        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DELETE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetchingDelete: false
            });

        case actionTypes.MORTGAGE_REQUEST_MORTGAGE_DATA_SET_FIELD:
            return setField(state, action);
        default:
            return state;
    }
};

module.exports = mortgageData;
