const { isInOffice } = require('utilities/contactCenter/disasterRecoveryhelper')
const usd = require('utilities/usd')

const CC_OFI_CHANNEL = 'OFI';
const CC_CIC_CHANNEL = 'CIC';

const isPrintOfficeMode = () => {
    return __CONTACT_CENTER__ && (isInOffice() || usd.getInOffice())
}

const getCCOfficeChannel = () => {
    return isPrintOfficeMode() ? CC_OFI_CHANNEL : CC_CIC_CHANNEL;
}

// @deprecated: Please use function isDocumentUploadAllowed instead this
const DOCUMENT_UPLOAD_ALLOWED = (!__CONTACT_CENTER__ || isPrintOfficeMode());

const isDocumentUploadAllowed = () => !__CONTACT_CENTER__ || isPrintOfficeMode();

module.exports = {
    DOCUMENT_UPLOAD_ALLOWED, // @deprecated: Please use function isDocumentUploadAllowed instead this
    getCCOfficeChannel,
    isDocumentUploadAllowed,
    isPrintOfficeMode,
}
