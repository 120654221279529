// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_REQUEST,
    MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_SUCCESS,
    MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_FAILURE,
    MORTGAGE_REQUEST_CALCULATE_PROGRESS_BAR_SUCCESS
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    progress: 0,
    error: ''
});

const progressBar = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_SUCCESS:
            return state.merge({
                progress: action.data.progress,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_PROGRESS_BAR_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case MORTGAGE_REQUEST_CALCULATE_PROGRESS_BAR_SUCCESS:
            return state.merge({
                progress: action.progress,
                error: '',
                isFetching: false
            });   
        default:
            return state
    }
};

module.exports = progressBar;
