// @ vendors
const { Iterable, fromJS } = require('immutable');
const moment = require('moment');
const cloneDeep = require('lodash/lang/cloneDeep');
// @ utilites
const formHelper = require('./formHelper');
const { findElementbyId } = require('./contractStateHelper');
const { formatText } = require('core/i18n').i18n;
const {
   isFieldEmpty,
   updateCardDetailElementError,
   validateDate,
   validateDocumentCountry,
   validateDocumentNumber,
   validateDocumentType,
   validateDocumentTypeNotEmpty,
   validateEmail,
   validateExistingOYAccountDocumentNumber,
   validateExistingOYCardDocumentNumber,
   validateIsRequiredField,
   validateMethodOfPayment,
   validatePhoneNumber,
   validatePostalCode,
   validatePostalCodeProvince,
   validatePostalCodeProvinceCheckingPayroll,
   validateDescriptionsOrCompany
} = require('./inputsValidationHelper');
// @ const
const {
    ADDRESS_OTHERS_REGEX,
    DOCUMENT_NUMBER_NIE_REGEX,
    DOCUMENT_NUMBER_NIF_PASSPORT_REGEX,
    DOCUMENT_TYPE_NIF,
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIE_RESIDENT_CARD,
    DOCUMENT_TYPE_PASSPORT,
    FORM_VALIDATION_HELPER_SHOW_ERROR
} = require('constants/index');
const { contractsValidations } = require('constants/contractsValidations');

const VALIDATIONS_FUNCTIONS = {
    validateDate,
    validateDocumentCountry,
    validateDocumentNumber,
    validateDocumentType,
    validateDocumentTypeNotEmpty,
    validateEmail,
    validateExistingOYAccountDocumentNumber,
    validateExistingOYCardDocumentNumber,
    validateIsRequiredField,
    validateMethodOfPayment,
    validatePhoneNumber,
    validatePostalCode,
    validatePostalCodeProvince,
    validatePostalCodeProvinceCheckingPayroll,
    validateDescriptionsOrCompany
};

function pushComponent (stepComponentsToValidate, component, cardDetails) {
    if (Object.keys(component).length &&
    (component.required || component.validations) &&
    formHelper.componentVisibility(cardDetails, component)) {
        stepComponentsToValidate.push(component);
    }
}

function validateJS(cardDetails) {
    return cardDetails && (cardDetails.toJS ? cardDetails.toJS() : cardDetails);
}

function getErrorMessage (cardDetails, component) {
    cardDetails = validateJS(cardDetails);
    const componentDetail = formHelper.getField(cardDetails, component);
    const errorState = componentDetail && componentDetail.isErrorVisible;
    let errorMsg;
    if (errorState) {
        errorMsg = componentDetail.msgError ?
            formatText(componentDetail.msgError) :
            formatText(component.msg_error || component.msgError);
    }

    const error = {
        state: errorState,
        message: errorMsg
    };

    return error;
}

function validateFields (cardDetails, component, isExtraValidation) {
    const { required } = component;
    let validations = component.validations || [];
    cardDetails = validateJS(cardDetails);
    let isElementValid = {
        cardDetails,
        errorMessage: null,
        element: formHelper.getField(cardDetails, component)
    };

    if (!isExtraValidation || !!isElementValid.element.value) {
        if (required &&
            (!validations.length || validations[0].validationFunction !== contractsValidations.VALIDATE_IS_REQUIRED_FIELD)
        ) {
            validations.unshift({
                validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
            });
        }
        validations.every(
            validation => VALIDATIONS_FUNCTIONS[validation.validationFunction](isElementValid, component, validation)
        );
    }

    updateCardDetailElementError(isElementValid);

    return {
        cardDetails,
        isValid: !isElementValid.errorMessage
    };
}

function getStepComponents (steps, visibleStep, cardDetails) {
    cardDetails = cardDetails && (cardDetails.toJS ? cardDetails : fromJS(cardDetails));

    const stepComponents = steps.toJS()[visibleStep - 1];
    let stepComponentsToValidate = [];
    if (stepComponents) {
        stepComponents.components.forEach( stepComponent => {
            if (stepComponent.grid && formHelper.componentVisibility(cardDetails, stepComponent)) {
                stepComponent.grid.forEach(gridComponent => {
                    gridComponent.forEach(component => {
                        if (component.required) {
                            component.required = findElementbyId(cardDetails, component.id, 'required');
                        }
                        pushComponent(stepComponentsToValidate, component, cardDetails);
                    });
                });
            } else {
                pushComponent(stepComponentsToValidate, stepComponent, cardDetails);
            }
        });
    }

    return stepComponentsToValidate;
}

function extraValidations (extraValidateComponents, cardDetails, component, steps, visibleStep) {
    const stepComponents = getStepComponents(steps, visibleStep, cardDetails);
    const stepComponentsToValidate = stepComponents.length ?
        stepComponents :
        cardDetails;
    extraValidateComponents.forEach(extraValidateComponent => {
        const extraComponent = stepComponentsToValidate.find(component => component.id === extraValidateComponent);
        validateFields(cardDetails, extraComponent, true);
    });
}

function validateComponent (cardDetails, component, steps, visibleStep) {
    validateFields(cardDetails, component);
    if (component.extraValidateComponents) {
        let extraValidateComponents = cloneDeep(component.extraValidateComponents);
        extraValidateComponents = extraValidateComponents.map( extraValidate => component.id.includes('holder_') ?
            `${component.id.substring(0,9)}${extraValidate}`:
            extraValidate
        );
        extraValidations(extraValidateComponents, cardDetails, component, steps, visibleStep);
    }

    return cardDetails;
}

function validateAllComponentsFromStep (steps, visibleStep, cardDetails) {
    const stepComponentsToValidate = getStepComponents(steps, visibleStep, cardDetails);

    let componentValidated;
    let isValid = true;

    stepComponentsToValidate.forEach(componentToValidate => {
        componentValidated = validateFields(cardDetails, componentToValidate);
        cardDetails = componentValidated.cardDetails;
        isValid = isValid && componentValidated.isValid;
    });

    return {
        cardDetails,
        isValid
    };
}

function updateValue(card_details, id, errorValue) {
    return card_details.update(
        card_details.findIndex(item => item.get('id') === id),
        item => item.set('isErrorVisible', errorValue)
    );
}

function isFieldEmptyTarget (inputName, isSingleInput = false) {
    const fieldExistsInCardDetails = (isSingleInput)
        ? inputName
        : Iterable.isIterable(inputName);
    return !fieldExistsInCardDetails || !inputName.get('value');
}

function validateField(ret,field_id){
    const input_name = ret.card_details.filter(y => y.get('id') === field_id).get(0);
    ret.card_details = updateValue(ret.card_details,field_id,!FORM_VALIDATION_HELPER_SHOW_ERROR);
    if (isFieldEmpty(input_name, true)) {
        ret.card_details = updateValue(ret.card_details,field_id,FORM_VALIDATION_HELPER_SHOW_ERROR);
        ret.isValidStep = false;
    }
    return ret;
}

function validateTargetField(ret,field_id){
    const input_name = formHelper.getFieldInTarget(ret.card_details, field_id);
    ret.card_details = updateValue(ret.card_details,field_id,!FORM_VALIDATION_HELPER_SHOW_ERROR);
    if (isFieldEmptyTarget(input_name)) {
        ret.card_details = updateValue(ret.card_details,field_id,FORM_VALIDATION_HELPER_SHOW_ERROR);
        ret.isValidStep = false;
    }
    return ret;
}

function validateDifferentDocuments (identificationValues, interveners, validations) {
    const identificationCount = {};
    let validField = true;
    const holder = interveners.getIn([0, 'documentNumber']);
    identificationValues.forEach(documentNumber => identificationCount[documentNumber.value] = (identificationCount[documentNumber.value] || 0) + 1);
    const duplicated = identificationValues.filter(documentNumber => identificationCount[documentNumber.value] > 1 || documentNumber.value === holder);
    duplicated.forEach(identification => {
        validations[1][identification.index][identification.key] = 'contractsView-documentNumberRepeatedError'
        validField = false;
    });
    return validField
}

function isValidDocumentNumberOfType (documentNumber, documentType) {
    let isValidDocumentNumber = false;
    const upperCaseDocumentNumber = documentNumber.toString().toUpperCase();

    if (documentType === DOCUMENT_TYPE_NIF || documentType === DOCUMENT_TYPE_PASSPORT) {
        isValidDocumentNumber = DOCUMENT_NUMBER_NIF_PASSPORT_REGEX.test(upperCaseDocumentNumber);
    } else if ([DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIE_RESIDENT_CARD].includes(documentType)) {
        isValidDocumentNumber = DOCUMENT_NUMBER_NIE_REGEX.test(upperCaseDocumentNumber);
    }

    return isValidDocumentNumber;
}

function validateTargetDocumentNumberOfType (ret, numberFieldId, typeFieldId) {
    const numberInputName = formHelper.getFieldInTarget(ret.card_details, numberFieldId);
    const typeInputName = formHelper.getFieldInTarget(ret.card_details, typeFieldId);
    ret.card_details = updateValue(ret.card_details, numberFieldId, !FORM_VALIDATION_HELPER_SHOW_ERROR);

    if (isFieldEmptyTarget(numberInputName) || !isValidDocumentNumberOfType(numberInputName.get('value'), typeInputName.get('value'))) {
        ret.card_details = updateValue(ret.card_details, numberFieldId, FORM_VALIDATION_HELPER_SHOW_ERROR);
        ret.isValidStep = false;
    }

    return ret;
}

function validateTargetDate(ret, fieldId){
    const inputName = formHelper.getFieldInTarget(ret.card_details, fieldId);
    ret.card_details = updateValue(ret.card_details, fieldId, !FORM_VALIDATION_HELPER_SHOW_ERROR);

    if (isFieldEmptyTarget(inputName) || inputName.get('value').isSame(moment(), 'day')) {
        ret.card_details = updateValue(ret.card_details, fieldId, FORM_VALIDATION_HELPER_SHOW_ERROR);
        ret.isValidStep = false;
    }

    return ret;
}

function validateAddressOthers(cardDetails, addressOthers, addressId) {
    return {
        cardDetails: (ADDRESS_OTHERS_REGEX.test(addressOthers))
            ? cardDetails
            : updateValue(cardDetails, addressId, FORM_VALIDATION_HELPER_SHOW_ERROR),
        isValidStep: (ADDRESS_OTHERS_REGEX.test(addressOthers))
    };
}

function isEmpty(str) {
    return (!str || 0 === str.length);
}

/***
* Function to validate that every element is true
* */
function isValidElement(element) {
    return !!element;
}

function isValidAccount(card_details) {
    const account = card_details.find(x => x.get('id') === 'cta_seleccionada').get('value');
    const emptyAccount = isEmpty(account);
    card_details = updateValue(card_details, 'cta_seleccionada', emptyAccount);

    return {
        card_details,
        isValidStep: !emptyAccount
    };
}

module.exports = {
    getErrorMessage,
    isEmpty,
    isValidAccount,
    isValidDocumentNumberOfType,
    isValidElement,
    updateValue,
    validateAddressOthers,
    validateAllComponentsFromStep,
    validateComponent,
    validateDifferentDocuments,
    validateField,
    validateFields,
    validateTargetDate,
    validateTargetDocumentNumberOfType,
    validateTargetField
};
