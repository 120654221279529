
// @ vendors
const Immutable = require('immutable');
const moment = require('moment');

// @constants
const actionTypes = require('constants/actionTypes');
const { DATE_FORMAT_API, DATE_FORMAT_FULL_MONTH } = require('constants/index');
const { DATE_PICKER_CUSTOM_LOCALE } = require('constants/commonsComponents');

const initialState = Immutable.fromJS({
    budgetDetails: [],
    byId: {},
    byOrder: [],
    choreDetails: [],
    expenseDetails: {
        currentMonth: '',
        currentPeriod: 0,
        maxExpense: 0,
        previousMonth: '',
        previousPeriod: 0,
    },
    getChildDetailsBudgetError: undefined,
    getChildDetailsBudgetSuccess: false,
    getChildDetailsChoresError: undefined,
    getChildDetailsChoresSucess: false,
    getChildDetailsExpensesError: undefined,
    getChildDetailsExpensesSuccess: false,
    getChildDetailsFetch: false,
    selectedMonth: moment().startOf('month').format(DATE_FORMAT_API),
});

function parseChoreDetails(choreList) {
    let byId = {};
    let byOrder = [];

    if (!!choreList) {
        byOrder = choreList.map((chore) => {
            chore['isExpanded'] = false;
            chore['date'] = chore['createdDate'];
            byId[chore['choreAssignmentId'].toString()] = chore;
            return chore['choreAssignmentId'].toString();
        });
    }

    return {
        byId,
        byOrder
    }
}

function parseChildDetailsResponse(state, response) {

    const openYoungChildDetails = {
        budgetSummary: response.budgetSummary.response,
        chores: response.choresResponse.chores,
        expenseSummary: response.expenseSummary.response,
    };

    let expenseDetails = {
        currentPeriod: 0,
        currentMonth: '',
        previousMonth: '',
        previousPeriod: 0,
        maxExpense: 0,
        isExpenseResponseNull: false
    };

    const expenseList = openYoungChildDetails.expenseSummary;
    if (!!expenseList && expenseList.length > 0) {
        expenseDetails.maxExpense = expenseList[0].maxExpense;
        expenseDetails.currentMonth = moment(expenseList[0].startDate).locale(DATE_PICKER_CUSTOM_LOCALE).format(DATE_FORMAT_FULL_MONTH);
        expenseDetails.currentPeriod = expenseList[0].totalExpense;
        expenseDetails.previousMonth = moment(expenseList[1].startDate).locale(DATE_PICKER_CUSTOM_LOCALE).format(DATE_FORMAT_FULL_MONTH);
        expenseDetails.previousPeriod = expenseList[1].totalExpense;
    } else {
        expenseDetails.isExpenseResponseNull = true;
    }

    return state.merge({
        getChildDetailsFetch: false,
        choreDetails: openYoungChildDetails.chores,
        budgetDetails: openYoungChildDetails.budgetSummary,
        expenseDetails: expenseDetails,
        getChildDetailsChoresSuccess: response.choresResponse.statusDetails.successStatusCode,
        getChildDetailsBudgetSuccess: response.budgetSummary.statusDetails.successStatusCode,
        getChildDetailsExpensesSuccess: response.expenseSummary.statusDetails.successStatusCode,
    }, parseChoreDetails(openYoungChildDetails.chores));
}

const openYoungDashboardChildDetails = (state = initialState, action = { type: null }) => {
    switch (action.type) {
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_DETAILS_REQUEST:
            return state.merge({
                getChildDetailsBudgetError: undefined,
                getChildDetailsChoresError: undefined,
                getChildDetailsExpensesError: undefined,
                getChildDetailsFetch: true,
            });
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_DETAILS_SUCCESS:
            return parseChildDetailsResponse(state, action.payload.openYoungChildDetails);
        case actionTypes.OPENYOUNG_DASHBOARD_CHILD_DETAILS_ERROR:
            return state.merge(initialState, {
                getChildDetailsBudgetError: action.payload.error,
                getChildDetailsChoresError: action.payload.error,
                getChildDetailsExpensesError: action.payload.error,
            });
        default:
            return state
    }
}
module.exports = openYoungDashboardChildDetails;
