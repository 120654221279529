// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST,
    MORTGAGE_REQUEST_FETCH_CONDITIONS_SUCCESS,
    MORTGAGE_REQUEST_FETCH_CONDITIONS_FAILURE,
    MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST_SET_ACCOUNT,
    MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_INPROGRESS,
    MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_SUCCESS,
    MORTGAGE_REQUEST_RESET_IS_BINDING_OFFER,
    MORTGAGE_REQUEST_FAILURE_OFFER
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    conditions: {
        idExpediente: '',
        importeHipoteca: '',
        importeFinanciarGastos: 0,
        interesVariable: '',
        plazo: '',
        taeProd: '',
        tipoInteres: '',
        titular: '',
        incluyeSeguroHogar: false,
        cuotaMensualResto: null,
    },
    accountId: null,
    accountIBAN: 0,
    error: '',
});

const mortgageRequestConditions = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_CONDITIONS_SUCCESS:
            return state.merge({
                conditions: action.data.data,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_CONDITIONS_FAILURE:
            return state.merge({
                conditions: {},
                error: action.data.error,
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST_SET_ACCOUNT:
            return state.merge({
                accountId: action.data.accountId,
                accountIBAN: action.data.accountIBAN
            });
        case MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_INPROGRESS:
            return state.merge({
                isBindingOffer: true,
            });
        case MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_SUCCESS:
            return state.merge({
                isBindingOffer: false,
            });
        case MORTGAGE_REQUEST_FAILURE_OFFER:
            return state.merge({
                isBindingOffer: false
            });
        case MORTGAGE_REQUEST_RESET_IS_BINDING_OFFER:
            return state.merge({
                isBindingOffer: false,
                accountId: null
            });
        default:
            return state;
    }
};

module.exports = mortgageRequestConditions;
