//@core
const { getStore } = require('core/storeHelper');

// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { BROKER_REIMBURSE_FUND_TOTAL } = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
      valid: false,
      amount: 0,
      amountIsValid: true,
      amountDirty: false,
      type: BROKER_REIMBURSE_FUND_TOTAL,
      reimburseFund: {
        account: {
          balance: { currency: '' }
        }
    }
    })
}

function validateStep(step) {
    const maxReimburseInvestmentPercentage = getStore().getState().brokerMyInvestments.get('maxFundsPartialReimbursementPercentage');
    const immMyInvestments = getStore().getState().brokerMyInvestments;
    const contractNumber = step.getIn(['reimburseFund','contractNumber']);
    const actualfund = immMyInvestments.getIn(['products','funds','stocks'], Immutable.fromJS({})).find(fund => fund.get('contractNumber') === contractNumber);
    const maxAmount = actualfund.getIn(['totalValueEur','amount']) * ( maxReimburseInvestmentPercentage / 100);
    return step.merge({
        valid: step.get('type') === BROKER_REIMBURSE_FUND_TOTAL || step.get('amount')>0 && step.get('amount') <= maxAmount,
        amountIsValid: step.get('type') === BROKER_REIMBURSE_FUND_TOTAL || step.get('amount')>0 && step.get('amount') <= maxAmount ,
        amountDirty: false,
    });
}

function wizardSuscriptionStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountDirty: true,
            });
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_SET_TYPE:
            return state.merge({
                type: action.payload.type,
                amount: action.payload.amount
            });
    }

    return state;
}

module.exports = wizardSuscriptionStep1;
