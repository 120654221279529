// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
/*const {
} = require('constants/index');
*/
function setInitialState() {
   return Immutable.Map().merge({
       hasSignaturePattern: true,
       disclaimerChecked: false,
       disclaimerInvalid: false,
       otp: '',
       success: false,
       valid: false
   });
}

function validateStep(step){
    const valid = step.get('disclaimerChecked');
    const validOtp = step.get('otp').length >= 4;
    return step.merge({
        success: valid && validOtp,
        disclaimerInvalid: !valid
    });
}

function toggleDisclaimer(step) {
    const disclaimerChecked = !step.get('disclaimerChecked');
    const validOtp = step.get('otp').length >= 4;
    return step.merge({
        valid: disclaimerChecked && validOtp,
        disclaimerChecked: disclaimerChecked,
        disclaimerInvalid: false
    })
}

function brokerExternalTransferFundsStep4(state = setInitialState(), action) {
   switch (action.type) {
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SET_VISIBLE_STEP:
            return setInitialState();
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_DISCLAIMER_TOGGLE:
            return toggleDisclaimer(state);
        case actionTypes.SIGNATURE_PATTERN_UPDATE_INPUT_SMS:
            const disclaimerChecked = state.get('disclaimerChecked');
            return state.merge ({
                otp: action.payload.value,
                valid: disclaimerChecked && action.payload.value.length >= 4
            })
        default:
            return state;
   }
}

module.exports = brokerExternalTransferFundsStep4;
