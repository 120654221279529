// @ constants
const {
    AMOUNT_TARGET_TYPE,
    INTERVENER_TYPE,
    TEXT_TARGET_TYPE
} = require('constants/index');

module.exports = {
    title: 'contractSummary-juniorAccountTitle',
    accountNumber: 'contractSummary-accountNumber',
    backButtonText: 'contractSummary-backTextAccount',
    backButtonUrl: '/myprofile/accounts',
    setSuccessStepPrefix: 'juniorAccount_setSuccessStepPrefix',
    savingMoneyExtraMessage: 'contractSummary-savingMoneyOYAccountReminder',
    extraMessage: 'contractSummary-juniorAccountReminder',
    customInstructions: 'juniorAccount_successInstructions',
    details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        source: 'account',
                        target: 'balance',
                        title: 'contractSummary-balanceAssociatedAccount',
                        type: AMOUNT_TARGET_TYPE
                    }
                ]
            },
            {
                columns: 6,
                offset: 1,
                type: INTERVENER_TYPE,
                title: 'contractsView-interveners',
                interveners: []
            }
        ]
    ],
    warningMessage: [
        [
            {
                text: 'contractSummary-juniorAccountCheckInfo'

            }
        ]
    ],
    successWithEmail_warningMessage: [
        [
            {
                text: 'contractSummary-juniorAccountCheckInfoWithEmail'
            }
        ]
    ],
}
