// @utilities
import { removeLocalStorageByPrefix } from 'utilities/cleanHelper';
import { LOCAL_ENVIRONMENT } from 'constants/index';
const { getSessionId } = require('utilities/usd');

// @constants
import { VIEWER } from 'constants/contactCenter/pdfViewer';


 
const BASE_URL =  window.__ENV__ === LOCAL_ENVIRONMENT? '/pdfViewer.html':'/ok-ui-frame-es-cc/pdfViewer.html';

const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
})

const openPDFViewer = (result, customId) => {
    const sessionId = getSessionId();
    if (sessionId && result) {
        const name = `${VIEWER}${customId ? '-' + customId : ''}-${sessionId}`;
        if (customId) {
            removeLocalStorageByPrefix(`${VIEWER}-${customId}`);
        } else {
            removeLocalStorageByPrefix(VIEWER);
        }

        const file = new Blob( [result], { type: 'application/pdf' });
        convertBlobToBase64(file).then((plainFile) => {
            localStorage.setItem(name, plainFile);
            const url = BASE_URL + `?storage=${name}`;
            window.open(url, '_blank');
        });
    }
};

const showPDFFailure = () => {
    const sessionId = getSessionId();
    const name = `${VIEWER}-${sessionId}`;
    const url = BASE_URL + `?storage=${name}`;
    removeLocalStorageByPrefix(VIEWER);
    window.open(url, '_blank');
};

export { openPDFViewer, showPDFFailure };
