const Immutable = require('immutable');
// const _ = require('underscore');
// const moment = require('moment');
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    let state = Immutable.fromJS({
        isFetching: false,
        isPartialFetching: false,
        error: '',
        filters: {
            to: '', //start date
            historicMonth: '', //historic months
        },
        resultMonthlyExpenses: 0,
        totalExpenseCurrentMonth: 0,
        totalExpenseCurrentMonthUpdate: false
    });
    return state;
}

function updateMonthlyExpensesHistogram(state, expensesMonthlyTotal) {
    let newState = state.set('resultMonthlyExpenses', expensesMonthlyTotal)
                        .set('isFetching', false);
    return(newState);
}

function updateTotalCurrentMonth(state, response) {
    let newState = state.set('totalExpenseCurrentMonth', response)
                        .set('totalExpenseCurrentMonthUpdate', true)
                        .set('isFetching', false);
    return(newState);
}


function monthlyExpensesIndicator(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_TOTAL_CURRENT_MONTH:
            return updateTotalCurrentMonth(state, action.payload.response.total);
        case actionTypes.GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });

        case actionTypes.GLOBAL_POSITION_MONTHLY_EXPENSES_TOTAL_SUCCESS:
            return updateMonthlyExpensesHistogram(state, action.payload.expensesMonthlyTotal);

        default:
            return state;
    }
}

module.exports = monthlyExpensesIndicator;
