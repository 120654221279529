// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
// @ utilities
const { hash } = require('utilities/hash');
const get = require('lodash/object/get');
const { sortByOrder } = require('lodash/collection');
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    CARD_STATEMENT_MONTHLY_EXTRACT,
    CARD_STATEMENT_SPECIAL_PURCHASE,
    EURO_TEXT
} = require('constants/index');

function mapFields(cardId, statements) {
    let byId = {};
    let byOrder = [];
    let byOrderSpecialPurchases = [];
    let byOrderMonthlyExtracts = [];
    statements = sortByOrder(statements, ['fechaProxLiquTeorica'], ['desc']);
    statements.map((statement) => {
        if (!!statement.fechaProxLiquTeorica) {
            let type;
            const id = hash(statement.fechaProxLiquTeorica + get(statement, 'importe.importe', 0));

            if (trim(get(statement, 'tipoLiquidacion')) === 'COMPRA ESPECIAL') {
                type = CARD_STATEMENT_SPECIAL_PURCHASE;
                byOrderSpecialPurchases.push(id);
            } else {
                type = CARD_STATEMENT_MONTHLY_EXTRACT;
                byOrderMonthlyExtracts.push(id);
            }
            
            const mappedStatement = {
                cardId,
                id,
                date: get(statement, 'fechaProxLiquTeorica'),
                generationDate: get(statement, 'fecliqex'),
                status: get(statement, 'estado'),
                amount: {
                    amount: get(statement, 'importe.importe', 0),
                    currency: get(statement, 'importe.divisa', EURO_TEXT)
                },
                paymentMethod: statement.nombreColectivo,
                movementDay: get(statement, 'numeroMovDia'),
                type,
            };

            byId[mappedStatement.id] = mappedStatement;
            byOrder.push(mappedStatement.id);
        }
    });

    return {
        byId,
        byOrder,
        byOrderMonthlyExtracts,
        byOrderSpecialPurchases,
    };
}

const initialState = Immutable.fromJS({});

function cardsStatementsReducer(state = initialState, action) {
    const cardId = get(action.payload, 'cardId');
    const moreData = get(action.payload, 'moreData');
    const nextValue = get(action.payload, 'nextValue');
    const paginationData = get(action.payload, 'paginationData');
    const statements = get(action.payload, 'statements');
    let processedStatements;
    switch (action.type) {
        case actionTypes.FETCH_CARDS_STATEMENTS_REQUEST:
            return state.mergeDeep({
                [cardId]: {
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {},
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENTS_SUCCESS:
            processedStatements = mapFields(cardId, statements);
            return state.mergeDeep({
                [cardId]: {
                    byId: processedStatements.byId,
                    byOrder: processedStatements.byOrder,
                    error: false,
                    loading: false,
                    monthlyExtracts: {
                        byOrder: processedStatements.byOrderMonthlyExtracts,
                    },
                    moreData: action.payload.moreData === 'S',
                    nextValue: action.payload.nextValue,
                    paginationData: action.payload.paginationData,
                    specialPurchases: {
                        byOrder: processedStatements.byOrderSpecialPurchases,
                    },
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENTS_FAILURE:
            return state.mergeDeep({
                [cardId]: {
                    loading: false,
                    error: true,
                    errorData: action.payload.error
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENTS_LOAD_MORE_FAILURE:
            return state.mergeDeep({
                [cardId]: {
                    error: true,
                    errorData: action.payload.error,
                    isFetchingMore: false,
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENTS_LOAD_MORE_REQUEST:
            return state.mergeDeep({
                [cardId]: {
                    error: false,
                    isFetchingMore: true,
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENTS_LOAD_MORE_SUCCESS:
            processedStatements = mapFields(cardId, statements);
            return state.mergeDeep({
                [cardId]: {
                    byId: state.getIn([cardId, 'byId']).merge(processedStatements.byId),
                    byOrder: state.getIn([cardId, 'byOrder']).concat(Immutable.fromJS(processedStatements.byOrder)),
                    isFetchingMore: false,
                    monthlyExtracts: {
                        byOrder: state.getIn([cardId, 'monthlyExtracts', 'byOrder']).concat(Immutable.fromJS(processedStatements.byOrderMonthlyExtracts)),
                    },
                    moreData: moreData === 'S',
                    nextValue,
                    paginationData,
                    specialPurchases: {
                        byOrder: state.getIn([cardId, 'specialPurchases', 'byOrder']).concat(Immutable.fromJS(processedStatements.byOrderSpecialPurchases)),
                    },
                }
            });
        default:
            return state
    }
}

module.exports = cardsStatementsReducer;
