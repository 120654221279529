// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

// @utilities
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: false,
    fetchSuccess: false,
    fetchFundsSuccess: false,
    fetchPlansSuccess: false
});

function assignRentabilityLastYear(data, ById) {
    data.forEach( item => {
        const id = item.Id;
        if (item.HistoricalPerformanceSeries) {
            ById[id].lastYearReturnValue = item.HistoricalPerformanceSeries[0].Return[0].Value;
        }
    });
    return Immutable.fromJS(ById);
}

function processVariation(data) {
    let byId = {};
    let byOrder = [];

    data.forEach( item => {
        const id = item.SecId;

        byId[id] = {
            id,
            hash: hash([id, item.ISIN, item.Name]),
            customInstitutionSecurityId: item.CustomInstitutionSecurityId,
            isin: item.ISIN,
            description: item.Name,
            category: item.CustomCategoryIdName || '-',
            rating: item.Rating,
            risk: item.srri ? item.srri + '/7' : '-',
            currency: item.CurrencyId.split('$$$$$')[1],
            ytd: item.ReturnM0,
            lastYearReturnValue: null,
            dgsCode: item.DGSCode
        }

        byOrder.push(id);
    });

    return {
        byOrder,
        byId
    };
}

function processRentabilityData(state, data, type) {
    let newById;
    newById = assignRentabilityLastYear(data, state.getIn([type, 'byId']).toJS());
    switch (type) {
        case 'funds':
            return state.merge({
                fetchFundsSuccess: true,
                funds: {
                    byOrder: state.getIn(['funds','byOrder']),
                    byId: newById
                },
            });
        case 'plans':
            return state.merge({
                fetchPlansSuccess: true,
                plans: {
                    byOrder: state.getIn(['plans','byOrder']),
                    byId: newById
                },
            });
    }
}


function processData(state, data) {
    const plans = processVariation(data.plans);
    const funds = processVariation(data.funds);

    return state.merge({
        isFetching: false,
        fetchSuccess: true,
        error: false,
        plans: {
            byOrder: plans.byOrder,
            byId: plans.byId
        },
        funds: {
            byOrder: funds.byOrder,
            byId: funds.byId
        }
    });
}

// {
// "SecId": "F0GBR04N40",
//   "ISIN": "LU0122613499",
//   "Name": "Franklin Biotechnology Discv N Acc $",
//   "CustomCategoryIdName": "RV Sectorial",
//   "Rating": "4",
//   "CustomRisk": 1,
//   "ReturnM0": -12.92,
//   "ReturnM12": -12,
//   "ReturnM36": 17.33,
//   "ReturnM60": 25.45,
//   "CustomMinimumPurchaseAmount": 500,
//   "CustomMinimumPurchaseAmountUnit": 1,
//   "CustomInstitutionSecurityId": "822-290-1",
//   "CustomAttributes2": "88",
//   "CustomAttributes3": "228",
//   "CurrencyId": "CU$$$$$USD"
// }

// {description: 'Barling Australia Fund Barling Australia Fund', category: 'RV España', risk: '6/7', rating: 5, currency: 'GBP', ytd: 7.68},

function brokerAlertsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_MOST_POPULAR_OF_MONTH_REQUEST:
            return state.merge({
                isFetching: true,
                error: false,
                fetchSuccess: false
            });
        case actionTypes.BROKER_GET_RENTABILITY_LAST_YEAR_PLANS_SUCCESS:
            return processRentabilityData(state, action.payload.plans, 'plans');
        case actionTypes.BROKER_GET_RENTABILITY_LAST_YEAR_FUNDS_SUCCESS:
            return processRentabilityData(state, action.payload.funds, 'funds');
        case actionTypes.BROKER_MOST_POPULAR_OF_MONTH_SUCCESS:
            return processData(state, action.payload.data);
        case actionTypes.BROKER_MOST_POPULAR_OF_MONTH_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
                fetchSuccess: false
            });
        default:
            return state
    }
}

module.exports = brokerAlertsReducer;
