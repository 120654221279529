const getBlockedAccountsFlags = (accountIndexes, blockedAccounts) => {

    let allBlocked = true;
    let atLeastOneBlocked = false;

    if (blockedAccounts.length === 0) {
        allBlocked = false
    }
    else {
        accountIndexes.forEach(accountId => {
            const isBlocked = blockedAccounts.contains(accountId);
            atLeastOneBlocked = atLeastOneBlocked || isBlocked
            allBlocked = allBlocked && isBlocked;
        });
    }
    return {
        allBlocked,
        atLeastOneBlocked
    }
}

module.exports = {
    getBlockedAccountsFlags
};

