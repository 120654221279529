// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    depositsData: {},
    success: false,
    hasBeenFetched: false
});

function mapResponse (response) {
    if (!response) {
        return {};
    }
    else {
        return {
            codPeriodoLiq: get(response, 'codPeriodoLiq'),
            cotPeriodo: get(response, 'cotPeriodo'),
            descPlazo: get(response, 'descPlazo'),
            descProducto: get(response, 'descProducto'),
            importeMax: get(response, 'importeMax'),
            importeMin: get(response, 'importeMin'),
            indala03: get(response, 'indala03'),
            indala04: get(response, 'indala04'),
            links: get(response, 'links'),
            listaAltaImposicionValidacion: get(response, 'listaAltaImposicionValidacion'),
            moneda: get(response, 'moneda'),
            perLiq: get(response, 'perLiq'),
            plazoPos: get(response, 'plazoPos'),
            producto: get(response, 'producto'),
            saldoDisponible: get(response, 'saldoDisponible'),
            tasa: get(response, 'tasa'),
            totalDescubierto: get(response, 'totalDescubierto')
        }
    }
}

function depositsData(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ADD_DEPOSIT_IMPOSITION_RESET:
            return state.mergeDeep(initialState);
        case actionTypes.DEPOSIT_DATA_IMPOSITION_REQUEST:
            return state.mergeDeep({
                isFetching: true,
            });
        case actionTypes.DEPOSIT_DATA_IMPOSITION_SUCCESS:
            return state.mergeDeep({
                depositsData: mapResponse(action.payload.data),
                isFetching: false,
                success: true,
                hasBeenFetched: true
            });

        case actionTypes.DEPOSIT_DATA_IMPOSITION_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
                success: false
            });
        default:
            return state;
    }
}

module.exports = depositsData;
