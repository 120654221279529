const Immutable = require('immutable');
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS
} = require('constants/index');

const etfsFundsAdapter = (results, state) => {
    let resultsArray;

    if (results.total > 99) {
        resultsArray = results.rows.reduce((prev, curr) =>  [ ...prev, ...curr ], []);
    } else {
        resultsArray = results.rows;
    }

    const massagedResults = resultsArray.reduce((prev, curr) => {

        const singleResult = {
            "customCategoryId3": curr.CustomCategoryId3,
            "name": curr.Name,
            "category": curr.CustomCategoryIdName,
            "rating": parseInt(curr.Rating, 10),
            "risk": curr.srri || 0,
            "ytd": curr.ReturnM0,
            "aYear": curr.ReturnM12,
            "threeYears": curr.ReturnM36,
            "fiveYears": curr.ReturnM60,
            "traspassableFund": curr.CustomTransferTaxExempt,
            "secId": curr.SecId,
            "CustomInstitutionSecurityID": curr.CustomInstitutionSecurityID,
            "KiidDocument": curr.KiidDocument,
            "ISIN": curr.ISIN,
            "productKey": curr.productKey,
            "watchlistId": curr.watchlistId,
            "currency": curr.Currency,
        };

        return [ singleResult, ...prev ];

    }, []);

    return state.mergeIn(['isFetchingResults'], false)
                .mergeIn(['showEmpty'], results.total < 1)
                .setIn(['resultsList'], Immutable.fromJS(massagedResults));
};

const etfsPlansAdapter = (results, state) => {
    let resultsArray;

    if (results.total > 99) {
        resultsArray = results.rows.reduce((prev, curr) =>  [ ...prev, ...curr ], []);
    } else {
        resultsArray = results.rows;
    }

    const massagedResults = resultsArray.reduce((prev, curr) => {

        const singleResult = {
            "dgsCode": curr.DGSCode,
            "name": curr.Name,
            "category": curr.CustomCategoryIdName,
            "rating": parseInt(curr.Rating, 10),
            "risk": curr.srri || 0,
            "ytd": curr.ReturnM0,
            "aYear": curr.ReturnM12,
            "threeYears": curr.ReturnM36,
            "fiveYears": curr.ReturnM60,
            "secId": curr.SecId,
            "CustomInstitutionSecurityID": curr.CustomInstitutionSecurityID,
            "KiidDocument": curr.KiidDocument,
            "productKey": curr.productKey,
            "watchlistId": curr.watchlistId
        };

        return [ singleResult, ...prev ];

    }, []);

    return state.mergeIn(['isFetchingResults'], false)
                .mergeIn(['showEmpty'], results.total < 1)
                .setIn(['resultsList'], Immutable.fromJS(massagedResults));
};

const ETFsStocksWarrantsAdapter = (data, state) => {
    const trimmed = data.map(item => {
        const keys = Object.keys(item);
        let cleaned = {};
        keys.forEach(key => {
            const itemInKey = item[key];

            if (itemInKey != null && itemInKey.trim) {
                cleaned[key] = itemInKey.trim();
            } else {
                cleaned[key] = itemInKey;
            }
        });
        return cleaned;
    });
    return state.mergeIn(['isFetchingResults'], false)
                .mergeIn(['showEmpty'], data.length < 1)
                .setIn(['resultsList'], Immutable.fromJS(trimmed));

};

const resultsAdapterSubReducer = (state, payload) => {

    switch (payload.productType) {
        case TYPE_STOCKS:
        case TYPE_WARRANTS:
        case TYPE_ETFS:
            return ETFsStocksWarrantsAdapter(payload.data, state);

        case TYPE_PLANS:
            return etfsPlansAdapter(payload.data, state);
        case TYPE_FUNDS:
            return etfsFundsAdapter(payload.data, state);

        default:
            return;
    }

};

module.exports = resultsAdapterSubReducer;
