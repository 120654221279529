// @vendors
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { FIRST_STEP, SECOND_STEP, WIZARD_SECOND_STEP, WIZARD_THIRD_STEP } = require('constants/index');
// @helpers
const { formatText } = require('core/i18n').i18n;

const setInitialState = () => {
    return Immutable.Map().merge({
        steps: [
            {
                valid: false,
                currentAccountId: null,
                destinationAccountId: null,
                receiptNumber: null,
                error: '',
            },
            {
                hasSignaturePattern: true,
            },
        ],
        showCancelModal: false,
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: '',
        token: null,
    });
}

const changeReceiptAccountReducer = (state = setInitialState(), action) => {
    const firstStepValidity = ['steps', FIRST_STEP, 'valid'];
    const firstStepError = ['steps', FIRST_STEP, 'error'];
    const firstStepDestinationAccountId = ['steps', FIRST_STEP, 'destinationAccountId'];
    const firstStepCurrentAccountId = ['steps', FIRST_STEP, 'currentAccountId'];
    const firstStepReceiptNumber = ['steps', FIRST_STEP, 'receiptNumber'];

    switch (action.type) {
        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SET_DESTINATION_ACCOUNT_ID:
            return state.setIn(firstStepDestinationAccountId, action.payload.accountId);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SET_CURRENT_ACCOUNT_ID:
            return state.setIn(firstStepCurrentAccountId, action.payload.accountId);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SET_RECEIPT:
            return state.setIn(firstStepReceiptNumber, action.payload.receiptNumber);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_VALIDATE_UI_STEP:
            const errorMessage = state.getIn(firstStepDestinationAccountId) ? '' : formatText('accountDebitChange-dropDownErrorMessage');
            return state
                .setIn(firstStepValidity, errorMessage === '')
                .setIn(firstStepError, errorMessage);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SHOW_CANCEL_MODAL:
            return state.merge({
                showCancelModal: action.payload.show
            });

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_VALIDATE_STEP:
            return state.set('visibleStep', state.getIn(firstStepValidity) ? SECOND_STEP : FIRST_STEP);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_VALIDATION_SUCCESS:
            switch (action.payload.step) {
                case 1:
                    return state.merge({
                        inProgress: true,
                        isFetching: false,
                        visibleStep: WIZARD_SECOND_STEP,
                    });
                case 2:
                    return state.set('visibleStep', WIZARD_THIRD_STEP);
                default:
                    return state;
            }

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_VALIDATION_FAILURE:
            return state.merge({
                error: action.payload.error.error.description,
                inProgress: true,
                isFetching: false,
                visibleStep: 1,
            });

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_RESET:
            return setInitialState();

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_WILL_CANCEL:
            return state.set('willCancel', action.payload.willCancel);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_REQUEST:
            return state.set('isFetching', true);

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false,
            });

        case actionTypes.CHANGE_RECEIPT_ACCOUNT_FAILURE:
            return state.merge({
                isFetching: false,
                submitError: true,
                error: action.payload.error,
            });

        default:
            return state;
    }
}

module.exports = changeReceiptAccountReducer;
