// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
const brokerETFsSellStep1 = require('./brokerETFsSell/brokerETFsSellStep1');
const brokerETFsSellStep2 = require('./brokerETFsSell/brokerETFsSellStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerETFsSellStep1(undefined, { type: null }),
            brokerETFsSellStep2(undefined, { type: null })
        ],
        visibleStep: 1,
        submitSuccessful: false,
        isFetching: false,
        inProgress: false,
        willCancel: false,
        etfsTransactionCostUrl:'',
        exAnteData: [],
        exAnteDataFetching: false,
        estimatedBaseAmount: null,
        estimatedBaseAmountExchanged: null,
        confirmationOrderSend: false,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerETFsSellStep1(step, action);
            case SECOND_STEP:
                return brokerETFsSellStep2(step, action);
        }
    });
}

function brokerETFsSellReducer(state = setInitialState(), action) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.BROKER_ETFS_SELL_RESET:
            return setInitialState();
        case actionTypes.BROKER_ETFS_SELL_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_ETFS_SELL_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;

        case actionTypes.BROKER_ETFS_SELL_VALIDATE_SUCCESS:
            return state
                .set('confirmationData', action.payload.data)
                .setIn(['steps', FIRST_STEP, 'loading'], false)
                .set('visibleStep', 2);

        case actionTypes.BROKER_ETFS_SELL_VALIDATE_FAILURE:
            return state.setIn(['steps', FIRST_STEP, 'loading'], false);

        case actionTypes.BROKER_ETFS_SELL_VALIDATE_REQUEST:
            return state.setIn(['steps', FIRST_STEP, 'loading'], true);

        case actionTypes.BROKER_ETFS_SELL_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);

        case actionTypes.BROKER_ETFS_SELL_CONFIRM_SUCCESS:
            return state
                .set('submitSuccessful', true)
                .setIn(['steps', SECOND_STEP, 'loading'], false)
                .set('orderNumber', action.payload.data.numeroOrden);

        case actionTypes.BROKER_ETFS_SELL_CONFIRM_FAILURE:
            //TODO handle error better
            return state
                .set('submitSuccessful', false)
                .setIn(['steps', SECOND_STEP, 'loading'], false);

        case actionTypes.BROKER_ETFS_SELL_CONFIRM_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'loading'], true);

        case actionTypes.BROKER_ETFS_SELL_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.value
            });
        case actionTypes.BROKER_ETF_SUBSCRIPTION_CHANGE_READCHECKED:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_ETF_SELL_FINISH:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true
        });
        case actionTypes.BROKER_ETFS_SELL_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_ETFS_SELL_ORDER_TYPE_OPTION_CHANGED:
        case actionTypes.BROKER_ETFS_SELL_ORDER_TYPE_VALUE_CHANGED:
        case actionTypes.BROKER_ETFS_SELL_ORDER_TYPE_VALUE_RESET:
        case actionTypes.BROKER_ETFS_SELL_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_ETFS_SELL_TITLE_QUANTITY_CHANGED:        
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
        case actionTypes.BROKER_SELL_CONTRACT_NUMBER:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_ETFS_SELL_EX_ANTE_FETCHING:
            return state.set('exAnteDataFetching', true);
        case actionTypes.BROKER_ETFS_SELL_EX_ANTE_SUCCESS:
            return state.merge({
                exAnteData: action.payload.data.response.exAnteCostList,
                exAnteDataFetching: false,
                estimatedBaseAmount: action.payload.data.response.estimatedBaseAmount,
                estimatedBaseAmountExchanged: action.payload.data.response.estimatedBaseAmountExchanged,
            });
        case actionTypes.BROKER_ETFS_SELL_EX_ANTE_FAILURE:
            return state.set('exAnteDataFetching', false);
        case actionTypes.BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_REQUEST:
            return state.set('confirmationOrderSend', false);
        case actionTypes.BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_SUCCESS:
            return state.set('confirmationOrderSend', true);
        case actionTypes.BROKER_ETFS_SELL_SEND_ORDER_SUMMARY_ERROR:
            return state.set('confirmationOrderSend', false);
        default:
            return state;
    }
}

module.exports = brokerETFsSellReducer;
