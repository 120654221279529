// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');

//@Actions
const actionTypes = require('constants/actionTypes');

// @utilities
const { buildProductLink } = require('utilities/productLinksHelper');
const { toFormatDate } = require('utilities/dateHelper');
const { formatWithoutRound } = require('utilities/currencyHelper');
const { toCamelCase } = require('utilities/stringHelper');

// @constants
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS,
    BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH,
    DATE_FORMAT,
    DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR
} = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    search: '',
    isFetching: false,
    fetched: false,
    results: {},
    error: false,
    visibility: false,
    searchEmpty: false
});

const formatName = (name) => {
    return name.length > BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH ?
        `${name.slice( 0, BROKER_PRODUCT_SEARCH_DISPLAY_NAME_MAX_LENGTH - 1 )}...`
        : name;
}

const buildQuotation = (value, type) => {
    return (type == TYPE_FUNDS || type == TYPE_PLANS) ? formatWithoutRound(value,3) : formatWithoutRound(value,4);
}

const resultsAdapter = (arr, type) => {
    const arrSanitized = !!arr ? arr : {instrumentsList: [], total: 0};

    return {
        instrumentsList: arrSanitized.instrumentsList.map(item => {
            return Object.assign({}, item, {
                quotation: buildQuotation(get(item, 'quotation', '-'), type),
                quotationDate: get(item, 'quotationDate') ?
                    toCamelCase(trim(toFormatDate(get(item, 'quotationDate'), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR, DATE_FORMAT))) : '-',
                dayChangeDate: get(item, 'dayChangeDate') ?
                    toCamelCase(trim(toFormatDate(get(item, 'dayChangeDate'), DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR, DATE_FORMAT))) : '-',
                quotationTime: get(item, 'quotationTime') ?
                    trim(get(item, 'quotationTime').slice(0,5)) : '',
                link: buildProductLink(type, item),
                dayChangePercentage: get(item, 'dayChangePercentage','-'),
                shortName: formatName(item.name)
            });
        }),
        total: arrSanitized.total
    }
}

const sanitizeData = (data) => {
    return {
        stocks: resultsAdapter(data.stocks, TYPE_STOCKS),
        funds: resultsAdapter(data.funds, TYPE_FUNDS),
        plans: resultsAdapter(data.plans, TYPE_PLANS),
        etfs: resultsAdapter(data.etfs, TYPE_ETFS),
        warrants: resultsAdapter(data.warrants, TYPE_WARRANTS),
        alternativeFunds: resultsAdapter(data.alternativeFunds, 'alternativeFunds')
    };
}

const brokerSearchWidgetReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.BROKER_SEARCH_WIDGET_SHOW:
            return state.set('visibility', true);
        case actionTypes.BROKER_SEARCH_WIDGET_HIDE:
            return INITIAL_STATE;
        case actionTypes.BROKER_SEARCH_WIDGET_RESET:
            return INITIAL_STATE;
        case actionTypes.BROKER_SEARCH_WIDGET_REQUEST:
            return state.merge({
                fetched: false,
                isFetching: true
            });
        case actionTypes.BROKER_SEARCH_WIDGET_SUCCESS:
            const data = action.payload.data;
            const results = sanitizeData(data);
            const searchEmpty = (results.alternativeFunds.total+results.stocks.total + results.funds.total + results.plans.total + results.etfs.total + results.warrants.total) === 0 ? true : false;
            return state.merge({
                fetched: true,
                isFetching: false,
                results: results,
                searchEmpty,
                error: false
            });
        case actionTypes.BROKER_SEARCH_WIDGET_FAILURE:
            const failResults = sanitizeData(action.payload.data);
            return state.merge({
                fetched: true,
                isFetching: false,
                results: failResults,
                error: action.payload.data.error
            });
        case actionTypes.BROKER_SEARCH_WIDGET_CHANGE_INPUT:
            return state.set('search', action.payload.search);
        case actionTypes.BROKER_SEARCH_WIDGET_CLEAR_RESULTS:
            return state.merge({
                results: {},
                searchEmpty: true,
                fetched: false,
                error: false
            });
        case actionTypes.BROKER_SIMPLE_SEARCH_RESULTS_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
}

module.exports = brokerSearchWidgetReducer;
