// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @helpers
const { recordLink, recordProcess, recordLinkV2 } = require('utilities/tagging');
const { formatText } = require('core/i18n').i18n;

//@Actions
const actionTypes = require('constants/actionTypes');

// @constants
const {
    TYPE_STOCKS,
    TYPE_ETFS,
    TYPE_FUNDS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    BROKER_SEARCH_WIDGET_UTAG_NAME,
    BROKER_SEARCH_WIDGET_UTAG_CLOSE,
    BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH,
    BROKER_SEARCH_WIDGET_UTAG_COLLAPSED_FILTERS,
    BROKER_SEARCH_WIDGET_UTAG_INPUT_RESULTS,
    BROKER_SEARCH_WIDGET_UTAG_INPUT_NO_RESULTS,
    BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH_OPEN_RESULTS,
    BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH_OPEN_NO_RESULTS,
    BROKER_SEARCH_WIDGET_UTAG_OPEN_PRODUCT,
    BROKER_ADVANCED_SEARCH_UTAG_NAME,
    BROKER_ADVANCED_SEARCH_UTAG_CLEAN_FILTERS,
    BROKER_ADVANCED_SEARCH_UTAG_SEARCH,
    BROKER_ADVANCED_SEARCH_UTAG_LINK_NAME,
    BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY,
    BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES,
    BROKER_PRODUCT_ADVANCED_SEARCH_RISK,
    BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE,
    BROKER_PRODUCT_ADVANCED_SEARCH_MARKET,
    BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES,
    BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER,
    BROKER_ADVANCED_SEARCH_UTAG_EDIT_SEARCH,
    TEALIUM_EVENT_PRODUCT_IMPRESSION,
    BROKER_ADVANCED_SEARCH_UTAG_CART_ADD,
    HYPHEN
} = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({});

const formatFilterSearch = (filterSearchValue) => {
    return filterSearchValue.label ? filterSearchValue.label : filterSearchValue.toJS().label;
}

const formatMultipleFilterSearch = (filterSearchValues, allLabel) => {
    if (filterSearchValues.size > 0) {
        let labelValues = '';
        filterSearchValues.map((item, index) => {
            if (index < filterSearchValues.size -1) {
                labelValues += `${item.label}, `
            } else {
                labelValues += item.label
            }
        });
        return labelValues;
    } else {
        return allLabel;
    }
}

const getMarketId = (type, item) =>{
    switch (type) {
        case TYPE_STOCKS:
        case TYPE_ETFS:
            return item.ticker ? item.ticker : HYPHEN;
        case TYPE_PLANS:
            return item.dgsCode ? item.dgsCode : HYPHEN;
        case TYPE_FUNDS:
        case TYPE_WARRANTS:
            return item.isin ? item.isin : HYPHEN;
    }
}

const getProductData = (type, result, isBuyLink) => {
    return {
        tealium_event: isBuyLink ? BROKER_ADVANCED_SEARCH_UTAG_CART_ADD : BROKER_ADVANCED_SEARCH_UTAG_LINK_NAME,
        product_id: getMarketId(type, result.toJS()),
        product_name: result.get('name', HYPHEN),
        product_brand: result.get('provider', HYPHEN),
        product_variant: result.get('currency', HYPHEN),
        product_category: `${type}/${result.get('assetClass', HYPHEN)}/${result.get('subAssetClass', HYPHEN)}/${result.get('risk', HYPHEN)}/${result.get('market', HYPHEN)}`
    };
}

const getAllFiltersValues = (filtersSearch) => {
    const searchTerm = filtersSearch.get('term');
    const allLabelM = formatText('broker-allOptionsM');
    const allLabelF = formatText('broker-allOptionsF');
    const assetClassValues = formatMultipleFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES), allLabelF);
    const currencyValue = formatFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY));
    const managerValue = formatFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER));
    const marketValue = formatFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_MARKET));
    const productCategoryValues = formatMultipleFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES), allLabelM);
    const riskValue = formatFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_RISK));
    const subAssetClassValue = formatFilterSearch(filtersSearch.get(BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE));
    return `${searchTerm}|${assetClassValues}|${currencyValue}|${managerValue}|${marketValue}|${productCategoryValues}|${riskValue}|${subAssetClassValue}`;
}

const getProductsListData = (isinList, nameList, providerList, currencyList, categoryList, productsList, type) => {
    productsList.instrumentsList.map(product => {
        const market = product.market || HYPHEN;
        isinList.push(getMarketId(type, product));
        nameList.push(product.name);
        providerList.push(product.provider);
        currencyList.push(product.currency);
        categoryList.push(`${type}/${get(product,'assetClass', HYPHEN)}/${get(product,'subAssetClass', HYPHEN)}/${market}`);
    });
}

const getCustomDataAdvancedSearchResults = (productsList) => {
    const tealiumEvent = TEALIUM_EVENT_PRODUCT_IMPRESSION;
    const isinList = [];
    const nameList = [];
    const providerList = [];
    const currencyList = [];
    const categoryList = [];
    getProductsListData(isinList, nameList, providerList, currencyList, categoryList, productsList.etfs, TYPE_ETFS);
    getProductsListData(isinList, nameList, providerList, currencyList, categoryList, productsList.funds, TYPE_FUNDS);
    getProductsListData(isinList, nameList, providerList, currencyList, categoryList, productsList.plans, TYPE_PLANS);
    getProductsListData(isinList, nameList, providerList, currencyList, categoryList, productsList.stocks, TYPE_STOCKS);
    getProductsListData(isinList, nameList, providerList, currencyList, categoryList, productsList.warrants, TYPE_WARRANTS);

    return {
        tealium_event: tealiumEvent,
        product_impression_id: isinList,
        product_impression_name: nameList,
        product_impression_brand: providerList,
        product_impression_variant: currencyList,
        product_impression_category: categoryList
    }
}

const brokerSearchTagging = (state = INITIAL_STATE, action) => {
    let term;
    switch (action.type) {
        //simpleSearch
        case actionTypes.BROKER_SEARCH_WIDGET_HIDE:
            recordLink(BROKER_SEARCH_WIDGET_UTAG_NAME, BROKER_SEARCH_WIDGET_UTAG_CLOSE);
            return state;
        case actionTypes.BROKER_ADVANCED_SEARCH_KEEP_FILTERS_TRACKING:
            term = action.payload.term;
            const searchEmpty = action.payload.searchEmpty;
            const eventTagOpened = searchEmpty ? BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH_OPEN_NO_RESULTS : BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH_OPEN_RESULTS;
            const eventTag = term !== "" ? eventTagOpened : BROKER_SEARCH_WIDGET_UTAG_COLLAPSED_FILTERS;
            recordLink(BROKER_SEARCH_WIDGET_UTAG_NAME, BROKER_SEARCH_WIDGET_UTAG_NAVEGATE_ADVANCED_SEARCH, eventTag);
            return state;
        case actionTypes.BROKER_ADVANCED_SEARCH_KEEP_FILTERS_PRODUCT_TRACKING:
            term = action.payload.term;
            const productTypeLabel = action.payload.productTypeLabel;
            recordLink(BROKER_SEARCH_WIDGET_UTAG_NAME, productTypeLabel, term);
            return state;
        case actionTypes.BROKER_SEARCH_WIDGET_SUCCESS_TRACKING:
            term = action.payload.term;
            const searchEmptyWritting = action.payload.searchEmptyWritting;
            const eventAction = searchEmptyWritting ? BROKER_SEARCH_WIDGET_UTAG_INPUT_NO_RESULTS : BROKER_SEARCH_WIDGET_UTAG_INPUT_RESULTS;
            recordLink(BROKER_SEARCH_WIDGET_UTAG_NAME, eventAction, term);
            return state;
        case actionTypes.BROKER_SIMPLE_SEARCH_PRODUCT_TRACKING:
            term = action.payload.term;
            recordLink(BROKER_SEARCH_WIDGET_UTAG_NAME, BROKER_SEARCH_WIDGET_UTAG_OPEN_PRODUCT, term);
            return state;
        //advancedSearch
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_CLEAR_TRACKING:
            recordLink(BROKER_ADVANCED_SEARCH_UTAG_NAME, BROKER_ADVANCED_SEARCH_UTAG_CLEAN_FILTERS);
            return state;
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_TRACKING:
            const allFiltersValues = getAllFiltersValues(action.payload.filtersSearch);
            recordLinkV2({
                eventCategory: 'broker/buscador-avanzado',
                eventAction: 'search',
                eventLabel: allFiltersValues,
                categoriaEvento: BROKER_ADVANCED_SEARCH_UTAG_NAME,
                accionEvento: BROKER_ADVANCED_SEARCH_UTAG_SEARCH,
                etiquetaEvento: allFiltersValues
            });
            return state;
        case actionTypes.BROKER_ADVANCED_SEARCH_EDIT:
            recordLink(BROKER_ADVANCED_SEARCH_UTAG_NAME, BROKER_ADVANCED_SEARCH_UTAG_EDIT_SEARCH);
            return state;
        case actionTypes.BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES_TRACKING:
            const typeLabel = action.payload.typeLabel;
            recordLink(BROKER_ADVANCED_SEARCH_UTAG_NAME, typeLabel);
            return state;
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS:
            const productsList = action.payload.data;
            if(!action.payload.disableTealium){
                recordProcess(null, null, getCustomDataAdvancedSearchResults(productsList), true);
            }
            return state;
        case actionTypes.BROKER_PRODUCT_ADVANCED_SEARCH_LINK_NAME_TRACKING:
            recordLinkV2({
                eventCategory: 'broker/buscador-avanzado',
                eventAction: `${action.payload.type}Results`,
                eventLabel: getMarketId(action.payload.type, action.payload.result.toJS()),
                ...getProductData(action.payload.type, action.payload.result, action.payload.isBuyLink),
              });
            return state;
        default:
            return state;
    }
}

module.exports = brokerSearchTagging;
