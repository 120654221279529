const { EURO_SYMBOL } = require('constants/index')

const raReimbursementTags = (reimbursmentObject={}) => ({
    setValidAmount:{
        categoriaEvento:"proc-reembolso",
        accionEvento:"importe a reembolsar",
        etiquetaEvento: reimbursmentObject.portfolioName,
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
    },
    setInvalidAmount:{
        categoriaEvento:"proc-reembolso",
        accionEvento:"error importe a reembolsar",
        etiquetaEvento: reimbursmentObject.portfolioName,
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
        categoriaError: reimbursmentObject.errorCategory,
        descripcionError: reimbursmentObject.errorCategory,
    },
    focusOTPBox:{
        categoriaEvento:"proc-otp",
        accionEvento:"inicio",
        etiquetaEvento:"orden de reembolso robo",
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
    },
    consultOTPSuccess:{
        categoriaEvento:"proc-otp",
        accionEvento:"exito",
        etiquetaEvento:"orden de reembolso robo",
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
    },
    consultOTPError:{
        categoriaEvento:"proc-otp",
        accionEvento:"error",
        etiquetaEvento:"orden de reembolso robo",
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
        categoriaError: reimbursmentObject.errorCategory,
        descripcionError: reimbursmentObject.errorDescription,
    },
    resetOTP: {
        categoriaEvento:"proc-otp",
        accionEvento:"reinicio",
        etiquetaEvento:"orden de reembolso robo",
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
    },
    reimbursementConfirm: {
        categoriaEvento:"proc-reembolso",
        accionEvento:"confirmar",
        etiquetaEvento:`robo cartera ${reimbursmentObject.productName}`,
        amount: reimbursmentObject.amount + EURO_SYMBOL,
        nombreProducto: reimbursmentObject.productName,
    },
});

module.exports = { raReimbursementTags };
