// @vendors
const Immutable = require('immutable');

// @reducers
const cardsCancelPayLaterStep1Reducer = require('./cardsCancelPayLaterStep1');

// @helpers
const get = require('lodash/object/get');

// @constants
const { FIRST_STEP } = require('constants/index');
const {
    CARDS_CANCEL_PAY_LATER_FAILURE,
    CARDS_CANCEL_PAY_LATER_INPROGRESS,
    CARDS_CANCEL_PAY_LATER_SET_VISIBLE_STEP,
    CARDS_CANCEL_PAY_LATER_SUCCESS,
    CARDS_CANCEL_PAY_LATER_VALIDATE_STEP,
    RESET_CARDS_CANCEL_PAY_LATER
} = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        cancelPayLaterData: {},
        isLoading: false,
        submitSuccessful: false,
        error: false,
        visibleStep: 1,
        steps: [
            cardsCancelPayLaterStep1Reducer(undefined, {}),
            { hasSignaturePattern: true }
        ]
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardsCancelPayLaterStep1Reducer(step, action);
        }
    })
}

function cardsCancelPayLaterReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case CARDS_CANCEL_PAY_LATER_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case CARDS_CANCEL_PAY_LATER_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case CARDS_CANCEL_PAY_LATER_SUCCESS:
            return state.merge({
                isLoading: false,
                submitSuccessful: true,
                error: '',
            });
        case CARDS_CANCEL_PAY_LATER_INPROGRESS:
            return state.set('isLoading', true);
        case CARDS_CANCEL_PAY_LATER_FAILURE:
            return state.merge({
                isLoading: false,
                submitSuccessful: false,
                error: get(action, 'payload.error', true),
            });
        case RESET_CARDS_CANCEL_PAY_LATER:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = cardsCancelPayLaterReducer;