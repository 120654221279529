// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
//@Actions
const actionTypes = require('constants/actionTypes');
// @constants
const {
    EURO_TEXT,
    TYPE_OTHERS,
    TYPE_OTROS,
    TYPE_MERCADO_MONETARIO,
    TYPE_RENTA_FIJA,
    TYPE_RENTA_VARIABLE,
    TYPE_GESTION_ALTERNATIVA,
    TYPE_ACTIVOS_REALES,
    TYPE_MIXTOS,
} = require('constants/index');
const {
    BROKER_CUSTOM_WALLET_GENERAL_VIEW,
    BROKER_CUSTOM_CONFIG_WALLET_GENERAL_NAME
}= require('customWallets/constants/index');

const { INVESTMENT_PRODUCTS_LIST } = require('constants/flagBasedFeatures');
const {
    TYPE_ETFS,
    TYPE_FUNDS,
    TYPE_PLANS,
    TYPE_STOCKS,
    TYPE_WARRANTS,
    TYPE_ALTERNATIVE_FUNDS_PG,
    ALTERNATIVE_FUNDS
} = require('constants/index');

const getPercentages = (stocksSummarization, walletSummarization, hasOthers, featureToggling) => {
    const totalStocks = get(stocksSummarization, 'stocks.percentageInWallet', 0);
    const totalWarrants = get(stocksSummarization, 'warrants.percentageInWallet', 0);
    const totalETFs = get(stocksSummarization, 'etfs.percentageInWallet', 0);
    const totalFunds = get(stocksSummarization, 'funds.percentageInWallet', 0);
    const totalAlternativeFunds = get(stocksSummarization, 'alternativeFunds.percentageInWallet', 0);
    const totalPlans = get(stocksSummarization, 'plans.percentageInWallet', 0);
    const totalOthers = get(stocksSummarization, 'others.percentageInWallet', 0);
    const products = featureToggling.get(INVESTMENT_PRODUCTS_LIST);
    const percentages = [
        {
            productId: TYPE_FUNDS,
            label: get(walletSummarization, 'funds.alias'),
            value: Number(totalFunds),
            currency: EURO_TEXT
        },
        {
            productId: TYPE_STOCKS,
            label: 'stocks',
            value: Number(totalStocks),
            currency: EURO_TEXT
        },
       {
            productId: TYPE_ALTERNATIVE_FUNDS_PG,
            label: ALTERNATIVE_FUNDS,
            value: Number(totalAlternativeFunds),
            currency: EURO_TEXT
        },
        {
            productId: TYPE_ETFS,
            label: 'etfs',
            value: Number(totalETFs),
            currency: EURO_TEXT
        },
        {
            productId: TYPE_PLANS,
            label: get(walletSummarization, 'plans.alias'),
            value: Number(totalPlans),
            currency: EURO_TEXT
        },
        {
            productId: TYPE_WARRANTS,
            label: 'warrants',
            value: Number(totalWarrants),
            currency: EURO_TEXT
        }
    ]

    let allowedPercentagesByOriginCountry = percentages.filter(percentage => products.includes(percentage.productId))

    if (hasOthers) {
        allowedPercentagesByOriginCountry.push({
            label: TYPE_OTHERS,
            value: Number(totalOthers),
            currency: EURO_TEXT
        })
    }

    return allowedPercentagesByOriginCountry;
};

const getPercentagesByActives = (stocksActivesSummarization) => {
    const totalMercadoMonetario = get(stocksActivesSummarization, '[Mercado monetario].percentage', 0);
    const totalRentaFija = get(stocksActivesSummarization, '[Renta fija].percentage', 0);
    const totalRentaVariable = get(stocksActivesSummarization, '[Renta variable].percentage', 0);
    const totalGestionAlternativa = get(stocksActivesSummarization, '[Inversion alternativa].percentage', 0);
    const totalActivosReales = get(stocksActivesSummarization, '[Activos reales].percentage', 0);
    const hasMixtos = get(stocksActivesSummarization, 'Mixtos.value', 0);
    const hasOtros = get(stocksActivesSummarization, 'Otros.value', 0);
    let percentages = [
        {
            label: TYPE_MERCADO_MONETARIO,
            value: Number(totalMercadoMonetario),
            currency: EURO_TEXT
        },
        {
            label: TYPE_RENTA_FIJA,
            value: Number(totalRentaFija),
            currency: EURO_TEXT
        },
        {
            label: TYPE_RENTA_VARIABLE,
            value: Number(totalRentaVariable),
            currency: EURO_TEXT
        },
        {
            label: TYPE_GESTION_ALTERNATIVA,
            value: Number(totalGestionAlternativa),
            currency: EURO_TEXT
        },
        {
            label: TYPE_ACTIVOS_REALES,
            value: Number(totalActivosReales),
            currency: EURO_TEXT
        },

    ]

    if (hasMixtos) {
        const totalMixtos = get(stocksActivesSummarization, 'Mixtos.percentage', 0);

        percentages.push({
            label: TYPE_MIXTOS,
            value: Number(totalMixtos),
            currency: EURO_TEXT
        })
    }

    if (hasOtros) {
        const totalOtros = get(stocksActivesSummarization, 'Otros.percentage', 0);

        percentages.push({
            label: TYPE_OTROS,
            value: Number(totalOtros),
            currency: EURO_TEXT
        })
    }

    return percentages;
};

const calculatePercentages = (state, stocksSummarization, stocksActivesSummarization, walletSummarization, totalSummarized, featureToggling) => {
    const hasStocks = get(stocksSummarization, 'stocks.total', 0);
    const hasFunds = get(stocksSummarization, 'funds.total', 0);
    const hasPlans = get(stocksSummarization, 'plans.total', 0);
    const hasWarrants = get(stocksSummarization, 'warrants.total', 0);
    const hasETFs = get(stocksSummarization, 'etfs.total', 0);
    const hasOthers = get(stocksSummarization, 'others.total', 0);
    const hasAlternativeFunds = get(stocksSummarization, 'alternativeFunds.total', 0);

    const totalProfitability = totalSummarized.summarizedProfitability;
    const totalInvestment = totalSummarized.summarizedInvestedCap;
    const totalValue = totalSummarized.summarizedValor;
    const percentages = getPercentages(stocksSummarization, walletSummarization, hasOthers, featureToggling);
    const activePerClassesPercentages = getPercentagesByActives(stocksActivesSummarization);

    // (Andres): There are a case when the user has 0 euros invested but they still have products, so to identify if the user
    // has investments, we need to check if there are products when the total investment is zero.
    const hasInvestments = totalInvestment > 0 || hasStocks || hasWarrants || hasETFs || hasFunds || hasPlans || hasOthers || hasAlternativeFunds;

    return state
        .set('percentages', Immutable.fromJS(percentages))
        .set('activePerClassesPercentages', Immutable.fromJS(activePerClassesPercentages))
        .set('totalProfitabilityPercentage', totalSummarized.profitablePercentage)
        .set('canDisplayProfitability', totalInvestment !== null && totalProfitability !== null)
        .set('totalProfitability', Immutable.fromJS({
            amount: totalProfitability,
            currency: EURO_TEXT
        }))
        .set('canDisplayInvestment', totalInvestment !== null && totalProfitability !== null)
        .set('totalInvestment', Immutable.fromJS({
            amount: totalInvestment,
            currency: EURO_TEXT
        }))
        .set('totalValue', Immutable.fromJS({
            amount: totalValue,
            currency: EURO_TEXT
        }))
        .set('isFetching', false)
        .set('hasInvestments', hasInvestments)
        .set('fetchSuccess', true);
};

const initialState = Immutable.fromJS({
    isFetching: true,
    fetchSuccess: false,
    fetchError: false,
    showModal: false,
    showEvolutionModal: false,
    canDisplayProfitability: false,
    isGeneralViewSelected: false,
    isCustomWalletSelected: false,
    selectedView: '',
    defaultView: {
        idDefaultView: null,
        isFetching: false,
        fetchSuccess: false,
        fetchError: false
    },
    totalValueGeneral: {
        amount: null,
        currency: EURO_TEXT
    },
    totalProfitability: {
        amount: null,
        currency: EURO_TEXT
    },
    canDisplayInvestment: false,
    totalInvestment: {
        amount: null,
        currency: EURO_TEXT
    },
    totalValue: {
        amount: null,
        currency: EURO_TEXT
    },
    hasInvestments: false,
    percentages: [],
    //activePerClassesPercentages: [],
    rentability: {
        importe: null,
        divisa: 'EUR'
    },
    capital: {
        importe: null,
        divisa: 'EUR'
    },
    evolutionIsFetching: false,
    evolutionFetchSuccess: false,
    evolutionError: false,
    evolutionData: [],
    summarizationData: {},
    hasPendingOperations: false,
    activeTab: 0
});

const myWalletChartsReducer = (state = initialState, action) => {
    let isGeneralView = false;

    switch (action.type) {
        case actionTypes.BROKER_GET_STOCKS_BY_TYPE_REQUEST:
            return state.merge({
                fetchSuccess: false,
                isFetching: true,
                fetchError: false
            });
        case actionTypes.BROKER_SUMMARIZE_INVESTMENTS_BY_TYPE:
            return calculatePercentages(
                state,
                action.payload.stocksSummarization,
                action.payload.stocksActivesSummarization,
                action.payload.walletSummarization,
                action.payload.totalSummarized,
                action.payload.featureToggling,

            );
        case actionTypes.BROKER_GET_STOCKS_BY_TYPE_FAILURE:
            return state.merge({
                fetchSuccess: false,
                isFetching: false,
                fetchError: true
            });
        case actionTypes.BROKER_EVOLUTION_DATA_FETCHING:
            return state.merge({
                evolutionError: false,
                evolutionFetchSuccess: false,
                evolutionIsFetching: true
            });
        case actionTypes.BROKER_EVOLUTION_DATA_FAILURE:
            return state.merge({
                evolutionFetchSuccess: false,
                evolutionIsFetching: false,
                evolutionError: true
            });
        case actionTypes.BROKER_EVOLUTION_DATA_SUCCESS:
            return state.merge({
                evolutionFetchSuccess: true,
                evolutionIsFetching: false
            }).setIn(['evolutionData'], Immutable.fromJS(action.payload.data));
        case actionTypes.BROKER_CHANGE_SELECTED_VIEW:
            isGeneralView = action.payload.data.toUpperCase() === BROKER_CUSTOM_CONFIG_WALLET_GENERAL_NAME.toUpperCase() ? true : false;
            return state.merge({
                selectedView: action.payload.data,
                isGeneralViewSelected : isGeneralView,
                isCustomWalletSelected : !isGeneralView,
                evolutionIsFetching: false,
                evolutionFetchSuccess: false,
                evolutionError: false,
                evolutionData: []
            });
        case actionTypes.BROKER_SET_GENERAL_VIEW_TOTAL:
            return state.mergeIn(['totalValueGeneral'], {
                amount: action.payload.generalTotalAmount,
                currency: EURO_TEXT
            }).mergeIn(['consolidatedValueGeneral'], {
                amount: action.payload.generalConsolidatedAmount,
                currency: EURO_TEXT
            });
        case actionTypes.BROKER_SET_SUMMARIZATION_DATA:
            const summarization = action.payload.summarization;
            const actualTotalValue = get(summarization, 'total.valor.importeEur.importe');
            const rentabilityOperations = get(summarization, 'consolidado.valor.importeEur.importe');

            return state.merge({
                summarizationData: summarization,
                hasPendingOperations: actualTotalValue !== rentabilityOperations
            });
        case actionTypes.BROKER_REQUEST_DEFAULT_VIEW_ID:
            return state.mergeIn(['defaultView'], {
                isFetching: true,
                fetchError: false,
                fetchSuccess: false,
            });
        case actionTypes.BROKER_GET_DEFAULT_VIEW_ID_SUCCESS:
            isGeneralView = Number(action.payload.viewId) === Number(BROKER_CUSTOM_WALLET_GENERAL_VIEW) ? true : false

            return state.merge({
                isGeneralViewSelected: isGeneralView,
                isCustomWalletSelected: !isGeneralView,
                selectedView: action.payload.viewName,
            }).mergeIn(['defaultView'], {
                idDefaultView: action.payload.viewId,
                isFetching: false,
                fetchSuccess: true,
                isGeneralView: isGeneralView,
                isCustomWalletSelected: !isGeneralView
            });
        case actionTypes.BROKER_GET_DEFAULT_VIEW_ID_FAILURE:
        case actionTypes.BROKER_CUSTOM_WALLET_GET_PORTFOLIOS_LIST_ERROR:
            return state.merge({
                isGeneralViewSelected: true,
                isCustomWalletSelected: false,
                selectedView: BROKER_CUSTOM_CONFIG_WALLET_GENERAL_NAME
            }).mergeIn(['defaultView'], {
                idDefaultView: BROKER_CUSTOM_WALLET_GENERAL_VIEW,
                fetchError: true,
                isFetching: false,
                isGeneralView: true,
                isCustomWalletSelected: false
            });
        case actionTypes.BROKER_MY_WALLET_TOGGLE_MODAL:
            const showModal = state.get('showModal');
            return state.set('showModal', !showModal);
        case actionTypes.BROKER_MY_WALLET_TOGGLE_EVOLUTION_MODAL:
            const showEvolutionModal = state.get('showEvolutionModal');
            return state.set('showEvolutionModal', !showEvolutionModal);
        case actionTypes.BROKER_GET_DEFAULT_VIEW_RESET:
            return initialState;
        case actionTypes.BROKER_MYWALLET_CHANGE_TAB:
            return state.merge({
                activeTab: action.payload.tab
            });
        default:
            return state;
    }
};

module.exports = myWalletChartsReducer;
