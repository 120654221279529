// @vendors
const Immutable = require('immutable');

//@utilities
const { stocksByTypeAdapter, stocksByContract, stocksByActiveClassAdapter, updateDescripcion, hasAssociatedRights } = require('./brokerMyInvestmentsHelper');

// @constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        products: {
            stocks: {stocks: []},
            warrants: {stocks: []},
            etfs: {stocks: []},
            funds: {stocks: []},
            alternativeFunds: {stocks: []},
            plans: {stocks: []},
            futures: {stocks: []},
            others: {stocks: []}
        },
        distribucionPorRenta:{},
        contracts: {},
        totalSummarizedValues: {
            summarizedInvestedCap: 0,
            summarizedProfitability: 0,
            summarizedValor: 0,
            profitablePercentage: 0
        },
        totalOriginalProducts: [],
        isFetching: false,
        fetchSuccess: false,
        errorReceived: false,
        isOutdated: false,
        isRightsAssociated: false,
        isderechosInfoShown: false,
        maxFundsPartialReimbursementPercentage: 0,
    });
}

const myInvestmentsRequested = (immState) => {
    return immState.merge({
        isFetching: true,
        fetchSuccess: false,
        errorReceived: false
    });
};

const myInvestmentsFetched = (immState, response) => {
    const summarizedInvestedCap = response.summarizedInvestedCap;
    const summarizedProfitability = response.summarizedProfitability;
    const summarizedValor = response.summarizedValor;
    const profitablePercentage = response.profitablePercentage;
    const distribucionPorRenta = response.distribucionPorRenta;
    const hasDerechosAssociado = hasAssociatedRights(response.data);
    const maxFundsPartialReimbursementPercentage = response.maxFundsPartialReimbursementPercentage;

    return immState.merge({
        products: stocksByTypeAdapter(response),
        actives: stocksByActiveClassAdapter(response),
        contracts: stocksByContract(response.contracts),
        distribucionPorRenta,
        totalSummarizedValues: {
            summarizedInvestedCap: summarizedInvestedCap == null ? null : Number(summarizedInvestedCap),
            summarizedProfitability: summarizedProfitability == null ? null : Number(summarizedProfitability),
            summarizedValor: Number(summarizedValor) || 0,
            profitablePercentage: Number(profitablePercentage) || 0
        },
        fetchSuccess: true,
        isFetching: false,
        errorReceived: false,
        isOutdated: false,
        isRightsAssociated: hasDerechosAssociado,
        isderechosInfoShown: hasDerechosAssociado,
        maxFundsPartialReimbursementPercentage: maxFundsPartialReimbursementPercentage,
    });
};

const myInvestmentsErrorFetching = (immState) => {
    return immState.merge({
        isFetching: false,
        fetchSuccess: false,
        errorReceived: true
    });
};

function brokerMyInvestmentsReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.SET_WALLET_ALIAS_SUCCESS:
        case actionTypes.SET_WALLET_ALIAS_FAILURE:
        case actionTypes.SET_WALLET_ALIAS_REQUEST:
            return updateDescripcion(state, action.payload.walletId, action.payload.alias);
        case actionTypes.BROKER_GET_STOCKS_BY_TYPE_FOR_THE_FIRST_TIME:
            return state.merge({
                totalOriginalProducts: action.payload.data
            });
        case actionTypes.BROKER_GET_INVESTMENTS_ISIN_FUNDS_SUCCESS:
             return state.mergeDeep({ products: action.payload.data.products });
         case actionTypes.BROKER_GET_INVESTMENTS_ISIN_PLANS_SUCCESS:
             return state.mergeDeep({ products: action.payload.data.products });
        case actionTypes.BROKER_GET_MY_INVESTMENTS_REQUEST:
            return myInvestmentsRequested(state);
        case actionTypes.BROKER_GET_MY_INVESTMENTS_SUCCESS:
            return myInvestmentsFetched(state, action.payload);
        case actionTypes.BROKER_GET_MY_INVESTMENTS_FAILURE:
            return myInvestmentsErrorFetching(state);
        case actionTypes.BROKER_GET_MY_INVESTMENTS_SET_OUTDATED:
            return state.set('isOutdated', true);
        case actionTypes.BROKER_GET_DERECHOS_INFO_MESSAGE:
            return state.merge({
                isderechosInfoShown: action.payload.value
            });
        default:
            return state;
    }
}

module.exports = brokerMyInvestmentsReducer;
