// @ vendors
const Immutable = require('immutable');
// @ constants
import {
  AGGREGATOR_ADD_ENTITY_VALIDATE_STEP,
  AGGREGATOR_ADD_ENTITY_SELECT_BANK,
  AGGREGATOR_ADD_ENTITY_GET_BANKS_REQUEST,
  AGGREGATOR_ADD_ENTITY_GET_BANKS_SUCCESS,
  AGGREGATOR_ADD_ENTITY_GET_BANKS_FAILURE,
  AGGREGATOR_ADD_ENTITY_GET_FORM_REQUEST,
  AGGREGATOR_ADD_ENTITY_GET_FORM_SUCCESS,
  AGGREGATOR_ADD_ENTITY_GET_FORM_FAILURE,
  AGGREGATOR_ADD_ENTITY_SET_FORM_VALUE,
  AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_REQUEST,
  AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_SUCCESS,
  AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_FAILURE,
  AGGREGATOR_ADD_ENTITY_SET_VISIBLE_STEP,
  AGGREGATOR_ADD_ENTITY_WILL_CANCEL,
  AGGREGATOR_ADD_ENTITY_CLEAR,
  AGGREGATOR_ADD_ENTITY_CLEAR_FORM_VALUES,
  AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_REQUEST,
  AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_SUCCESS,
  AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_FAILURE,
  GET_TINK_URL_REQUEST,
  GET_TINK_URL_SUCCESS,
  GET_TINK_CREATE_USER_BANKS_REQUEST,
  GET_TINK_CREATE_USER_BANKS_SUCCESS,
  GET_TINK_CREATE_USER_BANKS_FAILURE,
  REFRESH_AGGREGATION_TINK_REQUEST,
  REFRESH_AGGREGATION_TINK_SUCCESS,
  REFRESH_AGGREGATION_TINK_FAILURE,
} from 'constants/actionTypes';

//const { FIRST_STEP } = require('constants/index');
// @ reducers
const STEP_1 = Immutable.fromJS({
  valid: true,
  error: false,
  // cards status can be null= loading or an array: requested
  banks: null,
  isFetching: false,
  selectedBank: null,
});

const STEP_2 = Immutable.fromJS({
  valid: true,
  error: null,
  isFetching: false,
  name: null,
  url: null,
  form: null,
  code: null,
  credential: null,
  publicKey: null,
  psd2Supported: false,
  psd2Info: null,
});

function setInitialState() {
  return Immutable.fromJS({
    submitFetching: false,
    getTinkUrlFetching: false,
    visibleStep: 1,
    submitSuccessful: false,
    error: null,
    termsAndConditions: false,
    termsAndConditionsFetch: false,
    termsAndConditionsError: false,
    steps: [STEP_1, STEP_2],
    values: {},
    isCreateUserBanksFetching: false,
    userBank: null,
    isRefreshAggregationFetching: false,
  });
}

function aggregatorAddEntity(state = setInitialState(), action) {
  switch (action.type) {
    case AGGREGATOR_ADD_ENTITY_VALIDATE_STEP:
      const goToNextStep = () => state.merge({ visibleStep: action.payload.step + 1 });
      const hasSelectedBank = state.getIn(['steps', 0, 'selectedBank', 'id']);
      switch (action.payload.step) {
        case 1: {
          if (hasSelectedBank) {
            return goToNextStep();
          }
        }
        case 2: {
          return state;
        }
        default:
          return state;
      }
    case AGGREGATOR_ADD_ENTITY_SELECT_BANK: {
      const { selectedBank } = action.payload;
      return state.setIn(['steps', 0, 'selectedBank'], selectedBank);
    }
    case AGGREGATOR_ADD_ENTITY_GET_BANKS_REQUEST: {
      const values = state.getIn(['steps', 0]).merge({ isFetching: true, error: null });
      return state.setIn(['steps', 0], values);
    }
    case AGGREGATOR_ADD_ENTITY_GET_BANKS_SUCCESS: {
      const { banks } = action.payload;
      const values = state
        .getIn(['steps', 0])
        .merge({ banks, isFetching: false, error: !banks.length });
      return state.setIn(['steps', 0], values);
    }
    case AGGREGATOR_ADD_ENTITY_GET_BANKS_FAILURE: {
      const { error } = action.payload;
      const values = state.getIn(['steps', 0]).merge({ error, isFetching: false });
      return state.setIn(['steps', 0], values);
    }
    case AGGREGATOR_ADD_ENTITY_GET_FORM_REQUEST: {
      const values = state.getIn(['steps', 1]).merge({ form: null, isFetching: true, error: null });
      return state.setIn(['steps', 1], values);
    }
    case AGGREGATOR_ADD_ENTITY_GET_FORM_SUCCESS: {
      const { form, code, publicKey, beta, name, logo, psd2Supported, psd2Info } = action.payload;
      return state.updateIn(['steps', 1], f =>
        f.merge({
          form,
          code,
          publicKey,
          beta,
          name,
          logo,
          isFetching: false,
          psd2Supported,
          psd2Info,
        })
      );
    }
    case AGGREGATOR_ADD_ENTITY_GET_FORM_FAILURE: {
      const { error } = action.payload;
      const values = state.getIn(['steps', 1]).merge({ error, isFetching: false });
      return state.setIn(['steps', 1], values);
    }
    case AGGREGATOR_ADD_ENTITY_SET_FORM_VALUE: {
      const { id, value } = action.payload;
      return state.mergeIn(['values'], { [id]: value });
    }
    case AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_REQUEST: {
      return state.merge({ submitFetching: true, error: null, submitSuccessful: false });
    }

    case GET_TINK_URL_REQUEST: {
      return state.merge({ getTinkUrlFetching: true });
    }

    case GET_TINK_URL_SUCCESS: {
      return state.merge({ getTinkUrlFetching: false });
    }

    case GET_TINK_CREATE_USER_BANKS_REQUEST: {
      return state.merge({ isCreateUserBanksFetching: true });
    }

    case GET_TINK_CREATE_USER_BANKS_SUCCESS: {
      const { userBank } = action.payload;

      return state.merge({ userBank });
    }

    case GET_TINK_CREATE_USER_BANKS_FAILURE: {
      const { error } = action.payload;

      return state.merge({
        error,
      });
    }

    case REFRESH_AGGREGATION_TINK_REQUEST: {
      return state.merge({ isRefreshAggregationFetching: true });
    }

    case REFRESH_AGGREGATION_TINK_SUCCESS: {
      const { data } = action.payload;

      return state.merge({ data });
    }

    case REFRESH_AGGREGATION_TINK_FAILURE: {
      const { error } = action.payload;
      return state.merge({ error });
    }

    case AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_SUCCESS: {
      return state.merge({ submitFetching: false, submitSuccessful: true });
    }
    case AGGREGATOR_ADD_ENTITY_SEND_FORM_INFO_FAILURE: {
      const { error } = action.payload;
      return state.merge({ error, submitFetching: false });
    }
    case AGGREGATOR_ADD_ENTITY_SET_VISIBLE_STEP:
      return state.merge({
        visibleStep: action.payload.step,
      });

    case AGGREGATOR_ADD_ENTITY_WILL_CANCEL:
      return state;
    case AGGREGATOR_ADD_ENTITY_CLEAR:
      return setInitialState();
    case AGGREGATOR_ADD_ENTITY_CLEAR_FORM_VALUES:
      return state.merge({ values: {} });

    case AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_REQUEST:
      return state.merge({
        termsAndConditions: false,
        termsAndConditionsError: false,
        termsAndConditionsFetch: true,
      });
    case AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_SUCCESS:
      return state.merge({
        termsAndConditions: true,
        termsAndConditionsError: false,
        termsAndConditionsFetch: false,
      });
    case AGGREGATOR_ADD_ENTITY_TERMS_AND_CONDITIONS_FAILURE:
      return state.merge({
        termsAndConditions: false,
        termsAndConditionsError: true,
        termsAndConditionsFetch: false,
      });
    default:
      return state;
  }
}

module.exports = aggregatorAddEntity;
