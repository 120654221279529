const types = require('constants/actionTypes');

const {
    filterFirstLettersWithResults,
    searchByQuery,
    searchByFirstLetter,
    selectResult,
} = require('./glossaryIndex');

const ALL_FIRST_LETTERS = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
    'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
];

const initialState = {
    isOpen: false,
    query: '',
    results: [],
    selectedResult: false,
    selectedLetter: 'a',
    firstLettersWithResults: [],
};


const investmentFundsGlossary = (state = initialState, action) => {
    switch (action.type) {
        case (types.GLOSSARY_CLOSED): {
            return { ...state, isOpen: false, selectedResult: false };
        }
        case (types.GLOSSARY_LETTER_SELECTED): {
            const { letter, originCountry } = action.payload;
            const results = searchByFirstLetter(letter, originCountry);
            return { ...state, query: '', results, selectedLetter: letter };
        }
        case (types.GLOSSARY_OPENED): {
            const { query, originCountry} = action.payload;
            const results = searchByFirstLetter('a', originCountry);
            const selectedLetter = 'a';
            const firstLettersWithResults = ALL_FIRST_LETTERS.filter(letter => filterFirstLettersWithResults(letter, originCountry));
            return { ...initialState, firstLettersWithResults, isOpen: true, query, results, selectedLetter, selectedResult: false };
        }
        case (types.GLOSSARY_QUERY_CHANGED): {
            const { query, originCountry } = action.payload;
            const selectedLetter = query.length > 0 ? false : 'a';
            const results = query.length > 0 ? searchByQuery(query, originCountry) : searchByFirstLetter('a', originCountry);
            return { ...state, query, results, selectedLetter, selectedResult: false };
        }
        case(types.GLOSSARY_RESULT_CLOSED): {
            return { ...state, selectedResult: false };
        }
        case (types.GLOSSARY_RESULT_SELECTED): {
            const { result, originCountry } = action.payload;
            const results = searchByFirstLetter('a', originCountry);
            const selectedLetter = 'a';
            const firstLettersWithResults = ALL_FIRST_LETTERS.filter(letter => filterFirstLettersWithResults(letter, originCountry));
            const selectedResult = selectResult(result, originCountry);
            return { ...state, firstLettersWithResults, isOpen: true, query: result, results, selectedLetter, selectedResult };
        }
        default:
            return state;
    }
};


module.exports = investmentFundsGlossary;
