//@core
const { baseHost } = require('core/environmentConfig');
//@constants
const {
  LOCAL_ENVIRONMENT,
  DEV_ENVIRONMENT,
  QA_ENVIRONMENT,
  STAGING_ENVIRONMENT
} = require('constants/index');

const isALowerEnvironment = () => {
  let response = false
  switch (baseHost) {
    case LOCAL_ENVIRONMENT:
    case DEV_ENVIRONMENT:
    case QA_ENVIRONMENT:
    case STAGING_ENVIRONMENT:
      response = true
      break;
    default: response = false
  }
  return response
}

module.exports = {
    isALowerEnvironment
};
