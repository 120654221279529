const get = require("lodash/object/get");

const { FLOW_TYPE_ONLY_ACCESS } = require("../constants");
const {
    FETCH_DEVICE_ACTION_ERROR,
    FETCH_DEVICE_ACTION_SUCCESS,
    MODIFY_DEVICE_ALIAS_SUCCESS,
    MODIFY_DEVICE_ALIAS_ERROR,
    FETCH_DEVICES_FAILURE,
    FETCH_DEVICES_SUCCESS,
    FETCH_SESSION_ACTION_SUCCESS,
    FETCH_SESSION_ACTION_ERROR
} = require("constants/actionTypes");
const {
    FETCH_DEVICES_CC_OPEN_MODAL_BLOCK,
    FETCH_DEVICES_CC_OPEN_MODAL_HIDE,
    FETCH_DEVICES_CC_OPEN_MODAL_SHOW,
    FETCH_DEVICES_CC_OPEN_MODAL_UNBLOCK,
    MODIFY_DEVICES_CC_ALIAS
} = require("constants/contactCenter/actionTypes");

const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL
} = require("utilities/contactCenter/wrapUp/constants");

const ACTION_CODE_DEVICES = 5213;
const ACTION_CODE_DEVICES_HIDE = 5214;
const ACTION_CODE_DEVICES_BLOCK = 5215;
const ACTION_CODE_HIDDEN_DEVICES = 5216;
const ACTION_CODE_DEVICES_SHOW = 5218;
const ACTION_CODE_DEVICES_UNBLOCK = 5219;
const ACTION_CODE_DEVICES_ALIAS = 1300;
const ACTION_CODE_SESSION_OUT = 5278;

const commonCheckDeviceActions = action => {
    let operationState = OPERATION_LOG_DONE;
    let errorMessage;
    let actionCode;
    const actionCodeType = action.payload.ccActionCode || action.type;

    switch (actionCodeType) {
        case "FETCH_DEVICES_CC_OPEN_MODAL_BLOCK":
            actionCode = ACTION_CODE_DEVICES_BLOCK;
            break;
        case "FETCH_DEVICES_CC_OPEN_MODAL_HIDE":
            actionCode = ACTION_CODE_DEVICES_HIDE;
            break;
        case "FETCH_DEVICES_CC_OPEN_MODAL_SHOW":
            actionCode = ACTION_CODE_DEVICES_SHOW;
            break;
        case "FETCH_DEVICES_CC_OPEN_MODAL_UNBLOCK":
            actionCode = ACTION_CODE_DEVICES_UNBLOCK;
            break;
        case "MODIFY_DEVICES_CC_ALIAS":
            actionCode = ACTION_CODE_DEVICES_ALIAS;
            break;
        case 'FETCH_SESSION_ACTION_SUCCESS':
        case 'FETCH_SESSION_ACTION_ERROR':
            actionCode = ACTION_CODE_SESSION_OUT;
            break;
    }

    const sessionOutFlow = [FETCH_SESSION_ACTION_ERROR].includes(action.type);

    if (action.type === FETCH_DEVICE_ACTION_ERROR
        || sessionOutFlow) {
        operationState = OPERATION_LOG_FAIL;
        errorMessage =  get(action, 'payload.error.description')
            || get(action, 'payload.data.description')
            || get(action, 'payload.error.text')
            || 'No error\'s description'
    }

    return {
        actionCode,
        operationState,
        errorMessage
    };
};

module.exports = {
    [ACTION_CODE_DEVICES]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: "/myprofile/devices",
            failure: [{ action: FETCH_DEVICES_FAILURE }],
            success: [{ action: FETCH_DEVICES_SUCCESS }]
        }
    },

    [ACTION_CODE_DEVICES_ALIAS]: {
        entry: {
            action: [MODIFY_DEVICES_CC_ALIAS]
        },
        finish: {
            action: [MODIFY_DEVICE_ALIAS_ERROR, MODIFY_DEVICE_ALIAS_SUCCESS],
            check: commonCheckDeviceActions
        }
    },

    [ACTION_CODE_HIDDEN_DEVICES]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: "/myprofile/devices/forgotten",
            failure: [{ action: FETCH_DEVICES_FAILURE }],
            success: [{ action: FETCH_DEVICES_SUCCESS }]
        }
    },
    [ACTION_CODE_DEVICES_HIDE]: {
        entry: {
            action: [FETCH_DEVICES_CC_OPEN_MODAL_HIDE]
        },
        finish: {
            action: [FETCH_DEVICE_ACTION_ERROR, FETCH_DEVICE_ACTION_SUCCESS],
            check: commonCheckDeviceActions
        }
    },
    [ACTION_CODE_DEVICES_BLOCK]: {
        entry: {
            action: [FETCH_DEVICES_CC_OPEN_MODAL_BLOCK]
        },
        finish: {
            action: [FETCH_DEVICE_ACTION_ERROR, FETCH_DEVICE_ACTION_SUCCESS],
            check: commonCheckDeviceActions
        }
    },
    [ACTION_CODE_DEVICES_SHOW]: {
        entry: {
            action: [FETCH_DEVICES_CC_OPEN_MODAL_SHOW]
        },
        finish: {
            action: [FETCH_DEVICE_ACTION_ERROR, FETCH_DEVICE_ACTION_SUCCESS],
            check: commonCheckDeviceActions
        }
    },
    [ACTION_CODE_DEVICES_UNBLOCK]: {
        entry: {
            action: [FETCH_DEVICES_CC_OPEN_MODAL_UNBLOCK]
        },
        finish: {
            action: [FETCH_DEVICE_ACTION_ERROR, FETCH_DEVICE_ACTION_SUCCESS],
            check: commonCheckDeviceActions
        }
    },
    [ACTION_CODE_SESSION_OUT]: {
        finish: {
            action: [FETCH_SESSION_ACTION_SUCCESS, FETCH_SESSION_ACTION_ERROR],
            check: commonCheckDeviceActions
        }
    }
};
