// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
// @helpers
const sanitizeHelper = require('utilities/sanitizeHelper');

// @constants
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS
} = require('constants/index');

const {
    BROKER_SIMPLE_SEARCH_REQUEST,
    BROKER_SIMPLE_SEARCH_SUCCESS,
    BROKER_SIMPLE_SEARCH_FAILURE,
    BROKER_SIMPLE_SEARCH_CLEAR,
    BROKER_SIMPLE_SEARCH_TOGGLE_MODAL_FILTER,
    BROKER_SIMPLE_SEARCH_SELECT_FILTER,
    BROKER_SIMPLE_SEARCH_APPLY_FILTER,
    BROKER_SIMPLE_SEARCH_CLEAR_FILTERS,
    BROKER_SIMPLE_SEARCH_CHANGE_SEARCH_INPUT,
    BROKER_SIMPLE_SPECIFIC_SEARCH_SUCCESS
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    search: '',
    isFilterModalOpen: false,
    isFetching: false,
    isFetchingPage: false,
    fetched: false,
    results: {},
    filterResults: {},
    filter: null,
    isFilterApplied: false,
    error: null
});

const resultsAdapter = (arr, type) => {
    switch (type) {
        case TYPE_STOCKS:
            return arr.map(item => {
                return Object.assign({}, item, {
                    miccode: trim(
                        get(item, 'miccode')
                    ),
                    instrumentId: trim(
                        get(item, 'instrumentId')
                    ),
                    instrument: sanitizeHelper.sanitizeData(
                        trim(get(item, 'instrument'))
                    )
                });
            });
        case TYPE_WARRANTS:
        case TYPE_ETFS:
            return arr.map(item => {
                return Object.assign({}, item, {
                    instrumentId: trim(
                        get(item, 'instrumentId')
                    ),
                    instrument: sanitizeHelper.sanitizeData(
                        trim(get(item, 'instrument'))
                    )
                });
            });
        case TYPE_PLANS:
        case TYPE_FUNDS:
            return arr.map(item => {
                return Object.assign({}, item, {
                    Name: sanitizeHelper.sanitizeData(
                        trim(get(item, 'Name'))
                    )
                });
            });
        default:
            return arr;
    }
};

const sanitizeData = (data) => {
    return {
        stocks: data.stocks ? resultsAdapter(data.stocks, TYPE_STOCKS) : [],
        pensions: data.pensions ? resultsAdapter(data.pensions, TYPE_PLANS) : [],
        warrants: data.warrants ? resultsAdapter(data.warrants, TYPE_WARRANTS) : [],
        funds: data.funds ? resultsAdapter(data.funds, TYPE_FUNDS) : [],
        etfs: data.etfs ? resultsAdapter(data.etfs, TYPE_ETFS) : [],
    };
}

const filterReducer = (results, filter) => {
    switch (filter) {
        case TYPE_STOCKS:
            return { stocks: results.stocks };
        case TYPE_PLANS:
            return { pensions: results.pensions };
        case TYPE_WARRANTS:
            return { warrants: results.warrants };
        case TYPE_FUNDS:
            return { funds: results.funds };
        case TYPE_ETFS:
            return { etfs: results.etfs };
        default:
            return results;
    }
}

const simpleSearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BROKER_SIMPLE_SEARCH_CHANGE_SEARCH_INPUT:
            return state.set('search', action.payload.search);
        case BROKER_SIMPLE_SEARCH_REQUEST:
        const isFetchingPage = action.payload.pageNum > 1;
            return state.merge({
                fetched: false,
                isFetching: !isFetchingPage,
                isFetchingPage
            });
        case BROKER_SIMPLE_SEARCH_SUCCESS:
            const results = sanitizeData(action.payload.data);
            const isFilterToApply = !!state.get('filter');
            return state.merge({
                fetched: true,
                isFetching: false,
                results: results,
                isFilterApplied: isFilterToApply,
                filterResults: filterReducer(results, state.get('filter'))
            });
        case BROKER_SIMPLE_SPECIFIC_SEARCH_SUCCESS:
            const {
                data,
                productType,
                activeMarket,
                pageNum
            } = action.payload;

            const specificResults = sanitizeData(data);
            const productTypeFormatted = productType.trim().toLowerCase();
            const filterResults = filterReducer(specificResults, productTypeFormatted);
            const marketValue = activeMarket && activeMarket.value && activeMarket.value.trim();
            if (!!marketValue) {
                filterResults[productTypeFormatted] = filterResults[productTypeFormatted].filter((item)=>{
                    return item.miccode === marketValue;
                });
            }
            if (pageNum > 1) {
                let productArray = state.getIn(['filterResults', productTypeFormatted]);
                const filterProductResults = filterResults[productTypeFormatted];
                filterProductResults.forEach((obj)=>{
                    productArray = productArray.push(Immutable.Map(obj));
                });
                return state.merge({
                    fetched: true,
                    isFetching: false,
                    isFilterApplied: true,
                    isFetchingPage: false
                }).mergeIn(['filterResults'], {
                    [productType]: productArray
                });
            } else {
                return state.merge({
                    fetched: true,
                    isFetching: false,
                    isFilterApplied: true,
                    filterResults
                });
            }
        case BROKER_SIMPLE_SEARCH_FAILURE:
            const failResults = sanitizeData(action.payload.data);
            return state.merge({
                fetched: true,
                isFetching: false,
                isFetchingPage: false,
                results: failResults,
                error: action.payload.data.error
            });
        case BROKER_SIMPLE_SEARCH_CLEAR:
            return INITIAL_STATE;
        case BROKER_SIMPLE_SEARCH_TOGGLE_MODAL_FILTER:
            return state.set('isFilterModalOpen', !state.get('isFilterModalOpen'));
        case BROKER_SIMPLE_SEARCH_SELECT_FILTER:
            return state.set('filter', action.payload.filter);
        case BROKER_SIMPLE_SEARCH_APPLY_FILTER:
            const isAnyFilterToApply = !!state.get('filter');
            return state.merge({
                isFilterApplied: isAnyFilterToApply,
                isFilterModalOpen: false,
                filterResults: filterReducer(state.get('results').toJS(), state.get('filter'))
            });
        case BROKER_SIMPLE_SEARCH_CLEAR_FILTERS:
            return state.merge({
                isFilterApplied: false,
                filter: null,
                filterResults: filterReducer(state.get('results').toJS(), null)
            });
        default:
            return state;
    }
}

module.exports = simpleSearchReducer;
