
// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

const initialState = Immutable.fromJS({
    hasBeenFetch: false,
    error: '',
    isFetching: false,
    passwordError: {}
});

function loginAsClient(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOG_AS_CLIENT_SUCCESS:
            return state.merge({
                 hasBeenFetch: true,
                 isFetching: false,
                 info: action.info
            });
        case actionTypes.LOG_AS_CLIENT_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
                hasBeenFetch : false,
                passwordError: {}
        });
        case actionTypes.LOG_AS_CLIENT_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.LOG_AS_CLIENT_PASSWORD_ERROR:
            return state.merge({
                passwordError: action.payload.error,
                isFetching: false,
            });
        case actionTypes.LOG_AS_CLIENT_PASSWORD_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = loginAsClient;
