import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    zoneSetFailed as setFailed,
    zoneSetSucceeded as setSucceeded,
} from 'actions/cardControl';
import { error as errorAction } from 'actions/notifications';
const { success } = require('actions/notifications');
const { formatText } = require('core/i18n').i18n;
import { CARD_ZONE_SET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { getCard } from 'selectors/cards';
import { APIPost } from 'utilities/APIRequestHelper';


function* setZone({ payload: { active, cardId, pan, zoneId, lastDigitsPan } }) {
  const output = yield select(getCard, { cardId });
  if(output) {
    const { locations } = yield select(getCard, { cardId });
    yield put(setSucceeded({ active, cardId, zoneId }));
    try {
        const requestParams = {
            dispatch: null,
            endpoint: 'cardSetZone',
            url: apiURLBuilder.getURL('cardSetZone', { pan, zone: zoneId }),
            query: { enabled: active, lastDigitsPan },
        };
        yield call(APIPost, null, apiURLBuilder.getURL('cardSetZone', { pan, zone: zoneId }), requestParams);
        yield put(success({message: formatText("cardsConfig-showSuccessNotificationZones")}))
    } catch ({ message }) {
        yield put(setFailed({ cardId, locations, message }));
        yield put(errorAction({ message }, { voice: message }));
    }
  }
}


function* zoneSetSaga() {
  yield [
      takeLatest(CARD_ZONE_SET_REQUESTED, setZone),
  ];
}


export default [
  zoneSetSaga,
];
export { zoneSetSaga, setZone };
