// @vendors
const Immutable = require('immutable');

// @components
const fillFiltersSubReducer = require('./brokerAdvancedSearch/brokerAdvancedSearchFillFilters');
const resultsAdapterSubReducer = require('./brokerAdvancedSearch/brokerAdvancedSearchResultsAdapter');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    TYPE_STOCKS,
    TYPE_PLANS,
    TYPE_WARRANTS,
    TYPE_FUNDS,
    TYPE_ETFS,
    BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
    BROKER_ADVANCED_SEARCH_VIEW_ADVANCED_SEARCH_TAB
} = require('constants/index');

const productNames = [TYPE_STOCKS, TYPE_PLANS, TYPE_WARRANTS, TYPE_FUNDS, TYPE_ETFS];

const setInitialSelectedValues = () => {
    return {
        stocks: {
            term: '',
            selectedProduct: '',
            market: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE
        },
        warrants: {
            product: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            underlying: {
                value: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
                ids: {}
            },
            type: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            issuer: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            expiration: ''
        },
        etfs: {
            term: '',
            issuer: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            category: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            /*currency: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,*/
            leverage: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            complexity: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            market: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE
        },
        funds: {
            term: '',
            promoter: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            category: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            currency: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            /*geoZone: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,*/
            stars: [1, 2, 3, 4, 5],
            minProfitabilityRange: 0,
            maxProfitabilityRange: 20,
            risk: {
                low: true,
                medium: true,
                high: true
            },
            traspassableFund: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE
        },
        plans: {
            term: '',
            selectedProduct: '',
            promoter: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            category: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            currency: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,
            /*geoZone: BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE,*/
            stars: [1, 2, 3, 4, 5],
            minProfitabilityRange: 0,
            maxProfitabilityRange: 20,
            risk: {
                low: true,
                medium: true,
                high: true
            }
        }
    };
};

const setInitialState = () => {
    return Immutable.fromJS({
        activeTab: BROKER_ADVANCED_SEARCH_VIEW_ADVANCED_SEARCH_TAB,
        isFetchingFilters: false,
        isFetchingResults: false,
        activeProduct: '',
        allProductsFiltersFetched: false,
        fetchingDone: false,
        productNames: productNames,
        filters: {
            stocks: {
                fetched: false,
                markets: []
            },
            warrants: {
                fetched: false,
                products: [],
                underlyings: [],
                types: [],
                issuers: []
            },
            etfs: {
                fetched: false,
                leverages: [],
                categories: [],
                complexities: [],
                issuers: [],
                markets: []
            },
            funds: {
                fetched: false,
                promoters: [],
                categories: [],
                customCategories: [],
                currencies: [],
                /*geographicZones: [],  uncomment for geozone*/
                traspassableFund: [
                    'yes', 'no', BROKER_ADVANCED_SEARCH_DEFAULT_ALL_VALUE
                ],
                KiidDocument: ''
            },
            plans: {
                fetched: false,
                promoters: [],
                categories: [],
                customCategories: [],
                currencies: [],
                minPurchase: {} /** uncomment for geozone,
                geographicZones: []*/
            }
        },
        selectedValues: setInitialSelectedValues(),
        resultsList: [],
        showEmpty: false,
        checkedList: [],
        watchlistId: null
    });
};

const updateFilterFields = (payload, state) => {
    const productType = state.getIn(['activeProduct']);

    switch (payload.field) {
        case 'activeProduct':
            return state.merge({
                selectedValues: setInitialSelectedValues(),
                activeProduct: payload.value,
                resultsList: [],
                showEmpty: false
            });

        case 'stars': {
            const val = payload.value;
            const oldStars = state.getIn(['selectedValues', productType, 'stars']);
            const newStars = oldStars.set(val.index, val.checked ? val.stars : 0);

            if (Immutable.is(newStars, Immutable.fromJS([0, 0, 0, 0, 0]))) {
                return state;
            }

            return state.mergeIn(['selectedValues', productType, 'stars'], newStars);
        }

        case 'profitability':
            return state
                .mergeIn(['selectedValues', productType, 'minProfitabilityRange'], payload.value[0])
                .mergeIn(
                    ['selectedValues', productType, 'maxProfitabilityRange'],
                    payload.value[1]
                );

        case 'risk': {
            const riskTypeValue = !state.getIn([
                'selectedValues',
                productType,
                'risk',
                payload.value
            ]);
            return state.mergeIn(
                ['selectedValues', productType, 'risk', payload.value],
                riskTypeValue
            );
        }

        default:
            return state.mergeIn(['selectedValues', productType, payload.field], payload.value);
    }
};

function brokerAdvancedSearch(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_ADVANCED_SEARCH_CHANGE_TAB:
            return state.set('activeTab', action.payload.tab);

        case actionTypes.BROKER_ADVANCED_SEARCH_FIELD_CHANGE:
            return updateFilterFields(action.payload, state);

        case actionTypes.BROKER_ADVANCED_SEARCH_SAVE_CHECKED_LIST: {
            const existsInList = state.get('checkedList').includes(action.checkedItem);

            if (existsInList) {
                const itemIndex = state
                    .get('checkedList')
                    .findIndex(item => item === action.checkedItem);
                const newlist = state.getIn(['checkedList']).splice(itemIndex, 1);
                return state.set('checkedList', newlist);
            } else {
                const newlist = state.getIn(['checkedList']).push(action.checkedItem);
                return state.set('checkedList', newlist);
            }
        }

        case actionTypes.BROKER_ADVANCED_SEARCH_CLEAR_RESULTS:
            return setInitialState();

        case actionTypes.BROKER_ADVANCED_SEARCH_FIELDS_ARE_FETCHING:
            return state.mergeIn(['isFetchingFilters'], true);

        case actionTypes.BROKER_ADVANCED_SEARCH_GET_FIELDS_SUCCESS: {
            const newState = state
                .mergeIn(['isFetchingFilters'], false)
                .mergeIn(['filters', action.payload.product], fillFiltersSubReducer(action.payload))
                .mergeIn(['filters', action.payload.product, 'fetched'], true);

            const stocksFetched = newState.getIn(['filters', 'stocks', 'fetched']);
            const warrantsFetched = newState.getIn(['filters', 'warrants', 'fetched']);
            const fundsFetched = newState.getIn(['filters', 'funds', 'fetched']);
            const etfsFetched = newState.getIn(['filters', 'etfs', 'fetched']);
            const plansFetched = newState.getIn(['filters', 'plans', 'fetched']);
            const allProductsFiltersFetched =
                stocksFetched && warrantsFetched && fundsFetched && etfsFetched && plansFetched;

            return newState.mergeIn(['allProductsFiltersFetched'], allProductsFiltersFetched);
        }

        case actionTypes.BROKER_ADVANCED_SEARCH_FETCTHING_DONE:
            return state.mergeIn(['fetchingDone'], true);

        case actionTypes.BROKER_ADVANCED_SEARCH_GET_FIELDS_FAILURE:
            //> TODO handle error console.log(action, 'failure');
            return state.mergeIn(['isFetchingFilters'], false);

        case actionTypes.BROKER_GET_SEARCH_RESULTS_LIST_FETCHING:
            return state.mergeIn(['isFetchingResults'], true);

        case actionTypes.BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS: {
            const newState = resultsAdapterSubReducer(state, action.payload);
            return newState.set('checkedList', Immutable.fromJS([]));
        }

        case actionTypes.BROKER_GET_SEARCH_RESULTS_LIST_FAILURE:
            if (action.payload.data.body.message === 'No se encontraron resultados') {
                // this is because of the way empty results are 404 :/
                return state
                    .mergeIn(['isFetchingResults'], false)
                    .mergeIn(['showEmpty'], true)
                    .setIn(['resultsList'], Immutable.fromJS([]));
            } else {
                return state.mergeIn(['isFetchingResults'], false);
            }
        case actionTypes.BROKER_ADVANCED_SEARCH_RESET:
            return setInitialState();
        default:
            return state;
    }
}
module.exports = brokerAdvancedSearch;
