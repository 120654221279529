module.exports = {
    hideTitle: true,
    order: 1,
    footer_text: '',
    position: 'first',
    on_next_click: 'eAccount_verifyAccountLimit',
    components: [
        {
            id_component: 'step1-contract-savings',
            id: 'text-info',
            cms_key: 'eaccount'
        },
        ...(
            __CONTACT_CENTER__ ?
            [{
                id_component: 'legal-information'
            }] : []
        )
    ]
}
