const immutable = require('immutable');
//@constants
const {
    MORTGAGE_REQUEST_USER_COLLECTIVE_PROGRESS,
    MORTGAGE_REQUEST_USER_COLLECTIVE_SUCCESS,
    MORTGAGE_REQUEST_USER_COLLECTIVE_ERROR
} = require('constants/actionTypes')

const initialState = immutable.fromJS({
    isFetching: false,
    inCollective: false,
    collectiveInformation: {
        collectiveName: ''
    },
    error: '',
});

function mortgageRequestCollectiveTag(state = initialState, action) {
    switch (action.type){

        case MORTGAGE_REQUEST_USER_COLLECTIVE_PROGRESS:
            return state.merge({
                isFetching: true,
                error: ''
            });
        case MORTGAGE_REQUEST_USER_COLLECTIVE_SUCCESS:
            return state.merge({
                isFetching: false,
                inCollective: action.data.inCollective,
                collectiveInformation: action.data.collectiveInformation
            });
        case MORTGAGE_REQUEST_USER_COLLECTIVE_ERROR:
            return state.merge({
                isFetching: false,
                error: action.error
            });
        default:
            return state;
    }

}

module.exports = mortgageRequestCollectiveTag;
