// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = () =>
    Immutable.fromJS({
        openTraderLink: '',
        fetched: false,
        error: ''
    });

const brokerOpenTraderReducer = (state = initialState(), action) => {
    switch (action.type) {
        case actionTypes.BROKER_OPEN_TRADER_FETCH_LINK_SUCCESS:
            return state.merge({
                openTraderLink: action.payload.openTraderLink,
                fetched: true
            });
        case actionTypes.BROKER_OPEN_TRADER_FETCH_LINK_FAILURE:
            return state.merge({
                error: action.payload.error,
                fetched: false
            });
        default:
            return state;
    }
};

module.exports = brokerOpenTraderReducer;
