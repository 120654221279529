//@vendor
const Immutable = require('immutable');

//@constant
const actionTypes = require('constants/actionTypes');
const { DATE_FILTER_NONE } = require('constants/index');

// @helpers
const { getBody } = require('utilities/cmsDataHelpers');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @utilities
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    amount: '',
    selectedRadio: '',
    promotionsIsFetching: false,
    promotionsError: false,
    promotionsSuccess: false,
    promoCodeIsFetching: false,
    promoCodeError: false,
    promoCodeSuccess: false,
    promoCodeById: {},
    errorById: {},
    radioSwitchTabName: '',
    returnGiftCardFailure: false,
    returnGiftCardFetching: false,
    returnGiftCardSuccess: false,
    requestId: '',
    numerodecontrato: '',
    ShowLoader: false,
    promotionAd: {},
    promotionsList: [],
    couponCodeIsFetching: false,
    amazonCouponCodeSuccess: false,
    amazonCouponCodeError: '',
    isFetchingNextPage: false,
    byOrder: [],
    byId: {},
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        }
    }
});

function resetAmazonCouponCode(state) {
    return state.merge({
        promoCodeIsFetching: false,
        promoCodeError: false,
        promoCodeSuccess: false,
        promoCodeById: {},
        ShowLoader: false
    })
}

function resetAmazonGiftCardReturnState(state) {
    return state.merge({
        amount: '',
        requestId: '',
        returnGiftCardFailure: false,
        returnGiftCardFetching: false,
        returnGiftCardSuccess: false
    })
}

function returnAmazonGiftCardLoader(state) {
    return state.merge({
        ShowLoader: true,
        returnGiftCardFetching: true,
        returnGiftCardFailure: false,
        returnGiftCardSuccess: false
    })
}

function returnAmazonGiftCardSuccess(state) {
    return state.merge({
        returnGiftCardFailure: false,
        returnGiftCardSuccess: true,
        ShowLoader: false,
        returnGiftCardFetching: false
    })
}

function getGiftVoucherItem(infoItem) {
    return infoItem || '';
}

function processAmazonCouponCode(state, couponCodeList, requestMore) {
    const byId = requestMore ? state.get('byId').toJS() : {};
    const byOrder = requestMore ? state.get('byOrder').toJS() : [];
    let nextPageLink;
    const remainingBalance = couponCodeList.remainingBalance;
    const userGiftDetails = couponCodeList.userGiftCardDetails;
    if(couponCodeList._links){
        nextPageLink = couponCodeList._links.next.href;
    }

    if (userGiftDetails && userGiftDetails.length) {
        userGiftDetails.forEach(giftVoucher => {
            const id = __CONTACT_CENTER__ ? hash([giftVoucher.giftCardCode]) : hash([giftVoucher.requestId]);
            byId[id] = {
                id,
                'cardStatus': trim(getGiftVoucherItem(get(giftVoucher, 'cardStatus'))),
                'creationDate': trim(getGiftVoucherItem(get(giftVoucher, 'creationDate'))),
                'date': trim(getGiftVoucherItem(get(giftVoucher, 'creationDate'))),
                'eligibleToCancel': get(giftVoucher, 'eligibleToCancel') || false,
                'expiryDate': trim(getGiftVoucherItem(get(giftVoucher, 'expiryDate'))),
                'gift': trim(getGiftVoucherItem(get(giftVoucher, 'giftCardAmount'))),
                'numerodecontrato': trim(getGiftVoucherItem(get(giftVoucher, 'numerodecontrato'))),
                'promotionalPercentage': trim(getGiftVoucherItem(get(giftVoucher, 'promotionalPercentage'))),
                'requestId': trim(getGiftVoucherItem(get(giftVoucher, 'requestId'))),
                'showCodeLink': get(giftVoucher, 'showCodeLink') || false,
            };
            byOrder.push(id);
        });

    }
    return state.mergeDeep({
        couponCodeIsFetching: false,
        amazonCouponCodeSuccess: true,
        isFetchingNextPage:false,
        remainingBalance,
        nextPage: nextPageLink
    })
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder));
}

function setAmazonPromoCode(state, promoCode, id) {
    const promo = {};
    let promoCodeSuccess = false;
    if (promoCode.length > 0) {
        promo[id] = {
            id,
            promocode: promoCode
        }
        promoCodeSuccess = true;
    }
    return state.mergeDeep({
        promoCodeIsFetching: false,
        promoCodeError: false,
        promoCodeSuccess: promoCodeSuccess,
        promoCodeById: promo
    })
}

function setAmazonPromoCodeError(state, error, id) {
    const errorMsg = {};
    let promoCodeError = false;
    if (error['description'].length > 0) {
        errorMsg[id] = {
            id,
            error
        }
        promoCodeError = true;
    }
    return state.mergeDeep({
        errorById: errorMsg,
        promoCodeError: promoCodeError,
        promoCodeIsFetching: false,
        promoCodeSuccess: false
    })
}

function setAmazonPromoCodeLoader(state, id) {
    const loader = {};
    loader[id] = {
        id,
        promoCodeFetchingLoader: true
    }
    return state.merge({
        promoCodeIsFetching: true,
        ShowLoader: loader
    });
}

function processPromotions(state,promoAd){
    let ad = {};
    let promotionsSuccess = false;
    if(promoAd.length > 0){
        const { body,fieldLink,fieldImageLarge } = promoAd[0];
        const value = body && body[0] && body[0].value;
        const addField = fieldLink && fieldLink[0] && fieldLink[0].uri;
        const fieldText = fieldLink && fieldLink[0] && fieldLink[0].title;
        const url = fieldImageLarge && fieldImageLarge[0] && fieldImageLarge[0].url;
        ad = {
            body: getBody(value),
            fieldLink: addField,
            fieldImageLarge: url,
            fieldText
        }
        if(body && fieldLink && fieldImageLarge) {
            promotionsSuccess = true;
        }
    }
    return state.merge({
        promotionAd: ad,
        promotionsIsFetching: false,
        promotionsError: false,
        promotionsSuccess: promotionsSuccess
    });
}

function amazonGiftCards(state= initialState, action){
    switch (action.type){
        case actionTypes.AMAZON_GET_PROMOTION_CARD_IS_FETCHING :
            return state.merge({
                promotionsIsFetching: true
            });
        case actionTypes.AMAZON_GET_PROMOTION_CARD_SUCCESS :
            return processPromotions(state,action.payload.response);
        case actionTypes.AMAZON_GET_PROMOTION_CARD_ERROR :
            return state.merge({
                promotionsIsFetching: false,
                promotionsError: true,
                error: action.payload.error,
            });
        case actionTypes.AMAZON_GET_COUPON_CODE_SUCCESS:
            return processAmazonCouponCode(state, action.payload.response, action.payload.requestMore)
        case actionTypes.AMAZON_GET_COUPON_CODE_REQUEST:
            return state.merge({
                couponCodeIsFetching: true,
                amazonCouponCodeError: ''
            });
        case actionTypes.AMAZON_GET_COUPON_CODE_ERROR:
            return state.merge({
                amazonCouponCodeError: get(action, 'payload.error', ''),
                couponCodeIsFetching: false
            });
        case actionTypes.AMAZON_GET_PROMO_CODE_IS_FETCHING:
            return setAmazonPromoCodeLoader(state, action.payload.id)
        case actionTypes.AMAZON_GET_PROMO_CODE_ERROR:
            return setAmazonPromoCodeError(state, action.payload.error, action.payload.id);
        case actionTypes.AMAZON_GET_PROMO_CODE_SUCCESS:
            return setAmazonPromoCode(state, action.payload.response, action.payload.id);
        case actionTypes.FETCH_NEXT_AMAZON_COUPON_CODE_INFO_PAGE_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    isFetchingNextPage: true,
                    couponCodeIsFetching: true,
                    amazonCouponCodeError: ''
                }
            });
        case actionTypes.AMAZON_GIFT_CARD_SET_FILTERS:
            return state.set('filters', Immutable.fromJS(action.payload.filters));
        case actionTypes.AMAZON_GIFT_CARD_CLEAR_FILTERS:
            return state.set('filters', initialState.get('filters'));
        case actionTypes.AMAZON_GIFT_CARD_RESET_FILTER:
            const { filterKey } = action.payload;
            return state.setIn(['filters', filterKey], initialState.getIn(['filters', filterKey]));
        case actionTypes.RESET_AMAZON_COUPON_CODE:
            return resetAmazonCouponCode(state);
        case actionTypes.AMAZON_SET_CONSULT_TYPE:
            return state.merge({
                selectedRadio: action.payload.value
            });
        case actionTypes.AMAZON_GIFT_CARD_RETURN_REQUEST:
            return returnAmazonGiftCardLoader(state)
        case actionTypes.AMAZON_GIFT_CARD_RETURN_FAILURE:
            return state.merge({
                returnGiftCardFailure: true,
                ShowLoader: false,
                returnGiftCardFetching: false
            });
        case actionTypes.AMAZON_GIFT_CARD_RETURN_STATE_RESET:
            return resetAmazonGiftCardReturnState(state);
        case actionTypes.AMAZON_GIFT_CARD_RETURN_SUCCESS:
            return returnAmazonGiftCardSuccess(state);
        case actionTypes.SEND_AMAZON_GIFT_CARD_RETURN_DETAILS:
            return state.merge({
                amount: action.payload.giftCardDetails.giftAmount,
                requestId: action.payload.giftCardDetails.giftVoucherRequestId,
                numerodecontrato: action.payload.giftCardDetails.numerodecontrato
            });
        default :
            return state;
    }

}

module.exports = amazonGiftCards;
