// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isFetching: false,
    isFetched: false,
    fetchError: false,
    fetchErrorMessage: '',
    secId: '',
    riskLevel: '',
    investmentStrategy: '',
    commissionList: {},
});

const copyDataFromResponse = response => {
    if (!response) {
        return {};
    }

    const secDetailsData = (!response.secDetailsResponse || response.secDetailsResponse.length === 0) ? {} : response.secDetailsResponse[0];
    const screenerData = (!response.screenerResponse || !response.screenerResponse.rows || response.screenerResponse.rows.length === 0) ? {} : response.screenerResponse.rows[0];

    return {
        secId: get(secDetailsData, 'Id', ''),
        riskLevel: get(secDetailsData, 'CollectedSRRI.Rank', ''),
        investmentStrategy: get(secDetailsData, 'InvestmentStrategy', ''),
        commissionList: {
            performanceFee: get(secDetailsData, 'PerformanceFee', ''),
            totalExpenseRatio: get(secDetailsData, 'TotalExpenseRatio', ''),
            ongoingCharge: get(secDetailsData, 'OngoingCharge', ''),
            managementFee: get(secDetailsData, 'ManagementFee', ''),

            customBuyFee: get(screenerData, 'CustomBuyFee', ''),
            customSellFee: get(screenerData, 'CustomSellFee', ''),
            customMinimumPurchaseAmount: get(screenerData, 'CustomMinimumPurchaseAmount', ''),
            customMinimumPurchaseAmountUnit: get(screenerData, 'CustomMinimumPurchaseAmountUnit', ''),
            customMinimumAdditionalPurchaseAmount: get(screenerData, 'CustomMinimumAdditionalPurchaseAmount', ''),
            customMinimumAdditionalPurchaseAmountUnit: get(screenerData, 'CustomMinimumAdditionalPurchaseAmountUnit', ''),
            distributionFee: get(screenerData, 'DistributionFee', ''),
            custodianFee: get(screenerData, 'CustodianFee', ''),
        }
    };
};

const pensionPlanLegalConfirmationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_START:
            return state.merge({
                isFetching: true,
                isFetched: false,
                fetchError: false,
                fetchErrorMessage: '',
                secId: action.payload.secId,
                riskLevel: '',
                investmentStrategy: '',
                commissionList: {},
            });

        case actionTypes.PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_SUCCESS:
            return state.merge({
                isFetching: false,
                isFetched: true,
                fetchError: false,
                fetchErrorMessage: '',
            })
            .merge(copyDataFromResponse(action.payload));

        case actionTypes.PENSION_PLAN_LEGAL_CONFIRMATION_MORNINGSTAR_INFORMATION_REQUEST_ERROR:
            return state.merge({
                isFetching: false,
                isFetched: true,
                fetchError: true,
                fetchErrorMessage: action.payload.error,
                secId: action.payload.secId,
                riskLevel: '',
                investmentStrategy: '',
                commissionList: {},
            });

        case actionTypes.PENSION_PLAN_LEGAL_CONFIRMATION_RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

module.exports = pensionPlanLegalConfirmationReducer;
