//@core
const {
    getPublicSiteUrl,
    getURL,
} = require('core/apiURLBuilder')
//@constant
const {
    BROKER_FUND_LEGAL_DOCUMENT_DFI,
    BROKER_FUND_LEGAL_DOCUMENT_SEMIANUAL_REPORT,
    BROKER_PLAN_LEGAL_DOCUMENT_DDFP,
    BROKER_PLAN_LEGAL_DOCUMENT_SPECIFICATIONS,
    BROKER_PLAN_LEGAL_DOCUMENT_BULLETIN,
    BROKER_PLAN_LEGAL_DOCUMENT_SERVICE_PROVISION,
    BROKER_PRODUCT_TYPE_FUNDS,
    BROKER_PRODUCT_TYPE_ALTERNATIVE_FUNDS,
    BROKER_PRODUCT_TYPE_PLANS,
    BROKER_PRODUCT_TYPE_OPA,
    BROKER_PRODUCT_TYPE_CAPITAL_INCREASE,
    BROKER_PRODUCT_TYPE_OPV,
    BROKER_PRODUCT_TYPE_EXCHANGE,
    BROKER_PRODUCT_TYPE_LEGAL_WARRANTS,
    BROKER_WARRANTS_LEGAL_DOCUMENT_DFI,
    BROKER_PRODUCT_TYPE_ETFS_ES,
    BROKER_LEGAL_DOCUMENTATION_DFI,
    BROKER_LEGAL_DOCUMENTATION_ECONOMIC_REPORT,
    BROKER_LEGAL_DOCUMENTATION_MARKETING_REPORT,
    BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST,
    CONTRATO_PRESTACION_SERVICIOS_PERSONAS_FISICAS,
    CONTENT_TYPE_PDF
} = require('constants/index');

const getFundsDocuments = (fundIsin, immGlobalVariables, brokerFundLegalDocument, originCountry, isBuildYourPortfolio) => {
    const urlDFI = getURL('morningstarFundLegalDocumentUrl', {
        isin: fundIsin,
        documentType: BROKER_FUND_LEGAL_DOCUMENT_DFI,
        originCountry
    })
    const urlECONOMIC = getURL('morningstarFundLegalDocumentUrl', {
        isin: fundIsin,
        documentType: BROKER_FUND_LEGAL_DOCUMENT_SEMIANUAL_REPORT,
        originCountry
    });
    const memories = immGlobalVariables.getIn(['variables', 'parameters', 'memories', 'memories']);
    let publicSiteUrl = getPublicSiteUrl(false);
    if (!!publicSiteUrl && publicSiteUrl.slice(publicSiteUrl.length - 3, -2) === "/") {
        publicSiteUrl = publicSiteUrl.slice(0, -3);
    }
    const urlMARKETING = (memories
                            ? `${publicSiteUrl}${memories.trim()}${fundIsin}.pdf?originCountry=${originCountry}`
                            : '')
    const immFundTransactionCostDocument = brokerFundLegalDocument.getIn(['fundTransactionCostDocument', fundIsin])
    const dataBlock = immFundTransactionCostDocument ? immFundTransactionCostDocument.get('base64Data') : ''
    const dataID = immFundTransactionCostDocument ? immFundTransactionCostDocument.get('objectId'): ''
    const newCommisionAndCostBase64 = brokerFundLegalDocument.get('comisionAndCostDocument');
    const newCommisionAndCostID = `${fundIsin}.pdf`;
    const base64String = `data:${CONTENT_TYPE_PDF};base64,${isBuildYourPortfolio ? newCommisionAndCostBase64 : dataBlock}`

    return {
        [BROKER_LEGAL_DOCUMENTATION_DFI]: {
            url: urlDFI
        },
        [BROKER_LEGAL_DOCUMENTATION_ECONOMIC_REPORT]: {
            url: urlECONOMIC
        },
        [BROKER_LEGAL_DOCUMENTATION_MARKETING_REPORT]: {
            url: urlMARKETING
        },
        [BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST]: {
            pdf: {
                base64String,
                dataID: isBuildYourPortfolio ? newCommisionAndCostID : dataID,
            }
        },
    }
}

const getPlansDocuments = (planDgsCode, originCountry) => {
    const publicSiteUrl = getPublicSiteUrl().split("/en")[0];
    const urlFundamentalPlanData = getURL('morningstarPlanLegalDocumentUrl', {dgscode: planDgsCode, documentType: BROKER_PLAN_LEGAL_DOCUMENT_DDFP, originCountry: originCountry })
    const urlSpecificationsDocumentUrl = getURL('morningstarPlanLegalDocumentUrl', {dgscode: planDgsCode, documentType: 'Especificaciones', originCountry: originCountry })
    const urlBulletin = `${publicSiteUrl}/assets/static/pdf/planes/boletines/${planDgsCode}.pdf`;
    const urlContractProvisionServicesNaturalPersons = CONTRATO_PRESTACION_SERVICIOS_PERSONAS_FISICAS

    return {
        [BROKER_PLAN_LEGAL_DOCUMENT_DDFP]: {
            url: urlFundamentalPlanData
        },
        [BROKER_PLAN_LEGAL_DOCUMENT_SPECIFICATIONS]: {
            url: urlSpecificationsDocumentUrl
        },
        [BROKER_PLAN_LEGAL_DOCUMENT_BULLETIN]: {
            url: urlBulletin
        },
        [BROKER_PLAN_LEGAL_DOCUMENT_SERVICE_PROVISION]: {
            url: urlContractProvisionServicesNaturalPersons
        }
    }
}

const getWarrantsDocuments = (
  isin,
  immBrokerWarrantsBuy,
  immBrokerWarrantsSell,
  isSell,
  enableTransactionCost = true,
  market,
  isMedysifProvider
) => {
  const getDocumentUrl = isMedysifProvider
    ? getURL('getKidsDocumentForWarrant', { market, isin, docType: 'KID' })
    : getURL('kidDocumentPdf', { warrantIsin: isin });
  if (isSell) {
    const dataBlock = immBrokerWarrantsSell.get('sellWarrantsTransactionCostBase64Data');
    const dataID = immBrokerWarrantsSell.get('sellWarrantsTransactionCostObjectId');
    const base64String = `data:${CONTENT_TYPE_PDF};base64,${dataBlock}`;
    return {
      [BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST]: {
        read: false,
        pdf: {
          base64String,
          dataID,
        },
      },
    };
  } else {
    const dfiExists = immBrokerWarrantsBuy.get('dfiExists');
    const dfiDataBlock = immBrokerWarrantsBuy.get('warrantsKIDBase64Data');
    if (!immBrokerWarrantsBuy.get('WarrantsTransactionCostObjectId')) {
      const documents = {
        [BROKER_WARRANTS_LEGAL_DOCUMENT_DFI]: isMedysifProvider
            ? {
                read: false,
                base64Data: {
                    base64String: dfiDataBlock,
                },
            }
            : {
                read: false,
                url: dfiExists ? getDocumentUrl : null,
            },
      };
      if (enableTransactionCost) {
        documents[BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST] = {
          read: false,
          url: null,
        };
      }
      return documents;
    } else {
        const dataBlock = immBrokerWarrantsBuy.get('WarrantsTransactionCostBase64Data');
        const dataID = immBrokerWarrantsBuy.get('WarrantsTransactionCostObjectId');
        const base64String = `data:${CONTENT_TYPE_PDF};base64,${dataBlock}`;
        const documents = {
            [BROKER_WARRANTS_LEGAL_DOCUMENT_DFI]: isMedysifProvider
                ? {
                    read: false,
                    base64Data: {
                        base64String: dfiDataBlock,
                    },
                }
                : {
                    read: false,
                    url: dfiExists ? getDocumentUrl : null,
                }
        }

        if (enableTransactionCost) {
            documents[BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST] = {
                read: false,
                pdf: {
                    base64String,
                    dataID,
                },
            };
        }
        return documents;
    }
  }
};

const getOwnProduct = (productType, isin, planDgsCode) => {
    let identifier
    switch (productType) {
        case BROKER_PRODUCT_TYPE_ALTERNATIVE_FUNDS:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_FUNDS:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_PLANS:
            identifier = planDgsCode
            break;
        case BROKER_PRODUCT_TYPE_OPA:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_CAPITAL_INCREASE:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_OPV:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_EXCHANGE:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_LEGAL_WARRANTS:
            identifier = isin
            break;
        case BROKER_PRODUCT_TYPE_ETFS_ES.toUpperCase():
            identifier = isin
            break;
        default:
            break;
    }

    return identifier
}

module.exports = {
    getFundsDocuments,
    getPlansDocuments,
    getWarrantsDocuments,
    getOwnProduct,
}
