const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.Map({
    brokerErrorReceived: '',
    showTealium: true
});

function errors(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_ERROR:
            return state.merge({
                brokerErrorReceived: action.payload.error,
                showTealium: action.payload.showTealium
            });
        default:
            return state;
    }
}

module.exports = errors;
