// @ vendor
const Immutable = require('immutable');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/actionTypes');
const { CHORE_MAX_AMOUNT } = require('constants/index');

// @ commons
const { formatText } = require('core/i18n').i18n;

function setInitialState() {
    return Immutable.Map({
        valid: false,
        amount: 0,
        amountIsDirty: false,
        totalAvailableBalance: 0,
        choreType:[],
        choreTypeIsDirty:false,
        minDate: undefined, 
        selectedEndDate: undefined,
        concept:'',
        conceptIsDirty:false,
        selectedRepetitionType: Immutable.fromJS({value: formatText('createChoreStep-noRepetitions'), 
        label: formatText('createChoreStep-noRepetitions')}),
        repetitionOptionList: [],
        endDateIsDirty: false
    });
}

function validateStep(step) {
    let validAmount = step.get('amount') <= CHORE_MAX_AMOUNT && step.get('amount') !== 0;
    let validEndDate = !(moment(step.get('selectedEndDate').format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')));
    
    const valid = validAmount && validEndDate;
    return step.merge({
        valid: valid,
        amountIsDirty: true,
        endDateIsDirty: true
    });
}

function openYoungCardModifyChoreStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.MODIFY_CHORE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.MODIFY_CHORE_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountIsDirty: true
            })
        case actionTypes.MODIFY_CHORE_AVAILABLE_BALANCE:
            return state.merge({
                totalAvailableBalance: action.payload.totalAvailableBalance
            });
        case actionTypes.MODIFY_CHORE_SET_CHORE_TYPE:
            return state.mergeDeep({
                choreType: action.payload.choreType,
                choreTypeIsDirty:true
            });
        case actionTypes.MODIFY_CHORE_SET_REPETITION_TYPE:
            return state.mergeDeep({
                selectedRepetitionType: action.payload.selectedRepetitionType
            });
        case actionTypes.MODIFY_CHORE_SET_REPETITION_OPTION_LIST:
            return state.mergeDeep({
                repetitionOptionList: action.payload.repetitionOptionList
            });
        case actionTypes.MODIFY_CHORE_VALIDITY_DATE_CHANGED:
            return state.merge({
                selectedEndDate: action.payload.selectedEndDate
            });
        case actionTypes.MODIFY_CHORE_CONCEPT_CHANGED:
            return state.merge({
                concept: action.payload.concept,
                conceptIsDirty: true
            });
        default:
            return state;
    }
}

module.exports = openYoungCardModifyChoreStep1;
