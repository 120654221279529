const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    ...(__CONTACT_CENTER__ ? {
        agreementChecked: false,
        agreementIsDirty: false,
        valid: false,
        hireRestriction: false,
    } : { valid: true,
        hireRestriction: false, })
});

function validateStep(state,validFlag) {
    if (validFlag !== undefined) {
        if (validFlag) {
          return state.merge({
            valid: false,
            hireRestriction: true,
          });
        } else {
          return state.merge({
            valid: true,
            hireRestriction: false,
          });
        }
      }
    return state.merge({
        valid: state.get('agreementChecked'),
        agreementIsDirty: true
    });
}

function hireCardVirtualOnlyDebitStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_VIRTUAL_DEBIT_VALIDATE_STEP:
            return validateStep(state,action.payload.valid);

        case actionTypes.HIRE_CARD_VIRTUAL_DEBIT_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                agreementChecked: action.payload.checked,
                agreementIsDirty: false,
            });

        default:
            return state;
    }
}

module.exports = hireCardVirtualOnlyDebitStep1;
