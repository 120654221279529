// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
} = require('constants/index');

function setInitialState() {
   return Immutable.Map().merge({
        valid: false,
        validOriginPlan: true,
        planSelected: false,
        originPlanData: {},
        availablePlansFromSearch: [],
        planSelectedFromSearcher:'',
        filesAttached: {},
        isFetching: false,
        extractLoaded: false,
        error: false,
        notFound: false,
        success: false
   });
}

function originPlanDataFetchNotAvailable(state) {
    return state.get('originPlanData').merge({
        pensionFund: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        gestionEntity: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        depositEntity: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
    });
}

function originPlanDataFetchAvailable(response, state) {
    return state.get('originPlanData').merge({
        pensionFund: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        gestionEntity: response.ProviderCompany.Name,
        depositEntity: response.CustodianCompanies[0].Name, 
    });
}

function validateStep(step){
    const valid = step.get('success');

    return step.merge({
        valid: valid
    })
}

function mapData(planData, state) {
    const fileAttachedName = state.getIn(['filesAttached','name'],'');
    return Immutable.fromJS({
        fileAttachedName: fileAttachedName,
        DGSCode: planData.get('DGSCode'),
        name: planData.get('Name'),
        comercialData: {
            SecId: planData.get('SecId'),
            CustomMinimumPurchaseAmount: planData.get('CustomMinimumPurchaseAmount')
        },
       })
}

function brokerExternalTransferPlansStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SET_VISIBLE_STEP:
            return setInitialState();
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECT_ORIGIN:
            return state.merge({
                originPlanData: action.payload.planData,
                validOriginPlan: true
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_REMOVE_FILE_ATTACHED:
            return state.merge({
                filesAttached:{},
                extractLoaded: false,
                success: false
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_ATTACH_FILE:
            const fileObj = Immutable.fromJS({
                name: get(action.payload,'file.file.file.name',''),
                data: action.payload.file,
                description:'',
                success: false,
                error: ''
            });
            let planSelected = state.get('planSelected');
            let extractLoaded = true;
            return state.mergeDeep({
                originPlanData: {
                    fileAttachedName: fileObj.get('name'),
                },
                filesAttached: fileObj,
                extractLoaded: extractLoaded,
                success: extractLoaded && planSelected
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_DATA_REQUEST:
            return state.merge({
                isFetching: true,
                error: false,
                notFound: false,
                success: false
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_SELECTED_IN_SEARCHER_SUCCESS: 
            planSelected = true;
            extractLoaded = state.get('extractLoaded');
            return state.merge({
                planSelectedFromSearcher: action.payload.product,
                success: planSelected && extractLoaded,
                originPlanData: mapData(action.payload.planData, state),
                planSelected: planSelected,
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_NOT_FOUND:
            return state.merge({
                isFetching: false,
                notFound: true,
                originPlanData: originPlanDataFetchNotAvailable(state)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_EXTERNAL_FETCH_SUCCESS:
            extractLoaded = state.get('extractLoaded');
            planSelected = true;
            return state.merge({
                planSelected: planSelected,
                isFetching: false,
                success: planSelected && extractLoaded,
                originPlanData: originPlanDataFetchAvailable(action.payload.securityDetails, state)
            })
        case actionTypes.BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS:
            return state.merge({
                availablePlansFromSearch: action.payload.data.rows,
                planSelected: false,
                success:false,
                planSelectedFromSearcher: ''
            })
        default:
            return state;
    }
}

module.exports = brokerExternalTransferPlansStep1;
