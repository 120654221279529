// @vendors
const Immutable = require('immutable');
 
// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: false,
    errorMsg: '',
    isFetching: false,
    submitSuccessful: false,
});

function hireTravelPlusReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_TRAVEL_PLUS_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false,
            });

        case actionTypes.HIRE_TRAVEL_PLUS_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
            });

        case actionTypes.HIRE_TRAVEL_PLUS_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                isFetching: false,
            });
        
        case actionTypes.HIRE_TRAVEL_PLUS_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hireTravelPlusReducer;