const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const moment = require('moment');
const { DATE_FORMAT_API, DATE_FORMAT_TIME } = require('constants/index');
const { hash } = require('utilities/hash');

const today = moment().format(DATE_FORMAT_API);
function setInitialState() {
    return Immutable.fromJS({
        isFetching: false,
        error: false,
        hasBeenFetched: false,
        byDate: {},
        dateSelected: today
    });
}

// (Andres): The data model was based on this previous model, which all components were already handling
// {
//     "2016-12-02": {
//         events: [
//             {
//             'time': "EEUU 20:30 HS",
//             'title': "PMI Sector Construcción",
//             'estimation': "Estimación: 1,5% | Anterior: 1,75%",
//             }
//         ]
//     }
// }

function processEvents(state, data) {
    const byDate = {};

    data.forEach( event => {
        const id = hash(event.codigo + '' + event.titulo);
        const date = event.fecha_dia;

        if (!byDate[date]){
            byDate[date] = {
                events: []
            };
        }

        byDate[date].events.push({
            id,
            title: event.titulo,
            estimation: event.estimacion,
            previousData: event.dato_previo,
            time: event.campo_tipo_pais + ' ' + moment(event.hora, 'HH:mm:ss').format(DATE_FORMAT_TIME)
        });
    });

    return state.merge({
        isFetching: false,
        hasBeenFetched: true,
        error: false,
        byDate
    });
}

function brokerMarketsCalendar(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_MARKETS_DAY_CHANGED:
            return state.merge({
                dateSelected: action.date
            });

        case actionTypes.FETCH_BROKER_MARKET_CALENDAR_EVENTS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_BROKER_MARKET_CALENDAR_EVENTS_SUCCESS:
            return processEvents(state, action.payload.data);
        case actionTypes.FETCH_BROKER_MARKET_CALENDAR_EVENTS_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
                hasBeenFetched: false
            });

        default:
            return state;
    }
}

module.exports = brokerMarketsCalendar;
