const fundsHelper = {
    buildLinks(isin, contractId = null, isAlternativeFunds = false) {
        const patch = isAlternativeFunds ? 'alternative-fund' : 'fund';
        if(contractId) return (`/myprofile/broker/${patch}/isin/${isin}?contractId=${contractId}`)
        return (
            `/myprofile/broker/${patch}/isin/${isin}`
        )
    }
}

module.exports = fundsHelper;
