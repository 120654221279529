// @vendor
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const { GENDER_FEMALE } = require('constants/index');

const initialState = Immutable.fromJS({
    addingNewJuniorAccount: false,
    byId: {},
    byOrder: [],
    fetchSuccess: false,
    isFetching: false,
    juniorAccountId: '',
    juniorAccount: null,
    tutorCheckbox: {
        isDirty: false,
        isValid: false,
        value: false,
    },
    valid: false
}); 

function validateStep(state, externalComponentsAreValid) {
    return state.mergeDeep({
        valid: externalComponentsAreValid,
        tutorCheckbox: {
            isDirty: true,
            isValid: !!state.getIn(['tutorCheckbox', 'value']),
        }
    });
}

function hireCardOpenYoungStep3(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_OPEN_YOUNG_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);
        
        case actionTypes.HIRE_CARD_OPEN_YOUNG_SET_EXISTING_OPENYOUNG:
            const { 
                immOpenYoungProfileDetails, 
                mobileNumber, 
            } = action.payload;

            return state.merge({
                juniorAccount: {
                    birthDate: immOpenYoungProfileDetails.get('birthDate'),
                    birthSquare: immOpenYoungProfileDetails.get('populationBirth'),
                    country: immOpenYoungProfileDetails.get('country'),
                    documentNumber: immOpenYoungProfileDetails.get('documentCodeNumber'),
                    documentType: immOpenYoungProfileDetails.get('documentCodeType'),
                    gender: immOpenYoungProfileDetails.get('gender') === GENDER_FEMALE ? '1' : '0',
                    id: state.get('juniorAccountId'),
                    nationality: immOpenYoungProfileDetails.get('nationality'),
                    name: immOpenYoungProfileDetails.get('name'),
                    lastName: immOpenYoungProfileDetails.get('lastName'),
                    lastName2: immOpenYoungProfileDetails.get('lastName2'),
                    mobile: mobileNumber,
                    numberStreet: immOpenYoungProfileDetails.get('numberStreet'),
                    personCode: immOpenYoungProfileDetails.get('personCode'),
                    personType: immOpenYoungProfileDetails.get('personType'),
                    population: immOpenYoungProfileDetails.get('population'),
                    postalCode: immOpenYoungProfileDetails.get('postalCode'),
                    province: immOpenYoungProfileDetails.get('province'),
                    provinceBirth: immOpenYoungProfileDetails.getIn(['provinceBirth', 'province']),
                    restOfAddress: immOpenYoungProfileDetails.get('restOfAddress'),
                    streetName: immOpenYoungProfileDetails.get('streetName'),
                    typeOfRoads: immOpenYoungProfileDetails.get('typeOfRoads'),
                }
            });

        case actionTypes.HIRE_CARD_OPEN_YOUNG_SET_ACCOUNT:
            return state.merge({
                addingNewJuniorAccount: false,
                juniorAccountId: action.payload.accountId
            });

        case actionTypes.HIRE_CARD_OPEN_YOUNG_ADD_ACCOUNT:
            return state.merge({
                addingNewJuniorAccount: true,
                juniorAccountId: '',
            });

        case actionTypes.HIRE_CARD_OPEN_YOUNG_TOGGLE_TUTOR_CHECKBOX:
            const tutorCheckboxValue = state.getIn(['tutorCheckbox', 'value']);
            return state.merge({
                tutorCheckbox: {
                    isDirty: true,
                    isValid: !tutorCheckboxValue,
                    value: !tutorCheckboxValue,
                }
            });

        default:
            return state;
    }
}

module.exports = hireCardOpenYoungStep3;
