//@vendors
const Immutable = require('immutable');

//@helpers
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    inProgress: false,
    success: false
});

function accountNewChequebookReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_ACCOUNT_NEW_CHECKBOOK_INITIAL:
            return initialState;
        case actionTypes.SET_ACCOUNT_NEW_CHECKBOOK_REQUEST:
            return state.merge({
                inProgress: true
            });
        case actionTypes.SET_ACCOUNT_NEW_CHECKBOOK_SUCCESS:
            return state.merge({
                success: true,
                inProgress: false
            });
        case actionTypes.SET_ACCOUNT_NEW_CHECKBOOK_FAILURE:
            return state.merge({
                inProgress: false
            });
        default:
            return state
    }
}

module.exports = accountNewChequebookReducer;
