// @ vendors
const Immutable = require("immutable");

// @ constants
const actionTypes = require("constants/contactCenter/actionTypes");
const {WIZARD_FIRST_STEP} = require("constants/index");

function setInitialState() {
    return Immutable.fromJS({
        hasBeenSent: false,
        error: "",
        feedback: "",
        isFetching: false,
        visibleStep: WIZARD_FIRST_STEP,
        steps: [
            {
                valid: true,
                hasSignaturePattern: true
            },
            {
                valid: true,
            },
            {
                valid: true
            }
        ]
    });
}

function recoveryKeys(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.RECOVERY_KEY_SUCCESS:
            return state.merge({
                hasBeenSent: true,
                error: "",
                isFetching: false,
                feedback: ""
            });
        case actionTypes.RECOVERY_KEYS_DATA_REQUEST:
            return state.merge({
                error: "",
                isFetching: true
            });
        case actionTypes.RECOVERY_KEYS_DATA_ERROR:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.RECOVERY_KEYS_DATA_FAILURE:
            return state.merge({
                feedback: action.payload.message,
                isFetching: false
            });
        case actionTypes.RECOVERY_KEYS_SET_VISIBLE_STEP:
            return state.set("visibleStep", action.payload.step);
        case actionTypes.RECOVERY_KEYS_DATA_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = recoveryKeys;
