module.exports = {
    timeoutError: {
        errorTitle: 'contractsView-checkingAccountHireTimeoutErrorTitle',
        errorBody: 'contractsView-checkingAccountHireTimeoutErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    notAllowed: {
        errorTitle: 'contractsView-savingAccount_termsAndCondsErrorTitle',
        errorBody: 'contractsView-savingAccount_termsAndCondsErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    }
}
