import lunr from 'lunr';
const storage = require('utilities/storage');
const { COUNTRIES_CODES: { ES } } = require('constants/countries');
require("lunr-languages/lunr.stemmer.support")(lunr);
require('lunr-languages/lunr.multi')(lunr)
require("lunr-languages/lunr.es")(lunr)
require("lunr-languages/lunr.nl")(lunr)
require("lunr-languages/lunr.de")(lunr)
require("lunr-languages/lunr.pt")(lunr)


const MAX_RESULTS = 25;

/**
 * termsIndex starts as null to make it lighter on import.
 * If a function uses termsIndex, it must initialize it first.
 * Around 1ms to initialize.
 **/
let termsIndex = null;

const getTermsByLanguageAndOriginCountry = originCountry => {
    if(originCountry === ES) {
        const language = storage.cookies.get('language')
        if(language && typeof language.toUpperCase === "function"){
            return require(`./terms-${language.toUpperCase()}`)
        }

        return require(`./terms-${ES}`)
    }

    return require(`./terms-${originCountry}`)
}

const initIndex = originCountry => {
    const terms = getTermsByLanguageAndOriginCountry(originCountry)
    if (!termsIndex) {
        termsIndex = lunr(function() {
            this.use(lunr.multiLanguage('en', 'es', 'de', 'nl', 'pt'));
            this.ref('name');
            this.field('name');
            this.field('definition');
            terms.forEach(doc => {this.add(doc)}, this);
        });
    }
};

const filterFirstLettersWithResults = (letter, originCountry) => {
    const terms = getTermsByLanguageAndOriginCountry(originCountry)
    return terms.some(term => term.name[0].toLowerCase() === letter.toLowerCase());
}

const searchByQuery = (query, originCountry) => {
    if (!termsIndex) initIndex(originCountry);
    return termsIndex
        .search(`name:*${query}*^2 ${query}`) // Match is twice as strong for names.
        .slice(0, MAX_RESULTS - 1) // Truncate results.
        .map((result) => result.ref);
};

const searchByFirstLetter = (letter, originCountry) => {
    const terms = getTermsByLanguageAndOriginCountry(originCountry)
    return terms
    .filter(term => term.name[0].toLowerCase() === letter.toLowerCase()) // Search straight on the terms object.
    .slice(0, MAX_RESULTS - 1) // Truncate results.
    .map(term => term.name);
}

const selectResult = (result, originCountry) => {
    if (!termsIndex) initIndex(originCountry);
    const terms = getTermsByLanguageAndOriginCountry(originCountry)
    return terms.find(term => term.name === result);
};


export {
    filterFirstLettersWithResults,
    searchByQuery,
    searchByFirstLetter,
    selectResult,
};
