
const brokerProductFileTags = (eventTag) => (
    {
        brokerProductFile: {
            categoriaEvento: 'stockDescriptionInteraction',
            accionEvento: 'evolution',
            etiquetaEvento: eventTag
        },
        brokerProductFileBuy: {
            categoriaEvento: 'stockDescriptionInteraction',
            accionEvento: 'buy',
            etiquetaEvento: eventTag
        },
        brokerProductFileSearch: {
            categoriaEvento: 'stockDescriptionInteraction',
            accionEvento: 'searcher',
            etiquetaEvento: 'click'
        },
        brokerProductFileTrader: {
            categoriaEvento: 'stockDescriptionInteraction',
            accionEvento: 'opentrader',
            etiquetaEvento: 'click'
        }
    }
)

module.exports = { brokerProductFileTags }