// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    'estado': null,
    'fechaObjetivo': null,
    'fechaObjetivoVivienda': null,
    'fechaObjetivoTitulares': null,
    'idExpediente': 0,
    'idGestor': 0,
    'token': '',
    isFetching: false,
    hasBeenFetched: false
});

const mortgageRequestState = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_FETCH_STATE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_STATE_SUCCESS:
            const data = action.data;

            return state.merge({
                estado: get(data, 'data.estado', null) || null,
                fechaObjetivo: get(data, 'data.fechaObjetivo', null) || null,
                fechaObjetivoVivienda: get(data, 'data.fechaObjetivoVivienda', null) || null,
                fechaObjetivoTitulares: get(data, 'data.fechaObjetivoTitulares', null) || null,
                idExpediente: get(data, 'data.idExpediente', 0) || 0,
                idGestor: get(data, 'data.idGestor', 0) || 0,
                token: get(data, 'data.tokenSimulacion', ''),
                error: '',
                isFetching: false,
                hasBeenFetched: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_STATE_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_STATE_SET_DATE:
            return state.set('fechaObjetivo', action.data.date);
        case actionTypes.MORTGAGE_REQUEST_SET_INITIAL_STATE:
            return state.set('cargaInicial', true);
        case actionTypes.MORTGAGE_REQUEST_RESET_STATE:
            return state.merge(initialState);
        default:
            return state
    }
};

module.exports = mortgageRequestState;
