// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    data: [],
    personalData: [],
    economicData: [],
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    hasBeenPersonalDataFetched: false,
    hasBeenPersonalEconomicFetched: false
});

const dataProprietor = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_REQUEST:
            return state.merge({
                isFetching: true
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_SUCCESS:
            return state.merge({
                data: action.data.data,
                error: '',
                isFetching: false,
                hasBeenFetched: true
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_INFO:
            return state
                .set('personalData', action.data)
                .set('hasBeenPersonalDataFetched', true);

        case actionTypes.MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_ECONOMIC:
            return state
                .set('economicData', action.data)
                .set('hasBeenPersonalEconomicFetched', true);

        case actionTypes.MORTGAGE_REQUEST_FETCH_DATA_PROPRIETOR_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        default:
            return state;
    }
};

module.exports = dataProprietor;
