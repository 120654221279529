const get = require('lodash/object/get');
// @core
const {formatText} = require('core/i18n').i18n;

const {
    CARD_REQUEST_ADDITIONAL_FETCH_DATA_SUCCESS,
    CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR,
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_SUCCESS,
    CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_FAILURE,
    LIMITS_DATA_SUCCESS,
    LIMITS_DATA_FAILURE,
    MODIFY_NGO_LIMITS_INFO_SUCCESS,
    MODIFY_NGO_LIMITS_INFO_FAILURE,
    CARDS_CONTROL_HISTORY_QUERY_SUCCESS,
    CARDS_CONTROL_HISTORY_QUERY_FAILURE
} = require('constants/contactCenter/actionTypes')


const {
    CARD_CONTROL_ON_RESET,
    CARD_CONTROL_ON_SUCCESS,
    CARD_CONTROL_ON_FAILURE,
    CARD_GET_PAY_LATER_INFORMATION_FAILURE,
    CARD_GET_PAY_LATER_INFORMATION_SUCCESS,
    CARD_GET_PAYMENT_METHOD_INFORMATION_FAILURE,
    CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS,
    CARD_INCREASE_LIMITS_SUCCESS,
    CARD_REQUEST_LIMITS_FAILURE,
    CARD_SECURE_PAYMENT_SETUP_FAILURE,
    CARD_SECURE_PAYMENT_SETUP_SUCCESS,
    ECARD_INFORMATION_FAILURE,
    ECARD_INFORMATION_SUCCESS,
    FETCH_CARDS_TRANSACTIONS_FAILURE,
    FETCH_CARDS_TRANSACTIONS_SUCCESS,
    HIRE_INFORMATION_FAILURE,
    HIRE_INFORMATION_SUCCESS,
    HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_FAILURE,
    HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_START,
    HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_SUCCESS,
    HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_FAILURE,
    HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_START,
    HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_SUCCESS,
    OPEN_YOUNG_GET_BENEFICIARY_DOCUMENTATION_DATA_FAILURE,
    OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_REQUEST,
    OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_SUCCESS,
    UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_FAILURE,
    UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_SUCCESS,
} = require('constants/actionTypes')

const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE,
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_NONE_AND_RESET
} = require('utilities/contactCenter/wrapUp/constants')

const ACTION_CODE_CARD_LANDING = 5026
const ACTION_CODE_CARD_BLOCK = 5037
const ACTION_CODE_CARD_REPLACE = 5038
const ACTION_CODE_CARDS_CHARGE_UNCHARGE = 5039
const ACTION_CODE_REQUEST_ADDITIONAL_CARD = 5045
const ACTION_CODE_SEND_PIN = 5055
const ACTION_CODE_ACTIVATE_CARD = 5047
const ACTION_CODE_PIN_RESET = 5048
const ACTION_CODE_CARD_MODIFY_LIMIT = 5049
const ACTION_CODE_CARD_DETAILS = 5035
const ACTION_CODE_CARD_SECURE_PAYMENT = 5036
const ACTION_CODE_CARD_PURCHASE_DEFERRAL = 5040
const ACTION_CODE_CARD_CANCEL_POST_PAYMENT = 5041
const ACTION_CODE_CARD_CANCEL_RENEWAL = 5042
const ACTION_CODE_CARD_MOVEMENTS = 5034
const ACTION_CODE_CARD_STATEMENTS = 5052
const ACTION_CODE_CARD_DEPOSIT_MONEY = 5053
const ACTION_CODE_CARD_CANCEL_PAY_LATER_CONFIRM = 5043
const ACTION_CODE_CARD_PAY_LATER = 5051
const ACTION_CODE_CARD_PAYMENT_METHOD_CHANGE = 5050
const ACTION_CODE_CARD_TRANSFER_MONEY = 5044
const ACTION_CODE_CARD_STATE_MODIFICATION = 5046
const ACTION_CODE_CARD_REWARDS = 41001
const ACTION_CODE_MODIFY_CARD_ACCOUNT = 5054
const ACTION_CODE_UPDATE_ATM_CARD_LIMIT = 5187
const ACTION_CODE_UPDATE_SHOPPING_CARD_LIMIT = 5186
const ACTION_CODE_MODIFY_SOLIDARITY_CARD_LIMITS = 5184
const ACTION_CODE_CARD_CONTROL_HISTORY = 5205
const ACTION_CODE_CARD_CANCELLED_LANDING = 5221
const ACTION_CODE_CARD_OY_ADD_DOCUMENTATION = 5255;
const ACTION_CODE_CARD_OY_CHECK_DOCUMENTATION_STATE = 221513122;
const ACTION_CODE_CARD_REVOLVING = 5243;
const ACTION_CODE_CARD_TRAVEL_PLUS_OFF = 5281;
const ACTION_CODE_CARD_TRAVEL_PLUS_ON = 5280;
const ACTION_CODE_CARD_INSURANCE_BENEFITS = 221113115;

module.exports = {
    [ACTION_CODE_CARD_LANDING]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards'
        }
    },
    [ACTION_CODE_CARD_CANCELLED_LANDING]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards/cancelled'
        }
    },
    [ACTION_CODE_CARD_INSURANCE_BENEFITS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards/[0-9]+/benefits/[a-zA-Z0-9]+'
        }
    },
    [ACTION_CODE_CARD_MOVEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards/[0-9]+/movements',
            failure:  [
                { action: FETCH_CARDS_TRANSACTIONS_FAILURE }
            ],
            success: [
                { action: FETCH_CARDS_TRANSACTIONS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_CONTROL_HISTORY]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [ CARDS_CONTROL_HISTORY_QUERY_SUCCESS, CARDS_CONTROL_HISTORY_QUERY_FAILURE ],
            check: (action) => {

                let operationState = OPERATION_LOG_DONE
                if (action.type === CARDS_CONTROL_HISTORY_QUERY_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_CARD_CONTROL_HISTORY,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_CARDS_CHARGE_UNCHARGE]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/charge',
            failure: [
                { action: ECARD_INFORMATION_FAILURE }
            ],
            success: [
                { action: ECARD_INFORMATION_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_CARD_DEPOSIT_MONEY]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/deposit-money'
        }
    },
    [ACTION_CODE_CARD_CANCEL_PAY_LATER_CONFIRM]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/cancel-pay-later-confirm'
        }
    },
    [ACTION_CODE_CARD_PAY_LATER]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/pay-later',
            failure: [
                { action: CARD_GET_PAY_LATER_INFORMATION_FAILURE }
            ],
            success: [
                { action: CARD_GET_PAY_LATER_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_PAYMENT_METHOD_CHANGE]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/payment-method/change',
            failure: [
                { action: CARD_GET_PAYMENT_METHOD_INFORMATION_FAILURE }
            ],
            success: [
                { action: CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_TRANSFER_MONEY]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/transfer-money-to-checking-account',
            failure: [
                { action: CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_FAILURE }
            ],
            success: [
                { action: CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_CANCEL_RENEWAL]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/cancel-renewal'
        }
    },
    [ACTION_CODE_CARD_STATE_MODIFICATION]: {
        entry: {
            action: [ CARD_CONTROL_ON_RESET ],
            check: (action) => {
                let operationState = OPERATION_LOG_NONE
                const modalOpen = get(action, 'payload.modalOpen')

                if (modalOpen) {
                    operationState = OPERATION_LOG_DONE
                }

                return {
                    actionCode: ACTION_CODE_CARD_STATE_MODIFICATION,
                    operationState
                }
            }
        },
        finish: {
            action: [ CARD_CONTROL_ON_SUCCESS, CARD_CONTROL_ON_FAILURE, CARD_CONTROL_ON_RESET ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE,
                    errorMessage
                const modalOpen = get(action, 'payload.modalOpen');
                
                if (action.type === CARD_CONTROL_ON_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                    errorMessage = get(action, 'payload.error.description')
                } 

                if (action.type === CARD_CONTROL_ON_RESET && modalOpen) {
                    operationState = OPERATION_LOG_NONE
                } 

                if (action.type === CARD_CONTROL_ON_RESET && !modalOpen) {
                    operationState = OPERATION_LOG_NONE_AND_RESET
                } 

                return {
                    actionCode: ACTION_CODE_CARD_STATE_MODIFICATION,
                    operationState,
                    errorMessage
                }

            }
        }
    },
    [ACTION_CODE_CARD_BLOCK]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/block'
        }
    },
    [ACTION_CODE_CARD_REPLACE]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/replace'
        }
    },
    [ACTION_CODE_REQUEST_ADDITIONAL_CARD]: {
        entry: {
            action: [
                CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR,
                CARD_REQUEST_ADDITIONAL_FETCH_DATA_SUCCESS
            ],
            check: (action) => {
                const {type, payload: {response: {listaIntervinientes: {intervinientes}}}} = action;
                const hasInverners = list => list.filter(intervener => !(intervener && intervener.principal)).length > 0

                let operationState = type === CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE;
                let errorMessage;

                if (type === CARD_REQUEST_ADDITIONAL_FETCH_DATA_ERROR) {
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.data.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description'
                } else if (type === CARD_REQUEST_ADDITIONAL_FETCH_DATA_SUCCESS && !hasInverners(intervinientes)) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = formatText('cardRequestAdditional-feedbackErrorSubtitleNoOwner')
                }

                return {
                    operationState,
                    actionCode: ACTION_CODE_REQUEST_ADDITIONAL_CARD,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_CARD_STATEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards/[0-9]+/statements'
        }
    },
    [ACTION_CODE_SEND_PIN]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/send-pin'
        }
    },
    [ACTION_CODE_CARD_DETAILS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards/[0-9]+/details',
        }
    },
    [ACTION_CODE_CARD_REWARDS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/cards/[0-9]+/rewards'
        }
    },
    [ACTION_CODE_CARD_SECURE_PAYMENT]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/secure-payment',
            failure: [
                { action: CARD_SECURE_PAYMENT_SETUP_FAILURE }
            ],
            success: [
                { action: CARD_SECURE_PAYMENT_SETUP_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_PURCHASE_DEFERRAL]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/payment-purchase-deferral/[0-9]+'
        }
    },
    [ACTION_CODE_CARD_CANCEL_POST_PAYMENT]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/cancel-post-payment/[0-9]+'
        }
    },
    [ACTION_CODE_ACTIVATE_CARD]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/activate'
        }
    },
    [ACTION_CODE_PIN_RESET]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/pin-reset'
        }
    },
    [ACTION_CODE_MODIFY_CARD_ACCOUNT]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/modify-card-account'
        }
    },
    [ACTION_CODE_CARD_MODIFY_LIMIT]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/modify-limit',
            failure: [
                { action: CARD_REQUEST_LIMITS_FAILURE }
            ],
            success: [
                { action: CARD_INCREASE_LIMITS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_UPDATE_ATM_CARD_LIMIT]: {
        entry: {
            action: [ LIMITS_DATA_SUCCESS, LIMITS_DATA_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE,
                    errorMessage;

                if (action.type === LIMITS_DATA_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description'
                }

                return {
                    actionCode: ACTION_CODE_UPDATE_ATM_CARD_LIMIT,
                    operationState,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_MODIFY_SOLIDARITY_CARD_LIMITS]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/ngo-detail',
            failure: [
                { action: MODIFY_NGO_LIMITS_INFO_FAILURE }
            ],
            success: [
                { action: MODIFY_NGO_LIMITS_INFO_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_UPDATE_SHOPPING_CARD_LIMIT]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/commerce/modify-debit-limit',
            failure: [
                { action: LIMITS_DATA_FAILURE }
            ],
            success: [
                { action: LIMITS_DATA_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_OY_ADD_DOCUMENTATION]: {
        entry: {
            path: '/myprofile/cards/[0-9]+/upload-documentation'
        },
        finish: {
            action: [ UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_FAILURE, UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_SUCCESS ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE,
                    errorMessage;

                if (action.type === UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorDescription.error.description');
                }

                return {
                    actionCode: ACTION_CODE_CARD_OY_ADD_DOCUMENTATION,
                    operationState,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_CARD_OY_CHECK_DOCUMENTATION_STATE]: {
        entry: {
            action: [ OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_REQUEST ]
        },
        finish: {
            action: [ OPEN_YOUNG_GET_BENEFICIARY_DOCUMENTATION_DATA_FAILURE, OPEN_YOUNG_GET_CARD_DOCUMENTATION_DATA_SUCCESS ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE,
                    errorMessage;

                if (action.type === OPEN_YOUNG_GET_BENEFICIARY_DOCUMENTATION_DATA_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorDescription.description');
                }

                return {
                    actionCode: ACTION_CODE_CARD_OY_CHECK_DOCUMENTATION_STATE,
                    operationState,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_CARD_REVOLVING]: {
        entry: {
            path: '/myprofile/hire/card/revolving',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CARD_TRAVEL_PLUS_ON]: {
        entry: {
            action: [ HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_START ]
        },
        finish: {
            action: [ HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_FAILURE, HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_SUCCESS ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;

                if (action.type === HIRE_TRAVEL_PLUS_CC_WRAP_UP_ON_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorMsg.description')
                        || get(action, 'payload.errorMsg.text')
                        || 'No error\'s description';
                }

                return {
                    actionCode: ACTION_CODE_CARD_TRAVEL_PLUS_ON,
                    errorMessage,
                    operationState,
                }
            }
        }
    },
    [ACTION_CODE_CARD_TRAVEL_PLUS_OFF]: {
        entry: {
            action: [ HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_START ]
        },
        finish: {
            action: [ HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_FAILURE, HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_SUCCESS ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;

                if (action.type === HIRE_TRAVEL_PLUS_CC_WRAP_UP_OFF_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorMsg.description')
                        || get(action, 'payload.errorMsg.text')
                        || 'No error\'s description';
                }

                return {
                    actionCode: ACTION_CODE_CARD_TRAVEL_PLUS_OFF,
                    errorMessage,
                    operationState,
                }
            }
        }
    },
}

module.exports.secondaryFlows = [
    ACTION_CODE_CARD_OY_CHECK_DOCUMENTATION_STATE,
]
