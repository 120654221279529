// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        equals: null,
        firstInput: [null,null,null,null,null,null,null,null],
        secondInput: [null,null,null,null,null,null,null,null]
    });
}

function myProfileKeySignatureStep1(state = setInitialState(), action) {
    let equals;

    switch (action.type) {
        case actionTypes.KEY_SIGNATURE_UPDATE_FIRST_FIELD:
            let firstInput = state.get('firstInput');
            // if the value entered is an empty string because the user is deleting, instead of saving it, we save null as the initial value
            const firstValueToSave = action.payload.firstInput.value === '' ? null : action.payload.firstInput.value;
            firstInput = firstInput.set(action.payload.firstInput.position, firstValueToSave);
            equals = state.get('secondInput').equals(firstInput) && !firstInput.includes(null) && !state.get('secondInput').includes(null);
            return state.merge({
                equals,
                firstInput
            });
        case actionTypes.KEY_SIGNATURE_UPDATE_SECOND_FIELD:
            let secondInput = state.get('secondInput');
            // if the value entered is an empty string because the user is deleting, instead of saving it, we save null as the initial value
            const secondValueToSave = action.payload.secondInput.value === '' ? null : action.payload.secondInput.value;
            secondInput = secondInput.set(action.payload.secondInput.position, secondValueToSave);
            equals = state.get('firstInput').equals(secondInput) && !secondInput.includes(null) && !state.get('firstInput').includes(null);
            return state.merge({
                equals,
                secondInput
            });
        case actionTypes.KEY_SIGNATURE_CHANGE_VALIDATE_STEP:
            let inputsNull = false; 
            let inputsMatch = false;
            if (state.get('firstInput').includes(null) || state.get('secondInput').includes(null)) {
                inputsNull = true
            }
            else if (state.get('firstInput').equals(state.get('secondInput')) ) {
                inputsMatch = true;
            }
            return state.merge({
                inputsNull,
                inputsMatch
            });
            
    }

    return state;
}

module.exports = myProfileKeySignatureStep1;
