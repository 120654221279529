const Immutable = require('immutable');
const {
    ACCOUNT_BENEFITS_DATA_REQUEST,
    ACCOUNT_BENEFITS_DATA_SUCCESS,
    ACCOUNT_BENEFITS_DATA_FAILURE
} = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isFetching: false,
    hasBeenFetched: false,
    error: '',
    accountBenefitsData: {}
});

const IS_FETCHING_STATE = Immutable.fromJS({
    isFetching: true,
    hasBeenFetched: false,
    error: '',
    accountBenefitsData: {}
});

const createErrorState = error => Immutable.fromJS({
    isFetching: false,
    hasBeenFetched: false,
    error,
    accountBenefitsData: {}
});

const createSucessState = benefits123 => Immutable.fromJS({
    isFetching: false,
    hasBeenFetched: true,
    error: '',
    accountBenefitsData: benefits123
});

function benefits123(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACCOUNT_BENEFITS_DATA_REQUEST:
            return IS_FETCHING_STATE;
        case ACCOUNT_BENEFITS_DATA_FAILURE:
            return createErrorState(action.payload.error);
        case ACCOUNT_BENEFITS_DATA_SUCCESS:
            return createSucessState(action.payload);
        default:
            return state;
    }
}

module.exports = benefits123;
