// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const moment = require('moment');

// @helpers
const { getPublicSitePathByLanguaje } = require('core/apiURLBuilder');
const DateHelper = require('utilities/dateHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    HYPHEN,
    DATE_FORMAT_API,
    BROKER_CORPORATE_OPERATIONS_DERECHOS_PROCESS_TYPE,
    BROKER_CORPORATE_OPERATIONS_CANJE_PROCESS_TYPE,
    BROKER_CORPORATE_OPERATIONS_EVENT_CODE_AMPLIACION_CAPITAL,
    BROKER_CORPORATE_OPERATIONS_EVENT_CODE_CANJE
} = require('constants/index');

const initialState = Immutable.fromJS({
    stocksContracts: [],
    selectedContractValue: '',
    selectedContract: {
        associatedAccount: {
            number: '',
            pais: '',
            balance: {
                amount: '',
                currency: ''
            }
        },
        contract: {
            number: '',
            product: ''
        },
        links: {
            href: '',
        },
        operations: [],
        walletNumber: '',
        walletAccountType: '',
        owner: '',
        walletBalance: {
            amount: 0,
            currency: ''
        },
        fetched: false,
        loading: false,
        error: false
    },
    marketsNextAvailableDate: [],
    marketsDefaultNextAvailableDate: DateHelper.getNextBussinessDay(true, false),
    loading: false,
    error: ''
});

function getContractByAccount(state, contractNumber) {
    const selectedAccount = state.get('stocksContracts').find(stocksContract => {
        return stocksContract.getIn(['contract', 'number']) === contractNumber;
    });

    return selectedAccount;
}

function parseStocksContracts(stocksContracts) {
    const parsedStocksContracts = stocksContracts.map(stocksContract => {
        return {
            walletNumber: get(stocksContract, 'contrato.numerodecontrato'),
            // Contract Description
            walletAccountType: get(stocksContract, 'descripcion'),
            owner: get(stocksContract, 'nombreTitular'),
            intervention: get(stocksContract, 'descIntervenciones.nomTipInterv'),
            // Contract balance
            walletBalance: {
                amount: get(stocksContract, 'saldo1.importe', 0),
                currency: get(stocksContract, 'saldo1.divisa')
            },
            contract: {
                number: get(stocksContract, 'contrato.numerodecontrato'),
                product: get(stocksContract, 'contrato.producto')
            },
            cuenta: {
                digitodecontrol: get(stocksContract, 'cuenta.digitodecontrol'),
                fullNumber: get(stocksContract, 'cuenta.numerodecuenta')
            }
        };
    });

    return parsedStocksContracts;
}

function getEventCodeFromProcessSubtype(processSubtype) {
    switch (trim(processSubtype)) {
        case BROKER_CORPORATE_OPERATIONS_DERECHOS_PROCESS_TYPE:
            return BROKER_CORPORATE_OPERATIONS_EVENT_CODE_AMPLIACION_CAPITAL;
        case BROKER_CORPORATE_OPERATIONS_CANJE_PROCESS_TYPE:
            return BROKER_CORPORATE_OPERATIONS_EVENT_CODE_CANJE;
        default:
            return '';
    }
}

const findMarketNextBusinessDay = (immState, iso) => {
    let nextBusinessDay = immState.get('marketsNextAvailableDate').find(immMarket => immMarket.get('iso') === iso.trim());
    return nextBusinessDay ? nextBusinessDay.get('nextBusinessDay') : immState.get('marketsDefaultNextAvailableDate');
}

const isEndDateValid = (immState, endDate, iso) => {
    return findMarketNextBusinessDay(immState, iso).isSameOrBefore(moment(endDate, DATE_FORMAT_API));
}

function buildOperations(immState, operations) {
    return operations.map(operation => ({
        eventCode: getEventCodeFromProcessSubtype(get(operation, 'subtipoProceso')),
        valueCode: trim(get(operation, 'codigoValorInterno')),
        linkId: trim(get(operation, 'codigoValorEmision')),
        descriptionValue: get(operation, 'descripcionValor', HYPHEN),
        descriptionProcess: get(operation, 'descripcionSubproceso', HYPHEN),
        startDate: get(operation, 'fechaInicOperac', HYPHEN),
        endDate: get(operation, 'fechaCierreOperacion', HYPHEN),
        closeDate: get(operation, 'fechaFinOperac', HYPHEN),
        payment: get(operation, 'desembo') || HYPHEN,
        subtipoProceso: get(operation, 'subtipoProceso', ''),
        codigoEmision: get(operation, 'codigoEmision', ''),
        codigoValorInterno: get(operation, 'codigoValorInterno', ''),
        tipoProceso: get(operation, 'tipoProceso', ''),
        digitoCuentaValores: get(operation, 'digitoCuentaValores', ''),
        proportion: get(operation, 'proporcionOf', HYPHEN).trim(),
        fetchingAdditionalInformation: false,
        fetchedAdditionalInformation: false,
        fetchedAdditionalInformationError: false,
        additionalInformation: '',
        isin: operation.isin,
        iso: operation.iso,
        nominalValue: operation.importeNominal,
        linkTriptico: getPublicSitePathByLanguaje('broker-staticPdfValoresTriptico', {
            isin: operation.isin,
            mercado: operation.iso
        }),
        linkCNMV: getPublicSitePathByLanguaje('broker-staticPdfCNMV', {
            isin: operation.isin,
            mercado: operation.iso
        }),
        isExisteEnDividendos: get(operation, 'isExisteEnDividendos')
    })).filter(operation => isEndDateValid(immState, operation.endDate, operation.iso));
}

function parseContractOperations(immState, contractOperations) {
    const operations = get(contractOperations, 'listadoOperFinancieras', []);
    const parsedOperations = buildOperations(immState, operations);
    const parsedContractOperations = {
        associatedAccount: {
            number: get(contractOperations, 'cuentaAsociada.codbban', HYPHEN),
            pais: get(contractOperations, 'cuentaAsociada.pais'),
            balance: {
                amount: get(contractOperations, 'saldoCuentaAsociada.importe', HYPHEN),
                currency: get(contractOperations, 'saldoCuentaAsociada.divisa')
            },
        },
        links: {
            href: get(contractOperations, '_links.self.href'), 
        },
        operations: parsedOperations,
        loading: false,
        fetched: true,
        error: false
    };

    return parsedContractOperations;
}

const fetchingAdditionalInformation = (immState, operationIndex) => {
    return immState.mergeIn(['selectedContract', 'operations', operationIndex], {
        fetchingAdditionalInformation: true,
        fetchedAdditionalInformation: false,
        fetchedAdditionalInformationError: false
    });
};

const fetchedAdditionalInformation = (immState, operationIndex, response) => {
    const information = [
        response.texto1,
        response.texto2,
        response.texto3,
        response.texto4,
        response.texto5,
        response.texto6,
        response.texto7,
        response.texto8,
        response.texto9,
        response.texto10,
        response.texto11,
        response.texto12
    ]
        .filter(text => !!text)
        .join(' ')
        .trim();

    return immState.mergeIn(['selectedContract', 'operations', operationIndex], {
        fetchingAdditionalInformation: false,
        fetchedAdditionalInformation: true,
        fetchedAdditionalInformationError: false,
        additionalInformation: information
    });
};

const additionalInformationError = (immState, operationIndex) => {
    return immState.mergeIn(['selectedContract', 'operations', operationIndex], {
        fetchingAdditionalInformation: false,
        fetchedAdditionalInformation: false,
        fetchedAdditionalInformationError: true,
        additionalInformation: ''
    });
};

const parseMarketsAvailableDate = marketsData => {
    return marketsData.map(market => ({
        iso: get(market, 'isoCode', '').trim(),
        nextBusinessDay: DateHelper.getNextBussinessDay(get(market, 'isAvailableToday'), get(market, 'currentDate'))
    })).filter(market => market.iso);
}

function brokerCorporatesOperationsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_WALLET_LIST_SUCCESS:
            return state.merge({
                stocksContracts: parseStocksContracts(action.walletList),
                loading: false
            });
        case actionTypes.BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION_ERROR:
            return additionalInformationError(
                state,
                action.payload.operationIndex,
                action.payload.error
            );
        case actionTypes.BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION_SUCCESS:
            return fetchedAdditionalInformation(
                state,
                action.payload.operationIndex,
                action.payload.response
            );
        case actionTypes.BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATION_FETCH_ADDITIONAL_INFORMATION:
            return fetchingAdditionalInformation(state, action.payload.operationIndex);
        case actionTypes.BROKER_CORPORATES_OPERATIONS_STOCK_CONTRACT_CHANGED:
            return state.merge({
                selectedContractValue: action.payload.value,
                selectedContract: getContractByAccount(state, action.payload.value)
            });
        case actionTypes.BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_REQUEST:
            return state.mergeIn(['selectedContract'], {
                loading: true,
                fetched: false,
                error: false
            });
        case actionTypes.BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_SUCCESS:
            return state.mergeDeep({
                selectedContract: parseContractOperations(state, action.payload.contractOperations)
            });
        case actionTypes.BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_FAILURE:
            return state.mergeIn(['selectedContract'], {
                loading: false,
                fetched: true,
                error: true
            });
        case actionTypes.BROKER_SELECTED_MARKETS_EXCHANGE_AVAILABILITY_SUCCESS:
            return state.mergeDeep({
                marketsNextAvailableDate: parseMarketsAvailableDate(action.payload.marketsData)
            });
        case actionTypes.BROKER_CORPORATES_OPERATIONS_RESET:
            return initialState.set('stocksContracts', state.get('stocksContracts'));
        default:
            return state;
    }
}

module.exports = brokerCorporatesOperationsReducer;
