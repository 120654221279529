const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const {decodeHTMLEntities} = require('utilities/stringHelper');
const { formatText } = require('core/i18n').i18n;
const EXCLUDED_ENTRY = 0; // This is a bug from the service, to we exclude it for now
const trim = require('lodash/string/trim');

function setInitialState() {
    return Immutable.fromJS({
        isFetching: false,
        hasBeenFetched: false,
        indexes: [],
        error: '',
        errorReceived: false,
        stocksByIndex: {},
        indexesAggregation: '',
        defaultSpcSymbol: '',
        defaultExchangeId: '',
        indexesAggregationPlayers: '',
        indexesPlayers: [],
    });
}

const parseExchangeList = (exchangeList) => {
    return exchangeList
        .filter((exchangeItem) => {
            return exchangeItem.exchangeId !== EXCLUDED_ENTRY
        }).map((exchangeItem) => {
            return {
                category: trim(exchangeItem.category),
                countryCode: !!exchangeItem.country ? exchangeItem.country.id : null,
                instrument: decodeHTMLEntities(trim(exchangeItem.instrument)),
                last: exchangeItem.last,
                high: exchangeItem.high,
                low: exchangeItem.low,
                name: decodeHTMLEntities(trim(exchangeItem.instrument)),
                netChangePercentage: exchangeItem.netChangePercentage,
                netChangePrice: exchangeItem.netChangePrice,
                volume: exchangeItem.volume,
                updateDate: trim(exchangeItem.updateDate),
                updateTime: trim(exchangeItem.updateTime),
                tradeDate: trim(exchangeItem.tradeDate),
                tradeTime: trim(exchangeItem.tradeTime),
                miccode: exchangeItem.miccode,
                exchangeId: exchangeItem.exchangeId,
                instrumentId: trim(exchangeItem.instrumentId),
                spcSymbol: trim(exchangeItem.spcSymbol),
                stockPrice: {
                    price: exchangeItem.last,
                    currency: exchangeItem.currencyCode
                },
                variationPts: Math.abs(exchangeItem.netChangePrice),
                variationPct: Math.abs(exchangeItem.netChangePercentage),
                upOrDown: exchangeItem.netChangePercentage > 0 ? 'up' : 'down',
                instrumentTranslated: formatText(`broker-tickerIndex_${trim(exchangeItem.instrumentId)}`)
            }
        }).sort((item1, item2) => (item1.instrumentTranslated > item2.instrumentTranslated) ? 1 : -1)
}

function brokerExchangeIndexesReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_BY_INDEX_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.selectedMarket]: {
                        isFetching: true,
                        hasBeenFetched: false,
                        error: false
                    }
                }
            });
        case actionTypes.BROKER_STOCKS_BY_INDEX_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.selectedMarket]: {
                        isFetching: false,
                        hasBeenFetched: true,
                        error: false,
                        stocks: parseExchangeList(action.payload.data)
                    }
                }
            });
        case actionTypes.BROKER_STOCKS_BY_INDEX_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.selectedMarket]: {
                        isFetching: false,
                        hasBeenFetched: false,
                        error: true
                    }
                }
            });
        case actionTypes.BROKER_EXCHANGE_INDEXES_REQUEST:
            return state.merge({
                isFetching: true,
                errorReceived: false,
                hasBeenFetched: false
            });
        case actionTypes.BROKER_EXCHANGE_INDEXES_SUCCESS:
            return state.merge({
                isFetching: false,
                hasBeenFetched: true,
                errorReceived: false,
                error: '',
                indexes: parseExchangeList(action.payload.data)
            });
        case actionTypes.BROKER_EXCHANGE_INDEXES_FAILURE:
            return state.merge({
                isFetching: false,
                errorReceived: true,
                hasBeenFetched: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_EXCHANGE_INDEXES_PLAYERS_REQUEST:
            return state.merge({
                isFetchingPlayers: true,
                errorReceived: false,
            });
        case actionTypes.BROKER_EXCHANGE_INDEXES_PLAYERS_SUCCESS:
            return state.merge({
                indexesPlayers: parseExchangeList(action.payload.data)
            });
        case actionTypes.BROKER_EXCHANGE_INDEXES_PLAYERS_FAILURE:
            return state.merge({
                isFetchingPlayers: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_GET_DEFAULT_CONFIG_REQUEST:
            return state.merge({
                isFetching: true,
                errorReceived: false,
                hasBeenFetched: false
            });
        case actionTypes.BROKER_GET_DEFAULT_CONFIG_SUCCESS:
            const { indexesAggregation, instrumentSpecSymbol, instrument, exchangeId, indexesAggregationPlayers } = action.payload.response;
            return state.merge({
                indexesAggregation: indexesAggregation,
                defaultSpcSymbol: instrumentSpecSymbol,
                defaultIndex: instrument,
                defaultExchangeId: exchangeId,
                isFetching: false,
                hasBeenFetched: true,
                errorReceived: false,
                error: '',
                indexesAggregationPlayers
            });
        case actionTypes.BROKER_GET_DEFAULT_CONFIG_FAILURE:
            return state.merge({
                isFetching: false,
                errorReceived: true,
                hasBeenFetched: false,
                error: action.payload.error
            });
        default:
            return state
    }
}

module.exports = brokerExchangeIndexesReducer;
