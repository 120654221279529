// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        inProgress: false,
        success: false,
        error: '',
        scaRequiredToDownload: false,
        fileFormat: ''
    });
}

function accountTransactionsDownload(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_REQUEST:
            return state.merge({
                inProgress: true,
            });
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SUCCESS:
            return state.merge({
                success: true,
                inProgress: false,
            });
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_FAILURE:
            return state.merge({
                inProgress: false,
                error: action.payload.error,
            });
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SCA_REQUIRED:
            return state.merge({
                inProgress: false,
                scaRequiredToDownload: true,
                fileFormat: action.fileFormat
            });
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DOWNLOAD_SCA_RESET:
            return state.merge({
                inProgress: false,
                scaRequiredToDownload: false
            });
        default:
            return state;
    }
}

module.exports = accountTransactionsDownload;
