const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');
const { EURO_TEXT, API_ENTERPRISE_NUMBER, API_CENTER } = require('constants/index');

function createAccountNumber(details){
    return `${API_ENTERPRISE_NUMBER}${API_CENTER}${details.digitoControl}${details.noCuenta}`;
}

function mapFields(details) {
    return {
        date: get(details, 'fecprlqt'),
        fecvalor: get(details, 'fecvalor'),
        charges: {
            amount: get(details, 'importe6.importe', 0),
            currency: get(details, 'importe6.divisa', EURO_TEXT)
        },
        writtenOffAccount: {
            amount: get(details, 'importe3Liq.importe', 0),
            currency: get(details, 'importe3Liq.divisa', EURO_TEXT)
        },
        interests: {
            amount: get(details, 'importe7.importe', 0),
            currency: get(details, 'importe7.divisa', EURO_TEXT)
        },
        totalBonus: {
            amount: get(details, 'importeb.importe', 0),
            currency: get(details, 'importeb.divisa', EURO_TEXT)
        },
        chargeImport: {
            amount: get(details, 'importe4.importe', 0),
            currency: get(details, 'importe4.divisa', EURO_TEXT)
        },
        previousBalance: {
            amount: get(details, 'importe2.importe', 0),
            currency: get(details, 'importe2.divisa', EURO_TEXT)
        },
        totalMovements: {
            amount: get(details, 'importe8.importe', 0),
            currency: get(details, 'importe8.divisa', EURO_TEXT)
        },
        outstandingBalance: {
            amount: get(details, 'jimppdte.importe', 0),
            currency: get(details, 'jimppdte.divisa', EURO_TEXT)
        },
        amortizedFee: {
            amount: get(details, 'impamore.importe', 0),
            currency: get(details, 'impamore.divisa', EURO_TEXT)
        },
        totalBalance: {
            amount: get(details, 'importe.importe', 0),
            currency: get(details, 'importe.divisa', EURO_TEXT)
        },
        chargeAccount: createAccountNumber(details)
    };
}

const initialState = Immutable.fromJS({});

function cardsStatementDetailsReducer(state = initialState, action) {
    const statementId  = get(action.payload, 'statementId');

    let processedDetails;

    switch (action.type) {
        case actionTypes.FETCH_CARDS_STATEMENT_DETAILS_REQUEST:
            return state.mergeDeep({
                [statementId]: {
                    loading: true,
                    error: false,
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENT_DETAILS_SUCCESS:
            processedDetails = mapFields(action.payload.details);

            return state.mergeDeep({
                [statementId]: {
                    loading: false,
                    error: false,
                    details: processedDetails
                }
            });
        case actionTypes.FETCH_CARDS_STATEMENT_DETAILS_FAILURE:
            return state.mergeDeep({
                [statementId]: {
                    loading: false,
                    error: true,
                    errorData: action.payload.error
                }
            });
        default:
            return state
    }
}

module.exports = cardsStatementDetailsReducer;
