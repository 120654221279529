const _flow = methods => data => methods.reduce((acc, method) => method(acc), data)
const _f = method => param => data => method(data, param)

const mapKeys = require('lodash/object/mapKeys')
const mapValues = require('lodash/object/mapValues')
const map = require('lodash/collection/map')
const flatten = require('lodash/array/flatten')
const has = require('lodash/object/has')
const filter = require('lodash/collection/filter')
const uniq = require('lodash/array/uniq')
const pick = require('lodash/object/pick')
const negate = require('lodash/function/negate')

const log = msg => value => {
    window.console.log(msg, value)
    return value
}

const _has = _f(has)
const _mapKeys = _f(mapKeys)
const _mapValues = _f(mapValues)
const _map = _f(map)
const _filter = _f(filter)
const _flatten = _f(flatten)
const _pick = _f(pick)

const _ = {
    flow: _flow,
    has: _has,
    mapKeys: _mapKeys,
    mapValues: _mapValues,
    map: _map,
    filter: _filter,
    log: log,
    flatten: _flatten,
    pickBy: _pick,
    uniq,
    negate
}

module.exports = _
