// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_REQUEST,
    MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_SUCCESS,
    MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_FAILURE,
    MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_REQUEST,
    MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_SUCCESS,
    MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    rncPesonalDataSaved: false
});

const mortgageRequestSimulationLinkClient = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_REQUEST:
        case MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_SIMULATION_LINK_CLIENT_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false,
                rncPesonalDataSaved: true
            });
        case MORTGAGE_REQUEST_SAVE_RNC_PERSONAL_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false,
                rncPesonalDataSaved: false
            });
        default:
            return state
    }
};

module.exports = mortgageRequestSimulationLinkClient;
