// @ vendor
const Immutable = require('immutable');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/actionTypes');
const {DATE_FORMAT_API} = require('constants/index');

const setInitialState = () => {
    return Immutable.fromJS({
        budgetList:{},
        budgetListError: [],
        isFetching: false,
        isError: false,
        selectedMonth: moment().startOf('month').format(DATE_FORMAT_API)
    });
};

const openYoungBudgetReducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_BUDGET_RESET:
            return setInitialState();
        case actionTypes.OPEN_YOUNG_BUDGET_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.OPEN_YOUNG_BUDGET_SUCCESS:
            return state.merge({
                isFetching: false,
                isError: false,
                budgetList: action.payload.budgetList
            });
        case actionTypes.OPEN_YOUNG_BUDGET_ERROR:
            return state.merge({
                isFetching: false,
                isError: true,
                budgetListError: action.payload.budgetListError
            });
        case actionTypes.OPEN_YOUNG_SET_COMPARATIVE_MONTH:
            return state.merge({
                selectedMonth: action.payload.selectedMonth
            });
        default:
            return state;
    }
};

module.exports = openYoungBudgetReducer;
