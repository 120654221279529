// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    BANNER_TYPE_ID_LOCAL,
    BANNER_TYPE_ID_SESSION,
    BANNER_TYPE_ID_COOKIES,
    BANNER_TYPE_LINK
} = require('constants/index');

// @ helpers
const Storage = require('utilities/storage');

const initialState = () => {
    return Immutable.fromJS({
        data: {
            title: '',
            description: '',
            descriptionComponents: [],
            bannerType: '', // The type of banner can be BANNER_TYPE_LINK or span, by default it is the span that has the same behavior as the close button of the banner.
            optionsBannerType: {}, 
        },
        id: '',
        typeId: '',
        isClose: true,
        isWaiting: false,
    });
};

const updateStorage = (type, id, value) => {
    switch (type) {
        case BANNER_TYPE_ID_LOCAL:
            Storage.localStorage.set(id, value)
            break;
        case BANNER_TYPE_ID_SESSION:
            Storage.sessionStorage.set(id, value)
            break;
        case BANNER_TYPE_ID_COOKIES:
            Storage.cookies.set(id, value)
            break;
    }
}

const setData = (data) => {
    const type = data.bannerType;
    let stateData = {
        title: data.title,
        description: data.description,
        descriptionComponents: data.descriptionComponents,
        bannerType: type,
        optionsBannerType: {},
    }
    
    switch (type) {
        case BANNER_TYPE_LINK:
            stateData.optionsBannerType = {
                link: data.optionsBannerType.link,
                linkName: data.optionsBannerType.linkName,
                extraClass: data.optionsBannerType.extraClass,
                modifier: data.optionsBannerType.modifier,
            }
            break;
        default:
            stateData.optionsBannerType = {
                text: data.optionsBannerType.text,
                modifier: data.optionsBannerType.modifier,
                extraClass: data.optionsBannerType.extraClass
            }
            break;
    }
    return stateData;
}

// The typeId is the kind of Storage
// BANNER_TYPE_ID_LOCAL --> Local
// BANNER_TYPE_ID_SESSION --> Session
// BANNER_TYPE_ID_COOKIES --> Cookies
function getStorage(id, typeId) {
    let isStorage;

    switch (typeId) {
        case BANNER_TYPE_ID_LOCAL:
            isStorage = Storage.localStorage.get(id);
            break;
        case BANNER_TYPE_ID_SESSION:
            isStorage = Storage.sessionStorage.get(id);
            break;
        case BANNER_TYPE_ID_COOKIES:
            isStorage = Storage.cookies.get(id);
            break;
    }
    return isStorage;
}

const bannerUpdate = (state = initialState(), action) => {
    switch (action.type) {
        case actionTypes.BROKER_BANNER_UPDATE_CLOSE_OR_AGREED:
            // When the user accepts or closes, the storage takes the value of 1
            updateStorage(state.get('typeId'), state.get('id'), 1);
            return initialState();
        case actionTypes.BROKER_BANNER_UPDATE_HIDE:
            if (state.get('isWaiting')) {
                return state.merge({
                    isClose: true,
                })
            } else {
                return state;
            }
        case actionTypes.BROKER_BANNER_UPDATE_SHOW:
            const isStorage = getStorage(state.get('id'), state.get('typeId'));
            if (isStorage === 0) {
                return state.merge({
                    isClose: false,
                }) 
            } else {
                return state;
            }
        case actionTypes.BROKER_BANNER_UPDATE_DATA: {
            // When the us enter the first time, the storage takes the value of zero
            const isStorage = getStorage(action.payload.id, action.payload.typeId);
            if (!isStorage || !isStorage === 0) {
                updateStorage(action.payload.typeId, action.payload.id, 0);
                return state.mergeDeep({
                    data: setData(action.payload.data),
                    id: action.payload.id,
                    typeId: action.payload.typeId,
                    isWaiting: true,
                });
            }
        }
        default:
            return state;
    }
};

module.exports = bannerUpdate;
