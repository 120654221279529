const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const {
    EURO_SYMBOL,
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED
} = require('constants/index');

module.exports = {
    title: 'contractsView-depositThirteenMonthsStep2Title',
    order: 2,
    footer_text: '',
    disableNext: 'depositThirteenMonthsLeakRetention_nextButtonDisabled',
    position: 'middle',
    components:
        [
            {
                id_component: 'dropdown-accounts',
                label: 'contractsView-depositThirteenMonthsStep2Account',
                options: [],
                type: 'single',
                target: 'cta_seleccionada',
                targetIntervener: 'intervinientes_list',
                intervenersSpecial: true,
                order: 1,
                isIntervenerAvailable: true,
                renderInterveners: 'depositThirteenMonthsLeakRetention_shouldBuild',
                isMaxAmountLabelAvailable: false,
                intervenersHeader: 'contractsView-depositThirteenMonthsIntervenersHeader',
                maxAmountValue: 0,
                required: true,
                errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_THIRTEEN,
                accountChangeExtraFn: 'refreshDeposit13MonthsLeakRetentionHireInformation',
                intervenersHierarchicalTypeSelection: true,
                intervenerOptions: [
                    INTERVENER_LABEL_HOLDER,
                    INTERVENER_LABEL_AUTHORIZED,
                    INTERVENER_LABEL_EMPOWERED
                ],
                showIntervenerControls: true,
                restoreDefaultIntervenersEnabled: true
            },
            {
                id_component: 'slider',
                label: 'contractsView-depositThirteenMonthsStep2Amount',
                min_value: 0,// is populated by service
                max_value: 0,// is populated by service
                default_value: 0,// is populated by service
                value: null,// is populated by service
                type: 'single',
                format_type: EURO_SYMBOL,
                onChangeFn: 'depositThirteenMonthsLeakRetention_onChangeAmount',
                is_money: true,
                title: 'contractsView-amountTitle',
                target: 'monto_traspasar',
                source: 'depositThirteenMonthsLeakRetention_loadSliderAmounts',
                buildExtraInfo: 'depositThirteenMonthsLeakRetention_buildSliderExtraInfo',
                tooltipNoPadding: true,
                buildCondition: 'depositThirteenMonthsLeakRetention_shouldBuild'
            },
            {
                id_component: 'interest-band'
            },
            {
                id_component: 'checkbox',
                id: 'renewDeposit',
                target: 'renewDeposit',
                label: 'contractsView-depositThirteenMonthsStep2Renew',
                type: 'single',
                onChangeFn: 'depositThirteenMonthsLeakRetention_onChangeCheckbox',
                buildCondition: 'depositThirteenMonthsLeakRetention_shouldBuild'
            },
            {
                id_component: 'validation-modal',
                id: 'validationModal',
                title: 'depositThirteenMonthsLeakRetention_FinalModalTitle',
                titleType: 'calculated',
                content: 'depositThirteenMonthsLeakRetention_FinalModalChildren',
                allowContinue: true,
                open: 'depositThirteenMonthsLeakRetention_showFinalModal',
                showCloseButton: false,
                leaveButtonText: 'contractsView-depositThirteenMonthsBackButtonModal'
            },
            {
                id_component: 'validation-modal',
                id: 'accountModal',
                title: 'contractsView-depositThirteenMonthsStep2InitialModalTitle',
                content: 'depositThirteenMonthsLeakRetention_InitialModalChildren',
                allowContinue: false,
                showCloseButton: false,
                leaveButtonText: 'contractsView-depositThirteenMonthsBackButtonModal'
            },
            {
                id_component: 'disclaimer-personal-info',
                messageKey: 'depositThirteenMonthsMsg',
                linkTextKey: 'depositThirteenMonthsLinkText',
                linkUrlKey: 'depositThirteenMonthsLinkUrl'
            }
        ]
};
