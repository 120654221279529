// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    email: '',
    emailChange: false,
    emailValid: true,
    privacyValid: false,
    error: '',
    isFetching: false
});

const addEmail = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_FETCH_ADDEMAIL_REQUEST:
        case actionTypes.MORTGAGE_REQUEST_SAVE_ADDEMAIL_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_ADDEMAIL_SUCCESS:
            return state.merge({
                email: action.data.data || '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_SAVE_ADDEMAIL_SUCCESS:
            return state.merge({
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_ADDEMAIL_FAILURE:
        case actionTypes.MORTGAGE_REQUEST_SAVE_ADDEMAIL_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_UPDATE_ADDEMAIL_SUCCESS:
            return state.merge({
                email: action.data.newEmail,
                emailValid: action.data.newEmailValid,
                emailChange: action.data.newEmailChange
            });
        case actionTypes.MORTGAGE_REQUEST_UPDATE_PRIVACY_SUCCESS:
            return state.merge({
                privacyValid: action.data.privacyValid
            });
        default:
            return state
    }
};

module.exports = addEmail;
