import { call, put, takeLatest } from 'redux-saga/effects';
import { APIPost } from 'utilities/APIRequestHelper';
import apiURLBuilder from 'core/apiURLBuilder';
import {
    AUTHORIZATION_MODIFY_ACCOUNT_REQUEST,
    AUTHORIZATION_ACTIVATION
} from 'constants/actionTypes';
import {
    modifyAccountSuccess,
    modifyAccountFailure
} from 'actions/accountDebits/authorizationModifyAccount';
import {
    authorizationActivationFailure,
    authorizationActivationSuccess
} from 'actions/accountDebits/authorizationActivation';
import { todayDate } from 'utilities/dateHelper';


function* authorizationActivation({ newProduct, newContractNumber, authCode, authType, notice, noticeActivation, dataToken }) {
    try {
        const requestParams = {
            query: {
                claveAviso: {
                    codinternogau: authCode,
                    tipoautorizaciongau: {
                        codalfanum: authType
                    }
                },
                contrato: {
                    numerodecontrato: newContractNumber,
                    producto: newProduct
                },
                dataToken,
                fechaModif: todayDate(),
                indicadorDeudor: "DE",
                observaciones: "string",
                nivelAviso: notice,
                timestampClave: noticeActivation
            }
        }
        yield call(APIPost, null, apiURLBuilder.getURL('authorizationActivation'), requestParams);
        yield put(authorizationActivationSuccess());
    } catch (e) {
        yield put(authorizationActivationFailure(e.error));
    }
}

function* modifyAcount({ newProduct, newContractNumber, authCode, authType, dataToken, sourceAccountId, destinationAccountId }) {
   try {
        const requestParams = {
            query: {
                claveAutorizacion: {
                    codinternogau: authCode,
                    tipoautorizaciongau: {
                        codalfanum: authType
                    }
                },
                contrato: {
                    numerodecontrato: newContractNumber,
                    producto: newProduct
                },
                dataToken,
                origenFondo: "CTA"
            }
        }
        yield call(APIPost, null, apiURLBuilder.getURL('authorizationModifyAccount'), requestParams);
        yield put(modifyAccountSuccess(sourceAccountId, destinationAccountId));
    } catch (e) {
        yield put(modifyAccountFailure(e.error));
   }
}

function* authorizationsSagas() {
    yield [
        takeLatest(AUTHORIZATION_MODIFY_ACCOUNT_REQUEST, modifyAcount),
        takeLatest(AUTHORIZATION_ACTIVATION, authorizationActivation)
    ]
}

export default [
    authorizationsSagas
]