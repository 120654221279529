const {
    HIRE_CARD_OPEN_DEBIT_FAILURE,
    HIRE_CARD_OPEN_DEBIT_SUCCESS,
    HIRE_INFORMATION_FAILURE,
    HIRE_INFORMATION_OPEN_DEBIT_ERROR,
    HIRE_INFORMATION_OPEN_DEBIT_SUCCESS,
    HIRE_INFORMATION_SUCCESS,
    OK_WIZARD_GET_INFO_ERROR,
    OK_WIZARD_GET_INFO_SUCCESS,
} = require('constants/actionTypes')

const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE
} = require('utilities/contactCenter/wrapUp/constants');

const ACTION_CODE_HIRE_AT_HOME_ABROAD = 5182;
const ACTION_CODE_HIRE_DIAMOND_PACK = 5033;
const ACTION_CODE_HIRE_MINI_CARD = 5030;
const ACTION_CODE_HIRE_OPEN_CREDIT = 5028;
const ACTION_CODE_HIRE_OPEN_DEBIT = 5027;
const ACTION_CODE_HIRE_OPEN_YOUNG = 5031;
const ACTION_CODE_HIRE_PREMIUM_PACK = 5032;
const ACTION_CODE_HIRE_SOLIDARITY_DEBIT = 5180;
const ACTION_CODE_HIRE_VIA_T = 5029;
const ACTION_CODE_HIRE_VIRTUAL_CREDIT = 5245;
const ACTION_CODE_HIRE_VIRTUAL_ONLY_DEBIT = 5244;
const ACTION_CODE_HIRE_R42_METAL = 41002;
const ACTION_CODE_HIRE_MASTERCARD = 41003;
const ACTION_CODE_HIRE_R42_MAESTRO = 41004;
const ACTION_CODE_HIRE_R42_VANITY = 221111121;
const ACTION_CODE_HIRE_R42_NERUDA = 221111116;
const ACTION_CODE_HIRE_EH = 221111122;

module.exports = {
    [ACTION_CODE_HIRE_AT_HOME_ABROAD]: {
        entry: {
            path: '/myprofile/hire/card/at-home-abroad',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_DIAMOND_PACK]: {
        entry: {
            path: '/myprofile/hire/card/diamond-pack',
            failure: [
                { action: HIRE_INFORMATION_FAILURE  }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS  }
            ]
        }
    },
    [ACTION_CODE_HIRE_MINI_CARD]: {
        entry: {
            path: '/myprofile/hire/card/minicard',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_OPEN_CREDIT]: {
        entry: {
            path: '/myprofile/hire/card/open-credit',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_OPEN_DEBIT]: {
        entry: {
            action: [ HIRE_INFORMATION_OPEN_DEBIT_ERROR, HIRE_INFORMATION_OPEN_DEBIT_SUCCESS ],
            check: (action, state) => {
                let operationState = OPERATION_LOG_NONE;

                if (state.profile.get('originCountry') !== 'NL') {
                    operationState = OPERATION_LOG_FAIL;
                    if (action.type === HIRE_INFORMATION_OPEN_DEBIT_SUCCESS) {
                        operationState = OPERATION_LOG_DONE;
                    }
                }

                return {
                    actionCode: ACTION_CODE_HIRE_OPEN_DEBIT,
                    operationState,
                };
            }
        },
        finish: {
            action: [ HIRE_CARD_OPEN_DEBIT_FAILURE, HIRE_CARD_OPEN_DEBIT_SUCCESS ],
            check: (action, state) => {
                let operationState = OPERATION_LOG_NONE;

                if (state.profile.get('originCountry') !== 'NL') {
                    operationState = OPERATION_LOG_FAIL;
                    if (action.type === HIRE_CARD_OPEN_DEBIT_SUCCESS) {
                        operationState = OPERATION_LOG_DONE;
                    }
                }

                return {
                    actionCode: ACTION_CODE_HIRE_OPEN_DEBIT,
                    operationState,
                };
            }
        },
    },
    [ACTION_CODE_HIRE_MASTERCARD]: {
        entry: {
            action: [ HIRE_INFORMATION_OPEN_DEBIT_ERROR, HIRE_INFORMATION_OPEN_DEBIT_SUCCESS ],
            check: (action, state) => {
                let operationState = OPERATION_LOG_NONE;

                if (state.profile.get('originCountry') === 'NL') {
                    operationState = OPERATION_LOG_FAIL;
                    if (action.type === HIRE_INFORMATION_OPEN_DEBIT_SUCCESS) {
                        operationState = OPERATION_LOG_DONE;
                    }
                }

                return {
                    actionCode: ACTION_CODE_HIRE_MASTERCARD,
                    operationState,
                };
            }
        },
        finish: {
            action: [ HIRE_CARD_OPEN_DEBIT_FAILURE, HIRE_CARD_OPEN_DEBIT_SUCCESS ],
            check: (action, state) => {
                let operationState = OPERATION_LOG_NONE;

                if (state.profile.get('originCountry') === 'NL') {
                    operationState = OPERATION_LOG_FAIL;
                    if (action.type === HIRE_CARD_OPEN_DEBIT_SUCCESS) {
                        operationState = OPERATION_LOG_DONE;
                    }
                }

                return {
                    actionCode: ACTION_CODE_HIRE_MASTERCARD,
                    operationState,
                };
            }
        },
    },
    [ACTION_CODE_HIRE_R42_METAL]: {
        entry: {
            path: '/myprofile/hire/card/metallic',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_R42_MAESTRO]: {
        entry: {
            path: '/myprofile/hire/card/maestro',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_VIRTUAL_ONLY_DEBIT]: {
        entry: {
            path: '/myprofile/hire/card/virtual-only-debit',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_OPEN_YOUNG]: {
        entry: {
            path: '/myprofile/hire/card/open-young',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_PREMIUM_PACK]: {
        entry: {
            path: '/myprofile/hire/card/premium-pack',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_SOLIDARITY_DEBIT]: {
        entry: {
            path: '/myprofile/hire/card/solidarity',
            failure: [
                { action: OK_WIZARD_GET_INFO_ERROR }
            ],
            success: [
                { action: OK_WIZARD_GET_INFO_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_VIA_T]: {
        entry: {
            path: '/myprofile/hire/card/via-t',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_VIRTUAL_CREDIT]: {
        entry: {
            path: '/myprofile/hire/card/virtual-credit',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_R42_NERUDA]: {
        entry: {
            path: '/myprofile/hire/card/nrd-card',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_R42_VANITY]: {
        entry: {
            path: '/myprofile/hire/card/vanity-fair',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_HIRE_EH]: {
        entry: {
            path: '/myprofile/hire/card/eh-card',
            failure: [
                { action: HIRE_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRE_INFORMATION_SUCCESS }
            ]
        }
    },
}
