// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        isFetching: false,
        submitSuccessful: false,
        errorReceived: ''
    });
}

function debitReturnChangeReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.DEBIT_RETURN_CANCEL:
            return setInitialState();
        case actionTypes.DEBIT_RETURN_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.DEBIT_RETURN_SUCCESS:
            return state.merge(setInitialState(),{
                submitSuccessful: true
            });
        case actionTypes.DEBIT_RETURN_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                isFetching: false,
                submitSuccessful: false
            });
        default:
            return state
    }
}

module.exports = debitReturnChangeReducer;
