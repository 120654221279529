// @ vendors
const Immutable = require('immutable');
// @utilities
const get = require('lodash/object/get');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
const {EURO_TEXT} = require('constants/index');

const MAXIMUM_INSTALLMENTS = 36;

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        installmentsList: [],
        isFetching: false,
        error: '',
        installmentQuantity: 0,
        installmentMaximum: MAXIMUM_INSTALLMENTS,
        installmentChanged: false, //flag to determinate which label to show
        totalInterests: {
            amount: 0,
            currency: EURO_TEXT
        },
        extra: {},
        specificQuota: {
            amount: null,
            isFetching: false,
        },
        chosenDeferral: '',
        quotaMonthly: {
            amount: 0,
            currency: EURO_TEXT,
          },
        totalAmount: {
            amount: 0,
            currency: EURO_TEXT,
        },
    });
}

const parseInstallments = (installments = []) => {
    let list = [];

    installments.forEach(installment => {
        list.push({
            nextAmortizationDate: moment(installment.fechaProximaAmortizacion),
            amortizedCapital: {
                amount: get(installment, 'impCapitalAmortizado.importe', 0),
                currency: get(installment, 'impCapitalAmortizado.divisa', EURO_TEXT)
            },
            pendingCapital: {
                amount: get(installment, 'impCapitalPendiente.importe', 0),
                currency: get(installment, 'impCapitalPendiente.divisa', EURO_TEXT)
            },
            interests: {
                amount: get(installment, 'impIntereses.importe', 0),
                currency: get(installment, 'impIntereses.divisa', EURO_TEXT)
            },
            installmentTotal: {
                amount: get(installment, 'impTotalCuota.importe', 0),
                currency: get(installment, 'impTotalCuota.divisa', EURO_TEXT)
            },
        });
    });

    return {
        list,
        currency: get(installments, '0.impTotalCuota.divisa', EURO_TEXT)
    }
}

function cardPaymentPurchaseDeferralStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_PAYMENT_PURCHASE_SET_QUOTA_QUANTITY:
            return state.merge({
                installmentQuantity: action.payload.installmentQuantity,
                installmentChanged: true
            });
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_VALIDATE_STEP:
            return state.merge({
                valid: true
            });
        case actionTypes.CARD_REPAYMENT_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.CARD_REPAYMENT_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            })
        case actionTypes.CARD_PAYMENT_PURCHASE_SET_DEFERRAL_OPTION:
            return state.merge({
                chosenDeferral: action.payload.option
            })
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_AMOUNT: {
            const { amount } = action.payload;
            const values = state.get('specificQuota').merge({amount: amount, isFetching: false})
            return state.set('specificQuota', values)
        }
        case actionTypes.CARD_PAYMENT_PURCHASE_DEFERRAL_SPECIFIC_QUOTA_REQUEST: {
            return state.setIn(['specificQuota', 'isFetching'], true);
        }
        case actionTypes.CARD_REPAYMENT_SUCCESS:
            let resultsParsed = parseInstallments(get(action, 'payload.data.lista.dato'));
            let newState = state.set('installmentsList', Immutable.fromJS(resultsParsed.list))
            return newState.mergeDeep({
                isFetching: false,
                totalInterests: {
                    amount: get(action, 'payload.data.totalIntereses.importe', 0),
                    currency: get(action, 'payload.data.totalIntereses.divisa', EURO_TEXT),
                },
                quotaMonthly: {
                    amount: get(action, 'payload.data.cuotaMensual.importe', 0),
                    currency: get(action, 'payload.data.cuotaMensual.divisa', EURO_TEXT),
                },
                totalAmount: {
                    amount: get(action, 'payload.data.importeTotal.importe', 0),
                    currency: get(action, 'payload.data.importeTotal.divisa', EURO_TEXT),
                },
                extra: {
                    tipLiqPeriodosJTIPOLIQ: action.payload.data.tipLiqPeriodosJTIPOLIQ,
                    modalidadFormaDePagoJMODALID: action.payload.data.modalidadFormaDePagoJMODALID
                }
            })
        default:
            return state;
    }
}

module.exports = cardPaymentPurchaseDeferralStep1;
