// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        challenge: '',
        inProgress: true,
        isFetching: false,
        otpStep: false,
        showConfirmButton: true,
        isInputDisabled: false,
        success: false,
        error: false
        }
    );
}

function eCommerceKeyModifyReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ECOMMERCE_MODIFY_KEY_RESET:
            return setInitialState();
        case actionTypes.ECOMMERCE_MODIFY_KEY_CONFIRM_STEP:
            return state.merge({
                otpStep: true,
                showConfirmButton: false,
                isInputDisabled: true,
                inProgress: false
            });
        case actionTypes.SET_ECOMMERCE_KEY:
            return state.merge({
                challenge: action.payload
            })
        case actionTypes.FETCH_ECOMMERCE_MODIFY_KEY_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_ECOMMERCE_MODIFY_KEY_SUCCESS:
            return state.merge(setInitialState(), {
                success: true,
            });
        case actionTypes.FETCH_ECOMMERCE_MODIFY_KEY_FAILURE:
            return state.merge({
                error: true,
                isFetching: false,
                success: false,
            });
        default:
            return state;
    }
}

module.exports = eCommerceKeyModifyReducer;
