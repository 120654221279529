// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        newAccount: '',
        valid: false,
        newAccountIsDirty: false
    });
}

function setAccount(step, action) {
    return step.merge({
        newAccount: action.payload.account,
        newAccountIsDirty: false
    });
}

function validateStep(step) {
    return step.merge({
        valid: !!step.get('newAccount') && step.get('newAccount') !== '',
        newAccountIsDirty: true
    });
}

function accountDebitsEditStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_DEBITS_EDIT_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.ACCOUNT_DEBITS_EDIT_SET_ACCOUNT:
            return setAccount(state,action);
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_SUCCESS:
            return state.merge({
                valid: true,
                newAccountIsDirty: true
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_FAILURE:
            return state.merge({
                valid: false,
                newAccountIsDirty: true
            });
        case actionTypes.SET_ACCOUNT_DEBITS_EDIT_SUCCESS:
            return state.merge(setInitialState(),{
                newAccount: action.payload.accountId
            });
        default:
            return state;
    }
}

module.exports = accountDebitsEditStep1;
