// @vendors
const Immutable = require('immutable');

// @constants
const {
    BROKER_ALL_INDEXES_FETCHING,
    BROKER_ALL_INDEXES_FETCHING_SUCCESS,
    BROKER_ALL_INDEXES_FETCHING_FAILURE
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    results: [],
    isFetching: false,
    error: null
});

function brokerAllIndexesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_ALL_INDEXES_FETCHING:
            return INITIAL_STATE.set('isFetching', true);
        case BROKER_ALL_INDEXES_FETCHING_SUCCESS:
            return state.merge({
                isFetching: false,
                results: action.payload.data
            });
        case BROKER_ALL_INDEXES_FETCHING_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = brokerAllIndexesReducer;
