// @ vendors
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_SET_VISIBLE_STEP,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_VALIDATE_STEP,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_WILL_CANCEL,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_ACCOUNT,
    MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_REQUEST,
    MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_SUCCESS,
    MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_FAILURE,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_TOTAL_COST,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_REQUEST,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_SUCCESS,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_FAILURE,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_CLEAR
} = require('constants/actionTypes');

const { FIRST_STEP, SECOND_STEP } = require('constants/index');
// @ reducers
const simpleNoteRequestStep1 = require('./mortgageRequestSimpleNoteRequest/mortgageRequestSimpleNoteRequestStep1');
const simpleNoteRequestStep2 = require('./mortgageRequestSimpleNoteRequest/mortgageRequestSimpleNoteRequestStep2');

const setInitialState = () => {
    return Immutable.Map().merge({
        steps: [
            simpleNoteRequestStep1(undefined, { type: null }),
            simpleNoteRequestStep2(undefined, { type: null }),
            {hasSignaturePattern: true}
        ],
        reimburseFunds: Immutable.fromJS({}),
        errorReceived: false,
        isFetching: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false
    });
};

const updateStep1 = (steps, action) => {
    return steps.update(FIRST_STEP, step => {
        return simpleNoteRequestStep1(step, action);
    });
};

const updateStep2 = (steps, action) => {
    return steps.update(SECOND_STEP, step => {
        return simpleNoteRequestStep2(step, action);
    });
};

const simpleNoteRequest = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.data.step
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_WILL_CANCEL:
            return state.merge({
                willLeave: true
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_VALIDATE_STEP:
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_REQUEST:
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_SUCCESS:
            visibleStep = 0;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = partialSteps.getIn([0, 'valid']) ? 2 : 1;
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = partialSteps.getIn([1, 'valid']) ? 3 : 2;
                    break;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_FAILURE:
            partialSteps = updateStep2(state.get('steps'), action);
            visibleStep = partialSteps.getIn([1, 'valid']) ? 3 : 2;
            return state.merge({
                steps: partialSteps,
                visibleStep,
                errorReceived: true
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_ACCOUNT:
            return state.merge({
                steps: updateStep2(state.get('steps'), action)
            });
        case MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_REQUEST:
            return state.merge({
                isFetching: true,
            })
        case MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isFetching: false,
            });
        case MORTGAGE_REQUEST_SIMPLE_NOTE_SIGNATURE_FAILURE:
            return state.merge({
                errorReceived: true,
                isFetching: false,
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_TOTAL_COST:
            return state.merge({
                steps: updateStep2(state.get('steps'), action)
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_CLEAR:
            return setInitialState();
        default:
            return state;
    }
};

module.exports = simpleNoteRequest;
