const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState(){
    return Immutable.Map({
        isOpen: false
   });
}

function brokerNoStockAccountModal(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_NO_STOCK_ACCOUNT_MODAL_SHOW:
            return state.merge({
                isOpen: true
            });
        case actionTypes.BROKER_NO_STOCK_ACCOUNT_MODAL_HIDE:
            return state.merge({
                isOpen: false
            });
        default:
            return state
    }
}

module.exports = brokerNoStockAccountModal;
