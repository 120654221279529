// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    valid: false,
    loading: false,
    acceptedManifest: false,
    errorFlags: {
        manifestNotChecked: false
    },
    showWarning: false,
    allDocsRead: false
});

const validate = (immState) => {
    const acceptedManifest = immState.get('acceptedManifest');
    return immState.set('valid', acceptedManifest)
                   .setIn(['errorFlags', 'manifestNotChecked'], !acceptedManifest);
};

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
};

function toggleDisclaimer(step) {
    if (step.get('allDocsRead')) {
        return step.set('acceptedManifest', !step.get('acceptedManifest'))
            .set('valid', true)
            .set('showWarning', false);
    }
    else {
        return step.set('showWarning', true);
    }
}

function brokerStocksPurchaseOrderStep2Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_CHANGE_MANIFEST:
            return toggleDisclaimer(state);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_VALIDATE_STEP:
            return validate(state);
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_CONFIRM_FAILURE:
            return state.merge({
                loading: false
            })
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_FINISH:
            return state.merge({
                loading: false
            });
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_LOADING:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_STOCKS_PURCHASE_ORDER_DOCUMENTS_READ:
            return setDocumentsRead(state);
        default:
            return state;
    }
}

module.exports = brokerStocksPurchaseOrderStep2Reducer;
