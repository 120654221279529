// @ vendor
const Immutable = require('immutable');

// @helpers
const { formatText } = require('core/i18n').i18n;
const { formatAmount } = require('utilities/currencyHelper');

// @ constants
const actionTypes = require('constants/actionTypes');
const {EURO_TEXT} = require('constants/index');


function setInitialState() {
    return Immutable.Map({
        valid: false,
        fromAccountIsDirty: false,
        amountIsDirty: false,
        amount: 0,
        fromAccount: '',
        fromAccountBalance: 0,
        currency: EURO_TEXT,
        totalContractBalanceDrawnAmount: 0,
        creditCardLimit: 0,
        errorMessage: ""
    });
}

const errorMessages = (step) => {
    const creditCardLimit = step.get('creditCardLimit');
    const amount = step.get('amount');
    const validAmount = amount > 0;
    const enoughBalance = amount <= step.get('fromAccountBalance');
    const totalContractBalanceDrawnAmount = step.get('totalContractBalanceDrawnAmount');
    const totalContractBalanceDrawn = totalContractBalanceDrawnAmount > 0;
    
    let error = null;
    if (!validAmount) {
        error = formatText('cardDepositMoney-validAmountError');
    } else if (!enoughBalance) {
        error = formatText('cardDepositMoney-enoughBalanceError');
    } else if (validAmount && enoughBalance && !totalContractBalanceDrawn && creditCardLimit <= 1500 && amount > (3000 - amount)) {
        error = formatText('cardDepositMoney-errorMinor');
    } else if (validAmount && enoughBalance && !totalContractBalanceDrawn && creditCardLimit > 1500 && amount > (creditCardLimit * 2)) {
        error = formatText('cardDepositMoney-errorMajor', [creditCardLimit * 2]);
    } else if (totalContractBalanceDrawn && validAmount && enoughBalance && amount > (creditCardLimit + totalContractBalanceDrawnAmount)) {
        error = formatText('cardDepositMoney-errortotalContractBalanceDrawn', [formatAmount((creditCardLimit * 2) - totalContractBalanceDrawnAmount)]);
    } else if (validAmount && enoughBalance && totalContractBalanceDrawn && creditCardLimit <= 1500 && amount > (3000 - amount + totalContractBalanceDrawnAmount)) {
        error = formatText('cardDepositMoney-errorMinorContractBalanceDrawn', [formatAmount((3000 + totalContractBalanceDrawnAmount) / 2 )]);
    }
    return error;
}

function validateStep(step) {
    const creditCardLimit = step.get('creditCardLimit');
    const validFromAccount = !!step.get('fromAccount');
    const error = errorMessages(step);

    return step.merge({
        valid: validFromAccount && error === null,
        fromAccountIsDirty: !validFromAccount,
        amountIsDirty: error,
        creditCardLimit, 
        errorMessage: error
    });
}

function cardDepositMoneyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_DEPOSIT_MONEY_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_DEPOSIT_MONEY_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount
            })
        case actionTypes.CARD_DEPOSIT_MONEY_SET_ACCOUNT:
            return state.merge({
                fromAccount: action.payload.fromAccount,
                fromAccountBalance: action.payload.fromAccountBalance
            })
        case actionTypes.CARD_DEPOSIT_MONEY_SET_CREDIT_CARD_DATA:
            return state.merge({
                totalContractBalanceDrawnAmount: action.payload.totalContractBalanceDrawnAmount,
                creditCardLimit: action.payload.creditCardLimit
            });
        default:
            return state;
    }
}

module.exports = cardDepositMoneyStep1;
