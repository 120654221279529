// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, LOANS_MORTGAGES_INVESTMENT_CREDIT } = require('constants/index');
const { es:{ DATE_FORMAT_WITH_DASHES } } = require('commonsConstants/dateFormatTypes');
// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');
const DateHelper = require('utilities/dateHelper');

const initialState = Immutable.fromJS({
    isFetching: false,
    byId: {},
    byOrder: [],
    error: '',
    interveners: {},
    generalData: {},
});

const prepareDate = (account, key) => {
    const date = get(account, `balances.${key}`);
    const dateWrapped = moment(date, DATE_FORMAT_WITH_DASHES);
    return DateHelper.formatDate(dateWrapped);
};

/*
* Keep in mind that 'Investment Credits' are treated as a kind of account.
*/

const parseInvestmentCreditsList = (state, accountsList) => {
    const accounts = get(accountsList, 'payload.datosSalidaCuentas.cuentas', []);

    let byId = {};
    let byOrder = [];

    accounts.forEach( account => {
        const isInvestmentCredit = trim(get(account, 'cnuevo.producto')) === '100';

        if (isInvestmentCredit) {
            const balances = account.saldosCuenta;
            if (!balances){
                return;
            }
            const investmentCreditLimitAmount = (get(balances, 'importeDispuesto.importe') || 0) - (get(balances, 'importeSaldo.importe') || 0) + (get(balances, 'importeRetenido.importe') || 0);

            const ibanObject = {
                country: account.ibanComplex.pais,
                controlDigit: account.ibanComplex.digitodecontrol,
                codbban: account.ibanComplex.codbban
            };

            const id = generateAccountId(ibanObject);
            const accountId = id;
            const balance = get(account, 'balance.amount', 0) || 0;
            const currency = get(account, 'balance.currency', EURO_TEXT) || EURO_TEXT;
            const alias = account.descripcion;
            const iban = account.iban;
            const openingDate = prepareDate(account, 'fechaUltimaImpresionLimite');
            const expirationDate = prepareDate(account, 'fechaVencimLimite');
            const product = get(account, 'cnuevo.producto', 0) || 0;
            const contractNumber = get(account, 'cnuevo.numerodecontrato', 0) || 0;
            const controlDigit = get(account, 'ibanComplex.digitodecontrol', 0) || 0;
            const situationDate = DateHelper.formatDate(get(account, 'criterios.c2.fecsit', ''));

            let available;

            if (balance > 0 ) {
                available = {
                    amount: investmentCreditLimitAmount,
                    currency
                };
            } else {
                available = {
                    amount: Math.max(investmentCreditLimitAmount + balance, 0),
                    currency
                };
            }
            const disposedAmount = investmentCreditLimitAmount - available.amount;
            const disposed = {
                amount : disposedAmount,
                currency
            };
            const limit = {
                amount: investmentCreditLimitAmount,
                currency
            };
            const creditSituation = {
                error: '',
                isFetching: false,
                fetchSuccess: false
            };
            const oldContract = {
                subgroup: get(account, 'cviejo.subgrupo'),
                contractNumber: get(account, 'cviejo.numerodecontrato')
            }

            byId[id] = {
                id,
                accountId,
                alias,
                openingDate,
                expirationDate,
                limit,
                available,
                disposed,
                iban,
                product,
                contractNumber,
                controlDigit,
                situationDate,
                type: LOANS_MORTGAGES_INVESTMENT_CREDIT,
                creditSituation,
                oldContract
            };

            byOrder.push(id);
        }
    })

    return state
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder));
}

function updateCreditSituation(payload) {
    return {
        byId: {
            [payload.id]: {
                creditSituation: {
                    guaranteePercentage: get(payload.data, 'porcentajeGarantia', 0) || 0,
                    investmentsValuation: {
                        amount: get(payload.data, 'importeUltimaValoracion.importe', 0) || 0,
                        currency: get(payload.data, 'importeUltimaValoracion.divisa', EURO_TEXT)
                    },
                    executionLimit: {
                        amount: get(payload.data, 'limiteEjecucion.importe', 0) || 0,
                        currency: get(payload.data, 'limiteEjecucion.divisa', EURO_TEXT)
                    },
                    guaranteeExcess: {
                        amount: get(payload.data, 'importeExcesoGarantia.importe', 0) || 0,
                        currency: get(payload.data, 'importeExcesoGarantia.divisa', EURO_TEXT)
                    },
                    maximumTransfer: {
                        amount: get(payload.data, 'importeMaximoTraspaso.importe', 0) || 0,
                        currency: get(payload.data, 'importeMaximoTraspaso.divisa', EURO_TEXT)
                    },
                    error: '',
                    isFetching: false,
                    fetchSuccess: true
                },
            }
        }
    };
}

function creditsInvestmentReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            return parseInvestmentCreditsList(state, action);
        case actionTypes.FETCH_CREDIT_SITUATION_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.id]: {
                        creditSituation: {
                            isFetching: true
                        }
                    }
                }
            });
        case actionTypes.FETCH_CREDIT_SITUATION_SUCCESS:
            return state.mergeDeep(
                updateCreditSituation(action.payload)
            );
        case actionTypes.FETCH_CREDIT_SITUATION_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.id]: {
                        creditSituation: {
                            error: action.payload.error,
                            isFetching: false,
                            fetchSuccess: false
                        }
                    }
                }
            });
        default:
            return state;
    }
}

module.exports = creditsInvestmentReducer;
