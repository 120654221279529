const mockHelper = require('../utilities/mockDataHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
//TODO: Try to make template (hmtl) for the strHTML.
const AMOUNT_FOR_MINIMAL_COMMISSION = 4000;
const MINIMAL_COMMISSION = 80;

function calculateCommission(value) {
    return value <= AMOUNT_FOR_MINIMAL_COMMISSION ? MINIMAL_COMMISSION : (value*2)/100;
}

function onChangeSliderMoneyNeeded(value,component,actualState){
    let ret=[];
    ret.push({
        target: "i_solicitado",
        value: value
    },
    {
        target: "c_mensual",
        value: Math.round(( (value * ( 1 + actualState.filter(x=>x.id==="t_interes")[0].value / 100) + (value*2)/100 ) /  actualState.filter(x=>x.id==="meses_seleccionado")[0].value) * 100) / 100
    },
    {
        target: "c_apertura",
        value: calculateCommission(value)
    },
    {
        target: "i_prestamo",
        value: value*1.02
    },
    {
        target: "a_devolver", //"30 cuotas de 221,5 €"
        value: actualState.filter(x=>x.id==="meses_seleccionado")[0].value+" cuotas de "+Math.round(( (value * ( 1 + actualState.filter(x=>x.id==="t_interes")[0].value / 100) + (value*2)/100 ) /  actualState.filter(x=>x.id==="meses_seleccionado")[0].value) * 100) / 100+" €"
    }
    );
    return ret;
}



function onChangeSliderMonthsNeeded(value,component,actualState){
    let ret=[];
    ret.push(
    {
        target: "c_mensual",
        value: Math.round(( (actualState.filter(x=>x.id==="i_solicitado")[0].value * ( 1 + actualState.filter(x=>x.id==="t_interes")[0].value / 100) + (actualState.filter(x=>x.id==="i_solicitado")[0].value*2)/100 ) /  value) * 100) / 100
    },
    {
        target: "meses_seleccionado",
        value: value
    },
    {
        target: "a_devolver", //"30 cuotas de 221,5 €"
        value:  value+" cuotas de "+Math.round(( (actualState.filter(x=>x.id==="i_solicitado")[0].value * ( 1 + actualState.filter(x=>x.id==="t_interes")[0].value / 100) + (actualState.filter(x=>x.id==="i_solicitado")[0].value*2)/100 ) /  value) * 100) / 100+" €"
    }
    );
    return ret;
}

function loadCmbPurpose(){
    let options=[];
    options.push({
        value: 1,
        label: "Reforma de vivienda"
    });
    options.push({
        value: 2,
        label: "Compra de vehículo"
    });
    options.push({
        value: 3,
        label: "Elmentos de Hogar"
    });
    options.push({
        value: 4,
        label: "Estudios"
    });
    options.push({
        value: 5,
        label: "Sanidad"
    });
    return options;
}

function loadCmbAccounts(){
    return mockHelper.mockCmbAccounts();
}

function onChangeDropdownAccounts(accountId){
    return mockHelper.mockOnChangeDropdownAccounts(accountId);
}

function onChangeCmbPurpose(cmbSelected){
    let ret=[];
    ret.push(
    {
        target: "finalidad_seleccionado",
        value: cmbSelected.value
    }
    );
    return ret;
}

//TODO: Move to a library file -- utilities folder
function updateValue(card_details,id, errorValue){
    card_details = card_details.update(
      card_details.findIndex(function(item) {
        return item.get("id") === id;
      }), function(item) {
        return item.set("isErrorVisible", errorValue);
      }
    );
    return card_details;
}

function validateStep1(card_details){
    let ret={
        card_details:card_details,
        isValidStep: true
    };

    return ret;
}

function validateStep2(card_details){
    const MINIMUM_AMOUNT = 600;
    const MAXIMUM_AMOUNT = 18000;
    const MINIMUM_MONTHS_RETURN = 3;
    const MAXIMUM_MONTHS_RETURN = 60;
    const LOAN_AMOUNT = findElementbyId(card_details, 'i_solicitado');
    const MONTHS_RETURN = findElementbyId(card_details, 'meses_seleccionado');

    const IS_PURPOSE_VALID = findElementbyId(card_details, 'finalidad_seleccionado') !== 0;
    const IS_VALID_AMOUNT = LOAN_AMOUNT >= MINIMUM_AMOUNT && LOAN_AMOUNT <= MAXIMUM_AMOUNT;
    const IS_VALID_MONTH_RETURN = MONTHS_RETURN >= MINIMUM_MONTHS_RETURN && MONTHS_RETURN <= MAXIMUM_MONTHS_RETURN;

    let ret={
        card_details:card_details,
        isValidStep: IS_PURPOSE_VALID && IS_VALID_AMOUNT && IS_VALID_MONTH_RETURN
    };
    card_details.find(x => x.id === '');

    card_details=updateValue(card_details,'finalidad_seleccionado',true);

    ret.card_details = card_details;

    return ret;
}

function validateStep3(card_details){
    const IS_ACCOUNT_VALID = card_details.find(x => x.get('id') === 'cta_seleccionada').get("value") !== null;

    let ret={
        card_details:card_details,
        isValidStep: IS_ACCOUNT_VALID
    };

    card_details=updateValue(card_details,'cta_seleccionada',true);

    ret.card_details = card_details;

    return ret;
}

function validateStep4(card_details){
    let ret={
        card_details:card_details,
        isValidStep: true
    };

    return ret;
}

function pre_approved_loan_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');

    switch (step) {
        case 1:
        return validateStep1(immCardDetails)

        case 2:
        return validateStep2(immCardDetails)

        case 3:
        return validateStep3(immCardDetails)

        case 4:
        return validateStep4(immCardDetails)
    }
}

function pre_approved_loan_SignParams(){
    let ret;
    return ret;
}

function pre_approved_loan_HireParams(){
    let ret;
    return ret;
}

module.exports = {
    onChangeSliderMoneyNeeded: onChangeSliderMoneyNeeded,
    onChangeSliderMonthsNeeded: onChangeSliderMonthsNeeded,
    loadCmbPurpose: loadCmbPurpose,
    loadCmbAccounts: loadCmbAccounts,
    onChangeDropdownAccounts: onChangeDropdownAccounts,
    onChangeCmbPurpose: onChangeCmbPurpose,
    pre_approved_loan_validateStep: pre_approved_loan_validateStep,
    pre_approved_loan_SignParams: pre_approved_loan_SignParams,
    pre_approved_loan_HireParams: pre_approved_loan_HireParams
};
