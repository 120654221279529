//@vendors
const Immutable = require('immutable');

//@helpers
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
   retrievedList: []
});


function ngoRetrieveList(state = initialState, action) {
    switch (action.type) {
        case actionTypes.NGO_FOUNDATION_LIST_SUCCESS:
            return state.merge({
                retrievedList: action.payload.data
            });
        case actionTypes.NGO_FOUNDATION_LIST_ERROR:
            return state.merge({
                hasBeenFetched: true,
                inProgress: false,
            });
        default:
            return state
    }
}

module.exports = ngoRetrieveList;
