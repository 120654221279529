// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const contactCenterActionTypes = require('constants/contactCenter/actionTypes')

const SELECTED_VALUES = require('constants/contactCenter/brokerLegalConfirmationSelectedValues');

function setInitialState() {
    return Immutable.Map().merge({
        hasSignaturePattern: true,
        valid: false,
        readChecked: !__CONTACT_CENTER__ ? false : true,
        ...(__CONTACT_CENTER__ ? { mailSent: false, legalInfoChecked: SELECTED_VALUES.EMPTY } : {}),
        checkedIsValid: true,
        showWarning: false,
        allDocsRead: false
    });
}

function validateStep(step) {
    if(!__CONTACT_CENTER__) {
        return step.merge({
            valid: !!step.get('readChecked'),
            checkedIsValid: !!step.get('readChecked')
        });
    }

    const valid = step.get('legalInfoChecked') === SELECTED_VALUES.YES && step.get('mailSent');
    return step.merge({
        valid: valid,
        checkedIsValid: valid
    });
}

const toggleManifestCheckbox = (immState) => {
    if (immState.get('allDocsRead')) {
        return immState.set('readChecked', !immState.get('readChecked'))
            .set('checkedIsValid', true)
            .set('showWarning', false);
    }
    else {
        return immState.set('showWarning', true);
    }
};

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
}

function externalTransferFundStep4(state = setInitialState(), action) {

    switch (action.type) {
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_CHECKBOX:
            return toggleManifestCheckbox(state);
        case contactCenterActionTypes.BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND:
            return state.merge({
                legalInfoChecked: action.payload.value
            });
        case contactCenterActionTypes.PRE_CONTRACTUAL_MAIL_SENT_SUCCESS:
            return state.merge({
                mailSent: true
            });
        case contactCenterActionTypes.PRE_CONTRACTUAL_MAIL_SENT_FAILURE:
            return state.merge({
                mailSent: false
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_DOCUMENTS_READ:
            return setDocumentsRead(state);
        case contactCenterActionTypes.BROKER_LEGAL_INFORMATION_CONF_EXTERNAL_FUND_RESET:
            return setInitialState();
    }
    return state;
}

module.exports = externalTransferFundStep4;
