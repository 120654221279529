const get = require('lodash/object/get');

function brokerWarrantAdapter(productData) {
    return {
        underlyingName: productData.underlyingInstrument.trim(),
        issuerName: productData.issuerName.trim(),
        activeType: productData.productDescription.trim(),
        warrantType: productData.typeName.trim(),
        expirationDate: productData.madurityDate,
        excercisePrice: productData.strikePrice,
        marketplace: productData.exchangeName.trim().replace('Mercado ', ''),
        demandVolume: productData.bidSize1,
        demandPrice: productData.bid1,
        offerPrice: productData.ask1,
        offerVolume: productData.askSize1,
        exchangeId: productData.exchangeId,
        spcSymbol: productData.spcSymbol,
        instrumentId: productData.instrumentId.trim(),
        micCode: productData.micCode.trim(),
        underlying: {
            last: get(productData, 'subyacente.last'),
            variationPerc: get(productData, 'subyacente.netChangePercentage'),
            max: get(productData, 'subyacente.high'),
            min: get(productData, 'subyacente.low'),
            open: get(productData, 'subyacente.open'),
            date: get(productData, 'subyacente.updateDate'),
            time: get(productData, 'subyacente.updateTime'),
            exchangeId: get(productData, 'subyacente.exchangeId'),
            spcSymbol: get(productData, 'subyacente.spcSymbol'),
        }
    }
}

module.exports = brokerWarrantAdapter;
