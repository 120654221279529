const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');

const cardChangePaymentMethodStep1 = require('./cardChangePaymentMethod/cardChangePaymentMethodStep1');
const cardChangePaymentMethodStep2 = require('./cardChangePaymentMethod/cardChangePaymentMethodStep2');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            cardChangePaymentMethodStep1(undefined, {type: null}),
            cardChangePaymentMethodStep2(undefined, {type: null})
        ],
        visibleStep: 1,
        signatureKey: {
            referenceNumber: '',
            digits: []
        },
        newSMSCodeRequested: false,
        signatureKeyFailure: false,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: '',
        signatureKeyAttempt: 1,
        signatureKeyLocked: false,
        isDisabledButton: false,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardChangePaymentMethodStep1(step, action);
            case SECOND_STEP:
                return cardChangePaymentMethodStep2(step, action);
        }
    })
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function cardChangePaymentMethodReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_REQUEST:
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
            });
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_VALIDATE_STEP:
            let visibleStep;
            const currentStep = action.payload.step;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_FAILURE:
            return state.merge({
                error: action.payload.error,
                inProgress: false,
                isFetching: false
            });
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_METHOD:
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_INSTALLMENTS:
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true,
                isFetching: false
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_CLEAR:
            return setInitialState();
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                signatureKeyFailure: false,
                errorReceived: '',
                inProgress: false
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SET_DISABLED_BUTTON:
            return state.merge({
                isDisabledButton: action.payload.value,
            });
        default:
            return state
    }
}

module.exports = cardChangePaymentMethodReducer;
