const { formatText } = require('core/i18n').i18n;
const _ = require('underscore');

function typeCardReturnString(typeCard) {
    let type;
    switch (typeCard) {
      case formatText('urlGobalPosition-debitAccount'):
        type = 'debit';
        break;
      case formatText('urlGobalPosition-creditAccount'):
        type = 'credit';
        break;
      case 'credit':
        type = 'credit';
        break;
      case 'debit':
        type = 'debit';
        break;
      case '0':
        type = 'debit';
        break;
      case '1':
        type = 'credit';
        break;
      default:
        type = 'both';
    }
    return(type);
}

function typeAccountReturnString(typeAccount) {
    let type;
    switch (typeAccount) {
      case formatText('urlGobalPosition-customAccounts'):
        type = 'custom';
        break;
      case 'custom':
        type = 'custom';
        break;
      default:
        type = 'all';
    }
    return(type);
}

function typeCardReturnNumber(typeCard) {
    let type;
    switch (typeCard) {
      case formatText('urlGobalPosition-debitAccount'):
        type = '0';
        break;
      case formatText('urlGobalPosition-creditAccount'):
        type = '1';
        break;
      case "0":
        type = '0';
        break;
      case "1":
        type = '1';
        break;
      case "3":
        type = '3';
        break;
      default:
        type = '2';
    }
    return type;
}

function getAmountCreditCardsHistogram(dataMonth, hashCards) {
    let totalAmount = 0;
    let isCreditCard = false;
    if (dataMonth.subgroupMap[0] !== undefined) {
        _.each(dataMonth.subgroupMap[0].subgroupMap, function(data) {
            isCreditCard = hashCards.indexOf(data.value) !== -1;
            totalAmount += isCreditCard ? Math.abs(data.total) : 0;
        });
    }

    if (dataMonth.subgroupMap[1] !== undefined) {
        _.each(dataMonth.subgroupMap[1].subgroupMap, function(data) {
            isCreditCard = hashCards.indexOf(data.value) !== -1;
            totalAmount += isCreditCard ? Math.abs(data.total) : 0;
        });
    }
    return (totalAmount);
}

function getAmountDebitAccountsHistogram(dataMonth, hashCards) {
    let totalAmount = 0;
    let isCreditCard = false;
    if (dataMonth.subgroupMap[0] !== undefined) {
        _.each(dataMonth.subgroupMap[0].subgroupMap, function(data) {
            isCreditCard = hashCards.indexOf(data.value) !== -1;
            totalAmount += !isCreditCard ? Math.abs(data.total) : 0;
        });
    }

    if (dataMonth.subgroupMap[1] !== undefined) {
        _.each(dataMonth.subgroupMap[1].subgroupMap, function(data) {
            isCreditCard = hashCards.indexOf(data.value) !== -1;
            totalAmount += !isCreditCard ? Math.abs(data.total) : 0;
        });
    }
    return(totalAmount);
}

module.exports = {
    typeCardReturnString,
    typeAccountReturnString,
    typeCardReturnNumber,
    getAmountCreditCardsHistogram,
    getAmountDebitAccountsHistogram
};
