// @vendors
const Immutable = require('immutable');
// @ utilities
const get = require('lodash/object/get');
// @helpers
const actionTypes = require('constants/actionTypes');
const { getNerudaBonification } = require('utilities/cardsHelper');
// @constants
const { EURO_TEXT } = require('constants/index');

function setInitialState(){
    return Immutable.fromJS({
        bonification: {
            level: '',
            status: '',
        },
        byId: {},
        byOrder: [],
        error: false,
        loading: false,
        paymentAccount: {
            bank: '-',
            number: '',
        },
        totalAmount: {
            amount: 0,
            currency: '',
        },
   });
}

function parseRewards(rewards) {
    let byId = {};
    let byOrder = [];

    rewards.map( (reward, index) => {
        const parsedReward = {
            id: 'index'+index,
            amount: {
                amount: get(reward.amount, 'amount', 0),
                currency: get(reward.amount, 'currency', EURO_TEXT),
            },
            date: reward.date,
            description: reward.description,
            paymentAccount: {
                bank: reward.bankName,
                number: reward.accountIban,
            },
        };

        byId[parsedReward.id] = parsedReward;
        byOrder.push(parsedReward.id);
    });

    return {
        byOrder: byOrder,
        byId: byId
    };
}

function cardRewards(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_CARD_REWARDS_REQUEST:
            return state.merge({
                loading: true,
                error: false,
            });
        case actionTypes.FETCH_CARD_REWARDS_SUCCESS:
            const data = action.payload.data;
            const immCard = action.payload.immCard;
            const parsedRewards = parseRewards(data.rewards);
            return state.merge({
                byOrder: parsedRewards.byOrder,
                byId: parsedRewards.byId,
                loading: false,
                error: false,
                paymentAccount: {
                    bank : data.bankName || '-',
                    number: data.accountIban || '',
                },
                bonification: getNerudaBonification(immCard.getIn(['product', 'standard'])),
                totalAmount: data.totalAmount || 0,
            });
        case actionTypes.FETCH_CARD_REWARDS_FAILURE:
            return state.merge({
                loading: false,
                error: action.payload.error
            });
        case actionTypes.FETCH_CARD_REWARDS_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = cardRewards;
