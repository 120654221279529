const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const expenses = require('./globalPosition/expenses');
const savings = require('./globalPosition/savings');
const get = require('lodash/object/get');

function setInitialState(){
    return Immutable.fromJS({
       expenses: expenses(undefined, {type: null}),
       savings: savings(undefined, {type: null}),
       isFetching: false,
       hasBeenFetched: false, // With this flag we should avoid multiple calls to global position
       error: '',
       isClientWithoutAccounts: false,
       isOutdated: false,
       wealthDataFF:{},
       wealthErrorFF:false,
       isWealthDataFetching:false,
       redirectionFlag: ''
   });
}
function buildWealthRoboData(wealthData) {
    return {
    'total' :wealthData.total,
    'weInvestForYouTotal' : wealthData.weInvestForYouTotal,
    'youInvestTotal' : wealthData.youInvestTotal,
    }
}

function globalPosition(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_REQUEST:
             return state.merge({
                isFetching: true
             });
        case actionTypes.SET_WAFER_ID:
             return state.merge({
                redirectionFlag: action.payload
             });
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            const accounts = get(action, 'payload.datosSalidaCuentas.cuentas', []);
            const cards = get(action, 'payload.datosSalidaTarjetas.tarjetas', []);
            const isClientWithoutAccounts = Array.isArray(accounts) && accounts.length === 0 && Array.isArray(cards) && cards.length === 0;
            return state.mergeDeep({
                savings: savings(state.get('savings'), {
                    type: action.type,
                    payload: get(action, 'payload.datosSalidaDepositos.depositos', [])
                }),
                isClientWithoutAccounts,
                hasBeenFetched: true
            });
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_SAVING_SUCCESS:
            const postState =
            state.set('savings', savings(state.get('savings'), {
                    type: action.type,
                    payload: action.payload
                })
            );
            return postState;
        case actionTypes.ALL_PUBLIC_INFORMATION_LOADED_SUCCCESS:
            return state.merge({
                isFetching: false,
                isOutdated: false
            });
        case actionTypes.GLOBAL_POSITION_REQUEST_FAILURE:
            return state.merge({
                error: action.payload.error.error,
                errorMessage: action.payload.error.description,
                isFetching: false
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST:
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST_SUCCESS:
        case actionTypes.GLOBAL_POSITION_EXPENSES_REQUEST_FAILURE:
        case actionTypes.GLOBAL_POSITION_EXPENSES_DETAIL_REQUEST:
        case actionTypes.GLOBAL_POSITION_EXPENSES_DETAIL_SUCCESS:
        case actionTypes.GLOBAL_POSITION_EXPENSES_DETAIL_FAILURE:
        case actionTypes.GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS:
        case actionTypes.GLOBAL_POSITION_EXPENSES_UPDATE_EXPENSES:
        case actionTypes.GLOBAL_POSITION_CATEGORIES_EXPENSES_RESET:
        case actionTypes.GLOBAL_POSITION_EXPENSES_UPDATE_FILTERS_BY_CATEGORY:
            return state.merge({
                expenses: expenses(state.get('expenses'), action),
            });
        // (andres): Let´s mark the data as outdated if there's a valid OTP process,
        // to make sure we always show updated data
        case actionTypes.PASS_REQUEST_TRANSFER_SUCCESS:
        case actionTypes.SIGNATURE_PATTERN_SMS_VALIDATION_SUCCESS:
        case actionTypes.GLOBAL_POSITION_SET_OUTDATED:
            return state.merge({
                isOutdated: true
            });
        case actionTypes.GLOBAL_WEALTH_REQUEST:
            return state.merge({
                isWealthDataFetching: true
            });
        case actionTypes.PG_WEALTH_API_SUCCESS:
        const wealthRoboData = buildWealthRoboData(action.payload);
            return state.merge({
                wealthDataFF: wealthRoboData,
                isWealthDataFetching:false
            });
        case actionTypes.PG_WEALTH_API_FAILURE:
            return state.merge({
                wealthErrorFF: true,
                isWealthDataFetching:false
            });
        default:
            return state
    }
}

module.exports = globalPosition;
