//@ constants
const biocatch = require("constants/biocatch");
const actionTypes = require("constants/actionTypes");
const { changeBiocatchContext } = require("utilities/biocatch");

const {
    ACCOUNT_OVERVIEW,
    TRANSFERS_PAGE,
    ACCOUNTS_DEBITS,
    CARDS_PAGE,
    CHANGE_CES,
    VIEW_CES,
    INVESTMENTS_PAGE,
    TRANSFER_BETWEEN_MYACCOUNTS,
    NEW_BENEFICIARY_PAGE,
    PERSONAL_DETAILS,
    LOANS_MORTAGES,
    REUSE_TRANSFER,
    CHANGE_PASSWORD,
    CHALLENGE_PAGE,
    SECURITY_KEYS_PAGE,
    SECURITY_SETTINGS_PAGE,
    THIRD_PARTY_PROVIDER,
    DEPOSITS_PAGE,
    OPEN_NEW_CARD,
    MAKE_TRANSFER,
    ONG_TRANSFER,
    DEFAULT,
    OPEN_CC
} = biocatch;

const biocatchActionToContexts = {
    "/myprofile/global": ACCOUNT_OVERVIEW,
    "/myprofile/transfers": TRANSFERS_PAGE,
    "/myprofile/cards": CARDS_PAGE,
    "/myprofile/broker/general": INVESTMENTS_PAGE,
    "/myprofile/transfers/pass": TRANSFER_BETWEEN_MYACCOUNTS,
    "/myprofile/transfers/agenda/addBeneficiary": NEW_BENEFICIARY_PAGE,
    "/myprofile/keys": SECURITY_SETTINGS_PAGE,
    "/myprofile/keys/tpps": THIRD_PARTY_PROVIDER,
    "/myprofile/keys/tpps/tppKeyAdd": THIRD_PARTY_PROVIDER,
    "/myprofile/keys/password": CHANGE_PASSWORD,
    "/myprofile/keys/signature": SECURITY_KEYS_PAGE,
    "/myprofile/edit": PERSONAL_DETAILS,
    "/myprofile/loans-mortgages": LOANS_MORTAGES,
    "/myprofile/transfers/transfer/emited-reuse": REUSE_TRANSFER,
    "/myprofile/deposits": DEPOSITS_PAGE,
    "/myprofile/cards/hire/solidarity": OPEN_NEW_CARD,
    "/myprofile/transfers/transfer": MAKE_TRANSFER,
    "/myprofile/transfers/transfer/charity": ONG_TRANSFER,
    "/myprofile/keys/ecommerce": VIEW_CES,
    "/myprofile/keys/ecommerce/modify": CHANGE_CES,
    "/myprofile/keys/ecommerce/check": CHALLENGE_PAGE
};

const regexContext = [
    {
        regex: /^(\/myprofile\/hire)(\/card|\/pack)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        context: OPEN_NEW_CARD
    },
    {
        regex: /^(\/myprofile\/accounts\/)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+(\/debits)/,
        context: ACCOUNTS_DEBITS
    },
    {
        regex: /^(\/myprofile\/purchase)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        context: OPEN_CC
    },
    {
        regex: /^(\/myprofile\/keys\/tpps)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        context: THIRD_PARTY_PROVIDER
    }
];

const biocatchMiddleware = () => next => action => {
    if (action.type === actionTypes.LOCATION_CHANGE && !__CONTACT_CENTER__) {
        const { pathname } = action.payload;
        let context;
        const knownContext = biocatchActionToContexts[pathname];
        if (!!knownContext) {
            context = knownContext;
        } else {
            const foundRegexContext = regexContext.find(regex =>
                pathname.match(regex.regex)
            );
            !!foundRegexContext
                ? (context = foundRegexContext.context)
                : (context = DEFAULT);
        }
        changeBiocatchContext(context);
    }
    return next(action);
};

module.exports = biocatchMiddleware;
