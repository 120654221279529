//@vendor
const Immutable = require('immutable');
//@constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');

const initialState = Immutable.fromJS({
    valid: false,
    tentativeLimit: 0,
    maximumLimitAutorize: 0,
    currentLimit: 0,
    currency: EURO_TEXT,
    shouldShowModal: false,
    isLimitInvalid: false,
    suggestedAmount: 0
});

function validateStep(step) {
    const tentativeLimit = step.get('tentativeLimit');
    const currentLimit = step.get('currentLimit');
    const limitIsValid = currentLimit !== tentativeLimit;

    return step.merge({
        valid: limitIsValid && !step.get('shouldShowModal'),
        isLimitInvalid: !limitIsValid
    });
}

function cardModifyLimitStep1(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARD_MODIFY_LIMIT_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_MODIFY_LIMIT_SET_LIMIT:
            return state.merge({
                tentativeLimit: action.payload.limit ? parseInt(action.payload.limit, 10) : 0,
                isLimitInvalid: false
            });
        case actionTypes.CARD_MODIFY_LIMIT_SHOW_MODAL:
            return state.merge({
                shouldShowModal: true,
                suggestedAmount: action.payload.suggestedAmount || 0
            });
        case actionTypes.CARD_MODIFY_LIMIT_CLOSE_MODAL:
            return state.merge({
                shouldShowModal: false
            });
        case actionTypes.CARD_MODIFY_LIMIT_SETUP_STEP:
            return state.merge({
                currency: action.payload.currency || EURO_TEXT,
                currentLimit: action.payload.currentLimit,
                tentativeLimit: action.payload.tentativeLimit || 0,
                valid: false,
                isLimitInvalid: false,
                shouldShowModal: false
            });
        default:
            return state;
    }
}

module.exports = cardModifyLimitStep1;
