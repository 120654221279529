// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
} = require('constants/index');

// @reducers
const hireCardVirtualCreditStep1 = require('./hireCardVirtualCredit/hireCardVirtualCreditStep1');
const hireCardVirtualCreditStep2 = require('./hireCardVirtualCredit/hireCardVirtualCreditStep2');
const hireCardVirtualCreditStep3 = require('./hireCardVirtualCredit/hireCardVirtualCreditStep3');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorMsg: '',
    hiringStatus: '',
    isFetching: false,
    steps: [
        hireCardVirtualCreditStep1(),
        hireCardVirtualCreditStep2(),
        hireCardVirtualCreditStep3(),
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag:false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardVirtualCreditStep1(step, action);
            case SECOND_STEP:
                return hireCardVirtualCreditStep2(step, action);
            case THIRD_STEP:
                return hireCardVirtualCreditStep3(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function hireCardVirtualCreditReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
            case actionTypes.HIRE_CARD_LOADING:
                return state.merge({
                    loadingFlag: action.payload.loadingFlag
                });
        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_SET_ACCOUNT:
        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_ACCOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_SET_AGREEMENT_CHECKBOX:
            const { stepToUpdate = __CONTACT_CENTER__ ? FIRST_STEP : THIRD_STEP } = action.payload;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_SUCCESS:
            return state.merge({
                hiringStatus: action.payload.hiringStatus,
                isFetching: false,
                submitSuccessful: true
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                hiringStatus: action.payload.hiringStatus,
                isFetching: false
            });
        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_REQUEST:
            return state.merge({
                isFetchingEcardPdf: true,
                pdfDownloadError: false
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_FAILURE:
            return state.merge({
                isFetchingEcardPdf: false,
                pdfDownloadError: true
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_GENERATE_PDF_SUCCESS:
            return state.merge({
                isFetchingEcardPdf: false,
                pdfDownloadError: false
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_GET_TEXT_RELIEF_SUCCESS:
            return state.mergeDeep({
                response: {
                    textRelief: trim(action.payload.response.textoRelief)
                }
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_RESET:
            return initialState;

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        default:
            return state;
    }
}

module.exports = hireCardVirtualCreditReducer;
