// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, LOANS_MORTGAGES_PREGRANTED_CREDIT } = require('constants/index');
const { es:{ DATE_FORMAT_WITH_DASHES } } = require('commonsConstants/dateFormatTypes');
// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');
const DateHelper = require('utilities/dateHelper');

const CURRENT_ACCOUNTS = [
    '300800',
    '300801',
    '300805',
    '300806',
    '300807',
    '300808',
];

const initialState = Immutable.fromJS({
    isFetching: false,
    byId: {},
    byOrder: [],
    error: '',
    anyAccountWithoutPregrantedCredit: false
});

const prepareDate = (account, key) => {
    const date = get(account, `balances.${key}`);
    const dateWrapped = moment(date, DATE_FORMAT_WITH_DASHES);
    return DateHelper.formatDate(dateWrapped);
};

const canAskForPregrantedCredit = (account) => {
    const product = get(account, 'cnuevo.producto');
    const accountType = get(account, 'criterios.c1.subtipoproducto', '');

    return CURRENT_ACCOUNTS.indexOf(`${product}${accountType}`) !== -1;
}

/*
* Keep in mind that 'Pregranted Credits' are treated as a kind of account.
*/

const parsePregantedCreditsList = (state, accountsList) => {
    const accounts = get(accountsList, 'payload.datosSalidaCuentas.cuentas', []);
    let anyAccountWithoutPregrantedCredit = false;
    let byId = {};
    let byOrder = [];

    accounts.forEach( account => {
        const isPregrantedCredit = (get(account, 'saldosCuenta.importeLimite.importe', 0) || 0) > 0
        if (isPregrantedCredit) {
            //const pregrantedCreditLimitAmount = stringToNumber(get(account, 'balances.ultimoSaldoImpresoLimite', 0) || 0);
            const pregrantedCreditLimitAmount = get(account, 'saldosCuenta.importeLimite.importe',  0)

            const ibanObject = {
                country: account.ibanComplex.pais,
                controlDigit: account.ibanComplex.digitodecontrol,
                codbban: account.ibanComplex.codbban
            };

            const id = generateAccountId(ibanObject);
            const accountId = id;
            const balance = get(account, 'balance.amount', 0) || 0;
            const currency = get(account, 'balance.currency', EURO_TEXT) || EURO_TEXT;
            const alias = account.descripcion;
            const iban = account.iban;

            const openingDate = prepareDate(account, 'fechaUltimaImpresionLimite');
            const expirationDate = prepareDate(account, 'fechaVencimLimite');
            const product = get(account, 'cviejo.subgrupo', 0) || 0;
            const contractNumber = get(account, 'cviejo.numerodecontrato', 0) || 0;
            const newProduct = get(account, 'cnuevo.producto', 0) || 0;
            const newContractNumber = get(account, 'cnuevo.numerodecontrato', 0) || 0;
            const productSubtype = get(account, 'criterios.c1.subtipoproducto');
            const holder = trim(account.nombretitular);
            const controlDigit = ibanObject.controlDigit;

            let available;
            if (balance > 0 ) {
                available = {
                    amount: pregrantedCreditLimitAmount,
                    currency
                };
            } else {
                available = {
                    amount: Math.max(pregrantedCreditLimitAmount + balance, 0),
                    currency
                };
            }

            const disposedAmount = pregrantedCreditLimitAmount - available.amount;
            const disposed = {
                amount : disposedAmount,
                currency
            };
            const limit = {
                amount: pregrantedCreditLimitAmount,
                currency
            };

            byId[id] = {
                id,
                accountId,
                alias,
                openingDate,
                expirationDate,
                limit,
                available,
                disposed,
                iban,
                product,
                contractNumber,
                newProduct,
                newContractNumber,
                controlDigit,
                productSubtype,
                holder,
                type: LOANS_MORTGAGES_PREGRANTED_CREDIT
            };

            byOrder.push(id);
        } else if (canAskForPregrantedCredit(account)) {
            anyAccountWithoutPregrantedCredit = true;
        }
    });

    return state
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder))
        .set('anyAccountWithoutPregrantedCredit', anyAccountWithoutPregrantedCredit);
}

function pregrantedCreditsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            return parsePregantedCreditsList(state, action);
        case actionTypes.CREDIT_PREGRANTED_DETAILS_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        details: {
                            startingDate: action.payload.details[0].fechaInicio,
                            dueDate: moment(action.payload.details[0].fechaInicio).add(1, 'y'),
                            hour: action.payload.hour,
                            originalDate: action.payload.originalDate,
                            effectiveDate: action.payload.effectiveDate
                        }
                    }
                }
            });
        default:
            return state;
    }
}

module.exports = pregrantedCreditsReducer;
