// @helpers
const actionTypes = require('constants/actionTypes');

// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @constants
const {
    DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR
} = require('constants/index');

const initialState = Immutable.fromJS({
    isFetching: false,
    payLaterOpted: false,
    payLaterOptedDate: null,
    payLaterData: {},
    error: ''
});

function getPayLaterInfo(payload){
    let info = {payLaterOptedDate: null, payLaterOpted: false};
    if(payload.movements && payload.movements.length){
        const date = payload.movements[0].fechape;
        const formattedDate = moment(date).format(DATE_FORMAT_SLASHES_SHORT_DAY_MONTH_YEAR);
        info = {payLaterOptedDate: formattedDate, payLaterOpted: true, payLaterData: payload};
    }
    return info;
}

function creditCardPayLaterInfo(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CREDIT_CARD_PAY_LATER_REQUEST:
            return state.mergeDeep({
                isFetching: true
            });
        case actionTypes.CREDIT_CARD_PAY_LATER_INFO_SUCCESS:
            const payLaterInfo = getPayLaterInfo(action.payload);
            return state.mergeDeep({
                isFetching: false,
                payLaterOpted: payLaterInfo.payLaterOpted,
                payLaterOptedDate: payLaterInfo.payLaterOptedDate,
                payLaterData: payLaterInfo.payLaterData,
                error: ''
            });
        case actionTypes.CREDIT_CARD_PAY_LATER_INFO_ERROR:
            return state.mergeDeep({
                isFetching: false,
                payLaterOpted: false,
                payLaterOptedDate: null,
                error: action.error
            });
        case actionTypes.CREDIT_CARD_PAY_LATER_RESET:
            return initialState;
        default:
            return state
    }
}

module.exports = creditCardPayLaterInfo;
