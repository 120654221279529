// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');
const { getAccountsByCountries } = require('utilities/accountTypeHelper');

// @ helpers
const actionTypes = require('constants/actionTypes');
const { CHECKING_ACCOUNT_CODE } = require('constants/index');

const initialState = Immutable.fromJS({
    isFetching: false,
    byId: {},
    byOrder: [],
    error: ''
});

function processCheckingAccounts(state, data, originCountry) {
    const accounts = get(data, 'icollContratos.contrato', []);

    let byId = {};
    let byOrder = [];

    accounts.forEach(account => {
        const iban = {
            country: account.contratoUnificado.pais,
            controlDigit: account.contratoUnificado.digitodecontrol,
            codbban: account.contratoUnificado.codbban
        };

        const accountId = generateAccountId(iban);

        byId[accountId] = {
            accountId,
            accountType: account.tipoCuenta || '',
            iban: trim(account.contratoUnificado.codbban),
            balance: {
                currency: account.divisac,
                amount: account.importec
            },
            balances: {
                availableBalance: {
                    currency: account.saldoDisponible.divisa,
                    amount: account.saldoDisponible.importe
                }
            },
            catalogData: {
                product: getAccountsByCountries(originCountry, CHECKING_ACCOUNT_CODE)
            },
            contract: {
                product: account.contratoUnificadoPart.producto,
                number: account.contratoUnificadoPart.numerodecontrato
            },
            visible: account.visible,
            interveners: []
        };

        byOrder.push(accountId);
    });

    return {
        byId,
        byOrder
    };
}

function parseInterveners(interveners) {
    return interveners.map(intervener => {
        return {
            name: trim(intervener.nombre),
            type: trim(intervener.desIntervencion)
        };
    });
}

function checkingAccountsReducer(state = initialState, action) {
    let newState;

    switch (action.type) {
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_REQUEST:
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_REQUEST:
            newState = state.merge({
                isFetching: true,
                error: ''
            });

            return newState;
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_SUCCESS:
            const { data, originCountry } = action.payload;
            let processedCheckingAccounts = processCheckingAccounts(state, data, originCountry);
            newState = state.merge({
                isFetching: false,
                byOrder: processedCheckingAccounts.byOrder,
                byId: processedCheckingAccounts.byId
            });

            return newState;
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_FAILURE:
            newState = state.merge({
                isFetching: false,
                error: action.payload.data.errorDescription
            });

            return newState;
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_SUCCESS:
            let accountId = action.payload.accountId;
            let account = state.getIn(['byId', accountId]);
            let accountWithInterveners = account.merge({
                interveners: parseInterveners(action.payload.interveners)
            });
            return state.merge({
                byId: state.get('byId').setIn([accountId], accountWithInterveners),
                isFetching: false
            });
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.data.message
            });
        default:
            return state;
    }
}

module.exports = checkingAccountsReducer;
