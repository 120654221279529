/**
 * Created by adderlyjaureguicampos on 02/12/16.
 */

const IS_MODAL_COMPONENT = true;

function createObject(component, isModalComponent = false, nameContract = '') {
    let object;

    if (isModalComponent) {
        object =  {
            disabled: component.disabled || false,
            errorMessage: component.errorMessage,
            id: component.id,
            isErrorVisible: false,
            isVisible: component.visible,
            openModal: false,
            required: component.required,
            target: component.target,
            validations: component.validations,
            value: component.default_value || null,
            visible: []
        }
    } else {
        object =  {
            disabled: component.disabled || false,
            errorMessage: component.errorMessage,
            id: component.id,
            isErrorVisible: false,
            isVisible: component.visible,
            required: component.required,
            target: component.target,
            type: component.type,
            validations: component.validations,
            placeholder: component.placeholder,
            value: component.default_value || component.default_value === 0 ? component.default_value : null,
            visible: []
        }
        if (component.id_component === 'date-picker' && (nameContract === 'isCheckingAccount' || nameContract === 'isPayrollAccount')) {
            object.value = null;
        }
    }
    return object;
}

function returnObjects (components) {
    //Here I nee to read the steps.components and generate all the fields information
    let stepComponentsObjects = [];
    if (Array.isArray(components)){
        components.forEach(elements => {
            elements.components.forEach( stepComponents => {
                stepComponents.grid.forEach(gridComponent => {
                    gridComponent.forEach(component => {
                        if (Object.keys(component).length !== 0) {
                            if (component.id_component.includes('modal')) {
                                stepComponentsObjects.push(createObject(component, IS_MODAL_COMPONENT));
                            } else {
                                stepComponentsObjects.push(createObject(component))
                            }
                        }
                    });
                });
            });
        });
    } else {
        components.components.forEach( stepComponents => {
            if (stepComponents.grid) {
                stepComponents.grid.forEach(gridComponent => {
                    gridComponent.forEach(component => {
                        if (Object.keys(component).length !== 0) {
                            if (component.id_component.includes('modal')) {
                                stepComponentsObjects.push(createObject(component, IS_MODAL_COMPONENT));
                            } else {
                                stepComponentsObjects.push(createObject(component))
                            }
                        }
                    });
                });
            } else {
                if (Object.keys(stepComponents).length !== 0) {
                    if (stepComponents.id_component.includes('modal')) {
                        stepComponentsObjects.push(createObject(stepComponents, IS_MODAL_COMPONENT));
                    } else {
                        stepComponentsObjects.push(createObject(stepComponents));
                    }
                }
            }
        });
    }

    return stepComponentsObjects;
}

function returnObjectsFromArrayComponents(components, nameContract = false) {
    let stepComponentsObjects = [];
    let component;

    for (var key in components) {
        component = components[key];
        stepComponentsObjects.push(createObject(component, false, nameContract));
    }

    return stepComponentsObjects;
}

function replacePrefix(components, newPrefix = '', prefix = '###') {
    let newComponents = {};
    for (var key in components) {
        newComponents[key] = Object.assign({}, components[key]);
        newComponents[key].id = components[key].id.replace(prefix, newPrefix);
        if (newComponents[key].target) {
            newComponents[key].target = components[key].target.replace(prefix, newPrefix);
        }
        if (newComponents[key].uncheckElements) {
            newComponents[key].uncheckElements = newComponents[key].uncheckElements.map(function (element) {
                return element.replace(prefix, newPrefix);
            });
        }
    }

    return newComponents;
}

module.exports = {
    replacePrefix,
    returnObjects,
    returnObjectsFromArrayComponents
};
