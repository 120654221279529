const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        valid: true,
        total: {
            selected: true
        },
        partial: {
            selected: false,
            id: null
        },
        titles: {

        }
    });
}

function validateStep(step) {
    const partialOption = step.get('partial');
    const partialSelected = partialOption.get('selected');
    const partialId = partialOption.get('id');
    const validPartialOption = partialSelected && step.getIn(['titles', partialId]);
    return step.merge({
        valid: step.getIn(['total', 'selected']) || validPartialOption
    })
}

function brokerTransferValuesStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_TRANSFER_VALUES_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_TRANSFER_VALUES_SET_TITLES:
            return state
                .merge({
                    valid: true
                })
                .mergeDeepIn(['titles'], {
                    [action.payload.valueId]: action.payload.titles
                });
        case actionTypes.BROKER_TRANSFER_VALUES_TOGGLE_CHECK:
            const { valueId } = action.payload;

            // if the user is clicking in the selected radio
            if ( valueId === state.getIn(['partial', 'id']) ){
                return state;
            }

            return state
                .merge({
                    valid: true
                })
                .mergeIn(['partial'], {
                    id: valueId
                })
        case actionTypes.BROKER_TRANSFER_VALUES_SET_TYPE:
            return state
                .setIn(['total', 'selected'], false)
                .setIn(['partial', 'selected'], false)
                .setIn([action.payload.type, 'selected'], true);
        default:
            return state;
    }
}

module.exports = brokerTransferValuesStep2;
