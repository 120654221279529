// @constants
const {
    BROKER_ADVANCED_SEARCH_UTAG_NAME
} = require('constants/index');

const brokerAdvSearchTags = () => (
    {
        editAdvanceSearch: {
            categoriaEvento: BROKER_ADVANCED_SEARCH_UTAG_NAME,
            accionEvento: 'editar buscar',
            etiquetaEvento: ''
        }
    }
)
module.exports = { brokerAdvSearchTags }