// @ constants
const { INTERVENER_TYPE } = require('constants/index');

module.exports = {
    accountNumber: 'contractSummary-accountNumber',
    title: 'contractSummary-upgradeToPayrollAccountSuccessTitle',
    backButtonText: 'contractSummary-backToAccountsText',
    backButtonUrl: '/myprofile/accounts',
    seed: 'promotionalCode',
    setSuccessStepPrefix: 'upgradeToPayrollAccount_setSuccessStepPrefix',
    instructions: 'upgrade_to_payroll_account_instructions',
    details: [
        [
            {
                columns: 12,
                title: 'contractsView-interveners',
                type: INTERVENER_TYPE
            }
        ]
    ],
    warningMessage: [
        [
            {
                text:'contractsView-upgradeToPayrollAccountEmailWarningMessageNoSeed'
            }
        ]
    ],
    successWithEmail_warningMessage: [
        [
            {
                text:'contractsView-upgradeToPayrollAccountEmailWarningMessage',
            }
        ]
    ],
    seed_warningMessage: [
        [
            {
                text: 'contractsView-upgradeToPayrollAccountEmailWarningMessageNoSeed'
            },
            {
                id: 'upgradeToPayrollAccount-promoCode',
                text: 'contractsView-upgradeToPayrollAccountEmailWarningMessagePromotionCode',
                seed: true
            }
        ]
    ],
    seed_successWithEmail_warningMessage: [
        [
            {
                text:'contractsView-upgradeToPayrollAccountEmailWarningMessage',
            },
            {
                id: 'upgradeToPayrollAccount-promoCode',
                text: 'contractsView-upgradeToPayrollAccountEmailWarningMessagePromotionCode',
                seed: true
            }
        ]
    ]
}
