// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    loading: false,
    error: false,
    hasBeenFetched: false,
    isDownloading: false,
    preview: ""
});

function accountsTransactionReceiptsPreviewReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_RECEIPT_PREVIEW_REQUEST:
            return state.mergeDeep({
                loading: true,
                error: false
            });
        case actionTypes.FETCH_RECEIPT_PREVIEW_SUCCESS:
            return state.mergeDeep({
                loading: false,
                hasBeenFetched: true,
                preview: action.payload.preview
            });
        case actionTypes.FETCH_RECEIPT_PREVIEW_FAILURE:
            return state.mergeDeep({
                loading: false,
                error: action.payload.error
            });
        default:
            return state
    }
}

module.exports = accountsTransactionReceiptsPreviewReducer;
