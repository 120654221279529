const { isValidAccount } = require('../utilities/formValidationHelper');
const mockHelper = require('../utilities/mockDataHelper');
const formHelper = require('../utilities/formHelper');

function eCard_onChangeAmount(amount) {
    return formHelper.onChangeAmount(amount);
}

function eCard_onChangeIntervener(item, typeSelected, index) {
    return mockHelper.onChangeIntervener(item, typeSelected, index);
}

function validateStep1() {
    return {
        isValidStep: true
    }
}

function validateStep2(card_details) {
    return isValidAccount(card_details);
}

function validateStep3(card_details, confirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = confirmationCheckboxes.get('list').map(
        item => item.set('errorState', item.get('isRequired') && !item.get('value'))
    );

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details,
        isValidStep: confirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: confirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function e_card_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1()

        case 2:
            return validateStep2(immCardDetails)

        case 3:
            return validateStep3(immCardDetails, immConfirmationCheckboxes)
    }
}

function e_card_SignParams() {
    return {};
}

function e_card_HireParams(immAccount, gdprPlacementId) {
    let ret;

    if (immAccount) {
        ret = {
            hasGdprPlacementId: !!gdprPlacementId,
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        }
    }
    return ret;
}

function eCard_setSuccessStepPrefix(cardDetails, immProfileFields) {
    return formHelper.haveEmail(immProfileFields) ? {email: 'successWithEmail_'} : {};
}

module.exports = {
	e_card_validateStep: e_card_validateStep,
    eCard_onChangeAmount: eCard_onChangeAmount,
    eCard_onChangeIntervener: eCard_onChangeIntervener,
    eCard_setSuccessStepPrefix: eCard_setSuccessStepPrefix,
    e_card_SignParams: e_card_SignParams,
    e_card_HireParams: e_card_HireParams
};
