const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const findKey = require('lodash/object/findKey');
const { EURO_TEXT } = require('constants/index');
const { FILTERS_VALID, FILTERS_INVALID, API_CENTER, API_ENTERPRISE_NUMBER } = require('constants/index');
const { isItemAvailable } = require('utilities/APIParsingHelper');
const forEach = require('lodash/collection/forEach');

const balanceObj = () => {
    return { amount: 0, currency: EURO_TEXT };
};

const initialState = Immutable.fromJS({
    isFetchingAssociatedAccount: false,
    associatedAccountErrorReceived: false,
    associatedAccountFetchedSuccess: false,
    associatedAccountError: '',
    isFetching: false,
    isFetchingProducts: false,
    walletList: {},
    plansList: {},
    fundsList: {},
    futuresList: {},
    allFuturesList: {},
    stocksByWallet: {},
    walletSummarization: {
        plans: { balance: [balanceObj()] },
        futures: { balance: [balanceObj()] },
        funds: { balance: [balanceObj()] },
        values: { balance: [balanceObj()] },
        balance: [balanceObj()]
    },
    contractsValues: [],
    wealthRoboValues: {}
});

function formatProduct(stockItem) {
    return {
        name: stockItem.get('name'),
        investment: stockItem.get('investedCapital'),
        //@hardcoded: not in the service
        investmentCurrency: 'EUR',
        quote: stockItem.get('quote'),
        quoteCurrency: stockItem.get('quoteCurrency'),
        quoteDate: stockItem.get('quoteDate'),
        shares: stockItem.get('titles'),
        totalValue: stockItem.get('totalValue'),
        totalValueCurrency: stockItem.get('totalValueCurrency'),
        earnings: stockItem.get('profitability'),
        //@hardcoded: not in the service
        earningsCurrency: 'EUR',
        emisionCode: stockItem.get('emisionCode'),
        valueCode: stockItem.get('valueCode'),
        productType: stockItem.get('productType'),
        isin: stockItem.get('isin'),
        iso: stockItem.get('iso')
    };
}

//@hardcoded: mocking stockReturn until we have the actual data from API
//@hardcoded: mocking stockReturnCurrency until we have the actual data from API
function buildWalletStockList(groupedProducts) {
    const map = {};

    forEach(groupedProducts, (products, key) => {
        const filteredList = (products || []).filter(stockItem => !!stockItem.get('name'));
        const formatedProducts = filteredList.map(product => {
            return formatProduct(product);
        });

        map[key] = formatedProducts;
    });

    return Immutable.fromJS(map);
}

const buildFullContractNumber = walletItem => {
    const controlDigit = get(walletItem, 'cuenta.digitodecontrol');
    const product = get(walletItem, 'contrato.producto');
    const contractNumber = get(walletItem, 'contrato.numerodecontrato');

    return `${API_ENTERPRISE_NUMBER}${API_CENTER}${controlDigit}${product}${contractNumber}`;
};

//@hardcoded: mocking walletTotalReturn until we have the actual data from API
//@hardcoded: mocking walletTotalReturnCurrency until we have the actual data from API
function buildWalletState(walletItem) {
    return Immutable.fromJS({
        name: walletItem.descripcion,
        account: walletItem.cuenta,
        accountNumber: walletItem.contrato.numerodecontrato,
        productSubType: walletItem.criterios.c1.subtipoproducto,
        associatedAccount: {},
        owner: walletItem.nombreTitular,
        totalAmount: walletItem.saldo1.importe,
        currency: walletItem.saldo1.divisa,
        product: walletItem.contrato.producto,
        earnings: walletItem.profitability,
        earningsCurrency: 'EUR',
        contract: walletItem.contrato,
        intervenerType: trim(walletItem.descIntervenciones.nomTipInterv),
        fullContractNumber: buildFullContractNumber(walletItem),
        positions: 0,
        id: walletItem.contrato.numerodecontrato + walletItem.contrato.producto,
        filtros: walletItem.filtros,
        saldo1: walletItem.saldo1,
        contrato: walletItem.contrato,
        openingDate: get(walletItem, 'criterios.c2.fecsit', '')
    });
}

function buildContractsMap(walletList) {
    const contractsMap = {};
    walletList.forEach(walletItem => {
        const id = walletItem.contrato.numerodecontrato + walletItem.contrato.producto;
        contractsMap[id] = buildWalletState(walletItem);
    });
    return Immutable.Map(contractsMap);
}

function updatePositions(groupedProducts, walletList) {
    return walletList.map((wallet, id) => {
        const productList = groupedProducts[id] || [];
        return wallet.set(
            'positions',
            productList.reduce(function(accumulated, product) {
                return accumulated + product.get('titles');
            }, 0)
        );
    });
}

function calculateSummarization(params = {}) {
    const balance = Object.assign({}, params.balance);
    const items = params.items;

    balance.amount = 0;

    items.forEach(item => {
        if (!isItemAvailable(item)) {
            return;
        }
        const amount =
            get(item, 'saldo1Euros.importe') ||
            get(item, 'saldo1.importe') ||
            get(item, 'saldoActualEuros.importe') ||
            get(item, 'saldoActual.importe') ||
            0;
        balance.amount += amount;
    });

    return balance;
}

function calculateInvalids(thing = []) {
    return thing.filter(e => e.filtros.valido === FILTERS_INVALID).length;
}

function buildWalletSummarization(plans = [], futures = [], funds = [], values = []) {
    const filteredPlans = plans.filter(item => isItemAvailable(item));
    const filteredFutures = futures.filter(item => isItemAvailable(item));
    const filteredFunds = funds.filter(item => isItemAvailable(item));
    const filteredValues = values.filter(item => isItemAvailable(item));

    const summary = {
        plans: { balance: [balanceObj()], alias: 'plans', quantity: filteredPlans.length },
        futures: { balance: [balanceObj()], alias: 'futures', quantity: filteredFutures.length },
        funds: { balance: [balanceObj()], alias: 'funds', quantity: filteredFunds.length },
        values: { balance: [balanceObj()], alias: 'values', quantity: filteredValues.length }
    };

    const invalidPlans = calculateInvalids(plans);
    const invalidFutures = calculateInvalids(futures);
    const invalidFunds = calculateInvalids(funds);
    const invalidValues = calculateInvalids(values);

    summary.excludedProducts = invalidPlans + invalidFutures + invalidFunds + invalidValues;

    summary.values.balance[0] = calculateSummarization({
        items: values,
        balance: summary.values.balance[0]
    });

    summary.futures.balance[0] = calculateSummarization({
        items: futures,
        balance: summary.futures.balance[0]
    });

    summary.funds.balance[0] = calculateSummarization({
        items: funds,
        balance: summary.funds.balance[0]
    });

    summary.plans.balance[0] = calculateSummarization({
        items: plans,
        balance: summary.plans.balance[0]
    });

    return summary;
}

function updateWalletSummarization(params = {}) {
    const summary = params.summary;
    const items = params.items;
    const key = params.type;
    const oldItems = params.oldItems;

    const oldQuantity = Object.keys(oldItems).filter(
        key => oldItems[key].filtros.valido === FILTERS_VALID
    );
    const filteredItems = items.filter(item => isItemAvailable(item));

    summary[key].quantity = filteredItems.length;
    summary[key].balance[0] = calculateSummarization({
        balance: summary[key].balance[0],
        items: filteredItems
    });

    summary.excludedProducts += oldQuantity.length - filteredItems.length;

    return summary;
}

function buildContractsData(contract) {
    const map = contract.map(cont => {
        return {
            cuenta: get(cont, 'cuenta', {}),
            contrato: get(cont, 'contrato', {}),
            contractNumberShort: `${get(cont, 'contrato.numerodecontrato', '')}${get(cont, 'contrato.producto', '')}`
        }
    });
    return map || [];
}

function buildWealthRoboData(wealthData) {
    return {
        totalDiscrecionalAmount: get(wealthData, 'totalSaldoDiscrecional', 0),
        cantidadCuentasGD: get(wealthData, 'cantidadCuentasGD', 0),
        cantidadContratosGD: get(wealthData, 'cantidadContratosGD', 0),
        balance: [{ amount: get(wealthData, 'totalSaldoDiscrecional', 0), currency: EURO_TEXT }]
    }
}

function augmentWalletListWithAssociatedAccount(walletList, contracts) {
    const newWalletList = {};

    walletList.forEach((value, key) => {
        const contractKey = findKey(contracts, contract => {
            const numerodecontrato = get(contract, 'valores[0].contrato.numeroContrato');
            return numerodecontrato === value.get('accountNumber');
        });

        const country = trim(get(contracts[contractKey], 'valores[0].cuentaAsociada.pais'));
        const controlDigit = trim(
            get(contracts[contractKey], 'valores[0].cuentaAsociada.digitoControl')
        );
        const codbban = trim(get(contracts[contractKey], 'valores[0].cuentaAsociada.codBan'));
        const augmented = value.set('associatedAccount', {
            iban: `${country}${controlDigit}${codbban}`,
            balance: {
                currency: get(contracts[contractKey], 'valores[0].cuentaAsociada.saldo.moneda'),
                balance: get(contracts[contractKey], 'valores[0].cuentaAsociada.saldo.importe')
            }
        });
        newWalletList[key] = augmented;
    });
    return Immutable.fromJS(newWalletList);
}

function brokerWalletsReducer(state = initialState, action) {
    let mergerList = [];
    let walletSummarization;
    switch (action.type) {
        case actionTypes.BROKER_GET_STOCKS_BY_WALLET_REQUEST:
            return state.set('isFetchingProducts', true);
        case actionTypes.BROKER_GET_WALLET_LIST_SUCCESS:
            return state.merge({
                walletList: buildContractsMap(action.walletList),
                plansList: action.walletPlans,
                fundsList: action.walletFunds,
                futuresList: action.walletFutures,
                allFuturesList: Immutable.fromJS(action.walletAllFutures),
                valuesList: action.walletList,
                walletSummarization: buildWalletSummarization(
                    action.walletPlans,
                    action.walletFutures,
                    action.walletFunds,
                    buildContractsMap(action.walletList).toList().toJS()
                ),
                isFetching: false
            });
        case actionTypes.BROKER_GET_WALLET_LIST_FAILURE:
            return state.merge({
                walletList: {},
                stocksByWallet: {},
                isFetching: false,
                walletSummarization: {}
            });
        case actionTypes.BROKER_GET_STOCKS_BY_WALLET_SUCCESS:
            const updatedWalletList = updatePositions(
                action.groupedProducts,
                state.get('walletList')
            );
            return state
                .set(
                    'stocksByWallet',
                    buildWalletStockList(action.groupedProducts, action.walletList)
                )
                .set('isFetchingProducts', false)
                .set('walletList', updatedWalletList);
        case actionTypes.BROKER_GET_STOCKS_BY_WALLET_FAILURE:
            return state.set('stocksByWallet', []).set('isFetchingProducts', false);

        case actionTypes.BROKER_GET_MY_INVESTMENTS_REQUEST:
            return state.merge({
                isFetchingAssociatedAccount: true,
                associatedAccountErrorReceived: false,
                associatedAccountFetchedSuccess: false,
                associatedAccountError: ''
            });

        case actionTypes.BROKER_GET_MY_INVESTMENTS_FAILURE:
            return state.merge({
                isFetchingAssociatedAccount: false,
                associatedAccountFetchedSuccess: false,
                associatedAccountErrorReceived: true,
                associatedAccountError: action.payload.error
            });

        case actionTypes.BROKER_GET_MY_INVESTMENTS_SUCCESS:
            return state.merge({
                isFetchingAssociatedAccount: false,
                associatedAccountErrorReceived: false,
                associatedAccountFetchedSuccess: true,
                associatedAccountError: '',
                walletList: augmentWalletListWithAssociatedAccount(
                    state.get('walletList'),
                    action.payload.contracts
                )
            });

        case actionTypes.SET_WALLET_ALIAS_REQUEST:
            return state.mergeDeep({
                walletList: {
                    [action.payload.walletId]: {
                        name: action.payload.alias
                    }
                }
            });

        case actionTypes.SET_WALLET_ALIAS_FAILURE:
            return state.mergeDeep({
                walletList: {
                    [action.payload.walletId]: {
                        name: action.payload.alias
                    }
                }
            });

        case actionTypes.SET_WALLET_ALIAS_SUCCESS:
            return state.mergeDeep({
                walletList: {
                    [action.payload.walletId]: {
                        name: action.payload.alias
                    }
                }
            });

        //Profile settings DLV
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_WALLET_REQUEST:
            return state;

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_WALLET_FAILURE:
            return state;

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_FUTURE_FAILURE:
            return state;

        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            const mapedData = buildContractsData(
                get(action, 'payload.datosSalidaValores.valores', [])
            );
            const wealthRoboData = buildWealthRoboData(
                get(action, 'payload.datosSalidaWealth', {})
            );
            return state.merge({
                contractsValues: mapedData,
                wealthRoboValues: wealthRoboData
            });

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_WALLET_SUCCESS:
            const partiallyMergedState = state.mergeDeepIn(
                ['walletList', action.payload.walletId, 'filtros'],
                {
                    valido: action.payload.valid ? FILTERS_VALID : FILTERS_INVALID
                }
            );

            walletSummarization = updateWalletSummarization({
                summary: partiallyMergedState.get('walletSummarization').toJS(),
                items: partiallyMergedState.get('walletList').toList().toJS(),
                type: 'values',
                oldItems: state.get('walletList').toJS()
            });

            return partiallyMergedState.set('walletSummarization', Immutable.fromJS(walletSummarization));

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_FUTURE_SUCCESS:
            const partialMergedState = state.mergeDeepIn(['futuresList', action.payload.futureId, 'filtros'], {
                valido: action.payload.valid ? FILTERS_VALID : FILTERS_INVALID
            });

            walletSummarization = updateWalletSummarization({
                summary: partialMergedState.get('walletSummarization').toJS(),
                items: partialMergedState.get('futuresList').toList().toJS(),
                type: 'futures',
                oldItems: state.get('futuresList').toJS()
            });

            return partialMergedState.set('walletSummarization', Immutable.fromJS(walletSummarization));

        //Funds Profile ACTIONS
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_FUND_REQUEST:
            return state;

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_FUND_FAILURE:
            return state;

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_FUND_SUCCESS:
            mergerList = [];
            state.get('fundsList').toJS().forEach(fund => {
                if (fund.contrato.numerodecontrato === action.payload.fundId) {
                    fund.filtros.valido = action.payload.valid ? FILTERS_VALID : FILTERS_INVALID;
                }
                mergerList.push(fund);
            });

            walletSummarization = updateWalletSummarization({
                summary: state.get('walletSummarization').toJS(),
                items: mergerList,
                type: 'funds',
                oldItems: state.get('fundsList').toJS()
            });

            return state
                .mergeDeep({
                    fundsList: mergerList
                })
                .set('walletSummarization', Immutable.fromJS(walletSummarization));

        //Plans Profile ACTIONS
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_PLAN_REQUEST:
            return state;

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_PLAN_FAILURE:
            return state;

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_PLAN_SUCCESS:
            mergerList = [];
            state.get('plansList').toJS().forEach(plan => {
                if (plan.contrato.numerodecontrato === action.payload.planId) {
                    plan.filtros.valido === FILTERS_VALID
                        ? (plan.filtros.valido = FILTERS_INVALID)
                        : (plan.filtros.valido = FILTERS_VALID);
                }
                mergerList.push(plan);
            });

            walletSummarization = updateWalletSummarization({
                summary: state.get('walletSummarization').toJS(),
                items: mergerList,
                type: 'plans',
                oldItems: state.get('plansList').toJS()
            });

            return state
                .mergeDeep({
                    plansList: mergerList
                })
                .set('walletSummarization', Immutable.fromJS(walletSummarization));

        default:
            return state;
    }
}

module.exports = brokerWalletsReducer;
