// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const {
    BROKER_ALERT_BUY_BUTTON_STOCK,
    BROKER_ALERT_BUY_BUTTON_ETF,
    BROKER_ALERT_BUY_BUTTON_WARRANT
} = require('constants/index');

const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: false,
    endDate: '',
    stockName: '',
    exchangeName: '',
    spcSymbol: '',
    conditionName: '',
    conditionValue: '',
    conditionId: '',
    exchangeId: '',
    category: '',
    buyButtonUrl: '',
});

/**
 *
 * @param {Immutable.Map} immState
 * @param {Array} buttonResponse
 */
const generateBuyButtonUrl = (immState, buttonResponse) => {
    const firstButtonResponse = buttonResponse && buttonResponse.length ? buttonResponse[0] : {};
    const isin = get(firstButtonResponse, 'instrumentId', '').trim();
    const iso = get(firstButtonResponse, 'miccode', '').trim();
    const category = get(firstButtonResponse, 'category', '').trim();

    let url = '';

    switch (category) {
        case BROKER_ALERT_BUY_BUTTON_STOCK:
            url = `/myprofile/broker/stocksBuy/${isin}/${iso}`;
            break;
        case BROKER_ALERT_BUY_BUTTON_ETF:
            url = `/myprofile/broker/etfsBuy/${isin}/${iso}`;
            break;
        case BROKER_ALERT_BUY_BUTTON_WARRANT:
            url = `/myprofile/broker/warrantsBuy/${isin}/${iso}`;
            break;
        default:
            break;
    }

    return immState.set('buyButtonUrl', url);
};

function prepareAlert(alerts, state) {
    if (!alerts || alerts.length <= 0) {
        return state;
    }
    const alert = alerts.find(
        value => {
            return value.statusName === 'Activa';
        }
    );

    if (alert) {
        return state.merge({
            endDate: alert.dateCreation,
            stockName: alert.instrument,
            exchangeName: alert.exchangeName,
            spcSymbol: alert.spcSymbol,
            conditionName: alert.conditionName,
            conditionValue: alert.conditionValue,
            conditionId: alert.conditionId,
            exchangeId: alert.exchangeId,
            isFetching: false
        });
    } else {
        return state = initialState;
    }
}

function brokerProductFileReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_ALERTS_BUY:
            return generateBuyButtonUrl(state, action.payload.response);
        case actionTypes.BROKER_GET_ALERTS_FETCHING:
            return state.set('isFetching', true)
                        .set('error', false);
        case actionTypes.BROKER_GET_ALERTS_FAILURE:
            return state.set('isFetching', false)
                        .set('error', action.payload.data);
        case actionTypes.BROKER_GET_ALERTS_SUCCESS:
            if (action.payload.data.length) {
                return prepareAlert(action.payload.data, state);
            } else {
                return state.merge({
                    isFetching: false
                });
            }
        default:
            return state;
    }
}

module.exports = brokerProductFileReducer;
