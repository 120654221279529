// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
// @reducer
const cancelCreditCardRenewalStep = require('./cancelCreditCardRenewalStep');
function setInitialState() {
     return Immutable.Map().merge({
        steps: [
            cancelCreditCardRenewalStep(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        submitSuccessful: false,
        isFetching: false,
        dataLoaded: false,
        errorReceived: false,
        cancelRenewalDetails:{},
        fetchingRenewalDetails: false,
        errorGettingRenewalDetails: false
    });
}

const initialState = setInitialState();

function cancelCreditCardRenewal(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_RESET:
            return setInitialState();
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_VALIDATE_STEP:
            return state.merge({
                visibleStep: 2
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_FAILURE:
            return state.merge({
                error: action.payload.error,
                errorReceived: true
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_CANCEL:
            return state.merge({
                willCancel: true
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_SUCCESS:
            return state.merge({
                submitSuccessful: true
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_DETAILS_REQUEST:
            return state.merge({
                fetchingRenewalDetails: true,
                cancelRenewalDetails: {},
                errorGettingRenewalDetails: false
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_DETAILS_SUCCESS:
            return state.merge({
                fetchingRenewalDetails: false,
                cancelRenewalDetails: action.payload.data.methodResult,
                errorGettingRenewalDetails: false
            });
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_DETAILS_FAILURE:
            return state.merge({
                fetchingRenewalDetails: false,
                cancelRenewalDetails: {},
                errorGettingRenewalDetails: true
            });
        default:
            return state
    }
}

module.exports = cancelCreditCardRenewal;

