const actionTypes = require('../constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isVirtualOfficesValid: false,
    isFetchingVirtualOffices: false,
    virtualOfficeRequestSuccess: false,
    virtualOfficeRequestError: false,
})

function personeticsVirtualOffices(state = initialState, action) {

    switch (action.type) {
        case actionTypes.VALIDATE_PERSONETICS_VIRTUAL_OFFICES_REQUEST:
            return state.merge({
                isFetchingVirtualOffices: true,
                virtualOfficeRequestSuccess: false,
                virtualOfficeRequestError: false
            });
        case actionTypes.VALIDATE_PERSONETICS_VIRTUAL_OFFICES_SUCCESS:
            return state.merge({
                isVirtualOfficesValid: action.payload.isFeatureActive,
                isFetchingVirtualOffices: false,
                virtualOfficeRequestSuccess: true,
                virtualOfficeRequestError: false
            });
        case actionTypes.VALIDATE_PERSONETICS_VIRTUAL_OFFICES_FAILURE:
            return state.merge({
                isVirtualOfficesValid: false,
                isFetchingVirtualOffices: false,
                virtualOfficeRequestSuccess: false,
                virtualOfficeRequestError: true
            });
        default:
            return state;
    }
}

module.exports = personeticsVirtualOffices;
