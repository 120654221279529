const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');
const { hash } = require('utilities/hash');
const { EURO_TEXT, DATE_FILTER_NONE } = require('constants/index');
const accountsSettlementsDetailsReducer = require('./accountsSettlements/accountsSettlementsDetails');

const filtersInitialState = Immutable.fromJS({
    date: {
        from: '',
        isActive: false,
        selected: DATE_FILTER_NONE,
        to: ''
    }
});

const initialState = Immutable.fromJS({
    filters: filtersInitialState
});

function parseSettlements(accountId, settlements) {
    let byId = {};
    let byOrder = [];

    settlements.map((settlement, index) => {
        const parsedSettlement = {
            accountId,
            id: hash([settlement.periodoInicial, settlement.periodoFinal, index]),
            dateFrom: settlement.periodoInicial,
            dateTo: settlement.periodoFinal,
            dueAmount: {
                amount: get(settlement, 'importeDesde.importe', 0),
                currency: get(settlement, 'importeDesde.divisa', EURO_TEXT)
            },
            creditAmount: {
                amount: get(settlement, 'importeHaber.importe', 0),
                currency: get(settlement, 'importeHaber.divisa', EURO_TEXT)
            },
            showDue: settlement.signoImporte === 'D',
            extra: {
                applicationTypeCode: settlement.codigoTipoAplicacion
            },
            details : accountsSettlementsDetailsReducer(undefined, {type: null}),
        };

        byId[parsedSettlement.id] = parsedSettlement;
        byOrder.push(parsedSettlement.id);
    });

    return {
        byOrder: byOrder,
        byId: byId
    };
}

const addMoreSettlements = (state, payload) => {
    const newSettlements = Immutable.fromJS(parseSettlements(payload.accountId, payload.settlements));

    return state.mergeIn([payload.accountId], {
        nextPage: payload.nextPage,
        byOrder: state.getIn([payload.accountId, 'byOrder']).concat(newSettlements.get('byOrder')),
        byId: state.getIn([payload.accountId, 'byId']).concat(newSettlements.get('byId')),
        contractsTable: payload.contractsTable,
        error: false,
        isFetchingNextPage: false
    });
};

function accountsSettlementsReducer(state = initialState, action) {
    let accountId;
    let settlementId;

    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_REQUEST:
            return state.set(action.payload.accountId, Immutable.fromJS({
                    nextPage: '',
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {}
            }));
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_SUCCESS:
            accountId = action.payload.accountId;
            const parsedSettlements = parseSettlements(accountId, action.payload.settlements);
            return state.set(accountId, Immutable.fromJS({
                    nextPage: action.payload.nextPage,
                    byOrder: parsedSettlements.byOrder,
                    byId: parsedSettlements.byId,
                    contractsTable: action.payload.contractsTable,
                    loading: false,
                    error: false,
            }));
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    error: action.payload.error
                }
            });
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_DETAILS_REQUEST:
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_DETAILS_SUCCESS:
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_DETAILS_FAILURE:
            accountId = action.payload.accountId;
            settlementId = action.payload.settlementId;
            const details = accountsSettlementsDetailsReducer(state.getIn([accountId, 'byId', settlementId, 'details']), action)
            return state.setIn([action.payload.accountId, 'byId', settlementId, 'details'], details);
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE:
            return state.mergeIn([action.payload.accountId], { isFetchingNextPage: true });
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE_SUCCESS:
            return addMoreSettlements(state, action.payload);
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_NEXT_PAGE_FAILURE:
            return state.mergeIn([action.payload.accountId], { isFetchingNextPage: false, error: action.payload.error });
        case actionTypes.SET_ACCOUNT_SETTLEMENTS_FILTER:
            return state.mergeDeepIn(['filters'], action.payload.filters);
        case actionTypes.ACCOUNT_SETTLEMENTS_CLEAR_FILTERS: 
            return state.mergeDeepIn(['filters'], filtersInitialState);
        default:
            return state;
    }
}

module.exports = accountsSettlementsReducer;
