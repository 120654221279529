//@ core
const Immutable = require("immutable");
//@ utilities
const get = require("lodash/object/get");
const { hash } = require("utilities/hash");
//@ constants
const actionTypes = require("constants/actionTypes");
const { EURO_TEXT, DATE_FILTER_NONE } = require("constants/index");
const { TYPES } = require("constants/movementsTypes");
const {
    CATEGORY_KEYS: { INCOME_CATEGORY },
} = require("constants/categories");
//@ subreducers
const accountTransactionsDetailsReducer = require("./accountsTransactions/accountsTransactionsDetails");
const accountTransactionsReceiptsPreviewReducer = require("./accountsTransactions/accountTransactionsReceiptsPreview");

const initialState = Immutable.fromJS({
    filters: {
        reset: {
            isActive: false,
        },
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null,
        },
        description:{
            isActive: false,
            selected: ''
        },
        amount: {
            isActive: false,
            from: {
                amount: null,
                currency: EURO_TEXT,
            },
            to: {
                amount: null,
                currency: EURO_TEXT,
            },
        },
        movementType: {
            isActive: false,
            movementType: TYPES.ALL,
        },
        concept: {
            isActive: false,
            concept: TYPES.ALL,
        },
    },
});

function parseMovements(payload) {
    let byId = {};
    let byOrder = [];
    const { accountId, movements, repagination } = payload;

    movements.forEach((movement) => {
        const category = get(movement, "categoriaGanadora.category", "");
        const subcategory = get(movement, "categoriaGanadora.subcategory", "");

        const categoryCode = get(
            movement,
            "categoriaGanadora.categoryCode",
            ""
        );
        const subCategoryCode = get(
            movement,
            "categoriaGanadora.subcategoryCode",
            ""
        );

        const parsedMovement = {
            accountId,
            isOutdated: false,
            details: accountTransactionsDetailsReducer(undefined, {
                type: null,
            }),
            extra: {
                movementNumber: movement.diaMvto,
                situationId: repagination.situacion,
            },
            canBeRecategorized: !get(movement, "categoriaGanadora.online"),
            category,
            subcategory,
            categoryCode,
            subCategoryCode,
            description: movement.conceptoTabla || "",
            date: movement.fechaOperacion,
            isReceipt: movement.recibo,
            contractNumber: get(
                movement,
                "contratoPrincipalOperacion.numerodecontrato",
                0
            ),
            product: get(movement, "contratoPrincipalOperacion.producto", 0),
            centro: get(movement, "contratoPrincipalOperacion.centro.centro"),
            // can be 'S', 'N' o 'F' (yes, no, financed),
            nummov: movement.nummov,
            operacionDGO: movement.operacionDGO,
            finance: movement.indFinanciacion,
            balance: {
                amount: get(movement, "importe.importe", 0),
                currency: get(movement, "importe.divisa", EURO_TEXT),
            },
            totalBalance: {
                amount: get(movement, "saldo.importe", 0),
                currency: get(movement, "saldo.divisa", EURO_TEXT),
            },
            showPendingValoration:
                category.toLowerCase() === INCOME_CATEGORY.toLowerCase()
                    ? movement.pendienteValoracion
                    : false,
            valueDate: movement.fechaValor,
            isExpanded: movement.selected,
        };

        // Hashed id
        parsedMovement.id = hash([
            parsedMovement.extra.movementNumber,
            parsedMovement.date,
            parsedMovement.description,
            parsedMovement.balance.amount,
            parsedMovement.totalBalance.amount,
            parsedMovement.valueDate,
        ]);

        byId[parsedMovement.id] = parsedMovement;
        byOrder.push(parsedMovement.id);
    });

    return {
        byId,
        byOrder,
    };
}

function checkFilters(filters) {
    const movementType = get(filters, "movementType.movementType");
    const concept = get(filters, "concept.concept");
    const fromAmount = get(filters, "amount.from.amount");
    const toAmount = get(filters, "amount.to.amount");

    // @TODO: we need to remove this hardcoded 0 and 9999999.99 and improve this logic
    const hasAmountFilters =
        (fromAmount !== null && fromAmount !== 0) ||
        (toAmount !== null && toAmount !== 9999999.99);
    const movementTypeSelected =
        movementType !== null && movementType !== TYPES.ALL;
    const conceptSelected = concept !== null && concept !== TYPES.ALL;

    const response = {
        conceptSelected,
        dateFilterSelected: get(filters, "date.selected") !== DATE_FILTER_NONE,
        hasAmountFilters,
        movementTypeSelected,
    };

    return response;
}

function accountsTransactionReducer(state = initialState, action) {
    let filters;
    let currentMovements;
    let parsedMovements;
    let nextPage;
    let scaRequired;
    let byOrder;
    let byId;

    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    isOutdated: false,
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {},
                    scaRequired: false,
                },
            });

        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_SUCCESS: {
            parsedMovements = parseMovements(action.payload);

            // If SCA is required the next page has to be the selfPage
            scaRequired = action.payload.scaRequired;
            nextPage = scaRequired
                ? action.payload.self
                : action.payload.nextPage;

            return state.set(
                action.payload.accountId,
                Immutable.fromJS({
                    selfPage: action.payload.nextPage,
                    nextPage,
                    byOrder: parsedMovements.byOrder,
                    byId: parsedMovements.byId,
                    loading: false,
                    error: false,
                    scaRequired: false,
                    scaRequiredAccountModal: scaRequired,
                })
            );
        }

        case actionTypes.SET_ACCOUNT_SCA_MODAL_SHOW: {
            return state.mergeDeep({
                [action.payload.accountId]: {
                    scaRequired: true,
                },
            });
        }
        case actionTypes.FETCH_NEXT_ACCOUNT_TRANSACTIONS_PAGE_SUCCESS: {
            currentMovements = state.get(action.payload.accountId);
            parsedMovements = parseMovements(action.payload);

            // If SCA is required the next page has to be the selfPage
            scaRequired = action.payload.scaRequired;

            nextPage = currentMovements.get("scaRequired")
                ? action.payload.self
                : action.payload.nextPage;
            const selfPage = !action.payload.scaRequired
                ? action.payload.nextPage
                : currentMovements.get("selfPage");
            byOrder = currentMovements.get("scaRequired")
                ? currentMovements.get("byOrder")
                : currentMovements
                      .get("byOrder")
                      .concat(Immutable.fromJS(parsedMovements.byOrder));
            byId = currentMovements.get("scaRequired")
                ? currentMovements.toJS().byId
                : parsedMovements.byId;

            // Remove duplicates
            byOrder = byOrder.toJS().filter((movement, index) => {
                return byOrder.toJS().indexOf(movement) === index;
            });

            return state.mergeDeep({
                [action.payload.accountId]: {
                    selfPage,
                    nextPage,
                    byOrder,
                    byId,
                    isFetchingNextPage: false,
                    error: false,
                    scaRequired: false,
                    scaRequiredAccountModal: scaRequired,
                },
            });
        }

        case actionTypes.FETCH_NEXT_ACCOUNT_TRANSACTIONS_PAGE_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    isFetchingNextPage: true,
                    loading: false,
                    error: false,
                    scaRequired: false,
                },
            });

        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    error: action.payload.data,
                    isFetchingNextPage: false,
                    scaRequired: false,
                },
            });

        case actionTypes.RESET_ACCOUNT_TRANSACTIONS_FILTER:
            const { filterKey } = action.payload;
            return state.setIn(
                ["filters", filterKey],
                initialState.getIn(["filters", filterKey])
            );

        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_FILTER:
            filters = action.payload.filters;
            const {
                dateFilterSelected,
                hasAmountFilters,
                movementTypeSelected,
                conceptSelected,
            } = checkFilters(filters);

            const partial = state.mergeDeep({
                filters,
            });

            return partial.mergeDeep({
                filters: {
                    reset: {
                        isActive:
                            !dateFilterSelected &&
                            !hasAmountFilters &&
                            !movementTypeSelected &&
                            !conceptSelected,
                    },
                    date: {
                        isActive: dateFilterSelected,
                    },
                    amount: {
                        isActive: hasAmountFilters,
                    },
                    movementType: {
                        isActive: movementTypeSelected,
                    },
                    concept: {
                        isActive: conceptSelected,
                    },
                },
            });

        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_FILTER_CLEAR:
            return state.mergeDeep({
                filters: initialState.get("filters"),
            });

        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DETAILS_REQUEST:
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DETAILS_SUCCESS:
        case actionTypes.FETCH_ACCOUNT_TRANSACTIONS_DETAILS_FAILURE: {
            const { accountId, transactionId } = action.payload;
            const details = accountTransactionsDetailsReducer(
                state.getIn[(accountId, "byId", transactionId, "details")],
                action
            );
            return state.mergeDeep({
                [action.payload.accountId]: {
                    byId: {
                        [action.payload.transactionId]: {
                            details,
                        },
                    },
                },
            });
        }

        case actionTypes.FETCH_RECEIPT_PREVIEW_SUCCESS:
        case actionTypes.FETCH_RECEIPT_PREVIEW_FAILURE:
        case actionTypes.FETCH_RECEIPT_PREVIEW_REQUEST: {
            const { accountId, transactionId } = action.payload;
            const preview = accountTransactionsReceiptsPreviewReducer(
                state.getIn[(accountId, "byId", transactionId, "preview")],
                action
            );
            return state.mergeDeep({
                [action.payload.accountId]: {
                    byId: {
                        [action.payload.transactionId]: {
                            preview,
                        },
                    },
                },
            });
        }

        case actionTypes.FETCH_RECEIPT_DOWNLOAD_SUCCESS: {
            const { accountId, transactionId } = action.payload;
            return state.mergeDeep({
                [accountId]: {
                    byId: {
                        [transactionId]: {
                            preview: {
                                downloading: false,
                            },
                        },
                    },
                },
            });
        }

        case actionTypes.FETCH_RECEIPT_DOWNLOAD_FAILURE: {
            const { accountId, transactionId } = action.payload;
            return state.mergeDeep({
                [accountId]: {
                    byId: {
                        [transactionId]: {
                            preview: {
                                downloading: false,
                                errorDownloading: action.payload.error,
                            },
                        },
                    },
                },
            });
        }

        case actionTypes.FETCH_RECEIPT_DOWNLOAD_REQUEST: {
            const { accountId, transactionId } = action.payload;
            return state.mergeDeep({
                [accountId]: {
                    byId: {
                        [transactionId]: {
                            preview: {
                                downloading: true,
                            },
                        },
                    },
                },
            });
        }

        case actionTypes.ACCOUNT_TRANSACTIONS_CHANGE_CATEGORY:
            return state.mergeDeepIn(
                [
                    action.payload.accountId,
                    "byId",
                    action.payload.transactionId,
                ],
                Immutable.fromJS({
                    category: action.payload.category
                        .toString()
                        .includes("category")
                        ? action.payload.category
                        : `category_${action.payload.category}`,
                    subcategory: action.payload.subcategory
                        .toString()
                        .includes("category")
                        ? action.payload.subcategory
                        : `category_${action.payload.category}_${action.payload.subcategory}`,
                })
            );

        case actionTypes.ACCOUNT_TRANSACTIONS_OUTDATED:
            return state.map((item, key) => {
                if (key !== "filters") {
                    return item.set("isOutdated", true);
                }

                return item;
            });

        default:
            return state;
    }
}

module.exports = accountsTransactionReducer;
