const Immutable = require('immutable');
const _ = require('underscore');
const moment = require('moment');
const actionTypes = require('constants/actionTypes');
const { getAmountCreditCardsHistogram, getAmountDebitAccountsHistogram } = require('utilities/expenses');

let hasCredit = false;
let hasDebit = false;

function setInitialState() {
    let state = Immutable.fromJS({
        isFetching: false,
        isPartialFetching: false,
        totalExpensesCustomDate: 0,
        error: '',
        filters: {
            type: 'both', //credit or debit ot both
            group: 'M', //group for days or month
            category:''
        },
        contracts: {
            credit: true,
            debit:true
        },
        histogramData: [],
        lineData: []
    });
    return state;
}

// function to create data for bars
function buildHistogram(expensesHistogram, state, period, type, hashCards, from, to) {
    let histogramData = [];
    if (expensesHistogram.count > 0) {
        histogramData = [];
        const day= moment().format('DD');
        const orderExpensesHistogram = _.sortBy( expensesHistogram.subgroupMap, function( item ) { return item.value; }); //order by dates values
        let firstMomentYear;
        let firstMomentMonth;
        let difference = 11; //default 12 months always
        let valueArray = 0;
        let debitValue = 0;
        let creditValue = 0;
        let creditDebitLastYear = 0;
        let arrayYear = moment().endOf('month').diff(moment().startOf('year'), 'months') >= 11
        ?
        [parseInt(moment().format('YYYY'))]
        :
        [parseInt(moment().subtract(1, 'year').format('YYYY')), parseInt(moment().format('YYYY'))]; //aray with current and lasy year (default configuration)

        if (from === undefined || to === undefined || from === '' || to === '') {
            firstMomentYear = parseInt(arrayYear[valueArray]); //year number
            firstMomentMonth = parseInt(moment().subtract(11, 'month').format('MM')); //last year month number
        } else {
            arrayYear = []; //delete default array with years
            firstMomentMonth = parseInt(from.format('MM'));
            difference = Math.abs(parseInt(from.diff(to, 'months'))); //difference between the two dates
            const differenceArray= Math.round(difference / 12) + 2;
            for (let i = 0; i < differenceArray; i++) { arrayYear.push(parseInt(from.format('YYYY')) + i);} // create new array with all the years
            firstMomentYear = arrayYear[valueArray]; //default value for the first year of the bar chart
        }

        if (period === 'M') { // if period are all the months
            for (let i = 0; i < difference + 1; i++) {
                let monthData = firstMomentMonth;
                const yearData = firstMomentYear;
                monthData = monthData < 10 ? '0'+monthData.toString() : monthData.toString();
                const dataMonth =  _.findWhere(orderExpensesHistogram,{value:`${yearData}-${monthData}`});
                const dataLastYearMonth =  _.findWhere(orderExpensesHistogram,{value:`${yearData - 1}-${monthData}`})

                 //if it finds same dates value we add to the bar chart, if not we include an empty bar
                if (dataMonth) {
                    debitValue = getAmountDebitAccountsHistogram(dataMonth, hashCards);
                    creditValue = getAmountCreditCardsHistogram(dataMonth, hashCards);
                }

                if (dataLastYearMonth) {
                    creditDebitLastYear = getAmountDebitAccountsHistogram(dataLastYearMonth, hashCards) + getAmountCreditCardsHistogram(dataLastYearMonth, hashCards);
                }

                // variables to show the legend or not
                if (debitValue > 0 && hasDebit === false) { hasDebit = true;}
                if (creditValue > 0 && hasCredit === false) { hasCredit = true;}

                histogramData.push( //crete the bar with the name, amounts and the current year
                    {
                        name: moment(`${yearData}-${monthData}-01`).format('MMMM'),
                        TotalDebit: debitValue,
                        TotalCredit: creditValue,
                        TotalLastYear: creditDebitLastYear,
                        year: yearData
                    }
                )
                // Calculate when need to change the month for bars and the years.
                firstMomentMonth += 1;
                valueArray = firstMomentMonth <= 12  ? valueArray : valueArray += 1;
                firstMomentYear = arrayYear[valueArray]; //using the arrays for years
                firstMomentMonth = firstMomentMonth <= 12  ? firstMomentMonth : 1;
                debitValue = 0;
                creditValue = 0;
            }

        } else { // if period are the days of the current month
            for (let i = 0; i < day; i++ ) { //usea current day to the loop
                let day = i + 1;
                day = day < 10 ? '0'+day.toString() : day.toString();
                const dataDay = _.findWhere(orderExpensesHistogram,{value:`${moment().format('YYYY-MM')}-${day}`});
                const dataLastYearDay = _.findWhere(orderExpensesHistogram,{value:`${moment().subtract(1, 'year').format('YYYY-MM')}-${day}`});
                if (dataDay) {
                    debitValue = getAmountDebitAccountsHistogram(dataDay, hashCards);
                    creditValue = getAmountCreditCardsHistogram(dataDay, hashCards);
                }

                if(dataLastYearDay) {
                    creditDebitLastYear = getAmountDebitAccountsHistogram(dataLastYearDay, hashCards) + getAmountCreditCardsHistogram(dataLastYearDay, hashCards);
                }

                histogramData.push(
                    {
                        name: moment(`${moment().format('YYYY-MM')}-${day}`).format('DD'),
                        TotalDebit: debitValue,
                        TotalCredit: creditValue,
                        TotalLastYear: creditDebitLastYear
                    }
                )
                debitValue = 0;
                creditValue = 0;
            }
        }
    }
    return histogramData;
}

function updateExpensesHistogram(state, expensesHistogram, period, category, type, hashCards, from, to) {
    let newState = state.set('histogramData', buildHistogram(expensesHistogram, state, period, type, hashCards, from, to))
                        .set('isFetching', true)
                        .setIn(['filters', 'group'], period)
                        .setIn(['filters', 'category'], category)
                        .setIn(['contracts', 'credit'], hasCredit)
                        .setIn(['contracts', 'debit'], hasDebit);
    return(newState);
}

function updateExpensesCurrentDate(state, response) {
    let newState = state.set('totalExpensesCustomDate', Math.abs(response));
    return(newState);
}

function expensesHistogram(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.HISTOGRAM_EXPENSES_RESET:
            return setInitialState();
        case actionTypes.GLOBAL_POSITION_EXPENSES_TOTAL_REQUEST:
            return state.merge({
                isFetching: false
            });
        case actionTypes.GLOBAL_POSITION_EXPENSES_TOTAL_CURRENT_DATE:
            return updateExpensesCurrentDate(state, action.payload.response.total);
        case actionTypes.GLOBAL_POSITION_EXPENSES_TOTAL_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });

        case actionTypes.GLOBAL_POSITION_EXPENSES_TOTAL_SUCCESS:
            return updateExpensesHistogram(state,
                action.payload.expensesTotal,
                action.payload.period,
                action.payload.category,
                action.payload.type,
                action.payload.hashCards,
                action.payload.from,
                action.payload.to
            );

        default:
            return state;
    }
}

module.exports = expensesHistogram;
