// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

// @ constants
const { CHORE_MAX_AMOUNT, CHORE_MIN_AMOUNT } = require('constants/index');

function setInitialState() {
    return Immutable.Map({
        valid: false,
        remarkIsDirty: false,
        fromAccount:'',
        fromAccountisDirty: false,
        amount:0,
        amountisDirty: false,
        remark: '',
        cardAvailableBalance: 0,
        accountAvailableBalance: 0
    });
}

function validateStep(step) {
    const isAmountGreaterOrEqualToMinAmount = step.get('amount') >= CHORE_MIN_AMOUNT;
    const isAmountLessOrEqualToMaxAmount = step.get('amount') <= (CHORE_MAX_AMOUNT - step.get('cardAvailableBalance'));
    const isAmountLessThanAvailableBalance  = step.get('amount') <= step.get('accountAvailableBalance');
    const validAmount = isAmountGreaterOrEqualToMinAmount && isAmountLessOrEqualToMaxAmount && isAmountLessThanAvailableBalance;
   
    return step.merge({
        valid: validAmount,
        remarkIsDirty: true,
        amountisDirty: true,
        fromAccountisDirty: true
    });
}

function openYoungCardPaymentChoreStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_REMARK:
            return state.merge({
                remark: action.payload.remark
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountisDirty: action.payload.amountisDirty
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT:
            return state.merge({
                fromAccount: action.payload.fromAccount,
                fromAccountisDirty: true
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_CARD_AVAILABLE_BALANCE:
            return state.merge({
                cardAvailableBalance: action.payload.cardAvailableBalance
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT_AVAILABLE_BALANCE:
            return state.merge({
                accountAvailableBalance: action.payload.accountAvailableBalance
            });
        default:
            return state;
    }
}

module.exports = openYoungCardPaymentChoreStep1;
