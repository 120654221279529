// @vendors
const Immutable = require('immutable');
const _ = require('underscore');

// @constants
const {
    BROKER_STOCKS_TYPE_FOOTBALL,
    BROKER_STOCKS_TYPE_SOCIAL_MEDIA
} = require('constants/brokerKnowTheseStocks');
const MICCODE_CONVERSION = require('constants/miccodes');
const actionTypes = require('constants/actionTypes');

// @helpers
const sanitizeHelper = require('utilities/sanitizeHelper');

function defaultStockTypeState() {
   return Immutable.fromJS({
       isFetching: false,
       stocksResponseList: [],
       totalNumberOfStocks: 0,
       error:false
   }); 
}

function parseStockData(stock) {
   
    const instrumentId = stock.instrumentId.trim();
    const miccode = stock.miccode.trim();
    const market = MICCODE_CONVERSION.find(function(market){return market.MIC == miccode});
    const newStockObject =  Object.assign({}, {
                key: instrumentId + miccode,
                country: {
                    id: stock.country.id.trim(),
                    name: stock.country.name.trim()
                },
                currencyCode:  stock.currencyCode.trim(),
                instrument: sanitizeHelper.sanitizeData(stock.instrument.trim()),
                instrumentId: instrumentId,
                last: stock.last,
                localCode: stock.localCode.trim(),
                market: market,
                marketName: stock.marketName.trim(),
                miccode: miccode,
                tradeDate: stock.tradeDate.trim(),
                tradeTime: stock.tradeTime.trim(),                    
                netChangePercentage: stock.netChangePercentage,
                netChangePrice: stock.netChangePrice
            });

    return Immutable.fromJS(newStockObject);

}

function storeStockDetailSuccess(immStockDetail, response) {
    let stocksResponseList = [];

    if (response && response.length > 0) {

        //Eliminate record with exchangeId 0 since it doesn't have any meaningful business data
        response = _.without(response, _.findWhere(response, {
                exchangeId: 0
            }));

        stocksResponseList = response.map(function(stock) {
            return parseStockData(stock);
        });
    }

    return immStockDetail.merge({
        isFetching : false,
        totalNumberOfStocks: stocksResponseList.length,
        stocksResponseList
    });
}

const initialState = Immutable.fromJS({
    [BROKER_STOCKS_TYPE_FOOTBALL]: defaultStockTypeState(),
    [BROKER_STOCKS_TYPE_SOCIAL_MEDIA]: defaultStockTypeState(),
});

function brokerKnowTheseStocksReducer(state = initialState, action) {
    let immStockDetail;
    switch (action.type) {
        case actionTypes.BROKER_KNOW_THESE_STOCKS_FETCHING:
            return state.mergeIn([action.payload.stockType], {
                isFetching: true
            });
        case actionTypes.BROKER_KNOW_THESE_STOCKS_SUCCESS:
            immStockDetail = state.get(action.payload.stockType);
            return state.mergeIn([action.payload.stockType], storeStockDetailSuccess(immStockDetail, action.payload.response));
        case actionTypes.BROKER_KNOW_THESE_STOCKS_FAILURE:
            return state.mergeIn([action.payload.stockType], {
                error:true, isFetching: false
            });
        default:
            return state;
    }
}

module.exports = brokerKnowTheseStocksReducer;
