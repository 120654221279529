// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/actionTypes');

// @ utilities
const DateHelper = require('utilities/dateHelper');
// @actions
const { hasActivePeriodicalContributions } = require('actions/brokerPensionPlanPeriodicalContribution');

const initialState = () => {
    return Immutable.fromJS({
        periodicalContributionList: {},
        isFetching: false,
        hasPlans: false,
        hasPeriodicalPlans: false,
        isBeforeClosingTime: false,
        minDate: moment().startOf('day'),
        error: false,
        success: false,
        submitCancelSuccesful: false,
        cancelDataPlan: {},
        validationData: null,
        activeAportation: false,
        details: {
            isFetching: false,
            error: false,
            success: false,
            data: {}
        }
    });
};

const addDetails = (producto, numeroContrato, timeStamp, data, state) => {
    const periodicalContributionList = state.get('periodicalContributionList');
    const updateList = periodicalContributionList.map(item => {
        if(item.getIn(['contrato','producto']) === producto &&
            item.getIn(['contrato','numerodecontrato']) === numeroContrato &&
            item.getIn(['planAportacion','timestampAlta']) === timeStamp)
                  return item.merge({detalles: data})
        return item;
    })

    return updateList;
}

const brokerPensionPlanPeriodicalContribution = (state = initialState(), action) => {
    switch (action.type) {
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_CANCEL_SUCCESS:
            return state.merge({
                submitCancelSuccesful: true,
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST:
            return state.merge({
                isFetching: true,
                activeAportation: false
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS:
            const hasPeriodicalPlans = action.payload.data.aportacionesPeriodicas.length > 0 ? true : false;
            const isBeforeClosingTime = action.payload.data.isBeforeClosingTime;
            const timestamp = action.payload.data.timeResponse ? action.payload.data.timeResponse.timestamp : null;
            const minDate = DateHelper.getMinDateAvailable(isBeforeClosingTime, timestamp);
            const planId = action.payload.planId;
            return state.merge({
                periodicalContributionList: action.payload.data.aportacionesPeriodicas,
                hasPlans: action.payload.data.hasPlans,
                hasPeriodicalPlans: hasPeriodicalPlans,
                success: true,
                isFetching: false,
                isBeforeClosingTime: isBeforeClosingTime,
                minDate: minDate,
                activeAportation: hasPeriodicalPlans && planId ? hasActivePeriodicalContributions(action.payload.data.aportacionesPeriodicas, planId) : false
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR:
            return state.merge({
                error: true,
                isFetching: false
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_IS_STORED:
            return state.mergeDeep({
                details: {
                    data: action.payload.detailsStored
                }
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_REQUEST:
            return state.mergeDeep({
                details: {
                    isFetching: true,
                    success: false,
                    error: false,
                    data: {}
                }
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_SUCCESS:
            const updateList = addDetails(action.payload.producto, action.payload.numeroContrato,
                                action.payload.timeStamp, action.payload.data, state);
            return state.mergeDeep({
                periodicalContributionList: updateList,
                details: {
                    success: true,
                    isFetching: false,
                    data: action.payload.data
                }
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_ERROR:
            return state.mergeDeep({
                details: {
                    error: true,
                    isFetching: false
                }
            });
        case actionTypes.BROKER_PENSION_PLAN_PERIODICAL_VALIDATION_ERROR:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.BROKER_PENSION_PLAN_PERIODICAL_CONTRIBUTION_ERROR:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_PENSION_PLAN_CANCEL_VALIDATION_SUCCESS:
            const validationCancelData = action.payload.response;
            return state.merge({
                validationData: {
                    cuentaAsociada: validationCancelData.cuentaAsoc,
                    cuentaGrupo: validationCancelData.cuentaGrupo,
                    descripcion: validationCancelData.descripcion,
                    fecProxAportacion: validationCancelData.fecProxAportacion,
                    fecProxRevalo: validationCancelData.fecProxRevalo,
                    frecuenciaCuotaCada: validationCancelData.frecuenciaCuotaCada,
                    frecuenciaCuotaUnidadDeTiempo: validationCancelData.frecuenciaCuotaUnidadDeTiempo,
                    importeOPorcentajeReval: validationCancelData.importeOPorcentajeReval,
                    nomTitular: validationCancelData.nomTitular,
                    situacion: validationCancelData.situacion,
                    tipoPlanBaja: validationCancelData.tipoPlanBaja,
                    tipoRevalCuota: validationCancelData.tipoRevalCuota
                },
                inProgress: true,
                isFetching: false
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_RESET:
            return initialState();
        default:
            return state;
    }
};

module.exports = brokerPensionPlanPeriodicalContribution;
