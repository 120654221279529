// @ vendors
const { fromJS } = require('immutable');
// @ constants
const {
    AUTHORIZATION_MODIFY_ACCOUNT_CHANGE_SELECTED,
    AUTHORIZATION_MODIFY_ACCOUNT_FAILURE,
    AUTHORIZATION_MODIFY_ACCOUNT_RESET,
    AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS,
    AUTHORIZATION_MODIFY_ACCOUNT_SET_VIVIBLE_STEP,
    AUTHORIZATION_MODIFY_ACCOUNT_VALIDATE_STEP
} = require('constants/actionTypes');

const initialState = fromJS({
    steps: [
        {},
        { hasSignaturePattern: true }
    ],
    errorAccount: false,
    errorReceived: '',
    visibleStep: 1,
    submitSuccessful: false,
})

const authorizationModifyAccount = (state = initialState, action) => {
    switch (action.type) {
        case AUTHORIZATION_MODIFY_ACCOUNT_CHANGE_SELECTED:
            return state.merge({
                accountId: action.accountId,
            });
        case AUTHORIZATION_MODIFY_ACCOUNT_FAILURE:
            return state.merge({
                errorReceived: action.error,
                submitSuccessful: true
            });
        case AUTHORIZATION_MODIFY_ACCOUNT_RESET:
            return initialState;
        case AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS:
            return state.merge({
                submitSuccessful: true
            });
        case AUTHORIZATION_MODIFY_ACCOUNT_SET_VIVIBLE_STEP:
            return state.merge({
                visibleStep: action.step
            })
        case AUTHORIZATION_MODIFY_ACCOUNT_VALIDATE_STEP:
            let visibleStep;
            switch (state.get('visibleStep')) {
                case 1:
                    visibleStep = !!state.get('accountId') ? 2 : 1;
                    break;
                case 2:
                    visibleStep = 2;
                    break;
                default:
                    visibleStep = 1;
            }
            return state.merge({
                errorAccount: !state.get('accountId'),
                visibleStep,
            });
        default:
            return state;
    }
};

module.exports = authorizationModifyAccount;
