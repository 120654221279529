// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: !!__CONTACT_CENTER__,
        disclaimerChecked: !!__CONTACT_CENTER__,
        disclaimerInvalid: false,
        showWarning: false,
        allDocsRead: false
    });
}

function validateStep(step) {
    const disclaimerChecked = step.get('disclaimerChecked');

    return step.merge({
        disclaimerInvalid: !disclaimerChecked,
        valid: disclaimerChecked
    });
}

const setDocumentsRead = (state) => {
    return state.set('allDocsRead', true);
};

function toggleDisclaimer(step) {
    if (step.get('allDocsRead')) {
        return step.set('disclaimerChecked', !step.get('disclaimerChecked'))
            .set('disclaimerInvalid', false)
            .set('showWarning', false);
    }
    else {
        return step.set('showWarning', true);
    }
}

function wizardSuscriptionStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_HIRE_PENSION_PLAN_TOGGLE_DISCLAIMER:
            return validateStep(toggleDisclaimer(state));
        case actionTypes.BROKER_HIRE_PENSION_PLAN_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_HIRE_PENSION_PLAN_DOCUMENTS_READ:
            return setDocumentsRead(state);
    }

    return state;
}

module.exports = wizardSuscriptionStep2;
