// @vendors
const Immutable = require('immutable');

// @utils

const { formatText } = require('core/i18n').i18n;

// @constants
const actionTypes =  require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        initialContactTime: '',
        finalContactTime: '',
        error: ''
    });
}

function validateStep(step) {
    let valid = false;
    let error = '';
    const {initialContactTime, finalContactTime} = step.toJS();

    if(initialContactTime && finalContactTime && (initialContactTime.length === 5 && finalContactTime.length === 5)){
        if(initialContactTime < finalContactTime){
            valid = true;
            error = '';
        }
    }

    if(!valid){
        if(initialContactTime.length !== 5 || finalContactTime.length !== 5){
           error = formatText('updateClientContactTimeStep1-inlineErrorIncompleteFields');
        }
        else if(initialContactTime >= finalContactTime){
           error = formatText('updateClientContactTimeStep1-inlineErrorTimeUnexpected');
        }
    }

    return step.merge({
        valid,
        error
    });
}

function updateClientContactTimeStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.UPDATE_CONTACT_TIME_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.UPDATE_CONTACT_TIME_FROM_TIME:
            return state.merge({
                initialContactTime: action.payload.fromTime.slice(0,5)
            });
        case actionTypes.UPDATE_CONTACT_TIME_TO_TIME:
            return state.merge({
                finalContactTime: action.payload.toTime.slice(0,5)
            });
        default:
            return state;
    }
}

module.exports = updateClientContactTimeStep1;
