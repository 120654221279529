const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const trim = require('lodash/string/trim');

const MIN_IMPORT = 25;

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        isDirty: false,
        import: 0,
        importMin: MIN_IMPORT,
        importMax: 60000,
        method: '',
        methodDescription: ''
    });
}

function validateStep(step) {
    const importValue = step.get('import');
    let valid;

    if (importValue < step.get('importMin')) {
        valid = false;
    } else if (importValue > step.get('importMax'))  {
        valid = false;
    } else if (parseFloat(importValue) % 25 !== 0) {
        valid = false;
    } else {
        valid = true;
    }

    return step.merge({
        valid,
        isDirty: true
    });
}

function cardPayLaterStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_GET_PAY_LATER_INFORMATION_SUCCESS:
            return state.merge({
                method: action.payload.formaDePagoActual,
                methodDescription: trim(action.payload.descFormPagoActual),
                limitsData: action.payload.limitsData
            });
        case actionTypes.CARD_PAY_LATER_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_PAY_LATER_IMPORT_SET:
            let newState = state.merge({
                import: action.payload.import,
                isDirty: true
            });
            return validateStep(newState);
        case actionTypes.CARD_PAY_LATER_IMPORT_SET_MAX_MIN:
            return state.merge({
                importMin: action.payload.min,
                importMax: action.payload.max
            })
        default:
            return state;
    }
}

module.exports = cardPayLaterStep1;
