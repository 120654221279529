const DateHelper = require('utilities/dateHelper');
const { toCamelCase } = require('utilities/stringHelper');
const Immutable = require('immutable');
const { formatText } = require('core/i18n').i18n;

const tableMovementsHelper = {

    /**
     * Goes through the array of transactions, classifying them by their date
     * @param  {Immutable List} immItems The transaction list
     * @return {Object}   and object with the current year items, and the old ones
     */
    classifyItemsByDate(immItems, dateFieldName = 'date', handleElementsWithoutDate = false) {
        const date = new Date();
        const currentYear = date.getFullYear();
        const currentMonth = DateHelper.getMonth(date);

        const withoutDate = item => {
            const itemDate = item.get(dateFieldName);
            return  handleElementsWithoutDate && !itemDate;
        };

        const isRecent = item => {
            const itemDate = item.get(dateFieldName);
            return !withoutDate(item) && (DateHelper.isToday(itemDate) || DateHelper.isYesterday(itemDate));
        };

        const isInOldYear = item => {
            const itemYear = DateHelper.getFullYear(item.get(dateFieldName));
            return !withoutDate(item) && (itemYear !== currentYear);
        };

        const isInCurrentMonth = item => {
            const itemMonth = DateHelper.getMonth(item.get(dateFieldName));
            const itemYear = DateHelper.getYear(item.get(dateFieldName));
            return !withoutDate(item) && !isRecent(item) && (itemMonth === currentMonth) && (itemYear === currentYear.toString().substring(2));
        };

        const isInCurrentYear = item => {
            const itemYear = DateHelper.getFullYear(item.get(dateFieldName));
            return !withoutDate(item) && !isRecent(item) && !isInCurrentMonth(item) && (itemYear === currentYear);
        };

        const monthGrouper = item => {
            const itemMonth = DateHelper.getMonth(item.get(dateFieldName));
            return toCamelCase(DateHelper.getMonthFullName(itemMonth));
        };

        const yearGrouper = item => {
            return DateHelper.getFullYear(item.get(dateFieldName));
        };

        const thisMonthKey = formatText('myMoneyTransactions-thisMonth');
        const itemsWithoutDate = formatText('myMoneyTransactions-withoutDate');

        return {
            immRecentItems: immItems.filter(isRecent),
            immCurrentMonthItems: Immutable.Map().set(thisMonthKey, immItems.filter(isInCurrentMonth)),
            immCurrentYearItems: immItems.filter(isInCurrentYear).groupBy(monthGrouper),
            immOldYearItems: immItems.filter(isInOldYear).groupBy(yearGrouper),
            immItemsWithoutDate: Immutable.Map().set(itemsWithoutDate, immItems.filter(withoutDate)),
        }
    }
}

module.exports = tableMovementsHelper;
