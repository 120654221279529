// @vendors
const Immutable = require('immutable');

// @constants
const {
    CARDS_CANCEL_DEFER_PURCHASE_VALIDATE_STEP,
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    valid: false
});

function cardsCancelDeferPurchaseStep1Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CARDS_CANCEL_DEFER_PURCHASE_VALIDATE_STEP:
            return state.set('valid', true);
        default:
            return state;
    }
}

module.exports = cardsCancelDeferPurchaseStep1Reducer;
