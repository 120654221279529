const Immutable = require('immutable');

const {
    CARD_HISTORY_REQUEST_IN_PROGRESS,
    CARD_HISTORY_REQUEST_SUCCESS,
    CARD_HISTORY_REQUEST_FAILURE,
    CARD_HISTORY_SET_VALUE
} = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isFetching: false,
    hasBeenFetch: false,
    data: {},
    error: null,
    domicilio: {}
});

const latestCardHistory = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CARD_HISTORY_REQUEST_IN_PROGRESS:
            return INITIAL_STATE.merge({
                isFetching: true,
            });
        case CARD_HISTORY_REQUEST_SUCCESS:
            return state.merge({
                isFetching: false,
                hasBeenFetch: true,
                data: action.payload.data,
            });
        case CARD_HISTORY_REQUEST_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case CARD_HISTORY_SET_VALUE:
            return state.merge({
                [action.payload.key]: action.payload.value
            })
        default:
            return state;
    }
}

module.exports = latestCardHistory;
