const fioc = require('../../fioc/fioc');

module.exports = {
    title: 'contractsView-yourActivityTitle',
    subtitle: 'contractsView-yourActivitySubTitle',
    description: 'contractsView-yourActivityDescription',
    order: 3,
    footer_text: '',
    position: 'middle',
    on_back_click: 'checkingPayrollAccount_onBackClickedFioc',
    on_confirm_back_click: 'checkingPayrollAccount_onConfirmBackClickedFioc',
    on_confirm_back_title: 'contractsView-contractsView_checkingPayrollAccountStep2BackConfirm',
    on_confirm_back_description: 'contractsView-contractsView_checkingPayrollAccountStep2BackDescription',
    components: [
        {
            id_component: 'custom-error-message',
            id: 'step2-validation-error',
            chekcIds: ['step2-validation-error'],
            type: 'error',
            msg_error:'contractsView-validationErrorStep2Step3',
            classNames:'contract-view__custom-error-message-step2'
        },
        {
            id_component: 'condition-show',
            id:'show',
            conditionType: 'single',
            conditionId1: 'show_fioc',
            conditionValue1: false,
            item:[{
                id_component: 'fioc-is-updated'
            }]
        },
        {
            id_component: 'condition-show',
            id:'show',
            conditionType: 'single',
            conditionId1: 'show_fioc',
            conditionValue1: true,
            item:[fioc()]
        },
        {
            id_component: 'disclaimer-personal-info',
            messageKey: 'payrollAccountMsg',
            linkTextKey: 'payrollAccountLinkText',
            linkUrlKey: 'payrollAccountLinkUrl'
        }
    ]
};
