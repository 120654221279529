const Immutable = require('immutable');

const {
    CONFIRM_MODIFY_MAIN_PHONE_REQUEST,
    CONFIRM_MODIFY_MAIN_PHONE_SUCCESS,
    CONFIRM_MODIFY_MAIN_PHONE_FAILURE,

    MODIFY_MAIN_PHONE_RESET,
    MODIFY_MAIN_PHONE_SET_VISIBLE_STEP,
    MODIFY_MAIN_PHONE_VALIDATE,
    MODIFY_MAIN_PHONE_SHOW_CANCEL_MODAL,

    CONTACT_PHONE_DATA_REQUEST,
    CONTACT_PHONE_DATA_SUCCESS,
    CONTACT_PHONE_DATA_FAILURE,

    SET_MAIN_PHONE
} = require('constants/contactCenter/actionTypes');

const {
    WIZARD_FIRST_STEP,
} = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    currentStep: WIZARD_FIRST_STEP,
    showCancelModal: false,
    isFetchingData: false,
    phoneDataFetched: false,
    phoneDataError: false,
    mainContactPhoneData: {},
    steps: [
        {
            selectedPhoneId: null,
            currentPhoneId: null
        },
        {},
        {
            hasSignaturePattern: true
        }
    ],
});

const createConfirmModifyMainPhoneRequestState = previousState => {
    return previousState.merge({
        isFetching: true,
        hasBeenFetched: false,
        error: '',
    });
};

const contactPhoneDataSuccess = (previousState, data) => {
    const mainPhone = data.listaDispositivos.find((phone) => { return phone.preferente === 'S'}).numDispositivo;
    return previousState.mergeDeep({
        isFetchingData: false,
        phoneDataFetched: true,
        phoneDataError: '',
        mainContactPhoneData: data,
        steps: [
            {
                selectedPhoneId: mainPhone,
                currentPhoneId: mainPhone
            }
        ]
    });
};

const createConfirmModifyMainPhoneErrorState = (previousState, error) => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: false,
        error,
    });
};

const createConfirmModifyMainPhoneSuccessState = previousState => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: true,
        error: '',
    });
};

const setVisibleStep = (previousState, step) => {
    return previousState.merge({
        currentStep: step,
    });
}

const validateStep = (previousState, step) => {
    return (step < INITIAL_STATE.get('steps').size) ? previousState.merge({
        currentStep: step + 1
    }) : previousState;
}

const showCancelModal = (previousState, show) => {
    return previousState.merge({
        showCancelModal: show
    });
}

function modifyMainPhone(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CONFIRM_MODIFY_MAIN_PHONE_REQUEST:
            return createConfirmModifyMainPhoneRequestState(state);
        case CONFIRM_MODIFY_MAIN_PHONE_FAILURE:
            return createConfirmModifyMainPhoneErrorState(state, action.payload.error);
        case CONFIRM_MODIFY_MAIN_PHONE_SUCCESS:
            return createConfirmModifyMainPhoneSuccessState(state);
        case MODIFY_MAIN_PHONE_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case MODIFY_MAIN_PHONE_VALIDATE:
            return validateStep(state, action.payload.step);
        case MODIFY_MAIN_PHONE_SHOW_CANCEL_MODAL:
            return showCancelModal(state, action.payload.show);
        case MODIFY_MAIN_PHONE_RESET:
            return INITIAL_STATE;
        case CONTACT_PHONE_DATA_REQUEST:
            return state.merge({
                isFetchingData: true,
            });
        case CONTACT_PHONE_DATA_SUCCESS:
            return contactPhoneDataSuccess(state, action.payload);
        case CONTACT_PHONE_DATA_FAILURE:
            return state.merge({
                isFetchingData: false,
                phoneDataFetched: false,
                phoneDataError: action.payload.error
            });
        case SET_MAIN_PHONE:
            return state.mergeDeep({
                steps: [
                    {
                        selectedPhoneId: action.payload
                    }
                ]
            });
        default:
            return state;
    }
}

module.exports = modifyMainPhone;
