const voiceReader = require('actions/voiceReader');

const voiceMiddleware = store => next => action => {
    if (action && action.hasOwnProperty('voice')) {
        store.dispatch(voiceReader.voice(action.voice));
    }
    return next(action)
};

module.exports = voiceMiddleware;
