// @ vendors
const Immutable = require('immutable');
// @ utilities
const { formatText } = require('core/i18n').i18n;
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    CHAT_CONVERSATION_STATUS: {
        ACTIVE,
        CONNECTING,
        DISCONNECTED,
        ERROR,
        INACTIVE,
        MAX_MESSAGE_LENGTH,
        MESSAGE_REFRESH_INTERVAL
    },
    CHAT_MESSAGE_STATUS,
    CHAT_MESSAGE_TYPES: {
        MESSAGE,
        IDLE_ALERT,
        PARTICIPANTJOINED
    },
    CHAT_SENDER_TYPE: {
        AGENT,
        MESSAGE_TYPE,
        CLIENT
    },
    CHAT_WINDOW_STATUS: {
        COLLAPSED,
        EXPANDED
    }
} = require('constants/chat');

function setInitialState(immState) {
    return Immutable.fromJS({
        alias: '',
        chatId: '',
        chatAvailabilityStatusData:{},
        chatWindowStatus: COLLAPSED,
        conversationStatus: INACTIVE,
        conversationsToEnd: immState ? immState.get('conversationsToEnd') : [],
        disconnectedNotice: false,
        errorMessage: '',
        isChatAvailable: true,
        localId: '',
        messages: [],
        messagesFetching: false,
        options: {
            maximumMessageLength: MAX_MESSAGE_LENGTH,
            messagesRefreshInterval: MESSAGE_REFRESH_INTERVAL
        },
        secureKey: '',
        showCloseQuestion: false,
        userId: '',
        msg: '',
        msgReceiveFail : false,
        timer: false,
        participantJoined : false
    });
}

function chatReducer(state = setInitialState(), action) {
    switch (action.type) {

        case actionTypes.CHAT_AVAILABILITY_STATUS_SUCCESS:
            return state.merge({
                chatAvailabilityStatusData: action.payload.response,
                isChatAvailable: action.payload.response.nextOpening <= 0
            });

        case actionTypes.CHAT_RETRIEVE_MESSAGES_SUCCESS:
            let agentJoined = false;
            const receivedMessages = action.payload.messages.filter(message => {
                if (message.type.toUpperCase() === MESSAGE || message.type.toUpperCase() === IDLE_ALERT){
                    return message.from.type.toUpperCase() !== CLIENT ? Object.assign(message, {from: {type: AGENT}}) : message;
                }
                if(message.type.toUpperCase() === PARTICIPANTJOINED && message.from.type.toUpperCase() === AGENT){
                    agentJoined = true;
                }
            });
            const msgList = state.get('messages');
            const currentMessages = msgList.filter(message => message.get('status') !== CHAT_MESSAGE_STATUS.SENT);
            const allMessages = currentMessages.toJS().concat(receivedMessages);

            let retrieveMsgUpdatedState = {
                messages: allMessages,
                messagesFetching: false,
                conversationStatus: state.get('userId') ? ACTIVE : INACTIVE,
                msgReceiveFail : false
            }
            if(agentJoined) {
                retrieveMsgUpdatedState.participantJoined = true
            }
            if(action.payload.chatEnded) {
                retrieveMsgUpdatedState = {conversationStatus: DISCONNECTED}
            }

            return state.merge(retrieveMsgUpdatedState);

        case actionTypes.CHAT_RETRIEVE_MESSAGES_FAILURE:
            const newState = {messagesFetching: false, msgReceiveFail : true};
            if (action.payload.gotDisconnected && state.get('conversationStatus') !== INACTIVE){
                Object.assign(newState, {conversationStatus: DISCONNECTED , msgSendingFail : true});
            }
            return state.merge(newState);

        case actionTypes.CHAT_RETRIEVE_MESSAGES_IS_FETCHING:
            return state.merge({
                messagesFetching: true
            });

        case actionTypes.UPDATE_CHAT_TOKENS:
            return state.merge({
                chatId: action.payload.chatId,
                alias: action.payload.chatData.query.OpenBankChatAlias,
                userId: atob(action.payload.chatData.query.OpenBankChatUserId),
                secureKey: atob(action.payload.chatData.query.OpenBankChatSecureKey)
            });

        case actionTypes.CHAT_START_CONVERSATION_SUCCESS:
            const {
                alias,
                chatId,
                secureKey,
                userId
            } = action.payload.response;
            const options = action.payload.response.options || {
                maximumMessageLength: MAX_MESSAGE_LENGTH,
                messagesRefreshInterval: MESSAGE_REFRESH_INTERVAL
            }
            return state.merge({
                alias,
                chatId,
                conversationStatus: ACTIVE,
                options,
                secureKey,
                userId,
                messages: [],
                disconnectedNotice: false,
                messagesFetching: false
            });

        case actionTypes.CHAT_START_CONVERSATION_FAILURE:
            return state.merge({
                conversationStatus: ERROR,
                errorMessage: action.payload.error
            });

        case actionTypes.CHAT_START_CONVERSATION_FETCHING:
            return state.merge({
                conversationStatus: CONNECTING,
                localId: action.payload.localId,
                msgReceiveFail: false
            });

        case actionTypes.CHAT_MESSAGE_SENT:
            const messagesList = state.get('messages');
            const indexOfSentMsg = messagesList.findLastIndex(message => message.get('id') === action.payload.messageId);
            return state.mergeDeepIn(['messages', indexOfSentMsg], { status: CHAT_MESSAGE_STATUS.SENT });

        case actionTypes.CHAT_MESSAGE_SENDING:
            const { content, messageId: id } = action.payload;
            let messageBody = Immutable.fromJS({
                id,
                text: content,
                from: {
                    type: CLIENT,
                    nickname: ''
                },
                status: CHAT_MESSAGE_STATUS.SENDING
            });

            const newMessages = state.get('messages').push(messageBody);
            return state.merge({
               messages: newMessages,
               msg: content
            });

        case actionTypes.CHAT_MESSAGE_ERROR:
            const immMessages = state.get('messages');
            const { error, messageId } = action.payload;
            const updatedMessages = immMessages.update(
                immMessages.findLastIndex(message => message.get('id') === messageId),
                message => message.set('status', CHAT_MESSAGE_STATUS.ERROR)
            );
            return state.merge({
                errorMessage: error,
                messages: updatedMessages
            });

        case actionTypes.CHAT_CLOSE_ASK:
            return state.merge({
                showCloseQuestion: true
            });

        case actionTypes.CHAT_CLOSE_CANCEL:
            return state.merge({
                showCloseQuestion: false
            });

        case actionTypes.CHAT_CLOSE_CONFIRM:
            return setInitialState(state);

        case actionTypes.CHAT_WINDOW_TOGGLE:
            return state.merge({
                chatWindowStatus: state.get('chatWindowStatus') === EXPANDED ? COLLAPSED : EXPANDED
            });

        case actionTypes.CHAT_END_WHEN_CONNECTED:
            return state.merge({
                conversationsToEnd: state.get('conversationsToEnd').concat([action.payload.localId])
            });

        case actionTypes.CHAT_ENDED_CONVERSATION:
            return state.merge({
                conversationsToEnd: state.get('conversationsToEnd').filter(id => id !== action.payload.localId)
            });

        case actionTypes.CHAT_SET_TIMER:
            return state.merge({
                timer: true
            });

        case actionTypes.CHAT_RESET_TIMER:
            return state.merge({
                timer: false
            });

        case actionTypes.CHAT_DISCONNECTED_NOTICE:
            return state.merge({
                disconnectedNotice: true
            });

        case actionTypes.CHAT_ADD_WELCOME_MESSAGE:
            const welcomeMessage = state.get('messages').push(Immutable.fromJS(action.payload.message));
            return state.merge({
            messages: welcomeMessage
        });

        case actionTypes.SET_NAME_IN_WELCOME_MESSAGE:
            const messageList = state.get('messages');
            let newUpdatedMessages;
            if(messageList.size){
                let name = action.payload.name || '';
                name = name && name.trim();
                const text = formatText('chat-welcomeMessage', [name]);
                newUpdatedMessages = messageList.update(
                    messageList.findLastIndex(message => message.get('messageType') === MESSAGE_TYPE),
                    message => message.set('text', text)
                );
            }
            return state.merge({
                messages: newUpdatedMessages || messageList
            });

        default:
            return state;

    }
}

module.exports = chatReducer;
