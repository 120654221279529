module.exports = {
	title: 'contractsView-checkingAccountStep1Title',
	order: 1,
	footer_text: '',
	position: 'first',
	components: [
		{
			id_component: 'contract-info',
			id: 'text-info',
			cms_key: 'checkingaccount'
		},
    ...(
        __CONTACT_CENTER__ ?
        [{
            id_component: 'legal-information'
        }] : []
    )
	]
};
