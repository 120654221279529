function debounce(func, wait, immediate) {
    let timeout;
    return function debounceable() {
        const context = this;
        const args = arguments;
        const later = function later() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

module.exports = debounce;
