const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../inputs');

function buildPersonalData(prefix) {
    const personalData = wizardComponentsHelper.replacePrefix(inputs.personalData, prefix);

    return { 
        id_component: 'fioc',
        id: prefix + 'contact_data',
        exclude: [],
        grid: [
            [
                personalData.full_name
            ],
            [
                personalData.document_type,
                personalData.document_number,
                personalData.birthdate
            ],
            [
                personalData.country_of_birth,
                personalData.province_of_birth,
                personalData.town_of_birth
            ],
            [
                personalData.resident_country
            ]
        ]
    }

}

module.exports = buildPersonalData;