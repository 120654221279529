// @vendors
import Immutable from 'immutable';

// @constants
import * as actionTypes from 'constants/actionTypes';

function setInitialState() {
    return Immutable.fromJS({
        document: null,
        isFetching: false,
        error: false
    });
}

function parseMergedPDFResponse(state, response){
    return state.merge({
        error: false,
        isFetching: false,
        document: response.xhr.response,
    });
}

function officePrintedDocument(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_PDF_TO_PRINT_URL_FAILURE:
            return state.merge({
                error: true,
                isFetching: false,
            })
        case actionTypes.FETCH_PDF_TO_PRINT_URL_SUCCESS:
            return parseMergedPDFResponse(state, action.payload.response);
        case actionTypes.FETCH_PDF_TO_PRINT_URL_IN_PROGRESS:
            return state.merge({
                isFetching: true,
                error: false,
            })
        case actionTypes.RESET_PDF_TO_PRINT:
            return setInitialState().merge();
        default:
            return state;
    }
}

module.exports = officePrintedDocument;