// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');


// @utilities
const { parseAdFromCMS } = require('utilities/cmsDataHelpers');

const initialState = Immutable.fromJS({
    byId: {},
    byOrder: [],
    error: '',
    isFetching: false,
    isLoadingCardImages: false,
    idList: []
});


const markNotFoundAds = (ads = [], originalById) => {
    let byId = {};

    ads.forEach(ad => {
        byId[ad] = {
            contentNotFound: true
        }
    })


    return originalById.merge(Immutable.fromJS( byId ))
}

const formatAdList = (adList = [], adsIdsToFetch = []) => {
    let byId = {};
    let byOrder = [];

    adList.forEach((ad) => {
        const parsedAd = parseAdFromCMS(ad);

        adsIdsToFetch = adsIdsToFetch.filter( (item) => item !== parsedAd.adId);

        byId[parsedAd.adId] = parsedAd;
        byOrder.push(parsedAd.adId);
    });

    byId = markNotFoundAds(adsIdsToFetch, Immutable.fromJS(byId))

    return Immutable.fromJS({
        byId: byId,
        byOrder: byOrder
    });
};



const formatUpdateStatus = (state, adList) => {
    adList.forEach((ad) => {
        state = state.setIn(['byId', ad.locationId, 'adobeStatus'], ad.status)
    })

    return state;
};

function commercialAds(state = initialState, action) {
    let adList, adsIdsToFetch;
    switch (action.type) {
        case actionTypes.CARDS_ADS_SET_LOADING:
            return state.merge({
                isLoadingCardImages: action.payload.value
            });
        case actionTypes.FETCH_ADS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_ADS_SUCCESS:
            adList = action.payload.adList;
            adsIdsToFetch = action.payload.adsIdsToFetch;
            let newState = formatAdList(adList, adsIdsToFetch);

            return state.mergeDeep({
                byId: newState.get('byId'),
                byOrder: state.get('byOrder').concat(newState.get('byOrder')),
                error: '',
                isFetching: false
            });
        case actionTypes.FETCH_ADS_FAILURE:
            adsIdsToFetch = action.payload.adsIdsToFetch;

            return state.merge({
                byId: markNotFoundAds(adsIdsToFetch, state.get('byId')),
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.FETCH_ADS_REQUEST_RESET:
            return state.merge({
                isFetching: false
            });
        case actionTypes.FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_SUCCESS:
            return state.merge({
                isFetching: false
            });
        case actionTypes.FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.CONFIRM_CLAUSE_SUCCESS:
            return state.setIn(['byId', action.payload.contentId], Immutable.Map({}));
        case actionTypes.CONFIRM_CLAUSE_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.CLEAR_AD_LOCATION:
            return state.setIn(['byId', action.payload.adLocation], Immutable.Map({}));
        case actionTypes.UPDATE_ADOBE_STATUS:
            return formatUpdateStatus(state, action.payload);
        case actionTypes.STORE_ID_LIST:
            return state.mergeDeep({
                idList : action.payload.list
            });
        default:
            return state
    }
}

module.exports = commercialAds;
