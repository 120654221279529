module.exports = {
    legalWindow: {
        errorTitle: 'contractsView-legalWindowErrorTitle', 
        errorBody: 'contractsView-legalWindowErrorMessage',
        backButtonLabel: 'contractsView-legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    timeoutError: {
        errorTitle: 'contractsView-payrollAccountHireTimeoutErrorTitle',
        errorBody: 'contractsView-payrollAccountHireTimeoutErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    }
}
