const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_NONE
} = require('utilities/contactCenter/wrapUp/constants')

const {
    CREDITS_CANCEL_PREGRANTED_SUCCESS,
    CREDITS_CANCEL_PREGRANTED_FAILURE,
} = require('constants/actionTypes')

const {
    LOAN_REPAYMENT_PLAN_SUCCESS,
    LOAN_REPAYMENT_PLAN_FAILURE,
    FETCH_LOAN_SETTLEMENTS_FAILURE,
    FETCH_LOAN_SETTLEMENTS_SUCCESS,
    FETCH_LOAN_GENERAL_DATA_FAILURE,
    FETCH_LOAN_GENERAL_DATA_SUCCESS,
    FETCH_LOAN_MOVEMENTS_FAILURE,
    FETCH_LOAN_MOVEMENTS_SUCCESS,
} = require('ok-loans/src/loansConstants/actionTypes')

const ACTION_CODE_HIRE_PREGRANTED_CREDIT = 5066
const ACTION_CODE_REQUEST_PAYROLL_LOAN = 5067
const ACTION_CODE_HIRE_PAYOLL_LOAN = 5175
const ACTION_CODE_PURCHASE_EASY_LOAN = 5068
const ACTION_CODE_CANCEL_DISCOVERY_PROTECTION = 5084
const ACTION_CODE_LOANS_INVESTIMENT_WARRANTIES = 5085
const ACTION_CODE_MODIFY_LIMIT_DISCOVERY_PROTECTION = 5083
const ACTION_CODE_QUERY_DISCOVERY_PROTECTION = 5082
const ACTION_CODE_MODIFY_ASSOCIATED_ACCOUNT_EASY_LOAN = 5078
const ACTION_CODE_QUERY_PLAN_AUTOMATE_EASY_LOAN = 5077
const ACTION_CODE_QUERY_MOVEMENTS_EASY_LOAN = 5076
const ACTION_CODE_QUERY_INFO_EASY_LOAN = 5075
const ACTION_CODE_LOANS_MORTGAGES = 5069
const ACTION_CODE_LOANS_MORTGAGES_INFORMATION = 5070
const ACTION_CODE_LOANS_REPAYMENT_PLAN = 5071
const ACTION_CODE_LOANS_SETTLEMENTS = 5073
const ACTION_CODE_LOANS_MOVEMENTS = 5074
const ACTION_CODE_LOANS_INVESTIMENT_CREDIT = 5079
const ACTION_CODE_LOANS_INV_MOVEMENTS = 5080
const ACTION_CODE_LOANS_INV_SETTLEMENTS = 5081
const ACTION_CODE_LOANS_PARTIAL_AMORTIZATION = 5072
const ACTION_CODE_MODIFY_ASSOCIATED_ACCOUNT_LOAN = 5231

module.exports = {
    [ACTION_CODE_HIRE_PREGRANTED_CREDIT]: {
        entry: {
            path: '/myprofile/loans-mortgages/pre-granted-credit',
            failure: [
                { action: 'CONTRACT_PRE_GRANTED_CREDIT_GET_CONTRACT_INFORMATION_ERROR' }
            ],
            success: [
                { action: 'CONTRACT_PRE_GRANTED_CREDIT_GET_CONTRACT_INFORMATION_SUCCESS' }
            ],
        },
    },
    [ACTION_CODE_REQUEST_PAYROLL_LOAN]: {
        entry: {
            path: '/myprofile/loans-mortgages/request-payroll-loan.*'
        },
        finish: {
            action: [ 'PAYROLL_LOAN_REQUEST_FINISH_WITHOUT_UPLOAD' ],
        }
    },
    [ACTION_CODE_HIRE_PAYOLL_LOAN]: {
        entry: {
            path: '/myprofile/loans-mortgages/contract-payroll-loan',
            failure: [
                { action: 'CONTRACT_PAYROLL_LOAN_INITIALIZE_ERROR' }
            ],
            success: [
                { action: 'CONTRACT_PAYROLL_LOAN_INITIALIZE_SUCCESS' }
            ],
        }
    },
    [ACTION_CODE_LOANS_MORTGAGES]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages',
        }
    },
    [ACTION_CODE_LOANS_PARTIAL_AMORTIZATION]: {
        entry: {
            path: '/myprofile/loans-mortgages/loans/[0-9]+/partial-amortization',
            failure: [
                { action: 'LOAN_PARTIAL_AMORTIZATION_FAILURE' }
            ],
            success: [
                { action: 'LOAN_PARTIAL_AMORTIZATION_SUCCESS' }
            ]
        }
    },
    [ACTION_CODE_LOANS_MORTGAGES_INFORMATION] : {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/loans/[0-9]+',
            failure: [
                { action: FETCH_LOAN_GENERAL_DATA_FAILURE }
            ],
            success: [
                { action: FETCH_LOAN_GENERAL_DATA_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_LOANS_REPAYMENT_PLAN]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/loans/[0-9]+/repayment-plan',
            failure: [
                { action: LOAN_REPAYMENT_PLAN_FAILURE }
            ],
            success: [
                { action: LOAN_REPAYMENT_PLAN_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_LOANS_SETTLEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/loans/[0-9]+/settlements',
            failure: [
                { action: FETCH_LOAN_SETTLEMENTS_FAILURE }
            ],
            success: [
                { action: FETCH_LOAN_SETTLEMENTS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_LOANS_INVESTIMENT_CREDIT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/credit/investment-credit/[0-9]+'
        }
    },
    [ACTION_CODE_LOANS_INV_MOVEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/credit/[0-9]+/investment/movements'
        }
    },
    [ACTION_CODE_LOANS_INV_SETTLEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/credit/[0-9]+/investment/settlements'
        }
    },
    [ACTION_CODE_LOANS_MOVEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/loans/[0-9]+/movements',
            failure: [
                { action: FETCH_LOAN_MOVEMENTS_FAILURE }
            ],
            success: [
                { action: FETCH_LOAN_MOVEMENTS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_PURCHASE_EASY_LOAN]: {
        entry: {
            path: '/myprofile/loans-mortgages/contract-easy-loan',
            success: [
                { action: 'CONTRACT_EASY_LOAN_INSTALLMENT_SUCCESS' },
                { action: 'CONTRACT_EASY_LOAN_GET_TOTAL_IMPORT_SUCCESS' }
            ],
            failure: [
                { action: 'CONTRACT_EASY_LOAN_INSTALLMENT_ERROR' },
                { action: 'CONTRACT_EASY_LOAN_GET_TOTAL_IMPORT_ERROR' }
            ]
        }
    },
    [ACTION_CODE_LOANS_INVESTIMENT_WARRANTIES]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/credit/investment-credit/[0-9]+/warranties'
        }
    },
    [ACTION_CODE_CANCEL_DISCOVERY_PROTECTION]: {
        entry: {
            path: '/myprofile/loans-mortgages/credit/[0-9]+/cancel'
        },
        finish: {
            action: [ CREDITS_CANCEL_PREGRANTED_SUCCESS, CREDITS_CANCEL_PREGRANTED_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === CREDITS_CANCEL_PREGRANTED_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_CANCEL_DISCOVERY_PROTECTION,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_MODIFY_LIMIT_DISCOVERY_PROTECTION]: {
        entry: {
            path: '/myprofile/loans-mortgages/credit/[0-9]+/credit-pregranted-modify-limit'
        }
    },
    [ACTION_CODE_QUERY_DISCOVERY_PROTECTION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/credit/pregranted-credit/[0-9]+'
        }
    },
    [ACTION_CODE_MODIFY_ASSOCIATED_ACCOUNT_EASY_LOAN]: {
        entry: {
            path: '/myprofile/loans-mortgages/preapproved-loans/[0-9]+/modify-associated-account'
        }
    },
    [ACTION_CODE_MODIFY_ASSOCIATED_ACCOUNT_LOAN]: {
        entry: {
            path: '/myprofile/loans-mortgages/loans/[0-9]+/modify-associated-account',
        }
    },
    [ACTION_CODE_QUERY_PLAN_AUTOMATE_EASY_LOAN]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/preapproved-loans/[0-9]+/repayment-plan',
            failure:  [
                { action: LOAN_REPAYMENT_PLAN_FAILURE }
            ],
            success: [
                { action: LOAN_REPAYMENT_PLAN_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_QUERY_MOVEMENTS_EASY_LOAN]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/preapproved-loans/[0-9]+/movements',
            failure:  [
                { action: FETCH_LOAN_MOVEMENTS_FAILURE }
            ],
            success: [
                { action: FETCH_LOAN_MOVEMENTS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_QUERY_INFO_EASY_LOAN]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/loans-mortgages/preapproved-loans/[0-9]+',
            check: (path, state) => {
                const accountId = path.match(/\d+/g)[0]
                const immLoans = state.loans
                const loanGeneralDataSuccess = immLoans.getIn(['byId', accountId, 'generalData', 'success'])
                const dataNeedsToBeLoaded =  !loanGeneralDataSuccess
                const operationState = dataNeedsToBeLoaded ? OPERATION_LOG_NONE : OPERATION_LOG_DONE

                return { operationState }
            },
            failure:  [
                { action: FETCH_LOAN_GENERAL_DATA_FAILURE }
            ],
            success: [
                { action: FETCH_LOAN_GENERAL_DATA_SUCCESS }
            ]
        }
    }
}
