const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { BROKER_TYPE_AGENCIE,
        BROKER_TYPE_BANK,
} = require('constants/index');
function setInitialState() {
    return Immutable.Map().merge({
       agencieContract: '',
       accountIdTo: -1,
       valid: false,
       agencies: [],
       agencie: -1,
       errors: {
         selectedAccount: false,
         agencieContractInput: false,
         selectedAgencie: false,
         inputBank: false,
         inputOffice: false,
         inputDc: false,
         inputContract: false
       },
       transferType: {
           bankOrBox : {value: true, name: BROKER_TYPE_BANK},
           agencie: {value: false, name: BROKER_TYPE_AGENCIE},
           valid: false
       },
       contractNumber: {
           bank: '',
           office: '',
           dc: '',
           contract: '',
           valid: false
       }
    });
}

function formatAgencies(agencies) {
    const formattedAgencies = agencies.map(agencie => {
        return {
            agencieId: agencie.codAgencia,
            agencieName: agencie.nombreAgencia
        };
    });

    return Immutable.fromJS(formattedAgencies);
}

function validateStep(step) {
    const isAgencieContractError = step.get('agencieContract') === '';
    const isAgencieError = step.get('agencie') < 0;
    const isAccountToError = step.get('accountIdTo') < 0;
    const isBankInputError = step.getIn(['contractNumber','bank']) === '';
    const isOfficeInputError = step.getIn(['contractNumber','office']) === '';
    const isDcInputError = step.getIn(['contractNumber','dc']) === '';
    const isContractInputError = step.getIn(['contractNumber','contract']) === '';

    let isStepValid = false;

    if (step.getIn(['transferType','agencie','value'])) {
        isStepValid = (!isAgencieContractError &&
                         !isAgencieError &&
                         !isAccountToError);

        return step.merge({valid: isStepValid})
                .mergeDeep({errors:{selectedAccount: isAccountToError}})
                .mergeDeep({errors:{selectedAgencie: isAgencieError}})
                .mergeDeep({errors:{agencieContractInput: isAgencieContractError}});


    } else {
        isStepValid = (!isAccountToError &&
                        !isBankInputError &&
                        !isOfficeInputError &&
                        !isDcInputError &&
                        !isContractInputError);

        return step.merge({valid: isStepValid})
                .mergeDeep({errors:{selectedAccount: isAccountToError}})
                .mergeDeep({errors:{inputBank: isBankInputError}})
                .mergeDeep({errors:{inputOffice: isOfficeInputError}})
                .mergeDeep({errors:{inputDc: isDcInputError}})
                .mergeDeep({errors:{inputContract: isContractInputError}});
    }

}

function brokerTransferExternalStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_AGENCIE_CONTRACT:
            return state.merge({
                agencieContract: action.payload.agencieContract
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_ACCOUNT_TO:
            return state.merge({
                accountIdTo: action.payload.accountId
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_AGENCIE:
            return state.merge({
                agencie: action.payload.agencie
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_SUCCESS:
            return state.merge({
                agencies: formatAgencies(action.payload.response.datos.dato)
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_TYPE_BOB:
            return state.mergeDeep({
                transferType:{bankOrBox: {value: action.payload.bob}}
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_TYPE_AGENCIE:
            return state.mergeDeep({
                transferType:{agencie: {value: action.payload.agencie}}
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT:
            return state.mergeDeep({
                contractNumber:{contract: action.payload.contract}
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_BANK:
            return state.mergeDeep({
                contractNumber:{bank: action.payload.bank}
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_OFFICE:
            return state.mergeDeep({
                contractNumber:{office: action.payload.office}
            });
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_DC:
            return state.mergeDeep({
                 contractNumber:{dc: action.payload.dc}
            });
        default:
            return state;
    }
}

module.exports = brokerTransferExternalStep1;
