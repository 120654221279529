// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');
// @reducer
const sendPinByMailStep1 = require('./sendPinByMail/sendPinByMailStep1');

function setInitialState() {
     return Immutable.Map().merge({
        steps: [
            sendPinByMailStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: WIZARD_FIRST_STEP,
        submitSuccessful: false,
        isFetching: false,
        errorReceived: false
    });
}

function updateStep1(steps, action) {
   return steps.update(FIRST_STEP, (step) =>  sendPinByMailStep1(step, action));
}

const initialState = setInitialState();

function sendPinByMail(state = initialState, action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.SEND_PIN_RESET:
            return setInitialState();
        case actionTypes.SEND_PIN_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.SEND_PIN_SET_STEP_VALID_VALIDATE:
            let visibleStep;
            if (action.payload.step === WIZARD_FIRST_STEP) {
                partialSteps = updateStep1(state.get('steps'), action);
                visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.SEND_PIN_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.SEND_PIN_SUCCESS:
            return state.merge({
                data: action.payload.data,
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.SEND_PIN_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
                submitSuccessful: false,
                errorReceived: true
            });
        case actionTypes.SEND_PIN_CANCEL:
            return state.merge({
                willCancel: true
            });
        default:
            return state
    }
}

module.exports = sendPinByMail;
