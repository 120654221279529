const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const {CARD_BLOCK_REASON_THEFT} = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        comments: '',
        motive: CARD_BLOCK_REASON_THEFT,
        cardReplace: false,
        valid: false
    });
}

function validateStep(step) {
    const hasMotive = !!step.get('motive');

    return step.merge({
        valid: hasMotive
    });
}

function cardBlockStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_BLOCK_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_BLOCK_MOTIVE:
            return state.merge({
                motive: action.payload.motive
            });
        case actionTypes.CARD_BLOCK_COMMENTS:
            return state.merge({
                comments: action.payload.comments
            });
        case actionTypes.CARD_BLOCK_REQUEST_CARD_REPLACE:
            return state.merge({
                cardReplace: action.payload.cardReplace
            });
        default:
            return state;
    }
}

module.exports = cardBlockStep1;
