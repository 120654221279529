// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: false,
    errorMsg: '',
    fetchSuccess: false,
    isFetching: false,
    showFioc: false,
});

function fiocFormStatus(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_FIOC_FORM_STATUS_IN_PROGRESS:
            return state.mergeDeep({
                error: false,
                errorMsg: '',
                isFetching: true,
            });

        case actionTypes.GET_FIOC_FORM_STATUS_SUCCESS:
            return state.mergeDeep({
                error: false,
                errorMsg: '',
                fetchSuccess: true,
                isFetching: false,
                showFioc: get(action.payload, 'response.solicitarFioc', 'S') === 'S',
            });

        case actionTypes.GET_FIOC_FORM_STATUS_ERROR:
            return state.mergeDeep({
                error: true,
                errorMsg: get(action.error, 'errorDescription', ''),
                isFetching: false,
            });

        case actionTypes.GET_FIOC_FORM_STATUS_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = fiocFormStatus;
