// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        hasSignaturePattern: true,
        valid: null,
        readChecked: false,
        checkedIsValid: true
    });
}

function validateStep(step) {
    return step.merge({
        valid: true,
    });
}

function wizardReimburseInvestmentStep2(state = setInitialState(), action) {

    switch (action.type) {
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_VALIDATE_STEP:
            return validateStep(state);
    }
    return state;
}

module.exports = wizardReimburseInvestmentStep2;
