const { fromJS } = require("immutable");
const actionTypes = require("constants/actionTypes");

const initialState = fromJS({
    isFetching: false,
    isError: false,
    centro: null,
    contrato: null,
    entidad: null,
    maxAmount: null,
    minAmount: null,
    ngos: null,
    pan: null,
    producto: null,
    currentStep: 1,
    willCancel: false,
    steps: [{ valid: true }, { valid: true }, { hasSignaturePattern: true }],
    successOperation: false,
    errorOperation: false,
    newNGOCard: false,
});

export const okWizardSolidarity = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OK_WIZARD_REQUEST_SOLIDARITY_CARD_FETCHING:
            return state.set("isFetching", true).set("isError", false).set("newNGOCard", false);

        case actionTypes.OK_WIZARD_REQUEST_SOLIDARITY_CARD_SUCCESS:
            return state
                .set("centro", action.payload.centro)
                .set("contrato", action.payload.contrato)
                .set("entidad", action.payload.entidad)
                .set("maxAmount", action.payload.maxAmount)
                .set("minAmount", action.payload.minAmount)
                .set("ngos", action.payload.ngos)
                .set("pan", action.payload.pan)
                .set("producto", action.payload.producto)
                .set("isFetching", false)
                .set("isError", false);

        case actionTypes.OK_WIZARD_REQUEST_SOLIDARITY_CARD_ERROR:
            return state.set("isFetching", false).set("isError", true);

        case actionTypes.OK_WIZARD_REQUEST_SOLIDARITY_CARD_NOT_FOUND:
            return state
                .set("ngos", null)
                .set("minAmount", 0)
                .set("maxAmount", 99999999)
                .set("isFetching", false)
                .set("newNGOCard", true);

        case actionTypes.OK_WIZARD_CONFIRM_SOLIDARITY_CARD_FETCHING:
            return state.set("isFetching", true).set("isError", false);

        case actionTypes.OK_WIZARD_CONFIRM_SOLIDARITY_CARD_SUCCESS:
            return state
                .set("successOperation", true)
                .set("errorOperation", false);

        case actionTypes.OK_WIZARD_CONFIRM_SOLIDARITY_CARD_ERROR:
            return state
                .set("successOperation", false)
                .set("errorOperation", true);

        case actionTypes.OK_WIZARD_SET_NGOS_DATA:
            return state.set("ngos", action.payload);

        case actionTypes.OK_WIZARD_SOLIDARITY_VALIDATE_STEP:
            return state.set("currentStep", action.step + 1);

        case actionTypes.OK_WIZARD_SOLIDARITY_SET_VISIBLE_STEP:
            return state.set("currentStep", action.step);

        case actionTypes.OK_WIZARD_SOLIDARITY_SET_WILL_CANCEL:
            return state.set("willCancel", true);

        case actionTypes.OK_WIZARD_SOLIDARITY_SET_MIN_AMOUNT_LIMIT:
            return state.set("minAmount", action.payload);

        case actionTypes.OK_WIZARD_SOLIDARITY_SET_MAX_AMOUNT_LIMIT:
            return state.set("maxAmount", action.payload);

        case actionTypes.OK_WIZARD_SOLIDARITY_RESET:
            return initialState;

        default:
            return state;
    }
};
