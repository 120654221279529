// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    BROKER_HOLIDAY_VALIDATION_ERROR
} = require('constants/index');
const brokerETFBuyStep1 = require('./brokerETFBuy/brokerETFBuyStep1');
const brokerETFBuyStep2 = require('./brokerETFBuy/brokerETFBuyStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerETFBuyStep1(undefined, {type: null}),
            brokerETFBuyStep2(undefined, {type: null})
        ],
        visibleStep: 1,
        submitSuccessful: false,
        isFetching: false,
        inProgress: false,
        willCancel: false,
        dfiFetching: false,
        dfiError: false,
        dfiUrl: null,
        exAnteCostsList: {},
        exAnteDataFetching: false,
        exAnteDataError: false,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerETFBuyStep1(step, action);
            case SECOND_STEP:
                return brokerETFBuyStep2(step, action);
        }
    })
}

const productBuyReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.BROKER_ETF_BUY_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_ETF_BUY_SIGNATURE_FAILURE:
            return state.setIn(['steps', SECOND_STEP, 'hasSignaturePattern'], false);

        case actionTypes.BROKER_ETF_BUY_VALIDATE_REQUEST:
            return state.setIn(['steps', FIRST_STEP, 'loading'], true);
        case actionTypes.BROKER_ETF_BUY_VALIDATE_SUCCESS:
            return state.set('confirmationData', action.payload.data)
                        .setIn(['steps', FIRST_STEP, 'loading'], false)
                        .setIn(['steps', FIRST_STEP, 'titleQuantity'], get(action.payload.data, 'titulosImporteAproximado'))
                        .setIn(['steps', FIRST_STEP, 'errorFlags', 'holidayError'], false)
                        .set('visibleStep', 2);

        case actionTypes.BROKER_ETF_BUY_VALIDATE_FAILURE: //TODO handle error?
            const response = action.payload.data;
            const errorKey = (response.body ? response.body.error : response.error);
            let newState = state;

            if (errorKey === BROKER_HOLIDAY_VALIDATION_ERROR) {
                newState = state.setIn(['steps', FIRST_STEP, 'errorFlags', 'holidayError'], true);
            }
            return newState.setIn(['steps', FIRST_STEP, 'loading'], false);

        case actionTypes.BROKER_ETF_BUY_CONFIRM_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'loading'], true)
        case actionTypes.BROKER_ETF_BUY_CONFIRM_FAILURE:
            return state.setIn(['steps', SECOND_STEP, 'loading'], false)
        case actionTypes.BROKER_ETF_BUY_CONFIRM_SUCCESS:
            return state.set('submitSuccessful', true)
                        .setIn(['steps', SECOND_STEP, 'loading'], false)
                        .set('orderConfirmation', Immutable.Map({
                            associatedAccount: action.payload.data.datosCuentaAsociada.codIban.codbban,
                            accountBalance: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.importe,
                            accountCurrency: action.payload.data.datosCuentaAsociada.saldoCuentaAsociada.divisa,
                            orderNumber: action.payload.data.numeroOrden
                        }));
        default:
            return state;
    }
};

function brokerETFBuyReducer(state = setInitialState(), action) {
    let partialSteps;

    switch (action.type) {
        case actionTypes.BROKER_ETF_BUY_RESET:
            return setInitialState();
        case actionTypes.BROKER_ETF_BUY_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_ETF_BUY_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.BROKER_ETF_BUY_DOCUMENT_STORAGE_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], true)
        case actionTypes.BROKER_ETF_BUY_DOCUMENT_STORAGE_SUCCESS:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.BROKER_ETF_BUY_DOCUMENT_STORAGE_ERROR:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.BROKER_ETF_BUY_STOCK_CONTRACT_CHANGED:
        case actionTypes.BROKER_ETF_BUY_BUYFOR_OPTION_CHANGED:
        case actionTypes.BROKER_ETF_BUY_BUYFOR_VALUE_CHANGED:
        case actionTypes.BROKER_ETF_BUY_ORDER_TYPE_OPTION_CHANGED:
        case actionTypes.BROKER_ETF_BUY_ORDER_TYPE_VALUE_CHANGED:
        case actionTypes.BROKER_ETF_BUY_BUYFOR_VALUE_RESET:
        case actionTypes.BROKER_ETF_BUY_ORDER_TYPE_VALUE_RESET:
        case actionTypes.BROKER_ETF_BUY_VALIDITY_DATE_CHANGED:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
        case actionTypes.BROKER_ETF_BUY_SET_HOLIDAY_ERROR:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_ETF_BUY_TOGGLE_DOCUMENTS_CHECKBOX:
        case actionTypes.BROKER_ETF_BUY_TOGGLE_RISK_CHECKBOX:
        case actionTypes.BROKER_ETF_BUY_RESET_CHECKBOX:
        case actionTypes.BROKER_ETF_BUY_MIFID_CLAUSE_CHANGED:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_ETF_BUY_FINISH:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                submitSuccessful: true
            });
        case actionTypes.BROKER_ETF_BUY_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.value
            });
        case actionTypes.BROKER_ETF_BUY_DOCUMENT_DFI_FETCHING:
            return state.merge({
                dfiFetching: true,
                dfiError: false,
                dfiUrl: null
            })
        case actionTypes.BROKER_ETF_BUY_DOCUMENT_DFI_SUCCESS:
            return state.merge({
                dfiFetching: false,
                dfiError: false,
                dfiUrl: action.payload.DIFUrl
            })
        case actionTypes.BROKER_ETF_BUY_DOCUMENT_DFI_ERROR:
            return state.merge({
                dfiFetching: false,
                dfiError: true,
                dfiUrl: null
            })
        case actionTypes.BROKER_ETF_EXTANTCOST_ERROR:
            return state.merge({
                exAnteDataFetching: false,
                exAnteDataError: true
            })
        case actionTypes.BROKER_ETF_EXTANTCOST_FECHING:
            return state.merge({
                exAnteDataFetching: true,
            })
        case actionTypes.BROKER_ETF_EXTANTCOST_SUCESS:
            return state.merge({
                exAnteCostsList: action.payload.response,
                exAnteDataFetching: false,
                exAnteDataError: false,
            })
        default:
            return productBuyReducer(state, action);
    }
}

module.exports = brokerETFBuyReducer;
