//@vendors
const Immutable = require('immutable');
//@helpers
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    inProgress: false,
    success: false,
    error: ''
});

function accountIntervenerRemovalReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_INTERVENERS_REMOVE_RESET:
            return initialState;
        case actionTypes.ACCOUNT_INTERVENERS_REMOVE_REQUEST:
            return state.merge({
                inProgress: true
            });
        case actionTypes.ACCOUNT_INTERVENERS_REMOVE_SUCCESS:
            return state.merge({
                inProgress: false,
                success: true
            });
        case actionTypes.ACCOUNT_INTERVENERS_REMOVE_FAILURE:
            return state.merge({
                inProgress: false,
                error: action.payload.error
            });
        default:
            return state
    }
}

module.exports = accountIntervenerRemovalReducer;
