import actionTypes from 'constants/actionTypes';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isFetching: false,
    isFetched: false,
    error: false,
    horizon: '6m',
    results: [],
    totalPages: '1'
});

function brokerIAPredictionSearchReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_IA_GET_PREDICTION_SEARCH_FETCHING:
            return state
                .set('isFetched', false)
                .set('isFetching', true)
                .set('error', false);

        case actionTypes.BROKER_IA_GET_PREDICTION_SEARCH_FAILURE:
            return state
                .set('isFetched', false)
                .set('isFetching', false)
                .set('error', action.payload.data);

        case actionTypes.BROKER_IA_GET_PREDICTION_SEARCH_SUCCESS:
            const predictionResults = action.payload.data
            if (predictionResults) {
                return state.merge(predictionResults)
                        .set('isFetched', true)
                        .set('isFetching', false)
                        .set('error', false);
            } else {
                return state
                        .set('isFetched', false)
                        .set('isFetching', false)
                        .set('error', true);
            }

        default:
            return state;
    }
}

export default brokerIAPredictionSearchReducer
