const Immutable = require('immutable');

const {
    RENEW_IMPOSITION_INTEREST_REQUEST,
    RENEW_IMPOSITION_INTEREST_FAILURE,
    RENEW_IMPOSITION_INTEREST_SUCCESS,

    RENEW_IMPOSITION_INTEREST_RESET,
    RENEW_IMPOSITION_INTEREST_SET_VISIBLE_STEP,
    RENEW_IMPOSITION_INTEREST_VALIDATE,
    RENEW_IMPOSITION_INTEREST_SHOW_CANCEL_MODAL,
} = require('constants/contactCenter/actionTypes');

const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    currentStep: WIZARD_FIRST_STEP,
    showCancelModal: false,
    steps: [
        {},
        {
            hasSignaturePattern: true
        }
    ],
});

const createRenewalRequestState = previousState => {
    return previousState.merge({
        isFetching: true,
        hasBeenFetched: false,
        error: '',
    });
};

const createRenewalErrorState = (previousState, error) => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: false,
        error,
    });
};

const createRenewalSuccessState = previousState => {
    return previousState.merge({
        isFetching: false,
        hasBeenFetched: true,
        error: '',
    });
};

const setVisibleStep = (previousState, step) => {
    return previousState.merge({
        currentStep: step,
    });
}

const validateStep = (previousState) => {
    return previousState.get('currentStep') === WIZARD_FIRST_STEP ? previousState.merge({
        currentStep: WIZARD_SECOND_STEP
    }) : previousState;
}

const showCancelModal = (previousState, show) => {
    return previousState.merge({
        showCancelModal: show
    });
}

function impositionInterestRenewal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case RENEW_IMPOSITION_INTEREST_REQUEST:
            return createRenewalRequestState(state);
        case RENEW_IMPOSITION_INTEREST_FAILURE:
            return createRenewalErrorState(state, action.payload.error);
        case RENEW_IMPOSITION_INTEREST_SUCCESS:
            return createRenewalSuccessState(state);
        case RENEW_IMPOSITION_INTEREST_SET_VISIBLE_STEP:
            return setVisibleStep(state, action.payload.step);
        case RENEW_IMPOSITION_INTEREST_VALIDATE:
            return validateStep(state, action.payload.step);
        case RENEW_IMPOSITION_INTEREST_SHOW_CANCEL_MODAL:
            return showCancelModal(state, action.payload.show);
        case RENEW_IMPOSITION_INTEREST_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
}

module.exports = impositionInterestRenewal;
