
// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
    return  Immutable.fromJS({
        hasBeenSent : false,
        error: '',
        isFetching: false
    });
}

function codeOtp(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CODE_OTP_SUCCESS:
            return state.merge({
                 hasBeenSent: true,
                 error: '',
                 isFetching: false
            });
        case actionTypes.CODE_OTP_DATA_REQUEST:
            return state.merge({
                error: '',
                isFetching: true
        });
        case actionTypes.CODE_OTP_DATA_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.CODE_OTP_DATA_RESET:
            return setInitialState();
        case actionTypes.CODE_OTP_ERROR_RESET:
            return state.merge({
                error: '',
            });
        default:
            return state;
    }
}

module.exports = codeOtp;
