module.exports = {
    title: 'Préstamo Personal Open con nómina y 3 recibos',
    order: 6,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info'
        }
    ]
};
