// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        isStatusFetching: false,
        isNotificationFetching: false,
        refreshNotification : false,
        showHeaderAlertMessage: false,
        notificationType: '',
        promoCode: '',
        notificationError: false,
        statusError: false
    });
}

function collectivesNotification(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.COLLECTIVE_SEND_NOTIFICATION_REQUEST:
            return state.merge({
                isNotificationFetching: true,
            });

        case actionTypes.COLLECTIVE_SEND_NOTIFICATION_SUCCESS:
            return state.merge({
                refreshNotification: get(action.payload.response, 'refreshNotification'),
                isNotificationFetching: false,
                notificationError: false
            });

        case actionTypes.COLLECTIVE_SEND_NOTIFICATION_FAILURE:
            return state.merge({
                refreshNotification: false,
                isNotificationFetching: false,
                notificationError: true
            });

        case actionTypes.COLLECTIVE_BANNER_STATUS_REQUEST:
            return state.merge({
                isStatusFetching: true,
                statusError: false
            });

        case actionTypes.COLLECTIVE_BANNER_STATUS_SUCCESS:
            const response = action.payload.response;
            return state.merge({
                showHeaderAlertMessage: get(response, 'showHeaderAlertMessage'),
                notificationType: get(response, 'notificationType'),
                promoCode: get(response, 'promoCode'),
                statusError: false
            });

        case actionTypes.COLLECTIVE_BANNER_STATUS_FAILURE:
            return state.merge({
                isStatusFetching: false,
                statusError: true
            });

        default:
            return state;

    }
}

module.exports = collectivesNotification;
