const { getLanguage, formatText } = require('core/i18n').i18n;
const { EURO_TEXT } = require('constants/index');
const { KITS: { KIT_001, KIT_002, KIT_CUSTOM } } = require('constants/securityKit');
const { formatAmount, formatCurrency } = require('utilities/currencyHelper');
const isEqual = require('lodash/lang/isEqual');
const COUNTRY_CODES = require('commonsConstants/countryCodes');
const NO_LANGUAGE = 'NO_LANGUAGE'

const getLanguageKits = (referenceKits) => {
  const language = getLanguage()
  if(!referenceKits || !referenceKits.first() || !referenceKits.first().get('name')){
      return COUNTRY_CODES.COUNTRY_CODE_ES.toLowerCase()
  }

  if(typeof referenceKits.first().get('name') === 'string') return NO_LANGUAGE
  const existsLang = Object.keys(referenceKits.first().get('name').toJSON()).some(x => x === language)
  return existsLang ? language : COUNTRY_CODES.COUNTRY_CODE_ES.toLowerCase()
}

const getTitleKit = title => {
  const output = {
    initial: title,
    parenthesis: ''
  }

  if(!title) return output

  const position = title.indexOf("(");
  if (position === -1) return output
  output.initial = title.substring(0, position)
  output.parenthesis = title.substring(position, title.length)
  return output
}

const getTitleKitFromRow = (name, referenceKits) => {
  const language = getLanguageKits(referenceKits)
  return (language === NO_LANGUAGE  ? getTitleKit(name) : getTitleKit(name.get(language)))
}

const getDetailsKit = (set, referenceKits) => {
  const language = getLanguageKits(referenceKits)
  const reference = set.get('referenceSet')
  const titleMap = reference.get('name')
  const title = (language === NO_LANGUAGE ? getTitleKit(titleMap) : getTitleKit(titleMap.get(language)))
  let amountKit = [set.getIn(['amounts', 0, 'value'])]
  let titleKit = referenceKits.get(set.get('kitId'))
  let titleKitLanguages = titleKit.get('name')
  let typeKit = (language === NO_LANGUAGE ? titleKitLanguages : titleKitLanguages.get(language))
  let showAmount = false
  if(amountKit.length && typeof amountKit[0] !== "undefined") {
    const targetKitId = set.getIn(['amounts', 0, 'targetKitId'])
    if( targetKitId ) {
      titleKit = referenceKits.get(targetKitId)
      titleKitLanguages = titleKit.get('name')
      typeKit = (language === NO_LANGUAGE ? titleKitLanguages : titleKitLanguages.get(language))
    }
    showAmount = true
    amountKit = [formatAmount(amountKit[0], getLanguage(), 0)]
  }

  return {
    title: title,
    typeKit: typeKit,
    showAmount: showAmount,
    amountKit: amountKit
  }
};

const getCustomKitOptions = (set, referenceKits) => {
  const language = getLanguageKits(referenceKits)
  const idCart = set.get('id')
  const referenceSet = set.get('referenceSet')
  const kits = referenceSet.get('kits')

  const output = kits.map((kit, index) => {
    const withoutAmount = {
        idCart,
        index: 0,
        label: formatText('securityKit-firtsOptionNoAmount'),
        value: 0
    }
    const idKit = kit.get('id')
    const referenceCurrentKit = referenceKits.get(idKit)
    const label = referenceCurrentKit.get('name')
    const amounts = kit.get('amounts')
    let amountsOption = null
    let targetKitLabel = null
    if(amounts && amounts.size) {
      const firstAmount = amounts.first()
      const values = firstAmount.get('values')
      const targetKitId = firstAmount.get('targetKitId')
      targetKitLabel = referenceKits.get(targetKitId).get('name')
      if(values.size) {
        amountsOption = values.map((value, index) => {
          const amountLabel = formatAmount(value, getLanguage(), 0) + formatCurrency(EURO_TEXT)
          return {
            idCart,
            index: index + 1,
            targetKitId: targetKitId,
            label: amountLabel,
            value: value,
          }
        })
      }
    }
    return {
      idCart,
      index,
      amountsOption: (amountsOption ? amountsOption.insert(0, withoutAmount) : null),
      targetKitLabel: (targetKitLabel ? (language === NO_LANGUAGE ? targetKitLabel : targetKitLabel.get(language)) : null),
      label: (language === NO_LANGUAGE ? label : label.get(language)),
      value: idKit
    }
  })

  return output.toJSON()
}

const getDefaultKit = (sets) => {
  const withAmount = sets.some(function (set) {
      return set.amounts && set.amounts.length > 0
  });

  if(withAmount) return KIT_CUSTOM

  const kitsIdAppear = { KIT_001: 0, KIT_002: 0 }
  for (let i = 0; i < sets.length; i++) {
    const current = sets[i]
    kitsIdAppear['KIT_' + current.kitId] += 1
  }

  let outputKit = KIT_CUSTOM
  if (kitsIdAppear.KIT_001 === sets.length) outputKit = KIT_001
  else if (kitsIdAppear.KIT_002 === sets.length) outputKit = KIT_002

  return outputKit
}

const getReferenceSet = (set, referenceSets) => {
  const reference = referenceSets.filter(
    referenceSet => referenceSet.id === set.id
  )

  return (reference.length ? reference.shift() : {})
}


const createSets = (sets, referenceSets) => {
    return sets.map(set => {
        const referenceSet = getReferenceSet(set, referenceSets)
        return {
            ...set,
            referenceSet: referenceSet,
        }
    })
}

const createReferencePillButton = (kitCatalog) => {
  const result = kitCatalog.filter(
    kit => kit.buttonName
  );

  return result
}

const createReferenteKitsCatalog = (kitCatalog) => {
  const referenceKits = {}
  for (let i = 0; i < kitCatalog.length; i++) {
    const kit = kitCatalog[i]
    referenceKits[kit.id] = kit
  }

  return referenceKits
}

const toCompare = (item) => {
  return item.map(current => {
    return current.kitId
    + (current.amounts && current.amounts.length ? current.amounts[0].value : '')
    + (current.amounts && current.amounts.length ? current.amounts[0].targetKitId : '')
  })
}

const anyDiffInSecuritySets = (oldSets, newSets) => {
  return isEqual(toCompare(oldSets.toJSON()), toCompare(newSets.toJSON()))
}

const getTextPillButton = (referencePillButton, referenceKits) => {
  const language = getLanguageKits(referenceKits)
  const items = referencePillButton.toJS().map(item => ({
      text: (language === NO_LANGUAGE ? item.buttonName : item.buttonName[language]),
      value: item.id,
  }));

  return items
}

module.exports = {
    getDetailsKit,
    getCustomKitOptions,
    getDefaultKit,
    createSets,
    createReferencePillButton,
    createReferenteKitsCatalog,
    getTitleKitFromRow,
    anyDiffInSecuritySets,
    getLanguageKits,
    getTextPillButton
};
