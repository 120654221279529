// @ vendor
const Immutable = require('immutable');
const { isEmpty } = require('lodash');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP
} = require('constants/index');

// @ reducers
const externalTransferValuesStep1 = require('./externalTransferValues/externalTransferValuesStep1');
const externalTransferValuesStep2 = require('./externalTransferValues/externalTransferValuesStep2');

const  initialState = Immutable.fromJS({
    steps: [
        externalTransferValuesStep1(undefined, {type: null}),
        externalTransferValuesStep2(undefined, {type: null}),
        {hasSignaturePattern: true}
    ],
    visibleStep: 1,
    isFetching: false,
    submitSuccessful: false,
    submitError: undefined,
    documentUploadError: undefined,
    willLeave: false,
    willCancel: false,
    filters: {
        productType: {
            isActive: false,
            value: ''
        },
        market: {
            isActive: false,
            value: {}
        }
    }
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return externalTransferValuesStep1(step, action);
            case SECOND_STEP:
                return externalTransferValuesStep2(step, action);
        }
    });
}

function externalTransferValues(state = initialState, action) {
    switch(action.type) {
        case actionTypes.EXTERNAL_TRANSFER_VALUES_RESET:
            return initialState;
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    const partialStep1 = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialStep1,
                        visibleStep: partialStep1.get(FIRST_STEP).get('valid') ? 2 : 1
                    });
                case 2:
                    const partialStep2 = updateStep(SECOND_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialStep2,
                        visibleStep: partialStep2.get(SECOND_STEP).get('valid') ? 3 : 2
                    });
            }
        case actionTypes.EXTERNAL_TRANSFER_VALUES_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_DESTINATION_ACCOUNT:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_CONTRACT_NUMBER:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_BANK_NAME:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_ADD_FILE:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_REMOVE_FILE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_NO_OF_PRODUCTS:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_BROKER_SEARCH_INPUT_FIELD:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_ADD_PRODUCT_EMPTY_ROW:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_DELETE_PRODUCT:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_RESET_DUPLICATE_ERROR:
        case actionTypes.EXTERNAL_TRANSFER_VALUES_UNDO_DELETION:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_APPLY_FILTER:
            const { productType, activeMarket } = action.payload;
            return state.mergeIn(['filters'], {
                productType: {
                    isActive: productType !== '',
                    value: productType
                },
                market: {
                    isActive: !isEmpty(activeMarket),
                    value: activeMarket
                }
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_RESET_FILTER:
            const { filterKey: _filterKey } = action.payload;
            let _value = '';
            if (_filterKey === 'market') {
                _value = {};
            } else if (_filterKey === 'all') {
                return state.mergeIn(['filters'], {
                    productType: {
                        isActive: false,
                        value: ''
                    },
                    market: {
                        isActive: false,
                        value: {}
                    }
                });
            }
            return state.mergeIn(['filters', _filterKey], {
                isActive: false,
                value: _value,
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_REQUEST:
            return state.merge({
                isFetching: true,
                submitError: null,
                documentUploadError: null,
                submitSuccessful: false
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SUCCESS:
            return state.merge({
                isFetching: false,
                submitError: null,
                documentUploadError: null,
                submitSuccessful: true
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_ERROR:
            return state.merge({
                isFetching: false,
                submitError: action.payload.error,
                submitSuccessful: false
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_UPLOAD_ERROR:
            return state.merge({
                isFetching: false,
                submitError: null,
                documentUploadError: action.payload.error,
                submitSuccessful: true
            });
        default:
            return state;
    }
}

module.exports = externalTransferValues;
