//@vendor
const Immutable = require('immutable');
//@constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');

const initialState = Immutable.fromJS({
    currency: EURO_TEXT,
    currentLimit: 0,
    limitIsValid: false,
    limitIsDirty: false,
    limit: 0,
    valid: false,
});

function validateStep(step) {
    const limit = step.get('limit');
    const currentLimit = step.get('currentLimit');
    const limitIsValid = limit !== currentLimit;

    return step.merge({
        valid: limitIsValid,
        limitIsValid: limitIsValid,
        limitIsDirty: true,
    });
}

function cardModifyDebitLimitsStep1(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SET_LIMIT:
            return state.merge({
                limit: action.payload.limit ? parseInt(action.payload.limit, 10) : 0,
                limitIsValid: action.payload.limit !== state.get('currentLimit'),
                limitIsDirty: true,
            });
        case actionTypes.CARD_MODIFY_DEBIT_LIMITS_SETUP_STEP:
            return state.merge({
                currency: action.payload.currency,
                currentLimit: action.payload.currentLimit,
                limit: action.payload.currentLimit,
                valid: false,
                limitIsValid: false,
            });
        default:
            return state;
    }
}

module.exports = cardModifyDebitLimitsStep1;
