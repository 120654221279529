const actionTypes = require('../constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isVirtualOfficesValid: null,
    isFetchingVirtualOffices: false,
    virtualOfficeRequestSuccess: false,
    virtualOfficeRequestError: false,
    isDeviceTrusted: null,
    isFetchingTrustedDeviceRequest: false,
    trustedDeviceRequestSuccess: false,
    trustedDeviceRequestError: false,
})

function signaturePatternVirtualOffices(state = initialState, action) {

    switch (action.type) {
        case actionTypes.VALIDATE_USER_VIRTUAL_OFFICES_REQUEST:
            return state.merge({
                isFetchingVirtualOffices: true,
                virtualOfficeRequestSuccess: false,
                virtualOfficeRequestError: false
            });
        case actionTypes.VALIDATE_USER_VIRTUAL_OFFICES_SUCCESS:
            return state.merge({
                isVirtualOfficesValid: action.payload.isFeatureActive,
                isFetchingVirtualOffices: false,
                virtualOfficeRequestSuccess: true,
                virtualOfficeRequestError: false
            });
        case actionTypes.VALIDATE_USER_VIRTUAL_OFFICES_FAILURE:
            return state.merge({
                isVirtualOfficesValid: null,
                isFetchingVirtualOffices: false,
                virtualOfficeRequestSuccess: false,
                virtualOfficeRequestError: true
            });
        case actionTypes.SIGNATURE_PATTERN_TRUSTED_DEVICE_REQUEST:
            return state.merge({
                isFetchingTrustedDeviceRequest: true,
                trustedDeviceRequestSuccess: false,
                trustedDeviceRequestError: false
            });
        case actionTypes.SIGNATURE_PATTERN_TRUSTED_DEVICE_SUCCESS:
            return state.merge({
                isDeviceTrusted: !!(action.payload || []).length,
                isFetchingTrustedDeviceRequest: false,
                trustedDeviceRequestSuccess: true,
                trustedDeviceRequestError: false
            });
        case actionTypes.SIGNATURE_PATTERN_TRUSTED_DEVICE_FAILURE:
            return state.merge({
                isDeviceTrusted: false,
                isFetchingTrustedDeviceRequest: false,
                trustedDeviceRequestSuccess: false,
                trustedDeviceRequestError: true
            });
        default:
            return state;
    }
}

module.exports = signaturePatternVirtualOffices;
