//@vendor
const immutable = require('immutable');
const { isEqual } = require('lodash/lang');
const merge = require('lodash/object/merge');
const omit = require('lodash/object/omit');
// @helpers
const { formatText } = require('core/i18n').i18n;
const DateHelper = require('utilities/dateHelper');
//@constants
const actionTypes = require('constants/actionTypes');
const { mortgageProductCatalogIdentifiers, mortgageProductCatalogWFIdentifiers } = require('constants/mortgageRequestPreApproval');
const { HOLDER_OPTION_ONE, HOLDER_OPTION_TWO, MAX_DEPENDENTS } = require('constants/mortgageRequestPreApproval').mortgageRequestPreApprovalCommons;
const {
    HABITUAL_HOUSING, OLD_HOUSE,
    NEW_HOUSE,
    MIN_VALUE_OF_HOUSING,
    MIN_MONEY,
    MIN_TIME_MONEY_RETURN_DEFAULT,
    MIN_VALUE_COST_OF_HOUSING,
    INITIAL_MAX_HOUSING_COST,
    MIN_NEED_MONEY,
    MAX_NEED_MONEY,
    PERCENTAGE_HABITUAL_HOUSING,
    PERCENTAGE_SECOND_HOUSING,
    MAX_TIME_MONEY_RETURN_HH,
    MAX_TIME_MONEY_RETURN_SH,
    MIN_TIME_MONEY_RETURN,
    MONEY_NEED_PERCENTAGE_VALUE } = require('constants/mortgageRequestPreApproval').constantsCalculateCuota;
const { DATE_OF_BIRTH, HOME_OWNER_SHIP, WORK, MARITAL, OTHER_LOANS_AND_MORTGAGES, INCOMES_AND_EXPENSES } = require('constants/mortgageRequestPreApproval').idModalsMortgageRequest;
const { laborSituationOptions, homeOwnershipOptions, studiesLevelOptions } = require('constants/mortgageRequestPreApproval');
const { EURO_TEXT, DEFAULT_COUNTRY, FIRST_STEP, YES, NO, THIRD_STEP, WIZARD_FIRST_STEP, WIZARD_SECOND_STEP, WIZARD_THIRD_STEP, WIZARD_FOURTH_STEP } = require('constants/index');
const { PRE_APROBADO: PRE_APPROVED } = require('constants/mortgageRequestStates');
const communityCodes = require('constants/communityCodes').codes;
const UNKNOWN_VALUE = 'UNKNOWN';

const initialState = immutable.fromJS({
    steps: [
        { valid: false },
        { valid: true },
        { valid: false },
        { valid: false },
    ],
    isModalActive: '',
    currency: EURO_TEXT, //@hardcoded
    error: false,
    isFetching: false,
    success: false,
    visibleStep: 1,
    selectedHolder: HOLDER_OPTION_ONE,
    temporalModalData: {},
    holders: [{
        owner: '',
        dayOfBirth: '',
        monthOfBirth: '',
        studies: '',
        yearOfBirth: '',
        work: '',
        maritalStatus: '',
        dependents: '',
        personalLoans: null,
        monthlyFeesLoans: '',
        pendingAmountLoans: '',
        otherMortgage: null,
        goingCancelMortgage: null,
        monthlyFeesMortgage: '',
        pendingAmountMortgage: '',
        incomes: '',
        expenses: ''
    }, {
        owner: '',
        dayOfBirth: '',
        monthOfBirth: '',
        studies: '',
        yearOfBirth: '',
        work: '',
        maritalStatus: '',
        dependents: '',
        personalLoans: null,
        monthlyFeesLoans: '',
        pendingAmountLoans: '',
        otherMortgage: null,
        goingCancelMortgage: null,
        monthlyFeesMortgage: '',
        pendingAmountMortgage: '',
        incomes: '',
        expenses: ''
    }],
    whereHousingDirty: false,
    useHousing: HABITUAL_HOUSING,
    typeHousing: NEW_HOUSE,
    lang: DEFAULT_COUNTRY,
    whereHousing: 0,
    costOfhousing: MIN_VALUE_OF_HOUSING,
    moneyNeed: MIN_MONEY,
    moneyNeedPercentageValue: MONEY_NEED_PERCENTAGE_VALUE,
    moneyNeedPercentageError: false,
    timeMoneyReturn: MIN_TIME_MONEY_RETURN_DEFAULT,
    validModals: {
        [DATE_OF_BIRTH]: {
            fields: [{}, {}]
        },
        [HOME_OWNER_SHIP]: {
            fields: [{}, {}]
        },
        [WORK]: {
            fields: [{}, {}]
        },
        [MARITAL]: {
            fields: [{}, {}]
        },
        [OTHER_LOANS_AND_MORTGAGES]: {
            fields: [{}, {}]
        },
        [INCOMES_AND_EXPENSES]: {
            fields: [{}, {}]
        },
        validated: false
    },
    term: 0,
    productType: '',
    productCatalogAFI: [{
        identificador: mortgageProductCatalogIdentifiers.DEFAULT,
        tin: '',
        tae: '',
        euribor: '',
        cuota: null
    }],
    productCatalogWF: [{
        identificador: mortgageProductCatalogIdentifiers.DEFAULT,
        tin: '',
        tae: '',
        euribor: '',
        cuota: null
    }],
    monthlyFeeAFI: 0,
    monthlyFeeWF: 0,
    tin: 0,
    tae: 0,
    idPreaprob: '',
    preApprovalToken: '',
    insuranceConfig: '',
    insuranceHomeEnabled: false,
    preloadAfi: false,
    actionPreviousNextButton: false,
    catalogID: 0,
    subTypeDocumentID:0,
    collectiveCatalogID: null,
    errorCatalogue: false,
    errorInsurance: false,
    errorRestoreProcess: '',
    processHasBeenRestored: false,
    showLoading: true,
    maxHousingCost: INITIAL_MAX_HOUSING_COST,
    increaseHousingCostRange: true,
    needMoneyByUser: false,
});
const generateUuid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
const validateHolderProp = (oneHolder, fields, prop) => {
    return oneHolder ? fields.getIn([HOLDER_OPTION_ONE, prop]) : fields.every(item => item.get(prop));
}
const validateDateOfBirthModal = (state, isButtonPreMortgage) => {
    const oneHolder = state.get('selectedHolder') === HOLDER_OPTION_ONE;
    const holders = state.get('holders');
    const fields = holders.map(holder => {
        const valid = !isButtonPreMortgage
            ? state.getIn(['validModals', DATE_OF_BIRTH, 'valid'], true)
            : (!!holder.get('dayOfBirth') && !!holder.get('monthOfBirth') && !!holder.get('yearOfBirth'));

        return immutable.fromJS({
            dayOfBirth: !!holder.get('dayOfBirth'),
            monthOfBirth: !!holder.get('monthOfBirth'),
            yearOfBirth: !!holder.get('yearOfBirth'),
            filled: (!!holder.get('dayOfBirth') && !!holder.get('monthOfBirth') && !!holder.get('yearOfBirth')),
            semiComplete: (!!holder.get('dayOfBirth') && !!holder.get('monthOfBirth') && !!holder.get('yearOfBirth')),
            valid
        })
    });
    const valid = validateHolderProp(oneHolder, fields, 'valid');
    const filled = validateHolderProp(oneHolder, fields, 'filled');
    return immutable.fromJS({
        fields,
        valid,
        filled
    });
};
const validateHomeOwnershipModal = (state, isButtonPreMortgage) => {
    const oneHolder = state.get('selectedHolder') === HOLDER_OPTION_ONE;
    const holders = state.get('holders');
    const fields = holders.map(holder => {
        const valid = !isButtonPreMortgage ? state.getIn(['validModals', HOME_OWNER_SHIP, 'valid'], true) : !!holder.get('owner');
        return immutable.fromJS({
            owner: !!holder.get('owner'),
            filled: !!holder.get('owner'),
            semiComplete: !!holder.get('owner'),
            valid
        })
    });
    const valid = validateHolderProp(oneHolder, fields, 'valid');
    const filled = validateHolderProp(oneHolder, fields, 'filled');
    return immutable.fromJS({
        fields,
        valid,
        filled
    });
}
const validateWorkModal = (state, isButtonPreMortgage) => {
    const oneHolder = state.get('selectedHolder') === HOLDER_OPTION_ONE;
    const holders = state.get('holders');
    const fields = holders.map(holder => {
        const valid = !isButtonPreMortgage ? state.getIn(['validModals', WORK, 'valid'], true) : (!!holder.get('work') && !!holder.get('studies'));
        return immutable.fromJS({
            work: !!holder.get('work'),
            studies: !!holder.get('studies'),
            filled: (!!holder.get('work') && !!holder.get('studies')),
            semiComplete: (!!holder.get('work') || !!holder.get('studies')),
            valid
        })
    });
    const valid = validateHolderProp(oneHolder, fields, 'valid');
    const filled = validateHolderProp(oneHolder, fields, 'filled');
    return immutable.fromJS({
        fields,
        valid,
        filled
    });
}
const validateOtherLoansAndMortgages = (state, isButtonPreMortgage) => {
    const oneHolder = state.get('selectedHolder') === HOLDER_OPTION_ONE;
    const holders = state.get('holders');
    const fields = holders.map(holder => {
        const personalLoansValue = holder.get('personalLoans');
        let monthlyFeesLoansValidate = false;
        let pendingAmountLoansValidate = false;
        if (isEqual(personalLoansValue, YES) || isEqual(personalLoansValue, '')) {
            monthlyFeesLoansValidate = !!holder.get('monthlyFeesLoans') && parseInt(holder.get('monthlyFeesLoans')) > 0 && !(parseInt(holder.get('monthlyFeesLoans')) > parseInt(holder.get('pendingAmountLoans')));
            pendingAmountLoansValidate = !!holder.get('pendingAmountLoans') && parseInt(holder.get('pendingAmountLoans')) > 0;
        }
        const otherMortgageValue = holder.get('otherMortgage');
        let monthlyFeesMortgageValue = false;
        let pendingAmountMortgage = false;
        const goingCancelMortgageValue = holder.get('goingCancelMortgage');
        if (isEqual(otherMortgageValue, YES) || isEqual(otherMortgageValue, '')) {
            if (isEqual(goingCancelMortgageValue, NO) || isEqual(goingCancelMortgageValue, '')) {
                monthlyFeesMortgageValue = !!holder.get('monthlyFeesMortgage') && parseInt(holder.get('monthlyFeesMortgage')) > 0 && !(parseInt(holder.get('monthlyFeesMortgage')) > parseInt(holder.get('pendingAmountMortgage')));
                pendingAmountMortgage = !!holder.get('pendingAmountMortgage') && parseInt(holder.get('pendingAmountMortgage')) > 0;
            }
        }
        const filled = ((isEqual(personalLoansValue, NO) || (isEqual(personalLoansValue, YES) && monthlyFeesLoansValidate && pendingAmountLoansValidate))) &&
            ((isEqual(otherMortgageValue, NO) || (isEqual(otherMortgageValue, YES) && ((monthlyFeesMortgageValue && pendingAmountMortgage) || isEqual(goingCancelMortgageValue, YES)))));
        const valid = !isButtonPreMortgage ? state.getIn(['validModals', OTHER_LOANS_AND_MORTGAGES, 'valid'], true) : filled;
        const semiComplete = isEqual(otherMortgageValue, NO) || isEqual(otherMortgageValue, YES) || isEqual(personalLoansValue, NO) || isEqual(personalLoansValue, YES);
        return immutable.fromJS({
            monthlyFeesLoans: monthlyFeesLoansValidate,
            pendingAmountLoans: pendingAmountLoansValidate,
            monthlyFeesMortgage: monthlyFeesMortgageValue,
            pendingAmountMortgage: pendingAmountMortgage,
            filled,
            semiComplete,
            valid
        });
    });
    const valid = validateHolderProp(oneHolder, fields, 'valid');
    const filled = validateHolderProp(oneHolder, fields, 'filled');
    return immutable.fromJS({
        fields,
        valid,
        filled
    });
}
const validateMaritalStatus = (state, isButtonPreMortgage) => {
    const oneHolder = state.get('selectedHolder') === HOLDER_OPTION_ONE;
    const holders = state.get('holders');
    const fields = holders.map((holder, key) => {
        const maritalStatus = holder.get('maritalStatus');
        const dependents = holder.get('dependents');
        const isSecondHolder = key.toString() === HOLDER_OPTION_TWO;
        const hasDependents = isSecondHolder || dependents !== '';
        let isValidDependents = true;
        let semiComplete = !!maritalStatus;
        if (!isSecondHolder) {
            isValidDependents = hasDependents ? !(parseInt(dependents) > MAX_DEPENDENTS) : false;
            semiComplete = !!maritalStatus || hasDependents;
        }
        const valid = !isButtonPreMortgage ? state.getIn(['validModals', MARITAL, 'valid'], true) : !!maritalStatus && isValidDependents;
        return immutable.fromJS({
            dependents: hasDependents,
            filled: !!maritalStatus && hasDependents,
            maritalStatus: !!maritalStatus,
            semiComplete,
            valid,
        });
    });
    const valid = validateHolderProp(oneHolder, fields, 'valid');
    const filled = validateHolderProp(oneHolder, fields, 'filled');
    return immutable.fromJS({
        fields,
        valid,
        filled
    });
};
const validateIncomesAndExpenses = (state, isButtonPreMortgage) => {
    const oneHolder = state.get('selectedHolder') === HOLDER_OPTION_ONE;
    const holders = state.get('holders');
    const fields = holders.map((holder, index) => {
        const incomesValid = index === 0 ? holder.get('incomes', 0) > 0 : true; //fix bug OBK314-58322
        const valid = !isButtonPreMortgage ? state.getIn(['validModals', INCOMES_AND_EXPENSES, 'valid'], true) : incomesValid;
        return immutable.fromJS({
            incomes: incomesValid,
            filled: !!holder.get('incomes'),
            semiComplete: !!holder.get('incomes') || !!holder.get('expenses'),
            valid
        });
    });
    const valid = validateHolderProp(oneHolder, fields, 'valid');
    const filled = validateHolderProp(oneHolder, fields, 'filled');
    return immutable.fromJS({
        fields,
        valid,
        filled
    });
}
const validateStepThree = (state, isButtonPreMortgage) => {
    const validModals = {
        [DATE_OF_BIRTH]: validateDateOfBirthModal(state, isButtonPreMortgage),
        [HOME_OWNER_SHIP]: validateHomeOwnershipModal(state, isButtonPreMortgage),
        [WORK]: validateWorkModal(state, isButtonPreMortgage),
        [OTHER_LOANS_AND_MORTGAGES]: validateOtherLoansAndMortgages(state, isButtonPreMortgage),
        [MARITAL]: validateMaritalStatus(state, isButtonPreMortgage),
        [INCOMES_AND_EXPENSES]: validateIncomesAndExpenses(state, isButtonPreMortgage),
        validated: !isButtonPreMortgage ? state.getIn(['validModals', 'validated']) : true
    };
    if (isButtonPreMortgage) {
        // Check if all modals are valids
        validModals.valid = Object.values(omit(validModals, 'validated')).every(item => item.get('valid'));
    }
    return validModals;
};
const validateObligatoryFieldsByModal = (state, idModal) => {
    let values;
    if (isEqual(idModal, DATE_OF_BIRTH)) {
        values = validateDateOfBirthModal(state, false);
    } else if (isEqual(idModal, HOME_OWNER_SHIP)) {
        values = validateHomeOwnershipModal(state, false);
    } else if (isEqual(idModal, WORK)) {
        values = validateWorkModal(state, false);
    } else if (isEqual(idModal, INCOMES_AND_EXPENSES)) {
        values = validateIncomesAndExpenses(state, false);
    } else if (isEqual(idModal, OTHER_LOANS_AND_MORTGAGES)) {
        values = validateOtherLoansAndMortgages(state, false);
    } else if (isEqual(idModal, MARITAL)) {
        values = validateMaritalStatus(state, false);
    } else {
        return state;
    }
    return state.mergeDeep({
        validModals: {
            [idModal]: values
        }
    });
};

const handleTemporalModalData = (state, action) => {
    const handleModalTemporalValues = ({ modal, field }) => {
        const isModalActive = state.get('isModalActive');
        const temporalModalData = state.get('temporalModalData');
        const holder = action.payload.holder;
        const dirty = temporalModalData.getIn([modal, holder, field, 'dirty']);
        const temporalSavedFieldValue = temporalModalData.getIn([modal, holder, field, 'old']);
        const oldFieldValue = state.getIn(['holders', holder, field]);
        const temporalFieldValue = !!dirty ? temporalSavedFieldValue : oldFieldValue;
        const currentFieldValue = action.payload[field];

        // if the modal is not active dont modify the temporal data
        if (!isModalActive) {
            return temporalModalData;
        }

        return temporalModalData.mergeDeep({
            [modal]: {
                [holder]: {
                    [field]: {
                        current: currentFieldValue,
                        old: temporalFieldValue,
                        dirty: true,
                        fieldName: field
                    }
                }
            }
        });
    };
    const MODALS = {
        birthdate: 'birthdate',
        civilStatus: 'civilStatus',
        ownership: 'ownership',
        employmentSituation: 'employmentSituation',
        incomesAndExpenses: 'incomesAndExpenses',
        others: 'others'
    };
    const FIELDS = {
        dayOfBirth: 'dayOfBirth',
        monthOfBirth: 'monthOfBirth',
        yearOfBirth: 'yearOfBirth',
        maritalStatus: 'maritalStatus',
        dependents: 'dependents',
        owner: 'owner',
        work: 'work',
        studies: 'studies',
        incomes: 'incomes',
        expenses: 'expenses'
    };

    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DAY_OF_BIRTH:
            return handleModalTemporalValues({ modal: MODALS.birthdate, field: FIELDS.dayOfBirth });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MONTH_OF_BIRTH:
            return handleModalTemporalValues({ modal: MODALS.birthdate, field: FIELDS.monthOfBirth });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_YEAR_OF_BIRTH:
            return handleModalTemporalValues({ modal: MODALS.birthdate, field: FIELDS.yearOfBirth });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MARITAL_STATUS:
            return handleModalTemporalValues({ modal: MODALS.civilStatus, field: FIELDS.maritalStatus });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DEPENDENTS:
            return handleModalTemporalValues({ modal: MODALS.civilStatus, field: FIELDS.dependents });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_OWNER:
            return handleModalTemporalValues({ modal: MODALS.ownership, field: FIELDS.owner });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_WORK:
            return handleModalTemporalValues({ modal: MODALS.employmentSituation, field: FIELDS.work });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_STUDIES:
            return handleModalTemporalValues({ modal: MODALS.employmentSituation, field: FIELDS.studies });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_INCOMES:
            return handleModalTemporalValues({ modal: MODALS.incomesAndExpenses, field: FIELDS.incomes });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_EXPENSES:
            return handleModalTemporalValues({ modal: MODALS.incomesAndExpenses, field: FIELDS.expenses });

        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_OTHER_LOANS_MORTGAGES:
            return handleModalTemporalValues({ modal: MODALS.others, field: action.payload.typeValue });

        default:
            return state.get('temporalModalData');
    }
}

const setOtherLoansProp = (state, action) => {
    const {
        typeValue,
        value
    } = action.payload;
    let holders = state.get('holders');
    const index = parseInt(action.payload.holder);
    holders = holders.update(index, otherMortgage => otherMortgage.merge({
        [typeValue]: value
    }));
    return state.mergeDeep({
        holders,
        temporalModalData: handleTemporalModalData(state, action)
    });
};

const discardTemporalChangesInCurrentModal = state => {
    const discardHoldersData = () => {
        const temporalModalData = state.get('temporalModalData')
        let holders = state.get('holders');
        let valuesToDiscard = [];

        temporalModalData.forEach(modal => {
            modal.forEach((holder, holderKey) => {
                holder.forEach(field => {
                    valuesToDiscard = [
                        ...valuesToDiscard,
                        {
                            value: field.get('old'),
                            fieldName: field.get('fieldName'),
                            holder: holderKey
                        }
                    ]
                });
            });
        });

        valuesToDiscard.forEach(value => {
            holders = holders.setIn([value.holder, value.fieldName], value.value);
        });

        return holders;
    }
    const holders = discardHoldersData();

    return state
        .set('temporalModalData', immutable.Map({}))
        .set('holders', holders);
}

const clearTemporalChangesInCurrentModal = state => {
    return state.set('temporalModalData', immutable.Map({}));
};

const setHolderProp = (state, action, type, modal) => {
    const index = parseInt(action.payload.holder);
    const validated = state.getIn(['validModals', 'validated']);
    let holders = state.get('holders');
    let validModal;
    holders = holders.update(index, holder => holder.merge({
        [type]: action.payload[type],
        [`${type}IsDirty`]: true,
    }));
    if (validated) {
        validModal = state.getIn(['validModals', modal, 'fields']);
        validModal = validModal.update(index, holder => holder.merge({
            [type]: true
        }));
    }
    return state.mergeDeep({
        holders,
        validModals: {
            [modal]: {
                fields: validModal
            }
        },
        temporalModalData: handleTemporalModalData(state, action)
    });
};

const validateFirstStep = state => {
    const costOfhousingIsValid = state.get('costOfhousingIsValid');
    const moneyNeedIsValid = state.get('moneyNeedIsValid');
    const timeMoneyReturnIsValid = state.get('timeMoneyReturnIsValid');

    return costOfhousingIsValid && moneyNeedIsValid && timeMoneyReturnIsValid;
};

const validateStep = (state, action) => {
    const currentStep = action.payload.step;
    const index = currentStep - 1;
    let visibleStep = currentStep;
    let validModals = state.get('validModals');
    let valid = true;
    switch (index) {
        case FIRST_STEP:
            valid = validateFirstStep(state);
            break;
        case THIRD_STEP:
            validModals = validateStepThree(state, true);
            valid = validModals.valid;
            break;
    }
    // Check if move to next step
    visibleStep = valid && (currentStep !== WIZARD_THIRD_STEP && currentStep !== WIZARD_FOURTH_STEP && currentStep !== WIZARD_FIRST_STEP) ? currentStep + 1 : currentStep;
    return state.setIn(['steps', index, 'valid'], valid).merge({
        visibleStep,
        validModals
    });
};
const getAFIMortgageData = (state, data) => {
    let catalog = state.get('productCatalogAFI').toJS();
    let defaultTinTae = state.get('productCatalogAFI').toJS();
    if (data.payload.catalog.length > 0) {
        defaultTinTae = data.payload.catalog.find(product => product.identificador === mortgageProductCatalogIdentifiers.DEFAULT);
        catalog = data.payload.catalog;
    }
    return state.merge({
        productType: data.payload.type,
        productCatalogAFI: catalog,
        tin: defaultTinTae.tin,
        tae: defaultTinTae.tae,
        monthlyFeeAFI: defaultTinTae.cuota
    });
}

const getPreApprovalCollectiveCatalogue = (arrayPrices, productCatalogAFI) => {
    const collectiveCatalogue = arrayPrices.find(price => price.type === mortgageProductCatalogWFIdentifiers.COLLECTIVE);
    const defaultCatalogue = arrayPrices.find(price => price.type === mortgageProductCatalogWFIdentifiers.DEFAULT);
    let catalogues = [{
        identificador: mortgageProductCatalogIdentifiers.DEFAULT,
        tin: defaultCatalogue.interest,
        tae: defaultCatalogue.tae,
        euribor: defaultCatalogue.diferential,
        cuota: defaultCatalogue.fee
    }];

    if (collectiveCatalogue) {
        catalogues.push({
            identificador: mortgageProductCatalogIdentifiers.COLLECTIVE,
            tin: collectiveCatalogue.interest,
            tae: collectiveCatalogue.tae,
            euribor: collectiveCatalogue.diferential,
            cuota: collectiveCatalogue.fee
        });
    } else if (productCatalogAFI.length > 1) {
        // @hardcoded
        catalogues.push({
            identificador: mortgageProductCatalogIdentifiers.COLLECTIVE,
            tin: 'TBD',
            tae: 'TBD',
            euribor: 'TBD',
            cuota: 'TBD'
        });
    }
    return catalogues;
}
const parseRestoredData = data => {
    function getNormalizedValue(condition, defaultValue) {
        return condition ? '' : defaultValue;
    }
    function getMonthlyFeesMortgage(holder) {
        const condition = holder.cancelMortgageIndicatorFlag || !holder.otherMortgageParticipant;
        const defaultValue = holder.otherMortgageParticipant;
        return getNormalizedValue(condition, defaultValue);
    }
    function getPendingAmountMortgage(holder) {
        const condition = holder.cancelMortgageIndicatorFlag || !holder.pendingMortgageAmmount;
        const defaultValue = holder.pendingMortgageAmmount;
        return getNormalizedValue(condition, defaultValue);
    }
    function getPendingAmountLoans(holder) {
        return holder.pendingAmmountOthers;
    }
    function getMonthlyFeesLoans(holder) {
        return holder.otherLoans;
    }
    const {
        community,
        expedientState,
        expedientToken: preApprovalToken,
        mortgageCost: moneyNeed,
        newProperty,
        productType,
        propertyCost: costOfhousing,
        propertyType,
        term: timeMoneyReturn
    } = data;
    let steps = [];
    let visibleStep = WIZARD_FIRST_STEP;
    let selectedHolder = HOLDER_OPTION_TWO;
    const communityCode = communityCodes[community];
    const getWizardSteps = currentStep => {
        return [
            {
                valid: currentStep === WIZARD_SECOND_STEP || currentStep === WIZARD_THIRD_STEP || currentStep === WIZARD_FOURTH_STEP
            }, {
                valid: currentStep === WIZARD_THIRD_STEP || currentStep === WIZARD_FOURTH_STEP
            }, {
                valid: currentStep === WIZARD_FOURTH_STEP
            }, {
                valid: false
            }
        ];
    };
    const holders = data.participants.map(holder => {
        const { options: laboralOptions, abbreviations: laboralAbbreviations } = laborSituationOptions;
        const { options: studyOptions, abbreviations: studyAbbreviations } = studiesLevelOptions;
        const birthDate = holder.birthDate ? holder.birthDate : '';
        const birthDateDay = DateHelper.getDay(birthDate);
        const birthDateMonth = DateHelper.getMonth(birthDate);
        const dayOfBirth = DateHelper.getTwoDigitsDay(birthDateDay);
        const dependents = holder.peopleInCharge !== null ? holder.peopleInCharge : '';
        const expenses = holder.rental || '';
        const goingCancelMortgage = holder.cancelMortgageIndicatorFlag === null ? null : holder.cancelMortgageIndicatorFlag ? YES : NO;
        let hasOtherMortgage = null;
        if (holder.pendingMortgageAmmount === 0 && holder.otherMortgageParticipant === 0) {
            hasOtherMortgage = NO;
        } else if (holder.pendingMortgageAmmount !== null && holder.otherMortgageParticipant !== null) {
            hasOtherMortgage = YES;
        }
        const incomes = holder.income || '';
        const monthOfBirth = DateHelper.getTwoDigitsMonth(birthDateMonth);
        const monthlyFeesLoans = getMonthlyFeesLoans(holder);
        const maritalStatus = holder.civilStatus && holder.civilStatus !== UNKNOWN_VALUE ? holder.civilStatus : '';
        const monthlyFeesMortgage = getMonthlyFeesMortgage(holder)
        const ownerLabel = formatText(homeOwnershipOptions[holder.holderCurrentProperty]);
        const owner = holder.holderCurrentProperty && holder.holderCurrentProperty !== UNKNOWN_VALUE ? { value: holder.holderCurrentProperty, label: ownerLabel } : '';
        const personalLoans = holder.otherLoanFlag === null ? null : holder.otherLoanFlag ? YES : NO;
        const pendingAmountMortgage = getPendingAmountMortgage(holder);
        const pendingAmountLoans = getPendingAmountLoans(holder);
        const studyLabel = studyAbbreviations[holder.studiesLevel] ? formatText(studyAbbreviations[holder.studiesLevel]) : formatText(studyOptions[holder.studiesLevel]);
        const studies = holder.studiesLevel && holder.studiesLevel !== UNKNOWN_VALUE ? { value: holder.studiesLevel, label: studyLabel } : '';
        const workLabel = laboralAbbreviations[holder.laborSituationPreapproval] ? formatText(laboralAbbreviations[holder.laborSituationPreapproval]) : formatText(laboralOptions[holder.laborSituationPreapproval]);
        const work = holder.laborSituationPreapproval && holder.laborSituationPreapproval !== UNKNOWN_VALUE ? { value: holder.laborSituationPreapproval, label: workLabel } : '';
        const yearOfBirth = DateHelper.getFullYear(birthDate);

        return {
            dayOfBirth,
            dependents,
            expenses,
            maritalStatus,
            monthlyFeesLoans,
            monthlyFeesMortgage,
            monthOfBirth,
            goingCancelMortgage,
            incomes,
            otherMortgage: hasOtherMortgage,
            owner,
            personalLoans,
            pendingAmountMortgage,
            pendingAmountLoans,
            studies,
            work,
            yearOfBirth,
        };
    });

    if (holders.length === 1) {
        const emptyHolder = {
            owner: '',
            dayOfBirth: '',
            monthOfBirth: '',
            studies: '',
            yearOfBirth: '',
            work: '',
            maritalStatus: '',
            dependents: '',
            personalLoans: null,
            monthlyFeesLoans: '',
            pendingAmountLoans: '',
            otherMortgage: null,
            goingCancelMortgage: null,
            monthlyFeesMortgage: '',
            pendingAmountMortgage: '',
            incomes: '',
            expenses: ''
        };

        holders.push(emptyHolder);
        selectedHolder = HOLDER_OPTION_ONE;
    }

    if (expedientState === PRE_APPROVED) {
        visibleStep = WIZARD_FOURTH_STEP;
    } else if (productType && productType !== UNKNOWN_VALUE) {
        visibleStep = WIZARD_THIRD_STEP;
    } else {
        visibleStep = WIZARD_FIRST_STEP;
    }
    steps = getWizardSteps(visibleStep);

    const stepOne = {
        costOfhousing,
        costOfhousingIsValid: true,
        moneyNeed,
        moneyNeedIsValid: true,
        preApprovalToken,
        timeMoneyReturn,
        timeMoneyReturnIsValid: true,
        typeHousing: newProperty ? NEW_HOUSE : OLD_HOUSE,
        useHousing: propertyType,
        whereHousing: communityCode,
        whereHousingDirty: true
    };
    const stepTwo = {
        productType: data.productType,
        steps
    };
    const stepThree = {
        holders
    };
    const { mortgageConditions } = data;
    const euribor = !!mortgageConditions ? mortgageConditions.euriBorProd * 100 : 0;
    const monthlyFeeWF = !!mortgageConditions ? mortgageConditions.cuotaMensual : 0;
    const tin = !!mortgageConditions ? mortgageConditions.interes : 0;
    const tae = !!mortgageConditions ? mortgageConditions.taeProd * 100 : 0;

    // Colective info
    const defaultCatalogue = [{
        type: mortgageProductCatalogWFIdentifiers.DEFAULT,
        diferential: euribor,
        fee: monthlyFeeWF,
        interest: tin,
        tae,
    }];
    const productCatalogWF = getPreApprovalCollectiveCatalogue(defaultCatalogue, [{}, {}]);

    const parsedData = merge(
        stepOne,
        stepTwo,
        stepThree,
        {
            monthlyFeeWF,
            productCatalogWF,
            selectedHolder,
            tae,
            tin,
            visibleStep,
        }
    );
    return parsedData;
};

const validateField = (field, state) => {
    let isValid = true;
    let isPercentageError = state.get('isPercentageError'); // Only for moneyNeed field

    switch (field) {
        case 'costOfhousing':
            const costOfhousing = state.get(field);
            const maxHousingCost = state.get('maxHousingCost');
            isValid = costOfhousing >= MIN_VALUE_COST_OF_HOUSING && costOfhousing <= maxHousingCost;
            break;
        case 'moneyNeed':
            const moneyNeed = state.get(field);
            isValid = moneyNeed >= MIN_NEED_MONEY && moneyNeed <= MAX_NEED_MONEY;
            isPercentageError = false;
            if (isValid) {
                const percentageValueValid = isEqual(state.get('useHousing'), HABITUAL_HOUSING) ? PERCENTAGE_HABITUAL_HOUSING : PERCENTAGE_SECOND_HOUSING;
                const maxPercentageValueAllow = state.get('costOfhousing') * percentageValueValid / 100;
                const maxValueAllow = maxPercentageValueAllow < MIN_NEED_MONEY ? MIN_NEED_MONEY : maxPercentageValueAllow;
                isValid = moneyNeed <= maxValueAllow;
                isPercentageError = true;
            }
            break;
        case 'timeMoneyReturn':
            const timeMoneyReturn = state.get(field);
            const amountMax = isEqual(state.get('useHousing'), HABITUAL_HOUSING) ? MAX_TIME_MONEY_RETURN_HH : MAX_TIME_MONEY_RETURN_SH;
            isValid = timeMoneyReturn >= MIN_TIME_MONEY_RETURN && timeMoneyReturn <= amountMax;
            break;
        default:
            isValid = true;
            break;
    }

    return {
        [`${field}IsValid`]: isValid,
        isPercentageError
    };
};

function mortgageRequestPreApproval(state = initialState, action) {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_RESET_STATE:
            return state.merge(initialState);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_VALIDATE_STEP:
            return validateStep(state, action);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER:
            const selectedHolder = action.payload.holder;
            const showLoading = action.payload.showLoading;
            return state.merge({
                selectedHolder,
                showLoading
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DAY_OF_BIRTH:
            return setHolderProp(state, action, 'dayOfBirth', DATE_OF_BIRTH);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MONTH_OF_BIRTH:
            return setHolderProp(state, action, 'monthOfBirth', DATE_OF_BIRTH);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_YEAR_OF_BIRTH:
            return setHolderProp(state, action, 'yearOfBirth', DATE_OF_BIRTH);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_OWNER:
            return setHolderProp(state, action, 'owner', HOME_OWNER_SHIP);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_WORK:
            return setHolderProp(state, action, 'work', WORK);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_STUDIES:
            return setHolderProp(state, action, 'studies', WORK);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_MARITAL_STATUS:
            return setHolderProp(state, action, 'maritalStatus', MARITAL);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_DEPENDENTS:
            return setHolderProp(state, action, 'dependents', MARITAL);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_INCOMES:
            return setHolderProp(state, action, 'incomes', INCOMES_AND_EXPENSES);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_HOLDER_EXPENSES:
            return setHolderProp(state, action, 'expenses', INCOMES_AND_EXPENSES);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_OTHER_LOANS_MORTGAGES:
            return setOtherLoansProp(state, action);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_VALIDATE_OBLIGATORY_FIELDS:
            const idModal = action.payload.idModal;
            return validateObligatoryFieldsByModal(state, idModal);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_LOAD_VALIDATE_OBLIGATORY_FIELDS:
            return state.mergeDeep({
                validModals: validateStepThree(state, false)
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_INFO_STEP_ONE:
            const typeValue = action.payload.typeValue;
            const value = action.payload.value;
            return state.merge({
                [typeValue]: value,
                [`${typeValue}IsDirty`]: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_MONEY_NEED_PERCENTAGE_VALUE:
            const moneyNeedPercentageValue = action.payload.value;
            return state.merge({
                moneyNeedPercentageValue,
                moneyNeedPercentageError: false
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_MONEY_NEED_PERCENTAGE_ERROR:
            return state.merge({
                moneyNeedPercentageError: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_WHERE_HOUSING_DIRTY:
            return state.merge({
                whereHousingDirty: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_VALID_FIRST_STEP:
            const { currentStep, valid } = action.payload;
            const index = currentStep - 1;
            return state.setIn(['steps', index, 'valid'], valid)
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CHANGE_MODAL_ACTIVE:
            const idModalActive = action.payload.idModal;
            return state.merge({
                isModalActive: idModalActive
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SUCCESS:
            const productWFCatalog = getPreApprovalCollectiveCatalogue(action.payload.data.arrayprices, state.get('productCatalogAFI').toJS());
            const { cuota, tae, tin } = productWFCatalog.find(price => price.identificador === mortgageProductCatalogIdentifiers.DEFAULT);
            const referenceCode = action.payload.data.referenceCode;
            let updatedState = state.setIn(['steps', THIRD_STEP, 'valid'], true);
            return updatedState.merge({
                isFetching: false,
                visibleStep: WIZARD_FOURTH_STEP,
                monthlyFeeWF: cuota,
                tin,
                tae,
                idPreaprob: referenceCode,
                productCatalogWF: productWFCatalog
            });
        case actionTypes.MORTGAGE_REQUEST_INSURANCE_PRE_APPROVAL_SUCCESS:
            return state.merge({
                insurance: action.payload.data.insurance
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_TOGGLE_CO_VIEW:
            return state.merge({
                showCounterOfferView: action.payload.showCounterOfferView
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CO:
            return state.merge({
                co: action.payload,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_ACCEPT_PRE_APPROVAL_CO:
            return state.merge({
                moneyNeed: action.payload.newAmount,
                timeMoneyReturn: action.payload.newTerm
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_ERROR:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.MORTGAGE_REQUEST_INSURANCE_REQUEST_ERROR:
            return state.merge({
                errorInsurance: action.payload.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_HOME_INSURANCE_DATA_SUCCESS:
            return state.merge({
                insuranceHomeEnabled: action.payload.data
            });
        case actionTypes.MORTGAGE_REQUEST_INSURANCE_REQUEST_SUCCESS:
            return state.merge({
                insuranceConfig: action.payload.data.data
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_KO:
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_KO_RETURN_WIZARD:
            return state.merge({
                error: action.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_AFI_PRELOAD:
            return state.merge({
                preloadAfi: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_AFI_PRELOAD_FINISH:
            return state.merge({
                preloadAfi: false
            });
        case actionTypes.MORTGAGE_REQUEST_LINK_USER_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_LINK_USER_SUCCESS:
            return state.merge({
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_LINK_USER_ERROR:
            return state.merge({
                error: action.payload.error
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_UUID:
            const uuid = generateUuid()
            return state.merge({
                preApprovalToken: uuid
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_PRODUCT_TYPE:
            return getAFIMortgageData(state, action);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CHANGE_MODAL_ACTIVE_PREVIOUS_NEXT_BUTTON:
            return state.merge({
                actionPreviousNextButton: action.actionPrevousNextButton
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_SUCCESS:
        return state.merge({
                isFetching: false,
                catalogID: action.catalogID,
                collectiveCatalogID: action.collectiveCatalogID,
                subTypeDocumentID:action.subTypeDocumentID
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CATALOGUE_INFORMATION_ERROR:
            return state.merge({
                isFetching: false,
                errorCatalogue: action.error
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_SUCCESS:
            const parsedData = parseRestoredData(action.payload.data);
            return state.merge(parsedData, { isFetching: false, processHasBeenRestored: true });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_RESTORE_DATA_ERROR:
            return state.merge({
                errorRestoreProcess: action.error,
                isFetching: false,
                processHasBeenRestored: false
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_DISCARD_TEMPORAL_CHANGES_IN_CURRENT_MODAL:
            return discardTemporalChangesInCurrentModal(state, action);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_CLEAR_TEMPORAL_CHANGES_IN_CURRENT_MODAL:
            return clearTemporalChangesInCurrentModal(state, action);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_STEP_ONE_FIELD_VALID:
            const field = action.payload.response.field;
            const isValid = validateField(field, state);
            return state.merge(isValid);
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_MAX_HOUSING_COST:
            const { maxHousingCost, increaseHousingCostRange } = action.payload.response;
            return state.merge({
                'maxHousingCost': maxHousingCost,
                'increaseHousingCostRange': increaseHousingCostRange,
            });
        case actionTypes.MORTGAGE_REQUEST_PRE_APPROVAL_SET_NEED_MONEY_BY_USER:
            return state.merge({
                'needMoneyByUser': true
            });
        default:
            return state;
    }
}
module.exports = mortgageRequestPreApproval;
