// @Vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @Actions
const actionTypes = require('constants/actionTypes');

// @Constants
const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    WIZARD_THIRD_STEP
} = require('constants/index');

const brokerRightsCashExchangeStep1 = require('./brokerRightsCashExchange/brokerRightsCashExchangeStep1');
const brokerRightsCashExchangeStep2 = require('./brokerRightsCashExchange/brokerRightsCashExchangeStep2');
const SELECTED_VALUES = require('constants/contactCenter/brokerLegalConfirmationSelectedValues');

const initialState = Immutable.fromJS({
    steps: [
        brokerRightsCashExchangeStep1(undefined, {type: null}),
        brokerRightsCashExchangeStep2(undefined, {type: null})
    ],
    error: false,
    isFetching: false,
    selectedOperationIndex: -1,
    selectedContract: null,
    selectedOperation: null,
    submitSucessful: false,
    visibleStep: WIZARD_FIRST_STEP,
    selectedLegalConfirmation: SELECTED_VALUES.EMPTY,
    validLegalConfirmation: false,
    willCancel: false,
    willLeave: false
});

const updateStep = (index, steps, action) => {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerRightsCashExchangeStep1(step, action);
            case SECOND_STEP:
                return brokerRightsCashExchangeStep2(step, action);
        }
    })
}

//TODO: Adjust mapping when we have a real response
const mapConfirmationResponse = (response) => {
    return Immutable.fromJS({
        orderNumber: get(response, 'numerodeorden', '')
    })
}

const brokerRightsCashExchange = (state = initialState, action) => {
    let partialSteps;
    let visibleStep;
    switch(action.type) {
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_SUCCESS:
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_FAILURE:
            if (__CONTACT_CENTER__) {
                switch(state.get('visibleStep')){
                    case WIZARD_SECOND_STEP:
                        partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                        visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                        break;
                    case WIZARD_THIRD_STEP:
                        partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                        visibleStep = WIZARD_SECOND_STEP;
                        break;
                    default:
                        visibleStep = state.get('visibleStep');
                        partialSteps = state.get('steps');
                        break;
                }
                return state.merge({
                    steps: partialSteps,
                    visibleStep: visibleStep + 1
                });
            }
            else {
                switch(state.get('visibleStep')){
                    case WIZARD_FIRST_STEP:
                        partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                        visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                        break;
                    case WIZARD_SECOND_STEP:
                        partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                        visibleStep = WIZARD_SECOND_STEP;
                        break;
                    default:
                        visibleStep = state.get('visibleStep');
                        partialSteps = state.get('steps');
                        break;
                }
                return state.merge({
                    steps: partialSteps,
                    visibleStep: visibleStep
                });
            }
        case actionTypes.PRE_CONTRACTUAL_MAIL_RESET:
            return state.merge ({
                validLegalConfirmation:false,
            })
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALID_LEGAL_CONFIRMATION:
            return state.merge({
                validLegalConfirmation: true,
                selectedContract: action.payload.selectedContract,
                selectedOperation: action.payload.selectedOperation,
                visibleStep: WIZARD_FIRST_STEP
            })
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_CC_SUCCESS:
            return initialState
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_LEGAL_OPTION:
            return state.merge({
                selectedLegalConfirmation: action.payload.value
            })
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_SET_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_QUANTITY_INPUT:
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATION_REQUESTED:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_SET_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_SET_SELECTED_INDEX:
            return state.merge({
                selectedOperationIndex: action.payload.selectedIndex
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_SET_OPERATION_DATA:
            return state.merge({
                selectedContract: action.payload.selectedContract ? action.payload.selectedContract : Immutable.fromJS({}),
                selectedOperation: action.payload.selectedOperation ? action.payload.selectedOperation : Immutable.fromJS({})
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_RESET:
            return initialState;
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_CONFIRM_REQUEST:
            return state.merge({
                isFetching: true,
            })
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_SET_CONFIRM_SUCCESS:
            return state.merge({
                submitSucessful: true,
                confirmationData: mapConfirmationResponse(action.payload.confirmationResponse)
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_MANIFEST:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_DOCUMENTS_READ:
            partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        default:
            return state;
    }
}

module.exports = brokerRightsCashExchange;
