const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const moment = require('moment');

const initialState = Immutable.fromJS(
    {
        selectedFilter: 'filterValue',
		results: [],
        formData: {
            sector: '',
            dateFrom: moment(),
            dateTo: moment(),
            value: ''
        },
        // @hardcoded
        sectors: [
            { value: 'daily', label: 'Diario' },
            { value: 'weekly', label: 'Semanal' },
            { value: 'monthly', label: 'Mensual' },
            { value: 'strategic', label: 'Estrategico' },
            { value: 'smallcaps', label: 'Smallcap' }
        ]
    }
);

const resultList = Immutable.List([
    {
        value: 'ES0111845014',
        title: 'Albertis',
        sector: 'Autopistas',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Acs',
        sector: 'Construcción',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Acerinoz',
        sector: 'Industriales',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Aena',
        sector: 'Autopistas',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Amadeus',
        sector: 'Autopistas',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Acciona',
        sector: 'Autopistas',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'BBVA',
        sector: 'Bancos',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'bKIA',
        sector: 'Bancos',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Bankinter',
        sector: 'Estratégico',
        dateOfEmittion: '5-05-2015'
    },
    {
        value: 'ES0111845014',
        title: 'Caixabank',
        sector: 'Bancos',
        dateOfEmittion: '5-05-2015'
    }
]);

function brokerFundamentalAnalysisSearch(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_SEARCH_TYPE:
            return state.set('selectedFilter', action.selectedType);
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_SECTOR:
            return state.mergeIn(['formData', 'sector'], action.value);
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_DATE_FROM:
            return state
                    .mergeIn(['formData', 'dateFrom'], action.value)
                    .mergeIn(['formData', 'dateTo'], initialState.getIn(['formData', 'dateTo']));
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_DATE_TO:
            return state.mergeIn(['formData', 'dateTo'], action.value);
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_CHANGE_VALUE:
            return state.mergeIn(['formData', 'value'], action.value);
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_SUBMIT:
            return state.set('results', resultList)
        case actionTypes.BROKER_FUNDAMENTAL_ANALYSIS_CLEAR:
            return initialState;    
        default:
            return state;
    }
}

module.exports = brokerFundamentalAnalysisSearch;
