const { EURO_SYMBOL, MONTHS } = require('constants/index');

module.exports = {
    title: 'Datos del préstamo',
    order: 2,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'tab-form',
            label: 'Datos Generales',
            grid: [
                [
                    {
                        id_component: 'slider',
                        id: 'slider_money_needed',
                        label: 'Importe del préstamo solicitado',
                        min_value: 600,
                        max_value: 18000,
                        type: 'single',
                        format_type: EURO_SYMBOL,
                        default_value: 9000,
                        order: 1,
                        onChangeFn: 'personal_onChangeSliderMoneyNeeded',
                        value: 9000,
                        is_money: true
                    }
                ],
                [
                    {
                        id_component: 'slider',
                        id: 'slider_months_needed',
                        label: 'Plazo del préstamo',
                        min_value: 6,
                        max_value: 36,
                        type: 'single',
                        format_type: MONTHS,
                        default_value: 12,
                        order: 2,
                        onChangeFn: 'personal_onChangeSliderMonthsNeeded',
                        value: 30
                    }
                ],
                [
                    {
                        id_component: 'combo',
                        label: 'Finalidad del préstamo*',
                        type: 'form',
                        placeholder: 'Seleccione una opción ...',
                        source: 'personalLoan_loadCmbLoanPurpose',
                        onChangeFn: 'personalLoan_onChangeCmbPurpose',
                        target: 'personal_form_loan_information_data',
                        options: [],
                        order: 3
                    }
                ],
                [
                    {
                        id_component: 'amount-input',
                        label: 'Ingresos netos anuales de los titulares*',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    },
                ],
                [
                    {
                        id_component: 'combo',
                        label: 'Periodo de carencia*',
                        type: 'form',
                        placeholder: 'Seleccione una opción ...',
                        source: 'personalLoan_loadCmbGracePeriod',
                        onChangeFn: 'personalLoan_onChangeCmbPurpose',
                        target: 'personal_form_loan_information_data',
                        options: [],
                        order: 3
                    }
                ]
            ]
        },
        {
            id_component: 'tab-form',
            label: 'Datos de la vivienda actual',
            grid: [
                [
                    {
                        id_component: 'combo',
                        label: 'Regimen vivienda actual*',
                        type: 'form',
                        placeholder: 'Seleccione una opción ...',
                        source: 'personalLoan_loadCmbCurrentHousingRegimen',
                        onChangeFn: 'personalLoan_onChangeCmbPurpose',
                        target: 'personal_form_loan_information_data',
                        options: [],
                        order: 3
                    }
                ],
                [
                    {
                        id_component: 'amount-input',
                        label: 'Valor de la propiedad*',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    },
                    {
                        id_component: 'amount-input',
                        label: 'Cuota de la hipoteca actual*',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    }
                ],
                [
                    {
                        id_component: 'amount-input',
                        label: 'Importe pendiente Hipoteca actual*',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    },
                    {
                        id_component: 'combo',
                        label: 'Cuota Cancelación de Hipoteca actual*',
                        type: 'form',
                        placeholder: 'Seleccione una opción ...',
                        source: 'personalLoan_loadCmbCurrentMortgageCancellationFee',
                        onChangeFn: 'personalLoan_onChangeCmbPurpose',
                        target: 'personal_form_loan_information_data',
                        options: [],
                        order: 3
                    }
                ]
            ]
        },
        {
            id_component: 'tab-form',
            label: 'Otros datos relevantes',
            grid: [
                [
                    {
                        id_component: 'amount-input',
                        label: 'Cuota otros créditos',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    },
                    {
                        id_component: 'amount-input',
                        label: 'Otros créditos importe pendiente',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    }
                ],
                [
                    {
                        id_component: 'amount-input',
                        label: 'Otros gastos mensuales',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    },
                    {
                        id_component: 'amount-input',
                        label: 'Valor de otros bienes',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    }
                ],
                [
                    {
                        id_component: 'amount-input',
                        label: 'Valor de otros bienes',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    },
                    {
                        id_component: 'amount-input',
                        label: 'Origen otros gastos mensuales',
                        type: 'form',
                        target: 'personal_form_loan_information_data',
                        onChangeFn: 'personalLoan_onChangeAmount',
                        importMin: 0,
                        importMax: 1000000,
                        amountZeroAlertVisible: false,
                        amountErrorMsg: 'Error',
                        defaultValue: 0,
                        order: 1
                    }
                ]
            ]
        }
    ]
};
