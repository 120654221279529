// @vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const moment = require('moment');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    DATE_FORMAT_API,
} = require('constants/index');

// @helpers
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    byId: {},
    byOrder: [],
    error: '',
    fetchSuccess: false,
    isFetching: false,
});

function checkIsChild(birth) {
    const adultDate = moment().subtract(18, 'years');
    const childBirthDate = moment(birth, DATE_FORMAT_API);
    return moment(adultDate).isBefore(childBirthDate);
}

function processProfiles(state, action) {
    let byId = {};
    let byOrder = [];

    action.payload.profiles.forEach(profile => {
        const name = trim(get(profile, 'nombre') || '');
        const personCode = get(profile, 'personaMenor.codigodepersona') || '';
        const personType = get(profile, 'personaMenor.tipodepersona') || '';

        const accountsProfiles = profile.contratosCuenta || [];
        const cardProfiles = profile.contratosTarjeta || [];

        if (accountsProfiles.length > 0) {
            const profileAccount = accountsProfiles[0];
            const contractNumber = profileAccount.numeroContrato || '';
            const id = hash([personCode, personType, contractNumber]);
            byId[id] = {
                id,
                isUnder18: checkIsChild(profile.fechaNacimiento),
                name,
                personCode,
                personType,
                contractNumber,
                product: profileAccount.producto || '',
            }
            byOrder.push(id);
        } else if (cardProfiles.length > 0) {
            const profileCard = cardProfiles[0];
            const contractNumber = profileCard.numeroContrato || '';
            const panNumber = profileCard.pan || '';
            const id = hash([personCode, personType, contractNumber, panNumber]);

            byId[id] = {
                id,
                isUnder18: checkIsChild(profile.fechaNacimiento),
                name,
                personCode,
                personType,
                contractNumber,
                panNumber,
                product: profileCard.producto || '',
            }
            byOrder.push(id);
        }
    });

    return state.mergeDeep({
        fetchSuccess: true,
        isFetching: false,
    })
    .set('byId', Immutable.fromJS(byId))
    .set('byOrder', Immutable.fromJS(byOrder));
}

function openYoungProfilesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_GET_PROFILES_REQUEST:
            return state.merge({
                error: '',
                fetchSuccess: false,
                isFetching: true,
            });
        case actionTypes.OPEN_YOUNG_GET_PROFILES_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.OPEN_YOUNG_GET_PROFILES_SUCCESS:
            return processProfiles(state, action);
        default:
            return state;
    }
}

module.exports = openYoungProfilesReducer;
