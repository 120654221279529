// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false
    });
}


function validateStep(step) {
    return step.merge({
        valid: true
    });
}

function cancelCardpayLaterStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CANCEL_CARD_PAY_LATER_VALIDATE_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = cancelCardpayLaterStep1;
