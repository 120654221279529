// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

// @ Contact Center constants
const SELECTED_VALUES = require('constants/contactCenter/brokerLegalConfirmationSelectedValues');
const contactCenterActionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        hasSignaturePattern: true,
        valid: null,
        disclaimerChecked: false,
        disclaimerInvalid: false,
        selectedYesNoOption: SELECTED_VALUES.EMPTY,
    });
}

function validateStep(step, data = {}) {
    const disclaimerChecked = step.get('disclaimerChecked');
    const mData = __CONTACT_CENTER__ 
        ?   data 
        : {
           valid: disclaimerChecked,
           disclaimerInvalid: !disclaimerChecked
        }
    return step.merge(mData);
}

function resetConfirmationErrors(step) {
    return step.merge({
        yesNoOptionInvalid: false,
        mailInvalid: false
    });
}

function toggleDisclaimer(step) {
    return step.set('disclaimerChecked', !step.get('disclaimerChecked'))
               .set('disclaimerInvalid', false);
}
function changeLegalYesNoOption(step, option) {
    return resetConfirmationErrors(step).
    set('selectedYesNoOption', option);
}

function brokerInternalTransferFundsStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_STEP:
            return validateStep(state, action.payload.data);
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_TOGGLE_DISCLAIMER:
            return validateStep(toggleDisclaimer(state));
        case contactCenterActionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_YES_NO_OPTION_CHANGE:
            return changeLegalYesNoOption(state, action.payload.option);
        case contactCenterActionTypes.PRE_CONTRACTUAL_MAIL_SENT_REQUEST:
            return resetConfirmationErrors(state);
        case contactCenterActionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_INFORMATION_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = brokerInternalTransferFundsStep2;
