// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    processSelected: {},
    documentsUploaded: 0
});


function myProfileUploadDocumentationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_PROCESS_SELECTED:
            return state.merge({
                processSelected: action.payload
            });
        case actionTypes.INCREASE_DOCUMENTS_UPLOADED:
            const documentsUploaded = state.get('documentsUploaded')
            return state.merge({
                documentsUploaded: documentsUploaded + 1
            });
        case actionTypes.RESET_PROCESS_SELECTED:
            return state.merge({
                processSelected: {}
            });
        default:
            return state;
    }
}

module.exports = myProfileUploadDocumentationReducer;
