// @vendors
const Immutable = require('immutable');

// @helpers
const sanitizeHelper = require('utilities/sanitizeHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_MAIN_STOCKS_TAB_RISERS
} = require('constants/index');

const parseStocks = (stocksData) => {
    if (!stocksData){
        return;
    }
    return stocksData.map(stock => {  // TODO: remove non needed props
        return {
            ask1: stock.ask1,
            askSize1: stock.askSize1,
            bid1: stock.bid1,
            bidSize1: stock.bidSize1,
            category: stock.category.trim(),
            currencyCode:  stock.currencyCode,
            exchangeId: stock.exchangeId,
            high: stock.high,
            instrument: sanitizeHelper.sanitizeData(stock.instrument.trim()),
            instrumentId: stock.instrumentId.trim(),
            intradayType: stock.intradayType.trim(),
            isDecoding: stock.isDecoding,
            isNational: stock.isNational,
            last: stock.last,
            localCode: stock.localCode.trim(),
            low: stock.low,
            miccode: stock.miccode.trim(),
            netChangePercentage: stock.netChangePercentage,
            netChangePrice: stock.netChangePrice,
            open: stock.open,
            spcSymbol: stock.spcSymbol.trim(),
            systemContracting: stock.systemContracting.trim(),
            tradeDate: stock.tradeDate.trim(),
            tradeTime: stock.tradeTime.trim(),
            updateDate: stock.updateDate.trim(),
            updateTime: stock.updateTime.trim(),
            userCode: stock.userCode.trim(),
            userExchangeId: stock.userExchangeId,
            volume: stock.volume,
            weighting: stock.weighting,
            yclose: stock.yclose,
            yearChangePercentage: stock.yearChangePercentage,
            yearChangePrice: stock.yearChangePrice,
            yearHigh: stock.yearHigh,
            yearLow: stock.yearLow,
            yvolume: stock.yvolume
        }
    })
}

const initialState = Immutable.fromJS({
    isFetching: false,
    hasBeenFetched: false,
    error: false,
    selectedMarket: '',
    isFrom: '',
    showMoreSelectedTab: BROKER_MAIN_STOCKS_TAB_RISERS,
    stocksByMarket: {
        byVolume: {
            risers: [],
            fallers: []
        },
        byPercentage: {
            risers: [],
            fallers: []
        }
    }
});

function brokerTodaysMainStocksReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_REQUEST:
            return state.merge({
                isFetching: true,
                hasBeenFetched: false,
                error: false
            });
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_SET_SHOW_MORE_SELECTED_TAB:
            return state.set('showMoreSelectedTab', action.payload.tab);
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_RESET_SHOW_MORE_SELECTED_TAB:
            return state.set('showMoreSelectedTab', BROKER_MAIN_STOCKS_TAB_RISERS);
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_SUCCESS:
            const { byVolume = {}, byPercentage = {} } = action.payload;
            return state.mergeDeep({
                isFetching: false,
                hasBeenFetched: true,
                error: false,
                stocksByMarket: {
                    [action.payload.marketName]: {
                        byVolume: {
                            risers: parseStocks(byVolume.risers),
                            fallers: parseStocks(byVolume.fallers)
                        },
                        byPercentage: {
                            risers: parseStocks(byPercentage.risers),
                            fallers: parseStocks(byPercentage.fallers)
                        }
                    }
                }
            });
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_FAILURE:
            return state.merge({
                isFetching: false,
                hasBeenFetched: false,
                error: true
            });
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_MARKET_CHANGED:
            return state.merge({
                selectedMarket: action.payload.market
            });
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_RESET_STOCKS_MARKET:
            return state.merge({
                stocksByMarket: {}
            });
        case actionTypes.BROKER_TODAYS_MAIN_STOCKS_SET_PREVIOUS_PAGE:
            return state.merge({
                isFrom: action.payload.prevPage
            });
        default:
            return state
    }
}

module.exports = brokerTodaysMainStocksReducer;
