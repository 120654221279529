// @ constants
const {
    AMOUNT_TARGET_TYPE,
    INTERVENER_TYPE,
    TEXT_TARGET_TYPE
} = require('constants/index');

module.exports = {
    setSuccessStepPrefix: 'depositEighteenMonths_setSuccessStepPrefix',
    setSuccessAdditionalInfo: 'depositEighteenMonths_setSuccessStepAdditionalInfo',
    title: 'contractSummary-depositEighteenMonthsSuccessTitle',
    pending_title: 'contractSummary-depositEighteenMonthsPendingTitle',
    backButtonText: 'contractSummary-backToDeposits',
    backButtonUrl: '/myprofile/deposits',
    details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        title: 'contractSummary-depositEighteenMonthsNotRenewDeposit'
                    },
                    {
                        columns: 12,
                        target: 'monto_traspasar',
                        title: 'contractSummary-requestedAmount',
                        type: AMOUNT_TARGET_TYPE
                    },
                ]
            },
            {
                columns: 6,
                offset: 1,
                details: [
                    {
                        columns: 12,
                        type: INTERVENER_TYPE,
                        title: 'contractsView-interveners',
                        interveners: []
                    },
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        source: 'account',
                        target: 'balance',
                        title: 'contractSummary-balanceAssociatedAccount',
                        type: AMOUNT_TARGET_TYPE
                    }
                ]
            }
        ]
    ],
    renewable_details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        title: 'contractSummary-depositEighteenMonthsRenewDeposit'
                    },
                    {
                        columns: 12,
                        target: 'monto_traspasar',
                        title: 'contractSummary-requestedAmount',
                        type: AMOUNT_TARGET_TYPE
                    },
                ]
            },
            {
                columns: 6,
                offset: 1,
                details: [
                    {
                        columns: 12,
                        type: INTERVENER_TYPE,
                        title: 'contractsView-interveners',
                        interveners: []
                    },
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        source: 'account',
                        target: 'balance',
                        title: 'contractSummary-balanceAssociatedAccount',
                        type: AMOUNT_TARGET_TYPE
                    }
                ]
            }
        ]
    ],
    pending_details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        title: 'contractSummary-depositEighteenMonthsNotRenewDeposit'
                    },
                    {
                        columns: 12,
                        target: 'monto_traspasar',
                        title: 'contractSummary-requestedAmount',
                        type: AMOUNT_TARGET_TYPE
                    },
                ]
            },
            {
                columns: 6,
                offset: 1,
                details: [
                    {
                        columns: 12,
                        type: INTERVENER_TYPE,
                        title: 'contractsView-interveners',
                        interveners: []
                    },
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        source: 'account',
                        target: 'balance',
                        title: 'contractSummary-balanceAssociatedAccount',
                        type: AMOUNT_TARGET_TYPE
                    }
                ]
            }
        ]
    ],
    renewable_pending_details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        title: 'contractSummary-depositEighteenMonthsRenewDeposit'
                    },
                    {
                        columns: 12,
                        target: 'monto_traspasar',
                        title: 'contractSummary-requestedAmount',
                        type: AMOUNT_TARGET_TYPE
                    },
                ]
            },
            {
                columns: 6,
                offset: 1,
                details: [
                    {
                        columns: 12,
                        type: INTERVENER_TYPE,
                        title: 'contractsView-interveners',
                        interveners: []
                    },
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        source: 'account',
                        target: 'balance',
                        title: 'contractSummary-balanceAssociatedAccount',
                        type: AMOUNT_TARGET_TYPE
                    }
                ]
            }
        ]
    ],
    warningMessage: [
        [
            {
                text: 'contractSummary-depositEighteenMonthsNoMailMessage'
            }
        ]
    ],
    successWithEmail_warningMessage: [
        [
            {
                text: 'contractSummary-depositEighteenMonthsMailMessage'
            }
        ]
    ],
    pending_warningMessage: [
        [
            {
                text: 'contractSummary-depositEighteenMonthsNoMailMessage'
            }
        ]
    ],
    pending_successWithEmail_warningMessage: [
        [
            {
                text: 'contractSummary-depositEighteenMonthsMailMessage'
            }
        ]
    ],
}
