const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP } = require('constants/index');
const addBeneficiaryStep1 = require('./addBeneficiary/addBeneficiaryStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [addBeneficiaryStep1(undefined, { type: null }), { hasSignaturePattern: true }],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        addBeneficiaryFailed: false, // flag to hide/show wizard Error
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return addBeneficiaryStep1(step, action);
        }
    });
}

function addBeneficiaryReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.ADD_BENEFICIARY_VALIDATE_UI_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.ADD_BENEFICIARY_VALIDATE_STEP_SUCCESS:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    return state.merge({
                        visibleStep,
                        isFetching: false
                    });
            }
        case actionTypes.ADD_BENEFICIARY_VALIDATE_STEP_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.ADD_BENEFICIARY_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.ADD_BENEFICIARY_RESET:
            const initialState = setInitialState();
            return state.merge(initialState, {
                steps: [
                    {
                        ...initialState.getIn(['steps', 0]).toJS(),
                        country: state.getIn(['steps', 0, 'originCountry']),
                        originCountry: state.getIn(['steps', 0, 'originCountry'])
                    },
                    ...initialState.getIn(['steps', 1]).toJS(),
                ],
            });
        case actionTypes.ADD_BENEFICIARY_NAME:
        case actionTypes.ADD_BENEFICIARY_ADDRESS:
        case actionTypes.ADD_BENEFICIARY_CITY:
        case actionTypes.ADD_BENEFICIARY_ALIAS:
        case actionTypes.ADD_BENEFICIARY_IBAN:
        case actionTypes.ADD_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE:
        case actionTypes.ADD_BENEFICIARY_BIC:
        case actionTypes.ADD_BENEFICIARY_ACCOUNT_NUMBER:
        case actionTypes.ADD_BENEFICIARY_COUNTRY:
        case actionTypes.ADD_BENEFICIARY_BANK_OPTION:
        case actionTypes.ADD_BENEFICIARY_BANK_NAME:
        case actionTypes.ADD_BENEFICIARY_BANK_ADDRESS:
        case actionTypes.ADD_BENEFICIARY_BANK_CITY:
        case actionTypes.ADD_BENEFICIARY_CURRENCY:
        case actionTypes.ADD_BENEFICIARY_FILL_FORM:
        case actionTypes.ADD_BENEFICIARY_FILL_FORM_FROM_TRANSFER:
        case actionTypes.ADD_BENEFICIARY_RESET_LOAD_FROM_TRANSFER:
        case actionTypes.FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            });
        case actionTypes.ADD_BENEFICIARY_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.ADD_BENEFICIARY_VALIDATE_STEP_REQUEST:
        case actionTypes.ADD_BENEFICIARY_REQUEST_REQUEST:
            return state.merge({
                isFetching: true,
                error: ''
            });
        case actionTypes.ADD_BENEFICIARY_REQUEST_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            });
        case actionTypes.ADD_BENEFICIARY_REQUEST_FAILURE:
            return state.merge({
                isFetching: false,
                addBeneficiaryFailed: true,
                submitSuccessful: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = addBeneficiaryReducer;
