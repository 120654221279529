// @vendor
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/contactCenter/actionTypes');
// @commons

const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            steps: [
                {},
                { hasSignaturePattern: true }
            ],
            visibleStep: WIZARD_FIRST_STEP,
            errorReceived: '',
            hasBeenSent: false
        })
    );
};

function cardPinReset(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_PIN_RESET_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.CARD_PIN_RESET_VALIDATE_STEP:
            if (action.payload.step === WIZARD_FIRST_STEP) {
                return state.merge({
                    visibleStep: WIZARD_SECOND_STEP
                });
            } else {
                return state;
            }

        case actionTypes.CARD_PIN_RESET_RESET_WIZARD:
            return setInitialState();

        case actionTypes.CARD_PIN_RESET_ERROR:
            return state.merge({
                errorReceived: action.payload.error,
            });

        case actionTypes.CARD_PIN_RESET_SUCCESS:
            return state.merge({
                hasBeenSent: true,
                errorReceived: ''
            });

        default:
            return state;
    }
}

module.exports = cardPinReset;
