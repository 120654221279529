// @ vendors
const Immutable = require('immutable');
// @ constants
const {
  IDLE,
  FETCHING,
  FETCHED,
  FETCH_FAILED,
  SAVING,
  SAVE_FAILED,
  SAVED
} = require('constants/contactCenter/depositOrImpositionCancellationStatus');
const { WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');
const {
  FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_REQUEST,
  FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_FAILURE,
  FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_SUCCESS,

  DEPOSIT_RENOVATION_TO_CANCEL_SUCCESS,
  DEPOSIT_RENOVATION_TO_CANCEL_REQUEST,
  DEPOSIT_RENOVATION_TO_CANCEL_FAILURE,

  DEPOSIT_RENOVATION_CANCEL_SET_VISIBLE_STEP,
  DEPOSIT_RENOVATION_CANCEL_VALIDATE,
  DEPOSIT_RENOVATION_SHOW_CANCEL_MODAL,
} = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
  status: IDLE,
  error: '',
  renovations: [],
  currentStep: WIZARD_FIRST_STEP,
  depositId: '',
  showCancelModal: false,
  isFetching: false,
  steps: [
      {},
      {
          hasSignaturePattern: true
      }
  ],
});

const createFetchingState = () => {
  return INITIAL_STATE.merge({
      status: FETCHING
  });
}

const createFetchErrorState = (previousState, error) => {
  return previousState.merge({
      status: FETCH_FAILED,
      error,
      renovations: [],
  });
}

const createFetchSucessState = (previousState, { renovations, depositId }) => {
  return previousState.merge({
      status: FETCHED,
      error: '',
      depositId,
      renovations,
  });
}

const setVisibleStep = (previousState, step) => {
  return previousState.merge({
      currentStep: step,
  })
}

const validateStep = (previousState) => {
  return previousState.get('currentStep') === WIZARD_FIRST_STEP ? previousState.merge({
      currentStep: WIZARD_SECOND_STEP
  }) : previousState;
}

const showCancelModal = (previousState, show) => {
  return previousState.merge({
      showCancelModal: show
  });
}

const createSaveSucessState = previousState => {
  return previousState.merge({
      status: SAVED,
      error: '',
  });
};

const createIsSavingState = previousState => {
  return previousState.merge({
      status: SAVING,
      error: '',
  });
};

const createSaveErrorState = (previousState, error) => {
  return previousState.merge({
      status: SAVE_FAILED,
      error,
  });
};


function depositsRenovationCancel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_REQUEST:
      return createFetchingState(state, action.payload);
    case FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_FAILURE:
        return createFetchErrorState(state, action.payload.error);
    case FETCH_DEPOSIT_RENOVATION_CANCELLATION_DATA_SUCCESS:
        return createFetchSucessState(state, action.payload);
    case DEPOSIT_RENOVATION_CANCEL_SET_VISIBLE_STEP:
        return setVisibleStep(state, action.payload.step);
    case DEPOSIT_RENOVATION_CANCEL_VALIDATE:
        return validateStep(state, action.payload.step);
    case DEPOSIT_RENOVATION_SHOW_CANCEL_MODAL:
        return showCancelModal(state, action.payload.show);
    case DEPOSIT_RENOVATION_TO_CANCEL_REQUEST:
        return createIsSavingState(state);
    case DEPOSIT_RENOVATION_TO_CANCEL_FAILURE:
        return createSaveErrorState(state, action.payload.error);
    case DEPOSIT_RENOVATION_TO_CANCEL_SUCCESS:
        return createSaveSucessState(state);
    default:
      return state;
  }
}

module.exports = depositsRenovationCancel;
