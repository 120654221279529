// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ helpers
const { formatDate } = require('utilities/dateHelper');

// @ constants
const {
    MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_REQUEST,
    MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_SUCCESS,
    MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_FAILURE
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    capitalInicial: 0,
    divisa: '',
    fechaApertura: '',
    modalidadTipoInteresActual: '',
    modalidadTipoInteresDescuadre: '',
    modalidadTipoInteresDiferencial: '',
    fechaVencimientoActual: '',
    cuentaAsociada: '',
    tipoInteres: 0,
    error: '',
    isFetching: false
});

const operativeGeneralData = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_SUCCESS:
            const operativeGeneralDataAction = action.data;

            return state.merge({
                capitalInicial: operativeGeneralDataAction.capitalInicial.importe,
                divisa: operativeGeneralDataAction.capitalInicial.divisa,
                fechaApertura: formatDate(get(operativeGeneralDataAction, 'fechaApertura')),
                modalidadTipoInteresActual: get(operativeGeneralDataAction, 'modalidadTipoInteresActual', '') || '',
                modalidadTipoInteresDescuadre: get(operativeGeneralDataAction, 'modalidadTipoInteresDescuadre', '')  || '',
                modalidadTipoInteresDiferencial: get(operativeGeneralDataAction, 'modalidadTipoInteresDiferencial', '') || '',
                fechaVencimientoActual: formatDate(get(operativeGeneralDataAction, 'fechaVencimientoActual')),
                cuentaAsociada: operativeGeneralDataAction.cuentaAsociada.codbban,
                tipoInteres: operativeGeneralDataAction.tipoInteres,
                error: '',
                isFetching: false
            });
        case MORTGAGE_REQUEST_FETCH_OPERATIVE_GENERAL_DATA_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        default:
            return state
    }
};

module.exports = operativeGeneralData;
