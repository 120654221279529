const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP } = require('constants/index');

const cardPayLaterStep1 = require('./cardPayLater/cardPayLaterStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            cardPayLaterStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        signatureKeyFailure: false,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardPayLaterStep1(step, action);
        }
    })
}

function cardPayLaterReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_PAY_LATER_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_PAY_LATER_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.CARD_PAY_LATER_REQUEST:
            return state.merge({
                isFetching: true,
            });
        case actionTypes.CARD_GET_PAY_LATER_INFORMATION_FAILURE:
            return state.merge({
                informationError: action.payload.error,
                inProgress: false,
                isFetching: false
            });
        case actionTypes.CARD_PAY_LATER_FAILURE:
            return state.merge({
                error: action.payload.error,
                inProgress: false,
                isFetching: false
            });
        case actionTypes.CARD_PAY_LATER_IMPORT_SET:
        case actionTypes.CARD_PAY_LATER_IMPORT_SET_MAX_MIN:
        case actionTypes.CARD_GET_PAY_LATER_INFORMATION_SUCCESS:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true,
                isFetching: false
            })
        case actionTypes.CARD_PAY_LATER_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_PAY_LATER_CLEAR:
            return setInitialState();
        case actionTypes.CARD_PAY_LATER_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                signatureKeyFailure: false,
                errorReceived: '',
                inProgress: false
            })
        default:
            return state
    }
}

module.exports = cardPayLaterReducer;
