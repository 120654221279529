// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @constants
const {
    BROKER_TRANSFER_OF_DERIVATIVES_SET_ORIGIN_CONTRACT,
    BROKER_TRANSFER_OF_DERIVATIVES_FETCHING,
    BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_SUCCESS,
    BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_ERROR,
    BROKER_TRANSFER_OF_DERIVATIVES_MODIFY,
    BROKER_TRANSFER_OF_DERIVATIVES_REMOVE,
    BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_SUCCESS,
    BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_ERROR,
    BROKER_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT,
    BROKER_TRANSFER_OF_DERIVATIVES_MODIFY_CHANGE_CONCEPT,
    BROKER_TRANSFER_OF_DERIVATIVES_VALIDATE,
    BROKER_TRANSFER_OF_DERIVATIVES_SAVE,
    BROKER_TRANSFER_OF_DERIVATIVES_SAVE_SUCCESS,
    BROKER_TRANSFER_OF_DERIVATIVES_SAVE_ERROR,
    BROKER_TRANSFER_OF_DERIVATIVES_CANCEL,
    BROKER_TRANSFER_OF_DERIVATIVES_TOGGLE_DELETE_MODAL
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    previousState: {
        amountToTransfer: 0,
        concept: ''
    },
    hasDerivativeTransfer: false,
    creationDate: new Date(),
    originContractNumber: '',
    destinationContractNumber: '',
    amountToTransfer: 0,
    amountToTransferCurrency: '',
    amountToTransferInvalid: false,
    concept: '',
    valid: false,
    showDeleteModal: false,
    editing: false,
    editLoading: false,
    removeLoading: false,
    fetching: false,
    fetched: false,
    error: undefined
});

const updatePreviousState = (immState) => {
    const amountToTransfer = immState.get('amountToTransfer');
    const concept = immState.get('concept');

    return immState.set('previousState', {
        amountToTransfer,
        concept
    });
};

function brokerDerivativesMovementsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_TRANSFER_OF_DERIVATIVES_SET_ORIGIN_CONTRACT:
            return INITIAL_STATE.set('originContractNumber', action.payload.contractNumber);
        case BROKER_TRANSFER_OF_DERIVATIVES_FETCHING:
            return state.merge({
                fetching: true,
                fetched: false
            });
        case BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_SUCCESS:
            return state.merge({
                fetching: false,
                fetched: true,
                destinationContractNumber: get(action.payload.response, 'response[0].contratoDestino.numerodecuenta', ''),
                hasDerivativeTransfer: get(action.payload.response, 'response', []).length > 0
            });
        case BROKER_TRANSFER_OF_DERIVATIVES_FETCHING_ERROR:
            return state.merge({
                error: action.payload.error,
                fetching: false,
                fetched: true
            });
        case BROKER_TRANSFER_OF_DERIVATIVES_MODIFY:
            return updatePreviousState(state).merge({
                editing: true,
                amountToTransferInvalid: false,
                valid: false
            });
        case BROKER_TRANSFER_OF_DERIVATIVES_REMOVE:
            return state.set('removeLoading', true);
        case BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_SUCCESS:
            return INITIAL_STATE.merge({
                fetched: true,
                hasDerivativeTransfer: false
            });
        case BROKER_TRANSFER_OF_DERIVATIVES_REMOVE_ERROR:
            return state.set('removeLoading', false);
        case BROKER_TRANSFER_OF_DERIVATIVES_CHANGE_AMOUNT:
            return state.set('amountToTransfer', action.payload.amount);
        case BROKER_TRANSFER_OF_DERIVATIVES_MODIFY_CHANGE_CONCEPT:
            return state.set('concept', action.payload.concept);
        case BROKER_TRANSFER_OF_DERIVATIVES_VALIDATE:
            const amountToTransferInvalid = state.get('amountToTransfer') <= 0;
            return state.merge({
                valid: !amountToTransferInvalid,
                amountToTransferInvalid
            });
        case BROKER_TRANSFER_OF_DERIVATIVES_SAVE:
            return state.set('editLoading', true);
        case BROKER_TRANSFER_OF_DERIVATIVES_SAVE_SUCCESS:
            return state.set('editLoading', false);
        case BROKER_TRANSFER_OF_DERIVATIVES_SAVE_ERROR:
            return state.set('editLoading', false).mergeDeep(state.get('previousState'));
        case BROKER_TRANSFER_OF_DERIVATIVES_CANCEL:
            return state.set('editing', false).mergeDeep(state.get('previousState'));
        case BROKER_TRANSFER_OF_DERIVATIVES_TOGGLE_DELETE_MODAL:
            return state.set('showDeleteModal', !state.get('showDeleteModal'));
        default:
            return state;
    }
}

module.exports = brokerDerivativesMovementsReducer;
