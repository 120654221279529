const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const {
    EURO_SYMBOL,
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED
} = require('constants/index');

module.exports = {
    title: 'contractsView-depositWNMStep2Title',
    order: 2,
    footer_text: '',
    disableNext: 'deposittransfer_nextButtonDisabled',
    position: 'middle',
    components:
        [
            {
                id_component: 'label-deposit',
            },
            {
                id_component: 'dropdown-accounts',
                label: 'contractsView-depositWNMStep2Account',
                options: [],
                type: 'single',
                target: 'cta_seleccionada',
                targetIntervener: 'intervinientes_list',
                intervenersSpecial: true,
                isIntervenerAvailable: true,
                renderInterveners: 'deposittransfer_shouldBuild',
                isMaxAmountLabelAvailable: false,
                intervenersHeader: 'contractsView-depositWNMIntervenersHeader',
                maxAmountValue: 0,
                required: true,
                errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_WNM,
                accountChangeExtraFn: 'refreshMainNoNewMoneyDepositHireInformation',
                intervenersHierarchicalTypeSelection: true,
                intervenerOptions: [
                    INTERVENER_LABEL_HOLDER,
                    INTERVENER_LABEL_AUTHORIZED,
                    INTERVENER_LABEL_EMPOWERED
                ],
                showIntervenerControls: true,
                restoreDefaultIntervenersEnabled: true,
                buildCondition: 'deposittransfer_dropdwonAccountShouldBuild',
                showSavingsAccountsMessage: true,
                customCategoryEvent: 'deposittransfer_customCategoryEvent'
            },
            {
                id_component: 'import-amount-input',
                id: 'import-input',
                label: 'contractsView-depositWNMStep2Amount',
                subLabel: null,
                type: 'form',
                target: 'monto_traspasar',
                onChangeFn: 'deposittransfer_onChangeAmount',
                importMin: 0,
                importMax: 0,
                inputType: 'float',
                amountZeroAlertVisible: false,
                extraValueValidation: true,
                source: 'deposittransfer_loadAmountInputAmounts',
                subLabelBuildCondition: 'deposittransfer_shouldInputSubLabelBuild',
                defaultValue: 1,
                order: 1,
                infoMessageComplete: 'contractsView-depositWNMStep2ImportInputComplete',
                infoMessageMinimum: 'contractsView-depositWNMStep2ImportInputMin',
                format_type: EURO_SYMBOL,
                buildCondition: 'deposittransfer_shouldBuild',
                errorMessage: 'contractsView-depositWNMStep2ImportInputErrorMessage',
                categoryEvent: 'deposittransfer_inputCategoryEvent',
            },
            {
                id_component: 'interest-band'
            },
            {
                id_component: 'disclaimer-personal-info',
                messageKey: 'depositWNMMsg',
                linkTextKey: 'depositWNMLinkText',
                linkUrlKey: 'depositWNMLinkUrl'
            }
        ]
};
