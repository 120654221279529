// @ vendors
const Immutable = require('immutable');

//@Actions
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    tpps: {},
    isFetchingConsents: false,
    isFetchingRequest: false,
    isFetchingRemove: false,
    isFetchingRenew: false,
    showActives: true,
    removeSuccessful: false,
    renewSuccessful: false,
    renewFailure: false,
    isErrorRequest: false,
    consentsHaveBeenFetched: false,
    isFetchingGetAllConsents: false, 
    errorGetAllConsents: false, 
    allConsents: {}
});


const myProfileKeysTpps = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.TPP_KEYS_GET_CONSENTS_FAILURE:
            return state.merge({ isFetchingConsents: false, isErrorRequest: true, consentsHaveBeenFetched: true });
        case actionTypes.TPP_KEYS_GET_CONSENTS_IN_PROGRESS:
            return state.merge({ isFetchingConsents: true, isErrorRequest: false });
        case actionTypes.TPP_KEYS_GET_CONSENTS_SUCCESS: {
            const readableConsent = action.payload.type === "ALL" || action.payload.type === "AGGREGATOR";
            const fundsConsent = action.payload.type === "ALL" || action.payload.type === "PAYMENT_GATEWAY";

            return state
                .merge({ consentsHaveBeenFetched: true })
                .merge({ isFetchingConsents: false })
                .merge({isErrorRequest: false})
                .mergeDeepIn(['tpps'], {
                    [action.payload.id]: {
                        ...action.payload,
                        hasEmptyConsents: action.payload.consent ? false : true, 
                        hasReadableConsent: !!readableConsent,
                        hasFundsConsent: !!fundsConsent,
                        name: action.payload.name,
                        roles: action.payload.type
                    }
                });
        }
        case actionTypes.GET_ALL_CONSENTS_FAILURE:
            return state.merge({ isFetchingGetAllConsents: false, errorGetAllConsents: true });
        case actionTypes.GET_ALL_CONSENTS_IN_PROGRESS:
            return state.merge({ isFetchingGetAllConsents: true });
        case actionTypes.GET_ALL_CONSENTS_SUCCESS: {
            return state
                .merge({ isFetchingGetAllConsents: false })
                .merge({ errorGetAllConsents: false })
                // .mergeDeepIn(['tpps'], {
                //     [action.payload.tppId]: {
                //         consents: action.payload,
                //     }
                // })
                .mergeDeepIn(['allConsents'], {
                    ...action.payload
                });
        }
        case actionTypes.TPP_KEYS_REQUEST_DATA_IN_PROGRESS:
            return state.merge({
                isFetchingRequest: true
            });
        case actionTypes.TPP_KEYS_REQUEST_DATA_SUCCESS:
            return state.merge({
                isFetchingRequest: false,
                isErrorRequest: false,
                tpps: action.payload.tpps.reduce((lv, cv) => ({...lv, [cv.id]: cv}), {})
            });
        case actionTypes.TPP_KEYS_REQUEST_DATA_FAILURE:
            return state.merge({
                isErrorRequest: true,
                isFetchingRequest: false
            });
        case actionTypes.TPP_KEYS_RESET_TPP_KEY:
            return initialState;
        case actionTypes.TPP_KEYS_UPDATE_TAB:
            return state.merge({showActives: !state.get('showActives')});
        case actionTypes.TPP_KEYS_REMOVE_TPP_KEY_IN_PROGRESS:
            return state.merge({
                isFetchingRemove: true,
                removeSuccessful: false,
                removeFailure: false,
            });
        case actionTypes.TPP_KEYS_CONSENT_TPP_SUCCESS:
            return state.merge({
                removeSuccessful: true,
                isFetchingRemove: false,
                removeFailure: false,
            });
        case actionTypes.TPP_KEYS_CONSENT_TPP_FAILURE:
            return state.merge({
                removeFailure: true,
                isFetchingRemove: false,
                removeSuccessful: false,
            });
        case actionTypes.TPP_KEYS_CONSENT_RETRY_REMOVE:
            return state.merge({
                isFetchingRemove: false,
                removeSuccessful: false,
                removeFailure: false,
            })
        case actionTypes.TPP_KEYS_GET_MOVEMENTS_IN_PROGRESS:
            return state.mergeIn(['tpps', action.payload.id], {
                fetchingMovements: true,
                fetchingMovementsFailure: false,
                fetchingMovementsSuccess: false
            });
        case actionTypes.TPP_KEYS_GET_MOVEMENTS_SUCCESS:
            return state.mergeIn(['tpps', action.payload.id], {
                fetchingMovements: false,
                fetchingMovementsSuccess: true,
                fetchingMovementsFailure: false,
                movements: action.payload.movements
            });
        case actionTypes.TPP_KEYS_GET_MOVEMENTS_FAILURE:
            return state.mergeIn(['tpps', action.payload.id], {
                  fetchingMovements: false,
                  fetchingMovementsFailure: true,
                  fetchingMovementsSuccess: false
            });
        case actionTypes.TPP_KEYS_SET_EXPANDED_TPP_ACCORDION:
            return state.mergeIn(['tpps', action.payload.id],
                state.getIn(['tpps', action.payload.id])
                .set('isAccordionExpanded', action.payload.expanded));
        default:
            return state;
    }
}

module.exports = myProfileKeysTpps;


