
// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');


function setInitialState() {
    return Immutable.fromJS({
            data: {},
            error: '',
            isFetching: false,
            step: 1
    });
}

function updateSetPhoneOTP(phoneData) {
    return {
            data: phoneData,
            error: '',
            isFetching: false
    };
}

function userData(state = setInitialState() , action) {
    switch (action.type) {
        case actionTypes.USER_PHONE_SUCCESS:
            return state.merge(
                updateSetPhoneOTP(action.payload.data)
            );
        case actionTypes.USER_DATA_REQUEST:
            return state.merge({
                error: '',
                isFetching: true
        });
        case actionTypes.USER_DATA_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.USER_DATA_SET_STEP:
            return state.merge({
                 step: action.payload.step
            });
        case actionTypes.USER_DATA_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = userData;
