// @vendors
const Immutable = require('immutable');

// @reducers
const BrokerRequestFinancialOperationChangeStep1 = require('./BrokerRequestFinancialOperationChange/BrokerRequestFinancialOperationChangeStep1');
const BrokerRequestFinancialOperationChangeStep2 = require('./BrokerRequestFinancialOperationChange/BrokerRequestFinancialOperationChangeStep2');

// @constants
const actionTypes = require('constants/actionTypes');

const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP
} = require('constants/index');

const INITIAL_STATE = Immutable.fromJS({
    steps: [
        BrokerRequestFinancialOperationChangeStep1(undefined, { type: null }),
        BrokerRequestFinancialOperationChangeStep2(undefined, { type: null })
    ],
    visibleStep: WIZARD_FIRST_STEP,
    willLeave: false,
    isLoaded: false,
    selectedCorporateOperationIndex: -1,
    valor: {
        codigoEmision: '',
        codigoValorInterno: '',
        descriptionProcess: '',
        descriptionValue: '',
        startDate: '',
        endDate: '',
        tipoProceso: '',
        subtipoProceso: '',
        iso: ''
    },
    contrato: {
        number: '',
        product: ''
    },
    cuentaAsociada: {
        number: '',
        balance: {
            amount: 0,
            currency: ''
        }
    },
    digitodecontrol: '',
    contract: '',
    contractOwner: '',
    titlesNumber: 0, // @hardcoded
    orderNumber: '',
    operationCheckbox: false,
    errors: {
        checkbox: false,
    },
    fetchConfirmOrder: false,
    finished: false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return BrokerRequestFinancialOperationChangeStep1(step, action);
            case SECOND_STEP:
                return BrokerRequestFinancialOperationChangeStep2(step, action);
        }
    });
}

function brokerRequestFinancialOperationChangeReducer(state = INITIAL_STATE, action) {
    let visibleStep;
    let partialSteps;
    let immFirstStep;
    let immSecondStep;
    switch (action.type) {
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_START_OPERATION:
            return INITIAL_STATE.merge({
                digitodecontrol: action.payload.cuenta.digitodecontrol,
                contract: action.payload.walletAccountType,
                contrato: action.payload.contract,
                valor: action.payload.operation,
                contractOwner: action.payload.owner,
                cuentaAsociada: action.payload.associatedAccount,
                selectedCorporateOperationIndex: action.payload.index,
                isLoaded: action.payload.isLoaded
            });
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_OPERATION_CHECKBOX: {
            immSecondStep = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], BrokerRequestFinancialOperationChangeStep2(immSecondStep, action))
                        .set('fetchConfirmOrder',false);
        }
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_DOCUMENTS_READ: {
            immSecondStep = state.getIn(['steps', SECOND_STEP]);
            return state.setIn(['steps', SECOND_STEP], BrokerRequestFinancialOperationChangeStep2(immSecondStep, action))
                        .set('fetchConfirmOrder',false);
        }
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SUCCESS:
            return state.merge({
                finished: true,
                errors: {checkbox: false},
                fetchConfirmOrder: false,
                orderNumber: action.payload.response.numeroOrden
            });
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_FAILURE:
            return state.set('fetchConfirmOrder',false);
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_TITLES_AMOUNT:
            immFirstStep = state.getIn(['steps', FIRST_STEP]);
            return state.setIn(['steps', FIRST_STEP], BrokerRequestFinancialOperationChangeStep1(immFirstStep, action));
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_SET_VISIBLE_STEP:
            const step = action.payload.step;
           return state.set('visibleStep', step).setIn(['steps', step - 1, 'valid'], false);
        case actionTypes.BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE_SUCCESS:
            immFirstStep = state.getIn(['steps', FIRST_STEP]);
            return state.set('visibleStep', WIZARD_SECOND_STEP).setIn(['steps', FIRST_STEP], BrokerRequestFinancialOperationChangeStep1(immFirstStep, action));
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_WILL_CANCEL:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_VALIDATE_STEP:
            let isSecondStep = false;
            switch (state.get('visibleStep')) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                    break;
                case WIZARD_SECOND_STEP:
                    isSecondStep = true;
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_SECOND_STEP;
                    break;
                default:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_FIRST_STEP;
            }
            return state.merge({
                steps: partialSteps,
                fetchConfirmOrder: isSecondStep,
                visibleStep
            });
        default:
            return state;
    }
}

module.exports = brokerRequestFinancialOperationChangeReducer;
