const get = require('lodash/object/get');

//@constants
const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_NONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_DONE,
} = require('utilities/contactCenter/wrapUp/constants');

const {
    INSURANCE_REQUEST_POST_WIZARD_SUCCESS,
    INSURANCE_REQUEST_POST_WIZARD_ERROR,
    INSURANCE_REQUEST_RELATED_POST_WIZARD_SUCCESS,
    INSURANCE_REQUEST_FREE_STARTED,
    INSURANCE_REQUEST_COMBINED_STARTED,
} = require('insuranceConstants/actionTypes');

const { WIZARD_REQUEST_RESPONSE_OPTIONS } = require('insuranceConstants/insuranceRequestHome');

const ACTION_CODE_INSURANCES_LANDING = 5248;
const ACTION_CODE_INSURANCES_HOME_DETAIL = 5251;
const ACTION_CODE_INSURANCES_HOME_REQUEST = 5249;
const ACTION_CODE_INSURANCES_HOME_REQUEST_RELATED = 5250;
const ACTION_CODE_INSURANCES_HOME_REQUEST_RELATED_HIRED = 38001;

module.exports = {
    [ACTION_CODE_INSURANCES_LANDING]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/insurances'
        }
    },
    [ACTION_CODE_INSURANCES_HOME_REQUEST]: {
        entry: {
            action: [ INSURANCE_REQUEST_FREE_STARTED ],
            check: (action) => {

                let operationState = OPERATION_LOG_NONE
                const isEnabledFF = get(action, 'payload.isEnabledFF');
                const isCombinedMortgaged = get(action, 'payload.isCombinedMortgaged');

                if ((!isEnabledFF && isCombinedMortgaged === null) ||
                    (isEnabledFF && isCombinedMortgaged === false)
                ) {
                    operationState = OPERATION_LOG_DONE
                }

                return {
                    actionCode: ACTION_CODE_INSURANCES_HOME_REQUEST,
                    operationState
                }
            }
        },
        finish: {
            action: [INSURANCE_REQUEST_POST_WIZARD_SUCCESS, INSURANCE_REQUEST_POST_WIZARD_ERROR],
            check: (action) => {
                const actionCode = ACTION_CODE_INSURANCES_HOME_REQUEST;
                let operationState = OPERATION_LOG_NONE;

                if (!action.data.isCombined) {
                    if (action.type === INSURANCE_REQUEST_POST_WIZARD_SUCCESS
                        && action.data.response === WIZARD_REQUEST_RESPONSE_OPTIONS.SUCCESS) {
                        operationState = OPERATION_LOG_DONE;
                    } else if (action.type === INSURANCE_REQUEST_POST_WIZARD_ERROR) {
                        operationState = OPERATION_LOG_FAIL;
                    }
                }

                return {
                    actionCode,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_INSURANCES_HOME_DETAIL]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/insurances/home/[0-9]+'
        }
    },
    [ACTION_CODE_INSURANCES_HOME_REQUEST_RELATED_HIRED]: {
        entry: {
            action: [ INSURANCE_REQUEST_COMBINED_STARTED ]
        },
        finish: {
            action: [INSURANCE_REQUEST_POST_WIZARD_SUCCESS, INSURANCE_REQUEST_POST_WIZARD_ERROR],
            check: (action) => {
                const actionCode = ACTION_CODE_INSURANCES_HOME_REQUEST_RELATED_HIRED;
                let operationState = OPERATION_LOG_NONE;

                if (action.data.isCombined) {
                    if (action.type === INSURANCE_REQUEST_POST_WIZARD_SUCCESS
                        && action.data.response === WIZARD_REQUEST_RESPONSE_OPTIONS.SUCCESS) {
                        operationState = OPERATION_LOG_DONE;
                    } else if (action.type === INSURANCE_REQUEST_POST_WIZARD_ERROR) {
                        operationState = OPERATION_LOG_FAIL;
                    }
                }

                return {
                    actionCode,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_INSURANCES_HOME_REQUEST_RELATED]: {
        entry: {
            path: '/myprofile/mortgage/insurances-related'
        },
        finish: {
            action: [INSURANCE_REQUEST_RELATED_POST_WIZARD_SUCCESS],
            check: (action) => {
                let operationState = OPERATION_LOG_NONE;
                const actionCode = ACTION_CODE_INSURANCES_HOME_REQUEST_RELATED;

                if (action.type === INSURANCE_REQUEST_RELATED_POST_WIZARD_SUCCESS
                    && action.data.response === WIZARD_REQUEST_RESPONSE_OPTIONS.SUCCESS
                ) {
                    operationState = OPERATION_LOG_DONE;
                }
                else if (action.type === INSURANCE_REQUEST_RELATED_POST_WIZARD_SUCCESS
                    && action.data.response === WIZARD_REQUEST_RESPONSE_OPTIONS.ERROR
                ) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    actionCode,
                    operationState
                }
            }
        }
    }
}
