// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_TRANSFER_PENSION_PLANS_DEST_EXIST_OPTION,
    BROKER_TRANSFER_PENSION_PLANS_DEST_NEW_OPTION,
    BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
    API_ENTERPRISE_NUMBER,
    API_CENTER
} = require('constants/index');

function setInitialState() {
   return Immutable.Map().merge({
        valid: false,
        validDestinationPlan: true,
        validAccount: true,
        planSelected: false,
        accountSelected: false,
        destinationPlanData: {
            associatedAccount: {}
        },
        destinationTarget: BROKER_TRANSFER_PENSION_PLANS_DEST_EXIST_OPTION,
        destinationInWalletDisabled: false,
        availablePlansInWallet: [],
        availablePlansFromSearch: [],
        planFromWallet: true,
        isFetching: false,
        error: false,
        notFound: false,
        success: false
   });
}

function destinationPlanDataFetchNotAvailable(state) {
    return state.get('destinationPlanData').merge({
        pensionFund: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        gestionEntity: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        depositEntity: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND
    });
}

function destinationPlanDataFetchAvailable(response, state) {
    return state.get('destinationPlanData').merge({
        pensionFund: BROKER_TRANSFER_PENSION_PLAN_DATA_NOT_FOUND,
        gestionEntity: response.ProviderCompany.Name,
        depositEntity: response.CustodianCompanies[0].Name
    });
}

function validateStep(step){
    const planSelected = step.get('planSelected');

    if (step.get('planFromWallet')) {
        return step.merge({
            validDestinationPlan: planSelected,
            valid: planSelected
        })
    }
    else {
        const accountSelected = step.get('accountSelected');
        return step.merge({
            validAccount: accountSelected,
            validDestinationPlan: planSelected,
            valid: accountSelected && planSelected
        })

    }   
}

function mapData(planData, state) {
    if (state.get('destinationTarget') === BROKER_TRANSFER_PENSION_PLANS_DEST_EXIST_OPTION) {
        return Immutable.fromJS({
            planName: planData.get('Name'),
            DGSCode: planData.getIn(['comercialData','DGSCode']),
            name: planData.get('Name'),
            fullContractNumber: planData.get('fullContractNumber'),
            owner: planData.get('owner'),
            searchId: planData.get('searchId'),
            shares: planData.get('shares'),
            internalId: planData.get('internalId'),
            totalValueEur: planData.get('totalValueEur'),
            comercialData: {
                SecId: planData.getIn(['comercialData','SecId']),
                CustomMinimumPurchaseAmount: planData.getIn(['comercialData','CustomMinimumPurchaseAmount'])
            },
            associatedAccount: {
                productId: planData.getIn(['associatedAccount','productId']),
                contractNumber: planData.getIn(['associatedAccount','contractNumber']),
                //product: state.getIn(['destinationPlanData','associatedAccount','product']),
                controlDigit: planData.getIn(['associatedAccount','controlDigit']),
                fullAccountNumber: planData.getIn(['associatedAccount','fullAccountNumber']),
            },
            associatedAccountBalance: planData.get('associatedAccountBalance'),
           })
    } else {
        return Immutable.fromJS({
            planName: planData.get('Name'),
            searchId: planData.get('CustomInstitutionSecurityId'),
            DGSCode: planData.get('DGSCode'),
            name: planData.get('Name'),
            comercialData: {
                SecId: planData.get('SecId'),
                CustomMinimumPurchaseAmount: planData.get('CustomMinimumPurchaseAmount')
            },
            associatedAccount: {
                productId: state.getIn(['destinationPlanData','associatedAccount','productId']),
                contractNumber: state.getIn(['destinationPlanData','associatedAccount','contractNumber']),
                product: state.getIn(['destinationPlanData','associatedAccount','product']),
                controlDigit: state.getIn(['destinationPlanData','associatedAccount','controlDigit']),
                fullAccountNumber: state.getIn(['destinationPlanData','associatedAccount','fullAccountNumber']),
            },
            associatedAccountBalance: state.getIn(['destinationPlanData','associatedAccount','balance']),
           })
    }
}

function brokerInternalTransferFundsStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_DESTINATION:
            return state.merge({
                destinationPlanData: mapData(action.payload.planData, state),
                planSelected: true,
                success: true,
                validDestinationPlan: true
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_DATA_REQUEST:
            return state.merge({
                isFetching: true,
                error: false,
                notFound: false,
                success: false
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_IN_SEARCHER_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_FETCH_ALL_PLANS_IN_SEARCHER_REQUEST:
            return state.merge ({
                isFetching:true,
                error:false,
                success: false
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECTED_IN_SEARCHER_SUCCESS:
            return state.merge({
                planSelectedFromSearcher: action.payload.product,
                destinationPlanData: mapData(action.payload.planData, state),
                planSelected: true, 
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_NOT_FOUND:
            return state.merge({
                isFetching: false,
                notFound: true,
                destinationPlanData: destinationPlanDataFetchNotAvailable(state)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_SUCCESS:
            const selectedPlan = true;
            const accountSelected = state.get('planFromWallet') ? true : state.get('accountSelected');
            return state.merge({
                planSelected: true,
                isFetching: false,
                success: selectedPlan && accountSelected,
                destinationPlanData: destinationPlanDataFetchAvailable(action.payload.securityDetails, state)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_DESTINATION_OPTION:
            const selectFromWallet = action.payload.option === BROKER_TRANSFER_PENSION_PLANS_DEST_EXIST_OPTION;
            return state.merge({
                planSelected: false,
                accountSelected: false,
                destinationPlanData: {},
                destinationTarget: action.payload.option,
                planFromWallet: selectFromWallet,
                success: false,
                validDestinationPlan: true
            })
        case actionTypes.BROKER_TRANSFER_PENSION_PLAN_INTERNAL_ASSOCIATE_ACCOUNT:
            const completeCode = API_ENTERPRISE_NUMBER + API_CENTER + action.payload.selectedControlDigit + action.payload.selectedProduct + action.payload.selectedContractNumber;
            const selectedAccount = true;
            return state.merge({
                accountSelected: selectedAccount,
                validAccount:true,
                success: selectedAccount && state.get('planSelected'),
                destinationPlanData: {
                    associatedAccount:{
                        productId: action.payload.selectedId,
                        controlDigit: action.payload.selectedControlDigit,
                        product: action.payload.selectedProduct,
                        contractNumber : action.payload.selectedContractNumber,
                        balance : action.payload.selectedBalance,
                        fullAccountNumber : completeCode
                    }
                }
            })
        case actionTypes.BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS:
            return state.merge({
                availablePlansFromSearch: action.payload.data.rows
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_UPDATE_DESTINATION_PLANS:
            const planJustSelected = state.get('planSelected') ? action.payload.isPlanFromWallet : true;
            const destinationInWalletDisabled = action.payload.plans.toJS().length === 0;
            const destinationOption = destinationInWalletDisabled || planJustSelected ?
                BROKER_TRANSFER_PENSION_PLANS_DEST_EXIST_OPTION:
                BROKER_TRANSFER_PENSION_PLANS_DEST_NEW_OPTION;
            return state.merge({
                availablePlansInWallet: action.payload.plans,
                destinationTarget: destinationOption,
                destinationInWalletDisabled: destinationInWalletDisabled,
                planFromWallet: planJustSelected
            })
        default:
            return state;
    }
}

module.exports = brokerInternalTransferFundsStep2;
