// @vendors
const get = require('lodash/object/get');

const getCollectiveByAlias = (alias, info) => {
    const activeItem = info.find(item => item.get('alias') === alias);
    return activeItem ? activeItem.get('collectiveInfo', true) : null;
}

const getCollectiveByType = (type, info) => {
    const activeItem = info.find(item => {
        return item.get('type') === get(type, 'type') &&
            item.get('subType') === get(type, 'subType') &&
            item.get('standard') === get(type, 'standard')
    });

    return activeItem ? activeItem.get('collectiveInfo', true) : null;
}

const getCollectiveByParams = (alias, type, info) => {
    return alias ? getCollectiveByAlias(alias, info) : getCollectiveByType(type, info);
}

const getCollectiveTagDetails = (topbarDetails, profileDetails) => {
    let data = {};
    const topbarData = topbarDetails.get('topbarData');
    const collectives = topbarData.getIn(['topbar', 'collectives']);

    if (profileDetails.get('isCollective') && collectives && collectives.size){
        const collectiveType = profileDetails.get('collectiveType');
        const audience = `Collective_${collectiveType.get('company')}-${collectiveType.get('code3')}-${collectiveType.get('code4')}`;

        if (Array.isArray(collectives)) {
            collectives.forEach( col => {
                if (col.get('target_audience').indexOf(audience) > -1 ){
                    data = {
                        collective_title : col.get('title'),
                        collective_background_color : col.get('bg_color'),
                        collective_text_color : col.get('text_color')
                    }
                }

            })
        } else {
            data= {
                collective_title : collectives.get('title'),
                collective_background_color : collectives.get('bg_color'),
                collective_text_color : collectives.get('text_color')
            }
        }
    }

    return data;
}

module.exports = {
    getCollectiveByParams,
    getCollectiveTagDetails
};
