const sortBy = (fieldName, array) => {
    return array.sort(function (a, b) {
        if (a[fieldName] > b[fieldName]) {
          return 1;
        }
        if (a[fieldName] < b[fieldName]) {
          return -1;
        }
        return 0;
    });
}

const getEstatesToRequest = immEstates => {
    const estates = immEstates.get('list');
    const estatesState = immEstates.get('estatesState').filter(estate => estate.get('value') === 'request');
    const estatesToRequest = estates.filter(estate => {
        const estateIsToRequest = !!estatesState.find((estateStateValue, estateStateId) => (
            estate.get('bienId') === parseInt(estateStateId)
        ));
        return estateIsToRequest;
    });
    return estatesToRequest.toJS();
}

const getFirstElements = (arr, quantity) => {
    return arr.slice(0, quantity)
}

const getLastListElements = (arr, quantity) => {
    return arr.slice(arr.length - quantity, arr.length)
}

const findArrayIntersection = (array1, array2) => {
    return array1.filter((value) => array2.includes(value));
};

export {
    sortBy,
    getEstatesToRequest,
    getFirstElements,
    getLastListElements,
    findArrayIntersection
}
