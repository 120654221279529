// @ utilities
const PersonalDataHelper = require('utilities/personalDataHelper');
// @ constants
const { GENDER_MALE, GENDER_FEMALE } = require('constants/index');

function getFormElements(component, state) {
    return state.filter(x => x.id === component.target)[0].value;
}

function isNumber(value) {
    return !isNaN(value);
}

function changeDateFormat(date) {
    const d = date.split('-');

    return (`${d[2]}/${d[1]}/${d[0]}`);
}

function cleanSpecialChars(text) {
    return text.replace(/[^\w\s]/gi, '');
}

function removeFirstZero(value) {
    return (value && value.charAt(0) === '0') ? value.substr(1) : value;
}

function validateTypeOfValue(value) {
    if (value instanceof Object || isNumber(value)) {
        return value;
    } else {
        return value.trim();
    }
}

/**
 * Required since from the Combo and DatePicker now we just have the value
 * and the method to process require  target object with name and value properties
 * */
function buildTarget(sourceEvent, component) {
    const targetobject = {
        target: {
            value: sourceEvent,
            name: component.id
        }
    };
    return targetobject;
}

function existsInForm(formElements, sourceEvent) {
    const field = sourceEvent.target;
    return formElements.find(value => {
        return value.id === field.name;
    });
}

function updateFormElement(formElements, sourceEvent) {
    const field = sourceEvent.target;
    const value = validateTypeOfValue(field.value);
    formElements.filter(y => y.id === field.name)[0].value = value;
    return formElements;
}

function addFormElement(formElements, sourceEvent) {
    const field = sourceEvent.target;
    const value = validateTypeOfValue(field.value);
    formElements.push({id: field.name, value: value});
    return formElements;
}

function getFieldInTarget(card_details, field_id){
    const form_count =  card_details.filter(x => x.get('id') === 'form_count').get(0).get('value');
    const targets = form_count.map(form => card_details.filter(x => x.get('id') === form).get(0));
    let returnField = [];

    targets.forEach(targetItem => {
        targetItem.get('value').forEach(field => {
            if (field.get('id') == field_id) {
                returnField = field.set('target',targetItem.get('id'));
            }
        })
    });
    return returnField;
}

function getField (cardDetails, component) {
    const { target, id, type } = component;
    const fieldTarget = target || id;
    const targetElement = cardDetails.find(detail => detail.id === fieldTarget) ;
    let element;

    if (type === 'form') {
        element = targetElement.value.find(detail => detail.id === id);
        if (!element) {
            element = {
                id,
                value: null
            }
            targetElement.value.push(element);
        }
    } else {
        element = targetElement;
    }

    return element;
}

function componentVisibility (cardDetails, component) {
    let show = true;

    if (cardDetails && component.visible) {
        const visible = Array.isArray(component.visible) ? component.visible : [component.visible];
        let shouldShow = [true];

        visible.forEach(item => {
            const targetArray = item.target.split('.');
            const target = cardDetails.find(detail => detail.get('id') === targetArray[0]);

            const element = targetArray.length > 1 ?
                target.get('value').find(detail => detail.get('id') === targetArray[1]) :
                target;

            if (element) {
                if ('inverse' in item){ // in order to check a different value
                    shouldShow.push(item.value.indexOf(element.get('value')) === -1);
                } else {
                    shouldShow.push(item.value.indexOf(element.get('value')) !== -1);
                }
            }
        });

        show = shouldShow.every(value => value);
    }

    return show;
}

function syncFormValuesWithState(state, component, formValues) {
    state.filter(x => x.id === component.target)[0].value = formValues;
    return state;
}

//PENDING: there's an issue here when an autosuggest is emptied, the value gets nested
// like this {value: {value: ''}} and being an object the validators don't considerit empty
function buildComboTargetObject(sourceEvent, component) {
    const newValue = sourceEvent.value === '' ? sourceEvent.value : sourceEvent.value || sourceEvent;
    return buildTarget(newValue, component)
}

function buildDatePickerTargetObject(sourceEvent, component) {
    return buildTarget(sourceEvent, component)
}

function buildAmountTargetObject(sourceEvent, component) {
    return buildTarget(sourceEvent, component);
}

function handleFormElementData(sourceEvent, component, state) {
    let formElements = getFormElements(component, state);
    const elementExists = existsInForm(formElements, sourceEvent);
    if (elementExists) {
        formElements = updateFormElement(formElements, sourceEvent);
    } else {
        formElements = addFormElement(formElements, sourceEvent);
    }
    return syncFormValuesWithState(state, component, formElements);
}

function saveData(sourceEvent, component, state) {
    state.filter(x => x.id === component.id)[0].value = sourceEvent.target.value;
    if ("disabled" in sourceEvent.target) {
        state.filter(x => x.id === component.id)[0].disabled = sourceEvent.target.disabled;
    }
    return state;
}

function onChangeAmount(amount) {
    return { target: 'monto_traspasar', value: parseInt(amount) };
}

function stringToBoolean(value) {
    if (value && typeof value === 'string') {
        if (value.toLowerCase() === 'true') return true;
        if (value.toLowerCase() === 'false') return false;
    }
    return value;
}

const GENDERS = [GENDER_MALE, GENDER_FEMALE];

const getGender = value => GENDERS[value];

const formatPhoneNumber = phoneNumber => {
    const content = phoneNumber ? phoneNumber.replace(/ /g, '') : '';
    return `${content.substr(0, 2)}-${content.substr(2, 3)}-${content.substr(5, content.length)}`;
}

function getCardDetailsElement(component, card_details) {
    let element;
    if (card_details) {
        const target = card_details.find(x => x.get('id') === component.target);

        if (component.type === 'form') {
            element = target.find(x => x.get('id') === component.id);
        } else {
            element = target;
        }
    }

    return element;
}

function buildDisabled(value, disabled, state, field) {
    const component = state.find(element => element.id === field);
    const source = {
        target: {
            value: value,
            disabled: disabled,
            isErrorVisible: false,
            name: component.id
        }
    };
    let theTarget = state.find(form => form.id === component.target).value;
    let componentTarget = theTarget.find(element => element.id === component.id);

    if (!componentTarget) {
        theTarget.push({
            id : component.id,
            value,
            isErrorVisible : false
        });
    } else {
        componentTarget.value = value;
        componentTarget.isErrorVisible = false;
    }
    return saveData(source, component, state);
}

function changeRequired(state, field, required) {
    state.find(x => x.id === field).required = required;
    return state;
}

function ellipsisString(string, maxSize){
    return string.length >= maxSize ? `${string.substring(0, maxSize - 3)}...` : string;
}

function haveEmail(immProfileFields) {
    return PersonalDataHelper.getEmail(immProfileFields);
}

module.exports = {
    addFormElement,
    buildAmountTargetObject,
    buildComboTargetObject,
    buildDatePickerTargetObject,
    buildDisabled,
    buildTarget,
    changeDateFormat,
    changeRequired,
    cleanSpecialChars,
    componentVisibility,
    ellipsisString,
    existsInForm,
    formatPhoneNumber,
    getCardDetailsElement,
    getField,
    getFieldInTarget,
    getFormElements,
    getGender,
    handleFormElementData,
    haveEmail,
    isNumber,
    onChangeAmount,
    removeFirstZero,
    saveData,
    stringToBoolean,
    syncFormValuesWithState,
    updateFormElement,
    validateTypeOfValue
};
