const Immutable = require('immutable');

const {
    MOVEMENTS_CATEGORY_FETCHING,
    MOVEMENTS_CATEGORY_SUCCESS,
    MOVEMENTS_CATEGORY_FAILURE,
} = require('constants/actionTypes');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            error: false,
            fetching: false,
            movementsData: []
        })
    );
};

function expensesByCategoriesMovementsCategory(state = setInitialState(), action) {
    switch (action.type) {
        case MOVEMENTS_CATEGORY_FETCHING:
            return state.merge({
                error: false,
                fetching: true
            });

        case MOVEMENTS_CATEGORY_FAILURE:
            return state.merge({
                error: true,
                fetching: false
            });

        case MOVEMENTS_CATEGORY_SUCCESS:
            return state.merge({
                error: false,
                fetching: false,
                movementsData: action.payload.data
            });

        default:
            return state;
    }
}

module.exports = expensesByCategoriesMovementsCategory;