// @ vendors
const Inmutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Inmutable.fromJS({
    isFetching: false,
    error: '',
    provincesList: [],
    populationData: [],
    registersData: [],
    allEditSelectsLoaded: false
});

const getLocationData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_FETCH_PROVINCES_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_PROVINCES_SUCCESS:
            return state.merge({
                isFetching: false,
                provincesList: action.data.provinces
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_PROVINCES_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.data.error
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_POPULATION_REQUEST:
            return state.merge({
                isFetching: true,
                populationData: []
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_POPULATION_SUCCESS:
            return state.merge({
                isFetching: false,
                populationData: action.data
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_POPULATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.data.error
            });

        case actionTypes.MORTGAGE_REQUEST_FETCH_REGISTERS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_REGISTERS_SUCCESS:
            return state.merge({
                isFetching: false,
                registersData: action.data
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_REGISTERS_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.data.error
            });
        case actionTypes.MORTGAGE_REQUEST_FETCH_LOCATION_DATA_DONE:
            return state.set('allEditSelectsLoaded', true);

        case actionTypes.MORTGAGE_REQUEST_RESET_LOCATION_DATA:
            return initialState.merge();

        default:
            return state
    }
};

module.exports = getLocationData;
