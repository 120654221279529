const {
    DEBIT_4B_MC_MUNDO_123_SUBTYPE,
    DEBIT_AT_HOME_ABROAD_SUBTYPE,
    DEBIT_DIAMOND_SUBTYPE,
    DEBIT_PREMIUM_SUBTYPE,
    DEBIT_SOLIDARIA_SUBTYPE,
    MINICARD_SUBTYPE,
    OPEN_DEBIT_SUBTYPE,
    DEBIT_GERMANY_SUBTYPE
} = require('constants/contactCenter/debitCardSubtypes');

const getRangeLimits = (upperLimit) => {
    const limitAvailableRanges = [
        150, 200, 300, 400, 500,
        600, 700, 800, 900, 1000,
        1100, 1200, 1500, 1800,
        2000, 2400, 2500, 3000,
        3500, 4000, 4500, 5000,
        5500, 6000
    ];

    const indexOfMaximumElement = limitAvailableRanges.indexOf(upperLimit);
    return limitAvailableRanges.slice(0, indexOfMaximumElement + 1);
};

const getCardLimits = (cardType) => {

    const minimumAmount = 150;
    let middleAmount;
    let maximumAmount = 1200;
    let arrayLimitRanges = getRangeLimits(maximumAmount);

    switch (cardType) {
        case OPEN_DEBIT_SUBTYPE:
        case DEBIT_4B_MC_MUNDO_123_SUBTYPE:
        case DEBIT_AT_HOME_ABROAD_SUBTYPE:
        case MINICARD_SUBTYPE:
        case DEBIT_SOLIDARIA_SUBTYPE:
        case DEBIT_GERMANY_SUBTYPE:
            maximumAmount = 3000;
            middleAmount = 1200;
            arrayLimitRanges = getRangeLimits(maximumAmount);
            break;
        case DEBIT_DIAMOND_SUBTYPE:
            maximumAmount = 6000;
            arrayLimitRanges = getRangeLimits(maximumAmount);
            break;
        case DEBIT_PREMIUM_SUBTYPE:
            maximumAmount = 3000;
            arrayLimitRanges = getRangeLimits(maximumAmount);
            break;
        default:
            break;
    }
    return({
        minimumAmount,
        middleAmount,
        maximumAmount,
        arrayLimitRanges
    });
};

module.exports = {
    getCardLimits
};
