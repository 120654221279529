const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        byId: {},
        byOrder: [],
        isFetching: false,
        fetchSuccess: false,
        error: false
    });
}

function processPulses(state, data) {
    let byId = {};
    let byOrder = [];

    data.forEach( pulse => {
        const id = pulse.story_id;

        byId[id] = {
            id,
            title: pulse.titulo,
            subtitle: pulse.subtitulo,
            summary: pulse.sumario,
            body: pulse.cuerpo,
            inlet: pulse.entradilla,
            date: pulse.date
        }

        byOrder.push(id);
    });

    return state.merge({
        isFetching: false,
        fetchSuccess: true,
        error: false,
        byId,
        byOrder
    });
}

function brokerMarketsPulses(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_BROKER_MARKET_PULSES_REQUEST: 
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_BROKER_MARKET_PULSES_FAILURE: 
            return state.merge({
                isFetching: false,
                fetchSuccess: false,
                error: action.payload.error
            });
        case actionTypes.FETCH_BROKER_MARKET_PULSES_SUCCESS:
            return processPulses(state, action.payload.data); 
        default:
            return state;
    }
}
module.exports = brokerMarketsPulses;
