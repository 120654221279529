const brokerPensionPlanExtraordinaryTags = () => ({
    otpInit: {
        categoriaEvento: "proc-otp",
        accionEvento: "inicio",
        etiquetaEvento:"alta de aportaciones extraordinarias",
    },
    otpSuccess: {
        categoriaEvento:"proc-otp",
        accionEvento:"exito",
        etiquetaEvento:"alta de aportaciones extraordinarias",
    }
});

module.exports = { brokerPensionPlanExtraordinaryTags }