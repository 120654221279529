// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes =  require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        account: '',
        error: ''
    });
}

function validateStep(step) {
    const account = step.get('account');
    const valid = !!account;
    let error = '';
    if(!valid){
        error = 'error';
    }

    return step.merge({
        valid,
        error
    });
}

function authorizeBizumStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BIZUM_REGISTER__SET_ACCOUNT:
            return state.merge({
                account: action.payload.account,
                error: ''
            });
        case actionTypes.BIZUM_REGISTER__VALIDATE_UI_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = authorizeBizumStep1;
