// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    errorDic: '',
    isFetching: false,
    success: false,
    isFetchingDic: false,
    successDic: false,
    precontractualAccountAlias: '',
    contractualAccountAlias: '',
});

function termsAndCondsLegalWindowReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.TERMS_AND_CONDS_LEGAL_WINDOW_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
                success: false,
            });
        case actionTypes.TERMS_AND_CONDS_LEGAL_WINDOW_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
            });
        case actionTypes.TERMS_AND_CONDS_LEGAL_WINDOW_FAILURE:
            return state.merge({
                error: true,
                isFetching: false,
            });
        case actionTypes.TERMS_AND_CONDS_LEGAL_DIC_REQUEST:
            return state.merge({
                error: '',
                isFetchingDic: true,
                successDic: false,
            });
        case actionTypes.TERMS_AND_CONDS_LEGAL_DIC_SUCCESS:
            return state.merge({
                isFetchingDic: false,
                successDic: true,
            });
        case actionTypes.TERMS_AND_CONDS_LEGAL_DIC_FAILURE:
            return state.merge({
                errorDic: true,
                isFetchingDic: false,
            });
        case actionTypes.TERMS_AND_CONDS_LEGAL_WINDOW_RESET:
            return initialState;
        case actionTypes.TERMS_AND_CONDS_LEGAL_WINDOW_SET_PARAMS:
            return state.merge({
                precontractualAccountAlias: action.payload.precontractualAccountAlias,
                contractualAccountAlias: action.payload.contractualAccountAlias,
            })
        default:
            return state;
    }
}


module.exports = termsAndCondsLegalWindowReducer;
