const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { isSepa } = require('utilities/transfersHelper');

const {
    BENEFICIARY_BANK_OPTION_BIC,
    BENEFICIARY_BANK_OPTION_NAME,
    EURO_TEXT
} = require('constants/index');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');


function setInitialState(country=DEFAULT_ORIGIN_COUNTRY) {
    return Immutable.fromJS({
        beneficiaryId: '', //this is needed in validation edit benefeciary
        sepaTypeBeforeEdit: '',
        isEditing: false, //flag to determinate which api use on post
        isLoadingFromTransfer: false,
        valid: false,
        validationRan: false,
        currency: EURO_TEXT,
        name: '',
        nameIsDirty: false,
        address: '', //optional field
        city: '', //optional field
        originalAlias: '', // use in edit flow to skip alias repeated error
        alias: '',
        aliasIsDirty: false,
        aliasIsValid: false,
        country,
        originCountry: DEFAULT_ORIGIN_COUNTRY,
        iban: '',
        ibanIsDirty: false,
        ibanIsValid: false,
        accountNumber: '',
        accountNumberIsValid: false,
        accountNumberIsDirty: false,
        bankOption: BENEFICIARY_BANK_OPTION_BIC,
        bic: '',
        bicIsDirty: false,
        bicIsValid: false,
        bankName: '',
        bankNameIsDirty: false,
        bankAddress: '',
        bankCity: ''
    });
}

function validateNewBeneficiary(state) {
    let valid = !!state.get('name') && state.get('aliasIsValid');

    if (valid) {
        const insideSepa = isSepa(state.get('country'), state.get('currency'));
        if (insideSepa) {
            valid = state.get('ibanIsValid');
        } else {
            valid = state.get('accountNumberIsValid');
            if (valid) {
                const bankOption = state.get('bankOption');
                if (bankOption === BENEFICIARY_BANK_OPTION_BIC) {
                    valid = state.get('bicIsValid');
                } else {
                    valid = !!state.get('bankName');
                }
            }
        }
    }

    return valid;
}

function validateStep(state) {
    const valid = validateNewBeneficiary(state);

    return state.mergeDeep({
        valid,
        validationRan: true,
        nameIsDirty: true,
        aliasIsDirty: true,
        ibanIsDirty: true,
        accountNumberIsDirty: true,
        bicIsDirty: true,
        bankNameIsDirty: true
    });
}

function addBeneficiaryStep1(state = setInitialState(), action) {
    let newState,
        insideSepa,
        nextInsideSepa;
    switch (action.type) {
        case actionTypes.ADD_BENEFICIARY_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.ADD_BENEFICIARY_NAME:
            return state.mergeDeep({
                name: action.payload.name,
                nameIsDirty: true
            });
        case actionTypes.ADD_BENEFICIARY_ADDRESS:
            return state.set('address', action.payload.address);
        case actionTypes.ADD_BENEFICIARY_CITY:
            return state.set('city', action.payload.city);
        case actionTypes.ADD_BENEFICIARY_ALIAS:
            const originalAlias = state.get('originalAlias');
            const alias = action.payload.alias;
            return state.mergeDeep({
                alias,
                aliasIsDirty: true,
                aliasIsValid: originalAlias === alias ? true : action.payload.isValid
            });
        case actionTypes.ADD_BENEFICIARY_COUNTRY:
            const currency = state.get('currency');
            insideSepa = isSepa(state.get('country'), currency);
            nextInsideSepa = isSepa(action.payload.country, currency);

            newState = state.set('country', action.payload.country);

            if (insideSepa !== nextInsideSepa) {
                newState = newState.mergeDeep({
                    iban: '',
                    ibanIsDirty: false,
                    ibanIsValid: false,
                    bankOption: BENEFICIARY_BANK_OPTION_BIC,
                    accountNumber: '',
                    accountNumberIsValid: false,
                    accountNumberIsDirty: false,
                    bic: '',
                    bicIsDirty: false,
                    bicIsValid: false,
                    bankName: '',
                    bankNameIsDirty: false,
                    bankAddress: '',
                    bankCity: ''
                });
            }
            return newState;
        case actionTypes.ADD_BENEFICIARY_BIC:
            return state.mergeDeep({
                bic: action.payload.bic,
                bicIsDirty: true,
                bicIsValid: action.payload.isValid
            });
        case actionTypes.ADD_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE:
            return state.mergeDeep({
                iban: '',
                ibanIsDirty: action.ibanIsDirty,
                ibanIsValid: false
            });
        case actionTypes.ADD_BENEFICIARY_IBAN:
            return state.mergeDeep({
                iban: action.payload.iban,
                ibanIsDirty: action.payload.ibanIsDirty,
                ibanIsValid: action.payload.ibanIsValid
            });
        case actionTypes.ADD_BENEFICIARY_ACCOUNT_NUMBER:
            return state.mergeDeep({
                accountNumber: action.payload.accountNumber,
                accountNumberIsValid: action.payload.isValid,
                accountNumberIsDirty: true
            });
        case actionTypes.ADD_BENEFICIARY_CURRENCY:
            const country = state.get('country');
            insideSepa = isSepa(country, state.get('currency'));
            nextInsideSepa = isSepa(country, action.payload.currency);

            newState = state.set('currency', action.payload.currency || EURO_TEXT);

            if (insideSepa !== nextInsideSepa) {
                newState = newState.mergeDeep({
                    iban: '',
                    ibanIsDirty: false,
                    ibanIsValid: false,
                    bankOption: BENEFICIARY_BANK_OPTION_BIC,
                    accountNumber: '',
                    accountNumberIsValid: false,
                    accountNumberIsDirty: false,
                    bic: '',
                    bicIsDirty: false,
                    bicIsValid: false,
                    bankName: '',
                    bankNameIsDirty: false,
                    bankAddress: '',
                    bankCity: ''
                });
            }
            return newState;
        case actionTypes.ADD_BENEFICIARY_BANK_OPTION:
            return state.mergeDeep({
                bankOption: action.payload.option,
                bic: '',
                bicIsDirty: false,
                bicIsValid: false,
                bankName: '',
                bankNameIsDirty: false,
                bankAddress: '',
                bankCity: ''
            });
        case actionTypes.ADD_BENEFICIARY_BANK_NAME:
            return state.mergeDeep({
                bankName: action.payload.name,
                bankNameIsDirty: true
            });
        case actionTypes.ADD_BENEFICIARY_BANK_ADDRESS:
            return state.set('bankAddress', action.payload.address);
        case actionTypes.ADD_BENEFICIARY_BANK_CITY:
            return state.set('bankCity', action.payload.city);
        case actionTypes.ADD_BENEFICIARY_FILL_FORM:
            return state.mergeDeep({
                isEditing: true,
                beneficiaryId: action.payload.beneficiaryId,
                name: action.payload.name,
                originalAlias: action.payload.alias,
                alias: action.payload.alias,
                aliasIsValid: true,
                currency: action.payload.currency,
                country: action.payload.country,
                iban: action.payload.iban || '',
                ibanIsValid: !!action.payload.iban,
                accountNumber: action.payload.accountNumber || '',
                accountNumberIsValid: !!action.payload.accountNumber,
                bic: action.payload.bic || '',
                bicIsValid: !!action.payload.bic,
                address: action.payload.address || '',
                city: action.payload.city || '',
                bankOption: action.payload.bic
                    ? BENEFICIARY_BANK_OPTION_BIC
                    : BENEFICIARY_BANK_OPTION_NAME,
                bankName: action.payload.bankName || '',
                bankAddress: action.payload.bankAddress || '',
                bankCity: action.payload.bankCity || '',
                sepaTypeBeforeEdit: action.payload.sepaTypeBeforeEdit
            });
        case actionTypes.ADD_BENEFICIARY_FILL_FORM_FROM_TRANSFER:
            return state.mergeDeep({
                isLoadingFromTransfer: true,
                beneficiaryId: action.payload.beneficiaryId,
                name: action.payload.name,
                alias: action.payload.alias,
                aliasIsValid: true,
                currency: action.payload.currency,
                country: action.payload.country,
                iban: action.payload.iban || '',
                ibanIsValid: !!action.payload.iban,
                accountNumber: action.payload.accountNumber || '',
                accountNumberIsValid: !!action.payload.accountNumber,
                bic: action.payload.bic || '',
                bicIsValid: !!action.payload.bic,
                address: action.payload.address || '',
                city: action.payload.city || '',
                bankOption: action.payload.bic
                    ? BENEFICIARY_BANK_OPTION_BIC
                    : BENEFICIARY_BANK_OPTION_NAME,
                bankName: action.payload.bankName || '',
                bankAddress: action.payload.bankAddress || '',
                bankCity: action.payload.bankCity || '',
                sepaTypeBeforeEdit: action.payload.sepaTypeBeforeEdit
            });
        case actionTypes.ADD_BENEFICIARY_RESET_LOAD_FROM_TRANSFER:
            return state.set('isLoadingFromTransfer', false);
        case actionTypes.FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY:
            return state.mergeDeep({
                country: action.originCountry.toUpperCase(),
                originCountry: action.originCountry.toUpperCase()
            });
        default:
            return state;
    }
}

module.exports = addBeneficiaryStep1;
