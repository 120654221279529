require('./depositThirteenMonthsLinkedMaturities.scss');

const step1 = require('./step1');
const step2 = require('./step2');
const successStep = require('./successStep');
const customErrors = require('./customErrors');
const {
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_TYPE,
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_SUBTYPE,
    DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_STANDARD,
} = require('constants/contactCenter/hireDeposits');
const {
    EURO_SYMBOL,
} = require('constants');

const { OPERATIVA_DEPOSITOS_CONTRATAR_DEPOSITO_13_MESES } = require('constants/contactCenter/optionIdTracker');

const {
    DEPOSIT_THIRTEEN_MONTHS,
    messages: {DEPOSIT_THIRTEEN_MONTHS_AND_GDPR}
} = require('constants/operationTypes');

const { DEPOSIT_THIRTEEN_MONTS_GDPR_PID } = require('constants/gdprPlacementIds');
const { DEPOSITS_INTEREST_BAND_COUNTRIES } = require('constants/flagBasedFeatures');

const contract = {
    optionId: OPERATIVA_DEPOSITOS_CONTRATAR_DEPOSITO_13_MESES,
    customErrorBuilder: 'depositThirteenMonthsLinkedMaturities_customErrorBuilder', //esta?
    title: 'contractsView-depositThirteenMonthsLinkedMaturitiesContractTitle',
    service: '....',
    last_step: 3,
    showIntervenerControls: true,
    SignParamsFn: 'deposit_thirteen_months_linked_maturities_loan_SignParams',
    gdprPlacementId: DEPOSIT_THIRTEEN_MONTS_GDPR_PID,
    multiOTPMessageCode: DEPOSIT_THIRTEEN_MONTHS_AND_GDPR,
    define_steps: 2,
    successStepType: 'dynamic',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractSummary-backToDeposits',
    errorBackButtonLink: '/myprofile/deposits',
    onCancelURL: '/myprofile/deposits',
    hireParamsFn: 'depositThirteenMonthsLinkedMaturities_hireParamsFn',
    hasPrecontractualLink: true,
    type: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_TYPE,
    subtype: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_SUBTYPE,
    standard: DEPOSIT_13_MONTHS_LINKED_MATURITIES_PRODUCT_STANDARD,
    productName: 'contractsView-depositThirteenMonthsLinkedMaturitiesProductName',
    warningContractMessage: 'contractsView-depositThirteenMonthsLinkedMaturitiesWarningMessage',
    operativeName: 'operatives-depositHire_openThirteenMonthsLinkedMaturities',
    code_op: DEPOSIT_THIRTEEN_MONTHS,
    breadcrumb: [
        {
            text: 'breadcrumb-deposits',
            url: '/myprofile/deposits'
        },
        {
            text: 'contractsView-newDepositBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            {
                id: 'monto_traspasar',
                label: 'contractsView-depositThirteenMonthsAmountToDepositLabel',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 1,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ]
            },
            {
                id: 'gross_interest',
                label: 'contractsView-grossInterest',
                value: null,
                type: 'single',
                featureFlags: [DEPOSITS_INTEREST_BAND_COUNTRIES],
                format_type: EURO_SYMBOL,
                formatNumber: true,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ]
            },
            {
                id: 'tin',
                label: 'contractsView-tin',
                value: 'contractSummary-depositThirteenMonthsLinkedMaturitiesRetentionTIN',
                type: 'single',
                formatNumber: false,
                isLanguageString: true,
                order: 2,
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ]
            },
            {
                id: 'tae',
                label: 'contractsView-tae',
                value: 'contractSummary-depositThirteenMonthsLinkedMaturitiesRetentionTAE',
                type: 'single',
                order: 3,
                formatNumber: false,
                isLanguageString: true,
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            {
                type: 'separator',
                order: 4,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ]
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'balance_account',
                label: 'contractsView-accountBalanceAssociated',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 5,
                visible: [
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    }
                ]
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 2
                },
                order: 6,
                visible: [
                    {
                        step: 1,
                        order: 6
                    },
                    {
                        step: 2,
                        order: 6
                    },
                    {
                        step: 3,
                        order: 6
                    }
                ]
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 3,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'renewDeposit',
                label: '',
                value: true,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'validationModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'accountModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-depositThirteenMonthsServiceContractLabel1'
                        },
                        {
                            text: 'contractsView-depositThirteenMonthsServiceContractLabel2',
                            id: 'btn_ContractsView_precontractual',
                            href: 'contractsView-depositThirteenMonthsLinkedMaturitiesServiceContractLabel2URL'
                        },
                        {
                            text: 'contractsView-depositThirteenMonthsServiceContractLabel3'
                        },
                        {
                            text: 'contractsView-depositThirteenMonthsServiceContractLabel4',
                            id: 'btn_ContractsView_product',
                            href: 'contractsView-depositThirteenMonthsLinkedMaturitiesServiceContractLabel4URL'
                        },
                        {
                            text: 'contractsView-depositThirteenMonthsServiceContractLabel5'
                        }
                    ]
                }
            ]
        }
    },
    steps: [step1, step2],
    successStep,
    customErrors
};

module.exports = { contract };
