const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.List(
    [
        {
            "title": "Eventos para hoy",
            "date": "Claves bursátiles del jueves, 11 de agosto de 2016",
            "subtitle": "Claves bursátiles de la sesión",
            "description": "Este jueves de nuevo se presenta una jornada de escasas referencias macroeconómicas. En China se conocen los datos de los nuevos préstamos de julio, mientras que en Europa se publican los datos del IPC de julio tanto de Francia como de Italia, país este último en el que también se publica la balanza comercial de junio. En EEUU, por su parte, salen a la luz las peticiones semanales de desempleo y el índice de precios de importación y exportación de julio.\nLa estadounidense Macy's presenta resultados este jueves, mientras que en el Viejo Continente harán lo propio Deutsche Telekom, Thyssen Krupp y Rwe, entre otras."
        },
        {
            "title": "Último cierre E.E.U.U.",
            "date": "",
            "subtitle": "Cierre Mercado Americano",
            "description": "Wall Street cierra con pérdidas moderadas (Dow Jones: -0,20%; SP 500: -0,29%; Nasdaq: -0,40%), en una semana de escaso movimiento para la renta variable americana. Así, el Nasdaq cierra con una caída del -0,40%, hasta los 5204.58, tras haber marcado este martes un nuevo máximo histórico en precios de cierre. El S&P 500 cierra con pérdidas del -0,29%, hasta los 2175,49, después de haber marcado un máximo histórico intradía en la jornada anterior por encima de los 2.187 puntos. En este sentido, los inversores están digiriendo la caída de los precios del petróleo, así como los resultados trimestrales presentados por las empresas.\nOTROS MERCADOS El precio del barril de petróleo Brent, de referencia en Europa, cierra la jornada con una caída del -2,56% y un precio de 43,84 dólares, mientras que el barril West, de referencia en Estados Unidos, ha caído un -3,09%, hasta los 41,43 dólares. La rentabilidad de los bonos del Tesoro USA a 10 años cae hasta el 1,512%. El euro sube un 0.0057%, hasta 1,1175 dólares.",
        }
    ]
);

function brokerTodayNewsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_TODAY_NEWS_LIST:
            return state;
        default:
            return state;
    }
}

module.exports = brokerTodayNewsReducer;
