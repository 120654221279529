const actionTypes = require('../constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isFetching: false,
    success: false,
    error: ''
});

function cardActivationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_CARD_ACTIVATION_INITIAL:
            return initialState;
        case actionTypes.SET_CARD_ACTIVATION_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.SET_CARD_ACTIVATION_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true
            });
        case actionTypes.SET_CARD_ACTIVATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.SET_CARD_ACTIVATION_RESET:
            return initialState;
        default:
            return state
    }
}

module.exports = cardActivationReducer;
