const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const moment = require('moment');

// @ utilities
const DateHelper = require('utilities/dateHelper');

// @ constants
const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_SECOND_STEP,
    EURO_TEXT,
    BROKER_CONTRIBUTION_PLAN_MAXIMUN_PERCENTAGE_VALUE,
    BROKER_CONTRIBUTION_PLAN_MINIMUM_PERCENTAGE_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_LABEL,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_VALUE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_CODE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_CODE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_CODE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_CODE,
    BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BLANK_CODE,
    MAX_CONTRIBUTION_PLANS,
} = require('constants/index');

function setInitialState() {
    const firstDay = moment().startOf('day');
    const limitDate = firstDay.clone().add(15, 'days');
    const excludeDates = [];
    const validityDate = firstDay.clone();

    return Immutable.Map().merge({
        steps: [
            {
                originalAmount: 0,
                setPeriodicity: false,
                isPercentageInputAvailable: false,
                periodiclyLabel: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_VALUE,
                periodiclyMonthlyValue: 0,
                periodicityMinimum: 0,
                periodiclyMonthlyOptions: [
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_LABEL,
                        frecuenciaCuotaCada: 1,
                        frecuenciaCuotaCadaUnidadDeTiempo: 2,
                        value: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_VALUE
                    },
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_LABEL,
                        frecuenciaCuotaCada: 3,
                        frecuenciaCuotaCadaUnidadDeTiempo: 2,
                        value: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_VALUE
                    },
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_LABEL,
                        frecuenciaCuotaCada: 6,
                        frecuenciaCuotaCadaUnidadDeTiempo: 2,
                        value: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_VALUE
                    },
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_LABEL,
                        frecuenciaCuotaCada: 1,
                        frecuenciaCuotaCadaUnidadDeTiempo: 3,
                        value: BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_VALUE
                    }
                ],
                periodiclyAmount: 0,
                valid: true,
                validityDate,
                limitDate,
                excludeDates,
                revAnual: -1,
                revAnualOptions: [
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_LABEL,
                        importeOPorcentajeRevalorización: '',
                        tipoRetVal: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_VALUE
                    },
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_LABEL,
                        importeOPorcentajeRevalorización: 100,
                        tipoRetVal: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_VALUE
                    },
                    {
                        label: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_LABEL,
                        importeOPorcentajeRevalorización: 0,
                        tipoRetVal: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_VALUE
                    }
                ],
                errors: {
                    amountError: false,
                    selectedAccount: false,
                    periodiclyMonthlyError: false,
                    periodiclyAmountError: false,
                    revAnualError: false,
                    negativeBalanceError: false,
                    minimumBalanceError: false,
                    periodiclyPercentageError: false,
                    periodiclyPercentageEmpty: false,
                    contributionOverLimitsError: false,
                    periodiclyAmountOverLimitError: false,
                }
            },
            {
                hasSignaturePattern: true,
                disclaimerChecked: __CONTACT_CENTER__,
                disclaimerInvalid: false,
                valid: false,
                allDocsRead: false,
                showWarning: false
            }
        ],
        contractNumber: '',
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: '',
        token: null,
        pensionPlan: null,
        extraordinaryData: null,
        periodicData: null,
        validationData: null,
        pensionPlanFromWallet: null,
        isEditMode: false,
        contributionDataEdit: null,
        detailsDataEdit: null
    });
}

function validate(state, contributionMinimumAmount, contributionMinimumAmountCurrency , accountBalance) {
    const step1 = state.getIn(['steps',FIRST_STEP]);
    const revAnual = step1.get('revAnual');
    const amount = contributionMinimumAmount;
    const amountPeriodic = contributionMinimumAmount;
    const isEuroContribution = contributionMinimumAmountCurrency === EURO_TEXT;
    const isOriginalAmountError = isEuroContribution && Number(step1.get('originalAmount')) < amount;
    const isPeriodiclyAmountError = isEuroContribution && Number(step1.get('periodiclyAmount')) < amountPeriodic;
    const isRevAnualError = revAnual === -1;
    const isPercentageInputAbailable = step1.get('isPercentageInputAvailable');
    const isPeriodiclyPercentageEmpty = isPercentageInputAbailable && revAnual.importeOPorcentajeRevalorización === '';
    const isPeriodiclyPercentageError = isPercentageInputAbailable &&
                                        (( revAnual.importeOPorcentajeRevalorización > BROKER_CONTRIBUTION_PLAN_MAXIMUN_PERCENTAGE_VALUE) ||
                                        ( revAnual.importeOPorcentajeRevalorización < BROKER_CONTRIBUTION_PLAN_MINIMUM_PERCENTAGE_VALUE));
    const isContributionOverLimitsError = Number(step1.get('originalAmount')) > MAX_CONTRIBUTION_PLANS;
    const isPeriodiclyAmountOverLimitError = Number(step1.get('periodiclyAmount')) > MAX_CONTRIBUTION_PLANS;

    let isStepValid, visibleStep;
    let result;

    if (state.get('visibleStep') === 1) {
        const accountBalanceAmount = accountBalance.amount
        const negativeBalanceError = accountBalanceAmount <= 0;
        const minimumBalanceError = contributionMinimumAmountCurrency === accountBalance.currency ?
                                    accountBalanceAmount < contributionMinimumAmount || accountBalanceAmount < step1.get('originalAmount') : true
        if (step1.get('setPeriodicity')) {
            isStepValid =
                !isPeriodiclyAmountError &&
                !isRevAnualError &&
                !negativeBalanceError &&
                !isPeriodiclyPercentageError &&
                !isPeriodiclyPercentageEmpty &&
                !isPeriodiclyAmountOverLimitError

            visibleStep = (isStepValid ? 2 : 1);
            result = state.merge({visibleStep: visibleStep})
                    .mergeIn(['steps',FIRST_STEP,'valid'],isStepValid)
                    .mergeIn(['steps', FIRST_STEP,'errors','amountError'], false)
                    .mergeIn(['steps', FIRST_STEP,'errors','periodiclyAmountError'], isPeriodiclyAmountError)
                    .mergeIn(['steps',FIRST_STEP,'errors','revAnualError'], isRevAnualError)
                    .mergeIn(['steps',FIRST_STEP,'errors','periodiclyPercentageError'], isPeriodiclyPercentageError)
                    .mergeIn(['steps',FIRST_STEP,'errors','periodiclyPercentageEmpty'], isPeriodiclyPercentageEmpty)
                    .mergeIn(['steps',FIRST_STEP,'errors','periodiclyAmountOverLimitError'], isPeriodiclyAmountOverLimitError);
        } else {
            isStepValid =
                !isOriginalAmountError &&
                !negativeBalanceError &&
                !minimumBalanceError&&
                !isContributionOverLimitsError;

            visibleStep = (isStepValid ? 2 : 1);

            result = state.merge({visibleStep: visibleStep})
                    .mergeIn(['steps',FIRST_STEP,'valid'], isStepValid)
                    .mergeIn(['steps', FIRST_STEP,'errors','amountError'], isOriginalAmountError)
                    .mergeIn(['steps', FIRST_STEP,'errors','periodiclyAmountError'], false)
                    .mergeIn(['steps', FIRST_STEP,'errors','negativeBalanceError'], negativeBalanceError)
                    .mergeIn(['steps', FIRST_STEP,'errors','minimumBalanceError'], minimumBalanceError)
                    .mergeIn(['steps',FIRST_STEP,'errors','revAnualError'], false)
                    .mergeIn(['steps',FIRST_STEP,'errors','periodiclyPercentageError'], false)
                    .mergeIn(['steps',FIRST_STEP,'errors','periodiclyPercentageEmpty'], false)
                    .mergeIn(['steps',FIRST_STEP,'errors','contributionOverLimitsError'], isContributionOverLimitsError);
        }
    }

    if (state.get('visibleStep') === WIZARD_SECOND_STEP) {
        const disclaimerChecked = state.getIn(['steps', SECOND_STEP, 'disclaimerChecked']);

        return state.mergeIn(['steps', SECOND_STEP], {
            disclaimerInvalid: !disclaimerChecked,
            valid: disclaimerChecked
        });
    } else {
        return result;
    }
}

function getRevAnual(step1, contributionDetails) {
    const revAnual = contributionDetails.revalorizacion;

    let revAnualValue = -1,
        percentageInputAvailable = false;

    if (revAnual === BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_VALUE) {
        revAnualValue = {
            label: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_LABEL,
            importeOPorcentajeRevalorización: contributionDetails.datoRev,
            tipoRetVal: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_PERCENTAGE_VALUE,
            value: 0
        };
        percentageInputAvailable = true;
    } else if (revAnual === BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_VALUE) {
        revAnualValue = {
            label: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_LABEL,
            importeOPorcentajeRevalorización: 100,
            tipoRetVal: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_IPC_VALUE,
            value: 1
        };
    } else {
        revAnualValue = {
            label: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_LABEL,
            importeOPorcentajeRevalorización: 0,
            tipoRetVal: BROKER_CONTRIBUTION_PLAN_PERIODIC_ANUAL_REV_OPTION_NOREV_VALUE,
            value: 2
        };
    }

    return {
        revAnual: revAnualValue,
        isPercentageInputAvailable: percentageInputAvailable
    };
}

function mergeContributionForEdit(state, contributionData, contributionDetails) {

    const step1 = state.getIn(['steps', FIRST_STEP]);

    let mergedStep1AnualRev = getRevAnual(step1,contributionDetails);

    let periodicMonthValue;
    const periodicly = contributionData.getIn(['planAportacion','periodicidad']);

    switch(periodicly){
        case BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_VALUE:
            periodicMonthValue = BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_MONTH_CODE;
            break;
        case BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_VALUE:
            periodicMonthValue = BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_TRIANUAL_CODE;
            break;
        case BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_VALUE:
            periodicMonthValue = BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BIANUAL_CODE;
            break;
        case BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_VALUE:
            periodicMonthValue = BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_ANUAL_CODE;
            break;
        default:
            periodicMonthValue = BROKER_CONTRIBUTION_PLAN_PERIODIC_MONTHLY_OPTION_BLANK_CODE;
            break;
    }

    let mergedStep = step1.merge({
            setPeriodicity: true,
            isFetching: true,
            periodiclyLabel: periodicly,
            periodiclyMonthlyValue: periodicMonthValue,
            periodiclyAmount: contributionData.getIn(['planAportacion','importe']),
            validityDate: moment(new Date([contributionData.getIn(['planAportacion','fechaAlta']).split("-")])),
            isPercentageInputAvailable: mergedStep1AnualRev.isPercentageInputAvailable
    });

    return state.mergeIn(['steps', FIRST_STEP], mergedStep)
                .merge({
                    isEditMode: true,
                    isFetching: false,
                    inProgress: true,
                    contributionDataEdit: contributionData,
                    detailsDataEdit: contributionDetails
                })
                .setIn(['steps', FIRST_STEP, 'revAnual'],  mergedStep1AnualRev.revAnual);
}

const toggleManifestCheckbox = (immState) => {
    if (immState.getIn(['steps', SECOND_STEP, 'allDocsRead'])) {
        const disclaimerChecked = immState.getIn(['steps', SECOND_STEP, 'disclaimerChecked']);
        return immState.setIn(['steps', SECOND_STEP, 'disclaimerChecked'], !disclaimerChecked)
            .setIn(['steps', SECOND_STEP, 'showWarning'], false)
            .setIn(['steps', SECOND_STEP, 'valid'], true)
    }
    else {
        return immState.setIn(['steps', SECOND_STEP, 'showWarning'], true);
    }
};

const setDocumentsRead = (state) => {
    return state.setIn(['steps', SECOND_STEP, 'allDocsRead'], true);
}

function brokerPensionPlanContributionReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_PENSION_PLAN_SET_CONTRACT_NUMBER:
            return state.set('contractNumber', action.payload.contractNumber);
        case actionTypes.BROKER_PENSION_PLAN_TOGGLE_DISCLAIMER:
            return toggleManifestCheckbox(state);
        case actionTypes.BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_DOCUMENTS_READ:
            return setDocumentsRead(state);
        case actionTypes.BROKER_STOCKS_BUY_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_VALIDATE_STEP:
            const { contributionMinimumAmount, contributionMinimumAmountCurrency, accountBalance } = action.payload;
            return validate(state, contributionMinimumAmount, contributionMinimumAmountCurrency , accountBalance);
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_RESET:
            return setInitialState();
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_REQUEST:
            return state.merge({ isFetching: true });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_ERROR:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_RESET_FLAG_ERROR_REVANUAL:
            return state.mergeIn(['steps',FIRST_STEP,'errors','periodiclyPercentageError'], false)
                        .mergeIn(['steps',FIRST_STEP,'errors','periodiclyPercentageEmpty'], false);
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_SET_REVANUAL_PERCENTAGE_AVAILABLE:
            return state.mergeIn(['steps', FIRST_STEP, 'isPercentageInputAvailable'], action.payload.available);
        case actionTypes.BROKER_PENSION_PLAN_PERCENTAGE_PERIODICLY_VALUE:
            const revAnual =  state.getIn(['steps', FIRST_STEP, 'revAnual']);
            revAnual.importeOPorcentajeRevalorización = action.payload.value;
            return state.setIn(['steps', FIRST_STEP, 'revAnual'], revAnual);
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_SET_PERIODICITY:
            return state.mergeIn(['steps', FIRST_STEP, 'setPeriodicity'], action.payload.available);
        case actionTypes.FETCH_BROKER_PENSION_PLAN_SUCCESS:
            const pensionPlan = action.payload.pensionPlans.rows[0];
            return state.merge({
                pensionPlan: pensionPlan
            });
        case actionTypes.BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_SUCCESS:
            const data = action.payload.response;
            return state.merge({
                extraordinaryData: {
                    ctaAsociadaNumero: data.ctaAsociada.numerodecuentavar,
                    numerodecuentaDigitoControl: data.ctaAsociada.digitodecontrol,
                    codigoIdioma: data.codigoIdioma,
                    descriPlan: data.descriPlan,
                    nomTItular: data.nomTItular,
                    valorLiqDivisa: data.valorLiq.divisa,
                    valorLiqMonto: data.valorLiq.valorliquidativo,
                    fecValorLiquid: data.fecValorLiquid,
                    swIndOpConf: data.swIndOpConf,
                    indAccion3: data.indAccion3,
                    tipoEmpresa: data.tipoEmpresa,
                    impSaldo: data.impSaldo,
                    fecValorBanc: data.fecValorBanc,
                    fecUltimValLiq: data.fecUltimValLiq,
                    codOpera: data.codOpera,
                    codSubtipoProd: data.codSubtipoProd,
                    divisaDelMovimiento: data.divisaDelMovimiento
                },
                inProgress: true,
                isFetching: false
            });
        case actionTypes.BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_ERROR:
            return state.merge({
                error: action.payload.error,
                inProgress: true,
                isFetching: false,
                visibleStep: 1
            });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_SET_CONTRACT:
            return state.merge({
                pensionPlanFromWallet: action.payload.walletPlan
            });
        case actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_SET_ORIGINAL_AMOUNT:
            return state.mergeIn(['steps', FIRST_STEP, 'originalAmount'], action.payload.amount);
        case actionTypes.BROKER_PENSION_PLAN_PERIODICLY_MONTH_VALUE:
            return state
                .mergeIn(['steps', FIRST_STEP, 'periodiclyMonthlyValue'],action.payload.value)
                .mergeIn(['steps', FIRST_STEP, 'periodiclyLabel'],action.payload.label)
                .mergeIn(['steps', FIRST_STEP, 'periodicityMinimum'],action.payload.periodicityMinimum);
        case actionTypes.BROKER_PENSION_PLAN_REV_ANUAL_VALUE:
            return state.mergeIn(['steps', FIRST_STEP, 'revAnual'], action.payload.value);
        case actionTypes.BROKER_PENSION_PLAN_PERIODICLY_AMOUNT_VALUE:
            return state.mergeIn(['steps', FIRST_STEP, 'periodiclyAmount'], action.payload.value);
        case actionTypes.BROKER_PENSION_PLAN_VALIDITY_DATE_VALUE:
            return state.mergeIn(['steps', FIRST_STEP, 'validityDate'], action.payload.date);
        case actionTypes.BROKER_PENSION_PLAN_PERIODIC_CONTRIBUTION_SUCCESS:
            const periodicData = action.payload.response;
            const planData = periodicData.listaPlanAportaciones && periodicData.listaPlanAportaciones.planAportacion[0]
                ? periodicData.listaPlanAportaciones.planAportacion[0]
                : {};
            return state.merge({
                periodicData: {
                    codmonpl: planData.divisaImporteAportPlan,
                    estatusPlanAport: planData.estatusPlanAport,
                    switcho: periodicData.indPorMinReval,
                    indRevalOblig: periodicData.indRevalOblig,
                    swopcc: periodicData.indRevalOblig,
                    idsubtipo: periodicData.subtipoPlanPensiones,
                    codigodepersona: periodicData.codPersonaTitContrPrin.codigodepersona,
                    tipodepersona: periodicData.codPersonaTitContrPrin.tipodepersona,
                    tipoPlan: periodicData.tipoPlan
                },
                inProgress: true,
                isFetching: false
            });
        case actionTypes.BROKER_PENSION_PLAN_PERIODIC_CONTRIBUTION_ERROR:
            return state.merge({
                error: action.payload.error,
                inProgress: true,
                isFetching: false,
                visibleStep: 1
            });
        case actionTypes.BROKER_PENSION_PLAN_VALIDATION_SUCCESS:
            const validationData = action.payload.response;
            return state.merge({
                validationData: {
                    codbban: validationData.cuentaAsociada.codbban,
                    digitodecontrol: validationData.cuentaAsociada.digitodecontrol,
                    pais: validationData.cuentaAsociada.pais,
                    codIdioma: validationData.codIdioma,
                    codmonProd: validationData.codmonProd,
                    fecRevalorizacion: validationData.fecRevalorizacion,
                    fechaIni: validationData.fechaInicio,
                    fechaMod: validationData.fechaMod,
                    frecRevalorizaUnidadTiempo: validationData.frecRevalorizaUnidadTiempo,
                    frecuenciaRevalorizaCada: validationData.frecuenciaRevalorizaCada,
                    indCuentaDistintoCentro: validationData.indCuentaDistintoCentro,
                    indCuentaMismaDelPlan: validationData.indCuentaMismaDelPlan,
                    indDomiciBancaria: validationData.indDomiciBancaria,
                    indParticipenoTitularCta: validationData.indParticipenoTitularCta,
                    numeroDeParticipe: validationData.numeroDeParticipe
                },
                inProgress: true,
                isFetching: false
            });
        case actionTypes.BROKER_PENSION_PLAN_VALIDATION_EDIT_SUCCESS:
            const validationDataEdit = action.payload.response;
            return state.merge({
                validationData: {
                    codbban: validationDataEdit.cuentaAsoc.codbban,
                    digitodecontrol: validationDataEdit.cuentaAsoc.digitodecontrol,
                    pais: validationDataEdit.cuentaAsoc.pais,
                    codIdioma: validationDataEdit.codIdio,
                    codmonProd: validationDataEdit.codMonProducto,
                    fecRevalorizacion: validationDataEdit.fechaIniPriReval,
                    fechaIni: validationDataEdit.fechaIniPlanAport,
                    frecRevalorizaUnidadTiempo: validationDataEdit.frecuenciaRevalUnidad,
                    frecuenciaRevalorizaCada: validationDataEdit.frecuenciaRevalorizaCada,
                    indCuentaDistintoCentro: validationDataEdit.indCuentaDistintoCentro,
                    indCuentaMismaDelPlan: validationDataEdit.indCuentaMismaDelPlan,
                    indParticipenoTitularCta: validationDataEdit.indCuentaParticipeNoTitu,
                    fecProxReval: validationDataEdit.fecProxRevalo,
                    indImporteParaCuota: validationDataEdit.indImporteParaCuota,
                    indTipoMinus: validationDataEdit.indTipoMinus,
                    tipoPlan: validationDataEdit.tipoPlan
                },
                inProgress: true,
                isFetching: false
            });
        case actionTypes.BROKER_PENSION_PLAN_VALIDATION_ERROR:
            return state.merge({
                error: action.payload.error,
                inProgress: true,
                isFetching: false,
                visibleStep: 1
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST_EDIT:
            return state.merge({
                isFetching: true
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR_EDIT:
            return state.merge({
                inProgress: true,
                isFetching: false,
                visibleStep: 1
            });
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS_EDIT:
            return mergeContributionForEdit(state, action.payload.contributionData, action.payload.detailsData);
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS:
            const isBeforeClosingTime = action.payload.data.isBeforeClosingTime;
            const timestamp = action.payload.data.timeResponse ? action.payload.data.timeResponse.timestamp : null;
            const minDate = DateHelper.getMinDateAvailable(isBeforeClosingTime, timestamp);
            return state.mergeIn(['steps', FIRST_STEP, 'validityDate'], minDate);
        case actionTypes.BROKER_PENSION_PLAN_SEND_DOCUMENTATION_SUCCESS:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.BROKER_PENSION_PLAN_SEND_DOCUMENTATION_FAILURE:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        default:
            return state;
    }
}

module.exports = brokerPensionPlanContributionReducer;
