module.exports = {
    title: 'Selección de intervinientes',
    order: 3,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'combo',
            label: 'Titular(es)',
            type: 'single',
            placeholder: 'Seleccione una opción ...',
            source: 'personalLoan_loadCmbHolder',
            onChangeFn: 'personalLoan_onChangeCmbPurpose',
            target: '',
            options: [],
            order: 3
        },
        {
            id_component: 'combo',
            label: 'Avalista (s)(opcional)',
            type: 'single',
            placeholder: 'Seleccione una opción ...',
            source: 'personalLoan_loadCmbGuarantor',
            onChangeFn: 'personalLoan_onChangeCmbPurpose',
            target: '',
            options: [],
            order: 3
        }
    ]
};
