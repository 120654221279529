const terms = [
  { name: 'Aandelen',
  definition: 'Gewone aandelen vertegenwoordigen een gedeelte van het eigendom van een onderneming of beleggingsfonds. Aandelen zijn risicovoller naar mate de invloed van marktkrachten op de onderneming groter is en er geen garantie bestaat over de waarde van de oorspronkelijke investering. Gewone aandelen geven de houder stemrecht op de Algemene Vergadering van Aandeelhouders en geven ook recht op dividend. Als een onderneming echter failliet gaat, komen aandeelhouders achter in de rij bij het verdelen van de opbrengsten uit de boedel.' }
,
{ name: 'Aandelenfonds',
  definition: 'Een aandelenfonds is een fonds dat hoofdzakelijk belegt in aandelen. De werkwijze van het fonds kan gebaseerd zijn op diverse strategieën.' }
,
{ name: 'Aankoopkosten',
  definition: 'De aankoopkosten zijn de kosten die men betaalt bij de aankoop van een fonds. Let op: bij Nederlandse beursgenoteerde beleggingsfondsen wordt een deel van de aankoopkosten verwerkt in de koers door een afwijking van de intrinsieke waarde.' }
,
{ name: 'Aanvang',
  definition: 'Het minimale beleggingsbedrag bij aanvang is het kleinste bedrag dat een belegger kan storten als hij wil deelnemen aan een fonds.' }
,
{ name: 'Accumulation',
  definition: 'Accumulation is de Engelse term voor een fonds dat geen dividend uitkeert, maar dit toevoegt aan het vermogen (een groeifonds). Het tegenovergestelde is een Income, ofwel een dividenduitkerend fonds.' }
,
{ name: 'Actief management',
  definition: 'Actief management betekent dat de fondsmanager vanuit zijn visie beslist welke aandelen te kopen om tot een beter resultaat te komen dan de index.' }
,
{ name: 'Additioneel',
  definition: 'Het minimale additionele beleggingsbedrag is de minimum vervolgstorting die een belegger kan doen in een fonds als hij reeds deelneemt in dat fonds.' }
,
{ name: 'ADR\'s',
  definition: 'American Depository Receipts (ADR\'s) zijn certificaten die worden aangehouden bij Amerikaanse banken. De certificaten vertegenwoordigen een aantal aandelen van een buitenlands bedrijf. Buitenlandse bedrijven die een notering in de VS willen, zijn verplicht om ADR\'s aan te bieden.' }
,
{ name: 'AEX-index',
  definition: 'De AEX-index is de bekendste index van de Amsterdamse beurs. De graadmeter bestaat uit de 25 meest verhandelde aandelen op de Amsterdam Exchanges.' }
,
{ name: 'Afwijking ten opzichte van de intrinsieke waarde',
  definition: 'Spread is de afwijking van de intrinsieke waarde van een beleggingsfonds. Fondsen worden door de fondsaanbieder vaak aangeboden tegen een prijs die enkele procenten boven de intrinsieke waarde ligt. Evenzo is de fondsaanbieder vaak bereid aandelen van een fonds weer in te kopen tegen een koers die onder de intrinsieke waarde ligt. Het verschil tussen koers en intrinsieke waarde noemen we spread. Voor fondsen is er een maximum spread gedefinieerd.' }
,
{ name: 'AIBOR',
  definition: 'AIBOR staat voor Amsterdam Inter Bank Offered Rate en is het tarief dat commerciële banken bij elkaar in rekening brengen. De EURIBOR heeft de AIBOR opgevolgd.' }
,
{ name: 'AIM',
  definition: 'AIM, de Alternative Investment Market, werd in Londen in juni 1995 opgericht voor kleine snelgroeiende ondernemingen, die zich normaal niet zouden kwalificeren voor een notering op de beurs. Eind 2000 waren er 524 ondernemingen genoteerd op de AIM. De AIM is het Britse equivalent van de Nieuwe Markt.' }
,
{ name: 'Alfa',
  definition: 'Alfa is de outperformance die een beleggingsfonds heeft behaald bovenop wat verwacht zou mogen worden volgens een bepaald beleggingsmodel. Als alfa berekend wordt aan de hand van het Capital Asset Pricing Model, geeft een positieve alfa aan dat een fonds het beter heeft gedaan dan op basis van de gegeven beta verwacht mocht worden. Evenzo geeft een negatieve alfa aan dat het fonds een underperformance heeft, gegeven de verwachtingen die behoren bij de beta van het fonds.' }
,
{ name: 'American Stock Exchange (AMEX)',
  definition: 'The American Stock Exchange (AMEX) is qua grootte de tweede beurs met een fysieke handelsvloer. De AMEX legt zich toe op aandelen van kleine en middelgrote ondernemingen en heeft een sterke positie in de handel van gewone aandelen, indexaandelen (ETF\'s) en aandelenderivaten.' }
,
{ name: 'AMX-index',
  definition: 'De AMX-index is het zusje van de AEX-index. De AMX-index bestaat uit de 25 meest verhandelde aandelen na de AEX-aandelen van de Amsterdamse beurs.' }
,
{ name: 'Appreciatie',
  definition: 'Een appreciatie is de waardestijgingvan de ene munt ten opzichte van een andere. Een beweging in een wisselkoers resulteert erin dat men met een bepaalde munt meer van een andere geldeenheid kan kopen, de eerste munt is dus geäpprecieerd. Als bijvoorbeeld vorige week een euro 90 dollarcent kostte, en deze week een euro  95 dollarcent waard is, is de waarde van de euro ten opzichte van de dollar geäpprecieerd. De term appreciatie is het tegenovergestelde van depreciatie, en wordt gebruikt bij veranderingen van flexibele wisselkoersen.' }
,
{ name: 'Asset categorieën',
  definition: 'Asset categorieën zijn brede beleggingscategorieën die verschillende niveaus van risico en rendement bieden zoals aandelen, obligaties en kas. Aandelen hebben het grootste potentieel als het gaat om rendement, maar dit gaat gepaard met een relatief hoog risico. Obligaties bieden meer zekerheid met een (ten opzichte van aandelen) gemiddeld wat lager rendement. Onroerend goed wordt vaak genoemd als asset categorie, maar wordt door sommigen ook gezien als een sector van de aandelenmarkt. Onroerend goed kan goede rendementen bieden, maar herbergt het gevaar van illiquiditeit. Kas kent  het laagste verwachte rendement, maar biedt veel zekerheid en liquiditeit.' }
,
{ name: 'Asset management',
  definition: 'Asset management of vermogensbeheer is de algemene term voor het beheren van een portefeuille van een groep assets, zoals aandelen, obligaties of kas.' }
,
{ name: 'Assetverdeling',
  definition: 'De assetverdeling of asset mix is de beslissing hoe het vermogen wordt gespreid over de verschillende financiële asset categorieën (aandelen, obligaties en kas) en tastbare asset categorieën (vastgoed, commodities, metalen en verzamelobjecten). De asset mix wordt doorgaans bepaald door de wens om een optimale risicorendementsverhouding te bereiken die past bij de horizon en doelen van de belegger.' }
,
{ name: 'AVA',
  definition: 'AVA staat voor Algemene Vergadering van Aandeelhouders. Dit is het forum waarin aandeelhouders hun stemrecht kunnen uitoefenen.' }
,
{ name: 'Bank of England',
  definition: 'Opgericht in 1694, houdt de Bank of England (BOE) toezicht op het bankwezen in het VK. Sinds juni 1997 is de Bank ook verantwoordelijk voor de Britse officiële rentetarieven, waarvan de repo rate de belangrijkste is. Daarnaast houdt de Bank de beleggingen van de overheid bij, drukt bankbiljetten en verzamelt en publiceert statistieken met betrekking tot het Britse financiële systeem.' }
,
{ name: 'Basispunt',
  definition: 'Een basispunt is een honderdste van een procent (0.01%). Basispunten worden gebruikt om veranderingen in rentes uit te drukken. Een stijging van de rente van 6.25% naar 6.75% betekent een stijging van 50 basispunten.' }
,
{ name: 'Bear market',
  definition: 'Een langdurig pessimistisch gestemde markt, gekenmerkt door dalende koersen.' }
,
{ name: 'Bedrijfsobligatie',
  definition: 'Een bedrijfsobligatie is een obligatie die uitgegeven wordt door een onderneming om de bedrijfsactiviteiten te financieren. De hoofdsom wordt terugbetaald wanneer de obligatie afloopt. Daarnaast keert een obligatie tijdens zijn looptijd regelmatig rente uit. Er bestaan ook diverse fondsen die beleggen in bedrijfsobligaties.' }
,
{ name: 'Beheerder',
  definition: 'De beheerder is de organisatie waarbinnen het beheer van het fondsvermogen plaatsvindt.' }
,
{ name: 'Beheerkosten',
  definition: 'De jaarlijkse beheerkosten, meestal uitgedrukt als percentage, is de vergoeding die een vermogensbeheerder voor het managen van een fonds in rekening brengt. Deze vergoeding wordt in mindering gebracht op het vermogen van het fonds.' }
,
{ name: 'Beleggingsdoelstelling',
  definition: 'Een beleggingsdoelstelling is een door de fondsmanager weergegeven oogmerk voor de middelen geïnvesteerd in zijn fonds. Voorbeelden zijn vermogensgroei op de lange termijn of het genereren van een stabiele inkomstenstroom.' }
,
{ name: 'Beleggingsfonds',
  definition: 'Een beleggingsfonds is een collectieve belegging in meerdere effecten van een groep van beleggers. Doordat beleggers ook met kleinere bedragen op een eenvoudige wijze aandelen in een beleggingsfonds kunnen kopen en verkopen, de portefeuille van een fonds op een professionele manier wordt beheerd, en fondsen toegang hebben tot veel markten en effecten, wordt het risico van een beleggingfonds beperkt geacht. Beleggingsfondsen kennen verschillende juridische structuren en ook verschillende kostenstructuren. Daarbij komt dat er tussen beleggingsfondsen enorme verschillen bestaan in de doelstellingen, strategieën en type van beleggingen. Sommige beleggingsfondsen beperken zich bijvoorbeeld tot een bepaalde sector van de aandelenmarkt, andere beleggingsfondsen richten zich op het regelmatig uitkeren van een zo hoog mogelijk dividend.' }
,
{ name: 'Beleggingsmaatschappij',
  definition: 'Een juridische term voor een beleggingsfonds in de vorm van een NV of BV.' }
,
{ name: 'Benchmark',
  definition: 'Een benchmark is een voorafbepaalde index die dient als ijkpunt voor de prestaties van een beleggingsfonds. Fondsen vergelijken hun performances met de benchmarks als de AEX index of de MSCI Europa-index.' }
,
{ name: 'Benchmark Morningstar',
  definition: 'De referentie-index die Morningstar heeft gekozen als vergelijkingsmaatstaf voor dit fonds. Deze keuze is voor alle fondsen in een bepaalde Morningstar Categorie hetzelfde. Morningstar kiest deze benchmark om er vervolgens berekeningen mee uit te voeren. De benchmarkkeuze van Morningstar kan verschillen van de benchmark die het fonds zelf hanteert.' }
,
{ name: 'Beta',
  definition: 'De beta is een maatstaf voor de gevoeligheid van een fonds voor bewegingen van de markt en is een risicomaatstaf. De beta van de markt is per definitie 1.00. Een beta van 1.10 betekent dat een fonds het gemiddeld 10% beter doet dan de markt als de markt opwaarts beweegt, en gemiddeld 10% slechter scoort als de markt daalt. Veronderstelling hierbij is dat alle andere factoren gelijk blijven. Als de beta van een fonds 0.85 is, betekent dit dat een fonds tijdens een marktstijging het gemiddeld 15% minder goed doet dan het marktgemiddelde, en gemiddeld 15% beter scoort dan het marktgemiddelde bij een koersdaling.' }
,
{ name: 'Bewaarder',
  definition: 'De bewaarder (custodian) is een bancaire of trustorganisatie die verantwoordelijk is voor het bewaren en administreren van de effecten die een fonds bezit. Soms is de bewaarder ook verantwoordelijk voor de afwikkeling van transacties van het fonds.' }
,
{ name: 'Biedkoers',
  definition: 'De biedkoers is de koers waartegen een fondsaanbieder bereid is aandelen van een beleggingsfonds terug te kopen.' }
,
{ name: 'Big bang',
  definition: 'De Big Bang is de invoering van een computersysteem op de beurs in Londen in 1986. Effecten worden sinds die tijd op het scherm verhandeld. Ook werd bij de Big Bang de vaste commissie afgeschaft.' }
,
{ name: 'Blue chips',
  definition: 'Blue chips zijn aandelen in grote, bekende ondernemingen met een gerespecteerd management en een hoge kredietwaardigheid. In Nederland vind je veel blue chips in de AEX-index.' }
,
{ name: 'Boekjaar',
  definition: 'Boekjaar, een twaalfmaandsperiode waarover een onderneming haar omzet en winst rapporteert. Een boekjaar hoeft niet altijd samen te vallen met een kalenderjaar of fiscaal jaar.' }
,
{ name: 'Broker',
  definition: 'Een broker, of commissionair in het Nederlands, is een persoon of firma die bemiddelt tussen de koper en verkoper van effecten. De commissionair krijgt bij een transactie een vergoeding die commissie wordt genoemd.' }
,
{ name: 'Bull market',
  definition: 'Een langdurig optimistisch gestemde markt, gekenmerkt door stijgende koersen.' }
,
{ name: 'Buy and hold strategie',
  definition: 'Een buy en hold strategie is een strategie waarbij een belegger een aandeel koopt en voor een relatief lange periode aanhoudt. Dit in tegenstelling tot een korte termijn trading strategie. De buy en hold strategie heeft per definitie een lage omzet tot gevolg.' }
,
{ name: 'CAC 40',
  definition: 'De CAC 40-index is een index die bestaat uit veertig grote Franse ondernemingen met een notering op de beurs van Parijs. De Parijse beurs is onderdeel van de Euronext.' }
,
{ name: 'Callable obligatie',
  definition: 'Een callable obligatie is een obligatie die op wens van de uitgever eerder terugbetaald kan worden. Dit stelt de uitgever van de obligatie in staat om schulden tegen een lagere rente te herfinancieren als de rente daalt tot onder de couponrente van de obligatie. Als de rente behoorlijk is gedaald, gedraagt de koers van de obligatie zich alsof deze afloopt op het moment waarop deze callable is.' }
,
{ name: 'CAPM',
  definition: 'CAPM, het Capital Asset Pricing Model, is een wiskundig model dat gebruikt kan worden als hulp bij het vinden van een juiste prijs voor een effect. In het model staat de relatie tussen risico en verwacht rendement centraal. Het CAPM is een belangrijk model in de beleggingstheorie waarin het verwachte rendement (E) op een belegging uitgedrukt wordt in de termen van het verwachte rendement op de marktportefeuille (rm) en de Beta coëfficient, E = R + (beta)(rm - R), waar R  het risicovrije rendement is.' }
,
{ name: 'Cash dividend',
  definition: 'Dividend uitgekeerd in contanten.' }
,
{ name: 'CBS-index',
  definition: 'De CBS-index is de aandelenindex die dagelijks door het Centraal Bureau voor de Statistiek wordt berekend. De index bevat alle in Nederland genoteerde aandelen, maar geen beleggingsfondsen.' }
,
{ name: 'Closed end fonds',
  definition: 'Closed end fondsen zijn beleggingsfondsen waarvan het aantal uitstaande aandelen vast is. Net als bij aandelen wordt er bij de introductie een bepaald aantal aandelen aan het publiek aangeboden die vervolgens op de beurs wordt verhandeld. De koers is afhankelijk van het sentiment onder beleggers en kan afwijken van de intrinsieke waarde. Bij een koers onder de intrinsieke waarde spreken we van een discount, bij een koers boven de intrinsieke waarde spreken we over een premium.' }
,
{ name: 'Commissie',
  definition: 'Commissie is de vergoeding die betaald wordt aan de commissionair, ook wel broker genoemd, bij de aan- of verkoop van effecten. In Nederland treden banken vaak ook op als commissionair. De vergoeding kan varieren afhankelijk van de grootte van de order en de mate van advies die gepaard gaan bij de transactie.' }
,
{ name: 'Commissionair',
  definition: 'Een commissionair of broker is een persoon of firma die bemiddelt tussen de koper en verkoper van effecten. De commissionair krijgt bij een transactie een vergoeding die commissie wordt genoemd.' }
,
{ name: 'Consumenten Dienstverlening',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in winkels, persoonlijke diensten, bouw van woningen, verhuur van woningen, reisbureau’s, vermaak en onderwijs. Voorbeelden zijn Metro, Tesco en Hennes &amp; Mauritz.' }
,
{ name: 'Consumenten Prijs Index (CPI)',
  definition: 'The Consumenten Prijs Index (CPI) is een maatstaf voor de inflatie. Deze index bevat een mandje van goederen die door de gemiddelde consument wordt genuttigd (zoals voedsel, kleding, maar ook energie). De procentuele stijging van de index ten opzichte van een jaar geleden wordt vaak de inflatie genoemd.' }
,
{ name: 'Consumentengoederen',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in ondernemingen die voorzien in voedingsmiddelen, dranken, huishoudartikelen en persoonlijke verzorging, assecoires, schoenen, textiel, auto’s en auto-onderdelen, consumentenelektronica, luxe goederen, verpakkingen en tabak. Voorbeelden zijn Benetton, BMW en Clarins.' }
,
{ name: 'Converteerbare obligatie',
  definition: 'Een convertible of converteerbare obligatie is een door een bedrijf uitgegeven obligatie die tijdens de looptijd geconverteerd kan worden naar aandelen in dat bedrijf tegen een vooraf vastgestelde verhouding. De beslissingsbevoegdheid om te converteren kan zowel bij de uitgever als de houder liggen.' }
,
{ name: 'Coupon',
  definition: 'De coupon is het vaste rentepercentage dat met regelmaat op een vastrentende belegging wordt betaald.' }
,
{ name: 'Coupon obligatie',
  definition: 'Bij een coupon obligatie wordt de coupon uitbetaald aan de houder van de obligatie. De houder is niet noodzakelijk diegene aan wie de obligatie oorspronkelijk is uitgegeven. Vroeger werd de rente op de obligatie uitbetaald na inlevering van een aan de obligatie gehechte coupon.' }
,
{ name: 'Credit rating',
  definition: 'De credit rating geeft de kans en waarschijnlijkheid weer dat een onderneming niet in staat is zijn verplichtingen van een bepaalde obligatie na te komen. Er zijn meerdere bureaus die ratings geven, voorbeelden zijn Moody\'s, Standard &amp; Poor\'s en Fitch. Ieder bureau heeft zijn eigen systeem. Obligaties met een rating van AAA tot BBB- heten \'investment grade\' terwijl obligaties met een rating van BB+ te boek staan als speculatief.' }
,
{ name: 'Cyclische ondernemingen',
  definition: 'Cyclische ondernemingen of cyclicals zijn ondernemingen van wie de prestaties nauw samenhangen met de economische gang van zaken. De koersen zijn hoog wanneer de economische groei hoog is, maar hebben fors te lijden van recessies. Voorbeelden zijn de automobiel- en bouwindustrie.' }
,
{ name: 'Data per',
  definition: 'Data per geeft de datum aan waarvan wij een portefeuille van een beleggingsfonds in onze database hebben. Eén van de kernactiviteiten van Morningstar Nederland is het faciliteren, verzamelen, opslaan en verspreiden van portefeuillegegevens. Morningstar verzamelt voor zo ver mogelijk van ieder fonds de portefeuilleposities; de inhoud van het fonds. <br><br>Morningstar Nederland is voortdurend bezig om het aantal portefeuilles in haar database uit te breiden en de bestaande portefeuilles te actualiseren. Als fondsaanbieders ons niet voorzien van elektronische gegevens, verzamelt Morningstar handmatig de portefeuilles aan de hand van (half)jaarberichten.' }
,
{ name: 'Datum',
  definition: 'Datum refereert aan het tijdstip waarop de laatste intrinsieke waarde (NAV) of koers werd gerapporteerd.' }
,
{ name: 'DAX',
  definition: 'De Deutsche Aktienindex (DAX) is een index die bestaat uit de 30 grootste Duitse ondernemingen genoteerd aan de beurs van Frankfurt. Deze index verving in 1987 de Börsen Zeitung Index.' }
,
{ name: 'Delta',
  definition: 'Delta is een optieterm die aangeeft hoeveel aandelen er nodig zijn om het prijsrisico van een optie te dekken. Als een calloptie een delta heeft van 5, dan zal de stijging van waarde van de optie gelijk zijn aan de stijging van 5 aandelen van de onderliggende waarde.' }
,
{ name: 'Depreciatie',
  definition: 'Een depreciatie is de waardedaling van de ene munt ten opzichte van een andere. Een beweging in een wisselkoers resulteert erin dat men met een bepaalde munt minder van een andere geldeenheid kan kopen, de eerste munt is dus gedeprecieerd. Als bijvoorbeeld vorige week een euro 95 dollarcent kostte, en deze week een euro  90 dollarcent waard is, is de waarde van de euro ten opzichte van de dollar gedeprecieerd. De term depreciatie is het tegenovergestelde van appreciatie, en wordt gebruikt bij veranderingen van flexibele wisselkoersen.' }
,
{ name: 'Derivaat',
  definition: 'Een derivaat, ook wel afgeleid product, is een algemene benaming voor vermogenstitels waarvan de koers is gebaseerd op een andere, onderliggende belegging. Voorbeelden van derivaten zijn futures, warrants, swaps en opties.' }
,
{ name: 'Devaluatie',
  definition: 'De devaluatie van een munt is de daling van de waarde van een munteenheid die  een vaste wisselkoers kende. Een devaluatie is vaak het resultaat van een beslissing van de overheid.' }
,
{ name: 'Disconteringsvoet',
  definition: 'De disconteringsvoet wordt gebruikt om de huidige waarde van inkomsten in de toekomst te bepalen. Een belegger kan aan de hand van de disconteringsvoet bijvoorbeeld bepalen wat een goede prijs is van een obligatie die ieder jaar EUR 50 aan dividend zal uitkeren en op het einde van de looptijd EUR 1000 zal betalen door te berekenen wat vandaag de waarde is van deze bedragen.' }
,
{ name: 'Discount ten opzichte van intrinsieke waarde',
  definition: 'Wanneer een beleggingsfonds tegen een discount wordt verhandeld, betekent dit dat de onderliggende beleggingen in het fonds meer waard zijn dan de huidige koers van het fonds. De discount wordt doorgaans weergegeven als een percentage ten opzichte van de intrinsieke waarde. Vooral koersen van closed end fondsen kennen soms een forse afwijking van de intrinsieke waarde.' }
,
{ name: 'Distributeur',
  definition: 'Een distributeur is een persoon of bedrijf die verantwoordelijk is voor de verkoop en marketing van beleggingsfondsen aan beleggers op een continue basis. De distributeur is eveneens verantwoordelijk voor het toezenden van informatie over fondsen, zoals prospectus, jaarverslag en additionele informatie. In Nederland treden banken en verzekeraars onder andere op als distributeurs.' }
,
{ name: 'Diversificatie',
  definition: 'Diversificatie is een strategie om blootstelling aan niet-systematisch risico (dit is risico dat men kan elimineren) te beperken. Daarnaast is er ook systematisch risico, dat is risico dat inherent is aan beleggen in een bepaalde markt.  Men kan bijvoorbeeld het risico dat men loopt bij beleggen in een bepaalde sector of onderneming beperken door in de totale portefeuille een brede spreiding aan te brengen, maar men blijft altijd afhankelijk van marktbrede bewegingen. Bij diversificatie gaat het om het spreiden van uw beleggingen over verschillende beleggingen en fondscategorieën. Een gediversificeerde portefeuille kan aandelen, obligaties, kas en onroerend goed combineren. Beleggingsfondsen zelf kennen een verschillende vorm van spreiding, er zijn enerzijds breed gespreide mixfondsen en anderzijds ook zeer gespecialiseerde aandelenfondsen die in één sector van de aandelenmarkt beleggen. Het doel bij diversificatie is om de totale beleggingsportefeuille zo samen te stellen dat een minder goede performance in het ene onderdeel wordt gecompenseerd door een goed performance van een ander fonds, of gedeelte van de portefeuille.' }
,
{ name: 'Dividend',
  definition: 'Een dividend is een contante winstuitkering die door een fonds of bedrijf gedaan worden aan de aandeelhouder.' }
,
{ name: 'Dividendrendement',
  definition: 'Het dividendrendement wordt berekend door het dividend te delen door de huidige aandelenkoers en de uitkomst met 100 te vermenigvuldigen. Als een fonds bijvoorbeeld een dividend van EUR 0.25 uitkeert en de koers is EUR 4, dan is het dividendrendement 6.25%.' }
,
{ name: 'Domicilie of vestigingsland',
  definition: 'De domicilie is het land waarin een persoon, bedrijf of beleggingsfonds zijn woonplaats heeft. De domicilie kan belangrijke gevolgen voor de belastingheffing hebben.' }
,
{ name: 'Dow Jones Industrial Average',
  definition: 'De in 1896 begonnen Dow Jones Industrial Average is een index die bestaat uit 30 grote, liquide ondernemingen die worden verhandeld op de New York Stock Exchange (NYSE). De aandelen in de index worden gekozen door Dow Jones en de Wall Street Journal. Deze beroemde index bestaat uit een uitgebalanceerde selectie van grote stabiele ondernemingen, zogenoemde blue chips.' }
,
{ name: 'DTC’s',
  definition: 'Dutch Treasury Certificates zijn kortlopende (tot één jaar) schuldbewijzen van de Nederlandse Staat.' }
,
{ name: 'Duration',
  definition: 'Duration is de (gewogen) gemiddelde looptijd van een obligatie. De duration is ook een maatstaf voor het effect dat een renteverandering heeft op de koers van een obligatie of portefeuille van obligaties. Duration wordt gemeten in jaren (een duration van drie jaar betekent bijvoorbeeld dat de koers van een obligatie ongeveer 3% stijgt als de rente met 1% daalt).' }
,
{ name: 'Duurzaam beleggen',
  definition: 'Bij een fonds dat duurzaam belegt, worden de middelen geïnvesteerd in ondernemingen die bepaalde ethische normen hanteren. De groep duurzaam beleggende fondsen wordt steeds belangrijker en varieert sterk. Er zijn fondsen die niet beleggen in wapenproducenten, ondernemingen die dierproeven uitvoeren, alcoholproducenten en tabakproducenten. Maar er zijn ook fondsen die binnen een sector zoeken naar de meest duurzaam opererende onderneming of naar die ondernemingen die een positieve bijdrage leveren aan het milieu.' }
,
{ name: 'Economische cyclus',
  definition: 'De economische cyclus bestaat uit de elkaar opvolgende perioden van economische expansie en economische krimp, die gepaard gaan met veranderingen in de rentestanden, werkgelegenheid, productiviteit. De huidige stand van zaken in een economie (in casu groei of recessie) hebben invloed op de prestaties van (aandelen van) de diverse economische sectoren.' }
,
{ name: 'Eigen vermogen',
  definition: 'Eigen vermogen is het geïnvesteerde vermogen in een onderneming en is een andere term voor aandelenvermogen.' }
,
{ name: 'Emerging market',
  definition: 'Een opkomende markt (emerging market) is een financiële markt van een ontwikkelingsland. Een belegging in een opkomende markt wordt vaak gezien als risicovol vanwege (potentiële) politieke problemen, economische instabiliteit, een kort track record en illiquiditeit.' }
,
{ name: 'Energie',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in ondernemingen actief op gebieden als olie en gas. Voorbeelden zijn BP Amoco, Edinburgh Oil &amp; Gas, Pennzoil en Total Fina.' }
,
{ name: 'ETF\'s',
  definition: 'ETF\'s of trackeraandelen zijn geen traditionele beleggingsfondsen; ze combineren aspecten van beleggingsfondsen met indices en bieden van beiden de voordelen. Men zou ETF\'s indexaandelen kunnen noemen. Sinds begin 2001 worden ETF\'s verhandeld op de Amsterdamse beurs (onder andere I-shares). De ETF\'s of trackeraandelen volgen zeer nauwkeurig een index en worden net als een indexfonds passief gemanaged. ETF\'s worden via een gewone commissionair of broker op de beurs aangekocht tegen de reguliere tarieven die gelden voor aandelentransacties.' }
,
{ name: 'Euribor',
  definition: 'Rentetarief dat kredietwaardige banken elkaar in rekening brengen voor bedragen die luiden in euro\'s. Euribor heeft sinds de komst van de euro de rol van de Aibor overgenomen in Nederland.' }
,
{ name: 'Eurobond',
  definition: 'Een eurobond is een obligatie die uitgegeven is buiten het land van de geldeenheid waarin de obligatie luidt. Een Eurobond bijvoorbeeld in Japanse yennen is uitgegeven buiten Japan.' }
,
{ name: 'Euroland',
  definition: 'Euroland is een naam voor de combinatie van landen die de euro als munteenheid hebben aangenomen. Momenteel bestaat Euroland uit: België, Duitsland, Finland, Frankrijk, Griekenland, Ierland, Italië, Luxemburg, Nederland, Oostenrijk, Portugal en Spanje.' }
,
{ name: 'Euronext',
  definition: 'Euronext is de combinatie van de beurzen van Amsterdam (Amsterdam Exchanges), Brussel (Brussels Exchanges) en Parijs (Paris Bourse).' }
,
{ name: 'Euronext genoteerd',
  definition: 'Sommige beleggingsinstellingen - met name uit Nederland - hebben een notering aan de Euronexxt, het verband waarin onder andere de beurs van Amsterdam is opgegaan. Deze fondsen zijn in principe via iedere commissionair (een bank of via internetbanken) verkrijgbaar.' }
,
{ name: 'Europese Unie (EU)',
  definition: 'Oorspronkelijk opgericht als een gemeenschappelijke markt voor 6 landen in 1957 door het verdrag van Rome, was de EU eerst bekend als de Europese Gemeenschap. Een gemeenschappelijke munt (euro), één markt voor goederen en diensten en een politieke samenwerking van de leden, waren de belangrijkste samenbindende principes achter de unie.' }
,
{ name: 'Eurozone',
  definition: 'De eurozone of Euroland is een term die gebruikt wordt voor de combinatie van landen die de euro als munteenheid hebben aangenomen. Momenteel bestaat de eurozone uit: België, Duitsland, Finland, Frankrijk, Griekenland, Ierland, Italië, Luxemburg, Nederland, Oostenrijk, Portugal en Spanje.' }
,
{ name: 'Ex dividend',
  definition: 'Ex Dividend refereert aan een aandeel dat genoteerd is zonder dividend. Het dividend van een aandeel dat na de ex dividend datum wordt gekocht, wordt uitgekeerd aan de verkopende partij.' }
,
{ name: 'Farmacie &amp; Gezondheid',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in ondernemingen actief op het gebied van farmacie, medische apparatuur en farmaceutische groothandel. Een voorbeeld van een onderneming in deze sector is GlaxoSmithKline.' }
,
{ name: 'FBI',
  definition: 'Fiscale beleggingsinstelling is een juridische term voor een bepaalde vorm van een beleggingsfonds waarbij het fonds niet onderhevig is aan vennootschapsbelasting. Een fonds moet aan eisen voldoen om een FBI te zijn; een belangrijke voorwaarde is dat het inkomsten als rente en dividend weer uitkeert aan de aandeelhouders.' }
,
{ name: 'Federal Funds Rate',
  definition: 'De Federal Funds Rate is de meest genoemde rentestand van de VS. Deze rente wordt bepaald door het FOMC, het beleidsbepalend orgaan van de Fed, en geeft weer hoeveel Amerikaanse banken in rekening brengen om Federal Funds aan elkaar te lenen. De Federal Funds zijn de verplichte reserves die banken aanhouden bij de Fed. Dit systeem zorgt ervoor dat banken die voldoende reserves bij de Fed hebben deze kunnen uitlenen aan banken met een tekort aan reserves bij de Fed.' }
,
{ name: 'Federal Open Market Committee (FOMC)',
  definition: 'The FOMC is het beleidsbepalend orgaan van het Amerikaanse stelsel van centrale banken. De FOMC bestaat uit de zeven leden van the Board of Governors en vijf van de twaalf Reserve Bank voorzitters. De FOMC pleegt open markt transacties, het voornaamste instrument van de Amerikaanse monetaire autoriteit. Daarnaast stelt de FOMC doelzones vast voor de geldgroei en dirigeert de FOMC operaties met betrekking tot interventies in de valutamarkten.' }
,
{ name: 'Financieel adviseur',
  definition: 'Een financieel adviseur is een individu of firma die financieel advies geeft en financiële producten verkoopt. Een financieel adviseur ontvangt veelal een commissie als hij een product verkoopt. Er zijn zowel zelfstandige adviseurs die producten van meerdere aanbieders aanbieden als adviseurs in dienst van financiële instellingen welke zich vooral richten op producten van deze instelling. Het onderscheid tussen zelfstandige en niet-zelfstandige adviseurs is niet altijd even scherp.' }
,
{ name: 'Financiële Dienstverlening',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in banken, financieringsmaatschappijen, vermogensbeheerders, commissionairs en verzekeringsmaatschappijen. Voorbeelden zijn Egg, ING en BSCH.' }
,
{ name: 'Fonds',
  definition: 'Een fonds is een collectieve belegging in diverse effecten, afhankelijk van de doelstelling van het fonds. Een fonds kan beleggen in aandelen, vastrentende waarden of onroerend goed, of in een combinatie van deze categorieën.' }
,
{ name: 'Fondsaanbieder',
  definition: 'Een fondsaanbieder is een vermogensbeheerder die een reeks van beleggingsfondsen aanbiedt aan beleggers.' }
,
{ name: 'Fondsfamilie',
  definition: 'Een fondsfamilie (fund family) is een groep verschillende fondsen van één en dezelfde fondsaanbieders. Soms bieden fonds families de mogelijkheid om tegen geen of gereduceerde kosten te switchen tussen de verschillende familieleden.' }
,
{ name: 'Fondsmanager',
  definition: 'Een fondsmanager is de individu die op de loonlijst staat van de fondsaanbieder en die direct verantwoordelijk is voor het management van de portefeuille van het beleggingsfonds.' }
,
{ name: 'Fondssupermarkt',
  definition: 'Een fondssupermarkt is een bank of fondsaanbieder die een wijd fondsenassortiment afkomstig  van verschillende aanbieders verkoopt, meestal gebeurt dit via het internet.' }
,
{ name: 'Fondsvaluta',
  definition: 'De fondsvaluta is de valuta waarin een beleggingsfonds zijn notering heeft of zijn intrinsieke waarde uitdrukt.' }
,
{ name: 'Fondsvermogen',
  definition: 'Fondsvermogen of Netto vermogen (net  assets) geeft weer hoe groot een beleggingsfonds is. Netto vermogen wordt meestal weergegeven in miljoenen euro, en wordt vaak maandelijks gerapporteerd. Het cijfer wordt gebruikt om de populariteit van een fonds te meten.' }
,
{ name: 'Forward Pricing',
  definition: 'Bij forward pricing wordt de uitvoering van een order bij beleggingsfondsen naar voren verschoven. De fundmanager kan namelijk pas ´s ochtends de Intrinsieke waarde van het beleggingsfonds bepalen na sluiting van Europese, Amerikaanse en Aziatische beurzen. De pricing is gebaseerd op slotkoersen. Om arbitrage te voorkomen wordt na de aanname van een order, deze pas een dag later uitgevoerd.' }
,
{ name: 'FSA',
  definition: 'De FSA is een belangrijke autoriteit in het VK, die verantwoordelijk is voor het beleid in de sector van de financiële dienstverlening en het beschermen van belangen van beleggers.' }
,
{ name: 'FT 30',
  definition: 'De FT 30, ook bekend als de FTSE Ordinary Share Index, werd in 1935 gelanceerd en is gebaseerd op de koeren van de 30 topbedrijven uit het VK. De index bestaat uit blue chips.' }
,
{ name: 'FTSE',
  definition: 'De FTSE, spreek uit als \'Foetsie\', is genoemd naar de Financial Times (FT) en de London Stock Exchange (SE), welke de gezamelijke eigenaren van deze indices zijn. De FTSE Index familie herbergt vele indices waaronder de FTSE 100, 200, and techMARK, maar meestal wordt de naam FTSE gebruikt voor de FTSE 100. Dit is een aandelenindex gebaseerd op de 100 grootste Britse ondernemingen. Andere FTSE indices zijn de 350 en de All-Share.' }
,
{ name: 'FTSE 100',
  definition: 'De FTSE 100, begonnen in 1984, is een index die bestaat uit de 100 grootste ondernemingen qua marktkapitalisatie van de LSE. De FTSE 100 heeft de FT 30 vervangen als meest gebruikte index op de beurs van Londen.' }
,
{ name: 'FTSE 250',
  definition: 'De FTSE 250, begonnen in 1992, is de index van de 250 grootste ondernemingen van de Londense beurs qua marktkapitalisatie direct na de FTSE 100.' }
,
{ name: 'FTSE 350',
  definition: 'De  FTSE 350-index is een combinatie van de FTSE 100 en de FTSE 250.' }
,
{ name: 'FTSE All Share Index',
  definition: 'De FTSE All Share-index bestaat uit de 900 grootste ondernemingen van de London Stock Exchange.' }
,
{ name: 'Fund of Funds, Multi manager fonds',
  definition: 'Een fund of funds of multimanager fund is een fonds dat gespecialiseerd is in het kopen van aandelen in andere beleggingsfondsen en niet belegt in individuele aandelen.' }
,
{ name: 'G7 (Groep van Zeven)',
  definition: 'De G7 zijn de zeven leidende industriële landen, van wie de ministers van Financiën elkaar ontmoeten om economische en politieke onderwerpen te bespreken die van belang zijn voor de Groep van Zeven. De G7 bestaat uit de VK, de VS, Canada, Japan, Duitsland, Frankrijk en Italië. Rusland hoort bij de G8.' }
,
{ name: 'Gamma',
  definition: 'Gamma is een optieterm en geeft aan in welke mate de delta van een optie verandert ten gevolge van de koersverandering van de onderliggende waarde.' }
,
{ name: 'GDR (Global Depositary Receipt)',
  definition: 'Een GDR is vergelijkbaar met een ADR. Een GDR is een certificaat dat bij een bank wordt aangehouden en dat een aantal aandelen in een buitenlands bedrijf vertegenwoordigt. In sommige landen moeten buitenlandse bedrijven GDR\'s uitgeven als zij op de locale beurs een notering willen.' }
,
{ name: 'Geännualiseerd rendement',
  definition: 'Een geännualiseerd rendement is een rendement dat over een bepaalde periode omgerekend wordt naar een periode van een jaar. Als fonds A bijvoorbeeld een rendement van 5% in zes maanden heeft, en fonds B een rendement van 4% in vier maanden, dan is het geannualiseerde rendement van Fonds A en B iets meer dan 10% respectievelijk iets meer dan 12%.' }
,
{ name: 'Gemiddeld rendement',
  definition: 'Gemiddeld rendement is het gemiddelde jaarlijkse rendement van een fonds over de laatste drie jaar. In de sectie Rendement en Risico van de Quick take wordt deze berekend aan de hand van maandelijkse rendementen.' }
,
{ name: 'Gemiddelde',
  definition: 'Het gemiddelde laat de gemiddelde waarde zien van de verschillende gegevens van de fondsen die voldoen aan uw zoekcriteria. De gegevens kunnen managementkosten, alle beschikbare rendementen (1 dag, 1 jaar, 3 jaar enz.), de Morningstar ratings, de Style Box of de standaarddeviatie zijn. U kan per fondsgroep of per sector zoeken, maar ook door middel van een combinatie van beide. In de overzichten staan enkel de eerste 300 fondsen die voldoen aan uw criteria. Het gemiddelde wordt echter berekend met behulp van alle fondsen die aan de criteria voldoen.' }
,
{ name: 'Gilts',
  definition: 'Een gilt is een obligatie uitgegeven door de Britse overheid. Doordat een gilt wordt gegarandeerd door de overheid, is het in het algemeen één van de veiligste beleggingen in de VK.' }
,
{ name: 'Groei',
  definition: 'Groei is een karakterisering van een beleggingsaanpak waarbij de belegger zich richt op aandelen van ondernemingen die een sterke groei doormaken.' }
,
{ name: 'Groeifonds',
  definition: 'Een groeifonds is een fonds dat geen dividend uitkeert maar de inkomsten opnieuw belegt. In Nederland waren tot 1 januari 2001 rentegroeifondsen zeer populair omdat ze fiscale voordelen kenden. Bij buitenlandse fondsen duidt de Engelse term accumulation op een groeifonds.' }
,
{ name: 'Growth',
  definition: 'Growth of Groei is een beleggingstrategie waarbij aandelen worden geselecteerd met een track record van hoge groeicijfers en waarbij het potentieel bestaat voor verdere stijging van omzet en of winst.' }
,
{ name: 'Growth &amp; income fund',
  definition: 'Een growth and income fonds is een fonds waar de fondsmanager die aandelen selecteert die zowel dividend uitkeren als ook groei op de lange termijn laten zien.' }
,
{ name: 'Hang Seng',
  definition: 'De Hang Seng is de index met de 33 grootste aandelen op de aandelenmarkt van Hongkong.' }
,
{ name: 'Hardware',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in fabrikanten van computersystemen, communicatieapparatuur, halfgeleiders en componenten. Voorbeelden zijn Siemens, Cisco en Ericsson.' }
,
{ name: 'Hedge',
  definition: 'Een hedge wordt gebruikt om bepaalde risico’s af te dekken. Men kan bijvoorbeeld een aandelenpakket afdekken op koersdalingen met putopties van dezelfde onderliggende waarde. De constructie die men hiervoor nodig heeft, heet een hedge.' }
,
{ name: 'Hedge fonds',
  definition: 'Een hedge fonds is een beleggingsfonds dat als doel heeft goede rendementen te behalen door agressieve beleggingsstrategieën te gebruiken die doorgaans niet in \'normale\' beleggingsfondsen worden gebruikt. Een hedge fonds kan gebruik maken van beleggen met geleend geld, short selling, derivaten, swaps en arbitrage. De strategie van een hedge fonds is doorgaans complex en alleen geschikt voor professionele en/of institutionele beleggers.' }
,
{ name: 'High yield fonds',
  definition: 'Een high yield fonds is een obligatiefonds welke vooral belegt in obligaties met een lage kredietwaardigheid.' }
,
{ name: 'ICBE',
  definition: 'ICBE  staat for Instelling voor Collectieve Beleggingen in Effecten. De term refereert aan een richtlijn van de Europese Unie, die vaststelt waaraan een in de EU gevestigd fonds moet voldoen om verkocht te mogen worden in alle EU landen. De richtlijn heeft als doel beleggingsrichtlijnen in Europa te vereenvoudigen en beleggers meer bescherming te bieden. In Nederland wordt ICBE ook wel UCITS genoemd.' }
,
{ name: 'Index',
  definition: 'Een index is een verzameling effecten die zo is samengesteld dat ze een bepaald gedeelte van de markt representeert. Veel genoemde indices zijn de Dow Jones Industrial Average, de S&amp;P 500 en de AEX-index. Veel fondsen kiezen ervoor om hun prestaties te evalueren aan de hand van een index (de benchmark).' }
,
{ name: 'Index fonds',
  definition: 'Een indexfonds is een fonds dat zo nauwkeurig mogelijk een bepaalde index volgt met als doel om hetzelfde rendement als deze index te behalen. De fondsmanager bestudeert de samenstelling van de index en veranderingen daarin nauwkeurig om de portefeuille van zijn fonds een zo goed mogelijke afspiegeling te laten zijn van de index.' }
,
{ name: 'Industriële Materialen',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in vliegtuigbouw en defensie-industrie en ondernemingen die voorzien in chemicaliën, machines, bouwmaterialen en andere fabrieksgoederen. Voorbeelden zijn ABB, Air Liquide en EADS.' }
,
{ name: 'Inflatie',
  definition: 'Inflatie is de procentuele toename van lonen en prijzen over een bepaalde periode, doorgaans een jaar. In Nederland wordt de inflatie gemeten door het CBS; de consumentenprijsindex.' }
,
{ name: 'Inland Revenue',
  definition: 'The Inland Revenue (IR) is de Britse belastingdienst.' }
,
{ name: 'Institutionele belegger',
  definition: 'Een institutionele belegger is een algemene term voor een organisatie die grote bedragen belegt. Vaak zijn deze middelen afkomstig van anderen. Voorbeelden van institutionele beleggers zijn pensioenfondsen, beleggingsfondsen en verzekeraars.' }
,
{ name: 'IPO',
  definition: 'Een IPO (Initial Public Offering) is het  voor de eerste maal aanbieden van aandelen van een onderneming aan het publiek gepaard met een beursgang.' }
,
{ name: 'ISIN',
  definition: 'De ISIN is een internationale code voor een effect dat een notering heeft op een beurs.' }
,
{ name: 'Italian Stock Exchange (ISE)',
  definition: 'The Italiaanse beurs is het resultaat van het samengaan van de tien grootste beurzen in Italië in 1991. De beurs is gesitueerd in Milan. De belangrijkste indices zijn de MIB, de MIBTEL en de MIB 30.' }
,
{ name: 'Jaarverslag',
  definition: 'Een jaarverslag is een document dat een beleggingsfonds jaarlijks moet opstellen. Het verslag laat zien hoe het fonds zijn middelen heeft belegd, en geeft inzicht in de financiële stand van zaken door middel van de balans en resultatenrekening. Ook staat er in het jaarverslag een verslag van de directie en de bevindingen van een accountant. In het verslag van de directievoorzitter wordt teruggekeken op het voorgaande jaar en wordt vooruitgekeken naar zaken die in de toekomst zullen spelen en die op de jaarlijkse aandeelhoudersvergadering aan de orde kunnen komen.' }
,
{ name: 'Johannesburg Stock Exchange (JSE)',
  definition: 'De Johannesburg Stock Exchange is de enige beurs van Zuid-Afrika. De beurs kent een sterke focus op goud- en mijnbedrijven. Veel Zuid-Afrikaanse bedrijven (meer dan 30) hebben eveneens een notering aan de Londense beurs.' }
,
{ name: 'Junk bond',
  definition: 'Een junk bond is een obligatie die een hoge rentevergoeding biedt vanwege de hoge kans dat de uitgevende instelling niet aan haar verplichtingen kan voldoen. Junk bonds worden tegenwoordig ook high yield obligaties genoemd en zijn geschikt voor beleggers die een hoger risico willen nemen.' }
,
{ name: 'Koers',
  definition: 'De koers of intrinsieke waarde (NAV in het Engels) is de waarde van de beleggingen in een fonds. Als een fonds open end is, ligt de koers of afgifteprijs normaal gesproken dichtbij de intrinsieke waarde. Als een fonds closed end is kan de koers echter fors afwijken van de intrinsieke waarde. (Zie discount en premium ten opzichte van intrinsieke waarde.)' }
,
{ name: 'Koerswinst',
  definition: 'Een koerswinst is de winst gemaakt op de verkoop van een belegging zoals bijvoorbeeld een beleggingsfonds. Het verlies op de verkoop wordt een koersverlies genoemd.' }
,
{ name: 'Koerswinstverhouding',
  definition: 'De koerswinstverhouding (K/W of P/E) is een kengetal die wordt berekend door de aandelenkoers te delen door de winst per aandeel. Samen met de P/CF en de P/B is dit een veelgebruikte waarderingsmaatstaf voor aandelen. Ondernemingen waarvan verwacht wordt dat ze een sterke groei zullen doormaken, hebben doorgaans hogere K/W\'s dan ondernemingen met een lage groei. Als een aandeel een koers van EUR 16 heeft en de winst per aandeel EUR 4 heeft, is de koerswinstverhouding 4. Een aandeel dat evenens EUR 16 noteert, maar een winst per aandeel kent van EUR 6, heeft een K/W van 2.67. Het omgekeerde van de koerswinstverhouding wordt berekend door 1 te delen door de K/W, en heet de earnings yield. Bij een K/W van 20 is de earnings yield een twintigste of 5%. Dit geeft aan dat een belegger bij een investering een rendement verwacht van 5%.' }
,
{ name: 'Koopsom',
  definition: 'Een verzekering waarbij de verzekerde ineens een bepaald bedrag stort, waardoor hij later aanspraak kan maken op een bepaald bedrag of lijfrente. Bij het afsluiten van een koopsompolis kan vaak gekozen worden om te beleggen in één of meerdere beleggingsfondsen.' }
,
{ name: 'Kosten gecorrigeerde performance',
  definition: 'In een voor kosten gecorrigeerde performance worden de kosten die in rekening worden gebracht die bij de aankoop van een fonds afgetrokken van het rendement om een vergelijking zinvol te maken.' }
,
{ name: 'Kredietkwaliteit',
  definition: 'De kredietkwaliteit of credit quality is de beoordeling van de kwaliteit en veiligheid van een obligatie gebaseerd op de financiële conditie van de onderneming.' }
,
{ name: 'Laatkoers',
  definition: 'De laatkoers is de prijs waartegen een belegger aandelen in een beleggingsfonds kan kopen.' }
,
{ name: 'Large Cap',
  definition: 'Aandelen met een hoge marktkapitalisatie.' }
,
{ name: 'Leading indicators',
  definition: 'Leading indicators zijn reeksen van economische cijfers waarvan gebleken is dat veranderingen een voorspellende waarde hebben voor de gang van zaken in de rest van de economie. Voorbeelden van leading indicators zijn het aantal werkuren in een week, prijzen van ruwe en intermediaire goederen, en de uitgegeven bouwvergunningen. Vooral in de VS wordt naar publicatie van leading indicators uitgekeken.' }
,
{ name: 'Liquiditeit',
  definition: 'De liquiditeit geeft weer hoe makkelijk het is om een aandeel of ander effect te kopen of verkopen zonder een grote invloed te hebben op de koersvorming.' }
,
{ name: 'London Interbank Offered Rate (LIBOR)',
  definition: 'LIBOR (London Interbank Offered Rate) is de rente die de grootste en meest kredietwaardige internationale banken elkaar in Londen in rekening brengen als ze aan elkaar lenen.' }
,
{ name: 'London Stock Exchange (LSE)',
  definition: 'Gestart in 1973 door de combinatie van zes regionale beurzen van Groot Britannië en Ierland, is de London Stock Exchange (LSE) één van de grootste beurzen ter wereld. Bekendste index op de LSE is de FTSE 100.' }
,
{ name: 'Gemiddelde marktkapitalisatie',
  definition: 'De Gemiddelde marktkapitalisatie geeft de marktkapitalisatie van dat aandeel in de portefeuille waarbij de helft van het totaal aantal aandelen in de portefeuille een grotere marktkapitalisatie heeft, en de ander helft een kleinere marktkapitalisatie heeft. Het geeft een indruk of de \'gemiddelde\' positie in een portefeuille bestaat uit een aandeel met een hoge dan wel lage marktkapitalisatie. Het wordt weergegeven in miljoenen dollars.' }
,
{ name: 'Management fee',
  definition: 'De jaarlijkse management fee of beheerkosten, meestal uitgedrukt als percentage, is de vergoeding die een vermogensbeheerder voor het managen van een fonds in rekening brengt. Deze vergoeding wordt in mindering gebracht op het vermogen van het fonds.' }
,
{ name: 'Managementvergoeding',
  definition: 'Een performance fee is een vergoeding die aan de fondsmanager wordt betaald als hij een bepaalde performance heeft behaald in een gespecificeerde periode. Vaak wordt de performance fee uitbetaald als het fonds beter presteert dan zijn benchmarkindex. Fondsen met een performance fee zijn relatief zeldzaam, soms kennen buitenlandse fondsen of hedge funds zo\'n kostenstructuur.' }
,
{ name: 'Market timing',
  definition: 'Market timing is een beleggingsstrategie waarbij beleggers switchen tussen verschillende effecten of in en uit de markt stappen in de hoop om te profiteren van verschillende economische of technische veranderingen waarvan zij verwachten dat die invloed zullen hebben op de markt.' }
,
{ name: 'Maturity',
  definition: 'Maturity is de datum waarop de hoofdsom van een vastrentende belegging, zoals een obligatie, zal worden terugbetaald. De Maturity, oftewel de afloopdatum, van een vijfjaarsobligatie die is uitgegeven op 1 november 2000 is 1 november 2005.' }
,
{ name: 'Media',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in ondernemingen die medianetwerken bezitten of exploiteren en ondernemingen die content creëren. Voorbeelden zijn Time Warner, Pearson en TF1.' }
,
{ name: 'Mediaan',
  definition: 'De mediaan is de middelste waarde in een groep van getallen. In een serie met 1,4, 7, 8, 10, 11, 23, 45, en 52, is 10 is de mediaan.' }
,
{ name: 'Mid Cap',
  definition: 'Aandelen met een middelgrote marktkapitalisatie.' }
,
{ name: 'Minimale belegging',
  definition: 'De minimale belegging is het kleinste bedrag dat een belegger in een beleggingfonds kan storten. Er kan zowel een minimale belegging gelden voor de eerste storting in een fonds als voor het aanvullen van dit bedrag.' }
,
{ name: 'Mixfonds',
  definition: 'Een mixfonds combineert aandelen, obligaties en eventueel kas met als doel een rendement te behalen in combinatie met een gematigd risico.  Normaal gesproken doet een mixfonds het in een bear markt beter dan een aandelenfonds, en in een bull markt slechter. Tussen mixfondsen onderling bestaan grote verschillen in de percentages aandelen en obligaties in portefeuille en dus ook grote verschillen tussen het risico en het verwachte rendement.' }
,
{ name: 'Moderne Portefeuille Theorie',
  definition: 'De moderne portefeuilletheorie (MPT) is een benadering van beleggen die ervan uitgaat dat beleggers hun beschikbare vermogen in veel verschillende effecten investeren in een verhouding die gebaseerd is op hoe de verwachte rendementen van deze effecten met elkaar samenhangen. De evaluatie van portefeuillebeslissingen wordt gedaan door gebruik te maken van Alfa, Beta en de R-kwadraat bij de afweging van risico versus rendement. Voorstanders van de MPT beweren dat als de analyse goed wordt uitgevoerd, dit leidt tot optimale beleggingsportefeuilles. Een portefeuille is optimaal als er bij het gegeven risiconiveau geen hoger rendement verkregen kan worden.' }
,
{ name: 'Morningstar Categorie',
  definition: 'Fondsen worden door Morningstar gegroepeerd in categorieën. Morningstar doet dit niet door naar de doelstelling of het rendement van een fonds te kijken, maar kijkt vooral naar de portefeuille van het fonds. In de diverse categorieën vinden we fondsen die onderling vergelijkbaar zijn. Bij voorkeur onderzoekt Morningstar van één fonds portefeuilles van verschillende data om tot een goede beoordeling te komen.' }
,
{ name: 'Morningstar Equity Style Box',
  definition: 'De Morningstar Equity fund Style Box geeft grafisch weer hoe de dominante beleggingsstijl van een aandelenfonds is. De Style Box combineert twee dimensies: Grootte (Large-Caps, Mid-Caps en Small-Caps) en Waardering (Groei, Gemengd, Waarde), wat resulteert in negen mogelijke stijlcombinaties. De Style Box geeft onmiddelijk een indruk wat de focus van een fonds is, en kan de belegger helpen bij het maken van een portefeuillebeslissing.' }
,
{ name: 'Morningstar Fixed Income Style Box',
  definition: 'De Morningstar Fixed Income fund Style Box geeft de beleggingsstijl van een vastrentend fonds weer in een grafische vorm. De box combineert twee dimensies: Looptijd (Kort, Gemiddeld, Lang) en de kredietwaardigheid (Hoog, Gemiddeld, Laag), wat resulteert in negen mogelijke stijlcombinaties. De Style Box geeft onmiddelijk een indruk wat de focus van een fonds is, en kan de belegger helpen bij het maken van een portefeuillebeslissing.' }
,
{ name: 'Morningstar Rating',
  definition: 'De Morningstar Rating is een maatstaf die de voor risico gecorrigeerde prestaties ten opzichte van andere fondsen in dezelfde Morningstar Categorie vergelijkt. De rating is gebaseerd op de Stutzer index en maakt gebruik van drie jaar rendementshistorie. De beste 10% in een bepaalde categorie krijgt vijf sterren, de volgende 22.5% krijgt vier sterren, de middelste 35% krijgt drie sterren, de volgende 22.5% krijgt twee sterren en de slechtst presterende 10% krijgt één ster. Niet alle fondsen hebben een rating. Sommige fondsen bestaan nog niet lang genoeg, of de informatie is onvolledig. Ook kan het zijn dat de categorie te klein of te divers is om tot een zinvolle rating te komen. Het zegt niets over de kwaliteit van het fonds. Zie ook onze methodologiesectie die u kunt vinden onder Over Ons.' }
,
{ name: 'NASDAQ',
  definition: 'NASDAQ (National Association of Securities Dealers Automated Quotations) wordt meestal gebruikt om te refereren aan de NASDAQ US national stockmarket, die in 1971 werd opgericht. De NASDAQ is qua grootte de tweede beurs van de VS en is \'s werelds oudste electronische beurs. Belangrijke ondernemingen met een notering op de NASDAQ zijn Intel, Dell Computers, MP3.com, Microsoft en  Cisco Systems.' }
,
{ name: 'Netto fondsvermogen',
  definition: 'Netto vermogen (net  assets) geeft weer hoe groot een beleggingsfonds is. Netto vermogen wordt meestal weergegeven in miljoenen euro, en wordt vaak maandelijks gerapporteerd. Het cijfer wordt gebruikt om de populariteit van een fonds te meten.' }
,
{ name: 'New York Stock Exchange (NYSE)',
  definition: 'Op Wall Street in New York is de New York Stock Exchange (NYSE) de oudste en grootste aandelenbeurs van de VS. De S&amp;P 500 en de Dow Jones Industrial Average zijn de belangrijkste indices die het koersverloop op de NYSE weergeven.' }
,
{ name: 'NMAX',
  definition: 'Deel van Amsterdamse beurs dat bestemd is voor jonge bedrijven. De toelatingseisen voor de Nieuwe Markt zijn soepeler dan voor de officiële markt, waardoor ook fondsen met een minder uitgebreid track record een notering kunnen krijgen.' }
,
{ name: 'No-load fund',
  definition: 'Een no-load fonds is een (buitenlands) beleggingsfonds dat direct bij de fondsaabieder gekocht wordt zonder het betalen van aankoopkosten of een andere commissie. De beheervergoeding op een no-load fonds kan echter wel hoger zijn.' }
,
{ name: 'Nutsbedrijven',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in nutsbedrijven actief op het gebied van elektrische stroom, gas of water. Voorbeelden zijn E.On, Electrabel en Veolia.' }
,
{ name: 'Obligatie',
  definition: 'Een obligatie is een instrument om vreemd vermogen aan te trekken en wordt uitgegeven door de overheid of een bedrijf. Gedurende het leven van een obligatie, ontvangt de obligatiehouder reguliere rentebetalingen gebaseerd op de couponrente. Er zijn echter uitzonderingen, zoals zogenoemde zero coupon bonds, welke geen rente uitkeren en daarom worden uitgegeven tegen een koers die ver onder de nominale waarde van de obligatie ligt.' }
,
{ name: 'Obligatiefonds',
  definition: 'Een obligatiefonds belegt vooral in obligaties met als doel een stabiele stroom van inkomsten en/of koersresultaat voor beleggers te genereren. De waarde van een obligatiefonds beweegt tegenovergesteld aan veranderingen in de kapitaalmarktrente.' }
,
{ name: 'Omzet',
  definition: 'De omzet binnen de beleggingsportefeuille geeft weer hoe actief een fondsmanager gedurende een jaar heeft gehandeld. Het wordt weergegeven als percentage van het totale vermogen van een fonds. Een omzet van 20% bijvoorbeeld, betekent dat de fondsmanager eenvijfde van portefeuille heeft omgezet in andere posities. Een omzet van 100% echter betekent niet dat iedere positie uit de portefeuille is verkocht. Het percentage geeft een indicatie van het percentage van de portefeuilleposities die zijn veranderd in het afgelopen jaar.' }
,
{ name: 'Open end beleggingsfonds',
  definition: 'Een open end beleggingsfonds is een collectieve belegging waarvan het aantal aandelen kan variëren. Als er per saldo vraag is naar het fonds, worden extra aandelen uitgegeven. Als er meer aanbod dan vraag is, koopt het fonds aandelen in.' }
,
{ name: 'Opkomende markt',
  definition: 'Een opkomende markt (emerging market) is een financiële markt van een ontwikkelingsland. Een belegging in een opkomende markt wordt vaak gezien als risicovol vanwege (potentiële) politieke problemen, economische instabiliteit, een kort track record en illiquiditeit.' }
,
{ name: 'Oprichtingsdatum',
  definition: 'De oprichtingsdatum is de datum waarop een fonds van start is gegaan.' }
,
{ name: 'P/B',
  definition: 'P/B, Price-to-Book, is een financieel kengetal dat wordt berekend door de koers van een aandeel te delen door de boekwaarde van een onderneming (eveneens gemeten per aandeel). Deze ratio vergelijkt de marktwaarde van een onderneming met de boekwaarde. Voor een onderneming die bijvoorbeeld verhandeld wordt tegen een koers die enkele malen hoger is dan de boekwaarde, wordt door beleggers een groei verwacht, die in de toekomst de boekwaarde zal verhogen. Een lage P/B duidt er meestal op dat beleggers denken dat bepaalde bedrijfsonderdelen te hoog gewaardeerd worden op de balans van de onderneming.' }
,
{ name: 'P/CF',
  definition: 'P/CF, staat voor Price-to-Cash Flow, en is een financieel kengetal welke wordt berekend door de koers van een aandeel te delen door de cash flow per aandeel, waar de cash flow grofweg gesproken de winst plus afschrijving is. Een P/CF van 10 indiceert bijvoorbeeld dat tegenover iedere euro die een onderneming binnenkomt, de aandelenkoers tienmaal zo hoog is. Wat redelijke en normale P/CF verhoudingen zijn, verschilt van sector tot sector. In kapitaalintensieve sectoren ligt de P/CF verhouding doorgaans een stuk lager dan in minder kapitaalintensieve sectoren zoals software.' }
,
{ name: 'Paraplufonds',
  definition: 'Een paraplufonds (umbrella fund) is een soort fonds waaronder verschillende subfondsen \'hangen\'. Deze subfondsen hebben een van elkaar afwijkende beleggingsstrategie. Beleggers kunnen soms van het ene subfonds naar het andere switchen tegen gereduceerde kosten.' }
,
{ name: 'Paris Bourse',
  definition: 'De Paris Bourse is de Franse nationale aandelenmarkt welke onderdeel is van de Euronext, de combinatie van de beurzen van Amsterdam, Brussel en Parijs.' }
,
{ name: 'Penny stocks',
  definition: 'Een penny stock is een meestal  volatiel aandeel van een risicovol bedrijf met een lage marktkapitalisatie. De koers van een penny stock bedraagt vaak niet meer dan enkele tientallen centen.' }
,
{ name: 'Performance',
  definition: 'De performance of het rendement van een fonds geeft weer hoe de waarde van een belegging is gegroeid (of gedaald) over een bepaalde periode. Beleggers vergelijken de rendementen van fondsen om de prestaties van vergelijkbare fondsen tegen elkaar af te zetten.' }
,
{ name: 'Performance-evaluatie',
  definition: 'Performance-evaluatie is een proces dat uit twee stappen bestaat om de prestaties van een fondsmanager te beoordelen. Allereerst kijken we of een fonds het beter deed dan zijn benchmarkindex. De tweede stap is het evalueren van de methodes die gebruikt zijn om deze opbrengst te realiseren.' }
,
{ name: 'Periodiek',
  definition: 'Het minimale periodieke beleggingsbedrag is het minimum bedrag dat u periodiek moet storten als u wilt deelnemen in een beleggingsfonds.' }
,
{ name: 'Periodiek beleggen',
  definition: 'Periodiek beleggen houdt in dat een belegger meestal maandelijks (via een machtiging of periodieke overschrijving) geld inlegt in een beleggingsfonds.' }
,
{ name: 'Portefeuille',
  definition: 'Portefeuille is een verzameling van aandelen, obligaties of andere beleggingen. Zie ook Diversificatie.' }
,
{ name: 'Portefeuilletype',
  definition: 'Er zijn twee typen portefeuilles: quick en transactieportefeuilles. Een quick portefeuille is de eenvoudigste manier om een portefeuille aan te maken. Een transactieportefeuille biedt zeer uitgebreide mogelijkheden, zoals het verwerken van aankopen, verkopen, dividenden en splitsingen.' }
,
{ name: 'Positie',
  definition: 'Een positie is een belegging. Een fonds dat in Microsoft belegt heeft een positie in die onderneming. Een belegger kan posities innemen in fondsen, aandelen of andere beleggingen.' }
,
{ name: 'Preferente aandelen',
  definition: 'Preferente aandelen zijn vormen van eigen vermogen die een vooraf vastgesteld dividend uitkeren, terwijl het dividend op gewone aandelen varieert en soms zelfs nihil is. Preferente aandelen kennen vaak geen stemrecht, tenzij de onderneming niet in staat is het vastgestelde dividend uit te keren. Houders van preferente aandelen hebben bij liquidatie van een onderneming voorrang op houders van gewone aandelen.' }
,
{ name: 'Premium ten opzichte van intrinsieke waarde',
  definition: 'Wanneer een beleggingsfonds noteert tegen een premie boven de intrinsieke waarde, is de koers hoger dan de nettowaarde van het fonds (per aandeel). Closed end beleggingsfondsen kunnen een hoge premie noteren. De premie wordt meestal uitgedrukt als percentage, en geeft weer hoeveel meer beleggers voor de aandelen willen betalen dan de aandelen op dat moment volgens het beleggingsfonds waard zijn.' }
,
{ name: 'Prospectus',
  definition: 'Een prospectus is een formele verklaring van een fonds, die wordt uitgegeven voordat de aandelen worden aangeboden aan het publiek. In deze verklaring zet het fonds zijn doelstelling, de kosten, en andere feiten die de belegger moet weten om een geïnformeerde beslissing te nemen.' }
,
{ name: 'Ranking binnen categorie',
  definition: 'Ranking binnen categorie meet op een schaal van 1-100 hoe een fonds gepresteerd heeft ten opzichte van de andere fondsen in de categorie. Hierbij geeft 1 aan dat het fonds zeer goed heeft gepresteerd en 100 geeft aan dat het fonds qua performance binnen zijn categorie de hekkensluiter is.' }
,
{ name: 'Redemption fee',
  definition: 'Verkoopkosten zijn kosten die bij een belegger in rekening worden gebracht als hij zijn aandelen in een beleggingsfonds verkoopt. Bij buitenlandse fondsen worden de verkoopkosten vaak lager naar mate een belegger langer in een fonds heeft gezeten, na drie of vijf jaar zijn de kosten vaak nul. Deze kostenstructuur moet beleggers ervan weerhouden om te snel hun middelen weer uit de fondsen te halen.' }
,
{ name: 'Relatief tov Categorie',
  definition: 'Morningstar laat zien hoe de sector of regionale gewichten van een fonds zich verhouden met het gemiddelde gewicht in de categorie waarin het fonds is ingedeeld. Het categoriegemiddelde wordt op 1.00 gezet. Staat er bijvoorbeeld bij Financiële Dienstverlening 1.5, dan betekent dit dat het fonds 1.5 maal zoveel (50% meer) als andere fondsen uit de categorie (gemiddeld genomen) in deze sector belegt.' }
,
{ name: 'Rendement',
  definition: 'Rendement of total return of totaalrendement is de procentuele totale opbrengst op een belegging over een bepaalde periode en bestaat uit de som van koerswinst en uitgekeerde dividenden. De total return moet niet worden verward met het dividendrendement.' }
,
{ name: 'Rentetermijnstructuur',
  definition: 'De yield curve, of rentetermijnstructuur, is een grafiek die het verband weergeeft tussen de looptijd van een vastrentende belegging enerzijds en de daarop te ontvangen marktrente anderzijds. Een normale yield curve laat een stijgend verloop zien omdat de spaarrente doorgaans lager is dan de kapitaalmarktrente. Als iemand zijn geld voor een langere periode uitleent, eist hij een hogere vergoeding dan bij een lening over een korte termijn.' }
,
{ name: 'Risicopremie',
  definition: 'Het geëiste rendement op een belegging of investering minus de risicovrije rente (de rente die de meest kredietwaardige partners elkaar in rekening brengen). De risicopremie geeft dus weer welke vergoeding wordt gevraagd voor het lopen van risico op een belegging. Wordt veel gebruikt bij de analyse van obligaties.' }
,
{ name: 'Risicoprofiel',
  definition: 'Het balkje waarboven ‘Risicoprofiel’ staat, geeft weer hoe hoog de volatiliteit van een fonds is. Dit wordt gemeten aan de hand van de standaarddeviatie van het fonds. Fondsen met een lage volatiliteit hebben een standaarddeviatie tussen de 0 en 13.34. De fondsen met een gemiddeld risicoprofiel, hebben een standaarddeviatie die valt tussen de 13.34 en 26.67. Een fonds met een standaarddeviatie boven de 26.67 heeft een hoog risico.' }
,
{ name: 'R-kwadraat',
  definition: 'De R-kwadraat is dat gedeelte in het rendement van een fonds dat verklaard kan worden door bewegingen in zijn benchmarkindex. Een R-kwadraat van 1 indiceert dat alle bewegingen in de index gevolgd worden door bewegingen in het rendement van het fonds. Als een fonds alleen in indexaandelen belegt, zal het meestal een R-kwadraat dicht bij 1 kennen. Als een R-kwadraat omgekeerd laag is, betekent dit dat slechts een klein gedeelte van het rendement van een fonds wordt verklaard door de index. R-kwadraat wordt ook wel gebruikt in combinatie met beta. Een hoge R-kwadraat geeft aan dat de Beta betrouwbaar is. Een minder hoge R-kwadraat impliceert een insignificante beta.' }
,
{ name: 'S&amp;P 500',
  definition: 'De S&amp;P 500 is een index die bestaat uit  500 grote en meestal kredietwaardige ondernemingen uit de VS.' }
,
{ name: 'Sectorfondsen',
  definition: 'Sectorfondsen zijn fondsen die in één bepaalde sector beleggen zoals technologie, media, biotechnologie of de financiële dienstverlening. Vanwege de nauwe focus, zijn sectorfondsen vaak wat risicovoller dan breder beleggende fondsen. Het rendement kan sterk fluctueren omdat de sectorvoorkeuren van beleggers door de tijd heen vaak veranderen.' }
,
{ name: 'Securities and Exchange Commission (SEC)',
  definition: 'Deze Amerikaanse toezichthouder werd opgericht in 1934 en moet beleggers beschermen tegen malafide praktijken op de effectenmarkten zodat deze markten op een goede wijze functioneren. De SEC maakt regels waaraan alle ondernemingen die in de VS effecten aanbieden, moeten voldoen.' }
,
{ name: 'SEDOL',
  definition: 'SEDOL staat voor  Stock Exchange Daily Official List en is een identificatiecode voor Europese effecten van zeven karakters.' }
,
{ name: 'Sharpe Ratio',
  definition: 'De Sharpe Ratio geeft de prestaties van een beleggingsfonds weer, waarbij gecorrigeerd wordt voor risico. De Sharpe Ratio is ontwikkeld door de nobelprijswinnaar William Sharpe. De maatstaf wordt berekend door gebruik te maken van de standaarddeviatie en de opbrengst van een fonds boven de risicovrije voet en geeft weer hoeveel rendement er per eenheid risico is behaald. Hoe hoger de Sharpe Ratio, hoe beter de risicogecorrigeerde prestaties van een beleggingsfonds. De Sharpe Ratio over een bepaalde periode wordt berekend door de geännualiseerde performance van een fonds te berekenen, daarvan de risicovrije voet over dezelfde periode af te trekken en de uitkomst vervolgens door de standaarddeviatie van de performance van het fonds te delen. De Sharpe Ratio kan worden gebruikt om de prestaties van twee vergelijkbare fondsen te beoordelen.' }
,
{ name: 'Short',
  definition: 'Short\' zitten betekent dat men een effect hebt verkocht zonder dat men het in bezit had. Een individu of een fonds kan bijvoorbeeld een aandeel verkopen welke hij niet in portefeuille heeft. Hij zal dan in de toekomst alsnog dat aandeel moeten kopen om aan de levering te voldoen. Deze strategie kan worden toegepast als men verwacht dat de koers van het betreffend aandeel zal gaan dalen. De winstmarge op deze strategie is het verschil tussen de koers op het moment van verkoop, en de koers op het moment dat desbetreffend aandeel wordt aangekocht.' }
,
{ name: 'SICAV',
  definition: 'SICAV staat voor Sociétés d\'Investissement à Capital Variable en is een open-end beleggingsfonds. De meeste fondsen in Luxemburg zijn SICAV\'s.' }
,
{ name: 'Small Cap',
  definition: 'Aandelen met een kleine marktkapitalisatie.' }
,
{ name: 'Software',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in ondernemingen die actief zijn in het ontwerp en marketing van operationele systemen en applicaties. Voorbeelden zijn Microsoft, SAP en Siebel Systems.' }
,
{ name: 'Splitsing',
  definition: 'Een stock split of splitsing vindt plaats wanneer een onderneming het aantal uitstaande aandelen verhoogt, zonder de eigendomsverhoudingen te wijzigen. De koers van een aandeel wordt lager, waardoor ook kleinere beleggers de eerder hooggeprijsde aandelen kunnen kopen. Een aandeel dat bijvoorbeeld EUR 250 kost, kan worden gesplitst in 10 aandelen die dan ieder EUR 25 noteren. Een split kan zowel bij een gewoon aandeel als bij een beleggingsfonds plaatsvinden. In het laatste geval zal de belegger meer aandelen van een beleggingsfonds in bezit krijgen die dezelfde waarde vertegenwoordigen als voor de splitsing.' }
,
{ name: 'Standaarddeviatie',
  definition: 'De standaarddeviatie is een statistische maatstaf die de spreiding rondom een gemiddelde meet. De standaarddeviatie voor een fonds geeft weer hoe rendementen in een bepaalde periode van elkaar varieerden. Beleggers gebruiken de standaarddeviatie over een bepaalde periode als maatstaf voor risico die vervolgens weer gebruikt kan worden om te voorspellen in welke range toekomstige rendementen waarschijnlijk zullen voorkomen. Wanneer een fonds een hoge standaarddeviatie heeft, is de voorspelde range breder, wat een hogere volatiliteit impliceert. De standaarddeviatie kan gebruikt worden om het risico van een bepaald fonds te meten. Voor het meten van het risico van een portefeuille van meerdere fondsen is de standaarddeviatie minder geschikt omdat de standaarddeviatie van een portefeuille niet alleen afhangt van de standaarddeviatie van de onderdelen van de portefeuille, maar ook van de correlatie tussen de fondsrendementen. Als rendementen van een fonds een normale verdeling kennen, dan zal het rendement in ongeveer 68% van de gevallen binnen één standaarddeviatie van het gemiddelde vallen. In 95% van de gevallen valt het rendement binnen de bandbreedte die wordt gevormd door twee standaarddeviaties bij het gemiddelde rendement op te tellen en af te trekken.' }
,
{ name: 'Stijl',
  definition: 'De term stijl wordt vaak gebruikt om een beleggingsaanpak te karakteriseren. Morningstar refereert vaak aan beleggingsstijl in de context van een waarde-aanpak of een groeiaanpak.' }
,
{ name: 'Stock dividend',
  definition: 'Dividend uitgekeerd in aandelen.' }
,
{ name: 'Surséance',
  definition: 'Surséance van betaling wordt door een rechter aan een bedrijf verleend indien het tijdelijk niet aan zijn verplichtingen jegens debiteuren kan voldoen. Tijdens de periode van uitstel kunnen crediteuren geen faillissement aanvragen.' }
,
{ name: 'Switch fee',
  definition: 'Kosten die in rekening worden gebracht (in plaats van reguliere aan- en verkoopkosten)  wanneer men overstapt van het ene naar het andere fonds binnen een fondsfamilie.' }
,
{ name: 'Switchen',
  definition: 'Switchen is het overbrengen van geld in het ene fonds van een fondsaanbieder naar een andere fonds van dezelfde aanbieder.' }
,
{ name: 'Telecommunicatie',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in ondernemingen die voorzien in communicatiediensten en daarbij vaste of draadloze netwerken gebruiken en of diensten op dit gebied aanbieden. Vorobeelden zijn Swisscom, Vodafone en Telefonica.' }
,
{ name: 'The City',
  definition: 'De City staat ook bekend als de Square Mile. De City is het hart van het financiële district in Londen.' }
,
{ name: 'Tijgers',
  definition: 'De Tijgers of de vier Tijgers zijn Hongkong, Singapore, Taiwan en Zuid-Korea.' }
,
{ name: 'Totaal rendement',
  definition: 'De total return of (totaal) rendement is de procentuele totale opbrengst op een belegging over een bepaalde periode en bestaat uit de som van koerswinst en uitgekeerde dividenden. De total return moet niet worden verward met het dividendrendement.' }
,
{ name: 'Tracker',
  definition: 'Trackers of ETF\'s zijn geen traditionele beleggingsfondsen; ze combineren aspecten van beleggingsfondsen met indices en bieden van beiden de voordelen. Men zou ETF\'s indexaandelen kunnen noemen. Sinds begin 2001 worden ETF\'s verhandeld op de Amsterdamse beurs (onder andere I-shares). De ETF\'s of trackeraandelen volgen zeer nauwkeurig een index en worden net als een indexfonds passief gemanaged. ETF\'s worden via een gewone commissionair of broker op de beurs aangekocht tegen de reguliere tarieven die gelden voor aandelentransacties.' }
,
{ name: 'Tracker fund',
  definition: 'Een tracker fund is een fonds dat een bepaalde index volgt en probeert hetzelfde rendement te behalen als deze index. De fondsmanager bestudeert de samenstelling en veranderingen van de index om deze nauwkeurig te volgen. Wordt ook vaak indexfonds genoemd.' }
,
{ name: 'Tracking error',
  definition: 'De tracking error is een maatstaf die meet hoe dicht een fondsmanager bij zijn benchmarkindex blijft. Als fonds A een tracking error heeft van 2%  en fonds B een tracking error van 4% dan betekent dit dat fonds B zich  grotere vrijheden heeft gepermitteerd ten opzichte van zijn benchmarkindex dan fonds A. De portefeuille van fonds A lijkt meer op de benchmark dan de portefeuille van fonds B. Een tracking error van 0% betekent dat een fonds de index precies volgt.' }
,
{ name: 'UCITS',
  definition: 'UCITS staat for Undertakings for Collective Investment in Transferable Securities. De term refereert aan een richtlijn van de Europese Unie, die vaststelt waaraan een in de EU gevestigd fonds moet voldoen om verkocht te mogen worden in alle EU landen. De UCITS richtlijn heeft als doel beleggingsrichtlijnen in Europa te vereenvoudigen en beleggers meer bescherming te bieden. In Nederland wordt UCITS ook wel ICBE genoemd.' }
,
{ name: 'Unit-linked',
  definition: 'Unit-linked betekent gekoppeld aan een (pensioen)verzekering. Unit-linked beleggingsfondsen zijn alleen toegankelijk via deelname in producten als een beleggingshypotheek, koopsompolis en pensioenverzekeringen.' }
,
{ name: 'Vastrentend fonds',
  definition: 'Een vastrentend fonds is een fonds dat belegt in effecten die op een reguliere basis vaste inkomsten genereren, meestal obligaties, geldmarktinstrumenten of preferente aandelen.' }
,
{ name: 'Verkoopkosten',
  definition: 'Verkoopkosten zijn kosten die bij een belegger in rekening worden gebracht als hij zijn aandelen in een beleggingsfonds verkoopt. Bij buitenlandse fondsen worden de verkoopkosten vaak lager naar mate een belegger langer in een fonds heeft gezeten, na drie of vijf jaar zijn de kosten vaak nul. Deze kostenstructuur moet beleggers ervan weerhouden om te snel hun middelen weer uit de fondsen te halen.' }
,
{ name: 'Vermogensbeheer',
  definition: 'Vermogensbeheer, ook wel asset management is de algemene term voor het beheren van een portefeuille van een groep assets, zoals aandelen, obligaties of kas.' }
,
{ name: 'Vermogensstructuur',
  definition: 'De vermogensstructuur is de wijze waarop een onderneming is gefinancierd door investeerders. De meest basale kwestie bij het aantrekken van vermogen is de keuze tussen eigen vermogen (aandelen) of vreemd vermogen (obligaties). De verhouding tussen vreemd vermogen en het totale vermogen, de debt ratio, is het meeste gebruikte kengetal voor het weergeven van de vermogensstructuur.' }
,
{ name: 'Vlaggenschip',
  definition: 'Het vlaggenschip is meestal het oudste fonds van een fondsaanbieder of het fonds dat zeer veel vermogen heeft aangetrokken. Zulke fondsen dragen vaak de naam van de vermogensbeheerder.' }
,
{ name: 'Volatiliteit',
  definition: 'Volatiliteit is de geobserveerde mate van beweeglijkheid in de koers van een effect. De standaarddeviatie is de meest gebruikte maatstaf om volatiliteit te meten. Volatiliteit en standaarddeviatie worden gebruikt als indicatie van het risico van een belegging. (Zie Standaarddeviatie.)' }
,
{ name: 'Voortschrijdende rendementen',
  definition: 'Het voortschrijdend rendement geeft de beleggingsopbrengsten (total return) over recente periodes weer. Die periode kan een jaar, maand, week of dag zijn. Op 14 maart bijvoorbeeld beslaat het 1-jaars voortschrijdend rendement een periode van 15 maart 2000 tot 14 maart 2001. Het 1-week voortschrijdend rendement geeft op die dag de opbrengst over de periode van 8 tot 14 maart 2001 weer.' }
,
{ name: 'Waarde',
  definition: 'Waarde is een karakterisering voor een beleggingsaanpak waarbij de belegger ondergewaardeerde aandelen koopt.' }
,
{ name: 'Wereldwijd fonds',
  definition: 'Een wereldwijd fonds belegt in internationale aandelen of obligaties.' }
,
{ name: 'Winst',
  definition: 'De (netto)winst of earnings van een onderneming zijn het inkomen van een onderneming gedurende een bepaalde periode. De nettowinst wordt berekend door van de omzet, de operationele kosten, afschrijvingen, belasting en andere kosten af te trekken.' }
,
{ name: 'Winst per aandeel',
  definition: 'De Winst per Aandeel (WPA) of Earnings per Share (EPS) is de totale winst van een onderneming gedeeld door het aantal uitstaande aandelen. De WPA geeft de winstgevendheid van een onderneming weer, gezien door de ogen van een aandeelhouder. De WPA wordt gebruikt om de Koerswinstverhouding (K/W) of P/E te berekenen.' }
,
{ name: 'Year to Date',
  definition: 'Year to Date (YTD) betekent in het Nederlands: vanaf vorig jaareinde. Onder YTD vindt u dus het rendement van een beleggingsfonds vanaf de laatste jaarwisseling. Het geeft antwoord op de vraag hoe het fonds tot nu toe in het lopende jaar heeft gepresteerd.' }
,
{ name: 'Yield',
  definition: 'Yield is het uitbetaalde inkomen uit een belegging uitgedrukt als een percentage. De uitbetaalde inkomsten kunnen bestaan uit dividend of rente. In het geval van een uitgekeerd dividend, wordt er ook gesproken van dividendrendement. Yield moet niet worden verward met Total Return.' }
,
{ name: 'Yield curve',
  definition: 'De yield curve, of rentetermijnstructuur, is een grafiek die het verband weergeeft tussen de looptijd van een vastrentende belegging enerzijds en de daarop te ontvangen marktrente anderzijds. Een normale yield curve laat een stijgend verloop zien omdat de spaarrente doorgaans lager is dan de kapitaalmarktrente. Als iemand zijn geld voor een langere periode uitleent, eist hij een hogere vergoeding dan bij een lening over een korte termijn.' }
,
{ name: 'Yield to Maturity',
  definition: 'Yield to Maturity is het (effectief) rendement op een obligatiebelegging als deze tot het einde van de looptijd wordt aangehouden.' }
,
{ name: 'YTD',
  definition: 'YTD is de afkorting voor Year to Date en betekent in het Nederlands: vanaf vorig jaareinde. Onder YTD vindt u dus het rendement van een beleggingsfonds vanaf de laatste jaarwisseling. Het geeft antwoord op de vraag hoe het fonds tot nu toe in het lopende jaar heeft gepresteerd.' }
,
{ name: 'Zakelijke Dienstverlening',
  definition: 'Gedeelte van de portefeuille dat wordt belegd in adverteerders, drukkers, uitgevers, zakelijke ondersteuning, consultants, uitzendbureau’s, constructie, zakelijke bouw, afvalmanagement, distributie en transport. Voorbeelden zijn Bouygues, WPP en Easyjet.' }
,
{ name: 'Zero coupon obligatie',
  definition: 'Een zero coupon obligatie is een obligatie die wordt uitgegeven tegen een koers die ver onder het uiteindelijke aflossingsbedrag ligt doordat de obligatie geen couponnen (rente) uitbetaalt tijdens zijn looptijd. Een zero coupon obligatie kan bijvoorbeeld uitgegeven worden tegen EUR 900, terwijl de hoofdsom EUR 1000 is.' }

]

module.exports = terms;