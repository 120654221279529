// @ vendors
const tail = require('lodash/array/tail');
// @ helpers
const { formatText } = require('core/i18n').i18n;
// @ utilities
const { toCamelCase } = require('utilities/stringHelper');
// @ constants
const {
    CALL_TIME_OPTIONS_RIGHT_AWAY_ES,
    CALL_TIME_OPTIONS_WEEKLY_ES,
    CALL_TIME_OPTIONS_WEEKEND_ES
} = require('constants/index');
const RIGHT_AWAY = toCamelCase(CALL_TIME_OPTIONS_RIGHT_AWAY_ES);
const WEEKLY = toCamelCase(CALL_TIME_OPTIONS_WEEKLY_ES);
const WEEKEND = toCamelCase(CALL_TIME_OPTIONS_WEEKEND_ES);
const { TIME_OPTIONS_CALL_ME_BACK } = require('constants/timeOptionsCallMeBack');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');

const callTimeOptions = () => [{
    label: formatText('callmeback-callTimeRightAway'),
    labelKey: 'callmeback-callTimeRightAway',
    value: RIGHT_AWAY
},
{
    label: formatText('callmeback-callTimeWeekly'),
    labelKey: 'callmeback-callTimeWeekly',
    value: WEEKLY,
    disabled: true
},
{
    label: formatText('callmeback-callTimeWeekend'),
    labelKey: 'callmeback-callTimeWeekend',
    value: WEEKEND,
    disabled: true
}];

const defaultCallTimeValue = () => {
    return callTimeOptions()[0].value;
};

const formatCallTimeOptions = (formattedCallTimeOptions, value, country = DEFAULT_ORIGIN_COUNTRY) => {
    let options = (value === WEEKEND) ? tail(TIME_OPTIONS_CALL_ME_BACK[country]) : TIME_OPTIONS_CALL_ME_BACK[country];
    options && options.map((time) => {
        const newOption = {
            label: time,
            value:  `${value} ${time}`
        };

        formattedCallTimeOptions.push(newOption);
    });
};

const getCallTimeOptions = (callTimeOptionsArray, country) => {
    const formattedCallTimeOptions = [];

    callTimeOptionsArray.map((option) => {
        const value = option.value;

        formattedCallTimeOptions.push(option);

        if (value !== RIGHT_AWAY) {
            formatCallTimeOptions(formattedCallTimeOptions, value, country)
        }
    });

    return formattedCallTimeOptions;
};

const getBrokerCallTimeOptions = (country) => {
    let brokerCallTimeOptions = [callTimeOptions()[1]];
    return getCallTimeOptions(brokerCallTimeOptions, country);
};

const getPrivateCallTimeOptions = () => {
    let privateCallTimeOptions = [callTimeOptions()[1]];
    return getCallTimeOptions(privateCallTimeOptions);
};

module.exports = {
    defaultCallTimeValue,
    getBrokerCallTimeOptions,
    getPrivateCallTimeOptions
};
