const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../inputs');

function buildEmployed(prefix, authorized = false) {
    const inputsFioc = wizardComponentsHelper.replacePrefix(inputs.fioc, prefix);
    let grid = [[inputsFioc.profession]];
    if (!authorized) {
        grid = [
            ...grid,
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionIds: [`${prefix}fioc_profession_category`, `${prefix}fioc_profession`],
                conditionType: 'ifPrevIsComplete',
                item: inputsFioc.company_name
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'company_name',
                item: [inputsFioc.incomes_annual]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'incomes_annual',
                item: [
                    inputsFioc.capital_label,
                    inputsFioc.capital_work,
                    inputsFioc.capital_activities,
                    inputsFioc.capital_heritage,
                    inputsFioc.capital_business,
                    inputsFioc.capital_realState,
                    inputsFioc.capital_gambling,
                    inputsFioc.capital_other, {
                        id_component: 'condition-show',
                        id: 'show',
                        conditionType: 'single',
                        conditionId1: prefix + 'capital_other',
                        conditionValue1: true,
                        item: inputsFioc.capital_description
                    },
                    {
                        id_component: 'custom-error-message',
                        chekcIds: [
                            prefix + 'capital_work',
                            prefix + 'capital_activities',
                            prefix + 'capital_heritage',
                            prefix + 'capital_business',
                            prefix + 'capital_realState',
                            prefix + 'capital_gambling',
                            prefix + 'capital_other'
                        ],
                        msg_error: 'contractsView-listRequiredError'
                    }
                ]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionIds: [
                    prefix + 'capital_work',
                    prefix + 'capital_activities',
                    prefix + 'capital_heritage',
                    prefix + 'capital_business',
                    prefix + 'capital_realState',
                    prefix + 'capital_gambling',
                    prefix + 'capital_other'
                ],
                item: [inputsFioc.has_pep]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'single',
                conditionId1: prefix + 'has_pep',
                conditionValue1: 1,
                item:
                    [inputsFioc.has_pep_details]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'single',
                conditionId1: prefix + 'has_pep',
                conditionValue1: 1,
                item: [
                    inputsFioc.income_label,
                    inputsFioc.income_work,
                    inputsFioc.income_activities,
                    inputsFioc.income_business,
                    inputsFioc.income_realState,
                    inputsFioc.income_other,
                    {
                        id_component: 'condition-show',
                        id: 'show',
                        conditionType: 'single',
                        conditionId1: prefix + 'income_other',
                        conditionValue1: true,
                        item: inputsFioc.income_description
                    },
                    {
                        id_component: 'custom-error-message',
                        chekcIds: [
                            prefix + 'income_work',
                            prefix + 'income_activities',
                            prefix + 'income_business',
                            prefix + 'income_realState',
                            prefix + 'income_other'
                        ],
                        msg_error: 'contractsView-listRequiredError'
                    }
                ]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'has_pep',
                item: [inputsFioc.operationsLabel]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'operations_account',
                item: [inputsFioc.account_use_2]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'single',
                conditionId1: prefix + 'account_use_2',
                conditionValue1: 3,
                item: [
                    inputsFioc.account_use_operation_label,
                    inputsFioc.operations_dangerous_countries,
                    inputsFioc.checks_holder,
                    inputsFioc.checks_traveler,
                    inputsFioc.delivery_or_cash_withdrawals,
                    inputsFioc.buying_and_selling_foreing_banknotes,
                    inputsFioc.payment_orders_or_collection_abroad,
                    inputsFioc.foreing_trade_operations,
                    inputsFioc.none_of_the_above,
                    {
                        id_component: 'custom-error-message',
                        chekcIds: [
                            prefix + 'operations_dangerous_countries',
                            prefix + 'checks_holder',
                            prefix + 'checks_traveler',
                            prefix + 'delivery_or_cash_withdrawals',
                            prefix + 'buying_and_selling_foreing_banknotes',
                            prefix + 'payment_orders_or_collection_abroad',
                            prefix + 'foreing_trade_operations',
                            prefix + 'none_of_the_above'
                        ],
                        msg_error: 'contractsView-listRequiredError'
                    }
                ]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'and',
                conditionId1: prefix + 'account_use_2',
                conditionValue1: 3,
                conditionId2: prefix + 'has_pep',
                conditionValue2: 0,
                item: [
                    inputsFioc.income_label,
                    inputsFioc.income_work,
                    inputsFioc.income_activities,
                    inputsFioc.income_business,
                    inputsFioc.income_realState,
                    inputsFioc.income_other,
                    {
                        id_component: 'condition-show',
                        id: 'show',
                        conditionType: 'single',
                        conditionId1: prefix + 'income_other',
                        conditionValue1: true,
                        item: inputsFioc.income_description
                    },
                    {
                        id_component: 'custom-error-message',
                        chekcIds: [
                            prefix + 'income_work',
                            prefix + 'income_activities',
                            prefix + 'income_business',
                            prefix + 'income_realState',
                            prefix + 'income_other'
                        ],
                        msg_error: 'contractsView-listRequiredError'
                    }
                ]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'account_use_2',
                item: [inputsFioc.foreingTax]
            }]
        ];
    } else {
        grid = [
            ...grid,
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'fioc_sector',
                item: [
                    inputsFioc.profession_search,
                    inputsFioc.profession
                ]
            }],
            [{
                id_component: 'condition-show',
                id: 'show',
                conditionType: 'ifPrevIsComplete',
                conditionId1: prefix + 'fioc_profession',
                item: inputsFioc.company_name
            }]
        ];
    }

    return {
        id_component: 'condition-show',
        id: 'show',
        conditionType: 'single',
        conditionId1: prefix + 'fioc_ocupation',
        conditionValue1: 1,
        item: {
            id_component: 'fioc',
            id: prefix + 'fioc_employed',
            exclude: [],
            grid
        }
    };
}

module.exports = buildEmployed;