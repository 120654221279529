module.exports = {
    title: 'contractsView-contractsView_precontractualConditionsTitle',
    order: 2,
    footer_text: '',
    position: 'middle',
    disableNext: 'checkingPayrollAccount_nextButtonDisabled',
    on_next_click: 'checkingPayrollAccount_loadTermsAndConditionsLegalWindow',
    override_validation_step: 'legalWindow',
	components: [
        {
            id_component: 'step2-dic-viewer',
        }
	]
};