// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    success: false
});

function cardDownloadPdf(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARD_DOWNLOAD_PDF_RESET:
            return initialState;
        case actionTypes.CARD_DOWNLOAD_PDF_SUCCESS:
            return state.mergeDeep({
                success: true
            });
        case actionTypes.CARD_DOWNLOAD_PDF_FAILURE:
            return state.mergeDeep({
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = cardDownloadPdf;
