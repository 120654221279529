// @vendors
const Immutable = require('immutable');
const { get, isArray, isEmpty } = require('lodash');
// @constants
const actionTypes = require('constants/actionTypes');
const { PRODUCT_HIRING_BANNER_DETAILS } = require('constants/hiringProductBanners');
//@utilities
const { getFormattedProductBannerAdvantages } = require('utilities/cardsHelper');
//@helpers
const { nonNumericFilter } = require('utilities/stringHelper');

const initialState = Immutable.fromJS({
    isFetching: false,
    success: false,
    error: false,
    productBanners: [],
    isAccountBlockedValidationFetching: false,
    isAccountBlockedValidationSuccess: false,
    isAccountBlockedValidationError: false,
    accountBlockedErrorStatus: '',
    showErrorModal: false
});

function parseProductBannersList(state, response) {
    const productsList = get(response, 'products');
    const isCollectiveUser = get(response, 'isCollectives');

    if (productsList && isArray(productsList)) {
        const products = productsList.filter(product => !isEmpty(product));
        let productBanners = [];
        
        products.forEach(product => {
            const alias = product.alias || '';
            const productBannerContents = get(product,'cmsResponse.included');

            if (!isEmpty(productBannerContents)) {
                const productBannerDetails = PRODUCT_HIRING_BANNER_DETAILS[alias];
                const link = get(productBannerDetails, 'link') || '/myprofile';
                const sidebarImageDetails = productBannerDetails.sidebarImageDetails;
                const featureTogglingKey = productBannerDetails.featureTogglingKey;
                const featureTogglingFn = productBannerDetails.featureTogglingFn;
                const isCollectiveProduct = get(product, 'isCollectiveProduct') && isCollectiveUser;
                let title = '';
                let subtitle, codeType = '', sidebarImgUrl;
                let advantages = [];

                const productBanner = productBannerContents.find(res =>
                    res.type === "paragraph--oblea_product"
                );
                const productBannerAttributes = get(productBanner, 'attributes');
                subtitle = get(productBannerAttributes, 'field_ref_description.processed') || '';
                title = get(productBannerAttributes, 'field_text_title.processed') || '';
                
                //advantages list
                const advantagesList = productBannerContents.filter(function(res) {
                    return res.type === "ok_component--advantage";
                });
                advantages = getFormattedProductBannerAdvantages(advantagesList);

                //Side bar image url 
                const fileList = productBannerContents.find(function(res) {
                    return res.type === "file--image";
                });
                const fileURLAttributes = get(fileList, 'attributes.uri');
                if (fileURLAttributes && fileURLAttributes.url) {
                    const cmsBaseUrl = get(product,'cmsResponse.cmsBaseUrl');
                    sidebarImgUrl = `${cmsBaseUrl}${fileURLAttributes.url}`
                }

                if (product.type && product.subType) {
                    codeType = nonNumericFilter(product.type + product.subType)
                }
                productBanners.push({
                    alias,
                    title,
                    subtitle,
                    advantages,
                    type: product.type || '',
                    subType: product.subType || '',
                    standard: product.standard || '',
                    codeType,
                    link,
                    isCollectiveProduct,
                    sidebarImgUrl,
                    sidebarImageDetails,
                    featureTogglingKey,
                    featureTogglingFn
                });
            }
        });

        return state.merge({
            isFetching: false,
            success: true,
            error: false,
            productBanners
        });
    }
    return state;
}

function hiringProductBannersReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_HIRING_PRODUCT_BANNERS_REQUEST:
            return state.merge({
                isFetching : true,
                error : false
            });

        case actionTypes.FETCH_HIRING_PRODUCT_BANNERS_FAILURE:
            return state.merge({
                error : true,
                isFetching: false
            });

        case actionTypes.FETCH_HIRING_PRODUCT_BANNERS_SUCCESS:
            const response = action.payload.response;
            return parseProductBannersList(state, response)
        
        case actionTypes.FETCH_HIRING_PRODUCT_BANNERS_RESET:
            return initialState;

        case actionTypes.VALIDATE_BLOCKED_ACCOUNT_REQUEST:
            return state.merge({
                isAccountBlockedValidationFetching: true,
                isAccountBlockedValidationError: false,
                showErrorModal: false
            });
        case actionTypes.VALIDATE_BLOCKED_ACCOUNT_SUCCESS:
            return state.merge({
                isAccountBlockedValidationSuccess: true,
                isAccountBlockedValidationFetching: false,
                isAccountBlockedValidationError: false,
                showErrorModal: false
            });
        case actionTypes.VALIDATE_BLOCKED_ACCOUNT_FAILURE:
            return state.merge({
                accountBlockedErrorStatus: action.payload.status,
                isAccountBlockedValidationError: true,
                isAccountBlockedValidationFetching: false,
                isAccountBlockedValidationSuccess: false,
            });
        case actionTypes.SHOW_ACCOUNT_BLOCKED_ERROR_MODAL:
            return state.merge({
                showErrorModal: action.payload.showModal
            })
        default:
            return state;
    }
}

module.exports = hiringProductBannersReducer;

