// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const moment = require('moment');

// @ utilities
const { hash } = require('utilities/hash');
const { getPeriodicityText, getMonthsFromPeriodicityCode } = require('utilities/transfersHelper');

// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, TRANSFER_MAX_IMPORT, TRANSFER_MIN_IMPORT } = require('constants/index');

// @ contact-center
const { CC_PERIODICITY_CUSTOM, CC_PERIODICITY_RANGE_SPECIFICATION_DAY, CC_PERIODICITY_RANGE_SPECIFICATION_DAYS } = require('constants/contactCenter/transfers');


const initialState = Immutable.fromJS({
    filters: {
        amount: {
            isActive: false,
            from: {
                amount: null,
                currency: EURO_TEXT
            },
            to: {
                amount: null,
                currency: EURO_TEXT
            }
        },
        currency: {
            isActive: false,
            value: ''
        }
    },
    isRemoving: false,
    removeError: '',
    submitSuccessful: false
});

function parseTransfers(transfers, accountId) {
    let byId = {};
    let byOrder = [];

    transfers.forEach((movement) => {
        const parsedTransfer = {
            accountId,
            'concept': trim(movement.conceptoMP),
            'startDate': moment(movement.fechFinPerio),  // Isban is sending start and end date in the opposite fields
            'endDate': moment(movement.fechIniPerio),   // Isban is sending start and end date in the opposite fields
            'amount': {
                'amount': get(movement, 'importe.importe', 0),
                'currency': get(movement, 'importe.divisa', EURO_TEXT)
            },
            'isDeferred': trim(movement.descTipInst).toUpperCase() === 'TRANSFERENCIAS DIFERIDAS',
            'transferType': trim(movement.descTipInst),
            'periodicityText': getPeriodicityText(trim(get(movement, 'tipoPeriodicidad.codigoalfanum3', ''))),
            'emitter': {
                code: get(movement, 'personaTitular.codigodepersona', '') || '',
                type: get(movement, 'personaTitular.tipodepersona', '') || ''
            },
            'payer': {
                code: get(movement, 'personaOrdenante.codigodepersona', '') || '',
                type: get(movement, 'personaOrdenante.tipodepersona', '') || ''
            },
            'extra': {
                'numOrdCabMisPag': movement.numOrdCabMisPag,
                'product': get(movement, 'numOrdenDetalle.producto', '')
            }
        }

        parsedTransfer.id = hash([
            parsedTransfer.extra.numOrdCabMisPag,
            parsedTransfer.concept,
            parsedTransfer.startDate,
            parsedTransfer.endDate,
            parsedTransfer.transferType,
            parsedTransfer.amount.amount
        ])

        byId[parsedTransfer.id] = parsedTransfer;
        byOrder.push(parsedTransfer.id);
    });

    return {
        byId: byId,
        byOrder: byOrder
    };
}

function scheduledTransfersReducer(state = initialState, action) {
    let parsedTransfers;
    let accountId;

    switch (action.type) {
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {}
                }
            });
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_SUCCESS:
            accountId = action.payload.accountId;
            parsedTransfers = parseTransfers(action.payload.transfers, accountId);
            return state.set(accountId, Immutable.fromJS({
                nextPage: action.payload.nextPage,
                byOrder: parsedTransfers.byOrder,
                byId: parsedTransfers.byId,
                loading: false
            }));
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_NEXT_PAGE_SUCCESS:
            accountId = action.payload.accountId;
            const currentMovements = state.get(accountId);
            parsedTransfers = parseTransfers(action.payload.transfers, accountId);
            return state.mergeDeep({
                [accountId]: {
                    nextPage: action.payload.nextPage,
                    byOrder: currentMovements.get('byOrder').concat(Immutable.fromJS(parsedTransfers.byOrder)),
                    byId: parsedTransfers.byId,
                    loading: false,
                    error: false
                }
            });
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    loading: false,
                    error: action.payload.error
                }
            });
        case actionTypes.SET_SCHEDULED_TRANSFERS_FILTERS:
            const filters = action.payload.filters;
            // @TODO: we need to remove this hardcoded 0 and 9999999.99 and improve this logic
            const hasAmountFilters = (get(filters, 'amount.from.amount') !== null && get(filters, 'amount.from.amount') !== TRANSFER_MIN_IMPORT) ||
                (get(filters, 'amount.to.amount') !== null && get(filters, 'amount.to.amount') !== TRANSFER_MAX_IMPORT);
            const hasCurrencyFilter = !!get(filters, 'currency.value');
            const partial = state.mergeDeep({
                filters
            });

            return partial.mergeDeep({
                filters: {
                    amount: {
                        isActive: hasAmountFilters
                    },
                    currency: {
                        isActive: hasCurrencyFilter
                    }
                }
            });
        case actionTypes.RESET_SCHEDULED_TRANSFERS_FILTER:
            const { filter } = action.payload;
            return state.setIn(['filters', filter], initialState.getIn(['filters', filter]));
        case actionTypes.CLEAR_SCHEDULED_TRANSFERS_FILTERS:
            return state.mergeDeep({
                filters: initialState.get('filters')
            });
        case actionTypes.SET_REMOVE_SCHEDULED_TRANSFER_INITIAL:
            return state.mergeDeep({
                isRemoving: false,
                submitSuccessful: false,
                removeError: ''
            });
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_DETAILS_REQUEST:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                fetchingDetails: true
            });
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_DETAILS_FAILURE:
            return state.mergeDeepIn([action.payload.accountId, 'byId', action.payload.transferId], {
                error: action.payload.error,
                fetchingDetails: false
            });
        case actionTypes.FETCH_SCHEDULED_TRANSFERS_DETAILS_SUCCESS:
            accountId = action.payload.accountId;
            const { transferId, details } = action.payload
            const periodicity = get(details, 'idPeriodicidad.codigoalfanum3', '');
            return state.mergeDeepIn([accountId, 'byId', transferId], {
                details: {
                    periodicity,
                    amount: {
                        amount: get(details, 'importe.importe', 0),
                        currency: get(details, 'importe.divisa', EURO_TEXT)
                    },
                    name: trim(details.nombre),
                    periodicityText: getPeriodicityText(periodicity),
                    periodicityRangeQuantity: (getPeriodicityText(periodicity) === CC_PERIODICITY_CUSTOM) ? getMonthsFromPeriodicityCode(periodicity) : 1,
                    periodicityRangeSpecified: (periodicity === '200') ? CC_PERIODICITY_RANGE_SPECIFICATION_DAYS : CC_PERIODICITY_RANGE_SPECIFICATION_DAY,
                    originalNextExecutionDate: moment(details.fechaProximaEjecucion),
                    nextExecutionDate: details.fechaProximaEjecucion === '9999-12-31' ? moment(details.fechaInicioVigencia) : moment(details.fechaProximaEjecucion), // salvar el caso cdo viene una fecha erronea en fechaProximaEjecucion al momento de modificarla
                    beneficiaryIban: details.cuentaIBANBeneficiario,
                    operationType: get(details, 'tipoOperacion', {}),
                    beneficiary: get(details, 'actuante', {}),
                    beneficiaryFullIban: trim(get(details, 'cuentaIBANBeneficiario.pais')) + trim(get(details, 'cuentaIBANBeneficiario.digitodecontrol')) + trim(get(details, 'cuentaIBANBeneficiario.codbban')),
                    beneficiaryCountry: trim(get(details, 'cuentaIBANBeneficiario.pais')),
                    isResident: trim(details.indicadorResidenciaDestinatario),
                    treatmentNonWorkableDay: get(details, 'indTratamientoFechaEmis.codigoalfanum', '')
                },
                fetchingDetails: false
            });
        case actionTypes.REMOVE_SCHEDULED_TRANSFER_REQUEST:
            return state.mergeDeep({
                isRemoving: true
            });
        case actionTypes.REMOVE_SCHEDULED_TRANSFER_SUCCESS:
            return state.mergeDeep({
                isRemoving: false,
                submitSuccessful: true
            });
        case actionTypes.REMOVE_SCHEDULED_TRANSFER_FAILURE:
            return state.mergeDeep({
                isRemoving: false,
                removeError: action.payload.error
            });
        default:
            return state
    }

}

module.exports = scheduledTransfersReducer;
