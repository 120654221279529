const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    return Immutable.fromJS({
        getSavingsDetailFetch: false,
        savingsDetail: {},
        getSavingsDetailError: undefined,
        isRejectingSavingsRequest: false,
        submitSuccessful: false,
        isFetching: false,
        isFetchingOYAccounts: true,
        oyAccounts: [],
        oyAccountsError: undefined
    });
}
function openYoungCardSavings(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST:
            return state.merge({
                getSavingsDetailFetch: true,
                getSavingsDetailError: undefined,
                savingsDetail:{},
                isRejectingSavingsRequest: false,
                submitSuccessful: false,
                isFetching: true
            });
        case actionTypes.OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST_SUCCESS:
            return state.mergeDeep({
                getSavingsDetailFetch: false,
                getSavingsDetailError: undefined,
                savingsDetail: action.payload.moneySavingsDetail,
                isRejectingSavingsRequest: false,
                submitSuccessful: false,
                isFetching: false
            });
        case actionTypes.OPENYOUNG_CARD_MONEY_SAVINGS_DETAILS_REQUEST_ERROR:
            return state.merge({
                savingsDetail:{},
                getSavingsDetailFetch: false,
                getSavingsDetailError: action.payload.error || true,
                isRejectingSavingsRequest: false,
                submitSuccessful: false,
                isFetching: false
            });
        case actionTypes.OPENYOUNG_CARD_MONEY_SAVINGS_REJECT_REQUEST:
            return state.merge({
                isRejectingSavingsRequest: true,
                submitSuccessful: false,
                isFetching: true
            });
        case actionTypes.OPENYOUNG_CARD_GET_SAVINGS_REQUEST_CONFIRMATION_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isFetching: false
            });
        case actionTypes.OPENYOUNG_CARD_ACCOUNTS_REQUEST:
            return state.merge({
                isFetchingOYAccounts: true
            });
        case actionTypes.OPENYOUNG_CARD_ACCOUNTS_REQUEST_SUCCESS:
            return state.merge({
                isFetchingOYAccounts: false,
                oyAccounts: action.payload.oyAccountsList
            });
        case actionTypes.OPENYOUNG_CARD_ACCOUNTS_REQUEST_ERROR:
            return state.merge({
                isFetchingOYAccounts: false,
                oyAccountsError: action.payload.error || true
            });
        default:
            return state
    }
}
module.exports = openYoungCardSavings;