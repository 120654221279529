// @vendors
const Immutable = require('immutable');
// @reducers
const brokerTransferPensionPlansInternalStep1 = require('./brokerTransferPensionPlansInternal/brokerTransferPensionPlansInternalStep1');
const brokerTransferPensionPlansInternalStep2 = require('./brokerTransferPensionPlansInternal/brokerTransferPensionPlansInternalStep2');
const brokerTransferPensionPlansInternalStep3 = require('./brokerTransferPensionPlansInternal/brokerTransferPensionPlansInternalStep3');
const brokerTransferPensionPlansInternalStep4 = require('./brokerTransferPensionPlansInternal/brokerTransferPensionPlansInternalStep4');
// @constants
const actionTypes = require('constants/actionTypes');
const {
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    FOURTH_STEP,
    BROKER_TRANSFER_PLANS_PREDATED_FAIL_MSG,
    BROKER_TRANSFER_PLANS_GAP_FAIL_MSG,
    BROKER_TRANSFER_PLANS_BOLETIN_PREDATED_FAIL_MSG,
    BROKER_TRANSFER_PLANS_STORAGE_FAIL_MSG,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    WIZARD_THIRD_STEP,
    WIZARD_FOURTH_STEP
} = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerTransferPensionPlansInternalStep1(undefined, { type: null }),
            brokerTransferPensionPlansInternalStep2(undefined, { type: null }),
            brokerTransferPensionPlansInternalStep3(undefined, { type: null }),
            brokerTransferPensionPlansInternalStep4(undefined, { type: null })
        ],
        visibleStep: WIZARD_FIRST_STEP,
        submitSuccessful: false,
        valid: true,
        modalOpen: false,
        walletPlansData: {},
        isFetching: false,
        success: false,
        error: false,
        willLeave: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerTransferPensionPlansInternalStep1(step, action);
            case SECOND_STEP:
                return brokerTransferPensionPlansInternalStep2(step, action);
            case THIRD_STEP:
                return brokerTransferPensionPlansInternalStep3(step, action);
            case FOURTH_STEP:
                return brokerTransferPensionPlansInternalStep4(step, action);
        }
    });
}

function keyMessageSelector(code) {
    switch (code) {
        case BROKER_TRANSFER_PLANS_PREDATED_FAIL_MSG:
            return "brokerTraspassBetweenMyPlans-brokerTraspassBetweenMyPlans_documentPredatedFail"
        case BROKER_TRANSFER_PLANS_GAP_FAIL_MSG:
            return "brokerTraspassBetweenMyPlans-brokerTraspassBetweenMyPlans_gapFail"
        case BROKER_TRANSFER_PLANS_BOLETIN_PREDATED_FAIL_MSG:
            return "brokerTraspassBetweenMyPlans-brokerTraspassBetweenMyPlans_boletinPredatedFail"
        case BROKER_TRANSFER_PLANS_STORAGE_FAIL_MSG: 
            return "brokerTraspassBetweenMyPlans-brokerTraspassBetweenMyPlans_storageFail"
        default: 
            return "brokerTraspassBetweenMyPlans-brokerTraspassBetweenMyPlans_errorPlansDataNotFound"
    }
}

function getCurrentStep(immState) {
    switch (immState.get('visibleStep')){
        case WIZARD_FIRST_STEP:
            return FIRST_STEP;
        case WIZARD_SECOND_STEP:
            return SECOND_STEP;
        case WIZARD_THIRD_STEP:
            return THIRD_STEP;
        case WIZARD_FOURTH_STEP:
            return FOURTH_STEP;
        default:
            return FIRST_STEP;
    }
}

function getPlanAssociatedAccount(immAccounts, immPlan) {
    const accountContractNumber = immPlan.get('associatedAccountContractNumber')? immPlan.get('associatedAccountContractNumber').substring(3) : '';
    const accountProductNumber = immPlan.get('associatedAccountContractNumber') ? immPlan.get('associatedAccountContractNumber').substring(0, 3) : '';
    return immAccounts.get('byId').toList().find(immAccount => 
        immAccount.get('product') === accountProductNumber &&
        immAccount.get('contractNumber') === accountContractNumber
    );
}

function getPlanComercialData(comercialData, immPlan) {
    const customInstitutionSecurityId = immPlan.get('customInstitutionSecurityId');

    return comercialData.find(plan => 
        plan.CustomInstitutionSecurityId === customInstitutionSecurityId
    );
}

function mapPlanData(payload) {
    const walletPlansData = payload.immPlans.map(immPlan => {
        const immAccount = getPlanAssociatedAccount(payload.immAccounts, immPlan);
        const planComercialData = getPlanComercialData(payload.comercialData, immPlan);  
        if(immAccount) {
            return Immutable.fromJS({
                internalId: immPlan.get('id'),
                searchId: immPlan.get('customInstitutionSecurityId'),
                productId: immPlan.get('product'),
                contractNumber: immPlan.get('contractNumber'),
                controlDigit: immPlan.get('controlDigit'),
                fullContractNumber: `${API_ENTERPRISE_NUMBER}${API_CENTER}${immPlan.get('controlDigit')}${immPlan.get('product')}${immPlan.get('contractNumber')}`,
                name: immPlan.get('name'),
                owner: payload.immProfile.get('nombreCompleto'),
                totalValueEur: immPlan.get('totalValueEur').toJS(),
                shares: immPlan.get('shares'),
                associatedAccount: {
                    productId: immAccount.get('product'),
                    contractNumber: immAccount.get('contractNumber'),
                    controlDigit: immAccount.getIn(['ibanComplex', 'digitodecontrol']),
                    fullAccountNumber: `${API_ENTERPRISE_NUMBER}${API_CENTER}${immAccount.getIn(['ibanComplex', 'digitodecontrol'])}${immAccount.get('product')}${immAccount.get('contractNumber')}`
                },
                associatedAccountBalance: immAccount.getIn(['balances', 'availableBalance']).toJS(),
                comercialData: Immutable.fromJS(planComercialData)
            })
        }
    }).filter(walletPlan => walletPlan);

    return walletPlansData;
}

const internalTransferPensionPlan = (state = setInitialState(), action) => {
    let partialSteps;
    let visibleStep;
    let currentStep;
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_RESET:
            return setInitialState();
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_VISIBLE_STEP:
            switch (state.get('visibleStep')) {
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_FIRST_STEP;
                    break;
                case WIZARD_THIRD_STEP:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_SECOND_STEP;
                    break;
                case WIZARD_FOURTH_STEP:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_THIRD_STEP;
            }    
            return state.merge({
                steps: partialSteps,
                visibleStep: visibleStep 
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_VALIDATE_STEP:
            switch (state.get('visibleStep')) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                    break;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([SECOND_STEP, 'valid']) ? WIZARD_THIRD_STEP : WIZARD_SECOND_STEP;
                    break;
                case WIZARD_THIRD_STEP:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([THIRD_STEP, 'valid']) ? WIZARD_FOURTH_STEP : WIZARD_THIRD_STEP;
                    break;
                case WIZARD_FOURTH_STEP:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_FOURTH_STEP;
                    break;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_ORIGIN:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECT_DESTINATION:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_FAILURE:
            currentStep = getCurrentStep(state);
            return state.merge({
                steps: updateStep(currentStep, state.get('steps'), action),
                error: true
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_DATA_REQUEST:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_SUCCESS:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_NOT_FOUND:
            currentStep = getCurrentStep(state);
            return state.merge({
                steps: updateStep(currentStep, state.get('steps'), action)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_TRANSFER_TYPE_OPTION:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_FISCALIZATION_OPTION:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_AMOUNT:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SET_AMOUNT_RANGE:
            return state.merge({
                steps: updateStep(THIRD_STEP, state.get('steps'), action)
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_CHANGE_DESTINATION_OPTION:
        case actionTypes.BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_SELECTED_IN_SEARCHER_SUCCESS:
        case actionTypes.BROKER_TRANSFER_PENSION_PLAN_INTERNAL_ASSOCIATE_ACCOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_UPDATE_DESTINATION_PLANS:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_REQUEST:
            return state.merge({
                isFetching: true,
                success: false,
                error: false
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_SUCCESS:
            return state.merge({
                isFetching: false,
                success: true,
                walletPlansData: mapPlanData(action.payload)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_FETCH_ALL_PLANS_FAILURE:
            return state.merge({
                isFetching: false,
                error: true 
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_WILL_CANCEL:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_WILL_LEAVE:
            return state.merge({
                willLeave: true
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_DISCLAIMER_REQUIRED:
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_DISCLAIMER_TOGGLE:
        case actionTypes.SIGNATURE_PATTERN_UPDATE_INPUT_SMS:
            return state.merge({
                steps: updateStep(FOURTH_STEP, state.get('steps'), action)
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_REQUEST:
            return state.merge({
                isFetching: true,
                error: false,
                submitSuccessful: false
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_FAILURE:
            return state.merge({
                isFetching: false,
                error: true,
                errorMsg: action.payload.response.errorCode ? keyMessageSelector(action.payload.response.errorCode):keyMessageSelector()
            })
        case actionTypes.BROKER_WIZARD_TRANSFER_PENSION_PLANS_INTERNAL_PDF_SERVICE_SUCCESS:
            const error = !action.payload.response.success;
            return state.merge({
                isFetching: false,
                error: error,
                errorMsg: error ? keyMessageSelector(action.payload.response.errorCode):'',
                submitSuccessful: action.payload.response.success
            })
        default:
            return state;
    }
}

module.exports = internalTransferPensionPlan;