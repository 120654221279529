module.exports = {
    id_component: 'tab-form',
    label: 'Datos de residencia',
    id:'tab-form-residencia',
    exclude: [],
    grid: [
        [
            {
                id_component: 'combo',
                id: 'cmb_tipo_via',
                label: 'Tipo de vía',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbTypeOfRoad',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_address_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-nombre-via',
                label: 'Nombre de vía',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_address_data'
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-numero',
                label: 'Número',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_address_data'
            }
        ],
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-otros-datos',
                label: 'Otros datos (opcional)',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_address_data'
            },
            {
                id_component: 'input-safe',
                id: 'input-codigo-postal',
                label: 'Código Postal',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_address_data'
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_pais',
                label: 'País',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbPais',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_address_data',
                options: [],
                order: 3,
                msg_error:''
            },
            {
                id_component: 'combo',
                id: 'cmb_provincia',
                label: 'Provincia',
                type: 'form',
                placeholder: 'Seleccione una opción ...',
                source: 'personalLoan_loadCmbProvince',
                onChangeFn: 'personalLoan_onChangeCmbPurpose',
                target: 'personal_form_address_data',
                options: [],
                order: 3,
                msg_error: ''
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-poblacion',
                label: 'Población',
                type_format: 'text',
                type: 'single',
                onBlurFn: 'personalLoan_handleBlur',
                target: 'personal_form_address_data'
            }
        ]
    ]
};
