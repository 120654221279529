module.exports = {
    title: __CONTACT_CENTER__ ? 'contractsView-contractsView_depositNMStep1TitleCC' : 'contractsView-depositNMStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        ...(
            __CONTACT_CENTER__ ?
            [{
                id_component: 'contract-info',
                id: 'text-info',
                cms_key: 'depositnm_cc',
            }] : [{
                id_component: 'contract-info',
                id: 'text-info',
                cms_key: 'depositnm',
            }]
        )
    ]
};
