// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    mailSent: false,
});

function sendPrecontractualDocumentMail(state = initialState, action) {
   switch (action.type) {
       case actionTypes.SEND_PRECONTRACTUAL_DOCUMENT_MAIL_REQUEST:
            return state.merge({
                error: '',
                isFetching: true
            });
        case actionTypes.SEND_PRECONTRACTUAL_DOCUMENT_MAIL_SUCCESS:
            return state.merge({
                isFetching:false,
                mailSent: true
            });
        case actionTypes.SEND_PRECONTRACTUAL_DOCUMENT_MAIL_ERROR:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
                mailSent: false
            });
        case actionTypes.SEND_PRECONTRACTUAL_DOCUMENT_MAIL_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = sendPrecontractualDocumentMail;
