// @vendors
const Immutable = require('immutable');
// @helpers
const get = require('lodash/object/get');
// @ utilities
const { formatText } = require('core/i18n').i18n;
// @constants
const actionTypes = require('constants/actionTypes');
const {
    DOCUMENT_REQUIRED_NOT_SENT,
    DOCUMENT_TYPE_DNI,
    DOCUMENT_TYPE_DNI_VALUES,
    DOCUMENT_TYPE_NIE_NOT_UE,
    DOCUMENT_TYPE_NIE_UE,
    DOCUMENT_TYPE_NIE_VALUES,
    DOCUMENT_TYPE_PASSPORT,
    DOCUMENT_TYPE_PASSPORT_VALUES,
} = require('constants/accountAddDocumentation');

const {
    ONLY_LETTERS_ACCENTS_NUMBERS_SPACES_GUIDES_REGEX,
    REGEX_REMOVE_SPECIAL_SYMBOLS,
} = require('constants/index');

const initialState = Immutable.fromJS({
    accountId: '',
    accountNumber: '',
    alias: '',
    canUploadDocumentation: false,
    documentTypeForm: '',
    error: '',
    errorTramitationFlags: false,
    expedientNumber: '',
    fetchSuccess: false,
    files: {
        daal: {
            back: {
                error: false,
                file: '',
                name: '',
            },
            comments: '',
            done: false,
            error: '',
            flag: 'daal',
            front: {
                error: false,
                file: '',
                name: '',
            },
            isVisible: false,
        },
        document: {
            back: {
                error: false,
                file: '',
                name: '',
            },
            comments: '',
            done: false,
            error: '',
            flag: 'documento',
            front: {
                error: false,
                file: '',
                name: '',
            },
            isVisible: false,
        },
        receipt: {
            back: {
                error: false,
                file: '',
                name: '',
            },
            comments: '',
            done: false,
            error: '',
            flag: 'recibo',
            front: {
                error: false,
                file: '',
                name: '',
            },
            isVisible: false,
        },
    },
    hasOneHolder: false,
    inProgress: false,
    isFetching: false,
    subtype: '',
    uploadSuccess: false,
});

const buildValidName = (fullName, receiptDefaultName) => {
    let fileName = fullName.split('.')[0];
    const validateText = ONLY_LETTERS_ACCENTS_NUMBERS_SPACES_GUIDES_REGEX.test(fileName);
    if (!validateText) {
        const validName = fileName.replace(REGEX_REMOVE_SPECIAL_SYMBOLS, '');
        fileName = validName !== '' ? validName : receiptDefaultName;
    }
    return fileName;
}

function accountDocumentationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_RESET:
            return initialState;
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_REQUEST:
            return state.merge({
                canUploadDocumentation: false,
                error: '',
                fetchSuccess: false,
                isFetching: true,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_SUCCESS:
            const {
                response,
                userIsFromUE,
            } = action.payload;

            const documentTypeValue = get(response, 'documentacionIntervinienteList[0].personaDocumento.tipo', '');
            const isDocumentVisible = get(response, 'documentacionIntervinienteList[0].documento', '') === DOCUMENT_REQUIRED_NOT_SENT;
            const isDaalVisible = get(response, 'documentacionIntervinienteList[0].daal', '') === DOCUMENT_REQUIRED_NOT_SENT;
            const isReceiptVisible = get(response, 'documentacionIntervinienteList[0].recibo', '') === DOCUMENT_REQUIRED_NOT_SENT;
            let documentTypeForm = '';
            
            if (DOCUMENT_TYPE_DNI_VALUES.includes(documentTypeValue)) {
                documentTypeForm = DOCUMENT_TYPE_DNI;
            } else if (DOCUMENT_TYPE_PASSPORT_VALUES.includes(documentTypeValue)) {
                documentTypeForm = DOCUMENT_TYPE_PASSPORT;
            } else if (documentTypeValue === DOCUMENT_TYPE_NIE_VALUES && userIsFromUE) {
                documentTypeForm = DOCUMENT_TYPE_NIE_UE;
            } else if (documentTypeValue === DOCUMENT_TYPE_NIE_VALUES) {
                documentTypeForm = DOCUMENT_TYPE_NIE_NOT_UE;
            }

            return state.mergeDeep({
                canUploadDocumentation: isDaalVisible || isDocumentVisible || isReceiptVisible,
                documentTypeForm,
                fetchSuccess: true,
                files: {
                    daal: {
                        back: {
                            error: false,
                            file: '',
                            name: '',
                        },
                        front: {
                            error: false,
                            file: '',
                            name: '',
                        },
                        comments: '',
                        error: '',
                        flag: 'daal',
                        isVisible: isDaalVisible,
                    },
                    document: {
                        back: {
                            error: false,
                            file: '',
                            name: '',
                        },
                        comments: '',
                        error: '',
                        flag: 'documento',
                        front: {
                            error: false,
                            file: '',
                            name: '',
                        },
                        isVisible: isDocumentVisible,
                    },
                    receipt: {
                        back: {
                            error: false,
                            file: '',
                            name: '',
                        },
                        comments: '',
                        error: '',
                        flag: 'recibo',
                        front: {
                            error: false,
                            file: '',
                            name: '',
                        },
                        isVisible: isReceiptVisible,
                    },
                },
                isFetching: false,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_GET_TRAMITATION_EXPEDIENT_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_REMOVE_FILE:
            const immFile = state.getIn(['files', action.payload.documentName]);
            let filesState = {
                [action.payload.side]: {
                    error: false,
                    file: '',
                    name: '',
                }
            };
            
            // When user removes front and there is a back image, this back image will be the new front
            if (action.payload.side === 'front' && action.payload.documentName !== 'document' && 
                (!!immFile.getIn(['back', 'error']) || !!immFile.getIn(['back', 'file']))) {
                filesState = {
                    back: {
                        error: false,
                        file: '',
                        name: '',
                    },
                    front: {
                        error: immFile.getIn(['back', 'error']),
                        file: immFile.getIn(['back', 'file']),
                        name: immFile.getIn(['back', 'name']),
                    },
                };
            }

            return state.mergeDeep({
                files: {
                    [action.payload.documentName]: filesState,
                }
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_ADD_FILE:
            const { fileInfo } = action.payload;
            const fileBase64 = `${get(fileInfo, 'base64', '').split('base64,')[1]}`;
            const name = buildValidName(get(fileInfo, 'file.name', '')) || formatText('accountUploadDocumentation-accountUploadDocumentation_defaultNameFileImage');
            return state.mergeDeep({
                files: {
                    [action.payload.documentName]: {
                        [action.payload.side]: {
                            error: false,
                            file: fileBase64,
                            name,
                        }
                    }
                }
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_CHANGE_NAME:
            return state.mergeDeep({
                files: {
                    [action.payload.documentName]: {
                        [action.payload.side]: {
                            name: action.payload.name || formatText('accountUploadDocumentation-accountUploadDocumentation_defaultNameFileImage'),
                        }
                    }
                }
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_UPLOAD_FAILURE:
            return state.merge({
                error: action.payload.error,
                inProgress: false,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_UPLOAD_REQUEST:
            return state.merge({
                error: '',
                errorTramitationFlags: false,
                inProgress: true,
                uploadSuccess: false,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_UPLOAD_SUCCESS:
            return state.merge({
                inProgress: false,
                uploadSuccess: true,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_RETRY_UPLOAD:
            return state.mergeDeep({
                error: '',
                errorTramitationFlags: false,
                files: {
                    daal: {
                        back: {
                            error : false,
                        },
                        front: {
                            error : false,
                        },
                    },
                    document: {
                        back: {
                            error : false,
                        },
                        front: {
                            error : false,
                        },
                    },
                    receipt: {
                        back: {
                            error : false,
                        },
                        front: {
                            error : false,
                        },
                    },
                },
                uploadSuccess: false,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_SET_DONE_FILES:
            return state.mergeDeep({
                files: {
                    [action.payload.documentName]: {
                        done: true,
                    }
                }
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_SET_ACCOUNT_INFO:
            return state.merge({
                accountId: action.payload.accountId,
                accountNumber: action.payload.accountNumber,
                alias: action.payload.alias,
                expedientNumber: action.payload.expedientNumber,
                hasOneHolder: action.payload.hasOneHolder,
                subtype: action.payload.subtype,
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_SET_ERROR_FILE:
            let errorFile = {};

            if (!!action.payload.side) {
                errorFile[action.payload.side] = {
                    error: true,
                    file: '',
                    name: '',
                }
            } else {
                errorFile.error = true;
            }
            return state.mergeDeep({
                files: {
                    [action.payload.documentName]: errorFile,
                }
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_CHANGE_FILE_NAME:
            return state.mergeDeep({
                files: {
                    [action.payload.documentName]: {
                        [action.payload.side]: {
                            name: action.payload.name,
                        }
                    }
                }
            });
        case actionTypes.ACCOUNT_ADD_DOCUMENTATION_TRAMITATION_FLAG_FAILURE:
            return state.merge({
                error: true,
                errorTramitationFlags: true,
                inProgress: false,
            });
        default:
            return state;
    }
}

module.exports = accountDocumentationReducer;
