// @vendor
import { fromJS } from 'immutable';

// @core
const { formatText, getLanguage } = require('core/i18n').i18n;

// @utilities
import { getFormattedString } from 'utilities/currencyHelper';

// @constants
const {
    INIT_FILTER,
    INIT_CHECK_FILTER,
    INIT_DATE_FILTER,
    INIT_SELECT_FILTER,
    INIT_AMOUNT_FILTER,
    REMOVE_FILTER,
    REMOVE_FILTER_TAG,
    REMOVE_CHECK_FILTER,
    REMOVE_DATE_FILTER,
    REMOVE_SELECT_FILTER,
    REMOVE_AMOUNT_FILTER,
    SET_APPLIED,
    SET_CHECK_FILTER,
    SET_DATE_FILTER,
    SET_FROM_DATE_FILTER,
    SET_TO_DATE_FILTER,
    SET_SELECT_FILTER,
    SET_MAX_AMOUNT_FILTER,
    SET_MIN_AMOUNT_FILTER
} = require('constants/actionTypes');
const { DATE_PICKER_CUSTOM_DATE_FORMAT } = require('constants/commonsComponents');


const initialState = fromJS({
});

const getMultiTagFilter = (filter) => {
    if (!filter.get('active') || !filter.get('isTaggable')) {
        return {
            filterTagFrom: undefined,
            filterTagTo: undefined
        }
    }

    if (filter.get('filterType') == 'dateFilter')
        return {
            filterTagFrom: filter.get('from').locale(getLanguage()).format(formatText(DATE_PICKER_CUSTOM_DATE_FORMAT)),
            filterTagTo: filter.get('to').locale(getLanguage()).format(formatText(DATE_PICKER_CUSTOM_DATE_FORMAT))
        }
    if (filter.get('filterType') == 'amountFilter')
        return {
            filterTagFrom: getFormattedString({amount: filter.get('from') || 0, currency: filter.get('currency')}),
            filterTagTo: filter.get('to') && getFormattedString({amount: filter.get('to'), currency: filter.get('currency')})
        }
    return {}
}

const setFilterTags = (filters) => {
    return filters.reduce((acc, filter) => {
        const label = filter.get('label');
        return {
            ...acc,
            [filter.get('filterId')]: {
                ...filter.toJS(),
                filterTag: filter.get('active') && filter.get('label') && filter.get('isTaggable') ? label : undefined,
                ...(getMultiTagFilter(filter))
            }
        }
    }, {})
}

const filters = (state = initialState, action) => {
    switch (action.type) {
        case INIT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
            }
        });
        case INIT_CHECK_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        filterId: action.filterId,
                        active: action.active,
                        filterType: action.filterType,
                        label: action.label,
                        value: action.value,
                        removedFromTag: false,
                        isTaggable: action.isTaggable
                    }
                }
            });
        case INIT_SELECT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        filterId: action.filterId,
                        filterType: action.filterType,
                        active: action.active,
                        selected: action.selected,
                        initialSelected: action.initialSelected,
                        label: action.label,
                        filterTag: action.filterTag,
                        removedFromTag: false,
                        isTaggable: action.isTaggable
                    }
                }
            });
        case INIT_DATE_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        filterId: action.filterId,
                        filterType: action.filterType,
                        active: action.active,
                        selected: action.selected,
                        from: action.from,
                        to: action.to,
                        removedFromTag: false,
                        isTaggable: action.isTaggable
                    }
                }
            });
        case INIT_AMOUNT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        filterId: action.filterId,
                        filterType: action.filterType,
                        active: action.active,
                        filterTagFrom: action.filterTagFrom,
                        filterTagTo: action.filterTagTo,
                        currency: action.currency,
                        removedFromTag: false,
                        isTaggable: action.isTaggable
                    }
                }
            });
        case REMOVE_FILTER:
            return initialState;
        case REMOVE_CHECK_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: false,
                        filterTag: undefined,
                        removedFromTag: true
                    }
                }
            });
        case REMOVE_DATE_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: false,
                        filterTagFrom: undefined,
                        filterTagTo: undefined,
                        from: null,
                        to: null,
                        selected: "None",
                        removedFromTag: true
                    }
                }
            });
        case REMOVE_FILTER_TAG:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        removedFromTag: false
                    }
                }
            });
        case REMOVE_SELECT_FILTER:
            const initialSelected = state.getIn([action.filtersId, action.filterId, 'initialSelected']);
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: false,
                        filterTag: undefined,
                        selected: initialSelected,
                        removedFromTag: true
                    }
                }
            });
        case REMOVE_AMOUNT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: false,
                        filterTag: undefined,
                        filterTagFrom: undefined,
                        filterTagTo: undefined,
                        from: null,
                        to: null,
                        removedFromTag: true
                    }
                }
            });
        case SET_APPLIED:
            return state.mergeDeep({
                [action.filtersId]: setFilterTags(state.get(action.filtersId))
            })
        case SET_CHECK_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        filterId: action.filterId,
                        active: action.active,
                        removedFromTag: false
                    }
                }
            });
        case SET_DATE_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: action.active,
                        selected: action.selected,
                        from: action.from,
                        to: action.to,
                        removedFromTag: false
                    }
                }
            });
        case SET_FROM_DATE_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: action.active,
                        from: action.from
                    }
                }
            });
        case SET_TO_DATE_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: action.active,
                        to: action.to
                    }
                }
            });
        case SET_SELECT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        active: action.active,
                        selected: action.selected,
                        label: action.label,
                        removedFromTag: false
                    }
                }
            });
        case SET_MAX_AMOUNT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        to: action.value,
                        active: state.getIn([action.filtersId, action.filterId, 'from'], false) || action.value,
                        removedFromTag: false
                    }
                }
            });
        case SET_MIN_AMOUNT_FILTER:
            return state.mergeDeep({
                [action.filtersId]: {
                    [action.filterId]: {
                        from: action.value,
                        active: state.getIn([action.filtersId, action.filterId, 'to'], false) || action.value,
                        removedFromTag: false
                    }
                }
            });
        default:
            return state;
    }
}

export default filters;