// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = () => {
    return Immutable.fromJS({
        isLoading: false,
        bankName: '',
        lastBankName: '',
        fetchSuccess: false,
        error: ''
    });
};

const bankEntity = (state = initialState(), action) => {

    switch (action.type) {

        case actionTypes.FETCH_BANK_ENTITY_REQUEST:
            return state.merge({
                bankName: '',
                error: '',
                fetchSuccess: false,
                isLoading: true,
            });

        case actionTypes.FETCH_BANK_ENTITY_SUCCESS:
            const { bankName, lastBankName } = action.payload;
            return state.merge({
                bankName,
                lastBankName,
                fetchSuccess: true,
                isLoading: false
            });

        case actionTypes.FETCH_BANK_ENTITY_FAILURE:
            const { error } = action.payload;
            return state.merge({
                error,
                isLoading: false
            });

        case actionTypes.TRANSFER_BENEFICIARY_CANCEL_EDIT_FORM:
            return state.merge({
                bankName: state.get('lastBankName'),
                lastBankName: ''
            })
        case actionTypes.FETCH_BANK_ENTITY_RESET:
            return initialState();

        default:
            return state;
    }

};

module.exports = bankEntity;
