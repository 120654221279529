const Immutable = require('immutable');
import moment from 'moment';

const {
    HISTOGRAM_FETCHING,
    HISTOGRAM_SUCCESS,
    HISTOGRAM_FAILURE,
    HISTOGRAM_COMPARE_FETCHING,
    HISTOGRAM_COMPARE_FAILURE,
    HISTOGRAM_COMPARE_SUCCESS,
    HISTOGRAM_COMPARE_RESET
} = require('constants/actionTypes');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            error: false,
            fetching: false,
            errorCompare: false,
            fetchingCompare: false,
            histogramData: [],
            chartData: [],
            chartDataCompare: null
        })
    );
};

const geArrayDates = (to, from) => {
    const diffDates = to.diff(from, 'months', true);
    const arrayDates = [];
    const isMonth = moment(from).diff(to, 'months') === 0;

    if (!isMonth) {
        for (let i = 0; i <= diffDates; i++) {
            if (i === 0) {
                arrayDates.push(moment(from).format('YYYY-MM'));
            } else {
                arrayDates.push(moment(from.add(1, 'month')).format('YYYY-MM'));
            }
        }
    } else {
        const diffDays = to.diff(from, 'days', true);

        for(let i = 0; i <= diffDays; i++) {
            if (i === 0) {
                arrayDates.push(moment(from).format('YYYY-MM-DD'));
            } else {
                arrayDates.push(moment(from.add(1, 'day')).format('YYYY-MM-DD'));
            }
        }
    }

    return arrayDates;
}

const getChartData = (histogramData = {}, filters, isCompare = false) => {
    const { isCreditFilterSelected, isDebitFilterSelected } = filters;
    const expensesByMonth = {...histogramData.subgroupMap} || [];
    const DEBIT = 0, CREDIT = 1;
    let debitExpense, creditExpense;
    const isDayChart = moment.duration(moment(filters.toDate).diff(moment(filters.fromDate))).asDays() <= 31 && 
    moment(filters.toDate).diff(moment(filters.fromDate), 'months') < 1 
    && moment(filters.toDate).format('MM-YYYY') === moment(filters.fromDate).format('MM-YYYY');
    const arrayDatesMonths = geArrayDates(
        isCompare ? moment(filters.toDate).subtract(1, 'year') : moment(filters.toDate), 
        isCompare ? moment(filters.fromDate).subtract(1, 'year') : moment(filters.fromDate),
        isCompare
    );

    const dataChartAmounts = arrayDatesMonths.map((month) => {
        if(expensesByMonth[month]) {
            debitExpense = expensesByMonth[month].subgroupMap[DEBIT];
            creditExpense = expensesByMonth[month].subgroupMap[CREDIT];

            if (!isCompare) {
                return {
                    debitAmount: debitExpense && isDebitFilterSelected ? Math.abs(debitExpense.total) : 0,
                    creditAmount: creditExpense && isCreditFilterSelected ? Math.abs(creditExpense.total) : 0,
                    date: moment(month).format(isDayChart ? 'D' : 'MMM'),
                    tooltipDate: moment(month).format(isDayChart ? 'DD/MMMM' : 'MMMM YYYY')
                }
            } else {
                return {
                    debitAmountCompare: debitExpense && isDebitFilterSelected ? Math.abs(debitExpense.total) : 0,
                    creditAmountCompare: creditExpense && isCreditFilterSelected ? Math.abs(creditExpense.total) : 0,
                    dateCompare: moment(month).format(isDayChart ? 'D' : 'MMM'),
                    tooltipDate: moment(month).format(isDayChart ? 'DD/MMMM' : 'MMMM YYYY')
                }
            }
        } else {
            return {
                date: moment(month).format(isDayChart ? 'D' : 'MMM'),
                tooltipDate: moment(month).format(isDayChart ? 'DD/MMMM' : 'MMMM YYYY')
            }
        }
    });
        
    return dataChartAmounts.filter(Boolean);
}

function expensesByCategoriesMovements(state = setInitialState(), action) {
    switch (action.type) {
        case HISTOGRAM_FETCHING:
            return state.merge({
                error: false,
                fetching: true
            });

        case HISTOGRAM_FAILURE:
            return state.merge({
                error: true,
                fetching: false
            });

        case HISTOGRAM_SUCCESS:
            return state.merge({
                error: false,
                fetching: false,
                histogramData: action.payload.data,
                chartData: getChartData(action.payload.data, action.payload.filters)
            });

        case HISTOGRAM_COMPARE_FETCHING:
            return state.merge({
                errorCompare: false,
                fetchingCompare: true
            });

        case HISTOGRAM_COMPARE_FAILURE:
            return state.merge({
                errorCompare: true,
                fetchingCompare: false
            });

        case HISTOGRAM_COMPARE_SUCCESS:
            return state.merge({
                errorCompare: false,
                fetchingCompare: false,
                chartDataCompare: getChartData(action.payload.data, action.payload.filters, true)
            });
        case HISTOGRAM_COMPARE_RESET:
            return state.merge({
                errorCompare: false,
                fetchingCompare: false,
                chartDataCompare: null
            });

        default:
            return state;
    }
}

module.exports = expensesByCategoriesMovements;
