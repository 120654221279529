// @ vendors
const Immutable = require('immutable');
const { get } = require('lodash');

// @ constants
const {
    MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_REQUEST,
    MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_SUCCESS,
    MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_FAILURE,
    MORTGAGE_REQUEST_PROPERTY_VALUE_DEFINED_RESET
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    antiguedadVivienda: 0,
    conservacionInmueble: '',
    identificadorCatastral: '',
    importeTasado: 0,
    ocupanteActual: '',
    superficieParcela: 0,
    superficieTotalComputable: 0
});

const mortgagePropertyValueDefined = (state = initialState, action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_REQUEST:
            return state.merge({
                isFetching: true
            });
        case MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_SUCCESS:
            const data = action.data;
            return state.merge({
                antiguedadVivienda: get(data, 'data.antiguedadVivienda', 0),
                conservacionInmueble: get(data, 'data.conservacionInmueble', ''),
                identificadorCatastral: get(data, 'data.identificadorCatastral', ''),
                importeTasado: get(data, 'data.importeTasado', 0),
                ocupanteActual: get(data, 'data.ocupanteActual', ''),
                superficieParcela: get(data, 'data.superficieParcela', 0),
                superficieTotalComputable: get(data, 'data.superficieTotalComputable', 0),
                error: '',
                isFetching: false,
                hasBeenFetched: true
            });
        case MORTGAGE_REQUEST_FETCH_PROPERTY_VALUE_DEFINED_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false,
                hasBeenFetched: true
            });
        case MORTGAGE_REQUEST_PROPERTY_VALUE_DEFINED_RESET:
            return initialState.merge();
        default:
            return state;
    }
};

module.exports = mortgagePropertyValueDefined;
