// @ vendors
const Immutable = require('immutable');
// @ utilities
const get = require('lodash/object/get');
const { applySign } = require('utilities/APIParsingHelper');
const { hash } = require('utilities/hash');
const { toCamelCase } = require('utilities/stringHelper');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');



const initialState = Immutable.fromJS({
    loading: false,
    error: false,
    hasBeenFetched: false,
    byOrder: [],
    byId: {}
});

function parseSettlement(settlementsDetails) {
    let byId = {};
    let byOrder = [];

    settlementsDetails.forEach( (settlement) => {
        const parsedSettlement = {
            concept: toCamelCase(settlement.concepto.trim()),
            amount: {
                amount: applySign(get(settlement, 'importe.importe', 0), settlement.signoImporte),
                currency: get(settlement, 'importe.divisa', EURO_TEXT)
            },
            dateFrom: settlement.fechaInicioValidezCond,
            dateTo: settlement.fechaFinValidezCond,
            rate: settlement.tasa
        };

        parsedSettlement.id = hash([parsedSettlement.concept, parsedSettlement.rate, parsedSettlement.amount.amount]),

        byId[parsedSettlement.id] = parsedSettlement;
        byOrder.push(parsedSettlement.id);
    });

    return {
        byOrder: byOrder,
        byId: byId
    };
}

function accountsSettlementsDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_DETAILS_REQUEST:
            return state.mergeDeep({
                loading: true,
                error: false
            });
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_DETAILS_SUCCESS:
            const parsedSettlements = parseSettlement(action.payload.settlementsDetails);
            return state.mergeDeep({
                byOrder: parsedSettlements.byOrder,
                byId: parsedSettlements.byId,
                loading: false,
                hasBeenFetched: true
            });
        case actionTypes.FETCH_ACCOUNT_SETTLEMENTS_DETAILS_FAILURE:
            return state.mergeDeep({
                loading: false,
                error: action.payload
            });
        default:
            return state
    }
}


module.exports = accountsSettlementsDetailsReducer;
