const actionTypes = require('constants/contactCenter/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isWaiting: false, // true if waiting for some operation
    confirmationSuccess: false, // true
    confirmationFailure: false,
    resendSucess: false,
    resendFailure: false,

    // Purely UI related data
    screen: 0,  // 0:Initial warning; 1:Get SMS code; 2:Wrong SMS code; 3:Success
    smsCode: '',
    isInvalidSmsCode: true,
    showModal: false
});

function bizumConfirmationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BIZUM_CONFIRMATION_RESET:
            return initialState;

        case actionTypes.BIZUM_CONFIRMATION_RESEND_REQUEST:
            return state.merge({
                isWaiting: true,
                resendSuccess: false,
                resendFailure: false
            });

        case actionTypes.BIZUM_CONFIRMATION_RESEND_FAILURE:
            return state.merge({
                isWaiting: false,
                resendSuccess: false,
                resendFailure: true
            });

        case actionTypes.BIZUM_CONFIRMATION_RESEND_SUCCESS:
            return state.merge({
                isWaiting: false,
                resendSuccess: true,
                resendFailure: false
            });

        case actionTypes.BIZUM_CONFIRMATION_VALIDATE_REQUEST:
            return state.merge({
                isWaiting: true,
                confirmationSuccess: false,
                confirmationFailure: false
            });

        case actionTypes.BIZUM_CONFIRMATION_VALIDATE_FAILURE:
            return state.merge({
                isWaiting: false,
                confirmationSuccess: false,
                confirmationFailure: true
            });

        case actionTypes.BIZUM_CONFIRMATION_VALIDATE_ERROR:
            return state.merge({
                isWaiting: false,
                confirmationSuccess: false,
                confirmationError: true
            });

        case actionTypes.BIZUM_CONFIRMATION_VALIDATE_SUCCESS:
            return state.merge({
                isWaiting: false,
                confirmationSuccess: true,
                confirmationFailure: false
            });

        case actionTypes.BIZUM_CONFIRMATION_SET_CODE:
            return state.merge({
                smsCode: action.payload.text,
                isInvalidSmsCode: action.payload.isInvalidText
            });

        case actionTypes.BIZUM_CONFIRMATION_SET_SCREEN:
            return state.merge({
                screen: action.payload
            });

        case actionTypes.BIZUM_CONFIRMATION_SHOW_MODAL:
            return state.merge({
                showModal: action.payload
            });

        default:
            return state
    }
}

module.exports = bizumConfirmationReducer;
