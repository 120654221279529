// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_REQUEST,
    MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_SUCCESS,
    MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_FAILURE,
    MORTGAGE_REQUEST_HOLDER_ECONOMIC_EDIT_SET_FIELD,
    MORTGAGE_REQUEST_ECONOMIC_FLAG,
    MORTGAGE_REQUEST_HOLDER_ECONOMIC_RESET,
    MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_REQUEST,
    MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_SUCCESS,
    MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_FAILURE,
    MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_REQUEST,
    MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_SUCCESS,
    MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_FAILURE,
    MORTGAGE_REQUEST_DELETE_OTHER_LOAN_REQUEST,
    MORTGAGE_REQUEST_DELETE_OTHER_LOAN_SUCCESS
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    codigoCSV: '',
    confirmada: false,
    codigoPersona: '',
    cuotaMensualAlquiler: 0,
    cuotaMensualOtroPrestamo: 0,
    cuotaMensualPrestamoHipotecario: 0,
    datosEconomicosDeclarados: '',
    datosObservados: '',
    importeTotalpendienteOtraHipoteca: 0,
    ingresosNetos: 0,
    modelo130: [],
    numeroDocumento: '',
    quiereCancelarOtraHipoteca: false,
    regimenInmueble: '',
    tieneOtraHipoteca: false,
    tipoDocumentoIdentidad: '',
    irpf: [],
    error: '',
    isFetching: false,
    isFetchingOtherLoans: false,
    otherLoans: [],
    isSavingOtherLoan: false,
    isDeletingOtherLoan: false
});

const setField = (state, action) => {
    const { inputField, data } = action.payload;
    return state.merge({
        [inputField]: data
    });
};

const deleteOtherLoan = (state, id) => {
    return state.get('otherLoans').toJS().filter(elem => elem.id != id);
}

const holderEconomic = (state = initialState, action) => {
    switch (action.type) {

        case MORTGAGE_REQUEST_HOLDER_ECONOMIC_RESET:
            return initialState.merge();

        case MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_REQUEST:
            return state.merge({
                isFetching: true
            });

        case MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_SUCCESS:
            const data = action.data.data;

            return state.mergeDeep({
                codigoCSV: get(data, 'codigoCvs', '') || '',
                confirmada: get(data, 'confirmada', false) || false,
                codigoPersona: get(data, 'codigoPersona', '') || '',
                cuotaMensualAlquiler: get(data, 'cuotaMensualAlquiler', 0) || 0,
                cuotaMensualOtroPrestamo: get(data, 'cuotaMensualOtroPrestamo', 0) || 0,
                cuotaMensualPrestamoHipotecario: get(data, 'cuotaMensualPrestamoHipotecario', 0) || 0,
                datosEconomicosDeclarados: get(data, 'datosEconomicosDeclarados', '') || '',
                datosObservados: get(data, 'datosObservados', '') || '',
                importeTotalpendienteOtraHipoteca: get(data, 'importeTotalpendienteOtraHipoteca', 0) || 0,
                ingresosNetos: get(data, 'ingresosNetos', 0) || 0,
                modelo130: get(data, 'modelo130', []) || [],
                numeroDocumento: get(data, 'numeroDocumento', '') || '',
                quiereCancelarOtraHipoteca: get(data, 'quiereCancelarOtraHipoteca', false) || false,
                regimenInmueble: get(data, 'regimenInmueble', 'UNKNOWN') || 'UNKNOWN',
                tieneOtraHipoteca: get(data, 'tieneOtraHipoteca', false) || false,
                tipoDocumentoIdentidad: get(data, 'tipoDocumentoIdentidad', '') || '',
                irpf: get(data, 'irpf', []) || [],
                error: '',
                isFetching: false,
                flag: true
            });

        case MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });

        case MORTGAGE_REQUEST_HOLDER_ECONOMIC_EDIT_SET_FIELD:
            return setField(state, action);

        case MORTGAGE_REQUEST_ECONOMIC_FLAG:
            return state.merge({
                flag: action.flag
            });
        case MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_REQUEST:
            return state.set('isFetchingOtherLoans', true);
        case MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_SUCCESS:
            return state.merge({
                otherLoans: action.data,
                isFetchingOtherLoans: false
            });
        case MORTGAGE_REQUEST_FETCH_HOLDER_ECONOMIC_OTHER_LOANS_FAILURE:
            return state.merge({
                isFetchingOtherLoans: false,
                error: action.data.error
            });
        case MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_REQUEST:
            return state.merge({
                isSavingOtherLoan: true
            });
        case MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_SUCCESS:
            return state.merge({
                isSavingOtherLoan: false
            });
        case MORTGAGE_REQUEST_HOLDER_ECONOMIC_OTHER_LOANS_SAVE_FAILURE:
            return state.merge({
                isSavingOtherLoan: false,
                error: action.data.error
            });
        case MORTGAGE_REQUEST_DELETE_OTHER_LOAN_REQUEST:
            return state.merge({
                isDeletingOtherLoan: true
            });
        case MORTGAGE_REQUEST_DELETE_OTHER_LOAN_SUCCESS:
            return state.merge({
                otherLoans: deleteOtherLoan(state, action.data.id),
                isDeletingOtherLoan: false
            });
        default:
            return state;
    }
};

module.exports = holderEconomic;
