// @ vendors
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
     return Immutable.Map().merge({
        mailSent: false,
        isFetching: false,
        error: '',
        isLegalTextRead: false,
    });
}

const initialState = setInitialState();

function sendPreContractualMail(state = initialState, action) {
   switch (action.type) {
       case actionTypes.PRE_CONTRACTUAL_MAIL_SENT_REQUEST:
            return state.merge({
                error: '',
                isFetching: true
            });
        case actionTypes.PRE_CONTRACTUAL_MAIL_SENT_SUCCESS:
            return state.merge({
                mailSent: true,
                isFetching:false
            });
        case actionTypes.PRE_CONTRACTUAL_MAIL_SENT_FAILURE:
            return state.merge({
                error: action.payload.error,
                mailSent: false,
                isFetching: false
            });
        case actionTypes.PRE_CONTRACTUAL_MAIL_RESET:
            return initialState;
        case actionTypes.PRE_CONTRACTUAL_SET_LEGAL_TEXT_READ:
            return state.merge({
                isLegalTextRead: true,
            })
        default:
            return state
    }
}

module.exports = sendPreContractualMail;
