// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const { formatText } = require('core/i18n').i18n;
const { sortByOrder } = require('lodash/collection');
// @ reducers
const depositIntervenersReducer = require('./depositsInterveners');
// @ constants
const actionTypes = require('constants/actionTypes');
const { ACTION_CODE_PREFIX } = require('../utilities/contactCenter/wrapUp/constants');
const {
  CONTRACT_INDEX_DEPOSIT_18_MONTHS_RETENTION,
  CONTRACT_INDEX_COMBINED_DEPOSIT,
  CONTRACT_INDEX_DEPOSIT_24_MONTHS_RETENTION,
} = require('../constants');
const {
  CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE,
  CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE,
  CONTRACT_METHOD_GET_CONTRACT_INFORMATION,
  CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS,
} = require('../constants/actionTypes');

function setInitialState() {
  return Immutable.fromJS({
    error: '',
    isFetching: false,
    hasBeenFetched: false,
    success: false,
    isFetched: false,
    retentionFlows: {},
    retentionRequestedDeposits: {},
  });
}

function updateDeposits(deposits) {
  let byId = {};
  let byOrder = [];

  deposits.forEach(deposit => {
    const parsedDeposits = {
      description: trim(deposit.get('alias')),
      alias: deposit.get('description'),
      amount: deposit.get('totalAvailableBalance'),
      contract: {
        contractNumber: deposit.get('contractNumber'),
        product: deposit.get('product'),
        fullContractNumber: deposit.get('fullContractNumber'),
      },
      isHolder: deposit.get('isHolder'),
      id: deposit.get('id'),
      isClosed: false,
    };
    const depositId = deposit.get('id');

    byId[depositId] = parsedDeposits;
    byOrder.push(depositId);
  });

  return {
    byId: byId,
    byOrder: byOrder,
    error: '',
  };
}

function getRenovation(renovation) {
  let response;

  if (renovation === 'N') {
    response = formatText('impositionRow-noRenewal');
  } else {
    response = formatText('impositionRow-automatic');
  }

  return response;
}

function updateImpositions(impositions) {
  let byId = {};
  let byOrder = [];

  impositions = sortByOrder(impositions, ['idscontr'], ['asc']);

  impositions.map(imposition => {
    const renewal = getRenovation(imposition.indrevto);
    const isRenovationActive = imposition.indrevto !== 'N';
    const idImp = imposition.idscontr;
    const parsedImpositions = {
      id: idImp,
      activationDate: imposition.fecalta,
      lastRenewal: imposition.fecini,
      expirationDate: imposition.fecvenci,
      tae: imposition.tae,
      renovationOfferId: imposition.renovationOfferId,
      renewalState: imposition.renewalState,
      renovationId: imposition.renovationId,
      tin: imposition.tin,
      renewal: renewal,
      isRenovationActive: isRenovationActive,
      amount: imposition.imporcoa,
      currency: imposition.codmonsw,
    };

    byId[idImp] = parsedImpositions;
    byOrder.push(idImp);
  });

  return {
    byId,
    byOrder,
  };
}

function generateRetentionFlows(data) {
  const DEPOSIT_FLOW_BY_CONTRACT_INDEX = {
    [CONTRACT_INDEX_DEPOSIT_18_MONTHS_RETENTION]: {
      entry: {
        path: '/myprofile/purchase/deposit-eighteen-months-retention',
        failure: [
          { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
          { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE },
        ],
        success: [
          { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
          { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS },
        ],
      },
    },
    [CONTRACT_INDEX_COMBINED_DEPOSIT]: {
      entry: {
        path: '/myprofile/purchase/combined-deposit',
        failure: [
          { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
          { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE },
        ],
        success: [
          { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
          { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS },
        ],
      },
    },
    [CONTRACT_INDEX_DEPOSIT_24_MONTHS_RETENTION]: {
      entry: {
        path: '/myprofile/purchase/deposit-twentyfour-months-retention',
        failure: [
          { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
          { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE },
        ],
        success: [
          { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
          { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS },
        ],
      },
    },
  };

  return Object.entries(data).reduce((acc, [key, value]) => {
    return { ...acc, [`${ACTION_CODE_PREFIX}${value.actionCode}`]: DEPOSIT_FLOW_BY_CONTRACT_INDEX[key] };
  }, {});
}

function deposits(state = setInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_DEPOSITS:
      return state.merge(updateDeposits(action.payload.deposits));
    case actionTypes.RESET_FETCHED_IMPOSITIONS: 
      return state.mergeDeep({
        isFetched: false,
      });
    case actionTypes.FETCH_IMPOSITIONS_REQUEST:
      return state.mergeDeep({
        isFetching: true,
        byId: {
          [action.payload.id]: {
            isFetching: true,
          },
        },
      });
    case actionTypes.FETCH_IMPOSITIONS_SUCCESS:
      return state.mergeDeep({
        byId: {
          [action.payload.id]: {
            impositionAssociatedAccount: action.payload.impositionAssociatedAccount,
            impositionList: updateImpositions(action.payload.impositionList),
            borderDates: action.payload.borderDates,
            isClosed:
              new Date(action.payload.borderDates.endDate) <
              new Date(action.payload.actualDate),
            isFetching: false,
            success: true,
            closestExpirationDate: action.payload.closestExpirationDate,
            depositStandard: action.payload.depositStandard,
            depositSubType: action.payload.depositSubType,
            depositType: action.payload.depositType,
            country: action.payload.country,
            currency: action.payload.currency
          },
        },
        actualDate: action.payload.actualDate,
      });
    case actionTypes.FETCH_IMPOSITIONS_COMPLETED:
      return state.merge({
        isFetching: false,
        isFetched: true,
        hasBeenFetched: true,
        success: true,
      });
    case actionTypes.FETCH_IMPOSITIONS_FAILURE:
      return state.merge({
        error: action.payload.error,
        isFetching: false,
      });
    case actionTypes.FETCH_COMBINED_DEPOSITS_SUCCESS:
      return state.mergeDeep({
        byId: {
          [action.payload.id]: {
            impositionList: {
              byId: {
                [action.payload.impositionId]: {
                  fund: action.payload.fund,
                  fundContract: action.payload.fundContractNumber,
                },
              },
            },
          },
        },
      });
    case actionTypes.FETCH_COMBINED_DEPOSITS_DETAIL_FAILURE:
      return state.merge({
        error: action.payload.error,
      });
    case actionTypes.FETCH_COMBINED_DEPOSITS_DETAIL_SUCCESS:
      return state.mergeDeep({
        byId: {
          [action.payload.id]: {
            impositionList: {
              byId: {
                [action.payload.impositionId]: {
                  fundsAmount: action.payload.fundsAmount,
                  fundParticipations: action.payload.fundParticipations,
                },
              },
            },
          },
        },
        isFetchingCombinedDepositsDetail: false,
      });
    case actionTypes.FETCH_COMBINED_DEPOSITS_FAILURE:
      return state.merge({
        error: action.payload.error,
      });
    case actionTypes.FETCH_DEPOSIT_INTERVENERS_REQUEST:
    case actionTypes.FETCH_DEPOSIT_INTERVENERS_SUCCESS:
    case actionTypes.FETCH_DEPOSIT_INTERVENERS_FAILURE:
      const depositId = action.payload.depositId;
      return state.setIn(['byId', depositId, 'interveners'], depositIntervenersReducer(state.getIn(['byId', depositId, 'interveners']), action));
    case actionTypes.DEPOSITS_RESET:
      return setInitialState();
    case actionTypes.UPDATE_RETENTION_REQUESTED_DEPOSITS:
      const retentionFlows = generateRetentionFlows(action.payload.data);
      return state.merge({
        retentionFlows,
        retentionRequestedDeposits: action.payload.data,
      });
    default:
      return state;
  }
}

module.exports = deposits;
