// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { FIRST_STEP, WIZARD_FIRST_STEP, WIZARD_SECOND_STEP } = require('constants/index');
// @ utilities
const { validateAmount } = require('utilities/contactCenter/cardTransferMoneyToCheckingAccountHelper');

const mapRequestServiceResponse = serviceResponse => {
    if (!serviceResponse) {
        return {};
    }

    return {
        coestrefcodigoEstadoReferencia: get(serviceResponse, 'coestrefcodigoEstadoReferencia'),
        cuentaIngresoAsoContrato: {
            codbban: get(serviceResponse, 'cuentaIngresoAsoContrato.codbban'),
            digitodecontrol: get(serviceResponse, 'cuentaIngresoAsoContrato.digitodecontrol'),
            pais: get(serviceResponse, 'cuentaIngresoAsoContrato.pais'),
        },
        idsubtipcodSudtipoProductoContrato: get(serviceResponse, 'idsubtipcodSudtipoProductoContrato'),
        jimppdtesaldoDipantesyDespTrasp: {
            currency: get(serviceResponse, 'jimppdtesaldoDipantesyDespTrasp.divisa'),
            amount: get(serviceResponse, 'jimppdtesaldoDipantesyDespTrasp.importe'),
        },
        jmodalidmodalidadFormaPago: get(serviceResponse, 'jmodalidmodalidadFormaPago'),
        jtipoliqtipoLiquidacion: get(serviceResponse, 'jtipoliqtipoLiquidacion'),
        mlforpagcodigoFormaPago: get(serviceResponse, 'mlforpagcodigoFormaPago'),
        nomsprodtipoTarjeta: get(serviceResponse, 'nomsprodtipoTarjeta'),
        numbenefnumeroBeneficiario: get(serviceResponse, 'numbenefnumeroBeneficiario'),
        nutarbennumeroSecuencialTarjeta: get(serviceResponse, 'nutarbennumeroSecuencialTarjeta'),
    };
};

const setInitialState = () => (
    Immutable.fromJS({
        steps: [
            { currency: 'EUR', amount: 0, valid: false, validationMessage: '' },
            { hasSignaturePattern: true }
        ],
        visibleStep: WIZARD_FIRST_STEP,
        isFetching: false,
        fetchError: false,
        fetchErrorMessage: '',
        response: {},
        submitSuccessful: false,
        submitError: false,
        submitErrorMessage: '',
    })
);

const getValidatedStep1 = (state) => {
    const amount = state.getIn(['steps', FIRST_STEP, 'amount']);
    const currency = state.getIn(['steps', FIRST_STEP, 'currency']);
    const availableBalance = state.getIn(['response', 'jimppdtesaldoDipantesyDespTrasp', 'amount']);

    const validatorResult = validateAmount(amount, currency, availableBalance);

    return state.merge({
        steps: state.get('steps').setIn([FIRST_STEP, 'valid'], validatorResult.valid).setIn([FIRST_STEP, 'validationMessage'], validatorResult.validationMessage),
        visibleStep: validatorResult.valid ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP
    });
};

const cardTransferMoneyToCheckingAccountReducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_START:
            return state.merge({
                isFetching: true,
                fetchError: false,
                fetchErrorMessage: '',
                response: {},
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_FAILURE:
            return state.merge({
                isFetching: false,
                fetchError: true,
                fetchErrorMessage: action.payload.error.description,
                response: {},
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_REQUEST_SUCCESS:
            return state.merge({
                isFetching: false,
                fetchError: false,
                fetchErrorMessage: '',
                response: mapRequestServiceResponse(action.payload.response),
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_VALIDATE_STEP:
            if (action.payload.step === WIZARD_FIRST_STEP) {
                return getValidatedStep1(state);
            } else {
                return state;
            }

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SET_AMOUNT:
            return state.merge({
                steps: state.get('steps').setIn([FIRST_STEP, 'amount'], action.payload.amount)
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                submitError: false,
                submitErrorMessage: '',
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_SAVE_FAILURE:
            return state.merge({
                submitSuccessful: false,
                submitError: true,
                submitErrorMessage: action.payload.error.description,
            });

        case actionTypes.CARD_TRANSFER_MONEY_TO_CHECKING_ACCOUNT_CLEAR:
            return setInitialState();

        default:
            return state;
    }
};

module.exports = cardTransferMoneyToCheckingAccountReducer;
