const wizardComponentsHelper = require('../utilities/wizardComponenstHelper');
const inputs = require('./inputs');

const selfEmployed = require('./fioc/selfEmployed');
const employed = require('./fioc/employed');
const rentier = require('./fioc/rentier');
const pensioner = require('./fioc/pensioner');
const withoutActivity = require('./fioc/withoutActivity');

function buildFioc(prefix = '', authorized = false) {
    const inputsFioc = wizardComponentsHelper.replacePrefix(inputs.fioc, prefix);
    inputsFioc.ocupation.prefix = prefix;

    return {
        id_component: 'fioc',
        id: prefix + 'tab-form-datos-personales',
        exclude: [],
        grid: [
            [inputsFioc.ocupation],
            [selfEmployed(prefix, authorized)],
            [employed(prefix, authorized)],
            [rentier(prefix, authorized)],
            [pensioner(prefix, authorized)],
            [withoutActivity(prefix, authorized)]
        ]
    };
}

module.exports = buildFioc;
