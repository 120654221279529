const get = require('lodash/object/get')

const ACTION_CODE_BROKER_LANDING = 5086
const ACTION_CODE_VIEWS_BY_PORTFOLIO = 5087
const ACTION_CODE_BROKER_ADVANCE_SEARCH = 5088
const ACTION_CODE_SETUP_INVESTMENTS_ALARMS = 5089
const ACTION_CODE_FEATURED_QUERIES_VALUES_INDEX_BROKER = 5090
const ACTION_CODE_MARKETS_BROKER = 5091
const ACTION_CODE_NEWS_BROKER = 5092
const ACTION_CODE_MARKETS_DIARY_BROKER = 5093
const ACTION_CODE_PULSES_BROKER = 5094
const ACTION_CODE_MARKET_COMMENTS_BROKER = 5095
const ACTION_CODE_QUERIES_INDEX_VALUES_BROKER = 5096
const ACTION_CODE_ALL_INDEX_BROKER = 5097
const ACTION_CODE_TECH_ANALYSIS_SEARCH = 5098
const ACTION_CODE_CONSENSUS = 5099
const ACTION_CODE_ORDERS_AND_MOVEMENTS = 5100
const ACTION_CODE_ANNUL_ORDER = 5101
const ACTION_CODE_BROKER_INTERNAL_TRANSFER_FUND = 5102
const ACTION_CODE_HIRE_STOCKS_CONTRACT = 5103
const ACTION_CODE_SEE_CORPORATE_OPERATIONS = 5104
const ACTION_CODE_SUSCRIBE_RIGTHS = 5105
const ACTION_CODE_BUY_RIGHTS = 5106
const ACTION_CODE_SELL_RIGHTS = 5271
const ACTION_CODE_BROKER_EXTERNAL_TRANSFER_FUND = 5107
const ACTION_CODE_PRODUCT_FILE = 5108
const ACTION_CODE_SIMPLE_STOCKS_BUY = 5109
const ACTION_CODE_ADVANCED_STOCKS_BUY = 5110
const ACTION_CODE_SIMPLE_STOCKS_SELL = 5111
const ACTION_CODE_ADVANCED_STOCKS_SELL = 5112
const ACTION_CODE_BUY_INVESTMENT_FUND = 5113
const ACTION_CODE_REINMBURSE_INVESTMENT = 5114
const ACTION_CODE_ADDITIONAL_SUBSCRIPTION_INVESTMENT_FUND = 5115
const ACTION_CODE_BUY_PENSION_PLAN = 5116
const ACTION_CODE_CONTRIBUTE_TO_PENSION_PLAN = 5117
const ACTION_CODE_TRANSFER_INFORMATION = 5118
const ACTION_CODE_REQUEST_CONTRIBUTION_ANNULATION_TO_PENSION_PLAN = 5119
const ACTION_CODE_PLANS_INFO = 5120
const ACTION_CODE_PRODUCT_ETF = 5121
const ACTION_CODE_PRODUCT_WARRANT = 5122
const ACTION_CODE_VALUES_EXTERNAL_TRANSFER = 5123
const ACTION_CODE_VALUES_INTERNAL_TRANSFER = 5124
const ACTION_CODE_INTERVENIERS_STOCKS = 5125
const ACTION_CODE_BUY_WARRANTS = 5126
const ACTION_CODE_SELL_WARRANTS = 5127
const ACTION_CODE_BUY_ETF = 5128
const ACTION_CODE_SELL_ETF = 5129
const ACTION_CODE_RIGHTS_CASH_EXCHANGE = 5230
const ACTION_CODE_PLANS = 6000;
const ACTION_CODE_HIRE_STOCKS = 6001;
const ACTION_CODE_FUNDS = 6002;
const ACTION_CODE_ETFS = 6003;
const ACTION_CODE_WARRANTS = 6004;
const ACTION_CODE_EXPLORE_STOCKS = 6005;

const {
    BROKER_GET_PRODUCT_FILE_SUCCESS,
    BROKER_GET_PRODUCT_FILE_FAILURE,
    BROKER_RIGHTS_SELL_CONFIRM_FAILURE,
    BROKER_RIGHTS_SELL_CONFIRM_SUCCESS,
    BROKER_RIGHTS_BUY_CONFIRM_FAILURE,
    BROKER_RIGHTS_BUY_CONFIRM_SUCCESS,
    FETCH_BROKER_INVESTMENT_FUNDS_NOT_FOUND,
    FETCH_BROKER_INVESTMENT_FUNDS_FAILURE,
    FETCH_BROKER_INVESTMENT_FUNDS_SUCCESS,
    BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_FAILURE,
    BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_SUCCESS,
    BROKER_GET_MY_INVESTMENTS_SUCCESS,
    BROKER_GET_MY_INVESTMENTS_FAILURE,
    BROKER_ALERTS_CONFIG_CREATE_ALERT,
    BROKER_ALERTS_CONFIG_CREATE_ALERT_SUCCESS,
    BROKER_ALERTS_CONFIG_CREATE_ALERT_FAILURE,
    BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_FAILURE,
    BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_SUCCESS,
    BROKER_RIGHTS_CASH_EXCHANGE_SET_CONFIRM_SUCCESS,
    BROKER_RIGHTS_CASH_EXCHANGE_SET_FAILURE,
    BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS,
    BROKER_GET_SEARCH_RESULTS_LIST_FAILURE,
    BROKER_GET_DEFAULT_VIEW_ID_FAILURE,
    BROKER_GET_DEFAULT_VIEW_ID_SUCCESS,
    BROKER_ORDERS_AND_MOVEMENTS_CANCEL_SUCCESS,
    BROKER_ORDERS_AND_MOVEMENTS_CANCEL_FAILURE,
    BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_ERROR,
    BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_SUCCESS,
    BROKER_GET_POSSESSIONS_FAILURE,
    BROKER_GET_POSSESSIONS_SUCCESS,
    BROKER_EXCHANGE_INDEXES_FAILURE,
    BROKER_EXCHANGE_INDEXES_SUCCESS,
    BROKER_TODAYS_MAIN_STOCKS_FAILURE,
    BROKER_TODAYS_MAIN_STOCKS_SUCCESS,
    BROKER_STOCKS_BY_INDEX_FAILURE,
    BROKER_STOCKS_BY_INDEX_SUCCESS,
    FETCH_BROKER_MARKET_PULSES_FAILURE,
    FETCH_BROKER_MARKET_PULSES_SUCCESS,
    BROKER_GET_NEWS_FAILURE,
    BROKER_GET_NEWS_SUCCESS,
    BROKER_ALL_INDEXES_FETCHING_FAILURE,
    BROKER_ALL_INDEXES_FETCHING_SUCCESS,
    BROKER_CHANGE_SELECTED_VIEW,
    BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS,
    BROKER_ADVANCED_SEARCH_RESULTS_FAILURE
} = require('constants/actionTypes')

const {
    BROKER_MOVEMENT_CANCELATION_OPEN_MODAL
} = require('constants/contactCenter/actionTypes')

const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_ACCESS_BY_ACTION,
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE,
    UNKNOWN_ACTION_TYPE
} = require('utilities/contactCenter/wrapUp/constants')

module.exports = {
    [ACTION_CODE_FEATURED_QUERIES_VALUES_INDEX_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/mainStocks/[\\w]|[\%]+',
            failure: [ { action: UNKNOWN_ACTION_TYPE } ],
            success: [ { action: UNKNOWN_ACTION_TYPE } ],
            action: [
                BROKER_EXCHANGE_INDEXES_FAILURE,
                BROKER_EXCHANGE_INDEXES_SUCCESS,
                BROKER_TODAYS_MAIN_STOCKS_FAILURE,
                BROKER_TODAYS_MAIN_STOCKS_SUCCESS
            ],
            check: (action, state) => {
                const { type } = action;
                const pathname = get(state, 'routing.locationBeforeTransitions.pathname');
                const isExpectedPreviousPath = pathname.includes('broker/mainStocks/');
                let operationState = OPERATION_LOG_NONE;

                if (isExpectedPreviousPath && (type === BROKER_TODAYS_MAIN_STOCKS_SUCCESS)) {
                    operationState = OPERATION_LOG_DONE;
                } else if (isExpectedPreviousPath && (type === BROKER_TODAYS_MAIN_STOCKS_FAILURE || type === BROKER_EXCHANGE_INDEXES_FAILURE)) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    actionCode: ACTION_CODE_FEATURED_QUERIES_VALUES_INDEX_BROKER,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_MARKETS_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/markets'
        }
    },
    [ACTION_CODE_NEWS_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/news',
            failure: [
                { action: FETCH_BROKER_MARKET_PULSES_FAILURE },
                { action: BROKER_GET_NEWS_FAILURE }
            ],
            success: [
                { action: FETCH_BROKER_MARKET_PULSES_SUCCESS },
                { action: BROKER_GET_NEWS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_MARKETS_DIARY_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/marketsAgenda'
        }
    },
    [ACTION_CODE_PULSES_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/pulses'
        }
    },
    [ACTION_CODE_MARKET_COMMENTS_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/market-comments'
        }
    },
    [ACTION_CODE_QUERIES_INDEX_VALUES_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/index-values/[\\w]|[\%]+',
            failure: [ { action: UNKNOWN_ACTION_TYPE } ],
            success: [ { action: UNKNOWN_ACTION_TYPE } ],
            action: [
                BROKER_EXCHANGE_INDEXES_FAILURE,
                BROKER_EXCHANGE_INDEXES_SUCCESS,
                BROKER_STOCKS_BY_INDEX_FAILURE,
                BROKER_STOCKS_BY_INDEX_SUCCESS
            ],
            check: (action, state) => {
                const { type } = action;
                const pathname = get(state, 'routing.locationBeforeTransitions.pathname');
                const isExpectedPreviousPath = pathname.includes('broker/index-values/');
                let operationState = OPERATION_LOG_NONE;

                if (type === BROKER_STOCKS_BY_INDEX_SUCCESS) {
                    operationState = OPERATION_LOG_DONE;
                } else if (isExpectedPreviousPath && (type === BROKER_EXCHANGE_INDEXES_FAILURE || type === BROKER_STOCKS_BY_INDEX_FAILURE)) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    actionCode: ACTION_CODE_QUERIES_INDEX_VALUES_BROKER,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_ALL_INDEX_BROKER]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/all-indexes',
            failure: [
                { action: BROKER_ALL_INDEXES_FETCHING_FAILURE }
            ],
            success: [
                { action: BROKER_ALL_INDEXES_FETCHING_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_BROKER_LANDING]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/general',
            failure: [
                { action: BROKER_GET_MY_INVESTMENTS_FAILURE }
            ],
            success: [
                { action: BROKER_GET_MY_INVESTMENTS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_VIEWS_BY_PORTFOLIO]: { // BROKER_CHANGE_SELECTED_VIEW, BROKER_GET_STOCKS_BY_TYPE_SUCCESS, BROKER_EVOLUTION_DATA_SUCCESS
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/custom-portfolio',
            action: [BROKER_CHANGE_SELECTED_VIEW],
            failure: [
                { action: BROKER_GET_DEFAULT_VIEW_ID_FAILURE }
            ],
            success: [
                { action: BROKER_GET_DEFAULT_VIEW_ID_SUCCESS }
            ],
            check: () => ({actionCode: ACTION_CODE_VIEWS_BY_PORTFOLIO, operationState: OPERATION_LOG_ACCESS_BY_ACTION})
        }
    },
    [ACTION_CODE_SETUP_INVESTMENTS_ALARMS]: {
        entry: {
            action: [ BROKER_ALERTS_CONFIG_CREATE_ALERT ]
        },
        finish: {
            action: [ BROKER_ALERTS_CONFIG_CREATE_ALERT_SUCCESS, BROKER_ALERTS_CONFIG_CREATE_ALERT_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === BROKER_ALERTS_CONFIG_CREATE_ALERT_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_SETUP_INVESTMENTS_ALARMS,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_ANNUL_ORDER]: {
        entry: {
            action: [ BROKER_MOVEMENT_CANCELATION_OPEN_MODAL ],
            check: (action) => {
                let operationState = OPERATION_LOG_NONE
                const modalOpen = get(action, 'payload.open')

                if (modalOpen) {
                    operationState = OPERATION_LOG_DONE
                }

                return {
                    actionCode: ACTION_CODE_ANNUL_ORDER,
                    operationState
                }
            }
        },
        finish: {
            action: [ BROKER_ORDERS_AND_MOVEMENTS_CANCEL_SUCCESS, BROKER_ORDERS_AND_MOVEMENTS_CANCEL_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === BROKER_ORDERS_AND_MOVEMENTS_CANCEL_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_ANNUL_ORDER,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_SEE_CORPORATE_OPERATIONS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/corporatesOperations',
            failure: [
                { action: BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_FAILURE }
            ],
            success: [
                { action: BROKER_CORPORATES_OPERATIONS_CONTRACT_OPERATIONS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SUSCRIBE_RIGTHS]: {
        entry: {
            path: '/myprofile/broker/subscription/wizard'
        }
    },
    [ACTION_CODE_BUY_RIGHTS]: {
        entry: {
            path: '/myprofile/broker/rightsBuy'
        },
        finish: {
            action: [ BROKER_RIGHTS_BUY_CONFIRM_SUCCESS, BROKER_RIGHTS_BUY_CONFIRM_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === BROKER_RIGHTS_BUY_CONFIRM_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_BUY_RIGHTS,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_SELL_RIGHTS]: {
        entry: {
            path: '/myprofile/broker/rightsSell'
        },
        finish: {
            action: [ BROKER_RIGHTS_SELL_CONFIRM_SUCCESS, BROKER_RIGHTS_SELL_CONFIRM_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === BROKER_RIGHTS_SELL_CONFIRM_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_SELL_RIGHTS,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_RIGHTS_CASH_EXCHANGE]: {
        entry: {
            path: '/myprofile/broker/rightsCashExchange'
        },
        finish: {
            action: [ BROKER_RIGHTS_CASH_EXCHANGE_SET_CONFIRM_SUCCESS, BROKER_RIGHTS_CASH_EXCHANGE_SET_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE

                if (action.type === BROKER_RIGHTS_CASH_EXCHANGE_SET_FAILURE) {
                    operationState = OPERATION_LOG_FAIL
                }

                return {
                    actionCode: ACTION_CODE_ANNUL_ORDER,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_CONTRIBUTE_TO_PENSION_PLAN]: {
        entry: {
            path: '/myprofile/broker/pensionPlansContribution/[\\w-]+'
        }
    },
    [ACTION_CODE_REQUEST_CONTRIBUTION_ANNULATION_TO_PENSION_PLAN]: {
        entry: {
            path: '/myprofile/broker/cancel-pension-plan-pending-order/[0-9]+/[0-9]+/[0-9]+'
        }
    },
    [ACTION_CODE_BROKER_ADVANCE_SEARCH]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [ BROKER_GET_SEARCH_RESULTS_LIST_SUCCESS, BROKER_GET_SEARCH_RESULTS_LIST_FAILURE, BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS, BROKER_ADVANCED_SEARCH_RESULTS_FAILURE ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;

                if ([BROKER_ADVANCED_SEARCH_RESULTS_FAILURE,BROKER_GET_SEARCH_RESULTS_LIST_FAILURE].includes(action.type)) {
                    operationState = OPERATION_LOG_FAIL
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.data.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description';
                }

                return {
                    actionCode: ACTION_CODE_BROKER_ADVANCE_SEARCH,
                    errorMessage,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_BROKER_INTERNAL_TRANSFER_FUND]: {
        entry: {
            path: '/myprofile/broker/internalTransferFund'
        }
    },
    [ACTION_CODE_HIRE_STOCKS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/stocks'
        }
    },
    [ACTION_CODE_EXPLORE_STOCKS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/explore/stocks'
        }
    },
    [ACTION_CODE_HIRE_STOCKS_CONTRACT]: {
        entry: {
            path: '/myprofile/broker/stocksContract'
        }
    },
    [ACTION_CODE_BROKER_EXTERNAL_TRANSFER_FUND]: {
        entry: {
            path: '/myprofile/broker/externalTransferFund'
        }
    },
    [ACTION_CODE_SIMPLE_STOCKS_BUY]: {
        entry: {
            path: '/myprofile/broker/stocksBuy/.+/.+',
            failure: [
                { action: BROKER_GET_PRODUCT_FILE_FAILURE }
            ],
            success: [
                { action: BROKER_GET_PRODUCT_FILE_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_ADVANCED_STOCKS_BUY]: {
        entry: {
            path: '/myprofile/broker/stocksAdvancedBuy/.+/.+'
        }
    },
    [ACTION_CODE_SIMPLE_STOCKS_SELL]: {
        entry: {
            path: '/myprofile/broker/stocksSell/.+/.+'
        }
    },
    [ACTION_CODE_ADVANCED_STOCKS_SELL]: {
        entry: {
            path: '/myprofile/broker/stocksAdvancedSell/.+/.+/.+/.+'
        }
    },
    [ACTION_CODE_BUY_INVESTMENT_FUND]: {
        entry: {
            path: '/myprofile/broker/buyInvestmentFunds/[\\w-]*'
        }
    },
    [ACTION_CODE_REINMBURSE_INVESTMENT]: {
        entry: {
            path: '/myprofile/broker/reimburseInvestment/[0-9-]+/[0-9]+'
        }
    },
    [ACTION_CODE_ADDITIONAL_SUBSCRIPTION_INVESTMENT_FUND]: {
        entry: {
            path: '/myprofile/broker/buyInvestmentFunds/[\\w-]+/\\w+',
            failure: [
                { action: FETCH_BROKER_INVESTMENT_FUNDS_NOT_FOUND },
                { action: FETCH_BROKER_INVESTMENT_FUNDS_FAILURE }
            ],
            success: [
                { action: FETCH_BROKER_INVESTMENT_FUNDS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_VALUES_EXTERNAL_TRANSFER]: {
        entry: {
            path: '/myprofile/broker/callmeBack/externalTransferValues'
        }
    },
    [ACTION_CODE_VALUES_INTERNAL_TRANSFER]: {
        entry: {
            path: '/myprofile/broker/transferValues'
        }
    },
    [ACTION_CODE_WARRANTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/warrants'
        }
    },
    [ACTION_CODE_BUY_WARRANTS]: {
        entry: {
            path: '/myprofile/broker/warrantsBuy/[\\w]+/[A-Z]+',
            failure: [
                { action: BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_ERROR }
            ],
            success: [
                { action: BROKER_WARRANTS_LEGAL_DOCUMENT_COST_FETCH_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_TECH_ANALYSIS_SEARCH]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/techAnalysisSearch'
        }
    },
    [ACTION_CODE_CONSENSUS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/consensus'
        }
    },
    [ACTION_CODE_ORDERS_AND_MOVEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/ordersAndMovements'
        }
    },
    [ACTION_CODE_PLANS_INFO]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/plansInfo'
        }
    },
    [ACTION_CODE_PLANS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/plans'
        }
    },
    [ACTION_CODE_TRANSFER_INFORMATION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/pensions/transfer/information'
        }
    },
    [ACTION_CODE_INTERVENIERS_STOCKS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/interveniers/[0-9]+/stocks'
        }
    },
    [ACTION_CODE_PRODUCT_FILE]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/productFile/[\\w]*/[A-Z]+',
            failure: [
                {action: BROKER_GET_PRODUCT_FILE_FAILURE}
            ],
            success: [
                {action: BROKER_GET_PRODUCT_FILE_SUCCESS}
            ]
        }
    },
    [ACTION_CODE_ETFS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/etfs'
        }
    },
    [ACTION_CODE_PRODUCT_ETF]: {
            type: FLOW_TYPE_ONLY_ACCESS,
                entry: {
                path: '/myprofile/broker/etfsProductFile/[\\w]*/[A-Z]+',
            failure: [ { action: UNKNOWN_ACTION_TYPE } ],
            success: [ { action: UNKNOWN_ACTION_TYPE } ],
            action: [
                BROKER_GET_POSSESSIONS_FAILURE,
                BROKER_GET_POSSESSIONS_SUCCESS,
            ],
            check: (action, state) => {
                const { type } = action;
                const pathname = get(state, 'routing.locationBeforeTransitions.pathname');
                const isExpectedPreviousPath = pathname.includes('/broker/etfsProductFile/');
                let operationState = OPERATION_LOG_NONE;

                if (isExpectedPreviousPath && type === BROKER_GET_POSSESSIONS_SUCCESS) {
                    operationState = OPERATION_LOG_DONE;
                } else if  (isExpectedPreviousPath && type === BROKER_GET_POSSESSIONS_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                }

                return {
                    actionCode: ACTION_CODE_PRODUCT_ETF,
                    operationState
                }
            }
        }
    },
    [ACTION_CODE_PRODUCT_WARRANT]: {
                type: FLOW_TYPE_ONLY_ACCESS,
                entry: {
                    path: '/myprofile/broker/warrantsProductFile/[\\w]*/[A-Z]+',
                    failure: [ { action: UNKNOWN_ACTION_TYPE } ],
                    success: [ { action: UNKNOWN_ACTION_TYPE } ],
                    action: [
                        BROKER_GET_PRODUCT_FILE_FAILURE,
                        BROKER_GET_PRODUCT_FILE_SUCCESS
                    ],
                    check: (action, state) => {
                        const { type } = action;
                        const pathname = get(state, 'routing.locationBeforeTransitions.pathname');
                        const isExpectedPreviousPath = pathname.includes('/broker/warrantsProductFile/');
                        let operationState = OPERATION_LOG_NONE;

                        if (isExpectedPreviousPath && type === BROKER_GET_PRODUCT_FILE_SUCCESS) {
                            operationState = OPERATION_LOG_DONE;
                        } else if  (isExpectedPreviousPath && type === BROKER_GET_PRODUCT_FILE_FAILURE) {
                            operationState = OPERATION_LOG_FAIL;
                        }

                        return {
                            actionCode: ACTION_CODE_PRODUCT_WARRANT,
                            operationState
                        }
                    }
                }
            },
    [ACTION_CODE_SELL_WARRANTS]: {
        entry: {
            path: '/myprofile/broker/warrantsSell/[\\w-]+/[\\w-]+/[\\w-]+/[\\w-]+'
        }
    },
    [ACTION_CODE_BUY_ETF]: {
        entry: {
            path: '/myprofile/broker/etfsBuy/\\w+/[A-Z]+',
            failure: [
                { action: BROKER_GET_PRODUCT_FILE_FAILURE }
            ],
            success: [
                { action: BROKER_GET_PRODUCT_FILE_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SELL_ETF]: {
        entry: {
            path: '/myprofile/broker/etfsSell'
        }
    },
    [ACTION_CODE_BUY_PENSION_PLAN]: {
        entry: {
            path: '/myprofile/broker/buyPensionPlan/.*',
            failure: [
                { action: BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_FAILURE }
            ],
            success: [
                { action: BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_FUNDS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/broker/funds'
        }
    }
}
