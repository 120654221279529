const sendTextToClipBoard = text => {
    const fallbackCopyTextToClipboard = () => {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        document.execCommand("copy");

        document.body.removeChild(textArea);
    }

    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard();
        return;
    }

    navigator.clipboard.writeText(text);
};

module.exports = { sendTextToClipBoard };