//@utilities
const { formatText } = require('core/i18n').i18n;

//@constants
const MICCODE_CONVERSION = require('constants/miccodes');
const {
    BROKER_MARKET_EMPTY,
    BROKER_MARKET_MADRID,
    BROKER_MARKET_BARCELONA,
    EURONEXT_EURONEXT_LISBON,
    EURONEXT_EURONEXT_PARIS,
    EURONEXT_EURONEXT_AMSTERDAM,
    EURONEXT_EURONEXT_BRUSSELS,
    EURONEXT_EURONEXT_BRUSSELS_DERIVATIVES,
    BOLSA_DE_BARCELONA,
    BOLSA_VALORES_BILBAO,
    BOLSA_MADRID,
    MERCADO_CONTINUO_ESPANOL,
    MERCADO_ALTERNATIVO_BURSATIL,
    } = require('constants/index');

const translatedMarkets = [
        BROKER_MARKET_EMPTY,
        BROKER_MARKET_MADRID, 
        BROKER_MARKET_BARCELONA,
        EURONEXT_EURONEXT_LISBON,
        EURONEXT_EURONEXT_PARIS,
        EURONEXT_EURONEXT_AMSTERDAM,
        EURONEXT_EURONEXT_BRUSSELS,
        EURONEXT_EURONEXT_BRUSSELS_DERIVATIVES,
        BOLSA_DE_BARCELONA,
        BOLSA_VALORES_BILBAO,
        BOLSA_MADRID,
        MERCADO_CONTINUO_ESPANOL,
        MERCADO_ALTERNATIVO_BURSATIL,
        ];

function findByMicCode(miccode) {
    return MICCODE_CONVERSION.find(market => {
        return market.MIC == miccode
    });
}

function getCountryOfMarket(miccode) {
    const market = findByMicCode(miccode);
    return market ? market['COUNTRY'] : undefined
}

function getCountryCodeOfMarket(miccode) {
    const market = findByMicCode(miccode);
    return market ? market['ISO_COUNTRY_CODE'] : undefined;
}

function getOperatingMicOfMarket(miccode) {
    const market = findByMicCode(miccode);
    return market ? market['OPERATING MIC'] : undefined;
}

const getTranslatedMarketName = market => {
    let marketKey = market.replace('- ', '')
    marketKey = marketKey.split(' ').join('_')
    return formatText(`broker-market_${marketKey}`);
}

function getInstitutionDescOfMarket(miccode) {
    const market = findByMicCode(miccode);
    const marketName = market ? market['NAME-INSTITUTION DESCRIPTION'] : undefined;
    if ( marketName && translatedMarkets.includes(marketName)) {
        return getTranslatedMarketName(marketName);
    }
    return marketName;
}

function getAcronymOfMarket(miccode) {
    const market = findByMicCode(miccode);
    return market ? market['ACRONYM'] : undefined;
}

function getCityOfMarket(miccode) {
    const market = findByMicCode(miccode);
    return market ? market['CITY'] : undefined;
}

const getValidMarketName = market => {
    const marketValue = market.trim().toUpperCase();
    if (translatedMarkets.includes(market)) {
        getTranslatedMarketName(marketValue);
    }
    return marketValue;
}

module.exports = {
    findByMicCode,
    getCountryOfMarket,
    getCountryCodeOfMarket,
    getOperatingMicOfMarket,
    getInstitutionDescOfMarket,
    getAcronymOfMarket,
    getCityOfMarket,
    getValidMarketName
}
