// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    files: {},
    uploaded: false,
    uploadedCert: false,
    isFetching: false,
    isFetchingCert: false,
    error: '',
    errorCert: ''
});

const mortgageRequestPropertyValue = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_SUCCESS:
            return state.merge({
                uploaded: true,
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_UPLOAD_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_REQUEST:
            return state.merge({
                isFetchingCert: true
            });
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_SUCCESS:
            return state.merge({
                uploadedCert: true,
                errorCert: '',
                isFetchingCert: false
            });
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_CERT_UPLOAD_FAILURE:
            return state.merge({
                errorCert: action.data.error,
                isFetchingCert: false
            });
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_PAYMENT_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            })
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_ADD_FILE:
            const fileObj = {
                name: action.payload.name,
                data: action.payload.file
            };
            return state.setIn(['files', fileObj.name], fileObj);
        case actionTypes.MORTGAGE_REQUEST_PROPERTY_VALUE_RESET_VIEW:
            return initialState.merge();
        default:
            return state
    }
};

module.exports = mortgageRequestPropertyValue;
