const { ACCOUNT_TYPES } = require('utilities/accountsSortingHelper');
import { ACCOUNTS_BY_COUNTRIES } from 'constants/accounts';

const getCuentaCorrienteIndexes = (accounts, accountsMap) =>{
    return accounts.filter((accountId) => {
        const accountProduct = accountsMap.getIn([accountId, 'newProduct']);
        return accountProduct === '300' || accountProduct === '301'// CUENTA CORRIENTE
    });
}

const accountTypeIs = accountType => account => {
    const productCode = account.get('newProduct');
    const subTypeCode = account.get('accountType');
    const code = `${productCode}${subTypeCode}`;

    return code === accountType;
}

const isCuentaCorrienteIsNotDerivados = account => {
    const productCode = account.get('newProduct');
    const subTypeCode = account.get('accountType');
    const code = `${productCode}${subTypeCode}`;

    return productCode === ACCOUNT_TYPES.cuentaCorriente.otras
        && code !== ACCOUNT_TYPES.cuentaCorriente.cuentaDeDerivados;
}

const accountsToIds = (acc, account) => [...acc, account.get('accountId')];

const getAccountsByCountries = (originCountry, accountCode) =>{
    if (originCountry && ACCOUNTS_BY_COUNTRIES[originCountry]) {
        return ACCOUNTS_BY_COUNTRIES[originCountry][accountCode] ? ACCOUNTS_BY_COUNTRIES[originCountry][accountCode] : ACCOUNTS_BY_COUNTRIES[originCountry].DEFAULT;
    }
    return ACCOUNTS_BY_COUNTRIES.DEFAULT
}

module.exports = {
    getCuentaCorrienteIndexes,
    accountTypeIs,
    accountsToIds,
    isCuentaCorrienteIsNotDerivados,
    getAccountsByCountries
}
