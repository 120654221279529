//@vendors
const Immutable = require('immutable');
const range = require('lodash/utility/range');

//@helpers
const actionTypes = require('constants/actionTypes');
const {
    EURO_TEXT,
    INCREASE_AMOUNT_CARD_MOBILE_RECHARGE,
    MOBILE_VALIDATION_REGEX,
    PHONE_NUMBER_MAX_LENGTH
} = require('constants/index');

const operators = [
    {
        "code": "0001",
        "label": "Telefónica"
    },
    {
        "code": "0002",
        "label": "Vodafone"
    },
    {
        "code": "0003",
        "label": "Orange"
    },
    {
        "code": "0005",
        "label": "Euskaltel"
    },
    {
        "code": "0006",
        "label": "Carrefour Móvil"
    },
    {
        "code": "0007",
        "label": "Yoigo"
    }
];

const amountRangesLimits = {
    Telefónica: {
        min: 10,
        max: 150
    },
    Vodafone: {
        min: 5,
        max: 150
    },
    Orange: {
        min: 5,
        max: 150
    },
    Euskaltel: {
        min: 5,
        max: 180
    },
    'Carrefour Móvil': {
        min: 5,
        max: 150
    },
    Yoigo: {
        min: 5,
        max: 150
    }
};

function formatAmountRangesDropdownsOptions(operator) {
    const operatorLimits = amountRangesLimits[operator];
    const limitsArray = range(operatorLimits.min, operatorLimits.max, INCREASE_AMOUNT_CARD_MOBILE_RECHARGE);
    limitsArray.push(operatorLimits.max);
    return limitsArray;
}

function setInitialState() {
    return Immutable.fromJS({
        operators,
        amountRanges: formatAmountRangesDropdownsOptions('Telefónica'),
        valid: false,
        mobilePhone: '',
        mobilePhoneIsDirty: false,
        isMobilePhoneValid: false,
        selectedOperatorCode: operators[0].code,
        selectedOperator: operators[0].label,
        selectedAmount: {
            amount: amountRangesLimits[operators[0].label].min,
            currency: EURO_TEXT
        },
        extra: {
            cardStatus: '',
            endDate: '',
            limit: {
                currency: EURO_TEXT,
                amount: 0
            }
        }
    });
}

function validateMobilePhone(mobilePhone) {
    return !!mobilePhone &&
        (MOBILE_VALIDATION_REGEX.test(mobilePhone) || mobilePhone.includes('*')) &&
        mobilePhone.length === PHONE_NUMBER_MAX_LENGTH;
}

function validateStep(step) {
    const selectedOperator = step.get('selectedOperator');
    const selectedAmount = step.get('selectedAmount');
    const isMobilePhoneValid = step.get('isMobilePhoneValid');

    return step.merge({
        valid: !!selectedOperator && selectedAmount.get('amount') > 0 && isMobilePhoneValid,
        mobilePhoneIsDirty: true
    });
}

function cardMobileRechargeStep1(state = setInitialState(), action) {
    let operator,
        amount;
    switch (action.type) {
        case actionTypes.CARD_MOBILE_RECHARGE_OPERATOR_CHANGED:
            operator = action.payload.label;
            amount = state.getIn(['amountRanges', operator, 'min']);
            return state.merge({
                selectedOperator: operator,
                selectedOperatorCode: action.payload.code,
                amountRanges: formatAmountRangesDropdownsOptions(operator),
                selectedAmount: {
                    amount: amount,
                    currency: EURO_TEXT
                }
            });
        case actionTypes.CARD_MOBILE_RECHARGE_AMOUNT_CHANGED:
            return state.merge({
                selectedAmount: {
                    amount: action.payload.value,
                    currency: EURO_TEXT
                }
            });
        case actionTypes.CARD_MOBILE_RECHARGE_MOBILE_PHONE_CHANGED:
            return state.merge({
                mobilePhone: action.payload.value,
                isMobilePhoneValid: validateMobilePhone(action.payload.value)
            });
        case actionTypes.CARD_MOBILE_RECHARGE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_MOBILE_RECHARGE_SETUP_STEP:
            return state.merge({
                isMobilePhoneValid: validateMobilePhone(action.payload.mobilePhone),
                mobilePhone: action.payload.mobilePhone,
                mobilePhoneIsDirty: !!action.payload.mobilePhone
            });
        default:
            return state;
    }
}

module.exports = cardMobileRechargeStep1;
