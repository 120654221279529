const { ALPHANUMERIC_CHARACTERS, DEFAULT_COUNTRY, NUMBER_REGEX } = require('constants/index');
const { contractsValidations } = require('constants/contractsValidations');

module.exports = {
    id_component: 'grid-form',
    label: 'contractsView-juniorAccountContactData',
    id: 'tab-form-contact',
    exclude: [],
    buildCondition: 'juniorAccount_showBeneficiaryDataForms',
    grid: [
        [
            {
                id_component: 'phone-line',
                id: 'input-safe-phone-number',
                label: 'contractsView-juniorAccountMobilePhone',
                type_format: 'text',
                type: 'form',
                regex: NUMBER_REGEX,
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_contact_data',
                required: true,
                validations: [
                    {
                        validationFunction: contractsValidations.VALIDATE_PHONE_NUMBER
                    }
                ]
            }
        ],
        [
            {
                id_component: 'section-sub-header',
                id: 'input-safe-phone',
                label: 'contractsView-juniorAccountAddressTitle',
                target: 'junior_form_contact_data',
                type: 'form'
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_tipo_via',
                label: 'contractsView-juniorAccountRoadType',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadTypeOfRoad',
                target: 'junior_form_contact_data',
                options: [],
                order: 3,
                default_value: 'CL', //@hardcoded
                size: 4
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-nombre-via',
                label: 'contractsView-juniorAccountRoadName',
                type_format: 'text',
                type: 'form',
                renderSize: '8/12',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_contact_data',
                required: true,
                size: 8,
                regex: ALPHANUMERIC_CHARACTERS,
                maxLength: 50
            }
        ],
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-numero',
                label: 'contractsView-juniorAccountNumber',
                type_format: 'text',
                type: 'form',
                regex: NUMBER_REGEX,
                maxLength: 5,
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_contact_data',
                size: 4,
                required: true
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-otros-datos',
                label: 'contractsView-juniorAccountOtherData',
                type_format: 'text',
                type: 'form',
                renderSize: '8/12',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_contact_data',
                regex: ALPHANUMERIC_CHARACTERS,
                maxLength: 50,
                size: 8
            }
        ],
        [
            {
                id_component: 'input-safe',
                id: 'input-safe-codigo-postal',
                label: 'contractsView-juniorAccountZipCode',
                type_format: 'text',
                type: 'form',
                regex: NUMBER_REGEX,
                maxLength: 5,
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_contact_data',
                required: true,
                validations: [
                    {
                        validationFunction: contractsValidations.VALIDATE_POSTAL_CODE
                    },
                    {
                        associatedComponent: {
                            id: 'cmb_provincia',
                            target: 'junior_form_contact_data',
                            type: 'form'
                        },
                        validationFunction: contractsValidations.VALIDATE_POSTAL_CODE_PROVINCE
                    }
                ]
            },
            {
                id_component: 'input-safe',
                id: 'input-safe-poblacion',
                label: 'contractsView-juniorAccountPopulation',
                type_format: 'text',
                type: 'form',
                onBlurFn: 'juniorAccount_handleBlur',
                target: 'junior_form_contact_data',
                required: true,
                regex: ALPHANUMERIC_CHARACTERS,
                maxLength: 50
            },
            {
                id_component: 'combo',
                id: 'cmb_provincia',
                label: 'contractsView-juniorAccountProvince',
                type: 'form',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadCmbProvince',
                target: 'junior_form_contact_data',
                options: [],
                order: 3,
                extraValidateComponents: ['input-safe-codigo-postal'],
                required: true
            }
        ],
        [
            {
                id_component: 'combo',
                id: 'cmb_pais',
                label: 'contractsView-juniorAccountCountry',
                type: 'form',
                addFunction: {
                    lib: 'juniorAccount_onChangeSelect',
                    element: 'different_ship_country',
                    type: 'openModal',
                    value: DEFAULT_COUNTRY
                },
                renderSize: '4/12',
                placeholder: 'contractsView-juniorAccountCombosPlaceholder',
                source: 'juniorAccount_loadCmbCountries',
                target: 'junior_form_contact_data',
                msg_error: 'contractsView-juniorAccountDropdownCountryRequired',
                options: [],
                order: 3,
                size: 4,
                required: true
            }
        ]
    ]
};
