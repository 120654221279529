const trim = require('lodash/string/trim');
const IbanValidator = require('iban');

const IbanHelper = {
    getIbanComplexObject(iban) {
        return {
            codbban: iban.slice(4),
            digitodecontrol: iban.slice(2, 4),
            pais: iban.slice(0, 2)
        };
    },
    composeIban({ codbban, digitodecontrol, pais }) {
        return trim(pais) + trim(digitodecontrol) + trim(codbban);
    },
    getControlDigitFromCodBban(codbban) {
        return codbban.substring(8, 10);
    },
    completeWithCharater(code = '' , character = ' ', complete = 31){
        let codeLength = (code.length - complete) * -1;
        const whiteSpaces =  character.repeat(codeLength-1);
        return code.concat(whiteSpaces);
    },
    inv(item) {
        var ret = '';

        for (let a = 0; a <= item.length; a++) ret = ret + item.substr(item.length - a, 1);

        return ret;
    },
    splitIban(iban) {
        const entidad = iban.substr(4, 4);
        const oficina = iban.substr(8, 4);
        const dc = iban.substr(12, 2);
        const cuenta = iban.substr(14, 10);
        return {
            entidad,
            oficina,
            cuenta,
            dc
        }
    },
    DCsearch(entidad, oficina, cuenta) {
        const pesos = new Array();
        pesos[0] = 6;
        pesos[1] = 3;
        pesos[2] = 7;
        pesos[3] = 9;
        pesos[4] = 10;
        pesos[5] = 5;
        pesos[6] = 8;
        pesos[7] = 4;
        pesos[8] = 2;
        pesos[9] = 1;
        const entidadInv = IbanHelper.inv(entidad);
        const oficinaInv = IbanHelper.inv(oficina);
        const cuentaInv = IbanHelper.inv(cuenta);
        const ofient = oficinaInv + entidadInv;

        let suma = 0;
        for (var a = 0; a < 8; a++) suma += ofient.substr(a, 1) * pesos[a];

        let sumaCCC = 0;
        for (a = 0; a < 10; a++) sumaCCC += cuentaInv.substr(a, 1) * pesos[a];

        let dc = 11 - suma % 11;
        let dcCcc = 11 - sumaCCC % 11;

        if (dc == 10) dc = 1;
        if (dc == 11) dc = 0;
        if (dcCcc == 10) dcCcc = 1;
        if (dcCcc == 11) dcCcc = 0;
        return {
            dc,
            dcCcc
        }
    },
    calculateContract(cuenta, entidad = '0073', oficina = '0100') {
        const DC = IbanHelper.DCsearch(entidad, oficina, cuenta);
        return '' + entidad + oficina + DC.dc + DC.dcCcc + cuenta;
    },

    validateCC(cuenta, oficina, entidad, cdc) {
        const { dc, dcCcc } = IbanHelper.DCsearch(entidad, oficina, cuenta);
        return !(entidad.length !== 4 ||  oficina.length !== 4 || cdc.length !== 2 || cuenta.length !== 10 || (dc + '' + dcCcc) !== cdc);
    },
    extraValidationIbanES(iban) { //only works for sepan national, it doesnt work for sepa international ex: france
        const ibanSplited = IbanHelper.splitIban(iban);
        const isValidCC = IbanHelper.validateCC(ibanSplited.cuenta, ibanSplited.oficina, ibanSplited.entidad, ibanSplited.dc);
        return isValidCC;
    },
    extraValidationCCES(cc) { //only works for sepan national, it doesnt work for sepa international ex: france
        return IbanHelper.extraValidationIbanES(`XXXX${cc}`)
    },
    generateIBAN(country, bban) {
        let controlCode;
        // If IbanValidator can't generate a new full IBAN, it will throw an exception.
        // This happen When BBAN does not match the country or viceversa.
        try {
            const fullIban = IbanValidator.fromBBAN(country, bban);
            controlCode = fullIban.slice(2,4);
        } catch (err) {// we return 'XX' to make validation fail.
            controlCode = 'XX';
        }

        return country + controlCode + bban;
    },
    formatAccountTextWithSpaces(text, arr) {
        const length = text.length;
        let result = '';
        let index = 0;
        arr.forEach( num => {
            if(num < length) {
                result += text.substr(index, num)+ ' ';
                index += num;
            }
        });
        result += text.substr(index, length);
        return result;
    },
    isValidIban(iban) {
        return IbanValidator.isValid(iban);
    },
    isValidAccountNumber(countryCode, accountNumber) {
        return IbanValidator.isValidBBAN(countryCode, accountNumber);
    },
    ibanFromAccountNumber(countryCode, accountNumber) {
        return IbanValidator.fromBBAN(countryCode, accountNumber)
    }
}

module.exports = IbanHelper;
