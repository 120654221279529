// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        documentation: {
            error: false,
            isFetching: false,
            isPdfFetching: false,
            isPdfFetched: false,
            success: false,
            documents: [],
            acceptingDocument: false,
            acceptedDocument: false,
            acceptingDocumentFailed: false,
        },
        init: {
            error: false,
            isFetching: false,
            success: false,
            hasBeenFetched: false,
        },
        documents: {
            error: false,
            isFetching: false,
            success: false,
        },
        mail: {
            error: false,
            isFetching: false,
            success: false,
            sent: false,
        },
        operationProcessId: null,
    });
}

const setAcceptedPrecontractualDocumentation = (currentDocumentList, dmsDocumentId) => (
    currentDocumentList.map(currentDocument => {
        let isAccepted = currentDocument.isAccepted;

        if (dmsDocumentId === currentDocument.dmsDocumentId) {
            isAccepted = true;
        }

        return {
            ...currentDocument,
            isAccepted
        }
    })
)

const setPrecontractualDocumentationPdfAdapter = (currentDocumentList, file, dmsDocumentId) => {
    return currentDocumentList.map(currentDocument => {
        let newFile = currentDocument.file;

        if (dmsDocumentId === currentDocument.dmsDocumentId) {
            newFile = `data:application/pdf;base64,${file}`;
        }

        return {
            ...currentDocument,
            file: newFile
        }
    })
}

function preContractualPolling(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.PRE_CONTRACTUAL_POLLING_INIT_REQUEST:
            return state.mergeDeep({
                init: {
                    hasBeenFetched: true,
                    isFetching: true
                }
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_INIT_SUCCESS:
            return state.mergeDeep({
                init: {
                    isFetching: false,
                    success: true
                },
                operationProcessId: action.payload.operationProcessId,
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_INIT_FAILURE:
            return state.mergeDeep({
                init: {
                    isFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorPrecontractual'
                    }
                }
            });

        case actionTypes.PRE_CONTRACTUAL_DOCUMENTATION_LIST_REQUEST:
            return state.mergeDeep({
                documentation: {
                    isFetching: true
                }
            });

        case actionTypes.PRE_CONTRACTUAL_DOCUMENTATION_LIST_SUCCESS:
            return state.mergeDeep({
                documentation: {
                    isFetching: false,
                    documents: action.payload.documents,
                    success: true,
                },
            });

        case actionTypes.PRE_CONTRACTUAL_DOCUMENTATION_LIST_FAILURE:
            return state.mergeDeep({
                documentation: {
                    isFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorPrecontractual'
                    }
                }
            });

        case actionTypes.PRE_CONTRACTUAL_RAW_DOCUMENT_REQUEST:
            return state.mergeDeep({
                documentation: {
                    isPdfFetching: true
                }
            });

        case actionTypes.PRE_CONTRACTUAL_RAW_DOCUMENT_SUCCESS:
            return state.mergeDeep({
                documentation: {
                    isPdfFetching: false,
                    isPdfFetched: true,
                    documents: setPrecontractualDocumentationPdfAdapter(
                        state.get('documentation').get('documents').toJS(),
                        action.payload.file,
                        action.payload.dmsDocumentId
                    )
                },
            });

        case actionTypes.PRE_CONTRACTUAL_RAW_DOCUMENT_FAILURE:
            return state.mergeDeep({
                documentation: {
                    isPdfFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorPrecontractual'
                    }
                }
            });

        case actionTypes.PRE_CONTRACTUAL_POLLING_DOCUMENTS_REQUEST:
            return state.mergeDeep({
                documents: {
                    isFetching: true
                }
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS:
            return state.mergeDeep({
                documentation: {
                    documents: action.payload.documents
                },
                documents: {
                    isFetching: false,
                    success: true,
                    allDocuments: action.payload.allDocuments
                },
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_DOCUMENTS_FAILURE:
            return state.mergeDeep({
                documents: {
                    isFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorPrecontractual'
                    }
                }
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_MAIL_REQUEST:
            return state.mergeDeep({
                mail: {
                    isFetching: true,
                    sent: true,
                }
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_MAIL_FAILURE:
            return state.mergeDeep({
                mail: {
                    isFetching: false,
                    error: {
                        type: 'custom',
                        errorObject: 'serverErrorPrecontractual'
                    }
                }
            });
        case actionTypes.PRE_CONTRACTUAL_POLLING_MAIL_SUCCESS:
            return state.mergeDeep({
                mail: {
                    isFetching: false,
                    success: true,
                },
            });

        case actionTypes.TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_REQUEST:
            return state.mergeDeep({
                documentation: {
                    acceptingDocument: true,
                    acceptedDocument: false,
                    acceptingDocumentFailed: false,
                }
            });

        case actionTypes.TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_SUCCESS:
            return state.mergeDeep({
                documentation: {
                    acceptingDocument: false,
                    acceptedDocument: true,
                    documents: setAcceptedPrecontractualDocumentation(
                        state.get('documentation').get('documents').toJS(),
                        action.payload.dmsDocumentId
                    )
                }
            });

        case actionTypes.TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_FAILURE:
            return state.mergeDeep({
                documentation: {
                    acceptingDocumentFailed: true
                },
            });

        case actionTypes.TERMS_AND_CONDS_LEGAL_ACCEPT_DOCUMENT_RESET:
            return state.mergeDeep({
                documentation: {
                    acceptingDocument: false,
                    acceptedDocument: false,
                    acceptingDocumentFailed: false,
                },
            });

        case actionTypes.PRE_CONTRACTUAL_POLLING_RESET:
            return setInitialState();
        default:
            return state;
    }
}

module.exports = preContractualPolling;
