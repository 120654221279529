// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isDirty: false,
    isValid: false,
    value: '',
});

const validateStep = (state) => state.merge({
    isDirty: true,
    isValid: !!state.get('value'),
});

const openYoungTutorType = (state = initialState, action = {type: null}) => {
    switch (action.type) {
        case actionTypes.OPEN_YOUNG_TUTOR_TYPE_RESET:
            return initialState;

        case actionTypes.OPEN_YOUNG_TUTOR_TYPE_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.OPEN_YOUNG_TUTOR_TYPE_SET:
            return state.merge({
                isDirty: true,
                isValid: !!action.payload.value,
                value: action.payload.value,
            });

        default:
            return state;
    }
};

module.exports = openYoungTutorType;
