// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    BROKER_CONDITIONAL_ORDER_TERM,
    BROKER_STOCK_ORDER_CONDITION_VALUE,
    BROKER_DEFAULT_STOCK_ID,
    BROKER_DEFAULT_STOCK_MARKET_INDEX,
    BROKER_IBEX35_STOCK_MARKET_INDEX,
    BROKER_DEFAULT_CONDITION_TYPE_ID,
    BROKER_DEFAULT_CONDITION_TYPE_VALUE,
    BROKER_RESTRICTED_ORDER_TERM,
    BROKER_INDEX_ORDER_CONDITION_VALUE,
    EURO_TEXT
} = require('constants/index');
const DateHelper = require('utilities/dateHelper');

function setInitialState() {
    const firstDate = DateHelper.getNextBussinessDay(true, false);
    const limitDate = DateHelper.getStockLimitDate(firstDate);
    const excludeDates = DateHelper.getExcludedWeekends(limitDate);
    const validityDate = firstDate.clone();

    return Immutable.fromJS({
        //"Tipo de orden" radio button
        orderTerms: BROKER_CONDITIONAL_ORDER_TERM,
        //"Valor de la condición" radio button
        orderConditionValue: BROKER_STOCK_ORDER_CONDITION_VALUE,
        //Stock selected from the search input (only if "Acción" is selected in "Valor de la condición" radio button)
        stockId: BROKER_DEFAULT_STOCK_ID,
        //Currency of the stock with id stockId
        stockCurrencyCode: EURO_TEXT,
        //ISIN of the stock with id stockId
        stockISIN: '',
        //ISO of the stock with id stockId
        stockISO: '',
        //SpcSymbol of the stock with id stockId
        stockSpcSymbol: '',
        //last value of the stock with id stockId
        stockLast: 0,
        //Market index selected (only if "Índice" is selected in "Valor de la condición" radio button)
        stockMarketIndex: BROKER_DEFAULT_STOCK_MARKET_INDEX,
        //Option selected in "Tipo de condición" dropdown list
        conditionType: BROKER_DEFAULT_CONDITION_TYPE_ID,
        //"Valor de la condición" input value
        conditionTypeValue: BROKER_DEFAULT_CONDITION_TYPE_VALUE,
        //"Plazo de validez" date
        firstDate,
        validityDate,
        limitDate,
        excludeDates,
        justSelected: false,
        valid: false,
        exchangeId: '',
        stockError: false,
        orderConditionError: false
    });
}

function validateStep(step) {
    const validRestricted = step.get('orderTerms') === BROKER_RESTRICTED_ORDER_TERM;
    const validConditional = step.get('orderTerms') === BROKER_CONDITIONAL_ORDER_TERM
        && (step.get('orderConditionValue') === BROKER_INDEX_ORDER_CONDITION_VALUE
        || (step.get('orderConditionValue') === BROKER_STOCK_ORDER_CONDITION_VALUE
        && +step.get('conditionTypeValue') !== BROKER_DEFAULT_CONDITION_TYPE_VALUE
        && step.get('stockISIN')));

    const orderConditionValueIsEmpty = !step.get('conditionTypeValue');

    return step.merge({
        valid: validRestricted || (validConditional && !orderConditionValueIsEmpty),
        stockError: !step.get('stockISIN'),
        orderConditionError: orderConditionValueIsEmpty
    });
}

function handleInputReset(state) {
    if(!state.get('justSelected')) {
        return state.merge({
            stockCurrencyCode: EURO_TEXT,
            stockISIN: '',
            stockISO: '',
            stockSpcSymbol: '',
            stockLast: 0,
            exchangeId: ''
        });
    } else {
        return state.merge({
            justSelected: false
        })
    }
        
}

function brokerStocksAdvancedBuyStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_TERMS_CHANGED:
            return state.merge({
                orderTerms: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_VALUE_CHANGED:
            let stockId = state.get('stockId');
            let stockMarketIndex = state.get('stockMarketIndex');

            if (action.payload.value === BROKER_STOCK_ORDER_CONDITION_VALUE) {
                stockMarketIndex = BROKER_DEFAULT_STOCK_MARKET_INDEX
            }
            else {
                stockId = BROKER_DEFAULT_STOCK_ID;
                stockMarketIndex = BROKER_IBEX35_STOCK_MARKET_INDEX;
            }

            return state.merge({
                orderConditionValue: action.payload.value,
                stockId,
                stockMarketIndex
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_CONDITION_TYPE_CHANGED:
            return state.merge({
                conditionType: action.payload.value,
                conditionTypeValue: 0
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONDITION_TYPE_VALUE_CHANGED:
            return state.merge({
                conditionTypeValue: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_ORDER_TERM_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_INPUT_SEARCH_PRODUCT_SELECTED:
            return state.merge({
                justSelected: true,
                stockError: false,
                stockId: action.payload.value,
                stockCurrencyCode: action.payload.currencyCode,
                stockISIN: action.payload.isin,
                stockISO: action.payload.iso,
                stockSpcSymbol: action.payload.spcSymbol,
                stockLast: action.payload.last,
                exchangeId: action.payload.exchangeId
            });
        case actionTypes.BROKER_SEARCH_SELECTED_RESULT_RESET:
            return handleInputReset(state);
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
            const firstDate = DateHelper.getNextBussinessDay(action.payload.isAvailableToday, action.payload.currentDate);
            const limitDate = DateHelper.getStockLimitDate(firstDate);
            const validityDate = DateHelper.getValidDate(firstDate, limitDate, state.get('validityDate'));
            const excludeDates = DateHelper.getExcludedWeekends(limitDate);

            return state.merge({
                firstDate,
                validityDate,
                limitDate,
                excludeDates
            });
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedBuyStep1;
