// @ utilities
const { usdActionLogger } = require('utilities/contactCenter/wrapUp/usdActionLogger')

const usdWrapUpActionTrace = (store) => {
    return (next) => (action) => {

        // Call wrapup core utility
        usdActionLogger(action, store)

        return next(action);
    };
};

module.exports = usdWrapUpActionTrace;
