/**
 * Created by adderlyjaureguicampos on 03/01/17.
 */
module.exports = {
    title: 'Depósito Inversor Conservador',
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        {
            id_component: 'contract-info',
            id: 'text-info'
        }
    ]
};
