import { call, put, takeLatest } from 'redux-saga/effects';
import {
    countrySetFailed as setFailed,
    countrySetSucceeded as setSucceeded,
} from 'actions/cardControl';
const { success } = require('actions/notifications');
const { formatText } = require('core/i18n').i18n;
import { error as errorAction } from 'actions/notifications';
import { CARD_COUNTRY_SET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { APIPost } from 'utilities/APIRequestHelper';
import mapCountryNames from './mapCountryNames';


function* setCountry({ payload: { active, cardId, countryId, pan, lastDigitsPan } }) {
    try {
        const requestParams = {
            dispatch: null,
            endpoint: 'cardSetCountry',
            url: apiURLBuilder.getURL('cardSetCountry', { pan, country: countryId }),
            query: { enabled: active, lastDigitsPan },
        };
        const locations = yield call(APIPost, null, apiURLBuilder.getURL('cardSetCountry', { pan, country: countryId }), requestParams);
        yield put(setSucceeded({ cardId, locations: locations.zones.map(mapCountryNames) }));
        yield put(success({message: formatText("cardsConfig-showSuccessNotificationCountries")}))
    } catch ({ message }) {
        yield put(setFailed({ active: !active, cardId, countryId, message }));
        yield put(errorAction({ message }, { voice: message }));
    }
}


function* countrySetSaga() {
  yield [
      takeLatest(CARD_COUNTRY_SET_REQUESTED, setCountry),
  ];
}


export default [
  countrySetSaga,
];
export { countrySetSaga, setCountry };
