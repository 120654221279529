// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    files: {},
    isUploading: false,
    errorUploading: {}
});

const holderEdit = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_SUCCESS:
            return state.merge({
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_FAILURE:
            return state.merge({
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_EDIT_ADD_FILE:
            return state.setIn(['files', action.data.fileId], action.data.file)
        case actionTypes.MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_REQUEST:
            return state.set('isUploading', true);
        case actionTypes.MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_SUCCESS:
            return state.set('isUploading', false);
        case actionTypes.MORTGAGE_REQUEST_HOLDER_EDIT_UPLOAD_FILE_ERROR:
            return state.setIn(['errorUploading', action.data.fileId], action.data.error)
        default:
            return state
    }
};

module.exports = holderEdit;
