const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../inputs');

function buildAnotherAccount(prefix) {
    const anotherAccount = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, prefix);

    return { 
        id_component: 'fioc',
        id: prefix + 'another_account',
        exclude: [],
        grid: [
            [
                anotherAccount.input_iban
            ]
        ]
    }

}

module.exports = buildAnotherAccount;