// @ vendors
const { fromJS } = require('immutable');
// @ constants
const {
    AUTHORIZATION_ACTIVATION_FAILURE,
    AUTHORIZATION_ACTIVATION_RESET,
    AUTHORIZATION_ACTIVATION_SUCCESS
} = require('constants/actionTypes');

const initialState = fromJS({
    submitSuccessful: false,
    errorReceived: ''
})

const authorizationActivation = (state = initialState, action) => {
    switch (action.type) {
        case AUTHORIZATION_ACTIVATION_FAILURE:
            return state.merge({
                errorReceived: action.error,
                submitSuccessful: true
            });
        case AUTHORIZATION_ACTIVATION_RESET:
            return initialState;
        case AUTHORIZATION_ACTIVATION_SUCCESS:
            return state.merge({
                submitSuccessful: true
            });
        default:
            return state;
    }
}

module.exports = authorizationActivation;