import { call, put, takeLatest } from 'redux-saga/effects';
import { APIPost } from 'utilities/APIRequestHelper';
import apiURLBuilder from 'core/apiURLBuilder';
import { BROKER_SET_LEGAL_DOCUMENTATION_CHECKED } from 'constants/actionTypes';
import { setLegalDocumentationCheckedSuccess, setLegalDocumentationCheckedFailure } from 'actions/brokerLegalDocumentation';

function* setLegalDocumentationChecked({documentType, product, productDefinition, productType, uuid, marker}) {
    try {
        const requestParams = {
            query: {
                documentType,
                id: uuid,
                product,
                productDefinition,
                productType,
                marker
            }
        }
        yield call(APIPost, null, apiURLBuilder.getURL('brokerSetLegalDocumentationChecked'), requestParams);

        yield put(setLegalDocumentationCheckedSuccess(documentType, product));
    } catch (e) {
        yield put(setLegalDocumentationCheckedFailure(e.error));
    }
}

function* brokerLegalDocumentation() {
    yield [
        takeLatest(BROKER_SET_LEGAL_DOCUMENTATION_CHECKED, setLegalDocumentationChecked)
    ]
}

export default [
    brokerLegalDocumentation
]
