// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');

//@Actions
const actionTypes = require('constants/actionTypes');

const {
    ACCOUNT_INTERVENTION_AUTHORIZED_CODE,
    ACCOUNT_INTERVENTION_AUTHORIZED,
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE,
    ACCOUNT_INTERVENTION_OWNER_CODE,
    ACCOUNT_INTERVENTION_OWNER,
    ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE,
    ACCOUNT_INTERVENTION_REPRESENTATIVE,
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
} = require('constants/index');

const initialState = Immutable.fromJS({
    hireWelcomeAccountError: false,
    hireWelcomeAccountErrorMessage: '',
    confirmationCheckboxes: {
        value: false,
        errorState: false,
        errorMessage: 'contractsView-serviceContractErrorMessage',
    },
    hireWelcomeAccountNumber: {},
    hireWelcomeAccountSuccessful: false,
    hireWelcomePayrollAccountSuccessful: false,
    isFetchingWelcomeAccount: false,
    steps: [
        {
            cmsStepInfo: {
                information_step1: null,
                isFetchingStep1: false,
            },
            valid: true
        },
        {
            valid: false,
            selectedAccount: '',
            isSelectedAccountDirty: false,
            availableInterveners: [],
            interveners: [],
            intervenersOriginal: [],
            intervenersIsFetching: false,
            intervenersModified: false
        },
        {
            hasSignaturePattern: true
        }
    ],
    visibleStep: WIZARD_FIRST_STEP,
    willCancel: false
});

function validateUIStep(state, step) {
    switch (step) {
        case WIZARD_FIRST_STEP:
            return state.merge({'visibleStep': WIZARD_SECOND_STEP});
        case WIZARD_SECOND_STEP:
            const selectedAccount = state.getIn(['steps', SECOND_STEP, 'selectedAccount']);
            const validSecondStep = !!selectedAccount;

            return state
                .mergeIn(['steps', SECOND_STEP], {
                    valid: validSecondStep,
                    isSelectedAccountDirty: true
                })
                .merge({'visibleStep': validSecondStep ? step + 1 : step});
        default:
            return state;
    }
}

const parseInterveners = interveners => {
    const parsedInterveners = interveners
        .map(intervener => {
            const typeNumber = trim(intervener.tipoIntervencion) || '';
            return {
                name: trim(intervener.nombre) || '',
                type: trim(intervener.desIntervencion) || '',
                originalTypeNumber: typeNumber, // This value shouldn't be modified when changing roles
                typeNumber, // This is the value to change when selecting a different role than the one that comes from API
                identification: {
                    code: intervener.numeroPersona || -1,
                    type: trim(intervener.tipoPersona) || ''
                },
                interventionOrder: trim(intervener.ordenIntervencion) || '',
                interventionWay: trim(intervener.formaIntervencion) || '',
                relationship: null,
                error: false
            };
        });

    return parsedInterveners;
}

const filterAvailables = (currentInterveners = [], originalInterveners = []) => {
    const availableInterveners = originalInterveners.filter(intervener => {
        return !currentInterveners.find(originalIntervener => {
            return intervener.get('name') === originalIntervener.get('name');
        });
    });

    return availableInterveners;
}

const hireWelcomeAccount = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING:
            return state.setIn(['steps', FIRST_STEP, 'cmsStepInfo', 'isFetchingStep1'], true);
        case actionTypes.CONTRACT_METHOD_GET_CONTRACT_INFORMATION:
            return state.mergeIn(
                ['steps', FIRST_STEP, 'cmsStepInfo'],
                {
                    information_step1: action.payload.information,
                    isFetchingStep1: false,
                }
            );
        case actionTypes.WELCOME_ACCOUNT_CHECKBOX_CHANGE:
            return state.mergeIn(['confirmationCheckboxes', 'value'], !state.getIn(['confirmationCheckboxes', 'value']));
        case actionTypes.WELCOME_ACCOUNT_HIRE_IN_PROGRESS:
            return state.merge({
                hireWelcomeAccountError: false,
                hireWelcomeAccountSuccessful: false,
                isFetchingWelcomeAccount: true,
            })
        case actionTypes.WELCOME_ACCOUNT_HIRE_FAILURE:
            return state.merge({
                isFetchingWelcomeAccount: false,
                hireWelcomeAccountSuccessful: false,
                hireWelcomeAccountError: true,
                hireWelcomeAccountErrorMessage: action.payload.error
            });
        case actionTypes.WELCOME_ACCOUNT_HIRE_SUCCESS:
            return state.merge({
                isFetchingWelcomeAccount: false,
                hireWelcomeAccountError: false,
                hireWelcomeAccountNumber: action.payload.response.accountNumber,
                hireWelcomeAccountSuccessful: true
            });
        case actionTypes.WELCOME_ACCOUNT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.WELCOME_ACCOUNT_SET_WILL_CANCEL:
                return state.merge({willCancel: action.payload.willCancel});
        case actionTypes.WELCOME_ACCOUNT_VALIDATE_STEP:
            return validateUIStep(state, action.payload.step);
        case actionTypes.WELCOME_ACCOUNT_SET_SELECTED_ACCOUNT:
            return state.setIn(['steps', SECOND_STEP, 'selectedAccount'], action.payload.selectedAccount);
        case actionTypes.WELCOME_ACCOUNT_UPDATE_INTERVENERS_IN_PROGRESS:
            return state.mergeIn(['steps', SECOND_STEP], {
                intervenersIsFetching: true
            });
        case actionTypes.WELCOME_ACCOUNT_UPDATE_INTERVENERS:
            const parsedInterveners = Immutable.fromJS(
                parseInterveners(action.payload.inteveners, state)
            );
            return state.mergeIn(['steps', SECOND_STEP], {
                intervenersIsFetching: false,
                intervenersModified: false,
                interveners: parsedInterveners,
                intervenersOriginal: parsedInterveners
            });
        case actionTypes.WELCOME_ACCOUNT_UPDATE_INTERVENERS_FAILURE:
            return state.mergeIn(['steps', SECOND_STEP], {
                intervenersIsFetching: false,
                interveners: [],
                error: action.payload.error
            });
        case actionTypes.WELCOME_ACCOUNT_REMOVE_INTERVENER: {
            const interveners = state.getIn(['steps', SECOND_STEP, 'interveners']);
            const nextInterveners = interveners.splice(action.payload.index, 1);
            const availableInterveners = filterAvailables(
                nextInterveners,
                action.payload.originalInterveners
            );

            return state.mergeIn(['steps', SECOND_STEP], {
                intervenersModified: true,
                interveners: nextInterveners,
                availableInterveners //Todo: this will be used in add interverner action
            });
        }
        case actionTypes.WELCOME_ACCOUNT_RESET:
            return initialState;
        case actionTypes.WELCOME_ACCOUNT_RESTORE_DEFAULT_INTERVENERS:
            return state.mergeIn(['steps', SECOND_STEP], {
                intervenersModified: false,
                interveners: action.payload.originalInterveners,
                availableInterveners: Immutable.List()
            });
        case actionTypes.WELCOME_ACCOUNT_SET_ACCOUNT_INTERVENER_TYPE: {
            const interveners = state.getIn(['steps', SECOND_STEP, 'interveners']);
            const intervenerType = {
                [ACCOUNT_INTERVENTION_AUTHORIZED_CODE]: ACCOUNT_INTERVENTION_AUTHORIZED,
                [ACCOUNT_INTERVENTION_OWNER_CODE]: ACCOUNT_INTERVENTION_OWNER,
                [ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE]: ACCOUNT_INTERVENTION_REPRESENTATIVE,
                [ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE]: ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE
            };
            let type = action.payload.type;
            let typeNumber;
            const intervenerCode = type;
            type = intervenerType[intervenerCode];
            typeNumber = intervenerCode;

            return state.mergeIn(['steps', SECOND_STEP], {
                intervenersModified: true,
                interveners: interveners.mergeIn([action.payload.index], {
                    type: type,
                    typeNumber: typeNumber
                })
            });
        }
        default:
            return state;
    }
}

module.exports = hireWelcomeAccount;
