import classNames from 'classnames';

const contactCenterClassName = (ccClassName, resident = undefined) => {
    return classNames(resident, __CONTACT_CENTER__ ? ccClassName : undefined)
}

const webappClassName = (webClassName, resident = undefined) => {
    return classNames(resident, !__CONTACT_CENTER__ ? webClassName : undefined)
}

const conditionalClassName = (webClassName, ccClassName, resident = undefined) => {
    return classNames(resident, !__CONTACT_CENTER__ ? webClassName : ccClassName)
}

module.exports = {
    contactCenterClassName,
    webappClassName,
    conditionalClassName,
};