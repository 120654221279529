// @vendors
const Immutable = require('immutable');
const { formatText } = require('core/i18n').i18n;

//@Actions
const actionTypes = require('constants/actionTypes');

//@Constants
const {
    BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
    BROKER_PRODUCT_ADVANCED_SEARCH_TERM,
    BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY,
    BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES,
    BROKER_PRODUCT_ADVANCED_SEARCH_RISK,
    BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE,
    BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_SUB_ASSET_TYPES,
    BROKER_PRODUCT_ADVANCED_SEARCH_MARKET,
    BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES,
    BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER,
    BROKER_MYPROFILE_PRODUCT_FILE,
    BROKER_MYPROFILE_FUND_FILE,
    MYPROFILE_BROKER_EXPLORE_URL
} = require('constants/index');

const FILTERNAME = {
    assetClassValues: 'ASSET_CLASS',
    subAssetClassValues: 'SUB_ASSET_CLASS',
    productCategoryValues: 'PRODUCT_CATEGORY',
    currencyValues: 'CURRENCY',
    riskValues: 'RISK',
    marketValues: 'MARKET',
    managerValues: 'MANAGER',
};

const initCombosConfiguration = (combosConfiguration = {}) => {
    return {
        productCategoryValues: {
            enabled: combosConfiguration.productCategoryEnabled || false,
        },
        assetClassValues: {
            enabled: combosConfiguration.assetClassEnabled || false,
        },
        currencyValues: {
            enabled: combosConfiguration.currencyEnabled || false,
        },
        subAssetClassValues: {
            enabled: combosConfiguration.subAssetClassEnabled || false,
        },
        riskValues: {
            enabled: combosConfiguration.riskEnabled || false,
        },
        marketValues: {
            enabled: combosConfiguration.marketEnabled || false,
        },
        managerValues: {
            enabled: combosConfiguration.managerEnabled || false,
        }
    }
}

const INITIAL_STATE = Immutable.fromJS({
    filters: {
        assetClassValues: [],
        currencyValues: [],
        managerValues: [],
        marketValues: [],
        productCategoryValues: [],
        riskValues: [],
        subAssetClassValues: [],
        assetClassWithSubClassValues: [],
        combosConfiguration: initCombosConfiguration()
    },
    limitedFilters: {},
    disableFilters: false,
    isFetchingFilters: false,
    successFilters: false,
    errorFilters: false,
    isOnlyOneProductSelected: false,
    onlyOneProductTypeSelected: '',
    search: {
        term: '',
        currencyValues: {
            value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
            label: formatText('broker-allOptionsF')
        },
        assetClassValues: Immutable.List(),
        riskValues: {
            value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
            label: formatText('broker-allOptionsM')
        },
        subAssetClassValues: {
            value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
            label: formatText('broker-allOptionsF')
        },
        marketValues: {
            value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
            label: formatText('broker-allOptionsM')
        },
        productCategoryValues: Immutable.List(),
        managerValues: {
            value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
            label: formatText('broker-allOptionsM')
        }
    },
    subAssetsDisabled: true,
    combos: [],
    showClearButton: false,
    filtersConfiguration: false,
    lastFilterSelected: ''
});

const transformationFilterCriteria = {
    [BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY]: (key) => { return key.toUpperCase()},
    [BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES]: (key) => { return formatText(`brokerAdvancedSearch-brokerAdvancedSearch_${key.assetClassValue}`)},
    [BROKER_PRODUCT_ADVANCED_SEARCH_RISK]: (key) => { return key.replace('of','/')},
    [BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE]: (key) => { return formatText(`brokerAdvancedSearch-brokerAdvancedSearch_${key}`)},
    [BROKER_PRODUCT_ADVANCED_SEARCH_MARKET]: (key) => { return formatText(`brokerAdvancedSearch-brokerAdvancedSearch_${key}`)},
    [BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES]: (key) => { return formatText(`brokerAdvancedSearch-brokerAdvancedSearch_${key}`)},
    [BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER]: (key) => { return key }
}

const buildAdaptedFilter = (filterName, filter, allOptionsLabel, assetClass) => {
    let objFilters = [];
    if (filter) {
        if (filter.length !== 1) {
            objFilters.push({
                value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS,
                label: allOptionsLabel
            })
        }
        filter.map((key) => {
            objFilters.push({
                'value': assetClass ? key.assetClassValue : key,
                'label': transformationFilterCriteria[filterName](key)//Poeditor key
            });
        });
    }

    return objFilters;
}

const buildAdaptedSubClassFilter = (filter, allOptionsLabel, assetValue) => {
    if (filter) {
        const assetKey = filter.find((key) => key.get('assetClassValue') === assetValue);
        return buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE, assetKey ? assetKey.get('subAssetClassValues') : [], allOptionsLabel);
    }

    return [];
}

const filtersAdapter = (arr = [], state) => {
    const limitedFilters = state.get('limitedFilters');
    limitedFilters.forEach((array, key) => {
        arr[key] = arr[key].filter(value => array.indexOf(value) !== -1);
    });

    const assetClass = true;
    const allSubAssetsValues = arr.assetsValues.map(asset => {
        return asset.subAssetClassValues;
    }).flat();
    const allSubAssetsValuesProc =  allSubAssetsValues.filter((item, index) => {
        return allSubAssetsValues.indexOf(item) === index;
    });
    return {
        assetClassValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES, arr.assetsValues, formatText('broker-allOptionsF'), assetClass),
        currencyValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY, arr.currencyValues, formatText('broker-allOptionsF')),
        managerValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER, arr.managerValues, formatText('broker-allOptionsM')),
        marketValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_MARKET, arr.marketValues, formatText('broker-allOptionsM')),
        productCategoryValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES, arr.productCategoryValues, formatText('broker-allOptionsM')),
        riskValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_RISK, arr.riskValues, formatText('broker-allOptionsM')),
        assetClassWithSubClassValues: arr.assetsValues,
        subAssetClassValues: buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE, allSubAssetsValuesProc ? allSubAssetsValuesProc : [], formatText('broker-allOptionsF')),
        combosConfiguration: initCombosConfiguration(arr.combosConfiguration)
    }
}

const getAllOptionSelected = (array = []) => {
    return array.find(item => item.value === BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS) ? true : !array.length;
}

const getAllOptionSimpleSelected = (state, product) => {
    return state.getIn(['search', product]).value ? state.getIn(['search', product]).value === BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS : state.getIn(['search', product]).get('value') === BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS;
}

const getAllOptionMultipleSelected = (array = []) => {
    return array.find(item => item.value === BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS) ? true : !array.size;
}

const setSubAssetClassValues = (assetValues, state) => {
    let newState = state;
    const allOptionAssetSelected = getAllOptionSelected(assetValues);
    if (assetValues.length === 0) {
        const allSubAssetsValuesRaw = state.getIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_SUB_ASSET_TYPES]).map(asset => {
            return asset.get('subAssetClassValues');
        }).toJS().flat();
        const allSubAssetsValues = allSubAssetsValuesRaw.filter((item, index) => {
            return allSubAssetsValuesRaw.indexOf(item) === index;
        });
        const subAssetClassValue =  Immutable.fromJS(buildAdaptedFilter(BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE, allSubAssetsValues ? allSubAssetsValues : [], formatText('broker-allOptionsF')));
        newState = newState.setIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE], subAssetClassValue);
    } else if (assetValues.length === 1 && !allOptionAssetSelected) {
        const assetsWithSubAssetsValues = state.getIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_SUB_ASSET_TYPES]);
        const subAssetClassValue = Immutable.fromJS(buildAdaptedSubClassFilter(assetsWithSubAssetsValues, formatText('broker-allOptionsF'), assetValues[0].value));
        newState = newState.setIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE], subAssetClassValue);
    } else {
        newState = newState.setIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE], Immutable.fromJS([]));
    }

    return newState;
}

const getNewCombos = (field, value, state, multiple = false) => {
    let combos = state.get('combos');
    const search = state.get('search');
    const filter = combos.find(item => item.name === FILTERNAME[field]);
    let hasElements;

    if(multiple) {
        hasElements = !getAllOptionSelected(value);
    } else {
        hasElements = value.value !== BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS;
    }

    if(hasElements) {
        //If value has elements
        if(filter && multiple) {
            //Add multiple elements filter
            let filterValue = '';
            search.get(field).forEach(item => filterValue += `${item.value},`);
            filter.value = `${filterValue.slice(0,filterValue.length-1)}`;
            return combos;
        } else if (!filter && multiple) {
            //Update multiple elements filter
            let filterValue = '';
            value.forEach(item => filterValue += item.value);
            return combos.push({ name: FILTERNAME[field], value: filterValue });
        } else if(!filter) {
            //Add only one element
            return combos.push({ name: FILTERNAME[field], value: value.value });
        } else {
            //Update only one element
            filter.value = value.value;
            return combos;
        }
    } else {
        if(field === BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES) {
            //If filter is assetClassValues subAssetClassValues is delete
            const subAssetIndex = combos.findIndex(item => item.name === FILTERNAME[BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE]);
            combos = subAssetIndex >= 0 ? combos.delete(subAssetIndex) : combos;
        }
        //Delete field from combos when value is empty
        const index = combos.findIndex(item => item.name === FILTERNAME[field]);
        return index >= 0 ? combos.delete(index) : combos;
    }
}

const resetMultipleSelect = (field, state) => {
    return state.setIn(['search', field], Immutable.List());
}

const resetSimpleSelect = (field, text, state) => {
    const obj = {value: BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS, label: formatText(text)}
    return state.setIn(['search', field], obj);
}

const showClearButton = (state) => {
    return !getAllOptionSimpleSelected(state, BROKER_PRODUCT_ADVANCED_SEARCH_CURRENCY) || !getAllOptionSimpleSelected(state, BROKER_PRODUCT_ADVANCED_SEARCH_MANAGER) ||
            !getAllOptionSimpleSelected(state, BROKER_PRODUCT_ADVANCED_SEARCH_MARKET) || !getAllOptionSimpleSelected(state, BROKER_PRODUCT_ADVANCED_SEARCH_RISK) ||
            !getAllOptionSimpleSelected(state, BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE) || !getAllOptionMultipleSelected(state.getIn(['search', BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES])) ||
            !getAllOptionMultipleSelected(state.getIn(['search', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES]));
}

const clearDisabledFilters = (state, newState) => {
    const selected = state.get('search');
    let update = false;

    selected.forEach((item, key) => {
        if(key === BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES || key === BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES) {
            item.forEach((itemMulti) => {
                if(!newState.getIn(["filters", key], []).find(filter => filter.get("value") === itemMulti.value)) {
                    update = true;
                    let search = newState.get('search');
                    const subAssetIndex = search.get(key).findIndex(item => item.value === itemMulti.value);
                    const element = subAssetIndex >= 0 ? search.get(key).delete(subAssetIndex) : search.key;
                    newState = element.size >= 1 ? newState.setIn(['search', key], element) : resetMultipleSelect(key, newState);
                }
            });
            if(update) {
                newState = newState.set('combos',  getNewCombos(key, newState.getIn(['search', key]).size ? newState.getIn(['search', key]).toJS() : [], newState, true));
                update = false;
            }
        }
    })

    return newState;
}

const updateConfigFilters = (state, newState) => {
    //Additional logic, the last filter selected will keep the total options of the state before its selection.
    const lastFilterSelected = state.get('lastFilterSelected');
    const contentFilter = state.getIn(['filters', lastFilterSelected]);
    const isAllOptionSelected = lastFilterSelected === BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES || lastFilterSelected === BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES ?
                                getAllOptionMultipleSelected(state.getIn(['search', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES])) :
                                getAllOptionSimpleSelected(state, lastFilterSelected);
    if(!isAllOptionSelected){
        if(lastFilterSelected === BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE) {
            const previousSubAssetState = state.getIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_SUB_ASSET_TYPES]);
            newState = newState.setIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_SUB_ASSET_TYPES], previousSubAssetState);
        } else {
            newState = newState.setIn(['filters', lastFilterSelected], contentFilter);
        }
    }

    //If only one class is selected, subclasses will be loaded
    const selectedClass = state.getIn(['search', BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES]);
    newState = setSubAssetClassValues(selectedClass.toJS(), newState);

    //If only one product is selected, isOnlyOneProductSelected will be loaded for the specific product search
    const searchOnlyOneProduct = state.getIn(['search', BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES]).size === 1;
    const filtersOnlyOneProduct = newState.getIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES])
                                        .toJS()
                                        .filter(item => item.value != BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS).length === 1;
    const isOnlyOneProductSelected = searchOnlyOneProduct || filtersOnlyOneProduct;

    //onlyOneProductTypeSelected is loaded with the only one filter product available value or with the only one filter product selected
    let onlyOneProductTypeSelected = false;
    if(isOnlyOneProductSelected && searchOnlyOneProduct) {
        onlyOneProductTypeSelected = state.getIn(['search', BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES, 0]).value;
    }
    /*else if(isOnlyOneProductSelected && filtersOnlyOneProduct) {
        onlyOneProductTypeSelected = newState.getIn(['filters', BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES])
                                            .toJS()
                                            .filter(item => item.value != BROKER_PRODUCT_ADVANCED_SEARCH_ALL_OPTIONS)[0].value;
    }*/
    newState = newState.merge({
        isOnlyOneProductSelected,
        onlyOneProductTypeSelected
    });

    //If one of the search selections was disabled, that selection will be deleted
    newState = clearDisabledFilters(state, newState);
    return newState.set('showClearButton', showClearButton(state));
}

const brokerProductFiltersSearchReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_REQUEST:
            return state.merge({
                isFetchingFilters: true
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_SUCCESS:
            newState = state.merge({
                isFetchingFilters: false,
                successFilters: true,
                filters: filtersAdapter(action.payload.data, state),
                showClearButton: showClearButton(state),
                filtersConfiguration: false,
                errorFilters: false,
                disableFilters: false
            });
            return newState
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_FAILURE:
            return state.merge({
                isFetchingFilters: false,
                filtersConfiguration: false,
                errorFilters: action.payload.data.error,
                disableFilters: true
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_SUCCESS:
            newState = state.merge({
                isFetchingFilters: false,
                successFilters: true,
                filters: filtersAdapter(action.payload.data, state),
                showClearButton: true,
                filtersConfiguration: true,
                errorFilters: false,
                disableFilters: false
            });
            return updateConfigFilters(state, newState);
        case actionTypes.BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_FAILURE:
            return state.merge({
                isFetchingFilters: false,
                filtersConfiguration: true,
                errorFilters: action.payload.data.error,
                disableFilters: true
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_LIMITED:
            const filterType = action.payload.filterType;
            const limitedFilters = action.payload.limitedFilters;
            return state.setIn(['limitedFilters', filterType], limitedFilters);
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_CLEAR:
            return INITIAL_STATE;
        case actionTypes.BROKER_ADVANCED_SEARCH_FILTER_CHANGE:
            newState = state.setIn(['search', action.payload.field], action.payload.value);
            if(action.payload.field === BROKER_PRODUCT_ADVANCED_SEARCH_TERM) {
                //If field is term then combos is not update
                return newState;
            } else {
                return newState.set('combos',  getNewCombos(action.payload.field, action.payload.value, newState))
                               .set('lastFilterSelected', action.payload.field);
            }
        case actionTypes.BROKER_ADVANCED_SEARCH_MULTIPLE_PRODUCT_FILTER_CHANGE:
            const allOptionSelected = getAllOptionSelected(action.payload.value);
            const productsArray = action.payload.value;
            const firstProduct = productsArray.length > 0 ? action.payload.value[0] : false;

            if(allOptionSelected) {
                newState = resetMultipleSelect(BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES, state);
            } else {
                newState = state.setIn(['search', action.payload.field], Immutable.List(productsArray))
                                .set('isOnlyOneProductSelected', action.payload.value.length === 1)
                                .set('onlyOneProductTypeSelected', action.payload.value.length === 1 ? firstProduct.value : false);
            }
            return newState.set('combos',  getNewCombos(action.payload.field, action.payload.value, newState, true))
                           .set('lastFilterSelected', action.payload.field);
        case actionTypes.BROKER_ADVANCED_SEARCH_MULTIPLE_ASSET_FILTER_CHANGE:
            const allOptionAssetSelected = getAllOptionSelected(action.payload.value);
            const assetsArray = action.payload.value;

            if(allOptionAssetSelected) {
                newState = resetMultipleSelect(BROKER_PRODUCT_ADVANCED_SEARCH_ASSET_TYPES, state);
                newState = resetSimpleSelect(BROKER_PRODUCT_ADVANCED_SEARCH_SUB_ASSET_TYPE, 'broker-allOptionsF', newState);
            } else {
                newState = setSubAssetClassValues(action.payload.value, state);
                newState = newState.setIn(['search', action.payload.field], Immutable.List(assetsArray));
            }
            return newState.set('combos',  getNewCombos(action.payload.field, action.payload.value, newState, true))
                           .set('lastFilterSelected', action.payload.field);
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_REQUEST:
            return state.merge({
                isFetchingSearchRequest: true
,            });
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_SUCCESS:
            return state.merge({
                isFetchingSearchRequest: false
            });
        case actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_FAILURE:
            return state.merge({
                isFetchingSearchRequest: false
            });
            case actionTypes.LOCATION_CHANGE:
            if (!action.payload.pathname.includes(MYPROFILE_BROKER_EXPLORE_URL)
                || action.payload.pathname.includes(BROKER_MYPROFILE_PRODUCT_FILE)
                || action.payload.pathname.includes(BROKER_MYPROFILE_FUND_FILE)) {
                return INITIAL_STATE;
            } else {
                return state
            }
        default:
            return state;
    }
}

module.exports = brokerProductFiltersSearchReducer;
