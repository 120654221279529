const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const step4 = require('./step4');
const { formatText } = require('core/i18n').i18n;
const { EURO_SYMBOL } = require('constants/index');

const contract = {
    title: 'contractsView-personalLoanTitle',
    service: '....',
    last_step: 6,
    define_steps: 4,
    onCancelURL: '/myprofile/global',
    showIntervenerControls: false,
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelAccounts',
    errorBackButtonLink: '/myprofile/cards',//@hardcoded (Until real back url definition)
    breadcrumb: [
        {
            text: 'breadcrumb-loansAndMortgages',
            url: '/myprofile/prestamos-hipotecas'
        },
        {
            text: 'contractsView-personalLoanApplication'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        form_target: 'form_count',
        card_details: [
            {
                id: 'form_count',
                label: '',
                value: [
                    'personal_form_personal_data',
                    'personal_form_address_data',
                    'personal_form_contact_data',
                    'personal_form_loan_information_data',
                    'personal_form_work_information_data'
                ],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            //Form Values
            {
                id: 'personal_form_personal_data',
                value: [],
                visible: []
            },
            {
                id: 'personal_form_address_data',
                value: [],
                visible: []
            },
            {
                id: 'personal_form_contact_data',
                value: [],
                visible: []
            },
            {
                id: 'personal_form_loan_information_data',
                value: [],
                visible: []
            },
            {
                id: 'personal_form_work_information_data',
                value: [],
                visible: []
            },
            {
                id: 'i_solicitado',
                label: 'Importe solicitado',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 1,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 2
                    }
                ]
            },
            {
                id: 'meses_seleccionado',
                label: 'Plazo',
                value: 12,
                type: 'single',
                format_type: formatText('contractsView-months'),
                order: 2,
                confirmation_step: {
                    columns: 2,
                    order: 2,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    },
                    {
                        step: 5,
                        order: 2
                    }
                ]
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'array',
                format_type: '',
                order: 5,
                visible: [
                    {
                        step: 1,
                        order: 5
                    },
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    },
                    {
                        step: 4,
                        order: 5
                    }
                ]
            },
            {
                id: 'work_type_id',
                label: '',
                value: 0,
                type: 'single',
                format_type: '',
                order:0,
                visible:[
                ]
            },
            {
                id: 'activity_id',
                label: '',
                value: 0,
                type: 'single',
                format_type: '',
                order:0,
                visible:[
                ],
                isErrorVisible: false
            },
            {
                id: 'activity_id',
                label: '',
                value: 0,
                type: 'single',
                format_type: '',
                order:0,
                visible:[
                ]
            },
            {
                id: 'excludes_id',
                label: '',
                value: ['tab-datos-trabajador','tab-datos-empresa','tab-datos-otros-ingresos'],
                type: 'single',
                format_type: '',
                order:0,
                visible:[
                ]
            },
            {
                id: 'msg_datos_laborales',
                label: '',
                value: '',
                type: 'single',
                format_type: '',
                order:0,
                visible:[]
            }
        ]
    },
    steps: [
        step1,
        step2,
        step3,
        step4
    ]
};

module.exports = {
    contract
};
