const storage = require('utilities/storage');
const { PRODUCTION_ENVIRONMENT, OCULTO_ENVIRONMENT } = require('constants/index');

const CUSTOMER_SESSION_ID = 'customerSessionId';

const getCSIDFromCookie = () => {
    try {
        const csid = storage.cookies.get(CUSTOMER_SESSION_ID);
        if (!csid){
            return null;
        }
        return csid;
    } catch (e) {
        console.error(e); // eslint-disable-line
    }
};

const setCSID = customerSessionId => {
    try {
        !__CONTACT_CENTER__ && window.cdApi && window.cdApi.setCustomerSessionId && window.cdApi.setCustomerSessionId(customerSessionId)
    } catch (error) {
        if (window.__ENV__ === PRODUCTION_ENVIRONMENT || window.__ENV__ === OCULTO_ENVIRONMENT) {

        console.log(error, 'biocatch') // eslint-disable-line
        }
    }
}

export const changeBiocatchContext = (context) => {
    try {
        !__CONTACT_CENTER__ && context && window.cdApi && window.cdApi.changeContext &&  window.cdApi.changeContext(context)
    } catch (error) {
        if (window.__ENV__ === PRODUCTION_ENVIRONMENT || window.__ENV__ === OCULTO_ENVIRONMENT) {

            console.log(error, 'biocatch') // eslint-disable-line
            }
    }
}

export const initBioCatch = () => {
    try {
        const customerSessionId = getCSIDFromCookie();
        setCSID(customerSessionId);
    }
    catch(e) {
        console.error(e); // eslint-disable-line
    }
}

