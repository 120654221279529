// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        selectedOptionValue: -1,
        titleQuantity: 0,
        limitPrice: 0,
        loading: false,
        valid: false
    });
}

function validateStep(step) {
    const valid = step.get('titleQuantity') !== 0 && step.get('selectedOptionValue') !== -1;
    return step.merge({
        valid
    });
}

function brokerStocksAdvancedSellStep2(state = setInitialState(), action) {

    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED:
            return state.merge({
                selectedOptionValue: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_LIMITED_VALUE_CHANGED:
            return state.merge({
                limitPrice: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_REQUEST:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_SUCCESS:
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_FAILURE:
            return state.merge({
                loading: false
            });
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedSellStep2;
