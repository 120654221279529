const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState() {
    let stepsAmount = 1;
    let steps = [];

    for (let i = 0; i < stepsAmount; i++) {
        steps.push({valid: false})
    }

    return Immutable.fromJS({
        steps,
        visibleStep: 1,
        finalButton: 'mytransfers-applyFor',
        accounts: Immutable.List([]),
        isFetching: false
    });
}

const initialState = setInitialState();

function applyForReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.APPLYFOR_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.data
            })
        case actionTypes.APPLYFOR_SET_STEP_VALID:
            return state.merge({
                steps: state.get('steps').update(action.payload.data, () => {
                    return { valid: true };
                })
            });
        default:
            return state
    }
}

module.exports = applyForReducer;
