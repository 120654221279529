// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false
    });
}

function validateStep(step) {
   return step.merge({
        valid: true,
    });
}

function sendPinByMailStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.SEND_PIN_SET_STEP_VALID_VALIDATE:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = sendPinByMailStep1;
