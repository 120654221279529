// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        valueIsValid: true,
        currentlyOnMifid: false
    });
}

function validateStep(step) {
    return step.merge({
        valid: !!step.get('label'),
        valueIsValid: !!step.get('label')
    });
}

function externalTransferFundStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_FUND:
            return state.merge({
                instrumentId: state.toJS().selected.instrumentId,
                label: state.toJS().selected.label,
                isin: state.toJS().selected.isin,
                currency: state.toJS().selected.currency,
                valueIsValid: true
            });
        case actionTypes.BROKER_CONVENIENCE_MIFID_TEST_RETAKE_TEST:
            return state.merge({
                currentlyOnMifid: true
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SELECT_FUND:
            return state.merge({
                selected: action.payload.value,
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_FUND:
            return state.merge({
                selected: null,
            })
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP:
            return validateStep(state);
    }
    return state;
}

module.exports = externalTransferFundStep2;
