// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isHolder: {
        isCompleted: false,
        stepsValidation: [
            false, false, false
        ],
        stepsRoutes: [
            '/myprofile/mortgage/holder-info/1',
            '/myprofile/mortgage/holder-work/1',
            '/myprofile/mortgage/holder-economic/1',
        ]
    },
    isCoHolder: {
        isCompleted: false,
        stepsValidation: [
            false, false, false
        ],
        stepsRoutes: [
            '/myprofile/mortgage/co-holder-info/2',
            '/myprofile/mortgage/holder-work/2',
            '/myprofile/mortgage/holder-economic/2',
        ]
    }
});

const holdersDataValidation = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_UPDATE_HOLDERS_INFORMATION:
            const { holderKey, values } = action.data;
            return state.mergeIn([holderKey], {
                ...values
            });

        case actionTypes.MORTGAGE_RESET_COHOLDER_INFORMATION:
            return state.mergeIn(['isCoHolder'], {
                isCompleted: false,
                stepsValidation: [
                    false, false, false
                ],
            });

        default:
            return state;
    }
};

module.exports = holdersDataValidation;
