// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');

const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP
} = require('constants/index');

const setInitialState = () => (
    Immutable.fromJS({
        steps: [
            {
                isFetching: false,
                documentErrorMessage: '',
                country: DEFAULT_ORIGIN_COUNTRY,
                response: {}
            },
            {
                isFetching: false,
                interactionIdErrorMessage: '',
            },
            {
                isFetching: false,
                passwordError: {},
                token :''
            }
        ],
        interactionId: '',
        visibleStep: 1,
        unrecoverableError: false,
        submitSuccessful: false
    })
);

const getDataFromResponse = response => {
    const data = {
        'documento': {
            'documento': {
                'codigodocumpersonacorp': get(response, 'documento.documento.codigodocumpersonacorp'),
                'tipodocumpersonacorp': get(response, 'documento.documento.tipodocumpersonacorp'),
            },
            'nombre': get(response, 'documento.nombre'),
            'personaInter': {
                'codigodepersona': get(response, 'documento.personaInter.codigodepersona'),
                'tipodepersona': get(response, 'documento.personaInter.tipodepersona'),
            },
        }
    };

    return Immutable.fromJS(data);
};

const customerFinderReducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.CUSTOMER_FINDER_SEARCH_CLIENT_FETCHING:
            return state.setIn(['steps', FIRST_STEP, 'isFetching'], true);

        case actionTypes.CUSTOMER_FINDER_SEARCH_CLIENT_UPDATE_COUNTRY:
            return state.setIn(['steps', FIRST_STEP, 'country'], action.payload. country);

        case actionTypes.CUSTOMER_FINDER_SEARCH_CLIENT_SUCCESS:
            return state.merge({
                visibleStep: 2
            })
            .setIn(['steps', FIRST_STEP, 'response'], getDataFromResponse(action.payload.response))
            .setIn(['steps', FIRST_STEP, 'isFetching'], false);

        case actionTypes.CUSTOMER_FINDER_SEARCH_CLIENT_FAILURE:
            return state.mergeIn(['steps', FIRST_STEP], {isFetching: false, documentErrorMessage: action.payload.errorMessage})

        case actionTypes.CUSTOMER_FINDER_SEARCH_CLEAN_ERROR_MESSAGE:
            return state.setIn(['steps', FIRST_STEP, 'documentErrorMessage'], '');

        case actionTypes.CUSTOMER_FINDER_SEARCH_EMPTY_DOCUMENT:
            return state.setIn(['steps', FIRST_STEP, 'documentErrorMessage'], action.payload.errorMessage);

        case actionTypes.CUSTOMER_FINDER_SEARCH_UNRECOVERABLE_ERROR:
            return state.merge({
                unrecoverableError: true
            });

        case actionTypes.CUSTOMER_FINDER_SAVE_INTERACTION_ID:
            return state.merge({
                interactionId: action.payload.value
            });

        case actionTypes.CUSTOMER_FINDER_VALIDATE_WITH_API_START:
            return state.setIn([ 'steps', SECOND_STEP, 'isFetching' ], true);

        case actionTypes.CUSTOMER_FINDER_VALIDATE_WITH_API_SUCCESS:
            return state
                .mergeIn([ 'steps', SECOND_STEP ], {
                    isFetching: false,
                    interactionIdErrorMessage: ''
                })
                .set('visibleStep', 3);

        case actionTypes.CUSTOMER_FINDER_SET_INTERACTION_ID_ERROR_MESSAGE:
            return state.mergeIn(['steps', SECOND_STEP], {
                interactionIdErrorMessage: action.payload.errorMessage,
                isFetching: false
            });

        case actionTypes.CUSTOMER_FINDER_SET_PASSWORD_ERROR:
            return state.setIn(['steps', THIRD_STEP, 'passwordError'], Immutable.fromJS(action.payload.error))
                .setIn(['steps', THIRD_STEP, 'isFetching'], false);

        case actionTypes.CUSTOMER_FINDER_AUTHENTICATION_REQUEST_START:
            return state.setIn(['steps', THIRD_STEP, 'isFetching'], true);

        case actionTypes.CUSTOMER_FINDER_AUTHENTICATION_TOKEN_CREDENTIAL:
            return state.setIn(['steps', THIRD_STEP, 'token'], action.payload.token);

        case actionTypes.CUSTOMER_FINDER_AUTHENTICATION_REQUEST_SUCCESSFUL:
            return state.setIn(['steps', THIRD_STEP, 'isFetching'], false)
                .set('submitSuccessful', true);

        case actionTypes.CUSTOMER_FINDER_AUTHENTICATION_REQUEST_ERROR:
            return state.setIn(['steps', THIRD_STEP, 'isFetching'], false)
                .set('unrecoverableError', true);

        case actionTypes.CUSTOMER_FINDER_CLEAR_REQUEST:
            return setInitialState();

        default:
            return state;
    }
};

module.exports = customerFinderReducer;
