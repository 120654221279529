// @helpers
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');

// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');

// @utilities
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    isRemoving: false,
    isFetching: false,
    fetchSuccess: false,
    error: false,
    removeSuccess: false,
    removeError: false,
    byId: {},
    byOrder: [],
    isOutdated: false
});

function processBeneficiaries(state, beneficiaries, contract, economicCode) {
    let byId = {};
    let byOrder = [];

    beneficiaries.forEach( beneficiary => {
        const alias = trim(get(beneficiary, 'identificacionOrden.aliasBeneficiario', get(beneficiary, 'alias', '')));
        const id = hash(alias);

        byId[id] = {
            id,
            'alias': alias,
            'name': trim(get(beneficiary, 'nombreBeneficiario', '')),
            'iban': trim( get(beneficiary, 'cuentaIBAN.pais', '') + get(beneficiary, 'cuentaIBAN.digitodecontrol', '') + get(beneficiary, 'cuentaIBAN.codbban', '') ),
            'country': trim(beneficiary.pais) || trim(get(beneficiary, 'cuentaIBAN.pais')) ,
            'currency': get(beneficiary, 'importe.divisa', EURO_TEXT) || EURO_TEXT,
            'accountNumber': trim(get(beneficiary, 'ctaDestinoTXT', '')), // outside sepa
            'city': trim(get(beneficiary, 'localidad', '')),
            'address': trim(get(beneficiary, 'direccion', '')),
            'isFetchingDetails': false,
            'fetchDetailsSuccess': false,
            'extra': {
                economicCode,
                'type': trim(get(beneficiary, 'tipoDestinatario'), ''),
                'contract': {
                    'product': get(contract, 'producto'),
                    'number': get(contract, 'numerodecontrato')
                }
            },
        };

        byOrder.push(id);
    });

    return state.merge({
        isFetching: false,
        fetchSuccess: true,
        isOutdated: false
    })
    .set('byId', Immutable.fromJS(byId))
    .set('byOrder', Immutable.fromJS(byOrder));
}

function beneficiariesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_BENEFICIARIES_REQUEST:
            return state.mergeDeep({
                isFetching: true,
                error: false,
                byOrder: [],
                byId: {}
            });
        case actionTypes.FETCH_BENEFICIARIES_SUCCESS:
            return processBeneficiaries(state, action.payload.beneficiaries, action.payload.contract, action.payload.economicCode);
        case actionTypes.FETCH_BENEFICIARIES_FAILURE:
            return state.mergeDeep({
                isFetching: false,
                error: action.payload.error
            });
        case actionTypes.REMOVE_BENEFICIARY_REQUEST:
            return state.mergeDeep({
                isRemoving: true,
                removeSuccess: false,
                removeError: false
            });
        case actionTypes.REMOVE_BENEFICIARY_SUCCESS:
            return state.mergeDeep({
                isRemoving: false,
                removeSuccess: true,
                isOutdated: true
            });
        case actionTypes.REMOVE_BENEFICIARY_FAILURE:
            return state.mergeDeep({
                isRemoving: false,
                removeError: true,
            });
        case actionTypes.BENEFICIARY_DETAILS_FETCH:
            return state.mergeDeep({
                byId: {
                    [action.payload.beneficiaryId]: {
                        isFetchingDetails: true
                    }
                }
            });
        case actionTypes.BENEFICIARY_DETAILS_SUCCESS:
            const bicObj = action.payload.data.bic || {};
            return state.mergeDeep({
                byId: {
                    [action.payload.beneficiaryId]: {
                        isFetchingDetails: false,
                        fetchDetailsSuccess: true,
                        bic: trim(bicObj.entidadbic) + trim(bicObj.paisbic) + trim(bicObj.locatorbic) + trim(bicObj.branch),
                        bankName: action.payload.data.nombreBanco || '',
                        bankAddress: action.payload.data.direccionBanco || '',
                        bankCity: action.payload.data.localidadBanco || '',
                        bankCountry: action.payload.data.paisBanco || ''
                    }
                }
            });
        case actionTypes.BENEFICIARY_DETAILS_FAILURE:
            return state.mergeDeep({
                error: action.payload.error,
                byId: {
                    [action.payload.beneficiaryId]: {
                        isFetchingDetails: false
                    }
                }
            });
        case actionTypes.SET_REMOVE_BENEFICIARY_DEFAULT_VALUES:
            return state.mergeDeep({
                isRemoving: false,
                removeSuccess: false,
                removeError: false
            });
        case actionTypes.ADD_BENEFICIARY_REQUEST_SUCCESS:
            return state.merge({
                isOutdated: true
            });
        default:
            return state
    }

}

module.exports = beneficiariesReducer;
