const Immutable = require('immutable');

const {
    CATEGORIES_TAB_SELECTOR_FETCHING,
    CATEGORIES_TAB_SELECTOR_SUCCESS,
    CATEGORIES_TAB_SELECTOR_FAILURE
} = require('constants/actionTypes');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            error: false,
            fetching: false,
            categoriesTabSelector: []
        })
    );
};

function expensesByCategoriesTabSelector(state = setInitialState(), action) {
    switch (action.type) {
        case CATEGORIES_TAB_SELECTOR_FETCHING:
            return state.merge({
                error: false,
                fetching: true
            });

        case CATEGORIES_TAB_SELECTOR_FAILURE:
            return state.merge({
                error: true,
                fetching: false
            });

        case CATEGORIES_TAB_SELECTOR_SUCCESS:
            return state.merge({
                error: false,
                fetching: false,
                categoriesTabSelector: action.payload.data
            });

        default:
            return state;
    }
}

module.exports = expensesByCategoriesTabSelector;