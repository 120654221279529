// @Vendors
const Immutable = require('immutable');

// @Actions
const actionTypes = require('constants/actionTypes');

// @Constants
const { BROKER_RIGHTS_CASH_EXCHANGE_ERROR_RIGHTS_INPUT } = require('constants/index');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    rightsQuantity: 0,
    rightsQuantityError: false,
    valid: false
});

const brokerRightsCashExchangeStep1 = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_SUCCESS:
            return state.merge({
                isFetching: false,
                valid: true
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATE_STEP_FAILURE:
            return state.merge({
                valid: false,
                rightsQuantityError: action.payload.errorSource === BROKER_RIGHTS_CASH_EXCHANGE_ERROR_RIGHTS_INPUT
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_CHANGE_QUANTITY_INPUT:
            return state.merge({
                rightsQuantity: action.payload.rightsQuantity,
                rightsQuantityError: false
            });
        case actionTypes.BROKER_RIGHTS_CASH_EXCHANGE_VALIDATION_REQUESTED:
            return state.merge({
                error: '',
                isFetching: true
            });
        default: 
            return state;
    }
}

module.exports = brokerRightsCashExchangeStep1;