// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

const setInitialState = () => (
    Immutable.fromJS({
        isFetching: false,
        response: {
            status: '',
            level:'',
        },
        error: {
            error: '',
            errorDescription: '',
        },
        errorReceived: false,
    })
);

const raiseToN2= (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.RAISE_TO_N2_REQUEST:
            return state.merge({
                isFetching: true,
                errorReceived: false,
            });

        case actionTypes.RAISE_TO_N2_FAILURE:
            return state.merge({
                isFetching: false,
                error: {
                    error: action.payload.error,
                    errorDescription: action.payload.errorDescription,
                },
                errorReceived: true,
            });

        case actionTypes.RAISE_TO_N2_SUCCESS:
            return state.merge({
                isFetching: false,
                response: {
                    status: action.payload.status,
                    level: action.payload.level,
                },
                errorReceived: false,
            });
        case actionTypes.RAISE_TO_N2_RESET:
            return setInitialState();
        default:
            return state;
    }
};

module.exports = raiseToN2;
