// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    clickedId: '',
    error: '',
    inboxInsights: [],
    imgURL: '',
    isFetching: false,
    success: false,
});

function personeticsInboxInsightsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_PERSONETICS_INBOX_INSIGHTS_REQUEST:
            return state.merge({
                isFetching: true,
                error: '',
                success: false
            });
        case actionTypes.GET_PERSONETICS_INBOX_INSIGHTS_SUCCESS:
            return state.merge({
                inboxInsights: action.payload.response.insights,
                imgURL: action.payload.response.config.assestsBaseUrl,
                isFetching: false,
                error: '',
                success: true,
            })
        case actionTypes.GET_PERSONETICS_CLICKED_TEASER:
            return state.merge({
                clickedId: action.payload.clickedId,
            })
        case actionTypes.GET_PERSONETICS_INBOX_INSIGHTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
                success: false
            })
        default:
            return state;
    }
}

module.exports = personeticsInboxInsightsReducer;