// @ constants
const {
    ADD_DOCUMENTATION_BUTTON,
    CARD_DESIGN,
    INTERVENER_TYPE,
} = require('constants/index');

module.exports = {
    title: 'contractSummary-payrollAccountTitle',
    accountNumber: 'contractSummary-accountNumber',
    backButtonText: 'contractSummary-backTextAccount',
    backButtonUrl: '/myprofile/accounts',
    seed: 'promotionalCode',
    setSuccessStepPrefix: 'payrollAccount_setSuccessStepPrefix',
    instructions: 'checkingPayrollAccount_instructions',
    details: [
        [
            {
                columns: 12,
                details: [
                    {
                        columns: 5,
                        type: CARD_DESIGN
                    },
                    {
                        columns: 7,
                        interveners: [],
                        title: 'contractsView-interveners',
                        type: INTERVENER_TYPE
                    },
                    {
                        columns: 7,
                        type: ADD_DOCUMENTATION_BUTTON
                    }
                ]
            }
        ]
    ],
    warningMessage: [
        [
            {
                text: 'contractSummary-payrollAccountSuccessCheckInfo'
            }
        ]
    ],
    successWithEmail_warningMessage: [
        [
            {
                text: 'contractSummary-payrollAccountSuccessStepEmail'
            }
        ]
    ],
    successWithEmailWithOneHolder_warningMessage: [
        [
            {
                text: 'contractSummary-payrollAccountSuccessStepEmailWithOneHolder'
            }
        ]
    ],
    seed_warningMessage: [
        [
            {
                text: 'contractSummary-payrollAccountSuccessCheckInfo'
            },
            {
                id: 'upgradeToPayrollAccount-promoCode',
                text: 'contractsView-upgradeToPayrollAccountEmailWarningMessagePromotionCode',
                seed: true
            }
        ]
    ],
    seed_successWithEmail_warningMessage: [
        [
            {
                text: 'contractSummary-payrollAccountSuccessStepEmail'
            },
            {
                id: 'upgradeToPayrollAccount-promoCode',
                text: 'contractsView-upgradeToPayrollAccountEmailWarningMessagePromotionCode',
                seed: true
            }
        ]
    ],
    seed_successWithEmailWithOneHolder_warningMessage: [
        [
            {
                text: 'contractSummary-payrollAccountSuccessStepEmailWithOneHolder'
            },
            {
                id: 'upgradeToPayrollAccount-promoCode',
                text: 'contractsView-upgradeToPayrollAccountEmailWarningMessagePromotionCode',
                seed: true
            }
        ]
    ],
}
