const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { formatText } = require('core/i18n').i18n;
const find = require('lodash/collection/find');
const get = require('lodash/object/get');
const set = require('lodash/object/set');
const trim = require('lodash/string/trim');

const { CARD_PAYMENT_METHOD_FIXED, CARD_PAYMENT_METHOD_UNIQUE, CARD_PAYMENT_METHOD_VARIABLE } = require('constants/index');

function calculateInitialInstallmentsValue(method, methodDescription, min) {
    let methodMessageList;
    let currentValue;
    if (method === CARD_PAYMENT_METHOD_UNIQUE){
        return 100;
    }

    // Very ugly way to catch the current value, but ISBAN returns a string
    if (methodDescription.includes("Cuota Fija")){
        methodMessageList = methodDescription.split(" ");
        currentValue = methodMessageList[2] && parseInt(methodMessageList[2].replace(/[\.]/, '')); // Remove commas (thousand separator) so parseInt works
    } else if (methodDescription.includes("Pago Aplazado")){
        methodMessageList = methodDescription.replace('  ', ' ').split(" ");
        currentValue = methodMessageList[3] && parseInt(methodMessageList[3].replace(/[\.]/, '')); // Remove commas (thousand separator) so parseInt works
    }

    return !!currentValue ? currentValue : min
}

function prepareFixedInstallmentsOptions(fixedInstallments, variableInstallments, cardLimit) {
    const maxPercent = variableInstallments.max / 100
    const maxAmount = Math.min(fixedInstallments.max, Math.ceil(cardLimit * maxPercent));
    const increment = fixedInstallments.inc;
    const percent = variableInstallments.min / 100;
    const minAmount = Math.ceil((percent * cardLimit) / increment) * increment; // Gets the first multiple of the increment from the 10% of the cardLimit

    let results = [];
    for (let amount = minAmount; amount <= maxAmount; amount = amount + increment) {
        results.push(
            { value: amount, label: `${amount} ${formatText('currencies-EUR')}`}
        );
    }
    return results;
}

function prepareVariableInstallmentsOptions(variableInstallments) {
    const maxPercentage = Math.min(100, variableInstallments.max);
    const minPercentage = Math.max(0, variableInstallments.min);
    const increment = variableInstallments.inc;
    let results = [];
    for (let percentage = minPercentage; percentage <= maxPercentage; percentage = percentage + increment) {
        results.push(
            { value: percentage, label: `${percentage} %`}
        );
    }
    return results;
}


function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        method: '',
        methodDescription: '',
        fixedPay: {},
        fixedInstallments: {
            options: []
        },
        variableInstallments: {},
        marketCode: '',
        hiddenMarketCode: '',
        referenceStandard: '',
        currentPaymentMethod: '',
        currentPaymentMode: '',
        hiddenCurrentPaymentMode: '',
        currentSettlementType: '',
        proccessInit: false,
        installments: 0,
        newIdRango: 0,
        newSettlementType: 0
    });
}

function calculateNewIdRango(method, state) {
    switch ( method){
        case CARD_PAYMENT_METHOD_UNIQUE: //PM
            return state.get('fixedPay').get('idRangoFP');
        case CARD_PAYMENT_METHOD_FIXED:  //CF
            return state.get('fixedInstallments').get('idRangoFP');
        case CARD_PAYMENT_METHOD_VARIABLE: //PA
            return state.get('variableInstallments').get('idRangoFP');
    }
}

const calculateNewSettlement = (method, state) => {
        switch ( method){
        case CARD_PAYMENT_METHOD_UNIQUE: //PM
            return state.get('fixedPay').get('settlementType');
        case CARD_PAYMENT_METHOD_FIXED:  //CF
            return state.get('fixedInstallments').get('settlementType');
        case CARD_PAYMENT_METHOD_VARIABLE: //PA
            return state.get('variableInstallments').get('settlementType');
    }
}

function validateStep(step) {
    const hasMethod = !!step.get('method');

    return step.merge({
        valid: hasMethod
    });
}


function processInitialData(payload){
    const { response, cardLimit } = payload;
    const items = get(response, 'lista.dato', []);
    const fixedInstallmentsData = find(items, (obj) => obj.formaDePagoNueva === CARD_PAYMENT_METHOD_FIXED);
    const variableInstallmentsData = find(items, (obj) => obj.formaDePagoNueva === CARD_PAYMENT_METHOD_VARIABLE);

    const totalPayData = find(items, (obj) => obj.formaDePagoNueva === CARD_PAYMENT_METHOD_UNIQUE) || {};

    let initialData = {
        method: response.formaDePagoActual,
        methodDescription: trim(response.descFormPagoActual),
        fixedPay: {
            idRangoFP: totalPayData.idRangoFP,
            settlementType: totalPayData.tipoLiquidacionNueva
        },
        marketCode: response.codigoMercado,
        hiddenMarketCode: response.codigoMercadoOculto,
        referenceStandard: response.estandarDeReferencia,
        currentPaymentMethod: response.formaDePagoActual,
        currentPaymentMode: response.modalidadFormaPagoActual,
        hiddenCurrentPaymentMode: response.modalidadFormaPagoOculto,
        currentSettlementType: response.tipoLiquidacionActual
    };


    initialData.variableInstallments = {};
    if (!!variableInstallmentsData) {
        initialData.variableInstallments = {
            idRangoFP: variableInstallmentsData.idRangoFP,
            min: get(variableInstallmentsData, 'importeMinModali.importe', 0),
            max: get(variableInstallmentsData, 'importeMaxModali.importe', 0),
            inc: get(variableInstallmentsData, 'importeIncModali.importe', 0),
            settlementType: variableInstallmentsData.tipoLiquidacionNueva
        }
        initialData.variableInstallments.options = prepareVariableInstallmentsOptions(initialData.variableInstallments);
    }

    initialData.fixedInstallments = {};
    if (!!fixedInstallmentsData) {
        initialData.fixedInstallments = {
            idRangoFP: fixedInstallmentsData.idRangoFP,
            min: get(fixedInstallmentsData, 'importeMinModali.importe', 0),
            max: get(fixedInstallmentsData, 'importeMaxModali.importe', 0),
            inc: get(fixedInstallmentsData, 'importeIncModali.importe', 0),
            settlementType: fixedInstallmentsData.tipoLiquidacionNueva
        }
        initialData.fixedInstallments.options = prepareFixedInstallmentsOptions(initialData.fixedInstallments, initialData.variableInstallments, cardLimit);
    }

    const productType = get(initialData, 'referenceStandard.subtipodeproducto.tipodeproducto.tipodeproducto');
    set(initialData, 'referenceStandard.subtipodeproducto.tipodeproducto.tipodeproducto', productType || '   ');

    // Depends on selected payment options. Need immutable here to reuse methods on later updates
    let immCardChangeData = Immutable.fromJS(initialData);
    immCardChangeData = immCardChangeData.mergeDeep({
        installments: calculateInitialInstallmentsValue(immCardChangeData.get('method'),immCardChangeData.get('methodDescription'), immCardChangeData.getIn(['fixedInstallments', 'min'])),
        newIdRango: calculateNewIdRango(immCardChangeData.get('method'), immCardChangeData),
        newSettlementType: calculateNewSettlement(immCardChangeData.get('method'), immCardChangeData)
    })

    return immCardChangeData;
}

function cardChangePaymentMethodStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS:
            const cardChangePaymentPayData = processInitialData(action.payload)
            return state.merge(cardChangePaymentPayData);
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_METHOD:
            const method = action.payload.method;
            return state.merge({
                installments: method === CARD_PAYMENT_METHOD_UNIQUE ? 100 : '',
                proccessInit: true,
                method: action.payload.method,
                newSettlementType: calculateNewSettlement(action.payload.method, state),
                newIdRango: calculateNewIdRango(action.payload.method, state)
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_INSTALLMENTS:
            return state.merge({
                installments: action.payload.installments,
                proccessInit: true,
            })
        default:
            return state;
    }
}

module.exports = cardChangePaymentMethodStep1;
