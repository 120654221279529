const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { TYPE_FUNDS } = require('constants/index');

const initialState = Immutable.fromJS({
    error: false,
    loading: false,
    list: Immutable.List([]),
    iban: '',
    balance: {
        currency: '',
        amount: ''
    }
});

function brokerIntervenersFetching() {
    return initialState.merge({
        loading: true,
        error: false
    });
}

function brokerIntervenersFailure() {
    return initialState.set('error', true);
}

function extractList(data, type) {
    if (type == TYPE_FUNDS) {
         return data.listadoIntervinientes.intervinientes.map((item) => {
            return item;
        }) || [];
    } else {
         return data.lista.datos.map((item) => {
            return item;
        }) || [];
    }
}

function processBrockerInterveners(state, data, type) {
    let iban = '';
    
    if (type === TYPE_FUNDS && data && data.cccIBAN && data.cccIBAN.codbban) {
        iban = data.cccIBAN.codbban;
    } else if (data && data.cuentaAsociada && data.cuentaAsociada.codbban) {
        iban = data.cuentaAsociada.codbban;
    }

    return initialState.merge({
        loading: false,
        error: false,
        list: extractList(data, type),
        iban: iban.trim(),
        balance: {
            currency: (!type === TYPE_FUNDS ? data.importeCuentaAsociada.divisa : ''),
            amount: (!type === TYPE_FUNDS ? data.importeCuentaAsociada.importe : '')
        }
    });
}

function brokerIntervenersReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_INTERVENERS_SUCCESS:
            return processBrockerInterveners(state, action.payload.data, action.payload.type);
        case actionTypes.BROKER_GET_INTERVENERS_REQUEST:
            return brokerIntervenersFetching();
        case actionTypes.BROKER_GET_INTERVENERS_ERROR:
            return brokerIntervenersFailure();
        default:
            return state;
    }
}

module.exports = brokerIntervenersReducer;
