import countryNameHelper from 'utilities/countryNameHelper';

const mapCountryNames = zone => ({
    ...zone,
    nameLiteral: countryNameHelper(zone.code),
    countries: zone.countries.map(country => ({
        ...country,
        nameLiteral: countryNameHelper(country.name),
    })),
});


export default mapCountryNames;
