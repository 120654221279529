require('./depositWNM.scss');

const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const successStep = require('./successStep');
const customErrors = require('./customErrors');
const {
    EURO_SYMBOL,
    PERCENTAGE_SYMBOL,
    PRECONTRACTUAL_DEPOSIT,
} = require('constants/index');

const {
    DEPOSIT_WNM,
    messages: {DEPOSIT_WNM_AND_GDPR}
} = require('constants/operationTypes');
// @ helpers
const { formatText } = require('core/i18n').i18n;
const { DEPOSIT_WNM_GDPR_PID } = require('constants/gdprPlacementIds');
const { getDateAndPlaceText } = require('routes/contractsView/utilities/contactCenter/depositsPrintedDocumentHelper');
const { DEPOSITS_INTEREST_BAND_COUNTRIES } = require('constants/flagBasedFeatures');

const getContract = (notAllowedTAECountries = true) => ({
    title: __CONTACT_CENTER__ ?
    'contractsView-contractsView_depositWNMContractTitleCC' : 'contractsView-depositWNMContractTitle',
    service: '....',
    last_step: 4,
    showIntervenerControls: true,
    SignParamsFn: 'deposit_loan_SignParams',
    gdprPlacementId: DEPOSIT_WNM_GDPR_PID,
    multiOTPMessageCode: DEPOSIT_WNM_AND_GDPR,
    define_steps: 3,
    successStepType: 'dynamic',
    showHelpBar: true,
    alternativePositionedCheckboxes: true,
    alternativeLegalTextHeight: '393px',
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractSummary-backToDeposits',
    errorBackButtonLink: '/myprofile/deposits',
    onCancelURL: '/myprofile/deposits',
    notShowConfirmationCheckboxes: true,
    hireParamsFn: 'deposit_hireParamsFn',
    biggerLegalTexts: true,
    hasPolling: true,
    productAlias: PRECONTRACTUAL_DEPOSIT,
    warningContractMessage: 'contractsView-depositWNMWarningMessage',
    code_op: DEPOSIT_WNM,
    tealiumStepsLabels: [
        'precontractual',
        'selection',
        'legalinfo',
        'confirmation',
    ],
    customTagging: 'deposittransfer_customTagging',
    tealiumName: 'contractingDeposit',
    getCustomPrintDocument: 'deposit_getCustomPrintDocument',
    bodyClass: 'printer-show-deposit-body',
    printDocumentTranslations: {
        signDate: getDateAndPlaceText(),
        clientName: formatText('contractsView-contractsView_depositPrintDocumentSignText'),
    },
    emailInfo: {
        templateCode: 'TD_01',
    },
    breadcrumb: [
        {
            text: 'breadcrumb-deposits',
            url: '/myprofile/deposits'
        },
        {
            text: 'contractsView-newDepositBreadcrumb'
        }
    ],
    state: {
        deposit_product: {
            type: null,
            subtype: null,
            depositIdentify: null,
            standard: null,
            preContractualAlias: null,
            contractualAlias: null,
            startDate: null,
            name: null,
            max: null,
            min: null,
            context: null,
            wrapup: null,
        },
        isInfoModalOpen: false,
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            {
                id: 'monto_traspasar',
                label: 'contractsView-amountToDeposit',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ]
            },
            {
                id: 'tin',
                label: 'contractsView-tin',
                format_type: PERCENTAGE_SYMBOL,
                value: null,
                type: 'single',
                formatNumber: true,
                notImportantValue: true,
                order: 2,
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ]
            },
            {
                id: 'gross_interest',
                label: 'contractsView-grossInterest',
                value: null,
                type: 'single',
                featureFlags: [DEPOSITS_INTEREST_BAND_COUNTRIES],
                format_type: EURO_SYMBOL,
                formatNumber: true,
                confirmation_step: {
                    columns: 3,
                    highlighted: true,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    }
                ]
            },
            {
                id: 'tae',
                label: 'contractsView-tae',
                value: null,
                format_type: PERCENTAGE_SYMBOL,
                type: 'single',
                order: 3,
                formatNumber: true,
                notImportantValue: true,
                visible: notAllowedTAECountries && [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            {
                id: 'expiration',
                label: 'contractsView-endDate',
                value: null,
                type: 'single',
                order: 4,
                format_type: '',
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            {
                id: 'installment',
                label: 'contractsView-installment',
                value: null,
                type: 'single',
                order: 4,
                format_type: '',
                isLanguageString: true,
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ]
            },
            {
                type: 'separator',
                order: 5,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ]
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'balance_account',
                label: 'contractsView-accountBalanceAssociated',
                value: null,
                type: 'single',
                format_type: EURO_SYMBOL,
                formatNumber: true,
                order: 5,
                visible: [
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    },
                    {
                        step: 4,
                        order: 5
                    }
                ]
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 2
                },
                order: 6,
                visible: [
                    {
                        step: 1,
                        order: 6
                    },
                    {
                        step: 2,
                        order: 6
                    },
                    {
                        step: 3,
                        order: 6
                    },
                    {
                        step: 4,
                        order: 6
                    }
                ]
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'renewDeposit',
                label: '',
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'accountModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'perLiq',
                value: '',
                format_type: '',
                order: 10,
                visible: []
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: null 
                        }
                    ]
                }
            ]
        },
        precontractualConfirmationLabel: [
            {
                text: 'contractsView-contractsView_depositWNMPrecontractualText1'
            },
            {
                text: 'contractsView-contractsView_depositWNMPrecontractualText2',
                href: 'contractsView-contractsView_depositWNMPrecontractualText2URL'
            }
        ],
        preContractDocuments : {
            preContractPdfFileName: formatText('contractsView-contractsView_depositWNMContractualDownload'),
            preContractPdfTitle: formatText('contractsView-depositWNMStep1Title')
        },
        preContractConditionsDocuments: {
            preContractPdfFileName: formatText('contractsView-contractsView_depositWNMPrecontractualDownload'),
            preContractPdfTitle: formatText('contractsView-depositWNMStep1Title'),
        },
    },
    steps: [step1, step2, step3],
    successStep,
    customErrors
});

module.exports = {
    getContract
};

