// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

const setInitialState = () => (
    Immutable.fromJS({
        error: {
            error: '',
            errorDescription: '',
        },
        errorReceived: false,
    })
);


const registerLoginError= (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.REGISTER_LOGIN_FAILURE:
            return state.merge({
                error: {
                    error: action.payload.error,
                    errorDescription: action.payload.errorDescription,
                },
                errorReceived: true,
            });
        default:
            return state;
    }
};

module.exports = registerLoginError;
