const { formatText } = require('core/i18n').i18n;
const {ALPHANUMERIC_REGEX} = require('constants/contactCenter/regex');
const Storage = require('utilities/storage');

const formatClientId = (tipoPersona, codigoPersona) => {
    var paddingString = '0000000000';
    var totalLength = 10;
    tipoPersona = tipoPersona.toString();
    codigoPersona = codigoPersona.toString();

    return tipoPersona + String(paddingString + codigoPersona).slice(-1 * (totalLength - tipoPersona.length));
}

const generateUniqueIdentifierSession = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

const validateInteractionId = (interactionId = '') => {

    if(interactionId.length !== 16) {
        return formatText('stepValidateInteractionId-errorInteractionIdLength');
    }

    if(!(ALPHANUMERIC_REGEX.test(interactionId))) {
        return formatText('stepValidateInteractionId-errorInvalidInteractionId');
    }

    return '';
}

const hasTokenCredential = () => Storage.cookies.get('tokenCredential');

module.exports = {
    formatClientId,
    generateUniqueIdentifierSession,
    hasTokenCredential,
    validateInteractionId,
}
