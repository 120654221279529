const Immutable = require('immutable');

const {
    ACCOUNT_WARNING_MESSAGE_REQUEST_IN_PROGRESS,
    ACCOUNT_WARNING_MESSAGE_REQUEST_SUCCESS,
    ACCOUNT_WARNING_MESSAGE_REQUEST_FAILURE,
} = require('constants/contactCenter/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    isFetching: false,
    hasBeenFetch: false,
    data: {},
    error: null,
});

const accountWarningMessage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCOUNT_WARNING_MESSAGE_REQUEST_IN_PROGRESS:
            return INITIAL_STATE.merge({
                isFetching: true,
            });
        case ACCOUNT_WARNING_MESSAGE_REQUEST_SUCCESS:
            return state.merge({
                isFetching: false,
                hasBeenFetch: true,
                data: action.payload.data,
            });
        case ACCOUNT_WARNING_MESSAGE_REQUEST_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = accountWarningMessage;
