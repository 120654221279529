// @ vendor
const Immutable = require('immutable');
// @ reducers
const accessKeyChangeStep1 = require('./accessKeyChange/accessKeyChangeStep1');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [accessKeyChangeStep1(undefined, { type: null }), { hasSignaturePattern: true }],
        errorReceived: '',
        isFetching: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        cmcContract: {
            lastName: '',
            secondLastName: '',
            contract: {
                contractNumber: '',
                product: ''
            },
            dateOfBirth: '',
            name: '',
            organizationName: '',
            uid: '',
            lastAccess: '',
            keyChange: '',
            keySignature: ''
        }
    });
}

function updateStep(steps, action) {
    return steps.update(FIRST_STEP, step => {
        return accessKeyChangeStep1(step, action);
    });
}

function accessKeyChangeReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.ACCESS_KEY_CHANGE_RESET:
            return setInitialState();
        case actionTypes.ACCESS_KEY_CHANGE_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.ACCESS_KEY_CHANGE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.ACCESS_KEY_CHANGE_SET_FIELD:
            partialSteps = updateStep(state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                inProgress: true
            });
        case actionTypes.PROFILE_EDIT_WILL_CANCEL:
        case actionTypes.PROFILE_EDIT_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        case actionTypes.FETCH_ACCESS_KEY_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_ACCESS_KEY_DATA_SUCCESS:
            return state.mergeDeep({
                cmcContract: action.payload.cmcContract,
                errorReceived: '',
                isFetching: false
            });
        case actionTypes.FETCH_ACCESS_KEY_DATA_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                isFetching: false
            });
        case actionTypes.SET_ACCESS_KEY_CHANGE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.SET_ACCESS_KEY_CHANGE_SUCCESS:
            return state.merge(setInitialState(), {
                submitSuccessful: true,
                inProgress: false
            });
        case actionTypes.SET_ACCESS_KEY_CHANGE_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                isFetching: false,
                submitSuccessful: false,
                inProgress: false
            });
        default:
            return state;
    }
}

module.exports = accessKeyChangeReducer;
