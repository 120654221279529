// @ vendor
const Immutable = require('immutable');

// @helpers
const PersonalDataHelper = require('utilities/personalDataHelper');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    ONLY_LETTERS_ACCENTS_NUMBERS_SPACES_REGEX,
    PHONE_NUMBER_REGEX,
    POSTAL_CODE_REGEX,
} = require('constants/index');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');

const initialStructure = {
    country: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    isDirty: false,
    isValid: false,
    mobile: {
        errorType: '',
        isDirty: false,
        isValid: false,
        value: '',
    },
    number: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    originCountry: '',
    others: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    population: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    postalCode: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    province: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    roadName: {
        isDirty: false,
        isValid: false,
        value: '',
    },
    roadType: {
        isDirty: false,
        isValid: false,
        value: 'CL',
    },
};

const initialState = Immutable.fromJS(
    Object.assign({
        extraInstances: {}
    }, initialStructure)
);

const validateRequiredField = (state, fieldName) => !!state.getIn([fieldName, 'value']);

const validateRegex = (state, fieldName, regex) => regex.test(state.getIn([fieldName, 'value']));

const validateField = (state, fieldName) => {
    let errorType;
    let isValid;
    let validatedField = {
        [fieldName]: {
            isDirty: true,
        }
    };

    switch (fieldName) {
        case 'others':
        case 'population':
        case 'roadName':
            isValid = validateRequiredField(state, fieldName) &&
                validateRegex(state, fieldName, ONLY_LETTERS_ACCENTS_NUMBERS_SPACES_REGEX);
            break;

        case 'mobile':
            if (!validateRequiredField(state, fieldName)) {
                isValid = false;
                errorType = 'required';
            } else if (state.getIn([fieldName, 'value']).indexOf('*') < 0 && !validateRegex(state, fieldName, PHONE_NUMBER_REGEX)){
                isValid = false;
                errorType = 'format';
            } else {
                isValid = true;
            }
            validatedField[fieldName].errorType = errorType;
            break;

        case 'postalCode':
            if (!validateRequiredField(state, fieldName) || !validateRegex(state, fieldName, POSTAL_CODE_REGEX)) {
                isValid = false;
                errorType = 'required';
            } else if (!state.getIn([fieldName, 'value']).startsWith(state.getIn(['province', 'value']))) {
                isValid = false;
                errorType = 'province-mismatch';
            } else {
                isValid = true;
            }
            validatedField[fieldName].errorType = errorType;
            break;

        default:
            isValid = validateRequiredField(state, fieldName);
            break;
    }
    validatedField[fieldName].isValid = isValid;

    return validatedField;
};

const validateStep = (state, hasMobileField, originCountry) => {
    const validatedFields = Object.assign(
        validateField(state, 'roadName'),
        validateField(state, 'number'),
        validateField(state, 'country'),
        ...(hasMobileField ? validateField(state, 'mobile') : {}),
        // TODO: ADDRESS Remove this for validation
        ...(originCountry == DEFAULT_ORIGIN_COUNTRY ? validateField(state, 'province') : {}),
        validateField(state, 'population'),
        // TODO: ADDRESS validateField(state, 'postalCode')
        ...(originCountry == DEFAULT_ORIGIN_COUNTRY ? validateField(state, 'postalCode') : {}),
    );
    const fieldNames = Object.keys(validatedFields);
    let isValid = true;

    for (let i = 0; i < fieldNames.length; i++) {
        if (!validatedFields[fieldNames[i]].isValid) {
            isValid = false;
            break;
        }
    }

    return Object.assign({isValid}, validatedFields);
};

const hireContactData = (state = initialState, action = {type: null}) => {
    const instanceId = !!action.payload && action.payload.instanceId;
    let partialState;
    let newState;

    switch (action.type) {
        case actionTypes.HIRE_CONTACT_DATA_RESET:
            return initialState;

        case actionTypes.HIRE_CONTACT_DATA_SET_ORIGIN_COUNTRY:
            return state.mergeDeep({
                originCountry: action.payload.originCountry,
                country: {
                    value: action.payload.originCountry,
                }
            })

        case actionTypes.HIRE_CONTACT_DATA_CREATE_INSTANCE:
            return state.setIn(['extraInstances', instanceId], Immutable.fromJS(initialStructure));

        case actionTypes.HIRE_CONTACT_DATA_VALIDATE_FIELD:
            partialState = state;
            if (instanceId) {
                partialState = state.getIn(['extraInstances', instanceId]);
            }
            newState = validateField(partialState, action.payload.fieldName, instanceId);

            if (instanceId) {
                return state.mergeDeepIn(['extraInstances', instanceId], newState);
            } else {
                return state.mergeDeep(newState);
            }

        case actionTypes.HIRE_CONTACT_DATA_VALIDATE_STEP:
            partialState = state;
            if (instanceId) {
                partialState = state.getIn(['extraInstances', instanceId]);
            }
            newState = validateStep(partialState, !!action.payload.hasMobileField, state.get('originCountry'));

            if (instanceId) {
                return state.mergeDeepIn(['extraInstances', instanceId], newState);
            } else {
                return state.mergeDeep(newState);
            }

        case actionTypes.HIRE_CONTACT_DATA_SET_FIELD:
            partialState = {
                [action.payload.field]:{
                    isDirty: true,
                    isValid: true, // This is to hide error messages when user amends validation errors
                    value: action.payload.value,
                },
                isDirty: true,
            };

            if (instanceId) {
                return state.mergeDeepIn(['extraInstances', instanceId], partialState);
            } else {
                return state.merge(partialState);
            }

        case actionTypes.HIRE_CONTACT_DATA_FILL_OPEN_YOUNG_PROFILE:
            const immOpenYoungProfile = action.payload.immOpenYoungProfile;
            newState = {
                country: {
                    value: immOpenYoungProfile.get('originCountry'),
                },
                mobile: {
                    value: immOpenYoungProfile.get('mobile'),
                },
                number: {
                    value: immOpenYoungProfile.get('numberStreet'),
                },
                others: {
                    value: immOpenYoungProfile.get('restOfAddress'),
                },
                population: {
                    value: immOpenYoungProfile.get('population'),
                },
                postalCode: {
                    value: immOpenYoungProfile.get('postalCode'),
                },
                province: {
                    value: immOpenYoungProfile.get('province'),
                },
                roadName: {
                    value: immOpenYoungProfile.get('streetName'),
                },
                roadType: {
                    value: immOpenYoungProfile.get('typeOfRoads'),
                },
            };
            for (let key in newState) {
                newState[`${key}Initial`] = state.has(`${key}Initial`) ? state.get(`${key}Initial`) : newState[key];
            }

            if (instanceId) {
                return state.setIn(['extraInstances', instanceId], Immutable.fromJS(newState));
            } else {
                return state.merge(newState);
            }

        case actionTypes.HIRE_CONTACT_DATA_FILL_SHIPPING_ADDRESS_PROFILE:
            const immProfileShippingAddress = action.payload.immProfileShippingAddress;
            newState = {
                country: {
                    value: immProfileShippingAddress.get('country'),
                },
                number: {
                    value: immProfileShippingAddress.get('number'),
                },
                others: {
                    value: immProfileShippingAddress.get('others'),
                },
                population: {
                    value: immProfileShippingAddress.get('population'),
                },
                postalCode: {
                    value: immProfileShippingAddress.get('postalCode'),
                },
                province: {
                    value: immProfileShippingAddress.get('province'),
                },
                roadName: {
                    value: immProfileShippingAddress.get('roadName'),
                },
                roadType: {
                    value: immProfileShippingAddress.get('roadType'),
                },
            };

            for (let key in newState) {
                newState[`${key}Initial`] = state.has(`${key}Initial`) ? state.get(`${key}Initial`) : newState[key];
            }

            return state.merge(newState);

        case actionTypes.HIRE_CONTACT_DATA_FILL_PROFILE:
            const immProfile = action.payload.immProfile;
            const {
                addressCountry,
                locality,
                numberStreet,
                postalCode,
                province,
                restOfAddress,
                streetName,
                typeOfRoads,
            } = PersonalDataHelper.getAddress(immProfile);

            partialState = {
                country: {
                    value: addressCountry,
                },
                mobile: {
                    value: PersonalDataHelper.getPhoneOnly(immProfile),
                },
                number: {
                    value: numberStreet,
                },
                others: {
                    value: restOfAddress,
                },
                population: {
                    value: locality,
                },
                postalCode: {
                    value: postalCode,
                },
                province: {
                    value: province,
                },
                roadName: {
                    value: streetName,
                },
                roadType: {
                    value: typeOfRoads,
                },
            };

            if (instanceId) {
                return state.setIn(['extraInstances', instanceId], Immutable.fromJS(partialState));
            } else {
                return state.merge(partialState);
            }

        default:
            return state;
    }
};

module.exports = hireContactData;
