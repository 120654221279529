module.exports = {
    title: 'contractsView-preApprovedLoanStep1Title',
    order: 1,
    footer_text: '',
    position: 'first',
    components:[
        {
            id_component: 'contract-info',
            id: 'text-info'
        }
    ]
}
