import { call, put, takeLatest } from 'redux-saga/effects';
import {
    channelsSetFailed as setFailed,
    channelsSetSucceeded as setSucceeded,
} from 'actions/cardControl';
import { error as errorAction } from 'actions/notifications';
const { success } = require('actions/notifications');
const { formatText } = require('core/i18n').i18n;
import { CARD_CHANNELS_SET_REQUESTED } from 'constants/actionTypes';
import apiURLBuilder from 'core/apiURLBuilder';
import { APIPost } from 'utilities/APIRequestHelper';
import invertChannels from './invertChannels';
import mapChannels from './mapChannels';
import mapChannelsSend from './mapChannelsSend';


function* setChannels({ payload: { cardId, pan, channels, lastDigitsPan } }) {
    try {
        const requestParams = {
            dispatch: null,
            endpoint: 'cardSetChannels',
            url: apiURLBuilder.getURL('cardSetChannels', { pan }),
            query: mapChannelsSend(channels, lastDigitsPan),
        };
        const response = yield call(APIPost, null, apiURLBuilder.getURL('cardSetChannels', { pan }), requestParams);
        yield put(setSucceeded({ cardId, channels: mapChannels(response) }));
        yield put(success({message: formatText("cardsConfig-showSuccessNotificationChannels")}))
    } catch ({ message }) {
        yield put(setFailed({ cardId, channels: invertChannels(channels), message }));
        yield put(errorAction({ message }, { voice: message }));
    }
}


function* channelsSetSaga() {
    yield [
        takeLatest(CARD_CHANNELS_SET_REQUESTED, setChannels),
    ];
}


export default [
    channelsSetSaga,
];
export { channelsSetSaga, setChannels };
