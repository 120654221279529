// This is the business logic associated with the reducer.
// It mainly transform data to add it to the store.

export function generateDevicesList (devicesList) {

    const hasPhone = devicesList.some(device =>
        Boolean(device.tipoDispositivo) && device.tipoDispositivo.trim() === 'T');

    const hasEmail = devicesList.some(device =>
        Boolean(device.tipoDispositivo) && device.tipoDispositivo.trim() === 'I');

    if (!hasPhone) {
        devicesList.push({
            tipoDispositivo: 'T ',
            detalleContacto: '34-                                                                                                 ',
            numDispositivo: 1,
            horaDesde: '00:00',
            horaHasta: '23:59',
            tipoUso: '01',
            indPreferente: 'S'
        });
    }

    if (!hasEmail) {
        devicesList.push({
            tipoDispositivo: 'I ',
            detalleContacto: '                                                                                                    ',
            numDispositivo: 1,
            horaDesde: '00:00',
            horaHasta: '23:59',
            tipoUso: '01',
            indPreferente: 'S'
        });
    }

    return devicesList;
}


export function generateCollectiveID(profile){
    return `Collective_${profile.collectiveType.company}-${profile.collectiveType.code3}-${profile.collectiveType.code4}`
}