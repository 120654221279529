// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    titlesAmount: 0,
    valid: false,
    operationIndex: -1,
    flagsError: {
        titlesError: false
    }
});

const setTitlesAmount = (immState, titles) => {
    const titlesAmount = Math.max(0, parseInt(titles, 10) || 0);
    return immState.set('titlesAmount', titlesAmount)
                    .mergeIn(['flagsError', 'titlesError'], false);
};

const validate = (immState) => {
    const isAmountOfTitlesValid = immState.get('titlesAmount') > 0;
    return immState.mergeIn(['flagsError', 'titlesError'], !isAmountOfTitlesValid)
                   .set('valid', isAmountOfTitlesValid);
};

function brokerStocksSaleOrderStep1Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_SALE_ORDER_CHANGE_TITLES_AMOUNT:
            return setTitlesAmount(state, action.payload.titles);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_VALIDATE:
            return validate(state);
        case actionTypes.BROKER_STOCKS_SALE_ORDER_SET_OPERATION_INDEX:
            return state.set('operationIndex', action.payload.index);
        default:
            return state;
    }
}

module.exports = brokerStocksSaleOrderStep1Reducer;
