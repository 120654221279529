// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        titlesAmount: 0,
        mustRunMifid: false,
        valid: false,
        isAmountOfTitlesValid: false,
        isAmountOfTitlesError: false
    });
}

function validateStep(immState) {
    if (immState.get('valid')) {
        return immState.set('mustRunMifid', false);
    }
    const isAmountOfTitlesValid = immState.get('titlesAmount') > 0;
    return immState.merge({
        mustRunMifid: isAmountOfTitlesValid,
        isAmountOfTitlesError: !isAmountOfTitlesValid
    });
}

function brokerRequestFinancialOperationChangeStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_CHANGE_TITLES_AMOUNT:
            return state.merge({
                titlesAmount: Math.max(0, parseInt(action.payload.titles, 10) || 0)
            });
        case actionTypes.BROKER_REQUEST_FINANCIAL_OPERATION_CHANGE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_CONVENIENCE_MIFID_TEST_STEP_OMIT_TEST_CONTINUE_SUCCESS:
            return validateStep(state.set('valid', true));
        default:
            return state;
    }
}

module.exports = brokerRequestFinancialOperationChangeStep1;
