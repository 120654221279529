import {
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { APIGet, APIPost } from 'utilities/APIRequestHelper';
import apiURLBuilder from 'core/apiURLBuilder';
import { GET_KITS, SECURITY_KIT_CHANGE_SET_KIT } from 'constants/actionTypes';
import { NERUDA_SET } from 'constants/securityKit';
import {
    changeKitSuccess,
    changeKitFailure ,
    getKitsSuccess,
    getKitsFailure,
    setSecurityKitGetKitIsFetching
} from 'actions/myProfileSecurityKitChange';
import notification from 'actions/notifications';

const { formatText, getLanguage } = require('core/i18n').i18n;

const filterNerudaKit = (response) => {
    response.setCatalog = response.setCatalog.filter(set => NERUDA_SET.includes(set.id));
    response.sets = response.sets.filter(set => NERUDA_SET.includes(set.id));

    return response;
}

function* changeKit({ defaultKit, sets, dataToken}) {
    try {
        const newSets = sets.map(set => {
            return {
                amounts: (set.get('amounts') ? set.get('amounts') : []),
                id: set.get('id'),
                kitId: set.get('kitId'),
            }
        })

        const requestParams = {
            header: { version: 2 },
            query: {
                dataToken,
                sets: newSets,
            }
        }
        yield call(APIPost, null, apiURLBuilder.getURL('securityKitModify'), requestParams);
        yield put(changeKitSuccess(defaultKit, sets));
    } catch (e) {
        const message = formatText('securityKit-editFailure');
        yield all([
            put(changeKitFailure(e.error)),
            put(notification.error({message}, {voice: message}))
        ]);
    }
}

function* getKits() {
    try {
      yield put(setSecurityKitGetKitIsFetching(true));
      const headers = {
        header: {
          version: 2,
        }
      }

      const response = yield call(APIGet, null, apiURLBuilder.getURL('securityKitData', { 'language': getLanguage()}), headers);
      const state = yield select();
      const isNerudaUser = state.profile.get('isNerudaUser');
      if (isNerudaUser){
            yield put(getKitsSuccess(filterNerudaKit(response)));
      } else {
            yield put(getKitsSuccess(response));
      }
    } catch (e) {
        yield put(getKitsFailure(e.error));
    }
}

function* securityKitSagas() {
    yield [
        takeLatest(GET_KITS, getKits),
        takeLatest(SECURITY_KIT_CHANGE_SET_KIT, changeKit)
    ]
}

export default [
    securityKitSagas
]
