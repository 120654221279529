// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    fetchSuccess: false,
    information: '',
    isFetching: false
});

function hireInformationReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_INFORMATION_REQUEST:
            return state.merge({
                error: '',
                fetchSuccess: false,
                isFetching: true
            });
        case actionTypes.HIRE_INFORMATION_SUCCESS:
            return state.merge({
                fetchSuccess: true,
                information: action.payload.information,
                isFetching: false
            });
        case actionTypes.HIRE_INFORMATION_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });

        case actionTypes.HIRE_INFORMATION_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hireInformationReducer;
