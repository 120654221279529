const actionTypes = require('constants/contactCenter/actionTypes');
const Immutable = require('immutable');

const INITIAL_STATE = Immutable.fromJS({
    error: null,
    isFetching: false,
    timer: null,
});

const disasterRecoveryAgentTokenRenewal = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.DISASTER_RECOVERY_RENEW_AGENT_TOKEN_TIMER_START:
            return state.merge({
                timer: action.payload.timer,
            });

        case actionTypes.DISASTER_RECOVERY_RENEW_AGENT_TOKEN_TIMER_STOP:
            return state.merge({
                timer: null,
            });

        case actionTypes.DISASTER_RECOVERY_RENEW_AGENT_TOKEN_REQUEST:
            return state.merge({
                error: null,
                isFetching: true,
            });

        case actionTypes.DISASTER_RECOVERY_RENEW_AGENT_TOKEN_SUCCESS:
            return state.merge({
                error: null,
                isFetching: false,
            });

        case actionTypes.DISASTER_RECOVERY_RENEW_AGENT_TOKEN_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false,
            });

        default:
            return state;
    }
}

module.exports = disasterRecoveryAgentTokenRenewal;
