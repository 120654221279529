// @ vendors
const Immutable = require('immutable');

// @ constants
import {
  AGGREGATOR_REMOVE_ENTITY_REQUEST,
  AGGREGATOR_REMOVE_ENTITY_SUCCESS,
  AGGREGATOR_REMOVE_ENTITY_FAILURE,
  AGGREGATOR_TOGGLE_ACTIVE_ENTITY_REQUEST,
  AGGREGATOR_TOGGLE_ACTIVE_ENTITY_SUCCESS,
  AGGREGATOR_TOGGLE_ACTIVE_ENTITY_FAILURE,
  AGGREGATOR_GET_DETAILS_REQUEST,
  AGGREGATOR_GET_DETAILS_SUCCESS,
  AGGREGATOR_GET_DETAILS_FAILURE,
  AGGREGATOR_GET_ENTITIES_DATA_REQUEST,
  AGGREGATOR_GET_ENTITIES_DATA_SUCCESS,
  AGGREGATOR_GET_ENTITIES_DATA_FAILURE,
  AGGREGATOR_SEND_SECOND_KEY_AUTH_REQUEST,
  AGGREGATOR_SEND_SECOND_KEY_AUTH_FAILURE,
  AGGREGATOR_SEND_SECOND_KEY_AUTH_SUCCESS,
  AGGREGATOR_SEND_SECOND_KEY_AUTH_RESET,
  AGGREGATOR_TINK_LINK_FAILURE,
  AGGREGATOR_TINK_LINK_SUCCESSS,
  AGGREGATOR_TINK_LINK_REQUEST,
} from 'constants/actionTypes';
/*
 */

function setInitialState() {
  return Immutable.fromJS({
    isFetching: false,
    isFetchingRemove: false,
    isFetchingToggle: false,
    isFetchingConsentUrl: false,
    error: null,
    errorRemove: null,
    errorToggle: null,
    errorConsentUrl: null,
    certificate: null,
    firstRequest: true,
    isFetchingSecondKeyAuth: false,
    errorSecondKeyAuth: false,
    succesSecondKeyAuth: false,
    tinkConsentUrl: null,
    data: {
      banks: {},
      products: {},
      aggregation: {},
    },
    details: {},
  });
}

function aggregatorData(state = setInitialState(), action) {
  switch (action.type) {
    case AGGREGATOR_TINK_LINK_SUCCESSS:
      return state.merge({ isFetchingConsentUrl: false, tinkConsentUrl: action.payload.data });
    case AGGREGATOR_TINK_LINK_REQUEST:
      return state.merge({ isFetchingConsentUrl: true, error: null });
    case AGGREGATOR_TINK_LINK_FAILURE:
      return state.merge({ isFetchingConsentUrl: false, error: action.payload.error });
    case AGGREGATOR_GET_ENTITIES_DATA_REQUEST:
      return state.merge({
        isFetching: true,
        error: null,
        firstRequest: action.payload.firstRequest,
      });
    case AGGREGATOR_GET_ENTITIES_DATA_SUCCESS:
      return state.merge({ isFetching: false, data: action.payload.data });
    case AGGREGATOR_GET_ENTITIES_DATA_FAILURE:
      return state.merge({ isFetching: false, error: action.payload.error });
    case AGGREGATOR_REMOVE_ENTITY_REQUEST:
      return state.merge({ isFetchingRemove: true, error: null });
    case AGGREGATOR_REMOVE_ENTITY_SUCCESS: {
      const { entityId } = action.payload;
      return state
        .merge({ isFetchingRemove: false, error: null })
        .removeIn(['data', 'banks', entityId]);
    }
    case AGGREGATOR_REMOVE_ENTITY_FAILURE: {
      const error = action.payload ? action.payload.error : true;
      return state.merge({ isFetchingRemove: false, error: error });
    }
    case AGGREGATOR_GET_DETAILS_REQUEST: {
      const { productId, newRequest } = action.payload;

      return state.updateIn(['details', productId], product =>
        Immutable.fromJS({
          movements: newRequest ? null : product.getIn(['movements']),
          loading: true,
          error: null,
        })
      );
    }
    case AGGREGATOR_GET_DETAILS_SUCCESS: {
      const {
        productId,
        isNew,
        details: { movements, hasMore, url },
      } = action.payload;

      const newMovements = !isNew
        ? state.getIn(['details', productId, 'movements'], []).concat(movements)
        : movements;

      return state.mergeIn(
        ['details', productId],
        Immutable.fromJS({
          movements: newMovements.map(m => Immutable.fromJS(m)),
          url: url,
          loading: false,
          hasMore,
        })
      );
    }
    case AGGREGATOR_GET_DETAILS_FAILURE: {
      const { error, productId } = action.payload;
      return state.mergeIn(['details', productId], Immutable.fromJS({ error, loading: false }));
    }
    case AGGREGATOR_TOGGLE_ACTIVE_ENTITY_REQUEST:
      return state.merge({ isFetchingToggle: true, error: null });
    case AGGREGATOR_TOGGLE_ACTIVE_ENTITY_SUCCESS: {
      const { entityId, value } = action.payload;
      const keys = state
        .getIn(['data', 'products'])
        .keySeq()
        .toList()
        .filter(p => new RegExp(entityId).test(p));

      if (keys.size) {
        let newState = state;
        keys.forEach(k => (newState = newState.setIn(['data', 'products', k, 'visible'], value)));

        return newState;
      } else {
        return state.setIn(['data', 'products', entityId, 'visible'], value);
      }
    }
    case AGGREGATOR_TOGGLE_ACTIVE_ENTITY_FAILURE: {
      const { entityId, value, error } = action.payload;
      return state
        .setIn(['data', 'products', entityId, 'visible'], value)
        .set('errorToggle', error);
    }
    case AGGREGATOR_SEND_SECOND_KEY_AUTH_REQUEST:
      return state.merge({
        isFetchingSecondKeyAuth: true,
        errorSecondKeyAuth: false,
        succesSecondKeyAuth: false,
      });
    case AGGREGATOR_SEND_SECOND_KEY_AUTH_FAILURE:
      return state.merge({
        isFetchingSecondKeyAuth: false,
        errorSecondKeyAuth: true,
        succesSecondKeyAuth: false,
      });
    case AGGREGATOR_SEND_SECOND_KEY_AUTH_SUCCESS:
      return state.merge({
        isFetchingSecondKeyAuth: false,
        errorSecondKeyAuth: false,
        succesSecondKeyAuth: true,
      });
    case AGGREGATOR_SEND_SECOND_KEY_AUTH_RESET:
      return state.merge({
        isFetchingSecondKeyAuth: false,
        errorSecondKeyAuth: false,
        succesSecondKeyAuth: false,
      });
    default:
      return state;
  }
}

module.exports = aggregatorData;
