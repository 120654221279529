// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const findLastIndex = require('lodash/array/findLastIndex');

//@utilities
const { parseStock, parseContract, parseStocksByCategory } = require('./brokerMyInvestmentsStocksParser');

// @constants
const {
    BROKER_PRODUCT_TYPE_ACCIONES,
    BROKER_PRODUCT_TYPE_DERECHOS,
    EURO_TEXT
} = require('constants/index');

function buildStockList(stockList) {
    const results = stockList.map(stock => {
        return parseStock(stock)
    });

    return results;
}

function buildValuesList(productList, contractsList, investedCapital, earnings, actualValue, profitablePercentage, percentageInWallet) {
    const productListLength = productList ? productList.length : 0;

    const stocks = !productList || !contractsList ? [] : buildStockList(productList);

    // (andres): TotalValue will always be in euro
    const currency = EURO_TEXT;

    return {
        earnings: earnings == null ? null : Number(earnings),
        totalInvestment: investedCapital == null ? null : Number(investedCapital),
        totalValue: Number(actualValue) || 0,
        profitablePercentage: Number(profitablePercentage) || 0,
        currency,
        stocks,
        total: productListLength,
        totalExcludedItems: productListLength - stocks.length,
        hasExcludedItems: (productListLength - stocks.length) > 0,
        percentageInWallet
    };
}

function stocksByTypeAdapter(payload) {
    const products = payload.data;
    const valuesList = payload.valuesList;

    const stocksList = buildValuesList(
        parseStocksByCategory(get(products, 'ACCIONES.valores')),
        valuesList,
        get(products, 'ACCIONES.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'ACCIONES.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'ACCIONES.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'ACCIONES.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'ACCIONES.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const warrantsList = buildValuesList(
        get(products, 'WARRANTS.valores'),
        valuesList,
        get(products, 'WARRANTS.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'WARRANTS.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'WARRANTS.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'WARRANTS.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'WARRANTS.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const ETFSList = buildValuesList(
        get(products, 'ETF.valores'),
        valuesList,
        get(products, 'ETF.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'ETF.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'ETF.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'ETF.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'ETF.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const fundsList = buildValuesList(
        get(products, 'FONDOS.valores'),
        valuesList,
        get(products, 'FONDOS.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'FONDOS.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'FONDOS.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'FONDOS.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'FONDOS.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const plansList = buildValuesList(
        get(products, 'PLANES.valores'),
        valuesList,
        get(products, 'PLANES.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'PLANES.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'PLANES.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'PLANES.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'PLANES.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const othersList = buildValuesList(
        get(products, 'OTROS.valores'),
        valuesList,
        get(products, 'OTROS.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'OTROS.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'OTROS.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'OTROS.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'OTROS.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const alternativeFundsList = buildValuesList(
        get(products, 'FONDOS_ALTERNATIVOS.valores'),
        valuesList,
        get(products, 'FONDOS_ALTERNATIVOS.sumarizacion.consolidado.capitalInvertido.importeEur.importe', 0),
        get(products, 'FONDOS_ALTERNATIVOS.sumarizacion.consolidado.rentabilidad.importeEur.importe', 0),
        get(products, 'FONDOS_ALTERNATIVOS.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'FONDOS_ALTERNATIVOS.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe', 0),
        get(products, 'FONDOS_ALTERNATIVOS.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const futuresList = {
        totalValue: 0,
        currency: EURO_TEXT,
        stocks:[],
        total: 0,
        totalExcludedItems: 0,
        hasExcludedItems: false,
        percentageInWallet: undefined
    };

    return {
        funds: fundsList,
        stocks: stocksList,
        alternativeFunds: alternativeFundsList,
        etfs: ETFSList,
        plans: plansList,
        warrants: warrantsList,
        others: othersList,
        futures: futuresList,
    };
}

function stocksByActiveClassAdapter(payload) {
    const products = payload.actives;
    const valuesList = payload.valuesList;

    const mercadoMonetarioList = buildValuesList(
        get(products, '[Mercado monetario].valores'),
        valuesList,
        get(products, '[Mercado monetario].sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, '[Mercado monetario].sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, '[Mercado monetario].sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, '[Mercado monetario].sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, '[Mercado monetario].sumarizacion.consolidado.porcentajeEnCartera')
    );

    const rentaFijaList = buildValuesList(
        get(products, '[Renta fija].valores'),
        valuesList,
        get(products, '[Renta fija].sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, '[Renta fija].sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, '[Renta fija].sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, '[Renta fija].sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, '[Renta fija].sumarizacion.consolidado.porcentajeEnCartera')
    );

    const rentaVariableList = buildValuesList(
        get(products, '[Renta variable].valores'),
        valuesList,
        get(products, '[Renta variable].sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, '[Renta variable].sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, '[Renta variable].sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, '[Renta variable].sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, '[Renta variable].sumarizacion.consolidado.porcentajeEnCartera')
    );

    const gestionAlternativaList = buildValuesList(
        get(products, '[Inversion alternativa].valores'),
        valuesList,
        get(products, '[Inversion alternativa].sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, '[Inversion alternativa].sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, '[Inversion alternativa].sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, '[Inversion alternativa].sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, '[Inversion alternativa].sumarizacion.consolidado.porcentajeEnCartera')
    );

    const activosRealesList = buildValuesList(
        get(products, '[Activos reales].valores'),
        valuesList,
        get(products, '[Activos reales].sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, '[Activos reales].sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, '[Activos reales].sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, '[Activos reales].sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, '[Activos reales].sumarizacion.consolidado.porcentajeEnCartera')
    );

    const mixtosList = buildValuesList(
        get(products, 'Mixto.valores'),
        valuesList,
        get(products, 'Mixto.sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, 'Mixto.sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, 'Mixto.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'Mixto.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, 'Mixto.sumarizacion.consolidado.porcentajeEnCartera')
    );

    const othersList = buildValuesList(
        get(products, 'Otros.valores'),
        valuesList,
        get(products, 'Otros.sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
        get(products, 'Otros.sumarizacion.consolidado.rentabilidad.importeEur.importe'),
        get(products, 'Otros.sumarizacion.consolidado.valor.importeEur.importe', 0),
        get(products, 'Otros.sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
        get(products, 'Otros.sumarizacion.consolidado.porcentajeEnCartera')
    );

    return {
        mercadoMonetario: mercadoMonetarioList,
        rentaFija: rentaFijaList,
        rentaVariable: rentaVariableList,
        gestionAlternativa: gestionAlternativaList,
        activosReales: activosRealesList,
        mixtos: mixtosList,
        others: othersList
    };
}

function stocksByContract(payload) {
    return Immutable.fromJS(payload).map(immContract => {
        return parseContract(immContract);
    });
}

const updateDescripcion = (immState, walletId, description) => {

    return immState
        .set('contracts', immState.get('contracts').map(immContract => {
            const contractNumber = immContract.get('contract');

            if (contractNumber && contractNumber.length >=10) {
                const shortContractNumber = contractNumber.substring(contractNumber.length - 10);

                if (shortContractNumber == walletId) {
                    return immContract.set('descripcion', description);
                }
            }

            return immContract;
        }));
};

function hasAssociatedRights(payload = []) {
    const products = payload;
    const stocksList = get(products, 'ACCIONES.valores', []);
    const rightExists = stocksList.some((elem) => elem.tipoValor == BROKER_PRODUCT_TYPE_DERECHOS);
    let isAssociated = false;

    if (rightExists) {
        const indexOfDerechosLastInstance = findLastIndex(stocksList, elem => elem.tipoValor == BROKER_PRODUCT_TYPE_DERECHOS);
        isAssociated = stocksList[indexOfDerechosLastInstance].tipoValor == BROKER_PRODUCT_TYPE_ACCIONES;
    }

    return isAssociated;
}

module.exports = {
    stocksByTypeAdapter,
    stocksByContract,
    stocksByActiveClassAdapter,
    updateDescripcion,
    hasAssociatedRights
};
