const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const get = require('lodash/object/get');

const initialState = Immutable.fromJS({
    isFetchinglist: false,
    isFetchinglistError: false,
    watchlistContent: [],
    emptyWatchlist: false,
    isModalOpen: false,
    listName: '',
    isDeleteListRequest: false,
    listSelectedId: '',
    listSelectedIdForParam: '',
    savedProductDetails: {},
    editWatchListInProgress: false,
    editWatchListError: false,
    newWatchlistAdded: false
});

const findList = (state, listId) => {
    return state.get('watchlistContent').findIndex((list)=>{
        return list.get('id') === listId;
    });
};

const findProduct = (products, isin) => {
    return products.findIndex((product) => {
        return product.get('isin') === isin;
    });
};

const sortList = (state, sortByCategory, id, order) => {
    const index = findList(state, id);
    const products = state.getIn(['watchlistContent', index, 'products'])
        .sort((product1, product2) => {
            const modifiedItem1 = product1.get(sortByCategory).toString().toUpperCase();
            const modifiedItem2 = product2.get(sortByCategory).toString().toUpperCase();
            return  (modifiedItem1 > modifiedItem2) && (order === "down") ? 1 : -1;
        })
    return state.setIn(['watchlistContent', index, 'products'], products);
};

const parseResponse = (mappedData) => {
    return mappedData.map(list => {
        list.products.map(product => {
            product.unChecked = false;
            return product;
        });
        return list;
    })
};

function brokerWatchlistReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_WATCHLIST_REQUEST:
            return state.set('isFetchinglist', true);
        case actionTypes.BROKER_GET_WATCHLIST_SUCCESS:
            const mappedData = get(action,'payload.data',[]);
            return state.merge({
                isFetchinglist: false,
                emptyWatchlist: false,
                watchlistContent: parseResponse(mappedData)
            });
        case actionTypes.BROKER_GET_WATCHLIST_FAILURE:
            return state.merge({
                isFetchinglist: false,
                isFetchinglistError: true
            });
        case actionTypes.BROKER_SAVE_LIST_NAME:
            const _name = get(action,'payload.listName');
            return state.merge({
                'listName': _name,
                'newWatchlistAdded': false
            });
        case actionTypes.BROKER_CREATE_NEW_LIST:
            const emptyWatchlist = state.getIn(['emptyWatchlist']);
            return state.set('emptyWatchlist', !emptyWatchlist);
        case actionTypes.BROKER_WATCHLIST_MODAL_TOGGLE:
            const currentModalState = action.payload &&  action.payload.value ? state.getIn(['isModalOpen']) : action.payload.value;
            return state.merge({
                'isModalOpen': !currentModalState,
                'newWatchlistAdded': false
            });
        case actionTypes.BROKER_EDIT_WATCHLIST_NAME_IN_PROGRESS:
            return state.merge({
                editWatchListInProgress: true,
                editWatchListError: null
            });
        case actionTypes.BROKER_EDIT_WATCHLIST_NAME_ERROR:
            return state.merge({
                editWatchListInProgress: false,
                editWatchListError: action.payload.error
            });
        case actionTypes.BROKER_EDIT_WATCHLIST_NAME:
            const { name: listName, listId: _listId, products } = action.payload;
            const indexOflistTobeEdit = findList(state, _listId);
            let editedState = state;
            if (indexOflistTobeEdit !== -1) {
                editedState = state.merge({
                    editWatchListInProgress: false,
                    editWatchListError: null
                }).setIn(['watchlistContent', indexOflistTobeEdit, 'name'], listName)
                .setIn(['watchlistContent', indexOflistTobeEdit, 'products'],
                    products
                );
            }
            return editedState;
        case actionTypes.BROKER_DELETE_WATCHLIST_REQUEST:
            return state.merge({
                isDeleteListRequest: true
            });
        case actionTypes.BROKER_DELETE_WATCHLIST_SUCCESS:
            const { listId } = action.payload;
            const watchlistContent = state.get('watchlistContent');
            const indexOflistTobeDeleted = findList(state, listId);
            if (indexOflistTobeDeleted !== -1) {
                return state.merge({
                    isDeleteListRequest: false,
                    watchlistContent: watchlistContent.delete(indexOflistTobeDeleted)
                });
            }

        case actionTypes.BROKER_DELETE_PRODUCT_FROM_LIST_REQUEST:
            return state.merge({
                editWatchListInProgress: true,
                editWatchListError: null
            });

        case actionTypes.BROKER_DELETE_PRODUCT_FROM_LIST_ERROR:
            return state.merge({
                editWatchListInProgress: false,
                editWatchListError: action.payload.error
            });

        case actionTypes.BROKER_DELETE_PRODUCT_FROM_LIST_SUCCESS:
            const { editListId , isin: _isin, status } = action.payload;
            const watchlistContentArray = state.get('watchlistContent');
            const indexRequired = findList(state,  editListId);
            const _products = watchlistContentArray.getIn([indexRequired, 'products']);
            const productIndex = findProduct(_products, _isin);
            if (indexRequired !== -1 && productIndex !== -1) {
                return state.merge({
                    editWatchListInProgress: false,
                    editWatchListError: null,
                    watchlistContent: watchlistContentArray.setIn([indexRequired, 'products', productIndex, 'unChecked'], status)//,_products.delete(productIndex))
                });
            }
        case actionTypes.BROKER_WATCHLIST_SORT:
            const { sortBy, id, order } = action.payload;
            return sortList(state, sortBy, id, order);
        case actionTypes.BROKER_WATCHLIST_SELECTED_LIST:
            const selectId = get(action,'payload.id');
            return state.set('listSelectedId',selectId);
        case actionTypes.BROKER_WATCHLIST_SELECTED_LIST_FOR_PARAM:
            const listSelectedIdForParam = get(action,'payload.id');
            return state.set('listSelectedIdForParam', listSelectedIdForParam);
        case actionTypes.BROKER_WATCHLIST_SAVE_PRODUCT_DETAILS:
            const saveIsin = get(action, 'payload.isin');
            const saveMiccode = get(action, 'payload.micCode');
            const saveProductType = get(action, 'payload.productType');
            const saveProductKey = get(action, 'payload.productKey');
            return state.set('savedProductDetails', {saveIsin, saveMiccode,saveProductType, saveProductKey});
        case actionTypes.BROKER_WATCHLIST_RESET: {
            const watchlistContent = state.get('watchlistContent');
            const listSelectedIdForParam = state.get('listSelectedIdForParam');
            return initialState.merge({
                watchlistContent,
                listSelectedIdForParam
            });
        }
        case actionTypes.BROKER_WATCHLIST_ADDED:
            const newList = Immutable.fromJS(get(action,'payload.data.query'));
            const modifiedWatchlistContent = state.get('watchlistContent').unshift(newList);
            return state.merge({
                'watchlistContent': modifiedWatchlistContent,
                'listName': '',
                'newWatchlistAdded': true
            });
        case actionTypes.BROKER_WATCHLIST_ID_UPDATE:
            return state.merge({
                listSelectedId: ''
            });
        default:
            return state;
    }
}

module.exports = brokerWatchlistReducer;
