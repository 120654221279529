const raSuitabilityTestTags = () => ({
  alert_widget_link: {
    categoriaEvento: 'interaccion en invertimos por ti',
    accionEvento: 'siempre informado',
    etiquetaEvento: 'sigue leyendo',
  },
  moreInfoQ402: {
    categoriaEvento: 'proc-robo-advisor-test-mifid',
    accionEvento: 'new preferencias de sostenibilidad',
    etiquetaEvento: 'ver mas informacion',
  },
  lessInfoQ402: {
    categoriaEvento: 'proc-robo-advisor-test-mifid',
    accionEvento: 'new preferencias de sostenibilidad',
    etiquetaEvento: 'ver menos informacion',
  },
  openModalQ500: {
    categoriaEvento: 'proc-robo-advisor-test-mifid',
    accionEvento: 'new modal sostenibilidad en nuestras estrategias',
    etiquetaEvento: 'vista',
  },
  continueModalQ500: {
    categoriaEvento: 'proc-robo-advisor-test-mifid',
    accionEvento: 'new modal sostenibilidad en nuestras estrategias',
    etiquetaEvento: 'continuar',
  },
  reviewModalQ500: {
    categoriaEvento: 'proc-robo-advisor-test-mifid',
    accionEvento: 'new modal sostenibilidad en nuestras estrategias',
    etiquetaEvento: 'revisar respuestas',
  },
  closeModalQ500: {
    categoriaEvento: 'proc-robo-advisor-test-mifid',
    accionEvento: 'new modal sostenibilidad en nuestras estrategias',
    etiquetaEvento: 'cerrar',
  }
});

module.exports = { raSuitabilityTestTags };
