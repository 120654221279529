// @vendor
const API = require('services/api');
const get = require('lodash/object/get');
const set = require('lodash/object/set');
import { removeCookieAndExpirationTime } from 'ok-login'; // Login interface

// @core
const { cookiesDomain } = require('core/environmentConfig');
const { getDispatch } = require('core/storeHelper'); // Access to the Store dispatch
const apiURLBuilder = require('core/apiURLBuilder');

// @actions
const notification = require('actions/notifications');

// @utilities
const { formatMessage } = require('utilities/apiErrorLogger');
const { isDisasterRecoveryMode } = require('utilities/contactCenter/disasterRecoveryhelper');
const { handleOnUnathotizedError } = require('utilities/securityLevelCheckHelper');
const { manageLogoutTimer, showLogoutModal } = require('utilities/LogoutHelper');
const BrowserUtils = require('utilities/browserUtils');
const Storage = require('utilities/storage');
const usd = require('utilities/usd');

// @commons

// @constants
const KNOWN_API_ERRORS = require('constants/knownApiErrors');
const { TOKEN_CREDENTIAL } = require('constants/session');
const {
    CONNECTION_ERROR,
    TIMEOUT_ERROR,
    CLIENT_ERROR,
    NO_ERROR,
    WARNING,
    FATAL
} = require('constants/index');
const ERROR_SEVERITIES = [NO_ERROR, WARNING, FATAL];


const getMostProbableDescription = (errorDescription, alternativeErrorDescription) => {
    // With no further knowledge, we choose the longer description
    if (errorDescription && alternativeErrorDescription) {
        return errorDescription.length > alternativeErrorDescription.length
            ? errorDescription
            : alternativeErrorDescription;
    }
    return errorDescription ? errorDescription : alternativeErrorDescription;
};

const getErrorSeverity = (description, endpoint) => {
    return get(
        KNOWN_API_ERRORS,
        [description, endpoint],
        get(KNOWN_API_ERRORS, [description, 'default'])
    );
};

const fillResponse = (error, description, severity) => {
    return {
        error: {
            severity,
            status: error.status,
            description
        },
        body: error.response.body, //required by some other code
        text: description
    };
};

const serviceCall = function ({
    service,
    dispatch,
    endpoint,
    params = {isFullResponse: false, hideDefaultSnackbar: false},
    url = apiURLBuilder.getURL(endpoint, params),
    contentType,
    method
}) {
    let errorDescriptionText;
    let errorText;
    let mostProbablyDescription;
    let accessToken = Storage.cookies.get(TOKEN_CREDENTIAL) || '';

    if (__CONTACT_CENTER__) {
        if (!get(params, 'header.openbankAuthToken')) {
            set(params, 'header.openbankAuthToken', accessToken);
        }
        if (!get(params, 'header.openBankInteractionId')) {
            set(params, 'header.openBankInteractionId', usd.getInteractionId());
        }
    } else {
        set(params, 'header.openBankAuthToken', accessToken);
    }

    const parsedUrl = url.replace('http://', 'https://').replace(':80', '');

    // Only reset timer if it is not Contact Center and it's not KeepAlive endpoint
    !__CONTACT_CENTER__
        && url !== apiURLBuilder.getURL('keepAlive')
        && manageLogoutTimer(dispatch, null, accessToken);

    return service(parsedUrl, params, contentType, method)
        .then(response => {
            // If response is a file, we return the complete response
            if (response.type === 'application/pdf' ||
                response.type === 'application/vnd.ms-excel' ||
                response.type === 'text/xml' ||
                response.type === 'application/zip' ||
                response.type === 'application/octet-stream' ||
                !!params.isFullResponse
            ) {
                return response;
            } else {
                return response.body;
            }
        })
        .catch(error => {
            //First check a sort of non-error condition (HTTP authentication challenge)
            errorDescriptionText = get(
                error,
                'response.body.errorDescription',
                get(error, 'response.body', '')
            );
            errorText = get(error, 'response.body.error', get(error, 'response.body', ''));

            if (
                error.status === 401 ||
                errorDescriptionText === 'Incorrect authentication.' ||
                errorDescriptionText === 'No hay usuario logado' ||
                errorDescriptionText === '401 UNAUTHORIZED' ||
                errorText === 'Incorrect authentication.'
            ) {
                if (__CONTACT_CENTER__) {
                    if (!isDisasterRecoveryMode() || url !== apiURLBuilder.getURL('raiseInteractionN1')) {
                        handleOnUnathotizedError(error, dispatch);
                    } else {
                        throw error;
                    }
                } else {
                    showLogoutModal(dispatch);
                }
                
                removeCookieAndExpirationTime({ cookiesDomain: cookiesDomain });
                throw error;
            } else {
                //the error is rethrwon to continue the catch chain
                throw error;
            }
        })
        .catch(error => {
            let response;

            // First we try to match errorDescription
            const errorDescriptionTextSeverity = getErrorSeverity(errorDescriptionText, endpoint);
            if (ERROR_SEVERITIES.includes(errorDescriptionTextSeverity)) {
                response = fillResponse(error, errorDescriptionText, errorDescriptionTextSeverity);

                if (errorDescriptionTextSeverity === NO_ERROR) {
                    return response;
                } else {
                    throw response;
                }
            }

            // Then we try to match error
            const errorTextSeverity = getErrorSeverity(errorText, endpoint);

            if (ERROR_SEVERITIES.includes(errorTextSeverity)) {
                response = fillResponse(error, errorText, errorTextSeverity);

                if (errorTextSeverity === NO_ERROR) {
                    return response;
                } else {
                    throw response;
                }
            }

            // If no match in local errors list, we return any response as an error with WARNING severity
            if (errorDescriptionText || errorText) {
                mostProbablyDescription = getMostProbableDescription(
                    errorDescriptionText,
                    errorText
                );
                throw fillResponse(error, mostProbablyDescription, WARNING);
            }

            const status = get(error, 'response.status', get(error, 'status'));
            const getErrorType = error => {
                if (error.crossDomain) {
                    return CONNECTION_ERROR;
                }
                if (error.timeout) {
                    return TIMEOUT_ERROR;
                }
                if (error.response && error.response.clientError) {
                    //4xx
                    return CLIENT_ERROR;
                }
                return false;
            };
            const errorDescription = getErrorType(error);

            if (errorDescription) {
                throw {
                    error: {
                        status,
                        severity: FATAL,
                        description: errorDescription
                    },
                    body: {
                        errorDescription: errorDescription,
                        error: errorDescription
                    },
                    text: errorDescription
                };
            }
            throw error;
        })
        .catch(error => {
            if (error instanceof Error) {
                //development errors
                throw {
                    exception: error,
                    error: {
                        description: error.name
                    },
                    body: {
                        errorDescription: error.name, //required by standard
                        error: error.name //required by API logger
                    },
                    text: error.name //required by some other code
                };
            }
            //fallback to raw API errors
            throw error.response || error;
        })
        .catch(function (error) {
            if (error.exception) {
                throw error;
            }

            if (window.__SHOW_API_ERRORS__) {
                const message = formatMessage(
                    mostProbablyDescription || errorDescriptionText || errorText,
                    url,
                    method
                );
                !params.hideDefaultSnackbar && dispatch(notification.error({ message }, { voice: message }));
            }
            throw error;
        });
};

const APIPost = function (
    dispatchOrConfig,
    url,
    params = {},
    contentType = 'json',
    method = 'POST'
) {
    let config;
    if (dispatchOrConfig == null) {
        config = {};
        config.dispatch = getDispatch();
        config.url = url;
        config.params = params;
    } else if (typeof dispatchOrConfig === 'function') {
        config = {};
        config.dispatch = dispatchOrConfig;
        config.url = url;
        config.params = params;
        config.contentType = contentType;
        config.method = method;
    } else {
        config = dispatchOrConfig;
    }
    config.service = API.post;
    return serviceCall(config);
};

const APIGet = function (dispatchOrConfig, url, params = {}) {
    let config;
    if (dispatchOrConfig == null) {
        config = {};
        config.dispatch = getDispatch();
        config.url = url;
        config.params = params;
    } else if (typeof dispatchOrConfig === 'function') {
        config = {};
        config.dispatch = dispatchOrConfig;
        config.url = url;
        config.params = params;
    } else {
        config = dispatchOrConfig;
    }
    config.service = API.get;

    return serviceCall(config);
};

const APIPut = function (dispatchOrConfig, url, params = {}) {
    let config;
    if (dispatchOrConfig == null) {
        config = {};
        config.dispatch = getDispatch();
        config.url = url;
        config.params = params;
    } else if (typeof dispatchOrConfig === 'function') {
        config = {};
        config.dispatch = dispatchOrConfig;
        config.url = url;
        config.params = params;
    } else {
        config = dispatchOrConfig;
    }
    config.service = API.put;
    return serviceCall(config);
};

const APIDel = function (dispatchOrConfig, url, params = {}) {
    let config;
    if (dispatchOrConfig == null) {
        config = {};
        config.dispatch = getDispatch();
        config.url = url;
        config.params = params;
    } else if (typeof dispatchOrConfig === 'function') {
        config = {};
        config.dispatch = dispatchOrConfig;
        config.url = url;
        config.params = params;
    } else {
        config = dispatchOrConfig;
    }
    config.service = API.del;
    return serviceCall(config);
};

const APIPatch = function (dispatchOrConfig, url, params = {}) {
    let config;
    if (dispatchOrConfig == null) {
        config = {};
        config.dispatch = getDispatch();
        config.url = url;
        config.params = params;
    } else if (typeof dispatchOrConfig === 'function') {
        config = {};
        config.dispatch = dispatchOrConfig;
        config.url = url;
        config.params = params;
    } else {
        config = dispatchOrConfig;
    }
    config.service = API.patch;
    return serviceCall(config);
};

const downloadAsFile = (blobContent, fileType, fileName) => {
    var blob = new Blob([blobContent]);
    var userAgent = navigator.userAgent;

    if (BrowserUtils.browserIsIE()) {
        window.navigator.msSaveBlob(blob, fileName);
    } else if (userAgent.indexOf("CriOS") > -1) {
        blob = new Blob([blobContent], { type: 'application/pdf' });
        var reader = new FileReader();
        reader.onloadend = function () { window.open(reader.result); };
        reader.readAsDataURL(blob);
    } else {
        const link = document.createElement('a');
        link.download = fileName;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

const base64ToBlob = base64 => {
    var binary = atob(base64.split(',')[1]);
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    return view;
};

const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?','i'); // protocol

    return !!pattern.test(str);
}

module.exports = {
    serviceCall,
    APIGet,
    APIPost,
    APIPut,
    APIDel,
    APIPatch,
    downloadAsFile,
    base64ToBlob,
    validURL
};
