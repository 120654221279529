const { existingJunior } = require('utilities/APIParsingHelper')
const get = require('lodash/object/get');
const {
    isExpectedPath,
    getCurrentState
} = require('utilities/contactCenter/amazonGiftCardHelper');

const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE
} = require('../constants')

const ACTION_CODE_SEE_ACCOUNTS_LANDING = 5003;
const ACTION_CODE_HIRE_PAYROLL_ACCOUNT = 5004;
const ACTION_CODE_HIRE_CHECKING_ACCOUNT = 5005;
const ACTION_CODE_HIRE_SAVING_ACCOUNT = 5006;
const ACTION_CODE_HIRE_JUNIOR_ACCOUNT = 5007;
const ACTION_CODE_SEE_ACCOUNT_MOVEMENTS = 5008;
const ACTION_CODE_BRING_BANK_ACCOUNT = 5277;
const ACTION_CODE_SEE_BENEFITS_ACCOUNT_123 = 5009;
const ACTION_CODE_SEE_ACCOUNT_DETAILS_AND_INTERVERNERS = 5010;
const ACTION_CODE_ACCOUNT_CERTIFICATES_CONSULT = 5011;
const ACTION_CODE_SEE_ACCOUNT_SETTLEMENTS = 5013;
const ACTION_CODE_SEE_ACCOUNT_DEBITS = 5014;
const ACTION_CODE_ACCOUNT_DEBITS_CHANGE_ACCOUNT = 5015;
const ACTION_CODE_ACCOUNT_DEBIT_CHANGE_ACCOUNT = 5016;
const ACTION_CODE_SEE_ACCOUNT_CHECK = 5017;
const ACTION_CODE_NEW_CHECKBOOK = 5018;
const ACTION_CODE_UPGRADE_TO_PAYROLL_ACCOUNT = 5019;
const ACTION_CODE_ACCOUNT_WARNINGS = 5020;
const ACTION_CODE_DEBIT_PAYMENT_ANNULATION = 5021;
const ACTION_CODE_DEBIT_RETURN = 5022;
const ACTION_CODE_CHECKBOOK_CANCELLATION = 5023;
const ACTION_CODE_CHECK_CANCELLATION = 5024;
const ACTION_CODE_ACCOUNT_REMOVE_INTERVENER = 5025;
const ACTION_CODE_SEE_ACCOUNTS_CANCELLED_LANDING = 5222;
const ACTION_CODE_CHECK_AMAZON_PROMOTIONS = 5237;
const ACTION_CODE_HIRE_AMAZON_GIFTCARD = 5238;
const ACTION_CODE_HIRE_OPEN_YOUNG_PACK = 5242;
const ACTION_CODE_CHECK_AMAZON_CODES = 5246;
const ACTION_CODE_ACCOUNT_ADD_DOCUMENTATION = 5266;
const ACTION_CODE_HIRE_WELCOME_ACCOUNT= 5273;
const ACTION_CODE_HIRE_WELCOME_PAYROLL_ACCOUNT= 5274;
const ACTION_CODE_ACCOUNT_DEFER_DEFERRAL = 221111117;

const {
    ACCOUNT_BENEFITS_DATA_FAILURE,
    ACCOUNT_BENEFITS_DATA_SUCCESS,
    ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE,
    ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS,
} = require('constants/contactCenter/actionTypes')

const {
    ACCOUNT_ADD_DOCUMENTATION_UPLOAD_FAILURE,
    ACCOUNT_ADD_DOCUMENTATION_UPLOAD_REQUEST,
    ACCOUNT_ADD_DOCUMENTATION_UPLOAD_SUCCESS,
    AMAZON_GET_COUPON_CODE_ERROR,
    AMAZON_GET_COUPON_CODE_SUCCESS,
    AMAZON_GET_PROMOTION_CARD_ERROR,
    AMAZON_GET_PROMOTION_CARD_SUCCESS,
    FETCH_ACCOUNT_CHEQUES_FAILURE,
    FETCH_ACCOUNT_CHEQUES_SUCCESS,
    FETCH_ACCOUNT_DEBITS_FAILURE,
    FETCH_ACCOUNT_DEBITS_SUCCESS,
    FETCH_ACCOUNT_SETTLEMENTS_FAILURE,
    FETCH_ACCOUNT_SETTLEMENTS_SUCCESS,
    FETCH_ACCOUNT_TRANSACTIONS_FAILURE,
    FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
    HIRING_PRODUCT_INFORMATION_FAILURE,
    HIRING_PRODUCT_INFORMATION_SUCCESS,
    SET_ACCOUNT_CHECKBOOK_INFO_FAILURE,
    SET_ACCOUNT_CHECKBOOK_INFO_SUCCESS,
    SET_JUNIOR_DATA_SUCCESS,
    HIRE_CONTRACT_FAILURE,
    HIRE_CONTRACT_SUCCESS
} = require('constants/actionTypes')

// @constants
const {
    SELECTED_RADIO_COUPON,
    SELECTED_RADIO_PROMOTIONS,
} = require('constants/accountAmazonGiftCard');

module.exports = {
    [ACTION_CODE_SEE_ACCOUNTS_LANDING]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts',
            check: () => {
                return {
                    operationState: OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_SEE_ACCOUNTS_LANDING
                }
            }
        }
    },
    [ACTION_CODE_SEE_ACCOUNTS_CANCELLED_LANDING]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/cancelled'
        }
    },
    [ACTION_CODE_SEE_ACCOUNT_MOVEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action :[
                FETCH_ACCOUNT_TRANSACTIONS_FAILURE,
                FETCH_ACCOUNT_TRANSACTIONS_SUCCESS
            ],
            check: (action) => {
                const { type } = action;

                let operationState = type === FETCH_ACCOUNT_TRANSACTIONS_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE;
                let errorMessage;

                if (type === FETCH_ACCOUNT_TRANSACTIONS_FAILURE) {
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.data.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description'
                }

                return {
                    operationState,
                    actionCode: ACTION_CODE_SEE_ACCOUNT_MOVEMENTS,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_BRING_BANK_ACCOUNT]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/bring-bank-account',
            failure: [
                { action: HIRING_PRODUCT_INFORMATION_FAILURE }
            ],
            success: [
                { action: HIRING_PRODUCT_INFORMATION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SEE_BENEFITS_ACCOUNT_123]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/[0-9]+/benefits-account-123',
            failure: [
                { action: ACCOUNT_BENEFITS_DATA_FAILURE }
            ],
            success: [
                { action: ACCOUNT_BENEFITS_DATA_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SEE_ACCOUNT_DETAILS_AND_INTERVERNERS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/[0-9]+/details',
        }
    },
    [ACTION_CODE_ACCOUNT_WARNINGS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/[0-9]+/warnings',
            failure: [
                { action: ACCOUNTS_PRECAUTIONARY_WARNINGS_FAILURE },
            ],
            success: [
                { action: ACCOUNTS_PRECAUTIONARY_WARNINGS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SEE_ACCOUNT_SETTLEMENTS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [FETCH_ACCOUNT_SETTLEMENTS_SUCCESS, FETCH_ACCOUNT_SETTLEMENTS_FAILURE],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE,
                    errorMessage;

                if (action.type === FETCH_ACCOUNT_SETTLEMENTS_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.error.description')
                        || get(action, 'payload.error.text')
                        || 'No error\'s description'
                }

                return {
                    actionCode: ACTION_CODE_SEE_ACCOUNT_SETTLEMENTS,
                    operationState,
                    errorMessage
                }
            }
        }
    },
    [ACTION_CODE_SEE_ACCOUNT_DEBITS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/[0-9]+/debits'
        }
    },
    [ACTION_CODE_ACCOUNT_DEBITS_CHANGE_ACCOUNT]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/debits/change-account'
        }
    },
    [ACTION_CODE_ACCOUNT_DEBIT_CHANGE_ACCOUNT]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/debits/change-receipt-account/\\w+',
            failure: [
                { action: FETCH_ACCOUNT_DEBITS_FAILURE }
            ],
            success: [
                { action: FETCH_ACCOUNT_DEBITS_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_SEE_ACCOUNT_CHECK]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/[0-9]+/cheques',
            failure: [
                { action: FETCH_ACCOUNT_CHEQUES_FAILURE }
            ],
            success: [
                { action: FETCH_ACCOUNT_CHEQUES_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_NEW_CHECKBOOK]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/new-chequebook',
            failure: [
                { action: SET_ACCOUNT_CHECKBOOK_INFO_FAILURE }
            ],
            success: [
                { action: SET_ACCOUNT_CHECKBOOK_INFO_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_UPGRADE_TO_PAYROLL_ACCOUNT]: {
        entry: {
            path: '/myprofile/purchase/upgrade-to-payroll-account',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_UPGRADE_TO_PAYROLL_ACCOUNT,
                };
            }
        }
    },
    [ACTION_CODE_DEBIT_PAYMENT_ANNULATION]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/debits/annulationPayments/\\w+'
        }
    },
    [ACTION_CODE_DEBIT_RETURN]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/debits/returns/\\w+'
        }
    },
    [ACTION_CODE_CHECKBOOK_CANCELLATION]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/cheques/cancel-chequebook/[0-9]+',
            failure: [
                { action: FETCH_ACCOUNT_CHEQUES_FAILURE }
            ],
            success: [
                { action: FETCH_ACCOUNT_CHEQUES_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_CHECK_CANCELLATION]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/cheques/cancel-cheque/[0-9]+'
        }
    },
    [ACTION_CODE_ACCOUNT_REMOVE_INTERVENER]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/intervener/[0-9]+/remove'
        }
    },
    [ACTION_CODE_HIRE_PAYROLL_ACCOUNT]: {
        entry: {
            path: '/myprofile/purchase/payroll-account',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_HIRE_PAYROLL_ACCOUNT
                };
            }
        }
    },
    [ACTION_CODE_ACCOUNT_CERTIFICATES_CONSULT]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/accounts/[0-9]+/certificates'
        }
    },
    [ACTION_CODE_HIRE_CHECKING_ACCOUNT]: {
        entry: {
            path: '/myprofile/purchase/checking-account',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_HIRE_CHECKING_ACCOUNT
                };
            }
        }
    },
    [ACTION_CODE_HIRE_SAVING_ACCOUNT]: {
        entry: {
            path: '/myprofile/purchase/e-account-loan/[a-zA-Z0-9-]+',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_HIRE_SAVING_ACCOUNT
                };
            }
        }
    },
    [ACTION_CODE_HIRE_JUNIOR_ACCOUNT]: {
        entry: {
            path: '/myprofile/purchase/junior-account',
        },
        finish: {
            failure: [
                action => action.type === SET_JUNIOR_DATA_SUCCESS && (
                    action.payload.documentNumber === 0 ||
                    existingJunior(action.payload.responseCode)
                )
            ]
        }
    },
    [ACTION_CODE_HIRE_OPEN_YOUNG_PACK]: {
        entry: {
            path: '/myprofile/hire/pack/open-young',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_HIRE_OPEN_YOUNG_PACK
                };
            }
        }
    },
    [ACTION_CODE_HIRE_AMAZON_GIFTCARD]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/buy-amazon-gift-card'
        }
    },
    [ACTION_CODE_CHECK_AMAZON_PROMOTIONS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action :[
                AMAZON_GET_PROMOTION_CARD_ERROR,
                AMAZON_GET_PROMOTION_CARD_SUCCESS
            ],
            check: (action, state) => {
                const { type } = action;
                const currentState = getCurrentState(state);

                let operationState = OPERATION_LOG_NONE;

                if (isExpectedPath(state) && currentState === SELECTED_RADIO_PROMOTIONS) {
                    operationState = type === AMAZON_GET_PROMOTION_CARD_SUCCESS ? OPERATION_LOG_DONE : OPERATION_LOG_FAIL;
                }

                return {
                    operationState,
                    actionCode: ACTION_CODE_CHECK_AMAZON_PROMOTIONS
                }
            }
        }
    },
    [ACTION_CODE_CHECK_AMAZON_CODES]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action :[
                AMAZON_GET_COUPON_CODE_SUCCESS,
                AMAZON_GET_COUPON_CODE_ERROR
            ],
            check: (action, state) => {
                const { type } = action;
                const currentState = getCurrentState(state);

                let operationState = OPERATION_LOG_NONE;

                if (isExpectedPath(state) && currentState === SELECTED_RADIO_COUPON) {
                    operationState = type === AMAZON_GET_COUPON_CODE_SUCCESS ? OPERATION_LOG_DONE : OPERATION_LOG_FAIL;
                }

                return {
                    operationState,
                    actionCode: ACTION_CODE_CHECK_AMAZON_CODES
                }
            }
        }
    },
    [ACTION_CODE_ACCOUNT_ADD_DOCUMENTATION]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/upload-documentation',
            action: [ ACCOUNT_ADD_DOCUMENTATION_UPLOAD_REQUEST ],
        },
        finish: {
            action: [
                ACCOUNT_ADD_DOCUMENTATION_UPLOAD_FAILURE,
                ACCOUNT_ADD_DOCUMENTATION_UPLOAD_SUCCESS,
            ],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE;
                let errorMessage;

                if (action.type === ACCOUNT_ADD_DOCUMENTATION_UPLOAD_FAILURE) {
                    operationState = OPERATION_LOG_FAIL;
                    errorMessage = get(action, 'payload.errorDescription.description');
                }

                return {
                    actionCode: ACTION_CODE_ACCOUNT_ADD_DOCUMENTATION,
                    errorMessage,
                    operationState,
                };
            }
        }
    },
    [ACTION_CODE_HIRE_WELCOME_ACCOUNT]: {
        entry: {
            path: '/myprofile/hire/welcome-account',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_HIRE_SAVING_ACCOUNT
                };
            }
        }
    },
    [ACTION_CODE_HIRE_WELCOME_PAYROLL_ACCOUNT]: {
        entry: {
            path: '/myprofile/hire/welcome-payroll-account',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS
            ],
            check: (action) => {
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: ACTION_CODE_HIRE_WELCOME_PAYROLL_ACCOUNT
                };
            }
        }
    },
    [ACTION_CODE_ACCOUNT_DEFER_DEFERRAL]: {
        entry: {
            path: '/myprofile/accounts/[0-9]+/defer-payment/[0-9]+'
        }
    },
}
