// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @constants
const actionTypes = require('constants/actionTypes');

const {
    BROKER_ORDER_TYPE_CONDITION_TITLE_AMOUNT,
    BROKER_NO_STOCKS_CONTRACT_SELECTED,
    BROKER_WARRANTS_SELL_LIMIT_PRICE_VALUE_DEFAULT,
    BROKER_WARRANTS_SELL_TITLE_QUANTITY_VALUE_DEFAULT,
    BROKER_ORDER_TYPE_LIMITED,
    SUNDAY_INDEX_DAY,
    SATURDAY_INDEX_DAY
} = require('constants/index');

function setInitialState() {
    const limitDate = moment().add(90, 'days');
    const excludeDates = [];
    const validityDate = ''; // @todo Review it moment(new Date());
    let tempDate = moment();

    while (tempDate.isSameOrBefore(limitDate)) {
        const dayOfWeek = tempDate.day();

        if (dayOfWeek === SUNDAY_INDEX_DAY || dayOfWeek === SATURDAY_INDEX_DAY) {
            excludeDates.push(tempDate.clone());
        }

        tempDate = tempDate.add(1, 'days');
    }

    return Immutable.fromJS({
        stocksContractSelectedValue: BROKER_NO_STOCKS_CONTRACT_SELECTED,
        selectedOptionTitles: 0,
        orderTypeOption: BROKER_ORDER_TYPE_LIMITED,
        orderTypeValue: BROKER_WARRANTS_SELL_LIMIT_PRICE_VALUE_DEFAULT,
        sellPrice: BROKER_WARRANTS_SELL_LIMIT_PRICE_VALUE_DEFAULT,
        errorFlags: {
            orderTypeValue: false,
            titleQuantity: false
        },
        validityDate,
        limitDate,
        excludeDates,
        titleQuantity: BROKER_WARRANTS_SELL_TITLE_QUANTITY_VALUE_DEFAULT,
        valid: false
    });
}

function validateStep(step) {
    const isStocksContractEmpty =
        step.get('stocksContractSelectedValue') === BROKER_NO_STOCKS_CONTRACT_SELECTED;

    const titleQuantityValid = step.get('titleQuantity') !== '0' && step.get('titleQuantity') !== '';
    const titleQuantityLimitValid = step.get('titleQuantity') <= step.get('selectedOptionTitles');

    const isInvalidTitleQuantity =
        (step.get('orderTypeOption') === BROKER_ORDER_TYPE_CONDITION_TITLE_AMOUNT &&
            !step.get('orderTypeOption')) ||
        parseInt(step.get('orderTypeOption'), 10) <= 0;

    const isInvalidOrderTypeValue =
        !step.get('orderTypeValue') || parseFloat(step.get('orderTypeValue')) <= 0;

    const valid =
        !isStocksContractEmpty &&
        !isInvalidTitleQuantity &&
        !isInvalidOrderTypeValue &&
        titleQuantityValid &&
        titleQuantityLimitValid;

    return step.merge({
        errorFlags: {
            orderTypeValue: isInvalidOrderTypeValue
        },
        titleQuantityValid,
        titleQuantityLimitValid,
        valid
    });
}

function brokerWarrantsSellStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_SELL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_SELL_STOCK_CONTRACT_CHANGED:
            return state.merge({
                stocksContractSelectedValue: action.payload.value,
                selectedOptionTitles: action.payload.titles
            });
        case actionTypes.BROKER_WARRANTS_SELL_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_SELL_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_SELL_LIMIT_PRICE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_SELL_SET_SELL_PRICE:
            return state.merge({
                sellPrice: action.payload.sellPrice
            });
        default:
            return state;
    }
}

module.exports = brokerWarrantsSellStep1;
