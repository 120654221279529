// @ vendors
const Immutable = require('immutable');
const { sortByOrder } = require('lodash/collection');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    isFetching: false,
    movementsList: {},
    hasNGOsMovement: false,
});

function updateMovementsList (movementsList) {
    return sortByOrder(movementsList, ['ftext'], ['desc']);
}

const movements = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MOVEMENTS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_MOVEMENTS_SUCCESS:
            return state.merge({
                movementsList: updateMovementsList(action.payload.movementsList),
                error: '',
                isFetching: false
            });
        case actionTypes.FETCH_MOVEMENTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.VALID_NGO_CARD_SUCCESS:
            return state.merge({
                hasNGOsMovement: action.payload.hasNGOs
            });
        case actionTypes.VALID_NGO_CARD_FAIL:
            return state.merge({
                hasNGOsMovement: false
            });
        default:
            return state
    }
};

module.exports = movements;
