// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');
const {
    BROKER_BUY_FOR_AMOUNT_OF_TITLES,
    BROKER_BUY_FOR_APPROX_IMPORT
} = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        selectedOptionValue: -1,
        buyFor: BROKER_BUY_FOR_AMOUNT_OF_TITLES,
        titleQuantity: 0,
        buyForApproxImport: 0,
        limitPrice: 0,
        loading: false,
        invalidSelectedOption: false,
        invalidBuyFor: false,
        invalidLimitPrice: false,
        valid: false
    });
}

function validateStep(step) {
    const buyFor = step.get('buyFor');
    const limitPrice = parseFloat(step.get('limitPrice'));
    const titleQuantity = parseFloat(step.get('titleQuantity'));
    const buyForApproxImport = parseFloat(step.get('buyForApproxImport'));

    const invalidSelectedOption = step.get('selectedOptionValue') === -1;

    const invalidBuyFor =
        (buyFor === BROKER_BUY_FOR_APPROX_IMPORT && buyForApproxImport <= 0) ||
        (buyFor === BROKER_BUY_FOR_AMOUNT_OF_TITLES && titleQuantity <= 0);

    const invalidLimitPrice =
        limitPrice <= 0 ||
        (buyFor === BROKER_BUY_FOR_APPROX_IMPORT && limitPrice > buyForApproxImport);

    const valid = !invalidBuyFor && !invalidSelectedOption && !invalidLimitPrice;

    return step.merge({
        invalidSelectedOption,
        invalidBuyFor,
        invalidLimitPrice,
        valid
    });
}

function brokerStocksAdvancedBuyStep2(state = setInitialState(), action) {

    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_STOCK_CONTRACT_CHANGED:
            return state.merge({
                selectedOptionValue: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_APPROX_IMPORT_CHANGED:
            return state.merge({
                buyForApproxImport: action.payload.value
            });

        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_FOR_CHANGED:
            return state.merge({
                buyFor: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_LIMITED_VALUE_CHANGED:
            return state.merge({
                limitPrice: action.payload.value
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_REQUEST:
            return state.merge({
                loading: true
            });
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_SUCCESS:
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_VALIDATE_FAILURE:
            return state.merge({
                loading: false
            });
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedBuyStep2;
