// @vendors
const Immutable = require('immutable');
const { get, isArray } = require('lodash');
// @constants
const actionTypes = require('constants/actionTypes');
//@utilities
const { getFormattedProductAdvantages } = require('utilities/cardsHelper');

function setInitialState() {
    return Immutable.fromJS({
        isFetching: false,
        success: false,
        error: false,
        advantages: [],
        characteristics: {},
        description: {},
        precontractualLinksList: {},
        riskLevelsContent: {},
        cmsResponse: {},
        faqs: [],
    });
}

function parseHiringProductInformation(state, response) {
    const productsList = get(response, 'products');
    if (productsList.length && isArray(productsList)) {
        const contractTypes = [
            "ok_component--engagement_steps",
            "ok_component--first_step_for_card_contract",
            "ok_component--first_step_for_deposit_contract"
        ];
        const contractInfo = get(response, 'products[0].cmsResponse.included');
        if (contractInfo) {
            const advantagesList = contractInfo.filter(function (res) {
                return res.type === "ok_component--advantage";
            });
            const advantages = getFormattedProductAdvantages(advantagesList);
            const characteristicsContent = contractInfo.find(function (res) {
                return contractTypes.includes(res.type);
            });
            const characteristics = get(characteristicsContent, 'attributes') || {};
            const precontractualLinks = contractInfo.find(function (res) {
                return res.type === "paragraph--pre_contractual_link";
            });
            const precontractualLinksList = get(precontractualLinks, 'attributes') || {};
            const riskLevels = contractInfo.find(function (res) {
                return res.type === "paragraph--risk_bde";
            });
            const riskLevelsContent = riskLevels ? get(riskLevels, 'attributes') : {};
            const description = get(characteristics, 'field_description') || {};
            const riskLiquidityAlert = contractInfo.find(function (res) {
                return res.type === "taxonomy_term--liquidity_alerts";
            });
            const riskLiquidityAlertContent = riskLiquidityAlert ? get(riskLiquidityAlert, 'attributes') : {};
            const faqsList = contractInfo.filter(res => {
                return res.type === 'node--faq';
            });
            const faqs = faqsList.map(faq => {
                return {
                    id: get(faq, 'id'),
                    question: get(faq, 'attributes.title'),
                    answer: get(faq, 'attributes.body.processed'),
                };
            });
            return state.merge({
                isFetching: false,
                success: true,
                error: false,
                advantages: advantages,
                characteristics: characteristics,
                description: description,
                precontractualLinksList: precontractualLinksList,
                riskLevelsContent: riskLevelsContent,
                riskLiquidityAlertContent: riskLiquidityAlertContent,
                cmsResponse: contractInfo,
                faqs,
            });
        } else {
            return state.merge({
                error: true,
                success: false,
                isFetching: false
            });
        }
    }
    return state;
}

function hiringProductInformationReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.HIRING_PRODUCT_INFORMATION_IS_FETCHING:
            return state.merge({
                isFetching: true,
                error: false
            });

        case actionTypes.HIRING_PRODUCT_INFORMATION_FAILURE:
            return state.merge({
                error: true,
                isFetching: false
            });

        case actionTypes.HIRING_PRODUCT_INFORMATION_SUCCESS:
            const responseData = action.payload.response;
            return parseHiringProductInformation(state, responseData)

        case actionTypes.HIRING_PRODUCT_INFORMATION_RESET:
            return setInitialState();

        default:
            return state;
    }
}

module.exports = hiringProductInformationReducer;
