//@vendor
const Immutable = require('immutable');
//@constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    accessFrom: '',
    selectedOption: null,
    selectedContract: null
});

function brokerOrdersAndMovements(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_ACCESS_FROM:
            return state.merge({
                accessFrom: action.payload.type
            });
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SELECTED_OPTION_CHANGED:
            return state.merge({
                selectedOption: action.payload.selectedOption
            });
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_CONTRACT_NUMBER:
            return state.merge({
                selectedContract: action.payload.selectedContract
            });
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = brokerOrdersAndMovements;
