const Immutable = require('immutable');
const { hash } = require('utilities/hash');
const provincesOptions = require('constants/provinces');
const { sortByOrder } = require('lodash/collection');
const { countriesOptions } = require('constants/countries');
const { formatText } = require('core/i18n').i18n;
// @ constants
const {
    CARD_PAYMENT_METHOD_VARIABLE_INCREMENT_INTERVAL,
    CARD_PAYMENT_METHOD_VARIABLE_MAX_PERCENTAGE,
    CARD_PAYMENT_METHOD_VARIABLE_MIN_PERCENTAGE,
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIF,
    DOCUMENT_TYPE_NIE_RESIDENT_CARD,
    EURO_SYMBOL
} = require('constants/index');

//WorkTypes
const CUENTA_PROPIA = 1;
const CUENTA_AJENA = 2;
const EMPRESARIO = 3;
const FUNCIONARIO = 4;
const NO_TRABAJAS = 5;

const workTypeValues = {
    CUENTA_PROPIA: CUENTA_PROPIA,
    CUENTA_AJENA: CUENTA_AJENA,
    EMPRESARIO: EMPRESARIO,
    FUNCIONARIO: FUNCIONARIO,
    NO_TRABAJAS: NO_TRABAJAS
};


const activitiesValuesYouDontWork = {
    AMA_DE_CASA: 1,
    DESEMPLEADO: 2,
    ESTUDIANTE: 3,
    JUBILADO: 4,
    RENTISTA: 5
};

const methodOfPaymentValues = {
    FULL_PAYMENT: 'PM',
    FIXED_FEE: 'CF',
    PERCENTAGE: 'PA'
};


let accountsT=null;

function sortedElements(collection) {
    const collectionUnsorted = Object.keys(collection).map(key => {
        return {
            value: key,
            label: collection[key]
        }
    });

    return sortByOrder(collectionUnsorted, ['label'], ['asc']);
}

function mockCmbAccounts(){
    let immAccount = new Map();
    immAccount.set('iban','ES25007310050023562025457106');
    immAccount.set('aliasTentative','Cuenta Personal');
    immAccount.set('accountType','Tipo 1');
    let catalog1 = new Map();
    catalog1.set('product','product1');
    immAccount.set('catalogData',catalog1);
    immAccount.set('balance',
        Immutable.fromJS({
            some: 'object'
        })
    );

    let immAccount2 = new Map();
    immAccount2.set('iban','ES35007310050023562025458905');
    immAccount2.set('aliasTentative','Cuenta Familiar');
    immAccount2.set('accountType','Tipo 2');
    let catalog2 = new Map();
    catalog2.set('product','product2');
    immAccount2.set('catalogData',catalog2);
    immAccount2.set('balance',
        Immutable.fromJS({
            some: 'object'
        })
    );

    //Agrego a la lista de cuentas available
    let immAccountsT = new Map();
    immAccountsT.set(  hash('ES25007310050023562025457106') ,immAccount);
    immAccountsT.set(  hash('ES35007310050023562025458905') ,immAccount2);

    const accountsIndexes=[];
    accountsIndexes.push( hash('ES25007310050023562025457106'));
    accountsIndexes.push( hash('ES35007310050023562025458905'));

    accountsT= immAccountsT

    return { accountsIndexes: accountsIndexes, immAccountsT: immAccountsT}
}

const account1_List= Immutable.List.of ( Immutable.Map({ name: 'Arturo Perez', id:1, type:''}), Immutable.Map({ name: 'Juan Masud', id:2, type:''}));
const account2_List= Immutable.List.of ( Immutable.Map({ name: 'Mariano Lemos', id:1, type:''}), Immutable.Map({ name: 'Pilar Sordo', id:3, type:''}), Immutable.Map({ name: 'Rocio Quiroga', id:4, type:''}));
let intervinientes_list_selected;

function mockOnChangeDropdownAccounts(accountId){
    let ret=[];
    if (accountId){ //TODO: Fix - when the user navigate to another step, this function is called (?)

        if (accountsT.get(accountId).get('iban')==='ES35007310050023562025458905'){
            intervinientes_list_selected = account1_List;
            //intervinientes_list_selected=ret.toArray();
        }
        else
        {
            intervinientes_list_selected = account2_List;
            //intervinientes_list_selected = ret.toArray();
        }


        ret.push(
            {
                target: "cta_seleccionada",
                value: accountsT.get(accountId).get('iban')
            });
        ret.push(
            {
                target: "intervinientes_list",
                value: intervinientes_list_selected
            }
        );
    }
    return ret;
}

function onChangeIntervener(item, typeSelected, index) {
    let ret=[];
    intervinientes_list_selected=intervinientes_list_selected
        .map(function(item){
            return ( item.get('id')===index ) ? item.set('type',typeSelected) : item;
        });
    ret.push(
        {
            target: "intervinientes_list",
            value: intervinientes_list_selected
        }
    );
    return ret;
}

function mockContactSheduleSelect() {
    return [
        {
            value: 'morning',
            label: 'Mañana'
        },
        {
            value: 'late',
            label: 'Tarde'
        },
        {
            value: 'night',
            label: 'Noche'
        }
    ]
}

function mockProvinceSelect() {
    return sortedElements(provincesOptions);
}


function mockTaxRadioOptions() {
    return [
        {
            value: 1,
            label: 'Familiar/Personal'
        },
        {
            value: 2,
            label: 'Autónomo'
        },
        {
            value: 3,
            label: 'Inversión'
        }
    ]
}

function mockOwnMoneyRadioOptions() {
    return [
        {
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
    ]
}

function mockThirdPartyTransitionalFunds() {
    return [
        {
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
    ]
}

function mockPoliticalCharge() {
    return [
        {
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
    ];
}

function mockForeignTaxRadioOptions() {
    return [
        {
            value: true,
            label: formatText('contractsView-taxObligationsYes')
        },
        {
            value: false,
            label: formatText('contractsView-taxObligationsNo')
        }
    ];
}

function mockHoldersRadioOptions() {
    return [
        {
            value: false,
            label: formatText('contractsView-taxObligationsNo')
        },{
            value: true,
            label: formatText('contractsView-taxObligationsYes')
        }
    ];
}

function mockCountries() {
    return countriesOptions();
}

function mockImtCmb() {
    return [
        {
            value: 1,
            label: formatText('contractsView-taxReasonCitizenship')
        },
        {
            value: 2,
            label: formatText('contractsView-taxReasonResidence')
        },
        {
            value: 3,
            label: formatText('contractsView-taxReasonBoth')
        }
    ]
}

function mockIdentificationTypeCmb() {
    return [
        {
            value: DOCUMENT_TYPE_NIF,
            label: formatText(`contractsView-identificationType${DOCUMENT_TYPE_NIF}`)
        },
        {
            value: DOCUMENT_TYPE_NIE_RESIDENT_CARD,
            label: formatText(`contractsView-identificationType${DOCUMENT_TYPE_NIE_RESIDENT_CARD}`)
        },
        {
            value: DOCUMENT_TYPE_NIE,
            label: formatText(`contractsView-identificationType${DOCUMENT_TYPE_NIE}`)
        }
    ]
}

function mockRelationTypeCmb() {
    return [
        {
            value: '01',
            label: formatText('contractsView-payrollAccountRelationTypeHolder')
        },
        //Unavailable @ the moment
        // {
        //     value: '07',
        //     label: 'Apoderado'
        // },
        {
            value: '08',
            label: formatText('contractsView-payrollAccountRelationTypeAuthorized')
        }
    ]
}

function mockWorkType() {
    return [
        {
            value: CUENTA_PROPIA,
            label: 'Cuenta propia'
        },
        {
            value: CUENTA_AJENA,
            label: 'Cuenta ajena'
        },
        {
            value: EMPRESARIO,
            label: 'Empresario'
        },
        {
            value: FUNCIONARIO,
            label: 'Funcionario'
        },
        {
            value: NO_TRABAJAS,
            label: 'No trabajas'
        }
    ]
}

function getCuentaPropiaOptions() {
    return [
        {
            value: 1,
            label: 'Abogado'
        },
        {
            value: 2,
            label: 'Agente/Representante/Jefe de Ventas'
        },
        {
            value: 3,
            label: 'Arquitecto Técnico (Aparejador)'
        },
        {
            value: 4,
            label: 'Arquitecto'
        },
        {
            value: 5,
            label: 'Conductor/Taxista'
        },
        {
            value: 6,
            label: 'Economista'
        },
        {
            value: 7,
            label: 'Electricista'
        },
        {
            value: 8,
            label: 'Escritores y Periodistas'
        },
        {
            value: 9,
            label: 'Escultores/Pintores/Fotógrafos/Decoradores'
        },
        {
            value: 10,
            label: 'Fontanero'
        },
        {
            value: 11,
            label: 'Ingeniero Superior'
        },
        {
            value: 12,
            label: 'Ingeniero Técnico (Perito)'
        },
        {
            value: 13,
            label: 'Mecánico'
        },
        {
            value: 14,
            label: 'Otros Licenciados Superiores'
        },
        {
            value: 15,
            label: 'Otros Profesionales'
        },
        {
            value: 16,
            label: 'Otros Técnicos Grado Medio'
        },
        {
            value: 17,
            label: 'Peluquero/Peluquera'
        },
        {
            value: 18,
            label: 'Profesional de Informática (Nivel Medio)'
        },
        {
            value: 19,
            label: 'Profesional de la Sanidad (Nivel Superior)'
        },
        {
            value: 20,
            label: 'Profesional de la Sanidad (Nivel Medio)'
        }
    ]
}

function getCuentaAjenaOptions() {
    return  [
        {value: 1, label: 'Abogado'},
        {value: 2, label: 'Agente/Representante/Jefe de Ventas'},
        {value: 3, label: 'Analista de Aplicaciones Informáticas'},
        {value: 4, label: 'Arquitecto Técnico (Aparejador)'},
        {value: 5, label: 'Arquitecto'},
        {value: 6, label: 'Camarero/Barman'},
        {value: 7, label: 'Dependiente de Comercio/Vendedor'},
        {value: 8, label: 'Directivo de Empresa Privada'},
        {value: 9, label: 'Economista'},
        {value: 10, label: 'Empleado Administrativo/Contabilidad'},
        {value: 11, label: 'Empleado Instituciones Financieras'},
        {value: 12, label: 'Escritores y Periodistas'},
        {value: 13, label: 'Escultores/Pintores/Fotógrafos/Decoradores'},
        {value: 14, label: 'Estadístico, Matemático'},
        {value: 15, label: 'Ingeniero Superior'},
        {value: 16, label: 'Ingeniero Técnico (Perito)'},
        {value: 17, label: 'Jefe/Encargado (Industria o Servicios)'},
        {value: 18, label: 'Jefe de Oficina Administrativa'},
        {value: 19, label: 'Otros Licenciados Superiores'},
        {value: 20, label: 'Otros Profesionales'},
        {value: 21, label: 'Otros Técnicos Grado Medio'},
        {value: 22, label: 'Personal de Seguridad Privado'},
        {value: 23, label: 'Personal de Servicios Diversos'},
        {value: 24, label: 'Profesional de la Sanidad (Nivel Superior)'},
        {value: 25, label: 'Profesional de la Sanidad (Nivel Medio)'},
        {value: 26, label: 'Profesores'},
    ]
}

function getEmpresarioOptions() {
    return [
        {
            value: 1,
            label: 'Otros Propietarios de Empresas'
        },
        {
            value: 2,
            label: 'Propietario de Establecimiento Comercial'
        },
        {
            value: 3,
            label: 'Propietario de Hoteles'
        },
        {
            value: 4,
            label: 'Propietario de Restaurantes'
        }
    ];
}

function getFuncionarioOptions() {
    return [
        {value: 1, label: 'Bomberos, Policías y Personal de Protección'},
        {value: 2, label: 'Carteros, Ordenanzas y Mensajeros'},
        {value: 3, label: 'Directivo Administración Pública'},
        {value: 4, label: 'Empleado Administrativo/Contabilidad'},
        {value: 5, label: 'Militar de las Fuerzas Armadas'}
    ];
}

function getNoTrabajasOptions() {
    return [
        {value: activitiesValuesYouDontWork.AMA_DE_CASA, label: 'Ama de Casa'},
        {value: activitiesValuesYouDontWork.DESEMPLEADO, label: 'Desempleado'},
        {value: activitiesValuesYouDontWork.ESTUDIANTE, label: 'Estudiante'},
        {value: activitiesValuesYouDontWork.JUBILADO, label: 'Jubilado'},
        {value: activitiesValuesYouDontWork.RENTISTA, label: 'Rentista'}
    ];
}

function mockActivity(workTypeId) {
    switch (workTypeId) {
        case CUENTA_PROPIA:
            return getCuentaPropiaOptions();
        case CUENTA_AJENA:
            return getCuentaAjenaOptions();
        case EMPRESARIO:
            return getEmpresarioOptions();
        case FUNCIONARIO:
            return getFuncionarioOptions();
        case NO_TRABAJAS:
            return getNoTrabajasOptions();
    }
}

function mockPeriodicity() {
    return [
        {
            value: 1,
            label: 'Quincenal'
        },
        {
            value: 2,
            label: 'Mensual'
        }
    ]
}

function mockTypeTaxation() {
    return [
        {
            value: 1,
            label: 'Directa'
        },
        {
            value: 2,
            label: 'Indirecta'
        }
    ]
}

function mockEmploymentRelationship() {
    return [
        {
            value: 1,
            label: 'Option 1'
        },
        {
            value: 2,
            label: 'Option 2'
        }
    ]
}

function mockAddHolder() {
    return [
        {
            value: true,
            label: 'Sí'
        },
        {
            value: false,
            label: 'No'
        }
    ]
}

function mockDocumentType() {
    return [
        {
            value: 1,
            label: 'N.I.F'
        },
        {
            value: 2,
            label: 'TARJETA RESIDENCIA'
        }
    ];
}

function mockMethodOfPayment() {
    return [
        {
            value: methodOfPaymentValues.FIXED_FEE,
            label: formatText('contractsView-methodOfPaymentDeferredFixedFee')
        },
        {
            value: methodOfPaymentValues.PERCENTAGE,
            label: formatText('contractsView-methodOfPaymentDeferredVariableFee')
        },
        {
            value: methodOfPaymentValues.FULL_PAYMENT,
            label: formatText('contractsView-methodOfPaymentFullPay')
        }
    ];
}

function generateSequence(first, last, increment) {
    let numbers = [];
    let number = first;
    numbers.push(number);
    while (number < last) {
        number += increment;
        numbers.push(number);
    }
    return numbers;
}

function returnFeeAmountIncrease(contractName) {
    return ['diamond-pack', 'premium-pack'].includes(contractName) ? 100 : 30;
}

function generateMethodOfPaymentOptions(percentage = false, creditLimit, contractName) {
    const first =  percentage ? CARD_PAYMENT_METHOD_VARIABLE_MIN_PERCENTAGE : Math.ceil(creditLimit / 300) * 30;
    const last = percentage ? CARD_PAYMENT_METHOD_VARIABLE_MAX_PERCENTAGE : Math.floor(creditLimit / 30) * 30;
    const contractIncrement = returnFeeAmountIncrease(contractName);
    const increment = percentage ? CARD_PAYMENT_METHOD_VARIABLE_INCREMENT_INTERVAL : contractIncrement;
    const sequence = generateSequence(first, last, increment);
    return sequence.map(item => ({
            value: item,
            label: percentage ? String(item).concat('%') : String(item).concat(EURO_SYMBOL)
    }));
}

module.exports = {
    mockCmbAccounts: mockCmbAccounts,
    mockOnChangeDropdownAccounts: mockOnChangeDropdownAccounts,
    onChangeIntervener: onChangeIntervener,
    mockContactSheduleSelect: mockContactSheduleSelect,
    mockProvinceSelect: mockProvinceSelect,
    mockTaxRadioOptions: mockTaxRadioOptions,
    mockOwnMoneyRadioOptions: mockOwnMoneyRadioOptions,
    mockThirdPartyTransitionalFunds: mockThirdPartyTransitionalFunds,
    mockPoliticalCharge: mockPoliticalCharge,
    mockForeignTaxRadioOptions: mockForeignTaxRadioOptions,
    mockHoldersRadioOptions: mockHoldersRadioOptions,
    mockCountries: mockCountries,
    mockImtCmb: mockImtCmb,
    mockWorkType: mockWorkType,
    mockActivity: mockActivity,
    mockPeriodicity: mockPeriodicity,
    mockTypeTaxation: mockTypeTaxation,
    mockEmploymentRelationship: mockEmploymentRelationship,
    mockAddHolder: mockAddHolder,
    mockDocumentType: mockDocumentType,
    mockMethodOfPayment: mockMethodOfPayment,
    mockIdentificationTypeCmb: mockIdentificationTypeCmb,
    mockRelationTypeCmb: mockRelationTypeCmb,
    workTypeValues: workTypeValues,
    activitiesValuesYouDontWork: activitiesValuesYouDontWork,
    methodOfPaymentValues: methodOfPaymentValues,
    generateMethodOfPaymentOptions: generateMethodOfPaymentOptions,
    returnFeeAmountIncrease: returnFeeAmountIncrease,
    sortedElements: sortedElements
};
