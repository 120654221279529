const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

function setInitialState(){
    return Immutable.Map({
        clientPersonalInfoUpdated: null,
        generateDocumnentError : false,
        isOpen: false,
        isFetched: false,
        isFetching: null,
        preventError: false
   });
}

function brokerClientWarning(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_CHECK_CLIENT_PERSONAL_INFO_SUCCESS:
            return state.merge({
                clientPersonalInfoUpdated: action.payload.clientPersonalInfoUpdated,
                isFetched: true,
                isFetching: false
            });
        case actionTypes.BROKER_CHECK_CLIENT_PERSONAL_INFO_ERROR:
            return state.merge({
                isFetched: false,   //API call failed
                isFetching: false
            });
        case actionTypes.BROKER_CHECK_CLIENT_PERSONAL_INFO_REQUEST:
            return state.merge({
                isFetched: false,
                isFetching: true
            });
        case actionTypes.BROKER_GENERATE_NATIONAL_ID_DOC_SUCCESS:
            return state.merge({
                generateDocumnentError: false
            });
        case actionTypes.BROKER_GENERATE_NATIONAL_ID_DOC_ERROR:
            return state.merge({
                generateDocumnentError: true
            });
        case actionTypes.BROKER_GENERATE_NATIONAL_ID_DOC_REQUEST:
            return state.merge({
                generateDocumnentError: false
            });
        case actionTypes.BROKER_PREVENT_GENERATE_NATIONAL_ID_DOCUMENT_ERROR:
            return state.merge({
                preventError: true
            });
        case actionTypes.BROKER_SHOW_CLIENT_WARNING_INFO:
            return state.merge({
                isOpen: true,
                preventError: false
            });
        case actionTypes.BROKER_HIDE_CLIENT_WARNING_INFO:
            return state.merge({
                isOpen: false
            });
        default:
            return state
    }
}

module.exports = brokerClientWarning;
