// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes =  require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        terms: false,
        error: ''
    });
}

function validateStep(step) {
    const valid = step.get('terms');
    let error = '';

    if(!valid){
        error = 'error';
    }

    return step.merge({
        valid,
        error
    });
}

function authorizeBizumStep3(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BIZUM_REGISTER__SET_TERMS_CONDITIONS:
            return state.merge({
                terms: action.payload.terms,
                error: ''
            });
        case actionTypes.BIZUM_REGISTER_SET_ERROR:
            return state.merge({
                error: ''
            });
        case actionTypes.BIZUM_REGISTER__VALIDATE_UI_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = authorizeBizumStep3;
