// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { AMAZON_GIFT_CARD_MAX_AMOUNT,
        AMAZON_GIFT_CARD_MIN_AMOUNT
} = require('constants/accountAmazonGiftCard');

function setInitialState() {
    return Immutable.fromJS({
        ...(__CONTACT_CENTER__ ? {
            agreementChecked: false,
            agreementIsDirty: false,
            valid: false,
            accountSelected: '',
            accountIsDirty: false,
            amount: 0,
            amountisDirty: false,
            accountAvailableBalance: 0,
            giftVoucherAmount: 0,
        } : {
            valid: false,
            accountSelected: '',
            accountIsDirty: false,
            amount: 0,
            amountisDirty: false,
            accountAvailableBalance: 0,
            giftVoucherAmount: 0,
        })
    });
}

function validateStep(step) {
    const isAmountGreaterOrEqualToMinAmount = step.get('amount') >= AMAZON_GIFT_CARD_MIN_AMOUNT;
    const isAmountLessOrEqualToMaxAmount = step.get('amount') <= (AMAZON_GIFT_CARD_MAX_AMOUNT);
    const isGiftVoucherAmountLessThanAvailableBalance  = step.get('giftVoucherAmount') <= step.get('accountAvailableBalance');
    const validAmount = isAmountGreaterOrEqualToMinAmount &&
                        isAmountLessOrEqualToMaxAmount &&
                        isGiftVoucherAmountLessThanAvailableBalance;

    if (__CONTACT_CENTER__) {
        return step.merge({
            valid: validAmount && step.get('agreementChecked'),
            agreementIsDirty: true,
            amountisDirty: true,
            accountIsDirty: true,
        });
    } else {
        return step.merge({
            valid: validAmount,
            amountisDirty: true,
            accountIsDirty: true,
        });
    }

}

function accountsBuyAmazonGiftCardStep1(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountisDirty: true
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_ACCOUNT:
            return state.merge({
                accountSelected: action.payload.accountSelected,
                accountIsDirty: true
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_ACCOUNT_AVAILABLE_BALANCE:
            return state.merge({
                accountAvailableBalance: action.payload.accountAvailableBalance
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_VOUCHER_AMOUNT:
            return state.merge({
                giftVoucherAmount: action.payload.giftVoucherAmount
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                agreementChecked: action.payload.checked,
                agreementIsDirty: false
            });
        default:
            return state;
    }
}
module.exports = accountsBuyAmazonGiftCardStep1;
