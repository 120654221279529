// @ vendor
const Immutable = require('immutable');
// @ constants
const {
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_VALIDATE_STEP,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_ACCOUNT,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_TOTAL_COST,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_REQUEST,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_SUCCESS,
    MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_FAILURE
} = require('constants/actionTypes');

const setInitialState = () =>{
    return Immutable.Map().merge({
        valid: false,
        accountId: null,
        accountIsDirty: false,
        totalCost: 0,
        accountBalance: 0,
        enoughAccountBalance: false,
        accountIBAN: null,
        setPaymentAccountInProgress: false,
        validUI: false
    });
};

const validateStep = (step)=> {
    const accountId = step.get('accountId');
    const accountBalance = step.get('accountBalance');
    const totalCost = step.get('totalCost');
    return step.merge({
        validUI: !!accountId && accountBalance >= totalCost,
        valid: !!accountId && accountBalance >= totalCost,
        setPaymentAccountInProgress: false,
        accountIsDirty: true
    });
};

const mortgageRequestSimpleNoteRequestStep2 = (state = setInitialState(), action) => {
    switch (action.type) {
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_WIZARD_VALIDATE_STEP:
            return validateStep(state);
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_ACCOUNT:
            const { accountId, accountBalance, accountIBAN } = action.data;
            return state.merge({
                accountId,
                accountBalance,
                accountIBAN
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_TOTAL_COST:
            return state.merge({
                totalCost: action.data.totalCost,
                validUI: false,
                valid: false
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_REQUEST:
            return state.merge({
                setPaymentAccountInProgress: true
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_SUCCESS:
            return state.merge({
                setPaymentAccountInProgress: false,
                valid: true
            });
        case MORTGAGE_REQUEST_FETCH_SIMPLE_NOTE_REQUEST_SET_PAYMENT_ACCOUNT_FAILURE:
            return state.merge({
                setPaymentAccountInProgress: false,
                valid: false
            });
    }

    return state;
};

module.exports = mortgageRequestSimpleNoteRequestStep2;
