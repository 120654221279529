const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const findKey = require('lodash/object/findKey');
const { FILTERS_VALID } = require('constants/index');

function setInitialState() {
    return Immutable.List();
}

function formatContracts(contracts) {
    const formattedContracts = contracts.map(contract => {
        return {
            filters: {
                valid: get(contract, 'filtros.valido') === FILTERS_VALID
            },
            walletTitular: contract.nombreTitular.trim(),
            walletNumber: contract.contrato.numerodecontrato,
            walletAccountType: contract.descripcion || '-',
            walletBalance: {
                amount: contract.saldo1.importe,
                currency: contract.saldo1.divisa
            },
            associatedAccountAlias: '',
            associatedAccount: '',
            firstTitular: contract.nombreTitular.trim(),
            firstTitularType: contract.descIntervenciones.nomTipInterv.trim(),
            contactNumber: contract.contrato.numerodecontrato,
            productNumber: contract.contrato.producto,
            controlDigit: contract.cuenta.digitodecontrol,
            contractAccount: {
                entityCode: get(contract, 'cuenta.oficina.entidad'),
                officeCode: get(contract, 'cuenta.oficina.oficina'),
                controlDigit: get(contract, 'cuenta.digitodecontrol'),
                accountNumber: get(contract, 'cuenta.numerodecuenta')
            }
        };
    });

    return Immutable.fromJS(formattedContracts);
}

function mappedContract(contract, alias, contractNumber) {
    if (contract.get('walletNumber') === contractNumber) {
        return contract.merge({
            walletAccountType: alias
        });
    }

    return contract.merge(contract);
}

function mergeSateByContractNumber(contractNumber, alias, state) {
    const mergedContractAlias = state.map(contract => {
        return mappedContract(contract, alias, contractNumber);
    });

    return mergedContractAlias;
}

function mapStocks(products = []) {
    const mappedPositions = {};

    products.forEach(product => {
        const valueCode = get(product, 'codigoValor');
        const emisionCode = get(product, 'codigoEmision');

        const quote = get(product, 'importes.consolidado.cotizacion.importeDivisaValor.importe');
        const quoteCurrency = trim(get(product, 'importes.consolidado.cotizacion.importeDivisaValor.moneda'));
        const quoteEur = get(product, 'importes.consolidado.cotizacion.importeEur.importe');
        const quoteEurCurrency = trim(get(product, 'importes.consolidado.cotizacion.importeEur.moneda'));

        const totalValue = get(product, 'importes.consolidado.valor.importeDivisaValor.importe');
        const totalValueCurrency = trim(get(product, 'importes.consolidado.valor.importeDivisaValor.moneda'));
        const totalValueEur = get(product, 'importes.consolidado.valor.importeEur.importe');
        const totalValueEurCurrency = trim(get(product, 'importes.consolidado.valor.importeEur.moneda'));

        const investment = get(product, 'importes.consolidado.capitalInvertido.importeDivisaValor.importe');
        const investmentEur = get(product, 'importes.consolidado.capitalInvertido.importeEur.importe');

        const profitability = get(product, 'importes.consolidado.rentabilidad.importeDivisaValor.importe');
        const profitabilityEur = get(product, 'importes.consolidado.rentabilidad.importeEur.importe');

        const titles = get(product, 'importes.consolidado.titulos');
        const sellables = get(product, 'importes.total') && get(product, 'importes.total.titulos') ? get(product, 'importes.total.titulos') : titles;

        if (valueCode && emisionCode) {
            mappedPositions[valueCode + emisionCode] = {
                titles,
                sellables,
                valueCode,
                emisionCode,
                name: trim(get(product, 'instrumentInfo.instrument')),
                productType: get(product, 'tipoValor'),
                quoteDate: get(product, 'importes.consolidado.fechaCotizacion'),
                quoteTime: get(product, 'importes.consolidado.horaCotizacion'),
                quote: quote == null ? quoteEur : quote,
                quoteCurrency: quoteCurrency == null ? quoteEurCurrency : quoteCurrency,
                totalValue: totalValue == null ? totalValueEur : totalValue,
                totalValueCurrency: totalValueCurrency == null ? totalValueEurCurrency : totalValueCurrency,
                isin: get(product, 'instrumentInfo.instrumentId'),
                iso: get(product, 'instrumentInfo.micCode'),
                investedCapital: investment == null ? investmentEur : investment,
                profitability: profitability == null ? profitabilityEur : profitability
            };
        }
    });

    return mappedPositions;
}

function decorateContract(stateContract, contractsObject) {
    const contractKey = findKey(contractsObject, backendContract => {
        const numerodecontrato = trim(get(backendContract, 'contrato'));
        return numerodecontrato.endsWith(stateContract.get('contactNumber'));
    });
    let result;

    if (contractKey) {
        const associatedAccount =
            get(contractsObject[contractKey], 'cuentaAsociada') ||
            get(contractsObject[contractKey], 'valores[0].cuentaAsociada');

        const associatedAccountBalanceCurrency =
            get(associatedAccount, 'saldo.divisa') ||
            get(associatedAccount, 'saldo.moneda');

        const aux = {
            associatedAccount: trim(get(associatedAccount, 'codBan')),
            associatedAccountBalance: {
                amount: get(associatedAccount, 'saldo.importe', 0),
                currency: trim(associatedAccountBalanceCurrency)
            },
            stocks: mapStocks(get(contractsObject[contractKey], 'valores'))
        };
        result = stateContract.mergeDeep(aux);
    } else {
        result = stateContract;
    }

    return result;
}

function decorateContracts(contractsObject, state) {
    const mergedContracts = state.map(contract => {
        return decorateContract(contract, contractsObject);
    });

    return mergedContracts;
}

function mapAccountDescription(contract, accounts) {
    const accountDetails = accounts.toList().find(account => {
        const productNumber = contract.get('associatedAccount').substring(10, 13);
        const contractNumber = contract.get('associatedAccount').substring(13);

        return (
            account.get('product') === productNumber &&
            account.get('contractNumber') === contractNumber
        );
    });

    let accountAlias;
    if (accountDetails) {
        accountAlias = accountDetails.get('alias') ? accountDetails.get('alias') : '-';
    }

    return contract.mergeDeep({
        associatedAccountAlias: accountDetails ? accountAlias : '-'
    });
}

function mapAccountsDescriptions(accounts, state) {
    const mergedContracts = state.map(contract => {
        return mapAccountDescription(contract, accounts);
    });

    return mergedContracts;
}

function BrokerStocksContracts(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_WALLET_LIST_SUCCESS:
            return formatContracts(action.walletList);
        case actionTypes.SET_WALLET_ALIAS_SUCCESS:
            const immArr = mergeSateByContractNumber(
                action.payload.walletNumber,
                action.payload.alias,
                state
            );

            return state.mergeDeep(immArr);
        case actionTypes.BROKER_GET_MY_INVESTMENTS_SUCCESS:
            const mappedContracts = decorateContracts(get(action, 'payload.contracts'), state);

            return state.merge(mappedContracts);
        case actionTypes.BROKER_CONTRACT_POSITIONS_GET_ACCOUNTS_DETAILS:
            const mappedAccounts = mapAccountsDescriptions(get(action, 'payload.accounts'), state);

            return state.merge(mappedAccounts);
        case actionTypes.BROKER_GET_WALLET_LIST_FAILURE:
        default:
            return state;
    }
}

module.exports = BrokerStocksContracts;
