// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
const statusValues = require('constants/contactCenter/passwordPositionsStatusValues');

// @ utilities
const StringHelper = require('utilities/stringHelper');

const INITIAL_STATE = Immutable.fromJS({
    status: statusValues.IDLE,
    passwordLength: 0,
    requiredDigits: null,
    errorMessage: null
});

const passwordPositionsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.PASSWORD_POSITIONS_CONFIG_REQUEST:
            return Immutable.fromJS({
                status: statusValues.FETCHING_CONFIG,
                passwordLength: 0,
                requiredDigits: null,
                errorMessage: null
            });

        case actionTypes.PASSWORD_POSITIONS_CONFIG_REQUEST_SUCCESS:
            return Immutable.fromJS({
                status: statusValues.CONFIG_FETCHED_SUCCESSFULLY,
                passwordLength: action.payload.passwordLength,
                requiredDigits: StringHelper.toNumericArray(action.payload.requiredDigits),
                errorMessage: null
            });

        case actionTypes.PASSWORD_POSITIONS_CONFIG_REQUEST_FAILURE:
            return Immutable.fromJS({
                status: statusValues.CONFIG_FETCH_ERROR,
                passwordLength: 0,
                requiredDigits: null,
                errorMessage: action.payload.message
            });

        case actionTypes.PASSWORD_POSITIONS_RESET:
            return INITIAL_STATE;

        default:
            return state;
    }
};

module.exports = passwordPositionsReducer;
