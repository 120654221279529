const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    accountId: '',
    accountIsDirty: false,
    valid: false
});

function validateStep(state) {
    return state.merge({
        valid: !!state.get('accountId'),
        accountIsDirty: true
    });
}

function hireCardMaestroStep2(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_MAESTRO_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_CARD_MAESTRO_SET_ACCOUNT:
            return state.merge({
                accountId: action.payload.accountId,
                accountIsDirty: true
            });

        default:
            return state;
    }
}

module.exports = hireCardMaestroStep2;
