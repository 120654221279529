// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    products: {}
});

function preContractualInfo(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.PRE_CONTRACTUAL_INFO_REQUEST:
            action.payload.product.link = '';
            action.payload.product.error = false;
            return state.setIn(['products', action.payload.id], Immutable.fromJS(action.payload.product));
        case actionTypes.PRE_CONTRACTUAL_INFO_SUCCESS:
            return state.setIn(['products', action.payload.id, 'link'], action.payload.link);
        case actionTypes.PRE_CONTRACTUAL_INFO_FAILURE:
            return state.setIn(['products', action.payload.id, 'error'], true);
        case actionTypes.PRE_CONTRACTUAL_INFO_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = preContractualInfo;
