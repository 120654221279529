const mapKeys = require('lodash/object/mapKeys')
const contains = require('lodash/collection/contains')
const endsWith = require('lodash/string/endsWith')
const get = require('lodash/object/get')

const { ACTION_CODE_PREFIX } = require('./constants');

const unwrapActionCode = actionCode => {
    if (!actionCode) {
        return actionCode;
    }

    return parseInt(actionCode.replace(ACTION_CODE_PREFIX, ''));
}

const wrapActionCode = actionCode => contains(actionCode, ACTION_CODE_PREFIX) ?
    actionCode : `${ACTION_CODE_PREFIX}${actionCode}`

/*
    This method prefixes the action code constants in
    the flows, avoiding them to be numerical values.

    This comes in handy as it is not necessary to check
    whether the action is number or string. So that parsing
    or type comparisson are not necessary
*/
const prefixActionCodesInFlows = flows => mapKeys(flows, (item, key) => wrapActionCode(key))

const checkFinishActionsCode = {
    actionsCode: [],
    add(...items) {
        this.actionsCode.push(...items);
    },
    get(index) {
        return this.actionsCode[index] || '';
    },
    getAll() {
        return this.actionsCode;
    },
    clear() {
        this.actionsCode = []
    }
}

const pickBy = (o, fnc) => {
    const obj = {};

    for (const key in o) {
        if (typeof fnc === 'function') {
            if (fnc(o[key])) {
                obj[key] = o[key];
            }
        } else {
            if (o[key] !== null && o[key] !== false && o[key] !== undefined) {
                obj[key] = o[key];
            }
        }
    }

    return obj;
}

const testPath = (path, location) => {
    const pathInLocation = get(location, 'pathname');

    if (!path || !pathInLocation) {
        return;
    }

    const regex = new RegExp(`^${path}$`, 'i');
    const pathToTest = endsWith(pathInLocation, '/') ?
        pathInLocation.slice(0, -1) : pathInLocation;

    return regex.test(pathToTest);
}

module.exports = {
    prefixActionCodesInFlows,
    checkFinishActionsCode,
    testPath,
    unwrapActionCode,
    pickBy,
    wrapActionCode
}
