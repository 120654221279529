const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const { FILTERS_VALID } = require('constants/index');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const includes = require('lodash/collection/includes');
const findKey = require('lodash/object/findKey');

function setInitialState() {
    return  Immutable.fromJS({
        funds: Immutable.List(),
        contracts: [],
        accounts: []
    });
}

function formatFunds(funds, walletList) {

    const formattedFunds = funds.map(fund => {
        const wallet = walletList.find(
                wallet => wallet.accountNumber === fund.contratoUnificadoPart.numerodecontrato
            );
        return {

            filters: {
                valid: wallet.filtros.valido === FILTERS_VALID
            },
            firstOwner: wallet.owner,
            firstOwnerType: fund.tipoIntervencion.trim(),

            walletTitular: wallet.owner,
            walletNumber: fund.contratoUnificadoPart.numerodecontrato,

            walletAccountType: wallet.name,
            walletBalance: {
                amount: fund.importe,
                currency: fund.divisa
            },

            firstTitular: wallet.owner,
            firstTitularType: fund.tipoIntervencion.trim(),
            productNumber: fund.contratoUnificadoPart.producto,
            controlDigit: fund.contratoUnificado.digitodecontrol

        };
    });

    return Immutable.fromJS(formattedFunds);
}

function decorateContract(stateContract, contractsObject) {
    const stockContract = contractsObject.toJS();
    const contractKey = findKey(stockContract, backendContract => {
        const numerodecontrato = get(backendContract, 'elements[0].contrato.numerodecontrato');
        return numerodecontrato === stateContract.get('walletNumber');
    });
    let result;

    if (contractKey) {
        const aux = {
            associatedAccount: trim(
                get(stockContract[contractKey], 'elements[0].cuentaAsociada.codbban')
            ),
            associatedAccountBalance: {
                amount: get(stockContract[contractKey], 'elements[0].saldoCuentaAsociada.importe', 0),
                currency: get(stockContract[contractKey], 'elements[0].saldoCuentaAsociada.divisa', '')
            },
            titlesQuantity: get(stockContract[contractKey], 'elements[0].posicionContrato.titulos', 0)
        };
        result = stateContract.mergeDeep(aux);
    }
    else {
        result = stateContract;
    }

    return result;
}

function decorateContracts(contractsObject, state) {
    const mergedContracts = state.map(contract => {
        return decorateContract(contract, contractsObject);
    });

    return mergedContracts;
}

function mapAccountDescription(contract, accounts) {
    const accountDescription = accounts.toList().find(account => {
        return includes(
            account.get('iban'),
            contract.get('associatedAccount')
        );
    });

    return contract.mergeDeep({
        associatedAccountAlias: (accountDescription?accountDescription.get('alias'): '')
    });
}

function mapAccountsDescriptions(accounts, state) {
    const mergedContracts = state.map(contract => {
        return mapAccountDescription(contract, accounts);
    });

    return mergedContracts;
}

function BrockerTransferExternalFunds(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_TRASPASS_FUNDS_SUCCESS:
            const funds = formatFunds(action.payload.response.contratos.dato, action.payload.walletList);
            const mappedContracts = decorateContracts(
                state.get('contracts'),
                funds
            );
            const mappedAccounts = mapAccountsDescriptions(
                state.get('accounts'),
                mappedContracts
            );
            return state.merge({
                funds: mappedAccounts
            });
        case actionTypes.BROKER_GET_MY_INVESTMENTS_SUCCESS:
            return state.merge({
                contracts: get(action, 'payload.contracts')
            });
        case actionTypes.BROKER_CONTRACT_POSITIONS_GET_ACCOUNTS_DETAILS:
            return state.merge({
                accounts: get(action, 'payload.accounts')
            });
        default:
            return state;
    }
}

module.exports = BrockerTransferExternalFunds;
