// @vendors
const Immutable = require('immutable');
const moment = require('moment');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        emittedDateTime: moment(),
        isEmitted: false,
        checked: false,
        checkedWarning: false,
        checkedIsValid: true,
        checkedWarningIsValid: true,
        allDocsRead: false,
        showWarning: false
    });
}

function validateStep(step) {
    const checkedIsValid = step.get('checked') || __CONTACT_CENTER__;
    const checkedWarningIsValid = step.get('checkedWarning') || __CONTACT_CENTER__;
    return step.merge({
        valid: checkedIsValid && checkedWarningIsValid,
        checkedIsValid,
        checkedWarningIsValid
    });
}

const toggleManifestCheckbox = (immState) => {
    if (immState.get('allDocsRead')) {
        const checked = immState.get('checked');
        return immState.merge({
            checked: !checked,
            showWarning: false,
            valid: true,
            checkedIsValid: true
        });
    }
    else {
        return immState.set('showWarning', true);
    }
};

const setDocumentsRead = (state) => {
    return state.merge({
        allDocsRead: true
    });
};

function brokerWarrantsBuyStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_BUY_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_SUBSCRIPTION_CHANGE_READCHECKED:
            return toggleManifestCheckbox(state);
        case actionTypes.BROKER_WARRANTS_BUY_DOCUMENTS_READ:
            return setDocumentsRead(state);
        case actionTypes.BROKER_WARRANTS_BUY_FINISH:
            let newState = state.merge({
               isEmitted: true,
               emittedDateTime: moment(parseInt(action.payload.data))
            });
            return newState;
        case actionTypes.BROKER_WARRANTS_SUBSCRIPTION_CHANGE_WARNING_OPERATION_CHECKED:
            const checkedWarning = state.get('checkedWarning');
            return state.merge({
                checkedWarning: !checkedWarning,
                checkedWarningIsValid: true
            });
        default:
            return state;
    }
}

module.exports = brokerWarrantsBuyStep2;
