// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    disableAddNewFile: true,
    error: false,
    errorDescription: '',
    files: {
        dni: {
            dniDocumentBack: {},
            dniDocumentFront: {}
        },
        familyBook: [
            {
                name: 'familyBookDocument1',
                data: {},
                error: false,
            }
        ]
    },
    isFetching: false,
    submitSuccessful: false,
    valid: false,
});

function validateStep(state) {
    const mandatoryDocumentsAttached = !state.getIn(['files', 'dni', 'dniDocumentBack']).isEmpty() ||
    !state.getIn(['files', 'dni', 'dniDocumentFront']).isEmpty() ||
    !state.getIn(['files', 'familyBook', 0, 'data']).isEmpty();

    const familyBookDocumentsWithErrors = state.getIn(['files', 'familyBook']).filter(document => document.get('error'));

    return state.merge({
        valid: mandatoryDocumentsAttached && familyBookDocumentsWithErrors.size === 0
    });
}

function uploadDocumentsOpenYoungReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_REQUEST:
            return state.merge({
                error: false,
                isFetching: true,
            });

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isFetching: false,
            });

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_UPLOAD_FAILURE:
            return state.merge({
                error: true,
                errorDescription: action.payload.errorDescription,
                submitSuccessful: false,
                isFetching: false,
            });

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_FILE_DNI:
            const fileObjDNI = {
                name: action.payload.name,
                data: action.payload.file
            };

            return state.mergeDeep({
                files: {
                    dni: {
                        [fileObjDNI.name]: fileObjDNI
                    }
                }
            });

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_FILE_FAMILY_BOOK:
            const disableAddNewFile = state.getIn(['files', 'familyBook']).size >= 3 || action.payload.error;
            const newListFamilyBookDocumentsAdd = state.getIn(['files', 'familyBook']).update(
                state.getIn(['files', 'familyBook']).findIndex(immDocument => immDocument.get('name') === action.payload.name),
                immDocument => immDocument.merge({data: action.payload.file, error: action.payload.error})
            );

            return state.mergeDeep({
                disableAddNewFile: disableAddNewFile,
                files: {
                    familyBook: newListFamilyBookDocumentsAdd
                }
            });

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_ADD_NEW_CONTAINER_FOR_FILE:
            const newName = `familyBookDocument${state.getIn(['files', 'familyBook']).size + 1}`;
            const newListFamilyBookDocuments = state.getIn(['files', 'familyBook']).push(Immutable.fromJS({
                name: newName,
                data: {}
            }));

            return state.mergeDeep({
                disableAddNewFile: true,
                files: {
                    familyBook: newListFamilyBookDocuments
                }
            });

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_DELETE_FILE_DNI:
            return state.setIn(['files', 'dni', action.payload.name], initialState.getIn(['files', 'dni', action.payload.name]));

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_DELETE_FILE_FAMILY_BOOK:
            const newListFamilyBookDocumentsDelete = state.getIn(['files', 'familyBook']).update(
                state.getIn(['files', 'familyBook']).findIndex(immDocument => immDocument.get('name') === action.payload.name),
                immDocument => immDocument.withMutations(document => {
                    document.set('data',initialState.getIn(['files', 'familyBook', 0, 'data'])).set('error', false);
                })
            );

            return state.setIn(['files', 'familyBook'], newListFamilyBookDocumentsDelete);

        case actionTypes.UPLOAD_DOCUMENTS_OPEN_YOUNG_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = uploadDocumentsOpenYoungReducer;




