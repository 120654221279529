const preApprovedContract = require('./preApprovedContractLib');
const eAccountContract = require('./eAccountLib');
const personalLoanContract = require('./personalLoanLib');
const juniorAccountContract = require('./juniorAccountLib');
const miniCard = require('./miniCardLib');
const openStudent = require('./openStudentLib');
const eCard = require('./eCardLib');
const checkingAccount = require('./checkingAccountLib');
const checkingPayrollAccount = require('./checkingPayrollAccountLib');
const payrollAccount = require('./payrollAccountLib');
const depositThirteenMonths = require('./depositThirteenMonthsLib');
const depositEighteenMonths = require('./depositEighteenMonthsLib');
const depositnm =  require('./depositnmLib');
const depositwnm = require('./depositwnmLib')
const depositTransfer = require('./depositTransferLib');
const depositRenewal = require('./depositRenewalLib');
const depositThirteenMonthsExcepciones = require('./depositThirteenMonthsExcepcionesLib');
const combinedDeposit = require('./combinedDepositLib');
const depositThirteenMonthsLeakRetention = require('./depositThirteenMonthsLeakRetentionLib');
const depositThirteenMonthsDueDates = require('./depositThirteenMonthsDueDatesLib');
const depositThirteenMonthsLinkedMaturities = require('./depositThirteenMonthsLinkedMaturitiesLib');
const depositThirteenMonthsRenovacionExcepciones = require('./depositThirteenMonthsRenovacionExcepcionesLib');
const depositEighteenMonthsRetention = require('./depositEighteenMonthsRetentionLib');
const depositTwentyFourMonthsRetention = require('./depositTwentyFourMonthsRetentionLib');
const noNewMoneyDeposits = require('./noNewMoneyDepositsLib');
const duoCombinedDeposit = require('./duoCombinedDepositLib');
const diamondPremiumViaTVisa = require('./diamondPremiumViaTVisaLib');
const upgradeToPayrollAccount = require('./upgradeToPayrollAccountLib');
const fioc = require('./fiocLib');

module.exports = {
    //preContract Lib
    onChangeSliderMoneyNeeded: preApprovedContract.onChangeSliderMoneyNeeded,
    onChangeSliderMonthsNeeded: preApprovedContract.onChangeSliderMonthsNeeded,
    loadCmbPurpose: preApprovedContract.loadCmbPurpose,
    loadCmbAccounts: preApprovedContract.loadCmbAccounts,
    onChangeDropdownAccounts: preApprovedContract.onChangeDropdownAccounts,
    onChangeCmbPurpose: preApprovedContract.onChangeCmbPurpose,
    loadRadioButtonPayDay: preApprovedContract.loadRadioButtonPayDay,
    pre_approved_loan_validateStep: preApprovedContract.pre_approved_loan_validateStep,
    pre_approved_loan_HireParams: preApprovedContract.pre_approved_loan_HireParams,
    pre_approved_loan_SignParams: preApprovedContract.pre_approved_loan_SignParams,
    pre_approved_loan_hasCheckboxes: false,

    //eAccount Lib
    eAccount_onChangeAmount: eAccountContract.eAccount_onChangeAmount,
    eAccount_onChangeIntervener: eAccountContract.eAccount_onChangeIntervener,
    e_account_loan_validateStep: eAccountContract.eAccount_validateStep,
    eAccount_pollingNextButtonDisabled: eAccountContract.eAccount_pollingNextButtonDisabled,
    eAccount_verifyAccountLimit: eAccountContract.verifyAccountLimit,
    eAccount_loadTermsAndConditionsLegalWindow: eAccountContract.loadTermsAndConditionsLegalWindow,
    eAccount_SignParams: eAccountContract.eAccount_signParams,
    eAccount_hireParams: eAccountContract.eAccount_hireParams,
    eAccount_buildExtraInfo: eAccountContract.eAccount_buildExtraInfo,
    e_account_loan_hasCheckboxes: true,
    eAccount_setSuccessStepPrefix: eAccountContract.eAccount_setSuccessStepPrefix,
    eAccount_successStepCustomInstructions: eAccountContract.eAccount_successStepCustomInstructions,

    //personal Lib
    personal_loadCmbWorkType: personalLoanContract.personal_loadCmbWorkType,
    personal_onChangeCmbWorkType: personalLoanContract.personal_onChangeCmbWorkType,
    personal_loadActivity: personalLoanContract.personal_loadActivity,
    personal_onChangeCmbActivity: personalLoanContract.personal_onChangeCmbActivity,
    personal_onChangeSliderMonthsNeeded: personalLoanContract.personal_onChangeSliderMonthsNeeded,
    personal_onChangeSliderMoneyNeeded: personalLoanContract.personal_onChangeSliderMoneyNeeded,
    personalLoan_onChangeCmbPurpose: personalLoanContract.personalLoan_onChangeCmbPurpose,
    personalLoan_handleBlur: personalLoanContract.personalLoan_handleBlur,
    personalLoan_handleDatePickerCallback:
        personalLoanContract.personalLoan_handleDatePickerCallback,
    personalLoan_onChangeAmount: personalLoanContract.personalLoan_onChangeAmount,
    personalLoan_loadCmbPais: personalLoanContract.personalLoan_loadCmbPais,
    personalLoan_loadCmbProvince: personalLoanContract.personalLoan_loadCmbProvince,
    personalLoan_loadCmbTypeOfRoad: personalLoanContract.personalLoan_loadCmbTypeOfRoad,
    personalLoan_loadCmbContactSchedule: personalLoanContract.personalLoan_loadCmbContactSchedule,
    personalLoan_loadCmbFormOfIntervention:
        personalLoanContract.personalLoan_loadCmbFormOfIntervention,
    personalLoan_loadCmbInterventionType: personalLoanContract.personalLoan_loadCmbInterventionType,
    personalLoan_loadCmbRelationshipWithFirstTitular:
        personalLoanContract.personalLoan_loadCmbRelationshipWithFirstTitular,
    personalLoan_loadCmbTaxResidenceInSpain:
        personalLoanContract.personalLoan_loadCmbTaxResidenceInSpain,
    personalLoan_loadCmbNationality: personalLoanContract.personalLoan_loadCmbNationality,
    personalLoan_loadCmbResidenceCountry: personalLoanContract.personalLoan_loadCmbResidenceCountry,
    personalLoan_loadCmbCountryOfBirth: personalLoanContract.personalLoan_loadCmbCountryOfBirth,
    personalLoan_loadCmbLanguage: personalLoanContract.personalLoan_loadCmbLanguage,
    personalLoan_loadCmbLevelOfStudies: personalLoanContract.personalLoan_loadCmbLevelOfStudies,
    personalLoan_loadCmbCivilStatus: personalLoanContract.personalLoan_loadCmbCivilStatus,
    personalLoan_loadCmbGender: personalLoanContract.personalLoan_loadCmbGender,
    personalLoan_loadCmbDocumentType: personalLoanContract.personalLoan_loadCmbDocumentType,
    personalLoan_loadCmbLoanPurpose: personalLoanContract.personalLoan_loadCmbLoanPurpose,
    personalLoan_loadCmbGracePeriod: personalLoanContract.personalLoan_loadCmbGracePeriod,
    personalLoan_loadCmbCurrentHousingRegimen:
        personalLoanContract.personalLoan_loadCmbCurrentHousingRegimen,
    personalLoan_loadCmbCurrentMortgageCancellationFee:
        personalLoanContract.personalLoan_loadCmbCurrentMortgageCancellationFee,
    personalLoan_loadCmbHolder: personalLoanContract.personalLoan_loadCmbHolder,
    personalLoan_loadCmbGuarantor: personalLoanContract.personalLoan_loadCmbGuarantor,
    personal_loan_validateStep: personalLoanContract.personal_loan_validateStep,
    personal_loan_activity_own_account: personalLoanContract.personalLoan_activity_own_account,
    personal_loan_hasCheckboxes: false,

    //junior Account Lib
    junior_account_SignParams: juniorAccountContract.juniorAccount_SignParams,
    junior_account_validateStep: juniorAccountContract.juniorAccount_validateStep,
    juniorAccount_disableSecondStepNextButton: juniorAccountContract.juniorAccount_disableSecondStepNextButton,
    juniorAccount_doesntHaveCardOwners: juniorAccountContract.juniorAccount_doesntHaveCardOwners,
    juniorAccount_getCardOwners: juniorAccountContract.juniorAccount_getCardOwners,
    juniorAccount_handleBlur: juniorAccountContract.juniorAccount_handleBlur,
    juniorAccount_handleDatePickerCallback:
        juniorAccountContract.juniorAccount_handleDatePickerCallback,
    juniorAccount_hasCardOwners: juniorAccountContract.juniorAccount_hasCardOwners,
    juniorAccount_loadCardOwnerInfoInForms: juniorAccountContract.juniorAccount_loadCardOwnerInfoInForms,
    juniorAccount_loadCmbCountries: juniorAccountContract.juniorAccount_loadCmbCountries,
    juniorAccount_loadCmbDocumentType: juniorAccountContract.juniorAccount_loadCmbDocumentType,
    juniorAccount_loadCmbProvince: juniorAccountContract.juniorAccount_loadCmbProvince,
    juniorAccount_loadCmbRelationship: juniorAccountContract.juniorAccount_loadCmbRelationship,
    juniorAccount_loadCmbTaxReason: juniorAccountContract.juniorAccount_loadCmbTaxReason,
    juniorAccount_loadTypeOfRoad: juniorAccountContract.juniorAccount_loadTypeOfRoad,
    juniorAccount_onChangeAmount: juniorAccountContract.juniorAccount_onChangeAmount,
    juniorAccount_onChangeCardOwner: juniorAccountContract.juniorAccount_onChangeCardOwner,
    juniorAccount_onChangeCheckBox: juniorAccountContract.juniorAccount_onChangeCheckBox,
    juniorAccount_onClickNewBeneficiary: juniorAccountContract.juniorAccount_onClickNewBeneficiary,
    juniorAccount_validationModalContent:
        juniorAccountContract.juniorAccount_validationModalContent,
    juniorAccount_setCardOwners: juniorAccountContract.juniorAccount_setCardOwners,
    juniorAccount_showBeneficiaryDataForms: juniorAccountContract.juniorAccount_showBeneficiaryDataForms,
    juniorAccount_showCheckboxTutor: juniorAccountContract.juniorAccount_showCheckboxTutor,
    juniorAccount_successInstructions: juniorAccountContract.juniorAccount_successInstructions,
    juniorAccount_setSuccessStepPrefix: juniorAccountContract.juniorAccount_setSuccessStepPrefix,
    juniorAccount_onChangeSelect: juniorAccountContract.juniorAccount_onChangeSelect,
    juniorAccount_modalInfoAction: juniorAccountContract.juniorAccount_modalInfoAction,
    junior_account_hasCheckboxes: true,

    //MiniCard Lib
    mini_card_validateStep: miniCard.mini_card_validateStep,
    miniCard_loadCmbAccounts: miniCard.miniCard_loadCmbAccounts,
    miniCard_onChangeDropdownAccounts: miniCard.miniCard_onChangeDropdownAccounts,
    miniCard_onChangeAmount: miniCard.miniCard_onChangeAmount,
    miniCard_onChangeIntervener: miniCard.miniCard_onChangeIntervener,
    miniCard_HireParams: miniCard.miniCard_HireParams,
    miniCard_SignParams: miniCard.miniCard_SignParams,
    miniCard_setSuccessStepPrefix: miniCard.miniCard_setSuccessStepPrefix,
    mini_card_hasCheckboxes: true,

    //OpenStudent Lib
    open_student_validateStep: openStudent.open_student_validateStep,
    openStudent_loadCmbAccounts: openStudent.openStudent_loadCmbAccounts,
    openStudent_onChangeDropdownAccounts: openStudent.openStudent_onChangeDropdownAccounts,
    openStudent_onChangeAmount: openStudent.openStudent_onChangeAmount,
    openStudent_onChangeIntervener: openStudent.openStudent_onChangeIntervener,
    openStudent_HireParams: openStudent.openStudent_HireParams,
    openStudent_SignParams: openStudent.openStudent_SignParams,
    openStudent_setSuccessStepPrefix: openStudent.openStudent_setSuccessStepPrefix,
    open_student_hasCheckboxes: false,

    //ECard Lib
    e_card_validateStep: eCard.e_card_validateStep,
    eCard_loadCmbAccounts: eCard.eCard_loadCmbAccounts,
    eCard_onChangeDropdownAccounts: eCard.eCard_onChangeDropdownAccounts,
    eCard_onChangeAmount: eCard.eCard_onChangeAmount,
    eCard_onChangeIntervener: eCard.eCard_onChangeIntervener,
    e_card_HireParams: eCard.e_card_HireParams,
    e_card_SignParams: eCard.e_card_SignParams,
    e_card_hasCheckboxes: false,
    eCard_setSuccessStepPrefix: eCard.eCard_setSuccessStepPrefix,

    //Checking Account Lib
    checking_account_validateStep: checkingPayrollAccount.checkingPayrollAccount_validateStep,
    checkingAccount_setSuccessStepPrefix: checkingAccount.checkingAccount_setSuccessStepPrefix,
    checkingAccount_handleStep2LoadTagging: checkingAccount.checkingAccount_handleStep2LoadTagging,
    checking_account_hasCheckboxes: true,

    //Checking and Payroll Account Lib
    checkingPayrollAccount_disableFirstStepButton: checkingPayrollAccount.checkingPayrollAccount_disableFirstStepButton,
    checkingPayrollAccount_generalActivities:
        checkingPayrollAccount.checkingPayrollAccount_generalActivities,
    checkingPayrollAccount_handleBlur: checkingPayrollAccount.checkingPayrollAccount_handleBlur,
    checkingPayrollAccount_handleCardImageChange: checkingPayrollAccount.checkingPayrollAccount_handleCardImageChange,
    checkingPayrollAccount_handleChange: checkingPayrollAccount.checkingPayrollAccount_handleChange,
    checkingPayrollAccount_handleChangeRoadName: checkingPayrollAccount.checkingPayrollAccount_handleChangeRoadName,
    checkingPayrollAccount_handleCheck: checkingPayrollAccount.checkingPayrollAccount_handleCheck,
    checkingPayrollAccount_handleDatePickerCallback:
        checkingPayrollAccount.checkingPayrollAccount_handleDatePickerCallback,
    checkingPayrollAccount_handlePinBlur: checkingPayrollAccount.checkingPayrollAccount_handlePinBlur,
    checkingPayrollAccount_HireParams: checkingPayrollAccount.checkingPayrollAccount_HireParams,
    checkingPayrollAccount_instructions: checkingPayrollAccount.checkingPayrollAccount_instructions,
    // THIS WILL STAY COMMENTED UNTIL THE POSTAL CODE ISN'T REQUIRED FOR 'AUTORIZADO' HIRING
    // checkingPayrollAccount_isHolder: checkingPayrollAccount.checkingPayrollAccount_isHolder,
    checkingPayrollAccount_loadCmbNationality:
        checkingPayrollAccount.checkingPayrollAccount_loadCmbNationality,
    checkingPayrollAccount_loadIncomeAmounts:
        checkingPayrollAccount.checkingPayrollAccount_loadIncomeAmounts,
    checkingPayrollAccount_loadCmbNationalityWithSpainDisabled:
        checkingPayrollAccount.checkingPayrollAccount_loadCmbNationalityWithSpainDisabled,
    checkingPayrollAccount_loadCmbProvince:
        checkingPayrollAccount.checkingPayrollAccount_loadCmbProvince,
    checkingPayrollAccount_loadForeignTaxRadioOptions:
        checkingPayrollAccount.checkingPayrollAccount_loadForeignTaxRadioOptions,
    checkingPayrollAccount_loadHoldersRadioOptions:
        checkingPayrollAccount.checkingPayrollAccount_loadHoldersRadioOptions,
    checkingPayrollAccount_loadIdentificationTypeCmb:
        checkingPayrollAccount.checkingPayrollAccount_loadIdentificationTypeCmb,
    checkingPayrollAccount_loadImtCmb: checkingPayrollAccount.checkingPayrollAccount_loadImtCmb,
    checkingPayrollAccount_loadRelationTypeCmb:
        checkingPayrollAccount.checkingPayrollAccount_loadRelationTypeCmb,
    checkingPayrollAccount_loadTaxCountryCmb:
        checkingPayrollAccount.checkingPayrollAccount_loadTaxCountryCmb,
    checkingPayrollAccount_loadTypeOfRoad:
        checkingPayrollAccount.checkingPayrollAccount_loadTypeOfRoad,
    checkingPayrollAccount_nextButtonDisabled: checkingPayrollAccount.checkingPayrollAccount_nextButtonDisabled,
    checkingPayrollAccount_loadTermsAndConditionsLegalWindow: checkingPayrollAccount.checkingPayrollAccount_loadTermsAndConditionsLegalWindow,
    checkingPayrollAccount_onBackClicked:
        checkingPayrollAccount.checkingPayrollAccount_onBackClicked,
    checkingPayrollAccount_onBackClickedFioc:
        checkingPayrollAccount.checkingPayrollAccount_onBackClickedFioc,
    checkingPayrollAccount_onChangeCmbPurpose:
        checkingPayrollAccount.checkingPayrollAccount_onChangeCmbPurpose,
    checkingPayrollAccount_onChangeFioc: checkingPayrollAccount.checkingPayrollAccount_onChangeFioc,
    checkingPayrollAccount_onConfirmBackClicked:
        checkingPayrollAccount.checkingPayrollAccount_onConfirmBackClicked,
    checkingPayrollAccount_onConfirmBackClickedFioc:
        checkingPayrollAccount.checkingPayrollAccount_onConfirmBackClickedFioc,
    checkingPayrollAccount_SignParams: checkingPayrollAccount.checkingPayrollAccount_SignParams,
    checkingPayrollAccount_onChangeSelect: checkingPayrollAccount.checkingPayrollAccount_onChangeSelect,
    checkingCountryOfBirthPayrollAccount_onChangeSelect: checkingPayrollAccount.checkingCountryOfBirthPayrollAccount_onChangeSelect,
    checkingPayrollAccount_modalInfoAction: checkingPayrollAccount.checkingPayrollAccount_modalInfoAction,
    checkingPayrollAccount_onBlurValidateCondition: checkingPayrollAccount.checkingPayrollAccount_onBlurValidateCondition,
    visa_open_hasCheckboxes: true,
    checkingPayrollAccount_WarningContractMessage: checkingPayrollAccount.checkingPayrollAccount_WarningContractMessage,


    //Payroll Account Lib
    payroll_account_validateStep: checkingPayrollAccount.checkingPayrollAccount_validateStep,
    payrollAccount_buildUpgradeToPayrollAccountStep: payrollAccount.payrollAccount_buildUpgradeToPayrollAccountStep,
    payrollAccount_getTranslations: payrollAccount.payrollAccount_getTranslations,
    payrollAccount_setSuccessStepPrefix: payrollAccount.payrollAccount_setSuccessStepPrefix,
    payroll_account_hasCheckboxes: true,

    //Deposit Thirteen Months
    depositThirteenMonths_customErrorNotAllowed: depositThirteenMonths.depositThirteenMonths_customErrorNotAllowed,
    deposit_thirteen_months_validateStep: depositThirteenMonths.deposit_thirteen_months_validateStep,
    deposit_thirteen_months_loan_SignParams: depositThirteenMonths.deposit_thirteen_months_loan_SignParams,
    depositThirteenMonths_onChangeIntervener: depositThirteenMonths.depositThirteenMonths_onChangeIntervener,
    depositThirteenMonths_onChangeAmount: depositThirteenMonths.depositThirteenMonths_onChangeAmount,
    depositThirteenMonths_loadCmbAccounts: depositThirteenMonths.depositThirteenMonths_loadCmbAccounts,
    depositThirteenMonths_onChangeDropdownAccounts: depositThirteenMonths.depositThirteenMonths_onChangeDropdownAccounts,
    depositThirteenMonths_onChangeCheckbox: depositThirteenMonths.depositThirteenMonths_onChangeCheckbox,
    depositThirteenMonths_showInitalModal: depositThirteenMonths.depositThirteenMonths_showInitalModal,
    depositThirteenMonths_InitialModalChildren: depositThirteenMonths.depositThirteenMonths_InitialModalChildren,
    depositThirteenMonths_hireParamsFn: depositThirteenMonths.depositThirteenMonths_hireParamsFn,
    depositThirteenMonths_showFinalModal: depositThirteenMonths.depositThirteenMonths_showFinalModal,
    depositThirteenMonths_FinalModalChildren: depositThirteenMonths.depositThirteenMonths_FinalModalChildren,
    depositThirteenMonths_FinalModalTitle: depositThirteenMonths.depositThirteenMonths_FinalModalTitle,
    depositThirteenMonths_setSuccessStepPrefix: depositThirteenMonths.depositThirteenMonths_setSuccessStepPrefix,
    depositThirteenMonths_setSuccessStepAdditionalInfo: depositThirteenMonths.depositThirteenMonths_setSuccessStepAdditionalInfo,
    depositThirteenMonths_loadSliderAmounts: depositThirteenMonths.depositThirteenMonths_loadSliderAmounts,
    depositThirteenMonths_buildSliderExtraInfo: depositThirteenMonths.depositThirteenMonths_buildSliderExtraInfo,
    depositThirteenMonths_shouldBuild: depositThirteenMonths.depositThirteenMonths_shouldBuild,
    depositThirteenMonths_nextButtonDisabled: depositThirteenMonths.depositThirteenMonths_nextButtonDisabled,
    deposit_thirteen_months_hasCheckboxes: true,

    //Deposit Eighteen Months
    depositEighteenMonths_customErrorNotAllowed: depositEighteenMonths.depositEighteenMonths_customErrorNotAllowed,
    deposit_eighteen_months_validateStep: depositEighteenMonths.deposit_eighteen_months_validateStep,
    deposit_eighteen_months_loan_SignParams: depositEighteenMonths.deposit_eighteen_months_loan_SignParams,
    depositEighteenMonths_onChangeIntervener: depositEighteenMonths.depositEighteenMonths_onChangeIntervener,
    depositEighteenMonths_onChangeAmount: depositEighteenMonths.depositEighteenMonths_onChangeAmount,
    depositEighteenMonths_loadCmbAccounts: depositEighteenMonths.depositEighteenMonths_loadCmbAccounts,
    depositEighteenMonths_onChangeDropdownAccounts: depositEighteenMonths.depositEighteenMonths_onChangeDropdownAccounts,
    depositEighteenMonths_onChangeCheckbox: depositEighteenMonths.depositEighteenMonths_onChangeCheckbox,
    depositEighteenMonths_showInitalModal: depositEighteenMonths.depositEighteenMonths_showInitalModal,
    depositEighteenMonths_InitialModalChildren: depositEighteenMonths.depositEighteenMonths_InitialModalChildren,
    depositEighteenMonths_hireParamsFn: depositEighteenMonths.depositEighteenMonths_hireParamsFn,
    depositEighteenMonths_showFinalModal: depositEighteenMonths.depositEighteenMonths_showFinalModal,
    depositEighteenMonths_FinalModalChildren: depositEighteenMonths.depositEighteenMonths_FinalModalChildren,
    depositEighteenMonths_FinalModalTitle: depositEighteenMonths.depositEighteenMonths_FinalModalTitle,
    depositEighteenMonths_setSuccessStepPrefix: depositEighteenMonths.depositEighteenMonths_setSuccessStepPrefix,
    depositEighteenMonths_setSuccessStepAdditionalInfo: depositEighteenMonths.depositEighteenMonths_setSuccessStepAdditionalInfo,
    depositEighteenMonths_loadSliderAmounts: depositEighteenMonths.depositEighteenMonths_loadSliderAmounts,
    depositEighteenMonths_buildSliderExtraInfo: depositEighteenMonths.depositEighteenMonths_buildSliderExtraInfo,
    depositEighteenMonths_shouldBuild: depositEighteenMonths.depositEighteenMonths_shouldBuild,
    depositEighteenMonths_nextButtonDisabled: depositEighteenMonths.depositEighteenMonths_nextButtonDisabled,
    deposit_eighteen_months_hasCheckboxes: true,

    //Main Deposit with new money
    depositnm_customErrorNotAllowed: depositnm.depositnm_customErrorNotAllowed,
    depositnm_validateStep: depositnm.depositnm_validateStep,
    depositnm_loan_SignParams: depositnm.depositnm_loan_SignParams,
    depositnm_onChangeIntervener: depositnm.depositnm_onChangeIntervener,
    depositnm_onChangeAmount: depositnm.depositnm_onChangeAmount,
    depositnm_loadCmbAccounts: depositnm.depositnm_loadCmbAccounts,
    depositnm_showInitalModal: depositnm.depositnm_showInitalModal,
    depositnm_InitialModalChildren: depositnm.depositnm_InitialModalChildren,
    depositnm_WithoutNewBalanceModalChildren: depositnm.depositnm_WithoutNewBalanceModalChildren,
    depositnm_customCloseButton: depositnm.depositnm_customCloseButton,
    depositnm_hireParamsFn: depositnm.depositnm_hireParamsFn,
    depositnm_showFinalModal: depositnm.depositnm_showFinalModal,
    depositnm_FinalModalChildren: depositnm.depositnm_FinalModalChildren,
    depositnm_FinalModalTitle: depositnm.depositnm_FinalModalTitle,
    depositnm_setSuccessStepPrefix: depositnm.depositnm_setSuccessStepPrefix,
    depositnm_setSuccessStepAdditionalInfo: depositnm.depositnm_setSuccessStepAdditionalInfo,
    depositnm_loadSliderAmounts: depositnm.depositnm_loadSliderAmounts,
    depositnm_loadAmountInputAmounts: depositnm.depositnm_loadAmountInputAmounts,
    depositnm_buildSliderExtraInfo: depositnm.depositnm_buildSliderExtraInfo,
    depositnm_shouldBuild: depositnm.depositnm_shouldBuild,
    depositnm_dropdwonAccountShouldBuild: depositnm.depositnm_dropdwonAccountShouldBuild,
    depositnm_nextButtonDisabled: depositnm.depositnm_nextButtonDisabled,
    depositnm_pollingNextButtonDisabled: depositnm.depositnm_pollingNextButtonDisabled,
    depositnm_shouldInputSubLabelBuild: depositnm.depositnm_shouldInputSubLabelBuild,
    depositnm_moreInfoModalChildren: depositnm.depositnm_moreInfoModalChildren,
    depositnm_show45DaysWarning: depositnm.depositnm_show45DaysWarning,
    depositnm_hasCheckboxes: false,
    depositnm_loadTermsAndConditionsLegalWindow: depositnm.depositnm_loadTermsAndConditionsLegalWindow,
    depositnm_finalModalStateChange: depositnm.depositnm_finalModalStateChange,
    depositnm_moreInfoModalStateChange: depositnm.depositnm_moreInfoModalStateChange,
    depositnm_initialModalStateChange: depositnm.depositnm_initialModalStateChange,
    depositnm_getCustomPrintDocument: depositnm.depositnm_getCustomPrintDocument,

    //Main Deposit without new money
    deposit_customErrorNotAllowed: depositwnm.depositnm_customErrorNotAllowed,
    deposit_validateStep: depositwnm.depositwnm_validateStep,
    deposit_loan_SignParams: depositwnm.depositwnm_loan_SignParams,
    deposit_onChangeIntervener: depositwnm.depositwnm_onChangeIntervener,
    deposit_onChangeAmount: depositwnm.depositwnm_onChangeAmount,
    deposit_getCustomPrintDocument: depositwnm.depositwnm_getCustomPrintDocument,
    deposit_loadCmbAccounts: depositwnm.depositwnm_loadCmbAccounts,
    deposit_hireParamsFn: depositwnm.depositwnm_hireParamsFn,
    deposit_setSuccessStepPrefix: depositwnm.depositwnm_setSuccessStepPrefix,
    deposit_setSuccessStepAdditionalInfo: depositwnm.depositwnm_setSuccessStepAdditionalInfo,
    deposit_loadAmountInputAmounts: depositwnm.depositwnm_loadAmountInputAmounts,
    deposit_shouldBuild: depositwnm.depositwnm_shouldBuild,
    deposit_dropdwonAccountShouldBuild: depositwnm.depositwnm_dropdwonAccountShouldBuild,
    deposit_nextButtonDisabled: depositwnm.depositwnm_nextButtonDisabled,
    deposit_pollingNextButtonDisabled: depositwnm.depositwnm_pollingNextButtonDisabled,
    deposit_shouldInputSubLabelBuild: depositwnm.depositwnm_shouldInputSubLabelBuild,
    deposit_hasCheckboxes: false,
    deposit_loadTermsAndConditionsLegalWindow: depositwnm.depositwnm_loadTermsAndConditionsLegalWindow,

    //Deposit transfers
    deposittransfer_customErrorNotAllowed: depositTransfer.deposittransfer_customErrorNotAllowed,
    deposittransfer_validateStep: depositTransfer.deposittransfer_validateStep,
    deposittransfer_loan_SignParams: depositTransfer.deposittransfer_loan_SignParams,
    deposittransfer_onChangeIntervener: depositTransfer.deposittransfer_onChangeIntervener,
    deposittransfer_onChangeAmount: depositTransfer.deposittransfer_onChangeAmount,
    deposittransfer_loadCmbAccounts: depositTransfer.deposittransfer_loadCmbAccounts,
    deposittransfer_hireParamsFn: depositTransfer.deposittransfer_hireParamsFn,
    deposittransfer_setSuccessStepPrefix: depositTransfer.deposittransfer_setSuccessStepPrefix,
    deposittransfer_setSuccessStepAdditionalInfo: depositTransfer.deposittransfer_setSuccessStepAdditionalInfo,
    deposittransfer_loadAmountInputAmounts: depositTransfer.deposittransfer_loadAmountInputAmounts,
    deposittransfer_shouldBuild: depositTransfer.deposittransfer_shouldBuild,
    deposittransfer_dropdwonAccountShouldBuild: depositTransfer.deposittransfer_dropdwonAccountShouldBuild,
    deposittransfer_nextButtonDisabled: depositTransfer.deposittransfer_nextButtonDisabled,
    deposittransfer_pollingNextButtonDisabled: depositTransfer.deposittransfer_pollingNextButtonDisabled,
    deposittransfer_shouldInputSubLabelBuild: depositTransfer.deposittransfer_shouldInputSubLabelBuild,
    deposittransfer_hasCheckboxes: false,
    deposittransfer_loadTermsAndConditionsLegalWindow: depositTransfer.deposittransfer_loadTermsAndConditionsLegalWindow,
    deposittransfer_customTagging: depositTransfer.deposittransfer_customTagging,
    deposittransfer_customCategoryEvent: depositTransfer.deposittransfer_customCategoryEvent,
    deposittransfer_inputCategoryEvent: depositTransfer.deposittransfer_inputCategoryEvent,

    //Deposit Renewal
    depositrenewal_customErrorNotAllowed: depositRenewal.depositrenewal_customErrorNotAllowed,
    depositrenewal_validateStep: depositRenewal.depositrenewal_validateStep,
    depositrenewal_loan_SignParams: depositRenewal.depositrenewal_loan_SignParams,
    depositrenewal_onChangeIntervener: depositRenewal.depositrenewal_onChangeIntervener,
    depositrenewal_onChangeAmount: depositRenewal.depositrenewal_onChangeAmount,
    depositrenewal_loadCmbAccounts: depositRenewal.depositrenewal_loadCmbAccounts,
    depositrenewal_hireParamsFn: depositRenewal.depositrenewal_hireParamsFn,
    depositrenewal_officePrintedDocumentParamsFn: depositRenewal.depositrenewal_officePrintedDocumentParamsFn,
    depositrenewal_setSuccessStepPrefix: depositRenewal.depositrenewal_setSuccessStepPrefix,
    depositrenewal_setSuccessStepAdditionalInfo: depositRenewal.depositrenewal_setSuccessStepAdditionalInfo,
    depositrenewal_loadAmountInputAmounts: depositRenewal.depositrenewal_loadAmountInputAmounts,
    depositrenewal_shouldBuild: depositRenewal.depositrenewal_shouldBuild,
    depositrenewal_dropdwonAccountShouldBuild: depositRenewal.depositrenewal_dropdwonAccountShouldBuild,
    depositrenewal_nextButtonDisabled: depositRenewal.depositrenewal_nextButtonDisabled,
    depositrenewal_nextButtonDisabledStep1: depositRenewal.depositrenewal_nextButtonDisabledStep1,
    depositrenewal_pollingNextButtonDisabled: depositRenewal.depositrenewal_pollingNextButtonDisabled,
    depositrenewal_shouldInputSubLabelBuild: depositRenewal.depositrenewal_shouldInputSubLabelBuild,
    depositrenewal_hasCheckboxes: false,
    depositrenewal_loadTermsAndConditionsLegalWindow: depositRenewal.depositrenewal_loadTermsAndConditionsLegalWindow,
    depositrenewal_customTagging: depositRenewal.depositrenewal_customTagging,
    depositrenewal_handleInputAmountTooltipHover: depositRenewal.depositrenewal_handleInputAmountTooltipHover,
    depositrenewal_step2DisclaimerCustomTaggingCategory: depositRenewal.depositrenewal_step2DisclaimerCustomTaggingCategory,
    depositrenewal_inputCategoryEvent: depositRenewal.depositrenewal_inputCategoryEvent,
    depositrenewal_customCategoryEvent: depositRenewal.depositrenewal_customCategoryEvent,

    //Deposit Thirteen Months Due Dates
    depositThirteenMonthsDueDates_customErrorNotAllowed: depositThirteenMonths.depositThirteenMonths_customErrorNotAllowed,
    deposit_thirteen_months_due_dates_validateStep: depositThirteenMonthsDueDates.deposit_thirteen_months_due_dates_validateStep,
    deposit_thirteen_months_due_dates_loan_SignParams: depositThirteenMonths.deposit_thirteen_months_loan_SignParams,
    depositThirteenMonthsDueDates_onChangeIntervener: depositThirteenMonths.depositThirteenMonths_onChangeIntervener,
    depositThirteenMonthsDueDates_onChangeAmount: depositThirteenMonths.depositThirteenMonths_onChangeAmount,
    depositThirteenMonthsDueDates_loadCmbAccounts: depositThirteenMonths.depositThirteenMonths_loadCmbAccounts,
    depositThirteenMonthsDueDates_onChangeDropdownAccounts: depositThirteenMonths.depositThirteenMonths_onChangeDropdownAccounts,
    depositThirteenMonthsDueDates_onChangeCheckbox: depositThirteenMonths.depositThirteenMonths_onChangeCheckbox,
    depositThirteenMonthsDueDates_InitialModalChildren: depositThirteenMonths.depositThirteenMonths_InitialModalChildren,
    depositThirteenMonthsDueDates_hireParamsFn: depositThirteenMonthsDueDates.depositThirteenMonthsDueDates_hireParamsFn,
    depositThirteenMonthsDueDates_showFinalModal: depositThirteenMonthsDueDates.depositThirteenMonthsDueDates_showFinalModal,
    depositThirteenMonthsDueDates_FinalModalChildren: depositThirteenMonthsDueDates.depositThirteenMonthsDueDates_FinalModalChildren,
    depositThirteenMonthsDueDates_FinalModalTitle: depositThirteenMonthsDueDates.depositThirteenMonthsDueDates_FinalModalTitle,
    depositThirteenMonthsDueDates_setSuccessStepPrefix: depositThirteenMonths.depositThirteenMonths_setSuccessStepPrefix,
    depositThirteenMonthsDueDates_setSuccessStepAdditionalInfo: depositThirteenMonths.depositThirteenMonths_setSuccessStepAdditionalInfo,
    depositThirteenMonthsDueDates_loadSliderAmounts: depositThirteenMonths.depositThirteenMonths_loadSliderAmounts,
    depositThirteenMonthsDueDates_buildSliderExtraInfo: depositThirteenMonths.depositThirteenMonths_buildSliderExtraInfo,
    depositThirteenMonthsDueDates_shouldBuild: depositThirteenMonths.depositThirteenMonths_shouldBuild,
    depositThirteenMonthsDueDates_nextButtonDisabled: depositThirteenMonths.depositThirteenMonths_nextButtonDisabled,
    deposit_thirteen_months_due_dates_hasCheckboxes: true,

    //Deposit Thirteen Months Leak Retention
    depositThirteenMonthsLeakRetention_customErrorNotAllowed: depositThirteenMonths.depositThirteenMonths_customErrorNotAllowed,
    deposit_thirteen_months_leak_retention_validateStep: depositThirteenMonthsLeakRetention.deposit_thirteen_months_leak_retention_validateStep,
    deposit_thirteen_months_leak_retention_loan_SignParams: depositThirteenMonths.deposit_thirteen_months_loan_SignParams,
    depositThirteenMonthsLeakRetention_onChangeIntervener: depositThirteenMonths.depositThirteenMonths_onChangeIntervener,
    depositThirteenMonthsLeakRetention_onChangeAmount: depositThirteenMonths.depositThirteenMonths_onChangeAmount,
    depositThirteenMonthsLeakRetention_loadCmbAccounts: depositThirteenMonths.depositThirteenMonths_loadCmbAccounts,
    depositThirteenMonthsLeakRetention_onChangeDropdownAccounts: depositThirteenMonths.depositThirteenMonths_onChangeDropdownAccounts,
    depositThirteenMonthsLeakRetention_onChangeCheckbox: depositThirteenMonths.depositThirteenMonths_onChangeCheckbox,
    depositThirteenMonthsLeakRetention_InitialModalChildren: depositThirteenMonths.depositThirteenMonths_InitialModalChildren,
    depositThirteenMonthsLeakRetention_hireParamsFn: depositThirteenMonthsLeakRetention.depositThirteenMonthsLeakRetention_hireParamsFn,
    depositThirteenMonthsLeakRetention_showFinalModal: depositThirteenMonthsLeakRetention.depositThirteenMonthsLeakRetention_showFinalModal,
    depositThirteenMonthsLeakRetention_FinalModalChildren: depositThirteenMonthsLeakRetention.depositThirteenMonthsLeakRetention_FinalModalChildren,
    depositThirteenMonthsLeakRetention_FinalModalTitle: depositThirteenMonthsLeakRetention.depositThirteenMonthsLeakRetention_FinalModalTitle,
    depositThirteenMonthsLeakRetention_setSuccessStepPrefix: depositThirteenMonths.depositThirteenMonths_setSuccessStepPrefix,
    depositThirteenMonthsLeakRetention_setSuccessStepAdditionalInfo: depositThirteenMonths.depositThirteenMonths_setSuccessStepAdditionalInfo,
    depositThirteenMonthsLeakRetention_loadSliderAmounts: depositThirteenMonths.depositThirteenMonths_loadSliderAmounts,
    depositThirteenMonthsLeakRetention_buildSliderExtraInfo: depositThirteenMonths.depositThirteenMonths_buildSliderExtraInfo,
    depositThirteenMonthsLeakRetention_shouldBuild: depositThirteenMonths.depositThirteenMonths_shouldBuild,
    depositThirteenMonthsLeakRetention_nextButtonDisabled: depositThirteenMonths.depositThirteenMonths_nextButtonDisabled,
    deposit_thirteen_months_leak_retention_hasCheckboxes: true,

    //Deposit Thirteen Months Excepciones
    depositThirteenMonthsExcepciones_customErrorNotAllowed: depositThirteenMonths.depositThirteenMonths_customErrorNotAllowed,
    deposit_thirteen_months_excepciones_validateStep: depositThirteenMonthsExcepciones.deposit_thirteen_months_excepciones_validateStep,
    deposit_thirteen_months_excepciones_loan_SignParams: depositThirteenMonths.deposit_thirteen_months_loan_SignParams,
    depositThirteenMonthsExcepciones_onChangeIntervener: depositThirteenMonths.depositThirteenMonths_onChangeIntervener,
    depositThirteenMonthsExcepciones_onChangeAmount: depositThirteenMonths.depositThirteenMonths_onChangeAmount,
    depositThirteenMonthsExcepciones_loadCmbAccounts: depositThirteenMonths.depositThirteenMonths_loadCmbAccounts,
    depositThirteenMonthsExcepciones_onChangeDropdownAccounts: depositThirteenMonths.depositThirteenMonths_onChangeDropdownAccounts,
    depositThirteenMonthsExcepciones_onChangeCheckbox: depositThirteenMonths.depositThirteenMonths_onChangeCheckbox,
    depositThirteenMonthsExcepciones_showInitalModal: depositThirteenMonths.depositThirteenMonths_showInitalModal,
    depositThirteenMonthsExcepciones_InitialModalChildren: depositThirteenMonths.depositThirteenMonths_InitialModalChildren,
    depositThirteenMonthsExcepciones_hireParamsFn: depositThirteenMonthsExcepciones.depositThirteenMonthsExcepciones_hireParamsFn,
    depositThirteenMonthsExcepciones_showFinalModal: depositThirteenMonthsExcepciones.depositThirteenMonthsExcepciones_showFinalModal,
    depositThirteenMonthsExcepciones_FinalModalChildren: depositThirteenMonthsExcepciones.depositThirteenMonthsExcepciones_FinalModalChildren,
    depositThirteenMonthsExcepciones_FinalModalTitle: depositThirteenMonthsExcepciones.depositThirteenMonthsExcepciones_FinalModalTitle,
    depositThirteenMonthsExcepciones_setSuccessStepPrefix: depositThirteenMonths.depositThirteenMonths_setSuccessStepPrefix,
    depositThirteenMonthsExcepciones_setSuccessStepAdditionalInfo: depositThirteenMonths.depositThirteenMonths_setSuccessStepAdditionalInfo,
    depositThirteenMonthsExcepciones_loadSliderAmounts: depositThirteenMonths.depositThirteenMonths_loadSliderAmounts,
    depositThirteenMonthsExcepciones_buildSliderExtraInfo: depositThirteenMonths.depositThirteenMonths_buildSliderExtraInfo,
    depositThirteenMonthsExcepciones_shouldBuild: depositThirteenMonths.depositThirteenMonths_shouldBuild,
    depositThirteenMonthsExcepciones_nextButtonDisabled: depositThirteenMonths.depositThirteenMonths_nextButtonDisabled,
    deposit_thirteen_months_excepciones_hasCheckboxes: true,

    //Deposit Thirteen Months Linked Maturities
    depositThirteenMonthsLinkedMaturities_customErrorNotAllowed: depositThirteenMonths.depositThirteenMonths_customErrorNotAllowed,
    deposit_thirteen_months_linked_maturities_validateStep: depositThirteenMonthsLinkedMaturities.deposit_thirteen_months_linked_maturities_validateStep,
    deposit_thirteen_months_linked_maturities_loan_SignParams: depositThirteenMonths.deposit_thirteen_months_loan_SignParams,
    depositThirteenMonthsLinkedMaturities_onChangeIntervener: depositThirteenMonths.depositThirteenMonths_onChangeIntervener,
    depositThirteenMonthsLinkedMaturities_onChangeAmount: depositThirteenMonths.depositThirteenMonths_onChangeAmount,
    depositThirteenMonthsLinkedMaturities_loadCmbAccounts: depositThirteenMonths.depositThirteenMonths_loadCmbAccounts,
    depositThirteenMonthsLinkedMaturities_onChangeDropdownAccounts: depositThirteenMonths.depositThirteenMonths_onChangeDropdownAccounts,
    depositThirteenMonthsLinkedMaturities_onChangeCheckbox: depositThirteenMonths.depositThirteenMonths_onChangeCheckbox,
    depositThirteenMonthsLinkedMaturities_InitialModalChildren: depositThirteenMonths.depositThirteenMonths_InitialModalChildren,
    depositThirteenMonthsLinkedMaturities_hireParamsFn: depositThirteenMonthsLinkedMaturities.depositThirteenMonthsLinkedMaturities_hireParamsFn,
    depositThirteenMonthsLinkedMaturities_showFinalModal: depositThirteenMonthsLinkedMaturities.depositThirteenMonthsLinkedMaturities_showFinalModal,
    depositThirteenMonthsLinkedMaturities_FinalModalChildren: depositThirteenMonthsLinkedMaturities.depositThirteenMonthsLinkedMaturities_FinalModalChildren,
    depositThirteenMonthsLinkedMaturities_FinalModalTitle: depositThirteenMonthsLinkedMaturities.depositThirteenMonthsLinkedMaturities_FinalModalTitle,
    depositThirteenMonthsLinkedMaturities_setSuccessStepPrefix: depositThirteenMonths.depositThirteenMonths_setSuccessStepPrefix,
    depositThirteenMonthsLinkedMaturities_setSuccessStepAdditionalInfo: depositThirteenMonths.depositThirteenMonths_setSuccessStepAdditionalInfo,
    depositThirteenMonthsLinkedMaturities_loadSliderAmounts: depositThirteenMonths.depositThirteenMonths_loadSliderAmounts,
    depositThirteenMonthsLinkedMaturities_buildSliderExtraInfo: depositThirteenMonths.depositThirteenMonths_buildSliderExtraInfo,
    depositThirteenMonthsLinkedMaturities_shouldBuild: depositThirteenMonths.depositThirteenMonths_shouldBuild,
    depositThirteenMonthsLinkedMaturities_nextButtonDisabled: depositThirteenMonths.depositThirteenMonths_nextButtonDisabled,
    deposit_thirteen_months_linked_maturities_hasCheckboxes: true,

    //Deposit Thirteen Months Renovacion Excepciones
    depositThirteenMonthsRenovacionExcepciones_customErrorNotAllowed: depositThirteenMonths.depositThirteenMonths_customErrorNotAllowed,
    deposit_thirteen_months_renovacion_excepciones_validateStep: depositThirteenMonthsRenovacionExcepciones.deposit_thirteen_months_renovacion_excepciones_validateStep,
    deposit_thirteen_months_renovacion_excepciones_loan_SignParams: depositThirteenMonths.deposit_thirteen_months_loan_SignParams,
    depositThirteenMonthsRenovacionExcepciones_onChangeIntervener: depositThirteenMonths.depositThirteenMonths_onChangeIntervener,
    depositThirteenMonthsRenovacionExcepciones_onChangeAmount: depositThirteenMonths.depositThirteenMonths_onChangeAmount,
    depositThirteenMonthsRenovacionExcepciones_loadCmbAccounts: depositThirteenMonths.depositThirteenMonths_loadCmbAccounts,
    depositThirteenMonthsRenovacionExcepciones_onChangeDropdownAccounts: depositThirteenMonths.depositThirteenMonths_onChangeDropdownAccounts,
    depositThirteenMonthsRenovacionExcepciones_onChangeCheckbox: depositThirteenMonths.depositThirteenMonths_onChangeCheckbox,
    depositThirteenMonthsRenovacionExcepciones_showInitalModal: depositThirteenMonths.depositThirteenMonths_showInitalModal,
    depositThirteenMonthsRenovacionExcepciones_InitialModalChildren: depositThirteenMonths.depositThirteenMonths_InitialModalChildren,
    depositThirteenMonthsRenovacionExcepciones_hireParamsFn: depositThirteenMonthsRenovacionExcepciones.depositThirteenMonthsRenovacionExcepciones_hireParamsFn,
    depositThirteenMonthsRenovacionExcepciones_showFinalModal: depositThirteenMonthsRenovacionExcepciones.depositThirteenMonthsRenovacionExcepciones_showFinalModal,
    depositThirteenMonthsRenovacionExcepciones_FinalModalChildren: depositThirteenMonthsRenovacionExcepciones.depositThirteenMonthsRenovacionExcepciones_FinalModalChildren,
    depositThirteenMonthsRenovacionExcepciones_FinalModalTitle: depositThirteenMonthsRenovacionExcepciones.depositThirteenMonthsRenovacionExcepciones_FinalModalTitle,
    depositThirteenMonthsRenovacionExcepciones_setSuccessStepPrefix: depositThirteenMonths.depositThirteenMonths_setSuccessStepPrefix,
    depositThirteenMonthsRenovacionExcepciones_setSuccessStepAdditionalInfo: depositThirteenMonths.depositThirteenMonths_setSuccessStepAdditionalInfo,
    depositThirteenMonthsRenovacionExcepciones_loadSliderAmounts: depositThirteenMonths.depositThirteenMonths_loadSliderAmounts,
    depositThirteenMonthsRenovacionExcepciones_buildSliderExtraInfo: depositThirteenMonths.depositThirteenMonths_buildSliderExtraInfo,
    depositThirteenMonthsRenovacionExcepciones_shouldBuild: depositThirteenMonths.depositThirteenMonths_shouldBuild,
    depositThirteenMonthsRenovacionExcepciones_nextButtonDisabled: depositThirteenMonths.depositThirteenMonths_nextButtonDisabled,
    deposit_thirteen_months_renovacion_excepciones_hasCheckboxes: true,

    //Deposit Eighteen Months Retention
    deposit_eighteen_months_retention_validateStep: noNewMoneyDeposits.validateStep,
    deposit_eighteen_months_retention_loan_SignParams: noNewMoneyDeposits.loan_SignParams,
    depositEighteenMonthsRetention_onChangeAmount: noNewMoneyDeposits.onChangeAmount,
    depositEighteenMonthsRetention_onChangeCheckbox: noNewMoneyDeposits.onChangeCheckbox,
    depositEighteenMonthsRetention_hireParamsFn: depositEighteenMonthsRetention.depositEighteenMonthsRetention_hireParamsFn,
    depositEighteenMonthsRetention_showFinalModal: noNewMoneyDeposits.showFinalModal,
    depositEighteenMonthsRetention_FinalModalChildren: noNewMoneyDeposits.finalModalChildren,
    depositEighteenMonthsRetention_setSuccessStepPrefix: noNewMoneyDeposits.setSuccessStepPrefix,
    depositEighteenMonthsRetention_setSuccessStepAdditionalInfo: noNewMoneyDeposits.setSuccessStepAdditionalInfo,
    depositEighteenMonthsRetention_loadSliderAmounts: noNewMoneyDeposits.loadSliderAmounts,
    depositEighteenMonthsRetention_shouldBuild: noNewMoneyDeposits.shouldBuild,
    depositEighteenMonthsRetention_nextButtonDisabled: noNewMoneyDeposits.nextButtonDisabled,
    deposit_eighteen_months_retention_hasCheckboxes: true,

    //Deposit Twenty-Four Months Retention
    deposit_twentyfour_months_retention_validateStep: noNewMoneyDeposits.validateStep,
    deposit_twentyFour_months_retention_loan_SignParams: noNewMoneyDeposits.loan_SignParams,
    depositTwentyFourMonthsRetention_onChangeAmount: noNewMoneyDeposits.onChangeAmount,
    depositTwentyFourMonthsRetention_onChangeCheckbox: noNewMoneyDeposits.onChangeCheckbox,
    depositTwentyFourMonthsRetention_hireParamsFn: depositTwentyFourMonthsRetention.depositTwentyFourMonthsRetention_hireParamsFn,
    depositTwentyFourMonthsRetention_showFinalModal: noNewMoneyDeposits.showFinalModal,
    depositTwentyFourMonthsRetention_FinalModalChildren: noNewMoneyDeposits.finalModalChildren,
    depositTwentyFourMonthsRetention_setSuccessStepPrefix: noNewMoneyDeposits.setSuccessStepPrefix,
    depositTwentyFourMonthsRetention_setSuccessStepAdditionalInfo: noNewMoneyDeposits.setSuccessStepAdditionalInfo,
    depositTwentyFourMonthsRetention_loadSliderAmounts: noNewMoneyDeposits.loadSliderAmounts,
    depositTwentyFourMonthsRetention_shouldBuild: noNewMoneyDeposits.shouldBuild,
    depositTwentyFourMonthsRetention_nextButtonDisabled: noNewMoneyDeposits.nextButtonDisabled,
    deposit_twentyfour_months_retention_hasCheckboxes: true,

    //Duo Combined Deposit
    duoCombinedDeposit_onChangeAmount: duoCombinedDeposit.duoCombinedDeposit_onChangeAmount,
    duoCombinedDeposit_loadCmbAccounts: duoCombinedDeposit.duoCombinedDeposit_loadCmbAccounts,
    duoCombinedDeposit_onChangeDropdownAccounts:
        duoCombinedDeposit.duoCombinedDeposit_onChangeDropdownAccounts,
    duoCombinedDeposit_onChangeIntervener: duoCombinedDeposit.duoCombinedDeposit_onChangeIntervener,
    duo_combined_deposit_validateStep: duoCombinedDeposit.duo_combined_deposit_validateStep,
    duo_combined_deposit_hasCheckboxes: false,

    //Combined Deposit
    combined_deposit_validateStep: noNewMoneyDeposits.validateStep,
    combined_deposit_loan_SignParams: noNewMoneyDeposits.loan_SignParams,
    combinedDeposit_onChangeAmount: noNewMoneyDeposits.onChangeAmount,
    combinedDeposit_onChangeCheckbox: noNewMoneyDeposits.onChangeCheckbox,
    combinedDeposit_InitialModalChildren: noNewMoneyDeposits.initialModalChildren,
    combinedDeposit_hireParamsFn: combinedDeposit.hireParamsFn,
    combinedDeposit_showFinalModal: noNewMoneyDeposits.showFinalModal,
    combinedDeposit_FinalModalChildren: noNewMoneyDeposits.finalModalChildren,
    combinedDeposit_setSuccessStepPrefix: noNewMoneyDeposits.setSuccessStepPrefix,
    combinedDeposit_setSuccessStepAdditionalInfo: noNewMoneyDeposits.setSuccessStepAdditionalInfo,
    combinedDeposit_loadSliderAmounts: noNewMoneyDeposits.loadSliderAmounts,
    combinedDeposit_shouldBuild: noNewMoneyDeposits.shouldBuild,
    combinedDeposit_nextButtonDisabled: noNewMoneyDeposits.nextButtonDisabled,
    combined_deposit_hasCheckboxes: true,

    //Diamond Pack
    diamond_pack_validateStep: diamondPremiumViaTVisa.diamondPremiumViaTVisa_validateStep,
    diamond_pack_hasCheckboxes: true,

    //Premium Pack
    premium_pack_validateStep: diamondPremiumViaTVisa.diamondPremiumViaTVisa_validateStep,
    premium_pack_hasCheckboxes: true,

    //Via T
    via_t_validateStep: diamondPremiumViaTVisa.diamondPremiumViaTVisa_validateStep,
    via_t_hasCheckboxes: true,

    //Diamond Pack - Premium Pack - Via T - Visa Open Lib
    diamondPremiumViaTVisa_buildAddressExtraInfo: diamondPremiumViaTVisa.diamondPremiumViaTVisa_buildAddressExtraInfo,
    diamondPremiumViaTVisa_buildExtraInfo: diamondPremiumViaTVisa.diamondPremiumViaTVisa_buildExtraInfo,
    diamondPremiumViaTVisa_creditOptions: diamondPremiumViaTVisa.diamondPremiumViaTVisa_creditOptions,
    diamondPremiumViaTVisa_creditOptionsDisableContinueButton: diamondPremiumViaTVisa.diamondPremiumViaTVisa_creditOptionsDisableContinueButton,
    diamondPremiumViaTVisa_fixedFeeOptions: diamondPremiumViaTVisa.diamondPremiumViaTVisa_fixedFeeOptions,
    diamondPremiumViaTVisa_HireParams: diamondPremiumViaTVisa.diamondPremiumViaTVisa_HireParams,
    diamondPremiumViaTVisa_onChangeCreditLimit: diamondPremiumViaTVisa.diamondPremiumViaTVisa_onChangeCreditLimit,
    diamondPremiumViaTVisa_percentageOptions: diamondPremiumViaTVisa.diamondPremiumViaTVisa_percentageOptions,
    diamondPremiumViaTVisa_setSuccessStepPrefix: diamondPremiumViaTVisa.diamondPremiumViaTVisa_setSuccessStepPrefix,
    diamondPremiumViaTVisa_SignParams: diamondPremiumViaTVisa.diamondPremiumViaTVisa_SignParams,

    //upgradeToPayrollAccount
    upgrade_to_payroll_account_validateStep:
        upgradeToPayrollAccount.upgrade_to_payroll_account_validateStep,
    upgrade_to_payroll_account_onChangeIntervener:
        upgradeToPayrollAccount.upgrade_to_payroll_account_onChangeIntervener,
    upgrade_to_payroll_account_instructions:
        upgradeToPayrollAccount.upgrade_to_payroll_account_instructions,
    upgrade_to_payroll_account_HireParams:
        upgradeToPayrollAccount.upgradeToPayrollAccount_HireParams,
    upgrade_to_payroll_account_SignParams:
        upgradeToPayrollAccount.upgradeToPayrollAccount_SignParams,
    upgradeToPayrollAccount_setSuccessStepPrefix:
        upgradeToPayrollAccount.upgradeToPayrollAccount_setSuccessStepPrefix,
    upgrade_to_payroll_account_hasCheckboxes: true,

    // FIOC
    fioc_HireParams: fioc.fioc_HireParams,
    fioc_validateStep: fioc.fioc_validateStep,
};
