// @vendors
const Immutable = require('immutable');

// @reducers
const myProfileShippingAddressStep1 = require('./myProfileShippingAddress/myProfileShippingAddressStep1');

// @utilitits
const { capitalize } = require('utilities/stringHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');


const initialState = Immutable.fromJS({
    deleteError: false,
    deleteSuccessful: false,
    errorDescription: '',
    fetchError: false,
    fetchSuccess: false,
    hasShippingAddress: false,
    isDeleting: false,
    isEditMode: false,
    isFetching: false,
    isUpdating: false,
    shippingAddress: {},
    steps: [
        myProfileShippingAddressStep1(),
        { hasSignaturePattern: true },
    ],
    submitSuccessful: false,
    updateError: false,
    visibleStep: 1,
    willCancel: false,
});

function processShippingAddress(state, shippingAddressRaw) {
    const { domicilioEsp, numDomicilio, domicilioExt, codFormatoDomicilio } = shippingAddressRaw;

    const shippingAddress = {
        country: domicilioEsp && domicilioEsp.pais && domicilioEsp.pais.trim() || '',
        id: numDomicilio || -1,
        number: domicilioEsp && domicilioEsp.numeroVia && domicilioEsp.numeroVia.trim() || '',
        postalCode: domicilioEsp && domicilioEsp.codigoPostal && domicilioEsp.codigoPostal.trim() || '',
        population: domicilioEsp && domicilioEsp.localidad && domicilioEsp.localidad.trim() || '',
        province: domicilioEsp && domicilioEsp && domicilioEsp.plaza && domicilioEsp.plaza.provincia && domicilioEsp.plaza.provincia.trim() || '',
        others: domicilioEsp && domicilioEsp.anexoVia && domicilioEsp.anexoVia.trim() || '',
        roadName: domicilioEsp && domicilioEsp.nombreVia && domicilioEsp.nombreVia.trim() || '',
        roadType: domicilioEsp && domicilioEsp.tipoVia && domicilioEsp.tipoVia.trim() || ''
    };

    const shippingAddressExt = {
        country: domicilioExt && domicilioExt.pais && domicilioExt.pais.trim() || '',
        addressLine1: domicilioExt && domicilioExt.linea1Direccion && capitalize(domicilioExt.linea1Direccion).trim() || '',
        addressLine2: domicilioExt && domicilioExt.linea2Direccion && capitalize(domicilioExt.linea2Direccion).trim() || '',
        addressCity: domicilioExt && domicilioExt.plaza && capitalize(domicilioExt.plaza).trim() || ''
    }

    return state.merge({
        fetchSuccess: true,
        hasShippingAddress: true,
        isFetching: false,
        shippingAddress,
        shippingAddressExt,
        codFormatoDomicilio
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return myProfileShippingAddressStep1(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;
    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps) {
        nextStep = currentStep;
    }

    return nextStep;
}

function myProfileShippingAddress(state = initialState, action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.PROFILE_SHIPPING_ADDRESS_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_SET_EDIT_MODE:
            return state.merge({
                isEditMode: true
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_SCROLL_TO_TOP_TOGGLE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;

        case actionTypes.PROFILE_SHIPPING_ADDRESS_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.PROFILE_GET_SHIPPING_ADDRESS_REQUEST:
            return state.merge({
                isFetching: true,
            });

        case actionTypes.PROFILE_GET_SHIPPING_ADDRESS_FAILURE:
            return state.merge({
                fetchError: true,
                errorDescription: action.payload.errorDescription,
                isFetching: false,
            });

        case actionTypes.PROFILE_GET_SHIPPING_ADDRESS_ONE_RESULT_SUCCESS:
            return processShippingAddress(state, action.payload.shippingAddress);

        case actionTypes.PROFILE_GET_SHIPPING_ADDRESS_NO_RESULT_SUCCESS:
            return state.merge({
                fetchError: false,
                fetchSuccess: true,
                hasShippingAddress: false,
                isFetching: false,
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_UPDATE:
            return state.merge({
                isUpdating: true,
            });
        case actionTypes.PROFILE_SHIPPING_ADDRESS_ADD_FAILURE:
        case actionTypes.PROFILE_SHIPPING_ADDRESS_UPDATE_FAILURE:
            return state.merge({
                errorDescription: action.payload.errorDescription,
                isUpdating: false,
                updateError: true,
            });
        case actionTypes.PROFILE_SHIPPING_ADDRESS_ADD_SUCCESS:
        case actionTypes.PROFILE_SHIPPING_ADDRESS_UPDATE_SUCCESS:
            return state.merge({
                isUpdating: false,
                submitSuccessful: true,
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_DELETE:
            return state.merge({
                isDeleting: true,
                deleteError: false,
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_DELETE_FAILURE:
            return state.merge({
                errorDescription: action.payload.errorDescription,
                isDeleting: false,
                deleteError: true,
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_DELETE_SUCCESS:
            return state.merge({
                isDeleting: false,
                deleteSuccessful: true,
            });

        case actionTypes.PROFILE_SHIPPING_ADDRESS_RESET:
            return initialState;

        default:
            return state
    }
}

module.exports = myProfileShippingAddress;
