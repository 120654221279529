const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP, SECOND_STEP, BROKER_TRASPASS_EXTERNAL_VALUE_NUMBER } = require('constants/index');

const brokerTransferValuesStep1 = require('./brokerTransferExternalValues/brokerTransferExternalStep1');
const brokerTransferValuesStep2 = require('./brokerTransferExternalValues/brokerTransferExternalStep2');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerTransferValuesStep1(undefined, {type: null}),
            brokerTransferValuesStep2(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: '',
        token: null,
        transferValues: {
            ctasCer: '',
            dataToken: '',
            emprLiq: '',
            indBolsa: '',
            indOpe: '',
            intermediario: '',
            numFactu: '',
            numeroVal: 0,
            product: '',
            reftrun: '',
            tcontVaf: '',
            tofProce: ''
        }
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerTransferValuesStep1(step, action);
            case SECOND_STEP:
                return brokerTransferValuesStep2(step,action);
        }
    })
}

function setTransferValues(data) {
    const transferValues = {
        codVaiso: data.codVaiso,
        ctasCer: data.ctasCer,
        emprLiq: data.emprLiq,
        indBolsa: data.indBolsa,
        indOpe: data.indOpe,
        numFactu: data.numFactu,
        numeroVal: BROKER_TRASPASS_EXTERNAL_VALUE_NUMBER,
        product: data.product,
        reftrun: data.reftrun,
        tcontVaf: data.tcontVaf,
        tofProce: data.tofProce,
        codPers: data.codPers
    };

    return transferValues;
}

function brokerTransferValuesExternalReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.BROKER_STOCKS_BUY_SIGNATURE_SUCCESS:
            return state.set('token', action.payload.token);
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_AGENCIE_CONTRACT:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    })
            }
            return state;
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_ACCOUNT_TO:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_ISN:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_TITLE_AMOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_RESET:
            return setInitialState();
        case actionTypes.BROKER_TRANSFER_EXTERNAL_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_REQUEST:
            return state.merge({isFetching: true})
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALUES_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALUES_FAILURE:
            return state.merge({
                isFetching: false,
                submitError: true,
                error: action.payload.error
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_AGENCIE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_SUCCESS:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_GET_EMITION_VALUE_CODE_SUCCESS:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_ISN_ISO:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALUES_VALIDATION_SUCCESS:
            return state.merge({
                transferValues: setTransferValues(action.payload.response),
                inProgress: true,
                isFetching: false,
                visibleStep: 3
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_VALUES_VALIDATION_FAILURE:
            return state.merge({
                error: action.payload.error,
                inProgress: true,
                isFetching: false,
                visibleStep: 2
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_TYPE_BOB:
             partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
             return state.merge({
                 steps: partialSteps
             })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_TYPE_AGENCIE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_BANK:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_OFFICE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_SET_CONTRACT_DC:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_GET_AGENCIES_ERROR:
            return state.merge({
                isFetching: false,
                submitError: true,
                error: action.payload.error
            })
        case actionTypes.BROKER_TRANSFER_EXTERNAL_GET_VALUE_AND_EMITION_CODE_ERROR:
            return state.merge({
                isFetching: false,
                submitError: true,
                error: action.payload.error
            })
        default:
            return state
    }
}

module.exports = brokerTransferValuesExternalReducer;
