// @vendors
const Immutable = require('immutable');

// @constants
const {
    BLOCK_TRUSTED_DEVICE_ERROR,
    BLOCK_TRUSTED_DEVICE_REQUEST,
    BLOCK_TRUSTED_DEVICE_SUCCESS,
    BLOCK_TRUSTED_DEVICE_INFO,
    BLOCK_TRUSTED_DEVICE_RESET
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isError: false,
    isFetching: false,
    isSuccess: false,
    trustedDeviceDetails: {},
});

function devicesBlockTrusted(state = initialState, action) {
    switch (action.type) {
        case BLOCK_TRUSTED_DEVICE_REQUEST: {
            return state.merge({
                isFetching: true
            });
        }
        case BLOCK_TRUSTED_DEVICE_SUCCESS: {
            return state.merge({
                isError: false,
                isSuccess: true,
                isFetching: false
            });
        }
        case BLOCK_TRUSTED_DEVICE_ERROR: {
            return state.merge({
                isError: true,
                isSuccess: false,
                isFetching: false
            });
        }
        case BLOCK_TRUSTED_DEVICE_INFO: {
            const {payload: {deviceInfo}} = action
            return state.merge({
                trustedDeviceDetails: deviceInfo
            })
        }
        case BLOCK_TRUSTED_DEVICE_RESET:
            return initialState;
        default:
            return state;
    }
}

module.exports = devicesBlockTrusted;
