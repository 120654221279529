// @ vendors
const Immutable = require('immutable');
//const trim = require('lodash/string/trim');
// @ constants
const actionTypes = require('constants/actionTypes');
// @ utilities
//const { generateAccountId } = require('utilities/APIParsingHelper');

const setInitialState = () => Immutable.Map().merge(
    {
        pensionPlan: Immutable.Map(),
        errorReceived: '',
        isFetching: false,
        notFound: false
    }
);

const pad = (num, size) => (Math.pow( 10, size ) + ~~num ).toString().substring( 1 );

const brokerPensionPlansReducer = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.FETCH_BROKER_PENSION_PLAN_REQUEST:
            return state.merge({
                pensionPlan: Immutable.Map(),
                errorReceived: '',
                notFound: false,
                isFetching: true
            })
        case actionTypes.FETCH_BROKER_PENSION_PLAN_SUCCESS:
            const pensionPlan = action.payload.pensionPlans.rows[0];
            const securityDetails = action.payload.securityDetails[0];
            const type = pensionPlan.CustomInstitutionSecurityId.substring(0,3);
            const subtype = pensionPlan.CustomInstitutionSecurityId.substring(4,7);
            const reference = pad(pensionPlan.CustomInstitutionSecurityId.substring(8,9),7);
            const trailingPerformanceLength = securityDetails.TrailingPerformance.length;
            const variationPoints = parseFloat(securityDetails.TrailingPerformance[0].Return[0].Value) * (parseFloat(securityDetails.TrailingPerformance[trailingPerformanceLength-1].Return[2].Value) / 100);
            const variationPercent = securityDetails.TrailingPerformance[trailingPerformanceLength-1].Return[2].Value

            const customMinimumPurchaseAmount = Number(pensionPlan.CustomMinimumPurchaseAmount) || 0;
            const customMinimumAdditionalPurchaseAmount = Number(pensionPlan.CustomMinimumAdditionalPurchaseAmount) || 0;
            const customMinimumAdditionalPurchaseAmountUnit = Number(pensionPlan.CustomMinimumAdditionalPurchaseAmountUnit) || 0;
            const netAssetValueAmount = Number(securityDetails.TrailingPerformance[0].Return[0].Value) || 0;

            const hireMinimumAmount =
                customMinimumPurchaseAmount === 0 ?
                netAssetValueAmount * customMinimumAdditionalPurchaseAmountUnit :
                customMinimumPurchaseAmount;

            const hireMinimumAmountCurrency = securityDetails.Currency.Id;

            const contributionMinimumAmount =
                customMinimumAdditionalPurchaseAmount === 0 ?
                netAssetValueAmount * customMinimumAdditionalPurchaseAmountUnit :
                customMinimumAdditionalPurchaseAmount;

            const contributionMinimumAmountCurrency = securityDetails.Currency.Id;

            return state.merge({
                pensionPlan: {
                    secId: pensionPlan.SecId,
                    name: pensionPlan.Name,
                    isin: pensionPlan.SecId,
                    dgscode: pensionPlan.DGSCode,
                    customMinimumPurchaseAmount: {
                        amount: pensionPlan.CustomMinimumPurchaseAmount,
                        currency: securityDetails.Currency.Id
                    },
                    customMinimumAdditionalPurchaseAmount: {
                        amount: pensionPlan.CustomMinimumAdditionalPurchaseAmount,
                        currency: securityDetails.Currency.Id
                    },
                    returnM0: pensionPlan.ReturnM0,
                    netAssetValue: {
                      dayEndDate: securityDetails.TrailingPerformance[0].Date,
                      currency: securityDetails.TrailingPerformance[0].CurrencyId,
                      amount: netAssetValueAmount
                    },
                    holding: {
                        numberOfHoldings: '',
                        currencySymbol: '',
                        amount: '' // @hardcoded value
                    },
                    variationPoints: variationPoints,
                    variationPercent: variationPercent,
                    vocation: pensionPlan.CustomCategoryIdName,
                    provider: securityDetails.ProviderCompany.Name,
                    type,
                    subtype,
                    reference,
                    hireMinimumAmount: Number(hireMinimumAmount.toFixed(2)),
                    hireMinimumAmountCurrency,
                    contributionMinimumAmount: Number(contributionMinimumAmount.toFixed(2)),
                    contributionMinimumAmountCurrency,
                    monthlyMinimum: pensionPlan.CustomValue1,
                    triannualMinimum: pensionPlan.CustomValue2,
                    biannualMinimum: pensionPlan.CustomValue3,
                    annualMinimum: pensionPlan.CustomValue4,
                },
                error: '',
                notFound: false,
                isFetching: false
            });
        case actionTypes.FETCH_BROKER_PENSION_PLAN_FAILURE:
            return state.merge({
                error: action.payload.error,
                notFound: false,
                isFetching: false
            });
        case actionTypes.FETCH_BROKER_PENSION_PLAN_NOT_FOUND:
            return state.merge({
                error: action.payload.error,
                notFound: true,
                isFetching: false
            });

        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_RESET:
            return setInitialState();
        default:
            return state
    }
};

module.exports = brokerPensionPlansReducer;
