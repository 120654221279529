// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const now = require('lodash/date/now');
const trim = require('lodash/string/trim');
// @ reducers
const accountDebitsEditStep1 = require('./accountDebitsEdit/accountDebitsEditStep1');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, EURO_TEXT } = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            accountDebitsEditStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        submitSuccessful: false,
        visibleStep: 1
    });
}

function updateStep(steps, action) {
    return steps.update(FIRST_STEP, (step) => {
        return accountDebitsEditStep1(step, action);
    })
}

function parseAccountDebitsEdit(accountDebitsEdit) {
    let byId = {};
    let byOrder = [];

    accountDebitsEdit.forEach((account, index) => {
        const parsedAccountDebitsEdit = {
            amount: {
                amount: get(account, 'saldoActual.importe', 0),
                currency: get(account, 'saldoActual.divisa', EURO_TEXT)
            },
            codbban: trim(get(account, 'numeroCuenta.codbban', '')),
            controlDigit: get(account, 'numeroCuenta.digitodecontrol', ''),
            country: get(account, 'numeroCuenta.pais', ''),
            description: account.descripcion || '',
            id: now() + index.toString(),
            newContractNumber: get(account, 'cnuevo.numerodecontrato', 0),
            newProduct: get(account, 'cnuevo.producto', 0)
        };

        byId[parsedAccountDebitsEdit.newContractNumber] = parsedAccountDebitsEdit;
        byOrder.push(parsedAccountDebitsEdit.newContractNumber);
    });

    return {
        byId: byId,
        byOrder: byOrder
    };
}

function accountDebitsEditReducer(state = setInitialState(), action) {
    let parsedAccountDebitsEdit;
    let partialSteps;

    switch (action.type) {
        case actionTypes.ACCOUNT_DEBITS_EDIT_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(state.get('steps'), action);

                    return state.merge({
                        steps: partialSteps
                    });
            }
            return state;
        case actionTypes.ACCOUNT_DEBITS_EDIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.ACCOUNT_DEBITS_EDIT_SET_ACCOUNT:
            partialSteps = updateStep(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.ACCOUNT_DEBITS_EDIT_RESET:
            return setInitialState();
        case actionTypes.SET_ACCOUNT_DEBITS_EDIT_SUCCESS:
            const newSetSuccessState = state.mergeDeep({
                [action.payload.accountId]: {
                    error: '',
                    isFetching: false
                }
            });

            partialSteps = updateStep(newSetSuccessState.get('steps'), action);

            return newSetSuccessState.merge(setInitialState(),{
                submitSuccessful: true,
                steps: partialSteps
            });
        case actionTypes.SET_ACCOUNT_DEBITS_EDIT_FAILURE:
            const setAccountFailureState =  state.mergeDeep({
                [action.payload.accountId]: {
                    error: action.payload.error,
                    isFetching: false
                }
            });

            return setAccountFailureState.merge({
                submitSuccessful: false
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    byId: {},
                    byOrder: [],
                    error: '',
                    isFetching: true
                }
            });
        case actionTypes.SET_ORIGIN_ACCOUNT_ID:
            return state.mergeDeep({
                originAccountId: action.payload.accountId
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_SUCCESS:
            const newSuccessState = state.merge({
                originAccountId: action.payload.accountId
            });

            parsedAccountDebitsEdit = parseAccountDebitsEdit(action.payload.accountDebitsEdit);

            return newSuccessState.set(action.payload.accountId, Immutable.fromJS({
                byId: parsedAccountDebitsEdit.byId,
                byOrder: parsedAccountDebitsEdit.byOrder,
                error: '',
                isFetching: false,
            }));
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_FAILURE:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    error: action.payload.error,
                    isFetching: false
                }
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_REQUEST:
            return state.mergeDeep({
                [action.payload.accountId]: {
                    error: '',
                    isFetching: true
                }
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_SUCCESS:
            let visibleStep;
            const newValidatedState = state.mergeDeep({
                [action.payload.accountId]: {
                    error: '',
                    isFetching: false
                }
            });

            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(newValidatedState.get('steps'), action);
                    visibleStep = partialSteps.getIn([0, 'valid']) ? 2 : 1;

                    return newValidatedState.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }

            return newValidatedState;
        case actionTypes.FETCH_ACCOUNT_DEBITS_EDIT_VALIDATE_FAILURE:
            const newFailureState =  state.mergeDeep({
                [action.payload.accountId]: {
                    error: action.payload.error,
                    isFetching: false
                }
            });

            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(newFailureState.get('steps'), action);
                    visibleStep = partialSteps.getIn([0, 'valid']) ? 2 : 1;

                    return newFailureState.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }

            return newFailureState;
        default:
            return state
    }
}

module.exports = accountDebitsEditReducer;
