module.exports = {
    timeoutError: {
        errorTitle: 'contractsView-checkingAccountHireTimeoutErrorTitle',
        errorBody: 'contractsView-checkingAccountHireTimeoutErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    createError: {
        errorTitle: 'contractsView-checkingAccountHire_contractualErrorTitle',
        errorBody: 'contractsView-checkingAccountHire_contractualErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    ...(__CONTACT_CENTER__ ? {
        serverErrorPrecontractual: {
            errorTitle: 'contractsView-checkingAccountHire_contractualErrorTitle',
            errorBody: 'contractsView-checkingAccountHire_contractualErrorBody',
            backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
            backButtonLink: '/myprofile/accounts'
        }
    } : {}),
}
