// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        errorFlags: {
        },
        loading: false,
        valid: false
    });
}

function validateStep(step) {
    return step.merge({
        valid: true,
        errorFlags: {}
    });
}

function brokerStocksAdvancedBuyStep3(state = setInitialState(), action) {
    let newState;

    switch (action.type) {
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONFIRM_REQUEST:
            newState = state.merge({
                loading: true
            });
            return newState;
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONFIRM_SUCCESS:
        case actionTypes.BROKER_STOCKS_ADVANCED_BUY_CONFIRM_FAILURE:
            newState = state.merge({
                loading: false
            });
            return newState;
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedBuyStep3;
