const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.Map({
    fetchingLastNews: false,
    errorLastNews: false,
    financesNews: [],
    fetchingFinancesNews: false,
    errorFinancesNews: false,
    indexesData: [],
    fetchingIndexesData: false,
    errorIndexesData: false,
    lastNews: [],
    allTabsNews: [],
    newsPerPage: 0,
    totalNews: 0,
    fetchingLastNewsTabs: false,
    news: {}
});

function brokerMarketsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_FINANCES_NEWS_FETCHING:
        return state.mergeIn(['fetchingFinancesNews'], true)
                    .mergeIn(['errorFinancesNews'], false)

        case actionTypes.BROKER_LAST_NEWS_FETCHING:
            return state.mergeIn(['fetchingLastNews'], true)
                        .mergeIn(['errorLastNews'], false)

        case actionTypes.BROKER_FINANCES_NEWS_SUCCESS:
            return state.mergeIn(['fetchingFinancesNews'], false)
                        .mergeIn(['errorFinancesNews'], false)
                        .mergeIn(['financesNews'], action.payload.data)

        case actionTypes.BROKER_LAST_NEWS_SUCCESS:
            return state.mergeIn(['fetchingLastNews'], false)
                        .mergeIn(['errorLastNews'], false)
                        .mergeIn(['lastNews'], action.payload.news)
                        .mergeIn(['allTabsNews'], action.payload.allTabs)
                        .mergeIn(['newsPerPage'], action.payload.newsPerPage)
                        .mergeIn(['totalNews'], action.payload.totalNews)

        case actionTypes.BROKER_LAST_NEWS_TAB_SUCCESS:
            return state.mergeDeep({
                    [action.tabName] : [...action.news]
            })
                        .mergeIn(['fetchingLastNews'], false)

        case actionTypes.BROKER_LAST_NEWS_BODY_SUCCESS:
            return state.mergeDeep({
                news: {
                    [action.urn] : action.news
            }})
                    .mergeIn(['fetchingLastNews'], false)

        case actionTypes.BROKER_FINANCES_NEWS_ERROR:
            return state.mergeIn(['fetchingFinancesNews'], false)
                        .mergeIn(['errorFinancesNews'], true)

        case actionTypes.BROKER_LAST_NEWS_ERROR:
            return state.mergeIn(['fetchingLastNews'], false)
                        .mergeIn(['errorLastNews'], true)

        case actionTypes.BROKER_INDEXES_DATA_ERROR:
            return state.mergeIn(['fetchingIndexesData'], true)
                    .mergeIn(['errorIndexesData'], false)

        case actionTypes.BROKER_INDEXES_DATA_FETCHING:
            return state.mergeIn(['fetchingIndexesData'], true)
                        .mergeIn(['errorIndexesData'], false)

        case actionTypes.BROKER_INDEXES_DATA_SUCCESS:
            return state.mergeIn(['fetchingIndexesData'], false)
                        .mergeIn(['errorIndexesData'], false)
                        .mergeIn(['indexesData'], action.payload.data)

        default:
            return state
    }
}

module.exports = brokerMarketsReducer;
