// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const flattenDeep = require('lodash/array/flattenDeep');
const get = require('lodash/object/get');

// @utilities
const { hash } = require('utilities/hash');

// @constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT } = require('constants/index');

const initialState = Immutable.fromJS({});
const initialStateForValuesRequest = Immutable.fromJS({
    byId: {},
    byOrder: [],
    isFetching: true,
    success: false,
    error: ''
});

function prepareMovements(movements = []) {
    let byId = {};
    let byOrder = [];

    movements.forEach(movement => {
        const movementNotNull = !!movement.get('valueCode') && !!movement.get('emisionCode');

        if (movementNotNull) {
            const id = hash([movement.get('valueCode')] + movement.get('emisionCode'));
            const titles = movement.get('titles');

            byId[id] = {
                id,
                valueCode: movement.get('valueCode'),
                emisionCode: movement.get('emisionCode'),
                description: movement.get('name') || '',
                type: trim(movement.get('productType') || ''),
                titles: isNaN(titles) ? '' : titles,
                date: movement.get('quoteDate') || null,
                hour: movement.get('quoteTime') || null,
                price: {
                    amount: movement.get('quote') || 0,
                    currency: movement.get('quoteCurrency') || EURO_TEXT
                },
                value: {
                    amount: movement.get('totalValue') || 0,
                    currency: movement.get('totalValueCurrency') || EURO_TEXT
                }
            };

            byOrder.push(id);
        }
    });

    return {
        byId,
        byOrder
    };
}

function getValuesSuccess(immState, contractId, immStocks) {
    const preparedMovements = prepareMovements(immStocks);

    return immState.mergeDeep({
        [contractId]: {
            error: '',
            isFetching: false,
            success: true,
            byId: preparedMovements.byId,
            byOrder: preparedMovements.byOrder
        }
    });
}

function contractAllValuesToPrepareMovementsAdapter(contractAllMovements) {
    const movements = get(contractAllMovements, 'listaMovimientos.movimientoContrato');
    const movementsAsArray = flattenDeep([movements]);
    const movementsAdaptedToPrepareMovements = movementsAsArray.map((movement) => {
        return {
            valueCode: get(movement, 'codValor'),
            emisionCode: get(movement, 'codEmision'),
            name: get(movement, 'descValor'),
            productType: '',
            titles: get(movement, 'titulos'),
            quoteDate: get(movement, 'fechaCotizacion'),
            quoteTime: get(movement, 'horaCotizacion'),
            quote: get(movement, 'cotizacion.numeroimportevalores'),
            quoteCurrency: get(movement, 'cotizacion.divisa'),
            totalValue: get(movement, 'valoracion.importe'),
            totalValueCurrency: get(movement, 'valoracion.divisa')
        };
    });

    return Immutable.fromJS(movementsAdaptedToPrepareMovements);
}

function brokerContractValues(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_CONTRACT_VALUES_REQUEST:
            return state.mergeDeep({
                [action.payload.contractId]: initialStateForValuesRequest
            });
        case actionTypes.BROKER_CONTRACT_ALL_VALUES_SUCCESS:
            return getValuesSuccess(
                state,
                action.payload.contractId,
                contractAllValuesToPrepareMovementsAdapter(action.payload.response)
            );
        case actionTypes.BROKER_CONTRACT_VALUES_SUCCESS:
            return getValuesSuccess(
                state,
                action.payload.contractId,
                action.payload.data.get('stocks')
            );
        case actionTypes.BROKER_CONTRACT_VALUES_FAILURE:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    isFetching: false,
                    error: 'Contract not found' //@hardcoded
                }
            });
        default:
            return state;
    }
}

module.exports = brokerContractValues;
