import accountDebitsSagas from './accountDebitsSagas';
import authorizationsSagas from './authorizationsSagas';
import brokerLegalDocumentation from './brokerLegalDocumentation';
import cardControlSagas from './cardControl';
import securityKitSagas from './myProfile/securityKitSagas';
import receiptsSagas from './receiptsSagas';


module.exports = [
    ...accountDebitsSagas,
    ...authorizationsSagas,
    ...brokerLegalDocumentation,
    ...cardControlSagas,
    ...receiptsSagas,
    ...securityKitSagas
];
