const ContractStateHelper = {
    findElementbyId (collection, elementId, field = 'value', getField = true) {
        const element = collection.find(map => map.get('id') === elementId);
        return getField ? (element ? element.get(field) : null) : element;
    },

    getStandard (imageId) {
        return `0000${imageId.substring(10)}`
    }
}

module.exports = ContractStateHelper;
