const sustainabilityQuestion401Tags = (eventTag) => ({
    finishOn402: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "respuesta-test-mifid-new preferencias de sostenibilidad",
        etiquetaEvento: eventTag,  
    },
    finishOn401: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "respuesta-test-mifid-new preferencias de sostenibilidad",
        etiquetaEvento: 'respuesta-' + eventTag,  
    },
    answer11_1: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new preferencias de sostenibilidad",
        etiquetaEvento: "neutral",
    },
    answer11_2: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new preferencias de sostenibilidad",
        etiquetaEvento: "intermedio",
    },
    answer11_3: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new preferencias de sostenibilidad",
        etiquetaEvento: "avanzado",
    },
    answer11_tooltip: {
        categoriaEvento: "interaccion en test de idoneidad",
        accionEvento: "new tooltip inversion sostenible"
    },
    answer11_modal_open: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new modal inversion sostenible",
        etiquetaEvento: "vista",
    },
    answer11_modal_close: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new modal inversion sostenible",
        etiquetaEvento: "cerrar",   
    },
    answer11_modal_button: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new modal inversion sostenible",
        etiquetaEvento: "entendido",
    },
    moreInfoAccordion: {
        categoriaEvento: "proc-robo-advisor-test-mifid",
        accionEvento: "new preferencias de sostenibilidad",
        etiquetaEvento: "ver mas informacion",
    },
});

module.exports = { sustainabilityQuestion401Tags};
