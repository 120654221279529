const getCountryISO3 = require("country-iso-2-to-3");

const isOnlyOriginCountryEnable = (locations, originCountry) => {
  const output = locations
      .flatMap(zone => zone.countries)
      .every(country => country.name === getCountryISO3(originCountry) ? country.enabled : !country.enabled)
  return output
}

const getNumberEnableCountries = locations => {
  const output = locations
      .flatMap(zone => zone.countries)
      .filter(country => country.enabled === true)
  return output.length
}

const needDisableOriginCountryZone = (numberCountriesEnable, originCountryZone) => {
  if(numberCountriesEnable === 1) return null
  const originCountryZoneOutput = JSON.parse(JSON.stringify(originCountryZone))
  originCountryZoneOutput.enabled = false
  return originCountryZoneOutput
}

const getNumberCountriesFromZone = (locations, zoneCode) => {
  const zone = locations.filter(zone => zone.code === zoneCode)
  if(zone.length > 0) {
    return zone.shift().countries.length
  }
  return 1
}

module.exports = {
  isOnlyOriginCountryEnable,
  getNumberEnableCountries,
  needDisableOriginCountryZone,
  getNumberCountriesFromZone
}
