// @vendor
const Immutable = require("immutable");

// @constants
const {
    BROKER_GET_CONTRACT_STATUS_REQUEST,
    BROKER_GET_CONTRACT_STATUS_SUCCESS,
    BROKER_GET_CONTRACT_STATUS_FAILURE,
    BROKER_GET_CONTRACT_RESET
} = require("constants/contactCenter/actionTypes");

const setInitialState = () => {
    return Immutable.fromJS({
        contracts: {}
    });
};

const brokerContractState = (state = setInitialState(), action) => {
    switch (action.type) {
        case BROKER_GET_CONTRACT_STATUS_REQUEST:
            return state.mergeDeep({
                contracts: {
                    [`id${action.payload.contractId}`]: {
                        isFetching: true
                    }
                }
            });
        case BROKER_GET_CONTRACT_STATUS_SUCCESS:
            return state.mergeDeep({
                contracts: {
                    [`id${action.payload.contractId}`]: {
                        hasPending: action.payload.hasPending,
                        numberContract: action.payload.numberContract,
                        fetchError: false,
                        isFetching: false
                    }
                }
            });
        case BROKER_GET_CONTRACT_STATUS_FAILURE:
            return state.mergeDeep({
                contracts: {
                    [`id${action.payload.contractId}`]: {
                        hasPending: false,
                        numberContract: action.payload.numberContract,
                        fetchError: true,
                        isFetching: false
                    }
                }
            });
        case BROKER_GET_CONTRACT_RESET:
            return state.merge({
                contracts: {}
            });
        default:
            return state;
    }
};

module.exports = brokerContractState;
