// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        inProgress: false,
        success: false,
        error: ''
    });
}

function accountReceiptDownload(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_REQUEST:
            return state.merge({
                inProgress: true,
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_SUCCESS:
            return state.merge({
                success: true,
                inProgress: false,
            });
        case actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_FAILURE:
            return state.merge({
                inProgress: false,
                error: action.payload.error,
            });
        default:
            return state;
    }
}

module.exports = accountReceiptDownload;
