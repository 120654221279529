// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');
// @ reducers
const cardDepositMoneyStep1 = require('./cardDepositMoney/cardDepositMoneyStep1');

function setInitialState() {
    return Immutable.fromJS({
        allAccountsInactive: false,
        steps: [
            cardDepositMoneyStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        submitError: false,
        willLeave: false,
        willCancel: false,
        error: '',
        errorReceived: '',
        canReceiveDeposit: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardDepositMoneyStep1(step, action);
        }
    })
}

function cardDepositMoneyReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNTS_FOR_DEPOSIT_REQUEST:
            return state.merge({
                isFetching: true
            })
        case actionTypes.FETCH_ACCOUNTS_FOR_DEPOSIT_FAILURE:
            return state.merge({
                isFetching: false,
                submitSuccessful: false,
                errorReceived: '',
                inProgress: false
            })
        case actionTypes.FETCH_ACCOUNTS_FOR_DEPOSIT_SUCCESS:
            // (andres): If the request is successfull, we assume the card can receive
            // deposits. We don't care what the service returns.
            return state.merge({
                isFetching: false,
                canReceiveDeposit: true
            })
        case actionTypes.CARD_DEPOSIT_MONEY_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.CARD_DEPOSIT_MONEY_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_DEPOSIT_MONEY_SET_AMOUNT:
        case actionTypes.CARD_DEPOSIT_MONEY_SET_ACCOUNT:
        case actionTypes.CARD_DEPOSIT_MONEY_SET_CREDIT_CARD_DATA:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                inProgress: true
            })
        case actionTypes.CARD_DEPOSIT_MONEY_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_DEPOSIT_MONEY_CLEAR:
            return setInitialState();
        case actionTypes.CARD_DEPOSIT_MONEY_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                errorReceived: '',
                inProgress: false
            })
        case actionTypes.CARD_DEPOSIT_MONEY_FAILURE:
            return state.merge({
                submitError: true,
                error: action.error.body.error,
                errorReceived: action.error.error
            })
        case actionTypes.CARD_DEPOSIT_MONEY_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });
        default:
            return state
    }
}

module.exports = cardDepositMoneyReducer;
