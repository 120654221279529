// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_ORIGIN_CONTRACT_NUMBER_MAX_LENGTH
} = require('constants/index');
const {
    BROKER_NO_DESTINATION_ACCOUNT_SELECTED
} = require('components/externalTransferValuesSteps/constants');

const initialState =Immutable.fromJS({
    valid: false,
    bankName: '',
    bankNameIsDirty: false,
    destinationAccount: BROKER_NO_DESTINATION_ACCOUNT_SELECTED,
    isDestinationAccountDirty: false,
    destinationAccountNumber: '',
    destinationAccountObject: {},
    contractNumber: '',
    contractNumberIsDirty: false,
    filesAttached:[],
});

function validateStep(step) {
    const destinationAccount = step.get('destinationAccount');
    const contractNumber = step.get('contractNumber');
    const bankName = step.get('bankName');
    return step.merge({
        valid:  destinationAccount !== BROKER_NO_DESTINATION_ACCOUNT_SELECTED
            && contractNumber.length === BROKER_ORIGIN_CONTRACT_NUMBER_MAX_LENGTH
            && !!bankName,
        isDestinationAccountDirty: true,
        contractNumberIsDirty: true,
        bankNameIsDirty: true
    });
}

function  filterAccountFromList(value, selectOptions) {
    const selectedOption = selectOptions.filter((option) => {
        return option.value === value;
    });
    return selectedOption[0];
}

function externalTransferValuesStep1(state = initialState, action) {
    switch(action.type) {
        case actionTypes.EXTERNAL_TRANSFER_VALUES_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_DESTINATION_ACCOUNT:
            const selectedData = filterAccountFromList(action.payload.value, action.payload.selectOptions)
            return state.merge({
                destinationAccount: action.payload.value,
                destinationAccountNumber: selectedData.associatedAccount,
                isDestinationAccountDirty: true,
                destinationAccountObject: selectedData
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_CONTRACT_NUMBER:
            return state.merge({
                contractNumber: action.payload.contractNumber
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_SET_BANK_NAME:
            return state.merge({
                bankName: action.payload.bankName
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_ADD_FILE:
            const fileObj = Immutable.fromJS({
                name: get(action.payload, 'fileState.file.file.name', ''),
                data: get(action.payload, 'fileState.file', null)
            });
            return state.mergeDeep({
                filesAttached: [fileObj], //considering one file only
            });
        case actionTypes.EXTERNAL_TRANSFER_VALUES_REMOVE_FILE:
            const removeFileArray = state.get('filesAttached').delete(0); //considering one file only
            return state.merge({
                filesAttached: removeFileArray
            });
        default:
            return state;
    }
}
module.exports = externalTransferValuesStep1;
