// @vendor
const ReactDOM = require('react-dom');

// @constants
const { URL_REGEX } = require('constants/index');


const UrlHelper = {
    scrollTo( component ) {
        let node = ReactDOM.findDOMNode(component);

        if (node) {
            node.scrollIntoView();
        }
    },

    isAbsolute(url) {
        return /^[a-z][a-z0-9+.-]*:/i.test(url)
    },

    getDomainFromUrl(url) {
        return(url.replace(/(^\w+:|^)\/\//, ''));
    },

    getTopLevelDomain() {
        const parsedUrl = URL_REGEX.exec(location.origin);
        return parsedUrl && parsedUrl[2];
    },

    getQueryParams(query) {
        return query.split('&').reduce(function (q, query) {
            var chunks = query.split('=');
            var key = chunks[0];
            var value = chunks[1];
            return (q[key] = value, q);
        }, {});
    },

    redirectToExternalURL(url, queryParams = {}) {
        const queryParamsEntries = Object.entries(queryParams)
        let isLastQueryParam = false;

        if (queryParamsEntries.length === 0) {
            window.location.href = url
            return
        }
    
        const queryParamsString = queryParamsEntries.reduce((acc, [queryParamKey, queryParamValue], index) => {
            isLastQueryParam = index === queryParamsEntries.length - 1 
            acc = acc + `${queryParamKey}=${queryParamValue}${!isLastQueryParam ? '&' : ''}`
            return acc
        }, '')
    
        window.location.href = `${url}?${queryParamsString}`
    }
}

module.exports = UrlHelper;
