// @vendors
const Immutable = require('immutable');

const apiURLBuilder = require('core/apiURLBuilder');

// @constants
const {
    BROKER_FUND_LEGAL_DOCUMENT_DFI,
    BROKER_FUND_LEGAL_DOCUMENT_SEMIANUAL_REPORT
} = require('constants/index');

const {
    BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS,
    BROKER_FUND_LEGAL_DOCUMENT_MARKETING_REPORTS_FETCH_SUCCESS,
    BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH,
    BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR,
    BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH,
    BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_ERROR,
    BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_SUCCESS,
    BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_FETCH,
    BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_SUCCESS,
    BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_ERROR,
    FETCH_ALTERNATIVE_DOCUMENTS_SUCCESS
} = require('constants/actionTypes');

const INITIAL_STATE = Immutable.fromJS({
    fundamentalDataForInvesorUrl: '',
    marketingReportsUrl: '',
    semiAnnualReportUrl: '',
    fundTransactionCostDocument: {},
    lastPublishedEconomicReportUrl: '',
    comisionAndCostDocument: '',
    alternativeFundsDocs: {},
    isFetching: false,
    isFetched: false
});

const forcePrivateApi = () => {
    return __CONTACT_CENTER__;
}

const getLinksFromMorningstarextService = (immState, fundIsin, originCountry) => {
    return immState.merge({
        isFetched: true,
        isFetching: false,
        fundamentalDataForInvesorUrl:
        apiURLBuilder.getURL(
            'morningstarFundLegalDocumentUrl', {
                isin: fundIsin,
                documentType: BROKER_FUND_LEGAL_DOCUMENT_DFI,
                originCountry
            }, forcePrivateApi()),
        semiAnnualReportUrl: apiURLBuilder.getURL(
            'morningstarFundLegalDocumentUrl', {
                isin: fundIsin,
                documentType: BROKER_FUND_LEGAL_DOCUMENT_SEMIANUAL_REPORT,
                originCountry
            }, forcePrivateApi()),
        lastPublishedEconomicReportUrl:
        apiURLBuilder.getURL(
            'morningstarFundLegalDocumentUrl', {
                isin: fundIsin,
                documentType: BROKER_FUND_LEGAL_DOCUMENT_SEMIANUAL_REPORT,
                originCountry
            }, forcePrivateApi()),
    });
};

function brokerFundLegalDocumentReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_SUCCESS:
            return getLinksFromMorningstarextService(state, action.payload.isin, action.payload.originCountry)
        case BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_SUCCESS:
            return state.mergeIn(['fundTransactionCostDocument'], action.payload.response);
        case BROKER_FUND_LEGAL_DOCUMENT_MARKETING_REPORTS_FETCH_SUCCESS:
            return state.set('marketingReportsUrl', action.payload.url);
        case BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH:
        case BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH:
        case BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_FETCH:
        return state.merge({ isFetched: false, isFetching: true });
        case BROKER_FUND_LEGAL_DOCUMENT_COST_FETCH_ERROR:
        case BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_ERROR:
        case BROKER_FUND_LEGAL_DOCUMENT_MORNINGSTAREXT_FETCH_ERROR:
            return state.merge({ isFetched: true, isFetching: false});
        case BROKER_FUND_LEGAL_DOCUMENT_COMISION_AND_COST_SUCCESS:
            return state.merge({
                isFetched: true,
                isFetching: false,
                comisionAndCostDocument: action.payload,
            });
        case FETCH_ALTERNATIVE_DOCUMENTS_SUCCESS:
            return state.merge({
                isFetched: true,
                isFetching: false,
            }).mergeIn(['alternativeFundsDocs'], action.payload);
        default:
            return state;
    }
}

module.exports = brokerFundLegalDocumentReducer;
