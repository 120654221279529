// @ vendor
const Immutable = require('immutable');
// @constants
const actionTypes = require('constants/actionTypes');
// @helpers
const get = require('lodash/object/get');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: '',
    byOrder: [],
    byId: {},
});

function processCollectiveProductList(state, response) {
    const productList = get(response, 'products', []);

    let byId = {};
    let byOrder = [];

    productList.forEach(product => {
        const productId = product.type + product.subType + product.standard;
        const cmsResponse = get(product, 'cmsResponse.included', []);
        const productTypeDetails = cmsResponse.find((productType) => {
            return productType.type === 'paragraph--oblea_product'
        });
        const productNameDetails = cmsResponse.find((productType) => {
            return productType.type === 'ok_component--oblea_web'
        });
        const productImageDetails = cmsResponse.find((productType) => {
            return productType.type === 'file--image'
        });
        const productCTADetails = cmsResponse.find((productType) => {
            return productType.type === 'paragraph--cta_without_segmentation'
        });

        if(cmsResponse.length) {
            const productObject = {
                productId,
                productName: get(productNameDetails, 'attributes.name', ''),
                isProductHired: get(product, 'isHired', ''),
                productTitle: get(productTypeDetails, 'attributes.field_text_title.value', ''),
                productDescription: get(productTypeDetails, 'attributes.field_ref_description.value', ''),
                imageURL: get(product, 'cmsResponse.cmsBaseUrl', '') + get(productImageDetails, 'attributes.uri.url', ''),
                ctaLink: get(productCTADetails, 'attributes.field_cta_url.uri', ''),
                cardNumber: get(product, 'hiredProductDetails.cardNum', '')
            };

            byId[productId] = productObject;
            byOrder.push(productId);
        }
    });

    const nextState = {
        isFetching: false,
    };

    return state
        .mergeDeep(nextState)
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder));
}

function collectiveLanding(state = initialState, action) {
    switch (action.type) {
        case actionTypes.COLLECTIVE_LANDING_PRODUCT_LIST_IS_FETCHING:
            return state.merge({
                isFetching: true,
            });
        case actionTypes.COLLECTIVE_LANDING_PRODUCT_LIST_SUCCESS:
            return processCollectiveProductList(state, action.payload.response);
        case actionTypes.COLLECTIVE_LANDING_PRODUCT_LIST_ERROR:
            return state.merge({
                isFetching: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = collectiveLanding;
