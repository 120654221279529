// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');

function setInitialState() {
    return Immutable.Map().merge({
        valid: true
    });
}

function validateStep(step) {
   return step.merge({
        valid: true,
    });
}

function cancelCreditCardRenewalStep(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.CANCEL_CREDIT_CARD_RENEWAL_VALIDATE_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = cancelCreditCardRenewalStep;



