// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        isFetching: false,
        submitSuccessful: false,
        submitError: undefined,
    });
}

function openYoungCardChoreStatusUpdateInfo(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.OPENYOUNG_CARD_CHORE_STATUS_UPDATE_REQUEST:
            return state.merge({
                isFetching: true,
                submitSuccessful: false,
                submitError: undefined
            });
        case actionTypes.OPENYOUNG_CARD_CHORE_STATUS_UPDATE_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                submitError: undefined
            })
        case actionTypes.OPENYOUNG_CARD_CHORE_STATUS_UPDATE_ERROR:
            return state.merge({
                isFetching: false,
                submitSuccessful: false,
                submitError: action.payload.error
            });
        case actionTypes.OPENYOUNG_CARD_CHORE_RESET_STATUS_UPDATE_INFO:
            return state.merge({
                isFetching: false,
                submitSuccessful: false,
                submitError: undefined
            });
        default:
            return state
    }
}
module.exports = openYoungCardChoreStatusUpdateInfo;
