const fioc = require('../../fioc/fioc');
const contactData = require('../../fioc/data/contactData');
const personalData = require('../../fioc/data/personalData');
const genearlData = require('../../fioc/data/generalData');
const anotherAccount = require('../../fioc/data/anotherAccount');

module.exports = {
    title: 'contractsView-joinholdersDataTitle',
    order: 5,
    footer_text: '', 
    position: 'middle',
    on_back_click: 'checkingPayrollAccount_onBackClicked',
    on_confirm_back_click: 'checkingPayrollAccount_onConfirmBackClicked',
    on_confirm_back_title: 'contractsView-checkingAccountStep4BackConfirm',
    on_confirm_back_description: 'contractsView-checkingAccountStep4BackDescription',
    override_validation_step: 4,
    components: [
        {
            id_component: 'condition-show',
            id: 'show',
            conditionType: 'array-pos',
            conditionPos: '0',
            conditionId1: 'holders',
            conditionValue1: false,
            item: [
                {
                    id_component: 'without-joinholders',
                    id: 'without_joinholders',
                    text: 'contractsView-withoutJoinholders',
                }
            ]
        },
        {
            id_component: 'condition-show',
            id: 'show',
            conditionType: 'array-pos',
            conditionPos: '0',
            conditionId1: 'holders',
            conditionValue1: true,
            item: [
                {
                    id_component: 'custom-text',
                    id: 'joinholders_data_description',
                    text: 'contractsView-joinholdersDataDescriptions',
                },
                {
                    id_component: 'custom-error-message',
                    id: 'holder-validation-error',
                    chekcIds: ['holder-validation-error'],
                    type: 'error',
                    msg_error:'contractsView-validationError'
                },
                {
                    id_component: 'tabs-holder',
                    id: 'tabs-holder',
                    prefix: 'holder_#N#_',
                    idComponentHolder: 'holders',
                    tabs: {
                        tab1: [
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-personalDataTitle',
                                id:'tab-form-personalData-1',
                                exclude: [],
                                grid: [
                                    [personalData('holder_1_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-contactDataTitle',
                                id:'tab-form-contactData-1',
                                exclude: [],
                                grid: [
                                    [contactData('holder_1_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-generalDataTitle',
                                id:'tab-form-personalData-1',
                                exclude: [],
                                grid: [
                                    [genearlData('holder_1_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-anotherAccountTitle',
                                id:'tab-form-anotherAccount-1',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [anotherAccount('holder_1_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-1',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_1_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-1',
                                showInIntevenierAuthorized: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_1_',  true)]
                                ]
                            }
                        ],
                        tab2:[
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-personalDataTitle',
                                id:'tab-form-personalData-2',
                                exclude: [],
                                grid: [
                                    [personalData('holder_2_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-contactDataTitle',
                                id:'tab-form-contactData-2',
                                exclude: [],
                                grid: [
                                    [contactData('holder_2_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-generalDataTitle',
                                id:'tab-form-personalData-2',
                                exclude: [],
                                grid: [
                                    [genearlData('holder_2_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-anotherAccountTitle',
                                id:'tab-form-anotherAccount-2',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [anotherAccount('holder_2_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-2',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_2_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-2',
                                showInIntevenierAuthorized: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_2_',  true)]
                                ]
                            }
                        ],
                        tab3:[
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-personalDataTitle',
                                id:'tab-form-personalData-3',
                                exclude: [],
                                grid: [
                                    [personalData('holder_3_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-contactDataTitle',
                                id:'tab-form-contactData-3',
                                exclude: [],
                                grid: [
                                    [contactData('holder_3_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-generalDataTitle',
                                id:'tab-form-personalData-3',
                                exclude: [],
                                grid: [
                                    [genearlData('holder_3_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-anotherAccountTitle',
                                id:'tab-form-anotherAccount-3',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [anotherAccount('holder_3_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-3',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_3_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-3',
                                showInIntevenierAuthorized: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_3_',  true)]
                                ]
                            }
                        ],
                        tab4:[
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-personalDataTitle',
                                id:'tab-form-personalData-4',
                                exclude: [],
                                grid: [
                                    [personalData('holder_4_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-contactDataTitle',
                                id:'tab-form-contactData-4',
                                exclude: [],
                                grid: [
                                    [contactData('holder_4_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-generalDataTitle',
                                id:'tab-form-personalData-4',
                                exclude: [],
                                grid: [
                                    [genearlData('holder_4_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-anotherAccountTitle',
                                id:'tab-form-anotherAccount-4',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [anotherAccount('holder_4_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-4',
                                showInTitular: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_4_')]
                                ]
                            },
                            {
                                id_component: 'tab-form',
                                label: 'contractsView-activityTitle',
                                id:'tab-form-fioc-4',
                                showInIntevenierAuthorized: true,
                                exclude: [],
                                grid: [
                                    [fioc('holder_4_',  true)]
                                ]
                            }
                        ],
                    }
                },
                {
                    id_component: 'disclaimer-personal-info',
                    cssModifier: 'no-margin-top',
                    messageKey: 'checkingAccountMsg',
                    linkTextKey: 'checkingAccountLinkText',
                    linkUrlKey: 'checkingAccountLinkUrl'
                },
                {
                    id_component: 'information-modal',
                    id: 'resident_country',
                    title: 'contractsView-validationCountryModalTitle',
                    content: 'contractsView-validationCountryModalBody',
                    action: 'checkingPayrollAccount_modalInfoAction',
                    buttonText: 'contractsView-validationCountryModalButtonText',
                    openModal: false
                }
            ]
        }
    ]
};
