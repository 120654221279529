import {
    CARD_COUNTRIES_FILTER_CHANGE,
    CARD_COUNTRIES_FILTER_RESET,
    CARD_ZONE_SELECTED,
} from 'constants/actionTypes';


const initState = {
    countriesFilter: '',
    zoneSelected: false,
};


const cardControlReducer = (state = initState, action) => {
    switch (action.type) {
        case (CARD_COUNTRIES_FILTER_RESET):  {
            return initState;
        }
        case (CARD_COUNTRIES_FILTER_CHANGE): {
            const { value: countriesFilter } = action.payload;
            return {
                ...state,
                countriesFilter,
                zoneSelected: false
            };
        }
        case (CARD_ZONE_SELECTED): {
            const { code } = action.payload;
            return {
                ...state,
                zoneSelected: code !== state.zoneSelected ? code : false,
            };
        }
        default: return state;
    }
};


export default cardControlReducer;
