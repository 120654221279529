const mapChannelsSend = (channels, lastDigitsPan) => {
    const keys = Object.keys(channels);
    const result = {};
    if (keys.includes('atm')) result.atm = channels.atm ? 1 : 0;
    if (keys.includes('tpv')) result.pos = channels.tpv ? 1 : 0;
    if (keys.includes('online')) result.ecom = channels.online ? 1 : 0;
    lastDigitsPan ? result.lastDigitsPan = lastDigitsPan : null;
    return result;
};

export default mapChannelsSend;
