const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const {
    DECLARATION_RESULT_TO_GIVE_BACK,
    NUMBER_REGEX,
} = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        valid: false,
        accountId: '',
        accountIdIsDirty: false,
        amountToReturn: 0,
        amountToReturnValid: false,
        amountToReturnIsDirty: false,
        declarationResult: DECLARATION_RESULT_TO_GIVE_BACK,
        justificative: '',
        justificativeValid: false,
        justificativeValidIsDirty: false,
        differentialFee: 0,
        differentialFeeValid: false,
        differentialFeeValidIsDirty: false,
        firstPayment: 0,
        fractionPayment: false,
        domiciliateSecondPayment: true,
    });
}

const JUSTIFICATIVE_CHARACTERS_LIMIT = 13;

function isValid(value, field, compareToValue) {
    switch (field) {
        case 'amountToReturn':
            return value > 0;
        case 'justificative':
            return value.length === JUSTIFICATIVE_CHARACTERS_LIMIT && NUMBER_REGEX.test(value);
        case 'differentialFee':
            return compareToValue ? (value < compareToValue && value > 0) : value > 0;
        case 'accountId':
            return !!value
        default:
            return true;
    }
}

function validateStep(step) {
    const isGiveBack = step.get('declarationResult') === DECLARATION_RESULT_TO_GIVE_BACK;
    const valid = (
        isValid(step.get('justificative'), 'justificative') && (
            isGiveBack && isValid(step.get('amountToReturn'), 'amountToReturn') ||
            !isGiveBack && isValid(step.get('differentialFee'), 'differentialFee')
        ) && isValid(step.get('accountId'), 'accountId')
    );

    return step.merge({
        valid,
        accountIdIsDirty: true,
        amountToReturnIsDirty: true,
        justificativeValidIsDirty: true,
        differentialFeeValidIsDirty: true
    });
}

function strToBoolean(str) {
    return str === 'true';
}

function sixtyPercentOf(amount) {
    return  (amount * 0.6).toFixed(2);
}

function taxAndRentsPaymentWithDraftStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_ACCOUNT:
            return state.merge({
                accountId: action.payload.accountId,
                accountIdIsDirty: true
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_AMOUNT:
            const amountToReturn = action.payload.amount;
            return state.merge({
                amountToReturn,
                amountToReturnValid: isValid(amountToReturn, 'amountToReturn'),
                amountToReturnIsDirty: true
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_JUSTIFICATIVE:
            const { justificative } = action.payload;

            if (justificative.length <= JUSTIFICATIVE_CHARACTERS_LIMIT) {
                return state.merge({
                    justificativeValid: isValid(justificative, 'justificative'),
                    justificative,
                    justificativeValidIsDirty: true
                });
            } else {
                return state;
            }
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DECLARATION_RESULT:
            return state.merge({
                declarationResult: action.payload.declarationResult,
                accountId: '',
                amountToReturn: 0,
                differentialFee: 0,
                firstPayment: 0,
                fractionPayment: false,
                domiciliateSecondPayment: true
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_FRACTION_PAYMENT:
            return state.merge({
                fractionPayment: strToBoolean(action.payload.fractionPayment)
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DIFFERENTIAL_FEE:
            const { differentialFee, bestBalanceAmount } = action.payload;
            return state.merge({
                differentialFee,
                differentialFeeValid: isValid(differentialFee, 'differentialFee', bestBalanceAmount),
                firstPayment: sixtyPercentOf(differentialFee),
                accountId: '',
                differentialFeeValidIsDirty: true
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_SET_DOMICILIATE_SECOND_PAYMENT:
            return state.merge({
                domiciliateSecondPayment: strToBoolean(action.payload.domiciliateSecondPayment)
            });
        case actionTypes.TAX_AND_RENTS_PAYMENT_WITH_DRAFT_VALIDATE_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = taxAndRentsPaymentWithDraftStep1;
