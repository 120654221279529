// @ vendor
const Immutable = require('immutable');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP
} = require('constants/index');

// @ reducers
const accountsBuyAmazonGiftCardStep1 = require('./accountsBuyAmazonGiftCardStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            accountsBuyAmazonGiftCardStep1(undefined, {type: null}),
            {
                hasSignaturePattern: true,
                isPreContractCheckboxChecked: false,
            }
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        submitError: undefined,
        willLeave: false,
        willCancel: false,
        error: '',
        valid: false,
        destinationAccount: '',
        giftCardClaimCode: '',
        giftCardExpDate: '',
        status: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return accountsBuyAmazonGiftCardStep1(step, action);
            case SECOND_STEP:
                return step.merge({
                    isPreContractCheckboxChecked: action.payload.checked
                })
        }
    });
}

function processCardImage(state,cardImage){
    let card = {};
    let cardImageSuccess = false;
    if(cardImage.length > 0){
        const { fieldImageMedium, fieldImageSmall } = cardImage[0];
        const mediumSizeCard = fieldImageMedium && fieldImageMedium[0] && fieldImageMedium[0].url;
        const smallSizeCard = fieldImageSmall && fieldImageSmall[0] && fieldImageSmall[0].url;
        card = {
            fieldImageMedium: mediumSizeCard,
            fieldImageSmall: smallSizeCard,
        }
        cardImageSuccess = true
    }
    return state.merge({
        cardImage: card,
        cardImageIsFetching: false,
        cardImageSuccess: cardImageSuccess
    });
}

function accountsBuyAmazonGiftCard(state = setInitialState(), action) {
    switch(action.type) {
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_RESET:
            return setInitialState();
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_ACCOUNT:
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_SET_AMOUNT:
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_ACCOUNT_AVAILABLE_BALANCE:
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_VOUCHER_AMOUNT:
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_PRE_CONTRACT_CHECKBOX:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    const partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    const visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                default:
                    return state.merge({
                        visibleStep: action.payload.step + 1
                    });
            }
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_CARD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_SUCCESS:
            return state.merge({
                destinationAccount: action.payload.response.destinationAccount
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_GET_DESTINATION_ACCOUNT_FAILURE:
            return state.merge({
                error: true
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_SUCCESS:
            return state.merge({
                giftCardClaimCode: action.payload.response.giftCardClaimCode,
                giftCardExpDate: action.payload.response.giftCardExpDate,
                status: action.payload.response.status,
                submitSuccessful: true
            });
        case actionTypes.ACCOUNT_BUY_AMAZON_GIFT_SET_GIFT_CARD_FAILURE:
            return state.merge({
                submitError: true
            });
        case actionTypes.AMAZON_GET_GIFT_CARD_IMAGEIS_FETCHING :
            return state.merge({
                promotionsIsFetching: true
            });
        case actionTypes.AMAZON_GET_GIFT_CARD_IMAGE_SUCCESS :
            return processCardImage(state,action.payload.response);
        case actionTypes.AMAZON_GET_GIFT_CARD_IMAGE_ERROR :
            return state.merge({
                promotionsIsFetching: false,
                promotionsError: true,
                error: action.payload.error,
            });
        default:
            return state;
    }
}

module.exports = accountsBuyAmazonGiftCard;
