// @vendor
import reduxCatch from 'redux-catch';
import { applyMiddleware, compose, createStore } from 'redux';
const ReduxThunk = require('redux-thunk').default;
import createSagaMiddleware from 'redux-saga';
import sagas from 'sagas/sagas';
const { rootReducer: reducers } = require('reducers/index');
import { createBrowserHistory } from 'history';
const { middlewaresErrorsHandler } = require('middlewares/middlewaresErrorsHandler');
const biocatchMiddleware = require('middlewares/biocatchMiddleware');
import { actionTagging, actionTaggingLink } from '../middlewares/actionTagging';
const voiceReader = require('../middlewares/voiceReader');
const usdWrapUpActionTrace = require('middlewares/usdWrapUpActionTrace')
const sagaMiddleware = createSagaMiddleware();

// @constants
const { LOCAL_ENVIRONMENT, PRODUCTION_ENVIRONMENT, OCULTO_ENVIRONMENT } = require('constants/index');


const middlewares = [
    reduxCatch(middlewaresErrorsHandler),
    voiceReader,
    ReduxThunk,
    sagaMiddleware,
    actionTagging,
    actionTaggingLink,
    ...(__CONTACT_CENTER__ ? [
        usdWrapUpActionTrace
     ] : [biocatchMiddleware])
];

let enhancers;
if (window.__ENV__ === PRODUCTION_ENVIRONMENT || window.__ENV__ === OCULTO_ENVIRONMENT){
    enhancers = applyMiddleware.apply(null, middlewares);
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancers = composeEnhancers(
        applyMiddleware.apply(null, middlewares)
    );
}

const store = createStore(reducers, enhancers);

// Running sagas
for (let saga of sagas) {
    sagaMiddleware.run(saga)
}

const history = createBrowserHistory({
    basename: "/"
});

// For DEVELOPMENT we want to avoid a continous logout
if (window.__ENV__ === LOCAL_ENVIRONMENT){
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
          const nextReducer = require('../reducers');
          store.replaceReducer(nextReducer);
        });
    }
}

module.exports = {
    store,
    history
}
