// @vendors
const Immutable = require('immutable');

// @helpers
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        isMIFIDChecked: false,
        errorFlags: {
            mifidCheckMissing: false
        },
        loading: false,
        valid: false
    });
}

function validateStep(step) {
    const valid = step.get('isMIFIDChecked');
    let mifidCheckMissing = false;

    if (!valid) {
        mifidCheckMissing = true;
    }

    return step.merge({
        valid,
        errorFlags: {
            mifidCheckMissing
        }
    });
}

function brokerStocksAdvancedSellStep3(state = setInitialState(), action) {
    let newState;

    switch (action.type) {
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_MIFID_CLAUSE_CHANGED:
            newState = state.merge({
                isMIFIDChecked: !state.get('isMIFIDChecked')
            });
            return newState;
        case actionTypes.BROKER_WARRANTS_ADVANCED_BUY_FINISH:
            return validateStep(state);
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_REQUEST:
            newState = state.merge({
                loading: true
            });
            return newState;
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_SUCCESS:
        case actionTypes.BROKER_WARRANTS_ADVANCED_SELL_CONFIRM_FAILURE:
            newState = state.merge({
                loading: false
            });
            return newState;
        default:
            return state;
    }
}

module.exports = brokerStocksAdvancedSellStep3;
