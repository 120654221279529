const BrowserUtils = require('./browserUtils');
const downloadPlugin = require('downloadjs');
const { downloadAsFile } = require('./APIRequestHelper');

const download = (response, fileName, fileType) => {
  var userAgent = navigator.userAgent;

  if (BrowserUtils.browserIsIE()) {
    var blob = new Blob([response]);
    window.navigator.msSaveBlob(blob, fileName);
  } else if (userAgent.indexOf("CriOS") > -1) {
    downloadAsFile(response, fileType, fileName);
  } else {
    downloadPlugin(response, fileName, fileType);
  }
}
const downloadFileFromUrl =(url, fileName, callBackError) =>{
  fetch(url, { method: 'get'})
    .then(res =>  res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    }).catch((error) => {
      callBackError && callBackError(error);
  });
};

const base64ForPrint = (base64) => {
  const binaryImg = atob(base64);
    const length = binaryImg.length;
    const arrayBuffer = new ArrayBuffer(length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < length; i++) {
        uintArray[i] = binaryImg.charCodeAt(i);
    }
    
    return new Blob([uintArray], { type: 'application/pdf' });
}

module.exports = {
  download,
  base64ForPrint,
  downloadFileFromUrl
};