// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
// @ constants
const actionTypes = require('constants/actionTypes');

function setInitialState() {
    return Immutable.fromJS({
        error: '',
        isFetching: false,
        promotions: {},
        success: false,
        noContent:true
    });
}

function processPromotions(state, response) {
    let promotionObject = {};
    let noContentFlag=true;
    if (!!response && response.length) {
        noContentFlag=false;
        const data = response[0];
        const contentObject = get(data, 'content', []).filter((obj) => {
            return get(obj, '#type') === "promo_details_to_promo"
        });
        const promoDetails = get(contentObject[0], 'field_app_detail', []).filter((obj) => {
            return get(obj, '#type') === "app_promo_banner"
        });
        const promoCodeObject = get(data, 'icons', []).filter((obj) => {
            return get(obj, '#type') === "promo_code"
        });
        promotionObject = {
            cmsId: get(data, 'cms_id.value', ''),
            description: get(promoDetails[0], 'field_banner_title',''),
            imageURL: get(promoDetails[0], 'field_image_banner.url' , ''),
            isHired: get(data, 'hiredPromotion', ''),
            nid: get(data, 'id.value', ''),
            promoCode: get(promoCodeObject[0], 'field_title', ''),
            promoURL: get(data, 'url', ''),
        }
    }
    else {
        noContentFlag=true;
    }
    return state.merge({
        promotions: promotionObject,
        isFetching: false,
        error: false,
        success: true,
        noContent:noContentFlag
    });
}

function advancedInterstitialPromotions(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.ADVANCED_INTERSTITIAL_GET_PROMOTION_REQUEST:
            return state.merge({
                isFetching: true,
                error: false,
                success: false,
            });
        case actionTypes.ADVANCED_INTERSTITIAL_GET_PROMOTION_FAILURE:
            return state.merge({
                error: action.payload.error,
                success: false,
                isFetching: false
            });
        case actionTypes.ADVANCED_INTERSTITIAL_GET_PROMOTION_SUCCESS:
            return processPromotions(state, action.payload.response);
        default:
            return state;
    }
}

module.exports = advancedInterstitialPromotions;
