// @ vendors
const Immutable = require('immutable');

// @ constants
const {
    AGGREGATOR_CONSENTS_PSD2_INIT,
    AGGREGATOR_CONSENTS_PSD2_GET_URL_REQUEST,
    AGGREGATOR_CONSENTS_PSD2_GET_URL_SUCCESS,
    AGGREGATOR_CONSENTS_PSD2_GET_URL_FAILURE,
    AGGREGATOR_CONSENTS_PSD2_GET_INFO_REQUEST,
    AGGREGATOR_CONSENTS_PSD2_GET_INFO_SUCCESS,
    AGGREGATOR_CONSENTS_PSD2_GET_INFO_FAILURE,
} = require('constants/actionTypes');

const initialState = {
    isFetchingUrl: false,
    redirectionUrl: null,
    consentId: null,
    errorURL: null,
    isFetchingConsentInfo: false,
    consentInfo: null,
    errorConsentInfo: null,
};

function setInitialState() {
    return Immutable.fromJS(initialState);
}

function aggregatorConsents(state = setInitialState(), action) {
    switch (action.type) {
        case AGGREGATOR_CONSENTS_PSD2_INIT: {
            return state.merge(initialState)
        }
        case AGGREGATOR_CONSENTS_PSD2_GET_URL_REQUEST: {
            return state.merge({isFetchingUrl: action.payload})
        }
        case AGGREGATOR_CONSENTS_PSD2_GET_URL_SUCCESS: {
            return state.merge({
                redirectionUrl: action.payload.redirectUrl,
                consentId: action.payload.consentId,
            })
        }
        case AGGREGATOR_CONSENTS_PSD2_GET_URL_FAILURE: {
            return state.merge({errorURL: action.payload})
        }
        case AGGREGATOR_CONSENTS_PSD2_GET_INFO_REQUEST: {
            return state.merge({isFetchingConsentInfo: action.payload})
        }
        case AGGREGATOR_CONSENTS_PSD2_GET_INFO_SUCCESS: {
            return state.merge({consentInfo: action.payload})
        }
        case AGGREGATOR_CONSENTS_PSD2_GET_INFO_FAILURE: {
            return state.merge({errorConsentInfo: action.payload})
        }
        default:
            return state;
    }
}

module.exports = aggregatorConsents;