// @ utilities
const { isElHormigueroCard, isVanityCard } = require('utilities/APIParsingHelper');

// @ constants
const {
    R42_CARD_1_OFF_STANDARD,
    R42_CARD_2_OFF_STANDARD,
    R42_CARD_3_OFF_STANDARD,
    R42_CARD_4_OFF_STANDARD,
    R42_CARD_5_OFF_STANDARD,
    R42_CARD_6_OFF_STANDARD,
    R42_CARD_1_ON_STANDARD,
    R42_CARD_2_ON_STANDARD,
    R42_CARD_3_ON_STANDARD,
    R42_CARD_4_ON_STANDARD,
    R42_CARD_5_ON_STANDARD,
    R42_CARD_6_ON_STANDARD,
    R42_CARD_1_VIP_STANDARD,
    R42_CARD_2_VIP_STANDARD,
    R42_CARD_3_VIP_STANDARD,
    R42_CARD_4_VIP_STANDARD,
    R42_CARD_5_VIP_STANDARD,
    R42_CARD_6_VIP_STANDARD,
} = require('constants/cardR42StandardList');

const {
    NERUDA_CARD_1_ON_STANDARD,
    NERUDA_CARD_2_ON_STANDARD,
    NERUDA_CARD_3_ON_STANDARD,
    NERUDA_CARD_4_ON_STANDARD,
    NERUDA_CARD_5_ON_STANDARD,
    NERUDA_CARD_6_ON_STANDARD,
    NERUDA_CARD_7_ON_STANDARD,
    NERUDA_CARD_1_OFF_STANDARD,
    NERUDA_CARD_2_OFF_STANDARD,
    NERUDA_CARD_3_OFF_STANDARD,
    NERUDA_CARD_4_OFF_STANDARD,
    NERUDA_CARD_5_OFF_STANDARD,
    NERUDA_CARD_6_OFF_STANDARD,
    NERUDA_CARD_7_OFF_STANDARD,
    VANITY_CARD_1_OFF_STANDARD,
    VANITY_CARD_1_ON_STANDARD,
    HORMIGUERO_CARD_1_OFF_STANDARD,
    HORMIGUERO_CARD_1_ON_STANDARD,
} = require('constants/cardNerudaStandardList');

const {
    R42_CARD_OFF,
    R42_CARD_ON,
    R42_CARD_VIP,
} = require('constants/cardR42States');

const r42TravelPlusActiveStandards = [
    R42_CARD_1_ON_STANDARD,
    R42_CARD_2_ON_STANDARD,
    R42_CARD_3_ON_STANDARD,
    R42_CARD_4_ON_STANDARD,
    R42_CARD_5_ON_STANDARD,
    R42_CARD_6_ON_STANDARD,
];

const r42TravelPlusInactiveStandards = [
    R42_CARD_1_OFF_STANDARD,
    R42_CARD_2_OFF_STANDARD,
    R42_CARD_3_OFF_STANDARD,
    R42_CARD_4_OFF_STANDARD,
    R42_CARD_5_OFF_STANDARD,
    R42_CARD_6_OFF_STANDARD,
];

const r42TravelPlusVipStandards = [
    R42_CARD_1_VIP_STANDARD,
    R42_CARD_2_VIP_STANDARD,
    R42_CARD_3_VIP_STANDARD,
    R42_CARD_4_VIP_STANDARD,
    R42_CARD_5_VIP_STANDARD,
    R42_CARD_6_VIP_STANDARD,
];

const nerudaTravelPlusActiveStandards = [
    NERUDA_CARD_1_ON_STANDARD,
    NERUDA_CARD_2_ON_STANDARD,
    NERUDA_CARD_3_ON_STANDARD,
    NERUDA_CARD_4_ON_STANDARD,
    NERUDA_CARD_5_ON_STANDARD,
    NERUDA_CARD_6_ON_STANDARD,
    NERUDA_CARD_7_ON_STANDARD,
];

const nerudaTravelPlusInactiveStandards = [
    NERUDA_CARD_1_OFF_STANDARD,
    NERUDA_CARD_2_OFF_STANDARD,
    NERUDA_CARD_3_OFF_STANDARD,
    NERUDA_CARD_4_OFF_STANDARD,
    NERUDA_CARD_5_OFF_STANDARD,
    NERUDA_CARD_6_OFF_STANDARD,
    NERUDA_CARD_7_OFF_STANDARD,
];

const getTravelPlusState = (cardType, cardSubtype, cardStandard) => {
    let state;
    const r42TravelPlusSubtypes = ['013', '014', '015', '019'];
    
    if (cardType === '506' && r42TravelPlusSubtypes.includes(cardSubtype)) {
        if (r42TravelPlusActiveStandards.includes(cardStandard)) {
            state = R42_CARD_ON;
        } else if (r42TravelPlusInactiveStandards.includes(cardStandard)) {
            state = R42_CARD_OFF;
        } else if (r42TravelPlusVipStandards.includes(cardStandard)) {
            state = R42_CARD_VIP;
        }
    } else if (cardType === '500' && cardSubtype === '802') {
        if (nerudaTravelPlusActiveStandards.includes(cardStandard)) {
            state = R42_CARD_ON;
        } else if (nerudaTravelPlusInactiveStandards.includes(cardStandard)) {
            state = R42_CARD_OFF;
        }
        // Vanity
    } else if (isVanityCard(cardType, cardSubtype)) {
        if (cardStandard === VANITY_CARD_1_OFF_STANDARD) {
            state = R42_CARD_OFF;
        } else if (cardStandard === VANITY_CARD_1_ON_STANDARD) {
            state = R42_CARD_ON;
        }
    } else if (isElHormigueroCard(cardType, cardSubtype)) {
        if (cardStandard === HORMIGUERO_CARD_1_OFF_STANDARD) {
            state = R42_CARD_OFF;
        } else if (cardStandard === HORMIGUERO_CARD_1_ON_STANDARD) {
            state = R42_CARD_ON;
        }
    }

    return state;
}

module.exports = {
    getTravelPlusState,
};
