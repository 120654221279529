/* global dataLayer */

const pushOptimize = () => {
  if (typeof dataLayer != 'undefined'){
    window.triggerOpenbankOptimize = true
    dataLayer.push({ event: 'optimize.triggerOpenbank' })
    window.triggerOpenbankOptimize = false
    dataLayer.push({ event: 'optimize.triggerOpenbank' })
    dataLayer.pop()
    dataLayer.pop()
  }
};

module.exports = {
    pushOptimize,
};
