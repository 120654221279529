'use strict';

const request = require('superagent');
const { Promise } = require('es6-promise');
const apiURLBuilder = require('../core/apiURLBuilder');
const constants = require('constants/index');

const defaultParams = {
    header: {},
    query: {}
};

function soap(url, params) {
    return new Promise(function(resolve, reject) {
        request
            .post(url)
            .timeout(constants.DEFAULT_TIMEOUT)
            .send(params.query)
            .end(function(err, res) {
                if (err) {
                    if (err.timeout) {
                        reject(constants.TIMEOUT_ERROR);
                    } else {
                        reject(err.response || err);
                    }
                } else {
                    resolve(res);
                }
            });
    });
}

function get(url, params = defaultParams) {
    params.header = apiURLBuilder.isMocked() ? {} : params.header;
    params.header.accept = params.header.accept || 'application/json';
    let finalRequest = request
        .get(url)
        .timeout(constants.DEFAULT_TIMEOUT)
        .query(params.query)
        .set('Accept', params.header.accept);
    if (params.header.responseType) {
        finalRequest = finalRequest.responseType(params.header.responseType);
        delete params.header.responseType;
    }
    return finalRequest.set(params.header);
}

function post(url, params = defaultParams, contentType = 'json') {
    params.header = apiURLBuilder.isMocked() ? {} : params.header;

    let finalRequest = request
        .post(url)
        .send(params.query)
        .set('Accept', 'application/json');

    if (contentType) {
       finalRequest.type(contentType);
    }

    if (params.header.responseType) {
        finalRequest = finalRequest.responseType(params.header.responseType);
        delete params.header.responseType;
    }

    return finalRequest
        .set(apiURLBuilder.isMocked() ? {} : params.header);
}

function put(url, params = defaultParams, contentType = 'json') {
    params.header = apiURLBuilder.isMocked() ? {} : params.header;
    return request.put(url).send(params.query).type(contentType).set(params.header);
}

function del(url, params = defaultParams, contentType = 'json') {
    params.header = apiURLBuilder.isMocked() ? {} : params.header;
    return request.del(url).send(params.query).type(contentType).set(params.header);
}

function patch(url, params = defaultParams, contentType = 'json') {
    params.header = apiURLBuilder.isMocked() ? {} : params.header;
    return request.patch(url).send(params.query).type(contentType).set(params.header);
}

module.exports = {
    get,
    post,
    put,
    del,
    patch,
    soap
};
