const get = require('lodash/object/get');
const {formatText} = require('core/i18n').i18n;
// @core

const {
    LOG_AS_CLIENT_SUCCESS,
    LOG_AS_CLIENT_PASSWORD_SUCCESS,
    LOG_AS_CLIENT_PASSWORD_ERROR
} = require('constants/contactCenter/actionTypes');
const {
    ERROR_INVALID_PASSWORD,
    ERROR_USER_BLOCKED} = require('constants/contactCenter/errorTypes');
const actionTypes = require('constants/actionTypes');

const {
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_NONE,
    FLOW_TYPE_ONLY_ACCESS
} = require('utilities/contactCenter/wrapUp/constants');

const ACTION_CODE_AUTHENTICATE_N1 = 5279;

module.exports = {
    [ACTION_CODE_AUTHENTICATE_N1]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            action: [actionTypes.FETCH_PROFILE_SUCCESS],
            check: () => ({
                actionCode: ACTION_CODE_AUTHENTICATE_N1,
                operationState: window.location.pathname.includes('/keys/login-as-client') ? OPERATION_LOG_DONE : OPERATION_LOG_NONE,
            })
        },
        finish: {
            action: [LOG_AS_CLIENT_SUCCESS, LOG_AS_CLIENT_PASSWORD_SUCCESS, LOG_AS_CLIENT_SUCCESS, LOG_AS_CLIENT_PASSWORD_ERROR],
            check: (action) => {
                let operationState = OPERATION_LOG_DONE,
                    errorType = action.payload.error && action.payload.error.errorType || '',
                    errorMessage;

                if (action.type === LOG_AS_CLIENT_PASSWORD_ERROR) {
                    operationState = OPERATION_LOG_FAIL;
                    switch (errorType) {
                        case ERROR_INVALID_PASSWORD:
                            errorMessage = formatText('cc_agentAuthentication-passwordLabel')
                                + ': ' +
                                formatText('cc_agentAuthentication-errorMessage');
                            break;
                        case ERROR_USER_BLOCKED:
                            errorMessage = formatText('cc_passwordPositions-blockedUserMessage');
                            break;
                        default:
                            errorMessage = get(action, 'payload.error.errorMessage')
                                || get(action, 'payload.error.description')
                                || formatText('consultMiFID-serviceError');
                    }
                }

                return {
                    actionCode: ACTION_CODE_AUTHENTICATE_N1,
                    operationState,
                    errorMessage
                }
            }
        }
    },
};
