export const CATEGORYDICTIONARY = {
    default: {
        title: null,
        list: []
    },
    GENERIC_OFFER: {
        title: "contractsView-contractsView_genericOfferTermSectionTitle",
        list: []
    },
    PRIVATE:{
            title: "contractsView-contractsView_withoutNewMoneyWebTermSectionTitle",
            list: []
    },
    CC_OFFER: {
            title: "contractsView-contractsView_ccOfferTermSectionTitle",
            list: []
    },
    RETENTION: {
        title: "contractsView-contractsView_retentionTermSectionTitle",
        list: []
    },
    FLEE: {
        title: "contractsView-contractsView_leaksMatrixTermSectionTitle",
        list: []
    },
    RENEWAL: {
        title: "contractsView-contractsView_expiredOffersTermSectionTitle",
        list: []
    },
    SEGMENTED: {
        title: "contractsView-contractsView_segmentedTermSectionTitle",
        list: []
    }
};
export const ORDER = ['GENERIC_OFFER', 'CC_OFFER', 'FLEE', 'RENEWAL', 'default']

export const GENERIC_OFFER_CATEGORY = 'GENERIC_OFFER';
export const PRIVATE_CATEGORY = 'PRIVATE';
export const CC_OFFER_CATEGORY = 'CC_OFFER';
export const RETENTION_CATEGORY = 'RETENTION';
export const FLEE_CATEGORY = 'FLEE';
export const RENEWAL_CATEGORY = 'RENEWAL';
export const SEGMENTED_CATEGORY = 'SEGMENTED';