const { wrapupflows } = require('utilities/contactCenter/wrapUp/wrapupflows')
const { usdRouteLogger } = require('utilities/contactCenter/wrapUp/usdRouteLogger')
const get = require('lodash/object/get')
const reduce = require('lodash/collection/reduce')
const merge = require('lodash/object/merge')
const isFunction = require('lodash/lang/isFunction')
const { hasTokenCredential } = require('utilities/contactCenter/authenticationHelper');
const _ = require('utilities/contactCenter/lodashfp')

const { traceFlowEvent } = require('utilities/contactCenter/wrapUp/wrapUpCore')
const { wrapActionCode, checkFinishActionsCode, testPath } = require('./utilities')

const {
    OPERATION_LOG_TYPE_ENTRY,
    OPERATION_LOG_NONE,
    OPERATION_LOG_FAIL,
    OPERATION_LOG_DONE,
    OPERATION_LOG_ACCESS_BY_ACTION,
    OPERATION_LOG_INIT,
    OPERATION_LOG_TYPE_FINISH
} = require('utilities/contactCenter/wrapUp/constants')

const readActionsForActivation = (path, template = {}, itemPath = '.') => _.flow([
    _.pickBy(_.has(path)),
    _.mapValues((log, actionCode) => get(log, path).reduce((acc, actionItem) => {
        const thisActionCode = actionCode
        const thisAction = (itemPath === '.') ? actionItem : get(actionItem, itemPath)
        return thisAction ? Object.assign({}, acc, {
            [thisAction]: {
                [thisActionCode]: Object.assign({}, template, {
                    actionCode: thisActionCode,
                    action: thisAction
                })
            }
        }) : acc
    }, {})),
    actions => reduce(actions, (acc, action) => { // DeepMerge
        return merge(acc, action)
    }, {})
])

const interceptEntryAction = (actionInFlow, action, logsByActionCode, currentActionCode, state, location) => {
    if (actionInFlow) {
        const { operation } = actionInFlow
        const logs = logsByActionCode[currentActionCode];
        const checker = get(logs, 'entry.check');
        const operationStateObj = checker ? checker(action, state, traceFlowEvent) : { operationState: OPERATION_LOG_DONE }
        const { operationState, errorMessage } = operationStateObj
        const actionCode = operationStateObj.actionCode ? wrapActionCode(operationStateObj.actionCode) : currentActionCode;
        const path = get(logs, 'entry.path')

        if (
            (operationState === OPERATION_LOG_DONE || operationState === OPERATION_LOG_FAIL) &&
            (!path || testPath(path, location))
        ) {
            if (traceFlowEvent.getCurrentActionCode() !== currentActionCode) {
                traceFlowEvent.start(currentActionCode)
            }
            traceFlowEvent.notifyFlow(actionCode, operation, operationState, errorMessage)
        } else if (operationState === OPERATION_LOG_ACCESS_BY_ACTION) { // Log access by route with action and path
            traceFlowEvent.start(currentActionCode)
            traceFlowEvent.notifyFlow(actionCode, OPERATION_LOG_TYPE_ENTRY, OPERATION_LOG_DONE)
        } else if (operationState === OPERATION_LOG_INIT) {
            traceFlowEvent.start(currentActionCode)
        }
    }
}

const interceptAfterFinishAction = (after, action, operationFinish) => {
    if (after) {
        const { actionCode, operation, operationState, errorMessage } = after(action)
        let paramOperation = operation ? operation : operationFinish;

        if (operationState && operationState !== OPERATION_LOG_NONE) {
            traceFlowEvent.notifyFlow(wrapActionCode(actionCode), paramOperation, operationState, errorMessage)
        }
    }
}

const isEquivalent = (code1, code2) => {
    return (code1 === 'action-code-5223' && code2 === 'action-code-5224') || (code1 === 'action-code-5224' && code2 === 'action-code-5223')
}

const interceptFinishAction = (actionInFlow, action, logsByActionCode, currentActionCode, state) => {
    if (actionInFlow) {
        const { operation } = actionInFlow
        const logs = logsByActionCode[currentActionCode];
        const checker = get(logs, 'finish.check');
        const after = get(logs, 'finish.after');
        const operationStateObj = checker ? checker(action, state, traceFlowEvent) : {
            actionCode: currentActionCode,
            operationState: OPERATION_LOG_DONE
        }
        const { operationState, errorMessage } = operationStateObj
        const actionCode = wrapActionCode(operationStateObj.actionCode)

        if (operationState !== OPERATION_LOG_NONE) {
            if (actionCode !== currentActionCode && !isEquivalent(currentActionCode, actionCode)) {
                checkFinishActionsCode.add(actionCode)
            }
            traceFlowEvent.notifyFlow(actionCode, operation, operationState, errorMessage)
            interceptAfterFinishAction(after, action, operation)
        }
    }
}

const interceptEntryActionSuccessFailure = (actionInFlow, action, logsByActionCode, currentActionCode) => {
    if (actionInFlow) {
        const errorMessage = get(action, 'error.description')
        const { operation, operationState } = actionInFlow

        // If at least one of them fails, notify
        if (operationState === OPERATION_LOG_FAIL) {
            traceFlowEvent.notify(operation, operationState, errorMessage)
        }
        // If all of them are done, notify
        if (operationState === OPERATION_LOG_DONE) {
            traceFlowEvent.logExecutedAction(OPERATION_LOG_TYPE_ENTRY, action.type)
            const executedActions = traceFlowEvent.getExecutedActions(OPERATION_LOG_TYPE_ENTRY, currentActionCode)
            const successActions = get(logsByActionCode[currentActionCode], 'entry.success')
            let allDone = !!successActions && successActions.length
            if (Array.isArray(successActions)) {
                successActions.forEach(action => {
                    if (!executedActions.includes(action.action)) allDone = false
                })
            } else {
                allDone = false
            }

            if (allDone) {
                traceFlowEvent.notify(operation, operationState, errorMessage)
            }
        }
    }
}

let location;

const buildUsdActionLogger = logs => {
    return (action, store) => {
        const state = store.getState()
        const deposits = state.deposits.toJS()
        const logsByActionCode = { ...logs, ...deposits.retentionFlows}

        const entryActions = readActionsForActivation('entry.action', {
            operation: OPERATION_LOG_TYPE_ENTRY
        })(logsByActionCode)

        const finishActions = readActionsForActivation('finish.action', {
            operation: OPERATION_LOG_TYPE_FINISH
        })(logsByActionCode)

        const entryFailureActions = readActionsForActivation('entry.failure', {
            operation: OPERATION_LOG_TYPE_ENTRY,
            operationState: OPERATION_LOG_FAIL
        }, 'action')(logsByActionCode)

        const entrySuccessActions = readActionsForActivation('entry.success', {
            operation: OPERATION_LOG_TYPE_ENTRY,
            operationState: OPERATION_LOG_DONE
        }, 'action')(logsByActionCode)

        const failureAndSuccessFlags = Object.assign({},
            entryFailureActions,
            entrySuccessActions
        )

        const entrySuccessFailureActions = _.pickBy(_.negate(isFunction))(failureAndSuccessFlags)

        traceFlowEvent.setUp.dispatch = store.dispatch;

        if (action.type === '@@router/LOCATION_CHANGE') {
            if (hasTokenCredential()) { // only if you are logged in (avoid wrapUp)
                location = action.payload;
                usdRouteLogger(store, { location });
            }
        }

        const entryActionDescriptions = get(entryActions, action.type)
        for (let actionCode in entryActionDescriptions) {
            const entryActionDesc = entryActionDescriptions[actionCode]
            if (entryActionDesc && entryActionDesc.operation === OPERATION_LOG_TYPE_ENTRY) {
                interceptEntryAction(entryActionDesc, action, logsByActionCode, actionCode, state, location)
            }
        }

        const currentActionCode = traceFlowEvent.getCurrentActionCode()

        const finishActionDescriptions = get(finishActions, action.type)
        const hasCurrentActionCode = finishActionDescriptions && !!finishActionDescriptions[currentActionCode]

        for (let actionCode in finishActionDescriptions) {
            const isSameAction = hasCurrentActionCode ? actionCode === currentActionCode : null
            if (isSameAction || isSameAction === null) {
                const finishActionDesc = finishActionDescriptions[actionCode]
                if (finishActionDesc && finishActionDesc.operation === OPERATION_LOG_TYPE_FINISH) {
                    interceptFinishAction(finishActionDesc, action, logsByActionCode, actionCode, state)
                }
            }
        }


        const entrySuccessFailureActionDesc = get(entrySuccessFailureActions, `${action.type}.${currentActionCode}`)
        if (entrySuccessFailureActionDesc && entrySuccessFailureActionDesc.operation === OPERATION_LOG_TYPE_ENTRY) {
            interceptEntryActionSuccessFailure(entrySuccessFailureActionDesc, action, logsByActionCode, currentActionCode)
        }

        const finishFailureFlags = get(logsByActionCode, `${currentActionCode}.finish.failure`)
        // If at least one of them fails, notify
        if (Array.isArray(finishFailureFlags)) {
            const finishFailureValidators = finishFailureFlags.filter(isFunction)
            finishFailureValidators.forEach(isFailure => {
                if (isFailure(action)) {
                    traceFlowEvent.notify(OPERATION_LOG_TYPE_FINISH, OPERATION_LOG_FAIL)
                }
            })
        }
    }
}

const usdActionLogger = buildUsdActionLogger(wrapupflows)

module.exports = {
    buildUsdActionLogger,
    usdActionLogger
}
