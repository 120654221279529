// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    ACCOUNT_DETAILS_CERTIFICATE_TITULARIDAD,
    ACCOUNT_DETAILS_CERTIFICATE_SALDO
} = require('constants/index');

const initialState = Immutable.fromJS({
    isFetchingDocument: false,
    fileDownloadError: false,
    documents: [
        {
            'file': { 'fileName': ACCOUNT_DETAILS_CERTIFICATE_TITULARIDAD },
            'nombreTipoComunicacion': 'certificateSection-ownershipCertificate',
            'nombreGrupoComunicacion': 'certificateSection-ownershipCertificate',
            'isFetching': false,
            'isError': false
        },
        {
            'file': { 'fileName': ACCOUNT_DETAILS_CERTIFICATE_SALDO },
            'nombreTipoComunicacion': 'certificateSection-balanceCertificate',
            'nombreGrupoComunicacion': 'certificateSection-balanceCertificate',
            'isFetching': false,
            'isError': false
        }
    ]
});

function getUpdatedDocuments(documents, fileName, isFetching, isError) {
    return documents.map((document) => {
        if (document.getIn(['file', 'fileName']) === fileName) {
            return document.merge({
                isFetching,
                isError
            });
        }
        return document
    });
}

function accountDetailsCertificates(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_FETCHING:
            return state.mergeDeep({
                isFetchingDocument: true,
                fileDownloadError: false,
                documents: getUpdatedDocuments(state.get('documents'), action.payload.fileName, true, false)
            });
        case actionTypes.GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_SUCCESS:
            return state.mergeDeep({
                isFetchingDocument: false,
                fileDownloadError: false,
                documents: getUpdatedDocuments(state.get('documents'), action.payload.fileName, false, false)
            });
        case actionTypes.GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_ERROR:
            return state.mergeDeep({
                isFetchingDocument: false,
                fileDownloadError: action.payload.error,
                documents: getUpdatedDocuments(state.get('documents'), action.payload.fileName, false, action.payload.error)
            });
        case actionTypes.GET_ACCOUNT_DETAILS_CERTIFICATES_PDF_CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}

module.exports = accountDetailsCertificates;
