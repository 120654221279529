// @vendors
const Immutable = require('immutable');

// @actions
const actionTypes = require('constants/actionTypes');

// @constants
const {
    BROKER_NO_STOCKS_CONTRACT_SELECTED,
    BROKER_ORDER_TYPE_TO_MARKET,
    BROKER_ETFS_SELL_ORDER_TYPE_VALUE_DEFAULT,
    BROKER_ORDER_TYPE_LIMITED
} = require('constants/index');

// @helpers
const DateHelper = require('utilities/dateHelper');

function setInitialState() {
    const firstDate = DateHelper.getNextBussinessDay(true, false);
    const limitDate = DateHelper.getStockLimitDate(firstDate);
    const excludeDates = DateHelper.getExcludedWeekends(limitDate);
    const validityDate = firstDate.clone();

    return Immutable.fromJS({
        stocksContractSelectedValue: BROKER_NO_STOCKS_CONTRACT_SELECTED,
        orderTypeOption: BROKER_ORDER_TYPE_TO_MARKET,
        orderTypeValue: BROKER_ETFS_SELL_ORDER_TYPE_VALUE_DEFAULT,
        sellPrice: BROKER_ETFS_SELL_ORDER_TYPE_VALUE_DEFAULT,
        errorFlags: {
            orderTypeValue: false,
            titleQuantity: false,
            isETFsContractEmpty: false
        },
        firstDate,
        validityDate,
        limitDate,
        excludeDates,
        titleQuantityValid: true,
        titleQuantityLimitValid: true,

        valid: false,
        titleChangedManually: false
    });
}

function validateStep(step) {
    const titleQuantityValid = step.get('titleQuantity') && step.get('titleQuantity') != '0';
    const titleQuantityLimitValid = step.get('titleQuantity') <= step.get('selectedOptionTitles');
    const isETFsContractEmpty = step.get('stocksContractSelectedValue') === BROKER_NO_STOCKS_CONTRACT_SELECTED;
    const isInvalidOrderTypeValue = step.get('orderTypeOption') === BROKER_ORDER_TYPE_LIMITED
        && (!step.get('orderTypeValue') || step.get('orderTypeValue') <= 0);

    const valid = !isETFsContractEmpty && titleQuantityLimitValid && titleQuantityValid && !isInvalidOrderTypeValue;

    return step.merge({
        errorFlags: {
            orderTypeValue: isInvalidOrderTypeValue,
            isETFsContractEmpty: isETFsContractEmpty
        },
        valid,
        titleQuantityValid,
        titleQuantityLimitValid
    });
}

function brokerETFsSellStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_ETFS_SELL_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_ETFS_SELL_STOCK_CONTRACT_CHANGED:
            return state.merge({
                stocksContractSelectedValue: action.payload.value,
                selectedOptionTitles: action.payload.titles,
                errorFlags: {
                    stocksContract: false
                }
            });
        case actionTypes.BROKER_ETFS_SELL_ORDER_TYPE_OPTION_CHANGED:
            return state.merge({
                orderTypeOption: action.payload.value
            });
        case actionTypes.BROKER_ETFS_SELL_ORDER_TYPE_VALUE_CHANGED:
            return state.merge({
                orderTypeValue: action.payload.value,
                sellPrice: action.payload.sellPrice
            });
        case actionTypes.BROKER_ETFS_SELL_ORDER_TYPE_VALUE_RESET:
            return state.merge({
                orderTypeValue: BROKER_ETFS_SELL_ORDER_TYPE_VALUE_DEFAULT,
                errorFlags: {
                    orderTypeValue: false
                }
            });
        case actionTypes.BROKER_ETFS_SELL_VALIDITY_DATE_CHANGED:
            return state.merge({
                validityDate: action.payload.value
            });
        case actionTypes.BROKER_ETFS_SELL_TITLE_QUANTITY_CHANGED:
            return state.merge({
                titleQuantity: action.payload.value,
                titleChangedManually: action.payload.manually
            });
        case actionTypes.BROKER_SELL_CONTRACT_NUMBER:
            return state.merge({
                selectedOptionValue: action.payload.contract
            });
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_SUCCESS:
        case actionTypes.BROKER_EXCHANGE_AVAILABILITY_ERROR:
            const firstDate = DateHelper.getNextBussinessDay(action.payload.isAvailableToday, action.payload.currentDate);
            const limitDate = DateHelper.getStockLimitDate(firstDate);
            const validityDate = DateHelper.getValidDate(firstDate, limitDate, state.get('validityDate'));
            const excludeDates = DateHelper.getExcludedWeekends(limitDate);
            
            return state.merge({
                firstDate,
                validityDate,
                limitDate,
                excludeDates
            });
        default:
            return state;
    }
}

module.exports = brokerETFsSellStep1;
