// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_TRANSFER_EXTERNAL_FUND_TOTAL,
    BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL,
    BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL_AMOUNT
} = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        hasSignaturePattern: true,
        valid: false,
        destinationFundIsValid: true,
        partialIsValid: true,
        type: BROKER_TRANSFER_EXTERNAL_FUND_TOTAL,
        subType: BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL_AMOUNT,
    });
}

function validateStep(step) {
    return step.merge({
        valid:
            step.get('destinationFundIsValid') &&
            !!step.get('destinationFund') &&
            (step.get('type') === BROKER_TRANSFER_EXTERNAL_FUND_TOTAL ||
                (step.get('type') === BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL &&
                    step.get('amount') > 0)),
        destinationFundIsValid: step.get('destinationFundIsValid') && !!step.get('destinationFund'),
        partialIsValid: (step.get('type') !== BROKER_TRANSFER_EXTERNAL_FUND_PARTIAL || (step.get('amount') && step.get('amount') > 0)),
        amountDirty: !step.get('amount') || step.get('amount') <= 0,
    });
}

function externalTransferFundStep3(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_DESTINATION_FUND:
            return state.merge({
                destinationFund: action.payload.fundId,
                destinationFundIsValid: !action.payload.linkedToRobo,
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_TYPE:
            return state.merge({
                type: action.payload.type
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_SUBTYPE:
            return state.merge({
                subType: action.payload.subType
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountDirty: false
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_RESET_AMOUNT_DIRTY:
            return state.merge({
                amountDirty: false
            });
        case actionTypes.BROKER_WIZARD_EXTERNAL_TRANSFER_FUND_VALIDATE_STEP:
            return validateStep(state);
    }
    return state;
}

module.exports = externalTransferFundStep3;
