// @helpers
const actionTypes = require('constants/actionTypes');
const storage = require('utilities/storage');
const Immutable = require('immutable');
const moment = require('moment');
const {
    BROKER_LANDING_PLANS_PLACE,
    BROKER_LANDING_PG_PLACE,
    DATE_FORMAT_API2
} = require('constants/index');
const WARNING_FISCAL_DATES = require('constants/brokerProviderWarningFiscalDates');

function shouldDisplayModal(state, cookieName , closedAtCookie) {
    const today = new Date();
    const firstDate = state.get('firstDate');
    const endDate = state.get('endDate');
    const alreadyClosedAtPlace = storage.sessionStorage.get(closedAtCookie);

    if (
        !storage.cookies.get(cookieName) && !alreadyClosedAtPlace &&
        endDate >= today && firstDate <= today
    ) {
        return true;
    }
    
    return false;
}

function setInitialState() {
    return Immutable.Map().merge({
        shouldDisplay: false,
        closedAt: {
            [BROKER_LANDING_PLANS_PLACE]: false,
            [BROKER_LANDING_PG_PLACE]: false
        },
        firstDate: '',
        endDate: '',
        place:''
    });
}

function close(state) {
    const place = state.get('place');
    storage.sessionStorage.set((place + '_CLOSED'), true);

    return state.mergeDeep({
        closedAt: {
            [place]:true,
        },

        shouldDisplay: false
    });
}

function closeModalAndRemember(state) {
    storage.cookies.set(state.get('cookieName'), true);
    
    return state.merge({
        shouldDisplay: false,
    });
}

function init(state, username, place) {
    const cookieName = username + '_ATTENTION_PLAN_MODAL_UNDERSTOOD';
    const closedAtCookie = place + '_CLOSED';
    let shouldDisplay = shouldDisplayModal(state, cookieName, closedAtCookie) && !state.get('closedAt').get(place);

    return state.merge({
        shouldDisplay: shouldDisplay,
        cookieName: cookieName,
        place: place
    });
}

function setDates(state, { generalInitDate, generalCloseDate }) {
    const firstDate = moment(generalInitDate, DATE_FORMAT_API2);
    const endDate = moment(generalCloseDate, DATE_FORMAT_API2);
    return state.merge({
        firstDate: WARNING_FISCAL_DATES.getGeneralInitDate({
            year: firstDate.year(),
            month: firstDate.month(),
            day: firstDate.date()
        }),
        endDate: WARNING_FISCAL_DATES.getGeneralCloseDate({
            year: endDate.year(),
            month: endDate.month(),
            day: endDate.date()
        })
    });
}

function brokerAttentionPlanStartingModal(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_ATTETION_PLAN_MODAL_INIT:
            return init(state, action.payload.userName, action.payload.place);
        case actionTypes.BROKER_ATTETION_PLAN_MODAL_CLOSE:
            return close(state);
        case actionTypes.BROKER_ATTETION_PLAN_MODAL_CLOSE_AND_REMEMBER:
            return closeModalAndRemember(state);
        case actionTypes.HIDE_LOGOUT_POPUP:
            if (!!storage.sessionStorage.get('BROKER_LANDING_PG_PLACE_CLOSED')) {
                storage.sessionStorage.remove('BROKER_LANDING_PG_PLACE_CLOSED');
            }
            if (!!storage.sessionStorage.get('BROKER_LANDING_PLANS_PLACE_CLOSED')) {
                storage.sessionStorage.remove('BROKER_LANDING_PLANS_PLACE_CLOSED');
            }
            return state;
        case actionTypes.BROKER_ATTETION_PLAN_MODAL_GET_DATES_SUCCESS:
            return setDates(state, action.payload.data);
        default:
            return state;
    }
}

module.exports = brokerAttentionPlanStartingModal;
