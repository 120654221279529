// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    activeTab: 0
});

const accountDebits = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_TAB:
            return state.merge({
                activeTab: action.tab,
                doScroll: action.doScroll
            });
        case actionTypes.SET_SCROLL:
            return state.merge({
                doScroll: action.scroll
            });

        case actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_REQUEST:
            return state.merge({
                isFetching: true
            });

        case actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });

        case actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_SUCCESS:
            return state.merge({
                isFetching: false
            });

        default:
            return state;
    }
};

module.exports = accountDebits;
