module.exports = {
    title: 'contractsView-preApprovedLoanStep3Title',
    order: 3,
    footer_text: '',
    position: 'middle',
    components: [
        {
            id_component: 'dropdown-accounts',
            label: 'contractsView-preApprovedLoanDropdownLabel',
            options: [],
            type: 'single',
            source: 'loadCmbAccounts',
            onChangeFn: 'onChangeDropdownAccounts',
            target: 'cta_seleccionada',
            order: 1,
            isIntervenerAvailable: false,
            isMaxAmountLabelAvailable: false,
            maxAmountValue: 0,
            msg_error: 'contractsView-preApprovedLoanDropdownErrorMsg'
        },
        {
            id_component: 'radio-button',
            target: 'dia_pago_seleccionado',
            label: 'contractsView-preApprovedLoanRadiosLabel',
            options: [
                {
                    id: 1,
                    label: 'contractsView-preApprovedLoanRadio1Label',
                    default_value: 1
                },
                {
                    id: 2,
                    label: 'contractsView-preApprovedLoanRadio2Label',
                    default_value: 0
                }
            ],
            type: 'single',
            order: 2
        }
    ]
}
