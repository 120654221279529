// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const moment = require('moment');
const { formatText } = require('core/i18n').i18n;

// @ constants
const actionTypes = require('constants/actionTypes');
const { INTERVENER_CODES_BY_DESCRIPTION } = require('constants/interveners');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    WIZARD_THIRD_STEP
} = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            {
                valid: true
            },
            {
                valid: false,
                account: {},
                accountError: false,
                interveners: Immutable.List(),
                availableInterveners: Immutable.List()
            },
            {
                hasSignaturePattern: true,
                termsAndConditions: !!__CONTACT_CENTER__,
                valid: false
            }
        ],
        dataToken: '',
        visibleStep: WIZARD_FIRST_STEP,
        submitSuccessful: false,
        isFetching: false,
        inProgress: false,
        isFetchingStep1: false,
        informationStep1: null,
        errorFlags: {
            termsAndConditions: false
        },
        error: '',
        emailSend: true,
        checkBoxCondition: true,
        mailSent: false,
        emissionDate: moment(),
        precontractualDocumentReaded: false
    });
}

function parseInterveners(interveners) {
    return interveners.map(intervener => {
        const type = formatText('interventionTypes-' + trim(intervener.desIntervencion));
        return {
            name: trim(intervener.nombre),
            type: type,
            personType: intervener.tipoPersona,
            personNum: intervener.numPersona,
            interventionType: intervener.tipoIntervencion
        };
    });
}

function filterAvailables(currentInterveners = [], originalInterveners = []) {
    const availableInterveners = originalInterveners.filter(intervener => {
        return !currentInterveners.find(originalIntervener => {
            return intervener.get('name') === originalIntervener.get('name');
        });
    });

    return availableInterveners;
}

function validateStep1(state) {
    const interveners = state.getIn(['steps', SECOND_STEP, 'interveners']);
    return !interveners.isEmpty()
        ? state.setIn(['steps', SECOND_STEP, 'valid'], true)
        : state.setIn(['steps', SECOND_STEP, 'accountError'], true);
}

function validateStep2(state) {
    const isTermsAndConditionsValid = state.getIn(['steps', THIRD_STEP, 'termsAndConditions']);
    // set error when termsAndCondition is not checked
    return state
        .setIn(['errorFlags', 'termsAndConditions'], !isTermsAndConditionsValid)
        .setIn(['steps', THIRD_STEP, 'valid'], isTermsAndConditionsValid);
}

function updateIntervener(immState, payload) {
    const intervenerIndex = get(payload, 'index');
    const intervenerDescription = trim(get(payload, 'type'));
    const intervenerType = trim(INTERVENER_CODES_BY_DESCRIPTION[intervenerDescription]);

    return immState.mergeDeepIn(
        ['steps', SECOND_STEP, 'interveners', intervenerIndex],
        {
            type: intervenerDescription,
            interventionType: intervenerType
        }
    );
}

function transferReducer(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_REQUEST:
        case actionTypes.BROKER_STOCKS_CONTRACT_CONFIRM_REQUEST:
            return state.merge({
                isFetching: true,
                error: '',
                checkBoxCondition: false,
                emailSend: false
            });
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_SUCCESS:
            return state.merge({
                steps: state
                    .get('steps')
                    .setIn(
                        [SECOND_STEP, 'interveners'],
                        Immutable.fromJS(parseInterveners(action.payload.interveners))
                    ),
                isFetching: false
            });
        case actionTypes.BROKER_FETCH_CHECKING_ACCOUNTS_INTERVENERS_FAILURE:
        case actionTypes.BROKER_STOCKS_CONTRACT_CONFIRM_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.data.message
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_RESTORE_DEFAULT_INTERVENERS:
            return state.mergeIn(['steps', SECOND_STEP], {
                interveners: action.payload.originalInterveners,
                availableInterveners: Immutable.List()
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_SET_ACCOUNT_INTERVENER_NAME: {
            let interveners = state.getIn(['steps', SECOND_STEP, 'interveners']);
            const nextInterveners = interveners.setIn(
                [action.payload.index, 'name'],
                action.payload.name
            );
            const availableInterveners = filterAvailables(
                nextInterveners,
                action.payload.originalInterveners
            );

            return state.mergeIn(['steps', SECOND_STEP], {
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.BROKER_STOCKS_CONTRACT_SET_ACCOUNT_INTERVENER_TYPE:
            return updateIntervener(state, get(action, 'payload'));
        case actionTypes.BROKER_STOCKS_CONTRACT_ADD_INTERVENER: {
            let availableInterveners = state.getIn(['steps', SECOND_STEP, 'availableInterveners']);
            const intervener = availableInterveners.get(availableInterveners.size - 1);
            const interveners = state.getIn(['steps', SECOND_STEP, 'interveners']);
            const nextInterveners = interveners.push(intervener);
            availableInterveners = availableInterveners.pop();

            return state.mergeIn(['steps', SECOND_STEP], {
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.BROKER_STOCKS_CONTRACT_REMOVE_INTERVENER: {
            const interveners = state.getIn(['steps', SECOND_STEP, 'interveners']);
            let nextInterveners = interveners.splice(action.payload.index, 1);
            const availableInterveners = filterAvailables(
                nextInterveners,
                action.payload.originalInterveners
            );

            if (nextInterveners.size === 1) {
                nextInterveners = nextInterveners.setIn([0, 'type'], 'TITULAR');
            }

            return state.mergeIn(['steps', SECOND_STEP], {
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.BROKER_STOCKS_CONTRACT_RESET:
            let intialState = setInitialState();
            const stepIndex = action.payload.visibleStepIndex || WIZARD_FIRST_STEP;
            return intialState.set('visibleStep', stepIndex);
        case actionTypes.BROKER_STOCKS_CONTRACT_RESET_ACCOUNT:
            return state.mergeIn(['steps', FIRST_STEP], {
                account: {},
                accountError: false
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_SET_ACCOUNT:
            return state.mergeIn(['steps', SECOND_STEP], {
                account: action.payload.account,
                accountError: false
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_SET_VISIBLE_STEP:
            return state.set('visibleStep', action.payload.step);
        case actionTypes.BROKER_STOCKS_CONTRACT_VALIDATE_STEP:
            let partialState;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case WIZARD_FIRST_STEP:
                    return state.set('visibleStep', WIZARD_SECOND_STEP);
                case WIZARD_SECOND_STEP:
                    partialState = validateStep1(state);
                    const visibleStep = partialState.getIn(['steps', SECOND_STEP, 'valid']) ? WIZARD_THIRD_STEP : WIZARD_SECOND_STEP;
                    return partialState.merge({ visibleStep });
                case WIZARD_THIRD_STEP:
                    partialState = validateStep2(state);
            }
            return state.merge(partialState);
        case actionTypes.BROKER_STOCKS_CONTRACT_SET_TERMS_AND_CONDITIONS:
            return state
                .setIn(['steps', THIRD_STEP, 'termsAndConditions'], action.payload.isChecked)
                .setIn(['errorFlags', 'termsAndConditions'], false)
                .set('checkBoxCondition', false)
                .setIn(['steps', THIRD_STEP, 'valid'], action.payload.isChecked);
        case actionTypes.BROKER_STOCKS_CONTRACT_CONFIRM_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isFetching: false,
                orderConfirmation: {
                    contract: {
                        number: get(action, 'payload.contract.numerodecontrato', ''),
                        product: get(action, 'payload.contract.producto', '')
                    }
                }
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_SIGNATURE_PATTERN_SUCCESS:
            return state.merge({
                dataToken: action.payload.dataToken
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING:
            return state.merge({
                isFetchingStep1: true
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION:
            return state.merge({
                informationStep1: action.payload.information,
                isFetchingStep1: false
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE:
            return state.merge({
                isFetchingStep1: false
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION:
            return state.setIn(['steps', THIRD_STEP, 'sendingEmail'], true);
        case actionTypes.BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION_SUCCESS:
            return state.setIn(['steps', THIRD_STEP, 'sendingEmail'], false);
        case actionTypes.BROKER_STOCKS_CONTRACT_EMAIL_NOTIFICATION_ERROR:
            return state.setIn(['steps', THIRD_STEP, 'sendingEmail'], false);
        case actionTypes.BROKER_STOCKS_CONTRACT_FINISH_READING_PRECONTRACTUAL_DOCUMENT:
            return state.merge({
                precontractualDocumentReaded: true,
            })
        case actionTypes.BROKER_STOCKS_CONTRACT_WILL_LEAVE:
        case actionTypes.BROKER_STOCKS_CONTRACT_WILL_CANCEL:
        default:
            return state;
    }
}

module.exports = transferReducer;
