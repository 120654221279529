//@vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');

//@helpers
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    inProgress: false,
    hasBeenFetched: false,
    errorMessage: '',
    additionalInformation: {}
});

function parseAdditionalInformation (data) {
    const conditions = get(data, 'listaCondiciones.datoCondiciones[0]', {});
    return {
        contractNumber: data.numeroContrato,
        product: data.codProducto,
        preSignatureIndicator: conditions.indAntefirma,
        customisationIndicator: conditions.indPersonalizacion,
        documentTypeIndicator: conditions.indTipoDocum,
        destinyTypeIndicator: conditions.indTipoDocum,
        formatTypeIndicator: conditions.tipoFormato,
        currency: data.moneda,
        secuencial: conditions.secuencial,
        productSubType: data.subTiProduct
    }
}

function accountChequebookAdditionalInformation(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_ACCOUNT_CHECKBOOK_INFO_REQUEST:
            return state.merge({
                inProgress: true,
                hasBeenFetched: false,
                errorMessage: ''
            });
        case actionTypes.SET_ACCOUNT_CHECKBOOK_INFO_SUCCESS:
            return state.merge({
                hasBeenFetched: true,
                inProgress: false,
                additionalInformation: parseAdditionalInformation(action.payload.data)
            });
        case actionTypes.SET_ACCOUNT_CHECKBOOK_INFO_FAILURE:
            return state.merge({
                inProgress: false,
                errorMessage: action.payload.error
            });
        default:
            return state
    }
}

module.exports = accountChequebookAdditionalInformation;
