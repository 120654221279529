const { getLanguage, formatText } = require('core/i18n').i18n;
const moment = require('moment');
const momentTz = require('moment-timezone');
const { toCamelCase } = require('utilities/stringHelper');
const { SUNDAY_INDEX_DAY, SATURDAY_INDEX_DAY, ES_PREPOSITION } = require('constants/index');
const { COUNTRIES_CODES: { ES } } = require('constants/countries');
const { DATE_PICKER_CUSTOM_DATE_FORMAT } = require('constants/commonsComponents');
const DateFormatTypesCC = require('constants/contactCenter/dateFormatTypes');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');
const DateFormatTypes = require('commonsConstants/dateFormatTypes');
const { es: { DATE_FORMAT } } = require('commonsConstants/dateFormatTypes');
const DATE_FORMAT_TYPES = require("commonsConstants/dateFormatTypes");
let currentLanguage = getLanguage();

const DateHelper = {
    removeDot(date, language) {
        return language.toUpperCase() == DEFAULT_ORIGIN_COUNTRY ? date.replace('.', '') : date;
    },

    isToday(date) {
        const today = DateHelper.removeTimeFromDate(moment());
        date = DateHelper.removeTimeFromDate(date);
        return today.unix() === date.unix();
    },

    isTodayBetween(startDate, endDate) {
        const today = DateHelper.removeTimeFromDate(moment());
        const startDateParsed = DateHelper.removeTimeFromDate(moment(startDate));
        const endDateParsed = DateHelper.removeTimeFromDate(moment(endDate));
        return startDateParsed.unix() <= today.unix() && endDateParsed.unix() >= today.unix();
    },

    isYesterday(date) {
        let yesterday = moment().subtract(1, 'days');
        yesterday = DateHelper.removeTimeFromDate(yesterday);
        date = DateHelper.removeTimeFromDate(date);
        return yesterday.unix() === date.unix();
    },

    isCurrentYear(date) {
        return moment(date).year() === moment().year();
    },

    getTimeFormated(time = '') {
        let scheduleTime = time.toString();
        let formatedTime;
        if (scheduleTime.length === 1) {
            formatedTime = ("0" + time + ":00")
        } else {
            formatedTime = (time + ":00")
        }
        return formatedTime;
    },

    getDay(date) {
        return date ? moment(date).get('date') : '';
    },

    getTwoDigitsDay(dayDate) {
        const day = parseInt(dayDate);
        const twoDigitsDay = day < 10 ? '0' + day : day;
        return dayDate ? twoDigitsDay : '';
    },

    getMonth(date) {
        return date ? moment(date).month() + 1 : '';
    },

    getTwoDigitsMonth(monthDate) {
        const month = parseInt(monthDate);
        const twoDigitsMonth = month < 10 ? '0' + month : month;
        return monthDate ? twoDigitsMonth : '';
    },

    getYear(date) {
        return date ? moment(date).year().toString().substr(-2) : '';
    },

    getFullYear(date) {
        return date ? moment(date).year() : '';
    },

    getMonthName(monthValue, language = currentLanguage) {
        return DateHelper.getMonthFullName(monthValue, language).substr(0, 3);
    },

    getMonthNameFromDate(date, format, language = currentLanguage) {
        return DateHelper.removeDot(moment(date, format).locale(language).format('MMM'), language).toUpperCase();
    },

    getMonthFullName(monthValue, language = currentLanguage) {
        return moment().month(monthValue - 1).locale(language).format('MMMM');
    },

    getDateMonthsBefore(strDate, monthsBefore) {
        const date = new Date(strDate);
        return new Date(date.setMonth(date.getMonth() - monthsBefore));
    },

    getDateRange(startDate, endDate) {
        let dates = new Array();
        let date = new Date(startDate);
        while (date <= new Date(endDate)) {
            dates.push(new Date(date));
            date.setMonth(date.getMonth() + 1);
        }
        return dates;
    },

    getMonthsNamesRange(startDate, endDate, format, language = currentLanguage) {
        return DateHelper.getDateRange(startDate, endDate).map(date => {
            return DateHelper.getMonthNameFromDate(date, format, language);
        })
    },

    parseDate(dateString) {
        let parts = dateString.match(/(\d+)/g);
        let date = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based

        return date;
    },

    removeTimeFromDate(date) {
        return moment(date).hours(0).minutes(0).seconds(0);
    },

    isDateFormat(date) {
        return date instanceof Date && !isNaN(date.valueOf());
    },

    getPreviousMonthName(month) {
        if (month === 1) {
            return DateHelper.getMonthName(12);
        } else {
            return DateHelper.getMonthName(month - 1);
        }
    },

    getMonthAgo(date) {
        date = moment(date).subtract(1, 'months');
        return date;
    },

    milisecBetweenDates(start, end) {
        const mStart = moment(start);
        const mEnd = moment(end);

        return mEnd.diff(mStart)
    },

    durationBetweenDate(start, end) {
        return moment.duration(DateHelper.milisecBetweenDates(start, end));
    },

    fromToday(date) {
        const today = moment();
        const nDate = moment(date);

        return moment.duration(today.diff(nDate));
    },

    toToday(date) {
        const today = moment();
        const nDate = moment(date);

        return moment.duration(nDate.diff(today));
    },

    todayDate(format = 'YYYY-MM-DD') {
        return moment().format(format);
    },

    /**
     * To use this with CC, add new date formats in file constants/contactCenter/dateFormatTypes.
     *
     * @param {string} paramDate - String with date value to format.
     * @param {string} format - Desired format name. 'Privada' entries have more priority over Contact Center ones.
     */
    formatDate(paramDate, format = DATE_FORMAT) {
        const language = getLanguage();
        // TODO: It uses es if language does not exist
        let desiredFormat;
        try {
            desiredFormat = DateFormatTypes[language][format];
        } catch(e) {
            desiredFormat = DateFormatTypes['es'][format];
        }

        if (!desiredFormat && __CONTACT_CENTER__) {
            desiredFormat = DateFormatTypesCC[language][format];
        }

        let result = moment(paramDate).locale(language);
        if (desiredFormat) {
            result = result.format(desiredFormat);
            result = toCamelCase(DateHelper.removeDot(result, language));
        } else {
            result = result.format(format);
        }

        return result;
    },

    customFormat(dateStr, formatStr, language = currentLanguage) {
        return DateHelper.removeDot(moment(dateStr).locale(language).format(formatStr).toUpperCase(), language);
    },

    customNormalFormat(dateStr, formatStr, language = currentLanguage) {
        return DateHelper.removeDot(moment(dateStr).locale(language).format(formatStr), language);
    },

    formatSimple(date, language = currentLanguage) {
        return moment(date).locale(language).format('DD MMM');
    },

    getFormattedDate(date) {
        const formatDate = moment(date, 'DD MM YYYY')
            .locale('es')
            .format(formatText(DATE_PICKER_CUSTOM_DATE_FORMAT))
            .replace('.', '');
        if (DateHelper.isToday(formatDate)) {
            return formatText('common-today');
        }
        return DateHelper.formatSimple(formatDate);
    },

    stringDateToMoment(stringDate, format) {
        // TODO: It uses es language if it does not exist
        try {
            return moment(stringDate, DateFormatTypes[currentLanguage][format]);
        } catch(e) {
            return moment(stringDate, DateFormatTypes["es"][format]);
        }
    },

    /**
     * Get the day's week in numbers using momentjs library
     * Returns an Array with momentjs objects
     * @param {String} date / @see DATE_FORMAT_API constat
     * @return {Array} ie: [{momentjs object}]
     */
    getDaysOfWeek(date) {
        const startOfWeek = moment(date).startOf('isoWeek');
        const endOfWeek = moment(date).endOf('isoWeek');

        let days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day);
            day = day.clone().add(1, 'd');
        }

        return days;
    },

    /**
     * Get all the dates between two Moment Dates
     * @param {Obj} startDate   momentjs object that represents the initial date
     * @param {Obj} endDate     momentjs object that represents the end date
     * @return {Array}          Returns an array of momentjs objects.
    */
    getDaysBetweenDates(startDate, endDate) {
        if (!(moment.isMoment(startDate) && moment.isMoment(endDate))) {
            throw new TypeError('Neither startDate nor endDate are valid moment Objects');
        }

        let dates = [];
        let currDate = startDate.clone().startOf('day');
        let lastDate = endDate.clone().startOf('day');

        while (currDate.diff(lastDate) <= 0) {
            dates.push(currDate.clone());
            currDate.add(1, 'days');
        }

        return dates;
    },

    getNumOfDaysBetweenDates(firstDate, secondDate = null) {
        if (!secondDate) {
            return moment().diff(moment(firstDate), 'days')
        } else {
            return moment(secondDate).diff(moment(firstDate), 'days')
        }
    },

    /**
     * Get all the dates between two Moment Dates which are Saturdays and Sundays
     * @param {Obj} startDate   momentjs object that represents the initial date
     * @param {Obj} endDate     momentjs object that represents the end date
     * @return {Array}          Returns an array of momentjs objects.
    */
    getWeekendsBetweenDates(startDate, endDate) {
        if (!(moment.isMoment(startDate) && moment.isMoment(endDate))) {
            throw new TypeError('Neither startDate nor endDate are valid moment Objects');
        }

        let dates = DateHelper.getDaysBetweenDates(startDate, endDate);

        //Note: in ISO Weekdays notation 1 is always Monday and 7 is always Sunday
        return dates.filter(date => date.isoWeekday() >= 6);
    },

    fromArray(dateArray) {
        if (!dateArray) {
            return null;
        }

        return dateArray.map(number => {
            if (number > 1000) return number;
            return ("0" + number).slice(-2);
        }).join('-');
    },

    datetimeFromArrays(dateArray, timeArray) {
        let time = '';
        if (timeArray) {
            const [hour, mins] = timeArray;
            time = ' ' + ('00'+hour).slice(-2) + ':'+ ('00'+mins).slice(-2);
        }

        const UTCTime = DateHelper.fromArray(dateArray) + time;
        return UTCTime;
    },

    buildFormattedDate(paramDate, format) {
        const language = getLanguage();
        const date = DateHelper.removeDot(moment(paramDate).locale(language).format(format), language);

        return toCamelCase(date);
    },

    getLocalsDate (date,locale){
        const months = {
            "es": (date) => date,
            "en": (date) => date,
            "nl": (date) => date.toString().toLowerCase(),
            "de": (date) => date,
            "fr": (date) => date,
            "pt": (date) => date.toString().toLowerCase()
        };

        return months[locale](date);
    },

    //DEPRECATE THIS ON R7
    getImmediateBussinessDay() {
        /**
         *  returns moment date => today or next business day depending the day and business hours
         *
         *  SPAIN PENINSULAR TIME
         *  Standard time zone: UTC/GMT +1 hours
         *  Daylight Saving Time:   +1 hour
         *  Madrid summer time (DST) starts on Sunday 26 March 2017, 02:00 Madrid standard time
         *  Madrid winter time starts on Sunday 29 October 2017, 03:00 Madrid daylight time
         *
         */
        const CLOSING_HOUR = 17;
        const CLOSING_MINUTE = 30;

        const spanishPeninsularTime = momentTz().tz('Europe/Madrid')

        let momentDate = moment();

        if (spanishPeninsularTime.day() === SATURDAY_INDEX_DAY) {
            momentDate.add(2, 'd');
        } else if (spanishPeninsularTime.day() === SUNDAY_INDEX_DAY) {
            momentDate.add(1, 'd');
        } else {
            // if is friday we add 3 days if not we just add 1 day
            const daysToAdd = spanishPeninsularTime.day() === 5 ? 3 : 3;

            if (
                spanishPeninsularTime.hour() > CLOSING_HOUR ||
                (spanishPeninsularTime.hour() === CLOSING_HOUR && spanishPeninsularTime.minute() >= CLOSING_MINUTE)
            ) {
                momentDate.add(daysToAdd, 'd');
            }
        }

        return momentDate.startOf('day');
    },

    getNextBussinessDay(isAvailableToday, currentDate) {
        /**
         *  returns moment date => today or next business day depending the day and business hours
         *
         *  SPAIN PENINSULAR TIME
         *  Standard time zone: UTC/GMT +1 hours
         *  Daylight Saving Time:   +1 hour
         *  Madrid summer time (DST) starts on Sunday 26 March 2017, 02:00 Madrid standard time
         *  Madrid winter time starts on Sunday 29 October 2017, 03:00 Madrid daylight time
         *
         */

        let momentCurrentDate = momentTz().tz('Europe/Madrid');

        if (currentDate !== false) {
            momentCurrentDate = moment(currentDate);
        }

        if (momentCurrentDate.day() === SATURDAY_INDEX_DAY) {
            momentCurrentDate.add(2, 'd');
        } else if (momentCurrentDate.day() === SUNDAY_INDEX_DAY) {
            momentCurrentDate.add(1, 'd');
        } else {
            if (!isAvailableToday) {
                const daysToAdd = momentCurrentDate.day() === 5 ? 3 : 1;

                momentCurrentDate.add(daysToAdd, 'd');
            }
        }

        return momentCurrentDate.startOf('day');
    },

    getStockLimitDate(initialDate, days = 90) {
        const limitDate = initialDate.clone().add(days, 'd');
        const limitDateDay = limitDate.day();
        if (limitDateDay === SATURDAY_INDEX_DAY) {
            limitDate.subtract(1, 'd');
        }
        if (limitDateDay === SUNDAY_INDEX_DAY) {
            limitDate.subtract(2, 'd');
        }

        return limitDate;
    },

    getValidDate(first, last, toValid) {
        if (!toValid) {
            return first;
        }

        if (first > toValid)
            return first;
        else if (last < toValid) {
            return last
        }

        return toValid;
    },

    getExcludedWeekends(limitDate) {
        const excludeDates = [];
        let tempDate = moment();

        while (tempDate.isSameOrBefore(limitDate.clone().add(7, 'days'))) {
            const dayOfWeek = tempDate.day();

            if (dayOfWeek === SUNDAY_INDEX_DAY || dayOfWeek === SATURDAY_INDEX_DAY) {
                excludeDates.push(tempDate.clone());
            }

            tempDate = tempDate.add(1, 'days');
        }

        return excludeDates;
    },

    getMinDateAvailable(isBeforeClosingTime = false, timestamp) {

        const time = timestamp ? moment(parseInt(timestamp)) : moment();
        const minDate = isBeforeClosingTime ? time.startOf('day') : time.startOf('day').add('1', 'day');

        return minDate;
    },

    toFormatDate(date, formatDate, format) {
        const momentDate = moment(date, formatDate);
        const language = getLanguage();
        let desiredFormat = DateFormatTypes[language][format];
        if (language == 'en' && format == 'DATE_FORMAT'){
            desiredFormat = 'MMM DD, YYYY';
        }
        return momentDate.format(desiredFormat);
    },

    getStartDayOfMonth(date, isDateTruncate) {
        if (isDateTruncate) {
            return moment(date);
        } else {
            return moment(date).startOf('month');
        }
    },

    getOnlyDateFormatted(date) {
        const language = getLanguage();
        const format = DATE_FORMAT_TYPES[language].DATE_FORMAT_WITH_SLASHES_DATETIME;
        return moment.utc(new Date(date)).format(format)
    },

    getDateWithMonthName (date) {
        return moment(date).locale(getLanguage()).format('LL')
    },

    getDateCamelCaseShortMonth(date, withPreposition = false) {
        let formattedDate;
        if (date) {
            const language = getLanguage()
            let preposition = ''
            if (withPreposition && ES.toLowerCase() === language) { preposition = ES_PREPOSITION }
            const day = DateHelper.removeDot(moment(date).locale(language).format(`DD ${preposition}`), language)
            const month = toCamelCase(DateHelper.removeDot(moment(date).locale(language).format('MMM YYYY'), language))
            formattedDate = `${day} ${month}`
        }
        else {
            formattedDate = '';
        }
        return formattedDate;
    }
};

module.exports = DateHelper;
