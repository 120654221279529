//@vendor
const moment = require('moment');
const Immutable = require('immutable');
const get = require('lodash/object/get');
const set = require('lodash/object/set');
const trim = require('lodash/string/trim');

//@constants
const actionTypes = require('constants/actionTypes');
const {
    HYPHEN,
    EURO_TEXT,
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_NULLED,
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_CONSOLIDATED,
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_PENDING,
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_PERIODIC,
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_OTHER,
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_EXTRAORDINARY,
    BROKER_ORDERS_AND_MOVEMENTS_PLAN_PENDING_MOVES,
    BROKER_JOINT_CONTRACTS_VALUE,
    BROKER_JOINT_CONTRACTS_FUND,
    BROKER_ORDER_STATE_EXECUTED,
    BROKER_ORDER_STATE_ANULATED,
    BROKER_ORDER_STATE_PENDING,
    BROKER_ORDER_STATE_MARKET,
    BROKER_ORDER_STATE_PARCIALY,
    BROKER_ORDERS_AND_MOVEMENTS_SALE_OPERATION,
    BROKER_ORDERS_AND_MOVEMENTS_BUY_OPERATION,
    BROKER_ORDERS_AND_MOVEMENTS_SALE_OPERATION_CONDITIONAL,
    BROKER_ORDERS_AND_MOVEMENTS_BUY_OPERATION_CONDITIONAL,
    DATE_FILTER_FORTNIGHTLY
} = require('constants/index');
// @utilities
const { hash } = require('utilities/hash');
const { composeIban } = require('utilities/ibanHelper');
const { formatText } = require('core/i18n').i18n;
const { getValidMarketName } = require('utilities/micCodeHelper');

const SELECT_ALL_MOVEMENTS = ' ';

const initialStateFilters = Immutable.fromJS({
    filtersClear: true,
    state: {
        isActive: false,
        value: SELECT_ALL_MOVEMENTS
    },
    date: {
        isActive: true,
        selected: DATE_FILTER_FORTNIGHTLY,
        from: moment().startOf('day').subtract('2', 'weeks').format(),
        to: moment().startOf('day').format()
    }
});

function generateInitialStateForContractRequest(actualFilters = initialStateFilters) {
    return {
        byId: null,
        byOrder: null,
        isFetching: true,
        success: false,
        filters: actualFilters,
        error: ''
    };
}

function countDecimals(number) {
    const decimals = number.toString().split('.')[1];
    return decimals ? decimals.length : 0;
}

const initialState = Immutable.fromJS({});

function prepareExecutionDetails(details) {
    return details.map(item => ({
        date: item.fechaLiquidacion,
        time: item.horaLiquidacion,
        grossSale: {
            amount: item.brutoLiquidacion.importe || 0,
            currency: item.brutoLiquidacion.divisa || EURO_TEXT,
            decimalsLength: 2
        },
        exchangeSale: {
            amount: item.cambioLiquidacion.numeroimportevalores || 0,
            currency: item.cambioLiquidacion.divisa || EURO_TEXT,
            decimalsLength: 3
        },
        titles: item.titulosLiquidacion || 0,
        canonSale: {
            amount: item.canonLiquidacion.importe || 0,
            currency: item.canonLiquidacion.divisa || EURO_TEXT,
            decimalsLength: 2
        },
        dateTime: {
            amount: item.correoLiquidacion.importe || 0,
            currency: item.correoLiquidacion.divisa || EURO_TEXT,
            date: item.correoLiquidacion.fechaLiquidacion || null,
            time: item.correoLiquidacion.horaLiquidacion || null,
            decimalsLength: countDecimals(item.correoLiquidacion.importe)
        },
        liquidSale: {
            amount: item.liquidoLiquidacion.importe || 0,
            currency: item.liquidoLiquidacion.divisa || EURO_TEXT,
            decimalsLength: 2
        },
        commissionSale: {
            amount: item.comisionLiquidacion.importe || 0,
            currency: item.comisionLiquidacion.divisa || EURO_TEXT,
            decimalsLength: 2
        },
        totalExpensesSale: {
            amount: item.totalGastosLiquidacion.importe || 0,
            currency: item.totalGastosLiquidacion.divisa || EURO_TEXT,
            decimalsLength: 2
        },
        taxAmount : !!item.importeImpuesto && !!item.importeImpuesto.importe ? (
            {
                amount: item.importeImpuesto.importe,
                currency: item.importeImpuesto.divisa || EURO_TEXT,
                decimalsLength: 2
            }
        ): null,
    }));
}

function isSameState(state1, state2) {
    switch (state1) {
        case 'E':
            return state2 === BROKER_ORDER_STATE_EXECUTED
        case 'A':
            return state2 === BROKER_ORDER_STATE_ANULATED
        case 'P':
            return state2 === BROKER_ORDER_STATE_PENDING
        case 'X':
            return state2 === BROKER_ORDER_STATE_MARKET
        case 'Y':
            return state2 === BROKER_ORDER_STATE_PARCIALY
        default:
            return true;
    }
}

function prepareMovements (movements, contractId, productNumber, productSubtype, fundCode, mapper, state) {
    let byId = {};
    let byOrder = [];
    const filteredState = state.getIn([contractId, 'filters', 'state']).toJS();

    movements.forEach((movement, key) => {
        const orderState = get(movement, 'situacionOrden', '').trim();
        if (!filteredState.isActive || ( filteredState.isActive && isSameState(filteredState.value, orderState))) {
            const mappedMovement = mapper(movement, key);
            const id = mappedMovement.id;

            byId[id] = {
                id,
                contractNumber: contractId,
                productNumber,
                productSubtype,
                fundCode,
                details: {
                    isFetching: false,
                    success: false,
                    error: '',
                    data: {}
                }
            }

            Object.assign(byId[id], mappedMovement.props);

            byOrder.push(id);
        }
    });

    return {
        byId,
        byOrder
    };
}

function getDescription(type) {
    if (type.trim() === BROKER_ORDERS_AND_MOVEMENTS_BUY_OPERATION) {
        return BROKER_ORDERS_AND_MOVEMENTS_BUY_OPERATION_CONDITIONAL;
    } else if (type.trim() === BROKER_ORDERS_AND_MOVEMENTS_SALE_OPERATION) {
        return BROKER_ORDERS_AND_MOVEMENTS_SALE_OPERATION_CONDITIONAL;
    }
}


function mergeCancellableMovements(details, cancellableMovements) {
    const cancellableExtraordinaryContributions = get(cancellableMovements, 'listaAportExtraordinarias.aportExtraordinaria', []);
    const mergedMovPendienteDet = get(details, 'listaMovPendientesDet.movPendienteDet', []).map(detail => {
        const cancellableExtraordinaryContribution = cancellableExtraordinaryContributions.find(contribution => {
            return (moment(contribution.fechaSolScroll, 'DD-MM-YYYY').isSame(moment(detail.fechSolScroll, 'YYYY-MM-DD'), 'day')) && (contribution.numeroSecuencialOP === detail.numSecOpera);
        });

        const get = (object, key) => !!object ? object[key] : undefined

        return Object.assign({}, detail, {
            cancellable: !!cancellableExtraordinaryContribution,
            cancelDetails: !!cancellableExtraordinaryContribution ? {
                indSwitch: get(cancellableMovements, 'indSwitch'),
                fechaSolScroll: get(cancellableExtraordinaryContribution, 'fechaSolScroll'),
                numeroSecuencialOp: get(cancellableExtraordinaryContribution, 'numeroSecuencialOP'),
                codigoProducto: get(cancellableMovements, 'codigoProducto'),
                codigoSubtipoProducto: get(cancellableMovements, 'codigoSubtipoProducto'),
                literalOpcion: get(cancellableExtraordinaryContribution, 'literalOpcion'),
                nombreTitular: get(cancellableMovements, 'nombreTitular'),
                planDescripcion: get(cancellableMovements, 'planDescripcion'),
            } : {}
        });
    });

    const mergedData = Object.assign({}, details);
    set(mergedData, 'listaMovPendientesDet.movPendienteDet', mergedMovPendienteDet);

    return mergedData;
}

function processedDetails(details, movementType, cancellableMovements, isConditionatedOrder) {
    switch (movementType) {
        case BROKER_JOINT_CONTRACTS_VALUE:
            if (isConditionatedOrder) {
                return {
                    orderNumber: details.codigoSubscripcion,
                    orderType: get(details, 'descripcionOrdenBolsa', HYPHEN).trim(),
                    market: get(details, 'descripcionBolsa', HYPHEN).trim(),
                    date: details.fechaCreacion,
                    hour: '',
                    dateLimit: details.fechaExpiracion,
                    titles: details.numeroTitulosOrdenados,
                    type: getDescription(details.descripcionOperacion),
                    executionDetails: [],
                    price: {
                        currency: get(details, 'importeNominal.divisa') || EURO_TEXT,
                        amount: get(details, 'importeNominal.numeroimportevalores') || 0
                    },
                    rating: {
                        currency: get(details, 'importeNominal.divisa') || EURO_TEXT,
                        amount: get(details, 'importeNominal.importe') || 0
                    },
                    situationCode: details.codigoSituacion || '',
                    stockOperationNumber: details.numeroOperacionBolsa || '',
                    condition: details.descripcionCondicion || HYPHEN,
                    conditionValue: details.precioPorcentajeCondicion || HYPHEN,
                    valueName: details.nombreValor || HYPHEN,
                    limitedAmount: {
                        amount: details.titulosCambioOrden || HYPHEN,
                        currency: EURO_TEXT
                    },
                }
            } else {
                const market = details.bolsa ? getValidMarketName(details.bolsa) : HYPHEN;
                return {
                    orderNumber: details.numOrden,
                    orderType: get(details, 'tipoOrden', HYPHEN).trim(),
                    market,
                    date: details.fechaOperacion,
                    hour: details.horaOPeracion,
                    dateLimit: details.fechaLimite,
                    titles: details.titulosOrdenados,
                    type: details.tipoOrden,
                    executionDetails: prepareExecutionDetails(
                        get(details, 'listaDetalleOrden.detalleOrden') || []
                    ),
                    price: {
                        currency: get(details, 'importeNominal.divisa') || EURO_TEXT,
                        amount: get(details, 'importeNominal.numeroimportevalores') || 0
                    },
                    rating: {
                        currency: get(details, 'importeNominal.divisa') || EURO_TEXT,
                        amount: get(details, 'importeNominal.importe') || 0
                    },
                    limitedAmount: {
                        amount: get(details, 'importeLimitada.numeroimportevalores', HYPHEN),
                        currency: get(details, 'importeLimitada.divisa', EURO_TEXT)
                    },
                }
            }

        case BROKER_ORDERS_AND_MOVEMENTS_PLAN_PERIODIC:
            return {
                dateRevaluate: details.fecReval,
                withRevaluation: details.revalIpc === 'S',
                qttyRevaluation: details.datoRev,
                typeRevaluation: details.tiReval
            };
        case BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_CONSOLIDATED:
            return {
                associatedAccount: details.cuentaCargoAbonoIBAN.codbban,
                stateDescription: details.descEstado,
                operationDescription: details.descOperacion,
                operationDate: details.fechaOperacion,
                valueDate: details.fechaValor,
                import: {
                    amount: get(details, 'importeMovimiento.valorliquidativo'),
                    currency: get(details, 'importeMovimiento.divisa')
                },
                shares: get(details, 'numeroParticipaciones', HYPHEN).trim(),
                referenceNumber: details.refTraspSinCosteFis,
                originManager: details.nomGestoraOrigen,
                destinataryManager: details.nomGestoraDestino,
                destinataryAccount: composeIban(details.cuentaCargoAbonoIBAN),
                holdbacks: {
                    import: {
                        amount: get(details, 'retenciones.retencionFondo.importe', undefined),
                        currency: get(details, 'retencions.retencionFondo.divisa', EURO_TEXT)
                    },
                    percentage: get(details, 'retenciones.porcentajeRetencion', 0)
                },
                charge: get(details, 'totImportComision', '0,00').trim()
            }
        case BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_PENDING:
            return {
                associatedAccount: details.cuentaAsociada.numerodecuenta,
                stateDescription: details.descEstado,
                operationDescription: details.descTipoMovimiento,
                operationDate: details.fechaSolicitud,
                valueDate: details.fechaValor,
                shares: details.importeParticipaciones,
                referenceNumber: details.referenciaTraspaso,
                originManager: details.nomGestoraOrigen,
                destinataryManager: details.nomGestoraDestino,
                destinataryAccount: composeIban(details.iban),
                charge: '0,00'
            }
        case BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_NULLED:
            return {
                associatedAccount: composeIban(details.ctaAsociada),
                stateDescription: HYPHEN,
                operationDescription: details.tipoOperacion,
                operationDate: details.fechaSolicitud,
                valueDate: details.fechaValorBancario,
                shares: details.participaciones,
                referenceNumber: HYPHEN,
                originManager: HYPHEN,
                destinataryManager: HYPHEN,
                destinataryAccount: HYPHEN,
                charge: '0,00'
            }
        case BROKER_ORDERS_AND_MOVEMENTS_PLAN_PENDING_MOVES:
            if (__CONTACT_CENTER__ && cancellableMovements) {
                return mergeCancellableMovements(details, cancellableMovements);
            }
            return details;
    }
}

const valueMovementsMapper = movement => ({
    id: hash([movement.numOrden, movement.fecha, movement.operationTypeDescTransl]),
    props: {
        number: movement.numOrden,
        date: movement.fecha,
        description: movement.descripcionValor,
        state: trim(movement.orderStateTransl),
        rawState: movement.situacionOrden.trim(),
        type:
            movement.numOrden.length === 6 || movement.isOrdenCondicionada
                ? getDescription(movement.operationTypeDescTransl)
                : movement.operationTypeDescTransl,
        movementType: BROKER_JOINT_CONTRACTS_VALUE,
        isConditionated: movement.isOrdenCondicionada
    }
});

const getTransformedDate = dateStr => {
    if (dateStr) {
        const dateParts = dateStr.split('/');

        return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    }
    return dateStr;
};

const fundNulledMovements = movement => ({
    id: hash([movement.codOperacion, movement.fechaSolicitud, movement.numSecuenOper]),
    props: {
        dateRequested: movement.fechaSolicitud,
        dateValue: getTransformedDate(movement.fecValorEditada),
        sequenceNumber: movement.numSecuenOper,
        operationCode: movement.codOperacion,
        import: {
            amount: get(movement, 'impPetDivCliente.importe') || 0,
            currency: get(movement, 'impPetDivCliente.divisa') || EURO_TEXT
        },
        state: BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_NULLED,
        stateLabel: formatText('broker-brokerOrdersAndMovementsFundMovementsNulled'),
        movementType: BROKER_JOINT_CONTRACTS_FUND,
        shares: HYPHEN, //@hardcoded: TBD
        liquidativeValue: { //@hardcoded: TBD
            amount: HYPHEN,
            currency: EURO_TEXT
        }
    }
});

const fundConsolidatedMovements = movement => ({
    id: hash([
        movement.codigoOperacion,
        movement.fechaSolicitud,
        movement.numeroSecuencialOperacion
    ]),
    props: {
        dateRequested: movement.fechaSolicitud,
        dateValue: getTransformedDate(movement.fechaValorEditada),
        dateOriginal: movement.fechaValorEditada,
        sequenceNumber: movement.numeroSecuencialOperacion,
        operationCode: movement.codigoOperacion,
        import: {
            amount: get(movement, 'importeOperacion.importe', 0),
            currency: get(movement, 'importeOperacion.divisa', EURO_TEXT)
        },
        state: BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_CONSOLIDATED,
        stateLabel: formatText('broker-brokerOrdersAndMovementsFundMovementsConsolidated'),
        movementType: BROKER_JOINT_CONTRACTS_FUND,
        shares: get(movement, 'participacionesOperaEditada', HYPHEN).trim(),
        liquidativeValue: {
            amount: get(movement, 'valorLiquidativo.valorliquidativo', HYPHEN),
            currency: get(movement, 'valorLiquidativo.divisa', EURO_TEXT),
            decimalsLength: 3
        }
    }
});

const fundPendingMovements = movement => ({
    id: hash([movement.codigoOperacion, movement.fechaSolicitud, movement.numSecuOperacion]),
    props: {
        annullable: movement.annullable,
        dateRequested: movement.fechaSolicitud,
        dateValue: getTransformedDate(movement.fechaValorEditada),
        dateOriginal: movement.fechaValorEditada,
        sequenceNumber: movement.numSecuOperacion,
        operationCode: movement.codigoOperacion,
        import: {
            amount: get(movement, 'importePeticionDivisaCliente.importe') || 0,
            currency: get(movement, 'importePeticionDivisaCliente.divisa') || EURO_TEXT
        },
        state: BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_PENDING,
        stateLabel: formatText('broker-brokerOrdersAndMovementsFundMovementsPending'),
        movementType: BROKER_JOINT_CONTRACTS_FUND,
        requestType: movement.tipoPeticion,
        stateCode: movement.codigoEstado,
        units: movement.participacionesOrden,
        shares: movement.participacionesOrden,
        liquidativeValue: { //@hardcoded: TBD
            amount: HYPHEN,
            currency: EURO_TEXT
        }

    }
});

const PLAN_POSSIBLE_STATES = {
    AC: formatText('broker-ordersAndMovementsActivityPlanStateActive'),
    IN: formatText('broker-ordersAndMovementsActivityPlanStateInactive'),
    SU: formatText('broker-ordersAndMovementsActivityPlanStateSuspended'), //@hardcoded: check if SU is the real value that comes from service when dealing with suspended orders
    V: formatText('broker-ordersAndMovementsActivityPlanStatePending'),
    E: formatText('broker-ordersAndMovementsActivityPlanStateComunicated'),
    S: formatText('broker-ordersAndMovementsActivityPlanStatePendingLiq'),
    W: formatText('broker-ordersAndMovementsActivityPlanStateOrder')
};

const planPeriodicMovements = movement => ({
    id: hash([movement.tipoPlanAport, movement.fecAltaPlanaport, movement.timealtaTabla]),
    props: {
        date: movement.fecAltaPlanaport,
        dateLast: movement.fecUltEnvExtracto,
        dateNext: movement.fecSigReciEdi,
        operation: movement.tipoPlanAport,
        periodicity: movement.litPeriodi,
        periodicityText: movement.periodicidad,
        numCuotasPeriodi: movement.numCuotasPeriodi,
        import: {
            amount: trim(movement.importeAportPlan) || 0,
            currency: movement.divisaImporteAportPlan || EURO_TEXT
        },
        indRevalAnul: movement.indRevalAnul,
        stateCode: movement.estatusPlanAport,
        state: PLAN_POSSIBLE_STATES[movement.estatusPlanAport],
        timeStamp: movement.timealtaTabla,
        movementType: BROKER_ORDERS_AND_MOVEMENTS_PLAN_PERIODIC
    }
});

const planOtherMovements = movement => ({
    id: hash([
        movement.fechaValorLiquidativo,
        movement.importeDelMovimiento,
        movement.nombreDeLaOperac
    ]),
    props: {
        date: movement.fechaValorLiquidativo,
        operation: movement.nombreDeLaOperac,
        operationCode: movement.codNombreDeLaOperac,
        import: {
            amount: movement.importeDelMovimiento || 0,
            currency: movement.divisaDelMovimiento || EURO_TEXT
        },
        state: movement.estatusPlanAport, // @hardcoded: not coming from API
        movementType: BROKER_ORDERS_AND_MOVEMENTS_PLAN_OTHER
    }
});

const planExtraordinaryMovements = movement => ({
    id: hash([movement.fecValorLiq, movement.fechSolScroll, movement.estadoOp]),
    props: {
        date: movement.fecValorLiq,
        operation: '-',
        import: {
            amount: movement.importe.importe || 0,
            currency: movement.importe.divisa || EURO_TEXT
        },
        state: movement.estadoOp, // @hardcoded: not coming from API
        movementType: BROKER_ORDERS_AND_MOVEMENTS_PLAN_EXTRAORDINARY
    }
});

/**
 *
 * @param {Object} movement
 * @param {string | number} id
 */
const planPendingMovements = (movement, id) => ({
    id: id + '', // @hardcoded (franco.montenegro) The service doesn't provide any info to be used as id
    props: {
        operation: movement.nombreDeLaOperacion
            ? (movement.nombreDeLaOperacion + '').trim()
            : HYPHEN,
        movementType: BROKER_ORDERS_AND_MOVEMENTS_PLAN_PENDING_MOVES,
        operationCode: movement.codDeOperacion,
        operationsCount: movement.contadorDeOperaciones
    }
});

function brokerContractActivities(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITIES_REQUEST:
            let filters;
            if (state.getIn([action.payload.contractId, 'filters'])) {
                filters = state.getIn([action.payload.contractId, 'filters']);
            }
            return state.mergeDeep({
                [action.payload.contractId]: generateInitialStateForContractRequest(filters)
            });
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITIES_SUCCESS:
            let preparedMovements;
            const movement = [
                { listPath: 'payload.data.listaOrdenes.orden', mapper: valueMovementsMapper },
                {
                    listPath: 'payload.data.listaMvtosAnulados.movimientosAnulados',
                    mapper: fundNulledMovements
                },
                {
                    listPath: 'payload.data.listaMovimientosPendientes.movimientosPendientes',
                    mapper: fundPendingMovements
                },
                {
                    listPath: 'payload.data.listaMovimientos.movimientos',
                    mapper: fundConsolidatedMovements
                },
                {
                    listPath: 'payload.data.listaPlanAportaciones.planAportacion',
                    mapper: planPeriodicMovements
                },
                { listPath: 'payload.data.movimientos.movimiento', mapper: planOtherMovements },
                {
                    listPath: 'payload.data.listaMovPendientesDet.movPendienteDet',
                    mapper: planExtraordinaryMovements
                },
                {
                    listPath: 'payload.data.movPendientes.movPendiente',
                    mapper: planPendingMovements
                }
            ].find(({ listPath }) => !!get(action, listPath));

            let newState;

            if (!movement) {
                newState = state.mergeDeep({
                    [action.payload.contractId]: {
                        isFetching: false,
                        success: true,
                        byId: {},
                        byOrder: []
                    }
                });
            } else {
                preparedMovements = prepareMovements(
                    get(action, movement.listPath),
                    action.payload.contractId,
                    action.payload.productNumber,
                    action.payload.productSubtype,
                    action.payload.data.codigoFondo,
                    movement.mapper,
                    state
                );

                newState = state.mergeDeep({
                    [action.payload.contractId]: {
                        isFetching: false,
                        success: true,
                        byId: preparedMovements.byId,
                        byOrder: preparedMovements.byOrder
                    }
                });
            }

            return newState;
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITIES_FAILURE:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    isFetching: false,
                    error: action.payload.error
                }
            });
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITY_DETAILS_REQUEST:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    byId: {
                        [action.payload.activityId]: {
                            details: {
                                isFetching: true
                            }
                        }
                    }
                }
            });
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITY_DETAILS_SUCCESS:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    byId: {
                        [action.payload.activityId]: {
                            details: {
                                isFetching: false,
                                success: true,
                                error: false,
                                data: processedDetails(
                                    action.payload.data,
                                    action.payload.movementType,
                                    action.payload.cancellableMovements,
                                    action.payload.isConditionatedOrder
                                )
                            }
                        }
                    }
                }
            });
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITY_DETAILS_FAILURE:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    byId: {
                        [action.payload.activityId]: {
                            details: {
                                isFetching: false,
                                error: action.payload.error
                            }
                        }
                    }
                }
            });
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITIES_SET_FILTER:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    filters: Object.assign({}, action.payload.filter, { filtersClear: false })
                }
            })
        case actionTypes.BROKER_GET_CONTRACT_ACTIVITIES_CLEAR_FILTER:
            return state.mergeDeep({
                [action.payload.contractId]: {
                    filters: initialStateFilters
                }
            });
        default:
            return state;
    }
}

module.exports = brokerContractActivities;
