// @vendors
// const crypto = require('crypto');
const Storage = require('utilities/storage');
import { KJUR } from 'jsrsasign'
var jsMd5 = require('js-md5');

const hash = input => {
    if (Array.isArray(input)) {
        input = input.reduce((prev, next) => (prev.toString().concat(next)))
    }
    let response = input.split('').reduce((prevHash, currVal) =>
    ((prevHash << 5) - prevHash) + currVal.charCodeAt(0), 0);

    return Math.abs(response).toString();
};

const sha512 = word => {
    const md = new KJUR.crypto.MessageDigest({alg: "sha512"}); // sjcl supports sha256 only
    return md.digestString(word)
}

const sha256 = word => {
    const md = new KJUR.crypto.MessageDigest({alg: 'sha256'});
    return md.digestString(word)
}

const md5 = word => {
    return jsMd5(word);
}

const getPersonTypeAndCode = () => {
    let unhashedToken;
    let response = {
        type: '',
        f: ''
    }

    try {
        const token = Storage.cookies.get('tokenCredential');
        if (!token){
            return response;
        }
        unhashedToken = JSON.parse(atob(token));
        return {
            type: unhashedToken.tipoPersona,
            f: unhashedToken.codigoPersona
        };
    } catch (e) {
        return response;
    }
};

function getRandomString(length=10) {
    let randomString= '';
    let characters= 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  }

const getPersonTypeAndCodeHashed = () => {
    const { type, f } = getPersonTypeAndCode();
    return md5(`${ type }${ String('000000000' + f).slice(-9) }`);
};

const getPersonTypeAndCodeSaltedHashed = () => {
    const { type, f } = getPersonTypeAndCode();
    const saltValue = getRandomString(16);
    const password = `${ type }${ String('000000000' + f).slice(-9) }`;
    return sha256(password + saltValue);
};

const uniqueID = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
};

module.exports = {
    getPersonTypeAndCode,
    getPersonTypeAndCodeHashed,
    getPersonTypeAndCodeSaltedHashed,
    hash,
    md5,
    sha256,
    sha512,
    uniqueID
};
