// @ vendor
const Immutable = require('immutable');
const moment = require('moment')
// @ constants
const actionTypes = require('constants/actionTypes');
// @ utilities
const DateHelper = require('utilities/dateHelper');

const {
    BROKER_HIRE_PENSION_PLAN_ANNUAL_INCREMENT_PERCENTAGE,
    BROKER_CONTRIBUTION_PLAN_MAXIMUN_PERCENTAGE_VALUE,
    BROKER_CONTRIBUTION_PLAN_MINIMUM_PERCENTAGE_VALUE,
    BROKER_HIRE_PENSION_PLAN_PERIODIC_CONTRIBUTION,
    BROKER_HIRE_PENSION_PLAN_CONTRIBUTION,
    BROKER_HIRE_PENSION_PLAN_MONTHLY,
    EURO_TEXT,
    MAX_CONTRIBUTION_PLANS,
} = require('constants/index');

const setInitialState = () =>
    Immutable.fromJS({
        accountId: '',
        annualIncrement: '',
        annualIncrementPercentage: '',
        availableAccounts: Immutable.List(),
        initialContribution: '',
        interveners: Immutable.Map(),
        formatedInterveners: {},
        periodicContribution: false,
        periodicity: BROKER_HIRE_PENSION_PLAN_MONTHLY,
        periodicityAmount: '',
        periodicityAmountMinimum: '',
        periodicityDate: moment(),
        selectedIntervener: '',
        selectedIntervenerLabel: '',
        valid: false,
        isPercentageInputAvailable: false,
        errorFlags: {
            accountValid: true,
            accountBalanceValid: true,
            initialContributionValid: true,
            initialContributionValidBalance: true,
            intervenerValid: true,
            periodicityAmountNotError: true,
            initialContributionBetweenLimits: true,
            annualIncrementPercentageValid: true,
            annualIncrementPercentageNotError: true,
            annualIncrementPercentageNotEmpty: true,
            revAnualNotError: true,
        },
        contributionQuestion: {
            question: 'brokerHirePlans-contributionQuestion',
            answer: BROKER_HIRE_PENSION_PLAN_CONTRIBUTION,
            options: [
                {   id: BROKER_HIRE_PENSION_PLAN_CONTRIBUTION,
                    description: 'brokerHirePlans-contributionQuestionOption1'
                },
                {   id: BROKER_HIRE_PENSION_PLAN_PERIODIC_CONTRIBUTION,
                    description: 'brokerHirePlans-contributionQuestionOption2'
                }
            ]
        }
    });

// TODO: Verify if periodicity date needs some kind of validation, and if initial contribution should be =< account balance
const validateStep = (state, hireMinimumAmount, hireMinimumCurrency, accountBalance) => {
    const initialContribution = state.get('initialContribution');
    const periodicContribution = state.get('periodicContribution')
    const accountValid = !!state.get('accountId');
    const accountBalanceAmount = accountBalance ? accountBalance.get('amount') : 0;
    const accountBalanceCurrency = accountBalance ? accountBalance.get('currency') : EURO_TEXT;
    const isEuroContribution = hireMinimumCurrency === EURO_TEXT;
    const minimumBalance = hireMinimumCurrency !== accountBalanceCurrency || accountBalanceAmount >= initialContribution;

    const isInitialContributionNotEmpty = initialContribution !== '';
    const isInitialContributionHigherThanBalance = initialContribution > accountBalanceAmount;

    const isInitialContributionHigherThanAnualLimit = initialContribution > MAX_CONTRIBUTION_PLANS;

    const periodicityAmount = state.get('periodicityAmount');
    const periodicityAmountMinimum = state.get('periodicityAmountMinimum');
    const isPeriodicityAmountEmpty = periodicityAmount === '';
    const isPeriodicityAmountError = periodicContribution && (isPeriodicityAmountEmpty || periodicityAmount < periodicityAmountMinimum);

    const isRevAnualError = periodicContribution && state.get('annualIncrement') === '';
    const annualIncrementPercentage = state.get('annualIncrementPercentage');
    const isPercentageInputAbailable = state.get('isPercentageInputAvailable');
    const isAnnualIncrementPercentageEmpty = isPercentageInputAbailable && annualIncrementPercentage === '';
    const isAnnualIncrementPercentageError = isPercentageInputAbailable && (( annualIncrementPercentage > BROKER_CONTRIBUTION_PLAN_MAXIMUN_PERCENTAGE_VALUE) || ( annualIncrementPercentage < BROKER_CONTRIBUTION_PLAN_MINIMUM_PERCENTAGE_VALUE));
    const isAnnualIncrementPercentageValid = !isAnnualIncrementPercentageError && !isAnnualIncrementPercentageEmpty;
    const isCC = __CONTACT_CENTER__
    const errorFlags = Immutable.Map({
        accountValid,
        accountBalanceValid: isCC || (accountBalanceAmount > 0 && minimumBalance),
        initialContributionValid: isCC || (isEuroContribution && initialContribution >= hireMinimumAmount && isInitialContributionNotEmpty),
        initialContributionValidBalance: accountBalanceCurrency === EURO_TEXT && !isInitialContributionHigherThanBalance,
        periodicityAmountNotError: !isPeriodicityAmountError,
        annualIncrementPercentageNotError: !isAnnualIncrementPercentageError,
        annualIncrementPercentageNotEmpty: !isAnnualIncrementPercentageEmpty,
        annualIncrementPercentageValid: isAnnualIncrementPercentageValid,
        revAnualNotError: !isRevAnualError,
        initialContributionBetweenLimits: !isInitialContributionHigherThanAnualLimit,
    });
    const valid = errorFlags.every(flag => flag);

    return state.merge({
        valid,
        isPercentageInputAbailable,
        errorFlags
    });
};

const parseInterveners = interveners => {
    let byId = {};
    interveners.forEach(intervener => {
        byId[intervener.numeroPersona] = {
            name: intervener.nombre,
            nameTXT: intervener.nombreTXT,
            type: intervener.intervencionTXT,
            interventionType: intervener.intervencion,
            interventionForm: intervener.formaIntervencion,
            interventionFormTXT: intervener.formaIntervencionTXT,
            personNumber: intervener.numeroPersona,
            personType: intervener.tipoPersona
        };
    });

    return byId;
};

const formatInterveners = interveners => {
    return interveners.map(immIntervener => ({
        value: immIntervener.get('personNumber'),
        label: immIntervener.get('name')
    }));
};

const brokerHirePensionPlanReduscerStep1 = (state = setInitialState(), action) => {
    switch (action.type) {
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_ACCOUNTS_SUCCESS:
            const { immAccounts, accounts } = action.payload;
            const availableAccounts = accounts
                .map(account => {
                    const contractNumber = account.contratoUnificadoPart.numerodecontrato;
                    const product = account.contratoUnificadoPart.producto;
                    const immAccount = immAccounts.find(
                        accountId =>
                            contractNumber === accountId.get('contractNumber') &&
                            product === accountId.get('product')
                    );
                    return immAccount ? immAccount.get('accountId') : null;
                });
            const availableAccountsFiltered = availableAccounts.filter(account => !!account);
            return state.merge({
                availableAccounts: availableAccountsFiltered
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_FETCH_INTERVENERS_SUCCESS:
            const parsedInterveners = Immutable.fromJS(parseInterveners(action.payload.interveners));
            const formatedInterveners = formatInterveners(parsedInterveners);
            return state.set('interveners', parsedInterveners)
                        .set('formatedInterveners', formatedInterveners)
        case actionTypes.BROKER_HIRE_PENSION_PLAN_CONTRIBUTION_ANSWER:
            return state.mergeDeep({
                periodicContribution: action.payload.value === BROKER_HIRE_PENSION_PLAN_PERIODIC_CONTRIBUTION,
                contributionQuestion: {
                    answer: action.payload.value
                },
                isPercentageInputAvailable: false,
                errorFlags: {
                    periodicityAmountNotError: true,
                    annualIncrementPercentageNotError: true,
                    annualIncrementPercentageNotEmpty: true,
                    annualIncrementPercentageValid: true,
                    revAnualNotError: true
                },
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_ACCOUNT_NUMBER:
            return state.mergeDeep({
                accountId: action.payload.accountId,
                errorFlags: {
                    accountValid: true,
                    accountBalanceValid: true
                }
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT_PERCENTAGE:
            return state.mergeDeep({
                annualIncrementPercentage: action.payload.percentage
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_ANNUAL_INCREMENT:
            const isPercentageAvailable = action.payload.increment === BROKER_HIRE_PENSION_PLAN_ANNUAL_INCREMENT_PERCENTAGE
            const isPercetageError = isPercentageAvailable ? state.getIn(['errorFlags', 'annualIncrementPercentageError']) : false
            const isEmptyError = isPercentageAvailable ? state.getIn(['errorFlags', 'annualIncrementPercentageEmpty']) : false
            return state.mergeDeep({
                annualIncrement: action.payload.increment,
                annualIncrementPercentage: '',
                isPercentageInputAvailable: isPercentageAvailable,
                errorFlags: {
                    annualIncrementPercentageError: isPercetageError,
                    annualIncrementPercentageEmpty: isEmptyError,
                }
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_DATE:
            return state.mergeDeep({
                periodicityDate: action.payload.date
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_INITIAL_CONTRIBUTION:
            return state.mergeDeep({
                initialContribution: action.payload.amount,
                errorFlags: {
                    initialContributionValid: true,
                    initialContributionNotEmpty: true,
                    initialContributionValidBalance: true
                }
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_INTERVENER:
            return state.mergeDeep({
                selectedIntervener: action.payload.intervener.value,
                selectedIntervenerLabel: action.payload.intervener.label,
                errorFlags: {
                    intervenerValid: true
                }
            });
        case actionTypes.FETCH_BROKER_PENSION_PLAN_SUCCESS:
            const pensionPlan = action.payload.pensionPlans.rows[0];
            const securityDetails = action.payload.securityDetails[0];
            const customMinimumAdditionalPurchaseAmount = Number(pensionPlan.CustomMinimumAdditionalPurchaseAmount) || 0;
            const customMinimumAdditionalPurchaseAmountUnit = Number(pensionPlan.CustomMinimumAdditionalPurchaseAmountUnit) || 0;
            const netAssetValueAmount = Number(securityDetails.TrailingPerformance[0].Return[0].Value) || 0;
            const periodicityAmountMinimum = customMinimumAdditionalPurchaseAmount === 0 ? netAssetValueAmount * customMinimumAdditionalPurchaseAmountUnit : customMinimumAdditionalPurchaseAmount;
            return state.mergeDeep({
                periodicityAmountMinimum: periodicityAmountMinimum,
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY_AMOUNT:
            return state.mergeDeep({
                periodicityAmount: action.payload.amount,
                errorFlags: {
                    periodicityAmountError: false
                }
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_SET_PERIODICITY:
            const periodicity = action.payload.periodicity;
            const minimum = action.payload.minimum;
            return state.merge({
                periodicity: periodicity,
                periodicityAmountMinimum: minimum
            });
        case actionTypes.BROKER_HIRE_PENSION_PLAN_VALIDATE_STEP:
            const { hireMinimumAmount, hireMinimumCurrency, accountBalance } = action.payload;
            return validateStep(state, hireMinimumAmount, hireMinimumCurrency, accountBalance);
        case actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS:
            const isBeforeClosingTime = action.payload.data.isBeforeClosingTime;
            const timestamp = action.payload.data.timeResponse ? action.payload.data.timeResponse.timestamp : null;
            const minDate = DateHelper.getMinDateAvailable(isBeforeClosingTime, timestamp);

            return state.merge({
                periodicityDate: minDate
            });
        default:
            return state;
    }
};

module.exports = brokerHirePensionPlanReduscerStep1;
