// @ vendor
const Immutable = require('immutable');
// @ reducers
const myProfileKeysSignatureStep1 = require('./myProfileKeysSignature/myProfileKeysSignatureStep1');
// @ constants
const actionTypes = require('constants/actionTypes');
import { 
    BUTTON_CLICK,
    ACTION_EVENT_VIEW
} from 'constants/tagging';
const { FIRST_STEP, REGENERATE_SIGNATURE_LOW_BIOCATCH_SCORE, REGENERATE_SIGNATURE_HIGH_BIOCATCH_SCORE, REGENERATE_SIGNATURE_MAX_NUMBER_RETRIES } = require('constants/index');
// @ utilities
import { recordLinkV2 } from 'utilities/tagging';

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            myProfileKeysSignatureStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        biocatchError: false,
        biocatchErrorReceived: '',
        biocatchScore: '',
        errorReceived: '',
        isFetching: false,
        isFetchingBiocatchScore: false,
        inProgress: false,
        retryCounter: 0,
        retriesLeft: true,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        cmcContract: {
            lastName: '',
            secondLastName: '',
            contract: {
                contractNumber: '',
                product: ''
            },
            dateOfBirth: '',
            name: '',
            organizationName: '',
            uid: ''
        }
    });
}

function updateStep(steps, action) {
    return steps.update(FIRST_STEP, (step) => {
        return myProfileKeysSignatureStep1(step, action);
    })
}


function myProfileKeysSignatureReducer(state = setInitialState(), action) {
    let partialSteps;
    let visibleStep;
    switch (action.type) {
        case actionTypes.KEY_SIGNATURE_CHANGE_RETRY_PROCCESS:
            return state.merge({
                errorReceived: '',
                inProgress: true,
                submitSuccessful: false,
                retryCounter: state.get('retryCounter') + 1,
                retriesLeft: state.get('retryCounter') + 1 < REGENERATE_SIGNATURE_MAX_NUMBER_RETRIES
            })
        case actionTypes.KEY_SIGNATURE_CHANGE_RESET:
            return setInitialState();
        case actionTypes.KEY_SIGNATURE_CHANGE_VALIDATE_STEP:
            switch (state.get('visibleStep')) {
                case 1:
                    recordLinkV2({
                        categoriaEvento: window.utag_data.nombrePagina,
                        accionEvento: BUTTON_CLICK,
                        etiquetaEvento: ACTION_EVENT_VIEW
                    })
                    partialSteps = updateStep(state.get('steps'), action);
                    visibleStep = state.get('steps').get(0).get('equals') ? 2 : 1;
                    break;
                case 2:
                    visibleStep = 2;
                    break;
                default:
                    visibleStep = 1;
            }
            return state.merge({
                visibleStep, 
                partialSteps
            });
        case actionTypes.KEY_SIGNATURE_UPDATE_FIRST_FIELD:
        case actionTypes.KEY_SIGNATURE_UPDATE_SECOND_FIELD:
            partialSteps = updateStep(state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                inProgress: true
            });
        case actionTypes.KEY_SIGNATURE_CHANGE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.KEY_SIGNATURE_CHANGE_SET_FIELD:
            partialSteps = updateStep(state.get('steps'), action);
            return state.merge({
                steps: partialSteps,
                inProgress: true
            });
        case actionTypes.PROFILE_EDIT_WILL_CANCEL:
        case actionTypes.PROFILE_EDIT_WILL_LEAVE:
            return state.merge({
                willLeave: true
            })
        case actionTypes.FETCH_BIOCATCH_SCORE_REQUEST:
            return state.merge({
                isFetchingBiocatchScore: true,
                biocatchError: false,
            });
        case actionTypes.FETCH_BIOCATCH_SCORE_FAILURE:
            return state.merge({
                biocatchError: true,
                isFetchingBiocatchScore: false,
                biocatchScore: REGENERATE_SIGNATURE_HIGH_BIOCATCH_SCORE
            });
        case actionTypes.FETCH_BIOCATCH_SCORE_SUCCESS:
            return state.merge({
                biocatchScore: REGENERATE_SIGNATURE_LOW_BIOCATCH_SCORE,
                isFetchingBiocatchScore: false,
            });
        case actionTypes.FETCH_KEY_SIGNATURE_DATA_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_KEY_SIGNATURE_DATA_SUCCESS:
            return state.mergeDeep({
                cmcContract: action.payload.cmcContract,
                errorReceived: '',
                isFetching: false
            });
        case actionTypes.FETCH_KEY_SIGNATURE_DATA_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                isFetching: false
            });
        case actionTypes.SET_KEY_SIGNATURE_CHANGE_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.SET_KEY_SIGNATURE_CHANGE_SUCCESS:
            return state.merge(setInitialState(),{
                submitSuccessful: true,
                inProgress: false,
                biocatchScore: state.get('biocatchScore')
            });
        case actionTypes.SET_KEY_SIGNATURE_CHANGE_FAILURE:
            return state.merge({
                errorReceived: action.payload.error,
                isFetching: false,
                submitSuccessful: true,
                inProgress: false
            });
        default:
            return state
    }
}

module.exports = myProfileKeysSignatureReducer;
