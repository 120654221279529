/**
 * Created by adderlyjaureguicampos on 03/01/17.
 */
const mockHelper = require('../utilities/mockDataHelper');
const formValidationHelper = require('../utilities/formValidationHelper');

function duoCombinedDeposit_loadCmbAccounts() {
    return mockHelper.mockCmbAccounts();
}

function duoCombinedDeposit_onChangeDropdownAccounts(accountId) {
    return mockHelper.mockOnChangeDropdownAccounts(accountId);
}

function duoCombinedDeposit_onChangeIntervener(item, typeSelected, index) {
    return mockHelper.onChangeIntervener(item, typeSelected, index);
}


function duoCombinedDeposit_onChangeAmount(amount){
    let ret=[];
    ret.push(
        {
            target: "importe_depositar",
            value: amount
        }
    );
    return ret;
}

function validateStep1(card_details) {
    return  {
        card_details: card_details,
        isValidStep: true
    }
}

function validateStep2(card_details) {
    return  {
        card_details: card_details,
        isValidStep: true
    }
}

function validateStep3(card_details) {
    let isValidAccount = card_details.find(x => x.get('id') === 'cta_seleccionada').get('value') !== '' && card_details.find(x => x.get('id') === 'cta_seleccionada').get('value') !== null;
    card_details=formValidationHelper.updateValue(card_details,'cta_seleccionada',!isValidAccount);
    if (isValidAccount){
        let interveners = card_details.find( card => card.get("id") === 'intervinientes_list').get("value");
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners.map(intervener => intervener.get("type") !== '').toArray();
        }

        let  validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        card_details=formValidationHelper.updateValue(card_details,'cta_seleccionada',!isValidAccount);
    }
    const slideAmountIsNonZero = card_details.find(card => card.get('id') === 'importe_depositar').get('value') !== 0;
    let ret={
        card_details:card_details,
        isValidStep: isValidAccount && slideAmountIsNonZero
    };



    ret.card_details = card_details;

    return ret;
}

function validateStep4(card_details) {
    return  {
        card_details: card_details,
        isValidStep: true
    }
}

function duo_combined_deposit_validateStep(step, state) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');

    switch (step) {
        case 1:
            return validateStep1(immCardDetails);

        case 2:
            return validateStep2(immCardDetails);

        case 3:
            return validateStep3(immCardDetails);

        case 4:
            return validateStep4(immCardDetails);
    }
}

module.exports = {
    duoCombinedDeposit_onChangeAmount: duoCombinedDeposit_onChangeAmount,
    duo_combined_deposit_validateStep: duo_combined_deposit_validateStep,
    duoCombinedDeposit_loadCmbAccounts: duoCombinedDeposit_loadCmbAccounts,
    duoCombinedDeposit_onChangeDropdownAccounts: duoCombinedDeposit_onChangeDropdownAccounts,
    duoCombinedDeposit_onChangeIntervener: duoCombinedDeposit_onChangeIntervener
};
