// @ vendors
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    id: null,
    document: null,
    error: null
});

const holderConfirmDocuments = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_SUCCESS:
            return state.merge({
                document: action.data.data,
                error: '',
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_FAILURE:
            return state.merge({
                document: null,
                error: action.data.error,
                isFetching: false
            });
        case actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_RESET:
            return state.merge(initialState);
        default:
            return state
    }
};

module.exports = holderConfirmDocuments;
