//@vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
//@constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: false,
    success: false,
    isFetching: false,
    installmentData: {}
});

const mapInstallments = (state, response) => {
    const installmentList = get(response, 'salidaSimulacion.lamortiz.amortiz', []).map(item => {
        const currency = get(response, 'importeTotal.divisa', '') || '';

        return {
            situation: item.dessitua, //situation
            date: item.feproamo, // payment date
            impmin3: {
                amount: item.impmin3, // total installment amount
                currency
            },
            impminca: {
                amount: item.impminca, // interest
                currency
            },
            impmincm: {
                amount: item.impmincm, // installment amount without interest
                currency
            },
            pendingAmount: {
                amount: item.jimppdte, // pending capital
                currency
            },
            numaxsub: item.numaxsub // number of installment
        };
    });

    return state.mergeDeep({
        success: true,
        error: false,
        isFetching: false,
        installmentData: {
            totalImport: {
                amount: get(response, 'importeTotal.importe', 0) || 0,
                currency: get(response, 'importeTotal.divisa', '') || ''
            },
            numberOfInstallments: get(response, 'salidaSimulacion.jnumplaz', false) || false,
            isAbleToCancel: !!installmentList && installmentList.length > 0 && installmentList[installmentList.length - 1].pendingAmount.amount > 0,
            installmentList
        }
    });
};

function cardMovementsInstallmentChartReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARD_MOVEMENT_INSTALLMENTS_CHART_REQUEST:
            return state
                .merge({
                    error: false,
                    success: false,
                    isFetching: true
                })
                .set('installmentData', Immutable.fromJS({}));
        case actionTypes.CARD_MOVEMENT_INSTALLMENTS_CHART_SUCCESS:
            return mapInstallments(state, action.payload.response);
        case actionTypes.CARD_MOVEMENT_INSTALLMENTS_CHART_FAILURE:
            return state.merge({
                isFetching: false,
                success: false,
                error: action.payload.error
            });
        default:
            return state;
    }
}

module.exports = cardMovementsInstallmentChartReducer;
