//@vendors
const Immutable = require('immutable');
//@helpers
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');
const accountCancelChequebookStep1 = require('./accountCancelChequebook/accountCancelChequebookStep1');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            accountCancelChequebookStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        willCancel: false,
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return accountCancelChequebookStep1(step, action);
        }
    })
}

function accountCancelChequebookReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) { //This could be an if eventually - Leave it for consistency only.
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    })
            }
            return state;
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                errorReceived: '',
            })
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_FAILURE:
            return state.merge({
                isFetching: false,
                submitSuccessful: false,
                errorReceived: action.payload.error,
            })
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_SET_MOTIVE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);

            return state.merge({
                steps: partialSteps
            })
        case actionTypes.ACCOUNT_CANCEL_CHEQUEBOOK_RESET:
            return setInitialState();
        default:
            return state
    }
}

module.exports = accountCancelChequebookReducer;
