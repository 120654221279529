// @ vendor
const Immutable = require('immutable');
const moment = require('moment');
// @constants
const { DATE_FILTER_NONE, EURO_TEXT } = require('constants/index');
const actionTypes = require('constants/actionTypes');
// @helpers
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const sortBy = require('lodash/collection/sortBy');

// @utilities
const { hash } = require('utilities/hash');

const initialState = Immutable.fromJS({
    promotionsIsFetching: false,
    promotionsSuccess: false,
    promotionsError: '',
    promotionsList: [],
    referralsIsFetching: false,
    referralsSuccess: false,
    referralsError: '',
    byOrder: [],
    byId: {},
    giftTypes: [],
    totalAmountEarned: {
        amount: 0,
        currency: EURO_TEXT,
    },
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: null,
            to: null
        },
        state: {
            isActive: false,
            selected: ''
        },
        gift: {
            isActive: false,
            selected: ''
        }
    }
});

function processReferrals(state, invitationDetailList, totalAccumulateMgmTotal) {
    const giftTypes = [];
    let byId = {};
    let byOrder = [];

    invitationDetailList.forEach(promo => {
        const promotionDetails = promo.promotionDetails;
        const referrals = promo.guardianDetailList;
        const gift = trim(promotionDetails.giftAmount) || '';
        const giftType = trim(promotionDetails.giftType) || '';

        if (!giftTypes.includes(giftType)) {
            giftTypes.push(giftType);
        }

        if (referrals && referrals.length) {
            referrals.forEach(referral => {
                const id = hash([referral.guardianMail, referral.invitationCode]);
                const promotionCode = referral.promotionCode || '';
                const immPromo = state.get('allPromotions').find(promo => promo.get('promotionCode') === promotionCode);

                byId[id] = {
                    id,
                    'date': trim(get(referral, 'invitationDate', '')),
                    'invitationCode': trim(get(referral, 'invitationCode', '')),
                    'invitationDate': trim(get(referral, 'invitationDate', '')),
                    'utilizationDate': trim(get(referral, 'utilizationDate', '')),
                    'status': trim(get(referral, 'status', '')),
                    'promotionName': '',
                    'promotionUrl': '',
                    'gift': gift,
                    'giftType': giftType
                };

                if (!!immPromo) {
                    byId[id].promotionName = immPromo.get('title');
                    byId[id].promotionUrl = immPromo.get('baseUrl');
                }

                byOrder.push(id);
         });
        }
    });


    return state.mergeDeep({
        referralsIsFetching: false,
        referralsSuccess: true,
        totalAmountEarned: {
            amount: totalAccumulateMgmTotal || 0
        }
    })
    .set('giftTypes', Immutable.fromJS(giftTypes))
    .set('byId', Immutable.fromJS(byId))
    .set('byOrder', Immutable.fromJS(byOrder));
}

function processPromotions(state, promotionsList = []) {
    let promotionsListParsed = [];
    let promotionsListSorted = promotionsList.filter((promotion) => (
        moment().isBetween(moment(promotion.validFrom), moment(promotion.validTo), 'day', '[]')
        // The [] is being used because the start and end dates must be included
    ));

    promotionsListSorted = sortBy(promotionsListSorted, promotion => promotion.validFrom);

    if (promotionsList.length > 0) {
        promotionsListParsed = promotionsListSorted.map(promotion => {
            return {
                'applicationId': trim(get(promotion, 'applicationId', '')),
                'baseUrl': trim(get(promotion, 'baseUrl', '')),
                'imgUrl': trim(get(promotion, 'imgUrl', '')),
                'lang': trim(get(promotion, 'lang', '')),
                'mobileImgUrl': trim(get(promotion, 'mobileImgUrl', '')),
                'productId': trim(get(promotion, 'productId', '')),
                'promotionCode': trim(get(promotion, 'promotionCode', '')),
                'subtitle': trim(get(promotion, 'subtitle', '')),
                'title': trim(get(promotion, 'title', '')),
                'validFrom': trim(get(promotion, 'validFrom', '')),
                'validTo': trim(get(promotion, 'validTo', ''))
            };
        });
    }

    return state.merge({
        promotionsIsFetching: false,
        promotionsSuccess: true
    })
    .set('promotionsList', Immutable.fromJS(promotionsListParsed))
    .set('allPromotions', Immutable.fromJS(promotionsList));
}

function referralsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REFERRALS_GET_PROMOTION_DETAILS_IS_FETCHING:
            return state.merge({
                promotionsError: '',
                promotionsIsFetching: true,
                promotionsList: []
            });
        case actionTypes.REFERRALS_GET_PROMOTION_DETAILS_SUCCESS:
            const promotionsList = get(action, 'payload.promotionsList', []);
            return processPromotions(state, promotionsList);
        case actionTypes.REFERRALS_GET_PROMOTION_DETAILS_ERROR:
            return state.merge({
                promotionsError: get(action, 'payload.error', ''),
                promotionsIsFetching: false
            });
        case actionTypes.REFERRALS_SET_GUARDIAN_INVITATION_SUCCESS:
            return processReferrals(state, action.payload.invitationDetailList, action.payload.totalAccumulateMgmTotal);
        case actionTypes.REFERRALS_SET_GUARDIAN_INVITATION_REQUEST:
            return state.merge({
                referralsIsFetching: true,
                referralsError: ''
            });
        case actionTypes.REFERRALS_SET_GUARDIAN_INVITATION_ERROR:
            return state.merge({
                referralsError: get(action, 'payload.error', ''),
                referralsIsFetching: false
            });
        case actionTypes.REFERRALS_INVITATIONS_SET_FILTERS:
            return state.set('filters', Immutable.fromJS(action.payload.filters));
        case actionTypes.REFERRALS_INVITATIONS_CLEAR_FILTERS:
            return state.set('filters', initialState.get('filters'));
        case actionTypes.REFERRALS_INVITATIONS_RESET_FILTER:
            const { filterKey } = action.payload;
            return state.setIn(['filters', filterKey], initialState.getIn(['filters', filterKey]));
        default:
            return state;
    }
}

module.exports = referralsReducer;
