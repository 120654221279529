// @vendors
const Immutable = require('immutable');

// @constants
const INITIAL_STATE = Immutable.fromJS({
    hasSignaturePattern: true
});

function brokerCashChargeStep2Reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        default:
            return state;
    }
}

module.exports = brokerCashChargeStep2Reducer;
