// @vendor
const Immutable = require('immutable');

// @constants
const {
    SET_COLLECTIVE_INFO,
    SET_COLLECTIVES_DATA,
    GET_COLLECTIVE_INFO_FAILURE,
    GET_COLLECTIVE_INFO_IN_PROGRESS,
    GET_COLLECTIVE_INFO_SUCCESS
} = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    collectivesList: [],
    collectivesInfo: [],
    collective: {
        productsList: []
    },
    dataLoaded: false,
    infoLoaded: false,
    inProgress: false,
    error: false
});

const parseCollectivesList = collectives => {
    let collectivesList = [];
    if (!!collectives) {
        if (Array.isArray(collectives)) {
            collectivesList = collectives.map(collectiveData => {
                return {
                    title: collectiveData.title,
                    targetAudience: collectiveData.target_audience,
                    backgroundColor: collectiveData.bg_color,
                    textColor: collectiveData.text_color
                };
            });
        } else {
            const collective = {
                title: collectives.title,
                targetAudience: collectives.target_audience,
                backgroundColor: collectives.bg_color,
                textColor: collectives.text_color
            };

            collectivesList.push(collective);
        }
    }

    return collectivesList;
};

const processProductsList = (currentProducts, newProductList) => {
    let productsList = [];
    if (Array.isArray(newProductList)) {
        newProductList.map(productInfo => {
            const isCollective = productInfo.isCollectiveProduct;
            const alias = productInfo.alias;
            const existingProduct = currentProducts.find(product => product.alias === alias);

            if (isCollective && !existingProduct) {
                productsList.push({
                    type: productInfo.type,
                    subType: productInfo.subType,
                    standard: productInfo.standard,
                    alias,
                    isCollective,
                    productName: productInfo.productName
                });
            }
        });
    }

    return [...currentProducts, ...productsList];
};

const parseCollectiveInfo = collectiveInfo => {
    return {
        isCollective: collectiveInfo.isCollectives,
        collectiveID: collectiveInfo.collectivesId,
    };
};

const setCollectiveInfo = (state, action) => {
    const collectivesInfo = state.get('collectivesInfo').push(Immutable.fromJS({
        alias: action.payload.response.params.alias,
        type: action.payload.response.params.type,
        subType: action.payload.response.params.subType,
        standard: action.payload.response.params.standard,
        collectiveInfo: action.payload.response.collectiveInfo
    }));

    return state.merge({ collectivesInfo });
};

const collectives = (state = initialState, action) => {
    switch (action.type) {
        case SET_COLLECTIVES_DATA:
            const collectivesList = parseCollectivesList(action.payload.response.collectives)
            return state.merge({
                collectivesList,
                dataLoaded: true
            });
        case GET_COLLECTIVE_INFO_IN_PROGRESS:
            return state.set('inProgress', true);
        case GET_COLLECTIVE_INFO_SUCCESS:
            const collectiveInfo = action.payload.response.collectiveInfo;
            const currentProducts = state.getIn(['collective', 'productsList']).toJS();
            const productsList = processProductsList(currentProducts, collectiveInfo.products);
            const collective = parseCollectiveInfo(collectiveInfo);
            const partialMerge = state.mergeDeep({
                collective: {
                    productsList
                },
                infoLoaded: true
            });
            return partialMerge.mergeDeep({
                collective,
                inProgress: false,
                error: false
            });
        case GET_COLLECTIVE_INFO_FAILURE:
            return state.set('error', true);
        case SET_COLLECTIVE_INFO:
            return setCollectiveInfo(state, action);
        default:
            return state;
    }
}

module.exports = collectives;
