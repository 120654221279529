const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    fatcaRequested: false,
    modalVisible: false
})

function fatcaDocumentation(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FATCA_OPEN_MODAL:
            return state.mergeDeep({
                fatcaRequested: true,
                modalVisible: true
            });
        case actionTypes.FATCA_CLOSE_MODAL:
            return state.mergeDeep({
                modalVisible: false
            });
        default:
            return state;
    }
}



module.exports = fatcaDocumentation;