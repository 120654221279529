// @ vendor
const Immutable = require('immutable');

// @ reducers
const openYoungCardPaymentChoreStep1 = require('./openYoungCardPaymentChore/openYoungCardPaymentChoreStep1');

// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP } = require('constants/index');

function setInitialState() {
    return Immutable.fromJS({
        allAccountsInactive: false,
        steps: [
            openYoungCardPaymentChoreStep1(undefined, {type: null}),
            {hasSignaturePattern: true}
        ],
        visibleStep: 1,
        isFetching: false,
        submitSuccessful: false,
        submitError: undefined,
        willLeave: false,
        willCancel: false,
        error: ''
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return openYoungCardPaymentChoreStep1(step, action);
        }
    });
}

function openYoungCardPaymentChoreReducer(state = setInitialState(), action) {
    let partialSteps;
    switch(action.type) {
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_RESET:
            return setInitialState();
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_VALIDATE_STEP:
            let visibleStep;
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.get(FIRST_STEP).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_REMARK:
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_AMOUNT:
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT:
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_CARD_AVAILABLE_BALANCE:
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_ACCOUNT_AVAILABLE_BALANCE:
            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.OPENYOUNG_CARD_PAYMENT_CHORE_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });
        default:
            return state;
    }
}

module.exports = openYoungCardPaymentChoreReducer;
