//@vendor
const Immutable = require('immutable');
//@data
const { cardBenefits } = require('constants/cardBenefits');
//@constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: false,
    success: false,
    benefitsList: {}
});

function prepareBenefits(cardType) {
    return Immutable.fromJS(cardBenefits[cardType]);
}

function CardBenefitsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARD_BENEFITS_SET_BENEFIT_LIST: 
            return state.set('benefitsList', prepareBenefits(action.payload.cardType));
        default: 
            return state;
    }
}

module.exports = CardBenefitsReducer;