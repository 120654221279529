// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    error: '',
    insightDetails: {},
    insightId: '',
    isFetching: false,
    response: {},
    success: false,
});

function personeticsInsightDetailsReducer(state = initialState, action = {type: null}) {
    switch (action.type) {
        case actionTypes.GET_PERSONETICS_INSIGHT_DETAILS_REQUEST:
            return state.merge({
                error: '',
                isFetching: true,
                success: false
            });
        case actionTypes.GET_PERSONETICS_INSIGHT_DETAILS_SUCCESS:
            return state.merge({
                error: '',
                inboxInsights: action.payload.response.insights,
                insightId: action.payload.insightId,
                isFetching: false,
                response: action.payload.response,
                success: true
            })
        case actionTypes.GET_PERSONETICS_INSIGHT_DETAILS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            })
        default:
            return state;
    }
}

module.exports = personeticsInsightDetailsReducer;