const { EURO_SYMBOL, MONTHS } = require('constants/index');

module.exports = {
    title: 'contractsView-preApprovedLoanStep2Title',
    order: 2,
    footer_text: '',
    position: 'middle',
    info: function(){
    },
    components: [
        {
            id_component: 'slider',
            id: 'slider_money_needed',
            label: 'contractsView-preApprovedLoanSlider1Label',
            min_value: 600, // @hardcoded
            max_value: 18000, // @hardcoded
            type: 'single',
            format_type: EURO_SYMBOL, // @hardcoded
            default_value: 9000, // @hardcoded
            order: 1,
            onChangeFn: 'onChangeSliderMoneyNeeded',
            value: 9000, // @hardcoded
            is_money: true
        },
        {
            id_component: 'slider',
            id: 'slider_months_needed',
            label: 'contractsView-preApprovedLoanSlider2Label',
            min_value: 3, // @hardcoded
            max_value: 60, // @hardcoded
            type: 'single',
            format_type: MONTHS,
            default_value: 30, // @hardcoded
            order: 2,
            onChangeFn: 'onChangeSliderMonthsNeeded',
            value: 30 // @hardcoded
        },
        {
            id_component: 'combo',
            label: 'contractsView-preApprovedLoanComboLabel',
            type: 'single',
            placeholder: 'contractsView-preApprovedLoanComboPlaceholder',
            source: 'loadCmbPurpose',
            onChangeFn: 'onChangeCmbPurpose',
            target: 'finalidad_seleccionado',
            options: [],
            order: 3,
            msg_error: 'contractsView-preApprovedLoanComboErrorMsg'
        }
    ]
}
