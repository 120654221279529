// @ constants
const { AGENT_INFO } = require('constants/session');
const {
    AGENT_LOGIN_DISASTER,
    DISASTER_RECOVERY_CUSTOMER_FINDER,
} = require('constants/secureRouting');
// @ utils
const usd = require('utilities/usd');
const Storage = require('utilities/storage');
const { cleanResources } = require('utilities/cleanHelper');
const { cookiesDomain } = require('core/environmentConfig');

const isDisasterRecoveryMode = () =>{
    return !!Storage.cookies.get(AGENT_INFO);
}

const isInOffice = () => {
    const agenInfoCookie = Storage.cookies.get(AGENT_INFO);
    if (!agenInfoCookie) {
        return false;
    }

    const agentInfo = JSON.parse(agenInfoCookie);
    return agentInfo.inOffice;
};

const removeClientInfo = () => {
    cleanResources(cookiesDomain);
}

const removeAgentLoginInfo = () => {
    Storage.cookies.remove(AGENT_INFO, {domain: cookiesDomain});
}

const saveAgentLoginInfo = (info) =>{
    Storage.cookies.set(AGENT_INFO,JSON.stringify(info));
}

const clientLogoutDisasterRecovery = (history) => {
    history.push(DISASTER_RECOVERY_CUSTOMER_FINDER);
}

const agentLogoutDisasterRecovery = (history) => {
    removeClientInfo();
    removeAgentLoginInfo();
    usd.resetClient();
    usd.resetAgent();
    usd.cleanDisasterRecoveryStore();
    history.push(AGENT_LOGIN_DISASTER);
}

const retrieveAgentToken = () => {
    const agentInfoCookie = Storage.cookies.get(AGENT_INFO);
    if (!agentInfoCookie) {
        return null;
    }

    const agentInfo = JSON.parse(agentInfoCookie);
    return agentInfo.bankToken;
}

const retrieveAgentInfo = () => {
    const agentInfoCookie = Storage.cookies.get(AGENT_INFO);
    if (!agentInfoCookie) {
        return null;
    }

    const agentInfo = JSON.parse(agentInfoCookie);
    return agentInfo;
}

module.exports = {
    agentLogoutDisasterRecovery,
    clientLogoutDisasterRecovery,
    isDisasterRecoveryMode,
    isInOffice,
    removeAgentLoginInfo,
    retrieveAgentInfo,
    retrieveAgentToken,
    saveAgentLoginInfo,
}
