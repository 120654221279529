module.exports = {
    title: 'contractsView-contractsView_precontractualConditionsTitle',
    order: 3,
    footer_text: '',
    position: 'middle',
    disableNext: 'depositrenewal_pollingNextButtonDisabled',
    on_next_click: 'depositrenewal_loadTermsAndConditionsLegalWindow',
	components: [
		{
			id_component: 'precontractual-conditions',
			id: 'precontractual-conditions',
            customCategoryEvent: 'depositrenewal_customCategoryEvent'
		},
        ...(
                __CONTACT_CENTER__ ?
                [{
                    id_component: 'legal-information',
                    legal_text: 'depositos-depositos_messiLegalTextCC',
                    legal_text_passport: 'depositos-depositos_messiLegalTextPassportCC',
                }] : []
            )
	]
};