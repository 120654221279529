const Immutable = require('immutable');

const {
    MONTHLY__FETCHING,
    MONTHLY__SUCCESS,
    MONTHLY__FAILURE,
    MONTHLY__TOTALIZED__FETCHING,
    MONTHLY__TOTALIZED__SUCCESS,
    MONTHLY__TOTALIZED__FAILURE
} = require('constants/actionTypes');

const setInitialState = () => {
    return (
        Immutable.fromJS({
            error: false,
            fetching: false,
            errorTotalized: false,
            fetchingTotalized: false,
            monthlyValueTotalized: null,
            monthlyValue: 0
        })
    );
};

function expensesByCategoriesMonthly(state = setInitialState(), action) {
    switch (action.type) {
        case MONTHLY__FETCHING:
            return state.merge({
                error: false,
                fetching: true
            });

        case MONTHLY__FAILURE:
            return state.merge({
                error: true,
                fetching: false
            });

        case MONTHLY__SUCCESS:
            return state.merge({
                error: false,
                fetching: false,
                monthlyValue: action.payload.data
            });

        case MONTHLY__TOTALIZED__FETCHING:
            return state.merge({
                errorTotalized: false,
                fetchingTotalized: true
            });

        case MONTHLY__TOTALIZED__FAILURE:
            return state.merge({
                errorTotalized: true,
                fetchingTotalized: false
            });

        case MONTHLY__TOTALIZED__SUCCESS:
            return state.merge({
                errorTotalized: false,
                fetchingTotalized: false,
                monthlyValueTotalized: action.payload
            });
        default:
            return state;
    }
}

module.exports = expensesByCategoriesMonthly;